import React from "react";
import _ from "lodash";

class MobileLettersView extends React.Component {
	constructor() {
		super();
		this.onShiftClick = this.onShiftClick.bind( this );
	}

	onShiftClick() {
		if ( this.props.shift ) {
			this.props.onChangeMode( "alpha" );
			return;
		}
		this.props.onChangeMode( "shift" );
	}

	handleKey( key ) {
		this.context.focusManager.handleKey( key );
	}

	renderRowContent( symbols ) {
		return _.map(
			symbols.split( "" ),
			symbol => (
				<div
					key={ symbol }
					className="key one"
					onClick={ this.handleKey.bind( this, symbol ) }
				>
					{symbol}
				</div>
			)
		);
	}

	render() {
		let {shift} = this.props;
		let row1 = shift ? "ЙЦУКЕНГШЩЗХЪ" : "йцукенгшщзхъ";
		let row2 = shift ? "ФЫВАПРОЛДЖЭ" : "фывапролджэ";
		let row3 = shift ? "ЯЧСМИТЬБЮ" : "ячсмитьбю";
		let shiftIcon = shift ? "shift_active": "up";
		return (
			<div className="mobile-keyboard visible-xs">
				<section className="key-row light">
					{this.renderRowContent( row1 )}
				</section>
				<section className="key-row dark">
					{this.renderRowContent( row2 )}
				</section>
				<section className="key-row light">
					<div
						className="key one icon"
						onClick={ this.onShiftClick }
					>
						<img src={ `web/img/${shiftIcon}.svg` } alt="" />
					</div>
					{this.renderRowContent( row3 )}
					<div
						className="key one icon"
						onClick={ this.handleKey.bind( this, "Backspace" ) }
					>
						<img src="web/img/back.svg" alt="" />
					</div>
				</section>
				<section className="key-row">
					<div
						className="key two"
						onClick={this.props.onChangeMode.bind( this, "digits" )}
					>{"?123"}</div>
					<div
						className="key one"
						onClick={this.props.onLangChange}
					>RU</div>
					<div
						className="key three icon"
						onClick={ this.handleKey.bind( this, " " ) }
					>пробел</div>
					<div
						className="key one"
						onClick={ this.handleKey.bind( this, "." ) }
					>
						.
					</div>
					<div
						className="key two enter"
						onClick={ this.handleKey.bind( this, "Enter" ) }
					>ввод</div>
				</section>
			</div>
		);
	}

	static get contextTypes() {
		return { focusManager: React.PropTypes.object.isRequired };
	}
}

export default MobileLettersView;
