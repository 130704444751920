import Rx from "rx";
import _ from "lodash";
import ssgCrypto, {Key, KEY_KINDS} from "ssg.crypto";
import Queue from "promise-queue";

import EncryptedRepository from "../encrypted.repository.js";
import {isValidBaseURL} from "../../../common/utils.js";

import Contact from "../../models/contact.js";
import DebuggableError from "../../errors/debuggable.error.js";

let zero32 = new Buffer( 32 ).fill( 0 );
export const encryptedContactRecordSize = 256;//bytes
let contactRecordSize = 256 - 16;//bytes

let fileName = "contact1.dat";

let types = Object.create( null, {
	normal: { value: 1 },
	group: { value: 2 },
	multi: { value: 3 },
	multidescription: { value: 4 },
	admin: { value: 5 }
} );

//TODO: export
let typeByInt = {
	1: "normal",
	2: "group",
	3: "multi",
	4: "multidescription",
	5: "admin"
};

let statuses = Object.create( null, {
	invited: { value: 1 },
	joining: { value: 2 },
	active: { value: 3 },
	failed: { value: 4 },
	disconnected: { value: 5 }
} );

let statusByInt = {
	1: "invited",
	2: "joining",
	3: "active",
	4: "failed",
	5: "disconnected"
};

// format:
//json: {
// 0-7: zeros
// 8: type ( normal, group, multi )
// 9: unreadCount
// 10: status ( invited, active )
// 11-14: id
// 15-15: reserved
// 16-47: sharedId - 32 bytes
// 48-51: mainIndexInaccurate
// 52-55: metaIndexInaccurate--
// 56-119: apiUrlBase
// 120-195: name
// 196-227: globalId
// 228-231: multidescriptionId
// 232-239: lastMessageTS
//}
function serializeContact( json, size ) {
	if ( !( json.id > 0 ) ) {
		throw new Error( `id required: ${json.id}` );
	}
	if ( !Buffer.isBuffer( json.sharedId ) ) {
		throw new Error( "sharedId must be a buffer" );
	}

	if ( json.sharedId.length !== 32 ) {
		throw new Error( "sharedId invalid size" );
	}

	if ( json.sharedId.equals( zero32 ) ) {
		throw new Error( "sharedId zero on serialize" );
	}

	if ( json.unreadCount !== ( json.unreadCount | 0 ) ) {
		throw new Error( "unreadCount int required" );
	}

	if ( !statuses[ json.status ] ) {
		throw new Error( "invalid status" );
	}

	if ( json.mainIndexInaccurate !== ( json.mainIndexInaccurate | 0 ) ) {
		throw new Error( "mainIndexInaccurate int required", json.mainIndexInaccurate );
	}

	// if ( json.metaIndexInaccurate !== ( json.metaIndexInaccurate | 0 ) ) {
	// 	throw new Error( "metaIndexInaccurate int required" );
	// }

	if ( typeof json.apiUrlBase !== "string" || !isValidBaseURL( json.apiUrlBase ) ) {
		throw new Error( "apiUrlBase url required" );
	}

	if ( typeof json.name !== "string" ) {
		throw new Error( "name string required" );
	}

	let mb = ssgCrypto.createManagedBuffer( size );
	mb.useAsBuffer( buffer => {
		serializeContactToBuffer( json, buffer );
	} );
	return mb;
}

export function serializeContactToBuffer( json, buffer ) {
	let unreadByte = Math.min( 101, Math.max( 0, json.unreadCount | 0 ) );

	buffer.fill( 0 );

	// 8: type ( normal, group, multi, multidescription ). Most significant bit = 1 for external contacts
	buffer[ 8 ] = types[ json.type ] | ( json.isExternal ? 128 : 0 );
	// 9: unreadCount
	buffer[ 9 ] = unreadByte;
	// 10: status ( invited, active )
	buffer[ 10 ] = statuses[ json.status ];
	// 11-14: id
	buffer.writeUInt32LE( json.id, 11 );
	// 16-47: sharedId - 32 bytes
	json.sharedId.copy( buffer, 16 );

	// 48-51: mainIndexInaccurate
	buffer.writeUInt32LE( json.mainIndexInaccurate, 48 );
	// 52-55: lastMetaIndexInaccurate
	// buffer.writeUInt32LE( json.metaIndexInaccurate, 52 );
	// 56-119: apiUrlBase
	buffer.write( json.apiUrlBase, 56, 64 );
	// 120-195: name
	buffer.write( json.name, 120, 76 );

	// 196-227: globalId
	if ( json.globalId ) {
		json.globalId.copy( buffer, 196 );
	}

	// 228-231: multidescriptionId
	buffer.writeUInt32LE( json.multidescriptionId + 1, 228 );
	// 232-239: lastMessageTS
	buffer.writeIntLE( json.lastMessageTS || 0, 232, 6 );
}

export function serialize( json, size ) {
	if ( !json ) {
		throw new Error( "Trying to serialize empty contact" );
	}

	if ( !types[ json.type ] ) {
		throw new Error( "invalid type" );
	}

	return serializeContact( json, size );
}

function deserializeContact( buffer ) {
	// 11-14: id
	let id = buffer.readUInt32LE( 11 );
	if ( !id ) {
		throw new Error( "contact id 0" );
	}
	let urlEndIndex = buffer.indexOf( 0, 56 );
	if ( !~urlEndIndex || urlEndIndex > 119 ) {
		urlEndIndex = 120;
	}

	let nameEndIndex = buffer.indexOf( 0, 120 );
	if ( !~nameEndIndex ) {
		nameEndIndex = buffer.length;
	}
	let sharedId = new Buffer( 32 );
	buffer.copy( sharedId, 0, 16, 48 );
	if ( sharedId.equals( zero32 ) ) {
		throw new Error( "sharedId zero on deserialize" );
	}

	let globalId = new Buffer( 32 );
	buffer.copy( globalId, 0, 196, 228 );

	if ( globalId.equals( zero32 ) ) {
		globalId = null;
	}

	return {
		id,
		// 8: type ( normal, group, multi, multidescription ) . Most significant bit = 1 for external contacts
		type: typeByInt[ buffer[ 8 ] & 127 ],
		isExternal: !!( buffer[ 8 ] & 128 ),
		// 9: unreadCount
		unreadCount: buffer[ 9 ],
		// 10: status ( invited, active )
		status: statusByInt[ buffer[ 10 ] ],
		// 16-47: sharedId - 32 bytes
		sharedId,
		// 48-51: mainIndexInaccurate
		mainIndexInaccurate: buffer.readUInt32LE( 48 ),
		// 52-55: metaIndexInaccurate
//		metaIndexInaccurate: buffer.readUInt32LE( 52 ),
		// 56-119: apiUrlBase
		apiUrlBase: buffer.toString( "utf8", 56, urlEndIndex ),
		// 120-228: name
		name: buffer.toString( "utf8", 120, nameEndIndex ),
		// 228-231: multidescriptionId
		multidescriptionId: buffer.readUInt32LE( 228 ) - 1,
		// 232-239: lastMessageTS
		lastMessageTS: buffer.readIntLE( 232, 6 ) || undefined,
		globalId
	};
}

export function deserialize( mb ) {
	let json;

	mb.useAsBuffer( buffer => {
		if ( buffer.length !== contactRecordSize ) {
			throw new Error( "Trying to deserialize contact list entry from a buffer of invalid size" );
		}

		if ( buffer.readUInt32LE( 0 ) || buffer.readUInt32LE( 4 ) ) {
			json = { invalid: true };
			return;
		}

		json = deserializeContact( buffer );
	} );
	return json;
}

//File format:
//0: version. Everything else may be changed on next ersions. 1 for now.
//4: record size.
//record count = file size / record size
class ContactListRepository extends EncryptedRepository {
	constructor( StorageType, fileNumber = 1 ) {
		super( StorageType, `contact${fileNumber}.dat` );
		this._StorageType = StorageType;

		this._queue = new Queue( 1 );
	}

	_enqueue( func ) {
		if ( !this._encryptionKey ) {
			throw new Error( "Init first" );
		}
		return this.lockStorageThen( func );
	}

	init( encryptionKey ) {
		if ( this._encryptionKey ) {
			throw new DebuggableError( "Already initialized" );
		}
		if ( !( encryptionKey instanceof Key ) ) {
			throw new Error( "Invalid key type" );
		}
		if ( encryptionKey.kind !== KEY_KINDS.SYMMETRIC_ENCRYPTION ) {
			throw new Error( "Invalid key kind" );
		}
		this._encryptionKey = encryptionKey;
	}

	uninitAsync( ) {
		return Rx.Observable.fromPromise( this.uninitThen() );
	}

	uninitThen( ) {
		if ( !this._encryptionKey ) {
			return Promise.resolve();
		}
		return this._enqueue( () => {
			delete this._encryptionKey;
			return Promise.resolve();
		} );
	}

	_writeRandomAsync( index ) {
		return Rx.Observable.fromPromise( this._writeRandomThen( index ) );
	}

	_writeRandomThen( index ) {
		return (
			ssgCrypto.createRandomBufferThen( encryptedContactRecordSize )
				.then( randomBuffer => this._enqueue( storage =>
					this._writeThen( storage, randomBuffer, index )
				) )
			);
	}

	_writeAsync( storage, buffer, index ) {
		return Rx.Observable.fromPromise( this._writeThen( storage, buffer, index ) );
	}

	_writeThen( storage, buffer, index ) {
		if ( this._writeHook ) {
			return (
				this._writeHook( buffer, index )
					.then( () => storage.writeAtPositionThen(
						buffer,
						index * encryptedContactRecordSize
					) )
			);
		}
		return storage.writeAtPositionAsync(
			buffer,
			index * encryptedContactRecordSize
		);
	}

	_readAsync( storage, index ) {
		return storage.readAtPositionAsync( index * encryptedContactRecordSize, encryptedContactRecordSize );
	}

	_readThen( storage, index ) {
		return storage.readAtPositionThen( index * encryptedContactRecordSize, encryptedContactRecordSize );
	}

	deleteThen( index ) {
		return this._writeRandomThen( index );
	}

	deleteAsync( index ) {
		return this._writeRandomAsync( index );
	}

	isAnyDataAsync( fileNumber ) {
		return this._StorageType.isFileExistsAsync( `contact${fileNumber}.dat` );
	}

	getAllAsJsonAsync( isReverse ) {
		return Rx.Observable.fromPromise( this.getAllAsJsonThen( isReverse ) );
	}

	getAllAsJsonThen( isReverse ) {
		let chunkCount;
		return (
			this.getAllEncryptedThen()
				.then( chunks => {
					if ( isReverse ) {
						chunks.reverse();
					}
					chunkCount = chunks.length;
					return chunks;
				} )
				.then( chunks => this._decryptRecursiveThen( chunks ) )
				.then( array => {
					while ( array.length < chunkCount ) {
						array.push( { invalid: 1 } );
					}
					return array;
				} )
		);
	}

	_decryptRecursiveThen( chunks ) {
		let result = [];
		let decryptOneByOneThen = () => {
			return this._decryptThen( chunks[ result.length ] ).then( resMB => {
				let res = deserialize( resMB );
				resMB.dispose();
				result.push( res );
				if ( !res.invalid && result.length < chunks.length ) {
					return decryptOneByOneThen();
				}
			} );
		}
		return decryptOneByOneThen().then( () => result );
	}

	getAllEncryptedAsync( ) {
		return Rx.Observable.fromPromise( this.getAllEncryptedThen( ) );
	}

	getAllEncryptedThen( ) {
		return (
			this._enqueue( storage => storage.readAsBufferThen() )
				.then( buffer => {
					let chunks = [];
					for ( let i = 0; i < buffer.length; i += encryptedContactRecordSize ) {
						chunks.push( buffer.slice( i, i + encryptedContactRecordSize ) );
					}
					return chunks;
				} )
		);
	}

	readAtAsync( index ) {
		return Rx.Observable.fromPromise( this.readAtThen( index ) );
	}

	readAtThen( index ) {
		return (
			this._enqueue( storage => storage.readAtPositionThen( index * encryptedContactRecordSize, encryptedContactRecordSize ) )
				.then( encryptedBuffer => this._decryptThen( encryptedBuffer ) )
				.then( deserialize )
		);
	}

	writeAtAsync( index, json ) {
		return Rx.Observable.fromPromise( this.writeAtThen( index, json ) );
	}

	writeAtThen( index, json ) {
		let buffer = serialize( json, contactRecordSize );
		return (
			this._encryptThen( buffer )
				.then( encryptedBuffer =>
					this._enqueue( storage =>
						this._writeThen( storage, encryptedBuffer, index )
					)
				)
		);
	}

	preallocateAsync( recordCount ) {
		return Rx.Observable.fromPromise( this.preallocateThen( recordCount ) );
	}

	preallocateThen( recordCount ) {
		return this._enqueue( storage =>
			ssgCrypto.createRandomBufferThen( encryptedContactRecordSize * recordCount )
				.then( buffer => storage.replaceThen( buffer ) )
		);
	}

	insertEmptyRecordsAsync( index, count ) {
		return Rx.Observable.fromPromise( this.insertEmptyRecordsThen( index, count ) );
	}

	insertEmptyRecordsThen( index, count ) {
		index *= encryptedContactRecordSize;

		return this._enqueue( storage =>
			Promise.all( [
				storage.readAsBufferThen(),
				ssgCrypto.createRandomBufferThen( count * encryptedContactRecordSize )
			] ).then( ( [ buffer, insertData ] ) => {
				let resultBuffer = Buffer.concat( [
					buffer.slice( 0, index ),
					insertData, buffer.slice( index )
				] );
				return storage.replaceThen( resultBuffer );
			} )
		);
	}

	// This method does not require prior initialization
	getItemsCountAsync( ) {
		return Rx.Observable.fromPromise( this.getItemsCountThen() );
	}

	getItemsCountThen( ) {
		return (
			this.lockStorageThen( storage => storage.getSizeThen() )
				.then( size => ( size / encryptedContactRecordSize ) | 0 )
		);
	}

	static isExistAsync( StorageType, fileNumber ) {
		return StorageType.isFileExistsAsync( `contact${fileNumber}.dat` );
	}

	static isExistThen( StorageType, fileNumber ) {
		return StorageType.isFileExistsThen( `contact${fileNumber}.dat` );
	}
}

export default ContactListRepository;
