import Rx from "rx";
import ServiceBase from "./service.base.js";

class LogService extends ServiceBase {
	errorAsync( error ) {
		let str = error && error.toString ? error.toString() : Object.prototype.toString.call( error );
		console.error( error );
		return (
			this.ajax( {
				url: "/log",
				responseType: "json",
				method: "POST",
				body: str
			} )
		);
	}
}

export default LogService;
