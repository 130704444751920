import React from "react";

class AttachmentView extends React.Component {
	render() {
		return (
			<div>
				{ this.props.attachment.getName() }
				{ this.props.attachment.getMimeType() }
				{ this.props.attachment.getSize() }
			</div>
		);
	}
}

class AttachmentTableView extends React.Component {
	render() {
		return (
			<div>
				{
					this.props.attachments.map(
					( attachment, index ) =>
						<AttachmentView
							attachment={ attachment }
							key={ "" + index + attachment.getName() }
						/>
					)
				}
			</div>
		);
	}
}

export default AttachmentTableView;
