import React from "react";
import _ from "lodash";
import Qr from "../../components/qr.jsx";
import InputWithHelp from "../../components/input.withhelp.jsx";
import Toast from "../../components/toast.jsx";
import {DynamicToast} from "../../components/toast.jsx";
import TopLevel from "../common/top.level.view.jsx";
import Translation from "../../components/translation.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import Button from "../../components/button.jsx";
import ImageButton from "../../components/button.image.jsx";
import OnlineStatus from "../../components/online.status.jsx"
import {bindStateProperty} from "../../../common/utils";
import translate from "../../translations/translate.js";
import {InternetRetryConfirm} from "../../components/dialogs.jsx"

class InvitedDescriptionView extends React.Component {
	constructor() {
		super();
		this.onBackPress = this.onBackPress.bind( this );
	}

	static get propTypes() {
		return {
			onBack: React.PropTypes.func
		};
	}

	static get defaultProps() {
		return {
			onBack: _.noop
		};
	}

	onBackPress() {
		this.props.onBack();
	}

	renderContent() {
		if ( this.props.contact.multidescriptionId === -1 ) {
			return <Translation textId="contact.status.invited.description"/>;
		}
		return <Translation textId="contact.shared.status.invited.description"/>;
	}

	render() {
		return (
			<TopLevel className="invite-page">
				<header>
					<ImageButton type="icon-arrow-left" onClick={this.onBackPress} id="back"/>
					<span className="header-caption"><Translation textId="invite.header"/></span>
					<OnlineStatus/>
				</header>
				<main>
					<div className="small-text">
						{ this.renderContent() }
					</div>
				</main>
				<DeviceBackButton onPress={ this.onBackPress }/>
			</TopLevel>
		);
	}
}
export default InvitedDescriptionView;
