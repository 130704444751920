import _ from "lodash";
import Rx from "rx";

import ssgCrypto, {Config} from "ssg.crypto";

var config, econfig, defaultConfig, setConfigSubj = new Rx.ReplaySubject( 1 );
if ( process.env.CONFIGURATION_FILE ) {
	defaultConfig = require( process.env.CONFIGURATION_FILE ).default;
} else {
	defaultConfig = require( "../config.utest.js" ).default;
}
var defaultEConfig = new Config( defaultConfig.defaultEncryptionConfig );
ssgCrypto.addTrustedConfig( defaultEConfig );
export default {
	getSocketBase: function() {
		return config.socketBase;
	},
	getMasterKeyReseedCount: function() {
		return defaultConfig.masterKeyReseedCount || 9;
	},
	getContactRecordSize: function() {
		return defaultConfig.contactRecordSize || 16384;
	},
	getIsHashConnectionIds: function() {
		return !!config.isHashConnectionIds;
	},
	getPushNotificationConfig: function() {
		return defaultConfig.pushNotifications;
	},
	getApiBase: function() {
		return config.apiBase;
	},
	getUpdateSignatures: function() {
		return config.updateSignatures;
	},
	getDHParams: function() {
		return _.findWhere( config.dhGroups, { "default": true } );
	},
	getAppId: function() {
		return config.appId;
	},
	getIdsLength: function() {
		return 32;
	},
	getHistoryUseUKey: function() {
		return config.historyUseUKey;
	},
	getUseGlobalId: function() {
		return config.useGlobalIds || false;
	},
	getSeedLength: function() {
		return 32;
	},
	getDefaultEncryptionConfig: function() {
		return defaultEConfig;
	},
	getContactRecordCount: function() {
		return defaultConfig.contactRecordCount;
	},
	getNetworkTimeout: function() {
		var usingConfig = config || defaultConfig;
		return usingConfig.networkTimeout;//2000;
	},
	getTransactionRetryDelay: function() {
		var usingConfig = config || defaultConfig;
		return usingConfig.transactionRetryDelay;//500;
	},
	getDefaultTokenMode: function() {
		return config.defaultTokenMode;
	},
	getHelperContactToken: function() {
		return config.helperContactToken;
	},
	getPrivateServerConfig: function() {
		return defaultConfig.privateServer || config.privateServer || null;
	},
	getBuildMode:  function() {
		return defaultConfig.buildMode || ( defaultConfig.privateServer ? "enterprise" : "demo" );
	},
	getWebUrlBase: function() {
		return config.webUrl || config.apiBase;
	},
	getIsTestUtils: function() {
		return defaultConfig.testUtils;
	},
	getUseVirtualKeyboard: function() {
		var usingConfig = config || defaultConfig;
		if ( global.device && ( global.device.platform !== "Android" )
		&& usingConfig.iosPostponeUseKeyboard && config.useVirtualKeyboard ) {
			return usingConfig.iosPostponeUseKeyboard < +new Date;
		}

		if ( usingConfig.useVirtualKeyboard ) {
			return true;
		}
		return usingConfig.useVirtualKeyboard === undefined ? true : config.useVirtualKeyboard;
	},
	getUseWebVirtualKeyboard: function() {
		var usingConfig = config || defaultConfig;
		return usingConfig.webVirtualKeyboard;
	},
	getDefaultAutocleanTime: function() {
		var usingConfig = config || defaultConfig;
		return usingConfig.defaultAutocleanTime === undefined ? 60 * 60 : usingConfig.defaultAutocleanTime;
	},
	getWebTimeout: function() {
		var usingConfig = config || defaultConfig;
		return usingConfig.webTimeout || 10 * 60000;
	},
	getWebDisconnectTimeout: function() {
		var usingConfig = config || defaultConfig;
		return usingConfig.webDisconnectTimeout || 10 * 60000;
	},
	getShowWorkgroups: function() {
		return "showWorkgroups" in config ? config.showWorkgroups : false;
	},
	getUserExpiration: function() {
		return !!config.userExpiration;
	},
	getDemoTs: function() {
		return defaultConfig.demoMode || 0;
	},
	toJSON: function() {
		return config;
	},
	setConfiguration( newConfig ) {
		config = newConfig || defaultConfig;
		econfig = new Config( config.defaultEncryptionConfig );
		ssgCrypto.addTrustedConfig( econfig );
		_.forEach( config.trustedConfigs, config => {
			ssgCrypto.addTrustedConfig( new Config( config ) );
		} );
		setConfigSubj.onNext();
	},
	monitorSetConfiguration() {
		return setConfigSubj;
	}
};
