import React from "react";
import _ from "lodash";

import TopLevel from "../common/top.level.view.jsx";
import MenuBody from "../common/modal.menu.jsx";
import {ModalMenuItem, MenuItem} from "../common/modal.menu.jsx";
import ImageButton from "../../components/button.image.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import Translation from "../../components/translation.jsx";
import OnlineStatus from "../../components/online.status.jsx"
import translate from "../../translations/translate.js";
import InviteView from "../invite/invite.view.jsx";
import UseInviteView from "../useinvite/index.jsx";
import {Confirm} from "../../components/dialogs.jsx"
import ShareContacts from "../sharedcontacts/add.jsx";
import Activation from "./activation.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";
import configuration from "../../../common/configuration.js";

export default class ContactMenuView extends React.Component {
	constructor() {
		super();
		this.onClearSelect = this.onClearSelect.bind( this );
		this.shareContactsDone = this.shareContactsDone.bind( this );
		this.goToContact = this.goToContact.bind( this );
		this.exit = this.exit.bind( this );
		this._service = serviceLocator();
		this.state = {
			isPrivileged: false
		};
	}

	componentWillMount() {
		this._isPrivilegedSubscription = (
			this._service.observeGlobalUserType()
				.subscribe( stateChanges => {
					this.setState( stateChanges );
				} )
		);
	}

	componentWillUnmount() {
		this._isPrivilegedSubscription.dispose();
	}

	static get propTypes() {
		return { onBack: React.PropTypes.func };
	}

	static get defaultProps() {
		return { onBack: _.noop };
	}

	onClearSelect() {
		this._service.clearHistoryAsync().subscribe();
		this.props.onBack();
	}

	shareContactsDone() {
		this.props.onBack();
	}

	goToContact( contact ) {
		this.props.goToContact( contact );
	}

	renderInvite() {
		return (
			<ModalMenuItem
				titleTextId="contacts.menu.invite"
				icon="images/icon-add-user.svg" iconClass="add-user"
				id="menu-invite"
				>
				<InviteView onDone={this.goToContact}/>
			</ModalMenuItem>
		);
	}

	renderExternalInvite() {
		return (
			<ModalMenuItem
				titleTextId="contacts.menu.invite.external"
				icon="images/icon-add-user.svg" iconClass="add-user"
				id="menu-invite"
				>
				<InviteView onDone={this.goToContact} isExternal={true}/>
			</ModalMenuItem>
		);
	}

	renderCreateWorkgroup() {
		if ( !this.state.isPrivileged || !configuration.getShowWorkgroups() ) {
			return null;
		}
		return (
			<ModalMenuItem
				titleTextId="contacts.menu.share.contacts"
				icon="images/icon-add-group.svg" iconClass="add-user"
				id="menu-share-contacts"
				>
				<ShareContacts onDone={ this.shareContactsDone }/>
			</ModalMenuItem>
		);
	}

	renderCreateActivation() {
		if ( !this.state.isAdmin ) {
			return null;
		}
		return (
			<ModalMenuItem
				titleTextId="contacts.menu.add-user"
				icon="images/icon-add-user.svg" iconClass="add-user"
				id="menu-add-user"
				>
				<Activation onDone={ this.props.onBack }/>
			</ModalMenuItem>
		);
	}

	renderExit() {
		// if ( !_.get( global, "navigator.app" ) ) {
		// 	return null;
		// }
		return (
			<MenuItem
				titleTextId="contacts.menu.exit"
				icon="images/icon-header-close.svg" iconClass="trash"
				onClick={ this.exit }
				id="menu-exit"
			/>
		);
	}

	exit() {
		if ( _.get( global, "navigator.app.exitApp" ) ) {
			global.navigator.app.exitApp();
		} else {
			setTimeout( () => {
				location.href = location.href;
				window.location.reload();
			});
		}
	}

	render() {
		let { onBack } = this.props;
		return (
			<TopLevel>
				<header>
					<Translation textId="contacts.menu.header"/>
					<ImageButton type="icon-header-close" onClick={onBack} id="close"/>
					<OnlineStatus/>
				</header>
				<main>
					<MenuBody>
						<Confirm ref="confirmClear" textId="contacts.menu.clear.confirm" onConfirm={this.onClearSelect} />
						<MenuItem
							titleTextId="contacts.menu.clear"
							icon="images/icon-menu-trash.svg" iconClass="trash"
							onClick={() => this.refs.confirmClear.show() }
							id="menu-clear"
						/>
						{ this.renderInvite() }
						{ this.renderExternalInvite() }
						<ModalMenuItem
							titleTextId="contacts.menu.useinvite"
							icon="images/icon-add-user.svg" iconClass="add-user"
							id="menu-accept-invite"
							>
							<UseInviteView/>
						</ModalMenuItem>
						{ this.renderCreateWorkgroup() }
						{ this.renderCreateActivation() }
						{ this.renderExit() }
					</MenuBody>
				</main>
				<DeviceBackButton onPress={onBack}/>
			</TopLevel>
		);
	}
}
