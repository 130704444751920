import React from "react";
import Attachment from "../../../../api/models/message/attachment.js";
import AttachmentTableView from "./attachment.table.jsx";
import FileSelectView from "../../../components/file.select.jsx";

class MessageCreateView extends React.Component {
	constructor() {
		super();
		this.onTextChange = this.onTextChange.bind( this );
		this.gotFiles = this.gotFiles.bind( this );
	}
	componentDidMount() {
		this.subscribeToMessageChanges( this.props.message );
	}
	componentWillReceiveProps( nextProps ) {
		this.subscribeToMessageChanges( nextProps.message );
	}
	subscribeToMessageChanges( message ) {
		if ( this._modelSubscription && this.props.message === message ) {
			return;
		}
		this._modelSubscription && this._modelSubscription.dispose();//unsubscribe
		this._modelSubscription = message
																.getObservable()
																.subscribe( () => this.forceUpdate() );
	}
	gotFiles( files ) {
		for ( let i = 0; i < files.length; i++ ) {
			let file = files[ i ];
			this.props.message.addAttachment( Attachment.fromFile( file ) );
		}
	}
	onTextChange( event ) {
		this.props.message.setText( event.target.value );
	}
	render() {
		return (
			<div>
				<span>Message text</span>
				<br />
				<textarea ref="text" value={ this.props.message.getText() } onChange={ this.onTextChange } />
				<br />
				Attachments: <br />
				<AttachmentTableView attachments={ this.props.message.getAttachments() } />
				<FileSelectView onFiles={ this.gotFiles } multiple={ true } />
			</div>
		);
	}
}
export default MessageCreateView;
