import React from "react";

class AttachmentView extends React.Component {
	render() {
		let attachment = this.props.attachment;
		return (
			<div>{ attachment.getName() } / { attachment.getSize() }</div>
		);
	}
}

class MessageView extends React.Component {
	renderAttachments() {
		return (
			this.props.message.getAttachments()
				.map( ( attachment, index ) =>
						<AttachmentView
							attachment={ attachment }
							key={ index + attachment.getName() } />
					)
				);
	}
	render() {
		return (
			<div>
				<span>{ this.props.message.getText() }</span>
				<div>{ this.renderAttachments() }</div>
			</div>
		);
	}
}

export default MessageView;
