import _ from "lodash";
import Rx from "rx";
import ssgCrypto from "ssg.crypto";
import Queue from "promise-queue";

import ClientServerSequential from "./client.server.sequential.js";

//It expects that size of signature and participant id size will be multiplication of encryption block size
//TODO: explicitly check this
class ClientServerUnreliable {
	constructor( apiUrlBase, connectionId, sendFrom, fromIndex, signer ) {
		if ( !signer.makeSignThen ) {
			console.error( "signer" );
			console.error( signer );
			throw new Error( "Invalid signer" );
		}
		if ( fromIndex === undefined ) {
			debugger;
		}

		this._sendFromBuffer = sendFrom ? new Buffer( sendFrom, "base64" ) : new Buffer( 0 );
		this._fromHeaderSize = this._sendFromBuffer.length;
		this._signer = signer;
		this._id2participant = Object.create( null );
		this._verifyQueue = new Queue( 1 );

		this._connectionId = connectionId;
		this._index = fromIndex;

// TODO: more checks
// sendFrom may be empty. this mean that no "to" fiend is present.
// in case if it is not empty, it's length is used as length of "to" field in
// incoming messages
		/*if ( this._sendFromBuffer.length !== 32 ) {
			throw new Error( "Invalid sendfrom identifier" );
		}*/

		this._unauthConnection = new ClientServerSequential(
			apiUrlBase,
			connectionId
		);

		this._unauthConnection._initThen.then( () => {
			this._listenerId = this._unauthConnection._listenerId;
		} );

		this._unauthConnection.onGotMessage( this._gotUnauthMessage.bind( this ) );
	}

	_onVerificationCompleted( { from, success, signature, content, index, body } ) {
			if ( !success ) {
				console.warn( `Message verification failed`, success, this );
				//TODO: make separate object for failed message
				this._onAuthMessage( from, null, index );
				//TODO: log or something
				return;
			}
			this._onAuthMessage( from, content, index, body );
	}


	_getSignatureSize( index ) {
		//Assume that signature lengths depends purely on index not on sender
		return this._signer.getSignByteLength( index );
	}

	_callUntilSuccessAsync( func ) {
		return this._unauthConnection._callUntilSuccessAsync( func );
	}

	onAuthenticatedMessage( func ) {
		this._onAuthMessage = func;
		/*for ( let id in this._id2participant ) {
			this._tryVerifyPending( id );
		}*/
	}

	onConnected( func ) {
		this._unauthConnection.onConnected( func );
	}

	onDisconnected( ) {
		this._unauthConnection.onDisconnected( func );
	}

	tryListen( cb ) {
		this._unauthConnection.tryListen( this._index, cb );
	}

	tryStopListen( cb ) {
		this._unauthConnection.tryStopListen( cb );
	}

	check( transaction, ifIndex ) {
		this._unauthConnection.check( transaction, { ifIndex } );
	}

	send( bodyBuffer, transaction, index ) {
		if ( this._isDisposed ) {
			// debugger;
			console.warn( "Send unreliable on disposing connection" );
			//Underlying connection may be in a process of disposing
			// return;
		}
		let subj = new Rx.ReplaySubject();

		transaction.waitFor( subj, "making signature" );
		let connectionUseHandle = this.incUse();
		Promise.all( [
			this._keyThen,
			this._signer.makeSignThen( {
				getBufferThen: () => Promise.resolve( bodyBuffer )
			}, index )
		] )
		.then( ( [ key, signature ] ) =>
			ssgCrypto.encryptThen(
				Buffer.concat( [ this._sendFromBuffer, signature ] ),
				key,
				false,
				this._econfig
			)
		).then( encryptedHeader => {
			this._sendLowLevel(
				transaction, index,
				Buffer.concat( [ encryptedHeader, bodyBuffer ] )
			);
			subj.onCompleted();
			this.decUse( connectionUseHandle );
		},
		error => {
			console.error( "Signature make error" );
			console.error( error );
			transaction.cancel();
			subj.onCompleted();
		} );
	}

	_sendLowLevel( transaction, index, buffer ) {
		this._unauthConnection.send( {
				to: this._connectionId,
				body: buffer.toString( "base64" )
			},
			transaction,
			index === undefined ? {} : { ifIndex: index }
		);
	}

	hasParticipant( id ) {
		if ( typeof id !== "string" ) {
			throw new Error( "Participant id expected to be a string" );
		}
		let hs = this._id2participant;
		return !!( hs && hs[ id ] && hs[ id ]._verifier );
	}

	addParticipant( id, verifier ) {
		if ( typeof id !== "string" ) {
			throw new Error( "Participant id expected to be a string" );
		}
		if ( !verifier.getSignByteLength || !verifier.verifyThen ) {
			throw new Error( "Invalid verifier" );
		}

		if ( !this._id2participant[ id ] ) {
			this._id2participant[ id ] = {};
		}
		if ( this._id2participant[ id ]._verifier ) {
			throw new Error( "verifier already set" );
		}
		this._id2participant[ id ]._verifier = verifier;
		this._tryVerifyPending( id );
	}

	removeParticipant( id ) {
		delete this._id2participant[ id ];
	}

	_gotUnauthMessage( message ) {
		let { body, index } = message;
		body = new Buffer( body, "base64" ); //TODO: check

		if ( this._sendFromBuffer && body.length < this._fromHeaderSize ) {
			console.warn( `Message body length < ${this._fromHeaderSize}` );
			return;//log or something
		}

		this._parseMessageThen( body, index ).then( parsed => {
			if ( !this._id2participant[ parsed.from ] ) {
				this._id2participant[ parsed.from ] = { queue: [] };
			}
			this._verifyMessage( parsed );
		} );
	}

	_parseMessageThen( body, index ) {
		let headerSize = this._fromHeaderSize + this._getSignatureSize( index );
		let encryptedHeaderSize = ssgCrypto.getEncryptedSize( headerSize, false, this._econfig );
		let encryptedHeader = body.slice( 0, encryptedHeaderSize );

		let content = body.slice( encryptedHeaderSize );
		let headerMB = ssgCrypto.createManagedBuffer( headerSize );
		return (
			this._keyThen
			.then( key => ssgCrypto.decryptToManagedBufferThen(
				encryptedHeader, headerMB, key, false, this._econfig
			) )
			.then( () => new Promise( resolve => {
				headerMB.useAsBuffer( header => {
					resolve( {
						signature: Buffer.concat( [ header.slice( this._fromHeaderSize ) ] ),
						from: header.slice( 0, this._fromHeaderSize ).toString( "base64" ),
						body, index, content
					} );
				} );
				headerMB.dispose();
			} ) )
		);
	}

	_verifyMessage( parsed ) {
		let isVerified = false;
		let { from, index, body, signature, content } = parsed;
		setTimeout( () => {
			if ( isVerified || this._isDisposed ) {
				return;
			}
			//console.warn( `Message verification is taking too long #${index} from ${from}`, this.name );
		}, 1000 );

		let participant = this._id2participant[ from ];
		if ( !participant ) {
			participant = this._id2participant[ from ] = {
				queue: []
			};
		}
		if ( !participant._verifier || !this._onAuthMessage ) {
			if ( !this._onAuthMessage ) {
				console.error( "!this._onAuthMessage" );
			}
			participant.queue.push( parsed );
			return;
		}

		let verifier = participant._verifier;
		if ( parsed.verified ) {
			this._verifyQueue.add( () => {
				this._onVerificationCompleted( { from, success, signature, content, index, body } );
				isVerified = true;
			} );

		} else {
			this._verifyQueue.add( () =>
				verifier.verifyThen( {
					signature,
					binarySource: { getBufferThen: () => Promise.resolve( content ) },
					index
				} )
				.then( success => {
					this._onVerificationCompleted( { from, success, signature, content, index, body } );
					isVerified = true;
				} )
			);
		}
	}

	_tryVerifyPending( id ) {
		let participant = this._id2participant[ id ];

		if ( !participant._verifier || !this._onAuthMessage || !participant.queue || !participant.queue.length ) {
			return;
		}

		let q = participant.queue;
		delete participant.queue;

		try {
			q.forEach( parsed => {
				this._verifyMessage( parsed )
			} );
		}
		catch(e) {
			console.error( "Error processing queue" );
			console.error( e );
		}
	}

	incUse( ) {
		return this._unauthConnection.incUse();
	}

	decUse( useHandle ) {
		this._unauthConnection.decUse( useHandle );
	}

	dispose( ) {
		this._isDisposed = true;
		this._verifyQueue.add( () => {
			this._unauthConnection.dispose();
		} );
	}

	isDisposed( ) {
		return this._isDisposed;
	}
}

export default ClientServerUnreliable;
