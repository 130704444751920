import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import classNames from "classnames";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

import {getWebAvaColorByName, getContactInitials, getStatusColor, sortContacts}
	from "../../../../common/utils.js";
import Menu from "../common/menu.jsx";
import Translation from "../../../components/translation.jsx";
import Timestamp from "../../../components/timestamp.jsx";
import history from "../../../components/history.js";
import RemoteInterwindowExchangeServer from "../../../components/remote.interwindow.server.js";
import RemoteInterwindowExchangeClient from "../../../components/remote.interwindow.client.js";

function getContactHref( contact ) {
	//let openUrl = `#opener/${history.getCurrentPath().split("/")[0]}/${contact.id}`;

	return "javascript:;";
}

class CommonContact extends React.Component {
	constructor() {
		super();
		this.state = {
			isDragging: false
		};
		this.onClick = this.onClick.bind( this );
		this.onDrop = this.onDrop.bind( this );
		this.onDragEnter = this.onDragEnter.bind( this );
		this.onDragLeave = this.onDragLeave.bind( this );
		this.onDragOver = this.onDragOver.bind( this );
	}

	onDrop( event ) {
		let { contact } = this.props;
		event.stopPropagation();
		event.preventDefault();
		if ( !contact ) {
			return;
		}
		let {files} = event.dataTransfer;
		let fileArray = [];
		for ( let i = 0; i < files.length; i++ ) {
			fileArray.push( files[ i ] );
		}
		let isAlerted = false;
		let service = remoteServiceLocator();
		Rx.Observable.fromArray( fileArray )
			.concatMap( file => service.sendFileAsync( contact, file ).catch( e => {
				isAlerted || alert( e.message );
				isAlerted = true;
				return Rx.Observable.empty();
			} ) )
			.toArray()
			.subscribe();
		this.setState( { isDragging: false } );
	}

	onDragEnter( event ) {
		if ( this.props.contact.status !== "active" ) {
			console.log('not active!');
			this.setState( { isDragging: false } );
			event.preventDefault();
			event.stopPropagation();
			return;
		}
		this.setState( { isDragging: true } );
		event.preventDefault();
		event.stopPropagation();
	}

	onDragLeave( event ) {
		this.setState( { isDragging: false } );
		event.preventDefault();
		event.stopPropagation();
	}

	onDragOver( event ) {
		this.setState( { isDragging: true } );
		event.preventDefault();
		event.stopPropagation();
	}

	getRootClassName() {
		return classNames( "dropdown", {
			active: this.props.isActive,
			dragging: this.state.isDragging
		} );
	}

	getAvaColor() {
		let {contact} = this.props;
		return contact.isExternal ? "#888" : getWebAvaColorByName(contact.name);
	}

	openInNewWindow( contact ) {
		let openUrl = `#opener/${history.getCurrentPath().split("/")[0]}/${contact.id}`;
		window.open( `${openUrl}` );
	}

	renderMenu( menuItems, contact ) {
		if ( !menuItems || !menuItems.length ) {
			return null;
		}
		let boundMenuItems = _.map(
			menuItems,
			( { textId, handler } ) =>
				( { textId, handler: handler.bind( this, contact ) } )
		);
		boundMenuItems.push(
			{
				textId: "web.contacts.menu.newtab",
				handler: this.openInNewWindow.bind( this, contact )
			}
		);
		return (
			<Menu
				id={`contact_dropdown_${contact.id}`}
				className="btn-user"
				icon="web/img/ico-27.svg"
				items={boundMenuItems}
			/>
		);
	}

	onClick( event ) {
		let { contact } = this.props;
		remoteServiceLocator().closeNotificationsForContact( contact.id );
		if ( event.ctrlKey || event.metaKey ) {
			this.openInNewWindow( contact );
			event.preventDefault();
			return;
		}
		event.preventDefault();
		this.props.onClick();
	}

	renderInfo() {
		let {contact} = this.props;
		if ( contact.status && ( contact.status !== "active" ) ) {
			return (
				<Translation textId={`contact.status.${contact.status}`} />
			);
		}
		if ( contact.lastMessageTS ) {
			return (
				<Timestamp value={contact.lastMessageTS + this.props.timeDiff} />
			);
		}
		return null;
	}

	renderMetaInfo() {
		let {contact, displayMeta} = this.props;
		if ( !displayMeta ) {
			return null;
		}
		if ( contact.multidescriptionName ) {
			return <span>({contact.multidescriptionName})</span>;
		}

		if ( contact.type === "group" ) {
			return <Translation textId="web.contacts.meta.group"/>;
		}

		return null;
	}
}

class Contact extends CommonContact {
	render() {
		let {contact, isActive} = this.props;
		let initials = getContactInitials( contact.name );
		let isShared = contact.multidescriptionId !== -1;

		return (
			<li
				className={ this.getRootClassName() }
				key={contact.id}
				onDrop={this.onDrop}
				onDragEnter={this.onDragEnter}
				onDragLeave={this.onDragLeave}
				onDragOver={this.onDragOver}
			>
				<a
					href={getContactHref( contact )}
					onClick={ this.onClick }>
					<div
						className="btn-circle"
						style={{backgroundColor: this.getAvaColor()}}>
						{initials}
						{
							contact.unreadCount
							? <div className="amount">{contact.unreadCount}</div>
							: null
						}
					</div>
					<span className="contact-info">
						{ contact.name }
						<div className="date">
							{ this.renderInfo() }
							&nbsp;
							{ this.renderMetaInfo() }
						</div>
					</span>
					<div className="clearfix"></div>
				</a>
				{ isShared ? <img className="sharedcontacts-icon" src="web/img/multiacc.svg" alt="" /> : null }
				{ this.renderMenu( this.props.menuItems, contact ) }
			</li>
		);
	}

	static get propTypes() {
		return {
			contact: React.PropTypes.object.isRequired,
			isActive: React.PropTypes.bool.isRequired,
			onClick: React.PropTypes.func.isRequired,
			menuItems: React.PropTypes.array.isRequired
		};
	}
}

class Group extends CommonContact {
	renderInfo() {
		let { contact } = this.props;
		if ( contact.lastMessageTS ) {
			return (
				<Timestamp value={contact.lastMessageTS + this.props.timeDiff} />
			);
		}
		return null;
	}

	render() {
		let {contact, isActive} = this.props;
		let initials = getContactInitials( contact.name );
		return (
			<li
				className={ this.getRootClassName() }
				key={contact.id}
				onDrop={this.onDrop}
				onDragEnter={this.onDragEnter}
				onDragLeave={this.onDragLeave}
				onDragOver={this.onDragOver}
			>
				<a href={getContactHref( contact )} onClick={this.onClick} >
					<div
						className="btn-circle"
						style={{backgroundColor: this.getAvaColor()}}>
						{initials}
						{
							contact.unreadCount
							? <div className="amount">{contact.unreadCount}</div>
							: null
						}
					</div>
					<span className="contact-info">
						{ contact.name }
						<div className="date">
							{ this.renderInfo() }
							&nbsp;
							{ this.renderMetaInfo() }
						</div>
					</span>
					<div className="clearfix"></div>
				</a>
				{ this.renderMenu( this.props.menuItems, contact ) }
			</li>
		);
	}

	static get propTypes() {
		return {
			contact: React.PropTypes.object.isRequired,
			isActive: React.PropTypes.bool.isRequired,
			onClick: React.PropTypes.func.isRequired,
			menuItems: React.PropTypes.array.isRequired
		};
	}
}

class Multidescription extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	renderMenu( menuItems, contact ) {
		if ( !menuItems || !menuItems.length ) {
			return null;
		}
		let boundMenuItems = _.map(
			menuItems,
			( { textId, handler } ) =>
				( { textId, handler: handler.bind( this, contact ) } )
		);
		return (
			<Menu
				id={`contact_dropdown_${contact.id}`}
				className="btn-user"
				icon="web/img/ico-27.svg"
				items={boundMenuItems}
			/>
		);
	}

	renderContact( contact ) {
		return <Contact
			contact={contact}
			isActive={this.props.selectedContactId === contact.id}
			onClick={() => this.props.onSelectContact( contact )}
			menuItems={this.props.contactMenuItems}
			key={contact.id}
			ref={contact.id}
			timeDiff={this.props.timeDiff}
		/>;
	}

	renderList() {
		return (
			_.map(
				sortContacts( this.props.contact.sharedList ),
				contact => this.renderContact( contact )
			)
		);
	}

	renderHeader( style, ref ) {
		let {contact} = this.props;
		let initials = getContactInitials( contact.name );
		return (
			<li className="dropdown" style={style} ref={ref}>
				<a href="javascript:;">
					<div className="btn-2circles">{initials}</div>
					<span className="contact-info">{contact.name}</span>
					<div className="clearfix"></div>
				</a>
				{ this.renderMenu( this.props.multidescriptionMenuItems, contact ) }
			</li>
		);
	}

	onScrolled() {
		let items = this.props.contact.sharedList;
		if ( items.length === 0 ) {
			return;
		}
		let node = ReactDOM.findDOMNode( this.refs.header );
		if ( !node ) {
			return console.warn( "Header not found" );
		}
		let mainNode = node.parentNode;
		while ( mainNode.nodeName !== "DIV" ) {
			mainNode = mainNode.parentNode;
		}
		if ( !mainNode ) {
			return console.warn( "Main node not found" );
		}
		let bottomNode = ReactDOM.findDOMNode( this.refs[ _.last( items ).id ] );
		let mainRect = mainNode.getBoundingClientRect();
		let nodeRect = node.getBoundingClientRect();
		let bottomRect = bottomNode.getBoundingClientRect();
		let needStickedHeader = ( mainRect.top > nodeRect.top ) && ( bottomRect.top >= mainRect.top );
		let isChanged = (
			( this.state.isRenderStickedHeader !== needStickedHeader )
			|| ( this.state.stickedTop !== mainRect.top )
			|| ( this.state.stickedLeft !== mainRect.left )
			|| ( this.state.stickedWidth !== mainRect.width )
		);
		if ( !isChanged ) {
			return;
		}
		this.setState( {
			isRenderStickedHeader: needStickedHeader,
			stickedTop: mainRect.top,
			stickedLeft: mainRect.left,
			stickedWidth: nodeRect.width
		} );
	}

	renderStickedHeader() {
		if ( !this.state.isRenderStickedHeader ) {
			return null;
		}
		return this.renderHeader( {
			position: "fixed",
			background: "#fff",
			width: "100%",
			zIndex: "10",
			left: this.state.stickedLeft + "px",
			top: this.state.stickedTop + "px",
			width: this.state.stickedWidth + "px",
			maxWidth: "312px"
		} );
	}

	render() {
		let {contact, onClick} = this.props;
		let initials = getContactInitials( contact.name );
		return (
			<li
				className={ "dropdown" }
				key={contact.id}
				style={{padding: 0}}
			>
				<ul>
					{ this.renderStickedHeader() }
					{ this.renderHeader( null, "header" ) }
					{ this.renderList() }
				</ul>
			</li>
		);
	}
}

class ContactListListBar extends React.Component {
	constructor() {
		super();
		this._service = remoteServiceLocator();
		this.onScroll = this.onScroll.bind( this );
		this.state = { timeDiff: 0 };
		this.onVisibilityChange = this.onVisibilityChange.bind( this );
		document.addEventListener( "visibilitychange", this.onVisibilityChange );
	}

	componentWillMount() {
		this._timeDiffSubscription = (
			this._service.observeServerTimeDiff()
				.subscribe( timeDiff => {
					this.setState( { timeDiff } );
				} )
		);
		this.contactMenuItems = [];
		this.groupMenuItems = [];
		this.multidescriptionMenuItems = [];
		this._remoteServer = new RemoteInterwindowExchangeServer( connectData =>
			this._service.addSessionAsync( connectData )
		);

		global.window.addEventListener( "resize", this.onScroll );

		if ( this.props.onContactRename ) {
			this.contactMenuItems.push( {
				textId: "web.contact.menu.rename",
				handler: this.props.onContactRename
			} );
		}
		if ( this.props.onContactDelete ) {
			this.contactMenuItems.push( {
				textId: "web.contact.menu.delete",
				handler: this.props.onContactDelete
			} );
		}
		if ( this.props.onSendContact ) {
			this.contactMenuItems.push( {
				textId: "web.contact.menu.send.contact",
				handler: this.props.onSendContact
			} );
		}

		if ( this.props.onGroupRename ) {
			this.groupMenuItems.push( {
				textId: "web.group.menu.rename",
				handler: this.props.onGroupRename
			} );
		}
		if ( this.props.onGroupExit ) {
			this.groupMenuItems.push( {
				textId: "web.group.menu.exit",
				handler: this.props.onGroupExit
			} );
		}

		if ( this.props.onMultidescriptionExit ) {
			this.multidescriptionMenuItems.push( {
				textId: "web.multidescription.menu.delete",
				handler: this.props.onMultidescriptionExit
			} );
		}

		if ( this.props.onMultidescriptionWorkgroup ) {
			this.multidescriptionMenuItems.push( {
				textId: "web.multidescription.menu.workgroup",
				handler: this.props.onMultidescriptionWorkgroup
			} );
		}

		if ( this.props.onMultidescriptionRename ) {
			this.multidescriptionMenuItems.push( {
				textId: "web.multidescription.menu.rename",
				handler: this.props.onMultidescriptionRename
			} );
		}

		if ( this.props.onMultidescriptionRights ) {
			this.multidescriptionMenuItems.push( {
				textId: "web.multidescription.menu.rights",
				handler: this.props.onMultidescriptionRights
			} );
		}

		if ( this.props.onMultidescriptionAdd ) {
			this.multidescriptionMenuItems.push( {
				textId: "web.multidescription.menu.add",
				handler: this.props.onMultidescriptionAdd
			} );
		}
	}

	onVisibilityChange() {
		if ( !global.document.hidden ) {
			this._service.closeNotificationsForContact( this.props.selectedContactId );
		}
	}

	componentWillUnmount() {
		global.document.removeEventListener( "visibilitychange", this.onVisibilityChange );
		global.window.removeEventListener( "resize", this.onScroll );
		this._remoteServer.dispose();
		this._timeDiffSubscription.dispose();
	}

	renderLoading() {
		return (
			<div className={ "grow contact " + this.props.className }>
				<p>
					<Translation textId="web.contacts.loading"/>
				</p>
			</div>
		);
	}

	renderArrow() {
		if ( this.props.arrow === false ) {
			return null;
		}
		return (
			<div className="text-right arrow">
				<img src="web/img/arrow.svg" alt=""/>
			</div>
		);
	}

	renderEmpty() {
		return (
			<div className={ "grow contact " + this.props.className }>
				{ this.renderArrow() }
				<Translation textId={ this.props.emptyTextId } />
			</div>
		);
	}

	onScroll() {
		_.forEach( this.props.contacts, ({ type, id }) => {
			if ( type === "multidescription" ) {
				this.refs[ id ].onScrolled();
			}
		} );
	}

	renderFilled() {
		return (
			<div
				className={ "grow contact user active " + this.props.className }
				onScroll={this.onScroll} >
				<ul>
					{ _.map(
						sortContacts( this.props.contacts ),
						this.renderContact.bind( this )
					) }
				</ul>
			</div>
		);
	}

	onContactClick( contact, event ) {
		event && event.stopPropagation();
		if ( contact.status === "failed" || ( contact.status === "invited" && !contact.inviteToken ) ) {
			return;
		}
		this.props.onSelectContact( contact );
	}

	renderContact( contact ) {
		let isActive = contact.id === this.props.selectedContactId;

		switch( contact.type ) {
			case "normal":
				return <Contact
					key={contact.id}
					contact={contact}
					isActive={isActive}
					onClick={this.onContactClick.bind( this, contact )}
					menuItems={this.contactMenuItems}
					displayMeta={this.props.displayMeta}
					timeDiff={this.state.timeDiff}
				/>;
			case "group":
				return <Group
					key={contact.id}
					contact={contact}
					isActive={isActive}
					onClick={this.onContactClick.bind( this, contact )}
					menuItems={this.groupMenuItems}
					displayMeta={this.props.displayMeta}
					timeDiff={this.state.timeDiff}
				/>;
			case "multidescription":
				return <Multidescription
					key={contact.id}
					ref={contact.id}
					contact={contact}
					isActive={isActive}
					onSelectContact={this.onContactClick.bind( this )}
					multidescriptionMenuItems={this.multidescriptionMenuItems}
					contactMenuItems={this.contactMenuItems}
					selectedContactId={this.props.selectedContactId}
					timeDiff={this.state.timeDiff}
				/>;
		}
		return null;
	}

	render() {
		if ( !this.props.contacts ) {
			return this.renderLoading();
		}
		if ( this.props.contacts.length === 0 ) {
			return this.renderEmpty();
		}
		return this.renderFilled();
	}

	static get propTypes() {
		return {
			onRename: React.PropTypes.func,
			onDelete: React.PropTypes.func,
			onSendContact: React.PropTypes.func,
			onSelectContact: React.PropTypes.func,
			emptyTextId: React.PropTypes.string.isRequired
		};
	}
}

export default ContactListListBar;
