import React from "react";
import Translated from "./abstract/translated.jsx";

class TranslatedImageComponent extends Translated {
	render() {
		let {altTextId, ...props} = this.props;
		return (
			<img {...props} alt={ this.translate( this.props.altTextId ) } />
		);
	}
}

TranslatedImageComponent.propTypes = {
	altTextId: React.PropTypes.string.isRequired
};
export default TranslatedImageComponent;
