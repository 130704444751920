import Service from "../worker.client.js";

let instance = null;
let mockedInstance = null;

export default function() {
	if ( mockedInstance ) {
		return mockedInstance;
	}
	if ( !instance ) {
		instance = Object.create( Service.prototype );
		if ( global.location && ~global.location.href.indexOf( "/web/" ) ) {
			Service.call( instance, new global.Worker( "/service.js" ) );
		} else {
			if ( global.device && ( global.device.platform !== "Android" ) ) {
				Service.call( instance );
			} else {
				Service.call( instance, new global.Worker( "service.js" ) );
			}
		}
	}
	return instance;
};

export function mock( newMockedInstance ) { mockedInstance = newMockedInstance; };

export function unmock() { mockedInstance = null; };
