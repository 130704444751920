import React from "react";
import qr from "qr-image";

class QrComponent extends React.Component {
	render() {
		let {code, ...divProps} = this.props;
		if ( !code ) {
			return <div {...divProps}/>;
		}
		let svgString = qr.imageSync( code, { type: "svg" });
		return (
			<div {...divProps} dangerouslySetInnerHTML={ { __html: svgString } } />
		);
	}

	static get propTypes() {
		return {
			code: React.PropTypes.string.isRequired
		};
	}
}

export default QrComponent;
