import Rx from "rx";
import socketContainerLocator from "../transport/locators/socket.container.js";
import diagnosticsServiceLocator from "../services/locators/diagnostics.js";

import configuration from "../../common/configuration.js";

class HubBase {
	constructor( socketBase ) {
		this._socketContainer = socketContainerLocator();
		if ( socketBase ) {
			this._baseUrl = socketBase;
			this._socket = this._socketContainer.getAndUseSocket( socketBase );
			this._socket.on( "connect", this.onConnected.bind( this ) );
			this._socket.on( "disconnect", this.onDisconnected.bind( this ) );
			this._socketPromise = Promise.resolve( this._socket );
		} else {
			this._socketPromise = new Promise( resolve => {
				this._monitorConfigurationSubscription = (
					configuration.monitorSetConfiguration().take(1).subscribe( () => {
						this._baseUrl = configuration.getSocketBase();
						this._socket = this._socketContainer.getAndUseSocket( this._baseUrl );
						this._socket.on( "connect", this.onConnected.bind( this ) );
						this._socket.on( "disconnect", this.onDisconnected.bind( this ) );
						resolve( this._socket );
					} )
				);
			} );
		}
		this.onConnected = this.onConnected.bind( this );
		this.onDisconnected = this.onDisconnected.bind( this );
	}

	onConnected( ) {
		diagnosticsServiceLocator().reportServerStatus( this._baseUrl, true );
	}

	onDisconnected( ) {
		diagnosticsServiceLocator().reportServerStatus( this._baseUrl, false );
	}

	send( name, data ) {
		this._socketPromise.then( () => {
			this._socket.emit( name, data );
		} );
	}

	listen( name ) {
		return this._listenMessages( name );
	}

	_listenMessages( name ) {
		return (
			Rx.Observable.fromEventPattern( h => {
				this._socketPromise.then( () => {
					this._socket.on( name, h );
				} );
			}, h => {
				this._socketPromise.then( () => {
					this._socket.off( name, h );
				} );
			} )
		);
	}

	dispose( ) {
		this._monitorConfigurationSubscription.dispose();
		this._socket && this._socketContainer.unUseSocket( this._socket );
	}
}

export default HubBase;
