import React from "react";
import _ from "lodash";
import classNames from "classnames";

import Translation from "../../../components/translation.jsx";
import PromptContacts from "../common/prompt.contacts.jsx";
import SendContactsContent from "./send.contacts.progress.content.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class SendContactsContentView extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedIds: [],
			contacts: null,
			isInProgress: false
		};
		this.onChange = this.onChange.bind( this );
		this._service = remoteServiceLocator();
		this.onDone = this.onDone.bind( this );
	}

	componentWillMount() {
		this._contactsSubscription = (
			this._service.observeContactList()
			.subscribe( contacts => {
				this.setState( {
					contacts: _.filter(
						contacts,
						( { status, id } ) => ( status === "active" ) && ( id !== this.props.toContactId )
					)
				} );
			} )
		);
	}

	componentWillUnmount() {
		this._contactsSubscription.dispose();
	}

	onChange( selectedIds ) {
		this.setState( { selectedIds } );
	}

	onDone() {
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( { isInProgress: true } );
		this.props.onBack( this.state.selectedIds );
	}

	render() {
		if ( !this.state.contacts ) {
			return <Translation textId="web.send.contacts.loading.text" />;
		}
		if ( this.state.isInProgress ) {
			return <SendContactsContent className={ this.props.className } />;
		}
		return (
			<div className={ classNames( "grow message", this.props.className ) }>
				<div className="invite">
					<PromptContacts
						onChange={ this.onChange }
						selectedIds={ this.state.selectedIds }
						contacts={ this.state.contacts }
					/>
					<div className="text-center">
						<button
							className={ classNames( "btn btn-blue", { "disabled": this.state.isInProgress } ) }
							onClick={ this.onDone }
						>
							<Translation textId="web.send.contacts.button" />
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default SendContactsContentView;
