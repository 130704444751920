import React from "react";

import RightsTable from "./rights.table.jsx";
import Translation from "../../../components/translation.jsx";
import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class RightsView extends React.Component {
  constructor() {
    super();
    this._service = remoteServiceLocator();
    this.toggleModifyWorkgroup = this.toggleModifyWorkgroup.bind( this );
    this.toggleModifyContacts = this.toggleModifyContacts.bind( this );
    this.onDone = this.onDone.bind( this );
    this.state = {
      modifiedRights: Object.create( null )
    };
  }

  componentWillMount() {
    let {contact} = this.props;
    this._rightsSubscription = (
			this._service.observeWorkgroupRights( contact )
				.subscribe( baseRights => {
					this.setState( {baseRights} );
				} )
		);
		this._selfRightsSubscription = (
			this._service.observeSelfRights( contact )
				.subscribe( ({allowModifyWorkgroup, allowModifyContacts}) => {
					this.setState( {allowEdit: allowModifyWorkgroup, allowEditContacts: allowModifyContacts } );
				} )
		);
		this._participantsSubscription = (
			this._service.observeWorkgroupParticipants( contact )
				.subscribe( participants => {
					this.setState( { participants } );
				} )
		);
	}

  componentWillUnmount() {
    this._rightsSubscription.dispose();
		this._selfRightsSubscription.dispose();
		this._participantsSubscription.dispose();
	}

  toggleModifyWorkgroup( pid ) {
    if ( !this.state.allowEdit ) {
      return;
    }
    let {baseRights, modifiedRights} = this.state;
    let right = modifiedRights[ pid ] || baseRights[ pid ] || {allowModifyWorkgroup: true, allowModifyContacts: true};

    right.allowModifyWorkgroup = !right.allowModifyWorkgroup;
    modifiedRights[ pid ] = right;
    this.setState( {modifiedRights} );
  }

  toggleModifyContacts( pid ) {
    if ( !this.state.allowEdit ) {
      return;
    }
    if ( !this.state.allowEditContacts ) {
      return;
    }
    let {baseRights, modifiedRights} = this.state;
    let right = modifiedRights[ pid ] || baseRights[ pid ] || {allowModifyWorkgroup: true, allowModifyContacts: true};
    right.allowModifyContacts = !right.allowModifyContacts;
    modifiedRights[ pid ] = right;
    this.setState( {modifiedRights} );
  }

  onDone() {
    this._service.setWorkgroupRightsAsync( this.props.contact, this.state.modifiedRights )
      .subscribe( () => { this.props.onDone(); } );
  }

  renderRightsTable() {
    if ( !this.state.participants || !this.state.baseRights ) {
      return null;
    }
		return (
      <RightsTable
        toggleModifyWorkgroup={this.toggleModifyWorkgroup}
        toggleModifyContacts={this.toggleModifyContacts}
        participants={this.state.participants}
        baseRights={this.state.baseRights}
        modifiedRights={this.state.modifiedRights}
      />
		);
	}

  render() {
    return (
      <div className={this.props.className}>
        {this.renderRightsTable()}
        <button
					className="btn btn-green"
					onClick={this.onDone}>
					<Translation textId="web.sharedcontacts.rights.done" />
				</button>
      </div>
    );
  }

  static get propTypes() {
    return {
      onDone: React.PropTypes.func.isRequired,
      onBack: React.PropTypes.func.isRequired,
      contact: React.PropTypes.object.isRequired
    };
  }
}

export default RightsView;
