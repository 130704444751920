import _ from "lodash";
import React from "react";

import longTaskServiceLocator from "../../api/services/locators/long.task.js";

import Translation from "./translation.jsx";

function toMMSS( timespan ) {
    let sec_num = Math.floor( timespan/1000 );
    let minutes = Math.floor( sec_num / 60 );
    let seconds = sec_num - ( minutes * 60 );

    if ( minutes < 10 ) { minutes = "0" + minutes; }
    if ( seconds < 10 ) { seconds = "0" + seconds; }
    return minutes + ":" + seconds;
}

let longTaskService = null, longTaskHandle = null, playingInstances = [];

class AudioPlayer extends React.Component {
  constructor() {
    super();
    this.state = {
      isPlaying: false,
      isLoaded: false,
      position: 0
    };
    this.onRangeChange = this.onRangeChange.bind( this );
    this.onStop = this.onStop.bind( this );
    this.onPlay = this.onPlay.bind( this );
    this.refreshTime = this.refreshTime.bind( this );
    this.removeSelfFromPlayingInstances = this.removeSelfFromPlayingInstances.bind( this );
    if ( !longTaskService ) {
      longTaskService = longTaskServiceLocator();
    }
  }

  addSelfToPlayingInstances() {
    if ( !this.state.isPlaying || ~playingInstances.indexOf( this ) ) {
      return;
    }
    playingInstances.push( this );
    if ( !longTaskHandle ) {
      longTaskHandle = longTaskService.runLongTask( "Playing audio messages" );
    }
  }

  removeSelfFromPlayingInstances() {
    if ( this.state.isPlaying ) {
      return;
    }
    let index = playingInstances.indexOf( this );
    if ( !~index ) {
      return;
    }
    playingInstances.splice( index, 1 );
    if ( playingInstances.length === 0 && longTaskHandle ) {
      longTaskHandle.dispose();
      longTaskHandle = null;
    }
  }

  onRangeChange( event ) {
    let { value } = event.target;
    let { id } = this.props;
    cordova.plugins.OggOpusPlugin.seekPlay( value / 1000, id, () => {
      this.setState( { position: value } );
    }, error => {
      console.error("Error seeking", error);
    } );
  }

  onStop( event ) {
    event && event.preventDefault();
    event && event.stopPropagation();
    if ( !this.state.isPlaying ) {
      return;
    }
    setTimeout( this.removeSelfFromPlayingInstances, 1000 );
    cordova.plugins.OggOpusPlugin.pausePlay(
      this.props.id,
        () => {
          this.setState( { isPlaying: false } );
        }, ( error ) => {
          console.error( "Error pausing", error );
      }
    );
  }

  onPlay( event ) {
    event && event.preventDefault();
    event && event.stopPropagation();
    if ( this.state.isPlaying ) {
      return;
    }
    this.addSelfToPlayingInstances();
    if ( this.state.isLoaded ) {
      cordova.plugins.OggOpusPlugin.resumePlay(
        this.props.id,
        msg => {
          this.setState( { isPlaying: true }, this.refreshTime );
        }, error => {
          if ( error.indexOf && ~error.indexOf( "Not playing this audio" ) ) {
            //Reload
            this.setState(
              { position: 0, isPlaying: false, isLoaded: false },
              this.onPlay.bind( this, event )
            );
            return;
          }
          alert( "Resume error: " + ( error.message || error ) );
        }
      );
      return;
    }
    cordova.plugins.OggOpusPlugin.startPlay(
      this.props.buffer.toString( "base64" ),
      this.props.id,
      msg => {
        if (this._isDisposed) {
          this.onStop();
          return;
        }
        this.setState( { isPlaying: true, isLoaded: true }, this.refreshTime );
      }, error => {
        alert( "Play error: " + ( error.message || error ) );
      }
    );
  }

  componentWillUnmount() {
    if ( this._timeout ) {
      clearTimeout( this._timeout );
      this._timeout = 0;
    }
    this.onStop();
    this._isDisposed = true;
  }

  refreshTime() {
    if ( this._timeout ) {
      clearTimeout( this._timeout );
      this._timeout = 0;
    }

    cordova.plugins.OggOpusPlugin.getPlayState( state => {
      let stateParts = state.split( "," );
      let currentTime = +stateParts[ 0 ];
      let isPlaying = stateParts[ 1 ] === "true";
      let currentId = stateParts[ 2 ];
      let currentPosition = ( currentTime * 1000 ) | 0;

      if ( this.props.id !== currentId ) {
        this.setState( { position: 0, isPlaying: false, isLoaded: false } );
        return;
      }

      if ( !isPlaying ) {
        if ( this.state.isPlaying ) {
          this.setState( { position: 0, isPlaying: false } );
          this.props.onEnded && this.props.onEnded();
          return;
        }
        this.setState( { isPlaying: false } );
        return;
      }
      this.setState( { position: currentPosition } );
      this._timeout = setTimeout( this.refreshTime, 100 );
    }, error => {
      console.error( "Error getting play position", error );
    } );
  }

  getMilliseconds() {
    return this.props.milliseconds;
  }

  renderButton() {
    if ( !this.props.buffer ) {
      return (
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={34} height={34}>
            <path
              d="M256,475c-120.942,0-219-98.058-219-219    S135.058,37,256,37s219,98.058,219,219S376.942,475,256,475z"
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              strokeWidth="10"
            />
            <path
              d="M266,281.798c0,2.321-2.238,4.202-5,4.202h-14    c-2.761,0-5-1.881-5-4.202V88.491c0-2.321,2.239-3.491,5-3.491h14c2.762,0,5,1.17,5,3.491V281.798z"
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              strokeWidth="10"
            />
            <path
              d="M246.691,287c-1.953,0-4.691-2.819-4.691-5.581    v-13.576c0-2.762,2.738-3.843,4.692-3.843h162.616c1.953,0,2.691,1.081,2.691,3.842v13.578c0,2.761-0.738,5.58-2.691,5.58H246.691    z"
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              strokeWidth="10"
            />
          </svg>
        </div>
      );
    }

    if ( this.state.isPlaying ) {
      return (
        <div onTouchStart={ this.onStop }>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" width={34} height={34}>
            <path fill="#263238" fillOpacity=".5" d="M9.2 25c0 .5.4 1 .9 1h3.6c.5 0 .9-.4.9-1V9c0-.5-.4-.9-.9-.9h-3.6c-.4-.1-.9.3-.9.9v16zm11-17c-.5 0-1 .4-1 .9V25c0 .5.4 1 1 1h3.6c.5 0 1-.4 1-1V9c0-.5-.4-.9-1-.9 0-.1-3.6-.1-3.6-.1z" />
          </svg>
        </div>
      );
    }
    return (
      <div onTouchStart={ this.onPlay }>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" width={34} height={34}>
          <path fill="#263238" fillOpacity=".5" d="M8.5 8.7c0-1.7 1.2-2.4 2.6-1.5l14.4 8.3c1.4.8 1.4 2.2 0 3l-14.4 8.3c-1.4.8-2.6.2-2.6-1.5V8.7z" />
        </svg>
      </div>
    );
  }

  renderRange() {
    return (
      <input
        type="range"
        min={ 0 }
        max={ this.getMilliseconds() }
        value={ this.state.position }
        onChange={ this.onRangeChange }
      />
    );
  }

  renderPosition() {
    return (
      <div style={ { display: "inline-block", width: "7rem" } }>
        { toMMSS( this.state.position ) }
      </div>
    );
  }

  renderTime() {
    return (
      <div style={ { display: "inline-block", width: "7rem" } }>
        { toMMSS( ( this.props.milliseconds ) | 0 ) }
      </div>
    );
  }

  render() {
    if ( !_.get( global, "cordova.plugins.OggOpusPlugin" ) || !cordova.plugins.OggOpusPlugin.canPlay() ) {
      return <Translation textId="voice.message.unsupported"/>;
    }
    return (
      <div style={ { display: "flex", height: "50px", alignItems: "center" } }>
        { this.renderButton() }
        { this.renderPosition() }
        { this.renderRange() }
        { this.renderTime() }
      </div>
    );
  }
}

export default AudioPlayer;
