
class OggOpusPlayer {
  constructor( blobUrl ) {
    this._isEnded = false;
    this._blobUrl = blobUrl;
    this._audio = document.createElement( "audio" );
    this._audio.src = blobUrl;
    this._audio.onended = () => { this._isEnded = true; };
  }

  play( isSuppressWarning ) {
    this._isEnded = false;
    this._audio.play().catch( e => {
      this._isEnded = true;
      if ( !isSuppressWarning ) {
        alert("Play error: " + ( e.message || ( e + "" ) ) );
      }
    } );
  }

  pause() {
    this._audio.pause();
  }

  stop() {
    this._audio.stop();
  }

  isEnded() {
    return this._isEnded;
  }

  getCurrentTime() {
    return this._audio.currentTime;
  }

  isPlaying() {
    return !this._audio.paused;
  }

  setCurrentTime( time ) {
    this._isEnded = false;
    this._audio.currentTime = time;
  }

  dispose() {
  }
}

export default OggOpusPlayer;
