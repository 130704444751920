import React from "react";
import _ from "lodash";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";
import Message, {SendingMessage} from "./message.jsx";

class BaseMessagesView extends React.Component {
	constructor() {
		super();
		this.state = { messages: [] };
		this._service = remoteServiceLocator();
		this.onScroll = this.onScroll.bind( this );
		this.doDeleteAsync = this.doDeleteAsync.bind( this );
		this._isRequestingHistory = false;
	}

	onScroll( event ) {
		if ( event.target.scrollTop === 0 ) {
			this._requestMoreHistory();
		}
		if ( !this.isStickedToBottom
			&& this.calcIsStickedToBottom()
			&& this.props.contact
		) {
			this.isStickedToBottom = true;
			this._service.setReadAllAsync( this.props.contact ).subscribe();
		}
	}

	_requestMoreHistory() {
		if ( !this.props.contact || this._isRequestingHistory ) {
			return;
		}
		this._isRequestingHistory = true;
		this._service.queryHistoryAsync( this.props.contact ).subscribe(
			() => { this._isRequestingHistory = false; },
			() => { this._isRequestingHistory = false; }
		);
	}

	componentWillMount() {
		let {contact} = this.props;
		if ( !contact ) {
			return;
		}
		this._messagesSubscription = (
			this._service.observeMessages( contact )
				.subscribe( ( { messages, sending } ) => {
					this.setState( { messages, sending } );
				} )
		);
		this._keyboardSubscription = (
			this.context.focusManager.observeUseVirtualKeyboard()
				.subscribe( useVirtualKeyboard => {
					this.setState( { useVirtualKeyboard } );
				} )
		);
	}

	componentDidMount() {
		this.scrollToBottom();
		this.props.contact && this._service.setReadAllAsync( this.props.contact ).subscribe();
		if ( this.refs.root.scrollTop === 0 ) {
			this._requestMoreHistory();
		}
		this.isStickedToBottom = true;
		if ( this.props.contact ) {
			this._mute = this._service.muteContactNotification( this.props.contact );
		}
	}

	componentWillUpdate( nextProps, nextState ) {
		this.setIsStickedToBottom();
		let messagesChanged = _.keys( this.state.messages ).length !== _.keys( nextState.messages ).length;
		if ( this.isStickedToBottom && messagesChanged && this.props.contact ) {
			this._service.setReadAllAsync( this.props.contact ).subscribe();
		}
	}

	componentDidUpdate() {
		if ( this.isStickedToBottom ) {
			this.scrollToBottom();
			return;
		}
		this.setIsStickedToBottom();
	}

	setIsStickedToBottom() {
		this.isStickedToBottom = this.calcIsStickedToBottom();
	}

	calcIsStickedToBottom() {
		let {root} = this.refs;
		if ( !root ) {
			return false;
		}
		return root.clientHeight + root.scrollTop + 100 >= root.scrollHeight;
	}

	scrollToBottom() {
		let {root} = this.refs;
		if ( !root ) {
			return;
		}
		root.scrollTop = root.scrollHeight - root.clientHeight;
	}

	componentWillUnmount() {
		this._messagesSubscription && this._messagesSubscription.dispose();
		this._keyboardSubscription && this._keyboardSubscription.dispose();
		this._mute && this._mute.dispose();
	}

	renderMessage( message, index, shouldRenderSender ) {
		let { editingMessage } = this.props;
		let isEditing = (
			!!editingMessage
			&& ( ( message.index === editingMessage.index )
				|| ( ( message.replaceIndex !== undefined )
					&& ( message.replaceIndex === editingMessage.replaceIndex )
				)
			)
		);
		return (
			<Message
				key={ index }
				message={ message }
				contact={ this.props.contact }
				shouldRenderSender={ shouldRenderSender }
				onEdit={ this.props.onEdit }
				doDeleteAsync={ this.doDeleteAsync }
				isEditing={ isEditing }
				onReply={ this.props.onReply }
			/>
		);
	}

	doDeleteAsync( message ) {
		let { contact } = this.props;
		return remoteServiceLocator().sendDeleteMessageAsync( message, contact );
	}

	renderSendingMessage( message, shouldRenderSender ) {
		return (
			<SendingMessage
				key={"s" + message.id}
				shouldRenderSender={shouldRenderSender}
				message={message}
			/>
		);
	}

	renderMessages( shouldRenderSender ) {
		return (
			_.map(
				this.state.messages,
				( message, index ) =>
					this.renderMessage( message, index, shouldRenderSender )
			)
			.concat( _.map(
				this.state.sending,
				message => this.renderSendingMessage( message, shouldRenderSender )
			) )
		);
	}

	static get contextTypes() {
		return { focusManager: React.PropTypes.object.isRequired };
	}
}

export default BaseMessagesView;
