import Rx from "rx";
import Attachment from "./attachment.js";
import ObservableModel from "../abstract/observable.model.js";

class Message extends ObservableModel {
	constructor( text = "" ) {
		super();
		this._attachments = [];
		this._subscriptions = [];
		this._text = text;
	}
	setText( text ) {
		this._text = text;
		this.onChange();
	}
	getText( ) {
		return this._text;
	}
	addAttachment( attachment ) {
		this._attachments.push( attachment );
		//this._subscriptions.push( attachment.getObservable().subscribe( () => this.onChange() ) );
		this.onChange();
	}
	removeAttachment( attachment ) {
		let index = this._attachments.indexOf( attachment );
		if ( index === -1 ) {
			throw new Error( "No attachment to remove" );
		}
		this._attachments.splice( index, 1 );
		//this._subscriptions.splice( index, 1 )[ 0 ].dispose(); //unsubscribe
		this.onChange();
	}
	getAttachments( ) {
		return this._attachments;
	}
}

export default Message;
