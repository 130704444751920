import Rx from "rx";
import _ from "lodash";
import ssgCrypto, {KEY_KINDS} from "ssg.crypto";
import Queue from "promise-queue";

import EncryptedRepository from "../encrypted.repository.js";
import BaseRepository from "../base.repository.js";

import Contact from "../../models/contact.js";
import DebuggableError from "../../errors/debuggable.error.js";

let defaultVersion = 1;
let headerSize = 8;

function getFileNameByIndex( index ) {
	return `c${index}.dat`;
}

export const contactKeyMap = {
	dhPrivKey: KEY_KINDS.KEY_EXCHANGE_PRIVATE,
	signKey: KEY_KINDS.SIGNATURE_PRIVATE,
	seedKey: KEY_KINDS.INTERMEDIATE,
	creatorPublicKey: KEY_KINDS.SIGNATURE_PUBLIC,
	historyEncryptionSaltKey: KEY_KINDS.INTERMEDIATE,
	historyMacSaltKey: KEY_KINDS.INTERMEDIATE,
	historyFirstDKey: KEY_KINDS.INTERMEDIATE,
	historyLastUKey: KEY_KINDS.INTERMEDIATE,
	metaState: "UNCONTROLLED"
};

//File format:
//0: version. Everything else may be changed on next versions. 1 for now.
//4: record size.
//record count = ( file size - headerSize ) / record size
class ContactDetailsRepository {
	constructor( StorageType ) {
		if ( !StorageType ) {
			throw new Error( "StorageType required" );
		}
		this._StorageType = StorageType;
		this._queue = new Queue( 1 );
	}

	_enqueue( func, ...args ) {
		return func();
		
		return this._queue.add( () => {
			let timeout = setInterval( () => {
				console.error( "Timeout", func, ...args );
			}, 2000 );
			return func().then( result => {
				clearInterval( timeout );
				return result;
			} );
		} );
	}

	init( encryptionKey ) {
		if ( this._encryptionKey ) {
			throw new Error( "Already initialized" );
		}
		this._encryptionKey = encryptionKey;
		this._repos = Object.create( null );
	}

	startCreatingRandomFiles( defaultRecordSize, recordCount ) {
		let i = 0;
		let func = () => (
			this._StorageType.isFileExistsThen( getFileNameByIndex( i ) )
				.then( isExist => {
					if ( isExist ) {
						return;
					}

					let repo = this._getRepoByIndex( i );
					return (
						ssgCrypto.createRandomBufferThen( defaultRecordSize )
							.then( buffer => repo.lockStorageThen( storage =>
								storage.replaceThen( buffer )
							) )
					);
				} )
				.then( () => {
					setTimeout( () => {
						i++;
						if ( i < recordCount ) {
							this._queue.add( func );
						}
					}, 1000 );
				} )
		);
		this._queue.add( func );
	}

	uninitAsync( ) {
		return Rx.Observable.fromPromise( this.uninitThen() );
	}

	uninitThen( ) {
		if ( !this._encryptionKey ) {
			return Rx.Observable.just();
		}
		this._checkerSubscription && this._checkerSubscription.dispose();
		return this._enqueue( () => new Promise( ( resolve, reject ) =>
			Rx.Observable.fromArray( _.values( this._repos ) )
				.where( repo => !!repo )
				.flatMap( repo => repo.uninitAsync() )
				.toArray()
				.tap( () => {
					delete this._encryptionKey;
				} )
				.subscribeOnCompleted( resolve )
		) );
	}

	_getRepoByIndex( index ) {
		if ( !this._repos[ index ] ) {
			let fileName = getFileNameByIndex( index );
			let repo = new EncryptedRepository(
				this._StorageType,
				fileName
			);
			repo._encryptionKey = this._encryptionKey; //TODO: make new class
			repo._readyCb();

			this._repos[ index ] = repo;
			if ( this._writeHook ) {
				repo.hookWrite( buffer => this._writeHook( buffer, index ) );
			}
		}
		return this._repos[ index ];
	}

	writeRandomAsync( index, size ) {
		return Rx.Observable.fromPromise(
			ssgCrypto.createRandomBufferThen( size )
				.then( randomBuffer => this._writeEncryptedBufferThen( index, randomBuffer ) )
		);
	}

	writeRandomThen( index, size ) {
		return (
			ssgCrypto.createRandomBufferThen( size )
				.then( randomBuffer => this._writeEncryptedBufferThen( index, randomBuffer ) )
		);
	}

	setContactAtAsync( index, json, size, contactType ) {
		return Rx.Observable.fromPromise( this.setContactAtThen( index, json, size, contactType ) );
	}

	setContactAtThen( index, json, size, contactType ) {
		if ( !contactType ) {
			throw new Error( "contactType required" );
		}
		if ( typeof size !== "number" ) {
			throw new Error( "Size expected to be a number" );
		}
		return this._enqueue( () => {
			let repo = this._getRepoByIndex( index );
			return repo.writeObjectThen( json, size, this._getKeyMap( contactType ) );
		} );
	}

	_getKeyMap( contactType ) {
		return (
			contactKeyMap
		);
	}

	_moveRepo( from, to ) {
		this._repos[ to ] = this._repos[ from ];
		delete this._repos[ from ];
	}

	_writeEncryptedBufferThen( index, buffer ) {
		return this._queue.add( () => {
			let repo = this._getRepoByIndex( index );

			return (
				repo.lockStorageThen( storage => {
					return storage.replaceThen( buffer );
				} )
			);
		} );
	}

	getContactAtAsync( index, contactType ) {
		return Rx.Observable.fromPromise( this.getContactAtThen( index, contactType ) );
	}

	getContactAtThen( index, contactType ) {
		if ( !contactType ) {
			throw new Error( "contactType required" );
		}
		return this._enqueue( () => {
			let repo = this._getRepoByIndex( index );
			return repo.readObjectThen( this._getKeyMap( contactType ) );
		}, index, contactType );
	}

	deleteAsync( index ) {
		return this.writeRandomAsync( index );
	}

	dropDataAsync( index ) {
		return Rx.Observable.fromPromise( this.dropDataThen( index ) );
	}

	dropDataThen( index ) {
		return this._enqueue( () => new Promise( ( resolve, reject ) => {
			new EncryptedRepository(
				this._StorageType,
				getFileNameByIndex( index )
			)
				.dropDataAsync()
				.subscribe( resolve, reject );
		} ) );
	}

	isAnyDataAsync( ) {
		return Rx.Observable.fromPromise( this.isAnyDataThen() );
	}

	isAnyDataThen( ) {
		return this._enqueue( () =>
			this._StorageType.isFileExistsThen( getFileNameByIndex( 0 ) )
		);
	}

	moveContactAsync( from, to ) {
		return Rx.Observable.fromPromise( this.moveContactThen( from, to ) );
	}

	moveContactThen( from, to ) {
		let repo = this._getRepoByIndex( from );
		return this._enqueue( () =>
			repo.lockStorageThen( storage => {
				return (
					storage.renameThen(
						getFileNameByIndex( to )
					)
					.then( () => this._moveRepo( from, to ) )
				);
			} )
		);
	}

	moveTailContactsAsync( index, count, distance ) {
		let moveOne = i => {
			let from = index + i;
			let to = index + i + distance;
			return this.moveContactAsync( from, to );
		}
		if ( distance > 0 ) {
			return (
				Rx.Observable.range( 0, count )
					.map( i => count - i - 1 )
					.concatMap( moveOne )
					.toArray()
			);
		}
		return (
			Rx.Observable.range( 0, count )
				.concatMap( moveOne )
				.toArray()
		);
	}

	hookWrite( func ) {
		this._writeHook = func;
		if ( !this._repos ) {
			return;
		}
		for( let index in this._repos ) {
			this._repos[ index ].hookWrite( buffer => func( buffer, index ) );
		}
	}

	getRawDataAsync( index ) {
		let repo = this._getRepoByIndex( index );
		return repo.getRawDataAsync();
	}

	setRawDataAsync( buffer, index ) {
		let fileName = getFileNameByIndex(index);
		let repo = new EncryptedRepository( this._StorageType, fileName );
		return repo.setRawDataAsync( buffer );
	}
}

export default ContactDetailsRepository;
