import React from "react";
import ReactDOM from "react-dom";
import history from "../../components/history.js";
import Router from "./router.jsx";
import WebRouter from "../web/router.jsx";

class RootView extends React.Component {
	constructor() {
		super();
		this.state = {
			hashSegments: history.getCurrentPath().split( "/" ),
			version: 0
		};
	}

	componentWillMount() {
		history.getNavigationObservable()
			.subscribe( ( path ) => {
				this.setState( {
					hashSegments: path.split( "/" ),
					version: this.state.version + 1
				} );
			} );
	}

	render() {
		if ( window.useRouter === "web" ) {
			return (
				<WebRouter path={ this.state.hashSegments } key={ this.state.version }/>
			);
		}
		return (
			<Router path={ this.state.hashSegments } key={ this.state.version }/>
		);
	}
}

export default RootView;
