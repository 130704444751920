import ClientServerUnreliable from "./client.server.unreliable.js";

class ClientServerOrdered extends ClientServerUnreliable {
	constructor( apiUrlBase, connectionId, sendFrom, fromIndex, signer ) {
		fromIndex |= 0;
		super( apiUrlBase, connectionId, sendFrom, fromIndex, signer );
		this.onAuthenticatedMessage( this._gotUnorderedMessage.bind( this ) );
		this._nextIndex = fromIndex;
		this._sendIndex = fromIndex;
		this._messagesOutOfOrder = Object.create( null );
		this._isFailed = false;
	}

	onOrderedMessage( func ) {
		this._onOrderedMessage = func;
	}

	_gotUnorderedMessage( from, body, index, fullBody ) {
		if ( this._isFailed || this._messagesOutOfOrder[ index ] ) {
			return;
		}
		this._messagesOutOfOrder[ index ] = { from, body, fullBody };
		this._continueProcessOrderedMessages();
	}

	_continueProcessOrderedMessages( ) {
		while( this._messagesOutOfOrder[ this._nextIndex ] ) {
			let { from, body, fullBody } = this._messagesOutOfOrder[ this._nextIndex ];
			delete this._messagesOutOfOrder[ this._nextIndex ];
			let currentIndex = this._nextIndex;
			this._nextIndex++;
			try {
				this._onOrderedMessage( from, body, currentIndex, fullBody );
				this.setTimeout( currentIndex );
				if ( !body ) {
					this.fail();
					return;
				}
			} catch( E ) {
				console.error( "Error processing ordered message", body );
				console.error(E);
				this.fail();
				return;
			}
		}
	}

	setTimeout( index ) {
		return;
		let handle = setInterval( () => {
			if ( this._sendIndex > index ) {
				clearInterval( handle );
				return;
			}
			console.warn( "Ordered message processing timeout", index );
		}, 10000 );
	}

	fail( ) {
		this._isFailed = true;
		delete this._messagesOutOfOrder;
	}

	setProcessedMessage( index ) {
		// if ( this._sendIndex !== index ) {
		// 	throw new Error( `Invalid index` );
		// }

		if ( this._sendIndex > index ) {
			return;
		}

		this._sendIndex = index + 1;
	}

	getSendIndex( ) {
		return this._sendIndex;
	}

	tryListen( cb ) {
		this._unauthConnection.tryListen( this._nextIndex, cb );
	}
}

export default ClientServerOrdered;
