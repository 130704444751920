import React from "react";
import Rx from "rx";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import Calendar from "react-calendar/dist/entry.nostyle";

import Switch from "../common/switch.jsx";
import Prompt from "../common/prompt.jsx";
import translate from "../../../translations/translate.js";
import Translation from "../../../components/translation.jsx";
import Confirm from "../common/confirm.jsx";
import Qr from "../../../components/qr.jsx";
import remoteServiceLocator from "../../../../api/services/locators/remote.js";
import configuration from "../../../../common/configuration.js";

class UserTableView extends React.Component {
	constructor() {
		super();
		this._service = remoteServiceLocator();
		this.onEditAsync = this.onEditAsync.bind( this );
		this.onAddAsync = this.onAddAsync.bind( this );
		this.onDeleteAsync = this.onDeleteAsync.bind( this );
		this.onSendContactsConfirm = this.onSendContactsConfirm.bind( this );
		this.state = {
			users: null
		};
	}

	componentWillMount() {
		this._subscription = (
			Rx.Observable.combineLatest(
				this._service.observeUserTable(),
				this._service.getPrivateServerConfigAsync(),
				( users, config ) => ( { users, config } )
			).subscribe( stateUpdate => {
				this.setState( stateUpdate );
			} )
		);
	}

	componentWillUnmount() {
		this._subscription.dispose();
	}

	onEditAsync( userId, newData ) {
		let existingUser = _.find( this.state.users, { userId } );
		if ( !existingUser ) {
			return Rx.Observable.just( null );
		}
		return this._service.editUserAsync( { userId, ...newData } );
	}

	onAddAsync( user ) {
		return this._service.addUserAsync( user );
	}

	onDeleteAsync( userId ) {
		return this._service.deleteUserAsync( userId );
	}

	onSendContactsConfirm( user, userIds ) {
		return this._service.sendContactsToUserAsync( user, userIds );
	}

	isUserQuotaExceeded( ) {
		let {config, users} = this.state;
		return config.maxUsers <= users.length;
	}

	isPrivilegedQuotaExceeded( ) {
		let {config, users} = this.state;
		let privilegedCount = _.sumBy( users, ( { type } ) => type === "user" ? 0 : 1 );
		return config.maxPrivileged <= privilegedCount;
	}

	isAdminLow( ) {
		let {config, users} = this.state;
		let adminCount = _.sumBy( users, ( { type } ) => type === "admin" ? 1 : 0 );
		return config.minAdmins > adminCount;
	}

	isAdminLowWarn( ) {
		let {config, users} = this.state;
		let adminCount = _.sumBy( users, ( { type } ) => type === "admin" ? 1 : 0 );
		return config.recommendedAdmins > adminCount;
	}

	render() {
		let {users} = this.state;
		if ( !users ) {
			return <span>Loading...</span>;
		}
		return (
			<UserTableEditable
				users={users}
				onEditAsync={this.onEditAsync}
				onAddAsync={this.onAddAsync}
				onDeleteAsync={this.onDeleteAsync}
				onSendContactsConfirm={this.onSendContactsConfirm}
				isUserQuotaExceeded={this.isUserQuotaExceeded()}
				isPrivilegedQuotaExceeded={this.isPrivilegedQuotaExceeded()}
				isAdminLow={this.isAdminLow()}
				isAdminLowWarn={this.isAdminLowWarn()}
			/>
		);
	}
}

class UserTableEditable extends React.Component {
	constructor() {
		super();
		this.state = {
			editingUserId: null,
			isInProgress: false
		};
		this.onEditDone = this.onEditDone.bind( this );
		this.onEdit = this.onEdit.bind( this );
		this.onDelete = this.onDelete.bind( this );
		this.onAdd = this.onAdd.bind( this );
		this.onAddClick = this.onAddClick.bind( this );
		this.onPopupClose = this.onPopupClose.bind( this );
	}

	onEditDone( newData ) {
		if ( this.state.isInProgress ) {
			return;
		}
		if ( !newData ) {
			this.setState( { editingUserId: null } );
			return;
		}
		this.setState( { isInProgress: true } );
		this.props.onEditAsync( this.state.editingUserId, newData )
			.subscribe( () => {
				this.setState( { editingUserId: null, isInProgress: false } );
			} );
	}

	onEdit( user ) {
		if ( this.state.isInProgress || this.state.editingUserId ) {
			return;
		}
		this.setState( { editingUserId: user.userId } );
	}

	onDelete( user ) {
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( { isInProgress: true } );
		this.props.onDeleteAsync( user.userId )
			.subscribe( () => {
				this.setState( { isInProgress: false } );
			} );
	}

	onAdd( user ) {
		if ( this.state.isInProgress ) {
			return;
		}

		if ( !user ) {
			this.setState( { popup: null } );
			return;
		}
		this.setState( { isInProgress: true } );
		this.props.onAddAsync( user )
			.subscribe( addedUser => {
				this.setState( {
					popup: () => <ActivationPopup
						token={ addedUser.activationSenderToken }
						nickname={ addedUser.nickname }
						onClose={ this.onPopupClose }
					/>,
					isInProgress: false
				} );
			} );
	}

	onSendContactsConfirm( user, contacts ) {
		return this.props.onSendContactsConfirm( user, contacts );
	}

	onPopupClose() {
		this.setState( { popup: null } );
	}

	onAddClick() {
		this.setState( {
			popup: () => <AddUserDialog onAdd={this.onAdd} />
		} );
	}

	componentWillReceiveProps( nextProps ) {
		let { editingUserId } = this.state;
		if ( editingUserId && !_.find( nextProps.users, { userId: editingUserId } ) ) {
			this.setState( { editingUserId: null } );
		}
	}

	renderPopup() {
		if ( !this.state.popup ) {
			return null;
		}
		return this.state.popup();
	}

	renderRows() {
		let {editingUserId, isInProgress} = this.state;
		let {isPrivilegedQuotaExceeded, isAdminLowWarn, users} = this.props;
		return _.map( users, user => user.userId === editingUserId
			? <UserEditRow
				key={editingUserId}
				existingUser={user}
				isPrivilegedQuotaExceeded={isPrivilegedQuotaExceeded}
				onEditDone={this.onEditDone}
				enabled={!isInProgress}
				isAdminLowWarn={isAdminLowWarn}
			/>
			: <UserViewRow
				key={user.userId || "newUser"}
				user={user}
				isEditable={!editingUserId}
				onEdit={this.onEdit.bind( this, user )}
				onDelete={this.onDelete.bind( this, user )}
				onSendContactsConfirm={ this.onSendContactsConfirm.bind( this, user ) }
				isAdminLowWarn={isAdminLowWarn}
			/>
		);
	}

	renderAddButton() {
		if ( this.props.isUserQuotaExceeded ) {
			return <Translation textId="web.users.add.quota.exceeded" />;
		}
		return (
			<button onClick={this.onAddClick}>
				<Translation textId="web.users.add.button" />
			</button>
		);
	}

	renderIsAdminLowWarning() {
		if ( this.props.isAdminLow ) {
			return <Translation textId="web.users.adminlow.error" style={{color: "red"}}/>;
		}
		if ( this.props.isAdminLowWarn ) {
			return <Translation textId="web.users.adminlow.warn" style={{color: "red"}}/>;
		}
		return null;
	}

	render() {
		return (
			<div>
				{ this.renderAddButton() }
				<br />
				{ this.renderIsAdminLowWarning() }
				<table>
					<thead>
						<tr>
							<th><Translation textId="web.users.thead.nickname"/></th>
							<th><Translation textId="web.users.thead.type"/></th>
						</tr>
					</thead>
					<tbody>
						{ this.renderRows() }
					</tbody>
				</table>
				{this.renderPopup()}
			</div>
		);
	}
}

class UserViewRow extends React.Component {
	constructor() {
		super();
		this.state = { popup: null };
		this.onDeleteClick = this.onDeleteClick.bind( this );
		this.onDeleteConfirm = this.onDeleteConfirm.bind( this );
		this.onQrClick = this.onQrClick.bind( this );
		this.onPopupClose = this.onPopupClose.bind( this );
		this.onSendContactsClick = this.onSendContactsClick.bind( this );
		this.onSendContactsConfirm = this.onSendContactsConfirm.bind( this );
	}

	onDeleteConfirm( isConfirmed ) {
		if ( !isConfirmed ) {
			this.setState( { popup: null } );
			return;
		}
		this.props.onDelete();
	}

	onDeleteClick() {
		this.setState( { popup: () =>
			<Confirm
				titleTextId="web.user.confirm.title"
				yesButtonTextId="web.user.confirm.button.yes"
				onDone={ this.onDeleteConfirm }
			/>
		} );
	}

	onPopupClose() {
		this.setState( { popup: null } );
	}

	onQrClick() {
		this.setState( { popup: () => this.props.user.activationSenderToken
			? <ActivationPopup
				token={ this.props.user.activationSenderToken }
				nickname={ this.props.user.nickname }
				onClose={ this.onPopupClose }
			/>
			: null
		} );
	}

	onSendContactsConfirm( userIds ) {
		this.props.onSendContactsConfirm( userIds )
			.subscribe( () => {
				this.setState( { popup: null } );
			} );
	}

	onSendContactsClick() {
		this.setState( { popup: () =>
			<SendContactsPopup
				user={ this.props.user }
				onClose={ this.onPopupClose }
				onConfirm={ this.onSendContactsConfirm }
			/>
		} );
	}

	renderEdit() {
		if ( !this.props.isEditable ) {
			return null;
		}
		return (
			<button onClick={this.props.onEdit}>
				<Translation textId="web.users.edit.button" />
			</button>
		);
	}

	renderDelete() {
		if ( !this.props.isEditable ) {
			return null;
		}
		if ( this.props.isAdminLowWarn && ( this.props.user.type === "admin" ) ) {
			return null;
		}
		return (
			<button onClick={this.onDeleteClick}>
				<Translation textId="web.users.delete.button" />
			</button>
		);
	}

	renderQrButton() {
		if ( !this.props.user.activationSenderToken ) {
			return null;
		}
		return (
			<button onClick={this.onQrClick}>
				<Translation textId="web.users.code.button" />
			</button>
		);
	}

	renderSendContacts() {
		let { isActivated, activationReceiverToken } = this.props.user;
		if ( !isActivated || !activationReceiverToken ) {
			return null;
		}

		return (
			<button onClick={ this.onSendContactsClick }>
				<Translation textId="web.users.send.button" />
			</button>
		);
	}

	renderPopup() {
		if ( !this.state.popup ) {
			return null;
		}
		return this.state.popup();
	}

	renderExpiration() {
		let {user} = this.props;
		if (!configuration.getUserExpiration()) {
			return null;
		}

		return (
			<ExpireDateField value={user.expireDt}/>
		);
	}

	render() {
		let {user} = this.props;
		return (
			<tr>
				<td>{user.nickname}</td>
				<td><Translation textId={`web.users.type.${user.type}`}/></td>
				{this.renderExpiration()}
				<td>{this.renderEdit()}</td>
				<td>{this.renderDelete()}</td>
				<td>{this.renderQrButton()}</td>
				<td>{this.renderSendContacts()}</td>
				<td>{this.renderPopup()}</td>
			</tr>
		);
	}
}

class ExpireDateField extends React.Component {
	constructor() {
		super();

		this.onNever = this.onNever.bind(this);
		this.onChange = this.onChange.bind(this);
	}


	onNever() {
		if (!this.props.isEditable) {
			return;
		}
		this.props.onSave(null);
	}

	onChange(date) {
		this.props.onSave(+date);
	}

	renderViewing() {
		const {value} = this.props;
		const style = {padding: "0 10px"};
		if (!value) {
			return <td onClick={this.onEdit} style={style}>Never</td>;
		}
		return <td onClick={this.onEdit} style={style}>{new Date(value).toDateString()}</td>;
	}

	renderEditing() {
		let {value} = this.props;
		let valueStr;
		if (!value) {
			value = null;
			valueStr = "Never";
		} else {
			value = new Date(value);
			valueStr = value.toDateString();
		}
		return (
			<td>
				<div style={{overflow: "visible", height: "20px", width: "190px", position: "relative"}}>
					<span style={{margin: "10px"}}>{valueStr}</span>
					<a href="javascript:;" onClick={this.onNever} style={{margin: "10px"}}>Never</a>
					<br />
					<div style={{width: "320px", height: "320px", position: "absolute", top: "20px", left: 0}}>
						<Calendar
							onChange={this.onChange}
							value={value}
						/>
					</div>
				</div>
			</td>
		);
	}

	render() {
		if (!this.props.isEditable) {
			return this.renderViewing();
		}
		return this.renderEditing();
	}
}

class UserEditRow extends React.Component {
	constructor() {
		super();
		this.state = {};
		this.onNicknameChange = this.onNicknameChange.bind( this );
		this.onTypeChange = this.onTypeChange.bind( this );
		this.onApplyClick = this.onApplyClick.bind( this );
		this.onCancelClick = this.onCancelClick.bind( this );
		this.saveExpiration = this.saveExpiration.bind( this );
	}

	componentWillMount() {
		let {nickname, type, expireDt} = this.props.existingUser;
		this.setState( { nickname, type, expireDt } );
	}

	onNicknameChange() {
		if ( !this.props.enabled ) {
			return;
		}
		this.setState( { nickname: this.refs.nickname.value } );
	}

	saveExpiration(expireDt) {
		this.setState({expireDt});
	}

	onTypeChange() {
		if ( !this.props.enabled ) {
			return;
		}
		this.setState( { type: this.refs.type.value } );
	}

	onApplyClick() {
		if ( !this.props.enabled ) {
			return;
		}
		let {nickname, type, expireDt} = this.state;
		if ( !nickname || !type ) {
			return;
		}
		this.props.onEditDone( { nickname, type, expireDt: expireDt || 0 } );
	}

	onCancelClick() {
		if ( !this.props.enabled ) {
			return;
		}
		this.props.onEditDone( null );
	}

	renderNickname() {
		return <input
			type="text"
			value={this.state.nickname}
			onChange={this.onNicknameChange}
			ref="nickname"
			disabled={!this.props.enabled}
		/>;
	}

	renderType() {
		let {type} = this.state;
		let {existingUser, isPrivilegedQuotaExceeded, isAdminLowWarn} = this.props;
		if ( !existingUser.isActivated ) {
			return <Translation textId={`web.users.type.${type}`} />;
		}
		if ( ( existingUser.type === "user" ) && isPrivilegedQuotaExceeded ) {
			return <Translation textId={`web.users.type.${type}`} />;
		}
		if ( ( existingUser.type === "admin" ) && isAdminLowWarn ) {
			return <Translation textId={`web.users.type.${type}`} />;
		}
		return (
			<select
				onChange={this.onTypeChange}
				value={type}
				ref="type"
				disabled={ !this.props.enabled }
			>
				<option value="user">{ translate( "web.users.type.user" ) }</option>
				<option value="powerUser">{ translate( "web.users.type.powerUser" ) }</option>
				<option value="admin">{ translate( "web.users.type.admin" ) }</option>
			</select>
		);
	}

	renderPrivilegedQuotaWarning() {
		let {existingUser, isPrivilegedQuotaExceeded} = this.props;
		if ( ( existingUser.type !== "user" ) || !isPrivilegedQuotaExceeded ) {
			return null;
		}
		return <Translation textId="web.users.edit.privileged.quota.exceeded" key="privileged"/>;
	}

	renderUnactivatedWarning() {
		let {existingUser} = this.props;
		if ( existingUser.isActivated ) {
			return null;
		}
		return <Translation textId="web.users.edit.unactivated" key="unactivated"/>;
	}

	renderIsAdminLowWarning() {
		let {isAdminLowWarn, existingUser} = this.props;
		if ( !isAdminLowWarn || ( existingUser.type !== "admin" ) ) {
			return null;
		}
		return <Translation textId="web.users.edit.adminlow" key="adminlow"/>;
	}

	renderWarnings() {
		let warnings = _.filter( [
			this.renderPrivilegedQuotaWarning(),
			this.renderUnactivatedWarning(),
			this.renderIsAdminLowWarning()
		] );
		if ( !warnings.length ) {
			return null;
		}
		let style = {
			position: "absolute",
			width: "100%",
			top: 0,
			left: 0,
			background: "white",
			border: "thin gray dotted"
		};
		return (
			<div style={style}>
				{warnings}
			</div>
		);
	}

	renderApply() {
		return (
			<button onClick={this.onApplyClick}>
				<Translation textId="web.users.apply.button" />
			</button>
		);
	}

	renderCancel() {
		return (
			<button onClick={this.onCancelClick}>
				<Translation textId="web.users.cancel.button" />
			</button>
		);
	}

	renderExpiration() {
		let {existingUser} = this.props;
		if (!configuration.getUserExpiration()) {
			return null;
		}

		return (
			<ExpireDateField
				value={this.state.expireDt}
				onSave={this.saveExpiration}
				isEditable={true}
			/>
		);
	}

	render() {
		return (
			<tr>
				<td>{ this.renderNickname() }</td>
				<td>{ this.renderType() }</td>
				{ this.renderExpiration() }
				<td>{ this.renderApply() }</td>
				<td>{ this.renderCancel() }</td>
				<td>{ this.renderWarnings() }</td>
			</tr>
		);
	}
}

class UsersContentView extends React.Component {
	render() {
		return (
			<div className={this.props.className}>
				<div className="settings-box-info">
					<div className="invite">
						<UserTableView />
					</div>
				</div>
			</div>
		);
	}
}

class AddUserDialog extends React.Component {
	constructor() {
		super();
		this.state = {
			isInProgress: false
		};
		this.onDone = this.onDone.bind( this );
		this.onClose = this.onClose.bind( this );
	}

	onDone() {
		if ( this.state.isInProgress || !this.refs.nickname.value ) {
			return;
		}
		this.setState( { isInProgress: true } );
		this.props.onAdd( {
			nickname: this.refs.nickname.value,
			type: this.refs.type.value
		} );
	}

	onClose() {
		this.props.onAdd( null );
	}

	render() {
		let isBtnDisabled = this.state.isInProgress;
		return (
			<div className="static-modal">
				<Modal.Dialog>
					<Modal.Header>
						<Modal.Title>
							<Translation textId="web.users.add.title" />
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Translation textId="web.users.add.text" />
						<table style={{width: "100%"}}>
							<tbody>
								<tr>
									<td><Translation textId="web.users.add.nickname.title" /></td>
									<td><input type="text" ref="nickname" style={{width: "100%"}}/></td>
								</tr>
								<tr>
									<td><Translation textId="web.users.add.type.title" /></td>
									<td><select ref="type">
										<option value="user">{ translate( "web.users.type.user" ) }</option>
										<option value="powerUser">{ translate( "web.users.type.powerUser" ) }</option>
									</select></td>
								</tr>
							</tbody>
						</table>
					</Modal.Body>

					<Modal.Footer>
						<Button onClick={ this.onClose }>
							<Translation textId="web.users.add.close.button" />
						</Button>
						<Button bsStyle="primary" onClick={ this.onDone } disabled={ isBtnDisabled }>
							<Translation textId="web.users.add.save.button" />
						</Button>
					</Modal.Footer>

				</Modal.Dialog>
			</div>
		);
	}
}

class ActivationPopup extends React.Component {
	render() {
		let {nickname, token} = this.props;

		return (
			<div className="static-modal">
				<Modal.Dialog>
					<Modal.Header>
						<Modal.Title>
							<Translation textId="web.user.activation.title" params={[nickname]} />
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Qr code={token} className="qr-code"/>
						<br />
						<Translation textId="web.user.activation.text" />
						<br />
						<span className="code">{token}</span>
					</Modal.Body>

					<Modal.Footer>
						<Button onClick={ this.props.onClose }>
							<Translation textId="web.users.activation.close.button" />
						</Button>
					</Modal.Footer>

				</Modal.Dialog>
			</div>
		);
	}
}

class SendContactsPopup extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedUsers: Object.create( null ),
			isInProgress: false
		};
		this.onConfirm = this.onConfirm.bind( this );
		this.onUserSelectionChanged = this.onUserSelectionChanged.bind( this );
	}

	onConfirm() {
		this.setState( { isInProgress: true } );
		let selectedUserIds = _.keys( this.state.selectedUsers );
		this.props.onConfirm( selectedUserIds );
	}

	onUserSelectionChanged( userId ) {
		if ( this.state.isInProgress ) {
			return;
		}
		let { selectedUsers } = this.state;
		if ( selectedUsers[ userId ] ) {
			delete selectedUsers[ userId ];
		} else {
			selectedUsers[ userId ] = 1;
		}
		this.setState( { selectedUsers } );
	}

	renderFooter() {
		let { selectedUsers, isInProgress } = this.state;
		let isConfirmDisabled = _.isEmpty( selectedUsers );
		if ( isInProgress ) {
			return (
				<Modal.Footer>
					<Translation textId="web.user.send.progress" />
				</Modal.Footer>
			);
		}
		return (
			<Modal.Footer>
				<Button onClick={ this.onConfirm } disabled={ isConfirmDisabled }>
					<Translation textId="web.users.send.confirm.button" />
				</Button>
				<Button onClick={ this.props.onClose }>
					<Translation textId="web.users.send.close.button" />
				</Button>
			</Modal.Footer>
		);
	}

	render() {
		let { user } = this.props;
		let { selectedUsers } = this.state;
		let isConfirmDisabled = _.isEmpty( selectedUsers );
		return (
			<div className="static-modal">
				<Modal.Dialog>
					<Modal.Header>
						<Modal.Title>
							<Translation
								textId="web.user.send.title"
								params={ [ user.nickname ] }
							/>
						</Modal.Title>
					</Modal.Header>

					<Modal.Body style={ { height: "320px", overflow: "auto" } }>
						<br />
						<Translation textId="web.user.send.text" />
						<br />
						<UserListPrompt
							except={ user }
							selectedUsers={ selectedUsers }
							onChangeSelection={ this.onUserSelectionChanged }
						/>
					</Modal.Body>
					{ this.renderFooter() }
				</Modal.Dialog>
			</div>
		);
	}
}

class UserListPrompt extends React.Component {
	constructor() {
		super();
		this.state = {
			users: null
		};
	}

	componentWillMount() {
		this._userTableSubscription = (
			remoteServiceLocator().observeUserTable()
				.subscribe( userTable => {
					this.setState( {
						users: _.filter(
							userTable,
							( { userId, isActivated, activationReceiverToken } ) =>
								userId !== this.props.except.userId && isActivated && activationReceiverToken
						)
					} );
				} )
		);
	}

	componentWillUnmount() {
		this._userTableSubscription.dispose();
	}

	renderRows() {
		return _.map( this.state.users, ( { nickname, userId } ) =>
			<tr key={ userId }>
				<td>
					<input
						type="checkbox"
						id={ userId }
						checked={ !!this.props.selectedUsers[ userId ] }
						onChange={ this.props.onChangeSelection.bind( this, userId ) }
					/>
				</td>
				<td><label htmlFor={ userId }>{ nickname }</label></td>
			</tr>
		);
	}

	render() {
		if ( !this.state.users ) {
			return null;
		}
		return (
			<table className="unselectable">
				<tbody>
					{ this.renderRows() }
				</tbody>
			</table>
		);
	}
}

export default UsersContentView;
