import Rx from "rx";
import _ from "lodash";
import LangEn from "./langs/en.jsx";
import LangRu from "./langs/ru.jsx";
import LangUk from "./langs/uk.jsx";

let languageSubject = new Rx.BehaviorSubject( null );
let supportedLanguagesById = {};
let LanguagesTypes = [ LangEn, LangRu, LangUk ];
let defaultLanguage = null;

for ( let i = 0; i < LanguagesTypes.length; i++ ) {
	let instance = new LanguagesTypes[ i ]();
	supportedLanguagesById[ instance.getId() ] = instance;
	defaultLanguage = defaultLanguage || instance;
}

function translate( key, ...params ) {
	let lang = languageSubject.getValue();
	if ( !lang ) {
		throw new Error( `Translation language not set. key: ${key} ` );
	}
	return lang.translate( key, ...params );
};

global.setLanguage = translate.setLanguage = ( languagesByPriority ) => {
	for ( let i = 0; i < languagesByPriority.length; i++ ) {
		let primaryLanguage = languagesByPriority[ i ].split( "-" )[ 0 ].toLowerCase();
		if ( supportedLanguagesById[ primaryLanguage ] ) {
			languageSubject.onNext( supportedLanguagesById[ primaryLanguage ] );
			return;
		}
	}
	languageSubject.onNext( defaultLanguage );
};

translate.observeLanguageChanges = () => languageSubject;
translate.getLanguages = () => {
	return _.mapValues( supportedLanguagesById, lang => lang.getTitle() );
};
translate.getCurrentLanguage = () => languageSubject.getValue();
export default translate;
