import React from "react";
import serviceLocator from "../../../api/services/locators/worker.client.js";
import configuration from "../../../common/configuration.js";

import WebLoginView from "../web/login.jsx";
import ContactsView from "./contacts/contacts.jsx";
import GroupsView from "./groups/groups.jsx";
import SettingsView from "./settings/settings.jsx";
import ChatsView from "./chats/chats.jsx";
import SharedContactsView from "./sharedcontacts/sharedcontacts.jsx";
import OpenerView from "./opener.jsx";
import CheckLogin from "./check.login.jsx";
import CreateUserSystem from "./create.user.system.jsx";
import ExternalChatView from "./external/chat.jsx";

class Router extends React.Component {
	componentWillMount() {
		configuration.setConfiguration( null );
		serviceLocator().setConfigurationAsync( null ).subscribe();
	}

	renderWithCheck( Component, loginStatus, orGoto ) {
		return (
			<CheckLogin
				loginStatus={loginStatus}
				orGoto={orGoto}>
				<Component params={this.props.path.slice( 1 )}/>
			</CheckLogin>
		);
	}

	render() {
		let segments = this.props.path;

		switch ( segments[ 0 ] ) {
			case "chats":
				return this.renderWithCheck( ChatsView, true, "login" );
			case "contacts":
				return this.renderWithCheck( ContactsView, true, "login" );
			case "groups":
				return this.renderWithCheck( GroupsView, true, "login" );
			case "settings":
				return this.renderWithCheck( SettingsView, true, "login" );
			case "sharedcontacts":
				return this.renderWithCheck( SharedContactsView, true, "login" );
			case "opener":
				return this.renderWithCheck( OpenerView, false, `${segments[1]}/${segments[2]}` );
			case "createUserSystem":
				return this.renderWithCheck( CreateUserSystem, false, "chats" );
			case "external":
				return <ExternalChatView token={ segments[ 1 ] }/>;
		}

		return this.renderWithCheck( WebLoginView, false, "chats" );
	}
}

export default Router;
