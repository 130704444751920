"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _crypto = require("crypto");

var _crypto2 = _interopRequireDefault(_crypto);

var _bn = require("bn.js");

var _bn2 = _interopRequireDefault(_bn);

var _prng = require("../../random/locators/prng.js");

var _prng2 = _interopRequireDefault(_prng);

var _bnExternal = require("../../bn.external/bn.external.js");

var _bnExternal2 = _interopRequireDefault(_bnExternal);

var _sign = require("../abstract/sign.js");

var _sign2 = _interopRequireDefault(_sign);

var _schnorr = require("../../group/schnorr.js");

var _schnorr2 = _interopRequireDefault(_schnorr);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var random = (0, _prng2.default)();

var SchnorrSign = function (_AbstractSign) {
	_inherits(SchnorrSign, _AbstractSign);

	function SchnorrSign(group) {
		_classCallCheck(this, SchnorrSign);

		if (!(group instanceof _schnorr2.default)) {
			throw new Error("Group (schnorr) required");
		}

		var _this = _possibleConstructorReturn(this, (SchnorrSign.__proto__ || Object.getPrototypeOf(SchnorrSign)).call(this));

		_this._group = group;
		return _this;
	}

	_createClass(SchnorrSign, [{
		key: "getPrivateKeyByteLength",
		value: function getPrivateKeyByteLength() {
			return this._group.getQ().byteLength();
		}
	}, {
		key: "getSignByteLength",
		value: function getSignByteLength() {
			return this._group.getQ().byteLength() * 2;
		}
	}, {
		key: "setPrivateKey",
		value: function setPrivateKey(buffer) {
			//TODO: better check
			/*if ( this.isPrivateKeySet() ) {
   	throw new Error( "Private key already set" );
   }*/

			if (!Buffer.isBuffer(buffer)) {
				throw new Error("buffer parameter required");
			}
			var bnPriv = new _bn2.default(buffer);

			if (bnPriv.cmpn(1) !== 1) {
				throw new Error("Signature priv too small");
			}

			if (bnPriv.cmp(this._group.getQ()) !== -1) {
				throw new Error("Invalid signature priv");
			}
			if (this._privBufferResolve) {
				this._privBufferResolve(buffer);
			} else {
				this._privBufferThen = Promise.resolve(buffer);
				this._initKey();
			}
			return this;
		}
	}, {
		key: "postponePrivateKey",
		value: function postponePrivateKey() {
			var _this2 = this;

			if (this._privBufferResolve) {
				return;
			}
			this._privBufferThen = new Promise(function (resolve) {
				_this2._privBufferResolve = resolve;
			});
			this._initKey();
			return this;
		}
	}, {
		key: "setPrivateKeyRandom",
		value: function setPrivateKeyRandom() {
			var _this3 = this;

			if (this.isPrivateKeySet()) {
				throw new Error("Private key already set");
			}
			this._privBufferThen = random.pseudoRandomDataThen(this._group.getQ().byteLength() + 2).then(function (x) {
				return new Buffer(new _bn2.default(x).mod(_this3._group.getQ().subn(2)).addn(2).toArray("LE", _this3._group.getQ().byteLength()));
			});
			this._initKey();
			return this;
		}
	}, {
		key: "isPrivateKeySet",
		value: function isPrivateKeySet() {
			return !!this._privBufferThen;
		}
	}, {
		key: "_initKey",
		value: function _initKey() {
			this._privKeyThen = this._privBufferThen.then(function (buffer) {
				return new _bn2.default(buffer);
			});
		}
	}, {
		key: "makeSignThen",
		value: function makeSignThen(binarySource) {
			var _this4 = this;

			if (!this._privBufferThen) {
				throw new Error("Private key not set. Use setPrivateKey or setPrivateKeyRandom before signing");
			}
			var q = this._group.getQ();
			var qRed = _bn2.default.red(q);
			return Promise.all([binarySource.getBufferThen(), random.pseudoRandomDataThen(this._group.getQ().byteLength() + 2), this._privKeyThen]).then(function (_ref) {
				var _ref2 = _slicedToArray(_ref, 3),
				    buffer = _ref2[0],
				    kBuffer = _ref2[1],
				    privKey = _ref2[2];

				var kBN = new _bn2.default(kBuffer);
				return {
					k: kBN.mod(_this4._group.getQ().subn(1)).addn(1),
					buffer: buffer,
					xBN: privKey
				};
			}).then(function (data) {
				var k = data.k;
				var rThen = _bnExternal2.default.redPowExternalThen(_this4._group.getGRed(), k); //g^k

				var hash = _crypto2.default.createHash("sha256");
				hash.update(data.buffer);
				return rThen.then(function (r) {
					return {
						rBN: r,
						kRed: k.toRed(qRed),
						buffer: data.buffer,
						xBN: data.xBN,
						hash: hash
					};
				});
			}).then(function (data) {
				var rLength = _this4._group.getP().byteLength();
				data.hash.update(new Buffer(data.rBN.toArray("LE", rLength)));
				var eBN = new _bn2.default(data.hash.digest()).umod(q);
				var xeRed = data.xBN.mul(eBN).toRed(qRed);

				var sBN = data.kRed.redSub(xeRed).fromRed();

				var s = new Buffer(sBN.toArray("LE", q.byteLength()));
				var e = new Buffer(eBN.toArray("LE", q.byteLength()));
				return Buffer.concat([s, e]);
			});
		}
	}, {
		key: "toPrivateBufferThen",
		value: function toPrivateBufferThen() {
			if (!this._privBufferThen) {
				throw new Error("Private key not set. Use setPrivateKey or setPrivateKeyRandom first");
			}
			return this._privBufferThen;
		}
	}, {
		key: "toPublicBufferThen",
		value: function toPublicBufferThen() {
			var _this5 = this;

			if (!this._privBufferThen) {
				throw new Error("Private key not set. Use setPrivateKey or setPrivateKeyRandom first");
			}
			var pkLength = this._group.getP().byteLength();
			return this._privKeyThen.then(function (x) {
				return _bnExternal2.default.redPowExternalThen(_this5._group.getGRed(), x);
			}).then(function (bn) {
				return new Buffer(bn.toArray("LE", pkLength));
			});
		}
	}]);

	return SchnorrSign;
}(_sign2.default);

exports.default = SchnorrSign;