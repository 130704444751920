import React from "react";

import {getStatusColor} from "../../common/utils.js";
import serviceLocator from "../../api/services/locators/worker.client.js";
import Notifications from "../views/common/notifications.jsx";
import Translation from "./translation.jsx";

let isRegisteredNotification = false;
let timeout = null;

function notificationFactory() {
    return <NoConnectionNotificationView/>;
}

function registerNotification() {
    if ( isRegisteredNotification ) {
        return;
    }
    isRegisteredNotification = true;
    timeout = setTimeout( () => {
        timeout = null;
        Notifications.registerNotification( notificationFactory, "24px" );
    }, 1000 );
}

function unregisterNotification() {
    if ( !isRegisteredNotification ) {
        return;
    }
    isRegisteredNotification = false;
    if ( timeout ) {
        clearTimeout( timeout );
        return;
    }
    Notifications.unregisterNotification( notificationFactory );
}

class OnlineStatusView extends React.Component {
    constructor() {
        super();
        this._service = serviceLocator();
        this.state = {
            onlineStatus: "offline"
        };
    }

    componentWillMount() {
        this._subscription = (
            this._service.observeOnlineStatus()
                .subscribe( onlineStatus => {
                    if ( onlineStatus === "online" ) {
                        unregisterNotification();
                    } else {
                        registerNotification();
                    }
                    this.setState( {onlineStatus} );
                } )
        );
    }

    componentWillUnmount() {
        this._subscription.dispose();
    }

    render() {
        return (
            <div className={ "status " + getStatusColor( this.state.onlineStatus ) }>
                <span className="indicator" />
            </div>
        );
    }
}

class NoConnectionNotificationView extends React.Component {
    render() {
		return (
			<div style={{
					width: "100%",
					height: "100%",
					background: "#ccc",
					color: "#000",
					padding: "4px 12px",
					border: "#fff solid 2px",
					borderRadius: "10px"
				}}>
				<Translation textId="common.noInternet.notification" />
			</div>
		);
	}
}

export default OnlineStatusView;
