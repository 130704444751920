import React from "react";
import ReactDom from "react-dom";
import _ from "lodash";

import ChatCommon from "./chat.common.jsx";
import {MESSAGE_TYPE} from "../../../api/models/chat.message.js";
import ChatContactMenu from "./chat.contact.menu.jsx";
import serviceLocator from "../../../api/services/locators/worker.client.js";

class ChatContact extends ChatCommon {
	constructor() {
		super();
		this._service = serviceLocator();
	}

	componentWillMount() {
		this._messagesObservable = (
			this._service.observeMessages( this.props.contact )
		);
		super.componentWillMount();
	}

	componentWillUnmount() {
		this.setMessagesRead();
		super.componentWillUnmount();
	}

	onResume() {
		this.setMessagesRead();
		super.onResume();
	}

	setMessagesRead() {
		if ( this._readTill === this.state.maxReceivedIndex ) {
			return;
		}
		this._readTill = this.state.maxReceivedIndex;
		this._service.setReadAllAsync( this.props.contact ).subscribe();
	}

	renderMenu() {
		return <ChatContactMenu contact={ this.props.contact } />;
	}

	onMessageReceived( message ) {
		// if ( message.type === MESSAGE_TYPE.INCOMING && message.id && ( typeof message.id === "string" ) ) {
		// 	contactServiceLocator().sendMessageRead( this.props.contact.id, message.id, message.senderPid, message.index );
		// }
		//It is already in history
		// contactServiceLocator().putMessageToHistory( message, this.props.contact.id );
		super.onMessageReceived( message );
	}
}

export default ChatContact;
