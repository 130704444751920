import React from "react";
import classNames from "classnames";
import MediaQuery from "react-responsive";
import { Modal, Button } from "react-bootstrap";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";
import FocusManager from "../../../components/focus.manager.js";
import Translation from "../../../components/translation.jsx";
import Title from "./title.jsx";
import Notifications from "./notifications.jsx";

let focusManager = new FocusManager();
class LayoutView extends React.Component {
	constructor() {
		super();
		this.state = {
			isKeyboardVisible: false
		};
		this._service = remoteServiceLocator();
		this.onDragOver = this.onDragOver.bind( this );
		this.onShowKeyboard = () => this.setState( { isKeyboardVisible: true } );
		this.onHideKeyboard = () => this.setState( { isKeyboardVisible: false } );
	}

	onDragOver( event ) {
		event.stopPropagation();
		event.preventDefault();
	}

	componentWillMount() {
		this._isOnlineSubscription = (
			this._service.observeOnlineStatus()
				.subscribe( isOnline => {
					this.setState( { isOnline } );
				} )
		);
	}

	componentWillUnmount() {
		this._isOnlineSubscription.dispose();
	}

	static get childContextTypes() {
		return { focusManager: React.PropTypes.object.isRequired };
	}

	getChildContext() {
		return { focusManager };
	}

	renderHeader( namedContent ) {
		switch( this.props.activeMobileView ) {
			case "list":
				return (
					<header className="container hidden-xs">
						<div className="logo">
							<img src="web/img/ico-02.svg" alt="FM" />
						</div>
						<div className={namedContent.ListTitleContentView.className || "chats grow"}>
							{ namedContent.ListTitleContentView.children }
						</div>
						{ namedContent.MainTitleContentView.children }
					</header>
				);
			case "main":
				return (
					<header className="container">
						<div className="logo hidden-xs">
							<img src="web/img/ico-02.svg" alt="FM" />
						</div>
						<div className={( namedContent.ListTitleContentView.className || "chats grow" ) + " hidden-xs"}>
							{ namedContent.ListTitleContentView.children }
						</div>
						{ namedContent.MainTitleContentView.children }
					</header>
				);
			default:
				return null;
		}
	}

	renderMobileMenu( namedContent ) {
		switch( this.props.activeMobileView ) {
			case "list":
				return (
					<div className={ namedContent.MobileMenuContentView.className || "sidebar" }>
						{ namedContent.MobileMenuContentView.children }
					</div>
				);
			case "main":
				return (
					<div className={ ( namedContent.MobileMenuContentView.className || "sidebar" ) + " hidden-xs" }>
						{ namedContent.MobileMenuContentView.children }
					</div>
				);
			default:
				return null;
		}
	}

	renderWindowTitle() {
		switch( this.props.activeMobileView ) {
			case "list":
				return (
					<span>
						<MediaQuery query='(min-width: 768px)'>
							<Title textId={this.props.mainTitleTextId} params={this.props.mainTitleParams} />
						</MediaQuery>
						<MediaQuery query='(max-width: 767px)'>
							<Title textId={this.props.listTitleTextId} params={this.props.listTitleParams} />
						</MediaQuery>
					</span>
				);
			case "main":
				return <Title textId={this.props.mainTitleTextId} params={this.props.mainTitleParams} />;
			default:
				return null;
		}
	}

	renderOfflinePopup() {
		if ( this.state.isOnline ) {
			return null;
		}
		return (
			<div className="static-modal" style={{zIndex: 100}}>
				<Modal.Dialog>
					<Modal.Header>
						<Modal.Title>
							<Translation textId="web.offline.title"/>
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Translation textId="web.offline.text"/>
					</Modal.Body>

					<Modal.Footer>
					</Modal.Footer>
				</Modal.Dialog>
		  	</div>
		);
	}

	render() {
		let namedContent = Object.create( null );
		for( let i = 0; i < this.props.children.length; i++ ) {
			var child = this.props.children[ i ];
			if ( child.type === MainContentView ) {
				namedContent.MainContentView = child.props;
				continue;
			}
			if ( child.type === ListbarContentView ) {
				namedContent.ListbarContentView = child.props;
				continue;
			}
			if ( child.type === CustomContentView ) {
				namedContent.CustomContentView = child.props;
				continue;
			}
			if ( child.type === ListTitleContentView ) {
				namedContent.ListTitleContentView = child.props;
				continue;
			}
			if ( child.type === MainTitleContentView ) {
				namedContent.MainTitleContentView = child.props;
				continue;
			}
			if ( child.type === MobileMenuContentView ) {
				namedContent.MobileMenuContentView = child.props;
				continue;
			}
		}
		let listClassName = (
			this.props.activeMobileView !== "list"
			? "hidden-xs"
			: ""
		);
		let mainClassName = namedContent.MainContentView.className || "message";

		if ( this.props.activeMobileView !== "main" ) {
			mainClassName += " hidden-xs";
		}
		let containerClassName = classNames( "container", "main", {
			listcontainer: this.props.activeMobileView === "list"
		} );
		return (
			<div style={{width: "100%", height: "100%"}} onDragOver={this.onDragOver}>
				<Notifications />
				{ this.renderHeader( namedContent ) }
				<div className={containerClassName}>
					{ this.renderMobileMenu( namedContent ) }
					{
						React.cloneElement(
							React.Children.only( namedContent.ListbarContentView.children ),
							{ className: listClassName }
						)
					}
					{
						React.cloneElement(
							React.Children.only( namedContent.MainContentView.children ),
							{ className: mainClassName }
						)
					}
				</div>
				{ namedContent.CustomContentView.children || null }
				{ this.renderWindowTitle() }
				{ this.renderOfflinePopup() }
			</div>
		);
	}

	static get propTypes() {
		return {
			mainTitleTextId: React.PropTypes.string.isRequired,
			listTitleTextId: React.PropTypes.string.isRequired,
			mainTitleParams: React.PropTypes.array.isRequired,
			listTitleParams: React.PropTypes.array.isRequired
		};
	}

	static get defaultProps() {
		return {
			mainTitleParams: [],
			listTitleParams: []
		};
	}
}

export default LayoutView;

export class ListTitleContentView extends React.Component {
	render() { return null; }
};

export class MainTitleContentView extends React.Component {
	render() { return null; }
};

export class ListbarContentView extends React.Component {
	render() { return null; }
};

export class MainContentView extends React.Component {
	render() { return null; }
};

export class MobileMenuContentView extends React.Component {
	render() { return null; }
};
export class CustomContentView extends React.Component {
	render() { return null; }
};
