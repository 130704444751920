import React from "react";

import serviceLocator from "../../api/services/locators/worker.client.js";
import configuration from "../../common/configuration.js";

import Translated from "./abstract/translated.jsx";
import TextInput, {PasswordInput, MultiLineInput} from "./input.jsx";
import Checkbox from './checkbox.jsx';
import Translation from "./translation.jsx";

class TranslatedInputComponent extends Translated {
	constructor() {
		super();
		this.state = {
			showPassword: false,
			useVirtualKeyboard: null
		};
		this._service = serviceLocator();
	}

	componentWillMount() {
		this._useKeyboardSubscription = (
			this._service.observeProfile()
				.subscribe( ( { useVirtualKeyboard } ) => {
					this.setState( { useVirtualKeyboard } );
				} )
		);

		if ( !this._service.isLoggedIn() ) {
			this.setState( { useVirtualKeyboard: configuration.getUseVirtualKeyboard() } );
			return;
		}
		this._keyboardTimeout = setTimeout(
			() => {
				if ( this.state.useVirtualKeyboard === null ) {
					this.setState( { useVirtualKeyboard: configuration.getUseVirtualKeyboard() } );
				}
			}, 500
		);
	}

	componentDidMount() {
		if ( !this.state.useVirtualKeyboard && this.props.autofocus && this.refs.input ) {
			this.refs.input.focus();
		}
	}

	componentWillUnmount() {
		this._useKeyboardSubscription.dispose();
		if ( this._keyboardTimeout ) {
			clearTimeout( this._keyboardTimeout );
		}
	}

	static get contextTypes() {
		return { focusManager: React.PropTypes.object.isRequired };
	}

	toggleShowPassword() {
		this.setState({showPassword: ! this.state.showPassword});
	}

	render() {
		let { placeholderTextId, ...props } = this.props;
		props.placeholder = this.translate( this.props.placeholderTextId );//???
		if (this.state.useVirtualKeyboard === null) {
			return null;
		}
		if ( !this.state.useVirtualKeyboard ) {
			let { onChange, autofocus, firstUppercased, defaultLatinNoCaps, ...inputProps } = props;
			inputProps.onChange = event => onChange && onChange( event.target.value );
			inputProps.value = inputProps.value || "";
			return <input {...inputProps} ref="input"/>;
		}
		switch ( props.type ) {
			case "password":
				return (
					<span>
						<PasswordInput {...this.props}
						placeholder={ this.translate( this.props.placeholderTextId ) }
						showPassword={!! this.state.showPassword}
						noEnter={true}
						/>

						<div className="label showPassword" >
							<Checkbox className="f-right " value={!! this.state.showPassword}
								onClick={this.toggleShowPassword.bind(this)}/>
							<Translation textId="show.password"/>
						</div>
					</span>
				);
			case "multiline":
				return <MultiLineInput {...props}/>;
			default:
				return <TextInput {...props}/>;
		}
	}
}

TranslatedInputComponent.propTypes = {
	placeholderTextId: React.PropTypes.string.isRequired
};
export default TranslatedInputComponent;
