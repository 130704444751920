import React from "react";
import Translated from "./abstract/translated.jsx";

class TranslationComponent extends Translated {
	render() {
		let {textId, params, ...props} = this.props;
		let translation = this.translate( textId, ...params );
		return typeof translation === "string" ?
			<span {...props}>{translation}</span> : translation;
	}
}

TranslationComponent.defaultProps = {
	params: []
};

TranslationComponent.propTypes = {
	textId: React.PropTypes.string.isRequired,
	params: React.PropTypes.array
};
export default TranslationComponent;
