import Service from "../admin.js";

let instance = null;
let mockedInstance = null;

export default function() {
	if ( mockedInstance ) {
		return mockedInstance;
	}
	if ( !instance ) {
		instance = Object.create( Service.prototype );
		Service.call( instance );
	}
	return instance;
};

export function mock( newMockedInstance ) { mockedInstance = newMockedInstance; };

export function unmock() { mockedInstance = null; };
