"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _lodash = require("lodash");

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//TODO: make objects readonly
var KEY_KINDS = {
	INTERMEDIATE: { name: "INTERMEDIATE", code: "ki" },
	SYMMETRIC_ENCRYPTION: { name: "SYMMETRIC_ENCRYPTION", code: "ke" },
	MAC: { name: "MAC", code: "km" },
	KEY_EXCHANGE_PRIVATE: { name: "KEY_EXCHANGE_PRIVATE", code: "kr" },
	KEY_EXCHANGE_PUBLIC: { name: "KEY_EXCHANGE_PUBLIC", code: "ku" },
	SIGNATURE_PRIVATE: { name: "SIGNATURE_PRIVATE", code: "ks" },
	SIGNATURE_PUBLIC: { name: "SIGNATURE_PUBLIC", code: "kv" },
	contains: function contains(kind) {
		return !!kind && KEY_KINDS[kind.name] === kind;
	},
	byCode: function byCode(code) {
		return _lodash2.default.find(KEY_KINDS, function (kind) {
			return kind.code === code;
		});
	}
};

exports.default = KEY_KINDS;