import React from "react";
import Letters from "./mobile.letters.jsx";
import Digits from "./mobile.digits.jsx";
import Symbols from "./mobile.symbols.jsx";

class MobileEnKeyboardView extends React.Component {
	constructor() {
		super();
		this.state = {
			mode: "alpha"
		};
		this.onChangeMode = this.onChangeMode.bind( this );
	}

	onChangeMode( mode ) {
		this.setState( { mode } );
	}

	render() {
		let {onLangChange} = this.props;
		switch( this.state.mode ) {
			case "alpha":
				return <Letters shift={false} onChangeMode={this.onChangeMode} onLangChange={onLangChange}/>;
			case "shift":
				return <Letters shift={true} onChangeMode={this.onChangeMode} onLangChange={onLangChange}/>;
			case "caps":
				return <Letters shift={true} onChangeMode={this.onChangeMode} onLangChange={onLangChange}/>;
			case "digits":
				return <Digits onChangeMode={this.onChangeMode} onLangChange={onLangChange}/>;
			case "symbols":
				return <Symbols onChangeMode={this.onChangeMode} onLangChange={onLangChange}/>;
		}
		return (
			<span onClick={this.onChangeMode.bind( this, "alpha" )}>
				Unknown mobile keyboard mode {this.state.mode}
			</span>
		);
	}
}

export default MobileEnKeyboardView;
