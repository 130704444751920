import Rx from "rx";

let soundsSubject = new Rx.Subject();

soundsSubject
	.throttle( 2000 )
	.filter( active => active )
	.subscribe( () => {
		navigator.notification && navigator.notification.beep && navigator.notification.beep( 1 );
		navigator.notification && navigator.notification.vibrate && navigator.notification.vibrate( 200 );

		if ( navigator.notification && navigator.notification.beep ) {
			return;
		}
		if ( !global.document ) {
			console.warn( "Cannot make sound outside of document" );
			return;
		}
		try {
			let aSound = document.createElement( "audio" );
			let source = document.createElement( "source" );
			source.setAttribute( "src", "sounds/beep.mp3" );
			source.setAttribute( "type", "audio/wav" );
			aSound.appendChild( source );

			aSound.play();
		} catch( e ) {
			console.error( e );
		}
	} );

let sounds = {
	newMessage: function() {
		soundsSubject.onNext( true );
		/*		if ( !global.AudioContext ) {
					return;
				}
				let ac = new global.AudioContext();
				let osc = ac.createOscillator();
				let amp = ac.createGain();
				osc.connect(amp);
				amp.connect(ac.destination)
				amp.gain.setValueAtTime(1, ac.currentTime);
				amp.gain.linearRampToValueAtTime(0, ac.currentTime + 0.15);
				osc.start(ac.currentTime);
				osc.stop(ac.currentTime + 0.2);*/


	}
};

export default sounds;
