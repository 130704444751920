import React from "react";
import _ from "lodash";

class BigView extends React.Component {
	constructor() {
		super();
		this.state = {
			shift: false
		};
		this.onShiftClick = this.onShiftClick.bind( this );
	}

	handleKey( key ) {
		this.context.focusManager.handleKey( key );
	}

	onShiftClick( event ) {
		this.setState( { shift: !this.state.shift } );
	}

	renderRowContent( symbols, subsymbols ) {
		return _.map(
			symbols.split( "" ),
			( symbol, index ) => (
				<div
					key={ symbol }
					className="key one"
					onClick={ this.handleKey.bind( this, symbol ) }
				>
					{symbol}
					{ this.renderSubSymbol( subsymbols, index ) }
				</div>
			)
		);
	}

	renderSubSymbol( subsymbols, index ) {
		if ( !subsymbols || !subsymbols[ index ] ) {
			return null;
		}
		return (
			<span className="key-sub">
				{ subsymbols[ index ] }
			</span>
		);
	}

	render() {
		let row1 = this.state.shift ? "!\"№;%:?*()_+" : "1234567890-=";
		let row1Sub = !this.state.shift ? "!\"№;%:?*()_+" : "1234567890-=";
		let row2 = this.state.shift ? "ЙЦУКЕНГШЩЗХЪ/" : "йцукенгшщзхъ\\";
		let row3 = this.state.shift ? "ФЫВАПРОЛДЖЭ" : "фывапролджэ";
		let row4 = this.state.shift ? "ЯЧСМИТЬБЮ,'" : "ячсмитьбю.\"";
		return (
			<div className="keyboard hidden-xs">
				<section className="key-row">
					{ this.renderRowContent( row1, row1Sub ) }
					<div
						className="key one icon"
						onClick={ this.handleKey.bind( this, "Backspace" ) }
					>
						<img src="web/img/back.svg" alt="" />
					</div>
				</section>
				<section className="key-row">
					{ this.renderRowContent( row2 ) }
				</section>
				<section className="key-row">
					{ this.renderRowContent( row3 ) }
					<div className="key two enter">enter</div>
				</section>
				<section className="key-row">
					<div
						className="key one icon"
						onClick={this.onShiftClick}
					>
						<img
							src={ `web/img/${this.state.shift ? "shift_active" : "up" }.svg` }
							alt=""
						/>
					</div>
					{ this.renderRowContent( row4 ) }
					<div
						className="key one icon up"
						onClick={ this.handleKey.bind( this, "Up" ) }
					>
						<img src="web/img/up-arrow.svg" alt="" />
					</div>
				</section>
				<section className="key-row">
					<div
						className="key one icon"
						onClick={this.props.onLangChange}
					>RU</div>
					<div
						className="key nine icon"
						onClick={ this.handleKey.bind( this, " " ) }
					>space</div>
					<div
						className="key one icon left"
						onClick={ this.handleKey.bind( this, "Left" ) }
					>
						<img src="web/img/left-arrow.svg" alt="" />
					</div>
					<div
						className="key one icon down"
						onClick={ this.handleKey.bind( this, "Down" ) }
					>
						<img src="web/img/down-arrow.svg" alt="" />
					</div>
					<div
						className="key one icon right"
						onClick={ this.handleKey.bind( this, "Right" ) }
					>
						<img src="web/img/right-arrow.svg" alt="" />
					</div>
				</section>
			</div>
		);
	}

	static get contextTypes() {
		return { focusManager: React.PropTypes.object.isRequired };
	}
}

export default BigView;
