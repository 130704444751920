import React from "react";
import _ from "lodash";
import classNames from "classnames";
import Notifications from "./notifications.jsx";

class TopLevelView extends React.Component {
	constructor() {
		super();
		this.state = { notificationHeights: [] };
	}

	componentWillMount() {
		this._subscription = (
				Notifications.observeHeights().subscribe( notificationHeights => {
				this.setState( {notificationHeights} );
			} )
		);
	}

	componentWillUnmount() {
		this._subscription.dispose();
	}

	render() {
		let { style, className, children, ...props } = this.props;
		style = _.assign( {
			margin: 0,
			width: "100%",
			height: "100%",
			paddingTop: `calc(8rem + ${this.state.notificationHeights.join( " + " ) || "0px"})`,
			borderBottom: "none"
		}, style );
		let [header, main, ...other] = children;
		return (
			<section {...props} className={classNames( "view-root", className )} style={style}>
				{header}
				<Notifications />
				{main}
				{other}
			</section>
		);
	}
}

export default TopLevelView;
