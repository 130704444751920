import React from "react";
import Rx from "rx";

let handlersStacksPerEventName = {};

class HandlersStack {
	constructor( observable ) {
		this._subscription = null;
		this._observable = observable;
		this._handlers = [];
	}
	pushHandler( func ) {
		this._handlers.push( func );
		this._resubscribe();
	}
	removeHandler( func ) {
		let index = this._handlers.indexOf( func );
		~index && this._handlers.splice( index, 1 );
		this._resubscribe();
	}
	_resubscribe() {
		this._subscription && this._subscription.dispose();
		this._subscription = null;
		if ( this._handlers.length ) {
			this._subscription = (
					this._observable
						.subscribe( this._handlers[ this._handlers.length - 1 ] )
				);
		}
	}
}
class DeviceButton extends React.Component {
	constructor( eventName ) {
		super();
		if ( !handlersStacksPerEventName[ eventName ] ) {
			handlersStacksPerEventName[ eventName ] =
				new HandlersStack(
						Rx.Observable.fromEvent( global.document, eventName )
					);
		}
		this._handlersStack = handlersStacksPerEventName[ eventName ];
		this._onPress = this._onPress.bind( this );
	}
	componentDidMount() {
		this._handlersStack.pushHandler( this._onPress );
	}
	componentWillUnmount() {
		this._handlersStack.removeHandler( this._onPress );
	}
	_onPress( event ) {
		this.props.onPress( event );
	}
	render() {
		return null;
	}
}

DeviceButton.propTypes = {
	onPress: React.PropTypes.func.isRequired
};
export default DeviceButton;
