import _ from 'lodash';
import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

import Footer from "../common/footer.jsx";
import Notifications from "../common/notifications.jsx";
import WithFooterView from "../common/with.footer.view.jsx";
import ContactMenu from "./contacts.menu.jsx";
import ChatView from "../chat/chat.contact.jsx";
import Translation from "../../components/translation.jsx";
import translate from "../../translations/translate.js";
import HorizontalSwipable from "../../components/horizontal.swipeable.jsx";
import ImageButton from "../../components/button.image.jsx";
import FullScreen from "../../components/fullscreen.jsx";
import {ModalItemController} from "../common/modal.menu.jsx";
import RenameView from "./rename.jsx";
import InviteView from "../invite/invite.view.jsx";
import InvitedDescriptionView from "../invite/invited.description.view.jsx";
import {getAvaColorByName, getContactInitials, getStatusColor, sortContacts}
	from "../../../common/utils.js";
import {Confirm} from "../../components/dialogs.jsx"
import OnlineStatus from "../../components/online.status.jsx"
import ContactModel from "../../../api/models/contact.js";
import SharedContactsDashboard from "../sharedcontacts/dashboard.jsx";
import SearchContacts from "../common/search.contacts.jsx";
import AddAssistant from "./add.assistant.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";
import configuration from "../../../common/configuration.js";
let StatusText = ( { status } ) => <Translation textId={ "contact.status." + status }/>;
let Status = ( { status } ) => (
	<div className={"status " + getStatusColor( status )}>
		<span className="indicator"></span>
		<StatusText status={status}/>
	</div>
);
let WarningIcon = props => <span></span>;//<img {...props} className="info-icon" src="images/icon-exclamation-mark.svg"/>;
let DeleteIcon = props => <div {...props} className="round-btn f-right right icon delete"></div>;
let EditIcon = props => <div {...props} className="round-btn f-right icon edit"></div>;

let Avatar = ( { color, unreadCount, initials } ) => {
	let style = { backgroundColor: color };
	let cn = "ava count-sticker";
	if ( unreadCount ) {
		if ( unreadCount > 100 ) {
			cn += " small";
			unreadCount = "100+";
		}
		return (
			<div className={cn} data-count={unreadCount} style={style}>
				{initials}
			</div>
		);
	}
	return <div className="ava" style={style}>{initials}</div>;
};

let BaseContact = ( { avaColor, name, initials, status, unreadCount, children,
	allowEdit, onDelete, warn, contact, isWorkgroup, moreButton, isExpanded,
	isOpened, onOpen, onClose, ...props } ) => (
	<div className="item" {...props}>
		{ children}
		<Avatar color={avaColor} unreadCount={unreadCount} initials={initials} />
		<div className={ classNames( "name", `contact-${isWorkgroup ? "wg" : contact.multidescriptionId !== -1 ? "shared" : status}` )}>
			{name}
		</div>
		{ status === "invited" ? <Status status={status}/> : null }
		{ status === "joining" ? <Status status={status}/> : null }
		{ status === "disconnected" ? <Status status={status}/> : null }
		{ status === "failed" ? <span style={{color: "red"}}>FAILED</span> : null }
		{ isWorkgroup ? <Translation textId={ isWorkgroup === "collapsed" ? "contacts.workgroup.collapsed" : "contacts.workgroup" } /> : null }
		{ contact.multidescriptionId !== -1 && contact.multidescriptionId !== undefined
			? <Translation textId="contacts.shared" /> : null }
	</div>
);

class Contact extends React.Component {
	constructor() {
		super();
		this.showError = this.showError.bind( this );
		this.open = this.open.bind( this );
		this.close = this.close.bind( this );
		this.onClick = this.onClick.bind( this );
	}

	showError() {
		alert(
			translate( "contact.failed.message" )
			+ "\n"
			+ this.props.contact.failReason
		);
		this.props.onDelete && this.props.onDelete( this.props.contact );
	}

	open( event ) {
		event && event.stopPropagation();
		this.props.onOpen( this.props.contact );
	}

	close() {
		this.props.onClose();
	}

	renderOnMore() {
		if ( !this.props.moreButton ) {
			return null;
		}
		return (
			<div className="workgroup-dashboard" onClick={ this.open }>
				...
			</div>
		);
	}

	onClick( event ) {
		if ( this.props.onClick ) {
			this.props.onClick( event );
			return;
		}
		this.open();
	}

	renderModal() {
		if ( !this.props.isOpened ) {
			return null;
		}
		if ( !this.props.children ) {
			return null;
		}
		return (
			<FullScreen>
				{ React.cloneElement(
					React.Children.only( this.props.children ),
					{ onBack: this.close }
				) }
			</FullScreen>
		);
	}

	render() {
		let { warn, children, ...props } = this.props;
		let {status} = this.props.contact;
		if ( status === "failed" ) {
			return (
				<BaseContact {...props} onClick={ this.showError }>
					{warn && <WarningIcon />}
				</BaseContact>
			);
		}

		return (
			<BaseContact {...props} onClick={this.onClick}>
				{warn && <WarningIcon />}
				{this.renderOnMore()}
				{this.renderModal()}
			</BaseContact>
		);
	}
}

let EditButton = ( { contact, onEditEnd } ) =>  (
	<ModalItemController onBack={onEditEnd}>
		<EditIcon/>
		<RenameView contact={contact}/>
	</ModalItemController>
);

let SelectedContact = ( { onDelete, onEditEnd, ...props } ) => (
	<BaseContact {...props} className="item moved">
		<DeleteIcon onClick={onDelete.bind( this, props.contact )}/>
		<EditButton contact={props.contact} onEditEnd={onEditEnd}/>
	</BaseContact>
);

class ContactListItem extends React.Component {
	constructor() {
		super();
		this.onEditEnd = this.onEditEnd.bind( this );
		this.state = {};
	}

	open() {
		this.refs.unselected.open();
	}

	componentWillReceiveProps( nextProps ) {
		let { status, inviteToken, name, unreadCount } = nextProps.contact;
		if ( nextProps.unreadCount ) {
			unreadCount = nextProps.unreadCount;
		}

		this.setState( { status, inviteToken, name, unreadCount } );
	}

	shouldComponentUpdate( nextProps, nextState ) {
		let { status, inviteToken, name, unreadCount, allowModifyContacts } = nextState;
		return (
			status !== this.state.status ||
			inviteToken !== this.state.inviteToken ||
			unreadCount !== this.state.unreadCount ||
			name !== this.state.name ||
			nextProps.allowEdit !== this.props.allowEdit ||
			nextProps.isExpanded !== this.props.isExpanded ||
			allowModifyContacts !== this.state.allowModifyContacts ||
			nextProps.isOpened !== this.props.isOpened
		);
	}

	getContactModalView() {
		let { contact } = this.props;
		let { status, inviteToken } = contact;

		if ( status === "failed" ) {
			return null;
		}

		if ( ( status === "active" ) || ( status === "disconnected" ) ) {
			return <ChatView contact={contact}/>;
		}

		if ( status === "invited" ) {
			if ( inviteToken ) {
				return <InviteView token={inviteToken} isExternal={contact.isExternal} />;
			} else {
				return <InvitedDescriptionView contact={contact}/>;
			}
		}

		return null;
	}

	getSharedcontactsModalView() {
		let { contact } = this.props;
		if ( contact.status === "failed" ) {
			return null;
		}
		return <SharedContactsDashboard multidescription={ contact } />;
	}

	onEditEnd() {
		this.refs.contact.deselect();
	}

	render() {
		let { props } = this;
		let { contact } = props;
		let { unreadCount, status } = contact;
		if ( props.unreadCount ) {
			unreadCount = props.unreadCount;
		}

		let unselected = (
			contact.type === "multidescription"
			? <Contact { ...props } ref="unselected">
				{ this.getSharedcontactsModalView() }
			</Contact>
			: <Contact { ...props } unreadCount={ unreadCount } status={ status } ref="unselected">
					{ this.getContactModalView() }
				</Contact>
		);
		return (
			this.props.allowEdit
			? <HorizontalSwipable ref="contact">
				{ unselected }
				<SelectedContact
					{ ...props }
					contact={ contact }
					status={ status }
					onEditEnd={ this.onEditEnd }
					onDelete={ this.props.onDelete.bind( this, this.props.contact ) }
				/>
			</HorizontalSwipable>
			: unselected
		);
	}
}

let ContactList = ( { children } ) => {
	return (
		<div className="contact-list">
			{children}
		</div>
	);
};

class MenuHeader extends React.Component {
	constructor() {
		super();
		this.goToContact = this.goToContact.bind( this );
	}

	goToContact( contact ) {
		this.refs.controller.hide();
		this.props.goToContact( contact );
	}

	render() {
		let {goToContact, renderContacts, contacts, ...headerProps} = this.props;
		return (
			<header {...headerProps} onClick={this.show}>
				<Translation textId="contacts.header"/>
				{ !renderContacts ? null :
				<SearchContacts
					onRenderContacts={renderContacts}
				/> }
				<ModalItemController ref="controller">
					<ImageButton type="icon-hamburger" id="main-menu"/>
					<ContactMenu goToContact={this.goToContact}/>
				</ModalItemController>
				<OnlineStatus />
			</header>
		);
	}
}

class ContactListView extends React.Component {
	constructor() {
		super();
		this.state = {
			items: null,
			shared: null,
			openedContactId: null
		};
		this.updateChats = this.updateChats.bind( this );
		this.onDelete = this.onDelete.bind( this );
		this.contactSubscription = Rx.Observable.empty().subscribe();
		this.contact2Item = this.contact2Item.bind( this );
		this.onScroll = this.onScroll.bind( this );
		this.goToContact = this.goToContact.bind( this );
		this.renderContactsSublist = this.renderContactsSublist.bind( this );
		this.onOpen = this.onOpen.bind( this );
		this.onClose = this.onClose.bind( this );
		this.onDeleteClick = this.onDeleteClick.bind( this );
		this.onAddAssistant = this.onAddAssistant.bind( this );
		this.onBackFromAssistant = this.onBackFromAssistant.bind( this );
	}

	shouldComponentUpdate( nextProps, nextState ) {
		return (
			( nextState.openedContactId === null ) ||
			( this.state.openedContactId === null ) ||
			//Detect contact delete to close chat
			( this.state.items.length !== nextState.items.length )
		);
	}

	onOpen( contact ) {
		this.setState( { openedContactId: contact.id } );
	}

	onClose() {
		this.setState( { openedContactId: null } );
	}

	contact2Item( contact ) {
		return {
			id: contact.id,
			avaColor: this.getAvaColor( contact ),
			initials: this.getInitials( contact ),
			name: contact.name,
			status: contact.status,
			warn: contact.status != "invited" && !contact.isAuthenticated,
			contact
		};
	}

	updateChats( contacts, multidescriptions ) {
		let items = contacts
			// .filter( contact => contact.multidescriptionId === -1 )
			.map( this.contact2Item );

		let shared = multidescriptions
			.map( multidescription => ( {
				multidescription: this.contact2Item( multidescription ),
				items: sortContacts(
					multidescription.sharedList
						.filter( contact => contact.multidescriptionId === multidescription.id )
						.map( this.contact2Item )
				)
			} ) );

		this.setState( { items: sortContacts( items ), shared } );
	}

	getInitials( contact ) {
		return getContactInitials( contact.name );
	}

	getAvaColor( contact ) {
		if ( contact.isExternal ) {
			return "#888";
		}
		return getAvaColorByName( contact.name );
	}

	componentWillMount() {
		this.subscription = Rx.Observable.combineLatest(
			serviceLocator().observeContactList(),
			serviceLocator().observeSharedContacts(),
			( contacts, multidescriptions ) =>
				( { contacts, multidescriptions } )
		)
			.subscribe( ( { contacts, multidescriptions } ) => {
				this.updateChats( contacts, multidescriptions );
			} );
	}

	componentDidMount() {
		document.getElementsByTagName('main')[0].scrollTop = parseInt(global.contactsListPosition);
	}

	componentWillUnmount() {
		//TODO: fix
		global.contactsListPosition = document.getElementsByTagName('main')[0].scrollTop
		this.subscription.dispose();
	}

	onDeleteClick( contact ) {
		this._deleteContact = contact;
		this.refs.confirmDeletion.show()
	}

	onDelete( contact ) {
		contact = contact || this._deleteContact;

		if ( contact.type === "multidescription" ) {
			serviceLocator().exitMultidescriptionAsync( contact ).subscribe();
			return;
		}
		serviceLocator().deleteContactAsync( contact ).subscribe();
	}

	onScroll() {
		_.forEach(
			this.state.shared,
			( { multidescription } ) => {
				this.refs[ "shared_" + multidescription.id ].onScrolled();
			}
		);
	}

	goToContact( contact ) {
		let component = this.refs[ contact.id ];
		component && component.open();
	}

	onAddAssistant() {
		this.setState( {
			popup: () => <AddAssistant onBack={this.onBackFromAssistant} />
		} );
	}

	onBackFromAssistant( newContactId ) {
		this.setState( { popup: null } );
		if ( !newContactId ) {
			return;
		}
		setTimeout( () => {
			let component = this.refs[ newContactId ];
			component && component.open();
		}, 500 );
	}

	renderPopup() {
		if ( !this.state.popup ) {
			return null;
		}
		return this.state.popup();
	}

	renderNotLoaded() {
		return (
			<WithFooterView>
				<MenuHeader goToContact={this.goToContact} contacts={[]} renderContacts={() => []}/>
				<main onScroll={this.onScroll}>
					<ContactList>
						{ " " }
					</ContactList>
				</main>
				<Footer active="contacts"/>
			</WithFooterView>
		);
	}

	renderContacts( allowEdit, contacts ) {
		return (
			contacts.map( ( item, index ) =>
				<ContactListItem
					{...item}
					allowEdit={allowEdit}
					key={item.id}
					ref={item.id}
					isOpened={this.state.openedContactId === item.contact.id}
					onOpen={this.onOpen}
					onClose={this.onClose}
					onDelete={this.onDeleteClick}
				/>
			)
		);
	}

	renderShared( allowEdit, shared ) {
		return (
			shared.map( ( { items, multidescription } ) =>
				<SharedContacts
					key={"shared_" + multidescription.id}
					ref={"shared_" + multidescription.id}
					multidescription={multidescription}
					items={items}
					isOpened={this.state.openedContactId === multidescription.id}
					openedContactId={this.state.openedContactId}
					onOpen={this.onOpen}
					onClose={this.onClose}
					onDelete={this.onDeleteClick}
				/>
			)
		);
	}

	renderContactsSublist( searchString ) {
		let filterFunc = ( { name } ) => ~name.toLowerCase().indexOf( searchString );
		let contacts = _.filter( this.state.items, filterFunc );
		let shared = _.map( this.state.shared, ( { items, multidescription } ) => ( {
			multidescription,
			items: filterFunc( multidescription ) ? items : _.filter( items, filterFunc )
		} ) ).filter( ( { items } ) => items.length );
		if ( !contacts.length && !shared.length ) {
			return (
				<span className="small-text">
					<br />
					<Translation textId="contacts.search.empty" />
				</span>
			);
		}
		return (
			<ContactList>
				{ this.renderContacts( false, contacts ) }
				{ this.renderShared( true, shared ) }
			</ContactList>
		);
	}

	renderAddAssistant() {
		if (configuration.getHelperContactToken()) {
			return (
				<div className="item add-assistant" onClick={this.onAddAssistant}>
					<span className="name">
				  	<Translation textId="contacts.add.assistant" />
					</span>
				</div>
			);
		}
	}

	renderEmptyText() {
		const {items, shared} = this.state;
		if (items.length || shared.length) {
			return null;
		}
		return (
			<span className="small-text">
				<br />
				<Translation textId="contacts.empty" />
					<br />
					<br />
			</span>
		);
	}

	render() {
		if ( !this.state.items ) {
			return this.renderNotLoaded();
		}
		return (
			<WithFooterView>
				<MenuHeader
					goToContact={ this.goToContact }
					renderContacts={ this.renderContactsSublist }
				/>
				<main onScroll={ this.onScroll }>
					<ContactList>
						{ this.renderEmptyText() }
						{ this.renderContacts( true, this.state.items ) }
						{ this.renderShared( true, this.state.shared ) }
						{ this.renderAddAssistant() }
					</ContactList>
					<Confirm ref="confirmDeletion" textId="chat.delete.confirm" onConfirm={ this.onDelete } />
					{this.renderPopup()}
				</main>
				<Footer active="contacts"/>
			</WithFooterView>
		);
	}
}

class SharedContacts extends React.Component {
	constructor() {
		super();
		this.state = {};
		this.onClick = this.onClick.bind( this );
	}

	componentWillMount() {
		this._notificationsSubscription = (
			Notifications.observeHeights()
				.subscribe( () => {
					setTimeout( () => this.onScrolled(), 0 );
				} )
		);
		let { status } = this.props.multidescription.contact;
		if ( ( status === "failed" ) || ( status === "disconnected" ) ) {
			return;
		}
		//TODO: this is not working
		this.selfRightsSubscription = (
			serviceLocator()
				.observeSelfRights( this.props.multidescription )
				.subscribe( rights => {
					this.setState( { allowModifyContacts: !!rights && rights.allowModifyContacts } );
				} )
			);
	}

	componentWillUnmount() {
		this._notificationsSubscription.dispose();
		this.selfRightsSubscription && this.selfRightsSubscription.dispose();
	}

	onScrolled() {
		if ( !this.props.items || ( this.props.items.length === 0 )  || ( !this.props.isExpanded ) ) {
			return;
		}
		let node = ReactDOM.findDOMNode( this.refs[ this.props.multidescription.id ] );
		if ( !node ) {
			return console.warn( "Header not found" );
		}
		let mainNode = node;
		while ( mainNode.nodeName !== "MAIN" ) {
			mainNode = mainNode.parentNode;
		}
		if ( !mainNode ) {
			return console.warn( "Main node not found" );
		}
		let bottomNode = ReactDOM.findDOMNode( this.refs[ _.last( this.props.items ).id ] );
		let mainRect = mainNode.getBoundingClientRect();
		let nodeRect = node.getBoundingClientRect();
		let bottomRect = bottomNode.getBoundingClientRect();
		let needStickedHeader = ( mainRect.top > nodeRect.top ) && ( bottomRect.top >= mainRect.top );
		let isChanged = (
			( this.state.isRenderStickedHeader !== needStickedHeader )
			|| ( this.state.stickedTop !== mainRect.top )
		);
		if ( !isChanged ) {
			return;
		}
		this.setState( {
			isRenderStickedHeader: needStickedHeader,
			stickedTop: mainRect.top
		} );
	}

	onClick() {
		this.setState( { isExpanded: !this.state.isExpanded } );
	}

	renderStickedHeader() {
		if ( !this.state.isRenderStickedHeader || !this.state.isExpanded ) {
			return null;
		}
		return (
			<div className="sticked" style={ { top: this.state.stickedTop + "px" } }>
				{ this.renderHeader( "attached_" + this.props.multidescription.id ) }
			</div>
		);
	}

	renderHeader( key ) {
		let {isExpanded} = this.state;
		let {multidescription, items} = this.props;
		let unreadCount = isExpanded ? 0 : _.sumBy( items, "contact.unreadCount" );
		return (
			<ContactListItem
				{...multidescription}
				key={key}
				ref={key}
				name={multidescription.name + ":"}
				isWorkgroup={ isExpanded || _.isEmpty( items ) || "collapsed" }
				allowEdit={true}
				onOpen={this.props.onOpen}
				onClose={this.props.onClose}
				onDelete={this.props.onDelete}
				isOpened={this.props.isOpened}
				onClick={this.onClick}
				moreButton={true}
				unreadCount={unreadCount}
				isExpanded={isExpanded}
			/>
		);
	}

	renderContacts() {
		if ( !this.state.isExpanded ) {
			return null;
		}
		return this.props.items.map( item =>
			<ContactListItem
				{...item}
				key={item.id}
				ref={item.id}
				className="item shared"
				onOpen={this.props.onOpen}
				onClose={this.props.onClose}
				isOpened={this.props.openedContactId === item.id }
				onDelete={ this.props.onDelete }
				allowEdit={ this.state.allowModifyContacts }
			/>
		);
	}

	render() {
		return (
			<div>
				{ this.renderHeader( this.props.multidescription.id ) }
				{ this.renderStickedHeader() }
				{ this.renderContacts() }
			</div>
		);
	}
}

export default ContactListView;
