import React from "react";
import classNames from "classnames";

import Footer from "./footer.jsx";
import TopLevel from "./top.level.view.jsx";

class WithFooterView extends React.Component {
	render() {
		return (
			<TopLevel
				className={classNames( "has-footer", this.props.className )}
				id={this.props.id}
				style={this.props.style}
			>
				{ this.props.children }
			</TopLevel>
		);
	}
}

export default WithFooterView;
