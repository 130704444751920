import Rx from "rx";
import _ from "lodash";
import HookableRepository from "./hookable.js";

let keySize = 64; //TODO: calculate from config

class MasterKeyRepository extends HookableRepository {
	constructor( StorageType ) {
		super( StorageType, "master.dat" );
	}

	getKeyBufferSize( ) {
		return keySize;
	}

	readAsync( positionBit /*0 or 1*/ ) {
		return (
			this.lockStorageAsync(
				storage =>
					storage.readAtPositionAsync( keySize * positionBit, keySize )
						.map( buffer => buffer.length === keySize ? buffer : null )
			)
		);
	}

	writeAsync( positionBit, buffer ) {
		if ( buffer.length !== keySize ) {
			return Rx.Observable.throw( "Master Key buffer size invalid" );
		}
		if ( positionBit !== 0 && positionBit !== 1 ) {
			return Rx.Observable.throw( "PositionBit invalid" );
		}
		//TODO: making single lock can cause deadlock during backup
		//make sepparate queue to resolve this?
		return (
			this.lockStorageAsync( storage => storage.readAsBufferAsync() )
				.flatMap( existing => {
					let newExisting = new Buffer( 2 * keySize ).fill( 0 );
					existing.copy( newExisting );
					buffer.copy( newExisting, keySize * positionBit );
					return this.replaceDataAsync( newExisting );
				} )
		);
	}
}

export default MasterKeyRepository;
