import React from "react";

import history from "../../components/history.js";
import translate from "../../translations/translate.js";
import remoteServiceLocator from "../../../api/services/locators/remote.js";

import Autologout from "./autologout.jsx";

class CheckLoginView extends React.Component {
	constructor() {
		super();
		this.state = {};
		this._service = remoteServiceLocator();
		this._logout = this._logout.bind( this );
	}

	componentWillMount() {
		this._connectionStatusSubscription = (
			this._service.observeConnectionStatus()
				.distinctUntilChanged()
				.subscribe( isConnected => {
					this.onChangedConnectionStatus( isConnected );
				} )
		);
	}

	onChangedConnectionStatus( isConnected ) {
		this.setState( { show: isConnected === this.props.loginStatus } );
		if ( isConnected ) {
			this._service.startShowingNotifications();
		}
		if ( isConnected && !this._profileSubscription ) {
			this._profileSubscription = (
				this._service.observeProfile()
					.subscribe( ( { language } ) => {
						language && translate.setLanguage( [ language ] );
					} )
			);
		}
		if ( isConnected !== this.props.loginStatus ) {
			history.navigateTo( this.props.orGoto );
		}
	}

	_logout() {
		// history.navigateTo( this.props.orGoto );
		global.location.hash = "#" + this.props.orGoto;
		global.location.reload();
	}

	componentWillUnmount() {
		this._connectionStatusSubscription.dispose();
		this._profileSubscription && this._profileSubscription.dispose();
	}

	render() {
		if ( this.state.show ) {
			if ( this.props.loginStatus ) {
				//TODO: React.Fragment
				return (
					<div style={ { width: "100%", height: "100%" } }>
						<Autologout onTimeout={ this._logout } />
						{ this.props.children }
					</div>
				);
			}
			return this.props.children;
		}
		return null;
	}
}


class CheckLoginWrapperView extends React.Component {
	render() {
		return (
			<CheckLoginView {...this.props} key={ `${this.props.loginStatus}` } >
				{ React.Children.only( this.props.children ) }
			</CheckLoginView>
		);
	}

	static get propTypes() {
		return {
			loginStatus: React.PropTypes.bool.isRequired,
			orGoto: React.PropTypes.string.isRequired
		};
	}
}

export default CheckLoginWrapperView;
