import Rx from "rx";
import _ from "lodash";

import PromptParticipantsBase from "../common/prompt.participants.base.jsx";
import serviceLocator from "../../../api/services/locators/worker.client.js";

class ForwardMessageView extends PromptParticipantsBase {
  constructor() {
		super();
		this._service = serviceLocator();
		this.headerTextId = "forward.message.header";
		this.descriptionTextId = "forward.message.text";
	}

	onContinueClick() {
		let contactIds = this.state.selectedIds;
		let progressSubj = new Rx.BehaviorSubject( 0 );

    Rx.Observable.fromArray( contactIds )
      .flatMap( id => {
        let contact = _.find( this.state.contacts, { id } );
        if ( !contact ) {
          return Rx.Observable.empty();
        }
		    return this._service.forwardMessageAsync( contact, this.props.message );
      } )
      .toArray()
			.subscribe( () => {
				this.props.onBack();
        super.onContinueClick();
			} );
	}

	onBackPress() {
		this.props.onBack();
		super.onBackPress();
	}

	componentWillMount() {
    let contactPredicate = ( { status, multidescriptionId, id } ) => {
      return (
        ( status === "active" )
        &&
        !~multidescriptionId
        &&
        ( id !== this.props.contact.id )
      );
    };

    let groupPredicate = ( { status, multidescriptionId, id } ) => {
      return (
        ( status === "active" )
        &&
        ( id !== this.props.contact.id )
      );
    };

		this._contactsSubscription = (
      Rx.Observable.combineLatest(
        this._service.observeContactList(),
        this._service.observeGroupList(),
        ( contacts, groups ) => _.concat(
          _.filter( contacts, contactPredicate ),
          _.filter( groups, groupPredicate )
        )
      )
				.subscribe( contacts => {
					this.setState( { contacts } );
				} )
		);
	}

	componentWillUnmount() {
		this._contactsSubscription.dispose();
	}
}

export default ForwardMessageView;
