import _ from "lodash";

import PromptParticipantsBase from "../common/prompt.participants.base.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

class PromptContacts extends PromptParticipantsBase {
	constructor() {
		super();
		this.headerTextId = "sharedcontacts.add.contacts.header";
		this.descriptionTextId = "sharedcontacts.add.contacts.text";
		this._service = serviceLocator();
	}

	componentWillMount() {
		this._contactsSubscription = (
			this._service
				.observeContactList()
				.subscribe( contacts =>
					this.setState( {
						contacts: _.filter( contacts,
							( { status, multidescriptionId } ) =>
								( status === "active" )  && ( multidescriptionId === -1 )
						)
					} )
				)
		);
	}

	componentWillUnmount() {
		this._contactsSubscription.dispose();
	}
}

export default PromptContacts;
