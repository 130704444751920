import _ from "lodash";
import ClientServerBase from "./client.server.base.js";

class ClientServerSequential extends ClientServerBase {
	constructor( apiUrlBase, connectionId ) {
		super( apiUrlBase, connectionId );
		this._gotMessageHandler = this._gotMessageHandler.bind( this );

		this._on( "gotMessage", this._gotMessageHandler );
	}

	_gotMessageHandler( message ) {
		let { callId, listenerId } = message;
		if ( listenerId !== this._listenerId ) {
			return;
		}
		message = _.clone( message );
		if ( !callId ) {
			console.error( "Got message from server without callId" );
		}

		delete message.callId;
		if ( !this._onMessage ) {
			console.warn( "Got message without handler" );
			return;
		}
		this._onMessage( message );
	}

	onGotMessage( func ) {
		if ( this._onMessage ) {
			throw new Error( "onGotMessage double call" );
		}
		this._onMessage = func;
	}

	tryListen( fromIndex, cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}
		this._tryMakeCall( "listen", {
			listenerId: null,
			to: this._connectionId,
			fromIndex
		}, cb );
	}

	tryListenAndDelete( fromIndex, cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}
		this._tryMakeCall( "listenAndDelete", {
			listenerId: null,
			to: this._connectionId,
			fromIndex
		}, cb );
	}

	tryStopListen( cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}

		this._tryMakeCall( "stopListen", {
			listenerId: null,
			to: this._connectionId
		}, cb );
	}

	send( message, transaction, opOptions ) {
		if ( this._isDisposed ) {
			transaction.cancel();
			console.warn( "Disposed connection call send" );
			return;
		}
		if ( !transaction ) {
			throw new Error( "Transaction is required" );
		}
		this._initTransactionIfNeed( transaction );
		transaction.addMessage( this._apiUrlBase, message, opOptions );
	}

	check( transaction, opOptions ) {
		if ( this._isDisposed ) {
			transaction.cancel();
			console.warn( "Disposed connection call check" );
			return;
		}
		if ( !transaction ) {
			throw new Error( "Transaction is required" );
		}
		this._initTransactionIfNeed( transaction );
		transaction.addCheck( this._apiUrlBase, this._connectionId, opOptions );
	}

	tryGetMaxIndex( cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}

		this._tryMakeCall( "getMaxIndex", {
			to: this._connectionId
		}, cb );
	}

	dispose( ) {
		if ( !this._isDisposed ) {
			this._off( "gotMessage", this._gotMessageHandler );
		}
		super.dispose();
	}
}

export default ClientServerSequential;
