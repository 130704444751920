import React from "react";
import classNames from "classnames";

let ImageButton = ( { type, extension = "svg", ...props } ) => (
	<img {...props}
		src={`images/${type}.${extension}`}
		className={classNames( [ "image-button", type ] ) }
		/>
);

ImageButton.propTypes = {
	type: React.PropTypes.string.isRequired
};

export default ImageButton;
