import React from "react";

class CheckboxComponent extends React.Component {
	render() {
		return (
			<div
				{...this.props}
				className={ "switch-btn" + ( this.props.value ? " on " : " " ) + ( this.props.className || "" ) }
			/>
		);
	}
}

export default CheckboxComponent;
