import React from "react";
import { Modal, Button } from "react-bootstrap";
import Translation from "../../../components/translation.jsx";

class AddModalView extends React.Component {
	render() {
		return (
				<Modal.Dialog className="add-modal" dialogClassName="modal-sm">
					<Modal.Header>
						<Modal.Title>
							<Translation textId="web.contacts.add.modal.title" />
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<a href="javascript:;" onClick={this.props.onInvite} id="menu-invite">
							<img src="web/img/ico-50.svg" alt=""/>
							<Translation textId="web.contacts.add.invite.menuitem" />
						</a>
						<a href="javascript:;" onClick={this.props.onAccept} id="menu-accept">
							<img src="web/img/ico-51.svg" alt=""/>
							<Translation textId="web.contacts.add.accept.menuitem" />
						</a>
						<a href="javascript:;" onClick={this.props.onExternal} id="menu-external">
							<img src="web/img/ico-52.svg" alt=""/>
							<Translation textId="web.contacts.add.external.menuitem" />
						</a>
					</Modal.Body>

					<Modal.Footer>
						<Button onClick={ this.props.onCancel } bsClass="btn-transparent">
							<Translation textId="web.contacts.add.modal.cancel.button" />
						</Button>
					</Modal.Footer>
				</Modal.Dialog>
		);
	}
}

export default AddModalView;
