import Rx from "rx";
import React from "react";

import RegistrationHeader from "./header.jsx";
import RegistrationBody from "./body.jsx";
import RestoreView from "../restore/index.jsx";
import RejoinView from "../restore/rejoin.jsx";
import TopLevel from "../common/top.level.view.jsx";
import Translation from "../../components/translation.jsx";
import ArrowButton from "../../components/button.continue.jsx";
import FullScreen from "../../components/fullscreen.jsx";
import configuration from "../../../common/configuration.js";

class RegistrationView extends React.Component {
	render() {
		return (
			<TopLevel className="registration-page">
				<RegistrationHeader onBack={ this.props.onBack }/>
				<RegistrationBody requireInviteCode={ this.props.isPrivate }/>
			</TopLevel>
		);
	}
}

class RegistrationSelectionView extends React.Component {
	constructor() {
		super();
		this.state = {
			selected: "none"
		};
		this.onBack = this.onBack.bind( this );
		this.onPublicClick = this.onPublicClick.bind( this );
		this.onPrivateClick = this.onPrivateClick.bind( this );
		this.onRestoreClick = this.onRestoreClick.bind( this );
		this.gotoRejoin = this.gotoRejoin.bind( this );
		this.onDemoClick = this.onDemoClick.bind( this );
	}

	onBack() {
		this.setState( { selected: "none" } );
	}

	onPublicClick() {
		this.setState( { selected: "public" } );
	}

	onPrivateClick() {
		this.setState( { selected: "private" } );
	}

	onRestoreClick() {
		this.setState( { selected: "restore" } );
	}

	onDemoClick() {
		this.setState( { selected: "demo" } );
	}

	gotoRejoin() {
		this.setState( { selected: "rejoin" } );
	}

	renderDemoButton() {
		if ( configuration.getBuildMode() !== "demo" ) {
			return null;
		}
		return (
			<ArrowButton
				enabled={ true }
				caption="registration.selection.demo.button"
				handleClick={this.onDemoClick}
				id="demo"
			/>
		);
	}


	renderPublicButton() {
		if ( true ) {
			//No public build, demo only
			return null;
		}
		return (
			<ArrowButton
				enabled={ true }
				caption="registration.selection.public.button"
				handleClick={this.onPublicClick}
				id="public"
			/>
		);
	}

	renderPrivateButton() {
		if ( configuration.getBuildMode() !== "enterprise" ) {
			return null;
		}
		return (
			<ArrowButton
				enabled={ true }
				caption="registration.selection.private.button"
				handleClick={this.onPrivateClick}
				id="private"
			/>
		);
	}

	renderRestoreButton() {
		return (
			<ArrowButton
				enabled={ true }
				caption="registration.restore.button"
				handleClick={this.onRestoreClick}
				id="restore"
			/>
		);
	}

	renderSelect() {
		return (
			<TopLevel>
				<header>
					<Translation textId="registration.selection.header"/>
				</header>
				<main>
					<span className="small-text">
						<Translation textId="registration.selection.text" />
					</span>
					{ this.renderDemoButton() }
					{ this.renderPublicButton() }
					{ this.renderPrivateButton() }
					{ this.renderRestoreButton() }
				</main>
			</TopLevel>
		);
	}

	renderPublic() {
		return <RegistrationView onBack={ this.onBack } isPrivate={false} />;
	}

	renderDemo() {
		return <RegistrationView onBack={ this.onBack } isPrivate={false} />;
	}

	renderPrivate() {
		return <RegistrationView onBack={ this.onBack } isPrivate={true} />;
	}

	renderRestore() {
		return (
			<FullScreen>
				<RestoreView onBack={ this.onBack } onDone={this.gotoRejoin}/>
			</FullScreen>
		);
	}

	renderRejoin() {
		return (
			<FullScreen>
				<RejoinView/>
			</FullScreen>
		);
	}

	render() {
		switch( this.state.selected ) {
			case "none":
				return this.renderSelect();
			case "public":
				return this.renderPublic();
			case "private":
				return this.renderPrivate();
			case "restore":
				return this.renderRestore();
			case "rejoin":
				return this.renderRejoin();
			case "demo":
				return this.renderDemo();
			default:
				throw new Error( "Invalid selected state" );
		}
	}
}

export default RegistrationSelectionView;
