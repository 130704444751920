var base64 = {
	unescape: function unescape (str) {
	  return (str + Array(5 - str.length % 4)
	    .join('='))
	    .replace(/\-/g, '+')
	    .replace(/_/g, '/');
	},
	escape: function escape (str) {
	  return str.replace(/\+/g, '-')
	    .replace(/\//g, '_')
	    .replace(/=/g, '');
	},
	encode: function encode (str) {
		if ( !Buffer.isBuffer( str ) ) {
			throw new Error( "base64.encode requires a buffer. " + Object.prototype.toString.call( str ) + " received instead" );
		}
	  return this.escape(str.toString('base64'));
	},
	decode: function decode (str) {
	  return new Buffer(this.unescape(str), 'base64');
	},
	decodeIfNeed: function decode (str) { //TODO: remove
		return new Buffer(this.unescape(str || ''), 'base64');
  },
};

exports["default"] = base64;
module.exports = exports["default"];
