import React from "react";
import { ButtonToolbar, SplitButton, MenuItem, Dropdown } from "react-bootstrap";
import Translation from "../../../components/translation.jsx";

class ContactListMainTitleView extends React.Component {
	constructor() {
		super();
		this.sendContacts = this.sendContacts.bind( this );
		this.clearHistory = this.clearHistory.bind( this );
		this.invite = this.invite.bind( this );
		this.inviteExternal = this.inviteExternal.bind( this );
		this.acceptInvite = this.acceptInvite.bind( this );
		this.businessCards = this.businessCards.bind( this );
	}

	sendContacts() {
		this.props.onSendContacts();
	}

	clearHistory() {
		this.props.onClearHistory();
	}

	invite() {
		this.props.onInvite();
	}

	inviteExternal() {
		this.props.onInviteExternal();
	}

	acceptInvite() {
		this.props.onAcceptInvite();
	}

	businessCards() {
		this.props.onBusinessCards();
	}

	renderSendContacts() {
		let { contact, onSendContacts } = this.props;
		let showContactMenuItems = contact && ( contact.status === "active" ) && onSendContacts;
		if ( !showContactMenuItems ) {
			return null;
		}
		return (
			<MenuItem eventKey="1" onClick={this.sendContacts}>
				<Translation textId="web.contacts.menu.send.contacts" />
			</MenuItem>
		);
	}

	renderClearHistory() {
		let {contact, onClearHistory} = this.props;
		let showContactMenuItems = contact && ( contact.status === "active" ) && onClearHistory;
		if ( !showContactMenuItems ) {
			return null;
		}

		return (
			<MenuItem eventKey="2" onClick={this.props.onClearHistory}>
				<Translation textId="web.contacts.menu.clear.history" />
			</MenuItem>
		);
	}

	render() {
		let {contact} = this.props;
		return (
			<div className="navbar grow">
				<div id="navbar" className="navbar-collapse">
					<img
						className="hidden visible-xs-inline back-button"
						src="web/img/arrow2.svg"
						alt=""
						onClick={ this.props.onGoToList }
					/>
					<div className="name">
						{ contact ? contact.name : "" }
					</div>
					<ul
						className="nav navbar-nav navbar-right right"
						style={ { marginTop: 0 } }
					>
						<Dropdown id="contacts_dropdown" componentClass="li">
							<Dropdown.Toggle noCaret={true} useAnchor={true}>
								<img src="web/img/menu_2_moreoptions.svg" alt="" />
							</Dropdown.Toggle>
							<Dropdown.Menu className="dropdown-menu dropdown-nav-menu" bsClass="">
								{this.renderSendContacts()}
								{this.renderClearHistory()}
								<MenuItem eventKey="3" onClick={this.invite}>
									<Translation textId="web.contacts.menu.invite" />
								</MenuItem>
								<MenuItem eventKey="4" onClick={this.acceptInvite}>
									<Translation textId="web.contacts.menu.acceptInvite" />
								</MenuItem>
								<MenuItem eventKey="5" onClick={this.inviteExternal}>
									<Translation textId="web.contacts.menu.invite.external" />
								</MenuItem>
								<MenuItem eventKey="6" onClick={this.businessCards}>
									<Translation textId="web.contacts.menu.businessCards" />
								</MenuItem>
							</Dropdown.Menu>
						</Dropdown>
					</ul>
				</div>
			</div>
		);
	}

	static get propTypes() {
		return {
			onSendContacts: React.PropTypes.func,
			onClearHistory: React.PropTypes.func,
			onInvite: React.PropTypes.func,
			onGoToList: React.PropTypes.func,
			onAcceptInvite: React.PropTypes.func
		};
	}
}

export default ContactListMainTitleView;
