import React from "react";
import configuration from "../../../common/configuration.js";

const EVENTS = [
    "focus",
    "blur",
    "paste",
    "keydown",
    "keypress",
    "mousemove",
    "mouseup",
    "mouseover",
    "mouseout",
    "mousewheel",
    "click",
    "dblclick",
    "touchstart",
    "touchmove",
    "resize",
    "scroll",
    "zoom",
];

class Autologout extends React.Component {
    constructor( props ) {
        super( props );
        this._onActive = this._onActive.bind( this );
        this._onTimeout = this._onTimeout.bind( this );
        this._timeout = null;
        this._lastTimeoutSet = 0;
        EVENTS.forEach( ( eventName ) => {
            document.addEventListener( eventName, this._onActive );
        } );
        this._onActive();
        global.onTimeout = this._onTimeout;
        // console.log( "Mounting autologout" );
    }

    _onActive() {
      // console.log('Active');
      if ( +new Date - this._lastTimeoutSet < 1000 ) {
          return;
      }
      if ( this._timeout ) {
          clearTimeout( this._timeout );
      }
      this._lastTimeoutSet = +new Date;
      this._timeout = setTimeout( this._onTimeout, configuration.getWebTimeout() );
    }

    _onTimeout() {
        this._timeout = null;
        console.log( "Timeout!" );
        debugger;
        this.props.onTimeout();
    }

    componentWillUnmount() {
        if ( this._timeout ) {
            clearTimeout( this._timeout );
        }
        EVENTS.forEach( ( eventName ) => {
            document.removeEventListener( eventName, this._onActive );
        } );
    }

    render() {
        return null;
    }
}

export default Autologout;
