import _ from "lodash";
import Rx from "rx";
import translate from "../../browser/translations/translate.js";

class LongTaskService {
    constructor() {
        this._backgroundMode = _.get( global, "cordova.plugins.backgroundMode" );
        this._longTasks = [];
        this._runningSubject = new Rx.BehaviorSubject( false );
        if ( this._backgroundMode ) {
            this._backgroundMode.disable();
        }
    }

    runLongTask( description ) {
        let handle = {
            description,
            dispose: () => {
                let index = this._longTasks.indexOf( handle );
                if ( !~index ) {
                    return;
                }
                this._longTasks.splice( index, 1 );
                if ( this._longTasks.length > 0 ) {
                    return;
                }
                this._runningSubject.onNext( false );

                if ( _.get( global, "window.plugins.insomnia.allowSleepAgain" ) ) {
                  console.log("Running unlock");
                  window.plugins.insomnia.allowSleepAgain(() => {
                    console.log("Running unlock success");
                  }, err => {
                    console.log("Running unlock error", err);
                  });
                }

                if ( this._backgroundMode ) {
        		        this._backgroundMode.disable();
                }
            }
        };
        this._longTasks.push( handle );
        if ( this._backgroundMode && this._longTasks.length === 1 ) {
            this._runningSubject.onNext( true );
            //Run
            this._backgroundMode.setDefaults( {
      				title: translate( "notification.title" ),
      				text: translate( "notification.text" ),
      				icon: "ic_launcher"
      			} );
            this._backgroundMode.enable();
            this._updateText();
            if ( _.get( global, "window.plugins.insomnia.keepAwake" ) ) {
              console.log("Running lock");
              window.plugins.insomnia.keepAwake(() => {
                console.log("Running lock success");
              }, err => {
                console.log("Running lock error", err);
              });
            }
        }
        return handle;
    }

    observeRunningLongTask() {
        return this._runningSubject;
    }

    updateDescription( handle, description ) {
        let index = this._longTasks.indexOf( handle );
        if ( !~index ) {
            throw new Error( "Invalid handle" );
        }
        handle.description = description;
        this._updateText();
    }

    _updateText() {
        let text = _.map( this._longTasks, "description" ).join( ", " );
        if ( !this._backgroundMode ) {
            return;
        }

        this._backgroundMode.configure( {
            title: translate( "notification.title" ),
            text
        } );
    }
}

export default LongTaskService;
