"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _key = require("./key.js");

var _key2 = _interopRequireDefault(_key);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MasterKey = function () {
	function MasterKey() {
		_classCallCheck(this, MasterKey);

		this._queue = [];
		this._key = null;
		this._isUsingKey = false;
	}

	_createClass(MasterKey, [{
		key: "setKey",
		value: function setKey(key) {
			if (!(key instanceof _key2.default)) {
				throw new Error("invalid key type");
			}
			if (this._key) {
				throw new Error("Key already set");
			}
			this._key = key;
		}
	}, {
		key: "unuseKey",
		value: function unuseKey() {
			if (this._isUsingKey) {
				throw new Error("Cannot destroy key while using it");
			}
			delete this._key;
		}
	}, {
		key: "postponedKey",
		value: function postponedKey(func) {
			if (this._key && !this._isUsingKey) {
				this._isUsingKey = true;
				try {
					func(this._key);
				} finally {
					this._isUsingKey = false;
				}
				this._processsQueue();
				return;
			}
			this._queue.push(func);
		}
	}, {
		key: "_processsQueue",
		value: function _processsQueue() {
			while (this._queue.length) {
				this._isUsingKey = true;
				try {
					this._queue.shift()(this._key);
				} finally {
					this._isUsingKey = false;
				}
			}
		}
	}]);

	return MasterKey;
}();

exports.default = MasterKey;