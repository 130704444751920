import React from "react";

export default class Swipeable extends React.Component {
	constructor() {
		super();
		this.state = this.getDefaultState();
		this.touchStart = this.touchStart.bind( this );
		this.touchMove = this.touchMove.bind( this );
		this.touchEnd = this.touchEnd.bind( this );
		this.mouseDown = this.mouseDown.bind( this );
		this.mouseMove = this.mouseMove.bind( this );
		this.mouseUp = this.mouseUp.bind( this );
	}

	getDefaultState() {
		return {
			x: null,
			y: null,
			swiping: false,
			start: 0
		};
	}

	static get propTypes() {
		return {
			onSwiped: React.PropTypes.func,
			onSwiping: React.PropTypes.func,
			onSwipingUp: React.PropTypes.func,
			onSwipingRight: React.PropTypes.func,
			onSwipingDown: React.PropTypes.func,
			onSwipingLeft: React.PropTypes.func,
			onSwipedUp: React.PropTypes.func,
			onSwipedRight: React.PropTypes.func,
			onSwipedDown: React.PropTypes.func,
			onSwipedLeft: React.PropTypes.func,
			flickThreshold: React.PropTypes.number,
			delta: React.PropTypes.number
		};
	}

	static get getDefaultProps() {
		return {
			flickThreshold: 0.6,
			delta: 10
		};
	}

	calculateDiff( { clientX, clientY } ) {
		let deltaX = this.state.x - clientX;
		let deltaY = this.state.y - clientY;
		let absX = Math.abs( deltaX );
		let absY = Math.abs( deltaY );

		return { deltaX, deltaY, absX, absY };
	}

	handleStart( { clientX, clientY } ) {
		this.setState( {
			start: Date.now(),
			x: clientX,
			y: clientY,
			swiping: false
		} );
	}

	touchStart( { touches } ) {
		if ( touches.length > 1 ) {
			return;
		}
		this.handleStart( touches[ 0 ] );
	}

	mouseDown( ev ) {
		this.handleStart( ev );
	}

	handleMove( ev, { deltaX, deltaY, absX, absY } ) {
		let cancelPageSwipe = false;

		if ( absX < this.props.delta && absY < this.props.delta ) {
			return;
		}

		let { onSwiping, onSwipingLeft, onSwipingRight, onSwipingUp, onSwipingDown } = this.props;
		let { onSwipedLeft, onSwipedRight, onSwipedUp, onSwipedDown } = this.props;

		if ( onSwiping ) {
			onSwiping( ev, deltaX, deltaY, absX, absY );
		}
		if ( absX > absY ) {
			if ( deltaX > 0 ) {
				cancelPageSwipe = !!(onSwipingLeft || onSwipedLeft);
				if ( onSwipingLeft ) {
					onSwipingLeft( ev, absX );
				}
			} else {
				cancelPageSwipe = !!(onSwipingRight && onSwipedRight);
				if ( onSwipingRight ) {
					onSwipingRight( ev, absX );
				}
			}
		} else if ( deltaY > 0 ) {
			cancelPageSwipe = !!(onSwipingUp && onSwipedUp);
			if ( onSwipingUp ) {
				onSwipingUp( ev, absY );
			}
		} else {
			cancelPageSwipe = !!(onSwipingDown && onSwipedDown);
			if ( onSwipingDown ) {
				onSwipingDown( ev, absY );
			}
		}

		this.setState( { swiping: true } );

		if ( cancelPageSwipe ) {
			ev.preventDefault();
		}
	}

	touchMove( ev ) {
		if ( !this.state.x || !this.state.y || ev.touches.length > 1 ) {
			return;
		}
		let diff = this.calculateDiff( ev.changedTouches[ 0 ] );
		this.handleMove( ev, diff );
	}

	mouseMove( ev ) {
		if ( !this.state.x || !this.state.y ) {
			return;
		}
		let diff = this.calculateDiff( ev );
		this.handleMove( ev, diff );
	}

	handleEnd( ev, { deltaX, deltaY, absX, absY } ) {
		let time = Date.now() - this.state.start;
		let velocity = Math.sqrt( absX * absX + absY * absY ) / time;
		let isFlick = velocity > this.props.flickThreshold;

		let { onSwiped, onSwipedLeft, onSwipedRight, onSwipedUp, onSwipedDown } = this.props;

		if ( onSwiped ) {
			onSwiped( ev, deltaX, deltaY, isFlick );
		}

		if ( absX > absY ) {
			if ( deltaX > 0 ) {
				if ( onSwipedLeft ) {
					onSwipedLeft( ev, deltaX, isFlick );
				}
			} else if ( onSwipedRight ) {
				onSwipedRight( ev, deltaX, isFlick );
			}
		} else if ( deltaY > 0 ) {
			if ( onSwipedUp ) {
				onSwipedUp( ev, deltaY, isFlick );
			}
		} else if ( onSwipedDown ) {
			onSwipedDown( ev, deltaY, isFlick );
		}
		this.setState( this.getDefaultState() );
	}

	touchEnd( ev ) {
		if ( !this.state.swiping ) {
			this.setState( this.getDefaultState() );
			return;
		}
		let diff = this.calculateDiff( ev.changedTouches[ 0 ] );
		this.handleEnd( ev, diff );
	}

	mouseUp( ev ) {
		if ( !this.state.swiping ) {
			this.setState( this.getDefaultState() );
			return;
		}
		let diff = this.calculateDiff( ev );
		this.handleEnd( ev, diff );
	}

	render() {
		let { children, onSwiped, onSwipedLeft, onSwipedRight, onSwipedUp,
			onSwipedDown, onSwiping, onSwipingLeft, onSwipingRight, onSwipingUp,
			onSwipingDown, ...props } = this.props;
		return (
			<div {...props}
				onTouchStart={this.touchStart}
				onTouchMove={this.touchMove}
				onTouchEnd={this.touchEnd}
				onMouseDown={this.mouseDown}
				onMouseMove={this.mouseMove}
				onMouseUp={this.mouseUp}>
				{children}
			</div>
		);
	}
}
