import Rx from "rx";
import ssgCrypto, {KEY_KINDS} from "ssg.crypto";

import {
	deserializeObjectWithKeysFromManagedBufferAsync
} from "../../common/serializer.js";
import {connectKeyMap} from "../../api/services/remote.js";
import configuration from "../../common/configuration.js";

//THIS IS ONE-TIME
class RemoteInterwindowExchangeClient {
  constructor( onNewConnectData ) {
    if ( !window.opener ) {
      throw new Error( "No window opener. Cannot create interwindow exchange client" );
    }
    this._econfig = configuration.getDefaultEncryptionConfig();
    this._onNewConnectData = onNewConnectData;

    this.receiveMessage = this.receiveMessage.bind( this );
    this._isProcessed = false;
    window.addEventListener( "message", this.receiveMessage, false );
    window.opener.postMessage( "", window.location.origin );
  }

  receiveMessage( event ) {
    if ( window.location.origin !== event.origin ) {
      console.warn( "Origin mismatch" );
      return;
    }
		if ( !( typeof event.data === "string" ) ) {
			console.warn( "Got strange message", event.data );
			return;
		}
    if ( !event.data ) {
      console.warn( "Empty data" );
      return;
    }

    if ( this._isProcessed ) {
      console.warn( "Already processed" );
      return;
    }

    this._isProcessed = true;

    let buffer = new Buffer( event.data, "base64" );
    let mb = ssgCrypto.createManagedBuffer( buffer.length );
    mb.useAsBuffer( b => buffer.copy( b ) );

    deserializeObjectWithKeysFromManagedBufferAsync( mb, connectKeyMap, this._econfig )
      .subscribe( params => {
        this._onNewConnectData( params );
      } );
  }
}

export default RemoteInterwindowExchangeClient;
