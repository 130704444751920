"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _keyKind = require("./key.kind.js");

var _keyKind2 = _interopRequireDefault(_keyKind);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Key = function () {
	function Key(container, size, kind, configHashString, masterKey) {
		_classCallCheck(this, Key);

		if (!_keyKind2.default.contains(kind)) {
			throw new Error("Key kind is required");
		}

		if (!(typeof configHashString === "string")) {
			throw new Error("configHashString required");
		}

		if (masterKey) {
			this._managedBuffer = container.allocateEncrypted(size, new Promise(function (resolve) {
				return masterKey.postponedKey(function (key) {
					return key.postponeManagedBuffer(resolve);
				});
			}));
			this._masterKey = masterKey;
		} else {
			this._managedBuffer = container.allocateXored(size);
		}
		this._pendinCallbackCount = 0;
		this._isDisposed = false;
		this._pendingKeyUses = [];
		this._disposeRequested = false;
		this._kind = kind;
		this._configHashString = configHashString;
	}

	_createClass(Key, [{
		key: "isConsistentTo",
		value: function isConsistentTo(key) {
			return this._configHashString === key._configHashString;
		}
	}, {
		key: "postponeManagedBuffer",
		value: function postponeManagedBuffer(func, useHandle) {
			var _this = this;

			if (useHandle && !~this._pendingKeyUses.indexOf(useHandle)) {
				debugger;
				throw new Error("Invalid useHandle");
			}
			if (this._isDisposed) {
				debugger;
				throw new Error("Key disposed");
			}
			if (!this._masterKey) {
				func(this._managedBuffer);
				return;
			}
			this._pendinCallbackCount++;
			this._masterKey.postponedKey(function (masterKeyManaged) {
				if (_this._isDisposed) {
					throw new Error("Cannot call postponedKey callback as Key is disposed!");
				}
				_this._pendinCallbackCount--;
				func(_this._managedBuffer);
			});
		}
	}, {
		key: "_dispose",
		value: function _dispose() {
			if (this._pendinCallbackCount) {
				throw new Error("Cannot dispose key as there is callback pending");
			}
			this._managedBuffer.dispose();
			delete this._managedBuffer;
			this._isDisposed = true;
			this._disposeRequested = false;
		}
	}, {
		key: "dispose",
		value: function dispose() {
			if (this._isDisposed || this._disposeRequested) {
				throw new Error("Double dispose");
			}
			if (this._pendingKeyUses.length) {
				this._disposeRequested = true;
				return;
			}
			this._dispose();
		}
	}, {
		key: "isDisposed",
		value: function isDisposed() {
			return this._isDisposed;
		}
	}, {
		key: "addKeyUse",
		value: function addKeyUse(reason) {
			// console.log( `add use key: ${reason}` );
			if (this._isDisposed) {
				debugger;
				throw new Error("Key disposed");
			}
			var useHandle = { reason: reason };
			this._pendingKeyUses.push(useHandle);
			return useHandle;
		}
	}, {
		key: "removeKeyUse",
		value: function removeKeyUse(useHandle) {
			//TODO: call in finally-like code block. Now it is called in tap because finally works not as expected
			if (this._isDisposed) {
				debugger;
				throw new Error("Key disposed");
			}
			var index = this._pendingKeyUses.indexOf(useHandle);
			if (!~index) {
				throw new Error("Invalid key useHandle");
			}
			// console.log( `remove use key: ${useHandle.reason}` );
			this._pendingKeyUses.splice(index, 1);
			if (this._disposeRequested && !this._pendingKeyUses.length) {
				// console.log( "...disposing" );
				this._dispose();
			}
		}
	}, {
		key: "kind",
		get: function get() {
			return this._kind;
		}
	}, {
		key: "byteLength",
		get: function get() {
			if (this._isDisposed) {
				debugger;
				throw new Error("Key disposed");
			}

			if (!this._managedBuffer) {
				debugger;
			}
			return this._managedBuffer.byteLength;
		}
	}]);

	return Key;
}();

exports.default = Key;