import React from "react";
import Rx from "rx";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";

import Translation from "../../../components/translation.jsx";
import PromptContacts from "../common/prompt.contacts.jsx";
import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class ForwardMessageModal extends React.Component {
  constructor() {
    super();
    this.onConfirm = this.onConfirm.bind( this );
    this.onCancel = this.onCancel.bind( this );
    this.onChange = this.onChange.bind( this );
    this.state = {
      selectedIds: [],
      contacts: []
    };
    this._service = remoteServiceLocator();
  }

  componentWillMount() {
    let contactPredicate = ( { status, multidescriptionId, id } ) => {
      return (
        ( status === "active" )
        &&
        !~multidescriptionId
        &&
        ( id !== this.props.contact.id )
      );
    };

    let groupPredicate = ( { status, multidescriptionId, id } ) => {
      return (
        ( status === "active" )
        &&
        ( id !== this.props.contact.id )
      );
    };

    this._contactsSubscription = (
      Rx.Observable.combineLatest(
        this._service.observeContactList(),
        this._service.observeGroupList(),
        ( contacts, groups ) => _.concat(
          _.filter( contacts, contactPredicate ),
          _.filter( groups, groupPredicate )
        )
      )
      .subscribe( contacts => {
        this.setState( { contacts } );
      } )
    );
  }

  onChange( selectedIds ) {
		this.setState( { selectedIds } );
	}

  onConfirm() {
    let contactIds = this.state.selectedIds;
		let progressSubj = new Rx.BehaviorSubject( 0 );

    Rx.Observable.fromArray( contactIds )
      .flatMap( id => {
        let contact = _.find( this.state.contacts, { id } );
        if ( !contact ) {
          return Rx.Observable.empty();
        }
		    return this._service.forwardMessageAsync( contact, this.props.message );
      } )
      .toArray()
			.subscribe( () => {
				this.props.onBack();
			} );
  }

  onCancel() {
    this.props.onBack();
  }

  render() {
    return (
			<div className="static-modal">
				<Modal.Dialog>
					<Modal.Header>
						<Modal.Title>
							<Translation textId="forward.message.header" />
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
            <Translation textId="forward.message.text" />

            <PromptContacts
              style={ { maxHeight: "calc(100vh - 225px)" } }
  						onChange={ this.onChange }
  						selectedIds={ this.state.selectedIds }
  						contacts={ this.state.contacts }
  					/>

					</Modal.Body>

					<Modal.Footer>
						<Button onClick={ this.onConfirm }>
							<Translation textId="forward.message.button.confirm" />
						</Button>
						<Button bsStyle="primary" onClick={ this.onCancel }>
							<Translation textId="web.common.confirm.button.no" />
						</Button>
					</Modal.Footer>
				</Modal.Dialog>
			</div>
		);
  }
}

export default ForwardMessageModal;
