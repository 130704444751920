import React from "react";
import ReactDOM from "react-dom";
import history from "../../../components/history.js";
import Dropdown from "../../../components/dropdown.jsx";
import FileSelect from "../../../components/file.select.jsx";
import MessageModel from "../../../../api/models/message/message.js";
import MessageView from "./message.jsx";
import MessageAddressesView from "./message.addresses.jsx";
import MessageSender from "../../../../api/models/message/message.sender.js";
import MessageSerializer from "../../../../api/models/message/message.serializer.js";

let ttlDropdownItems = [
	{ id: "5m", seconds: 5 * 60, text: "5 minutes" },
	{ id: "10m", seconds: 10 * 60, text: "10 minutes" },
	{ id: "30m", seconds: 30 * 60, text: "30 minutes" },
	{ id: "1h", seconds: 60 * 60, text: "1 hour" },
	{ id: "2h", seconds: 2 * 3600, text: "2 hours" },
	{ id: "5h", seconds: 5 * 3600, text: "5 hours" },
	{ id: "10h", seconds: 10 * 3600, text: "10 hours" },
	{ id: "1d", seconds: 24 * 3600, text: "1 day" },
	{ id: "2d", seconds: 2 * 24 * 3600, text: "2 days" },
	{ id: "7d", seconds: 7 * 24 * 3600, text: "7 days" },
	{ id: "15d", seconds: 15 * 24 * 3600, text: "15 days" },
	{ id: "30d", seconds: 30 * 24 * 3600, text: "30 days" },
	{ id: "60d", seconds: 60 * 24 * 3600, text: "60 days" },
	{ id: "180d", seconds: 180 * 24 * 3600, text: "180 days" },
	{ id: "365d", seconds: 365 * 24 * 3600, text: "365 days" }
];

class CreateMessageView extends React.Component {
	constructor() {
		super();
		this.state = {
			ttlSelectedItem: ttlDropdownItems[ 0 ],
			mainMessage: new MessageModel(),
			fakeMessage: null
		};
		this.onSendClick = this.onSendClick.bind( this );
		this.toggleFakeMessage = this.toggleFakeMessage.bind( this );
	}
	componentWillMount() {
		//history.clearTheBar();
	}
	onTTLChange( newTTLItem ) {
		this.setState( { ttlSelectedItem: newTTLItem } );
	}
	toggleFakeMessage() {
		this.setState( { fakeMessage: this.state.fakeMessage ? null : new MessageModel() } );
	}
	renderFakeMessageConditionaly() {
		if ( this.state.fakeMessage === null ) {
			return null;
		}
		return (
			<span>
				<MessageView message={ this.state.fakeMessage } />,
				"Password: ",
				<input type="password" ref="fakePassword" />
			</span> );
	}
	onSendClick() {
		new MessageSender( {
			mainBinarySource: new MessageSerializer( this.state.mainMessage, 16 ).toBinarySource(),
			mainPassword: ReactDOM.findDOMNode( this.refs.mainPassword ).value || "",
			fakeBinarySource: this.state.fakeMessage && new MessageSerializer( this.state.fakeMessage, 16 ).toBinarySource(),
			fakePassword: this.refs.fakePassword && ReactDOM.findDOMNode( this.refs.fakePassword ).value || "",
			isSingleRead: ReactDOM.findDOMNode( this.refs.chkSingleRead ).checked,
			expirationTS: 1000000 + +new Date,
			algorithm: "aes-256-cbc"
		} )
			.getCompletition()
			.subscribe(
					result => {
						this.setState( { addresses: result.addresses } );
					},
					error => console.warn( "message send error", error )
				);
	}
	render() {
		return (
			<div>
				<h1>Create new message!&&&??</h1>
				<br />
				Time to live: <br />
				<Dropdown
					items={ ttlDropdownItems }
					selectedItem={ this.state.ttlSelectedItem }
					onChange={ this.onTTLChange }
				/> <br />
				<input type="checkbox" defaultChecked={ false } ref="chkSingleRead" /> Single read ?
				<br />
				<MessageView message={ this.state.mainMessage } /> <br />
				Password: <input type="password" ref="mainPassword" />
				<br />
				<input type="checkbox" onChange={ this.toggleFakeMessage } value={ !!this.state.fakeMessage } />
				Add Fake Message
				{ this.renderFakeMessageConditionaly() }
				<br />
				<button handleClick={ this.onSendClick }>Create!</button>
				<br />
				{
					this.state.addresses ? <MessageAddressesView addresses={ this.state.addresses } /> : null
				}
			</div>
			);
	}
}

export default CreateMessageView;
