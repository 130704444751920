import Rx from "rx";

export default {
	doubleCallbackToObservable: ( func, context ) => {
		if ( !func ) {
			debugger;
			return Rx.Observable.throw( new Error( "func parameter required" ) );
		}
		return ( ...args ) => Rx.Observable.create( ( observer ) => {
			func.call(
				context,
				...args,
				result => {
					observer.onNext( result );
					observer.onCompleted();
				},
				error => {
					let p = { func, context, args };
					observer.onError( error );
				}
			);
		} );
	},
	fromFileReader: ( reader ) => {
		return Rx.Observable.create( ( observer ) => {
			if ( reader.onerror ) {
				return observer.onError( new Error( "onerror must not be set" ) );
			}
			if ( reader.onload ) {
				return observer.onError( new Error( "onload must not be set" ) );
			}
			reader.onerror = ( error ) => {
				reader.onerror = null;
				reader.onload = null;
				observer.onError( error );
			};

			reader.onload = ( event ) => {
				reader.onerror = null;
				reader.onload = null;
				observer.onNext( reader.result );
				observer.onCompleted();
			};
		} );
	},
	fromFileWriter: ( writer ) => {
		return Rx.Observable.create( ( observer ) => {
			if ( writer.onerror ) {
				return observer.onError( new Error( "onerror must not be set" ) );
			}
			if ( writer.onwrite ) {
				return observer.onError( new Error( "onwrite must not be set" ) );
			}
			writer.onerror = ( error ) => {
				writer.onerror = null;
				writer.onwrite = null;
				observer.onError( error );
			};
			writer.onwrite = ( event ) => {
				writer.onerror = null;
				writer.onwrite = null;
				observer.onNext( event );
				observer.onCompleted();
			};
		} );
	}
};
