"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _tweetnacl = require("tweetnacl");

var _tweetnacl2 = _interopRequireDefault(_tweetnacl);

var _key = require("../primitives/key.js");

var _key2 = _interopRequireDefault(_key);

var _keyKind = require("../primitives/key.kind.js");

var _keyKind2 = _interopRequireDefault(_keyKind);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

//TODO: use own pseudoRandom generator

var DHed25519 = function () {
    function DHed25519(container, masterKey, param, configHashString) {
        _classCallCheck(this, DHed25519);

        if (!(typeof configHashString === "string")) {
            throw new Error("configHashString must be a string");
        }

        this._container = container;
        this._masterKey = masterKey;
        this._configHashString = configHashString;
    }

    _createClass(DHed25519, [{
        key: "createRandomKeyPairThen",
        value: function createRandomKeyPairThen() {
            var privKey = new _key2.default(this._container, 32, //length
            _keyKind2.default.KEY_EXCHANGE_PRIVATE, this._configHashString, this._masterKey);

            var pubKey = new _key2.default(this._container, 32, //length
            _keyKind2.default.KEY_EXCHANGE_PUBLIC, this._configHashString, this._masterKey);

            var _nacl$box$keyPair = _tweetnacl2.default.box.keyPair(),
                publicKey = _nacl$box$keyPair.publicKey,
                secretKey = _nacl$box$keyPair.secretKey;

            return Promise.all([new Promise(function (resolve) {
                privKey.postponeManagedBuffer(function (privMB) {
                    privMB.useAsBuffer(function (privBuffer) {
                        new Buffer(secretKey).copy(privBuffer);
                        resolve();
                    });
                });
            }), new Promise(function (resolve) {
                pubKey.postponeManagedBuffer(function (pubMB) {
                    pubMB.useAsBuffer(function (pubBuffer) {
                        new Buffer(publicKey).copy(pubBuffer);
                        resolve();
                    });
                });
            })]).then(function () {
                return { publicKey: pubKey, privateKey: privKey };
            });
        }
    }, {
        key: "createPublicKeyThen",
        value: function createPublicKeyThen(privateKey) {
            var pubKey = new _key2.default(this._container, 32, //length
            _keyKind2.default.KEY_EXCHANGE_PUBLIC, this._configHashString, this._masterKey);

            return new Promise(function (resolve) {
                privateKey.postponeManagedBuffer(function (privMB) {
                    privMB.useAsBuffer(function (privB) {
                        var publicKeyBuffer = _tweetnacl2.default.box.keyPair.fromSecretKey(privB).publicKey;
                        pubKey.postponeManagedBuffer(function (pubMB) {
                            pubMB.useAsBuffer(function (pubB) {
                                new Buffer(publicKeyBuffer).copy(pubB);
                            });
                            resolve(pubKey);
                        });
                    });
                });
            });
        }
    }, {
        key: "createSharedKeyThen",
        value: function createSharedKeyThen(privateKey, publicKey) {
            var _this = this;

            var privHandle = privateKey.addKeyUse("dh25519 shared key calc");
            var pubHandle = publicKey.addKeyUse("dh25519 shared key calc");
            return new Promise(function (resolve) {
                privateKey.postponeManagedBuffer(function (privMB) {
                    privMB.useAsBuffer(function (privB) {
                        var privBCopy = Buffer.concat([privB]);
                        publicKey.postponeManagedBuffer(function (pubMB) {
                            pubMB.useAsBuffer(function (pubB) {
                                var res = _tweetnacl2.default.box.before(pubB, privBCopy);
                                var resKey = new _key2.default(_this._container, res.length, _keyKind2.default.INTERMEDIATE, _this._configHashString, _this._masterKey);
                                resKey.postponeManagedBuffer(function (resMB) {
                                    resMB.useAsBuffer(function (resBuffer) {
                                        new Buffer(res).copy(resBuffer);
                                    });
                                    privateKey.removeKeyUse(privHandle);
                                    publicKey.removeKeyUse(pubHandle);
                                    resolve(resKey);
                                });
                            });
                        });
                    });
                });
            });
        }
    }], [{
        key: "getPrivateKeyLength",
        value: function getPrivateKeyLength() {
            return _tweetnacl2.default.box.publicKeyLength;
        }
    }, {
        key: "getPublicKeyLength",
        value: function getPublicKeyLength() {
            return _tweetnacl2.default.box.secretKeyLength;
        }
    }]);

    return DHed25519;
}();

exports.default = DHed25519;