import React from "react";
import _ from "lodash";

class MobileDigitsView extends React.Component {
	handleKey( key ) {
		this.context.focusManager.handleKey( key );
	}

	renderRowContent( symbols ) {
		return _.map(
			symbols.split( "" ),
			symbol => (
				<div
					key={ symbol }
					className="key one"
					onClick={ this.handleKey.bind( this, symbol ) }
				>
					{symbol}
				</div>
			)
		);
	}
	render() {
		let row1 = "1234567890";
		let row2 = "-/:;()@$&";
		let row3 = ",?!'\"";
		return (
			<div className="mobile-keyboard visible-xs">
				<section className="key-row light">
					{this.renderRowContent( row1 )}
				</section>
				<section className="key-row dark">
					{this.renderRowContent( row2 )}
				</section>
				<section className="key-row light">
					<div
						className="key one icon"
						onClick={this.props.onChangeMode.bind( this, "symbols" )}
					>
						#+=
					</div>
					{this.renderRowContent( row3 )}
					<div
						className="key one icon"
						onClick={ this.handleKey.bind( this, "Backspace" ) }
					>
						<img src="web/img/back.svg" alt="" />
					</div>
				</section>
				<section className="key-row">
					<div
						className="key two"
						onClick={this.props.onChangeMode.bind( this, "alpha" )}
					>
						ABC
					</div>
					<div
						className="key three icon"
						onClick={ this.handleKey.bind( this, " " ) }
					>
						пробіл
					</div>
					<div
						className="key one"
						onClick={ this.handleKey.bind( this, "." ) }
					>
						.
					</div>
					<div
						className="key two enter"
					>
						далі
					</div>
				</section>
			</div>
		);
	}

	static get contextTypes() {
		return { focusManager: React.PropTypes.object.isRequired };
	}
}

export default MobileDigitsView;
