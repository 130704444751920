import React from "react";
import _ from "lodash";

import Layout, {
	ListTitleContentView,
	MainTitleContentView,
	ListbarContentView,
	MainContentView,
	MobileMenuContentView,
	CustomContentView
} from "../common/layout.jsx";
import MobileMenu from "../common/mobile.menu.jsx";
import MainTitle from "../chats/main.title.jsx";
import MainContent from "./main.content.jsx";
import AddContent from "./add.content.jsx";
import ListTitle from "./list.title.jsx";
import ContactListListBar from "../common/contact.list.jsx";
import AddContacts from "./add.contacts.jsx";
import Workgroup from "./workgroup.jsx";
import Rights from "./rights.jsx";
import Prompt from "../common/prompt.jsx";
import Confirm from "../common/confirm.jsx";
import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class SharedContactsView extends React.Component {
	constructor() {
		super();
		this.state = {
			mainView: "contact",
			selectedContact: null,
			activeMobileView: "list",
			viewCounter: 0,
			searchString: null
		};
		this._service = remoteServiceLocator();
		this.selectContact = this.selectContact.bind( this );
		this.onCreateSharedContacts = this.onCreateSharedContacts.bind( this );
		this.onDoneAdd = this.onDoneAdd.bind( this );
		this.onDoneRights = this.onDoneRights.bind( this );
		this.onDoneAddContacts = this.onDoneAddContacts.bind( this );
		this.onGoToList = this.onGoToList.bind( this );
		this.onMultidescriptionExit = this.onMultidescriptionExit.bind( this );
		this.onMultidescriptionWorkgroup = this.onMultidescriptionWorkgroup.bind( this );
		this.onMultidescriptionRename = this.onMultidescriptionRename.bind( this );
		this.onMultidescriptionRights = this.onMultidescriptionRights.bind( this );
		this.onAddContacts = this.onAddContacts.bind( this );
		this.rename = this.rename.bind( this );
		this.delete = this.delete.bind( this );
		this.clearHistory = this.clearHistory.bind( this );
		this.clearRemoteHistory = this.clearRemoteHistory.bind( this );
		this.onSetSearchString = this.onSetSearchString.bind( this );
	}

	componentWillMount() {
		this._contactsSubscription = (
			this._service.observeSharedContacts()
			.subscribe( sharedcontacts => {
				this.setState( {sharedcontacts} );
			} )
		);
	}

	componentWillUnmount() {
		this._contactsSubscription.dispose();
	}

	clearHistory() {
		this.state.selectedContact && this._service.clearHistoryAsync( this.state.selectedContact ).subscribe();
	}

	clearRemoteHistory() {
		this.state.selectedContact && this._service.clearRemoteHistoryAsync( this.state.selectedContact ).subscribe();
	}

	getSelectedContact() {
		let {selectedContact, sharedcontacts} = this.state;
		if ( selectedContact
			&& !_.find(
				sharedcontacts,
				({sharedList}) => !!_.find( sharedList, {id: selectedContact.id} )
			)
		) {
			selectedContact = null;
		}
		if ( selectedContact && ( selectedContact.type !== "normal" ) ) {
			selectedContact = null;
		}
		return selectedContact;
	}

	getSelectedContactId() {
		let selectedContact = this.getSelectedContact();
		let contactId = selectedContact && selectedContact.id;
		return contactId;
	}

	getSelectedMultidescription() {
		let {selectedContact, sharedcontacts} = this.state;
		if ( selectedContact && !_.find( sharedcontacts, {id: selectedContact.id } ) ) {
			selectedContact = null;
		}
		if ( selectedContact && ( selectedContact.type !== "multidescription" ) ) {
			selectedContact = null;
		}
		return selectedContact;
	}

	backFromCreate( name, contactIds ) {
		this._service.createGroupAsync( name, contactIds )
			.subscribe( selectedContact => {
				this.setState( {
					mainView: "contact",
					selectedContact
				} );
			} );
	}

	onDoneAdd( multidescriptionId ) {
		this.setState( {
			activeMobileView: "list",
			mainView: "contact"
		} );
	}

	onDoneWorkgroup() {
		this.setState( {
			activeMobileView: "list",
			mainView: "contact",
			selectedContact: null
		} );
	}

	onDoneRights() {
		this.setState( {
			activeMobileView: "list",
			mainView: "contact",
			selectedContact: null
		} );
	}

	onDoneAddContacts() {
		this.setState( {
			activeMobileView: "list",
			mainView: "contact",
			selectedContact: null
		} );
	}

	renderMainTitle() {
		return <MainTitle
			contact={this.getSelectedContact() || this.getSelectedMultidescription()}
			onClearHistory={this.clearHistory}
			onClearRemoteHistory={this.clearRemoteHistory}
			onGoToList={this.onGoToList}
		/>;
	}

	renderContent() {
		switch( this.state.mainView ) {
			case "contact":
				return <MainContent
					contact={this.getSelectedContact()}
				/>;
			case "add":
				return <AddContent
					onDone={this.onDoneAdd}
					onBack={this.onDoneAdd}
					key={this.state.viewCounter}
				/>;
			case "workgroup":
				return <Workgroup
					contact={this.getSelectedMultidescription()}
					key={this.state.viewCounter}
					onDone={this.onDoneWorkgroup}
					onBack={this.onDoneWorkgroup}
				/>;
			case "rights":
				return <Rights
					contact={this.getSelectedMultidescription()}
					key={this.state.viewCounter}
					onDone={this.onDoneRights}
					onBack={this.onDoneRights}
				/>;
			case "addContacts":
				return <AddContacts
					contact={this.getSelectedMultidescription()}
					key={this.state.viewCounter}
					onDone={this.onDoneAddContacts}
					onBack={this.onDoneAddContacts}
				/>;
		}
		throw new Error( `Invalid main view: ${this.state.mainView}` );
	}

	renderPopup() {
		if ( this.state.popup ) {
			return this.state.popup();
		}
		return null;
	}

	selectContact( selectedContact ) {
		this.setState( {
			mainView: "contact",
			activeMobileView: "main",
			selectedContact
		} );
	}

	onCreateSharedContacts() {
		this.setState( {
			mainView: "add",
			activeMobileView: "main",
			viewCounter: this.state.viewCounter + 1
		} );
	}

	onGoToList() {
		this.setState( {
			activeMobileView: "list"
		} );
	}

	onMultidescriptionExit( contact ) {
		this.setState( {
			popup: () =>
			<Confirm
				titleTextId="web.multidescription.exit.title"
				yesButtonTextId="web.multidescription.exit.button.yes"
				onDone={ this.doExitMultidescription.bind( this, contact ) }
			/>
		} );
	}

	doExitMultidescription( contact, isConfirmed ) {
		if ( !isConfirmed ) {
			this.setState( { popup: null } );
			return;
		}
		if ( this.state.inProgress ) {
			return;
		}
		this.setState( { inProgress: true } );
		this._service.exitMultidescriptionAsync( contact )
			.subscribe( () => {
				this.setState( { popup: null, inProgress: false } );
			} );
	}

	onMultidescriptionWorkgroup( contact ) {
		this.setState( {
			mainView: "workgroup",
			activeMobileView: "main",
			selectedContact: contact
		} );
	}

	onMultidescriptionRename( contact ) {
		this.setState( {
			popup: () =>
				<Prompt
					titleTextId="web.multidescription.rename.title"
					defaultValue={ contact.name }
					onDone={ this.doRenameMultidescription.bind( this, contact ) }
				/>
		} );
	}

	onAddContacts( contact ) {
		this.setState( {
			mainView: "addContacts",
			activeMobileView: "main",
			selectedContact: contact,
			viewCounter: this.state.viewCounter + 1
		} );
	}

	doRenameMultidescription( contact, newName ) {
		if ( !newName || ( contact.name === newName ) ) {
			this.setState( { popup: null } );
			return;
		}
		this._service.renameContactAsync( contact, newName )
			.subscribe( () => {
				this.setState( { popup: null } );
			} );
	}

	onMultidescriptionRights( contact ) {
		this.setState( {
			mainView: "rights",
			activeMobileView: "main",
			selectedContact: contact
		} );
	}

	doRename( contact, newName ) {
		if ( !newName ) {
			this.setState( { popup: null } );
			return;
		}
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( { isInProgress: true } );
		this._service.renameContactAsync( contact, newName )
			.subscribe( () => {
				this.setState( { popup: null, isInProgress: false } );
			}, error => {
				this.setState( { popup: null, isInProgress: false } );
				alert( error );
			} );
	}

	doDelete( contact, isConfirmed ) {
		if ( !isConfirmed ) {
			this.setState( { popup: null } );
			return;
		}
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( { isInProgress: true } );
		this._service.deleteContactAsync( contact )
			.subscribe( () => {
				this.setState( { popup: null, isInProgress: false } );
			}, error => {
				this.setState( { popup: null, isInProgress: false } );
				alert( error );
			} );
	}

	rename( contact ) {
		this.setState( {
			popup: () =>
				<Prompt
					titleTextId="web.contact.rename.title"
					defaultValue={ contact.name }
					onDone={ this.doRename.bind( this, contact ) }
				/>
		} );
	}

	delete( contact ) {
		this.setState( {
			popup: () =>
				<Confirm
					titleTextId="web.contact.delete.title"
					yesButtonTextId="web.contact.delete.button.yes"
					onDone={ this.doDelete.bind( this, contact ) }
				/>
		} );
	}

	onSetSearchString( searchString ) {
		this.setState( { searchString } );
	}

	getFilteredContacts() {
		let { searchString, sharedcontacts } = this.state;
		if ( !searchString ) {
			return sharedcontacts;
		}
		let low = searchString.toLowerCase();
		return _.map(
			sharedcontacts, multi => {
				if ( ~multi.name.toLowerCase().indexOf( low ) ) {
					return multi;
				}
				return {
					...multi,
					sharedList: _.filter(
						multi.sharedList,
						( { name } ) => ~name.toLowerCase().indexOf( low )
					)
				};
			}
		).filter( ( { sharedList } ) => !!sharedList.length );
	}

	render() {
		let contactId = this.getSelectedContactId();
		let contact = this.getSelectedContact();
		let mainTitleTextId = contact ? "web.sharedcontacts.main.title": "web.sharedcontacts.list.title";
		return (
			<Layout
				activeMobileView={ this.state.activeMobileView }
				mainTitleTextId={mainTitleTextId}
				listTitleTextId="web.sharedcontacts.list.title"
				mainTitleParams={[contact && contact.name]}
				>
				<ListTitleContentView>
					<ListTitle
						onAddClick={this.onCreateSharedContacts}
						searchString={ this.state.searchString }
						onSetSearchString={ this.onSetSearchString }
					/>
				</ListTitleContentView>
				<MainTitleContentView>
					{this.renderMainTitle()}
				</MainTitleContentView>
				<MobileMenuContentView>
					<MobileMenu active="sharedcontacts">
						<button
							className="btn-circle newChat visible-xs"
							onClick={this.onCreateSharedContacts}>
							+</button>
					</MobileMenu>
				</MobileMenuContentView>
				<ListbarContentView>
					<ContactListListBar
						onSelectContact={ this.selectContact }
						selectedContactId={ contactId }
						contacts={ this.getFilteredContacts() }
						emptyTextId={ this.state.searchString ? "sharedcontacts.search.empty" : "web.sharedcontacts.list.empty" }
						arrow={ this.state.searchString === null }
						onMultidescriptionExit={this.onMultidescriptionExit}
						onMultidescriptionWorkgroup={this.onMultidescriptionWorkgroup}
						onMultidescriptionRename={this.onMultidescriptionRename}
						onMultidescriptionRights={this.onMultidescriptionRights}
						onMultidescriptionAdd={this.onAddContacts}
						onContactRename={ this.rename }
						onContactDelete={ this.delete }
					/>
				</ListbarContentView>
				<MainContentView>
					{this.renderContent()}
				</MainContentView>
				<CustomContentView>
					{this.renderPopup()}
				</CustomContentView>
			</Layout>
		);
	}
}

export default SharedContactsView;
