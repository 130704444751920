import socketContainerLocator from "./locators/socket.container.js";

class ServerConnection {
	constructor( apiUrlBase, onConnected, onDisconnected ) {
		this._onConnected = onConnected;
		this._onDisconnected = onDisconnected;

		this._socketContainer = socketContainerLocator();
		this._socket = this._socketContainer.getAndUseSocket( apiUrlBase );
		this._socket.on( "connect", onConnected );
		this._socket.on( "disconnect", onDisconnected );
	}

	sendMessage( name, body ) {
		this._socket.emit( name, body );
	}

	on( name, func ) {
		this._socket.on( name, func );
	}

	off( name, func ) {
		this._socket.removeListener( name, func );
	}

	dispose( ) {
		this._socket.off( "connect", this._onConnected );
		this._socket.off( "disconnect", this._onDisconnected );
		this._socketContainer.unUseSocket( this._socket );
	}
}

let varToExport;

export { varToExport as default };

varToExport = ServerConnection;

export function mockConnection( Implementation ) {
	varToExport = Implementation;
}

export function unmockConnection( Implementation ) {
	varToExport = ServerConnection;
}
