import React from "react";
import _ from "lodash";

import TopLevel from "../common/top.level.view.jsx";
import HeaderWait from "../common/header.wait.jsx";

import Qr from "../../components/qr.jsx";
import InputWithHelp from "../../components/input.withhelp.jsx";
import Toast from "../../components/toast.jsx";
import {DynamicToast} from "../../components/toast.jsx";
import Translation from "../../components/translation.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import ArrowButton from "../../components/button.continue.jsx";
import Button from "../../components/button.jsx";
import ImageButton from "../../components/button.image.jsx";
import OnlineStatus from "../../components/online.status.jsx"
import {bindStateProperty} from "../../../common/utils";
import translate from "../../translations/translate.js";
import {InternetRetryConfirm} from "../../components/dialogs.jsx"
import Checkbox from "../../components/checkbox.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";
import configuration from "../../../common/configuration.js";

let copy = _.get( global, "cordova.plugins.clipboard.copy" );

let InviteBlock = ( { textValue } ) => (
	<div>
		<div className="small-text">
			<Translation textId="invite.plain.text"/>
		</div>
		<div className="invite-code-block-">
			<InputWithHelp
				type="text"
				className="invite-code"
				value={textValue} readOnly={true}
				placeholderTextId="invite.token.placeholder"
				tooltipTextId="invite.token.tooltip"
				id="invite-code"
				/>
		</div>
	</div>
);

let InviteExternalBlock = ( { url } ) => (
	<div>
		<div className="small-text">
			<Translation textId="inviteUrl.plain.text"/>
		</div>
		<div className="invite-code-block-">
			<InputWithHelp
				type="text"
				className="invite-code"
				value={url} readOnly={true}
				placeholderTextId="invite.token.placeholder"
				tooltipTextId="invite.token.tooltip"
				id="invite-code"
			/>
	</div>
</div>
);

let InviteData = ( { qrValue, textValue, copyHandler, url, copyUrlHandler, isExternal } ) => (
	<div>
		<div className="big-text"><Translation textId="invite.qr.bigtext"/></div>
		<div className="small-text"><Translation textId="invite.qr.smalltext"/></div>
		<div className="qr-code blue f-left">
			<Qr className="qr-image" code={isExternal ? url : qrValue}/>
		</div>
		<div className="clear"></div>
		{!isExternal && <InviteBlock textValue={textValue}/>}
		{isExternal && <InviteExternalBlock url={url}/>}
		{copy && <Button caption="invite.button.copy" handleClick={isExternal ? copyUrlHandler : copyHandler} />}
	</div>
);

let CreateInvite = ( { nickname, nicknameChange, isValid, create, isLoading, isHide, toggleHide, textId } ) => (
	<div>
		<div className="small-text"><Translation textId={textId}/></div>
		<InputWithHelp
			type="text"
			value={nickname}
			onChange={nicknameChange}
			placeholderTextId="invite.nickname.placeholder"
			firstUppercased={true}
			tooltipTextId="invite.nickname.tooltip"
			autofocus={true}
			readOnly={isLoading}
		/>
		<br/>
		<br/>
		<Checkbox className="f-right" value={!!isHide} onClick={toggleHide}/>
		<div className="label">
			<Translation textId="invite.hide.checkbox"/>
		</div>
		<br/>
		<br/>
		<ArrowButton
			enabled={isValid && !isLoading}
			caption="invite.button.generate"
			handleClick={create}
			id="continue"
		/>
	</div>
);

class InviteView extends React.Component {
	constructor() {
		super();
		this.state = {
			nickname: "",
			token: null,
			isValid: false,
			isHide: false
		};
		this.onBackPress = this.onBackPress.bind( this );
		this.createInvite = this.createInvite.bind( this );
		this.copyInviteCode = this.copyInviteCode.bind( this );
		this.copyUrl = this.copyUrl.bind( this );
		this.toggleHide = this.toggleHide.bind( this );
	}

	static get propTypes() {
		return {
			onBack: React.PropTypes.func
		};
	}

	static get defaultProps() {
		return {
			onBack: _.noop
		};
	}

	validate() {
		let nickname = this.state.nickname.trim();
		let isValid = !!nickname && !_.find( this._nicknamesCache, { name: nickname } );

		if ( !isValid && nickname ) this.refs.toast.show( { textId: "invite.duplicate" } );

		this.setState( { isValid } );
	}

	componentWillMount() {
		if ( this.props.token ) {
			this.setState( { token: this.props.token } );
		}

		this._nicknamesCache = [];

		this._subscription = (
			serviceLocator().observeContactList()
				.subscribe( contactList => {
					this._nicknamesCache = (
						contactList
							.filter( ( { multidescriptionId } ) => multidescriptionId === -1 )
					);
				} )
		);
	}

	createInvite() {
		this.setState( { isInProgress: true } );
		serviceLocator().getProfileAsync()
			.flatMap( ( { nickname } ) =>
				serviceLocator().inviteContactAsync(
					this.state.nickname.trim(),
					( !this.state.isHide && nickname ) ? nickname.trim() : "",
					this.props.isExternal
				)
			)
			.flatMap( contact => serviceLocator().observeContactList()
				.filter( contacts => _.find( contacts, { inviteToken: contact.inviteToken } ) )
				.take( 1 )
				.map( () => contact )
			)
			.subscribe( contact => {
				this.props.onDone( contact );
			} );
	}

	copyInviteCode() {
		copy( this.state.token, () => {
			if ( !this.refs || !this.refs.toast ) {
				return;
			}
			this.refs.toast.show( { textId: "invite.copytoast" } );
		} );
	}

	copyUrl() {
		copy( configuration.getWebUrlBase() + "/#external/" + this.state.token, () => {
			if ( !this.refs || !this.refs.toast ) {
				return;
			}
			this.refs.toast.show( { textId: "invite.copytoast" } );
		} );
	}

	onBackPress() {
		this.props.onBack();
	}

	renderInviteData() {
		let token = this.state.token;
		return (
			<InviteData
				qrValue={ token }
				textValue={ token }
				copyHandler={ this.copyInviteCode }
				url={ configuration.getWebUrlBase() + "/#external/" + token }
				copyUrlHandler={ this.copyUrl }
				isExternal={ this.props.isExternal }
			/>
		);
	}

	toggleHide() {
		this.setState( {
			isHide: !this.state.isHide
		} );
	}

	renderCreateInvite() {
		if ( this.props.isExternal ) {
			return (
				<CreateInvite
					nickname={ this.state.nickname }
					nicknameChange={ bindStateProperty( this, "nickname", () => this.validate() ) }
					isValid={ this.state.isValid }
					create={ this.createInvite }
					isLoading={ this.state.isInProgress }
					isHide={ this.state.isHide }
					toggleHide={ this.toggleHide }
					textId="invite.create.external.text"
				/>
			);
		}
		return (
			<CreateInvite
				nickname={ this.state.nickname }
				nicknameChange={ bindStateProperty( this, "nickname", () => this.validate() ) }
				isValid={ this.state.isValid }
				create={ this.createInvite }
				isLoading={ this.state.isInProgress }
				isHide={ this.state.isHide }
				toggleHide={ this.toggleHide }
				textId="invite.create.text"
			/>
		);
	}

	renderHeader() {
		if ( !this.state.isInProgress ) {
			return (
				<header>
					<ImageButton type="icon-arrow-left" onClick={this.onBackPress} id="back"/>
					<span className="header-caption"><Translation textId="invite.header"/></span>
					<OnlineStatus/>
				</header>
			);
		}

		return <HeaderWait />;
	}

	render() {
		return (
			<TopLevel className="invite-page">
				{ this.renderHeader() }
				<main>
					{ this.state.token ? this.renderInviteData() : this.renderCreateInvite()}
				</main>
				<DeviceBackButton onPress={ this.onBackPress }/>
				<DynamicToast ref="toast" />
				<InternetRetryConfirm ref="noInternet" onConfirm={ this.createInvite } />
			</TopLevel>
		);
	}
}
export default InviteView;
