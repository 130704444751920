"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var VerifyAbstract = function () {
	function VerifyAbstract() {
		_classCallCheck(this, VerifyAbstract);
	}

	_createClass(VerifyAbstract, [{
		key: "getPublicKeyByteLength",
		value: function getPublicKeyByteLength() {
			throw new Error("Not implemented");
		}
	}, {
		key: "getSignByteLength",
		value: function getSignByteLength() {
			throw new Error("Not implemented");
		}
	}, {
		key: "setPublicKey",
		value: function setPublicKey() {
			throw new Error("Not implemented");
		}
	}, {
		key: "verifyThen",
		value: function verifyThen() {
			throw new Error("Not implemented");
		}
	}]);

	return VerifyAbstract;
}();

exports.default = VerifyAbstract;