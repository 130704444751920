"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _baseSource = require("./base.source.js");

var _baseSource2 = _interopRequireDefault(_baseSource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /*THIS CODE IS FOR TESTING ONLY!!!*/


var FakeSource = function (_BaseSource) {
	_inherits(FakeSource, _BaseSource);

	function FakeSource(callback) {
		_classCallCheck(this, FakeSource);

		var _this = _possibleConstructorReturn(this, (FakeSource.__proto__ || Object.getPrototypeOf(FakeSource)).call(this, callback));

		console.warn("Running entropy fake source");
		_this._next();
		return _this;
	}

	_createClass(FakeSource, [{
		key: "_next",
		value: function _next() {
			var _this2 = this;

			setTimeout(function () {
				_this2.gotData(+new Date(), 10);
				_this2._next();
			}, 10);
		}
	}]);

	return FakeSource;
}(_baseSource2.default);

exports.default = FakeSource;