import _ from 'lodash'
import Rx from 'rx'

export default (_.memoize(() => {
if ( _.get( global, "cordova.plugins.clipboard" ) ) {
  return {
    copy:  (f) => () => cordova.plugins.clipboard.copy(f()),
    paste: (f) => () => cordova.plugins.clipboard.paste(f),
    pasteObservable: Rx.Observable.empty()
  }
}

let clipboard = null;

Rx.Observable.fromEvent( document, "copy" )
    .subscribe( event => { clipboard = null; } )

return {
  pasteObservable:
    Rx.Observable.fromEvent( document, "paste" )
      .map( event => clipboard || event.clipboardData.getData( "text/plain" ) || "" ),

  copy:  (f) => () => clipboard = f(),
  paste: (f) => () => f(clipboard)
}
}))()
