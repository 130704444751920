import Rx from "rx";
import _ from "lodash";
import {KEY_KINDS} from "ssg.crypto";

import ClientServerJSON from "./client.server.json.js";

class ClientServerJSONMeta extends ClientServerJSON {
	_getKeyMapForMessage( json ) {
		switch ( json.type ) {
			case "create":
				return {
					publicKey: KEY_KINDS.SIGNATURE_PUBLIC,
					dhPubKey: KEY_KINDS.KEY_EXCHANGE_PUBLIC
				};
			case "invite":
				return {
					tmpPublicKey: KEY_KINDS.SIGNATURE_PUBLIC
				};
			case "accept":
				return {
					publicKey: KEY_KINDS.SIGNATURE_PUBLIC,
					dhPubKey: KEY_KINDS.KEY_EXCHANGE_PUBLIC
				};
			default:
				return {};
		}
	}

	getMaxIndexAsync( ) {
		return this._callUntilSuccessAsync( cb => {
			this._unauthConnection.tryGetMaxIndex( cb );
		} ).map( ( { maxIndex } ) => maxIndex );
	}
}

export default ClientServerJSONMeta;
