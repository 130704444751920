import React from "react";
import classNames from "classNames";

import Switch from "../common/switch.jsx";
import Translation from "../../../components/translation.jsx";
import Tinput from "../common/tinput.jsx";
import Toast from "../../../components/toast.jsx";
import Confirm from "../common/confirm.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class TimePeriod extends React.Component {
	render() {
		let cn = classNames( "time", this.props.className, {
			active: this.props.value === this.props.autoclean
		} );
		return (
			<div className={cn} onClick={this.props.onSet.bind( this, this.props.value)}>
				{this.props.num} <Translation textId={this.props.unit} params={[this.props.num]}/>
			</div>
		);
	}
}

class AccountView extends React.Component {
	constructor() {
		super();
		this.state = {
			lock: false,
			cleanOnExit: false,
			newPassword: "",
			showPassword: false,
			autoclean: null
		};

		this.lockOnChange = this.switchOnChange.bind( this, "lock" );
		this.cleanOnExitOnChange = this.switchOnChange.bind( this, "cleanOnExit" );
		this.onChangePasswordClick = this.onChangePasswordClick.bind( this );
		this.onShowPasswordChange = this.onShowPasswordChange.bind( this );
		this.onNewPasswordChange = this.onNewPasswordChange.bind( this );
		this.onSetAutoclean = this.onSetAutoclean.bind( this );
		this.clearHistoryClick = this.clearHistoryClick.bind( this );

		this._service = remoteServiceLocator();
	}

	componentWillMount() {
		this._profileSubscription = (
			this._service.observeProfile()
				.subscribe( ({lock, cleanOnExit, autoclean}) => {
					this.setState( {
						lock: !!lock,
						cleanOnExit: !!cleanOnExit,
						autoclean,
						frozen: false
					} );
				} )
		);
	}

	componentWillUnmount() {
		this._profileSubscription.dispose();
	}

	switchOnChange( name, event ) {
		return;
		if ( this.state.frozen ) {
			return;
		}
		this.setState( {
			[name]: event.target.checked,
			frozen: true
		} );
		this._service.updateProfileAsync( {
			[name]: event.target.checked
		} ).subscribe(
			() => {},
			() => {
				this.setState( {frozen: false} );
			}
		);
	}

	onNewPasswordChange( newPassword ) {
		if ( this.state.frozen ) {
			return;
		}
		this.setState( {newPassword} );
	}

	onShowPasswordChange( event ) {
		if ( this.state.frozen ) {
			return;
		}
		let showPassword = event.target.checked;
		this.setState( {showPassword} );
	}

	onChangePasswordClick() {
		if ( this.state.frozen ) {
			return;
		}
		this.setState( {changePasswordInProgress: true, showPassword: false, frozen: true} );
		this._service.changePasswordAsync( this.state.newPassword )
			.subscribe( isSuccess => {
				this.setState( {changePasswordInProgress: false, newPassword: "", frozen: false} );
				if ( !isSuccess ) {
					this.refs.dulplicatePassword.show();
					return;
				}
				this.refs.passwordChanged.show();
			} );
	}

	onSetAutoclean( autoclean ) {
		if ( this.state.frozen ) {
			return;
		}
		this.setState( {
			autoclean,
			frozen: true
		} );
		this._service.updateProfileAsync( {
			autoclean
		} ).subscribe(
			() => {},
			() => {
				this.setState( {frozen: false} );
			}
		);
	}

	clearHistoryClick() {
		this.setState( {
			popup: () =>
				<Confirm
					titleTextId="web.security.clearHistory.title"
					contentTextId="web.security.clearHistory.text"
					yesButtonTextId="web.security.clearHistory.button.yes"
					onDone={ this.doClearHistory.bind( this ) }
				/>
		} );
	}

	doClearHistory( confirmed ) {
		if ( !confirmed ) {
			this.setState( {
				popup: null
			} );
			return;
		}
		this.setState( {
			popup: null,
			frozen: true
		} );
		this._service.clearHistoryAsync()
			.subscribe(
				() => {
					this.setState( {
						frozen: false
					} );
				},
				() => {
					this.setState( {
						frozen: false
					} );
				}
			);
	}

	renderPopup() {
		if ( this.state.popup ) {
			return this.state.popup();
		}
		return null;
	}

	render() {
		let {autoclean} = this.state;

		return (
			<div className={this.props.className}>
				<div className="invite">
					<div className="title-sm"><Translation textId="web.security.password.label" /></div>
					<p><Translation textId="web.security.password.text" />
					</p>
					<Tinput
						className="name"
						type={this.state.showPassword ? "text" : "password" }
						placeholderTextId="web.security.password.placeholder"
						onChange={this.onNewPasswordChange}
						value={this.state.newPassword}
						/>
					<div className="settings-box">
						<div className="checkbox">
							<label className="nice-checkbox">
								<input
									type="checkbox"
									checked={this.state.showPassword}
									onChange={this.onShowPasswordChange}
									/>
								<span className="check"></span>
								<Translation textId="web.security.password.show.label" />
							</label>
						</div>
						<div className="text-center">
							<button className="btn btn-green" onClick={this.onChangePasswordClick}>
								<Translation textId="web.security.password.change.button" />
							</button>
						</div>
						<div className="settings-block">
							{/*}
							<p><Translation textId="web.security.autolock.label" /></p>
							<Switch
								id="lock"
								checked={this.state.lock}
								onChange={this.lockOnChange}
							/>
							<div className="clearfix"></div>
							{*/}
							<div className="title-sm"><Translation textId="web.security.history.head" /></div>
							<p><Translation textId="web.security.history.text" /></p>
								{/*}
							<div className="settings-block">
								<p><Translation textId="web.security.history.cleanonexit.label" /></p>
								<Switch
									id="cleanOnExit"
									checked={this.state.cleanOnExit}
									onChange={this.cleanOnExitOnChange}
								/>
								<div className="clearfix"></div>
							</div>
							{*/}
							<p><Translation textId="web.security.history.ttl.label" /></p>
							<div className="remove-time" style={{cssFloat: "left"}}>
								<TimePeriod className="" num="" unit="web.security.history.autoclean.unit.no" value={0} autoclean={autoclean} onSet={this.onSetAutoclean}/>
								<TimePeriod className="" num="1" unit="web.security.history.autoclean.unit.min" value={60} autoclean={autoclean} onSet={this.onSetAutoclean}/>
								<TimePeriod className="" num="10" unit="web.security.history.autoclean.unit.min" value={10*60} autoclean={autoclean} onSet={this.onSetAutoclean}/>
								<TimePeriod className="" num="1" unit="web.security.history.autoclean.unit.hour" value={60*60} autoclean={autoclean} onSet={this.onSetAutoclean}/>
								<TimePeriod className="" num="1" unit="web.security.history.autoclean.unit.day" value={24*60*60} autoclean={autoclean} onSet={this.onSetAutoclean}/>
								<TimePeriod className="" num="7" unit="web.security.history.autoclean.unit.day" value={7*24*60*60} autoclean={autoclean} onSet={this.onSetAutoclean}/>
								<TimePeriod className="" num="30" unit="web.security.history.autoclean.unit.day" value={30*24*60*60} autoclean={autoclean} onSet={this.onSetAutoclean}/>
								<TimePeriod className="" num="1" unit="web.security.history.autoclean.unit.year" value={365.25*24*60*60} autoclean={autoclean} onSet={this.onSetAutoclean}/>
							</div>
							<p><Translation textId="web.security.history.cleannow.label" /></p>
							<div className="clearfix"></div>
							<div className="text-center">
								<button className="btn btn-green" onClick={this.clearHistoryClick}>
									<Translation textId="web.security.history.cleannow.button" />
								</button>
							</div>
						</div>
					</div>
				</div>
				<Toast textId="web.security.dulplicatePassword.toast" ref="dulplicatePassword"/>
				<Toast textId="web.security.passwordChanged.toast" ref="passwordChanged"/>
				{this.renderPopup()}
			</div>
		);
	}
}

export default AccountView;
