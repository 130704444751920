import Rx from "rx";

import HookableRepository from "./hookable.js";

class ConfigRepository extends HookableRepository {
	constructor( StorageType ) {
		super( StorageType, "config.dat" );
	}

	setCurrentConfigAsync( json ) {
		return this.replaceDataAsync( new Buffer( JSON.stringify( json ), "utf8" ) );
	}

	getCurrentConfigAsync( ) {
		return (
			this.lockStorageAsync( storage => storage.readAsBufferAsync() )
				.map( buffer => buffer.length ? JSON.parse( buffer.toString() ) : null )
		);
	}

	isExistAsync() {
		return this._StorageType.isFileExistsAsync( "config.dat" );
	}

}

export default ConfigRepository;
