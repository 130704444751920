import React from "react";

import FullScreen from "../../components/fullscreen.jsx";

class PausedView extends React.Component {
    render() {
        return (
            <FullScreen>
                <div style={{
                    width:"100%",
                    height:"100%",
                    margin:"0"
                }}>
                      <div style={{
                          width:"100%",
                          height:"100%",
                          margin:"0",
                          background:"url( icon/splash.png ) no-repeat black",
                          backgroundPosition:"center",
                          backgroundSize:"contain",
                          position:"static",
                          top:"0",
                          left:"0",
                      }}> </div>
                </div>
            </FullScreen>
        );
    }
}

export default PausedView;
