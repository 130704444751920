"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lodash = require("lodash");

var _lodash2 = _interopRequireDefault(_lodash);

var _hash = require("../helpers/hash.js");

var _hash2 = _interopRequireDefault(_hash);

var _schnorr = require("../group/schnorr.js");

var _schnorr2 = _interopRequireDefault(_schnorr);

var _algorithms = require("../algorithms.js");

var _keyKind = require("../primitives/key.kind.js");

var _keyKind2 = _interopRequireDefault(_keyKind);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Config = function () {
	function Config(json) {
		_classCallCheck(this, Config);

		if (!json) {
			throw new Error("json required");
		}

		if (!_algorithms.ciphers[json.cipherAlgorithm] || !_algorithms.deciphers[json.cipherAlgorithm]) {
			throw new Error("Cipher-decipher algorithm " + json.cipherAlgorithm + " is not found");
		}

		if (!_algorithms.signers[json.signatureAlgorithm] || !_algorithms.verifiers[json.signatureAlgorithm]) {
			throw new Error("Signature algorithm " + json.signatureAlgorithm + " is not found");
		}

		if (!_algorithms.keyExchanges[json.keyExchange]) {
			throw new Error("Key exchange algorithm " + json.keyExchange + " is not found");
		}

		if (!_algorithms.keySizes[json.cipherAlgorithm]) {
			throw new Error("Key size for " + json.signatureAlgorithm + " is not found");
		}

		if (!_algorithms.kdfs[json.kdfPassword]) {
			throw new Error("Kdf " + json.kdfPassword + " is not found");
		}

		if (!_algorithms.kdfs[json.kdfKeyed]) {
			throw new Error("Kdf " + json.kdfKeyed + " is not found");
		}

		if (!("kdfPasswordParam" in json)) {
			throw new Error("kdfPasswordParam not found");
		}

		if (!("kdfKeyedParam" in json)) {
			throw new Error("kdfKeyedParam not found");
		}

		if (!("signatureParam" in json)) {
			throw new Error("signatureParam not found");
		}

		if (!("keyExchangeParam" in json)) {
			throw new Error("keyExchangeParam is not found");
		}

		if (!json.hmacAlgorithm) {
			throw new Error("hmacAlgorithm is not found");
		}

		this._keyExchange = json.keyExchange;
		if (json.signatureAlgorithm === "schnorr-4096-512") {
			//TODO: param converter
			this._keyExchangeParam = new _schnorr2.default(json.keyExchangeParam);
		} else {
			this._keyExchangeParam = json.signatureAlgorithm;
		}
		this._cipherAlgorithm = json.cipherAlgorithm;
		this._signatureAlgorithm = json.signatureAlgorithm;
		this._signatureParam = json.signatureParam;
		this._kdfPassword = json.kdfPassword;
		this._kdfPasswordParam = json.kdfPasswordParam;
		this._kdfKeyed = json.kdfKeyed;
		this._kdfKeyedParam = json.kdfKeyedParam;

		this._hmacAlgorithm = json.hmacAlgorithm;

		if (_algorithms.paramConverters[json.signatureAlgorithm]) {
			this._signatureParam = _algorithms.paramConverters[json.signatureAlgorithm](json.signatureParam);
		} else {
			this._signatureParam = json.signatureParam;
		}

		this._json = json;
	}

	_createClass(Config, [{
		key: "getHashString",
		value: function getHashString() {
			return _hash2.default.create(new Buffer(this._cipherAlgorithm), new Buffer([0]), new Buffer(this._signatureAlgorithm), new Buffer([0]), this._signatureParam ? new Buffer(JSON.stringify(this._signatureParam.toJson())) : new Buffer([0])).toString("hex");
		}
	}, {
		key: "validateThen",
		value: function validateThen() {
			if (_algorithms.validators[this._signatureAlgorithm]) {
				return _algorithms.validators[this._signatureAlgorithm](this._signatureParam);
			}
			return Promise.resolve(true);
		}
	}, {
		key: "getKeySize",
		value: function getKeySize(kind) {
			switch (kind) {
				case _keyKind2.default.INTERMEDIATE:
					return 32; //TODO: seed size
				case _keyKind2.default.SYMMETRIC_ENCRYPTION:
					return _algorithms.keySizes[this._cipherAlgorithm];
				case _keyKind2.default.MAC:
					return 32; //TODO: hmac sizes
				case _keyKind2.default.KEY_EXCHANGE_PRIVATE:
					{
						var sizeValue = _algorithms.keySizes[this._keyExchange];
						if (typeof sizeValue === "function") {
							return sizeValue(kind, this._keyExchangeParam);
						}
						return sizeValue;
					}
				// return this._keyExchangeParam.getQ().byteLength(); //TODO: more general
				case _keyKind2.default.KEY_EXCHANGE_PUBLIC:
					{
						var _sizeValue = _algorithms.keySizes[this._keyExchange];
						if (typeof _sizeValue === "function") {
							return _sizeValue(kind, this._keyExchangeParam);
						}
						return _sizeValue;
					}
				// return this._keyExchangeParam.getP().byteLength(); //TODO: more general
				case _keyKind2.default.SIGNATURE_PRIVATE:
					{
						var _sizeValue2 = _algorithms.keySizes[this._signatureAlgorithm];
						if (typeof _sizeValue2 === "function") {
							return _sizeValue2(kind, this._keyExchangeParam);
						}
						return _sizeValue2;
					}
				// return this._signatureParam.getQ().byteLength(); //TODO: more general
				case _keyKind2.default.SIGNATURE_PUBLIC:
					{
						var _sizeValue3 = _algorithms.keySizes[this._signatureAlgorithm];
						if (typeof _sizeValue3 === "function") {
							return _sizeValue3(kind, this._keyExchangeParam);
						}
						return _sizeValue3;
					}
				// return this._signatureParam.getP().byteLength(); //TODO: more general
				default:
					throw new Error("Invali key kind");
			}
		}
	}, {
		key: "getIdLength",
		value: function getIdLength() {
			return 32;
		}
	}, {
		key: "toJson",
		value: function toJson() {
			var values = {
				keyExchange: this._keyExchange,
				keyExchangeParam: this._keyExchangeParam,
				cipherAlgorithm: this._cipherAlgorithm,
				signatureAlgorithm: this._signatureAlgorithm,
				signatureParam: this._signatureParam,
				kdfPassword: this._kdfPassword,
				kdfPasswordParam: this._kdfPasswordParam,
				kdfKeyed: this._kdfKeyed,
				kdfKeyedParam: this._kdfKeyedParam,
				hmacAlgorithm: this._hmacAlgorithm
			};
			return _lodash2.default.mapValues(values, function (val) {
				return val && val.toJson ? val.toJson() : val;
			});
		}
	}, {
		key: "signatureParam",
		get: function get() {
			return this._signatureParam;
		}
	}, {
		key: "cipherAlgorithm",
		get: function get() {
			return this._cipherAlgorithm;
		}
	}, {
		key: "keyExchange",
		get: function get() {
			return this._keyExchange;
		}
	}, {
		key: "keyExchangeParam",
		get: function get() {
			return this._keyExchangeParam;
		}
	}, {
		key: "kdfPassword",
		get: function get() {
			return this._kdfPassword;
		}
	}, {
		key: "kdfPasswordParam",
		get: function get() {
			return this._kdfPasswordParam;
		}
	}, {
		key: "kdfKeyed",
		get: function get() {
			return this._kdfKeyed;
		}
	}, {
		key: "kdfKeyedParam",
		get: function get() {
			return this._kdfKeyedParam;
		}
	}, {
		key: "signatureAlgorithm",
		get: function get() {
			return this._signatureAlgorithm;
		}
	}, {
		key: "hmacAlgorithm",
		get: function get() {
			return this._hmacAlgorithm;
		}
	}]);

	return Config;
}();

exports.default = Config;