"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _baseSource = require("./base.source.js");

var _baseSource2 = _interopRequireDefault(_baseSource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TouchSource = function (_BaseSource) {
	_inherits(TouchSource, _BaseSource);

	function TouchSource(callback) {
		_classCallCheck(this, TouchSource);

		var _this = _possibleConstructorReturn(this, (TouchSource.__proto__ || Object.getPrototypeOf(TouchSource)).call(this, callback));

		_this._onHandlers();
		return _this;
	}

	_createClass(TouchSource, [{
		key: "_onHandlers",
		value: function _onHandlers() {
			var _this2 = this;

			if (global.DeviceOrientationEvent && !this._deviceorientation) {
				this._deviceorientation = function (event) {
					return _this2._handleOrientation(event);
				};
				global.addEventListener("deviceorientation", this._deviceorientation, true);
			}
			if (global.DeviceMotionEvent && !this._devicemotion) {
				this._devicemotion = function (event) {
					return _this2._handleMotion(event);
				};
				global.addEventListener("devicemotion", this._devicemotion, true);
			}
		}
	}, {
		key: "_offHandlers",
		value: function _offHandlers() {
			if (this._deviceorientation) {
				global.removeEventListener("deviceorientation", this._deviceorientation);
				delete this._deviceorientation;
			}
			if (this._devicemotion) {
				global.removeEventListener("devicemotion", this._devicemotion);
				delete this._devicemotion;
			}
		}
	}, {
		key: "_handleOrientation",
		value: function _handleOrientation(event) {
			var rot = event.rotationRate;
			if (!rot || isNaN(rot.alpha)) {
				return;
			}
			this.gotData(rot.alpha, "a");
			this.gotData(rot.beta, "b");
			this.gotData(rot.gamma, "g");
		}
	}, {
		key: "_handleMotion",
		value: function _handleMotion(event) {
			var acc = event.accelerationIncludingGravity;
			if (!acc || isNaN(acc.x) || acc.x === null) {
				return;
			}
			this.getDiffData(acc.x, "x");
			this.getDiffData(acc.y, "y");
			this.getDiffData(acc.z, "z");
		}
	}, {
		key: "getDiffData",
		value: function getDiffData(value, name) {
			var last = this["last" + name] | 0;
			var diff = value - last;
			this["last" + name] = value;

			diff && this.gotData(diff > 0 ? 1 : 0, 1);
			this.gotData(diff * 1000);
		}
	}, {
		key: "setIdle",
		value: function setIdle(isIdle) {
			if (isIdle) {
				this._offHandlers();
			} else {
				this._onHandlers();
			}
			_get(TouchSource.prototype.__proto__ || Object.getPrototypeOf(TouchSource.prototype), "setIdle", this).call(this, isIdle);
		}
	}]);

	return TouchSource;
}(_baseSource2.default);

exports.default = TouchSource;