import Rx from "rx";
import ssgCrypto from "ssg.crypto";

import ClientServerBase from "./client.server.base.js";
let triggerIdCounter = 0;

class ClientServerTrigger extends ClientServerBase {
	constructor( apiUrlBase, tos, onTriggered ) {
		if ( !tos ) {
			throw new Error( "tos required" );
		}
		if ( typeof onTriggered !== "function" ) {
			throw new Error( "onTriggered must be a function" );
		}
		triggerIdCounter++;
		super( apiUrlBase, "trigger" + triggerIdCounter );
		this._triggerIdAsync = new Rx.ReplaySubject();
		this._tos = tos;
		this._onTriggered = onTriggered;
		this._maxIndexesThen = new Promise( resolve => {
			this._maxIndexesCb = resolve;
		} );

		ssgCrypto.createRandomBase64StringThen( 32 )
			.then( triggerId => {
				this._triggerIdAsync.onNext( triggerId );
				this._triggerIdAsync.onCompleted();
				this.onConnected( this._init.bind( this ) );
				this._on( "triggered", this._triggeredHandler.bind( this ) );
			} );
		this._init();
	}

	_init() {
		this.listenAsync().subscribe( ( { maxIndexes } ) => {
			if ( !this._maxIndexesCb ) {
				return;
			}
			this._maxIndexesCb( maxIndexes );
			delete this._maxIndexesCb;
		} );
	}

	getMaxIndexesThen() {
		return this._maxIndexesThen;
	}

	listenAsync( ) {
		return (
			this._triggerIdAsync.flatMap( triggerId =>
				this._callUntilSuccessAsync( cb => {
					this._tryMakeCall( "trigger", {
						tos: this._tos, triggerId
					}, cb );
				} )
			)
		);
	}

	_triggeredHandler( message ) {
		this._triggerIdAsync.subscribe( triggerId => {
			if ( message.triggerId !== triggerId ) {
				return;
			}
			this._onTriggered( message.to );
		} );
	}
}

export default ClientServerTrigger;
