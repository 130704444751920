import ssgCrypto from "ssg.crypto";

import HubBase from "./hub.base.js";
import configuration from "../../common/configuration.js";

class MessageHub extends HubBase {
	constructor( baseUrl ) {
		super( baseUrl );
		this._pendingCalls = Object.create( null );
		this._listenMessages( "resp" ).subscribe( message => {
			this.onResp( message );
		} );
	}

	onResp( message ) {
		if ( !message || !message.callId ) {
			return;
		}
		const call = this._pendingCalls[ message.callId ];
		if ( !call ) {
			return;
		}
		const { resolve, reject, interval } = call;
		delete this._pendingCalls[ message.callId ];
		clearInterval( interval );
		if ( message.status === "ok" ) {
			resolve( message );
		} else {
			reject( message );
		}
	}

	sendMessageThen( params ) {
		const { serverSeed, isSingleRead, expirationTS, content, hash } = params;
		if ( !serverSeed ) {
			throw new Error( "serverSeed required" );
		}
		if ( !expirationTS ) {
			throw new Error( "expirationTS required" );
		}
		if ( !content ) {
			throw new Error( "content required" );
		}
		if ( !hash ) {
			throw new Error( "hash required" );
		}
		const timeout = configuration.getNetworkTimeout();
		return (
			ssgCrypto.createRandomBase64StringThen( 32 )
				.then( callId => new Promise( ( resolve, reject ) => {
					const serverMessage = {
						callId, serverSeed, isSingleRead, expirationTS, content, hash
					};
					const interval = setInterval( () => {
						this.send( "message", serverMessage );
					}, timeout );
					this.send( "message", serverMessage );
					this._pendingCalls[ callId ] = { resolve, reject, interval };
				} ) )
		);
	}

	receiveMessageThen( id ) {
		const timeout = configuration.getNetworkTimeout();
		return (
			ssgCrypto.createRandomBase64StringThen( 32 )
				.then( callId => new Promise( ( resolve, reject ) => {
					const serverMessage = {
						callId, id
					};
					const interval = setInterval( () => {
						this.send( "getmessage", serverMessage );
					}, timeout );
					this.send( "getmessage", serverMessage );
					this._pendingCalls[ callId ] = { resolve, reject, interval };
				} ) )
		);
	}
}

export default MessageHub;
