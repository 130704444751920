import React from "react";
import _ from "lodash";

import BaseMessagesView from "../common/messages.jsx";
import serviceLocator from "../../../../api/services/locators/worker.client.js";

//TODO: move to some common code
//copied from remote.server.js

class MessagesView extends BaseMessagesView {
	constructor() {
		super();
		this._service = {
			setReadAllAsync: this.setReadAllAsync.bind( this ),
			queryHistoryAsync: this.queryHistoryAsync.bind( this ),
			observeMessages: this.observeMessages.bind( this ),
			muteContactNotification: _.noop
		};
		this._service = serviceLocator();
	}

	convertMessage( contact, message ) {
		let json = message.toJson();
		let inviteString;
		switch ( json.contentType ) {
			case "text/plain":
				break;
			case "contact":
				delete json.contactInviteDataString;
				json.contentType = "text/plain";
				json.text = "Strange message sending you a contact";
				break;
			case "group":
				delete json.groupInviteDataString;
				json.contentType = "text/plain";
				json.text = "Strange message sending you a group";
				break;
			case "workgroup":
				delete json.workgroupInviteDataString;
				json.contentType = "text/plain";
				json.text = "Strange message sending you a workgroup";
				break;
			default:
				break;
		}

		return json;
	}

	setReadAllAsync( contact ) {
		return this._service.setReadAllAsync( contact );
	}

	queryHistoryAsync( contact ) {
		return this._service.queryHistoryAsync( contact );
	}

	observeMessages( contact ) {
		return (
			this._service.observeMessages( contact )
		);
	}

	doDeleteAsync( message ) {
		let { contact } = this.props;
		return this._service.sendDeleteMessageAsync(
			contact,
			message.replaceIndex === undefined ? message.index : message.replaceIndex
		);
	}

	render() {
		return (
			<div className="chat-body" ref="root" onScroll={ this.onScroll }>
				{ this.renderMessages( true ) }
			</div>
		);
	}
}

export default MessagesView;
