class DraftMessageService {
    constructor() {
        this._contactId2Draft = Object.create( null );
    }

    setDraftForContact( contactId, message ) {
        if ( typeof contactId !== "number" ) {
            throw new Error( "contactId required" );
        }
        if ( typeof message !== "string" ) {
            throw new Error( "message required" );
        }
        this._contactId2Draft[ contactId ] = message;
    }

    dropDraftForContact( contactId ) {
        if ( typeof contactId !== "number" ) {
            throw new Error( "contactId required" );
        }
        delete this._contactId2Draft[ contactId ];
    }

    tryGetDraftForContact( contactId ) {
        if ( typeof contactId !== "number" ) {
            throw new Error( "contactId required" );
        }
        return this._contactId2Draft[ contactId ] || null;
    }

    dropDrafts() {
        for ( let contactIdStr in this._contactId2Draft ) {
            delete this._contactId2Draft[ contactIdStr ];
        }
    }
}

export default DraftMessageService;
