"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _bn = require("bn.js");

var _bn2 = _interopRequireDefault(_bn);

var _millerRabin = require("miller-rabin");

var _millerRabin2 = _interopRequireDefault(_millerRabin);

var _bnWorker = require("./bn.worker.js");

var _bnWorker2 = _interopRequireDefault(_bnWorker);

var _prng = require("../random/locators/prng.js");

var _prng2 = _interopRequireDefault(_prng);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var random = (0, _prng2.default)();
var workerCount = 4;
var Worker = global.Worker || null;
var queue = [];
var workerInfo = [];

if (Worker && typeof Blob === "function" && global.TextEncoder) {
	var scriptBlob = void 0;
	var bytes = new global.TextEncoder("utf-8").encode(_bnWorker2.default);
	scriptBlob = new global.Blob([bytes], { type: "text/javascript" });
	var scriptUrl = scriptBlob && global.URL.createObjectURL(scriptBlob);

	var _loop = function _loop(i) {
		var worker = new global.Worker(scriptUrl);
		var wi = {
			worker: worker,
			currentItem: null
		};
		worker.onmessage = function (event) {
			wi.currentItem.resultCb(event.data);
			wi.currentItem = null;
			nextPoolItem();
		};
		worker.onerror = function (event) {
			debugger;
			console.error(event);
		};
		workerInfo.push(wi);
	};

	for (var i = 0; i < workerCount; i++) {
		_loop(i);
	}
} else {
	Worker = null;
}

function nextPoolItem() {
	if (!queue.length) {
		return;
	}
	var item = queue.shift();
	_runAtPoolItem(item);
}

function _runAtPoolItem(item) {
	if (!item.promise) {
		(function () {
			var prevCb = item.resultCb;
			item.resultCb = null;
			item.promise = new Promise(function (resolve, reject) {
				item.resultCb = function (result) {
					resolve(prevCb(result));
				};
			});
		})();
	}

	for (var i = 0; i < workerCount; i++) {
		var _wi = workerInfo[i];
		if (_wi.currentItem) {
			continue;
		}
		_wi.currentItem = item;
		_wi.worker.postMessage(item.workerMessage);
		return item.promise;
	}
	queue.push(item);
	return item.promise;
}

function redPowExternalThen(a, b) {
	if (global.BNNative) {
		return new Promise(function (resolve, reject) {
			global.BNNative.redPow(a, b, a.red.m, function (error, sResult) {
				if (error) {
					reject(error);
					return;
				}
				resolve(new _bn2.default(sResult, 16).toRed(a.red));
			});
		});
	}
	if (!Worker) {
		var result = a.redPow(b);
		return Promise.resolve(result);
	}
	var workerMessage = {
		action: "redPow",
		a: new Buffer(a.toArray()),
		b: new Buffer(b.toArray()),
		m: new Buffer(a.red.m.toArray())
	};
	return _runAtPoolItem({
		workerMessage: workerMessage,
		resultCb: function resultCb(buf) {
			return new _bn2.default(buf).toRed(a.red);
		}
	});
}

function millerTestExternalThen(params) {
	if (!params.bn) {
		return Promise.reject("require bn");
	}

	if (!params.rounds) {
		return Promise.reject("require rounds");
	}

	if (!Worker) {
		return random.getSyncGeneratorFuncThen().then(function (randomFunc) {
			var millerTest = new _millerRabin2.default({ generate: randomFunc });
			return millerTest.test(params.bn, params.rounds);
		});
	}
	return random.pseudoRandomDataThen(params.bn.byteLength() * params.rounds).then(function (random) {
		var workerMessage = {
			action: "muller-test",
			bn: new Buffer(params.bn.toArray()),
			rounds: params.rounds,
			random: random
		};
		return _runAtPoolItem({
			workerMessage: workerMessage,
			resultCb: function resultCb(res) {
				return res;
			}
		});
	});
}

exports.default = {
	redPowExternalThen: redPowExternalThen,
	millerTestExternalThen: millerTestExternalThen
};