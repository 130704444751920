import React from "react";
import _ from "lodash";

import Translation from "../../../components/translation.jsx";
import Switch from "../common/switch.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class RightsTableView extends React.Component {
  render() {
    return (
      <table style={{width: "100%"}}>
      <thead>
        <tr>
          <th><Translation textId="sharedcontacts.dashboard.participant"/></th>
          <th><Translation textId="sharedcontacts.dashboard.allowworkgroupedit"/></th>
          <th><Translation textId="sharedcontacts.dashboard.allowcontactsedit"/></th>
        </tr>
      </thead>
      <tbody>
      {_.map(
        this.props.participants,
        ( { nickname }, pid ) => {
          let {baseRights, modifiedRights} = this.props;
          let right = modifiedRights[ pid ] || baseRights[ pid ] || {allowModifyWorkgroup: true, allowModifyContacts: true};
          return (
            <tr key={pid}>
              <td><div className="item">{nickname}</div></td>
              <td>
                <Switch
                  checked={ right.allowModifyWorkgroup }
                  onChange={ this.props.toggleModifyWorkgroup.bind( this, pid ) }
                  id={`allowModifyWorkgroup${pid}`}
                />
              </td>
              <td>
                <Switch
                  checked={ right.allowModifyContacts }
                  onChange={ this.props.toggleModifyContacts.bind( this, pid ) }
                  id={`toggleModifyContacts${pid}`}
                />
              </td>
            </tr>
          );
        }
      ) }
      </tbody>
      </table>
    );
  }

  static get propTypes() {
    return {
      toggleModifyWorkgroup: React.PropTypes.func.isRequired,
      toggleModifyContacts: React.PropTypes.func.isRequired,
      participants: React.PropTypes.object.isRequired,
      baseRights: React.PropTypes.object.isRequired,
      modifiedRights: React.PropTypes.object.isRequired
    };
  }
}

export default RightsTableView;
