import React from "react";
import _ from "lodash";

import {getContactInitials} from "../../../../common/utils.js";

import PromptContacts from "../common/prompt.contacts.jsx";
import Translation from "../../../components/translation.jsx";
import Tinput from "../common/tinput.jsx";
import Timestamp from "../../../components/timestamp.jsx";
import Confirm from "../common/confirm.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class ParticipantsContentView extends React.Component {
	constructor() {
		super();
		this.onAddClick = this.onAddClick.bind( this );
		this._service = remoteServiceLocator();
		this.state = {};
	}

	componentWillMount() {
		this._participantsSubscription = (
			this._service.observeGroupParticipants( this.props.contact )
			.subscribe( participants => {
				this.setState( {participants} );
			} )
		);

		this._invitesSubscription = (
			this._service.observeGroupInvites( this.props.contact )
			.subscribe( invites => {
				this.setState( {invites} );
			} )
		);
	}

	componentWillUnmount() {
		this._participantsSubscription.dispose();
		this._invitesSubscription.dispose();
	}

	onAddClick() {
		this.props.onAdd();
	}

	renderParticipants() {
		let {contact} = this.props;
		return _.map(
			this.state.participants,
			(participant, pid) =>
			<Participant nickname={participant.nickname} contact={contact} pid={pid} key={pid} />
		);
	}

	renderInvites() {
		let {contact} = this.props;
		let {invites} = this.state;
		if ( _.isEmpty( invites ) ) {
			return null;
		}
		return (
			[ <tr key="!"><td colSpan="3"><Translation textId="group.participantlist.invites"/></td></tr> ]
			.concat( _.map(
				this.state.invites,
				(invite, pid) =>
				<Invite nickname={invite.nickname} contact={contact} pid={pid} key={pid} />
			) )
		);
	}

	renderTable() {
		return (
			<table className="table">
				<tbody>
					<tr>
						<td colSpan="3">
							<button className="btn-circle newChat" onClick={this.onAddClick}>+</button>
							<span><Translation textId="web.group.participants.invite.button"/></span>
						</td>
					</tr>
					{this.renderParticipants()}
					{this.renderInvites()}
				</tbody>
			</table>
		);
	}

	render() {
		return (
			<div className={this.props.className}>
				<div className="invite">
					<div className="title">
						<Translation textId="web.group.participants.text"/>
					</div>
					<div className="groups-table">
						{this.renderTable()}
					</div>
				</div>
			</div>
		);
}

	static get propTypes() {
		return {
			contact: React.PropTypes.object.isRequired,
			onAdd: React.PropTypes.func.isRequired
		};
	}
}

class Participant extends React.Component {
  constructor() {
    super();
    this._service = remoteServiceLocator();
    this.onRenameClick = this.onRenameClick.bind( this );
    this.onDeleteClick = this.onDeleteClick.bind( this );
    this.state = {};
  }

  onRenameClick() {
    //This is not implemented but is ready to implement
    //TODO: remove or fully implement. Need discuss
    this.setState( {
      popup: () => <Prompt
        titleTextId=""
        defaultValue={ this.props.nickname }
        onDone={ this.doRename }
      />
    } );
  }

  doRename( newNickname ) {
    let {pid, contact} = this.props;
    if ( this.state.isInProgress ) {
      return;
    }
    if ( !newNickname ) {
      this.setState( {popup: null} );
      return;
    }
    this.setState( { isInProgress: true } );
    this._service.renameGroupParticipantAsync( contact, pid, newNickname )
      .subscribe( () => {
        this.setState( {popup: null, isInProgress: false} );
      } );
  }

  onDeleteClick() {
    this.setState( {
      popup: () => <Confirm
        titleTextId="web.group.participant.delete.title"
        yesButtonTextId="web.group.participant.delete.button.yes"
        onDone={ this.doDelete.bind( this ) }
      />
    } );
  }

  doDelete( isConfirmed ) {
    let {pid, contact} = this.props;
    if ( this.state.isInProgress ) {
      return;
    }
    if ( !isConfirmed ) {
      this.setState( {popup: null} );
      return;
    }
    this.setState( { isInProgress: true } );
    this._service.deleteGroupParticipantAsync( contact, pid )
      .subscribe( () => {
        this.setState( {popup: null, isInProgress: false} );
      } );
  }

  renderPopup() {
    if ( !this.state.popup ) {
      return null;
    }
    return this.state.popup();
  }

  render() {
    let {nickname} = this.props;
    let initials = getContactInitials( nickname );
    return (
        <tr>
          <td>
            <div className="btn-circle blue">{initials}</div>
            <span>
              {nickname}
              <div className="date"></div>
            </span>
          </td>
          {/*<td>
              <a href="javascript:;" onClick={this.onRenameClick}>
                <img src="web/img/ico-36.svg" alt="Rename" />
              </a>
          </td>*/}
          <td className="text-center">
              <a href="javascript:;" onClick={this.onDeleteClick}>
                <img src="web/img/ico-37.svg" alt="Delete" />
              </a>
              {this.renderPopup()}
          </td>
        </tr>
    );
  }
}

class Invite extends Participant {
  doDelete( isConfirmed ) {
    let {pid, contact} = this.props;
    if ( this.state.isInProgress ) {
      return;
    }
    if ( !isConfirmed ) {
      this.setState( {popup: null} );
      return;
    }
    this.setState( { isInProgress: true } );
    this._service.deleteGroupInviteAsync( contact, pid )
      .subscribe( () => {
        this.setState( {popup: null, isInProgress: false} );
      } );
  }
}

export default ParticipantsContentView;
