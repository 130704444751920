import Rx from "rx";
import ssgCrypto,{KEY_KINDS} from "ssg.crypto";

import OneWayReceiver from "./oneway.receiver.js";
import { receiveMessageAsync, sendMessageAsync } from "../models/message/technical.js";
import configuration from "../../common/configuration.js";
import {callUntilSuccessAsync} from "../../common/utils.js";

class MailboxReceiver {
	constructor( token, startIndex, onMessage, onIndexUpdate, onError ) {
		if ( typeof token !== "string" ) {
			throw new Error( "Mailbox receiver token required to be a string" );
		}

		if ( typeof startIndex !== "number" ) {
			throw new Error( "Mailbox startIndex required to be a number" );
		}

		if ( typeof onMessage !== "function" ) {
			throw new Error( "Mailbox onMessage required to be a function" );
		}

		if ( typeof onIndexUpdate !== "function" ) {
			throw new Error( "Mailbox onIndexUpdate required to be a function" );
		}
		this._indexSubj = new Rx.BehaviorSubject( startIndex );
		this._connectionAsync = new Rx.ReplaySubject( 1 );
		this._createConnectionSubscription = (
			receiveMessageAsync( token )
				.subscribe( ( { apiUrlBase, connectionId, dhPrivKey,
					encryptionSaltKey, macSaltKey, econfig, type } ) => {
					if ( this._isDisposed ) {
						return;
					}
					if ( type !== "mailboxReceiver" ) {
						throw new Error( "Invalid message type for mailbox receiver" );
					}
					console.log('Creating OneWayReceiver', startIndex);
					let connection = new OneWayReceiver( apiUrlBase, connectionId,
						dhPrivKey, encryptionSaltKey,  macSaltKey, startIndex,
						econfig, onMessage
					);
					connection.onIndexUpdate( index => {
						this._onIndexChange( index );
						return onIndexUpdate( index );
					} );
					this._connectionAsync.onNext( connection );
					this._connectionAsync.onCompleted();
				}, onError )
		);
	}

	waitUntilAllProcessedAsync( ) {
		return (
			this._getMaxIndexAsync()
				.tap( maxIndex => { console.log('maxIndex', maxIndex); } )
				.flatMap( index => this._indexSubj.getValue() === index + 1
					? Rx.Observable.just( null )
					: this._indexSubj.skip( index - this._indexSubj.getValue() + 1 )
						.take( 1 )
						.flatMap( () => this.waitUntilAllProcessedAsync() )
				)
		);
	}

	_getMaxIndexAsync( ) {
		return (
			this._connectionAsync
				.flatMap( connection => connection.getMaxIndexAsync() )
		);
	}

	dispose( ) {
		if ( this._isDisposed ) {
			throw new Error( "Double dispose" );
		}
		this._connectionAsync.subscribe( connection => {
			connection.dispose();
		} );
		this._connectionAsync.onCompleted();
		this._createConnectionSubscription.dispose();
		this._isDisposed = true;
	}

	_onIndexChange( index ) {
		this._indexSubj.onNext( index + 1 );
	}

	static createNewMailboxDataAsync( econfig ) {
		return Rx.Observable.fromPromise(
			Promise.all( [
				ssgCrypto.createRandomBase64StringThen( 32 ),
				ssgCrypto.createRandomKeyExchangeKeyPairThen( econfig ),
				ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, econfig ),
				ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, econfig )
			] )
			.then( ( [ connectionId, { privateKey, publicKey }, encryptionSaltKey, macSaltKey ] ) =>
				( {
					connectionIdSender: ssgCrypto.hash( new Buffer( connectionId, "base64" ) ).toString( "base64" ),
					connectionIdReceiver: connectionId,
					dhPubKey: publicKey,
					dhPrivKey: privateKey,
					encryptionSaltKey,
					macSaltKey
				} )
			)
		);
	}

	static createSenderTokenAsync( apiUrlBase, econfig, mailboxData, senderAdditionalData ) {
		return sendMessageAsync( {
			apiUrlBase,
			connectionId: mailboxData.connectionIdSender,
			dhPubKey: mailboxData.dhPubKey,
			encryptionSaltKey: mailboxData.encryptionSaltKey,
			macSaltKey: mailboxData.macSaltKey,
			econfig: econfig,
			type: "mailboxSender",
			...senderAdditionalData
		} );
	}

	static createReceiverTokenAsync( apiUrlBase, econfig, mailboxData ) {
		return sendMessageAsync( {
			apiUrlBase,
			connectionId: mailboxData.connectionIdReceiver,
			dhPrivKey: mailboxData.dhPrivKey,
			encryptionSaltKey: mailboxData.encryptionSaltKey,
			macSaltKey: mailboxData.macSaltKey,
			econfig,
			type: "mailboxReceiver"
		} );
	}

	static createTokenPairAsync( apiUrlBase, econfig, senderAdditionalData ) {
		return (
			MailboxReceiver.createNewMailboxDataAsync( econfig )
				.flatMap( mailbox => Rx.Observable.combineLatest(
					MailboxReceiver.createSenderTokenAsync( apiUrlBase, econfig, mailbox, senderAdditionalData ),
					MailboxReceiver.createReceiverTokenAsync( apiUrlBase, econfig, mailbox ),
					( senderToken, receiverToken ) => {
						mailbox.dhPrivKey.dispose();
						mailbox.dhPubKey.dispose();
						mailbox.encryptionSaltKey.dispose();
						mailbox.macSaltKey.dispose();
						return { senderToken, receiverToken };
					}
				) )
		);
	}
}

export default MailboxReceiver;
