"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _tweetnacl = require("tweetnacl");

var _tweetnacl2 = _interopRequireDefault(_tweetnacl);

var _verify = require("../abstract/verify.js");

var _verify2 = _interopRequireDefault(_verify);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Ed25519Verify = function (_AbstractVerify) {
    _inherits(Ed25519Verify, _AbstractVerify);

    function Ed25519Verify() {
        _classCallCheck(this, Ed25519Verify);

        return _possibleConstructorReturn(this, (Ed25519Verify.__proto__ || Object.getPrototypeOf(Ed25519Verify)).apply(this, arguments));
    }

    _createClass(Ed25519Verify, [{
        key: "getPublicKeyByteLength",
        value: function getPublicKeyByteLength() {
            return _tweetnacl2.default.sign.publicKeyLength;
        }
    }, {
        key: "getSignByteLength",
        value: function getSignByteLength() {
            return _tweetnacl2.default.sign.signatureLength;
        }
    }, {
        key: "setPublicKey",
        value: function setPublicKey(publicKey) {
            if (!Buffer.isBuffer(publicKey)) {
                throw new Error("publicKey required");
            }
            this._publicKey = publicKey;
        }
    }, {
        key: "verifyThen",
        value: function verifyThen(_ref) {
            var _this2 = this;

            var signature = _ref.signature,
                binarySource = _ref.binarySource;

            if (!this._publicKey) {
                throw new Error("publicKey not set");
            }
            return binarySource.getBufferThen().then(function (message) {
                return _tweetnacl2.default.sign.detached.verify(message, signature, _this2._publicKey);
            });
        }
    }], [{
        key: "getPublicKeyByteLength",
        value: function getPublicKeyByteLength() {
            return _tweetnacl2.default.sign.publicKeyLength;
        }
    }]);

    return Ed25519Verify;
}(_verify2.default);

exports.default = Ed25519Verify;