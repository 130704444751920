	import React from "react";
import TranslationBase from "../abstract/translation.base.js";

class TranslationRu extends TranslationBase {
	constructor() {
		super( "ru", "Русский", {
			"empty":                                     "",
			"logout.soon":                               "Скоро выйдем",
			"voice.recording.text":                      "Запись аудио в процессе.",
			"voice.recording.cancel":                    "Отмена",
			"voice.recording.send":                      "Отправить",
			"voice.recording.init":                      "Подготовка",
			"voice.recording.recorded":                  "Запись окончена",
			"voice.recording.timeout":                   msLeft => `Ваше сообщение будет отправлено автоматически через ${ ( msLeft / 1000 ) | 0 } секунд`,
			"voice.message.unsupported":                 "Голосовые сообщения не поддерживаются на этом устройстве",
			"voice.recording.error.return":              "Вернуться к чату",
			"warn.expiring.header":                      "Истекает срок оплаты",
			"warn.expiring.text":                        days =>
																		days % 10 === 1 && days !== 11 ? `У Вас осталось меньше, чем ${days} день, чтобы оплатить использование ${process.env.APP_NAME}. Активировать сейчас?`
																		: days % 10 === 0 ?`У Вас осталось меньше ${days}-и дней, чтобы оплатить использование ${process.env.APP_NAME}. Активировать сейчас?`
																		: `У Вас осталось меньше ${days}-х дней, чтобы оплатить использование ${process.env.APP_NAME}. Активировать сейчас?`,
			"warn.expiring.button":                      "Продолжить",
			"warn.expired.header":                       "Время оплаты истекло",
			"warn.expired.text":                         "Пожалуйста, заплатите за использование ${process.env.APP_NAME}. Пока что Вы не сможете писать сообщения.",
			"warn.expired.button":                       "Продолжить",
			"notification.title":                        `${process.env.APP_NAME} в фоне`,
			"registration.header":                       <span style={{fontSize: "80%"}}>Перед началом работы</span>,
			"registration.text":                         "Важно: введённые данные не передаются на сервер, а хранятся на Вашем устройстве в зашифрованном виде",
			"upgrade.header":                            "Полная версия",
			"upgrade.text":                              "",
			"upgrade.password.placeholder":              "ПАРОЛЬ(не обязательно)",
			"upgrade.password.tooltip":                  <span>Пароль от Вашего дополнительного аккаунта</span>,
			"upgrade.fake.checkbox":                     "У Вас есть дополнительный аккаунт?",
			"upgrade.fake.text":                         "",
			"upgrade.continue.button":                   "ПРОДОЛЖИТЬ",
			"invite.code.placeholder":                   "Код приглашения",
			"invite.code.paste.button":                  "Вставить код приглашения",
			"registration.main.nickname.placeholder":    "Имя",
			"registration.main.nickname.tooltip":        "Так Вас будут видеть собеседники",
			"registration.main.password.placeholder":    "Пароль",
			"registration.main.password.tooltip":        <span>Пароль может содержать комбинацию любых символов из доступных раскладок клавиатуры</span>,
			"registration.fake.checkbox":                "Дополнительный аккаунт",
			"registration.fake.text":                    `${process.env.APP_NAME}  предоставляет возможность скрыть реальную переписку, если это необходимо. Для этого Вы можете создать ещё один, дополнительный, аккаунт, который не будет содержать компрометирующих данных, и в случае необходимости  показать его вместо основного`,
			"registration.fake.warning":                 <span>ВНИМАНИЕ!<br/>Используйте разные пароли для основного и дополнительного аккаунтов!</span>,
			"registration.continue.button":              "Продолжить",
			"registration.skip.button":                  "Пропустить",
			"registration.restore.button":               "Восстановить резервную копию",
			"registration.selection.header":             "Тип регистрации",
			"registration.selection.text":               <span>
			Все данные (сообщения, логины, пароли) обеспечены надёжным шифрованием, и возможность их дешифровки сервером {process.env.APP_NAME} отсутствует.
			Нам доступны лишь метаданные, а именно – информация о факте отправки сообщения адресату (но не содержимое сообщения).
			<br />
				Метаданные – необходимый минимум информации, требуемый для осуществления общения.
			<br />
				Вы можете использовать собственный сервер для работы с {process.env.APP_NAME}. В этом случае доступ к метаданным будете иметь лишь Вы и согласованные представители Вашей организации.
			</span>,
			"registration.selection.public.button":      "Публичный сервер",
			"registration.selection.private.button":     "Активировать лицензию",
			"registration.selection.demo.button":        "Бесплатная версия",
			"demo.expired.text":                         "Демонстрационный режим окончен. Необходимо оплатить.",
			"demo.dialog.text":                          days =>
																			( days % 10 === 1 && days !== 11 ) ? `Демонстрационная версия перестанет работать через ${days} день. Активировать сейчас?` :
																			( days % 10 === 2 && days !== 12 ) ? `Демонстрационная версия перестанет работать через ${days} дня. Активировать сейчас?` :
																			`Демонстрационная версия перестанет работать через ${days} дней. Активировать сейчас?`,
			"demo.dialog.title":                         "Демонстрационный режим",
			"demo.dialog.upgrade":                       "Активировать",
			"demo.dialog.close":                         "Закрыть",
			"demo.dialog.complete":                      `Активация успешна. Теперь вы пользуетесь полной версией ${process.env.APP_NAME}`,
			"restore.do.button":                         "Восстановить",
			"restore.header":                            "Восстановление",
			"restore.text":                              "",
			"restore.code.placeholder":                  "Введите код восстановления",
			"restore.code.error":                        "Неверный код восстановления",
			"restore.code.tooltip":                      "Вставьте код восстановления из буфера обмена или используйте QR-сканирование",
			"restore.code.paste.button":                 "Вставить код из буфера обмена",
			"restore.progress":                          "Восстановление записи #{0} из {1}",
			"rejoin.header":                             "Защита данных",
			"rejoin.text":                               `Для обеспечения надёжной защиты учётной записи ${process.env.APP_NAME} системой будет произведена замена долговременных криптографических ключей. Для начала процесса введите пароль от основного и, при его наличии, дополнительного аккаунтов`,
			"rejoin.main.password.placeholder":          "Пароль от основного аккаунта",
			"rejoin.main.password.tooltip":              "Пароль от основного аккаунта",
			"rejoin.fake.password.placeholder":          "Пароль от дополнительного аккаунта",
			"rejoin.fake.password.tooltip":              "Пароль от дополнительного аккаунта",
			"rejoin.main.warning":                       "Пароль от основного аккаунта введён неверно",
			"rejoin.fake.warning":                       "Пароль от дополнительного аккаунта введён неверно",
			"rejoin.equals.warning":                     "Пароли от основного и дополнительного аккаунтов совпадают. Пожалуйста, введите другой пароль",
			"rejoin.do.button":                          "Продолжить",
			"login.header":                              "Вход",
			"login.password.placeholder":                "Пароль",
			"login.password.tooltip":                    "Введите пароль",
			"login.continue.button":                     "Продолжить",
			"login.invalidpassword":                     "Пароль введён неверно",
			"invite.duplicate":                          "У Вас уже есть контакт с таким именем",
			"nickname.empty":                            "Поле ввода не может быть пустым",
			"shake.header":                              "Ключи...",
			"shake.text":                                <span>
			                                                — Шифрование информации происходит с использованием случайных данных, собираемых {process.env.APP_NAME}
														<br />
															Движение телефоном позволит сгенерировать уникальный ключ шифрования
															для Вашего аккаунта
														 </span>,
			"contact.status.disconnected.explanation":   "Аккаунт собеседника был удалён или контакт общего типа был добавлен дважды",
			"chat.expired.explanation":                  `Пожалуйста, оплатите использование ${process.env.APP_NAME}`,
			"contact.status.disconnected":               "Удалён",
			"contact.status.invited":                    "Приглашён",
			"contact.status.joining":                    "Ждём принятия приглашения",
			"contact.status.online":                     "В сети",
			"contact.status.failed":                     <span style={{color: "red"}}>Ошибка</span>,
			"contact.status.offline":                    "Не в сети",
			"contact.failed.message":                    "При добавлении контакта возникла ошибка. Для её устранения удалите контакт и создайте его заново. Рекомендуем сообщить об этой ошибке администратору",
			"footer.contacts":                           "Контакты",
			"footer.groups":                             "Группы",
			"footer.options":                            "Настройки",
			"contacts.header":                           "Контакты",
			"groups.header":                             "Группы",
			"options.header":                            "Настройки",
			"options.nickname":                          "ИМЯ",
			"options.password":                          "ПАРОЛЬ",
			"options.language":                          "ЯЗЫК",
			"options.web":                               "ВЕБ-ВЕРСИЯ",
			"options.cleanuptime":                       "АВТОШРЕДЕР",
			"options.pin":                               <span style={{fontSize: "80%"}}>ЗАПРОС ПАРОЛЯ</span>,
			"options.delete":                            "УДАЛИТЬ АККАУНТ",
			"options.updates":                           "ОБНОВЛЕНИЕ",
			"options.autoclean.header":                  "Автошредер",
			"options.autoclean.smalltext":               "Сообщения будут удаляться после прочтения через этот промежуток времени",
			"options.autoclean.no":                      "нет",
			"options.autoclean.unit.no":                 "-",
			"options.pin.no":                            "нет",
			"options.pin.header":                        "Запрос пароля",
			"options.pin.smalltext":                     "Запрос пароля через некоторое время бездействия. Это улучшает безопасность.",
			"options.autoclean.unit.sec":                "сек.",
			"options.autoclean.unit.min":                "мин.",
			"options.autoclean.unit.hour":               number => number === 1 ? "час" : ( number && number <= 4 ? "часа" : "часов" ),
			"options.autoclean.unit.day":                number => number === 1 ? "день" : ( number && number <= 4 ? "дня" : "дней" ),
			"options.autoclean.unit.year":               number => number === 1 ? "год" : ( number && number <= 4 ? "года" : "лет" ),
			"options.autoclean.continue.button":         "Установить",
			"options.autoclean.notset":                  "Не установлено",
			"options.backup":                            "РЕЗЕРВНАЯ КОПИЯ",
			"options.notifications":                     "ОПОВЕЩЕНИЯ",
			"options.keyboard":                          <span style={{fontSize: "80%"}}>СВОЯ КЛАВИАТУРА</span>,
			"options.upgrade":                           "АКТИВАЦИЯ",
			"invite.nickname.tooltip":                   "Имя контакта в Вашем списке контактов",
			"invite.nickname.placeholder":               "Имя контакта",
			"invite.token.tooltip":                      "Скопировать и отправить код приглашения",
			"invite.token.placeholder":                  "Код приглашения",
			"invite.header":                             "Приглашение",
			"invite.copytoast":                          "Код приглашения скопирован в буфер обмена",
			"invite.qr.bigtext":                         "QR-код приглашения",
			"invite.qr.smalltext":                       "— Покажите этот QR-код собеседнику для сканирования (Меню, `Принять приглашение`)",
			"invite.plain.text":                         "— Вы можете поделиться текстовой версией кода приглашения",
			"inviteUrl.plain.text":                      `— Вы можете поделиться так же отправить ссылку, чтобы ваш собеседник использовал веб-браузер для переписки с вами. Это полезно, если у собеседника не установлен ${process.env.APP_NAME}, но будьте внимательны: ссылка одноразовая.`,
			"invite.button.generate":                    "Пригласить",
			"invite.button.copy":                        "Скопировать в буфер",
			"invite.create.text":                        "— Для добавления контакта создайте приглашение",
			"invite.create.external.text":               "— Для добавления внешнего контакта создайте приглашение. В результате будет создана одноразовая ссылка, по которой Ваш собеседник анонимно перейдет и начнёт с Вами переписку.",
			"invite.hide.checkbox":                      "Не показывать Ваше имя в этом приглашении",
			"sharecontacts.header":                      "Общие контакты",
			"chat.button.send":                          "Отпр.",
			"chat.button.voice":                         "Голос",
			"chat.menu.clear":                           "Очистить чат",
			"chat.menu.clear.remote":                    "Очистить чат собеседника",
			"chat.menu.password":                        "Установить пароль",
			"chat.menu.delete":                          "Удалить контакт",
			"chat.menu.rename":                          "Переименовать контакт",
			"chat.menu.renamegroup":                     "Переименовать группу",
			"chat.menu.send.contacts":                   "Отправить контакты",
			"chat.menu.send.files":                      "Отправить файлы",
			"chat.menu.send.photo":                      "Отправить фото",
			"chat.clear.confirm":                        "Очистить чат?",
			"chat.clear.confirm.button.yes":             "Очистить",
			"chat.clear.confirm.remote":                 "Очистить чат у собеседника?",
			"chat.delete.confirm":                       "Удалить этот контакт?",
			"chat.fingerprint.label":                    "Отпечаток",
			"chat.randompool.label":                     "Random pool",
			"chat.warning":                              <div className="warning">Проверка кода<br/>на соответствие</div>,
			"chat.warning.button":                       "Нажмите после проверки",
			"chat.group.menu.clear":                     "Очистить чат",
			"chat.group.menu.delete":                    "Выйти",
			"chat.group.menu.invite":                    "Добавить участников",
			"chat.group.menu.invite.external":           "Внешний участник",
			"chat.group.menu.participants":              "Участники группы",
			"chat.group.delete.confirm":                 "После выхода вся история переписки в этой группе будет удалена. Подтверждаете выход?",
			"chat.group.delete.confirm.button.yes":      "Выйти",
			"chat.group.invites.notification":           "Пользователи, не вступившиев группу, не получат Ваше сообщение:",
			"chat.group.empty":                          "Вы одни в группе. Для того, что бы добавить участников, нажмите кнопку меню сверху справа.",
			"chat.empty.text":                           "У Вас пока нет сообщений в этом чате",
			"chat.message.delete":                       "Удалить",
			"chat.message.edit":                         "Редактировать",
			"chat.message.reply":                        "Ответить",
			"chat.message.forward":                      "Переслать",
			"chat.message.edited.text":                  "Отредактировано",
			"chat.message.deleted.text":                 "Сообщение удалено",
			"chat.message.file.text":                    "Отправлен файл:",
			"chat.message.contact.outgoing.text":        "Контакт был отправлен пользователю",
			"chat.message.contact.incoming.text":        "Получен контакт",
			"chat.message.contact.accept.button":        "Добавить контакт",
			"chat.message.contact.accept.failed":        "При добавлении контакта возникла ошибка. Попросите пользователя отправить его заново",
			"chat.message.workgroup.incoming.text":      "Получено приглашение в Рабочую группу",
			"chat.message.workgroup.outgoing.text":      "Приглашение в Рабочую группу отправлено",
			"chat.message.workgroup.accept.button":      "Принять приглашение в Рабочую группу",
			"chat.message.workgroup.unprivileged.text":  "Получено приглашение в Рабочую группу. Участие в Рабочей группе доступно пользователям Привилегированных аккаунтов. Ваш тип аккаунта – Непривилегированный. Для смены типа аккаунта обратитесь к администратору",
			"chat.message.group.incoming.text":          "Получено приглашение в группу",
			"chat.message.group.outgoing.text":          "Приглашение в группу отправлено",
			"chat.message.group.accept.button":          "Принять приглашение в группу",
			"chat.message.file.sending.text":            ( fname, sent, total ) => <span>Отправка файла {fname}. <br />{sent} кб из {total} кб.</span>,
			"chat.message.file.downloading.text":        ( fname, received, total ) => <span>Загрузка файла {fname}.<br />{received} кб из {total} кб.</span>,
			"chat.message.voice.sending.text":           ( sent, total ) => "Отправка голосового сообщения",
			"chat.message.file.downloaded.text":         "Файл загружен {0} и сохранён {1}",
			"chat.message.file.open.button":             "Открыть",
			"chat.message.file.open.error":              "Необходимо установить программу для открытия этого файла",
			"chat.message.file.sending.error":           "При отправке файла возникла ошибка",
			"chat.message.voice.sending.error":          "При отправке голосового сообщения возникла ошибка",
			"chat.message.file.inactive.text":           `Файл получен {0}. Для скачивания используйте веб-версию ${process.env.APP_NAME}`,
			"forward.message.header":                    "Пересылка сообщения",
			"forward.message.text":                      "Выберите кому переслать сообщение",
			"forward.message.button.confirm":            "Переслать",
			"menu.alt":                                  "Меню",
			"contact.rename.header":                     "Переименовать",
			"contact.rename.button.done":                "Всё",
			"contact.rename.text":                       "Переименовать контакт {0}",
			"contact.rename.placeholder":                "Новое имя",
			"contacts.menu.header":                      "Меню",
			"contacts.menu.clear":                       "Очистить всё",
			"contacts.menu.invite":                      "Пригласить контакт",
			"contacts.menu.invite.external":             "Пригласить по ссылке",
			"contacts.menu.useinvite":                   "Принять приглашение",
			"contacts.menu.inviteregistration":          `Пригласить в ${process.env.APP_NAME}`,
			"contacts.menu.clear.confirm":               "Подтвердите полное удаление истории переписки",
			"contacts.menu.share.contacts":              "Создать Рабочую группу",
			"contacts.menu.add-user":                    "Добавить пользователя",
			"contacts.menu.exit":                        "Выйти",
			"contacts.overflow":                         "Превышено возможное количество контактов",
			"contacts.invitealreadyused":                "Данное приглашение уже было использовано",
			"contacts.empty":                            `Список контактов пока пуст. Помните: ${process.env.APP_NAME} никогда не использует личные данные пользователей (номер мобильного телефона, e-mail, профили социальных сетей, прочее). Для добавления контакта нажмите на меню в правом верхнем углу и выберите <Пригласить контакт> или <Принять приглашение>`,
			"contacts.search.empty":                     "Контакты не найдены",
			"contacts.search.placeholder":               "Поиск контактов",
			"contacts.workgroup":                        "Рабочая группа",
			"contacts.workgroup.collapsed":              "Развернуть",
 			"contacts.shared":                           "Общий контакт",
			"contacts.add.assistant":                    "Техподдержка",
			"contacts.add.assistant.text":               `Вы можете добавить контакт Техподдержка. Это живой человек, который ответит на Ваши вопросы по ${process.env.APP_NAME} или примет обратную связь.`,
			"contacts.add.assistant.placeholder":        "Имя нового контакта",
			"contacts.add.assistant.ok.button":          "Добавить",
			"group.rename.header":                       "Переименовать группу",
			"group.rename.text":                         "",
			"group.rename.placeholder":                  "Название группы",
			"groups.empty":                              "Группы пока не созданы. Для создания группы нажмите «Добавить» в правом верхнем углу",
			"groups.search.empty":                       "Группа не найдена",
			"group.new.contacts.empty":                  "Для создания группы необходимы участники",
			"chats.search.placeholder":                  "Поиск чатов",
			"chats.search.empty":                        "Чаты не найдены",
			"useinvite.header":                          "Принять пригл.",
			"useinvite.text":                            "Принять приглашение: для сканирования QR-кода нажмите на значок QR в правом верхнем углу",
			"useinvite.text2":                           "Задайте имя для отображения в вашем списке контактов",
			"useinvite.nickname.placeholder":            "Имя",
			"useinvite.nickname.tooltip":                `Задать имя в списке контактов ${process.env.APP_NAME}`,
			"useinvite.continue.button":                 "Принять",
			"useinvite.code.tooltip":                    "Принять приглашение: для сканирования QR-кода нажмите на значок QR в правом верхнем углу",
			"useinvite.code.error":                      "Код приглашения неверный",
			"options.nickname.header":                   "Ваше имя",
			"options.nickname.text":                     "Имя, которое видят Ваши собеседники при приглашении в список контактов",
			"options.nickname.placeholder":              "Ваше имя",
			"options.nickname.button.done":              "Далее",
			"options.password.header":                   "Смена пароля",
			"options.password.text":                     () => <span>Пароль защищает данные аккаунта в случае попадания устройства к третим лицам. <p>Внимание: так как мы не храним пароль на серверах {process.env.APP_NAME}, то его восстановление в случае утери будет невозможно!</p></span>,
			"options.password.placeholder":              "Новый пароль",
			"options.password.button.done":              "Далее",
			"options.password.duplicate.alert":          "Данный пароль совпадает с одним из уже используемых в с основном или дополнительном аккаунте. Задайте другой пароль",
			"options.password.change.confirm":           "Сменить пароль?",
			"options.delete.confirm":                    `Удалить аккаунт? Пользователи из списка контактов ${process.env.APP_NAME} не будут оповещены об удалении`,
			"options.language.header":                   "Выбор языка",
			"options.update.header":                     "Обновление",
			"options.update.tocurrent.text":             `Вышло обновление ${process.env.APP_NAME}. Обновить мессенджер?`,
			"options.update.tocurrent.installedversion": "Текущая: {0}",
			"options.update.tocurrent.latestversion":    "Новая: {0}",
			"options.update.uptodate.text":              "Ваша версия {0} не требует обновления",
			"options.update.uptodate.noupdates":         `Вы используете актуальную версию ${process.env.APP_NAME}`,
			"options.update.notify.always.option":       "Уведомлять о новой версии",
			"options.update.notify.skip.option":         "Не напоминать про это обновление",
			"options.update.notify.never.option":        "Отключить обновления",
			"options.update.notify.text":                `Укажите, когда ${process.env.APP_NAME} стоит сообщать Вам об обновлениях`,
			"options.update.advanced.button":            "Больше опций >>",
			"options.update.installlatest.button":       "Установить последнюю версию",
			"options.update.tothis.button":              "Установить версию {0}",
			"options.update.versionlist.text":           `Обновить ${process.env.APP_NAME} до новой версии?`,
			"options.update.log.downloading":            "Загружается обновление",
			"options.update.log.download.progress":      ( loaded, total ) => !total ? `Загружено ${loaded} кб` : `Загружено ${loaded} из ${total} кб`,
			"options.update.log.download.completed":     "Готово!",
			"options.update.log.validation.begin":       "Идёт проверка пакета…",
			"options.update.log.validation.end":         "Проверка завершена",
			"options.update.log.unzip.progress":         ( loaded, total ) => `Распаковываем ${(100 * loaded / total).toFixed( 2 )}%`,
			"options.update.log.unzip.completed":        "Распаковка завершена",
			"options.update.log.applying":               "Применяем обновление…",
			"options.web.existing.text":                 "У Вас есть активная веб-сессия.",
			"options.web.existing.activity":             timestamp => <span>Последняя активность: {timestamp}</span>,
			"options.web.disconnect.button":             "Закрыть сессию",
			"options.web.header":                        "Веб версия",
			"options.web.notification":                  "У Вас есть активная веб-сессия. Нажмите, чтобы завершить",
			"options.web.text":                          `Для доступа к веб-версии введите в адресную строку браузера {0}. Затем, в приложении ${process.env.APP_NAME} зайдите в Меню, выберите пункт Веб-версия и отсканируйте QR-код, нажав на значок QR в правом верхнем углу`,
			"options.backup.header":                     <span style={ { fontSize: "80%" } }>Резервная копия</span>,
			"options.version":                           "ВЕРСИЯ",
			"prompt.default.header":                     "Введите текст",
			"prompt.default.text":                       "Описание значения",
			"prompt.default.placeholder":                "Тут",
			"prompt.default.button.done":                "Готово",
			"options.update.revert.text":                `Вы можете отменить все установленные обновления ${process.env.APP_NAME} до изначально установленной версии`,
			"options.update.revert.button":              "Отменить обновления",
			"options.update.revert.confirm":             `Подтвердите удаление обновлений ${process.env.APP_NAME}!`,
			"common.retry":                              "Повторить попытку",
			"common.loading":                            "Загрузка...",
			"common.wait.title":                         "Подождите...",//( total, current ) => total ? `Подождите... ${current}/${total}` : "Подождите...",
			"common.error":                              "Ошибка",
			"common.network.error":                      "Отсутствует связь с сервером",
			"common.noInternet.notification":            "Подключаемся к интернету...",
			"common.contact.name.empty":                 "",
			"show.password":                             "Показать пароль",
			"dialogs.paste":                             "Вставить",
			"dialogs.copy":                              "Копировать",
			"dialogs.clear":                             "Очистить",
			"dialogs.copied":                            "Скопировано в буфер",
			"dialogs.share":                             "Отправить",
			"dialog.title.alert":                        "Ошибка",
			"dialog.ok":                                 "Ок",
			"dialog.confirm":                            "Подтвердить",
			"dialog.retry":                              "Повторить",
			"dialog.cancel":                             "Отменить",
			"remove.account.data":                       "Удалить данные аккаунта",
			"group.add.nickname.placeholder":            "Имя в группе",
			"group.add.nickname.header":                 "ВАШЕ ИМЯ",
			"group.add.nickname.text":                   "Так Вас будут видеть участники группы",
			"group.add.name.placeholder":                "Название группы",
			"group.add.name.header":                     "Название",
			"group.add.name.text":                       "Введите название новой группы",
			"group.participantlist.header":              "Участники",
			"group.add.participants.header":             "Добавление участников",
			"group.add.participants.text":               "Добавление участников",
			"group.add.participants.button":             "Добавить участников",
			"group.add.participants.button.empty":       "Не добавлять в группу",
			"group.participantlist.invites":             "Приглашены:",
			"group.invite.delete.confirm":               "Удалить приглашение?",
			"workgroup.add.nickname.placeholder":        "Ваше имя в группе",
			"workgroup.add.nickname.header":             "Введите своё имя",
			"workgroup.add.nickname.text":               "Так Вас будут видеть участники Рабочей группы",
			"sharedcontacts.add.workgroup.header":       <span style={{fontSize: "70%"}}>Рабочая группа</span>,
			"sharedcontacts.add.workgroup.text":         "Добавление контактов в Рабочую группу. Участники Рабочей группы наряду с Вами смогут вести переписку с общими контактами",
			"sharedcontacts.add.contacts.header":        "Общие контакты",
			"sharedcontacts.add.contacts.text":          "Добавление общих контактов в Рабочую группу. Участники Рабочей группы, с которыми Вы будете вести переписку.",
			"sharedcontacts.add.name.placeholder":       "Название группы",
			"sharedcontacts.add.name.header":            <span style={{fontSize: "53%"}}>Создать Рабочую группу</span>,
			"sharedcontacts.add.name.text":              `Идёт создание Рабочей группы. Рабочая группа позволяет всем её участникам вести синхронизированную переписку в рамках общего списка пользователей. Участники Рабочей группы могут самостоятельно добавить пользователей из своего списка контактов ${process.env.APP_NAME} в общий`,
			"sharedcontacts.add.nickname.placeholder":   "Имя в Рабочей группе",
			"sharedcontacts.add.nickname.header":        "Ваше имя",
			"sharedcontacts.add.nickname.text":          "Чтобы каждый раз не вводить имя, задайте его по умолчанию в настройках",
			"sharedcontacts.dashboard.header":           <span style={{fontSize: "70%"}}>Рабочая группа</span>,
			"sharedcontacts.workgroup.edit.header":      <span style={{fontSize: "70%"}}>Рабочая группа</span>,
			"sharedcontacts.dashboard.workgroup.edit":   "Рабочая группа",
			"sharedcontacts.dashboard.addcontacts":      "Добавить контакты",
			"sharedcontacts.dashboard.participant":      "Участник имеет право:",
			"sharedcontacts.dashboard.allowworkgroupedit":"Редактировать Рабочую группу",
			"sharedcontacts.dashboard.allowcontactsedit":"Контакты",
			"sharedcontacts.dashboard.setrights.button": "Сохранить",
			"sharedcontacts.dashboard.workgroup.rights": "Права",
			"sharedcontacts.dashboard.workgroup.rights.text": "Укажите, кто из пользователей Рабочей группы будет иметь право её редактировать, а кто – добавлять и удалять общие контакты",
			"sharedcontacts.dashboard.workgroup.add":    "Добавить",
			"sharedcontacts.dashboard.workgroup.participant.delete": p => `Удалить участника ${p?p.nickname:""}?`,
			"sharedcontacts.dashboard.workgroup.participant.delete.yes": "Удалить",
			"sharedcontacts.dashboard.workgroup.invite.delete": p => `Удалить приглашённого участника ${p?p.nickname:""}?`,
			"sharedcontacts.dashboard.multiinvite":      "Посмотреть визитку",
			"sharedcontacts.dashboard.multiinvite.create":"Создать визитку",
			"sharedcontacts.dashboard.autoclean":        "Автошредер",
			"sharedcontacts.dashboard.autoclean.remote": "Автошредер у собеседников",
			"sharedcontacts.workgroup.edit.invites":     "Приглашены:",
			"sharedcontacts.search.placeholder":         "Поиск общих контактов",
			"sharedcontacts.search.empty":               "Контакты не найдены",
			"send.contacts.header":                      "Отправить контакты",
			"send.contacts.text":                        "Выберите контакты для отправки. Данный способ обеспечивает конфиденциальность и не требует личной встречи",
			"access.denied":                             "Отказано в доступе",
			"multiinvite.delete.button":                 "Удалить",
			"web.common.confirm.button.no":              "Отмена",
			"web.contact.menu.rename":                   "Переименовать",
			"web.contact.menu.delete":                   "Удалить",
			"web.contact.menu.send.contact":             "Поделиться контактом",
			"web.contacts.menu.send.contacts":           "Отправить контакты",
			"web.contacts.menu.clear.history":           "Очистить историю",
			"web.contacts.menu.clear.remotehistory":     "Очистить историю у собеседника",
			"web.contacts.menu.invite":                  "Пригласить",
			"web.contacts.menu.invite.external":         "Внешний контакт",
			"web.contacts.invite.content.absent":        "Пользователь ещё не принял приглашение",
			"web.contacts.menu.acceptInvite":            "Принять приглашение",
			"web.contacts.menu.businessCards":           "Визитка",
			"web.contact.rename.title":                  "Переименовать контакт",
			"web.contact.delete.title":                  "Удалить контакт",
			"web.contact.delete.button.yes":             "Удалить",
			"web.group.rename.title":                    "Имя группы",
			"web.group.exit.title":                      "Выйти из группы",
			"web.group.exit.button.yes":                 "Выйти",
			"web.group.menu.rename":                     "Переименовать",
			"web.group.menu.exit":                       "Выйти",
			"web.group.menu.participants":               "Участники",
			"web.chats.create":                          "Создать новый чат с...",
			"web.chats.title":                           "Чаты",
			"web.contacts.loading":                      "Идёт загрузка...",
			"web.common.prompt.close.button":            "Закрыть",
			"web.common.prompt.save.button":             "Сохранить",
			"web.accept.invite.text1":                   "Добавление нового контакта с использованием приглашения",
			"web.accept.invite.placeholder":             "Вставьте полученный код из буфера обмена или введите его вручную",
			"web.accept.invite.text2":                   "Вы можете переименовать контакт и задать имя по умолчанию",
			"web.accept.invite.add.button":              "Добавить в список",
			"web.accept.invite.title":                   "Принять приглашение",
			"web.invite.text1":                          "Покажите собеседнику QR-код для сканирования",
			"web.invite.text2":                          `Отправьте текстовую версию приглашения. Важно, чтобы приглашение попало
                                                          только к нужному Вам адресату.
                                                          `,//Для обеспечения гарантии безопасности обязательно сверьте QR-коды в приложении!
		  "web.invite.text3":                          `Отправьте адрес для веб-браузера.
														  Только учтите, что этот адрес одноразовый и если ваш собеседник закроет браузер, он не сможет возвратиться в чат.
														  Сделано это для безопасности - если кто-то перехватит ссылку, он не сможет ею воспользоваться.`,
			"web.invite.copied.toast":                   "Скопировано",
			"web.invite.copy_failed.toast":              "Нажмите Ctrl+C для копирования",
			"web.invite.copy.button":                    "Скопировать в буфер",
			"web.contacts.title":                        "Контакты",
			"web.contact.loading.text":                  "Загрузка контакта...",
			"web.newinvite.text1":                       `Вы создаёте приглашение в список контактов`,
			"web.newinvite.name.text":                   "Введите имя нового контакта",
			"web.newinvite.hide.name.label":             "Не показывать Ваше имя в этом приглашении",
			"web.newinvite.button":                      "Пригласить",
			"web.newinvite.title":                       "Новое приглашение",
			"web.send.contacts.loading.text":            "Загрузка...",
			"web.send.contacts.button":                  "Отправить",
			"web.send.contacts.title":                   "Отправка контактов",
			"web.send.contacts.progress.text":           "Идёт отправка контактов. Это может занять время, которое зависит от количества отправляемых контактов",
			"web.group.create.name":                     "Название группы:",
			"web.group.create.nickname":                 "Ваше имя в группе:",
			"web.group.create.name.placeholder":         "Название",
			"web.group.create.nickname.placeholder":     "Имя",
			"web.group.create.participants":             "Участники:",
			"web.group.create.button":                   "Создать группу",
			"web.group.create.title":                    "Создать новую группу",
			"web.group.create.progress.text":            "Идёт создание группы. Это может занять время, зависящее от количества добавляемых участников",
			"web.group.loading":                         "Загрузка группы...",
			"web.groups.list.empty":                     <p>У Вас пока нет групп в {process.env.APP_NAME}.<br />Самое время их создать!</p>,
			"web.groups.loading.text":                   "Загрузка...",
			"web.groups.title":                          "Группы",
			"web.chats.list.empty":                      <p>У Вас пока нет чатов {process.env.APP_NAME}.<br />Самое время их создать!</p>,
			"web.contacts.list.empty":                   <p>У Вас пока нет контактов {process.env.APP_NAME}.<br />Самое время их создать!</p>,
			"web.account.nickname.label":                "Ваше имя",
			"web.account.nickname.text":                 "Имя, которое видят Ваши собеседники при приглашении в список контактов",
			"web.account.notification.header":           "Настройки уведомлений",
			"web.account.notification.vibrate":          "Вибрация при входящих сообщениях",
			"web.account.notification.incoming":         "Звук входящих сообщений",
			"web.account.notification.outgoing":         "Звук отправки сообщений",
			"web.account.fakeaccount.header":            "Дополнительный аккаунт",
			"web.account.fakeaccount.text":              `${process.env.APP_NAME} предоставляет возможность скрыть реальную переписку, если это необходимо. Для этого Вы можете создать ещё один, дополнительный, аккаунт, который не будет содержать компрометирующих данных, и в случае необходимости  показать его вместо основного`,
			"web.account.fakeaccount.create.button":     "Создать дополнительный аккаунт",
			"web.account.backup.header":                 "Резервная копия данных",
			"web.account.backup.text":                   "Резервная копия позволит использовать данные текущего аккаунта при переустановке приложения, утере или смене устройства",
			"web.account.backup.create.button":          "Создать резервную копию",
			"web.account.delete.header":                 "Удалить аккаунт",
			"web.account.delete.text":                   `Удаление аккаунта приведёт к очищению списка контактов ${process.env.APP_NAME}, при этом пользователи из него не будут оповещены об удалении. Если Вы хотите оповестить кого-то об удалении Вашего аккаунта, Вам стоит сделать это вручную до удаления учётной записи ${process.env.APP_NAME}`,
			"web.account.delete.button":                 "Удалить аккаунт",
			"web.account.delete.prompt.title":           "Удаление аккаунта",
			"web.account.delete.prompt.text":            "Дополнительный аккаунт будет удалён вместе с основным!",
			"web.account.delete.prompt.button.yes":      "Удалить",
			"web.account.delete.progress.title":         "Удаляем",
			"web.account.delete.progress.text":          "Подождите чуть-чуть",
			"web.account.title":                         "Аккаунт",
			"web.application.update.head":               "Обновление",
			"web.application.update.text":               `Вы можете обновить приложение самостоятельно или включить автообновление. При автоматическом обновлении ПО ${process.env.APP_NAME} данные учётной записи сохранятся`,
			"web.application.update.autoupdate.label":   "Включить автообновление",
			"web.application.update.currentversion":     "Текущая версия {0}",
			"web.application.update.button":             "Обновить",
			"web.application.invite.head":               "Приглашение",
			"web.application.invite.text":               `Хотите начать диалог с кем-то, кто ещё не установил ${process.env.APP_NAME}? Отправьте ему приглашение!`,
			"web.application.invite.button":             "Пригласить",
			"web.application.language.head":             "Выбор языка",
			"web.application.language.text":             `Пока ${process.env.APP_NAME} доступен на русском и английском языках, но мы работаем над расширением списка локализаций!`,
			"web.application.title":                     "Приложение",
			"web.info.title":                            "Информация",
			"web.notifications.title":                   "Оповещения",
			"web.notifications.content":                 "Для работы оповещений о новых сообщениях необходимо в браузере разрешить отправлять оповещения.",
			"web.settings.menu.account":                 "Аккаунт",
			"web.settings.menu.account.text":            <span>Ваше имя, настройки уведомлений,<br />создание и удаление аккаунтов, резервные копии</span>,
			"web.settings.menu.security":                "Безопасность",
			"web.settings.menu.security.text":           <span>Создание и удаление паролей<br />и истории переписки</span>,
			"web.settings.menu.application":             "Приложение",
			"web.settings.menu.application.text":        <span>Обновление {process.env.APP_NAME}, приглашение<br />друзей, покупка и активация <br />ваучера, выбор языка</span>,
			"web.settings.menu.info":                    "Информация",
			"web.settings.menu.info.text":               "Веб-версия, F.A.Q. и обратная связь",
			"web.settings.menu.notifications":           "Оповещения",
			"web.settings.menu.notifications.on":        "Включено",
			"web.settings.menu.notifications.off":       "Выключено",
			"web.settings.menu.notifications.switch":    "Включить оповещения в браузере",
			"web.settings.menu.notifications.warn":      "Браузер запретил отправку уведомлений. Пожалуйста, включите уведомления в настройках браузера.",
			"web.settings.menu.users":                   "Пользователи",
			"web.settings.menu.users.text":              "Все пользователи системы",
			"web.settings.title":                        "Настройки",
			"web.security.password.label":               "Пароль",
			"web.security.password.text":                `Пароль обеспечит неприкосновенность данных аккаунта. Внимание: так как мы не храним данные пароля на серверах ${process.env.APP_NAME}, то его восстановление в случае утери будет невозможно! Также данный пароль используется для блокировки экрана`,
			"web.security.password.placeholder":         "Пароль",
			"web.security.password.show.label":          "Показать пароль",
			"web.security.password.change.button":       "Изменить пароль",
			"web.security.autolock.label":               `Блокировать экран при сворачивании ${process.env.APP_NAME}`,
			"web.security.history.head":                 "Шредер переписки",
			"web.security.history.text":                 "Настройте параметры удаления истории переписки. Будьте уверены в конфиденциальности Ваших данных!",
			"web.security.history.cleanonexit.label":    `Удалять историю при выходе из ${process.env.APP_NAME}`,
			"web.security.history.ttl.label":            "Вся переписка будет автоматически удаляться через определённое время. Задайте интервал удаления",
			"web.security.history.autoclean.unit.no":    "нет",
			"web.security.history.autoclean.unit.sec":   "секунд",
			"web.security.history.autoclean.unit.min":   "минут",
			"web.security.history.autoclean.unit.hour":  number => number == 1 ? "час" : ( number && number <= 4 ? "часа" : "часов" ),
			"web.security.history.autoclean.unit.day":   number => number == 1 ? "день" : ( number && number <= 4 ? "дня" : "дней" ),
			"web.security.history.autoclean.unit.year":  number => number == 1 ? "год" : ( number && number <= 4 ? "года" : "лет" ),
			"web.security.history.cleannow.label":       "Немедленно удалить историю переписки?",
			"web.security.history.cleannow.button":      "Удалить историю",
			"web.security.title":                        "Безопасность",
			"web.fakeaccount.create.title":              "Дополнительный аккаунт",
			"web.fakeaccount.create.text":               <p>{process.env.APP_NAME}  предоставляет возможность скрыть реальную переписку, если это необходимо. Для этого Вы можете создать ещё один, дополнительный, аккаунт, который не будет содержать компрометирующих данных, и в случае необходимости  показать его вместо основного</p>,
			"web.fakeaccount.create.button":             "Создать дополнительный аккаунт",
			"web.fakeaccount.name.placeholder":          "Имя",
			"web.fakeaccount.password.placeholder":      "Пароль",
			"web.fakeaccount.dulplicatePassword.toast":  "Пароли основного и дополнительного аккаунтов совпадают. Пожалуйста, введите другой пароль",
			"web.security.dulplicatePassword.toast":     "Данный пароль уже используется, введите другой пароль",
			"web.security.passwordChanged.toast":        "Пароль успешно изменён",
			"web.security.clearHistory.title":           "Очистка истории",
			"web.security.clearHistory.text":            "Вы уверены, что хотите очистить всю историю переписки? Восстановление данных будет невозможно!",
			"web.security.clearHistory.button.yes":      "Очистить",
			"web.message.placeholder":                   "Новое сообщение",
			"web.contacts.add.modal.title":              "Добавьте новый контакт",
			"web.contacts.add.modal.cancel.button":      "ОТМЕНИТЬ",
			"web.contacts.add.invite.menuitem":          "Пригласить",
			"web.contacts.add.accept.menuitem":          "Принять приглашение",
			"web.contacts.add.external.menuitem":        "Внешний контакт",
			"web.menu.chats":                            "Чаты",
			"web.menu.contacts":                         "Контакты",
			"web.menu.groups":                           "Группы",
			"web.menu.sharedcontacts":                   "Рабочие группы",
			"web.menu.settings":                         "Настройки",
			"web.notification.text":                     "Веб-версия",
			"web.sharedcontacts.loading":                "Загрузка...",
			"web.sharedcontacts.title":                  "Рабочие группы",
			"web.sharedcontacts.list.empty":             <p>Рабочие группы {process.env.APP_NAME} пока не созданы<br />
			                                                Создать Рабочую группу?</p>,
			"web.helper.prompt.title":                   "Имя контакта-ассистента",
			"web.helper.prompt.default":                 "Ассистент",
			"web.helper.title":                          "Ассистент",
			"web.helper.text":                           "Вы можете анонимно общаться с агентом нашей службы поддержки. Для этого добавьте его в контакты, нажав кнопку:",
			"web.helper.button":                         "Добавить ассистента",
			"web.file.download":                         "Загрузить",
			"web.file.save":                             "Сохранить",
			"web.file.save.mobsafari":                   `Сделайте длительное нажатие на этом тексте и выберите в открывшемся меню "Открыть в новой вкладке/фоновом режиме"`,
			"web.file.open":                             "Открыть",
			"web.file.header":                           "Файл",
			"web.sharedcontacts.create.nickname.text":   "Задайте имя в Рабочей группе. Установки по умолчанию доступны в настройках",
			"web.sharedcontacts.create.nickname.placeholder":"Ваше имя",
			"web.sharedcontacts.create.name.text":       "Имя, отображаемое у Ваших собеседников в списке контактов",
			"web.sharedcontacts.create.name.placeholder":"Имя контакта",
			"web.sharedcontacts.create.nickname.prev":   "Отменить",
			"web.sharedcontacts.create.nickname.next":   "Далее",
			"web.sharedcontacts.create.name.prev":       "Задать имя",
			"web.sharedcontacts.create.name.next":       "Далее",
			"web.sharedcontacts.create.workgroup.prev":  "Назад",
			"web.sharedcontacts.create.workgroup.next":  "Далее",
			"web.sharedcontacts.create.rights.prev":     "Назад",
			"web.sharedcontacts.create.rights.next":     "Далее",
			"web.sharedcontacts.create.contacts.prev":   "Назад",
			"web.sharedcontacts.create.contacts.next":   "Далее",
			"web.sharedcontacts.create.workgroup.text":  "Выберите участников для создания Рабочей группы",
			"web.sharedcontacts.create.contacts.text":   "Выберите участников для добавления в список. Каждому из них будет выслано приглашение. Если приглашение будет принято, в списке собеседника появится контакт с именем `{0}`.",
			"web.sharedcontacts.create.rights.text":     "Вы можете задать индивидуальные права для членов Рабочей группы",
			"web.sharedcontacts.create.wait.text":       "Рабочая группа в процессе создания. Это может занять время, зависящее от количества участников и общих контактов",
			"web.multidescription.exit.title":           "Выйти?",
			"web.multidescription.exit.button.yes":      "Выйти",
			"web.multidescription.rename.title":         "Переименование",
			"web.multidescription.menu.rights":          "Права",
			"web.multidescription.menu.rename":          "Переименовать",
			"web.multidescription.menu.workgroup":       "Рабочая группа",
			"web.multidescription.menu.delete":          "Выйти и удалить",
			"web.workgroup.remove.title":                "Удалить участника рабочей группы",
			"web.workgroup.remove.button.yes":           "Удалить",
			"web.workgroup.add.button":                  "Добавить",
			"web.workgroup.add.back.button":             "Вернуться",
			"web.workgroup.add.text":                    "Выберите участников для добавления в рабочую группу",
			"web.workgroup.add.continue":                "Продолжить",
			"web.sharedcontacts.rights.done":            "Задать права",
			"web.sharedcontacts.add.next":               "Добавить",
			"web.multidescription.menu.add":             "Добавить контакты",
			"web.group.participants.text":               "Редактирование списка участников группы",
			"web.group.participants.invite.button":      "Пригласить участника",
			"web.group.participant.delete.title":        "Удалить участника?",
			"web.group.participant.delete.button.yes":   "Удалить",
			"web.group.add.contacts.button":             "Добавить в группу",
			"web.contacts.meta.group":                   "(группа)",
			"web.chats.main.normal.title":               "Чат с {0}",
			"web.chats.main.group.title":                "Групповой чат {0}",
			"web.chats.list.title":                      "Чаты",
			"web.contacts.main.title":                   "Чат с {0}",
			"web.contacts.list.title":                   "Контакты",
			"web.groups.main.title":                     "Групповой чат {0}",
			"web.groups.list.title":                     "Группы",
			"web.sharedcontacts.main.title":             "Чат с {0}",
			"web.sharedcontacts.list.title":             "Общие контакты",
			"web.settings.list.title":                   "Настройки",
			"web.settings.account.main.title":           `Настройки аккаунта ${process.env.APP_NAME}`,
			"web.settings.security.main.title":          "Настройки безопасности",
			"web.settings.application.main.title":       `Настройки ${process.env.APP_NAME}`,
			"web.settings.info.main.title":              "О приложении",
			"web.settings.notifications.main.title":     "Оповещения",
			"web.settings.users.main.title":             "Пользователи",
			"web.login.title":                           `Вход в ${process.env.APP_NAME} Web`,
			"web.login.generateing.code":                "Создается код...",
			"web.login.text1":                           `Для активации веб интерфейса, зайдите на телефоне в свой ${process.env.APP_NAME}, выберите настройки - веб версия и отсканируйте qr-код`,
			"web.login.text2":                           "Чтобы уменьшить использование данных, подключите свой телефон к WiFi",
			"web.login.create.group":                    "Вы так же можете начать анонимную группу прямо в браузере и приглашать других по ссылке!",
			"web.login.create.group.button":             "Создать анонимную группу",
			"web.chats.newcontact":                      "Новый контакт",
			"web.offline.title":                         "Соединение с глобальной сетью не установлено",
			"web.offline.text":                          "Подключаемся к Интернету...",
			"web.opener.textId":                         "Производится безопасное соединение, пожалуйста, ожидайте...",
			"web.contacts.menu.newtab":                  "Открыть в новом окне",
			"contact.status.invited.description":        <span>
           	                                 	           Согласие на диалог от другой стороны пока не было получено. Возможные причины:<br /><br />
           	                                                1. Контакт получен от анонимного пользователя, однако владелец контакта сейчас не в сети или не подтвердил своего участия <br />
           	                                                2. Анонимный пользователь отправил Ваш контакт иному собеседнику, который на данный момент не в сети или отказался принять Вашу карточку контакта {process.env.APP_NAME} <br />
           	               	                                3. Контакт был добавлен посредством множественного приглашения (визитки), но владелец визитки сейчас не в сети.
           	                                         	</span>,
			"contact.shared.status.invited.description": <span>
           	                                            	Согласие на диалог от другой стороны пока не было получено. Возможные причины:<br /><br />
           	                                                1. Контакт получен от анонимного пользователя, однако владелец контакта сейчас не в сети или не подтвердил своего участия в данной беседе <br />
           	                                                2. Анонимный пользователь отправил Ваш контакт иному собеседнику, который на данный момент не в сети или отказался принять Вашу карточку контакта {process.env.APP_NAME} <br />
           	                                       	        3. Анонимный пользователь из рабочей группы сделал этот контакт общим, но владелец контакта сейчас не в сети или не подтвердил своего участия в данной беседе.
           	         	                                </span>,
			"service.message.group.left":                "Пользователь {0} покинул группу",
			"service.message.group.join":                "Пользователь {0} вступил в группу",
			"backup.copytoast":                          "Токен скопирован",
			"backup.qr.bigtext":                         "",
			"backup.qr.smalltext":                       `Сохраните QR-код, без которого восстановление списка контактов ${process.env.APP_NAME} будет невозможно!`,
			"backup.plain.text":                         "",
			"backup.token.tooltip":                      "",
			"backup.button.copy":                        "Скопировать",
			"backup.button.delete":                      "Удалить резервную копию",
			"backup.button.create":                      "Создать резервную копию",
			"backup.create.text":                        `Вы можете создать резервную копию данных аккаунта ${process.env.APP_NAME} на разные случаи жизни: переустановка приложения, смена или утеря устройства, прочие ситуации. Резервная копия надёжно защищена ассиметричным шифрованием. Дешифровка резервной копии будет возможна лишь при наличии уникального кода, который будет сгененирован приложением. Помните, что при утере кода восстановление резервной копии будет невозможно! Сохраните его при помощи переноса на бумажный носитель или в виде QR-кода!`,
			"backup.fake.checkbox":                      "Дополнительный аккаунт",
			"backup.fake.password.placeholder":          "Пароль от дополнительного аккаунта",
			"backup.fake.password.tooltip":              "При наличии дополнительного аккаунта, введите пароль от него в это поле",
			"backup.fake.password.invalid":              "Неверный пароль",
			"backup.progress":                           `Сохранение записи #{0} из {1}`,
			"backup.button.cancel":                      "Отменить",
			"activation.isprivileged.checkbox":          "Привилегированный",
			"activation.button.generate":                "Добавить",
			"activation.nickname.placeholder":           "Имя пользователя",
			"activation.nickname.tooltip":               `Для удобства работы с контактами в списке ${process.env.APP_NAME} задайте имя пользователя`,
			"activation.text":                           "Для добавления нового пользователя создайте код активации",
			"activation.header":                         "Активация нового пользователя",
			"activation.qr.bigtext":                     "",
			"activation.qr.smalltext":                   "",
			"activation.token.placeholder":              "",
			"activation.button.copy":                    "Копировать",
			"activation.copytoast":                      "Скопировано",
			"activation.plain.text":                     "",
			"activation.user.quota.exceeded":            "Достигнут максимум количества пользователей",
			"activation.privileged.quota.exceeded":      "Достигнут максимум количества Привилегированных пользователей",
			"web.users.title":                           "Пользователи",
			"web.users.add.title":                       "Новый пользователь",
			"web.users.add.text":                        `Вы можете сделать данного пользователя администратором только после его регистрации в системе ${process.env.APP_NAME}`,
			"web.users.add.nickname.title":              "Имя",
			"web.users.add.type.title":                  "Тип пользователя",
			"web.users.add.close.button":                "Отмена",
			"web.users.add.save.button":                 "Сохранить",
			"web.users.add.button":                      "Добавить пользователя",
			"web.users.send.button":                     "Отправить контакты",
			"web.users.send.close.button":               "Отменить",
			"web.users.send.confirm.button":             "Отправить",
			"web.user.send.title":                       "Отправка контактов пользователю {0}",
			"web.user.send.text":                        "Выберите контакты для отправки",
			"web.user.send.progress":                    "Отправка контактов в процессе. Это может занять некоторое время в зависимости от количества выбранных контактов.",
			"web.users.activation.close.button":         "Закрыть",
			"web.users.add.quota.exceeded":              `Вы превысили допустимое количество пользователей и дальнейшее их добавление в ${process.env.APP_NAME} невозможно`,
			"web.users.edit.privileged.quota.exceeded":  "Дальнейшее повышение уровня привилегий для данного контакта невозможно, так как Вы превысили допустимое количество привилегированных пользователей",
			"web.users.edit.unactivated":                `Пользователь не прошёл процедуру регистрации ${process.env.APP_NAME}. Тип пользователя не может быть изменён Вами до начала его регистрации`,
			"web.users.edit.adminlow":                   "Внимание: недостаточно администраторов для изменения типов пользователей. Пожалуйста, добавьте администраторов!",
			"web.users.adminlow.error":                  `Внимание: недостаточно администраторов! Помните, что администраторы обеспечивают доступ к списку пользователей ${process.env.APP_NAME}! В случае утери всеми из них доступов к своим аккаунтам, список контактов будет заблокирован, а его восстановление – невозможно из-за особенностей шифрования. Авторы ${process.env.APP_NAME} рекомендуют добавление как минимум четырёх администраторов`,
			"web.users.adminlow.warn":                   `Внимание: недостаточно администраторов! Помните, что администраторы обеспечивают доступ к списку пользователей ${process.env.APP_NAME}! В случае утери всеми из них доступов к своим аккаунтам, список контактов будет заблокирован, а его восстановление – невозможно из-за особенностей шифрования. Авторы ${process.env.APP_NAME} рекомендуют добавление как минимум четырёх администраторов`,
			"web.users.thead.nickname":                  "Имя",
			"web.users.thead.type":                      "Тип",
			"web.users.edit.button":                     "Редактировать",
			"web.users.delete.button":                   "Удалить",
			"web.users.cancel.button":                   "Отмена",
			"web.users.apply.button":                    "Применить",
			"web.users.code.button":                     "Код активации",
			"web.users.type.user":                       "Обычный пользователь",
			"web.users.type.powerUser":                  "Привилегированный пользователь",
			"web.users.type.admin":                      "Администратор",
			"web.user.confirm.title":                    "Удалить пользователя?",
			"web.user.confirm.button.yes":               "Удалить пользователя",
			"web.user.activation.title":                 "Код активации для {0}",
			"web.user.activation.text":                  "Текстовый код",
			"web.message.delete.title":                  "Удалить сообщение?",
			"web.message.delete.button.yes":             "Удалить",
			"activation.isaddcontact.checkbox":          "Добавить в список контактов",
			"group.new.progress.header":                 "Новая группа",
			"group.new.progress.text":                   "Создаём группу. Пожалуйста, подождите: время ожидания зависит от количества участников",
			"group.add.progress.header":                 "+Участники",
			"group.add.progress.text":                   "Добавляем участников. Пожалуйста, подождите: время ожидания зависит от количества участников",
			"web.group.external.main.title":             "Чат в группе {0}",
			"web.group.external.title":                  "Чат",
			"web.contact.external.main.title":           "Чат c {0}",
			"web.contact.external.title":                "Чат",
			"group.external.header":                     <span style={{fontSize: "80%"}}>Внешний участник</span>,
			"group.external.text":                       "",
			"group.external.placeholder":                "ИМЯ",
			"group.external.copytoast":                  "Скопировано",
			"group.external.qr.bigtext":                 "QR-код адреса",
			"group.external.qr.smalltext":               "— Предоставьте этот QR-код собеседнику для сканирования",
			"group.external.plain.text":                 "— Или отправьте ссылку иным способом: по электронной почте, в SMS или при помощи другого мессенджера",
			"group.external.token.tooltip":              `Ссылка, по которому собеседник, не зарегистрированный в ${process.env.APP_NAME}, сможет вести переписку в группе`,
			"group.external.button.copy":                "Скопировать в буфер",
			"group.external.duplicate.warn":             "Данное имя уже зарегистрировано, пожалуйста, выберите другое",
			"group.external.duplicate.invite.warn":      "Участник с таким именем уже приглашён",
			"web.group.external.nickname":               "Имя внешнего участника",
			"web.group.external.nickname.placeholder":   "Имя",
			"web.group.external.nickname.text":          "Ваше имя в группе",
			"web.group.external.name.placeholder":       "Название",
			"web.group.external.name.text":              "Название новой группы",
			"web.group.external.add.button":             "Добавить",
			"web.group.external.create.group.button":    "Создать новую группу",
			"web.group.menu.external":                   "Добавить внешнего участника",
			"web.group.menu.new":                        "Сделать новую группу",
			"web.group.external.done.button":            "Закрыть",
			"web.group.external.duplicate.warn":         "Данное имя уже зарегистрировано, пожалуйста, выберите другое",
			"web.group.external.duplicate.invite.warn":  "Участник с таким именем уже приглашён",
			"web.group.external.joining":                "В процессе присоединения к группе",
			"web.group.external.failed":                 "Попытка присоединения к группе не удалась. Возможно, ссылка уже была использована",
			"web.group.external.exited":                 "Вы вышли из группы",
			"web.unread.title":                          cnt => cnt % 10 !== 1 || cnt === 11
								                         ? (cnt + ` непрочитанных сообщений - ${process.env.APP_NAME}`)
								                         : ( cnt + ` непрочитанное сообщение - ${process.env.APP_NAME}` ),
	    "web.businessCards.text1":                   "Визитка - это приглашение, которое может быть использовано сколько угодно раз. Дайте другим отсканировать QR-код и они смогут анонимно вас добавить в список контактов. У вас будут появляться новые контакты с именами BC, BC(1), BC(2) и тд. При необходимости, переименуйте эти контакты.",
			"web.businessCards.text2":                   "Токен визитки - его Вы можете отправить с помощью смс или любым другим удобным способом. Но лучше использовать QR-код.",
 			"web.businessCards.copy.button":             "Скопировать",
 			"web.businessCards.copied.toast":            "Скопировано",
 			"web.businessCards.copy_failed.toast":       "Копирование не удалось",
 			"web.businessCards.create.text":             "Визитка - это приглашение, которое может быть использовано сколько угодно раз. Дайте другим отсканировать QR-код и они смогут анонимно вас добавить в список контактов. У вас будут появляться новые контакты с именами BC, BC(1), BC(2) и тд. При необходимости, переименуйте эти контакты.",
 			"web.businessCards.create.button":           "Создать",
			"web.businessCards.title":                   "Визитка",
			"web.businessCards.delete.button":           "Удалить",
			"web.businessCards.delete.title":            "Если Вы удалите визитку, никто больше не сможет её использовать для добавления Вас в список контактов.",
			"web.businessCards.delete.button.yes":       "Да, удалить",
			"web.businessCards.qr.download":             "Скачать как PNG"
		} );
	}
}

export default TranslationRu;
