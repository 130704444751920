"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.IManagedBuffer = exports.PlainBuffer = exports.TransformedBuffer = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); //triplesec's implementation of aes as it uses in place encryption which is essential
//in locking secure buffers


var _triplesec = require("triplesec");

var _prng = require("../random/locators/prng.js");

var _prng2 = _interopRequireDefault(_prng);

var _inplaceCrypto = require("../helpers/inplace.crypto.js");

var _inplaceCrypto2 = _interopRequireDefault(_inplaceCrypto);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AES = _triplesec.ciphers.AES;

var CHUNK_SIZE = 100 * 1024;

function xor(a, x) {
	var length = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : a.length;

	for (var i = 0; i < length; i++) {
		a[i] ^= x[i];
	}
}

var IManagedBuffer = function () {
	function IManagedBuffer() {
		_classCallCheck(this, IManagedBuffer);
	}

	_createClass(IManagedBuffer, [{
		key: "lock",
		value: function lock() {
			throw new Error("Not implemented");
		}
	}, {
		key: "unlock",
		value: function unlock() {
			throw new Error("Not implemented");
		}
	}, {
		key: "getAsUint32Array",
		value: function getAsUint32Array() {
			throw new Error("Not implemented");
		}
	}, {
		key: "getAsBuffer",
		value: function getAsBuffer() {
			throw new Error("Not implemented");
		}
	}, {
		key: "dispose",
		value: function dispose() {
			throw new Error("Not implemented");
		}
	}, {
		key: "byteLength",
		get: function get() {
			throw new Error("Not implemented");
		}
	}]);

	return IManagedBuffer;
}();

var TransformedBuffer = function () {
	function TransformedBuffer(managedBuffer, forwardTransform, backTransformedUse, disposeTransform, dataSize) {
		var _this = this;

		_classCallCheck(this, TransformedBuffer);

		if (!managedBuffer) {
			throw new Error("managedBuffer is required");
		}

		if (!forwardTransform) {
			throw new Error("forwardTransform is required");
		}

		if (!backTransformedUse) {
			throw new Error("backTransformedUse is required");
		}

		if (!disposeTransform) {
			throw new Error("disposeTransform is required");
		}

		if (managedBuffer.byteLength < dataSize) {
			throw new Error("managedBuffer.byteLength < dataSize");
		}
		if (dataSize !== (dataSize | 0)) {
			throw new Error("TransformedBuffer received invalid dataSize " + dataSize);
		}
		var isTransformed = false;
		var lockCount = 0;
		this.isDisposed = false;
		this.dispose = function () {
			disposeTransform();
			managedBuffer.dispose();
			_this.isDisposed = true;
		};
		this._allocatedSize = managedBuffer.byteLength;
		this._dataSize = dataSize;

		var useLocked = function useLocked(func) {
			try {
				managedBuffer.lock();
				func();
			} finally {
				managedBuffer.unlock();
			}
		};

		var useBackTransformedWithCounter = function useBackTransformedWithCounter(func) {
			lockCount++;
			try {
				if (isTransformed) {
					isTransformed = false;
					backTransformedUse(function () {
						return useLocked(func);
					});
					isTransformed = true;
				} else {
					useLocked(func);
					if (!isTransformed && lockCount === 1) {
						isTransformed = forwardTransform();
					}
				}
			} finally {
				lockCount--;
			}
		};

		this.useAsBuffer = function (func) {
			useBackTransformedWithCounter(function () {
				return func(managedBuffer.getAsBuffer(_this._dataSize));
			});
		};

		this.useAsUint32Array = function (func) {
			useLocked(function () {
				useBackTransformedWithCounter(function () {
					return func(managedBuffer.getAsUint32Array(Math.ceil(_this._dataSize)));
				});
			});
		};

		this.isLocked = false;
		this._tryTransform = function () {
			if (isTransformed || lockCount > 0) {
				return;
			}
			isTransformed = forwardTransform();
		};
	}

	_createClass(TransformedBuffer, [{
		key: "byteLength",
		get: function get() {
			return this._dataSize;
		}
	}]);

	return TransformedBuffer;
}();

var PlainBuffer = function (_TransformedBuffer) {
	_inherits(PlainBuffer, _TransformedBuffer);

	function PlainBuffer(managedBuffer, dataSize) {
		_classCallCheck(this, PlainBuffer);

		var _this2 = _possibleConstructorReturn(this, (PlainBuffer.__proto__ || Object.getPrototypeOf(PlainBuffer)).call(this, managedBuffer, function (buffer) {
			return false;
		}, function (buffer) {
			throw new Error("No plain back transform possible");
		}, //backward transform
		function () {}, dataSize));

		delete _this2._tryTransform;

		var useLong = function useLong(func, get) {
			var cbCalled = false;
			managedBuffer.lock();
			func(get(), function () {
				if (cbCalled) {
					console.warn("Duplicate cb call");
					return;
				}
				cbCalled = true;
				managedBuffer.unlock();
			});
		};
		//func(buffer, cb)
		_this2.useAsBufferLong = function (func) {
			useLong(func, function () {
				return managedBuffer.getAsBuffer(dataSize);
			});
		};
		_this2.useAsUint32ArrayLong = function (func) {
			useLong(func, function () {
				return managedBuffer.getAsUint32Array(Math.ceil(dataSize / 4) * 4);
			});
		};
		return _this2;
	}

	return PlainBuffer;
}(TransformedBuffer);

var EncryptedBuffer = function (_TransformedBuffer2) {
	_inherits(EncryptedBuffer, _TransformedBuffer2);

	function EncryptedBuffer(managedBuffer, keyTransformedBufferThen, dataSize) {
		_classCallCheck(this, EncryptedBuffer);

		if (managedBuffer.byteLength % 16) {
			throw new Error("managedBuffer length must be divisible by 16 for use as EncryptedBuffer");
		}

		var keyTransformedBuffer = null; //This will become a valid value when IV and Key are ready
		var transformReady = false;
		var plainBuffer = new PlainBuffer(managedBuffer, managedBuffer.byteLength);

		if (!keyTransformedBufferThen.then) {
			keyTransformedBufferThen = Promise.resolve(keyTransformedBufferThen);
		}

		var iv = null;
		var container = managedBuffer._chunk._container;

		var encrypt = function encrypt() {
			//use CBC mode
			_inplaceCrypto2.default.encryptAesCbc(plainBuffer, keyTransformedBuffer, iv);
		};
		var decrypt = function decrypt() {
			_inplaceCrypto2.default.decryptAesCbc(plainBuffer, keyTransformedBuffer, iv);
		};
		//managedBuffer, forwardTransform, backTransformedUse, disposeTransform

		var _this3 = _possibleConstructorReturn(this, (EncryptedBuffer.__proto__ || Object.getPrototypeOf(EncryptedBuffer)).call(this, managedBuffer, function () {
			//forward transform
			if (!transformReady) {
				return false;
			}
			encrypt();
			return true;
		}, function (func) {
			keyTransformedBuffer.useAsBuffer(function () {
				decrypt();
				try {
					func();
				} finally {
					encrypt();
				}
			});
		}, //backward transform
		function () {
			//dispose transform
			iv && iv.fill(0);
			iv = null;
			transformReady = false;
		}, dataSize));

		var tryTransform = _this3._tryTransform.bind(_this3);
		delete _this3._tryTransform;

		Promise.all([keyTransformedBufferThen, (0, _prng2.default)().pseudoRandomDataThen(16)]).then(function (_ref) {
			var _ref2 = _slicedToArray(_ref, 2),
			    keyTransformedBufferSync = _ref2[0],
			    ivSync = _ref2[1];

			if (!(keyTransformedBufferSync instanceof TransformedBuffer)) {
				throw new Error("keyTransformedBuffer have invalid type in EncryptedBuffer");
			}
			if (managedBuffer.isDisposed) {
				return;
			}
			iv = ivSync;
			keyTransformedBuffer = keyTransformedBufferSync;
			transformReady = true;
			tryTransform();
		});

		_this3.__getIV = function () {
			return iv;
		};
		return _this3;
	}

	return EncryptedBuffer;
}(TransformedBuffer);

var XoredBuffer = function (_TransformedBuffer3) {
	_inherits(XoredBuffer, _TransformedBuffer3);

	function XoredBuffer(managedBuffer, dataSize) {
		_classCallCheck(this, XoredBuffer);

		if (!managedBuffer) {
			throw new Error("managedBuffer is required");
		}
		var x = null;

		var _this4 = _possibleConstructorReturn(this, (XoredBuffer.__proto__ || Object.getPrototypeOf(XoredBuffer)).call(this, managedBuffer, function () {
			//forward transform
			if (!x) {
				return false;
			}
			try {
				managedBuffer.lock();
				xor(managedBuffer.getAsBuffer(dataSize), x);
			} finally {
				managedBuffer.unlock();
			}
			return true;
		}, function (func) {
			try {
				managedBuffer.lock();
				xor(managedBuffer.getAsBuffer(dataSize), x);
				func();
			} finally {
				xor(managedBuffer.getAsBuffer(dataSize), x);
				managedBuffer.unlock();
			}
		}, //backward transform
		function () {
			//dispose transform
			x && x.fill(0);
			x = null;
		}, dataSize));

		var tryTransform = _this4._tryTransform.bind(_this4);
		delete _this4._tryTransform;

		(0, _prng2.default)().pseudoRandomDataThen(managedBuffer.byteLength).then(function (newX) {
			if (managedBuffer.isDisposed) {
				return;
			}
			x = newX;
			tryTransform();
		});
		return _this4;
	}

	return XoredBuffer;
}(TransformedBuffer);

var ManagedBuffer = function (_IManagedBuffer) {
	_inherits(ManagedBuffer, _IManagedBuffer);

	function ManagedBuffer(chunk, offset, size) {
		_classCallCheck(this, ManagedBuffer);

		var _this5 = _possibleConstructorReturn(this, (ManagedBuffer.__proto__ || Object.getPrototypeOf(ManagedBuffer)).call(this));

		_this5._chunk = chunk;
		_this5._offset = offset;
		_this5._size = size;
		_this5.isDisposed = false;
		_this5._lockCount = 0;
		_this5.isLocked = false;
		return _this5;
	}

	_createClass(ManagedBuffer, [{
		key: "dispose",
		value: function dispose() {
			if (this.isDisposed) {
				console.warn("Double dispose of ManagedBuffer");
				return;
			}
			if (this._lockCount) {
				throw new Error("Cannot dispose locked buffer. lock count: " + this._lockCount);
			}

			this._chunk.unallocate(this);
			this.isDisposed = true;
			delete this._chunk;
			delete this._offset;
			delete this._size;
		}
	}, {
		key: "move",
		value: function move(chunk, offset) {
			if (this.isDisposed) {
				throw new Error("Use of ManagedBuffer after dispose");
			}
			if (chunk !== this._chunk) {
				throw new Error("ManagedBuffer can be moved only inside chunk it was created. This method is for use internally");
			}

			if (this._lockCount) {
				throw new Error("Cannot move locked buffer");
			}

			this._offset += offset;
		}
	}, {
		key: "getOffset",
		value: function getOffset() {
			if (this.isDisposed) {
				throw new Error("Use of ManagedBuffer after dispose");
			}
			return this._offset;
		}
	}, {
		key: "getAsBuffer",
		value: function getAsBuffer(size) {
			if (!this.isLocked) {
				throw new Error("ManagedBuffer must be locked first");
			}

			if (size === undefined) {
				throw new Error("Size parameter required");
			}
			return this._chunk.getBuffer().slice(this._offset, this._offset + size);
		}
	}, {
		key: "getAsUint32Array",
		value: function getAsUint32Array(size) {
			if (!this.isLocked) {
				throw new Error("ManagedBuffer must be locked first");
			}
			if (size === undefined) {
				throw new Error("Size parameter required");
			}

			var ab = this._chunk.getArrayBuffer();
			var s = Math.ceil(size / 4);
			return new Uint32Array(ab, this._offset, s);
		}
	}, {
		key: "lock",
		value: function lock() {
			if (!this._lockCount) {
				this.isLocked = true;
				this._chunk.onLock();
			}
			this._lockCount++;
		}
	}, {
		key: "unlock",
		value: function unlock() {
			this._lockCount--;
			if (this._lockCount < 0) {
				throw new Error("Unlock without lock");
			}
			if (!this._lockCount) {
				this.isLocked = false;
				this._chunk.onUnlock();
			}
		}
	}, {
		key: "byteLength",
		get: function get() {
			return this._size;
		}
	}]);

	return ManagedBuffer;
}(IManagedBuffer);

var SecureChunk = function () {
	function SecureChunk(container, size) {
		_classCallCheck(this, SecureChunk);

		this._arrayBuffer = new ArrayBuffer(size || container.getChunkSize());
		this._buffer = new Buffer(this._arrayBuffer);
		this._allocationPointer = 0;
		this._orderedAllocatedBuffers = [];
		this._container = container;
		this._freeQueue = [];
		this._lockCount = 0;
	}

	_createClass(SecureChunk, [{
		key: "isFree",
		value: function isFree() {
			return this._allocationPointer === 0;
		}
	}, {
		key: "getUsedBytesCount",
		value: function getUsedBytesCount() {
			return this._allocationPointer;
		}
	}, {
		key: "tryAllocate",
		value: function tryAllocate(size) {
			if (size < 0 || size % 4) {
				throw new Error("invalid size value: " + size);
			}

			if (this._arrayBuffer.byteLength < this._allocationPointer + size) {
				return null;
			}

			var secureBuffer = new ManagedBuffer(this, this._allocationPointer, size);
			this._orderedAllocatedBuffers.push(secureBuffer);
			this._allocationPointer += size;
			return secureBuffer;
		}
	}, {
		key: "unallocate",
		value: function unallocate(managedBuffer) {
			var index = this._orderedAllocatedBuffers.indexOf(managedBuffer);
			if (!~index) {
				throw new Error("Secure memory corruption! Cannot find allocated buffer to free");
			}
			var sbuffer = this._orderedAllocatedBuffers[index];
			var unallocSize = sbuffer.byteLength;

			this._buffer.fill(255, sbuffer.getOffset(), sbuffer.getOffset() + unallocSize);
			this._freeSpaceOrPostpone(managedBuffer, unallocSize);
		}
	}, {
		key: "_freeSpaceOrPostpone",
		value: function _freeSpaceOrPostpone(managedBuffer, unallocSize) {
			if (this._isAnyLocked()) {
				//postpone
				this._freeQueue.push({ managedBuffer: managedBuffer, unallocSize: unallocSize });
				return;
			}
			this._freeSpace(managedBuffer, unallocSize);
		}
	}, {
		key: "_freeSpace",
		value: function _freeSpace(managedBuffer, unallocSize) {
			var index = this._orderedAllocatedBuffers.indexOf(managedBuffer);
			if (!~index) {
				throw new Error("Secure memory corruption! Cannot find allocated buffer to free");
			}

			this._orderedAllocatedBuffers.splice(index, 1);
			for (var i = index; i < this._orderedAllocatedBuffers.length; i++) {
				var tmpsbuffer = this._orderedAllocatedBuffers[i];
				if (tmpsbuffer.isDisposed) {
					continue;
				}
				var tmpOffset = tmpsbuffer.getOffset();
				var tmpSize = tmpsbuffer.byteLength;

				this._buffer.copy(this._buffer, tmpOffset - unallocSize, tmpOffset, tmpOffset + tmpSize);

				tmpsbuffer.move(this, -unallocSize);
			}

			this._allocationPointer -= unallocSize;
			if (!this._allocationPointer) {
				this._container.removeChunk(this);
			}
		}
	}, {
		key: "_isAnyLocked",
		value: function _isAnyLocked() {
			return !!this._lockCount;
		}
	}, {
		key: "onLock",
		value: function onLock() {
			this._lockCount++;
		}
	}, {
		key: "onUnlock",
		value: function onUnlock() {
			this._lockCount--;
			if (this._freeQueue.length && !this._isAnyLocked()) {
				while (this._freeQueue.length) {
					var _freeQueue$shift = this._freeQueue.shift(),
					    _managedBuffer = _freeQueue$shift.managedBuffer,
					    unallocSize = _freeQueue$shift.unallocSize;

					this._freeSpace(_managedBuffer, unallocSize);
				}
			}
		}
	}, {
		key: "getBuffer",
		value: function getBuffer() {
			return this._buffer;
		}
	}, {
		key: "getArrayBuffer",
		value: function getArrayBuffer() {
			return this._arrayBuffer;
		}
	}]);

	return SecureChunk;
}();

var SecureBufferContainer = function () {
	function SecureBufferContainer(chunkSize) {
		_classCallCheck(this, SecureBufferContainer);

		this._chunks = [];
		this._chunkSize = chunkSize || CHUNK_SIZE;
	}

	_createClass(SecureBufferContainer, [{
		key: "getChunkSize",
		value: function getChunkSize() {
			return this._chunkSize;
		}
	}, {
		key: "getUsedBytesCount",
		value: function getUsedBytesCount() {
			return this._chunks.reduce(function (acc, curr) {
				return acc + curr.getUsedBytesCount();
			}, 0);
		}
	}, {
		key: "_allocateInternal",
		value: function _allocateInternal(size) {
			if (size > CHUNK_SIZE) {
				var _newChunk = new SecureChunk(this, size);
				this._chunks.push(_newChunk);
				var _allocated = _newChunk.tryAllocate(size);
				if (!_allocated) {
					throw new Error("tryAllocate large failed with a new chunk: " + size);
				}
				return _allocated;
			}
			for (var i = 0; i < this._chunks.length; i++) {
				var _allocated2 = this._chunks[i].tryAllocate(size);
				if (_allocated2) {
					return _allocated2;
				}
			}
			var newChunk = new SecureChunk(this);
			this._chunks.push(newChunk);
			var allocated = newChunk.tryAllocate(size);
			if (!allocated) {
				throw new Error("tryAllocate failed with a new chunk. Maybe because of too big buffer size request: " + size);
			}
			return allocated;
		}
	}, {
		key: "allocateXored",
		value: function allocateXored(size) {
			//return this.allocatePlain( size );
			var internalBuffer = this._allocateInternal(Math.ceil(size / 4) * 4);
			if (!internalBuffer) {
				throw new Error("internalBuffer not allocaed");
			}
			return new XoredBuffer(internalBuffer, size);
		}
	}, {
		key: "allocateEncrypted",
		value: function allocateEncrypted(size, keyWordsThen) {
			//return this.allocatePlain( size );
			return new EncryptedBuffer(this._allocateInternal(Math.ceil(size / 16) * 16), keyWordsThen, size);
		}
	}, {
		key: "allocatePlain",
		value: function allocatePlain(size) {
			return new PlainBuffer(this._allocateInternal(Math.ceil(size / 4) * 4), size);
		}
	}, {
		key: "removeChunk",
		value: function removeChunk(chunk) {
			var index = this._chunks.indexOf(chunk);
			if (!~index) {
				throw new Error("Trying to remove non existing secure chunk");
			}
			this._chunks.splice(index, 1);
		}
	}]);

	return SecureBufferContainer;
}();

exports.default = SecureBufferContainer;
exports.TransformedBuffer = TransformedBuffer;
exports.PlainBuffer = PlainBuffer;
exports.IManagedBuffer = IManagedBuffer;