import React from "react";
import serviceLocator from "../../../api/services/locators/worker.client.js";
import longTaskServiceLocator from "../../../api/services/locators/long.task.js";
import configuration from "../../../common/configuration.js";
import history from "../../components/history.js";
import Paused from "./paused.jsx";
import Notifications from "../common/notifications.jsx";
import {isOpenedExternalView} from "../../../common/lifecycle_observer.js"

const USER_ACTIVITY_EVENTS = "blur focus focusin focusout load resize scroll click dblclick mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave change select submit keydown keypress keyup touchstart touchend touchmove".split(' ');

class LoggedInView extends React.Component {
	constructor() {
		super();
		this.state = {
			isPaused: false
		};
		this._service = serviceLocator();
		this._longTaskService = longTaskServiceLocator();

		this.onPause = this.onPause.bind( this );
		this.onResume = this.onResume.bind( this );
		this.onTimeout = this.onTimeout.bind( this );
		this.onTimeout_pre = this.onTimeout_pre.bind( this );
		this._notificationFactory = this._notificationFactory.bind( this );

		if ( configuration.getIsTestUtils() ) {
			global.onPause = this.onPause;
			global.onResume = this.onResume;
		}
	}

	componentWillMount() {
		if ( !this._service.isLoggedIn() ) {
			history.navigateTo( "" );
		}
		this._profileSubscription = (
			this._service.observeProfile()
				.map( profile => profile && profile.pinTime )
				.distinctUntilChanged()
				.subscribe( pinTime => {
					this._pinTime = pinTime;
					if ( this._timeout ) {
						clearTimeout( this._timeout );
						this._timeout = 0;
					}
					if ( this.state.isPaused ) {
						this._timeout = setTimeout( this.onTimeout, this._pinTime * 1000 );
					}
				} )
		);
		this._longTaskSubscription = (
			this._longTaskService.observeRunningLongTask()
				.subscribe( isLongTaskRunning => {
					this._isLongTaskRunning = isLongTaskRunning;
					if ( this._pinTime && !this._timeout && !isLongTaskRunning ) {
						this._timeout = setTimeout( this.onTimeout, this._pinTime * 1000 );
					}
					if ( this._timeout && isLongTaskRunning ) {
						clearTimeout( this._timeout );
						this._timeout = 0;
					}
				} )
		);

	}

	componentDidMount() {
		global.document.addEventListener( "pause", this.onPause );
		global.document.addEventListener( "resume", this.onResume );
		for (let i = 0; i < USER_ACTIVITY_EVENTS.length; i++) {
			global.window.addEventListener( USER_ACTIVITY_EVENTS[i], this.onUserActive, true );
		}
	}

	componentWillUpdate() {
		if ( !this._service.isLoggedIn() ) {
			history.navigateTo( "" );
		}
	}

	componentWillUnmount() {
		global.document.removeEventListener( "pause", this.onPause );
		global.document.removeEventListener( "resume", this.onResume );
		for (let i = 0; i < USER_ACTIVITY_EVENTS.length; i++) {
			global.window.removeEventListener( USER_ACTIVITY_EVENTS[i], this.onUserActive );
		}
	}

	onPause() {
		console.log('Pause');
		if ( this._timeout ) {
			clearTimeout( this._timeout );
		}
		global.document.body.style.visibility = "hidden";
		if (isOpenedExternalView() || this._isLongTaskRunning) {
			return;
		}
		// if ( this._pinTime ) {
		this._timeout = setTimeout( this.onTimeout, (this._pinTime||0) * 1000 );
		// }
		this.setState( { isPaused: true } );
	}

	onResume() {
		console.log('Resume');
		this.setState( { isPaused: false } );
		setTimeout( () => {
			global.document.body.style.visibility = "";
		}, 500 );
		this._clearPinTimeout();
	}

	onTimeout() {
		this._timeout = 0;
		// if ( this._isLongTaskRunning ) {
		// 	this._timeout = setTimeout( this.onTimeout, 60000 );
		// 	return;
		// }
		history.navigateTo( "login" );
	}

	onTimeout_pre() {
		Notifications.registerNotification( this._notificationFactory, "24px" );
	}

	_notificationFactory() {
		return <PreLogoutNotification timeoutAt={ this._timeout_at } />;
	}

	_setPinTimeout() {
		this._timeout_at = +new Date + this._pinTime * 1000;
		this._timeout = setTimeout( this.onTimeout, this._pinTime * 1000 );
		this._timeout_pre = setTimeout( this.onTimeout_pre, this._pinTime * 1000 - 10000 );
		Notifications.unregisterNotification( this._notificationFactory );
	}

	_clearPinTimeout() {
		if ( this._timeout ) {
			clearTimeout( this._timeout );
			this._timeout = 0;
		}
		Notifications.unregisterNotification( this._notificationFactory );
	}

	_isTimeoutSet() {
		return !!this._timeout;
	}

	onUserActive() {
		if ( !this._timeout || !this._pinTime ) {
			return;
		}
		clearTimeout( this._timeout );
		this._timeout = setTimeout( this.onTimeout, this._pinTime * 1000 );
	}

	render() {
		// if ( this.state.isPaused ) {
		//  	return <Paused />;This causes incorrect behaviour when switching activities. Like sending files
		// }
		if ( !this._service.isLoggedIn() ) {
			return null;
		}
		return this.props.children;
	}
}

class PreLogoutNotification extends React.Component {
	render() {
		return (
			<div style={{
					width: "100%",
					height: "100%",
					background: "#ccc",
					color: "#000",
					padding: "4px 12px",
					border: "#fff solid 2px",
					borderRadius: "10px"
				}} onClick={this.props.onClose}>
				<Translation textId="logout.soon" />
			</div>
		);
	}
}

export default LoggedInView;
