import React from "react";
import { Button } from "react-bootstrap";
import Translation from "../../../components/translation.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class NewInviteContentView extends React.Component {
	constructor() {
		super();
		this.state = { name: "", isHideName: false };
		this.onNameChange = this.onNameChange.bind( this );
		this.onHideNameChange = this.onHideNameChange.bind( this );
		this.doInviteClick = this.doInviteClick.bind( this );
		this._service = remoteServiceLocator();
	}

	onNameChange( event ) {
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( { name: event.target.value } );
	}

	onHideNameChange( event ) {
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( { isHideName: event.target.checked } );
	}

	doInviteClick() {
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( { isInProgress: true } );
		this._service.inviteContactAsync( this.state.name, this.state.isHideName ? "" : undefined, this.props.isExternal )
			.subscribe( contact => {
				this.props.onInvite( contact );
			} );
	}

	render() {
		return (
			<div className={this.props.className} >
				<div className="invite">
					<p>
						<Translation textId="web.newinvite.text1" />
						<br /><br />
						<Translation textId="web.newinvite.name.text" />
					</p>
					<input
						className="name"
						type="text"
						value={ this.state.name }
						onChange={ this.onNameChange }
					/>
					<div className="checkbox">
						<label className="nice-checkbox">
							<input
								type="checkbox"
								onChange={ this.onHideNameChange }
								checked={ this.state.isHideName}
							/>
							<span className="check"></span>
								<Translation textId="web.newinvite.hide.name.label" />
						</label>
					</div>
					<div className="text-center">
						<Button
							onClick={this.doInviteClick}
							disabled={!this.state.name}
						>
							<Translation textId="web.newinvite.button" />
						</Button>
					</div>
				</div>
			</div>
		);
	}

	static get propType() {
		return {
			onInvite: React.PropTypes.func
		};
	}
}

export default NewInviteContentView;
