import ParticipantListBase from "../common/participant.list.base.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";
import translate from "../../translations/translate.js";

class ParticipantList extends ParticipantListBase {
	constructor() {
		super();
		this.headerTextId = "group.participantlist.header";
		this._service = serviceLocator();
	}
	componentWillMount() {
		this._participantsSubscription = (
			this._service
				.observeGroupParticipants( this.props.contact )
				.subscribe( participants => {
					this.setState( {
						participants
					} );
				} )
		);
		this._invitesSubscription = (
			this._service
				.observeGroupInvites( this.props.contact )
				.subscribe( invites => {
					this.setState( {
						invites
					} );
				} )
		);
	}

	onDeleteInvite(pid) {
		if (confirm(translate("group.invite.delete.confirm"))) {
			this._service.deleteGroupInviteAsync(this.props.contact, pid).subscribe();
		}
	}

	componentWillUnmount() {
		this._participantsSubscription.dispose();
		this._invitesSubscription.dispose();
	}
}

export default ParticipantList;
