import React from "react";
import Switch from "../common/switch.jsx";
import Translation from "../../../components/translation.jsx";
import CreateFakeAccountContent from "./create.fake.account.content.jsx";
import Confirm from "../common/confirm.jsx";
import { Modal, Button } from "react-bootstrap";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class AccountView extends React.Component {
	constructor() {
		super();
		this.state = {
			vibrate: false,
			receiveSound: false,
			sendSound: false,
			frozen: true,
			nickname: "",
			isNicknameUpdated: false,
			nicknameSaveInProgress: false,
			isLoaded: false,
			isCreatingFake: false
		};

		this.vibrationOnChange = this.switchOnChange.bind( this, "vibrate" );
		this.receiveSoundOnChange = this.switchOnChange.bind( this, "receiveSound" );
		this.sendSoundOnChange = this.switchOnChange.bind( this, "sendSound" );
		this.nicknameOnChange = this.nicknameOnChange.bind( this );
		this.saveNickname = this.saveNickname.bind( this );
		this.doneCreatingFake = this.doneCreatingFake.bind( this );
		this.onCreateFakeClick = this.onCreateFakeClick.bind( this );
		this.onDeleteAccountClick = this.onDeleteAccountClick.bind( this );
		this.doDeleteAccount = this.doDeleteAccount.bind( this );

		this._service = remoteServiceLocator();
	}

	componentWillMount() {
		this._profileSubscription = (
			this._service.observeProfile()
				.subscribe( ( { vibrate, receiveSound, sendSound, nickname } ) => {
					this.setState( {
						frozen: false,
						vibrate: !!vibrate,
						receiveSound: !!receiveSound,
						sendSound: !!sendSound,
						nickname: this.state.isNicknameUpdated ? this.state.nickname : nickname,
						isLoaded: true
					} );
				} )
		);
	}

	componentWillUnmount() {
		this._profileSubscription.dispose();
	}

	nicknameOnChange( event ) {
		let nickname = event.target.value;

		if ( this.state.nicknameSaveInProgress ) {
			this.setState( {
				nickname,
				isNicknameUpdated: true
			} );
			return;
		}
		this.setState( {
			nickname,
			isNicknameUpdated: false,
			nicknameSaveInProgress: true
		}, this.saveNickname );
	}

	saveNickname() {
		let {nickname} = this.state;
		this._service.updateProfileAsync( { nickname } )
			.delay( 500 )
			.subscribe( () => {
				if ( this.state.isNicknameUpdated ) {
					this.setState( {
						isNicknameUpdated: false
					}, this.saveNickname );
					return;
				}
				this.setState( { nicknameSaveInProgress: false } );
			} );

	}

	switchOnChange( name, event ) {
		if ( this.state.frozen ) {
			return;
		}
		this.setState( {
			[name]: event.target.checked,
			frozen: true
		} );
		this._service.updateProfileAsync( {
			[name]: event.target.checked
		} ).subscribe(
			() => {},
			() => {
				this.setState( {frozen: false} );
			}
		);
	}

	doneCreatingFake() {
		this.setState( {isCreatingFake: false} );
	}

	onCreateFakeClick() {
		this.setState( {isCreatingFake: true} );
	}

	onDeleteAccountClick() {
		this.setState( {
			popup: () =>
				<Confirm
					titleTextId="web.account.delete.prompt.title"
					contentTextId="web.account.delete.prompt.text"
					yesButtonTextId="web.account.delete.prompt.button.yes"
					onDone={ this.doDeleteAccount }
				/>
		} );
	}

	doDeleteAccount() {
		this.setState( {
			popup: () =>
				<Modal.Dialog>
					<Modal.Header>
						<Modal.Title>
							<Translation textId="web.account.delete.progress.title"/>
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Translation textId="web.account.delete.progress.text"/>
					</Modal.Body>

					<Modal.Footer>
					</Modal.Footer>
				</Modal.Dialog>
		} );
		this._service.deleteAccountAsync( )
			.subscribe( () => {
				global.window.location.reload();
			} );
	}

	renderPopup() {
		if ( this.state.popup ) {
			return this.state.popup();
		}
		return null;
	}

	render() {
		if ( !this.state.isLoaded ) {
			return null;
		}
		if ( this.state.isCreatingFake ) {
			return <CreateFakeAccountContent className={this.props.className} onBack={this.doneCreatingFake} />;
		}
		return (
			<div className={this.props.className}>
				<div className="invite">
					<div className="title-sm"><Translation textId="web.account.nickname.label" /></div>
					<p><Translation textId="web.account.nickname.text" /></p>
					<input
						className="name"
						type="text"
						value={this.state.nickname}
						onChange={this.nicknameOnChange}
					/>
					<div className="settings-box">
						<div className="settings-block">
							<div className="title-sm"><Translation textId="web.account.notification.header" /></div>
							{/*<p><Translation textId="web.account.notification.vibrate" /></p>
							<Switch
								id="vibration"
								checked={this.state.vibrate}
								onChange={this.vibrationOnChange}
							/>
							<div className="clearfix"></div>*/}
							<p><Translation textId="web.account.notification.incoming" /></p>
							<Switch
								id="receiveSound"
								checked={this.state.receiveSound}
								onChange={this.receiveSoundOnChange}
							/>
							<div className="clearfix"></div>
							{/*<p><Translation textId="web.account.notification.outgoing" /></p>
							<Switch
								id="sendSound"
								checked={this.state.sendSound}
								onChange={this.sendSoundOnChange}
							/>
							<div className="clearfix"></div>*/}
						</div>
						<div className="settings-account">
							<div className="title-sm"><Translation textId="web.account.fakeaccount.header" /></div>
							<p><Translation textId="web.account.fakeaccount.text" /></p>
							<div className="text-center">
								<button className="btn btn-green" onClick={this.onCreateFakeClick}>
									<Translation textId="web.account.fakeaccount.create.button" />
								</button>
							</div>
							{/*TODO:implement backup
								<div className="title-sm"><Translation textId="web.account.backup.header" /></div>
							<p><Translation textId="web.account.backup.text" /></p>
							<div className="text-center">
								<button className="btn btn-green disabled"><Translation textId="web.account.backup.create.button" /></button>
							</div>
							*/}
							<div className="title-sm"><Translation textId="web.account.delete.header" /></div>
							<p><Translation textId="web.account.delete.text" /></p>
							<div className="text-center">
								<button className="btn btn-green" onClick={this.onDeleteAccountClick}>
									<Translation textId="web.account.delete.button" />
								</button>
							</div>
						</div>
					</div>
				</div>
				{this.renderPopup()}
			</div>
		);
	}
}

export default AccountView;
