import ssgCrypto, {KEY_KINDS} from "ssg.crypto";
import configuration from "../../common/configuration.js";

export const CHUNK_SIZE = 16384;

const FILE_CHUNK_SIZE = 10 * 1024*1024;

class BinarySource {
	constructor( getChunk, size, onError, onDispose ) {
		if ( size !== ( size | 0 ) ) {
			throw new Error( "size must be a number" );
		}

		if ( onDispose && ( typeof onDispose !== "function" ) ) {
			throw new Error( "onDispose must be a function" );
		}

		if ( onError && ( typeof onError !== "function" ) ) {
			throw new Error( "onError must be a function" );
		}

		this._getChunk = getChunk;
		this._chunkCount = ( ( size + CHUNK_SIZE - 1 ) / CHUNK_SIZE ) | 0;
		this._size = size;
		this._onDispose = onDispose;
		this._onError = onError;
		this._isErrored = false;
	}

	static fromBuffer( buffer, onError, onDispose ) {
		return new BinarySource( ( start, end, cb ) => {
				cb( buffer.slice( start, end ) );
			},
			buffer.length,
			onError,
			onDispose
		);
	}

	static fromFile( file, onError, onDispose ) {
		let currentStart = 0, currentEnd = 0;
		let currentBuffer = null;
		let binarySource = null;

		let read = ( start, end, cb ) => {
			let reader = new FileReader();
			let slicedFile = file.slice( start, end );
			reader.onerror = error => {
				debugger;
				binarySource._isErrored = true;
				binarySource._onError && binarySource._onError( error );
				console.error( "Error reading sliced file", error, start, end );
				reader.onload = null;
				reader.onerror = null;
				// reader = new FileReader();
				// setTimeout( () => {
				// 	read( start, end, cb );
				// }, 2000 );
			};
			reader.onload = event => {
				let result = new Buffer( new Uint8Array( reader.result ) );
				if ( !result.length ) {
					debugger;
				}
				cb( result );
			};
			reader.readAsArrayBuffer( slicedFile );
		};

		let readBuffered = ( start, end, cb ) => {
			if ( ( start >= currentStart ) && ( end <= currentEnd ) ) {
				cb( currentBuffer.slice( start - currentStart, end - currentStart ) );
				return;
			}
			if ( ( start >= currentStart ) && ( start < currentEnd ) && ( end - currentEnd <= FILE_CHUNK_SIZE ) ) {
				let buffer1 = currentBuffer.slice( start - currentStart );
				read( currentEnd, currentEnd + FILE_CHUNK_SIZE, chunk => {
					currentStart = currentEnd;
					currentEnd += FILE_CHUNK_SIZE;
					currentBuffer = chunk;
					cb( Buffer.concat( [ buffer1, chunk.slice( 0, end - start - buffer1.length ) ] ) );
				} );
				return;
			}

			if ( end - start <= FILE_CHUNK_SIZE ) {
				let chunkEnd = Math.min( start + FILE_CHUNK_SIZE, file.size );
				read( start, chunkEnd, chunk => {
					currentStart = start;
					currentEnd = chunkEnd;
					currentBuffer = chunk;
					cb( chunk.slice( 0, end - start ) );
				} );
				return;
			}
			console.warn( "Unbuffered file read" );
			read( start, end, cb );
		};

		return binarySource = new BinarySource(
			readBuffered,
			file.size,
			onError,
			onDispose
		);
	}

	static pseudoRandom( size ) {
		let keyThen = ssgCrypto.createRandomKeyThen(
			KEY_KINDS.SYMMETRIC_ENCRYPTION,
			configuration.getDefaultEncryptionConfig()
		);
		return new BinarySource( ( start, end, cb ) => {
			let blockSize = ssgCrypto.getEncryptionAlgorithmBlockSize();
			let blockStart = ( start / blockSize ) | 0;
			let blockCount = ( ( ( end + blockSize - 1 ) / blockSize ) | 0 ) - blockStart;
			let plain = new Buffer( blockCount * blockSize );
			let intCount = blockCount * blockSize / 4;
			let intStart = blockStart * blockStart / 4;

			for( let i = 0; i < intCount; i++ ) {
				plain.writeUInt32LE( i * 4, i + intStart );
			}
			keyThen
				.then( key => ssgCrypto.encryptDeterministicThen( key, plain ) )
				.then( encrypted => {
					cb( encrypted.slice(
						start - blockStart * blockSize,
						end - blockStart * blockSize
					) );
				} );
		},
		size,
		() => keyThen.then( key => { key.dispose(); } ) );
	}

	static fromChunked( chunkSize, totalSize, getChunkCb ) {
		throw new Error( "Not implemented" );
		return new BinarySource( ( start, end, cb ) => {
			if ( start > end ) {
				throw new Error( `start > end: ${start} > ${end}` );
			}
			let chunkStart = ( start / chunkSize ) | 0;
			let chunkEnd = ( ( end + chunkSize - 1 ) / chunkSize ) | 0;
			if ( chunkStart === chunkEnd ) {
				cb( new Buffer( 0 ) );
				return;
			}
			let leftChunks = chunkEnd - chunkStart;
			let chunks = new Array( leftChunks );
			for( let i = chunkStart; i < chunkEnd; i++ ) {
				getChunkCb( i, ( i => chunk => {
					if ( chunkSize !== chunk.length ) {
						throw new Error( "chunkSize !== chunk.length" );
					}
					chunks[ i ] = chunk;
					leftChunks--;
					if ( !leftChunks ) {
						cb( Buffer.concat( chunks ).slice(
							start - chunkStart * chunkSize,
							end - chunkStart * chunkSize
						) );
					}
				} )( i ) );
			}
		}, totalSize );
	}

	get size( ) {
		return this._size;
	}

	getBufferCb( start, end, cb ) {
		if ( end < start ) {
			throw new Error( `end < start: ${end} < ${start}` );
		}
		if ( end > this._size ) {
			throw new Error( `end > size: ${end} > ${this._size}` );
		}
		let timeout = setTimeout( () => {
			console.error("timeout binary source" + this + cb);
			debugger;
		}, 5000 );
		// console.log( "Binary source getNextBytes start" );
		this._getChunk( start, end, res => {
			// console.log( "Binary source getNextBytes end" );
			clearTimeout( timeout );
			cb( res );
		} );
	}

	toBufferCb( cb ) {
		this.getBufferCb( 0, this._size, cb );
	}

/*
	cb( chunk, onDone( error? ) )
	cbEnd()
*/
	process( cb, cbEnd ) {
		let chunkIndex = -1;
		let isEnded = false;
		let next = () => {
			if ( isEnded ) {
				return;
			}
			chunkIndex++;
			if ( chunkIndex >= this._chunkCount ) {
				isEnded = true;
				cbEnd();
			} else {
				try {
					this._getChunk( chunkIndex * CHUNK_SIZE, ( chunkIndex + 1 ) * CHUNK_SIZE, chunk => {
						try {
							cb( chunk, error => {
								if ( error ) {
									isEnded = true;
									cbEnd( error );
									return;
								}
								next();
							}, chunkIndex )
						} catch( e ) {
							isEnded = true;
							cbEnd( e );
						}
					} );
				} catch( e ) {
					isEnded = true;
					cbEnd( e );
				}
			}
		};
		next();
	}

	dispose( ) {
		this._onDispose && this._onDispose();
		this._onDispose = null;
	}
}

export default BinarySource;
