import React from "react";
import _ from 'lodash';

import WithFooterView from "../common/with.footer.view.jsx";
import MenuBody from "../common/menu.body.jsx";
import Translation from "../../components/translation.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import FullScreen from "../../components/fullscreen.jsx";
import TopLevel from "../common/top.level.view.jsx";
import HeaderWait from "../common/header.wait.jsx";
import ImageButton from "../../components/button.image.jsx";
import PromptContacts from "./prompt.contacts.jsx";
import Toast from "../../components/toast.jsx";
import {Alert, Confirm, InternetRetryConfirm} from "../../components/dialogs.jsx";
import OnlineStatus from "../../components/online.status.jsx"
import WorkgroupRights from "./workgroup.rights.jsx";
import MultiinviteDetailed from "./multiinvite.detailed.jsx";
import Autoclean from "../options/autoclean.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

// NOTE: props.multidescription can be out of date because parrent component stops
// updating itself when this view is active. That is why subscribing to
// multidescription changes is necessary.
class DashboardView extends React.Component {
	constructor() {
		super();
		this.state = {
			workgroupParticipants: Object.create( null ),
			isInProgress: false
		};
		this.onWorkgroupEdit = this.onWorkgroupEdit.bind( this );
		this.onWorkgroupRights = this.onWorkgroupRights.bind( this );
		this.backFromWorkgroupEdit = this.backFromWorkgroupEdit.bind( this );
		this.backFromWorkgroupRights = this.backFromWorkgroupRights.bind( this );
		this.onAddContacts = this.onAddContacts.bind( this );
		this.onSelectedAddContacts = this.onSelectedAddContacts.bind( this );
		this.onCreateOrShowMultiinvite = this.onCreateOrShowMultiinvite.bind( this );
		this.backFromMultiinvite = this.backFromMultiinvite.bind( this );
		this.deleteMultiinvite = this.deleteMultiinvite.bind( this );
		this.onSetAutoclean = this.onSetAutoclean.bind( this );
		this.backFromAutoclean = this.backFromAutoclean.bind( this );
		this.onSetRemoteAutoclean = this.onSetRemoteAutoclean.bind( this );
		this.backFromRemoteAutoclean = this.backFromRemoteAutoclean.bind( this );
		this.onBackPress = this.onBackPress.bind( this );
		this._service = serviceLocator();
	}

	componentWillMount() {
		this.setState( { multidescription: this.props.multidescription } );
		this._selfRightsSubscription = (
			this._service
				.observeSelfRights( this.props.multidescription )
				.filter( selfRights => !!selfRights )
				.subscribe( selfRights => {
					if ( !selfRights.allowModifyContacts ) {
						this.setState( { showAddContacts: false } );
					}
					this.setState( { selfRights } );
				}, error => {
					alert( error.message );
				} )
			);
		this._participantsSubscription = (
			Rx.Observable.combineLatest(
				this._service.observeWorkgroupParticipants( this.props.multidescription ),
				this._service.observeWorkgroupInvites( this.props.multidescription ),
				this._service.observeWorkgroupRights( this.props.multidescription ),
				( ps, is, rights ) => {
					let newPs = Object.create( null );
					let vinegret = { ...ps, ...is };
					for ( let pid in vinegret ) {
						newPs[ pid ] = { ...vinegret[ pid ], rights: rights[ pid ] };
					}
					return newPs;
				}
			)
				.subscribe( workgroupParticipants => {
					this.setState( { workgroupParticipants } );
				}, error => {
					alert( error.message );
				} )
		);
		this._autocleanSubscription = (
			this._service.observeWorkgroupAutocleanTime( this.props.multidescription )
				.subscribe( autocleanTime => {
					this.setState( { autocleanTime } );
				}, error => {
					alert( error.message );
				} )
		);
		this._remoteAutocleanSubscription = (
			this._service.observeWorkgroupRemoteAutocleanTime( this.props.multidescription )
				.subscribe( remoteAutocleanTime => {
					this.setState( { remoteAutocleanTime } );
				}, error => {
					alert( error.message );
				} )
		);
		this._multidescriptionSubscription = (
			this._service.observeSharedContacts()
				.subscribe( multidescriptions => {
					let multidescription = _.find( multidescriptions, { id: this.props.multidescription.id } );
					if ( !multidescription ) {
						this.props.onBack();
						return;
					}
					this.setState( { multidescription } );
				} )
		);
	}

	componentWillUnmount() {
		this._selfRightsSubscription.dispose();
		this._participantsSubscription.dispose();
		this._remoteAutocleanSubscription.dispose();
		this._multidescriptionSubscription.dispose();
	}

	onBackPress() {
		this.props.onBack();
	}

	onSetAutoclean() {
		this.setState( { showAutoclean: true } );
	}

	onSetRemoteAutoclean() {
		this.setState( { showRemoteAutoclean: true } );
	}

	onWorkgroupEdit() {
		this.setState( { showWorkgroupEdit: true } );
	}

	onWorkgroupRights() {
		this.setState( { showWorkgroupRights: true } );
	}

	backFromAutoclean( update ) {
		if ( !update ) {
			this.setState( {
				showAutoclean: false,
				isInProgress: false
			} );
			return;
		}

		if ( this.state.isInProgress ) {
			return;
		}

		this.setState( { isInProgress: true } );
		this._service.setWorkgroupAutocleanTimeAsync( this.state.multidescription, update.autoclean )
			.subscribe( () => {
				this.setState( {
					showAutoclean: false,
					isInProgress: false
				} );
			} );
	}

	backFromRemoteAutoclean( update ) {
		if ( !update ) {
			this.setState( {
				showRemoteAutoclean: false,
				isInProgress: false
			} );
			return;
		}

		if ( this.state.isInProgress ) {
			return;
		}
		this._service.setWorkgroupRemoteAutocleanTimeAsync( this.state.multidescription, update.autoclean )
			.subscribe( () => {
				this.setState( {
					showRemoteAutoclean: false,
					isInProgress: false
				} );
			} );
	}

	backFromWorkgroupEdit() {
		this.setState( { showWorkgroupEdit: false } );
	}

	backFromWorkgroupRights( modifiedRights ) {
		if ( !modifiedRights ) {
			this.setState( { showWorkgroupRights: false } );
			return;
		}
		this._service.setWorkgroupRightsAsync( this.state.multidescription, modifiedRights )
			.subscribe( () => {
				this.setState( { showWorkgroupRights: false } );
			}, error => {
				alert( error.message );
				this.refs.workgroupRights && this.refs.workgroupRights.retry();
			} );
	}

	onAddContacts() {
		this.setState( { showAddContacts: true } );
	}

	onSelectedAddContacts( contactIds ) {
		if ( !contactIds || !contactIds.length ) {
			this.setState( { showAddContacts: false } );
			return;
		}
		let progressSubj = new Rx.BehaviorSubject(0);
		this._service.shareContactsAsync( contactIds, this.state.multidescription.id )
			.subscribe( () => {
				this.setState( { showAddContacts: false } );
			} );
		return progressSubj;
	}

	onCreateOrShowMultiinvite() {
		if ( this.state.multidescription.multiinviteToken ) {
			this.setState( {
				showMultiinvite: true
			} );
			return;
		}
		this._service.createMultiInviteAsync( this.state.multidescription )
			.subscribe( () => {
				this.setState( {
					showMultiinvite: true
				} );
			} );
	}

	backFromMultiinvite() {
		this.setState( { showMultiinvite: false } );
	}

	deleteMultiinvite() {
		this._service.deleteMultiInviteAsync( this.state.multidescription )
			.subscribe( () => {
				this.setState( {
					showMultiinvite: false
				} );
			} );
	}

	getMenuItems() {
		let items = [
			{
				titleTextId: "sharedcontacts.dashboard.workgroup.rights",
				value: null,
				icon: "images/icon-menu-shield.svg",
				iconClass: "pen",
				onSelect: this.onWorkgroupRights,
				id: "menu-rights"
			},
			{
				titleTextId: "sharedcontacts.dashboard.workgroup.edit",
				value: null,
				icon: "images/icon-menu-pen.svg",
				iconClass: "pen",
				onSelect: this.onWorkgroupEdit,
				id: "menu-edit"
			}
		];
		if ( this.state.selfRights && this.state.selfRights.allowModifyContacts ) {
			items.push(
				{
					titleTextId: "sharedcontacts.dashboard.addcontacts",
					value: null,
					icon: "images/icon-add-user.svg",
					iconClass: "shield",
					onSelect: this.onAddContacts,
					id: "menu-add"
				}
			);
		}

		if ( this.state.selfRights && this.state.selfRights.allowModifyWorkgroup ) {
			items.push(
				{
					titleTextId: "sharedcontacts.dashboard.autoclean",
					value: null,
					icon: "images/icon-menu-timer.svg",
					iconClass: "shield",
					onSelect: this.onSetAutoclean,
					id: "menu-autoclean"
				}
			);

			items.push(
				{
					titleTextId: "sharedcontacts.dashboard.autoclean.remote",
					value: null,
					icon: "images/icon-menu-timer.svg",
					iconClass: "shield",
					onSelect: this.onSetRemoteAutoclean,
					id: "menu-autoclean"
				}
			);
		}

		items.push( {
			titleTextId: ( this.state.multidescription && this.state.multidescription.multiinviteToken )
				? "sharedcontacts.dashboard.multiinvite"
				: "sharedcontacts.dashboard.multiinvite.create",
			value: null,
			icon: "images/icon-add-group.svg",
			iconClass: "shield",
			onSelect: this.onCreateOrShowMultiinvite,
			id: "menu-multiinvite"
		} );
		// items.forEach( item => item.id = item.title );
		return items;
	}

	getCurrentFullscreenView() {
		if ( this.state.showWorkgroupRights ) {
			return <WorkgroupRights
				onBack={ this.backFromWorkgroupRights }
				workgroupParticipants={ this.state.workgroupParticipants }
				selfRights={ this.state.selfRights }
				ref="workgroupRights"
			/>;
		}

		if ( this.state.showWorkgroupEdit ) {
			return <WorkgroupEdit
				multidescription={ this.state.multidescription } onBack={ this.backFromWorkgroupEdit } />;
		}

		if ( this.state.showAddContacts ) {
			return <PromptContacts onDone={ this.onSelectedAddContacts } />;
		}
		if ( this.state.showMultiinvite ) {
			return <MultiinviteDetailed
				multidescription={ this.state.multidescription }
				onDelete={ this.deleteMultiinvite }
				onBack={ this.backFromMultiinvite }
				/>;
		}
		if ( this.state.showAutoclean ) {
			return <Autoclean
				onBack={this.backFromAutoclean}
				autocleanTime={this.state.autocleanTime}
				onSetAutoclean={this.backFromAutoclean}
				enabled={!this.state.isInProgress}
				/>;
		}
		if ( this.state.showRemoteAutoclean ) {
			return <Autoclean
				onBack={this.backFromRemoteAutoclean}
				autocleanTime={this.state.remoteAutocleanTime}
				onSetAutoclean={this.backFromRemoteAutoclean}
				enabled={!this.state.isInProgress}
				/>;
		}
		return null;
	}

	renderHeader() {
		if ( !this.state.isInProgress ) {
			return (
				<header>
					<span className="header-caption">
						<Translation textId="sharedcontacts.dashboard.header" />
					</span>
					<ImageButton type="icon-arrow-left" onClick={this.onBackPress} id="dashboard-back"/>
					<OnlineStatus/>
				</header>
			);
		}
		return <HeaderWait />;
	}

	render() {
		return (
			<FullScreen>
				<TopLevel>
					{ this.renderHeader() }
					<main>
						<MenuBody items={ this.getMenuItems() } />
					</main>
					{ this.getCurrentFullscreenView() }
				</TopLevel>
				<DeviceBackButton onPress={ this.onBackPress } />
			</FullScreen>
		);
	}
}


class WorkgroupEdit extends React.Component {
	constructor() {
		super();
		this.onBackPress = this.onBackPress.bind( this );
		this.onAddToWorkgroup = this.onAddToWorkgroup.bind( this );
		this.onPromptWorkgroupDone = this.onPromptWorkgroupDone.bind( this );
		this.doDeleteParticipant = this.doDeleteParticipant.bind( this );
		this.doDeleteInvite = this.doDeleteInvite.bind( this );
		this.state = {
			workgroupParticipants: Object.create( null ),
			invites: Object.create( null )
		};
		this._service = serviceLocator();
	}

	componentWillMount() {
		this._participantsSubscription = (
			this._service.observeWorkgroupParticipants( this.props.multidescription )
				.subscribe( workgroupParticipants => {
					this.setState( { workgroupParticipants } );
				} )
		);

		this._invitesSubscription = (
			this._service.observeWorkgroupInvites( this.props.multidescription )
				.subscribe( workgroupInvites => {
					this.setState( { workgroupInvites } );
				} )
		);

		this._selfRightsSubscription = (
			this._service.observeSelfRights( this.props.multidescription )
				.subscribe( selfRights => {
					this.setState( {selfRights} );
					if ( !selfRights || !selfRights.allowModifyWorkgroup ) {
						this.setState( { showAddToWorkgroup: false } );
					}
				} )
		);
	}

	componentWillUnmount() {
		this._participantsSubscription.dispose();
		this._invitesSubscription.dispose();
		this._selfRightsSubscription.dispose();
	}

	onBackPress() {
		this.props.onBack();
	}

	onDelete( pid ) {
		this.setState( { loading: true } );
		if ( this.state.workgroupParticipants[ pid ] ) {
			this.setState(
				{ selectedPid: pid },
				() => this.refs.confirmDeleteParticipant.show()
			);
		} else {
			this.setState(
				{ selectedPid: pid },
				() => this.refs.confirmDeleteInvite.show()
			);
		}
	}

	doDeleteParticipant() {
		this._service.removeWorkgroupParticipantAsync(
			this.props.multidescription,
			this.state.selectedPid
		).subscribe( () => {
			this.setState( { loading: false } );
		} );
	}

	doDeleteInvite() {
		this._service.removeWorkgroupInviteAsync(
			this.props.multidescription,
			this.state.selectedPid
		).subscribe( () => {
			this.setState( { loading: false } );
		} );
	}

	getContatItems( allowEdit ) {
		return _.map(
			this.state.workgroupParticipants,
			( p, pid ) =>
				<div className="item" key={pid}>
					{ this.state.selfRights && this.state.selfRights.allowModifyWorkgroup
						? <div
							onClick={ this.onDelete.bind( this, pid ) }
							className="round-btn f-right right icon delete"
							/>
						: null
					}
					<div className="name">{p.nickname}</div>
				</div>
		).concat(
			_.isEmpty( this.state.workgroupInvites )
			? []
			: [ <div key="hr" className="hr"><Translation textId="sharedcontacts.workgroup.edit.invites"/></div> ]
		).concat( _.map(
			this.state.workgroupInvites,
			( i, pid ) =>
				<div className="item" key={pid}>
					{ this.state.selfRights && this.state.selfRights.allowModifyWorkgroup
						? <div
							onClick={ this.onDelete.bind( this, pid ) }
							className="round-btn f-right right icon delete"
							/>
						: null
					}
					<div className="name">{i.nickname}</div>
				</div>
		) );
	}

	onAddToWorkgroup() {
		this.setState( { showAddToWorkgroup: true } );
	}

	onPromptWorkgroupDone( contactIds ) {
		if ( !contactIds ) {
			this.setState( { showAddToWorkgroup: false } );
			return;
		}
		Rx.Observable.fromArray( contactIds )
			.flatMap( contactId => this._service.addToWorkGroupAsync(
				this.props.multidescription, { id: contactId }
			) )
			.toArray()
			.subscribe( () => {
				this.setState( { showAddToWorkgroup: false } );
			} );
	}

	isAllowedEdit() {
		return !!this.state.selfRights && !!this.state.selfRights.allowModifyWorkgroup;
	}

	getCurrentFullscreenView() {
		if ( this.state.showAddToWorkgroup ) {
			return <PromptContacts onDone={ this.onPromptWorkgroupDone } />;
		}

		return null;
	}

	render() {
		let {selfRights, workgroupParticipants, workgroupInvites, selectedPid} = this.state;
		let allowEdit = this.isAllowedEdit();
		return (
			<FullScreen>
				<TopLevel>
					<header>
						<span className="header-caption">
							<Translation textId="sharedcontacts.dashboard.header" />
						</span>
						<ImageButton type="icon-arrow-left" onClick={this.onBackPress}/>
						<OnlineStatus/>
					</header>
					<main>
						<div className="contact-list">
							{
								selfRights && selfRights.allowModifyWorkgroup
								? <div className="item" onClick={ this.onAddToWorkgroup }>
									<div className="name">
										<Translation textId="sharedcontacts.dashboard.workgroup.add" />
									</div>
								</div>
								: null
							}
							{this.getContatItems( allowEdit )}
						</div>
					</main>
					{ this.getCurrentFullscreenView() }
					<Confirm
						ref="confirmDeleteParticipant"
						textId="sharedcontacts.dashboard.workgroup.participant.delete"
						params={ [ workgroupParticipants && workgroupParticipants[ selectedPid ] ] }
						buttonTextIds={ [ "sharedcontacts.dashboard.workgroup.participant.delete.yes" ] }
						onConfirm={ this.doDeleteParticipant }
					/>

					<Confirm
						ref="confirmDeleteInvite"
						textId="sharedcontacts.dashboard.workgroup.invite.delete"
						params={ [ workgroupInvites && workgroupInvites[ selectedPid ] ] }
						buttonTextIds={ [ "sharedcontacts.dashboard.workgroup.participant.delete.yes" ] }
						onConfirm={ this.doDeleteInvite }
					/>
				</TopLevel>
				<DeviceBackButton onPress={ this.onBackPress } />
			</FullScreen>
		);
	}
}

export default DashboardView;
