import React from "react";
import Messages from "../contacts/messages.jsx";
import Footer from "../common/message.write.footer.jsx";
import Translation from "../../../components/translation.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class MainGroupContentView extends React.Component {
	constructor() {
		super();
		this._service = remoteServiceLocator();
	}

	render() {
		if ( !this.props.contact ) {
			return (
				<div className={this.props.className}>
					<Messages contact={null} />
					<Footer contact={null} />
				</div>
			);
		}

		let {contact} = this.props;
		if ( !contact ) {
			return (
				<div className={this.props.className}>
					<div className="invite">
						<p>
							<Translation textId="web.sharedcontacts.loading" />
						</p>
					</div>
				</div>
			);
		}

		return (
			<div className={this.props.className} key={contact.id}>
				<Messages contact={contact} />
				<Footer contact={contact} />
			</div>
		);
	}
}

export default MainGroupContentView;
