import DefaultStorage from "../storage/default.storage.js";
import LocalStorage from "../storage/local.storage.js";
import Repository from "../contact/contacts.js";
import configuration from "../../../common/configuration.js";

let instance = null;
let mockedInstance = null;

export default function() {
	if ( mockedInstance ) {
		return mockedInstance;
	}
	if ( !instance ) {
		let cr = configuration.getContactRecordCount();
		let cs = configuration.getContactRecordSize();
		if ( !cr ) {
			console.warn( "Contact record count not set at a config. Defaulting to 100" );
			cr = 100;
		}


		if ( global.location.href.substr( 0, 4 ) === "http" ) {
			console.warn( `Cannot use more than ${LocalStorage.getMaxContacts()} contact records for in-memory storage` );
			cr = LocalStorage.getMaxContacts();
		}
		instance = new Repository( DefaultStorage, cs, cr );
	}
	return instance;
};

export function mock( newMockedInstance ) { mockedInstance = newMockedInstance; };

export function unmock() { mockedInstance = null; };
