import React from "react";
import _ from "lodash";
import Translation from "../../components/translation.jsx";
import Timg from "../../components/timg.jsx";
import FullScreen from "../../components/fullscreen.jsx";

function validateChildrenCount( childrenCount ) {
	return ( props, propName, componentName ) => {
		let children = props[ propName ];
		if ( React.Children.count( children ) !== childrenCount ) {
			return new Error( `${componentName} should have exactly ${childrenCount} children.` );
		}
	};
}

export let MenuItem = ( { value, arrow, icon, titleTextId, iconClass = "", children, ...props } ) => (
	<div {...props} className="item">
		{value && <span className="value f-right">{value}</span>}
		{arrow && <img src="images/icon-menu-arrow-right.svg" className="arrow"/>}
		<Timg src={icon} altTextId={titleTextId} className={"icon " + iconClass}/>
		<span className="label"><Translation textId={titleTextId}/></span>
		{children}
	</div>
);

export class ModalItemController extends React.Component {
	constructor() {
		super();
		this.state = {
			visible: false,
			version: 0
		};
		this.show = this.show.bind( this );
		this.onBack = this.onBack.bind( this );
	}

	static get propTypes() {
		return {
			onBack: React.PropTypes.func,
			children: validateChildrenCount( 2 )
		};
	}

	static get defaultProps() {
		return {
			onBack: _.noop
		};
	}

	show() {
		if ( React.Children.toArray( this.props.children ) [ 1 ] ) {
			this.setState( { visible: true, version: this.state.version + 1 } );
		}
	}

	hide() {
		this.setState( { visible: false, version: this.state.version + 1 } );
	}

	onBack() {
		this.hide();
		this.props.onBack();
	}

	renderChild( child ) {
		return (
			<ModalItem visible={true} onBack={this.onBack} key={this.state.version}>
				{child}
			</ModalItem>
		);
	}

	render() {
		let { children } = this.props;
		let [ trigger, modalView ] = React.Children.toArray( children );
		if ( this.state.visible ) {
			return this.renderChild( modalView );
		}
		return React.cloneElement( trigger, { onClick: this.show } );
	}
}

export class ModalItem extends React.Component {
	static get propTypes() {
		return {
			visible: React.PropTypes.bool,
			onBack: React.PropTypes.func
		};
	}

	static get defaultProps() {
		return {
			visible: false,
			onBack: _.noop
		};
	}

	renderChild( child, onBack ) {
		return (
			<FullScreen>
				{React.cloneElement( React.Children.only( child ), { onBack } )}
			</FullScreen>
		);
	}

	render() {
		let { children, visible, onBack } = this.props;
		return visible && this.renderChild( children, onBack );
	}
}

export let ModalMenuItem = ( { children, onBack, ...props } ) => (
	<ModalItemController onBack={onBack}>
		<MenuItem {...props} />
		{children}
	</ModalItemController>
);

export default class ModalMenuView extends React.Component {
	render() {
		return (
			<div className="menu-list">
				{this.props.children}
			</div>
		);
	}
}
