import React from "react";
import { Button } from "react-bootstrap";
import { isValidToken } from "../../../../common/utils.js";
import remoteServiceLocator from "../../../../api/services/locators/remote.js";
import Translation from "../../../components/translation.jsx";
import Tinput from "../common/tinput.jsx";

class AcceptInviteContentView extends React.Component {
	constructor() {
		super();
		this.state = {
			token: ""
		};
		this.onTokenChange = this.onTokenChange.bind( this );
		this.onNameChange = this.onNameChange.bind( this );
		this.onContinueClick = this.onContinueClick.bind( this );

		this._service = remoteServiceLocator();
	}

	onTokenChange( token ) {
		if ( this.state.status === "inProgress" ) {
			return;
		}
		if ( !isValidToken( token ) ) {
			this.setState( { token, status: "invalid" } );
			return;
		}
		this.setState( { token } );
		this.requestData( token );
	}

	onNameChange( event ) {
		if ( this.state.status === "inProgress" ) {
			return;
		}
		this.setState( { name: event.target.value } );
	}

	onContinueClick() {
		this.setState( { status: "inProgress" } );

		this._service.acceptInviteAsync( this.state.token, this.state.name )
			.subscribe( contact => {
				if ( !contact ) {
					this.setState( { status: "invalid", name: "" } );
					return;
				}
				this.props.onBack( contact );
			} );
	}

	requestData( token ) {
		this._service.getInviteDataAsync( token )
			.subscribe( data => {
				if ( this.state.token !== token ) {
					return;
				}
				if ( !data ) {
					this.setState( { token, status: "invalid", name: "" } );
					return;
				}
				this.setState( {
					status: "valid",
					name: data.nickname || this.state.name
				} );
			} );
	}

	render() {
		//TODO: check uniquness
		let continueEnabled = this.state.status === "valid" && this.state.name;
		return (
			<div className={this.props.className}>
				<div className="invite">
					<p>
						<Translation textId="web.accept.invite.text1" />
					</p>
					<Tinput
						className="name"
						type="text"
						placeholderTextId="web.accept.invite.placeholder"
						value={ this.state.token }
						onChange={ this.onTokenChange }
						id="invite-code"
					/>
					<br /><br /><br />
					<p>
						<Translation textId="web.accept.invite.text2" />
					</p>
					<input
						className="name"
						type="text"
						value={this.state.name || ""}
						onChange={this.onNameChange}
						id="invite-name"
					/>
					<div className="text-center">
						<Button
							className="btn-blue"
							disabled={!continueEnabled}
							onClick={this.onContinueClick}
						>
							<Translation textId="web.accept.invite.add.button" />
						</Button>
					</div>
				</div>
			</div>
		);
	}

	static get propTypes() {
		return {
			onBack: React.PropTypes.func
		}
	}
}

export default AcceptInviteContentView;
