import Rx from "rx";
import Queue from "promise-queue";
import ssgCrypto from "ssg.crypto";

import configuration from "../../common/configuration.js";

let transactionQueue = new Queue( 1 );

class Transaction {
	constructor( transactionId, onCompleted ) {
		if ( typeof transactionId !== "string" ) {
			throw new Error( "transactionId must be a string" );
		}

		if ( onCompleted && ( typeof onCompleted !== "function" ) ) {
			throw new Error( "onCompleted handler must be a function" );
		}

		this._onCompleted = onCompleted ? [ onCompleted ] : [];
		this._transactionId = transactionId;
		this._waits = [];
		this._isDone = false;

		this._whenDoneThen = new Promise( resolve => {
			this._whenDoneCb = resolve;
		} );
		this._whenDoneThen.then(
			() => {
				this._isDone = true;
				if ( !this._isInitialized ) {
					this.setCompleted( { status: "ok", empty: true } );
				}
			},
			err => { console.error( err ); }
		);
	}

	isInitialized( ) {
		return !!this._isInitialized;
	}

	initialize( apiUrlBase, doneCallback ) {
		this._isInitialized = true;
		this._apiUrlBase = apiUrlBase;
		this._operations = [];
		this._whenDoneThen.then( () => {
			doneCallback( this._isCanceled );
		} );
	}

	waitSendReadyThen( ) {
		return this._whenDoneThen;
	}

	addCheck( apiUrlBase, to, opOptions ) {
		if ( this._apiUrlBase !== apiUrlBase ) {
			throw new Error( "apiUrlBase do not match" );
		}
		if ( !this._isInitialized ) {
			throw Error( "Initialize transaction first" );
		}
		if ( this._isDone ) {
			throw new Error( "Cannot add message to a done transaction" );
		}

		let operation = {
			name: "check",
			to: to,
			...opOptions
		};
		this._operations.push( operation );
	}

	addMessage( apiUrlBase, message, opOptions ) {
		if ( this._apiUrlBase !== apiUrlBase ) {
			throw new Error( "apiUrlBase do not match" );
		}
		if ( !this._isInitialized ) {
			throw Error( "Initialize transaction first" );
		}
		if ( this._isDone ) {
			throw new Error( "Cannot add message to a done transaction" );
		}

		let operation = {
			name: "send",
			to: message.to,
			body: message.body,
			...opOptions
		};
		this._operations.push( operation );
	}

	addOneWayMessage( apiUrlBase, message, opOptions ) {
		if ( this._apiUrlBase !== apiUrlBase ) {
			throw new Error( "apiUrlBase do not match" );
		}
		if ( !this._isInitialized ) {
			throw Error( "Initialize transaction first" );
		}
		if ( this._isDone ) {
			throw new Error( "Cannot add message to a done transaction" );
		}

		let operation = {
			name: "sendOneWay",
			toHash: message.toHash,
			body: message.body,
			...opOptions
		};
		this._operations.push( operation );
	}

	startExpire( apiUrlBase, message ) {
		if ( this._apiUrlBase !== apiUrlBase ) {
			throw new Error( "apiUrlBase do not match" );
		}
		if ( !this._isInitialized ) {
			throw Error( "Initialize transaction first" );
		}
		if ( this._isDone ) {
			throw new Error( "Cannot add message to a done transaction" );
		}

		if ( typeof message.containerId !== "string" ) {
			throw new Error( "String container required" );
		}

		if ( typeof message.index !== "number" ) {
			throw new Error( "Number index required" );
		}

		let operation = {
			name: "setStartExpire",
			containerId: message.containerId,
			index: message.index
		};
		this._operations.push( operation );
	}

	addSetValue( apiUrlBase, message, opOptions ) {
		if ( this._apiUrlBase !== apiUrlBase ) {
			throw new Error( "apiUrlBase do not match" );
		}
		if ( !this._isInitialized ) {
			throw Error( "Initialize transaction first" );
		}
		if ( this._isDone ) {
			throw new Error( "Cannot add message to a done transaction" );
		}

		if ( typeof message.containerId !== "string" ) {
			throw new Error( "String container required" );
		}

		if ( typeof message.index !== "number" ) {
			throw new Error( "Number key required" );
		}

		if ( typeof message.value !== "string" ) {
			throw new Error( "String value required" );
		}

		let operation = {
			...opOptions,
			name: "set",
			containerId: message.containerId,
			index: message.index,
			value: message.value,
			lockId: message.lockId
		};
		this._operations.push( operation );
	}

	waitFor( observable, name ) {
		if ( this._isDone ) {
			throw new Error( "Transaction done. Nothing to wait: " + name );
		}
		let startAt = +new Date;
		this._waits.push( observable );
		let interval = setInterval( () => {
			console.warn( `Transaction is still waiting for ${name}`, this._transactionId );
		}, 4000 );

		observable.subscribeOnCompleted( () => {
			clearInterval( interval );
			console.log(`${name} completed in`, (+new Date - startAt) + " ms");
			this._waits.splice( this._waits.indexOf( observable ), 1 );
			if ( this._askedDone && !this._waits.length ) {
				this._whenDoneCb();
			}
		} );
	}

	done( ) {
		this._askedDone = true;
		if ( !this._waits.length ) {
			this._whenDoneCb();
		}
	}

	setCompleted( result ) {
		if ( this._isCompleted ) {
			return;
		}
		try {
			this._result = result;
			this._isCompleted = true;
			this._onCompleted.forEach( func => func( result ) );
		} finally {
			this._onCompleted = null;
		}
	}

	onCompleted( func ) {
		if ( !this._isCompleted ) {
			this._onCompleted.push( func );
		} else {
			func( this._result );//already completed
		}
	}

	getTransactionId( ) {
		return this._transactionId;
	}

	getOperations( ) {
		return this._operations || [];
	}

	cancel( )  {
		this._isCanceled = true;
	}

	static runWithRetriesAsync( func, skipQueue, name ) {
		return (
			Rx.Observable.fromPromise( Transaction.runWithRetriesThen( func, skipQueue, name ) )
		);
	}

	static runWithRetriesThen( func, skipQueue, name ) {
		if ( skipQueue ) {
			return Transaction._runWithRetriesThen( func, name );
		}
		let resSubj = new Rx.ReplaySubject();
		return transactionQueue.add( () =>
			Transaction._runWithRetriesThen( func )
		);
	}

	static _runWithRetriesThen( func, name ) {
		let retry;
		let complete;
		let timeoutHandler = null;
		let isCompleted = false;

		let funcThen = ( t, prevResult ) => {
			let res = func( t, prevResult );
			if ( res && res.subscribe ) {
				res = new Promise( ( resolve, reject ) => {
					res.subscribe( resolve, reject );
				} );
			} else if ( !res || !res.then ) {
				res = Promise.resolve( res );
			}

			return res;
		};

		function createTimeout( onTimeout ) {
			timeoutHandler && clearTimeout( timeoutHandler );
			timeoutHandler = setTimeout( () => {
				if ( isCompleted ) {
					return;
				}
				console.warn("Transaction timeout. retrying", name);
				onTimeout();
			}, configuration.getNetworkTimeout() );
		}

		return (
			ssgCrypto.createRandomBase64StringThen( configuration.getIdsLength() )
				.then( tid => {
					retry = ( prevResult ) => {
						if ( this._isCanceled ) {
							timeoutHandler && clearTimeout( timeoutHandler );
							timeoutHandler = null;
							isCompleted = true;
							return Promise.resolve( { status: "canceled" } );
						}
						console.log( "starting transaction", new Date, name );
						let t = new Transaction( tid );
						return (
							funcThen( t, prevResult )
								.then( () => {
									console.log( "transaction done", new Date, name );
									t.done();
									return t.waitSendReadyThen();
								} )
								.then( () => {
									console.log( "transaction send ready", new Date, name );
									return new Promise( resolve => {
										createTimeout( resolve );
										t.onCompleted( resolve );
									} );
								} )
								.then( result => {
									console.log( "transaction completed", new Date, result, name );
									timeoutHandler && clearTimeout( timeoutHandler );
									timeoutHandler = null;
									if ( !result ) {
										//timeout
										return retry();
									}
									switch ( result.status ) {
										case "ok":
											if ( isCompleted ) {
												console.error( "Transaction double complete", name );
												return;
											}
											return result;
										case "duplicate":
											console.warn( "Transaction duplicate", name );
											return { status: "ok" };//TODO: do not hide real result
										case "checkNotMet":
											console.warn( "checkNotMet", t, result, name );
											return new Promise( ( resolve, reject ) => {
												setTimeout(
													() => { retry( result ).then( resolve, reject ); },
													configuration.getTransactionRetryDelay() * ( Math.random() + 1 ) | 0
												);
											} );
											//TODO: some wait for new messages mechanism
										case "timeout":
											console.warn( "Transaction timedout", name );
											return retry( result );
										default:
											console.error( "invalid server response", result, name );
											return retry( result );
									}
								} )
						);
					}
					return retry();
				} )
		);
	}
}

export default Transaction;
