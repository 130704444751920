"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _crypto = require("crypto");

var _crypto2 = _interopRequireDefault(_crypto);

var _bn = require("bn.js");

var _bn2 = _interopRequireDefault(_bn);

var _prng = require("../../random/locators/prng.js");

var _prng2 = _interopRequireDefault(_prng);

var _bnExternal = require("../../bn.external/bn.external.js");

var _bnExternal2 = _interopRequireDefault(_bnExternal);

var _verify = require("../abstract/verify.js");

var _verify2 = _interopRequireDefault(_verify);

var _schnorr = require("../../group/schnorr.js");

var _schnorr2 = _interopRequireDefault(_schnorr);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var random = (0, _prng2.default)();

var SchnorrVerify = function (_AbstractVerify) {
	_inherits(SchnorrVerify, _AbstractVerify);

	function SchnorrVerify(group) {
		_classCallCheck(this, SchnorrVerify);

		if (!(group instanceof _schnorr2.default)) {
			throw new Error("Group (schnorr) required");
		}

		var _this = _possibleConstructorReturn(this, (SchnorrVerify.__proto__ || Object.getPrototypeOf(SchnorrVerify)).call(this));

		_this._group = group;
		return _this;
	}

	_createClass(SchnorrVerify, [{
		key: "getPublicKeyByteLength",
		value: function getPublicKeyByteLength() {
			return this._group.getP().byteLength();
		}
	}, {
		key: "getSignByteLength",
		value: function getSignByteLength() {
			return this._group.getQ().byteLength() * 2;
		}
	}, {
		key: "setPublicKey",
		value: function setPublicKey(publicKey) {
			if (!Buffer.isBuffer(publicKey)) {
				throw new Error("publicKey required");
			}

			if (publicKey.length !== this._group.getP().byteLength()) {
				throw new Error("publicKey invalid size. Expected " + this._group.getP().byteLength() + " but got " + publicKey.length);
			}

			this._publicKey = publicKey;
		}
	}, {
		key: "verifyThen",
		value: function verifyThen(params) {
			var _this2 = this;

			if (!Buffer.isBuffer(params.signature)) {
				throw new Error("signature buffer required");
			}

			if (!params.binarySource || !params.binarySource.getBufferThen) {
				throw new Error("binarySource required");
			}

			if (params.signature.length != this.getSignByteLength()) {
				throw new Error("buffer invalid size. Expecting " + this.getSignByteLength() + ", got " + params.signature.length);
			}

			if (!this._publicKey) {
				throw new Error("set publicKey first. use setPublicKey method");
			}

			var subgroupLength = this._group.getQ().byteLength();
			var sBuffer = params.signature.slice(0, subgroupLength);
			var eBuffer = params.signature.slice(subgroupLength, 2 * subgroupLength);
			var sBN = new _bn2.default(sBuffer);
			var eBN = new _bn2.default(eBuffer); //ensure it is in group
			var yRed = new _bn2.default(this._publicKey).toRed(this._group.getGRed().red);
			var q = this._group.getQ();

			if (sBN.cmp(q) !== -1) {
				console.error("Invalid sBN size");
				return Promise.resolve(false);
			}

			if (eBN.cmp(q) !== -1) {
				console.error("Invalid eBN size");
				return Promise.resolve(false);
			}
			if (sBN.cmpn(0) === 0) {
				console.error("sBN zero");
				return Promise.resolve(false);
			}

			if (eBN.cmpn(0) === 0) {
				console.error("eBN zero");
				return Promise.resolve(false);
			}
			var bufferThen = params.binarySource.getBufferThen();
			if (!bufferThen.then) {
				throw new Error("binarySource.bufferThen returned invalid value");
			}

			var yeThen = _bnExternal2.default.redPowExternalThen(yRed, eBN);
			var gsThen = _bnExternal2.default.redPowExternalThen(this._group.getGRed(), sBN);
			return Promise.all([yeThen, gsThen, bufferThen.then(function (buffer) {
				return _crypto2.default.createHash("sha256").update(buffer);
			})]).then(function (_ref) {
				var _ref2 = _slicedToArray(_ref, 3),
				    ye = _ref2[0],
				    gs = _ref2[1],
				    hash = _ref2[2];

				var rvBN = gs.redMul(ye).fromRed();
				var rvLength = _this2._group.getP().byteLength();

				hash.update(new Buffer(rvBN.toArray("LE", rvLength)));
				return new _bn2.default(hash.digest()).umod(q).cmp(eBN) === 0;
			});
		}
	}]);

	return SchnorrVerify;
}(_verify2.default);

exports.default = SchnorrVerify;