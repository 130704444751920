import Rx from "rx";

export const MESSAGE_TYPE = {
	INCOMING: { name: "INCOMING" },
	OUTGOING: { name: "OUTGOING" },
	SERVICE:  { name: "SERVICE" }
};

export class ChatMessageBase {
	constructor( type, timestamp, senderPid, index, p2pIndex, id ) {
		if ( !type || ( MESSAGE_TYPE[ type.name ] !== type ) ) {
			throw new Error( "Invalid chat message type" );
		}

		if ( typeof senderPid !== "string" ) {
			throw new Error( "SenderPid string required" );
		}

		if ( typeof timestamp !== "number" ) {
			throw new Error( "Invalid message timestamp" );
		}

		if ( typeof index !== "number" ) {
			throw new Error( "Invalid message index" );
		}

		this._type = type;
		this._timestamp = timestamp;
		this._senderPid = senderPid;
		this.index = index;
		this.p2pIndex = p2pIndex;

		this.id = id;
	}

	get type( ) {
		return this._type;
	}

	get timestamp( ) {
		return this._timestamp;
	}

	get senderPid( ) {
		return this._senderPid;
	}

	get contentType( ) {
		return this._contentType;
	}

	toJson( ) {
		return {
			type: this._type.name,
			timestamp: this._timestamp,
			senderPid: this._senderPid,
			p2pIndex: this.p2pIndex,
			index: this.index,
			id: this.id,
			contentType: this._contentType,
			ttlBaseTimestamp: this.ttlBaseTimestamp,
			isRead: this.isRead,
			isProcessed: this.isProcessed
		};
	}

	static fromJson( {
		type,
		timestamp,
		senderPid,
		index,
		p2pIndex,
		id,
		contentType,
		text,
		thumbnailBase64,
		milliseconds,
		fileToken,
		fileName,
		contactInviteDataString,
		workgroupInviteDataString,
		groupInviteDataString,
		ttlBaseTimestamp,
		isProcessed,
		replaceIndex,
		replyTo,
		isRead
	} ) {
		if ( text ) {
			let message = new ChatMessageText(
				MESSAGE_TYPE[ type ] || MESSAGE_TYPE[ type.name ],
				text,
				timestamp,
				senderPid,
				index,
				p2pIndex,
				id,
				replaceIndex,
				replyTo
			);
			message.isRead = isRead;
			message.ttlBaseTimestamp = ttlBaseTimestamp;
			return message;
		}

		if ( contentType === "delete" ) {
			let message = new ChatMessageDelete(
				MESSAGE_TYPE[ type ] || MESSAGE_TYPE[ type.name ],
				timestamp,
				senderPid,
				index,
				p2pIndex,
				id,
				replaceIndex
			);
			message.isRead = isRead;
			message.ttlBaseTimestamp = ttlBaseTimestamp;
			return message;
		}

		if ( thumbnailBase64 ) {
			let message = new ChatMessageImage(
				MESSAGE_TYPE[ type ] || MESSAGE_TYPE[ type.name ],
				thumbnailBase64,
				fileToken,
				fileName,
				contentType,
				timestamp,
				senderPid,
				index,
				p2pIndex,
				id
			);
			message.isRead = isRead;
			message.ttlBaseTimestamp = ttlBaseTimestamp;
			return message;
		}

		if ( contactInviteDataString ) {
			let message = new ChatMessageContact(
				MESSAGE_TYPE[ type ] || MESSAGE_TYPE[ type.name ],
				contactInviteDataString,
				timestamp,
				senderPid,
				index,
				p2pIndex,
				id
			);
			message.isRead = isRead;
			message.ttlBaseTimestamp = ttlBaseTimestamp;
			message.isProcessed = isProcessed;
			return message;
		}

		if ( workgroupInviteDataString ) {
			let message = new ChatMessageWorkgroup(
				MESSAGE_TYPE[ type ] || MESSAGE_TYPE[ type.name ],
				workgroupInviteDataString,
				timestamp,
				senderPid,
				index,
				p2pIndex,
				id
			);
			message.isRead = isRead;
			message.ttlBaseTimestamp = ttlBaseTimestamp;
			message.isProcessed = isProcessed;
			return message;
		}

		if ( groupInviteDataString ) {
			let message = new ChatMessageGroup(
				MESSAGE_TYPE[ type ] || MESSAGE_TYPE[ type.name ],
				groupInviteDataString,
				timestamp,
				senderPid,
				index,
				p2pIndex,
				id
			);
			message.isRead = isRead;
			message.ttlBaseTimestamp = ttlBaseTimestamp;
			message.isProcessed = isProcessed;
			return message;
		}

		if ( !fileToken ) {
			return null;
		}

		if ( milliseconds ) {
			let message = new ChatMessageAudio(
				MESSAGE_TYPE[ type ] || MESSAGE_TYPE[ type.name ],
				milliseconds,
				fileToken,
				fileName,
				contentType,
				timestamp,
				senderPid,
				index,
				p2pIndex,
				id
			);
			message.isRead = isRead;
			message.ttlBaseTimestamp = ttlBaseTimestamp;
			return message;
		}
		let message = new ChatMessageFile(
			MESSAGE_TYPE[ type ] || MESSAGE_TYPE[ type.name ],
			fileToken,
			fileName,
			contentType,
			timestamp,
			senderPid,
			index,
			p2pIndex,
			id
		);
		message.isRead = isRead;
		message.ttlBaseTimestamp = ttlBaseTimestamp;
		return message;
	}
}

export class ChatMessageDelete extends ChatMessageBase {
	constructor( type, timestamp, senderPid, index, p2pIndex, id, replaceIndex ) {
		super( type, timestamp, senderPid, index, p2pIndex, id );
		this._contentType = "delete";
		this._replaceIndex = replaceIndex;
	}

	get replaceIndex( ) {
		return this._replaceIndex;
	}

	toJson( ) {
		let json = super.toJson();
		json.replaceIndex = this._replaceIndex;
		return json;
	}
}

export class ChatMessageText extends ChatMessageBase {
	constructor( type, text, timestamp, senderPid, index, p2pIndex, id, replaceIndex, replyTo ) {
		super( type, timestamp, senderPid, index, p2pIndex, id );
		this._text = text;
		this._contentType = "text/plain";
		this._replaceIndex = replaceIndex;
		this._replyTo = replyTo;
	}

	get replyTo() {
		return this._replyTo;
	}

	get replaceIndex( ) {
		return this._replaceIndex;
	}

	get text( ) {
		return this._text;
	}

	toJson( ) {
		let json = super.toJson();
		json.text = this._text;
		json.replaceIndex = this._replaceIndex;
		if (this._replyTo && this._replyTo.replySenderPid) {
			json.replyTo = this._replyTo;
		}
		return json;
	}
}

export class ChatMessageFile extends ChatMessageBase {
	constructor( type,
		fileToken,
		fileName,
		contentType,
		timestamp,
		senderPid,
		index,
		p2pIndex,
		id
	) {
		super( type, timestamp, senderPid, index, p2pIndex, id );

		if ( !fileToken ) {
			throw new Error( "fileToken required" );
		}

		// if ( !fileName ) {
		// 	throw new Error( "FileName required" );
		// }

		this._fileToken = fileToken;
		this._fileName = fileName;
		this._contentType = contentType;
	}

	get fileToken( ) {
		return this._fileToken;
	}

	get fileName( ) {
		return this._fileName;
	}

	toJson( ) {
		let json = super.toJson();
		json.fileToken = this._fileToken;
		json.fileName = this._fileName;
		return json;
	}
}

export class ChatMessageImage extends ChatMessageBase {
	constructor( type,
		thumbnailBase64,
		fileToken,
		fileName,
		contentType,
		timestamp,
		senderPid,
		index,
		p2pIndex,
		id
	) {
		super( type, timestamp, senderPid, index, p2pIndex, id );
		if ( !thumbnailBase64 ) {
			throw new Error( "Thumbnail required" );
		}

		if ( !fileToken ) {
			throw new Error( "fileToken required" );
		}
		// It is not required
		// if ( !fileName ) {
		// 	throw new Error( "FileName required" );
		// }

		this._thumbnailBase64 = thumbnailBase64;
		this._fileToken = fileToken;
		this._fileName = fileName;
		this._contentType = contentType;
	}

	get thumbnailBase64( ) {
		return this._thumbnailBase64;
	}

	get fileToken( ) {
		return this._fileToken;
	}

	get fileName( ) {
		return this._fileName;
	}

	toJson( ) {
		let json = super.toJson();
		json.thumbnailBase64 = this._thumbnailBase64;
		json.fileToken = this._fileToken;
		json.fileName = this._fileName;
		return json;
	}
}

export class ChatMessageAudio extends ChatMessageBase {
	constructor( type,
		milliseconds,
		fileToken,
		fileName,
		contentType,
		timestamp,
		senderPid,
		index,
		p2pIndex,
		id
	) {
		super( type, timestamp, senderPid, index, p2pIndex, id );
		if ( !milliseconds ) {
			throw new Error( "Milliseconds required" );
		}

		if ( !fileToken ) {
			throw new Error( "fileToken required" );
		}

		this._milliseconds = milliseconds;
		this._fileToken = fileToken;
		this._fileName = fileName;
		this._contentType = contentType;
	}

	get thumbnailBase64( ) {
		return this._thumbnailBase64;
	}

	get fileToken( ) {
		return this._fileToken;
	}

	get fileName( ) {
		return this._fileName;
	}

	toJson( ) {
		let json = super.toJson();
		json.thumbnailBase64 = this._thumbnailBase64;
		json.fileToken = this._fileToken;
		json.fileName = this._fileName;
		json.milliseconds = this._milliseconds;
		return json;
	}
}

export class ChatMessageContact extends ChatMessageBase {
	constructor( type, contactInviteDataString, timestamp, senderPid, index, p2pIndex, id ) {
		super( type, timestamp, senderPid, index, p2pIndex, id );
		this._contactInviteDataString = contactInviteDataString;
		this._contentType = "contact";
	}

	get contactInviteDataString( ) {
		return this._contactInviteDataString;
	}

	toJson( ) {
		let json = super.toJson();
		json.contactInviteDataString = this._contactInviteDataString;
		json.isProcessed = this.isProcessed;
		return json;
	}
}

export class ChatMessageWorkgroup extends ChatMessageBase {
	constructor( type, workgroupInviteDataString, timestamp, senderPid, index, p2pIndex, id ) {
		super( type, timestamp, senderPid, index, p2pIndex, id );
		this._workgroupInviteDataString = workgroupInviteDataString;
		this._contentType = "workgroup";
	}

	get workgroupInviteDataString( ) {
		return this._workgroupInviteDataString;
	}

	toJson( ) {
		let json = super.toJson();
		json.workgroupInviteDataString = this._workgroupInviteDataString;
		json.isProcessed = this.isProcessed;
		return json;
	}
}

export class ChatMessageGroup extends ChatMessageBase {
	constructor( type, groupInviteDataString, timestamp, senderPid, index, p2pIndex, id ) {
		super( type, timestamp, senderPid, index, p2pIndex, id );
		this._groupInviteDataString = groupInviteDataString;
		this._contentType = "group";
	}

	get groupInviteDataString( ) {
		return this._groupInviteDataString;
	}

	toJson( ) {
		let json = super.toJson();
		json.groupInviteDataString = this._groupInviteDataString;
		json.isProcessed = this.isProcessed;
		return json;
	}
}
