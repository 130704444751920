import _ from "lodash";
import Queue from "promise-queue";

import BaseStorage from "./base.storage.js";

const CHUNK_SIZE = 8192;

let deviceReadyThen = new Promise( resolve => {
	if ( !global.document ) {
		console.warn( "FILES: Document object is not available" );
	} else {
		global.document.addEventListener( "deviceready", resolve );
	}
} );

let resolveDataDirThen = (
	deviceReadyThen
		.then( () =>
			new Promise( ( resolve, reject ) =>
				global.resolveLocalFileSystemURL(
					cordova.file.dataDirectory, resolve, reject
				)
			)
		)
	);

function promiseFromReader( reader ) {
	if ( reader.onload || reader.onerror ) {
		throw new Error( "reader.onload || reader.onerror" );
	}
	return new Promise( ( resolve, reject ) => {
		reader.onload = resolve;
		reader.onerror = reject;
	} ).then( () => {
		reader.onload = null;
		reader.onerror = null;
		return reader.result;
	}, error => {
		reader.onload = null;
		reader.onerror = null;
		throw error;
	} );
}

function promiseFromWriter( writer ) {
	if ( writer.onwriteend || writer.onerror ) {
		throw new Error( "writer.onload || writer.onerror" );
	}
	return new Promise( ( resolve, reject ) => {
		writer.onwriteend = resolve;
		writer.onerror = reject;
	} ).then( event => {
		writer.onwriteend = null;
		writer.onerror = null;
		return event;
	}, error => {
		writer.onwriteend = null;
		writer.onerror = null;
		throw error;
	} );
}

function writeBase64String(data, isPendingBlobReadResult) {

    var that=this;
    var supportsBinary = (typeof window.Blob !== 'undefined' && typeof window.ArrayBuffer !== 'undefined');
    var isProxySupportBlobNatively = (cordova.platformId === "windows8" || cordova.platformId === "windows");
    var isBinary;

    // Check to see if the incoming data is a blob
    if (data instanceof File || (!isProxySupportBlobNatively && supportsBinary && data instanceof Blob)) {
        var fileReader = new FileReader();
        fileReader.onload = function() {
            // Call this method again, with the arraybuffer as argument
            FileWriter.prototype.write.call(that, this.result, true /* isPendingBlobReadResult */);
        };
        fileReader.onerror = function () {
            // DONE state
            that.readyState = FileWriter.DONE;

            // Save error
            that.error = this.error;

            // If onerror callback
            if (typeof that.onerror === "function") {
                that.onerror(new ProgressEvent("error", {"target":that}));
            }

            // If onwriteend callback
            if (typeof that.onwriteend === "function") {
                that.onwriteend(new ProgressEvent("writeend", {"target":that}));
            }
        };

        // WRITING state
        this.readyState = FileWriter.WRITING;

        if (supportsBinary) {
            fileReader.readAsArrayBuffer(data);
        } else {
            fileReader.readAsText(data);
        }
        return;
    }

    // Mark data type for safer transport over the binary bridge
    isBinary = true;
    if (isBinary && cordova.platformId === "windowsphone") {
        // create a plain array, using the keys from the Uint8Array view so that we can serialize it
        data = Array.apply(null, new Uint8Array(data));
    }

    // Throw an exception if we are already writing a file
    if (this.readyState === FileWriter.WRITING && !isPendingBlobReadResult) {
        throw new FileError(FileError.INVALID_STATE_ERR);
    }

    // WRITING state
    this.readyState = FileWriter.WRITING;

    var me = this;

    // If onwritestart callback
    if (typeof me.onwritestart === "function") {
        me.onwritestart(new ProgressEvent("writestart", {"target":me}));
    }

    // Write file
    cordova.exec(
        // Success callback
        function(r) {
            // If DONE (cancelled), then don't do anything
            if (me.readyState === FileWriter.DONE) {
                return;
            }

            // position always increases by bytes written because file would be extended
            me.position += r;
            // The length of the file is now where we are done writing.

            me.length = me.position;

            // DONE state
            me.readyState = FileWriter.DONE;

            // If onwrite callback
            if (typeof me.onwrite === "function") {
                me.onwrite(new ProgressEvent("write", {"target":me}));
            }

            // If onwriteend callback
            if (typeof me.onwriteend === "function") {
                me.onwriteend(new ProgressEvent("writeend", {"target":me}));
            }
        },
        // Error callback
        function(e) {
            // If DONE (cancelled), then don't do anything
            if (me.readyState === FileWriter.DONE) {
                return;
            }

            // DONE state
            me.readyState = FileWriter.DONE;

            // Save error
            me.error = new FileError(e);

            // If onerror callback
            if (typeof me.onerror === "function") {
                me.onerror(new ProgressEvent("error", {"target":me}));
            }

            // If onwriteend callback
            if (typeof me.onwriteend === "function") {
                me.onwriteend(new ProgressEvent("writeend", {"target":me}));
            }
        }, "File", "write", [this.localURL, data, this.position, isBinary]);
};

class FileStorage extends BaseStorage {
	constructor( fileName ) {
		super();
		this._fileName = fileName;
		this._fileEntryThen = (
			resolveDataDirThen
				.then( de => new Promise( ( resolve, reject ) => {
					de.getFile( fileName, { create: true }, resolve, reject );
				} ) )
		);
		this._queue = new Queue( 1 );
	}

	_enqueue( func ) {
		return this._queue.add( () => this._fileEntryThen.then( func ) );
	}

	dropDataThen( ) {
		return this._enqueue( fe => new Promise( ( resolve, reject ) =>
			fe.remove( resolve, reject )
		) );
	}

	_readAsBufferThen( fe ) {
		let startAt = +new Date;
		return (
			new Promise( ( resolve, reject ) => {
				fe.file( resolve, reject );
			} )
				.then( file => {
					let reader = new FileReader();
					let resultThen = promiseFromReader( reader );

					reader.readAsArrayBuffer( file );
					return (
						resultThen
							.then( arraybuffer => {
								const buf = new Buffer( new Uint8Array( arraybuffer ) );
								if (buf.length === 0) { //TODO: remove
									return FileStorage.isFileExistsThen( this._fileName ).then( isExist => {
										if (isExist) {
											console.warn("Empty buffer " + this._fileName);
										}
										return buf;
									} );
								}
								return buf;
							} )
					);
				} )
				.catch( e => {
					debugger;
					if ( e && e.code === 1 ) {
						alert("Got Error: " + e);
						return new Buffer( 0 );
					}
					throw e;
				} )
				.then( result => {
					console.log("_readAsBufferThen", +new Date - startAt);
					return result;
				} )
		);
	}

	readAsBufferThen( ) {
		return this._enqueue( fe => this._readAsBufferThen( fe ) );
	}

	_replaceWithStringThen( fe, string ) {
		let startAt = +new Date;
		return (
			new Promise( ( resolve, reject ) => {
				fe.createWriter( resolve, reject );
			} )
				// .then( writer => {
				// 	let writerResultThen = promiseFromWriter( writer );
				// 	writer.truncate( 0 );
				// 	return writerResultThen.then( event => writer );
				// } )
				.then( writer => {
					let writerResultThen = promiseFromWriter( writer );
					writer.seek(0);
					writeBase64String.call( writer, string );
					return writerResultThen;
				} )
				.then( result => {
					console.log("_replaceWithStringThen", +new Date - startAt);
					return result;
				} )
		);
	}

	_replaceThen( fe, buffer ) {
		let startAt = +new Date;
		return (
			new Promise( ( resolve, reject ) => {
				fe.createWriter( resolve, reject );
			} )
				// .then( writer => {
				// 	let writerResultThen = promiseFromWriter( writer );
				//  This is the reason of disappearing contacts. App sometimes closes just after truncate!!
				// 	writer.truncate( 0 );
				// 	return writerResultThen.then( event => writer );
				// } )
				.then( writer => {
					let writerResultThen = promiseFromWriter( writer );
					let ab = new ArrayBuffer( buffer.length );
					let ui8a = new Uint8Array( ab );
					for ( let i = 0; i < buffer.length; i++ ) {
						ui8a[ i ] = buffer[ i ];
					}
					// buffer.copy( ui8a );

					writer.seek(0);
					writer.write( ab );
					return writerResultThen;
				} )
				.then( result => {
					console.log("_replaceThen", +new Date - startAt);
					return result;
				} )
		);
	}

	replaceThen( buffer ) {
		return this._enqueue( fe =>
			Buffer.isBuffer( buffer )
			? this._replaceThen( fe, buffer )
			//: this._replaceWithStringThen( fe, buffer )
			: this._replaceThen( fe, Buffer.from( buffer, "base64" ))
		);
	}

	readAtPositionThen( start, length ) {
		let startAt = +new Date;
		return (
			this._enqueue( fe => {
				let startAt = +new Date;
				return new Promise( ( resolve, reject ) => {
					fe.file( resolve, reject );
				} )
					.then( file => {
						let reader = new FileReader();
						let resultThen = promiseFromReader( reader );
						let slicedFile = file.slice( start, start + length );
						reader.readAsArrayBuffer( slicedFile );
						return (
							resultThen
								.then( arraybuffer => new Buffer(
									new Uint8Array( arraybuffer )
								) )
						);
					} )
					.then( result => {
						console.log("readAtPositionThen", +new Date - startAt);
						return result;
					} )
					.catch( e => {
						if ( e && e.code === 1 ) {
							return new Buffer( 0 );
						}
						throw e;
					} )
			} )
		);
	}

	writeAtPositionThen( buffer, start ) {
		if ( !Buffer.isBuffer( buffer ) ) {
			buffer = Buffer.from( buffer, "base64" );
		}
		return this._enqueue( fe =>
			this._readAsBufferThen( fe )
				.then( fileContent => {
					if ( fileContent.length < start + buffer.length ) {
						let newFileContent = new Buffer( start + buffer.length );
						fileContent.copy( newFileContent );
						fileContent = newFileContent;
					}
					buffer.copy( fileContent, start );
					return this._replaceThen( fe, fileContent );
				} )
		);
		//TODO: remove writeAtPositionThen method as it cannot be implemented efficiently
		//or modify file plugin
		//cordova-plugin-file\src\android\LocalFilesystem.java:373
	}

	getSizeThen() {
		return this._enqueue( fe =>
			new Promise( ( resolve, reject ) => {
				fe.file( resolve, reject );
			} )
				.then( file => file.size )
		);
	}

	renameThen( newFileName ) {
		return this._enqueue( fe =>
			new Promise( ( resolve, reject ) => {
				fe.getParent( resolve, reject );
			} )
				.then( parentFe =>
					new Promise( ( resolve, reject ) => {
						fe.moveTo( parentFe, newFileName, resolve, reject );
					} )
				)
				.then( newFe => {
					this._fileEntryThen = Promise.resolve( newFe );
					this._fileName = newFileName;
				} )
		);
	}

	getModificationTimeThen() {
		return this._enqueue( fe =>
			new Promise( ( resolve, reject ) => {
				fe.file( resolve, reject );
			} ).then(({lastModifiedDate}) => lastModifiedDate)
		);
	}

	static isFileExistsAsync( fileName ) {
		return Rx.Observable.fromPromise( FileStorage.isFileExistsThen( fileName ) );
	}

	static isFileExistsThen( fileName ) {
		return (
			resolveDataDirThen
				.then( de => new Promise( ( resolve, reject ) => {
					de.getFile(
						fileName, { create: false, exclusive: false }, resolve, reject
					)
				} ) )
				.then( () => true )
				.catch( error => {
					if ( error.code === FileError.NOT_FOUND_ERR ) {
						return false;
					}
					console.error( `Strange file error ${fileName}: ${error.code}` );
					return false;
				} )
		);
	}
}

export default FileStorage;
