import React from "react";
import Rx from "rx";
import _ from "lodash";

import WithFooterView from "../common/with.footer.view.jsx";
import HeaderWait from "../common/header.wait.jsx";

import FullScreen from "../../components/fullscreen.jsx";
import ImageButton from "../../components/button.image.jsx";
import Checkbox from "../../components/checkbox.jsx";
import ArrowButton from "../../components/button.continue.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import Translation from "../../components/translation.jsx";

import {getAvaColorByName, getContactInitials, getStatusColor, sortContacts}
	from "../../../common/utils.js";

//TODO: move contactIds to props

function toggleElementAtArray( array, element ) {
	let index = array.indexOf( element );
	~index ? array.splice( index, 1 ) : array.push( element );
}

let Avatar = ( { color, unreadCount, initials } ) => {
	if ( unreadCount ) {
		return <div className="ava count-sticker" data-count={unreadCount}
					style={{ backgroundColor: color }}>{initials}</div>;
	}
	return <div className="ava" style={{ backgroundColor: color }}>{initials}</div>;
};

let Contact = ( {contact, selectedIds, onToggle} ) => (
	<div className="item" onClick={ onToggle }>
		<Checkbox value={ ~selectedIds.indexOf( contact.id ) } />
		<Avatar {...{
				color: getAvaColorByName( contact.name ),
				unreadCount: 0,
				initials: getContactInitials( contact.name )
			}} />
		<div className="name">{contact.name}</div>
	</div>
);

let ContactList = ( { children } ) => (
	<div className="contact-list">
		{children}
		{!children.length && <h1><Translation textId="contacts.empty" /></h1>}
	</div>
);

class PromptParticipantsBase extends React.Component {
	constructor() {
		super();

		this.onBackPress = this.onBackPress.bind( this );
		this.onContinueClick = this.onContinueClick.bind( this );
		this.state = { contacts: [], selectedIds: [], done: false };
	}

	onBackPress() {
		this.setState( { done: true } );
		this.props.onDone && this.props.onDone();
	}

	onContinueClick() {
		this.setState( { done: true } );
		let res = this.props.onDone && this.props.onDone( this.state.selectedIds );
		if ( res && res.subscribe ) {
			res.subscribe( current => {
				this.setState( {
					total: this.state.selectedIds.length,
					current: current + 1
				} );
			} );
		}
	}

	toggleContact( contact ) {
		if ( this.state.done ) {
			return;
		}
		let selectedIds = this.state.selectedIds;

		toggleElementAtArray( selectedIds, contact.id );

		this.setState( {selectedIds} );
	}

	renderDescription() {
		if ( !this.descriptionTextId ) {
			return null;
		}
		return (
			<span className="small-text">
				<Translation textId={this.descriptionTextId} />
			</span>
		);
	}

	isButtonEnabled() {
		return ( this.state.selectedIds.length > 0 ) && ( !this.state.done );
	}

	getButtonCaption() {
		return "registration.continue.button";
	}

	renderHeader() {
		if ( this.state.done ) {
			return <HeaderWait total={this.state.total} current={this.state.current}/>;
		}
		return (
			<header className="small">
				<ImageButton type="icon-arrow-left" onClick={this.onBackPress}/>
				<span className="header-caption">
					<Translation textId={ this.headerTextId } />
				</span>
			</header>
		);
	}

	render() {
		return (
			<FullScreen>
				<WithFooterView id={this.props.id}>
					{ this.renderHeader() }
					<main>
						{ this.renderDescription() }
						<ContactList>
							{ sortContacts( this.state.contacts ).map(
								contact =>
									<Contact
										contact={ contact }
										selectedIds={ this.state.selectedIds }
										onToggle={ () => this.toggleContact( contact ) }
										key={ contact.id }
									/>
							) }
						</ContactList>
					</main>
					<footer>
						<ArrowButton
							enabled={ this.isButtonEnabled() }
							caption={ this.getButtonCaption() }
							handleClick={this.onContinueClick}
							id="prompt-participants-done"
						/>
					</footer>
				</WithFooterView>
				<DeviceBackButton onPress={ this.onBackPress }/>
			</FullScreen>
		);
	}

}

export default PromptParticipantsBase;
