import React from "react";
import _ from "lodash";

import Group from "./group.jsx";
import Translation from "../../components/translation.jsx";

class GroupListView extends React.Component {
	renderGroup( item ) {
		return (
			<Group
				contact={item}
				key={ item.id.toString( 16 ) }
			/>
		);
	}

	render() {
		return (
			<div className="group-list">
				{ this.props.items.map( item => this.renderGroup( item ) ) }
				{
					!this.props.items.length
					?<span className="small-text">
						<br />
						<Translation textId="groups.empty" />
					</span>
					: null
				}
			</div>
		);
	}
}
GroupListView.propTypes = {
	items: React.PropTypes.array.isRequired
};

export default GroupListView;
