import Rx from "rx";
import _ from "lodash";
import ssgCrypto, {Key, KEY_KINDS} from "ssg.crypto";

import { makeThumbnailThen } from "../../common/image.js";
import ClientServerRPCObservables from "./client.server.rpc.observables.js";
import configuration from "../../common/configuration.js";
import MessageModel from "../models/message/message.js";
import MessageSender from "../models/message/message.sender.js";
import MessageSerializer from "../models/message/message.serializer.js";
import Attachment from "../models/message/attachment.js";
import BinaryStream from "../models/binary.stream.js";
const MAX_FILE_SIZE_MB = 64;

function createSigner( macKey ) { //TODO: move to module
	return {
		getSignByteLength: index => 32,
		makeSignThen: ( { getBufferThen } ) =>
			getBufferThen()
				.then( buffer =>
					ssgCrypto.makeHmacCodeThen(
						macKey,
						buffer,
						configuration.getDefaultEncryptionConfig()
					)
				)
	};
}

function createVerifier( macKey ) {
	return {
		getSignByteLength: index => 32,
		verifyThen: ( { signature, binarySource } ) => {
			const keyHandle = macKey.addKeyUse('createVerifier');
			return (
				binarySource.getBufferThen()
					.then( buffer =>
						ssgCrypto.makeHmacCodeThen(
							macKey,
							buffer,
							configuration.getDefaultEncryptionConfig()
						)
					)
					.then( code => {
						macKey.removeKeyUse(keyHandle);
						return code.equals( signature );
					} )
			);
		}
	};
}

class RemoteControlClient extends ClientServerRPCObservables {
	constructor( connectionId, seedMacKey, seedEncryptionKey, macKey ) {
		if ( typeof connectionId !== "string" ) {
			throw new Error( "string connectionId required" );
		}

		if ( !( seedEncryptionKey instanceof Key )
			|| ( seedEncryptionKey.kind !== KEY_KINDS.INTERMEDIATE ) ) {
			throw new Error( "Seed Encryption key required" );
		}

		if ( !( seedMacKey instanceof Key )
			|| ( seedMacKey.kind !== KEY_KINDS.INTERMEDIATE ) ) {
			throw new Error( "Seed Mac key required" );
		}

		if ( !( macKey instanceof Key )
			|| ( macKey.kind !== KEY_KINDS.MAC ) ) {
			throw new Error( "Mac key required" );
		}

		super(
			configuration.getSocketBase(),
			connectionId,
			seedMacKey,
			seedEncryptionKey,
			createSigner( macKey ),
			createVerifier( macKey ),
			configuration.getDefaultEncryptionConfig()
		);
		this._onlineStatusSubj = new Rx.BehaviorSubject( true );
		this._sendingFiles = Object.create( null );
		this._sendingMessages = Object.create( null );
	}

	_callClientAsync( name, params ) {
		let timeout = setInterval( () => {
			console.warn( "RPC call timeout", name, params );
		}, 5000 );
		return super._callClientAsync( name, params ).finally( () => {
			clearInterval( timeout );
		} );
	}

	_uncompactContacts( currentContacts, diff, type2Set ) {
		for ( var i = 0; i < diff.length; i++ ) {
			var d = diff[ i ];
			if ( type2Set ) {
				d.type = type2Set;
			}
			if ( !d.status ) {
				delete currentContacts[ d.id ];
			} else {
				currentContacts[ d.id ] = d;
			}
		}
		return _.values( currentContacts );
	}

	observeContactList( ) {
		let currentContacts = Object.create( null );
		return (
			this._callRemoteObservable( "observeContactList", {} )
				.map( diff => {
					let array = _.toArray( diff );
					array.forEach( contact => {
						contact.type = "normal";
					} );
					return array;
				} )
				.map( diff => this._uncompactContacts( currentContacts, diff ) )
		);
	}

	observeGroupList( ) {
		let currentContacts = Object.create( null );
		return (
			this._callRemoteObservable( "observeGroupList", {} )
				.map( diff => {
					let array = _.toArray( diff );
					array.forEach( contact => {
						contact.type = "group";
					} );
					return array;
				} )
				.map( diff => this._uncompactContacts( currentContacts, diff ) )
		);
	}

	inviteContactAsync( contactName, selfNameOverride, isExternal ) {
		return (
			this._callClientAsync( "inviteContact", {contactName, selfNameOverride, isExternal} )
				.tap( contact => {
					contact.type = "normal";
				} )
		);
	}

	getInviteDataAsync( token ) {
		return this._callClientAsync( "getInviteData", {token} );
	}

	acceptInviteAsync( token,name ) {
		return this._callClientAsync( "acceptInvite", {token, name} );
	}

	renameContactAsync( contact, newName ) {
		return this._callClientAsync( "renameContact", {
			contactId: contact.id,
			contactType: contact.type,
			newName
		} );
	}

	deleteContactAsync( contact ) {
		return this._callClientAsync( "deleteContact", {
			contactId: contact.id
		} );
	}

	forwardMessageAsync( contact, forwardingMessage ) {
		let message = {
			type: "text",
			contentType: forwardingMessage.contentType
		};

		if ( forwardingMessage.text ) {
			message.text = forwardingMessage.text;
			message.type = "text";
		}
		if ( forwardingMessage.fileToken ) {
			message.fileToken = forwardingMessage.fileToken;
			message.type = "file";
		}
		if ( forwardingMessage.milliseconds ) {
			message.milliseconds = forwardingMessage.milliseconds;
		}
		if ( forwardingMessage.thumbnailBase64 ) {
			message.thumbnailBase64 = forwardingMessage.thumbnailBase64;
		}
		if ( forwardingMessage.fileName ) {
			message.name = forwardingMessage.fileName;
		}
		return this._callClientAsync( "sendMessage", {
			contactId: contact.id,
			contactType: contact.type,
			message
		} );
	}

	sendTextMessageAsync( contact, text, replyTo ) {
		let message = { type: "text", text, replyTo };
		if ( !this._sendingMessages[ contact.id ] ) {
			this._sendingMessages[ contact.id ] = new Rx.BehaviorSubject( [] );
		}
		let startAt = +new Date;
		return (
			Rx.Observable.fromPromise( ssgCrypto.createRandomBase64StringThen( 32 ) )
				.tap( id => {
					message.id = id;
					let sending = this._sendingMessages[ contact.id ].getValue();
					sending.push( message );
					this._sendingMessages[ contact.id ].onNext( sending );
				} )
				.flatMap( id =>
					this._callClientAsync( "sendMessage", {
						contactId: contact.id,
						contactType: contact.type,
						message
					} )
				)
				.tap( () => {
					message.isSent = true;
					this._sendingMessages[ contact.id ].onNext( this._sendingMessages[ contact.id ].getValue() );
				} )
		);
	}

	// sendFileMessageAsync( contact, fileToken, fileSize, fileType, fileName, messageId, thumbnailBase64 ) {
	// 	return this._callClientAsync( "sendMessage", {
	// 		contactId: contact.id,
	// 		contactType: contact.type,
	// 		message: {
	// 			type: "file",
	// 			contentType: fileType,
	// 			size: fileSize,
	// 			name: fileName,
	// 			fileToken,
	// 			id: messageId,
	// 			thumbnailBase64
	// 		}
	// 	} );
	// }

	sendFileMessageAsync( contact, fileToken, fileSize, fileType, fileName, messageId, thumbnailBase64, messageProperties ) {
		let message = {
			type: "file",
			contentType: fileType,
			size: fileSize,
			name: fileName,
			fileToken,
			id: messageId,
			thumbnailBase64,
			...( messageProperties || {} )
		};

		return this._callClientAsync( "sendMessage", {
			contactId: contact.id,
			contactType: contact.type,
			message
		} );
	}

	sendContactsAsync( contact, contactIdsToSend ) {
		return this._callClientAsync( "sendContacts", {
			contactId: contact.id,
			contactIdsToSend
		} );
	}

	observeMessages( contact ) {
		let resObservable;
		switch( contact.type ) {
			case "normal":
				resObservable = this._callRemoteObservable( "observeContactMessages", {
					contactId: contact.id
				} );
				break;
			case "group":
				resObservable = this._callRemoteObservable( "observeGroupMessages", {
					contactId: contact.id
				} );
				break;
			default:
				throw new Error( "ContactType required" );
		}
		if ( !this._sendingFiles[ contact.id ] ) {
			this._sendingFiles[ contact.id ] = new Rx.BehaviorSubject( Object.create( null ) );
		}

		if ( !this._sendingMessages[ contact.id ] ) {
			this._sendingMessages[ contact.id ] = new Rx.BehaviorSubject( [] );
		}

		return Rx.Observable.combineLatest(
			resObservable,
			this._sendingFiles[ contact.id ],
			this._sendingMessages[ contact.id ].observeOn( Rx.Scheduler.default ),
			( diff, sendingFiles, sendingMessagesLocal ) => {
				let doUpdateFiles = false;
				let doUpdateLocal = false;
				for ( let id in sendingFiles ) {
					if ( _.find( diff, { id } ) ) {
						delete sendingFiles[ id ];
						doUpdateFiles = true;
					}
				}

				let i = 0;
				while ( i < sendingMessagesLocal.length ) {
					let {id} = sendingMessagesLocal[ i ];
					if ( _.find( diff, { id } ) ) {
						sendingMessagesLocal.splice( i, 1 );
						doUpdateLocal = true;
					} else {
						i++;
					}
				}
				if ( doUpdateLocal ) {
					this._sendingMessages[ contact.id ].onNext( sendingMessagesLocal );
				}

				if ( doUpdateFiles ) {
					this._sendingFiles[ contact.id ].onNext( sendingFiles );
				}

				return { diff, sending: sendingMessagesLocal, sendingFiles };
			}
		);
	}

	createGroupAsync( name, nickname, contactIds ) {
		return this._callClientAsync( "createGroup", {
			name, nickname, contactIds
		} );
	}

	observeGroupParticipants( contact ) {
		return this._callRemoteObservable( "observeGroupParticipants", {
			contactId: contact.id
		} );
	}

	observeGroupInvites( contact ) {
		return this._callRemoteObservable( "observeGroupInvites", {
			contactId: contact.id
		} );
	}

	renameGroupParticipantAsync( contact, pid, newNickname ) {
		return this._callClientAsync( "renameGroupParticipant", {
			contactId: contact.id,
			pid, newNickname
		} );
	}

	deleteGroupParticipantAsync( contact, pid ) {
		return this._callClientAsync( "deleteGroupParticipant", {
			contactId: contact.id,
			pid
		} );
	}

	deleteGroupInviteAsync( contact, pid ) {
		return this._callClientAsync( "deleteGroupInvite", {
			contactId: contact.id,
			pid
		} );
	}

	addGroupParticipantsAsync( group, contactIds ) {
		return this._callClientAsync( "addGroupParticipants", {
			contactId: group.id,
			contactIds
		} );
	}

	addGroupParticipantExternalAsync( group, nickname ) {
		return this._callClientAsync( "addGroupParticipantExternal", {
			contactId: group.id,
			nickname
		} );
	}

	exitGroupAsync( contact ) {
		return this._callClientAsync( "exitGroup", {
			id: contact.id
		} );
	}

	observeProfile( ) {
		return this._callRemoteObservable( "observeProfile", {} );
	}

	exitMultidescriptionAsync( contact ) {
		return this._callClientAsync( "exitMultidescription", {
			id: contact.id
		} );
	}

	updateProfileAsync( profileChanges ) {
		return this._callClientAsync( "updateProfile", {
			profileChanges
		} );
	}

	observeChats( ) {
		let currentContacts = Object.create( null );
		return (
			this._callRemoteObservable( "observeChats", {} )
				.map( contacts => _.toArray( contacts ) )
				.map( diff => this._uncompactContacts( currentContacts, diff ) )
		);
	}

	createFakeAccountAsync( name, password ) {
		return this._callClientAsync( "createFakeAccount", {
			name, password
		} );
	}

	changePasswordAsync( password ) {
		return this._callClientAsync( "changePassword", {
			password
		} );
	}

	clearHistoryAsync( contact ) {
		let contactId = contact && contact.id;
		let contactType = contact && contact.type;
		return this._callClientAsync( "clearHistory", {
			contactId, contactType
		} );
	}

	clearRemoteHistoryAsync( contact ) {
		let contactId = contact && contact.id;
		let contactType = contact && contact.type;
		return this._callClientAsync( "clearRemoteHistory", {
			contactId, contactType
		} );
	}

	setReadAllAsync( contact ) {
		return this._callClientAsync( "setReadAll", {
			contactType: contact.type,
			contactId: contact.id
		} );
	}

	queryHistoryAsync( contact ) {
		return this._callClientAsync( "queryHistory", {
			contactType: contact.type,
			contactId: contact.id
		} );
	}

	observeSharedContacts( ) {
		let currentContacts = Object.create( null );
		return (
			this._callRemoteObservable( "observeSharedContacts", {} )
				.map( contacts => _.toArray( contacts ) )
				.tap( contacts => {
					this._uncompactSharedContacts( currentContacts, contacts )
				} )
		);
	}

	_uncompactSharedContacts( currentContacts, contacts ) {
		let mIdsSet = Object.create( null );
		contacts.forEach( contact => {
			mIdsSet[ contact.id ] = 1;
			contact.type = "multidescription";
			contact.sharedList = this._uncompactContacts(
				currentContacts[ contact.id ] = currentContacts[ contact.id ] || Object.create( null ),
				_.toArray( contact.sharedList ),
				"normal"
			);
			contact.sharedList.forEach( contact => {
				contact.type = "normal";
			} );
		} );

		_.forEach( currentContacts, ( m, mId ) => {
			if ( !mIdsSet[ mId ] ) {
				delete currentContacts[ mId ];
			}
		} );
		return contacts;
	}

	addHelperContactAsync( name ) {
		return (
			this._callClientAsync( "addHelperContact", {name} )
		);
	}

	_createMessageFileWithProgressAsync( file, nameOverride ) {
		let sender;
		let onError = error => {
			sender.getProgress().onError( error );
		};
		let chunkSize = 16;
		let message = new MessageModel();
		let readSubj = new Rx.Subject();
		let total = file.size;
		let attachment = new Attachment(
			() => BinaryStream.createMonitoredStream(
				BinaryStream.fromFile( file, onError ),
				buffer => { readSubj.onNext( buffer.length ); }
			),
			file.name || nameOverride,
			file.type || "application/octet-stream"
		);
		message.addAttachment( attachment );
		let fakeMessage = new MessageModel();
		sender = new MessageSender( {
			mainBinaryStream: new MessageSerializer( message, chunkSize ).createBinaryStream(),
			mainPassword: "",
			fakeBinaryStream: new MessageSerializer( fakeMessage, chunkSize ).createBinaryStream(),
			fakePassword: "fake",
			isSingleRead: false,
			expirationTS: 1000 * 60 * 60 * 24 * 365 + +new Date(),
			algorithm: "aes-256-cbc"
		} );
		let completition = (
			Rx.Observable.fromPromise( sender.completeThen() )
				.pluck( "addresses" )
				.map( addresses => _.find( addresses, "name", "token" ) )
				.pluck( "value" )
		);
		return (
			readSubj.scan( ( sum, len ) => sum + len, 0 ).map( sent => ( { sent, total } ) )
				.takeUntil( completition )
				.concat( completition )
		);
	}

	// sendFileAsync( contact, file ) {
	// 	if ( file.size > MAX_FILE_SIZE_MB * 1024 * 1024 ) {
	// 		return Rx.Observable.throw( new Error( `You can send file of size up to ${MAX_FILE_SIZE_MB} mb.` ) );
	// 	}
	// 	if ( !this._sendingFiles[ contact.id ] ) {
	// 		this._sendingFiles[ contact.id ] = new Rx.BehaviorSubject( Object.create( null ) );
	// 	}
	// 	let thumbnailBase64Then = null;
	// 	if ( file.type.split( "/" )[ 0 ] === "image" ) {
	// 		thumbnailBase64Then = (
	// 			makeThumbnailThen( file, 320, 320 )
	// 				.catch( () => undefined )
	// 		);
	// 	} else {
	// 		thumbnailBase64Then = Promise.resolve();
	// 	}
	//
	// 	let progress = this._createMessageFileWithProgressAsync( file );
	// 	let messageId;
	// 	let fileInfo;
	// 	return (
	// 		Rx.Observable.fromPromise( Promise.all( [
	// 			ssgCrypto.createRandomBase64StringThen( 32 ),
	// 		 	thumbnailBase64Then
	// 		] ) )
	// 		.flatMap( ( [ id, thumbnailBase64 ] ) => {
	// 			messageId = id;
	// 			fileInfo = {
	// 				name: file.name,
	// 				contentType: file.type,
	// 				thumbnailBase64,
	// 				progress
	// 			};
	// 			let files = this._sendingFiles[ contact.id ].getValue();
	// 			files[ messageId ] = fileInfo;
	// 			this._sendingFiles[ contact.id ].onNext( files );
	// 			return progress.last();
	// 		} )
	// 		.concatMap( fileToken =>
	// 			this.sendFileMessageAsync( contact, fileToken, file.size,
	// 				file.type, file.name, messageId, fileInfo.thumbnailBase64 )
	// 		)
	// 		.tap( () => {
	// 			let files = this._sendingFiles[ contact.id ].getValue();
	// 			delete files[ messageId ];
	// 			this._sendingFiles[ contact.id ].onNext( files );
	// 		} )
	// 	);
	// }

	sendVoiceMessageAsync( contact, ui8Array, milliseconds, mimeType, messageId ) {
		return this.sendFileAsync(
			contact,
			new Blob( [ ui8Array ], { type: mimeType } ),
			{ milliseconds, messageId, name: "message.ogg" }
		);
	}

	sendFileAsync( contact, file, messageProperties ) {
		let type = file.type || "application/octet-stream";
		if ( !messageProperties ) {
			messageProperties = {};
		}
		if ( file.size > MAX_FILE_SIZE_MB * 1024 * 1024 ) {
			return Rx.Observable.throw( new Error( `You can send file of size up to ${MAX_FILE_SIZE_MB} mb.` ) );
		}
		if ( !this._sendingFiles[ contact.id ] ) {
			this._sendingFiles[ contact.id ] = new Rx.BehaviorSubject( Object.create( null ) );
		}
		let thumbnailBase64Then = null;
		if ( type.split( "/" )[ 0 ] === "image" ) {
			thumbnailBase64Then = (
				makeThumbnailThen( file, 320, 320 )
					.catch( () => undefined )
			);
		} else {
			thumbnailBase64Then = Promise.resolve();
		}

		let messageId;
		let fileInfo;
		return (
			Rx.Observable.fromPromise( Promise.all( [
				ssgCrypto.createRandomBase64StringThen( 32 ),
			 	thumbnailBase64Then
			] ) )
			.flatMap( ( [ id, thumbnailBase64 ] ) => {
				messageId = messageProperties.messageId || id;
				fileInfo = {
					name: file.name || messageProperties.name,
					contentType: type,
					thumbnailBase64,
					sendingFile: file,
					total: file.size,
					...messageProperties
				};
				let files = this._sendingFiles[ contact.id ].getValue();
				files[ messageId ] = fileInfo;
				this._sendingFiles[ contact.id ].onNext( files );

				let resultSubj = this._createMessageFileWithProgressAsync(
					file, messageProperties.name
				);
				resultSubj.filter( res => typeof res !== "string" )
					.subscribe( ( { sent, total } ) => {
						fileInfo.sent = sent;
						this._sendingFiles[ contact.id ].onNext( files );
					}, error => {
						fileInfo.error = error;
						this._sendingFiles[ contact.id ].onNext( files );
					} );
				return resultSubj.filter( res => typeof res === "string" );
			} )
			.concatMap( fileToken =>
				this.sendFileMessageAsync( contact, fileToken, file.size,
				  type, file.name, messageId, fileInfo.thumbnailBase64, messageProperties )
			)
			.tap( () => {
				let files = this._sendingFiles[ contact.id ].getValue();
				delete files[ messageId ];
				this._sendingFiles[ contact.id ].onNext( files );
			} )
		);
	}

	addSharedContactListAsync( nickname, name, workgroupIds, contactIds, rights ) {
		return this._callClientAsync( "addSharedContactList", {
			nickname, name, workgroupIds, contactIds, rights
		} );
	}

	observeSelfRights( { id } ) {
		return this._callRemoteObservable( "observeSelfRights", {
			contactId: id
		} );
	}

	observeWorkgroupParticipants( {id} ) {
		return this._callRemoteObservable( "observeWorkgroupParticipants", {
			contactId: id
		} );
	}

	observeWorkgroupRights( {id} ) {
		return this._callRemoteObservable( "observeWorkgroupRights", {
			contactId: id
		} );
	}

	observeWorkgroupInvites( {id} ) {
		return this._callRemoteObservable( "observeWorkgroupInvites", {
			contactId: id
		} );
	}

	removeWorkgroupParticipantAsync( {id}, pid ) {
		return this._callClientAsync( "removeWorkgroupParticipant", {
			contactId: id, pid
		} );
	}

	removeWorkgroupInviteAsync( {id}, pid ) {
		return this._callClientAsync( "removeWorkgroupInvite", {
			contactId: id, pid
		} );
	}

	addToWorkGroupAsync( {id: multidescriptionId}, {id: contactId} ) {
		return this._callClientAsync( "addToWorkGroup", {
			multidescriptionId, contactId
		} );
	}

	setWorkgroupRightsAsync( {id: multidescriptionId }, modifiedRights ) {
		return this._callClientAsync( "setWorkgroupRights", {
			multidescriptionId, modifiedRights
		} );
	}

	shareContactsAsync( contactIds, multidescriptionId ) {
		return this._callClientAsync( "shareContacts", {
			contactIds, multidescriptionId
		} );
	}

	observeOnlineStatus( ) {
		return this._onlineStatusSubj;
	}

	_connected( ) {
		super._connected();
		this._onlineStatusSubj.onNext( true );
	}

	_disconnected( ) {
		super._disconnected();
		this._onlineStatusSubj.onNext( false );
	}

	addSessionAsync( sessionDataString ) {
		return this._callClientAsync( "addSession", {
			sessionDataString
		} );
	}

	deleteAccountAsync( ) {
		return this._callClientAsync( "deleteAccount", {} );
	}

	acceptInviteByInviteIdAsync( inviteId, {multidescriptionId} ) {
		return this._callClientAsync(
			"acceptInviteByInviteId",
			{inviteId, multidescriptionId}
		);
	}

	joinGroupByInviteIdAsync( inviteId ) {
		return this._callClientAsync(
			"joinGroupByInviteId",
			{inviteId}
		);
	}

	joinWorkgroupByInviteIdAsync( inviteId ) {
		return this._callClientAsync(
			"joinWorkgroupByInviteId",
			{inviteId}
		);
	}

	observeUserTable( ) {
		return this._callRemoteObservable( "observeUserTable", {} ).map( users => _.toArray( users ) );
	}

	observeGlobalUserType( ) {
		return this._callRemoteObservable( "observeGlobalUserType", {} );
	}

	addUserAsync( user ) {
		return this._callClientAsync( "addUser", {user} );
	}

	editUserAsync( user ) {
		return this._callClientAsync( "editUser", {user} );
	}

	deleteUserAsync( userId ) {
		return this._callClientAsync( "deleteUser", {userId} );
	}

	sendDeleteMessageAsync( messageIndex, contactId, contactType ) {
		return this._callClientAsync(
			"sendDeleteMessage",
			{ messageIndex, contactId, contactType }
		);
	}

	sendEditTextMessageAsync( messageIndex, newText, contactId, contactType ) {
		return this._callClientAsync(
			"sendEditTextMessage",
			{ messageIndex, newText, contactId, contactType }
		);
	}

	getPrivateServerConfigAsync( ) {
		return this._callClientAsync( "getPrivateServerConfig", {} );
	}

	muteContactNotificationAsync( contactId, contactType ) {
		return this._callClientAsync(
			"muteContactNotification",
			{ contactId, contactType }
		);
	}

	unmuteContactNotificationAsync( muteId ) {
		return this._callClientAsync(
			"unmuteContactNotification",
			{ muteId }
		);
	}

	observeCommonData() {
		let currentShared = Object.create( null );
		let currentContacts = Object.create( null );
		let currentGroups = Object.create( null );
		return this._callRemoteObservable(
			"observeCommonData",
			{}
		).map( ( { m, c, g, isAdmin, isPrivileged, expireDt, p, timeDiff, serverTimeStamp } ) => ( {
			m: this._uncompactSharedContacts( currentShared, _.toArray( m ) ),
			c: this._uncompactContacts( currentContacts, _.toArray( c ), "normal" ),
			g: this._uncompactContacts( currentGroups, _.toArray( g ), "group" ),
			isAdmin, isPrivileged, expireDt, p, timeDiff, serverTimeStamp
		} ) );
	}

	observeNewMessages() {
		return this._callRemoteObservable(
			"observeNewMessages",
			{}
		);
	}

	sendContactsToUserAsync( userId, userIds ) {
		return this._callClientAsync(
			"sendContactsToUser",
			{ userId, userIds }
		);
	}

	businessCardCreateAsync( name, description ) {
		return this._callClientAsync(
			"businessCardCreate",
			{ name, description }
		);
	}

	businessCardDeleteAsync( token ) {
		return this._callClientAsync(
			"businessCardDelete",
			{ token }
		);
	}

	businessCardUpdateAsync( token, name, description ) {
		return this._callClientAsync(
			"businessCardUpdate",
			{ token, name, description }
		);
	}

	observeBusinessCards() {
		return this._callRemoteObservable(
			"observeBusinessCards",
			{}
		);
	}
}

export default RemoteControlClient;
