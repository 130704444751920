import Rx from "rx";
import _ from "lodash";
import ssgCrypto from "ssg.crypto";

import ContactListRepository, {encryptedContactRecordSize} from "./contact.list.js";

const CONTACTS_PER_FILE = 128;

class ContactListSplit {
    constructor( StorageType ) {
        this._StorageType = StorageType;
        this._reposThen = null;
    }

    init( encryptionKey ) {
        this._encryptionKey = encryptionKey;
        this._encryptionKeyHandle = encryptionKey.addKeyUse( "ContactList split" );
    }

    uninitAsync( ) {
        return Rx.Observable.fromPromise( this.uninitThen() );
    }

    uninitThen( ) {
        return (
            this._getReposThen()
                .then( repos => Promise.all( repos.map( repo => repo.uninitThen() ) ) )
                .then( () => {
                    this._encryptionKey.removeKeyUse( this._encryptionKeyHandle );
                    delete this._encryptionKeyHandle;
                    delete this._encryptionKey;
                    this._reposThen = null;
                } )
        );
    }

    _getReposAsync() {
        return Rx.Observable.fromPromise( this._getReposThen() );
    }

    _getReposThen() {
        if ( !this._encryptionKey ) {
            throw new Error( "!this._encryptionKey in _getReposThen" );
        }
        if ( !this._reposThen ) {
            this._reposThen = new Promise( ( resolve, reject ) => {
                let subj = new Rx.ReplaySubject();
                subj.onNext( 0 );
                subj
                    .concatMap( fileNumber =>
                        ContactListRepository.isExistAsync( this._StorageType, fileNumber + 1 )
                            .flatMap( isExist => {
                                if ( isExist ) {
                                    subj.onNext( fileNumber + 1 );
                                    return Rx.Observable.empty();
                                }
                                subj.onCompleted();
                                return Rx.Observable.just( fileNumber );
                            } )
                    )
                    .concatMap( maxFileNumber => Rx.Observable.range( 0, maxFileNumber + 1 ) )
                    .concatMap( fileNumber =>
                        ContactListRepository.isExistAsync( this._StorageType, fileNumber )
                            .map( isExist => {
                                if ( !isExist ) {
                                    return null;
                                }
                                let repo = new ContactListRepository( this._StorageType, fileNumber );
                                this._initRepo( repo, fileNumber );
                                return repo;
                            } )
                    )
                    .toArray()
                    .flatMap( repos => this._normalizeAsync( repos ) )
                    .subscribe( resolve, reject );
            } );
        }
        return this._reposThen;
    }

    _getReposUninitedAsync() {
        let subj = new Rx.ReplaySubject();
        subj.onNext( 0 );
        return (
            subj
                .concatMap( fileNumber =>
                    ContactListRepository.isExistAsync( this._StorageType, fileNumber + 1 )
                        .flatMap( isExist => {
                            if ( isExist ) {
                                subj.onNext( fileNumber + 1 );
                                return Rx.Observable.empty();
                            }
                            subj.onCompleted();
                            return Rx.Observable.just( fileNumber );
                        } )
                )
                .concatMap( maxFileNumber => Rx.Observable.range( 0, maxFileNumber + 1 ) )
                .concatMap( fileNumber =>
                    ContactListRepository.isExistAsync( this._StorageType, fileNumber )
                        .map( isExist => {
                            if ( !isExist ) {
                                return null;
                            }
                            return new ContactListRepository( this._StorageType, fileNumber );
                        } )
                )
                .filter( repo => !!repo )
                .toArray()
        );
    }

    _normalizeAsync( repos ) {
        return (
            Rx.Observable.fromArray( repos )
                .concatMap( repo => repo
                    ? repo.getItemsCountAsync()
                    : Rx.Observable.just( null )
                )
                .toArray()
                .flatMap( counts => {
                    if ( counts.length > 0 ) {
                        let needRecreate = counts[ counts.length - 1 ] > CONTACTS_PER_FILE;
                        for ( let i = 0; i < counts.length - 1; i++ ) {
                            if ( counts[ i ] !== CONTACTS_PER_FILE ) {
                                needRecreate = true;
                            }
                        }
                        if ( _.some( repos, repo => !repo ) ) {
                            needRecreate = true;
                        }

                        if ( !needRecreate ) {
                            return Rx.Observable.just( repos );
                        }
                    }
                    return (
                        this._readAllConcatedAsync( repos )
                            .flatMap( list => this._storeAllAsync( repos, list ) )
                    );
                } )
        );
    }

    _readAllConcatedAsync( repos ) {
        return (
            Rx.Observable.fromArray( repos )
                .concatMap( repo => {
                    if ( !repo ) {
                        return Rx.Observable.just( [] );
                    }
                    return repo.getAllEncryptedAsync();
                } )
                .toArray()
                .map( array => _.flatten( array ) )
        );
    }

    //Return new repos array
    _storeAllAsync( repos, list ) {
        let chunks = _.chunk( list, CONTACTS_PER_FILE );

        while ( repos.length > chunks.length ) {
            chunks.push( null );
        }

        chunks = _.map(
            chunks,
            ( content, fileNumber ) => ( { content, fileNumber } )
        );
        return (
            Rx.Observable.fromArray( chunks )
                .concatMap( ( { content, fileNumber } ) => {
                    let repo = repos[ fileNumber ];
                    if ( !repo ) {
                        repo = new ContactListRepository( this._StorageType, fileNumber );
                        if ( this._encryptionKey ) {
                            this._initRepo( repo, fileNumber );
                        }
                    }
                    if ( !content ) {
                        return repo.dropDataAsync().flatMap( () => {
                            return Rx.Observable.empty()
                        } );
                    }
                    return repo.replaceDataAsync( Buffer.concat( content ) ).map ( () => {
                        return repo;
                    } );
                } )
                .toArray()
        );
    }

    hookWrite( func ) {
        if ( this._writeHook ) {
            throw new Error( "writeHook already set" );
        }
        this._writeHook = func;
    }

    _initRepo( repo, fileNumber ) {
        repo.init( this._encryptionKey );
        if ( this._writeHook ) {
            repo.hookWrite(
                ( buffer, index ) => {
                    if ( index === undefined ) {
                        return Promise.resolve();
                    }
                    return this._writeHook(
                        buffer,
                        index + fileNumber * CONTACTS_PER_FILE
                    );
                }
            );
        }
    }

    getRawDataAsync() {
        return (
            this._getReposAsync()
                .flatMap( repos => Rx.Observable.fromArray( repos ) )
                .concatMap( repo => repo.getRawDataAsync() )
                .toArray()
                .map( datas => Buffer.concat( datas ) )
        );
    }

    deleteThen( index ) {
        let fileNumber = Math.floor( index / CONTACTS_PER_FILE );
        let indexInFile = index - fileNumber * CONTACTS_PER_FILE;

        return (
            this._getReposThen()
                .then( repos => !repos[ fileNumber ]
                    ? false
                    : repos[ fileNumber ].deleteThen( indexInFile )
                )
        );
    }

    deleteAsync( index ) {
        let fileNumber = Math.floor( index / CONTACTS_PER_FILE );
        let indexInFile = index - fileNumber * CONTACTS_PER_FILE;

        return (
            this._getReposAsync()
                .flatMap( repos => !repos[ fileNumber ]
                    ? Rx.Observable.just()
                    : repos[ fileNumber ].deleteAsync( indexInFile )
                )
        );
    }

    isAnyDataAsync( ) {
        return (
            this._getReposAsync()
                .flatMap( repos => Rx.Observable.fromArray( repos ) )
                .concatMap( repo => repo.isAnyDataAsync() )
                .first( { predicate: _.identity, defaultValue: false } )
        );
    }

    getAllAsJsonAsync( isReverse ) {
        let reposCount;
        let lastChunk;
        return (
            this._getReposAsync()
                .flatMap( repos => {
                    reposCount = repos.length;
                    return Rx.Observable.fromArray( isReverse
                        ? _.clone( repos ).reverse()
                        : repos
                    );
                } )
                .concatMap( repo => Rx.Observable.defer( () =>
                    repo.getAllAsJsonAsync( isReverse )
                ) )
                .takeWhile( chunk => !( _.some( chunk, { invalid: 1 } ) && ( lastChunk = chunk ) ) )
                .toArray()
                .map( chunks => {
                    chunks.push( lastChunk );
                    let array = _.flatten( chunks );
                    while ( array.length < reposCount * CONTACTS_PER_FILE ) {
                        array.push( { invalid: 1 } );
                    }
                    return array;
                } )
        );
    }

    getAllEncryptedAsync( ) {
        return (
            this._getReposAsync()
                .flatMap( repos => Rx.Observable.fromArray( repos ) )
                .concatMap( repo => repo.getAllEncryptedAsync() )
                .toArray()
                .map( chunks => _.flatten( chunks ) )
        );
    }

    readAtAsync( index ) {
        let fileNumber = Math.floor( index / CONTACTS_PER_FILE );
        let indexInFile = index - fileNumber * CONTACTS_PER_FILE;
        return (
            this._getReposAsync()
                .flatMap( repos => !repos[ fileNumber ]
                    ? Rx.Observable.just( null )
                    : repos[ fileNumber ].readAtAsync( indexInFile )
                )
        );
    }

    writeAtAsync( index, json ) {
        return Rx.Observable.fromPromise( this.writeAtThen( index, json ) );
    }

    writeAtThen( index, json ) {
        let fileNumber = Math.floor( index / CONTACTS_PER_FILE );
        let indexInFile = index - fileNumber * CONTACTS_PER_FILE;
        return (
            this._getReposThen()
                .then( repos => !repos[ fileNumber ]
                    ? null
                    : repos[ fileNumber ].writeAtThen( indexInFile, json )
                )
        );
    }

    preallocateAsync( recordCount ) {
        this._reposThen = new Promise( ( resolve, reject ) => {
            this._getReposAsync()
                .flatMap( repos => Rx.Observable.fromArray( repos ) )
                .concatMap( repo => repo.dropDataAsync() )
                .toArray()
                .flatMap( () => Rx.Observable.range( 0, Math.ceil( recordCount / CONTACTS_PER_FILE ) ) )
                .concatMap( fileNumber => {
                    let repo = new ContactListRepository( this._StorageType, fileNumber );
                    let count = Math.min( CONTACTS_PER_FILE, recordCount - fileNumber * CONTACTS_PER_FILE );
                    this._initRepo( repo, fileNumber );

                    return repo.preallocateAsync( count ).map( () => repo );
                } )
                .toArray()
                .subscribe( resolve, reject );
        } );

        return (
            Rx.Observable.fromPromise( this._reposThen )
                .map( () => true )
        );
    }

    insertEmptyRecordsAsync( index, count ) {
        // return Rx.Observable.throw( new Error( "Not implemented" ) );
        return (
            this.getAllEncryptedAsync()
                .flatMap( allEncrypted => {
                    for ( let i = 0; i < count; i++ ) {
                        allEncrypted.splice( i, 0, null );
                    }
                    return allEncrypted;
                } )
                .flatMap( buf => buf
                    ? Rx.Observable.just( buf )
                    : Rx.Observable.fromPromise(
                        ssgCrypto.createRandomBufferThen( encryptedContactRecordSize )
                    )
                )
                .toArray()
                .flatMap( newContacts => this.setRawDataAsync( Buffer.concat( newContacts ) ) )
        );
    }

    getItemsCountAsync( ) {
        return (
            this._getReposUninitedAsync()
                .flatMap( repos => Rx.Observable.fromArray( repos ) )
                .concatMap( repo => repo.getItemsCountAsync() )
                .toArray()
                .map( counts => _.sum( counts ) )
        );
    }

    dropDataAsync() {
        return (
            this._getReposUninitedAsync()
                .flatMap( repos => Rx.Observable.fromArray( repos ) )
                .concatMap( repo => repo.dropDataAsync() )
                .toArray()
        );

    }

    setRawDataAsync( buffer ) {
        let list = [];
        for ( let i = 0; i < buffer.length; i += 256 ) {
            list.push( buffer.slice( i, i + 256 ) );
        }
        return (
            this._getReposUninitedAsync()
                .flatMap( repos => this._storeAllAsync( repos, list ) )
        );
    }

    static isExistAsync( StorageType ) {
        return (
            Rx.Observable.combineLatest(
                ContactListRepository.isExistAsync( StorageType, 0 ),
                ContactListRepository.isExistAsync( StorageType, 1 ),
                ( isExist0, isExist1 ) => isExist0 || isExist1
            )
        );
    }
}

export default ContactListSplit;
