import React from "react";
import Translation from "../../../components/translation.jsx";

class InviteAbsentContent extends React.Component {
	renderContent() {
		if ( this.props.contact.multidescriptionId === -1 ) {
			return <Translation textId="contact.status.invited.description"/>;
		}
		return <Translation textId="contact.shared.status.invited.description"/>;
	}

	render() {
		return (
			<div className={this.props.className}>
				<div className="invite">
					{ this.renderContent() }
				</div>
			</div>
		);
	}
}

export default InviteAbsentContent;
