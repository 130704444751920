import Rx from "rx";
import ReactDOM from "react-dom";
import _ from "lodash";
import {scrollIntoView} from "../../common/utils.js";

class FocusManager {
	constructor( ) {
		this.focused = null;
		this.keyboards = [];
		this.handleClick = this.handleClick.bind( this );
		this.useVirtualKeyboardSubj = new Rx.BehaviorSubject(
			false//process.env.OWN_KEYBOARD !== "off"
		);
	}

	registerKeyboard( keyboard ) {
		if ( this.activeKeyboard ) {
			this.activeKeyboard.hide();
		}
		this.keyboards.unshift( keyboard );
		this.useVirtualKeyboardSubj.onNext( true );

		if ( this.focused ) {
			this._showKeyboard();
		}
	}

	unregisterKeyboard( keyboard ) {
		this.keyboards = _.without( this.keyboards, keyboard );
		this.useVirtualKeyboardSubj.onNext( this.useVirtualKeyboardSubj.getValue() );
	}

	get activeKeyboard( ) {
		return this.keyboards[ 0 ];
	}

	get useVirtualKeyboard( ) {
		return this.useVirtualKeyboardSubj.getValue();
	}

	set useVirtualKeyboard( use ) {
		this.useVirtualKeyboardSubj.onNext( use );
	}

	observeUseVirtualKeyboard( ) {
		return this.useVirtualKeyboardSubj;
	}

	handleClick( ev ) {
		if ( !this.focused || ev.target.classList.contains('unfocusable')) {
			return;
		}
		if ( this.keyboards.length ) {
			let keyboardNode = ReactDOM.findDOMNode( this.activeKeyboard );
			if ( !keyboardNode || keyboardNode.contains( ev.target ) ) {
				return;
			}
		}
		let focusedNode = ReactDOM.findDOMNode( this.focused );
		if ( !focusedNode.contains( ev.target ) ) {
			this.removeFocus();
		}
	}

	getFocus( input ) {
		if ( this.focused === input ) {
			return;
		}
		if ( this.focused ) {
			this.removeFocus();
		}
		this.focused = input;
		this.focused.focus();
		this._showKeyboard();
		return true;
	}

	_showKeyboard() {
		if ( !this.activeKeyboard ) {
			return;
		}
		this.activeKeyboard.show( () => {
			//FIXME: setUpdate can cause race condition with handleFirstUppercased
			//TODO: handle updateLayout once
			if ( !this.focused ) {
				return;
			}
			if (this.focused.props.defaultLatinNoCaps ) {
				 this.activeKeyboard.updateLayout({language: 'en', mode: 'alpha'})
			} else {
				this.handleFirstUppercased(this.focused)
			}
			scrollIntoView( ReactDOM.findDOMNode( this.focused ) );
		} );
	}

	handleFirstUppercased(input, text=null) {
		if ( !this.activeKeyboard ) {
			return;
		}
		let caps = this.activeKeyboard.isCaps();
		if (! input.props.firstUppercased) {
			if (caps) this.activeKeyboard.toLowerCase();
		} else if (text === '' || ! input.props.value.length) {
			this.activeKeyboard.toUpperCase();
		}
	}

	removeFocus( ) {
		this.focused.blur();
		this.focused = null;
		if ( this.activeKeyboard ) {
			this.activeKeyboard.hide();
		}
	}

	unregisterInput( input ) {
		if ( input === this.focused ) {
			this.focused = null;
		}
	}

	handleKey( key ) {
		if ( this.focused ) {
			if ( key === "OK" ) {
				this.removeFocus();
			} else {
				this.focused.executeCommand( key );
			}
		}
	}

	handleKeyPreview( key ) {
		if ( this.focused ) {
			this.focused.setPreviewChar( key );
		}
	}
}

export default FocusManager;
