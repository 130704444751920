import Rx from "rx";
import Queue from "promise-queue";

class BaseRepository {
	constructor( StorageType, fileName ) {
		this._StorageType = StorageType;
		this._storage = new StorageType( fileName );
		this._queue = new Queue( 1 );
	}

	dropDataAsync( ) {
		return this.lockStorageAsync( storage => storage.dropDataAsync() );
	}

	isAnyDataAsync( ) {
		return this.lockStorageAsync( storage => storage.readAtPositionAsync( 0, 1 ).map( data => !!data.length ) );
	}

	lockStorageAsync( callback ) {
		return Rx.Observable.fromPromise( this.lockStorageThen( callback ) );
	}

	lockStorageThen( callback ) {
		// return this._queue.add( () => {
			let res = callback( this._storage );
			if ( res && res.subscribe ) {
				return new Promise( ( resolve, reject ) => {
					res.subscribe( resolve, reject );
				} );
			}
			return res;
		// } );
	}

	getStorage( ) {
		return this._storage;
	}
}

export default BaseRepository;
