import DefaultStorage from "../storage/default.storage.js";
import Repository from "../seed.js";

let instance = null;
let mockedInstance = null;

export default function() {
	if ( mockedInstance ) {
		return mockedInstance;
	}
	if ( !instance ) {
		instance = new Repository( DefaultStorage );
	}
	return instance;
};

export function mock( newMockedInstance ) { mockedInstance = newMockedInstance; };

export function unmock() { mockedInstance = null; };
