"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

exports.default = function () {
	if (mockedInstance) {
		return mockedInstance;
	}
	if (!instance) {
		var random = new _randomGenerator2.default();
		random.addSource(_accelerometerSource2.default);
		random.addSource(_keyboardSource2.default);
		random.addSource(_mouseSource2.default);
		random.addSource(_performanceSource2.default);
		random.addSource(_timerSource2.default);
		random.addSource(_touchSource2.default);

		instance = new _prng2.default(random);
	}
	return instance;
};

exports.mock = mock;
exports.unmock = unmock;

var _prng = require("../prng.js");

var _prng2 = _interopRequireDefault(_prng);

var _randomGenerator = require("../random.generator.js");

var _randomGenerator2 = _interopRequireDefault(_randomGenerator);

var _accelerometerSource = require("../sources/accelerometer.source.js");

var _accelerometerSource2 = _interopRequireDefault(_accelerometerSource);

var _keyboardSource = require("../sources/keyboard.source.js");

var _keyboardSource2 = _interopRequireDefault(_keyboardSource);

var _mouseSource = require("../sources/mouse.source.js");

var _mouseSource2 = _interopRequireDefault(_mouseSource);

var _performanceSource = require("../sources/performance.source.js");

var _performanceSource2 = _interopRequireDefault(_performanceSource);

var _timerSource = require("../sources/timer.source.js");

var _timerSource2 = _interopRequireDefault(_timerSource);

var _touchSource = require("../sources/touch.source.js");

var _touchSource2 = _interopRequireDefault(_touchSource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = null;
var mockedInstance = null;

;

function mock(newMockedInstance) {
	mockedInstance = newMockedInstance;
};

function unmock() {
	mockedInstance = null;
};