import _ from "lodash";
import Rx from "rx";
import ssgCrypto from "ssg.crypto";

import ServerConnection from "./server.connection.js";
import configuration from "../../common/configuration.js";
import diagnosticsServiceLocator from "../services/locators/diagnostics.js";

let apiUrlBase2ConnectionInfo = Object.create( null );
let skipRegistrationId = undefined;

export function setRegistrationIdToSkip( registrationId ) {
	skipRegistrationId = registrationId;
}

function onConnected( url ) {
	diagnosticsServiceLocator().reportServerStatus( url, true );
	let info = apiUrlBase2ConnectionInfo[ url ];
	if ( !info ) {
		return;
	}
	for ( let listenerId in info.listenerId2Object ) {
		let obj = info.listenerId2Object[ listenerId ];
		try {
			obj._onConnected && obj._onConnected();
		}catch(e) {
			console.error( e );
		}
	}
}

function onDisconnected( url ) {
	diagnosticsServiceLocator().reportServerStatus( url, false );
	let info = apiUrlBase2ConnectionInfo[ url ];
	if ( !info ) {
		return;
	}
	for ( let listenerId in info.listenerId2Object ) {
		let obj = info.listenerId2Object[ listenerId ];
		obj._onDisconnected && obj._onDisconnected();
	}
}

function initServerConnection( url ) {
	if ( !apiUrlBase2ConnectionInfo[ url ] ) {
		let serverConnection = new ServerConnection( url, () => onConnected( url ), () => onDisconnected( url ) );

		let info = apiUrlBase2ConnectionInfo[ url ] = {
			connection: serverConnection,
			callId2Cb: Object.create( null ),
			listenerId2Object: Object.create( null )
		};

		serverConnection.on( "resp", result => {
			let cb = info.callId2Cb[ result.callId ];
			if ( !cb ) {
				return;
			}
			delete info.callId2Cb[ result.callId ];
			console.log("resp", result, new Date);
			cb( result );
		} );
		//TODO: better
		let autorespond = ( { callId } ) => {
			serverConnection.sendMessage( "resp", { callId, status: "ok" } );
		};
		serverConnection.on( "onlineMessage", autorespond );
		serverConnection.on( "paired", autorespond );
		serverConnection.on( "unpaired", autorespond );
		serverConnection.on( "gotMessage", autorespond );
		serverConnection.on( "gotOneWayMessage", autorespond );
		serverConnection.on( "triggered", autorespond );
		serverConnection.on( "userData", autorespond );
		serverConnection.on( "userIsPrivileged", autorespond );
		serverConnection.on( "user", autorespond );
	}
	return apiUrlBase2ConnectionInfo[ url ];
}

class ClientServerBase {
	constructor( apiUrlBase, connectionId ) {
		if ( typeof apiUrlBase !== "string" ) {
			throw new Error( "apiUrlBase must be a string" );
		}

		if ( typeof connectionId !== "string" ) {
			throw new Error( "connectionId must be a string" );
		}

		this._connectionInfo = initServerConnection( apiUrlBase );
		this._usageSubject = new Rx.BehaviorSubject( [] );

		this._connectionId = connectionId;
		this._apiUrlBase = apiUrlBase;
		this._isInitialized = false;
		this._isDisposed = false;
		this._initThen = new Promise( resolve => {
			this._initCb = resolve;
		} );
		this._initializeBegin();
	}

	_on( name, handler ) {
		this._connectionInfo.connection.on( name, handler );
	}

	_off( name, handler ) {
		this._connectionInfo.connection.off( name, handler );
	}

	_initializeBegin( ) {
		ssgCrypto.createRandomBase64StringThen( 32 )
			.then( listenerId => {
				listenerId = this._connectionId + "_" + listenerId;
				if ( this._isDisposed ) {
					this._initCb( false );
					return;
				}
				this._listenerId = listenerId;
				this._connectionInfo.listenerId2Object[ listenerId ] = this;
				this._isInitialized = true;
				this._initCb( true );
			} );
	}

	onConnected( func ) {
		if ( this._onConnected ) {
			throw new Error( "onConnected double call" );
		}
		this._onConnected = func;
	}

	onDisconnected( func ) {
		if ( this._onDisconnected ) {
			throw new Error( "onDisconnected double call" );
		}
		this._onDisconnected = func;
	}

	_tryMakeCall( name, params, cb ) {
		if ( this._isDisposed ) {
			throw new Error( "Disposed" );
		}
		if ( typeof cb !== "function" ) {
			throw new Error( "cb function required" );
		}
		console.log("_tryMakeCall", name, params, new Date);
		Promise.all( [
			ssgCrypto.createRandomBase64StringThen( 32 ),
			this._initThen
		] )
			.then( ( [ callId ] ) => {
				if ( this._isDisposed ) {
					console.warn( `Disposed while calling ${name}` );
					return;
				}
				if ( ( "listenerId" in params ) && ( !params.listenerId ) ) {
					params.listenerId = this._listenerId;
				}
				this._connectionInfo.callId2Cb[ callId ] = cb;
				setTimeout( () => {
					if ( !this._connectionInfo || !this._connectionInfo.callId2Cb[ callId ] ) {
						return;
					}
					delete this._connectionInfo.callId2Cb[ callId ];
					cb( { status: "timeout" } );
				}, configuration.getNetworkTimeout() );
				this._connectionInfo.connection.sendMessage(
					name,
					_.assign( { callId }, params )
				);
			} );
	}

	_initTransactionIfNeed( transaction ) {
		if ( this._isDisposed ) {
			throw new Error( "Disposed" );
		}
		if ( transaction.isInitialized() ) {
			return;
		}
		transaction.initialize( this._apiUrlBase, isCanceled => {
			if ( isCanceled || this._isDisposed ) {
				transaction.setCompleted( { status: "canceled" } );
				return;
			}
			let operations = transaction.getOperations();

			this.tryTransact(
				transaction.getTransactionId(),
				operations,
				result => transaction.setCompleted( result )
			);
		} );
	}

	tryTransact( transactionId, operations, cb ) {
		if ( this._isDisposed ) {
			throw new Error( "Disposed" );
		}
		let startAt = +new Date;
		console.log( "tryTransact", new Date );
		this._tryMakeCall( "write", {
			transactionId,
			operations,
			skipRegistrationId
		}, res => {
			console.log( "tryTransact in", +new Date - startAt, "ms" );
			cb( res );
		} );
	}

	_callUntilSuccessThen( func ) {
		return new Promise( resolve => {
			let recursive = () => {
				func( resp => {
					if ( resp && resp.status === "timeout" ) {
						setTimeout( recursive, configuration.getTransactionRetryDelay() );
						return;
					}
					resolve( resp );
				} );
			};
			recursive();
		} );
	}

	_callUntilSuccessAsync( func ) {
		return Rx.Observable.create( observer => {
			let isDisposed = false;
			let recursive = () => {
				if ( this._isDisposed || isDisposed ) {
					observer.onCompleted();
					return;
				}
				func( resp => {
					if ( resp.status === "timeout" ) {
						setTimeout( recursive, configuration.getTransactionRetryDelay() );
						return;
					}
					observer.onNext( resp );
					observer.onCompleted();
				} );
			};
			recursive();
			return () => isDisposed = true;
		} );
	}

	incUse( ) {
		let handle = Object.create( null );
		let usage = this._usageSubject.getValue();
		usage.push( handle );
		this._usageSubject.onNext( usage );
		return handle;
	}

	decUse( useHandle ) {
		let usage = this._usageSubject.getValue();
		let index = usage.indexOf( useHandle );
		if ( !~index ) {
			throw new Error( "Invalid connection usage handle" );
		}
		usage.splice( index, 1 );
		this._usageSubject.onNext( usage );
		return useHandle;
	}

	dispose( ) {
		this._usageSubject
			.filter( ( { length } ) => !length )
			.first()
			.subscribe( () => {
				if ( this._isDisposed ) {
					throw new Error( "Already disposed" );
				}
				this._isDisposed = true;
				if ( this._isInitialized ) {
					delete this._connectionInfo.listenerId2Object[ this._listenerId ];
				}
				if ( _.isEmpty( this._connectionInfo.listenerId2Object ) ) {
					//TODO: disconnect
					delete apiUrlBase2ConnectionInfo[ this._apiUrlBase ];
				}
				delete this._connectionInfo;
				this._isInitialized = false;
			} );
	}
}

export default ClientServerBase;
