import React from "react";
import classNames from "classnames";
import _ from "lodash";

import Translation from "../../../components/translation.jsx";
import Switch from "../common/switch.jsx";
import Tinput from "../common/tinput.jsx";
import PromptContacts from "../common/prompt.contacts.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class AddSharedContactListContent extends React.Component {
	constructor() {
		super();
		this._service = remoteServiceLocator();
		this.onChangeName = this.onChangeName.bind( this );
		this.onSetNameDone = this.onSetNameDone.bind( this );
		this.onNameBack = this.onNameBack.bind( this );

		this.onChangeNickname = this.onChangeNickname.bind( this );
		this.onSetNicknameDone = this.onSetNicknameDone.bind( this );
		this.onNicknameBack = this.onNicknameBack.bind( this );

		this.onWorkgroupChange = this.onWorkgroupChange.bind( this );
		this.onSetWorkgroupDone = this.onSetWorkgroupDone.bind( this );
		this.onWorkgroupBack = this.onWorkgroupBack.bind( this );

		this.onContactsChange = this.onContactsChange.bind( this );
		this.onSetContactsDone = this.onSetContactsDone.bind( this );
		this.onContactsBack = this.onContactsBack.bind( this );

		this.onRightsChange = this.onRightsChange.bind( this );
		this.onSetRightsDone = this.onSetRightsDone.bind( this );
		this.onRightsBack = this.onRightsBack.bind( this );

		this.state = {
			step: "loading",
			name: "",
			nickname: "",
			selectedWorkgroupIds: [],
			selectedContactIds: [],
			rights: Object.create( null )
		};
	}

	onChangeNickname( newNickname ) {
		this.setState( {nickname: newNickname} );
	}

	onSetNicknameDone() {
		if ( this.state.nickname ) {
			this.setState( {step: "name"} );
		}
	}

	onNicknameBack() {
		this.props.onBack();
	}

	onChangeName( newName ) {
		this.setState( {name: newName} );
	}

	onSetNameDone() {
		if ( this.state.name ) {
			this.setState( {step: "workgroup"} );
		}
	}

	onNameBack() {
		this.setState( {step: "nickname"} );
	}

	onWorkgroupChange( newIds ) {
		this.setState( {
			selectedWorkgroupIds: newIds
		} );
	}

	onSetWorkgroupDone() {
		if ( this.state.selectedWorkgroupIds.length ) {
			this.setState( {step: "rights"} );
		}
	}

	onWorkgroupBack() {
		this.setState( {step: "name"} );
	}

	onRightsChange( newRights ) {
		this.setState( {
			selectedRights: newRights
		} );
	}

	onSetRightsDone() {
		this.setState( {
			step: "contacts"
		} );
	}

	onRightsBack() {
		this.setState( {
			step: "workgroup"
		} );
	}

	onContactsChange( newIds ) {
		this.setState( {
			selectedContactIds: newIds
		} );
	}

	onSetContactsDone() {
		if ( this.state.selectedContactIds.length ) {
			this.setState( {step: "creating"} );
			this._service.addSharedContactListAsync(
				this.state.nickname,
				this.state.name,
				this.state.selectedWorkgroupIds,
				this.state.selectedContactIds,
				this.state.rights
			).subscribe( contactId => {
				this.props.onDone( contactId );
			} )
		}
	}

	onContactsBack() {
		this.setState( { step: "rights" } );
	}

	componentWillMount() {
		this._service.observeProfile()
			.take( 1 )
			.subscribe( ({nickname}) => {
				if ( nickname ) {
					this.setState( {
						nickname,
						step: "name"
					} );
				} else {
					this.setState( {
						step: "nickname"
					} );
				}
			} );
	}

	render() {
		switch( this.state.step ) {
			case "loading":
				return null;
			case "nickname":
				return <AddSharedContactListContentStepNickame
					nickname={this.state.nickname}
					className={this.props.className}
					onChange={this.onChangeNickname}
					onDone={this.onSetNicknameDone}
					onBack={this.onNicknameBack}
					/>;
			case "name":
				return <AddSharedContactListContentStepName
					name={this.state.name}
					className={this.props.className}
					onChange={this.onChangeName}
					onDone={this.onSetNameDone}
					onBack={this.onNameBack}
					/>;
			case "workgroup":
				return <AddSharedContactListContentStepWorkgroup
					selectedContactIds={this.state.selectedWorkgroupIds}
					className={this.props.className}
					onChange={this.onWorkgroupChange}
					onDone={this.onSetWorkgroupDone}
					onBack={this.onWorkgroupBack}
					/>;
			case "rights":
				return <AddSharedContactListContentStepRights
					workgroupIds={this.state.selectedWorkgroupIds}
					rights={this.state.rights}
					className={this.props.className}
					onChange={this.onRightsChange}
					onDone={this.onSetRightsDone}
					onBack={this.onRightsBack}
					/>;
			case "contacts":
				return <AddSharedContactListContentStepContacts
					selectedContactIds={this.state.selectedContactIds}
					workgroupIds={this.state.selectedWorkgroupIds}
					name={this.state.name}
					className={this.props.className}
					onChange={this.onContactsChange}
					onDone={this.onSetContactsDone}
					onBack={this.onContactsBack}
					/>;
			case "creating":
				return <span className={this.props.className}>
					<Translation textId="web.sharedcontacts.create.wait.text" />
				</span>;
		}
	}

	static get propTypes() {
		return {
			onDone: React.PropTypes.func.isRequired,
			onBack: React.PropTypes.func.isRequired,
		};
	}
}

class AddSharedContactListContentStepNickame extends React.Component {
	constructor() {
		super();
		this.onNextClick = this.onNextClick.bind( this );
	}

	onNextClick() {
		if ( !this.props.nickname ) {
			return;
		}
		this.props.onDone();
	}

	render() {
		let nextClassName = classNames( "btn btn-green", {
			disabled: !this.props.nickname.trim()
		} );
		return (
			<div className={this.props.className}>
				<button
					className="btn btn-green"
					onClick={this.props.onBack}>
					<Translation textId="web.sharedcontacts.create.nickname.prev" />
				</button>
				<div style={{padding: "20px"}}>
					<Translation textId="web.sharedcontacts.create.nickname.text" />
					<br />
					<Tinput
						placeholderTextId="web.sharedcontacts.create.nickname.placeholder"
						value={this.props.nickname}
						onChange={this.props.onChange}
						style={{maxWidth: "100%"}}
						/>
				</div>
				<button
					className={nextClassName}
					onClick={this.onNextClick}>
					<Translation textId="web.sharedcontacts.create.nickname.next" />
				</button>
			</div>
		);
	}
}

class AddSharedContactListContentStepName extends React.Component {
	constructor() {
		super();
		this.onNextClick = this.onNextClick.bind( this );
	}

	onChange( event ) {
		this.props.onChange( event.target.value );
	}

	onNextClick() {
		if ( !this.props.name ) {
			return;
		}
		this.props.onDone();
	}

	render() {
		let nextClassName = classNames( "btn btn-green", {
			disabled: !this.props.name.trim()
		} );
		return (
			<div className={this.props.className}>
				<button
					className="btn btn-green"
					onClick={this.props.onBack}>
					<Translation textId="web.sharedcontacts.create.name.prev" />
				</button>
				<div style={{padding: "20px"}}>
					<Translation textId="web.sharedcontacts.create.name.text" />
					<br />
					<Tinput
						placeholderTextId="web.sharedcontacts.create.name.placeholder"
						value={this.props.name}
						onChange={this.props.onChange}
						style={{maxWidth: "100%"}}
					/>
				</div>
				<button
					className={nextClassName}
					onClick={this.onNextClick}>
					<Translation textId="web.sharedcontacts.create.name.next" />
				</button>
			</div>
		);
	}
}

class AddSharedContactListContentStepWorkgroup extends React.Component {
	constructor() {
		super();
		this.onNextClick = this.onNextClick.bind( this );
		this._service = remoteServiceLocator();
	}

	componentWillMount() {
		this._subscription = (
			this._service.observeContactList()
				.subscribe( contacts => {
					this.setState( {contacts} );
				} )
		);
	}

	componentWillUnmount() {
		this._subscription.dispose();
	}

	onNextClick() {
		if ( !this.props.selectedContactIds.length ) {
			return;
		}
		this.props.onDone();
	}

	render() {
		if ( !this.state.contacts ) {
			return null;
		}
		let nextClassName = classNames( "btn btn-green", {
			disabled: !this.props.selectedContactIds.length
		} );
		let {className} = this.props;
		className = classNames( className, "noflex" );
		return (
			<div className={className}>
				<button
					className="btn btn-green"
					onClick={this.props.onBack}>
					<Translation textId="web.sharedcontacts.create.workgroup.prev" />
				</button>
				<div style={{padding: "20px"}}>
					<Translation textId="web.sharedcontacts.create.workgroup.text" />
					<PromptContacts
						contacts={this.state.contacts}
						onChange={this.props.onChange}
						selectedIds={this.props.selectedContactIds}
					/>
				</div>
				<button
					className={nextClassName}
					onClick={this.onNextClick}>
					<Translation textId="web.sharedcontacts.create.workgroup.next" />
				</button>
			</div>
		);
	}
}

class AddSharedContactListContentStepRights extends React.Component {
	constructor() {
		super();
		this.onNextClick = this.onNextClick.bind( this );
		this._service = remoteServiceLocator();
	}

	componentWillMount() {
		this._subscription = (
			this._service.observeContactList()
				.subscribe( contacts => {
					this.setState( {contacts} );
				} )
		);
	}

	componentWillUnmount() {
		this._subscription.dispose();
	}

	onNextClick() {
		this.props.onDone();
	}

	toggleModifyWorkgroup( id ) {
		let {rights} = this.props;
		let right = rights[ id ] || {allowModifyWorkgroup: true, allowModifyContacts: true};
		right.allowModifyWorkgroup = !right.allowModifyWorkgroup;
    rights[ id ] = right;
		this.props.onChange( rights );
	}

	toggleModifyContacts( id ) {
    let {rights} = this.props;
		let right = rights[ id ] || {allowModifyWorkgroup: true, allowModifyContacts: true};
		right.allowModifyContacts = !right.allowModifyContacts;
		rights[ id ] = right;
		this.props.onChange( rights );
	}

	renderRightsTable() {
		return (
			<table style={{width: "100%"}}>
			<thead>
				<tr>
					<th><Translation textId="sharedcontacts.dashboard.participant"/></th>
					<th><Translation textId="sharedcontacts.dashboard.allowworkgroupedit"/></th>
					<th><Translation textId="sharedcontacts.dashboard.allowcontactsedit"/></th>
				</tr>
			</thead>
			<tbody>
			{_.map(
				this.props.workgroupIds,
				id => {
					let {name} = _.find( this.state.contacts, {id} );
					let right = this.props.rights[ id ] || {allowModifyWorkgroup: true, allowModifyContacts: true};
					return (
						<tr key={id}>
							<td><div className="item">{name}</div></td>
							<td>
								<Switch
									checked={ right.allowModifyWorkgroup }
									onChange={ this.toggleModifyWorkgroup.bind( this, id ) }
									id={`allowModifyWorkgroup${id}`}
								/>
							</td>
							<td>
								<Switch
									checked={ right.allowModifyContacts }
									onChange={ this.toggleModifyContacts.bind( this, id ) }
									id={`toggleModifyContacts${id}`}
								/>
							</td>
						</tr>
					);
				}
			) }
			</tbody>
			</table>
		);
	}

	render() {
		if ( !this.state.contacts ) {
			return null;
		}
		let {className} = this.props;
		className = classNames( className, "noflex" );
		return (
			<div className={className}>
				<button
					className="btn btn-green"
					onClick={this.props.onBack}>
					<Translation textId="web.sharedcontacts.create.rights.prev" />
				</button>
				<div style={{padding: "20px"}}>
					<Translation
						textId="web.sharedcontacts.create.rights.text"
						params={[this.props.name]}
					/>
					{this.renderRightsTable()}
				</div>
				<button
					className="btn btn-green"
					onClick={this.onNextClick}>
					<Translation textId="web.sharedcontacts.create.rights.next" />
				</button>
			</div>
		);
	}
}

class AddSharedContactListContentStepContacts extends React.Component {
	constructor() {
		super();
		this.onNextClick = this.onNextClick.bind( this );
		this._service = remoteServiceLocator();
	}

	componentWillMount() {
		this._subscription = (
			this._service.observeContactList()
				.subscribe( contacts => {
					this.setState( {contacts} );
				} )
		);
	}

	componentWillUnmount() {
		this._subscription.dispose();
	}

	onNextClick() {
		if ( !this.props.selectedContactIds.length ) {
			return;
		}
		this.props.onDone();
	}

	render() {
		if ( !this.state.contacts ) {
			return null;
		}
		let nextClassName = classNames( "btn btn-green", {
			disabled: !this.props.selectedContactIds.length
		} );
		let disabledIds2Text = Object.create( null );
		let {workgroupIds} = this.props;

		for( let i = 0; i < workgroupIds.length; i++ ) {
			disabledIds2Text[ workgroupIds[ i ] ] = "Workgroup contact";
		}
		let {className} = this.props;
		className = classNames( className, "noflex" );

		return (
			<div className={className}>
				<div className="invite">
					<button
						className="btn btn-green"
						onClick={this.props.onBack}>
						<Translation textId="web.sharedcontacts.create.contacts.prev" />
					</button>
					<div style={{padding: "20px"}}>
						<Translation
							textId="web.sharedcontacts.create.contacts.text"
							params={[this.props.name]}
						/>
						<PromptContacts
							contacts={this.state.contacts}
							onChange={this.props.onChange}
							selectedIds={this.props.selectedContactIds}
							disabledIds2Text={disabledIds2Text}
						/>
					</div>
					<button
						className={nextClassName}
						onClick={this.onNextClick}>
						<Translation textId="web.sharedcontacts.create.contacts.next" />
					</button>
				</div>
			</div>
		);
	}
}

export default AddSharedContactListContent;
