"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _base = require("./base.js");

var _base2 = _interopRequireDefault(_base);

var _crypto = require("crypto");

var _crypto2 = _interopRequireDefault(_crypto);

var _prng = require("../random/locators/prng.js");

var _prng2 = _interopRequireDefault(_prng);

var _key = require("../primitives/key.js");

var _key2 = _interopRequireDefault(_key);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var random = (0, _prng2.default)();

var NodeDecipher = function (_BaseDecipher) {
	_inherits(NodeDecipher, _BaseDecipher);

	function NodeDecipher(algorithm, key, blockSize, useIV, usePadding) {
		_classCallCheck(this, NodeDecipher);

		if (!(key instanceof _key2.default)) {
			throw new Error("Invalid key type");
		}

		var _this = _possibleConstructorReturn(this, (NodeDecipher.__proto__ || Object.getPrototypeOf(NodeDecipher)).call(this, blockSize, usePadding));

		_this._ivSize = useIV ? blockSize : 0;
		_this._algorithm = algorithm;
		_this._keyHandle = key.addKeyUse("decipher");
		_this._key = key;
		_this._appended = [];
		_this._usePadding = usePadding;
		return _this;
	}

	_createClass(NodeDecipher, [{
		key: "append",
		value: function append(buffer) {
			this._appended.push(buffer);
			return this;
		}
	}, {
		key: "getResultThen",
		value: function getResultThen() {
			var _this2 = this;

			var totalBuffer = Buffer.concat(this._appended);
			if (totalBuffer < this._ivSize) {
				return Promise.reject(new Error("Size of input data to decipher less than iv size"));
			}
			var iv = totalBuffer.slice(0, this._ivSize);
			return new Promise(function (resolve, reject) {
				_this2._key.postponeManagedBuffer(function (mb) {
					mb.useAsBuffer(function (keyBuffer) {
						var decipher = _crypto2.default.createDecipheriv(_this2._algorithm, keyBuffer, iv);
						decipher.setAutoPadding(_this2._usePadding);
						var result = void 0;
						try {
							result = Buffer.concat([decipher.update(totalBuffer.slice(_this2._ivSize)), decipher.final()]);
							resolve(result);
						} catch (e) {
							debugger;
							console.log("Decrypt failed " + totalBuffer.toString("base64") + " using key " + keyBuffer.toString("base64") + " padding " + _this2._usePadding);
							reject(e);
						}
						_this2._key.removeKeyUse(_this2._keyHandle);
						delete _this2._appended;
						delete _this2._key;
						delete _this2._algorithm;
					});
				});
			});
		}
	}], [{
		key: "getDecryptedSize",
		value: function getDecryptedSize(blockSize, useIV, encryptedSize) {
			return encryptedSize - (useIV ? blockSize : 0);
		}
	}]);

	return NodeDecipher;
}(_base2.default);

exports.default = NodeDecipher;