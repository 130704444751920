import BaseRepository from "./base.repository.js";

class HookableRepository extends BaseRepository {
	constructor( StorageType, fileName ) {
		super( StorageType, fileName );
		this._writeHook = null;
	}

	hookWrite( func ) {
		this._writeHook = func;
	}

	replaceDataThen( buffer ) {
		if ( this._writeHook ) {
			return Promise.all( [
				this._writeHook( buffer ),
				this.lockStorageThen( storage => storage.replaceThen( buffer ) )
			] );
		}
		return this.lockStorageThen( storage => storage.replaceThen( buffer ) );
	}

	replaceDataAsync( buffer ) {
		return Rx.Observable.fromPromise( this.replaceDataThen( buffer ) );
	}

	getRawDataAsync( ) {
		return (
			this.lockStorageAsync( storage => storage.readAsBufferAsync() )
		);
	}

	setRawDataAsync( buffer ) {
		if ( !buffer ) {
			throw new Error( "Buffer required" );
		}
		return (
			this.lockStorageAsync( storage => storage.replaceAsync( buffer ) )
		);
	}
}

export default HookableRepository;
