import io from "socket.io-client";

class SocketContainer {
  constructor() {
    this._socketPerUrl = Object.create( null );
  }

  getAndUseSocket( url ) {
    if ( !this._socketPerUrl[ url ] ) {
      this.connect( url );
    }
    return this._socketPerUrl[ url ];
  }

  connect( url ) {
    let socket = io.connect( url, {
      "sync disconnect on unload": true,
      timeout: 5000,
      reconnectionDelayMax: 5000,
      cookie: false,
      // Problems with proxy etc.
      // transports: [ "websocket" ]
    } );

    socket.on('connect', () => {
      console.error(new Date, 'Socket connect');
    });

    socket.on('disconnect', () => {
      console.error(new Date, 'Socket disconnect');
    });

    socket.on('close', () => {
      console.error(new Date, 'Socket close');
    });

    this._socketPerUrl[ url ] = socket;
    // socket.on( "disconnect", () => {
    //   this.connect( url );
    // } );
  }

  unUseSocket( socket ) {
    //TODO: count refs and disconnect
  }
}

export default SocketContainer;
