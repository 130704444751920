import React from "react";
import _ from "lodash";
import Rx from "rx";

import Translation from "../../components/translation.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import FullScreen from "../../components/fullscreen.jsx";
import TopLevel from "../common/top.level.view.jsx";
import ImageButton from "../../components/button.image.jsx";
import Checkbox from "../../components/checkbox.jsx";
import Toast from "../../components/toast.jsx";
import ArrowButton from "../../components/button.continue.jsx";
import OnlineStatus from "../../components/online.status.jsx"

class WorkgroupRights extends React.Component {
	constructor() {
		super();
		this.onBackPress = this.onBackPress.bind( this );
		this.onContinueClick = this.onContinueClick.bind( this );

		this.state = {
			modifiedRights: Object.create( null )
		};
	}

	onBackPress() {
		this.props.onBack();
	}

	setModifiedRights( modifiedRights ) {
		for ( let pid in modifiedRights ) {
			let m = modifiedRights[ pid ];
			let p = this.props.workgroupParticipants[ pid ];
			if ( !p ||
				( ( m.allowModifyContacts === p.rights.allowModifyContacts )
					&& ( m.allowModifyWorkgroup === p.rights.allowModifyWorkgroup )
				)
			) {
				delete modifiedRights[ pid ];
			}
		}
		this.setState( { modifiedRights } );
	}

	toggleModifyWorkgroup( p, pid ) {
		if ( this.state.loading || !this.isAllowedEdit() ) {
			return;
		}
		let modifiedRights = this.state.modifiedRights;

		if ( !( pid in modifiedRights ) ) {
			modifiedRights[ pid ] = _.clone( p.rights );
		}

		modifiedRights[ pid ].allowModifyWorkgroup = !modifiedRights[ pid ].allowModifyWorkgroup;
		this.setModifiedRights( modifiedRights );
	}

	toggleModifyContacts( p, pid ) {
		if ( this.state.loading || !this.isAllowedEdit() ) {
			return;
		}

		let modifiedRights = this.state.modifiedRights;

		if ( ( !this.props.selfRights || !this.props.selfRights.allowModifyContacts )
			&& !p.rights.allowModifyContacts ) {
				this.refs.accessdenied.show();
				return;
			}


		if ( !( pid in modifiedRights ) ) {
			modifiedRights[ pid ] = _.clone( p.rights );
		}

		modifiedRights[ pid ].allowModifyContacts = !modifiedRights[ pid ].allowModifyContacts;
		this.setModifiedRights( modifiedRights );
	}

	isAllowedModifyWorkgroup( p, pid ) {
		let modifiedRights = this.state.modifiedRights;
		if ( !( pid in modifiedRights ) ) {
			return p.rights.allowModifyWorkgroup;
		}
		let rights = modifiedRights[ pid ];
		if ( "allowModifyWorkgroup" in rights ) {
			return rights.allowModifyWorkgroup;
		}
		return p.rights.allowModifyWorkgroup;
	}

	isAllowedModifyContacts( p, pid ) {
		let modifiedRights = this.state.modifiedRights;
		if ( !( pid in modifiedRights ) ) {
			return p.rights.allowModifyContacts;
		}
		let rights = modifiedRights[ pid ];
		if ( "allowModifyContacts" in rights ) {
			return rights.allowModifyContacts;
		}
		return p.rights.allowModifyContacts;
	}

	onContinueClick() {
		let modifiedRights = _.map( this.state.modifiedRights, ( rights, pid ) => ( { rights, pid } ) );
		this.setState( { loading: true } );
		this.props.onBack( modifiedRights )
	}

	retry() {
		this.setState( { loading: false } );
	}

	getContatItems( allowEdit ) {
		return (
			<table>
			<thead>
				<tr>
					<th><Translation textId="sharedcontacts.dashboard.participant"/></th>
					<th><Translation textId="sharedcontacts.dashboard.allowworkgroupedit"/></th>
					<th><Translation textId="sharedcontacts.dashboard.allowcontactsedit"/></th>
				</tr>
			</thead>
			<tbody>
			{_.map(
				this.props.workgroupParticipants,
				(p, pid) =>
				<tr key={pid}>
					<td>
					<div className="item">
						<span className="name">
							{p.nickname}
						</span>
					</div>
					</td>
					{
						!p.rights
						? null
						: [ <td key="1">
							<Checkbox
								value={ this.isAllowedModifyWorkgroup( p, pid ) }
								onClick={ this.toggleModifyWorkgroup.bind( this, p, pid ) }
								className={ !allowEdit ? "disabled": "" }
							/>
						</td>,
						<td key="2">
							<Checkbox
								value={ this.isAllowedModifyContacts( p, pid ) }
								onClick={ this.toggleModifyContacts.bind( this, p, pid ) }
								className={ !allowEdit ? "disabled": "" }
							/>
						</td>
						]
					}
				</tr>
			)}
			</tbody>
			</table>
		);
	}

	getCurrentFullscreenView() {
		return null;
	}

	isAllowedEdit() {
		return !!this.props.selfRights && !!this.props.selfRights.allowModifyWorkgroup;
	}

	render() {
		let allowEdit = this.isAllowedEdit();
		return (
			<FullScreen>
				<TopLevel>
					<header>
						<span className="header-caption">
							<Translation textId="sharedcontacts.dashboard.header" />
						</span>
						<ImageButton type="icon-arrow-left" onClick={this.onBackPress}/>
						<OnlineStatus/>
					</header>
					<main>
						<Translation textId="sharedcontacts.dashboard.workgroup.rights.text"/>
						<div className="contact-list">
							{this.getContatItems( allowEdit )}
						</div>
					</main>
					{
						allowEdit
						?
						<footer>
							<ArrowButton
								enabled={
									!this.state.loading
									&& ( this.props.continueAlwaysActive
										|| !_.isEmpty( this.state.modifiedRights ) )
									}
								caption="sharedcontacts.dashboard.setrights.button"
								handleClick={this.onContinueClick}
								id="rights-save"
							/>
						</footer>
						: null
					}
					{ this.getCurrentFullscreenView() }
				</TopLevel>
				<DeviceBackButton onPress={ this.onBackPress } />
				<Toast textId="access.denied" ref="accessdenied" />
		</FullScreen>
		);
	}
}

export default WorkgroupRights;
