import React from "react";
import Rx from "rx";
import _ from "lodash";
import Qr from "../../components/qr.jsx";
import InputWithHelp from "../../components/input.withhelp.jsx";
import Timestamp from "../../components/timestamp.jsx";
import FullScreen from "../../components/fullscreen.jsx";
import TopLevel from "../common/top.level.view.jsx";
import Translation from "../../components/translation.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import Button from "../../components/button.jsx";
import ArrowButton from "../../components/button.continue.jsx";
import ImageButton from "../../components/button.image.jsx";
import TranslatedInput from "../../components/tinput.jsx";
import TooltipButton from "../../components/button.tooltip.jsx";
import {InternetRetryConfirm} from "../../components/dialogs.jsx"
import {DynamicToast} from "../../components/toast.jsx";
import OnlineStatus from "../../components/online.status.jsx"
import Notifications from "../common/notifications.jsx";
import Checkbox from "../../components/checkbox.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

class BackupView extends React.Component {
	constructor() {
		super();
		this.state = {
			token: "",
			isInProgress: false,
		};
		this._service = serviceLocator();
		this.onCreated = this.onCreated.bind( this );
		this.onDeleted = this.onDeleted.bind( this );
		this.onSetProgress = this.onSetProgress.bind( this );
		this.onBackPress = this.onBackPress.bind( this );
	}

	static get propTypes() {
		return {
			onBack: React.PropTypes.func
		};
	}

	componentWillMount() {
		this._subscription = (
			this._service.observeBackup()
				.subscribe( backup => {
					this.setState( { backup } );
				} )
		);
	}

	onCreated( haveFake ) {
		if ( haveFake ) {
			this._service.reload();
			return;
		}
		this.setState( { isInProgress: false } )
	}

	onDeleted() {
		this.setState( {
			token: null
		} );
	}

	renderContent() {
		let {token, backup} = this.state;
		if ( backup === undefined ) {
			return null;
		}
		if ( backup ) {
			return <BackupTokenView
				token={backup.token}
				onDeleted={this.onDeleted}
			/>;
		}

		return <BackupCreateView
			onCreated={this.onCreated}
			onSetProgress={this.onSetProgress}
			onBack={this.props.onBack}
		/>;
	}

	renderBackButton() {
		if ( this.state.isInProgress ) {
			return null;
		}
		return (
			<ImageButton type="icon-arrow-left" onClick={this.props.onBack}/>
		);
	}

	onBackPress() {
		if ( this.state.isInProgress ) {
			this._service.cancelCreateBackupAsync().subscribe();
			return;
		}
		this.props.onBack();
	}

	onSetProgress( isInProgress ) {
		this.setState( { isInProgress } );
	}

	render() {
		return (
			<FullScreen><TopLevel>
				<header>
					{this.renderBackButton()}
					<span className="header-caption">
						<Translation textId="options.backup.header" />
					</span>
					<OnlineStatus/>
				</header>
				{this.renderContent()}
				<DeviceBackButton onPress={this.onBackPress} />
			</TopLevel></FullScreen>
		);
	}
}

class BackupCreateView extends React.Component {
	constructor() {
		super();
		this.state = {
			haveFake: false,
			isInProgress: false,
			isPasswordInvalid: false,
			password: ""
		};
		this.onCheckboxChange = this.onCheckboxChange.bind( this );
		this.onPasswordChange = this.onPasswordChange.bind( this );
		this.onCreate = this.onCreate.bind( this );
		this.onCancel = this.onCancel.bind( this );
		this.onBack = this.onBack.bind( this );
		this._service = serviceLocator();
	}

	onBack() {
		if ( this.state.isInProgress ) {
			this.onCancel();
			return;
		}
		this.props.onBack();
	}

	onCancel() {
		this._service.cancelCreateBackupAsync().subscribe();
	}

	onCreate() {
		let {isInProgress, haveFake, password} = this.state;
		let {onCreated} = this.props;
		if ( isInProgress ) {
			return;
		}
		this.setState( { isInProgress: true } );
		this.props.onSetProgress( true );

		this._service.createBackupAsync( haveFake ? password : undefined )
			.subscribe( ( [ index, total ] ) => {
				this.setState( { index, total } );
			}, error => {
				this.props.onSetProgress( false );
				if ( error === "EInvalidPassword" || error === "ENotFakePassword"
					|| error.message === "EInvalidPassword" || error.message === "ENotFakePassword" ) {
					this.setState( {
						isInProgress: false,
						isPasswordInvalid: true
					} );
					return;
				}
				if ( error === "Canceled" || error.message === "Canceled" ) {
					this.setState( { isInProgress: false, index: 0, total: 0 } );
					return;
				}
				console.error( error );
			}, () => {
				onCreated( haveFake );
			} );
	}

	onCheckboxChange() {
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( {
			haveFake: !this.state.haveFake,
			password: ""
		} );
	}

	onPasswordChange( password ) {
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( {password} );
	}

	renderPassword() {
		let {password, haveFake} = this.state;
		if ( !haveFake ) {
			return null;
		}
		return (
			<InputWithHelp
				type="password"
				value={password}
				onChange={this.onPasswordChange}
				placeholderTextId="backup.fake.password.placeholder"
				tooltipTextId="backup.fake.password.tooltip"
			/>
		);
	}

	renderWarning() {
		if ( !this.state.isPasswordInvalid || !this.state.haveFake ) {
			return null;
		}
		return (
			<div className="warning">
				<Translation textId="backup.fake.password.invalid"/>
			</div>
		);
	}

	renderCheckBox() {
		return (
			<div className="label">
				<Checkbox
					className="f-right"
					value={this.state.haveFake}
					onClick={this.onCheckboxChange}
				/>
				<Translation textId="backup.fake.checkbox"/>
			</div>
		);
	}

	renderProgress() {
		let {index, total, isInProgress} = this.state;
		if ( !isInProgress || !total ) {
			return null;
		}
		return <Translation textId="backup.progress" params={[index, total]}/>;
	}

	renderCreateButton() {
		if ( this.state.isInProgress ) {
			return;
		}
		return (
			<ArrowButton
				caption="backup.button.create"
				handleClick={this.onCreate}
				enabled={!this.state.isInProgress}
			/>
		);
	}

	renderCancelButton() {
		if ( !this.state.isInProgress ) {
			return;
		}
		return (
			<ArrowButton
				className="red-border"
				caption="backup.button.cancel"
				handleClick={this.onCancel}
			/>
		);
	}

	render() {
		return (
			<main>
				<div>
					<div className="small-text"><Translation textId="backup.create.text"/></div>
					{this.renderCheckBox()}
					{this.renderPassword()}
					{this.renderWarning()}
					{this.renderCreateButton()}
					{this.renderProgress()}
					{this.renderCancelButton()}
				</div>
				<DeviceBackButton onPress={this.onBack}/>
			</main>
		);
	}
}

class BackupTokenView extends React.Component {
	constructor() {
		super();
		this.state = {
			isInProgress: false
		};
		this.copyToken = this.copyToken.bind( this );
		this.onDelete = this.onDelete.bind( this );
		this._service = serviceLocator();
	}

	copyToken() {
		let copy = _.get( global, "cordova.plugins.clipboard.copy" );
		copy( this.props.token, () => this.refs.toast.show( { textId: "backup.copytoast" } ) );
	}

	onDelete() {
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( { isInProgress: true } );
		this._service.dropBackupAsync()
			.subscribe( () => {
				this.setState( {
					isInProgress: false
				}, this.props.onDeleted );
			} );
	}

	renderQrCode() {
		let {token} = this.props;
		if ( !token ) {
			return null;
		}
		return (
			<div className="qr-code blue f-left">
				<Qr className="qr-image" code={token}/>
			</div>
		);
	}

	renderCopyButton() {
		let copy = _.get( global, "cordova.plugins.clipboard.copy" );
		if ( !copy ) {
			return null;
		}
		return (
			<Button
				caption="backup.button.copy"
				handleClick={this.copyToken}
				enabled={this.props.enabled}
			/>
		);
	}

	render() {
		let {token} = this.props;
		return (
			<main>
				<div>
					<Button
						caption="backup.button.delete"
						handleClick={this.onDelete}
						enabled={!this.state.isInProgress}
					/>
					<div className="big-text"><Translation textId="backup.qr.bigtext"/></div>
					<div className="small-text"><Translation textId="backup.qr.smalltext"/></div>
					{this.renderQrCode()}
					<div className="clear"></div>
					<div className="small-text">
						<Translation textId="backup.plain.text"/>
					</div>
					<div className="invite-code-block-">
						<InputWithHelp
							type="text"
							className="invite-code"
							value={token}
							readOnly={true}
							placeholderTextId="empty"
							tooltipTextId="backup.token.tooltip"
							id="invite-code"
						/>
					</div>
					{this.renderCopyButton()}
					<DynamicToast ref="toast"/>
				</div>
			</main>
		);
	}
}

class BackupActiveView extends React.Component {
	constructor() {
		super();
		this.state = {
			isInProgress: false
		};
	}


	render() {
		return (
			<main>
				<div>
					<div className="big-text"><Translation textId="backup.qr.bigtext"/></div>
					<div className="small-text"><Translation textId="backup.qr.smalltext"/></div>
				</div>
			</main>
		);
	}
}

export default BackupView;
