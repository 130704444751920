import React from "react";
import ReactDOM from "react-dom";
import Translation from "./translation.jsx";
import FullScreenComponent from "./fullscreen.jsx";
import Tappable from 'react-tappable'
import Toast from './toast.jsx'
import _ from 'lodash'
import classNames from "classnames";

//TODO: refactor this code
class Tooltip extends React.Component {
	constructor() {
		super();
		this.hide = this.hide.bind( this );
		this.onEditClick = this.onEditClick.bind( this );
		this.onForwardClick = this.onForwardClick.bind( this );
		this.onCopyClick = this.onCopyClick.bind( this );
		this.onReplyClick = this.onReplyClick.bind( this );
		this.share = this.share.bind( this );
	}

	hide( ev ) {
		// let focusedNode = ReactDOM.findDOMNode( this );
		// if ( !focusedNode || !focusedNode.contains( ev.target ) ) {
			this.props.onHide();
		// }
	}

	componentDidMount() {
		// //Some king of bug calls in iOS safari mousedown event on touchend
		// global.document.addEventListener( "mousedown", this.hide );
		global.document.addEventListener( "touchstart", this.hide );
		global.document.addEventListener( "focus", this.hide );
	}

	componentWillUnmount() {
		// //Some king of bug calls in iOS safari mousedown event on touchend
		// global.document.removeEventListener( "mousedown", this.hide );
		global.document.removeEventListener( "touchstart", this.hide );
		global.document.removeEventListener( "focus", this.hide );
	}

	onEditClick( ev ) {
		this.props.onEdit();
		ev.preventDefault();
		ev.stopPropagation();
	}

	onCopyClick( ev ) {
		this.refs.copied.show();
		this.props.onCopy();
		ev.preventDefault();
		ev.stopPropagation();
	}

	onForwardClick( ev ) {
		this.props.onForward();
		ev.preventDefault();
		ev.stopPropagation();
	}

	onReplyClick( ev ) {
		setTimeout(this.props.onReply, 100);
		ev.preventDefault();
		ev.stopPropagation();
	}

	renderPaste() {
		return !this.props.onPaste ? null :
			<Translation
				className='unfocusable'
				onTouchStart={ this.props.onPaste }
				onClick={ this.props.onPaste }
				textId='dialogs.paste'
			/>;
	}

	renderCopy() {
		return !this.props.onCopy ? null :
			<Translation
				className='unfocusable'
				onTouchStart={ this.onCopyClick }
				onClick={ this.onCopyClick }
				textId="dialogs.copy"
			/>;
		}

		renderDelete() {
			return !this.props.onDelete ? null :
				<Translation
					className="unfocusable"
					onTouchStart={ this.props.onDelete }
					onClick={this.props.onDelete}
					textId="chat.message.delete"
				/>;
		}

		renderEdit() {
			return !this.props.onEdit ? null :
				<Translation
					className="unfocusable"
					onTouchStart={ this.onEditClick }
					onClick={ this.onEditClick }
					textId="chat.message.edit"
				/>;
		}

		renderForward() {
			return !this.props.onForward ? null :
				<Translation
					className="unfocusable"
					onTouchStart={ this.onForwardClick }
					onClick={ this.onForwardClick }
					textId="chat.message.forward"
				/>;
		}

		renderReply() {
			return !this.props.onReply ? null :
				<Translation
					className="unfocusable"
					onTouchStart={ this.onReplyClick }
					onClick={ this.onReplyClick }
					textId="chat.message.reply"
				/>;
		}

		renderClear () {
			return !this.props.onClear ? null :
				<Translation
					className='unfocusable'
					onTouchStart={ this.props.onClear }
					onClick={ this.props.onClear }
					textId='dialogs.clear'
				/>;
		}

		share() {
			window.plugins.socialsharing.share( this.props.onShare(), "" );
		}

		renderShare () {
			return !(_.get(window, 'plugins.socialsharing') && this.props.onShare) ? null :
				<Translation
					onTouchStart={ this.share }
					onClick={ this.share }
					textId="dialogs.share"
					className="unfocusable"
				/>;
		}

		render() {
			let { textId, trigger, isRight } = this.props;
			let actions = _.filter( [
				this.renderReply(), this.renderCopy(), this.renderPaste(), this.renderClear(),
				this.renderShare(), this.renderDelete(), this.renderEdit(),
				this.renderForward()
			] );
			let { top, left } = trigger ? trigger.getBoundingClientRect() : { left: 0, top: 0 };

			let tooltipStyle = {
				"bottom": ( global.document.body.scrollHeight - top ) + "px",
				"left": left + "px",
				right: 'unset',
				'fontSize': actions.length == 4 ? '1.6rem' : '2rem'
			};
			if ( isRight ) {
				tooltipStyle.left = "unset";
				tooltipStyle.right = "10%";
			}
			let triangleClassName = classNames(
				"unfocusable",
				isRight ? "triangle-right" : "triangle-left"
			);
			let separators = actions.length < 2 ? [] :  _.fill( Array( actions.length - 1 ),
			<span className='separator unfocusable'>|</span>
		);

		return (
			! _.filter( actions ).length ? null :
			<div className="tooltip unfocusable" style={tooltipStyle}>
				{ _.zip( actions, separators ) }
				<img src="images/tooltip-triangle.svg" className={ triangleClassName } />
				<Toast textId="dialogs.copied" ref="copied" />
			</div>
		);
	}
}

class TooltipController extends FullScreenComponent {
	constructor() {
		super();
		this.state = { visible: false };
		this.hide = this.hide.bind( this );
		this._isRenderedContent = false;
	}

	show( trigger ) {
		this.setState( { visible: true, trigger } );
	}

	hide() {
		this.setState( { visible: false } );
	}

	remount() {
		if ( this.state.visible ) {
			this._isRenderedContent = true;
			ReactDOM.render( this.renderContent(), this._mountedElement );
		} else {
			if ( !this._isRenderedContent ) {
				return;
			}
			this._isRenderedContent = false;
			ReactDOM.unmountComponentAtNode( this._mountedElement );
		}
	}

	renderContent() {
		let { children, ...props } = this.props;
		return <Tooltip {...props} trigger={this.state.trigger} onHide={this.hide}/>;
	}

	render() {
		let { children } = this.props;
		if ( !React.Children.count( children ) ) {
			return null;
		}
		return React.Children.only( children );
	}
}

class TooltipWrapper extends React.Component {
	constructor() {
		super();
		this.show = this.show.bind( this );
	}

	show() {
		let trigger = ReactDOM.findDOMNode( this.refs.trigger ).firstChild;
		this.refs.dialog.show( trigger );
	}

	render() {
		let { children, showOnTap,...props } = this.props;
		if ( showOnTap ) {
			return (
				<TooltipController ref="dialog" {...props}>
					<Tappable onTap={this.show} ref="trigger">
						{this.props.children}
					</Tappable>
				</TooltipController>
			);
		}

		return (
			<TooltipController ref="dialog" {...props}>
				<Tappable onPress={this.show} ref="trigger">
					{this.props.children}
				</Tappable>
			</TooltipController>
		);
	}
}

export default TooltipWrapper;
