import LocalStorage from "./local.storage.js";
import FileStorage from "./file.storage.js";

const unmocked = global.resolveLocalFileSystemURL ? FileStorage : LocalStorage;
let MockType = unmocked;

export { MockType as default };

export function mock( StorageType ) {
	MockType = StorageType;
}

export function unmock() {
	MockType = global.resolveLocalFileSystemURL ? FileStorage : LocalStorage;
}

export { unmocked };
