import React from "react";
import Clipboard from "clipboard";

import Translation from "../../../components/translation.jsx";
import Qr from "../../../components/qr.jsx";
import Toast from "../../../components/toast.jsx";
import configuration from "../../../../common/configuration.js";

class InviteContentView extends React.Component {
	componentDidMount() {
		this._clipboard = new Clipboard( "#copy" );
		this._clipboard.on( "success", event => {
			this.refs.copied.show();
			event.clearSelection();
		} );
		this._clipboard.on( "error", event => {
			this.refs.copy_failed.show();
		} );
	}

	componentWillUnmount() {
		this._clipboard.destroy();
	}

	renderTokenInvite() {
		const {inviteToken} = this.props.contact;
		return (
			<div className={this.props.className} >
				<div className="message-code">
					<p>
						<Translation textId="web.invite.text1" />
					</p>
					<Qr code={inviteToken} className="qr-code" />
					<p>
						<Translation textId="web.invite.text2" />
					</p>
					<div className="code" id="invite_token">{inviteToken}</div>
					<div className="links">
						<a
							href="javascript:;"
							id="copy"
							data-clipboard-target="#invite_token"
						>
							<Translation textId="web.invite.copy.button" />
						</a>
					</div>
				</div>
				<Toast textId="web.invite.copied.toast" ref="copied"/>
				<Toast textId="web.invite.copy_failed.toast" ref="copy_failed"/>
			</div>
		);
	}

	renderExternalInvite() {
		const {inviteToken} = this.props.contact;
		const inviteUrl = configuration.getWebUrlBase() + "/#external/" + inviteToken;
		return (
			<div className={this.props.className} >
				<div className="message-code">
					<p><Translation textId="web.invite.text3" /></p>
					<Qr code={inviteUrl} className="qr-code" />

					<div className="code" id="invite_url">{inviteUrl}</div>
					<div className="links">
						<a
							href="javascript:;"
							id="copy"
							data-clipboard-target="#invite_url"
						>
							<Translation textId="web.invite.copy.button" />
						</a>
					</div>
				</div>
				<Toast textId="web.invite.copied.toast" ref="copied"/>
				<Toast textId="web.invite.copy_failed.toast" ref="copy_failed"/>
			</div>
		);
	}

	render() {
		if ( this.props.contact.isExternal ) {
			return this.renderExternalInvite();
		}
		return this.renderTokenInvite();
	}
}

export default InviteContentView;
