import React from "react";
import Prompt from "../common/prompt.jsx";

class SetNicknameView extends React.Component {
	constructor() {
		super();
		this.onSetNickname = this.onSetNickname.bind( this );
	}

	onSetNickname( newName ) {
		this.props.onSetNickname( { nickname: newName } );
	}

	validateNickname( nickname ) {
		return [];
 	}

	render() {
		return (
			<Prompt
				onSetValue={this.onSetNickname}
				onBack={this.props.onBack}
				validate={this.validateNickname}
				headerTextId="options.nickname.header"
				textId="options.nickname.text"
				placeholderTextId="options.nickname.placeholder"
				defaultValue={ this.props.nickname }
				autofocus={true}
				firstUppercased={true}
			/>
		);
	}
}

export default SetNicknameView;
