import Rx from "rx";
import _ from "lodash";
import ssgCrypto, { KEY_KINDS, Config } from "ssg.crypto";

import ClientServerOnline from "./client.server.online.js";
import {
	serializeObject,
	deserializeObject,
	serializeObjectWithKeysToNewManagedBufferThen,
	deserializeObjectWithKeysFromManagedBufferThen
} from "../../common/serializer.js";

class ClientServerRPC extends ClientServerOnline {
	constructor( apiUrlBase, connectionId, seedMacKey, seedEncryptionKey, signer, verifier, econfig ) {
		if ( seedMacKey.kind !== KEY_KINDS.INTERMEDIATE ) {
			throw new Error( "seedMacKey INTERMEDIATE key required" );
		}
		if ( seedEncryptionKey.kind !== KEY_KINDS.INTERMEDIATE ) {
			throw new Error( "seedEncryptionKey INTERMEDIATE key required" );
		}
		if ( !( econfig instanceof Config ) ) {
			throw new Error( "econfig required" );
		}
		super( apiUrlBase, connectionId );
		this._seedMacKey = seedMacKey;
		this._seedEncryptionKey = seedEncryptionKey;
		this._signer = signer;
		this._verifier = verifier;
		this._econfig = econfig;
		this._waitingCalls = Object.create( null );
		this._pairSubj = new Rx.BehaviorSubject( false );
		this._connectionSubj = new Rx.BehaviorSubject( true );

		this._remoteClientId = null;
		this._remotePublicKeyThen = null;
		this._selfPrivateKeyThen = null;
		this._selfPublicKeyThen = null;
		this._encryptionKeyThen = null;
		this._macKeyThen = null;

		this._clientIdThen = ssgCrypto.createRandomBase64StringThen( 32 );

		this.onGotMessage( message => {
			this._processDataMessageAsync( message )
				.flatMap( resp => resp
					? this._sendToClientAsync( resp, message.pairData )
					: Rx.Observable.just()
				)
				.subscribe();
		} );
		this._pendingClientCalls = Object.create( null );
		this._receivedCalls = Object.create( null );

		this._remoteMethods = Object.create( null );
		this._remoteMethods.resp = this._remote_resp;

		this.onConnected( () => { this._connected(); } );
		this.onDisconnected( () => { this._disconnected(); } );
		this._pair();
	}

	_connected( ) {
		this._connectionSubj.onNext( true );
		this._pair();
	}

	_pair( ) {
		this._clientIdThen.then( clientId =>
			this._callUntilSuccessThen( cb => {
				if ( !this._connectionSubj.getValue() ) { //not connected
					cb();
					return;
				}
				this.tryPair( clientId, cb );
			} )
		);
	}

	_disconnected() {
		this._connectionSubj.onNext( false );
		this._onUnpaired( { isForever: false } );
	}

	_onPaired( message ) {
		let { pairData } = message;
		this._pairSubj.onNext( pairData );
		if ( this._pairedCustomHandler ) {
			try {
				this._pairedCustomHandler( message );
			} catch ( e ) {
				console.error( e );
			}
		}
		this._retryPendingRemoteCalls();
	}

	_retryPendingRemoteCalls() {
		Rx.Observable.fromArray( _.values( this._pendingClientCalls ) )
			.concatMap( ( { message } ) =>
				this._sendToClientAsync( message )
			)
			.subscribe();		
	}

	_onUnpaired( message ) {
		this._pairSubj.onNext( false );
		if ( this._unpairedCustomHandler ) {
			try {
			 this._unpairedCustomHandler( message );
		  } catch( e ) {
			  console.error( e );
		  }
	  }
	}

	_getPrivateKeyThen( ) {
		if ( !this._selfPrivateKeyThen ) {
			let keyPairThen = ssgCrypto.createRandomKeyExchangeKeyPairThen( this._econfig )
			this._selfPrivateKeyThen = keyPairThen.then( ( { privateKey } ) => privateKey );
			this._selfPublicKeyThen  = keyPairThen.then( ( { publicKey } ) => publicKey );
		}
		return this._selfPrivateKeyThen;
	}

	_doHandshake( ) {
		let startAt = +new Date;
		this._getPrivateKeyThen(); //init private and public keys if needed
		if ( !this._remotePublicKeyThen ) {
			this._remotePublicKeyThen = new Promise( resolve => {
				this._remotePublicKeyCallback = resolve;
			} );
		}
		let pubThen = this._remotePublicKeyThen;
		let resultPairThen = ( this._selfPublicKeyThen
			.then( publicKey => serializeObjectWithKeysToNewManagedBufferThen(
				{ publicKey }, {
					publicKey: KEY_KINDS.KEY_EXCHANGE_PUBLIC
				}, this._econfig
			) )
			.then( mb => new Promise( resolve => {
				mb.useAsBuffer( buffer => {
					resolve( Buffer.concat( [ buffer ] ) );//clone
				} );
			} ) )
			.then( buffer => this._signer.makeSignThen( {
				getBufferThen: () => Promise.resolve( buffer )
			} ).then( signature => Buffer.concat( [ buffer, signature ] ) ) )
			.then( encryptedSigned =>
				this._callUntilSuccessThen( cb => {
					this._connectionSubj.getValue() //isConnected
					? this._tryCallEncrypted( "h", encryptedSigned, cb )
					: cb()
				} )
			)
			.then( () => Promise.all( [
				pubThen,
				this._selfPrivateKeyThen
			] ) )
			.then( ( [ publicKey, privateKey ] ) =>
				ssgCrypto.createSharedKeyThen( privateKey, publicKey, this._econfig )
			)
			.then( sharedKey => Promise.all( [
				ssgCrypto.createDerivedKeyFromKeysThen(
					sharedKey,
					this._seedEncryptionKey,
					KEY_KINDS.SYMMETRIC_ENCRYPTION,
					this._econfig
				),
				ssgCrypto.createDerivedKeyFromKeysThen(
					sharedKey,
					this._seedMacKey,
					KEY_KINDS.MAC,
					this._econfig
				)
			] ) )
		).then( resultPair => {
			return resultPair;
		} );
		this._encryptionKeyThen = resultPairThen.then( ( [ encryptionKey, macKey ] ) => encryptionKey );
		this._macKeyThen = resultPairThen.then( ( [ encryptionKey, macKey ] ) => macKey );
	}

	_doHandshakeIfNeedThen( ) {
		if ( !this._encryptionKeyThen ) {
			this._doHandshake();
		}
		return this._encryptionKeyThen;
	}

	_remote_resp( param, json ) {
		let { callId } = json;
		let { cb } = this._pendingClientCalls[ callId ];
		if ( !cb ) {
			console.warn( "Got client rpc resp for unknown call", callId );
			return;
		}
		delete this._pendingClientCalls[ callId ];
		cb( param );
	}

	_processHandshakeBodyAsync( body ) {
		this._doHandshakeIfNeedThen();
		let pubThen = this._remotePublicKeyThen;
		let signLength = this._verifier.getSignByteLength();
		let signature = body.slice( body.length - signLength );
		let content = body.slice( 0, body.length - signLength );
		let remoteClientId = this._remoteClientId;
		this._verifier.verifyThen( {
			signature,
			binarySource: { getBufferThen: () => Promise.resolve( content ) }
		} ).then( isSuccess => {
			if ( !isSuccess ) {
				throw new Error( "Invalid signature in handshake" );
			}
			let mb = ssgCrypto.createManagedBuffer( content.length );
			mb.useAsBuffer( buf => {
				content.copy( buf );
			} );
			return deserializeObjectWithKeysFromManagedBufferThen( mb, {
				publicKey: KEY_KINDS.KEY_EXCHANGE_PUBLIC
			}, this._econfig ).then( res => { mb.dispose(); return res; } );
		} ).then( ( { publicKey } ) => {
			if ( remoteClientId !== this._remoteClientId ) {
				// pubThen.onCompleted();
				publicKey.dispose();
				return;
			}
			if ( this._remotePublicKeyCallback ) {
				this._remotePublicKeyCallback( publicKey );
				delete this._remotePublicKeyCallback;
			} else {
				this._remotePublicKeyThen = Promise.resolve( publicKey );
			}
		} );
		return Rx.Observable.just();
	}

	_processCallBodyAsync( encryptedSigned ) {
		let macCode = encryptedSigned.slice( encryptedSigned.length - 32 );//TODO: get mac length
		let encrypted = encryptedSigned.slice( 0, encryptedSigned.length - 32 );
		return (
			Rx.Observable.fromPromise(
				Promise.all( [
					this._macKeyThen,
					this._encryptionKeyThen
				] )
					.then( ( [ macKey, encryptionKey ] ) =>
						ssgCrypto.makeHmacCodeThen( macKey, encrypted, this._econfig )
							.then( macCode2 => {
								if ( !macCode.equals( macCode2 ) ) {
									throw new Error( "Mac code mismatch" );
								}
								return ssgCrypto.decryptToNewBufferThen( encrypted, encryptionKey, true, this._econfig );
							} )
					)
			)
				.flatMap( plain => {
					let json = deserializeObject( plain );
					let startAt = new Date;
					return this._processJsonMessage( json );
				} )
		);
	}

	_processDataMessageAsync( message ) {
		let { pairData, body } = message;
		let messageType = body.substr( 0, 1 );
		let encryptedSigned = new Buffer( body.substr( 1 ), "base64" );
		return (
			this._pairSubj
				.take( 1 )
				.flatMap( pairedData => {
					switch( messageType ) {
						case "h":
							if ( this._remoteClientId !== pairedData ) {
								this._remoteClientId = pairedData;
								return this._processHandshakeBodyAsync( encryptedSigned );
							}
							return Rx.Observable.just();
						case "p":
							if ( this._remoteClientId !== pairedData ) {
								return Rx.Observable.just();
							}
							return this._processCallBodyAsync( encryptedSigned );
						default:
							throw new Error( "Invalid message" );
					}
				} )
		);
	}

	_processJsonMessage( json ) {
		let { name, param, callId } = json;
		let method = this._remoteMethods[ json.name ];
		if ( !method ) {
			return Rx.Observable.throw( new Error( `Method ${json.name} not implemented` ) );
		}
		if ( this._receivedCalls[ callId ] ) {
			return Rx.Observable.just();
		}
		this._receivedCalls[ callId ] = true;
		//TODO: error handling
		let startAt = +new Date();
		let retValue = method.call( this, json.param, json );
		if ( retValue === undefined ) {
			return Rx.Observable.just();
		}
		if ( !retValue || !retValue.subscribe ) {
			return Rx.Observable.just( {
				name: "resp",
				param: retValue,
				callId: json.callId
			} );
		}
		return retValue.concat( Rx.Observable.just( {} ) ).first().map( param => ( {
			name: "resp",
			param,
			callId: json.callId
		} ) );
	}

	_unpairAsync( ) {
		this._isUnpairing = true;
		return this._callUntilSuccessAsync( cb => {
			this._tryMakeCall( "unpair", {
				with: this._connectionId,
				listenerId: null
			}, cb );
		} );
	}

	_tryCallEncrypted( messageType, encryptedSigned, cb ) {
		if ( typeof cb !== "function" ) {
			throw new Error( "Callback not received" );
		}
		this.trySend( messageType + encryptedSigned.toString( "base64" ), cb );
	}

	_sendToClientAsync( message, ifClientId ) {
		if ( !message || !message.callId ) {
			throw new Error( "message.callId required" );
		}
		if ( this._isDisposed ) {
			// console.warn( "Sending disposed rpc connection message", message );
			return Rx.Observable.just();
		}
		this._doHandshakeIfNeedThen();
		let plainBuffer = serializeObject( message );
		return Rx.Observable.fromPromise(
			Promise.all( [
				this._macKeyThen,
				this._encryptionKeyThen,
			] )
				.then( ( [ macKey, encryptionKey ] ) =>
					ssgCrypto.encryptThen( plainBuffer, encryptionKey, true, this._econfig )
						.then( encrypted => ssgCrypto.makeHmacCodeThen( macKey, encrypted, this._econfig )
							.then( macCode => Buffer.concat( [ encrypted, macCode ] ) )
						)
				)
				.then( encryptedSigned => this._callUntilSuccessThen( cb => {
					let pd = this._pairSubj.getValue();
					if ( !pd || ( ifClientId && ( ifClientId !== pd ) ) ) {
						cb();
						return;
					}
					this._tryCallEncrypted( "p", encryptedSigned, cb )
				} ) )
		);
	}

	_callClientAsync( name, param ) {
		let respSubj = new Rx.ReplaySubject( 1 );
		let startAt = new Date;
		return (
			Rx.Observable.fromPromise( ssgCrypto.createRandomBase64StringThen( 32 ) )
				.flatMap( callId => {
					let message = {
						name, param, callId
					};
					if ( !this._pendingClientCalls ) {
						return Rx.Observable.empty();
					}
					this._pendingClientCalls[ callId ] = {
						cb: ( resp ) => {
							arguments.length && respSubj.onNext( resp );
							respSubj.onCompleted();
						},
						message
					};
					return this._sendToClientAsync( message );
				} )
				.flatMap( () => respSubj )
		);
	}

	onPaired( func ) {
		if ( this._pairedCustomHandler ) {
			throw new Error( "Paired custom handler already set" );
		}
		this._pairedCustomHandler = func;
	}

	onUnpaired( func ) {
		if ( this._unpairedCustomHandler ) {
			throw new Error( "Unpaired custom handler already set" );
		}
		this._unpairedCustomHandler = func;
	}

	dispose( ) {
		for( let callId in this._pendingClientCalls ) {
			this._pendingClientCalls[ callId ].cb();
		}
		delete this._pendingClientCalls;
		super.dispose();
	}
}

export default ClientServerRPC;
