import Rx from "rx";
import ssgCrypto, {KEY_KINDS} from "ssg.crypto";
import crypto from "crypto";
import Queue from "promise-queue";

import base64 from "../../../../common/utils/base64.js";
import base32 from "../../../../common/utils/base32.js";
import MessageSerializer from "./message.serializer.js";
import Encrypter from "./encrypter.js";
import keyHelper from "./key.helper.js";
import Pack from "./pack.js";
import messageServiceLocator from "../../services/locators/message.js";
import configuration from "../../../common/configuration.js";
import {encodeAsToken, encodeAsLocalToken} from "./address.codec.js";
import BinaryStream from "../binary.stream.js";

const CHUNK_SIZE = 16384 * 2;
const CONCURENCY = 4;

class Storer {
	/*
	mainBinaryStream, fakeBinaryStream(optional),
	mainPassword, fakePassword(optional),
	idLength,
	isSingleRead,
	expirationTS
	*/
	constructor( params ) {
		if ( !( "isSingleRead" in params ) ) {
			throw new Error( "isSingleRead required" );
		}

		if ( !( "expirationTS" in params ) ) {
			throw new Error( "expirationTS required" );
		}

		if ( !params.algorithm ) {
			throw new Error( "algorithm required" );
		}

		if ( !( params.mainBinaryStream instanceof BinaryStream ) ) {
			throw new Error( "mainBinaryStream required" );
		}

		if ( params.fakeBinaryStream && !( params.fakeBinaryStream instanceof BinaryStream ) ) {
			throw new Error( "fakeBinaryStream must be BinaryStream" );
		}
		this._messageService = messageServiceLocator();

		let packThen = this._packThen( params );
		this._idLength = params.idLength || 1000;
		this._isSingleRead = params.isSingleRead;
		this._expirationTS = params.expirationTS;
		this._progressSubject = new Rx.ReplaySubject( 1 );
		this._sendingChunkCount = 0;
		this._onSendResolve = null;
		this._completition = this._sendThen( packThen, params.localToken );
	}

	_makeKeyDataThen( params ) {
		//TODO: secure memory management
		if ( params.fakeBinaryStream ) {
			return (
				ssgCrypto.createRandomBufferThen( 32 )
					.then( serverSeed =>
						keyHelper.generateClientKeyWithDifferentPositionsThen(
							serverSeed, params.mainPassword, params.fakePassword
						).then( clientKey => ( { serverSeed, clientKey } ) )
					)
			);
		}
		return (
			Promise.all( [
				ssgCrypto.createRandomBufferThen( 32 ),
				ssgCrypto.createRandomBufferThen( 32 )
			] ).then( ( [ serverSeed, clientKey ] ) =>
				( { serverSeed, clientKey } )
			)
		);
	}

	_packThen( params ) {
		let messageArray = [ { pass: params.mainPassword, binaryStream: params.mainBinaryStream } ];
		if ( params.fakeBinaryStream ) {
			messageArray.push( { pass: params.fakePassword, binaryStream: params.fakeBinaryStream } );
		}
		//TODO: secure key generation
		return (
			this._makeKeyDataThen( params ).then( keyData => {
				return (
					Promise.all( messageArray.map( serializedData =>
						ssgCrypto.createKeyFromBufferThen(
							keyHelper.saltKey( keyData.clientKey, keyData.serverSeed, serializedData.pass ),
							KEY_KINDS.SYMMETRIC_ENCRYPTION,
							configuration.getDefaultEncryptionConfig()
						).then( key => {
							let binaryStream = (
								new Encrypter( {
									econfig: configuration.getDefaultEncryptionConfig(),
									binaryStream: serializedData.binaryStream,
									key
								} ).getBinaryStream()
							);
							key.dispose();
							return {
								binaryStream,
								position: keyHelper.getMessagePosition(
									keyData.clientKey,
									keyData.serverSeed,
									serializedData.pass
								)
							};
						} )
					) )
						.then( parts => {
							let pack = new Pack( ssgCrypto.getEncryptionAlgorithmBlockSize( configuration.getDefaultEncryptionConfig() ) );
							parts.forEach( part => pack.addPart( part ) );
							return pack.createBinaryStreamThen();
						} )
						.then( binaryStream => {
							return {
								binaryStream,
								clientKey: keyData.clientKey,
								serverSeed: keyData.serverSeed
							};
						} )
				);
			} )
		);
	}

	_hashThen( packAsync ) {
		return packThen.then( pack => ssgCrypto.hash( pack.buffer ) );
	}

	_getId( hash ) {
		return base32.encode( hash ).substr( 0, this._idLength || 10000 );
	}

	_sendThen( packThen, useLocalToken ) {
		let clientKey, serverSeed;
		return (
			packThen.then( packData => {
				clientKey = packData.clientKey;
				serverSeed = packData.serverSeed;
				if ( packData.binaryStream.size > 128 * 1024 ) {
					return this._sendLongThen( packData );
				}
				return this._sendShortThen( packData );
			} ).then( result => {
				let apiBaseUrl = configuration.getApiBase();
				let token = (
					useLocalToken
					? encodeAsLocalToken( result.id, clientKey )
					: encodeAsToken( apiBaseUrl, result.id, clientKey )
				);
				return {
					id: result.id,
					hash: result.hashResult,
					expirationTS: this._expirationTS,
					clientKey,
					addresses: [ {
						name: "token",
						value: token
					} ]
				};
			} )
		);
	}

	_sendLongThen( packData ) {
		const { serverSeed, clientKey, binaryStream } = packData;

		const hash = crypto.createHash( "sha256" );
		this._monitoredStream = BinaryStream.createMonitoredStream(
			binaryStream,
			buffer => {
				hash.update( buffer );
			}
		);

		return (
			this._messageService.beginCreateThen( {
				dataLength: binaryStream.size,
				idLength: this._idLength
			} )
			.then( result => {
				this._removeToken = result.removeToken;
				this._tmpId = result.tmpId;
				return this._sendQueuedThen();
			} )
			.then( result => {
				const hashResult = hash.digest();
				return this._messageService.finishCreateThen( {
					tmpId: this._tmpId,
					idLength: this._idLength,
					hash: hashResult,
					isSingleRead: this._isSingleRead,
					expirationTS: this._expirationTS,
					serverSeed
				} ).then( ( res ) => ( { ...res, hashResult } ) );
			} )
		);
	}

	_sendShortThen( packData ) {
		const { serverSeed, clientKey, binaryStream } = packData;
		return (
			new Promise( ( resolve ) => {
				binaryStream.getAllBytesCb( resolve );
			} ).then( ( content ) => {
				const hashResult = ssgCrypto.hash( content );
				return this._messageService.sendMessageThen( {
					serverSeed: base64.encode( serverSeed ),
					isSingleRead: this._isSingleRead,
					expirationTS: this._expirationTS,
					content: base64.encode( content ),
					hash: base64.encode( hashResult )
				} ).then( ( res ) => ( { ...res, hashResult } ) );
			} )
		);
	}

	_sendQueuedThen() {
		if ( this._isSending ) {
			throw new Error( "Already sending" );
		}
		this._isSending = true;

		let queue = new Queue( CONCURENCY );
		return (
			new Promise( resolve => {
				this._onSendResolve = resolve;
				this._sendRecursiveThen( queue );
			} )
				.finally( () => {
					this._isSending = false;
				} )
		);

		// return new Promise( ( resolve, reject ) => {
		// 	this._progressSubject.subscribe( ( { total, sent } ) => {
		// 		if ( sent < total ) {
		// 			return;
		// 		}
		// 		this._progressSubject.onCompleted();
		// 		this._isSending = false;
		// 		resolve();
		// 	}, error => {
		// 		this._isSending = false;
		// 		reject( error );
		// 	} );
		// } );
	}

	_sendRecursiveThen( queue ) {
		let position = this._monitoredStream.position;
		if ( this._monitoredStream.size === 0 ) {
			this._onSendResolve();
			return Promise.resolve();
		}
		return queue.add( () =>
			new Promise( ( resolve, reject ) => {
				this._monitoredStream.getNextBytesCb(
					Math.min( CHUNK_SIZE, this._monitoredStream.size - position ),
					resolve, reject
				);
			} )
			.then( chunk => {
				if ( this._monitoredStream.size > this._monitoredStream.position ) {
					this._sendRecursiveThen( queue );
				}
				this._sendingChunkCount++;
				return this._messageService.addChunkThen( {
					tmpId: this._tmpId,
					position,
					chunk
				} ).then( result => {
					this._sendingChunkCount--;
					this._progressSubject.onNext( {
						total: this._monitoredStream.size,
						sent: this._monitoredStream.position
					} );
					if ( this._monitoredStream.size === this._monitoredStream.position && !this._sendingChunkCount ) {
						this._onSendResolve();
					}
				} );
			} )
		);
	}

	retryAfterError( ) {
		if ( !this._error ) {
			throw new Error( "No error to retry" );
		}
		if ( !this._chunksLeft ) {
			throw new Error( "No chunkLeft defined to send" );
		}
		return this._sendQueuedThen();
	}

	getProgress( ) {
		return this._progressSubject;
	}

	completeThen( ) {
		return this._completition;
	}
}

export default Storer;
