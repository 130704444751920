import Rx from "rx";
import _ from "lodash";
import ssgCrypto,{KEY_KINDS, Config} from "ssg.crypto";
import Queue from "promise-queue";

import profileServiceLocator from "./locators/profile.js";
import currentUserServiceLocator from "./locators/current.user.js";
import contactServiceLocator from "./locators/contact.js";
import groupServiceLocator from "./locators/group.js";
import sharedcontactsServiceLocator from "./locators/sharedcontacts.js";
import configServiceLocator from "./locators/config.js";
import {profileKeyMap} from "./profile.js";
import configuration from "../../common/configuration.js";
import contactRepositoryLocator from "../repositories/locators/contact.js";
import seedRepositoryLocator from "../repositories/locators/seed.js";
import profileRepositoryLocator from "../repositories/locators/profile.js";
import masterKeyRepositoryLocator from "../repositories/locators/master.key.js";
import {serializeContactToBuffer} from "../repositories/contact/contact.list.js";
import {contactKeyMap} from "../repositories/contact/contact.details.js";
import {sendMessageAsync, receiveMessageAsync} from "../models/message/technical.js";
import Contact from "../models/contact.js";
import ClientServerIndexValue from "../transport/client.server.index.value.js";
import {serializeObjectWithKeysToNewManagedBufferAsync, deserializeObjectWithKeysFromManagedBufferAsync}
	from "../../common/serializer.js";
import Transaction from "../transport/transaction.js";

class BackupWriter {
	constructor( apiUrlBase, containerId, publicKey, macKey, encryptionKey, econfig ) {
		this._container = new ClientServerIndexValue( apiUrlBase, containerId );
		this._publicKey = publicKey;
		this._macKey = macKey;
		this._encryptionKey = encryptionKey;
		this._econfig = econfig;
		this._keysAsyncPerItem = Object.create( null );
	}

	setItem( buffer, index, transaction ) {
		let waitSubj = new Rx.Subject();
		transaction.waitFor( waitSubj, "Encrypt and mac" );
		this.makeEncryptedDataAsync( buffer )
			.subscribe( buffer => {
				let base64 = buffer.toString( "base64" );
				this._container.send( index, base64, transaction );
				waitSubj.onCompleted();
			} );
	}

	setContactListItem( buffer, contactIndex, transaction ) {
		this.setItem( buffer, 2 * contactIndex + 3, transaction );
	}

	setContactDetailsItem( buffer, contactIndex, transaction ) {
		this.setItem( buffer, 2 * contactIndex + 4, transaction );
	}

	setProfileItem( buffer, transaction ) {
		this.setItem( buffer, 2, transaction );
	}

	setSeedItem( buffer, transaction ) {
		this.setItem( buffer, 1, transaction );
	}

	setMasterKeyItem( buffer, transaction ) {
		this.setItem( buffer, 0, transaction );
	}

	makeEncryptedDataAsync( mb ) {
		return Rx.Observable.fromPromise(
			ssgCrypto.encryptThen( mb, this._encryptionKey, true, this._econfig )
				.then( encrypted =>
					ssgCrypto.makeHmacCodeThen( this._macKey, encrypted, this._econfig )
						.then( macCode => Buffer.concat( [ encrypted, macCode ] ) )
				)
		);
	}

	dispose( ) {
		//TODO: dispose keys
	}
}

class BackupReader extends ClientServerIndexValue {
	constructor( apiUrlBase, containerId, privateKey, macKey, encryptionKey, econfig ) {
		super( apiUrlBase, containerId );
		this._container = new ClientServerIndexValue( apiUrlBase, containerId );
		this._privateKey = privateKey;
		this._macKey = macKey;
		this._encryptionKey = encryptionKey;
		this._econfig = econfig;
	}

	readContactsData( ) {
		let total;
		return (
			this.lockAsync()
				.flatMap( () =>
					this._callUntilSuccessAsync( cb => {
						this.tryGetMaxIndex( cb );
					} )
				)
				.tap( ( { maxIndex } ) => { total = ( ( maxIndex - 2 ) /2 ) | 0; } )
				.flatMap( () => Rx.Observable.range( 0, total ) )
				.concatMap( index => Rx.Observable.defer( () =>
					this._callUntilSuccessAsync( cb => {
						this.tryGetMessages( 3 + index * 2, 4 + index * 2, cb );
					} )
					.flatMap(
						( { messages: {
							[index * 2 + 3]: listItem,
							[index * 2 + 4]: details
						} } ) => Rx.Observable.combineLatest(
							this.decodeAsync( listItem.value ),
							this.decodeAsync( details.value ),
							( listItemBuffer, detailsBuffer ) =>
								( { listItemBuffer, detailsBuffer, total } )
						)
					)
				) )
		);
	}

	getProfileDataAsync( ) {
		return (
			this._callUntilSuccessAsync( cb => {
				this.tryGetMessages( 2, 2, cb );
			} )
				.flatMap( ( { messages } ) => this.decodeAsync( messages[ 2 ].value, "base64" ) )
		);
	}

	getSeedDataAsync( ) {
		return (
			this._callUntilSuccessAsync( cb => {
				this.tryGetMessages( 1, 1, cb );
			} )
				.flatMap( ( { messages } ) => this.decodeAsync( messages[ 1 ].value ) )
		);
	}

	getMasterKeyDataAsync( ) {
		return (
			this._callUntilSuccessAsync( cb => {
				this.tryGetMessages( 0, 0, cb );
			} )
				.flatMap( ( { messages } ) => this.decodeAsync( messages[ 0 ].value ) )
		);
	}

	decodeAsync( base64 ) {
		let buffer = new Buffer( base64, "base64" );
		let macCode = buffer.slice( buffer.length - 32 );
		let encrypted = buffer.slice( 0, buffer.length - 32 );
		return Rx.Observable.fromPromise(
			ssgCrypto.makeHmacCodeThen( this._macKey, encrypted, this._econfig )
				.then( calculatedMacCode => {
					if ( !calculatedMacCode.equals( macCode ) ) {
						debugger;
						return Promise.resolve( new Error( "Mac code mismatch(1)" ) );
					}
					return ssgCrypto.decryptToNewBufferThen(
						encrypted, this._encryptionKey, true, this._econfig
					);
				} )
		);
	}
}

class BackupService {
	constructor( ) {
		this.initRepos();
		this.initQueue();
	}

	init( ) {
		if ( this._backupWriterSubj ) {
			return;
		}
		this._econfig = configuration.getDefaultEncryptionConfig();
		this._backupWriterSubj = new Rx.ReplaySubject( 1 );
		this._backupDataSubj = new Rx.ReplaySubject( 1 );

		this.initServices();
		this.readProfileData();
	}

	uninit( ) {
		if ( !this._backupWriterSubj ) {
			return;
		}
		this._backupWriterSubj.onNext( null );
		this._backupDataSubj.onNext( null );
		this._backupWriterSubj.onCompleted();
		this._backupDataSubj.onCompleted();
	}

	initServices( ) {
		this._profileService = profileServiceLocator();
		this._currentUserService = currentUserServiceLocator();
		this._contactService = contactServiceLocator();
		this._groupService = groupServiceLocator();
		this._sharedContactsService = sharedcontactsServiceLocator();
	}

	initRepos( ) {
		this._seedRepository = seedRepositoryLocator();
		this._profileRepository = profileRepositoryLocator();
		this._masterKeyRepository = masterKeyRepositoryLocator();
		this._contactRepository = contactRepositoryLocator();

		this._contactRepository.hookDataUpdate(
			this._onContactListUpdate.bind( this ),
			this._onContactDetailsUpdate.bind( this )
		);
		this._seedRepository.hookWrite( this._onSeedWrite.bind( this ) );
		this._profileRepository.hookWrite( this._onProfileWrite.bind( this ) );
		this._masterKeyRepository.hookWrite( this._onMasterKeyWrite.bind( this ) );
	}

	readProfileData( ) {
		this._profileService.getProfileAsync()
			.subscribe( profile => {
				if ( !profile ) {
					this._backupWriterSubj.onNext( null );
					this._backupDataSubj.onNext( null );
					return;
				}
				let {backup} = profile;
				this._backupDataSubj.onNext( backup );
				if ( backup ) {
					this._backupWriterSubj.onNext(
						new BackupWriter(
							configuration.getSocketBase(),
							backup.containerId,
							backup.publicKey,
							backup.macKey,
							backup.encryptionKey,
							this._econfig
						)
					);
				} else {
					this._backupWriterSubj.onNext( null );
				}
			} );
	}

	initQueue( ) {
		this._queue = new Queue( 1 );
	}

	enqueue( func ) {
		return this._queue.add( func );
	}

	enqueueUnlocked( func ) {
		return this._queue.add( () => {
			func();
		} );
	}

	_onContactListUpdate( buffer, index ) {
		return (
			this.enqueueUnlocked( () =>
				this._runTransactionWithWriterThen( ( transaction, writer ) => {
					writer.setContactListItem( buffer, index, transaction );
				} )
 			)
		);
	}

	_onContactDetailsUpdate( buffer, index ) {
		return (
			this.enqueueUnlocked( () =>
				this._runTransactionWithWriterThen( ( transaction, writer ) => {
					writer.setContactDetailsItem( buffer, index, transaction );
				} )
 			)
		);
	}

	_onSeedWrite( buffer ) {
		return (
			this.enqueueUnlocked( () =>
				this._runTransactionWithWriterThen( ( transaction, writer ) => {
					writer.setSeedItem( buffer, transaction );
				} )
			)
		);
	}

	_onProfileWrite( buffer ) {
		return (
			this.enqueueUnlocked( () =>
				this._runTransactionWithWriterThen( ( transaction, writer ) => {
					writer.setProfileItem( buffer, transaction );
				} )
			)
		);
	}

	_onMasterKeyWrite( buffer ) {
		return (
			this.enqueueUnlocked( () =>
				this._runTransactionWithWriterThen( ( transaction, writer ) => {
					writer.setMasterKeyItem( buffer, transaction );
				} )
			)
		);
	}

	_runTransactionWithWriterThen( func ) {
		return Transaction.runWithRetriesThen( transaction => {
			if ( !this._backupWriterSubj ) {
				return;
			}
			let waitSubj = new Rx.Subject();
			transaction.waitFor( waitSubj, "backup writer" );
			this._backupWriterSubj
				.take( 1 )
				.subscribe( writer => {
					if ( !writer ) {
						waitSubj.onCompleted();
						return;
					}
					func( transaction, writer );
					waitSubj.onCompleted();
				} );
		}, "nolock" );
	}

	cancelCreateBackup( ) {
		this._isCancelling = true;
	}

	//Returns token
	createBackupAsync( fakePassword, progressSubj ) {
		return Rx.Observable.fromPromise( this.createBackupThen( fakePassword, progressSubj ) );
	}

	createBackupThen( fakePassword, progressSubj ) {
		this._isCancelling = false;
		return this.enqueue( () => new Promise( ( resolve, reject ) => {
			let backupData, writer;
			this._backupDataSubj.take( 1 )
				.flatMap( existingBackup => {
					if ( existingBackup ) {
						return Rx.Observable.throw( new Error( "Backup already exist" ) );
					}
					return this._checkFakePasswordAsync( fakePassword )
				} )
				.flatMap( () => this._createBackupDataAsync() )
				.flatMap( bd => {
					if ( !bd ) {
						throw new Error( "Empty backup data", bd );
					}
					backupData = bd;
					return this._createBackupTokenAsync( backupData );
				} )
				.flatMap( t => {
					backupData.privateKey.dispose();
					delete backupData.privateKey;
					backupData.token = t;
					writer = this._createBackupWriter( backupData );
					return this._makeInitialBackupAsync( writer, backupData, progressSubj );
				} )
				.flatMap( () => this._updateProfileAsync( backupData, fakePassword ) )
				.subscribe(
					() => {
						this._backupDataSubj.onNext( backupData );
						this._backupWriterSubj.onNext( writer );
						progressSubj.onCompleted();
						resolve( backupData.token );
					},
					error => {
						console.error( "Error creating backup", error );
						try {
							if ( backupData ) {
								this._dropBackupData( backupData );
							}
						}
						finally {
							reject( error );
						}
					}
				);
		} ) );
	}

	_dropBackupData( { token } ) {
		if ( token ) {
			this._contactService.removeMessageAsync( token ).subscribe();
		}
	}

	_checkFakePasswordAsync( password ) {
		if ( password === undefined ) {
			return Rx.Observable.just();
		}
		return (
			this._currentUserService
				.tryGetMasterKey( password )
				.flatMap( data => {
					if ( !data ) {
						return Rx.Observable.throw( "EInvalidPassword" );
					}
					if ( data.positionBit === this._currentUserService.getCurrentPositionBit() ) {
						return Rx.Observable.throw( "ENotFakePassword" );
					}
					return Rx.Observable.just();
				} )
		);
	}

	_createBackupTokenAsync( { privateKey, macKey, encryptionKey, containerId } ) {
		return sendMessageAsync( {
			apiUrlBase: configuration.getSocketBase(),
			privateKey,
			macKey,
			encryptionKey,
			containerId,
			econfig: this._econfig,
			config: configuration.toJSON(),
			type: "backup"
		} );
	}

	_createBackupDataAsync( ) {
		return Rx.Observable.fromPromise(
			Promise.all( [
				ssgCrypto.createRandomKeyExchangeKeyPairThen( this._econfig ),
				ssgCrypto.createRandomKeyThen( KEY_KINDS.MAC, this._econfig ),
				ssgCrypto.createRandomKeyThen( KEY_KINDS.SYMMETRIC_ENCRYPTION, this._econfig ),
				ssgCrypto.createRandomBase64StringThen( configuration.getIdsLength() )
			] ).then( ( [ { privateKey, publicKey }, macKey, encryptionKey, containerId ] ) =>
					( { privateKey, publicKey, macKey, encryptionKey, containerId } )
				)
		);
	}

	_createBackupWriter( { containerId, publicKey, macKey, encryptionKey } ) {
		return new BackupWriter(
			configuration.getSocketBase(),
			containerId,
			publicKey,
			macKey,
			encryptionKey,
			this._econfig
		);
	}

	_updateProfileAsync( backup, fakePassword ) {
		if ( this._isCancelling ) {
			return Rx.Observable.throw( "Canceled" );
		}
		if ( fakePassword === undefined ) {
			return this._profileService.updateProfileAsync( { backup } );
		}
		return (
			this._profileService.updateProfileAsync( { backup } )
				.flatMap( () => this._currentUserService.logOutAsync() )
				.flatMap( () => this._currentUserService.logInAsync( fakePassword ) )
				.flatMap( () => this._profileService.updateProfileAsync( { backup } ) )
		);
	}

	_makeInitialBackupAsync( writer, backupData, progressSubj ) {
		let totalCount;
		return (
			this._contactRepository.getRawListAsync()
				.tap( list => { totalCount = list.length; } )
				.flatMap( list => Rx.Observable.fromArray(
					_.map( list, ( buffer, index ) => ( { buffer, index } ) )
				) )
				.concatMap( ( { buffer, index } ) => Rx.Observable.defer( () =>
					this._isCancelling
						? Rx.Observable.just( undefined, Rx.Scheduler.default )
						: this._contactRepository.getRawDetailsDataAsync( index )
							.map( details => ( { details, buffer, index } ) )
				) )
				.concatMap( data => !data
					? Rx.Observable.just( undefined )
					: Transaction.runWithRetriesAsync( transaction => {
						if ( this._isCancelling ) {
							console.warn( `Cancelling at contact #${data.index}` );
							return;
						}
						progressSubj && progressSubj.onNext( [ data.index, totalCount ] );
						writer.setContactListItem( data.buffer, data.index, transaction );
						writer.setContactDetailsItem( data.details, data.index, transaction );
					}, "nolock" )
				)
				.concat( Rx.Observable.defer( () =>
					Rx.Observable.combineLatest(
						this._profileRepository.getRawDataAsync(),
						this._seedRepository.getRawDataAsync(),
						this._masterKeyRepository.getRawDataAsync(),
						( profileBuffer, seedBuffer, mkBuffer ) =>
							( { profileBuffer, seedBuffer, mkBuffer } )
					)
						.flatMap( ( { profileBuffer, seedBuffer, mkBuffer } ) =>
							Transaction.runWithRetriesAsync( transaction => {
								if ( this._isCancelling ) {
									return;
								}
								writer.setProfileItem( profileBuffer, transaction );
								writer.setSeedItem( seedBuffer, transaction );
								writer.setMasterKeyItem( mkBuffer, transaction );
							}, "nolock" )
						)
				) )
				.toArray()
		);
	}

	dropBackupAsync( ) {
		return (
			this._backupDataSubj
				.filter( backup => typeof backup !== "string" )
				.take( 1 )
				.flatMap( existingBackup => {
					if ( !existingBackup ) {
						return Rx.Observable.throw( new Error( "Backup not exist" ) );
					}
					return this._profileService.updateProfileAsync( { backup: null } );
				} )
				.tap( () => {
					this._backupDataSubj.onNext( null );
					this._backupWriterSubj.onNext( null );
				} )
		);
	}

	restoreBackupAsync( token, progressSubj ) {
		let listItems = [];
		return (
			receiveMessageAsync( token )
				.flatMap( ( { apiUrlBase, privateKey, macKey, encryptionKey, containerId, econfig, type, config } ) => {
					if ( type !== "backup" ) {
						throw new Error( "Backup message expected" );
					}
					if ( !config ) {
						return Rx.Observable.just(
							new BackupReader( apiUrlBase, containerId, privateKey, macKey, encryptionKey, econfig )
						);
					}
					return (
						configServiceLocator()
							.setCurrentConfigAsync( config )
							.map( () =>
								new BackupReader( apiUrlBase, containerId, privateKey, macKey, encryptionKey, econfig )
							)
					);
				} )
				.flatMap( reader =>
					reader.readContactsData()
						.concatMap( ( { listItemBuffer, detailsBuffer, total } ) =>
							Rx.Observable.defer( () => {
								progressSubj && progressSubj.onNext( {
									index: listItems.length, total
								} );
								listItems.push( listItemBuffer );
								return this._contactRepository.setDetailsRawDataAsync(
									detailsBuffer, listItems.length - 1
								);
							} )
						)
						.concat( Rx.Observable.defer( () =>
							this._contactRepository.setRawListAsync( listItems )
						) )
						.concat( Rx.Observable.defer( () =>
							reader.getSeedDataAsync()
								.flatMap( seedBuffer =>
									this._seedRepository.setRawDataAsync( seedBuffer )
								)
						) )
						.concat( Rx.Observable.defer( () =>
							reader.getProfileDataAsync()
								.flatMap( profileBuffer =>
									this._profileRepository.setRawDataAsync( profileBuffer )
								)
						) )
						.concat( Rx.Observable.defer( () =>
							reader.getMasterKeyDataAsync()
								.flatMap( masterKeyBuffer =>
									this._masterKeyRepository.setRawDataAsync( masterKeyBuffer )
								)
						) )
					)
					.toArray()
		);
	}

	rejoinContactsAsync( password1, password2 ) {
		this.init();
		// return Rx.Observable.just();

		return this._currentUserService.resetSeedAsync( password1, password2 );

///
		if ( password2 === undefined ) {
			return (
				this._currentUserService.logInAsync( password1 )
					// .flatMap( () => this.rejoinLoggedInForAsync( this._sharedContactsService ) )
					.flatMap( () => this.rejoinLoggedInForAsync( this._contactService ) )
					.flatMap( () => this.rejoinLoggedInForAsync( this._groupService ) )
			);
		}
		return (
			this._currentUserService.logInAsync( password1 )
				// .flatMap( () => this.rejoinLoggedInForAsync( this._sharedContactsService ) )
				.flatMap( () => this.rejoinLoggedInForAsync( this._contactService ) )
				.flatMap( () => this.rejoinLoggedInForAsync( this._groupService ) )
				.flatMap( () => this._currentUserService.logOutAsync() )
				.flatMap( () => this._currentUserService.logInAsync( password2 ) )
				// .flatMap( () => this.rejoinLoggedInForAsync( this._sharedContactsService ) )
				.flatMap( () => this.rejoinLoggedInForAsync( this._contactService ) )
				.flatMap( () => this.rejoinLoggedInForAsync( this._groupService ) )
		);
	}

	rejoinLoggedInForAsync( service ) {
		return (
			service.observeContactsFullyLoad()
				.flatMap( contacts => Rx.Observable.fromArray( contacts ) )
				.filter( ( { status, multidescriptionId } ) =>
					( ( status === "active" ) || ( status === "invited" ) ) &&
					( ( multidescriptionId === -1 ) || ( multidescriptionId === undefined ) )
				)
				.concatMap( contact => Rx.Observable.defer( () => service.rejoinContactAsync( contact ) ) )
				.toArray()
				.map( () => true )
		);
	}

	observeBackup( ) {
		return this._backupDataSubj;
	}
}

export default BackupService;
