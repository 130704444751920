"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.keyExchanges = exports.kdfs = exports.paramConverters = exports.validators = exports.verifiers = exports.signers = exports.keySizes = exports.getsSignatureSize = exports.getsDecryptedSize = exports.getsEncryptedSize = exports.deciphers = exports.ciphers = undefined;

var _node = require("./ciphers/node.js");

var _node2 = _interopRequireDefault(_node);

var _node3 = require("./deciphers/node.js");

var _node4 = _interopRequireDefault(_node3);

var _sign = require("./signatures/schnorr/sign.js");

var _sign2 = _interopRequireDefault(_sign);

var _verify = require("./signatures/schnorr/verify.js");

var _verify2 = _interopRequireDefault(_verify);

var _sign3 = require("./signatures/ed25519/sign.js");

var _sign4 = _interopRequireDefault(_sign3);

var _verify3 = require("./signatures/ed25519/verify.js");

var _verify4 = _interopRequireDefault(_verify3);

var _schnorr = require("./group/schnorr.js");

var _schnorr2 = _interopRequireDefault(_schnorr);

var _scrypt = require("./kdfs/scrypt.js");

var _scrypt2 = _interopRequireDefault(_scrypt);

var _pbkdf = require("./kdfs/pbkdf2.js");

var _pbkdf2 = _interopRequireDefault(_pbkdf);

var _dh = require("./keyexchange/dh.js");

var _dh2 = _interopRequireDefault(_dh);

var _ed = require("./keyexchange/ed25519.js");

var _ed2 = _interopRequireDefault(_ed);

var _keyKind = require("./primitives/key.kind.js");

var _keyKind2 = _interopRequireDefault(_keyKind);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ciphers = exports.ciphers = Object.create(null, {
	"aes-256-cbc": {
		value: function value(key, usePadding) {
			return new _node2.default("aes-256-cbc", key, 16, true, usePadding);
		}
	}
});

var deciphers = exports.deciphers = Object.create(null, {
	"aes-256-cbc": {
		value: function value(key, usePadding) {
			return new _node4.default("aes-256-cbc", key, 16, true, usePadding);
		}
	}
});

var getsEncryptedSize = exports.getsEncryptedSize = Object.create(null, {
	"aes-256-cbc": {
		value: function value(plainSize, usePadding) {
			return _node2.default.getEncryptedSize(16, true, plainSize, usePadding);
		}
	}
});

var getsDecryptedSize = exports.getsDecryptedSize = Object.create(null, {
	"aes-256-cbc": {
		value: function value(encryptedSize) {
			return _node4.default.getDecryptedSize(16, true, encryptedSize);
		}
	}
});

var getsSignatureSize = exports.getsSignatureSize = Object.create(null, {
	"schnorr-4096-512": {
		value: function value(param) {
			if (!(param instanceof _schnorr2.default)) {
				param = new _schnorr2.default(param);
			}
			return param.getP().byteLength();
		}
	}
});

var keySizes = exports.keySizes = Object.create(null, {
	"aes-256-cbc": {
		value: 32
	},
	"schnorr-4096-512": {
		value: function value(kind, param) {
			switch (kind) {
				case _keyKind2.default.SIGNATURE_PRIVATE:
					return param.getQ().byteLength();
				case _keyKind2.default.SIGNATURE_PUBLIC:
					return param.getP().byteLength();
			}
		}
	},
	"dh": {
		value: function value(kind, param) {
			switch (kind) {
				case _keyKind2.default.KEY_EXCHANGE_PRIVATE:
					return param.getQ().byteLength();
				case _keyKind2.default.KEY_EXCHANGE_PUBLIC:
					return param.getP().byteLength();
			}
		}
	},
	"ed25519": {
		value: function value(kind) {
			switch (kind) {
				case _keyKind2.default.SIGNATURE_PRIVATE:
					return _sign4.default.getPrivateKeyByteLength();
				case _keyKind2.default.SIGNATURE_PUBLIC:
					return _verify4.default.getPublicKeyByteLength();
			}
		}
	},
	"dh25519": {
		value: function value(kind) {
			switch (kind) {
				case _keyKind2.default.KEY_EXCHANGE_PRIVATE:
					return _ed2.default.getPrivateKeyLength();
				case _keyKind2.default.KEY_EXCHANGE_PUBLIC:
					return _ed2.default.getPublicKeyLength();
			}
		}
	}
});

var signers = exports.signers = Object.create(null, {
	"schnorr-4096-512": {
		value: function value(paramGroup) {
			var privKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

			var signer = new _sign2.default(paramGroup);
			if (privKey === "later") {
				signer.postponePrivateKey();
			} else {
				privKey ? signer.setPrivateKey(privKey) : signer.setPrivateKeyRandom();
			}
			return signer;
		}
	},
	"ed25519": {
		value: function value(param, privKey) {
			var signer = new _sign4.default();
			if (privKey === "later") {
				signer.postponePrivateKey();
			} else {
				privKey ? signer.setPrivateKey(privKey) : signer.setPrivateKeyRandom();
			}
			return signer;
		}
	}
});

var verifiers = exports.verifiers = Object.create(null, {
	"schnorr-4096-512": {
		value: function value(paramGroup, pubKey) {
			var v = new _verify2.default(paramGroup);
			v.setPublicKey(pubKey);
			return v;
		}
	},
	"ed25519": {
		value: function value(param, pubKey) {
			var v = new _verify4.default(param);
			v.setPublicKey(pubKey);
			return v;
		}
	}
});

var validators = exports.validators = Object.create(null, {
	"schnorr-4096-512": {
		value: function value(paramGroup) {
			return paramGroup.verifyGroupThen();
		}
	}
});

var paramConverters = exports.paramConverters = Object.create(null, {
	"schnorr-4096-512": {
		value: function value(paramGroupJson) {
			return new _schnorr2.default(paramGroupJson);
		}
	}
});

var kdfs = exports.kdfs = Object.create(null, {
	"scrypt": {
		value: _scrypt2.default
	},
	"pbkdf2": {
		value: _pbkdf2.default
	}
});

var keyExchanges = exports.keyExchanges = Object.create(null, {
	"dh": {
		value: _dh2.default
	},
	"dh25519": {
		value: _ed2.default
	}
});