import React from "react";
import { Modal, Button } from "react-bootstrap";

import Translation from "../../../components/translation.jsx";

class ConfirmView extends React.Component {
	constructor() {
		super();
		this.onYes = this.onYes.bind( this );
		this.onNo = this.onNo.bind( this );
	}

	onYes() {
		this.props.onDone( true );
	}

	onNo() {
		this.props.onDone( false );
	}

	render() {
		return (
			<div className="static-modal">
				<Modal.Dialog>
					<Modal.Header>
						<Modal.Title>
							<Translation textId={this.props.titleTextId} />
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						{ this.props.contentTextId && <Translation textId={this.props.contentTextId} /> }
					</Modal.Body>

					<Modal.Footer>
						<Button onClick={ this.onYes }>
							<Translation textId={this.props.yesButtonTextId} />
						</Button>
						<Button bsStyle="primary" onClick={ this.onNo }>
							<Translation textId={this.props.noButtonTextId || "web.common.confirm.button.no" } />
						</Button>
					</Modal.Footer>
				</Modal.Dialog>
			</div>
		);
	}

	static get propTypes() {
		return {
			onDone: React.PropTypes.func.isRequired,
			titleTextId: React.PropTypes.string.isRequired,
			yesButtonTextId: React.PropTypes.string.isRequired,
			noButtonTextId: React.PropTypes.string,
			contentTextId: React.PropTypes.string
		};
	}
}

export default ConfirmView;
