import React from "react";

import Layout, {
	ListTitleContentView,
	MainTitleContentView,
	ListbarContentView,
	MainContentView,
	MobileMenuContentView,
	CustomContentView
} from "../common/layout.jsx";
import MobileMenu from "../common/mobile.menu.jsx";
import ListTitle from "./list.title.jsx";
import SettingsListBar from "./list.bar.jsx";
import AccountTitle from "./account.title.jsx";
import AccountContent from "./account.content.jsx";
import SecurityTitle from "./security.title.jsx";
import SecurityContent from "./security.content.jsx";
import ApplicationTitle from "./application.title.jsx";
import ApplicationContent from "./application.content.jsx";
import InfoTitle from "./info.title.jsx";
import InfoContent from "./info.content.jsx";
import UsersTitle from "./users.title.jsx";
import UsersContent from "./users.content.jsx";
import NotificationsTitle from "./notifications.title.jsx";
import NotificationsContent from "./notifications.content.jsx";
import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class SettingsView extends React.Component {
	constructor() {
		super();
		this.state = {
			activeView: "account",
			activeMobileView: "list",
			isAdmin: false
		};
		this.onChangeActiveView = this.onChangeActiveView.bind( this );
		this.onGoToList = this.onGoToList.bind( this );
		this._service = remoteServiceLocator();
	}

	componentWillMount() {
		this._subscription = (
			this._service.observeGlobalUserType()
				.subscribe( ( { isAdmin } ) => {
					this.setState( { isAdmin } );
				} )
		);
	}

	componentWillUnmount() {
		this._subscription.dispose();
	}

	onGoToList() {
		this.setState( {
			activeMobileView: "list"
		} );
	}


	renderMainTitle() {
		switch( this.state.activeView ) {
			case "account":
				return <AccountTitle onGoToList={this.onGoToList}/>;
			case "security":
				return <SecurityTitle onGoToList={this.onGoToList}/>;
			case "application":
				return <ApplicationTitle onGoToList={this.onGoToList}/>;
			case "info":
				return <InfoTitle onGoToList={this.onGoToList}/>;
			case "notifications":
				return <NotificationsTitle onGoToList={this.onGoToList}/>;
			case "users":
				return <UsersTitle onGoToList={this.onGoToList}/>;
		}
		return null;
	}

	renderContent() {
		switch( this.state.activeView ) {
			case "account":
				return <AccountContent />;
			case "security":
				return <SecurityContent />;
			case "application":
				return <ApplicationContent />;
			case "info":
				return <InfoContent />;
			case "notifications":
				return <NotificationsContent />;
			case "users":
				if ( !this.state.isAdmin ) {
					return <div />;
				}
				return <UsersContent />;
		}
		return null;
	}

	renderPopup() {
		if ( this.state.popup ) {
			return this.state.popup();
		}
		return null;
	}

	onChangeActiveView( activeView ) {
		this.setState( {
			activeView,
			activeMobileView: "main"
		} );
	}

	render() {
		return (
			<Layout
				activeMobileView={ this.state.activeMobileView }
				mainTitleTextId={ `web.settings.${this.state.activeView}.main.title` }
				listTitleTextId="web.settings.list.title"
				>
				<ListTitleContentView>
					<ListTitle />
				</ListTitleContentView>
				<MainTitleContentView>
					{this.renderMainTitle()}
				</MainTitleContentView>
				<MobileMenuContentView>
					<MobileMenu active="settings"/>
				</MobileMenuContentView>
				<ListbarContentView>
					<SettingsListBar
						activeView={this.state.activeView}
						onChangeActiveView={this.onChangeActiveView}
						isAdmin={this.state.isAdmin}
					/>
				</ListbarContentView>
				<MainContentView>
					{this.renderContent()}
				</MainContentView>
				<CustomContentView>
					{this.renderPopup()}
				</CustomContentView>
			</Layout>
		);
	}

}

export default SettingsView;
