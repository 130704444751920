import React from "react";
import _ from "lodash";

import ChatCommon from "./chat.common.jsx";
import ChatGroupMenu from "./chat.group.menu.jsx";
import Notifications from "../common/notifications.jsx";
import Translation from "../../components/translation.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

class ChatGroup extends ChatCommon {
	constructor() {
		super();
		this._service = serviceLocator();
	}

	componentWillMount() {
		this._messagesObservable = this._service.observeMessages( this.props.contact );
		this._participantsSubscription = (
			this._service
				.observeGroupInvites( this.props.contact )
				.subscribe( invites => {
					this.setState( { invites } );
				} )
		);

		this._participantsSubscription = (
			this._service
				.observeGroupParticipants( this.props.contact )
				.subscribe( participants => {
					this.setState( { participants } );
				} )
		);
		this.setMessagesRead();
		super.componentWillMount();
	}

	componentWillUnmount() {
		this._participantsSubscription.dispose();
		if ( this.notificationFactory ) {
			Notifications.unregisterNotification( this.notificationFactory );
			delete this.notificationFactory;
		}
		this.setMessagesRead();
		super.componentWillUnmount();
	}

	componentDidUpdate( prevProps, prevState ) {
		let hasInvites = !_.isEmpty( this.state.invites );
		if ( this.state.newMessage && hasInvites && !this.notificationFactory ) {
			this.notificationFactory = () => <InvitesNotification invites={this.state.invites} />;
			Notifications.registerNotification( this.notificationFactory, "48px" );
		}
		if ( ( !this.state.newMessage || !hasInvites ) && this.notificationFactory ) {
			Notifications.unregisterNotification( this.notificationFactory );
			delete this.notificationFactory;
		}
		super.componentDidUpdate( prevProps, prevState );
	}

	renderMenu() {
		return <ChatGroupMenu contact={ this.props.contact } />;
	}

	onMessageReceived( message ) {
		//It is already in history
		// groupServiceLocator().putMessageToHistory( message, this.props.contact.id );
		super.onMessageReceived( message );
	}

	renderFooter() {
		let { participants } = this.state;
		if ( !participants || _.size( participants ) > 1 ) {
			return super.renderFooter();
		}
		return (
			<div className="keyboard">
				<div className="bg-b05 recording">
					<div style={ { fontSize: "16px", padding: "20px" } }>
						<Translation textId="chat.group.empty" />
					</div>
				</div>
			</div>
		);
	}
}

class InvitesNotification extends React.Component {
	render() {
		return (
			<div style={{
					width: "100%",
					height: "100%",
					background: "#ccc",
					color: "#000",
					padding: "4px 12px",
					border: "#fff solid 2px",
					borderRadius: "10px",
					overflow: "auto"
				}}>
				<Translation textId="chat.group.invites.notification" />
				{ _.map( this.props.invites, ( { nickname } ) => nickname ).join( ", " ) }
			</div>
		);
	}
}

export default ChatGroup;
