import Rx from "rx";
import Queue from "promise-queue";

import {
	serializeObjectWithKeysToNewManagedBufferThen,
	deserializeObjectWithKeysFromManagedBufferThen,
	deserializeObject,
	deserializeJSONOnlyFromManagedBuffer
} from "../../common/serializer.js";

import ClientServerEncrypted from "./client.server.encrypted.js";

class ClientServerJSON extends ClientServerEncrypted {
	constructor( apiUrlBase, connectionId, sendFrom, fromIndex, signer, econfig ) {
		super( apiUrlBase, connectionId, sendFrom, fromIndex, signer, econfig );
		this.onDecryptedMessage( this._onManagedBufferMessage.bind( this ) );

		this._deserializationQueue = new Queue( 1 );
	}

	onJsonMessage( func ) {
		this._onJsonMessage = func;
	}

	send( json, transaction, optionalKey2KindMap ) {
		let waitSubjs = new Rx.Subject();
		transaction.waitFor( waitSubjs, "serializing json" );
		let connectionUseHandle = this.incUse();
		let index = this.getSendIndex();

		serializeObjectWithKeysToNewManagedBufferThen( json, optionalKey2KindMap || {} )
			.then( managedBuffer =>
				this.sendAndDisposeThen( managedBuffer, transaction, index )
			)
				.then( () => {
					waitSubjs.onCompleted();
					this.decUse( connectionUseHandle );
				} );
	}

	_onManagedBufferMessage( from, managedBuffer, index ) {
		if ( !this._onJsonMessage ) {
			throw new Error( "No onJsonMessage handler" );
		}
		if ( !managedBuffer ) {
			return this._onJsonMessage( from, managedBuffer, index ) || Rx.Observable.empty();
		}
		let json = deserializeJSONOnlyFromManagedBuffer( managedBuffer );
		let keyMap = this._getKeyMapForMessage( json );
		let processSubj = new Rx.Subject();

		this._deserializationQueue.add( () =>
			deserializeObjectWithKeysFromManagedBufferThen( managedBuffer, keyMap, this._econfig )
				.then( obj => {
					managedBuffer.dispose();
					let res = this._onJsonMessage( from, obj, index );

					if ( res && res.subscribe ) {
						res.subscribe(
							() => {},
							error => { processSubj.onError( reject ); },
							() => { processSubj.onCompleted(); }
						);
						return;
					}
					processSubj.onCompleted()
				} )
		);
		return processSubj;
	}

	_getKeyMapForMessage( json ) {
		return {};
	}

	dispose( ) {
		super.dispose();
	}
}

export default ClientServerJSON;
