import Rx from "rx";
import React from "react";
import ssgCrypto from "ssg.crypto";

import TopLevel from "../common/top.level.view.jsx";
import Translation from "../../components/translation.jsx";

import * as utils from "../../../common/utils.js";
import history from "../../components/history.js";

import configuration from "../../../common/configuration.js";
import serviceLocator from "../../../api/services/locators/worker.client.js";

class ShakeView extends React.Component {
	constructor() {
		super();
		this.state = {
			line1: "4127489394",
			line2: "8732462519",
			progress: 0,
			leftResseds: configuration
		};
		this._remakeCode();
		this._service = serviceLocator();
	}

	componentWillMount() {
		let reg = this.props.registrationData;
		let writeResult = this._service.writeRandomSeedAsync( reg.password1, reg.password2 || null ).shareReplay();

		Rx.Observable.interval( 200 )
			.map( i => i / 10 )
			.takeUntil( writeResult )
			.subscribe( progress => {
				this.setState( { progress } );
			} );

		Rx.Observable.interval( 500 )
			.takeUntil( writeResult )
			.subscribe( () => {
				this._remakeCode();
			} );

		writeResult.subscribe( () => {
			this.props.onDone();
		} );
	}

	_onReseed() {
		if ( this.state.leftResseds <= 0 ) {
			return;
		}
		this._remakeCode();
	}

	_remakeCode() {
		Promise.all( [
				ssgCrypto.getRandomIntInRangeThen( 0, 100000 ),
				ssgCrypto.getRandomIntInRangeThen( 0, 100000 ),
				ssgCrypto.getRandomIntInRangeThen( 0, 100000 ),
				ssgCrypto.getRandomIntInRangeThen( 0, 100000 )
			] ).then( ( [ ...args ] ) => args.map( u => ( u + 100000 ).toString().substr( -5 ) ) )
			.then(
				lines =>
					this.setState( {
						line1: lines[ 0 ] + lines[ 1 ],
						line2: lines[ 2 ] + lines[ 3 ],
						leftResseds: this.state.leftResseds - 1
					} )
			);
	}

	componentWillUnmount() {
	}

	render() {
		let displayProgress = Math.max( 0, Math.min( 1, this.state.progress ) );
		return (
			<TopLevel id="screen-generator">
				<header>
					<Translation textId="shake.header" />
				</header>
				<main style={ { overflow: "hidden" } }>
					<div className="code-block-center-div-1">
						<div className="code-block-center-div-2">
							<div className="code-block shake">
								<div className="bg-digits">
									88888888888888<br />
									88888888888888<br />
									88888888888888<br />
									88888888888888
								</div>
								<div className="code f-left padded">
									<div style={{ width: 0, height: 0 }}>
										{ this.state.line1 }<br />
										{ this.state.line2 }
									</div>
									<div style={ { overflow: "hidden", width: `${displayProgress * 100}%`, color: "#45bd10" } }>
										{ this.state.line1 }<br />
										{ this.state.line2 }
									</div>

								</div>
							</div>
						</div>
					</div>
					<div style={{ clear: "both" }} />
					<div className="information small-text">
						<Translation textId="shake.text" />
					</div>
					<img
						className="info-image"
						src="images/img-generator-info.png"
					/>
				</main>
			</TopLevel>
		);
	}
}

export default ShakeView;
