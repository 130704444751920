import Rx from "rx";
import _ from "lodash";
import EncryptedRepository from "./encrypted.repository.js";
import ssgCrypto, {KEY_KINDS} from "ssg.crypto";

import configuration from "../../common/configuration.js";

let defaultFileSize = 16384;

export const profileKeyMap = {
	"backup.publicKey": KEY_KINDS.KEY_EXCHANGE_PUBLIC,
	"backup.macKey": KEY_KINDS.MAC,
	"backup.encryptionKey": KEY_KINDS.SYMMETRIC_ENCRYPTION,
	"activation.dhPubKey": KEY_KINDS.KEY_EXCHANGE_PUBLIC,
	"activation.encryptionSaltKey": KEY_KINDS.INTERMEDIATE,
	"activation.macSaltKey": KEY_KINDS.INTERMEDIATE
};

class ProfileRepository extends EncryptedRepository {
	constructor( StorageType ) {
		super( StorageType, "profile.dat" );
		this._profile = null;
	}

	initAsync( encryptionKey, positionBit /*0 or 1*/ ) {
		if ( this._encryptionKey ) {
			return Rx.Observable.throw( new Error( "Already initialized" ) );
		}
		this._positionBit = positionBit;
		this._encryptionKey = encryptionKey;
		return (
			this.lockStorageAsync(
				storage =>
					this._convertIfNeeded( storage )
						.flatMap( () => this._readProfileAsync( storage ) )
			).tap( profile => {
				this._readyCb();
			} )
		);
	}

	_getPosition( ) {
		return this._positionBit * this._actualFileSize / 2;
	}

	_convertIfNeeded( storage ) {
		return Rx.Observable.fromPromise(
			storage.readAsBufferThen()
				.then( buffer => {
					if ( buffer.length >= defaultFileSize ) {
						this._actualFileSize = buffer.length;
						return Promise.resolve( null );
					}
					return (
						ssgCrypto.createRandomBufferThen( defaultFileSize - buffer.length )
							.then( randomBuffer =>
								storage.replaceThen( Buffer.concat( [
									buffer.slice( 0, buffer.length / 2 ),
									randomBuffer.slice( 0, randomBuffer.length / 2 ),
									buffer.slice( buffer.length / 2 ),
									randomBuffer.slice( randomBuffer.length / 2 )
								] ) )
							)
							.then( () => this._actualFileSize = defaultFileSize )
						);
				} )
		);
	}

	updateProfileAsync( json ) {
		let plainRecordSize = ssgCrypto.getDecryptedSize(
			this._actualFileSize / 2,
			configuration.getDefaultEncryptionConfig()
		);
		return (
			Rx.Observable.fromPromise( this._readyThen )
				.flatMap( () =>
					this.lockStorageAsync( storage => {
						delete this._profile;
						return (
							this._readProfileAsync( storage )
								.map( oldProfile => _.extend( {}, oldProfile, json ) )
								.flatMap( newProfile => this._jsonObjectToBufferAsync(
									newProfile, plainRecordSize, profileKeyMap
								) )
								.flatMap( plainBuffer => Rx.Observable.fromPromise(
									this._encryptThen( plainBuffer ) )
								)
								.flatMap( encryptedBuffer => {
									return storage.writeAtPositionAsync( encryptedBuffer, this._getPosition( ) )
								} )
						);
					} )
				)
		);
	}

	_readProfileAsync( storage ) {
		return (
			storage.readAsBufferAsync()
				.map( buffer =>
					this._positionBit
					? buffer.slice( buffer.length / 2 )
					: buffer.slice( 0, buffer.length / 2 )
				)
				.flatMap( encryptedBuffer => this._decryptAsync( encryptedBuffer ) )
				.flatMap( plainBuffer => this._bufferToJsonObjectAsync( plainBuffer, profileKeyMap )
					.catch( error => console.warn( "Error reading profile", error ) || Rx.Observable.just( {} ) )
					.tap(  () => plainBuffer.dispose() )
				)
		);
	}

	getProfileAsync( ) {
		if ( this._profile ) {
			return Rx.Observable.just( this._profile );
		}
		return (
			Rx.Observable.fromPromise( this._readyThen )
				.flatMap( () =>
					this.lockStorageAsync( storage => this._readProfileAsync( storage ) )
				)
				.tap( p => { this._profile = p; } )
		);
	}

	uninitAsync( ) {
		return Rx.Observable.defer( () => {
			delete this._profile;
			return Rx.Observable.just();
		} ).flatMap( () => {
			let res = super.uninitAsync();
			return res || Rx.Observable.just();
		} );
	}
}

export default ProfileRepository;
