"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _crypto = require("crypto");

var _crypto2 = _interopRequireDefault(_crypto);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var HmacSha256 = function () {
	function HmacSha256(key) {
		_classCallCheck(this, HmacSha256);

		this._hmacThen = new Promise(function (resolve) {
			key.postponeManagedBuffer(function (mb) {
				mb.useAsBuffer(function (kb) {
					resolve(_crypto2.default.createHmac("sha256", kb));
				});
			});
		});
	}

	_createClass(HmacSha256, [{
		key: "makeCodeThen",
		value: function makeCodeThen(buffer) {
			return this._hmacThen.then(function (hmac) {
				hmac.update(buffer);
				return hmac.digest();
			});
		}
	}, {
		key: "verifyCodeThen",
		value: function verifyCodeThen(buffer, code) {
			return this._hmacThen.map(function (hmac) {
				hmac.update(buffer);
				return hmac.digest().equals(code);
			});
		}
	}, {
		key: "dispose",
		value: function dispose() {
			delete this._hmacThen;
		}
	}], [{
		key: "codeSize",
		get: function get() {
			return 32;
		}
	}]);

	return HmacSha256;
}();

exports.default = HmacSha256;