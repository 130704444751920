import ssgCrypto from "ssg.crypto";

import base64 from "../common/utils/base64.js";
import base32 from "../common/utils/base32.js";
//import dataUriLoader from "./crypto/data.uri.loader.js";

ssgCrypto.enableFakeSource();
let host = process.env.HOST || "http://kryptos.local:1338";
console.warn("utest config included");

let config = {
	apiBase: host,
	socketBase: host,
	defaultTokenMode: 1,
	identifierByteSize: 32,
	updateSignatures: [
		{
			name: "adm",
			title: "Administrator signature",
			pubKey: base64.decode( "hKK1rJCzBRHisP0TMCKvgAXLe9fK_nyHGIV0t28It9pqYJ7O_NouLPb0DoqIjWdPYfahOJBa8mE0sH_AdgDpvZr9GGsfbbH3IHvAo-ifAPA2WHMVKTkvAvBj4PoTV1OAFp57bi66rUOMfFf94fLl8BzWVwWPsm0SLyrA_JENFViaorIdn_DWtnHWaUkW2hgOFEEn83p8NK1Wb4OqQ5B1d_9dOWvBYuGcO3dZFEqVg4r-ukTYG3XKekMhEazfEmenKA1cT2D8yQr2EqOGdbtvBL9t6wFQjOfwqF_OHhJeZLVD9R66J_f71xzXMc87aQgbHBpYSaKGxeiH_oTnsNKk1R9QIKQaEkgEBaIkAKjh5T_ZDmCSD6-nkGvHzpiJ0S-r77doJQW838i9brZShYPMBECeAhAdt1NwOHMWmZXHPYHYSvHReVwuKKn2hv9tiK7zRo5M2rIaQ5FtAkAag4pzf3VA-iUhRVsCc3WWRNE9V0_sFrcV6Y26h105f6H1yMS6RdQ-AOokC33r6hg1pMPaLig9wGCfASsUomQzhssnyMerk-2yobQaep23AnY34gzYzqZTmstEM7wwFBhvGKJqtBnj7KkZ2LVL1AtmnLayWJhMMtGvzFS0nSOEyc3t0po1X3pt5kJ14ooFYg5qp7wIU6T6d7CzJLVkkKXEiJmY8sc" )
		},
		{
			name: "dev",
			title: "Developer signature",
			pubKey: base64.decode( "auXtugW1XbEUGCsOVTFH4iHNb1eCyP_XJD46hhAFBd3kOaYC0FuYobvrJWhLH_1t99HuBz62D9Uq5f0fkQR5kinvCSytW0d2TyIYPvlGozfcMHV4ddvzFV85mt0CXSkDLAB68IfKTyjCuFe5KSodpnqY5SJFutwVDTO5jKazctCqMv-ErWKRbPCY_hLnP-EcDpFGi2FYhVpf0ebAHbBZyi1jn54Th7OrTSpdZPC3l4GIZg239AC7MXFGuo2CDEULrWuHV4-AIA9TA-_GhZTmFO3Cmo3HE4N_BJ6SUceEmvPho9GBqZZOkXnQFWiPulIKwu1E2RX0IbB3Vwt3rzJHB6cXJkUfYCpA7IwjAdntZvpep0bACCRNkT6b1jsOWT10hSLBIYRv2eZeyDP5CZvn2d1bw-ME3_mq8htP1A_cLSvBg82h9xuutgFlCDzZg5mG1a2mauqAd9wjIaKhPlciK-6Q9tuqTNWgL6ov3o4ECXX522bHW1x7rCGYBmntMLBNT4sQLTyWCG4zD8qfrczYIAAvQLC0DaPgGQL8x715WXLSStvLkoM9X0SPv-z0Dmsl7zG7RGU84-rsjFQ2kU0DAiK3rcr3BD6e367dOs64FpXQ_cErUIjpQigb7WjHtWmtGJxj_uMohjZI2-DQWVZV9go7ALCPOOa3Ft0rD-NLBM8" )
		}
	],
	pushNotifications: {
		"android": {
			senderID: "979900970342"
		},
		ios: {
			senderID: "979900970342",
			gcmSandbox: "true",
			alert: "true",
			badge: true,
			sound: "true"
		}
	},
	appId: "pgm",
	updateSignatures: [],
	defaultEncryptionConfig: {
		cipherAlgorithm: "aes-256-cbc",
		signatureAlgorithm: "schnorr-4096-512",
		kdfPassword: "scrypt",
		kdfPasswordParam: {
			N: 3, //1<<3=8
			r: 8
		},
		kdfKeyed: "pbkdf2",
		kdfKeyedParam: {
			c: 12,
			klass: "HMAC_SHA256"
		},
		signatureParam: {
			g: "49629722ba89dfd440fc13df811c7a7c9598e7b193eb9c242e7a11870e09703d",
			p: "590dcea43751a1837947e18d8ccc80d2a5c0e2a21905e5304d1cd1cf66714ddd",
			q: "dd097db5"
		},
		keyExchange: "dh",
		keyExchangeParam: {
			g: "49629722ba89dfd440fc13df811c7a7c9598e7b193eb9c242e7a11870e09703d",
			p: "590dcea43751a1837947e18d8ccc80d2a5c0e2a21905e5304d1cd1cf66714ddd",
			q: "dd097db5"
		},
		hmacAlgorithm: "hmac-sha-256"
	},
	contactRecordCount: 100,
	transactionRetryDelay: 10,
	networkTimeout: 200
};
export default config;
