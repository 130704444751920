import React from "react";
import Translation from "./translation.jsx";
import _ from "lodash";
import classNames from "classnames";

import Button from "./button.jsx";


let Arrow = () => (
	<svg className="arrow" preserveAspectRatio="xMidYMid" width="12" height="20" viewBox="0 0 12 20">
		<path d="M1.911,20.009 L0.009,18.178 L8.620,9.999 L0.009,1.821 L1.911,-0.010 L11.997,10.000 L1.911,20.009 Z"/>
	</svg>
);

class ArrowButton extends React.Component {
	render() {
		return (
			<Button {...this.props}>
				<Arrow/>
			</Button>
		);
	}
}

export default ArrowButton;
