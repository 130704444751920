const openedViews = [];

export function openExternalView() {
  const viewId = {};
  openedViews.push(viewId);
  console.log('Opening external view');
  return viewId;
};

export function closeExternalView(viewId) {
  const index = openedViews.indexOf(viewId);
  if (index === -1) {
    throw new Error('Extenral view not found');
  }
  console.log('Closing external view');
  openedViews.splice(index, 1);
};

export function isOpenedExternalView() {
  console.log('External view is opened', openedViews.length > 0);
  return openedViews.length > 0;
}
