import React from "react";
import Swipeable from "./swipable.jsx";
import ReactDOM from "react-dom";

function validateChildrenCount( childrenCount ) {
	return ( props, propName, componentName ) => {
		let children = props[ propName ];
		if ( React.Children.count( children ) !== childrenCount ) {
			return new Error( `${componentName} should have exactly ${childrenCount} children.` );
		}
	};
}

export default class HorizontalSwipable extends React.Component {
	constructor() {
		super();
		this.state = {
			isSelected: false
		};

		let mouseDownListener = (ev) => {
			if (-1 == [].slice.call(ReactDOM.findDOMNode(this).querySelectorAll('*')).indexOf(ev.target)) {
				this.deselect();
			}
		}

		this.select = () => {
			this.setState( { isSelected: true } );
			this._rootNode.addEventListener( "mousedown", mouseDownListener, true);
		}
		this.deselect = () => {
			this.setState( { isSelected: false } )
			this._rootNode.removeEventListener( "mousedown", mouseDownListener, true);
		}
	}

	componentDidMount() {
		let el = ReactDOM.findDOMNode( this );
		while ( el && !~el.className.indexOf( "view-root" ) ) el = el.parentNode;
		this._rootNode = el || document;
	}

	componentWillUnmount() {
		if ( this.state.isSelected ) {
			this.deselect();
		}
	}

	static get propTypes() {
		return {
			children: validateChildrenCount( 2 )
		};
	}

	render() {
		let { children } = this.props;
		let [ defaultState, selectedState ] = React.Children.toArray( children );
		return (
			<Swipeable onSwipedRight={this.deselect} onSwipedLeft={this.select}>
				{this.state.isSelected ? selectedState : defaultState}
			</Swipeable>
		);
	}
}
