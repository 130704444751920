import Rx from "rx";
import Message from "./message.js";
import MessageSerializer from "./message.serializer.js";
import ssgCrypto from "ssg.crypto";
import keyHelper from "./key.helper.js";
import BinaryStream from "../binary.stream.js";

class MessagePack {
	constructor( chunkSize ) {
		if ( chunkSize !== ( chunkSize | 0 ) ) {
			throw new Error();
		}
		this._parts = [];
		this._chunkSize = chunkSize;
	}

	addPart( params ) {
		if ( !params.binaryStream || !( params.binaryStream instanceof BinaryStream ) ) {
			throw new Error( "binaryStream required" );
		}
		if ( !( "position" in params ) ) {
			throw new Error( "no message position" );
		}

		if ( params.binaryStream.size % this._chunkSize ) {
			throw new Error( "Invalid data length");
		}
		this._parts.push( params );
	}

	createBinaryStreamAsync( ) {
		return Rx.Observable.fromPromise( this.createBinaryStreamThen() );
	}

	createBinaryStreamThen( ) {
		if ( !this.isValid() ) {
			throw new Error( "Invalid message" );
		}

		if ( this._isCreatedBinaryStream ) {
			throw new Error( "BinaryStream already created" );
		}
		this._isCreatedBinaryStream = true;

		let bs0 = this._parts[ 0 ].binaryStream;
		let bs1 = this._parts[ 1 ];
		bs1 && ( bs1 = bs1.binaryStream );

		let l0 = bs0.size;
		let l1Then;

		let bs1Then;
		if ( bs1 ) {
			l1Then = Promise.resolve( bs1.size );
			bs1Then = Promise.resolve( bs1 );
		} else {
			l1Then = ssgCrypto.getRandomIntInRangeThen(
				( l0 / this._chunkSize + 1 ) >> 1,
				( ( l0 << 1 ) / this._chunkSize ) | 0
			).then( res => res * this._chunkSize );

			bs1Then = l1Then.then( l1 => BinaryStream.pseudoRandom( l1 ) );
		}

		let l2Then = l1Then.then( l1 =>
				ssgCrypto.getRandomIntInRangeThen(
					0,
					1 + ( ( ( l0 + l1 ) / ( 8 * this._chunkSize ) ) | 0 )
				)
			)
			.then( res => res * this._chunkSize );


		let sideEffectCount = 0;
		return Promise.all( [
			l1Then,
			l2Then,
			bs1Then
		] ).then( ( [ l1, l2, bs1 ] ) => {
			if ( sideEffectCount ) {
				throw new Error( "Duplicate subscription to toBinaryStreamThen" );
			}
			sideEffectCount++;
			let bs2 = BinaryStream.pseudoRandom( l2 );

			if ( ( bs0.size % this._chunkSize !== 0 )
				|| ( bs1.size % this._chunkSize !== 0 )
				|| ( bs2.size % this._chunkSize !== 0 ) ) {
				throw new Error( `Invalid sizes to make zebra: ${bs0.size}/${bs1.size}/${bs2.size}` );
			}

			if ( this._parts[ 0 ].position !== 0 ) {
				let t = bs0; //first message data must have b === 0
				bs0 = bs1;
				bs1 = t;
			}
			this._bs0 = bs0;
			this._bs1 = bs1;
			this._bs2 = bs2;
			this._i0 = 0;
			this._i1 = 0;
			this._i2 = 0;

			return this._outStream = BinaryStream.fromChunked(
				( chunkCount, cb ) => {
					this._zebraItCb(
						chunkCount,
						cb
					);
				},
				this._chunkSize,
				( l0 + l1 + l2 ) / this._chunkSize
			);
		} );
	}

	getErrors( ) {
		return this._validate();
	}

	isValid( ) {
		return this._validate().length === 0;
	}

	_getIngredientChunkIndexes( i, c0, c1, c2 ) {
		let i0, i1, i2;
		if ( c0 > c1 + c2 ) {
			if ( i < c1 * 2 ) {
				i0 = ( ( i + 1 ) / 2 ) | 0;
				i1 = ( i / 2 ) | 0;
				i2 = 0;
			} else if ( i < ( c1 + c2 ) * 2 ) {
				i0 = ( ( i + 1 ) / 2 ) | 0;
				i1 = c1;
				i2 = ( i / 2 - c1 ) | 0;
			} else {
				i0 = i - c1 - c2;
				i1 = c1;
				i2 = c2;
			}
		} else if ( c1 > c0 + c2 ) {
			if ( i < c0 * 2 ) {
				i0 = ( ( i + 1 ) / 2 ) | 0;
				i1 = ( i / 2 ) | 0;
				i2 = 0;
			} else if ( i < ( c0 + c2 ) * 2 ) {
				i0 = c0;
				i1 = ( i / 2 ) | 0;;
				i2 = ( ( i + 1 ) / 2 - c0 ) | 0;
			} else {
				i0 = c0;
				i1 = i - c0 - c2;
				i2 = c2;
			}
		} else {
			if ( i < Math.min( c0, c1 ) * 2 ) {
				i0 = ( ( i + 1 ) / 2 ) | 0;
				i1 = ( i / 2 ) | 0;
				i2 = 0;
			} else if ( i < Math.max( c0, c1 ) * 2 ) {
				if ( c0 > c1 ) {
					i0 = ( ( i + 1 ) / 2 ) | 0;
					i1 = c1;
					i2 = ( i / 2 - c1 ) | 0;
				} else {
					i0 = c0;
					i1 = ( i / 2 ) | 0;
					i2 = ( ( i + 1 ) / 2 - c0 ) | 0;
				}
			} else {
				i0 = c0;
				i1 = c1;
				i2 = i - c0 - c1;
			}
		}
		return { i0, i1, i2 };
	}

	_zebraItCb( chunkCount, cb ) {
		let start = this._outStream.position;
		let end = start + chunkCount * this._chunkSize;
		let bs0 = this._bs0;
		let bs1 = this._bs1;
		let bs2 = this._bs2;

		let c0 = bs0.size / this._chunkSize;
		let c1 = bs1.size / this._chunkSize;
		let c2 = bs2.size / this._chunkSize;
		let chunkStart = ( start / this._chunkSize ) | 0;
		let chunkEnd = chunkStart + chunkCount;

		let { i0: endi0, i1: endi1, i2: endi2 } =
			this._getIngredientChunkIndexes( chunkEnd, c0, c1, c2 );

		let buffer = new Buffer( chunkCount * this._chunkSize ).fill( 0 );

		bs0.getNextBytesCb( ( endi0 - this._i0 ) * this._chunkSize, d0 =>
			bs1.getNextBytesCb( ( endi1 - this._i1 ) * this._chunkSize, d1 =>
				bs2.getNextBytesCb( ( endi2 - this._i2 ) * this._chunkSize, d2 => {
					let resultBuffer = this._zebraIt(
						d0,
						d1,
						d2,
						c0,
						c1,
						c2,
						chunkStart,
						chunkEnd
					);
					if ( resultBuffer.length !== ( chunkEnd - chunkStart ) * this._chunkSize ) {
						throw new Error( `_zebraIt check error` );
					}
					cb( resultBuffer );
				} )
			)
		);
	}

	_zebraIt( d0, d1, d2, c0, c1, c2, chunkStart, chunkEnd ) {
		let buffer = new Buffer( d0.length + d1.length + d2.length );
		buffer.fill( 0 );
		let starti0 = this._i0;
		let starti1 = this._i1;
		let starti2 = this._i2;
		let cAll = c0 + c1 + c2;

		for ( let i = chunkStart; i < chunkEnd; i++ ) {
			if ( c0 - this._i0 === cAll - i ) { // left d0 chunks is exactly how many left at all. Fill next chunks with d0's chunks only
				d0.copy(
					buffer,
					( i - chunkStart ) * this._chunkSize,
					( this._i0 - starti0 ) * this._chunkSize,
					( this._i0 - starti0 + 1 ) * this._chunkSize
				);
				this._i0++;
				continue;
			}

			if ( c1 - this._i1 === cAll - i ) { // left d1 chunks is exactly how many left at all. Fill next chunks with d0's chunks only
				d1.copy(
					buffer,
					( i - chunkStart ) * this._chunkSize,
					( this._i1 - starti1 ) * this._chunkSize,
					( this._i1 - starti1 + 1 ) * this._chunkSize
				);
				this._i1++;
				continue;
			}

			if ( i & 1 ) { //i % 2 === 1 . Using d1
				if ( this._i1 < c1 ) {
					d1.copy(
						buffer,
						( i - chunkStart ) * this._chunkSize,
						( this._i1 - starti1 ) * this._chunkSize,
						( this._i1 - starti1 + 1 ) * this._chunkSize
					);
					this._i1++;
					continue;
				}
			} else { //i % 2 === 0 . Using d0
				if ( this._i0 < c0 ) {
					d0.copy(
						buffer,
						( i - chunkStart ) * this._chunkSize,
						( this._i0 - starti0 ) * this._chunkSize,
						( this._i0 - starti0 + 1 ) * this._chunkSize
					);
					this._i0++;
					continue;
				}
			}
			//Nothing relevant to fill with. use d2
			d2.copy(
				buffer,
				( i - chunkStart ) * this._chunkSize,
				( this._i2 - starti2 ) * this._chunkSize,
				( this._i2 - starti2 + 1 ) * this._chunkSize
			);
			this._i2++;
		}

		return buffer;
	}

	//returns error list
	_validate( ) {
		let errorsCodes = [];
		if ( this._parts.length === 0 ) {
			errorsCodes.push( 1 ); //TODO: make file of error constants
		}

		if ( this._parts.length > 2 ) {
			errorsCodes.push( 2 );
		}

		if ( this._parts.length === 2 ) {
			let length0 = this._parts[ 0 ].binaryStream.size;
			let length1 = this._parts[ 1 ].binaryStream.size;

			/*if ( Math.min( length0, length1 ) * 2 < Math.max( length0, length1 ) ) {
				errorsCodes.push( 3 );
			}*/

			// if ( length0 + length1 > 1024 * 1024 * 1024 ) { //TODO: get from config
			// 	errorsCodes.push( 4 );
			// }

			if ( this._parts[ 0 ].position === this._parts[ 1 ].position  ) {
				errorsCodes.push( 5 );
			}
		} else {
			let length0 = this._parts[ 0 ].binaryStream.size;
			// if ( length0 * 1.5 > 1024 * 1024 * 1024 ) {
			// 	errorsCodes.push( 4 );
			// }
		}

		return errorsCodes;
	}
}

export default MessagePack;
