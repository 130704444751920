"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _rx = require("rx");

var _rx2 = _interopRequireDefault(_rx);

var _base = require("./base.js");

var _base2 = _interopRequireDefault(_base);

var _crypto = require("crypto");

var _crypto2 = _interopRequireDefault(_crypto);

var _prng = require("../random/locators/prng.js");

var _prng2 = _interopRequireDefault(_prng);

var _key = require("../primitives/key.js");

var _key2 = _interopRequireDefault(_key);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var random = (0, _prng2.default)();

var NodeCipher = function (_BaseCipher) {
	_inherits(NodeCipher, _BaseCipher);

	function NodeCipher(algorithm, key, blockSize, useIV, usePadding) {
		_classCallCheck(this, NodeCipher);

		if (!(key instanceof _key2.default)) {
			throw new Error("Invalid key type");
		}

		var _this = _possibleConstructorReturn(this, (NodeCipher.__proto__ || Object.getPrototypeOf(NodeCipher)).call(this, blockSize, usePadding));

		_this._keyHandle = key.addKeyUse("cipher");
		_this._key = key;
		var ivSize = useIV ? blockSize : 0;
		var ivThen = random.pseudoRandomDataThen(ivSize);

		_this._nodeCipherThen = ivThen.then(function (iv) {
			return new Promise(function (resolve) {
				key.postponeManagedBuffer(function (mb) {
					mb.useAsBuffer(function (keyBuffer) {
						var keyCopy = new Buffer(keyBuffer.length);
						keyBuffer.copy(keyCopy);
						var cipher = _crypto2.default.createCipheriv(algorithm, keyCopy, iv);
						cipher.setAutoPadding(_this._usePadding);
						resolve(cipher);
					});
				});
			});
		});

		_this._resultDeferred = [ivThen];
		return _this;
	}

	_createClass(NodeCipher, [{
		key: "append",
		value: function append(buffer) {
			this._resultDeferred.push(this._nodeCipherThen.then(function (cipher) {
				return cipher.update(buffer);
			}));
			return this;
		}
	}, {
		key: "getResultThen",
		value: function getResultThen() {
			var _this2 = this;

			this._resultDeferred.push(this._nodeCipherThen.then(function (cipher) {
				return cipher.final();
			}));
			return Promise.all(this._resultDeferred).then(function (resultArray) {
				_this2._key.removeKeyUse(_this2._keyHandle);
				return Buffer.concat(resultArray);
			});
		}
	}], [{
		key: "getEncryptedSize",
		value: function getEncryptedSize(blockSize, useIV, plainSize, usePadding) {
			var encryptedSize = plainSize + (useIV ? blockSize : 0);
			if (usePadding) {
				encryptedSize = ((encryptedSize / blockSize | 0) + 1) * blockSize;
			}
			return encryptedSize;
		}
	}]);

	return NodeCipher;
}(_base2.default);

exports.default = NodeCipher;