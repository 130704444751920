import React from "react";
import Rx from "rx";
import _ from "lodash";

let notificationsSubj = new Rx.BehaviorSubject( [] );

class NotificationsView extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentWillMount() {
		this._subscription = (
			notificationsSubj.subscribe( notifications => this.setState({notifications}) )
		);
	}

	componentWillUnmount() {
		this._subscription.dispose();
	}

	renderNotification( viewFactory, cssHeight, index ) {
		let top = [];
		for( let i = 0; i < index; i++ ) {
			top.push( this.state.notifications[ i ].cssHeight );
		}
		return (
			<div style={{
				position: "absolute",
				top: `calc(8rem + ${top.join( " + " ) || "0px"})`,
				left: "0px",
				width: "100%",
				height: cssHeight
			}}
				key={index}
			>{viewFactory()}</div>
		);
	}

	render() {
		return (
			<div>
				{this.state.notifications.map(
					( {viewFactory, cssHeight}, index ) =>
						this.renderNotification( viewFactory, cssHeight, index )
				)}
			</div>
		);
	}

	static registerNotification( viewFactory, cssHeight ) {
		notificationsSubj.onNext( notificationsSubj.getValue().concat( [ {
			viewFactory,
			cssHeight
		} ] ) );
	}

	static unregisterNotification( viewFactory ) {
		let notifications = notificationsSubj.getValue();
		let found = _.find( notifications, ({viewFactory}) );
		if ( !found ) {
			return;
		}
		notificationsSubj.onNext( _.without( notifications, found ) );
	}

	static observeHeights() {
		return notificationsSubj.map( notifications => _.map( notifications, "cssHeight" ) );
	}
}

export default NotificationsView;
