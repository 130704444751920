class DebuggableError extends Error {
	constructor( innerError, message, data ) {
		debugger;
		//fix arguments
		if ( typeof innerError === "string" ) {
			data = message;
			message = innerError;
			innerError = null;
		}

		if ( typeof message !== "string" ) {
			data = message;
		}

		super( message );
		this.name = this.constructor.name;
		if ( typeof Error.captureStackTrace === "function" ) {
			Error.captureStackTrace( this, this.constructor.name );
		} else {
			this.stack = ( new Error( message ) ).stack;
		}


		this.message = message;
		this.messages = message ? [ message ] : [];
		this.data = data || {};

		if ( innerError instanceof DebuggableError ) {
			this.messages.splice( 0, 0, ...innerError.messages );
			this.data = { ...innerError.data, ...this.data };
			this.stack = innerError.stack;
		} else if ( innerError instanceof Error ) {
			innerError.message && this.messages.splice( 0, 0, innerError.message );
			this.stack = innerError.stack;
		}
	}

	_stringify( obj ) {
		let seen = [];

		return JSON.stringify( obj, ( key, val ) => {
			if ( val != null && typeof val == "object" ) {
				if ( seen.indexOf( val ) >= 0 ) {
					return "[seen at " + seen.indexOf( val ) + "]";
				}
				seen.push( val );
			}
			return val;
		} );
	}

	toString() {
		return this._stringify( {
				messages: this.messages,
				data: this.data,
				stack: this.stack.toString()
			} );
	}
}

export default DebuggableError;
