import _ from "lodash";
import ssgCrypto from "ssg.crypto";

import { receiveMessageAsync } from "./models/message/technical.js";
import ClientServerSequential from "./transport/client.server.sequential.js";
import Multicast from "./transport/multicast.js";
import Transaction from "./transport/transaction.js";
import Contact from "./models/contact.js";
import contactServiceLocator from "./services/locators/contact.js";
import groupServiceLocator from "./services/locators/group.js";
import configuration from "../common/configuration.js";

let sequentialConnections = Object.create( null );

let testUtils = {
    sendSequentialMessage: ( connectionId, message ) => {
        if ( !sequentialConnections[ connectionId ] ) {
            sequentialConnections[ connectionId ] = new ClientServerSequential(
                configuration.getSocketBase(), connectionId
            );
        }
        return Transaction.runWithRetriesThen(
            transaction => {
                if ( _.isArray( message ) ) {
                    _.forEach( message, submessage => {
                        sequentialConnections[ connectionId ].send( submessage, transaction );
                    } );
                } else {
                    sequentialConnections[ connectionId ].send( message, transaction );
                }
            },
            "skipQueue"
        );
        sequentialConnections[ connectionId ]
    },
    addSelf: prefix => {
        let contactService = contactServiceLocator();
        return new Promise( ( resolve, reject ) => {
            contactService.createContactAndInviteAsync( prefix + "a", "" )
                .flatMap( ( { inviteToken } ) => contactService.receiveInviteAsync( inviteToken ) )
                .flatMap( invite => contactService.acceptInviteAsync( invite, prefix + "b" ) )
                .subscribe( resolve, reject );
        } );
    },
    deleteContact: contact => {
        let contactService = contactServiceLocator();
        contact = testUtils.findContact( contact );
        return new Promise( ( resolve, reject ) => {
            contactService.deleteContactAsync( contact.id )
                .subscribe( resolve, reject );
        } );
    },
    addGroup: ( name, nickname, externalCount = 0 ) => {
        let groupService = groupServiceLocator();
        return new Promise( ( resolve, reject ) => {
            groupService.createGroupAsync( name, nickname )
                .flatMap( group => Rx.Observable.range( 0, externalCount )
                    .concatMap( i => Rx.Observable.defer( () =>
                        Rx.Observable.fromPromise(
                            testUtils.addExternalParticipantToGroup(
                                group, "ext_" + i
                            )
                        )
                    ) )
                )
                .toArray()
                .subscribe( resolve, reject )
        } );
    },
    addExternalParticipantToGroup( group, extName ) {
        let groupService = groupServiceLocator();
        group = testUtils.findGroup( group );
        return new Promise( ( resolve, reject ) => {
            groupService.createExternalInviteAsync( group, extName )
                .flatMap( url => receiveMessageAsync( _.last( url.split( "/" ) ) ) )
                .flatMap( inviteData =>
                    Contact.createFromInviteAsync(
                        inviteData,
                        "No store",
                        "group",
                        +new Date
                    )
                    .flatMap( group => Multicast.tryJoinAsync(
                        inviteData.nickname,
                        {...inviteData,
                            signKey: group.signKey, dhPrivKey: group.dhPrivKey,
                            pid: group.pid.toString( "base64" )
                        },
                        {}
                    ) )
                ).subscribe( resolve, reject );
        } );
    },
    inviteParticipantToGroup( group, contact ) {
        let groupService = groupServiceLocator();
        let contactService = contactServiceLocator();
        group = testUtils.findGroup( group );
        contact = testUtils.findContact( contact );
        if ( !group ) {
            return Promise.reject( new Error( "Group not found" ) );
        }
        if ( !contact ) {
            return Promise.reject( new Error( "Contact not found" ) );
        }
        return new Promise( ( resolve, reject ) => {
            groupService.createInviteOrNullAsync( group, contact.name )
                .flatMap( invite => invite
                    ? contactService.sendInviteToGroupAsync( contact, invite )
                    : Rx.Observable.just( null )
                )
            .subscribe( resolve, reject );
        } );
    },
    findContact: param => {
        if ( param._mutationsObservable ) {
            return param;
        }
        let contactService = contactServiceLocator();
        if ( typeof param === "number" ) {
            param = { id: param };
        }

        if ( typeof param === "string" ) {
            param = { name: param };
        }
        return _.find( contactService._contacts, param );
    },
    findGroup: param => {
        if ( param._mutationsObservable ) {
            return param;
        }
        let groupService = groupServiceLocator();
        if ( typeof param === "number" ) {
            param = { id: param };
        }

        if ( typeof param === "string" ) {
            param = { name: param };
        }
        return _.find( groupService._contacts, param );
    },
    getContacts: () => {
        let contactService = contactServiceLocator();
        return contactService._contacts;
    },
    getGroups: () => {
        let groupService = groupServiceLocator();
        return groupService._contacts;
    },
    sendContactMessage: ( contact, text ) => {
        let contactService = contactServiceLocator();
        contact = testUtils.findContact( contact );
        return ssgCrypto.createRandomBase64StringThen( 32 )
            .then( id => new Promise( ( resolve, reject ) => {
                contactService.sendMessageAsync( contact, text, id )
                    .subscribe( resolve, reject );
            } ) );
    },
    sendGroupMessage: ( contact, text ) => {
        let groupService = groupServiceLocator();
        contact = testUtils.findGroup( contact );
        return ssgCrypto.createRandomBase64StringThen( 32 )
            .then( id => new Promise( ( resolve, reject ) => {
                groupService.sendMessageAsync( contact, text, id )
                    .subscribe( resolve, reject );
            } ) );
    },
    interval: ( count, delay, func ) => {
        let i = 0;
        let f = () => {
            if ( i < count ) {
                setTimeout( f, delay );
                func(i++);
            }
        };
        f();
    },
    failContact: ( contact, failReason ) => {
        let contactService = contactServiceLocator();
        contact = testUtils.findContact( contact );
        return new Promise( ( resolve, reject ) => {
            contactService.setContactFailedAsync( contact.id, failReason )
                .subscribe( resolve, reject );
        } );
    },
    failGroup: ( contact, failReason ) => {
        let groupService = groupServiceLocator();
        contact = testUtils.findGroup( contact );
        return new Promise( ( resolve, reject ) => {
            groupService.setContactFailedAsync( contact.id, failReason )
                .subscribe( resolve, reject );
        } );
    }
};

export default testUtils;
