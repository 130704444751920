import React from "react";

class MessageAddressesView extends React.Component {
	render() {
		return (
			<div>
				{
					this.props.addresses.map(
						address => (
							<div key={ address.name }>
								{ address.name }: <br />
							<input type="text" style={{ color: "black" }} value={ address.value } onChange={ () => 0 } />
							</div>
				) )
				}
			</div>
		);
	}
}

export default MessageAddressesView;
