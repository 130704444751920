import ssgCrypto from "ssg.crypto";
import ClientServer from "./client.server.base.js";

class ClientServerIndexValue extends ClientServer {
	constructor( apiUrlBase, connectionId ) {
		super( apiUrlBase, connectionId );
		this._gotUpdateValue = this._gotUpdateValue.bind( this );

		this._on( "updateIndexValue", this._gotUpdateValue );
	}

	_gotUpdateValue( message ) {
		if ( !this._onUpdateValue ) {
			console.warn( "updateIndexValue not listened" );
			return;
		}
		this._onUpdateValue( message );
	}

	onUpdateValue( func ) {
		if ( this._onUpdateValue ) {
			throw new Error( "onUpdateValue already subscribed" );
		}
		if ( !( typeof func === "function" ) ) {
			throw new Error( "function required" );
		}
		this._onUpdateValue = func;
	}

	tryUpdateTtl( ttl, cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}
		this._tryMakeCall( "updateIndexValuesTTL", {
			listenerId: null,
			containerId: this._connectionId,
			lockId: this._lockId,
			ttl
		}, cb );
	}

	tryLock( newLockId, cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}

		this._tryMakeCall( "lockIndexValues", {
			containerId: this._connectionId,
			newLockId,
			lockId: this._lockId
		}, cb );
	}

	lockAsync( ) {
		return (
			Rx.Observable.fromPromise( ssgCrypto.createRandomBase64StringThen( 32 ) )
				.tap( lockId => { this._lockId = lockId; } )
				.flatMap( lockId => this._callUntilSuccessAsync( cb => {
					this.tryLock( lockId, cb );
				} ) )
		);
	}

	send( index, value, transaction, opOptions ) {
		if ( !transaction ) {
			throw new Error( "Transaction is required" );
		}
		this._initTransactionIfNeed( transaction );
		transaction.addSetValue( this._apiUrlBase, {
			containerId: this._connectionId,
			lockId: this._lockId,
			index,
			value
		}, opOptions );
	}

	startExpire( index, transaction, opOptions ) {
		if ( !transaction ) {
			throw new Error( "Transaction is required" );
		}
		this._initTransactionIfNeed( transaction );
		transaction.startExpire( this._apiUrlBase, {
			containerId: this._connectionId,
			index
		}, opOptions );
	}


	updateTtlAsync( ttl ) {
		if ( typeof ttl !== "number" ) {
			throw new Error( "Ttl number required" );
		}
		return this._callUntilSuccessAsync( cb => {
			this.tryUpdateTtl( ttl, cb );
		} );
	}

	tryGetUnsetRanges( fromIndex, toIndex, cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}

		if ( typeof fromIndex !== "number" ) {
			throw new Error( "number fromIndex required" );
		}

		if ( typeof toIndex !== "number" ) {
			throw new Error( "number toIndex required" );
		}

		this._tryMakeCall( "getUnsetRanges", {
			containerId: this._connectionId,
			fromIndex,
			toIndex
		}, cb );
	}

	tryGetMaxIndex( cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}

		this._tryMakeCall( "getMaxIndex", {
			containerId: this._connectionId
		}, cb );
	}

	tryGetMessages( fromIndex, toIndex, cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}

		this._tryMakeCall( "getMessages", {
			containerId: this._connectionId,
			fromIndex, toIndex
		}, cb );
	}

	dispose( ) {
		if ( !this._isDisposed ) {
			this._off( "updateIndexValue", this._gotUpdateValue );
		}
		super.dispose();
	}
}

export default ClientServerIndexValue;
