import _ from "lodash";

class AutoPlayer {
  constructor() {
    this.messageComponentInstances = [];
  }

  addAudioMessageComponentInstance( reactElement ) {
    if ( !reactElement || !reactElement.props || !reactElement.props.message || !reactElement.onPlay ) {
      throw new Error( "Invalid component instance" );
    }
    this.messageComponentInstances.push( reactElement );
  }

  removeAudioMessageComponentInstance( reactElement ) {
    let index = this.messageComponentInstances.indexOf( reactElement );
    if ( !~index ) {
      return;
    }
    this.messageComponentInstances.splice( index, 1 );
  }

  playNext( currentInstance ) {
    let currentMessage = currentInstance.props.message;
    // if ( currentMessage.type === "INCOMING" ) {
    //   return;
    // }
    let nextInstances = _.filter( this.messageComponentInstances, inst => {
      let { message } = inst.props;
      return /*message.type === "INCOMING" && */message.index > currentMessage.index;
    } );
    if ( _.isEmpty( nextInstances ) ) {
      return;
    }
    let nextInstance = _.minBy( nextInstances, instance => instance.props.message.index );
    nextInstance.onPlay();
  }
}

export default new AutoPlayer();
