import React from "react";
import Rx from "rx";

import TopLevel from "../common/top.level.view.jsx";
import InputWithHelp from "../../components/input.withhelp.jsx";
import Toast from "../../components/toast.jsx";
import Translation from "../../components/translation.jsx";
import ArrowButton from "../../components/button.continue.jsx";

import translate from "../../translations/translate.js";
import history from "../../components/history.js";
import keysHelper from "../../../common/helpers/keys.js";

import serviceLocator from "../../../api/services/locators/worker.client.js";
import configuration from "../../../common/configuration.js";
import { Confirm, InternetRetryConfirm } from "../../components/dialogs.jsx";

class LoginView extends React.Component {
	constructor() {
		super();
		this._service = serviceLocator();
		if ( this._service.isLoggedIn() ) {
			window.location.reload();
		}
		this.state = {
			password: "",
			warnExpiration: null,
			demoPendingDays: null
		};
		this.onContinueClick = this.onContinueClick.bind( this );

		this.onDeleteSelect = this.onDeleteSelect.bind( this );
		this.onUpgrade = this.onUpgrade.bind( this );
		this.onCloseUpgrade = this.onCloseUpgrade.bind( this );
	}

	handleInput( propertyName, value ) {
		this.setState( { [ propertyName ]: value } );
	}

	onContinueClick() {
		this.setState( { loading: true } );
		this._service.logInAndConnectAsync( this.state.password )
			.subscribe( result => {
				this.setState( { loading: false } );
				if ( result ) {
					this.onLogin();
				} else {
					this.showInvalidPassword();
				}
			},
			error => {
				this.setState( { loading: false } );
				this._service.errorAsync( error )
					.subscribe(
						() => {},
						error => this.refs.noInternet.show()
					);
			} );
	}

	onLogin() {
		if ( configuration.getDemoTs() ) {
			this.onLoginDemoCheck();
			return;
		}

		if ( !configuration.getUserExpiration() ) {
			history.navigateTo( "contacts" );
			return;
		}
		this.onLoginExpirationCheck();
	}

	onLoginExpirationCheck() {
		this._service.observeExpireDt().take( 1 ).subscribe( expireDt => {
			if ( !expireDt || +new Date + 7*24*3600000 < expireDt ) {
				history.navigateTo( "contacts" );
				return;
			}
			this._service.observeGlobalUserType().take( 1 ).subscribe( ( { isPrivileged } ) => {
				if ( isPrivileged ) {
					history.navigateTo( "contacts" );
					return;
				}
				if ( +new Date < expireDt ) {
					this.setState( { warnExpiration: "expiring", expireDt } );
				} else {
					this.setState( { warnExpiration: "expired", expireDt } );
				}
			} );
		} );
	}

	onLoginDemoCheck() {
		this._service.getProfileAsync().subscribe( ( { userId } ) => {
			if ( userId ) {
				this.onLoginExpirationCheck();
				return;
			}
			this._service.getDemoLeftTimeAsync().subscribe( leftTime => {
				if ( !leftTime ) {
					alert( translate( "demo.expired.text" ) );
					history.navigateTo( "options/upgrade" );
					return;
				}
				this.setState( { demoPendingDays: ( leftTime / ( 24*3600000 ) ) | 0 } );
				this.refs.confirmGoToActivate.show();
				return;
			} );
		} );
	}

	onUpgrade() {
		history.navigateTo( "options/upgrade" );
	}

	onCloseUpgrade() {
		history.navigateTo( "contacts" );
	}

	showInvalidPassword() {
		//alert( translate( "login.invalidpassword" ) );
		this.refs.invalidPassword.show();
	}

	onDeleteSelect() {
		let drop = this._service.dropAccountAsync();
		if ( drop ) return drop.subscribe();
	}

	render() {
		if ( this.state.warnExpiration ) {
			return <Expiring
				isExpired={ this.state.warnExpiration === "expired" }
				expireDt={ this.state.expireDt }
			/>;
		}
		return (
			<TopLevel className="login-page">
				<header>
					<Translation textId="login.header" />
				</header>
				<main>
					<InputWithHelp
						type="password"
						value={ this.state.password }
						onChange={ this.handleInput.bind( this, "password" ) }
						autofocus={ true }
						placeholderTextId="login.password.placeholder"
						tooltipTextId="login.password.tooltip"
					/>
					<ArrowButton
						caption="login.continue.button"
						handleClick={ this.onContinueClick }
						enabled={ !this.state.loading }
					/>
					<Confirm
						ref="confirmDeleteAccount"
						textId="options.delete.confirm"
						onConfirm={ this.onDeleteSelect }
					/>
					<Confirm
						ref="confirmGoToActivate"
						textId="demo.dialog.text"
						onConfirm={ this.onUpgrade }
						onReset={ this.onCloseUpgrade }
						titleTextId="demo.dialog.title"
						buttonTextIds={ [ "demo.dialog.upgrade", "demo.dialog.close" ] }
						params={ [ this.state.demoPendingDays ] }
					/>
					<div className="delete-account">
						<a href="javascript:;" onClick={ () => this.refs.confirmDeleteAccount.show() } className='delete-account'>
							<Translation textId='remove.account.data' />
						</a>
					</div>
				</main>

				<InternetRetryConfirm ref="noInternet" onConfirm={ this.onContinueClick } />
				<Toast textId="login.invalidpassword" ref="invalidPassword" />
			</TopLevel>
		);
	}
}

class Expiring extends React.Component {
	constructor() {
		super();
		this.onOkClick = this.onOkClick.bind(this);
	}

	onOkClick() {
		history.navigateTo( "contacts" );
	}

	renderExpiring() {
		let daysLeft = ( this.props.expireDt - ( +new Date ) ) / ( 24 * 3600000 );
		daysLeft = Math.max( 0, daysLeft | 0) + 1;
		return (
			<TopLevel className="login-page">
				<header>
					<Translation textId="warn.expiring.header" />
				</header>
				<main>
					<Translation textId="warn.expiring.text" params={ [ daysLeft ] } />
					<ArrowButton
						caption="warn.expiring.button"
						handleClick={ this.onOkClick }
					/>
				</main>
			</TopLevel>
		);
	}

	renderExpired() {
		return (
			<TopLevel className="login-page">
				<header>
					<Translation textId="warn.expired.header" />
				</header>
				<main>
					<Translation textId="warn.expired.text" />
					<ArrowButton
						caption="warn.expired.button"
						handleClick={ this.onOkClick }
					/>
				</main>
			</TopLevel>
		);
	}

	render() {
		if ( this.props.isExpired ) {
			return this.renderExpired();
		}
		return this.renderExpiring();
	}
}

export default LoginView;
