import React from "react";
import Translation from "../../components/translation.jsx";
import Timg from "../../components/timg.jsx";

class MenuView extends React.Component {
	renderItemContent( item ) {
		return [
			<span className="value f-right" key="1">{ item.value }</span>,
			item.arrow ? <img src="images/icon-menu-arrow-right.svg" className="arrow" key="2" /> : null,
			<Timg src={ item.icon } altTextId={ item.titleTextId } className={ "icon " + ( item.iconClass || "" ) }  key="3"/>,
			<span className="label" key="4">
				<Translation textId={ item.titleTextId } />
			</span>
		];
	}

	onSelect( event, item ) {
		if ( event.preventDefault ) {
			event.preventDefault();
		}
		item.onSelect();
	}

	render() {
		//{/*onTouchStart={ event => this.onSelect( event, item ) }*/}
		return (
			<div className="menu-list">
				{ this.props.items.map(
					( item, index ) =>
						<div
							className="item"
							key={ index }
							onClick={ event => this.onSelect( event, item ) }
							id={item.id}
						>
							{ this.renderItemContent( item ) }
						</div>
				) }
			</div>
		);
	}
}

export default MenuView;
