import base64 from "../../../common/utils/base64.js";
import {callUntilSuccessThen} from "../../common/utils.js";
import MessageSerializer from "../models/message/message.serializer.js";
import Encrypter from "../models/message/encrypter.js";
import keyHelper from "../models/message/key.helper.js";
import Pack from "../models/message/pack.js";
import ServiceBase from "./service.base.js";
import MessageHub from "../hubs/message.js";

class MessageService extends ServiceBase {
	constructor( apiBaseUrl ) {
		super( apiBaseUrl );
		this._hub = new MessageHub( apiBaseUrl );
	}

	ajaxWithRetryThen( request ) {
		return callUntilSuccessThen( cb => {
			this.ajaxThen( request )
				.then(
					cb, error => {
						//TODO: flaxible error handling
						if ( error.message === "No such message" ) {
							cb( error );
							return;
						}
						cb();
					}
				);
		} );
	}
	/*
	id - id of requested message
	*/
	beginCreateThen( params ) {
		return (
			this.ajaxWithRetryThen( {
				url: `${params.apiUrlBase || ""}/message/create`,
				responseType: "json",
				method: "POST",
				headers: {
					"Content-Type": "application/json; charset=utf-8"
				},
				body: JSON.stringify( {
					dataLength: params.dataLength
					// hash: base64.encode( params.hash )
				} )
			} )
		);
	}
	addChunkThen( params ) {
		return (
			this.ajaxWithRetryThen( {
				url: `${params.apiUrlBase || ""}/message/append/${params.tmpId}/${params.position}`,
				responseType: "json",
				headers: {
					"Content-Type": "application/octet-stream"
				},
				method: "POST",
				body: params.chunk
			} )
		);
	}
	finishCreateThen( params ) {
		return (
			this.ajaxWithRetryThen( {
				url: `${params.apiUrlBase || ""}/message/finish`,
				responseType: "json",
				headers: {
					"Content-Type": "application/json; charset=utf-8"
				},
				method: "POST",
				body: JSON.stringify( {
					tmpId: params.tmpId,
					hash: base64.encode( params.hash ),
					isSingleRead: params.isSingleRead,
					expirationTS: params.expirationTS,
					serverSeed: base64.encode( params.serverSeed ),
					idLength: params.idLength
				} )
			} )
		);
	}
	deleteThen( params ) {
		return (
			this.ajaxWithRetryThen( {
				url: `${params.apiUrlBase || ""}/message/token/${params.token}`,
				responseType: "json",
				method: "DELETE"
			} )
		);
	}

	removeMessageByIdAsync( params ) {
		return Rx.Observable.fromPromise( this.removeMessageByIdThen( params ) );
	}

	removeMessageByIdThen( { id, apiUrlBase } ) {
		return (
			this.ajaxThen( {
				url: `${apiUrlBase || ""}/message/id/${id}`,
				responseType: "json",
				method: "DELETE"
			} )
		);
	}

	beginReadThen( params ) {
		return (
			this.ajaxWithRetryThen( {
				url: `${params.apiUrlBase || ""}/message/${params.id}`,
				responseType: "json",
				method: "GET"
			} )
			.then( result => {
				return result && ( {
					expirationTS: result.expirationTS,
					id: result.id,
					isSingleRead: result.isSingleRead,
					readToken: result.readToken,
					hash: result.hash && base64.decode( result.hash ),
					serverSeed: result.serverSeed && base64.decode( result.serverSeed ),
					dataLength: result.dataLength
				} );
			} )
		);
	}
	readChunkThen( params ) {
		if ( isNaN( params.position ) || isNaN( params.length ) ) {
			throw new Error( "Invalid chunk range request" );
		}
		return (
			this.ajaxWithRetryThen( {
				url: `${params.apiUrlBase || ""}/message/read/${params.token}`,
				responseType: "arraybuffer",
				method: "GET",
				headers: {
					Range: `bytes=${params.position}-${params.position + params.length - 1 }`
				}
			} )
			.then( result => {
				return new Buffer( new Uint8Array( result ) );
			} )
		);
	}

	sendMessageThen( params ) {
		return this._hub.sendMessageThen( params );
	}

	receiveMessageThen( id ) {
		return this._hub.receiveMessageThen( id );
	}
}

export default MessageService;
