import Rx from "rx";
import React from "react";
import ReactDOM from "react-dom";
import MessageReceiver from "../../../../api/models/message/message.receiver.js";
import MessageView from "./message.jsx";
import MessageDeserializer from "../../../../api/models/message/message.deserializer.js";

class MessageReadView extends React.Component {
	constructor() {
		super();
		this.state = {};
		this.decryptHandler = this.decryptHandler.bind( this );
	}
	componentDidMount() {
		let receiver = new MessageReceiver( { token: this.props.token } );

		this.setState( { receiver: receiver }, () => this.decryptWithPassword( receiver ) );
	}
	decryptWithPassword( receiver ) {
		return (
			receiver
				.decryptAsync( { password: ReactDOM.findDOMNode( this.refs.password ).value } )
				.observeOn( Rx.Scheduler.default )
				.flatMap( buffer => new MessageDeserializer( buffer ).getObservable() )
				.subscribe(
					message => {
						this.setState( { message: message } );
					},
					error => console.error( error )
				)
		);
	}
	decryptHandler() {
		this.decryptWithPassword( this.state.receiver );
	}
	render() {
		return (
			<div style={{ color: "black" }}>
				Reading message<br />
				Password: <input style={{ color: "black", border: "solid 1px red" }} type="text" ref="password" /><br />
				{
					this.state.message ? <MessageView message={ this.state.message } /> : null
				}
				<button handleClick={this.decryptHandler} >Decrypt!</button>
			</div>
		);
	}
}

export default MessageReadView;
