"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _crypto = require("crypto");

var _crypto2 = _interopRequireDefault(_crypto);

var _fakeSource = require("./sources/fake.source.js");

var _fakeSource2 = _interopRequireDefault(_fakeSource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var POOL_COUNT = 16;
var isEnabledFakeSource = null;

var GeneratorBase = function () {
	/*
 randomGenerator - used for reseeding
 */
	function GeneratorBase(randomGenerator) {
		var _this = this;

		_classCallCheck(this, GeneratorBase);

		this._key = null;
		this._counter = 0;
		this._randomGenerator = randomGenerator;

		this._pools = Array(POOL_COUNT);
		this._poolDataCounter = 0;

		this._stateReady = new Promise(function (resolve) {
			randomGenerator.getPriorityDataThen(32).then(function (buffer) {
				_this.reseed(buffer);
				resolve();
			});
		});

		this._queryIdleData();
	}

	_createClass(GeneratorBase, [{
		key: "reseed",
		value: function reseed(buffer) {
			var newHash = _crypto2.default.createHash("sha256");
			this._key && newHash.update(this._key);

			newHash.update(buffer);

			this._key = newHash.digest();
			this._cipher = _crypto2.default.createCipheriv("aes-256-ecb", this._key, new Buffer(0));
			this._counter++;
		}
	}, {
		key: "_queryIdleData",
		value: function _queryIdleData() {
			var _this2 = this;

			this._randomGenerator.getIdleDataThen(32).then(function (buffer) {
				_this2._gotPoolData(buffer);
			});
		}
	}, {
		key: "_gotPoolData",
		value: function _gotPoolData(buffer) {
			this._poolDataCounter++;
			var index = this._poolDataCounter % POOL_COUNT;

			var newHash = _crypto2.default.createHash("sha256");
			this._pools[index] && newHash.update(this._pools[index]);
			newHash.update(buffer);
			this._pools[index] = newHash.digest();
			if (index === 0) {
				this._usePoolsToUpdateKey();
			}
			this._queryIdleData();
		}
	}, {
		key: "_usePoolsToUpdateKey",
		value: function _usePoolsToUpdateKey() {
			var counter = this._poolDataCounter;
			var poolCount = 1;
			while ((counter & 1) === 0 && poolCount < POOL_COUNT) {
				poolCount++;
				counter >>= 1;
			}

			this.reseed(Buffer.concat(this._pools.slice(0, poolCount)));
			for (var i = 0; i < poolCount; i++) {
				this._pools[i].fill(0);
			}
		}
	}, {
		key: "_generateBlocksThen",
		value: function _generateBlocksThen(count) {
			var _this3 = this;

			return this._stateReady.then(function () {
				return new Promise(function (resolve) {
					var buffers = [];

					var nextMilestone = function nextMilestone() {
						for (var i = 0; i < 1000 && buffers.length < count; i++) {
							buffers.push(_this3._generateBlockSync());
						}
						if (buffers.length < count) {
							setTimeout(nextMilestone, 0);
							return;
						}
						resolve(Buffer.concat(buffers));
					};
					nextMilestone();
				});
			});
		}
	}, {
		key: "_generateBlockSync",
		value: function _generateBlockSync() {
			if (!this._counter) {
				throw "Invalid counter state";
			}
			var counterBuffer = new Buffer(16);
			counterBuffer.writeUInt32LE(this._counter++, 0);

			var encrypted = this._cipher.update(counterBuffer);

			if (encrypted.length !== 16) {
				throw new Error(encrypted.length + " !== 16");
			}
			return encrypted;
		}
	}, {
		key: "pseudoRandomDataThen",
		value: function pseudoRandomDataThen(length) {
			return this._generateBlocksThen(length + 15 >> 4).then(function (blocks) {
				return blocks.slice(0, length);
			});
		}
		/*
  Query function that will generate random data syncronously. Required by some libs.
  */

	}, {
		key: "getSyncGeneratorFuncThen",
		value: function getSyncGeneratorFuncThen() {
			var _this4 = this;

			return this._stateReady.then(function () {
				return function (length) {
					if (length > 65536) {
						throw "Invalid prnd size";
					}
					var blocks = Array(length + 15 >> 4);
					for (var i = 0; i < blocks.length; i++) {
						blocks[i] = _this4._generateBlockSync();
					}
					if (length % 16 !== 0) {
						blocks[blocks.length - 1] = blocks[blocks.length - 1].slice(0, length % 16);
					}
					return Buffer.concat(blocks, length);
				};
			});
		}
	}, {
		key: "getBitsRequiredForReseed",
		value: function getBitsRequiredForReseed() {
			return 256 * POOL_COUNT;
		}
	}, {
		key: "disableFakeSource",
		value: function disableFakeSource() {
			if (isEnabledFakeSource !== null) {
				this._randomGenerator = null;
				throw new Error("Fake source already configured");
			}
			isEnabledFakeSource = false;
		}
	}, {
		key: "enableFakeSource",
		value: function enableFakeSource() {
			if (isEnabledFakeSource !== null) {
				this._randomGenerator = null;
				throw new Error("Fake source already configured");
			}
			isEnabledFakeSource = true;
			this._randomGenerator.addSource(_fakeSource2.default);
		}
	}]);

	return GeneratorBase;
}();

exports.default = GeneratorBase;