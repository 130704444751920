"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

exports.default = function (param) {
	var c = param.c,
	    klass = param.klass;

	switch (klass) {
		case "HMAC_SHA256":
			klass = _triplesec.HMAC_SHA256;
			break;
		default:
			throw new Error("Unsupported PRF");
	}
	//pbkdf2 = ({key/*WordArray*/, salt/*WordArray*/, c, klass/*=HMAC*/, progress_hook, dkLen}, cb)
	return function (key, salt, res, cb) {
		if (!(key instanceof _secureBufferContainer.TransformedBuffer)) {
			throw new Error("Key must be a TransformedBuffer");
		}

		if (!(salt instanceof _secureBufferContainer.TransformedBuffer)) {
			throw new Error("Salt must be a TransformedBuffer");
		}

		if (!(res instanceof _secureBufferContainer.TransformedBuffer)) {
			throw new Error("Res must be a TransformedBuffer");
		}

		var plainKey = (0, _secureBufferContainer3.default)().allocatePlain(key.byteLength);
		var plainSalt = (0, _secureBufferContainer3.default)().allocatePlain(salt.byteLength);
		key.useAsBuffer(function (keyBuffer) {
			return plainKey.useAsBuffer(function (keyPlainBuffer) {
				keyBuffer.copy(keyPlainBuffer);
			});
		});

		salt.useAsBuffer(function (saltBuffer) {
			return plainSalt.useAsBuffer(function (saltPlainBuffer) {
				saltBuffer.copy(saltPlainBuffer);
			});
		});

		plainKey.useAsUint32ArrayLong(function (uint32Key, cbKey) {
			return plainSalt.useAsUint32ArrayLong(function (uint32Salt, cbSalt) {
				return (0, _triplesec.pbkdf2)({
					key: new _triplesec.WordArray(Array.prototype.slice.call(uint32Key).map(endian_reverse), plainKey.byteLength), //TODO: less memory traces
					salt: new _triplesec.WordArray(Array.prototype.slice.call(uint32Salt).map(endian_reverse), plainSalt.byteLength),
					c: c, klass: klass,
					dkLen: res.byteLength
				}, function (resWordArray) {
					res.useAsBuffer(function (resBuffer) {
						resWordArray.to_buffer().copy(resBuffer);
						resWordArray.scrub();
						cbKey();
						cbSalt();
						plainKey.dispose();
						plainSalt.dispose();
						cb(res);
					});
				});
			});
		});
	};
};

var _triplesec = require("triplesec");

var _secureBufferContainer = require("../secure.container/secure.buffer.container.js");

var _secureBufferContainer2 = require("../secure.container/locators/secure.buffer.container.js");

var _secureBufferContainer3 = _interopRequireDefault(_secureBufferContainer2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function endian_reverse(x) {
	return x >>> 24 & 0xff | (x >>> 16 & 0xff) << 8 | (x >>> 8 & 0xff) << 16 | (x & 0xff) << 24;
};

;