"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _tweetnacl = require("tweetnacl");

var _tweetnacl2 = _interopRequireDefault(_tweetnacl);

var _prng = require("../../random/locators/prng.js");

var _prng2 = _interopRequireDefault(_prng);

var _sign = require("../abstract/sign.js");

var _sign2 = _interopRequireDefault(_sign);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Ed25519Sign = function (_AbstractSign) {
    _inherits(Ed25519Sign, _AbstractSign);

    function Ed25519Sign() {
        _classCallCheck(this, Ed25519Sign);

        var _this = _possibleConstructorReturn(this, (Ed25519Sign.__proto__ || Object.getPrototypeOf(Ed25519Sign)).call(this));

        _this._privKeyThen = null;
        _this._privKeyCallback = null;
        return _this;
    }

    _createClass(Ed25519Sign, [{
        key: "getPrivateKeyByteLength",
        value: function getPrivateKeyByteLength() {
            return _tweetnacl2.default.sign.secretKeyLength;
        }
    }, {
        key: "getSignByteLength",
        value: function getSignByteLength() {
            return _tweetnacl2.default.sign.signatureLength;
        }
    }, {
        key: "setPrivateKey",
        value: function setPrivateKey(buffer) {
            if (!Buffer.isBuffer(buffer)) {
                throw new Error("buffer parameter required");
            }

            if (this._privKeyCallback) {
                this._privKeyCallback(buffer);
                this._privKeyCallback = null;
                return;
            }

            if (this._privKeyThen) {
                throw new Error("Private key already set");
            }

            this._privKeyThen = Promise.resolve(buffer);

            return this;
        }
    }, {
        key: "postponePrivateKey",
        value: function postponePrivateKey() {
            var _this2 = this;

            if (this._privKeyThen) {
                throw new Error("Private key already set");
            }
            this._privKeyThen = new Promise(function (resolve) {
                _this2._privKeyCallback = resolve;
            });
        }
    }, {
        key: "setPrivateKeyRandom",
        value: function setPrivateKeyRandom() {
            var keyPairThen = (0, _prng2.default)().pseudoRandomDataThen(32).then(function (seed) {
                return _tweetnacl2.default.sign.keyPair.fromSeed(seed);
            });
            this._privKeyThen = keyPairThen.then(function (_ref) {
                var secretKey = _ref.secretKey;
                return secretKey;
            });
            this._publicKeyThen = keyPairThen.then(function (_ref2) {
                var publicKey = _ref2.publicKey;
                return publicKey;
            });
        }
    }, {
        key: "isPrivateKeySet",
        value: function isPrivateKeySet() {
            return !!this._privKeyThen;
        }
    }, {
        key: "toPrivateBufferThen",
        value: function toPrivateBufferThen() {
            return this._privKeyThen.then(function (uInt8Array) {
                return new Buffer(uInt8Array);
            });
        }
    }, {
        key: "toPublicBufferThen",
        value: function toPublicBufferThen() {
            if (!this._publicKeyThen) {
                this._publicKeyThen = this._privKeyThen.then(function (secretKey) {
                    return _tweetnacl2.default.sign.keyPair.fromSecretKey(secretKey);
                }).then(function (_ref3) {
                    var publicKey = _ref3.publicKey;
                    return publicKey;
                });
            }
            return this._publicKeyThen.then(function (publicKey) {
                return new Buffer(publicKey);
            });
        }
    }, {
        key: "makeSignThen",
        value: function makeSignThen(binarySource) {
            return Promise.all([binarySource.getBufferThen(), this._privKeyThen]).then(function (_ref4) {
                var _ref5 = _slicedToArray(_ref4, 2),
                    message = _ref5[0],
                    secretKey = _ref5[1];

                return _tweetnacl2.default.sign.detached(message, secretKey);
            }).then(function (signature) {
                return new Buffer(signature);
            });
        }
    }], [{
        key: "getPrivateKeyByteLength",
        value: function getPrivateKeyByteLength() {
            return _tweetnacl2.default.sign.secretKeyLength;
        }
    }, {
        key: "getSignByteLength",
        value: function getSignByteLength() {
            return _tweetnacl2.default.sign.signatureLength;
        }
    }]);

    return Ed25519Sign;
}(_sign2.default);

exports.default = Ed25519Sign;