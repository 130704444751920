import React from "react";
import Rx from "rx";
import _ from "lodash";

import PromptWorkgroup from "./prompt.workgroup.jsx";
import PromptContacts from "./prompt.contacts.jsx";
import WorkgroupRights from "./workgroup.rights.jsx";

import TopLevel from "../common/top.level.view.jsx";
import ImageButton from "../../components/button.image.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import Translation from "../../components/translation.jsx";
import Prompt from "../common/prompt.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

class ShareContacts extends React.Component {
	constructor() {
		super();
		this.cancelSetName = this.cancelSetName.bind( this );
		this.cancelSetNickname = this.cancelSetNickname.bind( this );
		this.setNickname = this.setNickname.bind( this );
		this.setName = this.setName.bind( this );
		this.selectWorkgroup = this.selectWorkgroup.bind( this );
		this.selectContacts = this.selectContacts.bind( this );
		this.backFromWorkgroupRights = this.backFromWorkgroupRights.bind( this );
		this.state = { step: 0 };
		this._service = serviceLocator();
	}

	componentWillMount() {
		this._profileSubscription = this._service.getProfileAsync()
			.subscribe( profile => {
				if ( profile.nickname ) {
					this.setState( { step: 2, nickname: profile.nickname } );
					return;
				}
				this.setState( { step: 1, askNickname: true } );
			} );

		this._contactsSubscription = (
			this._service.observeContactList()
				.subscribe( contacts => {
					this.setState( { contacts } );
				} )
		);
	}

	getWorkgroupParticipants() {
		let rights = this.state.modifiedRights || {};
		return _.reduce( this.state.workgroup, ( acc, id ) => {
			let contact = _.find( this.state.contacts, { id } );
			if ( !contact ) {
				return acc;
			}
			let pid = contact.pid;
			if ( !pid ) {
				return acc;
			}
			acc[ pid ] = {
				nickname: contact.name,
				rights: rights[ pid ] || {
					allowModifyWorkgroup: true,
					allowModifyContacts: true
				}
			};
			return acc;
		}, {} );
	}

	componentWillUnmount() {
		this._profileSubscription.dispose();
		this._contactsSubscription.dispose();
	}

	cancelSetName() {
		if ( !this.state.askNickname ) {
			this.props.onBack();
			return;
		}
		this.setState( {step: 1} );
	}

	cancelSetNickname() {
		this.props.onBack();
	}

	setNickname( nickname ) {
		this.setState( {nickname, step: 2} );
	}

	setName( name ) {
		this.setState( {name, step: 3} );
	}

	selectWorkgroup( contactIds ) {
		if ( !contactIds ) {
			this.props.onDone();
			return;
		}

		if ( !contactIds.length ) {
			this.setState( {
				step: 5,
				workgroup: contactIds,
				modifiedRights: {}
			} );
			return;
		}

		this.setState( {
			step: 4,
			workgroup: contactIds
		} );
	}

	selectContacts( contactIds ) {
		if ( _.isEmpty( contactIds ) ) {
			this.setState( {
				step: 3
			} );
			return;
		}
		let progressSubj = new Rx.BehaviorSubject(-1);
		this.addAsync( this.state.workgroup, contactIds, this.state.modifiedRights, progressSubj )
			.subscribe(
				this.props.onDone
			);
		return progressSubj;
	}

	addAsync( workgroupContactIds, contactIds, rights, progressSubj ) {
		rights = _.reduce( rights, ( acc, { rights, pid } ) => {
			let contact = _.find( this.state.contacts, c => c.pid.toString( "base64" ) === pid );
			acc[ contact.id ] = rights;
			return acc;
		}, Object.create( null ) );
		return (
			this._service
				.addSharedContactListAsync(
					this.state.nickname,
					this.state.name,
					workgroupContactIds,
					contactIds, rights
				)
		);
	}

	backFromWorkgroupRights( modifiedRights ) {
		if ( !modifiedRights ) {
			this.setState( { step: 3 } );
		} else {
			this.setState( { modifiedRights, step: 5 } );
		}
	}

	render() {
		switch( this.state.step ) {
			case 0:
				return null;
			case 1:
				return <NicknamePrompt
					nickname={ this.state.nickname }
					cancelSetNickname={ this.cancelSetNickname }
					setNickname={ this.setNickname }
				/>;
			case 2:
				return <NamePrompt
					nickname={ this.state.name }
					cancelSetName={ this.cancelSetName }
					setName={ this.setName }
				/>;
			case 3:
				return <PromptWorkgroup onDone={ this.selectWorkgroup } id="prompt-workgroup" />;
			case 4:
				return <WorkgroupRights
					onBack={ this.backFromWorkgroupRights }
					workgroupParticipants={ this.getWorkgroupParticipants() }
					selfRights={ { allowModifyContacts: true, allowModifyWorkgroup: true } }
					continueAlwaysActive={true}
				/>;
			case 5:
				return <PromptContacts onDone={ this.selectContacts } id="prompt-contacts" />;
		}
	}
}

class NicknamePrompt extends React.Component {
	constructor() {
		super();
		this.validate = this.validate.bind( this );
	}

	validate( value ) {
		if ( value.trim() ) {
			return [];
		}
		return [ "common.contact.name.empty" ];
	}

	render() {
		return <Prompt
			defaultValue={ this.props.nickname || "" }
			onBack={ this.props.cancelSetNickname }
			onSetValue={ this.props.setNickname }
			placeholderTextId="workgroup.add.nickname.placeholder"
			headerTextId="workgroup.add.nickname.header"
			textId="workgroup.add.nickname.text"
			autofocus={true}
			validate={ this.validate }
		/>;
	}
}

class NamePrompt extends React.Component {
	constructor() {
		super();
		this.validate = this.validate.bind( this );
	}

	validate( value ) {
		if ( value.trim() ) {
			return [];
		}
		return [ "common.contact.name.empty" ];
	}

	render() {
		return <Prompt
			defaultValue={ this.props.name || ""}
			onBack={ this.props.cancelSetName }
			onSetValue={ this.props.setName }
			placeholderTextId="sharedcontacts.add.name.placeholder"
			headerTextId="sharedcontacts.add.name.header"
			textId="sharedcontacts.add.name.text"
			autofocus={true}
			validate={ this.validate }
		/>;
	}
}

export default ShareContacts;
