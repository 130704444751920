import React from "react";
import Translation from "../../../components/translation.jsx";

class CreateProgressContentView extends React.Component {
	render() {
		return (
			<div className={this.props.className}>
				<div className="invite">
					<Translation textId="web.group.create.progress.text" />
				</div>
			</div>
		);
	}
}

export default CreateProgressContentView;
