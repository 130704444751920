import _ from "lodash";
import ClientServerBase from "./client.server.base.js";

class ClientServerOneWay extends ClientServerBase {
	constructor( apiUrlBase, connectionId, isReceive ) {
		super( apiUrlBase, connectionId );
		this._isReceive = isReceive;

		if ( isReceive ) {
			this._gotMessageHandler = this._gotMessageHandler.bind( this );
			this._on( "gotOneWayMessage", this._gotMessageHandler );
		}
	}

	_gotMessageHandler( message ) {
		let { callId, listenerId } = message;
		if ( listenerId !== this._listenerId ) {
			return;
		}
		message = _.clone( message );
		if ( !callId ) {
			console.error( "Got message from server without callId" );
		}

		delete message.callId;
		if ( !this._onMessage ) {
			console.warn( "Got message without handler" );
			return;
		}
		this._onMessage( message );
	}

	onGotMessage( func ) {
		if ( this._onMessage ) {
			throw new Error( "onGotMessage double call" );
		}
		this._onMessage = func;
	}

	tryListen( fromIndex, cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}
		if ( !this._isReceive ) {
			throw new Error( "This One Way connection cannot receive messages" );
		}
		this._tryMakeCall( "listenOneWay", {
			listenerId: null,
			to: this._connectionId,
			fromIndex
		}, cb );
	}

	tryStopListen( cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}
		if ( !this._isReceive ) {
			throw new Error( "This One Way connection cannot receive messages" );
		}

		this._tryMakeCall( "stopListenOneWay", {
			listenerId: null,
			to: this._connectionId
		}, cb );
	}

	send( message, transaction, opOptions ) {
		if ( !transaction ) {
			throw new Error( "Transaction is required" );
		}
		if ( this._isReceive ) {
			throw new Error( "This One Way connection cannot send messages" );
		}
		this._initTransactionIfNeed( transaction );
		transaction.addOneWayMessage( this._apiUrlBase, message, opOptions );
	}

	tryGetMaxIndex( cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}
		if ( !this._isReceive ) {
			throw new Error( "This One Way connection cannot receive messages" );
		}

		this._tryMakeCall( "getMaxIndex", {
			toOneWay: this._connectionId
		}, cb );
	}

	dispose( ) {
		if ( !this._isDisposed && this._isReceive ) {
			this._off( "gotOneWayMessage", this._gotMessageHandler );
		}
		super.dispose();
	}
}

export default ClientServerOneWay;
