import React from "react";

import ContactListTitle from "../common/contact.list.title.jsx";

class ContactListListTitle extends React.Component {
	render() {
		return (
			<ContactListTitle
				{...this.props}
				textId="web.groups.title"
				placeholderTextId="group.search.placeholder"
			/>
		);
	}

	static get propTypes() {
		return {
			onAddClick: React.PropTypes.func.isRequired
		};
	}
}

export default ContactListListTitle;
