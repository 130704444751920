import React from "react";
import Translation from "../../../components/translation.jsx";
import Tinput from "../common/tinput.jsx";
import Toast from "../../../components/toast.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class CreateFakeAccountContentView extends React.Component {
	constructor() {
		super();
		this._service = remoteServiceLocator();
		this.state = {
			name: "",
			password: "",
			showPassword: false,
			inProgress: false
		};
		this.onNameChange = this.onNameChange.bind( this );
		this.onPasswordChange = this.onPasswordChange.bind( this );
		this.onShowPasswordChange = this.onShowPasswordChange.bind( this );
		this.onCreateClick = this.onCreateClick.bind( this );
	}

	onNameChange( name ) {
		if ( this.state.inProgress ) {
			return;
		}
		this.setState( {name} );
	}

	onPasswordChange( password ) {
		if ( this.state.inProgress ) {
			return;
		}
		this.setState( {password} );
	}

	onShowPasswordChange( event ) {
		if ( this.state.inProgress ) {
			return;
		}
		let showPassword = event.target.checked;
		this.setState( {showPassword} );
	}

	onCreateClick() {
		if ( this.state.inProgress ) {
			return;
		}
		this.setState( {inProgress: true} );
		let {name, password} = this.state;
		this._service.createFakeAccountAsync( name, password )
			.subscribe( isSuccess => {
				this.setState( {inProgress: false} );
				if ( !isSuccess ) {
					this.refs.dulplicatePassword.show();
					return;
				}
				this.props.onBack();
			}) ;
	}

	render() {
		return (
			<div className={this.props.className}>
				<div className="invite">
					<div className="title-sm">
						<Translation textId="web.fakeaccount.create.title" />
					</div>
					<Translation textId="web.fakeaccount.create.text" />

					<Tinput
						className="name"
						type="text"
						placeholderTextId="web.fakeaccount.name.placeholder"
						value={ this.state.name }
						onChange={ this.onNameChange }
					/>
					<Tinput
						className="name"
						type={this.state.showPassword ? "text" : "password"}
						placeholderTextId="web.fakeaccount.password.placeholder"
						value={ this.state.password }
						onChange={ this.onPasswordChange }
					/>
					<div className="checkbox">
						<label className="nice-checkbox">
							<input
								type="checkbox"
								onChange={this.onShowPasswordChange}
								checked={this.state.showPassword}
								/>
							<span className="check"></span>
							<Translation textId="web.security.password.show.label" />
						</label>
					</div>
					<div className="text-center">
						<button className="btn btn-blue" onClick={this.onCreateClick}>
							<Translation textId="web.fakeaccount.create.button" />
						</button>
					</div>
				</div>
				<Toast textId="web.fakeaccount.dulplicatePassword.toast" ref="dulplicatePassword"/>
			</div>
		);
	}

	static get propTypes() {
		return {
			onBack: React.PropTypes.func.isRequired
		};
	}
}

export default CreateFakeAccountContentView;
