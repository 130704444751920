//TODO: use ssgCrypto
import Rx from "rx";
import React from "react";
import ssgCrypto from "ssg.crypto";

class Hello extends React.Component {
	constructor() {
		super();
		this.state = {};
		this.generateSubGroupClick = this.generateSubGroupClick.bind( this );
	}

	generateSubGroupClick() {
		let qLength = prompt( "Length of q in bytes", 32 ) | 0;
		if ( !qLength ) {
			return;
		}
		let pLength = prompt( "Length of p in bytes", 512 ) | 0;
		if ( !pLength ) {
			return;
		}
		let dt = +new Date;

		ssgCrypto.queryRandomSchnorrGroupThen( pLength, qLength )
			.then(
				( { gRed, p, q, r, h } ) => {
					this.setState( {
						message: ( this.state.message || "" ) + `
							ms: ${+new Date - dt}
							r: ${r.toString(16)} (${r.byteLength()})
							p: ${p.toString(16)} (${p.byteLength()})
							q: ${q.toString(16)} (${q.byteLength()})
							h: ${h.toString(16)} (${h.byteLength()})
							g: ${gRed.toString(16)} (${gRed.byteLength()})
						`
					} );
				},
				error => console.error( error )
			);
	}

	render() {
		return (
			<div>
				<button onClick={ this.generateSubGroupClick }>Generate schnorr group</button>
				<br />
				<pre style={{ color: "black" }}>
					{ this.state.message }
				</pre>
			</div>
		);
	}
}

export default Hello;
