import React from "react";
import moment from "moment/min/moment-with-locales.js";
import Translated from "./abstract/translated.jsx";
import translate from "../translations/translate.js";

import serviceLocator from "../../api/services/locators/worker.client.js";

export default class Timestamp extends Translated {
	static get propTypes() {
		return {
			value: React.PropTypes.number.isRequired
		};
	}

	getValue() {
		return this.props.value;
	}

	render() {
		if ( this.props.mode === "server" ) {
			return <TimestampServer value={this.props.value} />;
		}
		let value = this.getValue();
		if ( !value ) {
			return <span>...</span>;
		}
		let ts = moment( value ).locale( translate.getCurrentLanguage().getId() );
		return <span>{ ts.calendar() }</span>;
	}
}

let timeResyncObservable;

class TimestampServer extends Timestamp {
	constructor() {
		super();
		this.state = {};
		if ( !timeResyncObservable ) {
			timeResyncObservable = serviceLocator().observeTimeResync();
			timeResyncObservable.subscribe();
		}
	}

	componentWillMount() {
		this._subscription = (
			timeResyncObservable
				.map( timeDiff => {
					let value = super.getValue();
					if ( !value ) {
						return value;
					}
					return value - timeDiff;
				} )
				.subscribe( value => {
					this.setState( { value } );
				} )
		);
	}

	componentWillUnmount() {
		this._subscription.dispose();
	}

	getValue() {
		return this.state.value;
	}
}
