import React from "react";

class FileSelect extends React.Component {
	constructor() {
		super();
		this.state = { version: 0 };
		this._onChange = this._onChange.bind( this );
	}
	_onChange( event ) {
		this.props.onFiles( event.target.files );
		this.setState( { version: this.state.version + 1 } ); //recreate file input
	}
	render() {
		return (
				<input
					type="file"
					multiple={ this.props.multiple }
					key={ this.state.version }
					onChange={ this._onChange }
					ref="input"
				/>
			);
	}
}

FileSelect.propTypes = {
	onFiles: React.PropTypes.func.isRequired
};
export default FileSelect;
