import Rx from "rx";
import _ from "lodash";

import ClientServerRPC from "./client.server.rpc.js";
import configuration from "../../common/configuration.js";

class HistorySyncConnection extends ClientServerRPC {
	constructor( apiUrlBase, connectionId, seedMacKey, seedEncryptionKey, signer,
		verifier, econfig, contactsService, multidescriptionId )  {
		if ( multidescriptionId !== ( multidescriptionId | 0 ) ) {
			throw new Error( "multidescriptionId required" );
		}
		super( apiUrlBase, connectionId, seedMacKey, seedEncryptionKey, signer,
			verifier, econfig );

		this._remoteMethods.getHistoryItems = this._remote_getHistoryItemsAsync;
		this._contactsService = contactsService;
		this._multidescriptionId = multidescriptionId;
	}

	_remote_getHistoryItemsAsync( { fromIndex, toIndex, sharedId } ) {
		return (
			this._contactsService
				.getContactBySharedIdAsync( new Buffer( sharedId, "base64" ) )
				.tap( contact => {
					if ( !contact || ( contact.multidescriptionId !== this._multidescriptionId ) ) {
						throw new Error( "Contact not found" );
					}
				} )
				.flatMap( contact => this._contactsService.getHistoryContainerAsync( contact.id ) )
				.flatMap( historyContainer => historyContainer.getItemsAsync( fromIndex, toIndex ) )
				.map( indexValues => _.pickBy( indexValues ) )
		);
	}

	queryHistoryItemsAsync( fromIndex, toIndex, contact ) {
		return this._callClientAsync( "getHistoryItems", {
			fromIndex, toIndex,
			sharedId: contact.sharedId.toString( "base64" )
		} );
	}

}

export default HistorySyncConnection;
