import _ from "lodash";

import downloadServiceLocator from "./locators/download.js";

class AudioDownloader {
  constructor() {
    this.cache = Object.create( null );
    this.queries = [];
    this.activeQuery = null;

    this._nextQuery = this._nextQuery.bind( this );
  }

  queryAudio( message ) {
    let messageId = message.id;
    if ( typeof messageId !== "string" ) {
      throw new Error( "messageId must be a string" );
    }
    if ( this.cache[ messageId ] ) {
      return this.cache[ messageId ];
    }

    let query = { message, index: message.index }
    let promise = new Promise( ( resolve, reject ) => {
      query.resolve = resolve;
      query.reject = reject;
    } );
    query.promise = promise;
    this.queries.push( query );

    setTimeout( this._nextQuery, 1 );
    return promise;
  }

  _setActiveQuery( message ) {
    let activeQuery = downloadServiceLocator().downloadFileAsBufferThen( message );
    this.activeQuery = activeQuery;
    this.cache[ message.id ] = this.activeQuery;
    activeQuery.then( () => {
      if ( this.activeQuery === activeQuery ) {
        this.activeQuery = null;
      }
      this._nextQuery();
    } ).catch( ( error ) => {
      delete this.cache[ message.id ];
      if ( this.activeQuery === activeQuery ) {
        this.activeQuery = null;
      }
      alert( "Error query audio: " + ( error.message || ( error + "" ) ) );
    } );
    return this.activeQuery;
  }

  _nextQuery() {
    if ( !this.queries.length || this.activeQuery ) {
      return;
    }

    let query = _.maxBy( this.queries, "index" );
    let index = _.indexOf( this.queries, query );
    this.queries.splice( index, 1 );
    this._setActiveQuery( query.message ).then( query.resolve, query.reject );
  }

  setCachedAudio( messageId, promise ) {
    if ( typeof messageId !== "string" ) {
      throw new Error( "messageId must be a string" );
    }
    if ( typeof promise.then !== "function" ) {
      throw new Error( "promise required" );
    }

    if ( this.cache[ messageId ] ) {
      console.warn( "Duplicate audio cache entry" );
      return;
    }
    this.cache[ messageId ] = promise;
  }

  cancelQuery( promise ) {
    if ( this.activeQuery === promise ) {
      this.activeQuery = null;
      // this._nextQuery(); this will be called once activeQuery actually completes
      return;
    }
    let index = _.findIndex( this.queries, { promise } );
    if ( !~index ) {
      return;
    }
    this.queries.splice( index, 1 );
  }

}

export default new AudioDownloader();
