import React from "react";
import TooltipButton from "./button.tooltip.jsx";
import Tinput from "./tinput.jsx";

class InputWithHelpComponent extends React.Component {
	static get propTypes() {
		return {
			tooltipTextId: React.PropTypes.string.isRequired
		};
	}

	render() {
		let { tooltipTextId, ...props } = this.props;
		return (
			<div className="input-with-help">
				<Tinput {...props} />
				<div className="info-button-wrapper">
					<TooltipButton type="question-mark" tooltipTextId={tooltipTextId}/>
				</div>
			</div>
		);
	}
}

export default InputWithHelpComponent;
