import Rx from "rx";
import _ from "lodash";
import React from "react";
import ssgCrypto, {KEY_KINDS} from "ssg.crypto";

import base64 from "../../../../common/utils/base64.js";
import configuration from "../../../common/configuration.js";

//TODO: use default econfig or config special for updates

class UpdatesView extends React.Component {
	constructor() {
		super();
		this.state = {
			signaturesData: []
		};
		this._econfig = configuration.getDefaultEncryptionConfig();
		this.onAppApkChange = this.onAppApkChange.bind( this );
		this.onSignGenerateClick = this.onSignGenerateClick.bind( this );
		this.onPrivKeyUpload = this.onPrivKeyUpload.bind( this );
	}

	getSignatureDataThen( privKeyBuffer ) {
		if ( !privKeyBuffer ) {
			return this._getSignatureDataThen();
		}
		return ssgCrypto.createKeyFromBufferThen( privKeyBuffer, KEY_KINDS.SIGNATURE_PRIVATE, this._econfig ).then(
			privKey => this._getSignatureDataThen( privKey )
		);
	}

	_getSignatureDataThen( privKey ) {
		let signer = ssgCrypto.createSigner( privKey, this._econfig );
		if ( !privKey ) {
			signer.setPrivateKeyRandom();
		}
		return (
			Promise.all( [
				signer.toPrivateBufferThen(),
				signer.toPublicBufferThen(),
				signer.makeSignThen( {
					getBufferThen: () => Promise.resolve(
						this.state.appApkBuffer || new Buffer( 0 )
					)
				} )
			] )
				.then( ( [ privKey, pubKey, signature ] ) =>
					( { privKey, pubKey, signature } )
				)
		);
	}

	onPrivKeyUpload( event ) {
		let file = event.target.files[ 0 ];
		let reader = new FileReader();
		let resultingObservable = (
			Rx.Observable.merge(
				Rx.Observable.fromEvent( reader, "load", event => {
					return new Buffer( new Uint8Array( reader.result ) );
				} ),
				Rx.Observable.fromEvent( reader, "error", event => event )
					.flatMap( error => Rx.Observable.throw( error ) )
			)
			.take( 1 )
		);

		reader.readAsArrayBuffer( file );

		resultingObservable
			.subscribe(
				fileContent => {
					this.getSignatureDataThen( fileContent )
						.then(
							data => {
								this.setState( {
									signaturesData: _( this.state.signaturesData )
										.concat( [ data ] ).value()
								} );
							}
						);

				}
			);
	}

	onSignGenerateClick() {
		this.getSignatureDataThen()
			.then(
				data => {
					this.setState( {
						signaturesData: _( this.state.signaturesData )
							.concat( [ data ] ).value()
					} );
				}
			);
	}

	onAppApkChange( event ) {
		let file = event.target.files[ 0 ];
		let reader = new FileReader();
		let resultingObservable = (
			Rx.Observable.merge(
				Rx.Observable.fromEvent( reader, "load", event => {
					return new Buffer( new Uint8Array( reader.result ) );
				} ),
				Rx.Observable.fromEvent( reader, "error", event => event )
					.flatMap( error => Rx.Observable.throw( error ) )
			)
			.take( 1 )
		);

		reader.readAsArrayBuffer( file );

		resultingObservable
			.subscribe(
				fileContent => {
					this.setState( {
						fingerprint: ssgCrypto.hash( fileContent ).toString( "hex" ),
						appApkBuffer: fileContent
					} );
					this.reCalculateSignatures();
				}
			);
	}

	reCalculateSignatures() {
		Promise.all(
			this.state.signaturesData
				.map( signatureData =>
					ssgCrypto.createKeyFromBufferThen( signatureData.privKey, KEY_KINDS.SIGNATURE_PRIVATE, this._econfig )
						.then( privKey => {
							let signer = ssgCrypto.createSigner( privKey, this._econfig );

							return signer.makeSignThen( {
								getBufferThen: () => Promise.resolve( this.state.appApkBuffer || new Buffer( 0 ) )
							} ).then( newSignature => { signatureData.signature = newSignature; } );
						} )
				)
		)
			.then( results => {
				this.forceUpdate();
			} );
	}

	render() {
		return (
			<div style={{ color: "black" }}>
				Upload apk file: <br />
				<input type="file" onChange={ this.onAppApkChange } /> <br />
				Signatures: <br />
				{
					_.map( this.state.signaturesData,
						( signatureData, index ) =>
							<div key={ signatureData.privKey.toString( "base64" ) + index }>
								<a href={ "data:application/octet-stream;base64," + signatureData.privKey.toString( "base64" ) }>
									Download priv key
								</a>
								<br />
								Public key:
								<br />
								{ signatureData.pubKey.toString( "base64" ) }
								<br />
								<a style={{color:"initial"}} href={ "data:application/octet-stream;base64," + signatureData.signature.toString( "base64" ) }>
									Download signature
								</a>
							</div>
					)
				}

				Generate private key:
				<br />
				<button onClick={ this.onSignGenerateClick } >
					Generate
				</button>
				<br />
				Upload private key
				<input type="file" onChange={ this.onPrivKeyUpload } />
			</div>
		);
	}
}

export default UpdatesView;
