"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var BitContainer = function () {
	function BitContainer(initialByteSize) {
		_classCallCheck(this, BitContainer);

		this._buffer = new Buffer(initialByteSize || 16);
		this._buffer.fill(0);
		this._bitCount = 0;
	}

	_createClass(BitContainer, [{
		key: "ensureByteSize",
		value: function ensureByteSize(byteSize) {
			if (this._buffer.length >= byteSize) {
				return;
			}

			var newBuffer = new Buffer(qItem.length);
			newBuffer.fill(0);
			if (this._bitCount) {
				this._buffer.copy(newBuffer, 0, 0, this._bitCount + 7 >> 3);
			}
			this._buffer = newBuffer;
		}
	}, {
		key: "addBits",
		value: function addBits(int32, bitCount) {
			int32 = Math.abs(int32);

			if (!bitCount) {
				var _bitsLeft = 20;
				if (this._buffer.length * 8 < this._bitCount + _bitsLeft) {
					_bitsLeft = this._buffer.length * 8 - this._bitCount;
				}
				while (int32 > 1 && _bitsLeft) {
					//pushBit
					this._buffer[this._bitCount >> 3] |= (1 & int32) << (this._bitCount & 7);
					int32 >>>= 1;
					this._bitCount++;
					_bitsLeft--;
				}
				return;
			}

			var bitsLeft = bitCount;
			if (this._buffer.length * 8 < this._bitCount + bitsLeft) {
				bitsLeft = this._buffer.length * 8 - this._bitCount;
			}
			while (bitsLeft) {
				//pushBit
				this._buffer[this._bitCount >> 3] |= (1 & int32) << (this._bitCount & 7);
				int32 >>>= 1;
				this._bitCount++;
				bitsLeft--;
			}
		}
	}, {
		key: "getFullByteCount",
		value: function getFullByteCount() {
			return this._bitCount >> 3;
		}
	}, {
		key: "takeBytes",
		value: function takeBytes(count) {
			if (this._bitCount < count << 3) {
				return null;
			}
			var result = new Buffer(count);
			this._buffer.copy(result, 0, 0, count);
			this._buffer.copy(this._buffer, 0, count, this._bitCount + 7 >> 3);
			this._bitCount -= count << 3;
			return result;
		}
	}, {
		key: "clear",
		value: function clear() {
			this._buffer.fill(0);
			this._bitCount = 0;
		}
	}, {
		key: "getBitCount",
		value: function getBitCount() {
			return this._bitCount;
		}
	}]);

	return BitContainer;
}();

exports.default = BitContainer;