import Rx from "rx";

import OneWaySender from "./oneway.sender.js";
import { receiveMessageAsync } from "../models/message/technical.js";

class MailboxSender {
	constructor( dataOrToken, onError ) {
		this._connectionAsync = new Rx.ReplaySubject( 1 );
		let dataSource = (
			typeof dataOrToken === "string"
			? receiveMessageAsync( dataOrToken )
			: Rx.Observable.just( dataOrToken )
		);
		this._createConnectionSubscription = (
			dataSource
				.subscribe( ( { apiUrlBase, connectionId, dhPubKey, encryptionSaltKey,
					macSaltKey, econfig, type } ) => {
					if ( this._isDisposed ) {
						return;
					}
					if ( ( type !== "mailboxSender" ) && ( type !== "activation" ) ) {
						throw new Error( "Invalid message type for mailbox sender" );
					}
					this._connectionAsync.onNext(
						new OneWaySender( apiUrlBase, connectionId, dhPubKey,
							encryptionSaltKey, macSaltKey, econfig )
					);
					this._connectionAsync.onCompleted();
				}, onError )
		);
	}

	sendMessageAsync( message ) {
		if ( this._isDisposed ) {
			throw new Error( "Mailbox sender disposed" );
		}
		return (
			this._connectionAsync.flatMap( connection =>
				connection.sendMessageAsync( message )
			)
		);
	}

	dispose( ) {
		if ( this._isDisposed ) {
			throw new Error( "Already disposed" );
		}
		this._connectionAsync.subscribe( connection => {
			connection.dispose();
		} );
		this._connectionAsync.onCompleted();
		this._createConnectionSubscription.dispose();
		this._isDisposed = true;
	}
}

export default MailboxSender;
