import Rx from "rx";
import React from "react";
import ReactDOM from "react-dom";

import serviceLocator from "../../../api/services/locators/worker.client.js";
import configuration from "../../../common/configuration.js";
import {bindStateProperty} from "../../../common/utils.js";

import TopLevel from "../common/top.level.view.jsx";
import InputInviteView from "../registration/input.invite.jsx";
import Checkbox from "../../components/checkbox.jsx";
import InputWithHelp from "../../components/input.withhelp.jsx";
import Translation from "../../components/translation.jsx";
import ArrowButton from "../../components/button.continue.jsx";
import history from "../../components/history.js";
import translate from "../../translations/translate.js";
import FullScreen from "../../components/fullscreen.jsx";
import ShakeView from "../shake/index.jsx";
import SetAutoclean from "../options/autoclean.jsx";
import Contact from "../../../api/models/contact.js";
import {DynamicToast} from "../../components/toast.jsx";
import {InternetRetryConfirm} from "../../components/dialogs.jsx";
import ImageButton from "../../components/button.image.jsx";
import ButtonQrView from "../../components/button.qr.jsx";

class UpgradeView extends React.Component {
  render() {
    return (
      <FullScreen>
        <TopLevel className="registration-page">
          <UpgradeHeader onBack={ this.props.onBack }/>
          <UpgradeBody/>
        </TopLevel>
      </FullScreen>
    );
  }
}

class UpgradeHeader extends React.Component {
	constructor() {
		super();
		this.state = {
			qrCallback: null
		};
	}
	componentWillMount() {
		this._qcCallbackSubscription = (
			this.context.observeQrCallback().subscribe(qrCallback => {
				this.setState({qrCallback});
			})
		);
	}
	componentWillUnmount() {
		this._qcCallbackSubscription.dispose();
	}
	renderQrButton() {
		let {qrCallback} = this.state;
		if ( !qrCallback ) {
			return null;
		}
		return <ButtonQrView onScan={ qrCallback } />;
	}

	renderBackButton() {
		if ( !this.props.onBack ) {
			return null;
		}
		return <ImageButton type="icon-arrow-left" onClick={this.props.onBack}/>;
	}

	render() {
		return (
			<header className="small">
				{ this.renderBackButton() }
				<span className="header-caption">
					<Translation textId="upgrade.header" />
				</span>
				{ this.renderQrButton() }
			</header>
		);
	}

	static get contextTypes() {
		return { observeQrCallback: React.PropTypes.func.isRequired };
	}
}

class UpgradeBody extends React.Component {
	constructor() {
		super();
		this._service = serviceLocator();
		this.state = {
			fakeAcc: false,
			isValid: false,
			inviteData: null,
			passwordFake: "",
			inviteToken: "",
			authPassword: ""
		};
		this.onInviteSet         = this.onInviteSet.bind( this );
		this.toggleFakeAccount   = this.toggleFakeAccount.bind( this );
		this.onContinueClick     = this.onContinueClick.bind( this );
    this.validate            = this.validate.bind( this );
	}

	componentWillMount() {
		this.context.setQrCallback(this.setInviteCode.bind( this ));
		this.validate();
	}

	componentDidMount() {
	}

	onInviteSet( inviteData ) {
		this.setInviteData( inviteData );
	}

	toggleFakeAccount() {
		this.setState( { fakeAcc: !this.state.fakeAcc }, this.validate );
	}

	componentDidUpdate( prevProps, prevState ) {
		if ( !prevState.fakeAcc && this.state.fakeAcc ) {
			let element = ReactDOM.findDOMNode( this.refs.scrollingNode );
			element.scrollTop = element.scrollHeight;
		}
	}

	setInviteData( inviteData ) {
		this.setState( { inviteData }, this.validate );
	}

	setInviteCode( text ) {
		let [ inviteToken, authPassword ] = text.split( "|" );
		this.setState( { inviteToken, authPassword } );
	}

	validate() {
		let { inviteData } = this.state;
		let isValid = !!inviteData;
		this.setState( { isValid } );
	}

	getRegistrationData() {
		let { fakeAcc, passwordFake } = this.state;
		if ( fakeAcc ) {
			return { passwordFake };
		}
		return {};
	}

	onContinueClick() {
		let { inviteData, inviteToken } = this.state;
		let { requireInviteCode } = this.props;
		let registrationData = this.getRegistrationData();

		if ( !inviteData && requireInviteCode ) {
			return;
		}
    this.setState( { isLoading: true } );
		this._service.upgradeAsync( registrationData, inviteToken )
			.subscribe(
				() => {
          alert( translate( "demo.dialog.complete" ) );
          this._service.reload();
				},
				error => {
					if ( error.xhr ) {
						this.refs.noInternetRegister.show();
					} else {
						debugger;
						this.refs.toast.show( { content: `${error.message}` } );
						console.error( error );
						alert( "There was an error during upgrade" );
					}
				}
			);
	}


	render() {
		let { passwordFake, fakeAcc, inviteToken, authPassword } = this.state;
		return (
			<main ref="scrollingNode">
				<span className="small-text">
					<Translation textId="upgrade.text" />
				</span>
				<InputInviteView
					token={inviteToken}
					authPassword={authPassword}
					onTokenChange={bindStateProperty( this, "inviteToken" )}
					onInviteSet={this.onInviteSet}
					type="activation/createUserSystem"
				/>

				<div className="fake-account-panel row">
					<Checkbox className="f-right" value={!!fakeAcc} onClick={this.toggleFakeAccount}/>
					<div className="label">
						<Translation textId="upgrade.fake.checkbox"/>
					</div>
					{fakeAcc && (
						<FakePanel
							password={passwordFake}
							passwordChange={bindStateProperty( this, "passwordFake", this.validate )}
						/>
					)}
				</div>
				<ArrowButton
					enabled={this.state.isValid}
					caption="upgrade.continue.button"
					handleClick={this.onContinueClick}
					id="continue"
				/>
				<DynamicToast ref="toast"/>
				<InternetRetryConfirm ref="noInternetRegister" onConfirm={this.onContinueClick} onReset={this.props.onBack}/>
			</main>
		);
	}
	static get contextTypes() {
		return { setQrCallback: React.PropTypes.func.isRequired };
	}
}

class Panel extends React.Component {
	render() {
		let { password, passwordChange, ...divProps } = this.props;
		return (
			<div {...divProps}>
				<InputWithHelp
					type="password"
					value={password} onChange={passwordChange}
					placeholderTextId="upgrade.password.placeholder"
					tooltipTextId="upgrade.password.tooltip"
				/>
			</div>
		);
	}
}

class FakePanel extends React.Component {
	render() {
		return (
			<div>
				<span className="small-text">
					<br/>
					<Translation textId="upgrade.fake.text" />
				</span>

				<Panel {...this.props} />
			</div>
		);
	}
}
export default UpgradeView;
