import SocketContainer from "../socket.container.js";

let instance = null;
let mockedInstance = null;

export default function() {
	if ( mockedInstance ) {
		return mockedInstance;
	}
	if ( !instance ) {
		instance = new SocketContainer();
	}
	return instance;
};

export function mock( newMockedInstance ) { mockedInstance = newMockedInstance; };

export function unmock() { mockedInstance = null; };
