import React from "react";
import _ from "lodash";
import classNames from "classnames";

import Translation from "../../../components/translation.jsx";
import Confirm from "../common/confirm.jsx";
import PromptContacts from "../common/prompt.contacts.jsx";
import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class WorkgroupView extends React.Component {
	constructor() {
		super();
		this.state = { activeView: "list" };
		this.onAdd = this.onAdd.bind( this );
		this.backFromAdd = this.backFromAdd.bind( this );
	}

	backFromAdd() {
		this.setState( { activeView: "list" } );
	}

	onAdd() {
		this.setState( { activeView: "add" } );
	}

	render() {
		if ( !this.props.contact ) {
			return null;
		}
		switch( this.state.activeView ) {
			case "list":
				return <WorkgroupMembersView
					className={this.props.className}
					contact={this.props.contact}
					onDone={this.props.onDone}
					onBack={this.props.onBack}
					onAdd={this.onAdd}
				/>;
			case "add":
				return <WorkgroupAddView
					className={this.props.className}
					contact={this.props.contact}
					onBack={this.backFromAdd}
				/>;
		}
	}

	static get propTypes() {
		return {
			contact: React.PropTypes.object,
			onDone: React.PropTypes.func.isRequired,
			onBack: React.PropTypes.func.isRequired
		};
	}
}

class WorkgroupMembersView extends React.Component {
	constructor() {
		super();
		this._service = remoteServiceLocator();
		this.onRemove = this.onRemove.bind( this );
		this.onRemoveInvite = this.onRemoveInvite.bind( this );
		this.state = {
			removeInProgress: []
		};
	}

	componentWillMount() {
		this._rightsSubscription = (
			this._service.observeSelfRights( this.props.contact )
				.subscribe( ({allowModifyWorkgroup}) => {
					this.setState( {allowEdit: allowModifyWorkgroup} );
				} )
		);
		this._participantsSubscription = (
			this._service.observeWorkgroupParticipants( this.props.contact )
				.subscribe( participants => {
					this.setState( { participants } );
				} )
		);
		this._invitesSubscription = (
			this._service.observeWorkgroupInvites( this.props.contact )
				.subscribe( invites => {
					this.setState( { invites } );
				} )
		);
	}

	componentWillUnmount() {
		this._rightsSubscription.dispose();
		this._participantsSubscription.dispose();
		this._invitesSubscription.dispose();
	}

	onRemove( pid ) {
		this.setState( {removeInProgress: this.state.removeInProgress.concat( [pid] )} );
		this._service.removeWorkgroupParticipantAsync( this.props.contact, pid )
			.subscribeOnError( error => {
				this.setState( {removeInProgress: _.without( this.state.removeInProgress, pid ) } );
				//TODO: handle error
				alert( error.message );
			} );
	}

	onRemoveInvite( pid ) {
		this.setState( {removeInProgress: this.state.removeInProgress.concat( [pid] )} );
		this._service.removeWorkgroupInviteAsync( this.props.contact, pid )
			.subscribeOnError( error => {
				this.setState( {removeInProgress: _.without( this.state.removeInProgress, pid ) } );
				//TODO: handle error
				alert( error.message );
			} );
	}

	renderParticipants() {
		return (
			_.map( this.state.participants,
				({nickname}, pid) =>
					<Participant
						nickname={nickname}
						allowEdit={this.state.allowEdit}
						onRemove={this.onRemove}
						pid={pid}
						key={pid}
					/>
			)
		);
	}

	renderInvites() {
		return (
			_.map( this.state.invites,
				({nickname}, pid) =>
					<Participant
						nickname={nickname}
						allowEdit={this.state.allowEdit}
						onRemove={this.onRemoveInvite}
						pid={pid}
						key={pid}
					/>
			)
		);
	}

	render() {
		return (
			<div className={this.props.className} key={this.props.contact.id}>
				<button
					className="btn btn-green"
					onClick={this.props.onAdd}>
					<Translation textId="web.workgroup.add.button" />
				</button>

				<div className="list" style={{padding: "20px"}}>
					{ this.renderParticipants() }
					{ _.isEmpty( this.state.invites )
					 ? null
				 	 : <div style={{textAlign: "center", color: "gray" }}>Invites:</div>
			 		}
					{ this.renderInvites() }
				</div>
			</div>
		);
	}

	static get propTypes() {
		return {
			contact: React.PropTypes.object.isRequired,
			onDone: React.PropTypes.func.isRequired,
			onBack: React.PropTypes.func.isRequired
		};
	}
}

class Participant extends React.Component {
	constructor() {
		super();
		this.state = {};
		this.onRemove = this.onRemove.bind( this );
	}

	confirmRemove( isConfirmed ) {
		if ( this.state.inProgress ) {
			return;
		}
		if ( !isConfirmed ) {
			this.setState( { popup: null } );
			return;
		}
		this.props.onRemove( this.props.pid );
		this.setState( {inProgress: true} );
	}

	onRemove() {
		this.setState( {
			popup: () =>
			<Confirm
				titleTextId="web.workgroup.remove.title"
				yesButtonTextId="web.workgroup.remove.button.yes"
				onDone={ this.confirmRemove.bind( this ) }
			/>
		} );
	}

	renderControls() {
		if ( !this.props.allowEdit ) {
			return null;
		}
		return (
			<div style={{cssFloat: "right"}}>
				<a href="javascript:;" onClick={this.onRemove}>Remove</a>
			</div>
		);
	}

	renderPopup() {
		if ( !this.state.popup ) {
			return null;
		}
		return this.state.popup();
	}

	render() {
		return (
			<div className="checkbox">
				<div style={{cssFloat: "left"}}>
					{this.props.nickname}
					<div className="date">{this.props.text}</div>
				</div>
				{this.renderControls()}
				{this.renderPopup()}
				<div style={{clear:"both"}}></div>
			</div>
		);
	}
}

class WorkgroupAddView extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedContactIds: []
		};
		this.onNextClick = this.onNextClick.bind( this );
		this.onChange = this.onChange.bind( this );
		this._service = remoteServiceLocator();
	}

	componentWillMount() {
		this._subscription = (
			this._service.observeContactList()
				.subscribe( contacts => {
					this.setState( {contacts} );
				} )
		);
	}

	componentWillUnmount() {
		this._subscription.dispose();
	}

	onNextClick() {
		if ( this.state.selectedContactIds.length === 0 ) {
			return;
		}

		if ( this.state.inProgress ) {
			return;
		}
		this.setState( {inProgress: true} );
		Rx.Observable.fromArray( this.state.selectedContactIds )
			.map( contactId => _.find( this.state.contacts, {id: contactId} ) )
			.filter( contact => !!contact )
			.concatMap( contact => this._service.addToWorkGroupAsync( this.props.contact, contact ) )
			.toArray()
			.subscribe( () => {
				this.props.onBack();
			} );
	}

	onChange( selectedContactIds ) {
		if ( this.state.inProgress ) {
			return;
		}
		this.setState( {selectedContactIds} );
	}

	render() {
		if ( !this.state.contacts ) {
			return null;
		}
		let nextClassName = classNames(
			"btn btn-green",
			{ disabled: this.state.selectedContactIds.length === 0 }
		);
		return (
				<div className={this.props.className}>
					<button
						className="btn btn-green"
						onClick={this.props.onBack}>
						<Translation textId="web.workgroup.add.back.button" />
					</button>
					<div style={{padding: "20px"}}>
						<Translation textId="web.workgroup.add.text" />
						<PromptContacts
							contacts={this.state.contacts}
							onChange={this.onChange}
							selectedIds={this.state.selectedContactIds}
						/>
					</div>
					<button
						className={nextClassName}
						onClick={this.onNextClick}>
						<Translation textId="web.workgroup.add.continue" />
					</button>
				</div>
			);
	}
}

export default WorkgroupView;
