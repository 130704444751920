import React from "react";

import Translation from "../../../components/translation.jsx";
import SearchButton from "./button.search.jsx";
import Tinput from "./tinput.jsx";

class ContactListListTitle extends React.Component {
	constructor() {
		super();
		this.onSearch = this.onSearch.bind( this );
		this.offSearch = this.offSearch.bind( this );
		this.onSearchUpdate = this.onSearchUpdate.bind( this );
		this.onKeyDown = this.onKeyDown.bind( this );
	}

	onSearch() {
		this.props.onSetSearchString( "" );
	}

	offSearch() {
		this.props.onSetSearchString( null );
	}

	onSearchUpdate( value ) {
		this.props.onSetSearchString( value );
	}
//
	renderSearchButton() {
		if ( !this.props.onSetSearchString ) {
			return null;
		}
		return (
			<button
				className="newChat btn-transparent search-svg"
				onClick={ this.onSearch }
			><SearchButton />
			</button>
		);
	}

	renderNewChatButton() {
		if ( !this.props.onAddClick ) {
			return null;
		}
		return (
			<button
				className="newChat btn-transparent"
				onClick={ this.props.onAddClick }
			>+
			</button>
		);
	}

	onKeyDown( event ) {
		if ( event.key === "Escape" ) {
			this.offSearch();
		}
	}

	renderSearch() {
		return (
			<div>
				<Tinput
					className="search-input"
					type="text"
					placeholderTextId={ this.props.placeholderTextId }
					value={ this.props.searchString }
					onChange={ this.onSearchUpdate }
					id="search"
					autofocus={true}
					onKeyDown={this.onKeyDown}
				/>
				<button
					className="newChat btn-transparent"
					onClick={ this.offSearch }
				>x
				</button>
			</div>
		);
	}

	render() {
		if ( this.props.searchString !== null ) {
			return this.renderSearch();
		}
		return (
			<div>
				<Translation textId={this.props.textId} />
				{ this.renderSearchButton() }
				{ this.renderNewChatButton() }
			</div>
		);
	}
}

export default ContactListListTitle;
