import React from "react";
import translate from "../../translations/translate.js";

class TranslatedComponent extends React.Component {
	constructor() {
		super();
		let componentDidMount = this.componentDidMount;
		let componentWillUnmount = this.componentWillUnmount;

		this.componentDidMount = function() {
			this.translatedComponentComponentDidMount();
			componentDidMount && componentDidMount.apply( this );
		};
		this.componentWillUnmount = function() {
			this.translatedComponentComponentWillUnmount();
			componentWillUnmount && componentWillUnmount.apply( this );
		};
	}
	translatedComponentComponentDidMount() {
		this.languageChangeSubscription = (
			translate.observeLanguageChanges()
				.subscribe( () => this.forceUpdate() )
			);
	}
	translatedComponentComponentWillUnmount() {
		this.languageChangeSubscription.dispose();
		this.languageChangeSubscription = null;
	}
	translate( key, ...params ) {
		let text = translate( key );

		if ( typeof text === "string" ) {
			for ( let i = 0; i < params.length; i++ ) {
				text = text.split( `{${i}}` ).join( params[ i ] );
			}
		} else if ( typeof text === "function" ) {
			text = text( ...params );
		} else if ( params.length ) {
			throw new Error( `translation text ${key} must be a func or string to be parameterized` );
		}
		return text;
	}
}

export default TranslatedComponent;
