import Rx from "rx";
import ssgCrypto,{Key,KEY_KINDS,Config} from "ssg.crypto";
import Queue from "promise-queue";

import ClientServerOneWay from "./client.server.oneway.js";
import Transaction from "./transaction.js";
import {callUntilSuccessAsync, key2StringAsync} from "../../common/utils.js";
import {deserializeFromManagedBufferThen} from "../../api/models/message/technical.js";

class OneWayReceiver {
	constructor( apiUrlBase, connectionId, dhPrivKey, encryptionSaltKey,
		macSaltKey, startIndex, econfig, onMessage ) {
		if ( !( dhPrivKey instanceof Key ) ) {
			throw new Error( "dhPubKey must be a key" );
		}
		if ( !( encryptionSaltKey instanceof Key ) ) {
			throw new Error( "encryptionSaltKey must be a key" );
		}
		if ( !( macSaltKey instanceof Key ) ) {
			throw new Error( "macSaltKey must be a key" );
		}
		if ( dhPrivKey.kind !== KEY_KINDS.KEY_EXCHANGE_PRIVATE ) {
			throw new Error( "dhPrivKey must be KEY_EXCHANGE_PRIVATE" );
		}
		if ( encryptionSaltKey.kind !== KEY_KINDS.INTERMEDIATE ) {
			throw new Error( "encryptionSaltKey must be INTERMEDIATE" );
		}
		if ( macSaltKey.kind !== KEY_KINDS.INTERMEDIATE ) {
			throw new Error( "macSaltKey must be INTERMEDIATE" );
		}
		if ( !( econfig instanceof Config ) ) {
			throw new Error( "econfig must be Config" );
		}
		if ( typeof onMessage !== "function" ) {
			throw new Error( "onMessage must be a function" );
		}
		if ( startIndex !== (startIndex|0) ) {
			throw new Error( "startIndex required" );
		}
		this._connection = new ClientServerOneWay( apiUrlBase, connectionId, true );
		this._dhPrivKey = dhPrivKey;
		this._encryptionSaltKey = encryptionSaltKey;
		this._macSaltKey = macSaltKey;
		this._econfig = econfig;
		this._onMessage = onMessage;
		this._queue = new Queue( 1 );
		this._connection.onGotMessage( this.gotMessage.bind( this ) );
		//TODO: disconnect on dispose
		//TODO: store start index
		this._index = startIndex;
		this._messages = Object.create( null );
		this.listen();
		this._connection.onConnected( () => this.listen() );
	}

	listen( ) {
		callUntilSuccessAsync( this._connection.tryListen.bind( this._connection, this._index ) ).subscribe();
	}

	gotMessage( message ) {
		console.log('Got oneway message', message, this._index);
		let {index} = message;
		if ( this._messages[ index ] || ( this._index > index ) ) {
			return;
		}
		this._messages[ index ] = message;
		while ( this._messages[ this._index ] ) {
			this.gotMessageOrdered( this._messages[ this._index ] );
			delete this._messages[ this._index ];
			this._index++;
		}
	}

	gotMessageOrdered( { body, index } ) {
		let bodyBuffer = new Buffer( body, "base64" );
		let dhPubKeySize = this._econfig.getKeySize( KEY_KINDS.KEY_EXCHANGE_PUBLIC );
		let dhPubBuffer = bodyBuffer.slice( 0, dhPubKeySize );
		let encrypted = bodyBuffer.slice( dhPubKeySize, bodyBuffer.length - 32 );
		let macCode = bodyBuffer.slice( bodyBuffer.length - 32 );
		this._queue.add( () =>
			ssgCrypto.createKeyFromBufferThen( dhPubBuffer, KEY_KINDS.KEY_EXCHANGE_PUBLIC, this._econfig )
				.then( dhPubKey => ssgCrypto.createSharedKeyThen( this._dhPrivKey, dhPubKey, this._econfig )
					.then( sharedKey => { dhPubKey.dispose(); return sharedKey; } )
				)
				.then( sharedKey => Promise.all( [
					this._makeEncryptionKeyThen( sharedKey ), //SYMM
					this._makeMacKeyThen( sharedKey ) //MAC
				] ) )
				.then( ( [ encryptionKey, macKey ] ) =>
					ssgCrypto.makeHmacCodeThen( macKey, encrypted, this._econfig )
						.then( macCode2 => {
							if ( !macCode.equals( macCode2 ) ) {
								throw new Error( "Mac code mismatch" );
							}
							let managedBuffer = ssgCrypto.createManagedBuffer(
								ssgCrypto.getDecryptedSize( encrypted.length, this._econfig )
							);
							return (
								ssgCrypto.decryptToManagedBufferThen(
									encrypted, managedBuffer, encryptionKey, true, this._econfig
								).then( () => managedBuffer )
							);
						} )
				)
				.then( managedBuffer => deserializeFromManagedBufferThen( managedBuffer, this._econfig )
			 		.then( message => {
						managedBuffer.dispose();
						return message;
					} )
				)
				.catch( error => {
					this._onMessage( null, error );
					return error;
				} )
				.then( message => new Promise( ( resolve, reject ) => {
					( this._onMessage( message ) || Rx.Observable.empty() )
						.subscribe(
							() => {},
							reject,
							resolve
						);
				} ) )
				.then( message =>
					this._onIndexChangeThen( index )
				)
		);
	}

	_onIndexChangeThen( index ) {
		let res = this._onIndexUpdate && this._onIndexUpdate( index );
		if ( !res ) {
			return Promise.resolve();
		}
		return new Promise( resolve => { res.subscribeOnCompleted( resolve ); } );
	}

	onIndexUpdate( func ) {
		if ( this._onIndexUpdate ) {
			throw new Error( "onIndexUpdate already set" );
		}
		if ( typeof func !== "function" ) {
			throw new Error( "onIndexUpdate hendler must be a function" );
		}
		this._onIndexUpdate = func;
		return this;
	}

	_makeEncryptionKeyThen( sharedKey ) {
		return (
			ssgCrypto.createDerivedKeyFromKeysThen(
				sharedKey,
				this._encryptionSaltKey,
				KEY_KINDS.SYMMETRIC_ENCRYPTION,
				this._econfig
			)
		);
	}

	_makeMacKeyThen( sharedKey ) {
		return (
			ssgCrypto.createDerivedKeyFromKeysThen(
				sharedKey,
				this._macSaltKey,
				KEY_KINDS.MAC,
				this._econfig
			)
		);
	}

	getMaxIndexAsync( ) {
		return callUntilSuccessAsync( cb =>
			this._connection.tryGetMaxIndex( cb )
		).map( ( { maxIndex } ) => maxIndex );
	}

	dispose( ) {
		callUntilSuccessAsync( this._connection.tryStopListen.bind( this._connection ) )
			.subscribe( () => {
				this._connection.dispose();
			} );
	}
}

export default OneWayReceiver;
