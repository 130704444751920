import React from "react";
import ReactDOM from "react-dom";
import Rx from "rx";
import _ from "lodash";

import configuration from "../../../common/configuration.js";
import serviceLocator from "../../../api/services/locators/worker.client.js";
import TopLevel from "../common/top.level.view.jsx";
import InputTokenView from "./input.token.jsx";
import ArrowButton from "../../components/button.continue.jsx";
import Translation from "../../components/translation.jsx";
import ImageButton from "../../components/button.image.jsx";
import ButtonQrView from "../../components/button.qr.jsx";

class RestoreView extends React.Component {
	constructor() {
		super();
		this.state = {};
		this.onTokenChange = this.onTokenChange.bind( this );
		this.onDataSet = this.onDataSet.bind( this );
		this.onContinueClick = this.onContinueClick.bind( this );
		this.cleanData = this.cleanData.bind( this );
		this.onScan = this.onScan.bind( this );
		this._service = serviceLocator();
	}

	onScan( text ) {
		let parts = text.split( "|" );
		let token = parts[ 0 ];
		let authPassword = parts[ 1 ] || "";
		this.setState( { token, authPassword } );
	}
	onTokenChange( token ) {
		this.setState( { token } );
	}

	onDataSet( data ) {
		this.setState( { data } );
	}
	cleanData() {
		this.setState( { data: null } );
	}

	onContinueClick() {
		let { token, isInProgress, data } = this.state;
		if ( isInProgress || !data) {
			return;
		}
		this.setState( { isInProgress: true } );
		configuration.setConfiguration( data.config );
		this._service.setConfigurationAsync( data.config )
			.flatMap( () => this._service.restoreBackupAsync( this.state.token ) )
			.subscribe( ( { index, total } ) => {
				this.setState( { index, total } );
			}, error => {
				alert( error.message || error );
			}, () => {
				this.props.onDone();
			} );
	}

	renderProgress() {
		let {index, total} = this.state;
		if ( !total ) {
			return null;
		}
		return <Translation textId="restore.progress" params={[index, total]} />
	}

	render() {
		let {token, data, isInProgress} = this.state;
		return (
			<TopLevel id="restore">
				<header>
					<ImageButton type="icon-arrow-left" onClick={this.props.onBack}/>
					<span className="header-caption">
						<Translation textId="restore.header"/>
					</span>
					<ButtonQrView onScan={ this.onScan }/>
				</header>
				<main>
					<div>
						<span className="small-text">
							<br/>
							<Translation textId="restore.text" />
							<InputTokenView
								token={token}
								authPassword=""
								onTokenChange={this.onTokenChange}
								onDataSet={this.onDataSet}
								cleanData={this.cleanData}
							/>
							<ArrowButton
								enabled={!!data && !isInProgress}
								caption="restore.do.button"
								handleClick={this.onContinueClick}
								id="continue"
							/>
						{this.renderProgress()}
						</span>
					</div>
				</main>
			</TopLevel>
		);
	}
}

export default RestoreView;
