import ssgCrypto from "ssg.crypto";

import base64 from "../common/utils/base64.js";
//import dataUriLoader from "./crypto/data.uri.loader.js";

ssgCrypto.disableFakeSource();
let host = "https://kryptos.secserv.me:44302";
// let host = "";
console.warn("Enterprise /config included");

let config = {
	apiBase: host,
	socketBase: host,
	defaultTokenMode: 1,
	identifierByteSize: 32,
	pushNotifications: {
		"android": {
			senderID: "296310413136"
		},
		"ios": {
			senderID: "296310413136",
			gcmSandbox: "true",
			alert: "true",
			badge: true,
			sound: "true"
		}
	},
	appId: "pgm",
	updateSignatures: [],
	defaultEncryptionConfig: {
		cipherAlgorithm: "aes-256-cbc",
		kdfPassword: "scrypt",
		kdfPasswordParam: {
			N: 3, //1<<3=8
			r: 8
		},
		kdfKeyed: "pbkdf2",
		kdfKeyedParam: {
			c: 7,
			klass: "HMAC_SHA256"
		},
		signatureAlgorithm: "ed25519",
		signatureParam: null,
		keyExchange: "dh25519",
		keyExchangeParam: null,
		hmacAlgorithm: "hmac-sha-256"
	},
	contactRecordCount: 1024,
	transactionRetryDelay: 2000,
	networkTimeout: 20000,
	helperContactToken: "",
	masterKeyReseedCount: 9,
	buildMode: "enterprise",
	isHashConnectionIds: true,
	testUtils: true,
	defaultAutocleanTime: 365.25 * 24 * 3600,
	webTimeout: 10 * 60000,
	privateServer: {
		minAdmins: 2,
		recommendedAdmins: 3,
		maxPrivileged: 5,
		maxUsers: 1000
	},
	// showWorkgroups: true,
};

export default config;
