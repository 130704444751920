import _ from "lodash";
import Rx from "rx";
import ssgCrypto, {KEY_KINDS, Config} from "ssg.crypto";

import Multicast from "../transport/multicast.js";
import Contact from "../models/contact.js";
import configuration from "../../common/configuration.js";
import {
	deserializeFromBase64StringAsync,
	receiveMessageAsync,
	serializeMessageToBase64StringAsync,
	sendMessageAsync
} from "../models/message/technical.js";
import {
	ChatMessageBase,
	ChatMessageText,
	ChatMessageContact,
	ChatMessageWorkgroup,
	ChatMessageGroup,
	MESSAGE_TYPE
} from "../models/chat.message.js";
import ContactWithHistoryService from "./contact.with.history.js";
import sharedcontactsServiceLocator from "./locators/sharedcontacts.js";
import OneWaySender from "../transport/oneway.sender.js";
import testUtils from "../test.utils.js";

if ( configuration.getIsTestUtils() ) {
	console.warn( "Including testUtils" );
	global.testUtils = testUtils;
}

class ContactsService extends ContactWithHistoryService {
	constructor( ) {
		super( "normal", sharedcontactsServiceLocator() );
		this.isSendReadMessage = true;
		this._onCleanHistory = this._onCleanHistory.bind( this );

		this._acceptSharedContactsQueue = new Rx.Subject();
		this._acceptSharedContactsQueue.concatAll().subscribe();
	}

	getParticipantNameOrNull( contactId, pid ) {
		let contact = _.find( this._contacts, c => c.id === contactId );
		if ( !contact ) {
			return null;
		}
		if ( !contact.pid ) {
			return null;
		}

		if ( contact.pid.toString( "base64" ) === pid ) {
			return "Me";//TODO: use translation
		}

		if ( contact.multidescriptionId !== -1 ) {
			let privateName = sharedcontactsServiceLocator().getPrivateNameOrNull( contact, pid );
			if ( privateName ) {
				return privateName;
			}
		}

		return null;//contact.name;
	}

	initAsync( encryptionKey, positionBit ) {
		return (
			super.initAsync( encryptionKey, positionBit )
				.tapOnCompleted( () => {
					this._fullyLoadedObservable.subscribeOnCompleted( () => {
						this._contactRepository.startCreatingRandomFiles();
					} );
				} )
		);
	}

	_inviteToTokenAsync( invite ) {
		return (
			sendMessageAsync( {...invite, type: "invite" } )
				.tap( () => {
					invite.tmpPrivateKey.dispose();
				} )
		);
	}

	createContactAndInviteAsync( contactName, nicknameSelf, isExternal ) {
		let multicast, contact;
		return (
			this.local2ServerTimeAsync( +new Date )
				.flatMap( serverTime => Contact.createNewAsync(
					this._contactType,
					this.getUnconflictedName( contactName ),
					serverTime,
					isExternal
				) )
				.tap( c => {
					contact = c;
					contact.noPersist = true;
				} )
				.flatMap( () => this._insertContactAsync( contact ) )
				.flatMap( () => this._addMulticastAsync(
					contact,
					Multicast.createNewAsync(
						configuration.getSocketBase(),
						"",
						contact.sharedId,
						contact.pid,
						contact.seedKey,
						contact.dhPrivKey,
						contact.signKey,
						configuration.getDefaultEncryptionConfig(),
						this._getP2pPrivateHandlers()
					)
				) )
				.tap( m => { multicast = m; } )
				.flatMap( () => multicast.createInviteAsync( nicknameSelf || "" ) )
				.flatMap( invite => this._inviteToTokenAsync( invite ) )
				.flatMap( inviteToken => this.updateAsync( contact.id, { inviteToken, noPersist: false } ) )
				.map( () => contact )
		);
	}

	createSharedContactAsync( baseContact, multidescription, sharedName ) {
		let newContact;
		return (
			this.local2ServerTimeAsync( +new Date )
				.flatMap( serverTime =>
					Contact.createNewAsync(
						this._contactType,
						this.getUnconflictedName( baseContact.name, multidescription.id ),
						serverTime
					)
				)
				.tap( c => newContact = c )
				.tap( () => newContact.update( {
					multidescriptionId: multidescription.id
				} ) )
				.flatMap( () => this._insertContactAsync( newContact ) )
				.flatMap( () => this._addMulticastAsync(
					newContact,
					Multicast.createNewAsync(
						newContact.apiUrlBase,
						"",
						newContact.sharedId,
						newContact.pid,
						newContact.seedKey,
						newContact.dhPrivKey,
						newContact.signKey,
						configuration.getDefaultEncryptionConfig(),
						this._getP2pPrivateHandlers()
					)
				) )
				.flatMap( multicast => this._sendInviteToContact(
					multicast,
					baseContact,
					sharedName,
					multidescription.globalId
				) )
				.map( () => newContact )
		);
	}

	_sendInviteToContact( multicast, sendToContact, inviteName, globalId ) {
		let invite;
		return (
			multicast.createInviteAsync( inviteName )
			.tap( i => {
				invite = i;
				invite.globalId = globalId;
			} )
			.flatMap( () => serializeMessageToBase64StringAsync( { ...invite, type: "invite" } ) )
			.tap( () => {
				invite.tmpPrivateKey.dispose();
			} )
			.flatMap( inviteString => (
				this._sendMessageAsync( sendToContact, {
					type: "new_contact_invite",
					inviteString
				} )
			) )
		);
	}

	sendContactsAsync( toContactId, contactIds, progressSubj ) {
		return (
			Rx.Observable.combineLatest(
				this.local2ServerTimeAsync( +new Date ),
				this.getDetailedContactAsync( toContactId ),
				( serverTime, destContact ) =>
					( { serverTime, destContact } )
			)
				.flatMap( ( { serverTime, destContact } ) =>
					Rx.Observable.fromArray( contactIds )
						.map( contactId => ( { serverTime, destContact, contactId } ) )
				)
				.concatMap( ( { serverTime, destContact, contactId } ) => Rx.Observable.defer( () =>
					Rx.Observable.combineLatest(
						this.getDetailedContactAsync( contactId ),
						Contact.createNewAsync( this._contactType, "", serverTime ),
						( sourceContact, newContact ) =>
							( { sourceContact, destContact, newContact } )
					)
					.flatMap( ( { sourceContact, destContact, newContact } ) =>
						Multicast.createNewAsync(
								newContact.apiUrlBase,
								"",
								newContact.sharedId,
								newContact.pid,
								newContact.seedKey,
								newContact.dhPrivKey,
								newContact.signKey,
								configuration.getDefaultEncryptionConfig(),
								this._getP2pPrivateHandlers()
						)
							//Skip warning about unset new participant handler
							.tap( multicast => { multicast.onNewParticipant( () => Rx.Observable.just() ); } )
							.flatMap( multicast => Rx.Observable.combineLatest(
									this._sendInviteToContact( multicast, sourceContact, destContact.name ),
									this._sendInviteToContact( multicast, destContact, sourceContact.name ),
									() => ({})
								)
								.flatMap( () => multicast.removeAsync( multicast.getPid() ) )
							)
					)
				) )
					.tap( () => { progressSubj && progressSubj.onNext( progressSubj.getValue() + 1 ); } )
					.toArray().tapOnCompleted( () => { progressSubj && progressSubj.onCompleted(); } )
		);
	}

	receiveInviteAsync( token ) {
		return (
			receiveMessageAsync( token )
				.flatMap( message => {
					if ( message.type === "invite" ) {
						return Rx.Observable.just( message );
					}

					if ( message.type === "multiinvite" ) {
						return Rx.Observable.just( message );
					}

					if ( message.type === "createUserSystem" ) {
						return Rx.Observable.just( message );
					}

					if ( message.type === "activation" ) {
						return Rx.Observable.just( message );
					}

					return Rx.Observable.throw( new Error( `Invalid type of invite message: ${message.type}` ) );
				} )
		);
	}

	acceptInviteAsync( inviteData, name, multidescriptionId ) {
		let multicast, contact;
		//TODO: receive/send of all type of messages must be at a seperate class
		/*connectionId, dhPubKey, encryptionSaltKey, macSaltKey, econfig*/
		if ( inviteData.type === "multiinvite" ) {
			return this.useMultiinviteAsync( inviteData, name );
		}
		if ( inviteData.type !== "invite" ) {
			return Rx.Observable.throw( "Message is not an invite" );
		}
		return this._enqueueTryAcceptSharedContact( () =>
			this.local2ServerTimeAsync( +new Date )
				.flatMap( lastMessageTS =>
					Contact.createFromInviteAsync(
						inviteData,
						this.getUnconflictedName( name, multidescriptionId ),
						this._contactType,
						lastMessageTS
					)
				)
				.tap( c => {
					contact = c;
					if ( multidescriptionId !== undefined ) {
						contact.update( { multidescriptionId } );
					}
				} )
				.flatMap( () => this._insertContactAsync( contact ) )
				.flatMap( () => this._addMulticastAsync(
					contact,
					Multicast.tryJoinAsync(
						"",
						{...inviteData,
							signKey: contact.signKey, dhPrivKey: contact.dhPrivKey,
							pid: contact.pid.toString( "base64" )
						},
						this._getP2pPrivateHandlers()
					)
				) )
				.flatMap( multicast => multicast
					? this.updateAsync( contact.id, { status: "active" } )
						.tap( () => this._sendNotifyes() )
						.map( () => contact )
					: this._deleteContactAsync( contact.id ).map( () => null )
				)
		);
	}

	useMultiinviteAsync( {apiUrlBase, connectionId, dhPubKey, encryptionSaltKey,
		macSaltKey, econfig}, contactName ) {
		let multicast, contact;
		return (
			this.local2ServerTimeAsync( +new Date )
				.flatMap( serverTime => Contact.createNewAsync(
					this._contactType,
					this.getUnconflictedName( contactName ),
					serverTime
				) )
				.tap( c => contact = c )
				.flatMap( () => Multicast.createNewAsync(
						configuration.getSocketBase(),
						"",
						contact.sharedId,
						contact.pid,
						contact.seedKey,
						contact.dhPrivKey,
						contact.signKey,
						configuration.getDefaultEncryptionConfig(),
						this._getP2pPrivateHandlers()
				) )
				.tap( m => multicast = m )
				.flatMap( () => multicast.createInviteAsync( "" ) )
				.flatMap( invite => {
					let sender = new OneWaySender( apiUrlBase, connectionId,
						dhPubKey, encryptionSaltKey, macSaltKey, econfig );
					return (
						sender.sendMessageAsync( { ...invite, type: "invite" } )
							.tap( () => {
								sender.dispose();
								invite.tmpPrivateKey.dispose();
							} )
					);
				} )
				.flatMap( () => this._insertContactAsync( contact ) )
				.flatMap( () => this._addMulticastAsync( contact, Rx.Observable.just( multicast ) ) )
				.tap( () => this._sendNotifyes() )
				.map( () => contact )
		);
	}

	sendInviteToWorkgroupAsync( contact, inviteString ) {
		return (
			this._sendMessageAsync( contact, {
					type: "workgroup_invite",
					inviteString
				} )
		);
	}

	sendInviteToGroupAsync( contact, inviteString ) {
		return (
			this._sendMessageAsync( contact, {
					type: "group_invite",
					inviteString
				} )
		);
	}

	_processMessageJsonAndGetModel( msg, fromContact, multicast, index ) {
		let {json, from} = msg;
		switch( json.type ) {
			case "group_invite": {
				if ( multicast.isOutgoingParticipant( msg.from ) ) {
					return null;
				}

				return new ChatMessageGroup(
					multicast.isOutgoingParticipant( msg.from )
						? MESSAGE_TYPE.OUTGOING
						: MESSAGE_TYPE.INCOMING,
						json.inviteString,
						json.timestamp,
						from,
						index,
						msg.p2pIndex,
						json.id
				);

			}
			case "workgroup_invite": {
				if ( multicast.isOutgoingParticipant( msg.from ) ) {
					return null;
				}

				return new ChatMessageWorkgroup(
					multicast.isOutgoingParticipant( msg.from )
						? MESSAGE_TYPE.OUTGOING
						: MESSAGE_TYPE.INCOMING,
						json.inviteString,
						json.timestamp,
						from,
						index,
						msg.p2pIndex,
						json.id
				);

				/*return (
					sharedcontactsServiceLocator()
						.acceptWorkGroupInviteIfNotJoinedAsync( json.inviteString )
						.map( () => message )
				);*/
			}
			case "new_contact_invite": {
				return new ChatMessageContact(
					multicast.isOutgoingParticipant( msg.from )
						? MESSAGE_TYPE.OUTGOING
						: MESSAGE_TYPE.INCOMING,
					json.inviteString,
					json.timestamp,
					from,
					index,
					msg.p2pIndex,
					json.id
				);
			}
		}
		return super._processMessageJsonAndGetModel( msg, fromContact, multicast, index );
	}

	shareContactAsync( contactId, multidescriptionId, privateData ) {
		let contact = this._findContactById( contactId );
		let multidescription = sharedcontactsServiceLocator()._findContactById( multidescriptionId );
		if ( !contact ) {
			console.warn( "No contact to share" );
			return Rx.Observable.just( null );
		}
		if ( !multidescription ) {
			console.warn( "No multidescription for share" );
			return Rx.Observable.just( null );
		}
		if ( contact.status === "failed" ) {
			console.warn( "Trying to share failed contact" );
			return Rx.Observable.just( null );
		}

		if ( multidescription.status === "failed" ) {
			console.warn( "Trying to share with failed contact" );
			return Rx.Observable.just( null );
		}
		return (
			this._getMulticastOrNullAsync( contactId )
				.flatMap( multicast => multicast
					? multicast.publishPrivateDataAsync( privateData )
						.flatMap( () =>
							this.updateAsync( contactId, { multidescriptionId } )
						)
					: Rx.Observable.just( null )
				)
		);
	}

	publishPrivateDataAsync( contact, privateData ) {
		return (
			this._getMulticastOrNullAsync( contact )
				.flatMap( multicast => multicast
					? multicast.publishPrivateDataAsync( privateData )
					: Rx.Observable.just( null )
				)
		);
	}

	getSharedContacts( multidescriptionId ) {
		return _.filter( this._contacts, { multidescriptionId } );
	}

	createAliasInviteIfPrivateDataIsAsync( contact, encryptedPrivateData,
		newPrivateData, inviteAction ) {
		if ( contact.status === "failed" ) {
			console.warn( "Trying to conditionaly create alias for failed contact" );
			return Rx.Observable.just();
		}
		return (
			this._getMulticastOrNullAsync( contact )
				.flatMap( multicast => multicast
					? multicast.conditionalyCreateInviteAsync(
						"",
						true,
						() =>
							multicast
								.observePrivateDataLatest()
								.first( { defaultValue: null } )
								.map( currentPrivateData => encryptedPrivateData === currentPrivateData ),
						newPrivateData,
						undefined,//rights
						inviteAction
					)
					: Rx.Observable.just( null )
				)
				.flatMap( invite => invite
					? serializeMessageToBase64StringAsync( {...invite, type: "invite"} )
					: Rx.Observable.just( null )
				)
			);
	}

	_enqueueTryAcceptSharedContact( func ) {
		let subj = new Rx.ReplaySubject();
		this._acceptSharedContactsQueue.onNext( Rx.Observable.defer( () => {
			//TODO: error handling
			func().subscribe( res => {
				subj.onNext( res );
				subj.onCompleted();
			} );
			return subj;
		} ) );
		return subj;
	}

	acceptInviteIfNotJoinedAsync( inviteString, multidescriptionId = -1 ) {
		return (
			deserializeFromBase64StringAsync( inviteString )
				.flatMap( inviteData => {
					if ( inviteData.type !== "invite" ) {
						return Rx.Observable.throw( "Invite message required" );
					}
					let subj = new Rx.ReplaySubject();
					this._acceptSharedContactsQueue.onNext( Rx.Observable.defer( () =>
						this._checkAndAddContactUsingInviteDataAsync( inviteData, multidescriptionId )
							.tap( () => {
								}, error => {
									console.error( error );
									subj.onError( error );
								}, () => {
									subj.onNext( true );
									subj.onCompleted();
								}
							)
					) );
					return subj;
				} )
				.catch( e => {
					console.error( "Failed to accept invite", e );
					return Rx.Observable.just( false );
				} )
		);
	}

	_checkAndAddContactUsingInviteDataAsync( inviteData, multidescriptionId ) {
		//TODO: use secure memory management
		let found = _.find(
			this._contacts,
			c => c.sharedId.equals( inviteData.sharedId )
		);
		if ( found ) {
			if ( found.status !== "active" ) {
				return Rx.Observable.just( false );
			}
			return (
				this._getMulticastOrNullAsync( found )
					.flatMap( multicast => multicast
						? multicast.removeInviteAsync( inviteData.tmpPid )
						: Rx.Observable.just( null )
					)
					.map( () => false )
			);
		}
		if ( inviteData.globalId ) {
			found = _.find(
				this._contacts,
				c => c.globalId && inviteData.globalId.equals( c.globalId )
			);
			if ( found ) {
				if ( ( found.status === "disconnected" ) || ( found.status === "failed" ) ) {
					return (
						this.deleteContactAsync( found.id )
							.flatMap( () => this._addContactUsingInviteDataAsync(
								inviteData, multidescriptionId
							) ).map( () => true )
					);
				}
				return (
					Rx.Observable.fromPromise(
					Promise.all( [
						ssgCrypto.createRandomSignatureKeyPairThen( invite.econfig ), //TODO: no need for public key
						ssgCrypto.createRandomKeyExchangeKeyPairThen( invite.econfig ), //TODO: no need for public key
						ssgCrypto.createRandomBase64StringThen( invite.econfig.getIdLength() )
					] )
						.then( ( [
							{ privateKey: signKey, publicKey: sPubKey },
							{ privateKey: dhPrivKey, publicKey: dhPubKey },
							pid
						] ) => {
							sPubKey.dispose();
							dhPubKey.dispose();
							return { ...inviteData, signKey, dhPrivKey, pid };
						} )
					).flatMap( inviteData =>
						Multicast.tryJoinAsync(
							inviteData.nickname,
							inviteData,
							this._getP2pPrivateHandlers()
						)
					)
					.flatMap( m => m
						? this.exitFromMulticastAsync( m ).map( () => true )
						: Rx.Observable.just( false )
					)
				);
			}
		}
		return this._addContactUsingInviteDataAsync( inviteData, multidescriptionId );
	}

	_addContactUsingInviteDataAsync( inviteData, multidescriptionId ) {
		let contact, multicast;
		return (
			this.local2ServerTimeAsync( +new Date )
				.flatMap( lastMessageTS =>
					Contact.createFromInviteAsync(
						inviteData,
						this.getUnconflictedName( inviteData.nickname, multidescriptionId ),
						this._contactType,
						lastMessageTS
					)
				)
				.tap( c => contact = c )
				.tap( () => contact.update( {
					status: "invited",
					multidescriptionId
				} ) )
				.flatMap( () => this._insertContactAsync( contact ) )
				.flatMap( () => this._addMulticastAsync(
					contact,
					Multicast.tryJoinAsync(
						inviteData.nickname,
						{
							...inviteData,
							signKey: contact.signKey,
							dhPrivKey: contact.dhPrivKey,
							pid: contact.pid.toString( "base64" )
						},
						this._getP2pPrivateHandlers()
					)
				) )
				.flatMap( multicast => multicast
					? Rx.Observable.just()
					: this.updateAsync( contact.id, { status: "disconnected" } )
				)
		);
	}

	createSharedContactIfNotExistAsync( inviteString, name, multidescriptionId ) {
		//TODO: use secure memory management
		let contact;
		return (
			deserializeFromBase64StringAsync( inviteString )
				.flatMap( inviteData => this.observeContactsFullyLoad()
					.map( contacts => ( { inviteData, contacts } ) )
				)
				.flatMap( ( { inviteData, contacts } ) =>
					this._enqueueTryAcceptSharedContact( () => {
						if ( _.find( this._contacts, c => c.sharedId.equals( inviteData.sharedId ) ) ) {
							contact = null;
							//TODO: remove invite
							return Rx.Observable.just();
						}
						let unconflictedName = this.getUnconflictedName( name, multidescriptionId );

						return this.local2ServerTimeAsync( +new Date )
							.flatMap( lastMessageTS =>
								Contact.createFromInviteAsync(
									inviteData,
									unconflictedName,
									this._contactType,
									lastMessageTS
								)
							)
							.tap( c => {
								contact = c;
								c.update( { multidescriptionId } );
							} )
							.flatMap( () => this._insertContactAsync( contact ) )
							.flatMap( () =>
								this._addMulticastAsync(
									contact,
									Multicast.tryJoinAsync(
										"",
										{...inviteData,
											signKey: contact.signKey, dhPrivKey: contact.dhPrivKey,
											pid: contact.pid.toString( "base64" )
										},
										this._getP2pPrivateHandlers()
									)
								)
								.flatMap( multicast => {
									if ( !multicast ) {
										return (
											this._deleteContactAsync( contact.id )
												.map( () => null )
										);
									}
									return Rx.Observable.just( multicast );
								} )
							)
						} )
				)
		);
	}

	exitFromMulticastAsync( multicast ) {
		if ( !multicast ) {
			return Rx.Observable.just( null );
		}
//TODO: do this in one transaction
		return (
			multicast.observeInvitesLatest()
			.take( 1 )
			.flatMap( invites => Rx.Observable.fromArray( _.keys( invites ) ) )
			.concatMap( tmpPid => Rx.Observable.defer( () => multicast.removeInviteAsync( tmpPid ) ) )
			.toArray()
			.flatMap( () => multicast.observeParticipantsLatest() )
			.take( 1 )
			.flatMap( ps => {
				let rootPid = multicast.getRootAliasPid( multicast.getPid() );
				let pid2Exit = [];
				for ( let pid in ps ) {
					if ( !ps[ pid ].isExited
						&& ( multicast.getRootAliasPid( pid ) === rootPid )
						&& ( pid !== multicast.getPid() )
					) {
						pid2Exit.push( pid );
					}
				}
				pid2Exit.push( multicast.getPid() );
				return Rx.Observable.fromArray( pid2Exit );
			} )
			.concatMap( pid => Rx.Observable.defer( () => multicast.isDisposed()
				? Rx.Observable.empty()
				: multicast.removeAsync( pid ) )
			)
			.toArray()
		);
	}

	deleteContactAsync( contactId ) {
		let contact = this._findContactById( contactId );
		if ( !contact ) {
			return Rx.Observable.just( false );
		}
		return (
			Rx.Observable.combineLatest(
				contact.inviteToken
					? this.removeMessageAsync( contact.inviteToken )
						.catch( () => Rx.Observable.just() )
					: Rx.Observable.just(),
				( contact.status === "disconnected" ) || ( contact.status === "failed" )
				|| ( contact.status === "joining" )
					? Rx.Observable.just()
					: this._getMulticastOrNullAsync( contact, true )
						.flatMap( multicast => multicast
							? this.exitFromMulticastAsync( multicast )
							: Rx.Observable.just( null )
						).catch( () => Rx.Observable.just( null ) ),
					() => null
			)
			.flatMap( () => super.deleteContactAsync( contactId ) )
		);
	}

	updateAsync( id, changesJSON, isLazy ) {
		if ( !( "name" in changesJSON ) ) {
			return this._updateAsync( id, changesJSON, isLazy );
		}
		return (
			this.getDetailedContactAsync( id )
				.flatMap( contact => {
					if ( ( contact.multidescriptionId === -1 ) || !changesJSON.name ) {
						return this._updateAsync( id, changesJSON, isLazy );
					}
					return (
						sharedcontactsServiceLocator().sendRenameContactMessageAsync(
							contact.multidescriptionId,
							contact.id,
							changesJSON.name
						)
					);
				} )
		);
	}

	_updateAsync( id, changesJSON, isLazy ) {
		return super.updateAsync( id, changesJSON, isLazy );
	}

	addHelperContactAsync( name ) {
		let contactName = this.getUnconflictedName( name );
		return (
			this.receiveInviteAsync( configuration.getHelperContactToken() )
				.flatMap( inviteData => this.acceptInviteAsync( inviteData, contactName ) )
				.map( contact => contact.id )
		);
	}

	setParticipantAutocleanMaxTimeAsync( contact, timeToSet ) {
		return (
			this._getMulticastOrNullAsync( contact )
				.flatMap( multicast => multicast
					? multicast.observeParticipantsLatest()
					.take( 1 )
					.flatMap( allParticipants => {
						let selfRootPid = multicast.getRootAliasPid( multicast.getPid() );
						let pidsToSet = _.filter(
							_.keys( allParticipants ),
							pid => {
								let rootPid = multicast.getRootAliasPid( pid );
								return ( rootPid !== selfRootPid ) && ( pid === rootPid );
							}
						);
						if ( pidsToSet.length > 2 ) {
							console.error( pidsToSet );
							throw new Error( "pidsToSet.length > 2" );
						}
						return Rx.Observable.fromArray( pidsToSet );
					} )
					.flatMap( pid => multicast.observeMetaLatest().take( 1 )
						.filter( meta => {
							if ( !meta[ "autocleanTimeMax-" + pid ] ) {
								console.warn( `!meta[ autocleanTimeMax-${pid} ]` );
								debugger;
							}
							return meta[ "autocleanTimeMax-" + pid ] !== timeToSet;
						} )
						.flatMap( meta => !multicast.isDisposed()
							? multicast.sendUpdateMessageAsync(
								{ [ "autocleanTimeMax-" + pid ]: timeToSet }
							)
							: Rx.Observable.empty()
						)
					)
					: Rx.Observable.empty()
				)
				.toArray()
		);
	}

	askParticipantsToCleanHistoryAsync( contactId ) {
		return (
			this._getMulticastOrNullAsync( contactId )
				.flatMap( multicast =>
					multicast.observeParticipantsLatest().take( 1 )
						.flatMap( allParticipants => {
							let selfRootPid = multicast.getRootAliasPid( multicast.getPid() );
							let pidsToSet = _.filter(
								_.keys( allParticipants ),
								pid => multicast.getRootAliasPid( pid ) !== selfRootPid
							);
							return Rx.Observable.fromArray( pidsToSet );
						} )
						.flatMap( pid => multicast.sendPrivateMessageAsync(
							"clean_history", { pid }, pid
						).catch( error => {
							console.error( "Error cleaning remote history", error );
							return Rx.Observable.empty();
						} ) )
				)
		);
	}

	_getP2pPrivateHandlers( ) {
		return {
			...super._getP2pPrivateHandlers(),
			"clean_history": this._onCleanHistory
		};
	}

	_onCleanHistory( multicast, pid, json, p2pIndex ) {
		if ( multicast.getPid() !== json.pid ) {
			return Rx.Observable.just();
		}
		for( let sContactId in this._contactId2MulticastThen ) {
			let contactId = sContactId | 0;
			this._contactId2MulticastThen[ sContactId ].then( m => {
					if ( m === multicast ) {
						this.clearContactHistory( contactId );
					}
			} );
		}
		return Rx.Observable.just();
	}

	_onMulticastCreated( contact, multicast ) {
		Rx.Observable.combineLatest(
			multicast.observeParticipantsLatest(),
			multicast.observeInvitesLatest(),
			( ps, invites ) => {
				let participantsPerGroup = _.reduce( ps, ( acc, {isExited}, pid ) => {
					let rpid = multicast.getRootAliasPid( pid );
					acc[ rpid ] = acc[ rpid ] || 0;
					if ( !isExited ) {
						acc[ rpid ] = acc[ rpid ] + 1;
					}
					return acc;
				}, Object.create( null ) );
				return {
					participantCount: _.filter( participantsPerGroup ).length,
					inviteCount: _.values( invites ).length,
					exited: _.filter( participantsPerGroup, count => count === 0 ).length,
					ps,
					invites
				};
			} )
			.filter( ({ participantCount, inviteCount, exited }) =>
				( participantCount === 1 )
				&& ( inviteCount === 0 )
				&& ( exited > 0 )
				&& ( ( contact.status === "active" ) || ( contact.status === "invited" ) )
				&& multicast
			)
			.first( { defaultValue: null } )
			.flatMap( res => res
				? this.updateAsync( contact.id, { status: "disconnected" } )
				: Rx.Observable.empty()
			)
			.subscribeOnError( error => {
				console.error( "error on _onMulticastCreated(disconnected)", error );
			} );
		super._onMulticastCreated( contact, multicast );
	}
}

export default ContactsService;
