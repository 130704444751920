import _ from "lodash";
import ssgCrypto, {KEY_KINDS} from "ssg.crypto";

import configuration from "../../common/configuration.js";

import contactServiceLocator from "./locators/contact.js";
import OneWayReceiver from "../transport/oneway.receiver.js";
import profileServiceLocator from "./locators/profile.js";
import {
	deserializeFromBase64StringThen,
	serializeMessageToBase64StringThen,
	sendMessageThen
} from "../models/message/technical.js";

class BusinessCardService {
  constructor() {
    this._cardsSubscription = null;
    this._profileService = profileServiceLocator();
    this._contactService = contactServiceLocator();
    this._receiversPerToken = Object.create( null );
  }

  observeCards() {
    return (
      this._profileService.observeProfile()
        .map( profile => {
          if ( !profile || !profile.businessCards ) {
            return [];
          }
          //Prevent private data from leaving this logic level
          return _.map(
            profile.businessCards,
            ( { token, name, description } ) => ( { token, name, description } )
          );
        } )
    );
  }

  onLogin() {
    if ( this._cardsSubscription ) {
      this._cardsSubscription.dispose();
    }
    this._cardsSubscription = (
      this._profileService.observeProfile()
        .filter( profile => !!profile )
        .subscribe( ( { businessCards } ) => {
          let cardsRemoved = _.clone( this._receiversPerToken );

          _.forEach( businessCards, ( card ) => {
            if ( cardsRemoved[ card.token ] ) {
              delete cardsRemoved[ card.token ];
            } else {
              this._receiversPerToken[ card.token ] = this._createReceiver( card );
            }
          } );

          _.forEach( cardsRemoved, ( unimportant, token ) => {
            this._receiversPerToken[ token ].dispose();
            delete this._receiversPerToken[ token ];
          } );
        } )
    );
  }

  _createReceiver( card ) {
    return {
      promise: deserializeFromBase64StringThen( card.privateData ).then( priv => {
        let owr = new OneWayReceiver(
          configuration.getApiBase(), priv.connectionId, priv.dhPrivKey,
          priv.encryptionSaltKey, priv.macSaltKey,
          card.messageIndex | 0, configuration.getDefaultEncryptionConfig(),
          this._onMessage.bind( this, card )
        );
        owr.onIndexUpdate( index =>
          Rx.Observable.fromPromise( this._updateMessageIndexThen( card.token, index + 1 ) )
        );
        return owr;
      } ),
      dispose: function() {
        this.promise.then( owr => { owr.dispose(); } )
      }
    };
  }

  _updateMessageIndexThen( token, index ) {
    return (
      this._profileService.getProfileThen()
        .then( profile => {
          if ( !profile ) {
            return;
          }
          let { businessCards } = profile;
          let found = _.find( businessCards, { token } );
          if ( !found ) {
            return;
          }
          if ( index <= found.messageIndex ) {
            return;
          }
          found.messageIndex = index;
          return this._profileService.updateProfileThen( { businessCards } );
      } )
    );
  }


  _onMessage( card, message ) {
    this._contactService.getContactsAsync()
      .flatMap( contacts => {
        //TODO: queue ?
        let found = _.find( contacts, c => message.sharedId.equals( c.sharedId ) );
        if ( found ) {
          return Rx.Observable.just();
        }

        return (
          this._contactService.acceptInviteAsync( message, card.name || "BC" )
        );
      } )
      .subscribeOnError( error => {
        console.error( error );
      } );
  }

  deleteCardThen( token ) {
    return (
      this._profileService.getProfileThen()
        .then( profile => {
          if ( !profile ) {
            return;
          }
          let { businessCards } = profile;
          let index = _.findIndex( businessCards, { token } );
          if ( !~index ) {
            return;
          }

          businessCards.splice( index, 1 );
          return this._profileService.updateProfileThen( { businessCards } );
        })
    );
  }

  _createMultiinviteTokenThen( connectionId, dhPubKey, encryptionSaltKey, macSaltKey ) {
		let connectionIdHash = ssgCrypto.hash( new Buffer( connectionId, "base64" ) ).toString( "base64" );
		//TODO: Change message format to skip converting sensetive data to text/buffer
		return (
			sendMessageThen(
				{
					apiUrlBase: configuration.getApiBase(),
					connectionId: connectionIdHash,
					dhPubKey, encryptionSaltKey, macSaltKey,
					econfig: configuration.getDefaultEncryptionConfig(),
					type: "multiinvite"
				}
			)
		);
	}

  _createMultiinviteDataThen( connectionId, dhPrivKey, encryptionSaltKey, macSaltKey ) {
    return (
      serializeMessageToBase64StringThen( {
        dhPrivKey, encryptionSaltKey, macSaltKey, connectionId,
        econfig: configuration.getDefaultEncryptionConfig(),
        type: "multiinvitePrivate"
      } )
    );
  }


  createCardThen( name, description ) {
    let econfig = configuration.getDefaultEncryptionConfig();
    return (
      Promise.all( [
        ssgCrypto.createRandomKeyExchangeKeyPairThen( econfig ),
        ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, econfig ),
        ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, econfig ),
        ssgCrypto.createRandomBase64StringThen( 32 )
      ] ).then( ( [ { privateKey, publicKey }, encryptionSaltKey, macSaltKey, connectionId ] ) =>
        Promise.all( [
          this._createMultiinviteTokenThen( connectionId, publicKey, encryptionSaltKey, macSaltKey ),
          this._createMultiinviteDataThen( connectionId, privateKey, encryptionSaltKey, macSaltKey ),
          this._profileService.getProfileThen(),
        ] )
      )
        .then( ( [ token, privateData, profile ] ) => {
          if ( !profile ) {
            return;
          }
          let { businessCards } = profile;
          let found = _.find( businessCards, { token } );
          if ( found ) {
            return;
          }
          if ( !businessCards ) {
            businessCards = [];
          }
          businessCards.push( {
            name, description, token, privateData, messageIndex: 0
          } );
          return this._profileService.updateProfileThen( { businessCards } );
        } )
    );
  }

  updateCardThen( token, name, description ) {
    if ( typeof token !== "string" ) {
      throw new Error( "Token string required" );
    }
    if ( typeof name !== "string" && name !== undefined ) {
      throw new Error( "Name string required" );
    }
    if ( typeof description !== "string" && description !== undefined ) {
      throw new Error( "Description string required" );
    }
    return (
      this._profileService.getProfileThen()
        .then( profile => {
          if ( !profile ) {
            return;
          }
          let { businessCards } = profile;
          let found = _.find( businessCards, { token } );
          if ( !found ) {
            return;
          }
          if ( name !== undefined ) {
            found.name = name;
          }
          if ( description !== undefined ) {
            found.description = description;
          }
          return this._profileService.updateProfileThen( { businessCards } );
        } )
    );
  }
}

export default BusinessCardService;
