var charString = "0123456789ACEFGHJKLMNPQRSTUVWXYZ".toLowerCase();

var char2Number = {};

for ( var i = 0; i < charString.length; i++ ) {
	char2Number[ charString.charAt( i ) ] = i;
}

var base32 = {
	encode: function( buffer ) {
		var totalBits = buffer.length * 8;
		var charArray = Array( ( totalBits + 4 ) / 5 | 0 );
		var charIndex = 0;

		for ( var bitIndex = 0; bitIndex < totalBits; bitIndex += 5, charIndex++ ) {
			var charNumber = buffer[ bitIndex / 8 | 0 ] + ( buffer[ bitIndex / 8 + 1 | 0 ] << 8 );
			charNumber >>= bitIndex % 8;
			charNumber &= 31;
			charArray[ charIndex ] = charString.charAt( charNumber );
		}
		return charArray.join( "" );
	},
	decode: function( str ) {
		var result = new Buffer( str.length * 5 / 8 | 0 );
		var bitIndex = 0;
		str = str.toLowerCase();

		for ( var i = 0; i < str.length; i++ ) {
			var charNumber = char2Number[ str.charAt( i ) ];
			if ( charNumber === undefined ) {
				continue;
			}
			result[ bitIndex / 8 | 0 ] |= charNumber << ( bitIndex % 8 );
			result[ bitIndex / 8 + 1 | 0 ] |= charNumber >> ( 8 - bitIndex % 8 );
			bitIndex += 5;
		}
		if ( bitIndex / 8 !== result.length ) {
			return result.slice( 0, bitIndex / 8 );
		}
		return result;
	}
};

exports["default"] = base32;
module.exports = exports["default"];
