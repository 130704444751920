import Rx from "rx";
import translate from "../../browser/translations/translate.js";

class WebNotifications {
    constructor() {
        if ( !global.Notification ) {
          this._notificationsEnabledSubj = Rx.Observable.just( false );
          return;
        }
        this._notificationsEnabledSubj = new Rx.BehaviorSubject(
            global.Notification.permission === "granted"
  		        && !global.localStorage.notifications_muted
        );
    }

    isNotificationsEnabled() {
        return this._notificationsEnabledSubj.getValue();
    }

    observeNotifications() {
        return this._notificationsEnabledSubj;
    }

    toggleNotifications() {
        if ( !global.Notification ) {
          return;
        }
        if ( this.isNotificationsEnabled() ) {
            global.localStorage.notifications_muted = "1";
            this._notificationsEnabledSubj.onNext( false );
            return;
        }
        if ( global.localStorage.notifications_muted ) {
            delete global.localStorage.notifications_muted;
        }
        if ( global.Notification.permission === "granted" ) {
            this._notificationsEnabledSubj.onNext( true );
            return;
        }
        global.Notification.requestPermission( () => {
            if ( global.Notification.permission !== "granted" ) {
                //TODO: move away this
                alert( translate( "web.settings.menu.notifications.warn" ) );
                this._notificationsEnabledSubj.onNext( false );
                return;
            }
            delete global.localStorage.notifications_muted;
            this._notificationsEnabledSubj.onNext( true );
        } );
    }
}

export default WebNotifications;
