import React from "react";
import _ from "lodash";

import Prompt from "../common/prompt.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

class RenameSelfView extends React.Component {
	constructor() {
		super();
		this.onSetName = this.onSetName.bind( this );
		this.validateName = this.validateName.bind( this );
		this._service = serviceLocator();
	}

	onSetNickname( value ) {
		let { onBack, contact } = this.props;

		this._service
			.renameSelfAsync( contact, value.trim() )
			.subscribe( onBack );
	}

	onSetName( name ) {
		let { onBack, contact } = this.props;

		this._service
			.renameContactAsync( contact, name )
			.subscribe( onBack );
	}

	componentWillMount() {
	}

	validateName( name ) {
		let errors = [];
		name = name.trim();
		//TODO: check name duplication
		let isValid = !!name;// && !_.find( this.nicknamesCache, name );
		if (!isValid && name) errors.push("invite.duplicate")
		if (!name) errors.push("nickname.empty")
		return errors;
	}

	render() {
		let { onBack, contact } = this.props;
		return (
			<Prompt
				onSetValue={this.onSetName}
				onBack={onBack}
				validate={this.validateName}
				headerTextId="group.rename.header"
				textId="group.rename.text"
				placeholderTextId="group.rename.placeholder"
				defaultValue={contact.name}
				fullScreen={false}
				autofocus={true}
				firstUppercased={true}
			/>
		);
	}
}

export default RenameSelfView;
