import React from "react";
import RemoteInterwindowExchangeClient from "../../components/remote.interwindow.client.js";
import remoteServiceLocator from "../../../api/services/locators/remote.js";
import Translation from "../../components/translation.jsx";

class OpenerView extends React.Component {
  componentWillMount() {
    this._client = new RemoteInterwindowExchangeClient( this.gotConnectionData.bind( this ) );
    this._service = remoteServiceLocator();
  }

  gotConnectionData( params ) {
    this._service.beginConnectUsingData(
      params.connectionId,
      params.seedMacKey,
      params.seedEncryptionKey,
      params.macKey
    );
  }

  render() {
    return <Translation textId="web.opener.textId" />;
  }
}

export default OpenerView;
