import _ from "lodash";

import configuration from "../../../common/configuration.js";
import PromptParticipantsBase from "../common/prompt.participants.base.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

class PromptWorkgroup extends PromptParticipantsBase {
	constructor() {
		super();
		this.headerTextId = "sharedcontacts.add.workgroup.header";
		this.descriptionTextId = "sharedcontacts.add.workgroup.text";
		this._service = serviceLocator();
	}

	isButtonEnabled() {
		return !this.state.done;
	}

	componentWillMount() {
		this._contactsSubscription = (
			this._service
				.observeContactList()
				.map( contacts => _.filter(
					contacts,
					({status, multidescriptionId}) => {
						return ( status === "active" )  && ( multidescriptionId === -1 );
					}
				) )
				.subscribe( contacts => {
					this.setState( { contacts } );
				} )
			);
	}

	componentWillUnmount() {
		this._contactsSubscription.dispose();
	}
}

export default PromptWorkgroup;
