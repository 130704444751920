import _ from "lodash";
import Rx from "rx";
import sanitize from "sanitize-filename";
import ssgCrypto from "ssg.crypto";

import configuration from "./configuration.js";
import MessageReceiver from "../api/models/message/message.receiver.js";
import MessageDeserializer from "../api/models/message/message.deserializer.js";
import BinaryStream from "../api/models/binary.stream.js";
import base64 from "../../common/utils/base64.js";
import base32 from "../../common/utils/base32.js";
import rxHelper from "./helpers/rx.helper.js";

import {decodeMessageToken} from "../api/models/message/address.codec.js";
import Encrypter from "../api/models/message/encrypter.js";
import Decrypter from "../api/models/message/decrypter.js";
import MessageSerializer from "../api/models/message/message.serializer.js";
import MessageModel from "../api/models/message/message.js";
import MessageSender from "../api/models/message/message.sender.js";

export function getRangeFromPoint( x, y ) {
	if ( global.document.caretRangeFromPoint ) {
		return global.document.caretRangeFromPoint( x, y ); //WebKit
	}
	let position = global.document.caretPositionFromPoint( x, y ); //FF
	let range = document.createRange();
	range.setStart( position.offsetNode, position.offset );
	range.setEnd( position.offsetNode, position.offset );
	return range;
}
export function scrollIntoView( element ) {
	if ( element.scrollIntoViewIfNeeded ) {
		element.scrollIntoViewIfNeeded();
	} else {
		element.scrollIntoView();
	}
}
export function bindStateProperty( target, propertyName, cb ) {
	return value => target.setState( { [ propertyName ]: value }, cb );
}
export function isValidToken( token ) {
	try {
		decodeMessageToken( token );
		return true;
	} catch(e) {
		return false;
	}
	//TODO: try decode
	//return !!configuration.getTokenMode( token.length );
}

export function sendEncryptedMessageAsync( messageText, password = "" ) {
	return Rx.Observable.fromPromise(
		sendEncryptedMessageThen( messageText, password )
	);
};

export function sendEncryptedMessageThen( messageText, password = "" ) {
	let chunkSize = 16;
	let message = new MessageModel( messageText );

	return new MessageSender( {
		mainBinaryStream: new MessageSerializer( message, chunkSize ).createBinaryStream(),
		mainPassword: password,
		fakeBinaryStream: null,
		fakePassword: null,
		isSingleRead: false,
		expirationTS: 1000 * 60 * 60 * 24 + +new Date(),
		algorithm: "aes-256-cbc"
	} ).completeThen()
			.then( ( { addresses } ) => addresses )
			.then( addresses => _.find( addresses, "name", "token" ) )
			.then( ( { value } ) => value );
}

export function sendEncryptedMessageLocalThen( messageText, password = "" ) {
	let chunkSize = 16;
	let message = new MessageModel( messageText );

	return new MessageSender( {
		mainBinaryStream: new MessageSerializer( message, chunkSize ).createBinaryStream(),
		mainPassword: password,
		fakeBinaryStream: null,
		fakePassword: null,
		isSingleRead: false,
		expirationTS: 1000 * 60 * 60 * 24 + +new Date(),
		algorithm: "aes-256-cbc",
		localToken: true
	} ).completeThen()
			.then( ( { addresses } ) => addresses )
			.then( addresses => _.find( addresses, "name", "token" ) )
			.then( ( { value } ) => value );
}

export function sendEncryptedMessageWithFakeAsync( fakeMessageText, fakePassword, messageText, password = "" ) {
	return Rx.Observable.fromPromise(
		sendEncryptedMessageWithFakeThen( fakeMessageText, fakePassword, messageText, password )
	);
};

export function sendEncryptedMessageWithFakeThen( fakeMessageText, fakePassword, messageText, password = "" ) {
	let chunkSize = 16;
	let message = new MessageModel( messageText );
	let fakeMessage = new MessageModel( fakeMessageText );

	return new MessageSender( {
		mainBinaryStream: new MessageSerializer( message, chunkSize ).createBinaryStream(),
		mainPassword: password,
		fakeBinaryStream: new MessageSerializer( fakeMessage, chunkSize ).createBinaryStream(),
		fakePassword: fakePassword,
		isSingleRead: false,
		expirationTS: 1000 * 60 * 60 * 24 + +new Date(),
		algorithm: "aes-256-cbc"
	} ).completeThen()
			.then( ( { addresses } ) => addresses )
			.then( addresses => _.find( addresses, "name", "token" ) )
			.then( ( { value } ) => value );
}

function decryptMessageThen( receiver, password ) {
	return receiver
		// .getStreamFactoryThen( password )
		// .then( factory => new MessageDeserializer( factory ).getThen() )
		.getBufferThen( password )
		.then( buffer => new MessageDeserializer( () => BinaryStream.fromBuffer( buffer ) ).getThen() )
		.then( message => message.getText() );
}
export function receiveMessageAsync( token, password = "" ) {
	return Rx.Observable.fromPromise( receiveMessageThen( token, password ) );
}

export function receiveMessageThen( token, password = "" ) {
	let receiver = new MessageReceiver( { token: token } );
	return decryptMessageThen( receiver, password );
}
export function receiveMessageWithFakeThen( token, fakePassword, password = "" ) {
	let receiver = new MessageReceiver( { token: token } );
	return Promise.all( [
		decryptMessageThen( receiver, password ),
		decryptMessageThen( receiver, fakePassword )
	] ).then( ( [ message, fakeMessage ] ) => ( { message, fakeMessage } ) );
}
export function getRandomKeyThen( byteLength ) {
	return (
		ssgCrypto.createRandomBufferThen( byteLength )
			.map( data => base64.encode( ssgCrypto.hash( data ) ) )
	);
}

export function serializeMessage( type, index, body ) {
	let prefix = new Buffer( 5 );
	prefix[ 0 ] = type;
	prefix.writeUInt32LE( index, 1 );
	return Buffer.concat( [ prefix, body ] );
}
export function deserializeMessage( messageBuffer ) {
	return {
		type: messageBuffer[ 0 ],
		index: messageBuffer.readUInt32LE( 1 ),
		body: messageBuffer.slice( 5 )
	};
}

let palette = [
	"#695f4b",
	"#4a7852",
	"#4a6578",
	"#5a5784",
	"#784a76"
];

export function getAvaColorByName( name ) {
	if ( name === null ) {
		return null;
	}
	let hash = ssgCrypto.hash( new Buffer( name ) );
	let index = hash.readUInt32BE( 0 ) % palette.length;
	return palette[ index ];
}

export function getWebAvaColorByName( name ) {
	let hash = ssgCrypto.hash( new Buffer( name ) );
	let index = hash.readUInt32BE( 0 ) % palette.length;
	return palette[ index ];
}

export function getContactInitials( nickname ) {
	if ( nickname === null ) {
		return null;
	}
	if ( typeof nickname !== "string" ) {
		throw new Error( "nickname must be a string" );
	}
	nickname = _.trim( nickname );

	if ( !nickname ) {
		return "";
	}
	let subNames = _.compact( nickname.split( " " ) );
	if ( subNames.length === 1 ) {
		return subNames[ 0 ].substr( 0, 2 );
	}
	return subNames.map( subname => subname.substr( 0, 1 ) ).slice( 0, 2 ).join( "" );
}

export function calculateFingerprintAsync( yourPrivKey, hisPubKey ) {
	return Rx.Observable.just( 0 );
}

let STATUS_COLORS = {
	invited: "yellow",
	online: "green",
	offline: "red"
};

export function getStatusColor( status ) {
	return STATUS_COLORS[ status ] || "red";
}

export function sortContacts( contacts ) {
	contacts.sort( ( a, b ) => {
		if ( ( b.status === "invited" && b.type === "normal" ) && ( a.status !== "invited" || a.type !== "normal" ) ) {
			return 1;
		}
		if ( ( a.status === "invited" && a.type === "normal" ) && ( b.status !== "invited" || b.type !== "normal" ) ) {
			return -1;
		}

		let aContact = a.contact ? a.contact : a;
		let bContact = b.contact ? b.contact : b;

		if ( ( bContact.type === "multidescription" ) && ( aContact.type !== "multidescription" ) ) {
			return -1;
		}
		if ( ( aContact.type === "multidescription" ) && ( bContact.type !== "multidescription" ) ) {
			return 1;
		}

		let c = ( bContact.lastMessageTS || 0 ) - ( aContact.lastMessageTS || 0 );
		if ( c > 0 ) {
			return 1;
		}
		if ( c < 0 ) {
			return -1;
		}
		let namea = a.name.toUpperCase();
		let nameb = b.name.toUpperCase();
		if ( namea > nameb ) {
			return 1;
		}
		if ( namea < nameb ) {
			return -1;
		}
		return 0;
	} );
	return contacts;
}

export function isValidBaseURL(str) {
	var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return pattern.test(str);
}

function truncateThen( writer, size ) {
	return new Promise( ( resolve, reject ) => {
		writer.onwrite && observer.onError( new Error( "!!writer.onwrite" ) );
		writer.onerror && observer.onError( new Error( "!!writer.onerror" ) );

		writer.onwrite = ( event ) => {
			writer.onwrite = null;
			writer.onerror = null;
			resolve( writer );
		};

		writer.onerror = ( error ) => {
			writer.onwrite = null;
			writer.onerror = null;
			reject( error );
		};
		writer.truncate( size );
	} );
}

function writeThen( writer, buffer ) {
	let ab = new ArrayBuffer( buffer.length );
	let ui8a = new Uint8Array( ab );
	// buffer.copy( ui8a );
	for( let i = 0; i < buffer.length; i++ ) {
		ui8a[ i ] = buffer[ i ];
	}
	return new Promise( ( resolve, reject ) => {
		writer.onwrite && observer.onError( new Error( "!!writer.onwrite" ) );
		writer.onerror && observer.onError( new Error( "!!writer.onerror" ) );

		writer.onwrite = ( event ) => {
			writer.onwrite = null;
			writer.onerror = null;
			resolve();
		};

		writer.onerror = ( error ) => {
			writer.onwrite = null;
			writer.onerror = null;
			reject( error );
		};
		writer.write( ab );
	} );
}

export function openFileWith( fileBinaryStream, fileName, contentType ) {
	//TODO: move to file helper
	const CHUNK_SIZE = 8192;

	if ( !global.requestFileSystem ) {
		createBlobFromStream( fileBinaryStream, contentType ).completition
			.subscribe( blob => {
				let fileUrl = URL.createObjectURL( blob );
				window.open( fileUrl, "_blank" );
			} );
		return;
	}
	fileName = sanitize( fileName );

	let rxRequestFileSystem = rxHelper.doubleCallbackToObservable( global.requestFileSystem, global );
	let rxResolveLocalFileSystemURL = rxHelper.doubleCallbackToObservable( global.resolveLocalFileSystemURL, global );
	let resolvedDirAsync = (
		rxResolveLocalFileSystemURL( cordova.file.externalDataDirectory || cordova.file.documentsDirectory )
			.flatMap( deApp => rxHelper.doubleCallbackToObservable( deApp.getDirectory, deApp )( "Downloads", { create: true } ) )
	);

	rxRequestFileSystem( LocalFileSystem.PERSISTENT, 0 )
		.flatMap( () => resolvedDirAsync )
		.flatMap( de => rxHelper.doubleCallbackToObservable( de.getFile, de )( fileName, { create: true } ) )
		.flatMap( fe => rxHelper.doubleCallbackToObservable( fe.createWriter, fe )()
			.flatMap( writer => truncateAsync( writer, 0 ) )
			.flatMap( writer => {
				return (
					Rx.Observable.range( 0, ( ( fileBinaryStream.size + CHUNK_SIZE - 1 ) / CHUNK_SIZE ) | 0 )
						.concatMap( () =>
							Rx.Observable.defer( () => {
								let size = Math.min( CHUNK_SIZE, fileBinaryStream.size - fileBinaryStream.position );
								let subj = new Rx.ReplaySubject();
								fileBinaryStream.getNextBytesCb(
									size,
									chunk => {
										writeAsync( writer, chunk )
											.subscribe( res => {
													subj.onNext( res );
													subj.onCompleted();
												},
												error => subj.onError( error)
											);
									}
								);
								return subj;
							} )
						)
						.toArray()
						.map( () => fe )
				);
			} )
		).subscribe( fe => {
			let path = fe.toURL();
			if ( !path ) {
				console.error( "No file created" );
				return;
			}
			if ( _.get( global, "cordova.plugins.fileOpener2" ) ) {
				global.cordova.plugins.fileOpener2.open(
					path,
					contentType,
					{
						error : function(errorObj) {
							if ( _.get( global, "cordova.InAppBrowser.open" ) ) {
								global.cordova.InAppBrowser.open( path, "_system" );
								return;
							}
							alert('Error status: ' + errorObj.status + ' - Error message: ' + errorObj.message);
						},
						success : function () {
						}
					}
				);
				return;
			}

			if ( _.get( global, "cordova.InAppBrowser.open" ) ) {
				global.cordova.InAppBrowser.open( path, "_system" );
				return;
			}
			window.open( path, "_blank" );
		} );
};

export function createBlobFromStream( fileBinaryStream, contentType ) {
	const CHUNK_SIZE = 8192;
	let progress = new Rx.BehaviorSubject( {
		total: fileBinaryStream.size,
		received: 0
	} );
	let completition = (
		Rx.Observable.range( 0, ( ( fileBinaryStream.size + CHUNK_SIZE - 1 ) / CHUNK_SIZE ) | 0 )
		.concatMap( () =>
			Rx.Observable.defer( () => {
				let size = Math.min( CHUNK_SIZE, fileBinaryStream.size - fileBinaryStream.position );
				let subj = new Rx.ReplaySubject();
				fileBinaryStream.getNextBytesCb(
					size,
					chunk => {
						progress.onNext( {
							total: fileBinaryStream.size,
							received: fileBinaryStream.position
						} );
						subj.onNext( chunk );
						subj.onCompleted();
					}
				);
				return subj;
			} )
		)
		.toArray()
		.map( chunks => Buffer.concat( chunks ) )
		.map( fileContentBuffer => new Blob( [ fileContentBuffer ], { type: contentType } ) )
		.tap( () => { progress.onCompleted(); } )
	);
	return {
		completition, progress
	};
}

export function callUntilSuccessAsync( func ) {
	return Rx.Observable.create( observer => {
		let isDisposed = false;
		let recursive = () => {
			if ( isDisposed ) {
				observer.onCompleted();
				return;
			}
			try {
				func( resp => {
					if ( !resp || ( resp.status === "timeout" ) ) {
						setTimeout( recursive, configuration.getTransactionRetryDelay() );
						return;
					}
					observer.onNext( resp );
					observer.onCompleted();
				} );
			} catch( e ) {
				observer.onError( e );
			}
		};
		recursive();
		return () => isDisposed = true;
	} );
}

export function callUntilSuccessThen( func ) {
	return new Promise( ( resolve, reject ) => {
		let recursive = () => {
			func( resp => {
				if ( !resp || ( resp.status === "timeout" ) ) {
					setTimeout( recursive, configuration.getTransactionRetryDelay() );
					return;
				}
				resolve( resp );
			} );
		};
		recursive();
	} );
}

export function key2StringAsync( key ) {
	//TODO: exception in production mode
	let resSubj = new Rx.ReplaySubject();
	key.postponeManagedBuffer( mb => mb.useAsBuffer( b => {
		resSubj.onNext( b.toString( "base64" ) );
		resSubj.onCompleted();
	} ) );

	return resSubj;
}
