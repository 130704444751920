import Rx from "rx";
import ssgCrypto, {Config, Key, KEY_KINDS} from "ssg.crypto";
import Queue from "promise-queue";

import configuration from "../../common/configuration.js";

import ClientServerOrdered from "./client.server.ordered.js";

function key2StringAsync( key ) {
	let resSubj = new Rx.ReplaySubject();
	key.postponeManagedBuffer( mb => mb.useAsBuffer( b => {
		resSubj.onNext( b.toString( "base64" ) );
		resSubj.onCompleted();
	} ) );

	return resSubj;
}

class ClientServerEncrypted extends ClientServerOrdered {
	constructor( apiUrlBase, connectionId, sendFrom, fromIndex, signer, econfig ) {
		if ( !( econfig instanceof Config ) ) {
			throw new Error( "econfig of type Config required" );
		}
		super( apiUrlBase, connectionId, sendFrom, fromIndex, signer );
		this.onOrderedMessage( this._gotEncryptedMessage.bind( this ) );
		this._econfig = econfig;
		this._keyThen = new Promise( resolve => {
			this._keyCb = resolve;
		} );
		this._isKeySet = false;
		this._decryptQueue = new Queue( 1 );
		this._encryptQueue = new Queue( 1 );

	}

	debug( ) {
		this._debugging = true;
	}

	onDecryptedMessage( func ) {
		this._onDecryptedMessage = func;
	}

	_gotEncryptedMessage( from, body, index ) {
		if ( !body ) {
			//Failed message
			this._decryptQueue.add( () =>
				this._onDecryptedMessage( from, body, index )
			);
			return;
		}
		this._decryptQueue.add( () =>
			this._decryptThen( from, body, index, this._keyThen )
				.then( plainMB => {
					if ( plainMB.byteLength < 4 ) {
						plainMB.dispose();
						throw new Error( "Message body too small to contain index" );
					}

					let clientIndex;
					let bodyMB = ssgCrypto.createManagedBuffer( plainMB.byteLength - 4 );
					plainMB.useAsBuffer( plainBody => {
						clientIndex = plainBody.readUInt32LE( 0 );
						bodyMB.useAsBuffer( bodyBuffer => {
							plainBody.copy( bodyBuffer, 0, 4 );
						} );
					} );
					plainMB.dispose();
					if ( clientIndex !== index ) {
						console.error( `Client and server indexes does not match: ${clientIndex} va ${index}` );
						throw new Error( `Client and server indexes does not match: ${clientIndex} va ${index}` );
					}
				return this._onDecryptedMessage( from, bodyMB, index );
			} )
		);
	}

	setEncryptionKey( key ) {
		if ( !( key instanceof Key ) ) {
			throw new Error( "Invalid key type" );
		}
		if ( this._isKeySet ) {
			throw new Error( "Encryption key already set" );
		}
		if ( key.kind !== KEY_KINDS.SYMMETRIC_ENCRYPTION ) {
			throw new Error( "Invalid key kind" );
		}
		this._isKeySet = true;
		this._keyCb( key );
	}

	sendAndDisposeThen( managedBuffer, transaction, index ) {
		let promise = new Rx.ReplaySubject();
		if ( index === undefined ) {
			index = this.getSendIndex();
		}
		let newMB = ssgCrypto.createManagedBuffer( managedBuffer.byteLength + 4 );

		managedBuffer.useAsBuffer( buffer =>
			newMB.useAsBuffer( newBody => { //TODO: move copy method to managedBuffer class
				newBody.writeUInt32LE( index, 0 );
				buffer.copy( newBody, 4 );
			} )
		);

		transaction.waitFor( promise, "encrypting" );
		let connectionUseHandle = this.incUse();

		return this._encryptQueue.add( () =>
			this._encryptThen(
				newMB,
				index,
				this._keyThen,
				transaction //for use in MEK mode
			)
			.then( encryptedBuffer => {
				managedBuffer.dispose();
				newMB.dispose();
				this.decUse( connectionUseHandle );

				super.send( encryptedBuffer, transaction, index );
				promise.onCompleted();
			} )
		);
	}

	_encryptThen( managedBuffer, index, keyThen ) {
		return (
			keyThen
				.then( key =>
					ssgCrypto.encryptThen( managedBuffer, key, true, this._econfig )
				)
		);
	}

	_decryptThen( from, body, index, keyThen ) {
		let resultMB = ssgCrypto.createManagedBuffer( ssgCrypto.getDecryptedSize( body.length, this._econfig ) );
		return (
			keyThen
				.then( key =>
					this._isDisposed
					? new Promise()
					: ssgCrypto.decryptToManagedBufferThen( body, resultMB, key, true, this._econfig )
				)
				.then( () => resultMB )
		);
	}
}

export default ClientServerEncrypted;
