import React from "react";
import _ from "lodash";

import FullScreen from "../../components/fullscreen.jsx";
import TopLevel from "../common/top.level.view.jsx";
import Translation from "../../components/translation.jsx";
import Tinput from "../../components/tinput.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import ImageButton from "../../components/button.image.jsx";
import OnlineStatus from "../../components/online.status.jsx"

class SearchContactsButtonView extends React.Component {
    constructor() {
        super();
        this.state = {
            isPopupVisible: false
        };
        this.onButtonClick = this.onButtonClick.bind( this );
        this.onBack = this.onBack.bind( this );
    }
    onButtonClick() {
        this.setState( { isPopupVisible: true } );
    }
    onBack() {
        this.setState( { isPopupVisible: false } );
    }
    renderPopup() {
        return <Popup
            onBack={this.onBack}
            onRenderContacts={this.props.onRenderContacts}
            contacts={this.props.contacts}
        />;
    }
    render() {
        if ( this.state.isPopupVisible ) {
            return this.renderPopup();
        }
        return (
            <svg x="0px" y="0px" viewBox="0 0 56.966 56.966" className="search-svg" onClick={this.onButtonClick}>
                <path
                    d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
                    fill="#FFFFFF"
                />
            </svg>
        );
    }
}

class Popup extends React.Component {
    constructor() {
        super();
        this.state = {
            value: ""
        };
        this.onValueChange = this.onValueChange.bind( this );
    }
    onValueChange( value ) {
        this.setState( { value } );
    }
    render() {
        return (
            <FullScreen>
				<TopLevel>
					<header>
                        <Tinput
    						value={this.state.value}
    						onChange={ this.onValueChange }
    						placeholderTextId="contacts.search.placeholder"
                            autofocus={true}
                            className="input-header"
    					/>
                        <ImageButton type="icon-header-close" onClick={this.props.onBack} id="close"/>
                        <OnlineStatus/>
                    </header>
                    <main>
                        { this.props.onRenderContacts( this.state.value.toLowerCase() ) }
                    </main>
                    <DeviceBackButton onPress={ this.props.onBack }/>
                </TopLevel>
            </FullScreen>
        );
    }
}

export default SearchContactsButtonView;
