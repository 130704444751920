"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _bitContainer = require("./bit.container.js");

var _bitContainer2 = _interopRequireDefault(_bitContainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var BIT_CONTAINER_SIZE = 65536;

var Generator = function () {
  function Generator() {
    _classCallCheck(this, Generator);

    this._sources = [];
    this._priorityQueue = [];
    this._idleQueue = [];
    this._bitContainer = new _bitContainer2.default(BIT_CONTAINER_SIZE);
    this._idleTime = true;
  }

  _createClass(Generator, [{
    key: "addSource",
    value: function addSource(Source) {
      var _this = this;

      //TODO: check type
      var source = new Source(function (int32, count) {
        return _this._gotData(source, int32, count);
      });

      this._sources.push(source);

      source.setIdle(this._idleTime);
    }
  }, {
    key: "getPriorityDataThen",
    value: function getPriorityDataThen(size) {
      var _this2 = this;

      if (size > BIT_CONTAINER_SIZE) {
        throw "Invalid queryPriorityData size: " + size;
      }
      return new Promise(function (resolve) {
        _this2._priorityQueue.push({ length: size, resolve: resolve });
        _this2._setIdleTimeAll();
      });
    }
  }, {
    key: "getIdleDataThen",
    value: function getIdleDataThen(size) {
      var _this3 = this;

      if (size > BIT_CONTAINER_SIZE) {
        throw "Invalid queryIdleData size: " + size;
      }
      return new Promise(function (resolve) {
        _this3._idleQueue.push({ length: size, resolve: resolve });
      });
    }
  }, {
    key: "_gotData",
    value: function _gotData(source, int32, count) {
      var qItem = this._priorityQueue[0] || this._idleQueue[0];
      if (!qItem) {
        this._bitContainer.clear();
        return;
      }
      this._bitContainer.addBits(int32, count);

      if (this._bitContainer.getFullByteCount() < qItem.length) {
        return;
      }

      if (this._priorityQueue[0] === qItem) {
        this._priorityQueue.shift();
      }
      if (this._idleQueue[0] === qItem) {
        this._idleQueue.shift();
      }

      this._setIdleTimeAll();

      qItem.resolve(this._bitContainer.takeBytes(qItem.length));
    }
  }, {
    key: "_setIdleTimeAll",
    value: function _setIdleTimeAll() {
      var _this4 = this;

      if (this._idleTime === (this._priorityQueue.length === 0)) {
        return;
      }
      this._idleTime = this._priorityQueue.length === 0;

      this._sources.forEach(function (source) {
        return source.setIdle(_this4._idleTime);
      });
    }
  }]);

  return Generator;
}();

exports.default = Generator;