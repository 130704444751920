import React from "react";
import ReactDom from "react-dom";
import Translation from "./translation.jsx";
import FullScreenComponent from "./fullscreen.jsx";

class StatefulWrapper extends React.Component {
	render() {
		return React.Children.only( this.props.children );
	}
}

class Tooltip extends React.Component {
	constructor() {
		super();
		this.hide = this.hide.bind( this );
	}

	hide() {
		this.props.onHide();
	}

	componentDidMount() {
		global.document.addEventListener( "mousedown", this.hide );
		global.document.addEventListener( "touchstart", this.hide );
	}

	componentWillUnmount() {
		global.document.removeEventListener( "mousedown", this.hide );
		global.document.removeEventListener( "touchstart", this.hide );
	}

	render() {
		let { textId, rect } = this.props;
		let { top } = rect;
		let tooltipStyle = {
			"bottom": ( global.document.body.scrollHeight - top ) + "px"
		};
		return (
			<div className="tooltip" style={tooltipStyle}>
				<Translation textId={textId}/>
				<img src="images/tooltip-triangle.svg" className="triangle"/>
			</div>
		);
	}
}

class TooltipController extends FullScreenComponent {
	constructor() {
		super();
		this.state = { visible: false };
		this.hide = this.hide.bind( this );
	}

	show( rect ) {
		this.setState( { visible: true, rect } );
	}

	hide() {
		this.setState( { visible: false } );
	}

	remount() {
		if ( this.state.visible ) {
			ReactDom.render( this.renderContent(), this._mountedElement );
		} else {
			ReactDom.unmountComponentAtNode( this._mountedElement );
		}
	}

	renderContent() {
		let { children, ...props } = this.props;
		let { rect } = this.state;
		return <Tooltip {...props} rect={rect} onHide={this.hide}/>;
	}

	render() {
		let { children } = this.props;
		if ( !React.Children.count( children ) ) {
			return null;
		}
		return React.Children.only( children );
	}
}

class TooltipWrapper extends React.Component {
	constructor() {
		super();
		this.onClick = this.onClick.bind( this );
	}

	onClick() {
		let rect = ReactDom.findDOMNode( this.refs.trigger ).getBoundingClientRect();
		this.refs.tooltip.show( rect );
	}

	render() {
		let { textId, children } = this.props;
		let wrappedChild = React.cloneElement( React.Children.only( children ), { onClick: this.onClick } );
		return (
			<TooltipController ref="tooltip" textId={textId}>
				<StatefulWrapper ref="trigger">
					{wrappedChild}
				</StatefulWrapper>
			</TooltipController>
		);
	}
}

export default TooltipWrapper;
