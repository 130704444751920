import Rx from "rx";
import ssgCrypto, {KEY_KINDS} from "ssg.crypto";

import {
	serializeObjectWithKeysToNewManagedBufferAsync
} from "../../common/serializer.js";
import {connectKeyMap} from "../../api/services/remote.js";
import configuration from "../../common/configuration.js";

class RemoteInterwindowExchangeServer {
	constructor( onNewConnectData ) {
		this.receiveMessage = this.receiveMessage.bind( this );
		this._econfig = configuration.getDefaultEncryptionConfig();

		this._onNewConnectData = onNewConnectData;
		window.addEventListener( "message", this.receiveMessage, false );
	}

	receiveMessage( event ) {
		if ( window.location.origin !== event.origin ) {
			return;
		}
		if ( event.data ) {
			return;
		}
		Rx.Observable.fromPromise( Promise.all( [
			ssgCrypto.createRandomBase64StringThen( 32 ),
			ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, this._econfig ),
			ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, this._econfig ),
			ssgCrypto.createRandomKeyThen( KEY_KINDS.MAC, this._econfig )
		] ).then( ( [ connectionId, seedMacKey, seedEncryptionKey, macKey ] ) =>
			( { connectionId, seedMacKey, seedEncryptionKey, macKey } )
		) )
		.flatMap( params => this._onNewConnectData( params ).map( () => params ) )
		.flatMap( params => serializeObjectWithKeysToNewManagedBufferAsync(
			params, connectKeyMap
		) )
		.flatMap( mb => Rx.Observable.create( observer => {
			mb.useAsBuffer( b => {
				observer.onNext( b.toString( "base64" ) );
				observer.onCompleted();
			} );
		} ) )
		.subscribe( dataStr => {
			event.source && event.source.postMessage( dataStr, window.location.origin );
		} );
	}

	dispose( ) {
		window.removeEventListener( "message", this.receiveMessage );
	}
}

export default RemoteInterwindowExchangeServer;
