import React from "react";
import ImageButton from "./button.image.jsx";
import TooltipWrapper from "./tooltip.jsx";

let ButtonTooltip = ( { tooltipTextId, ...props } ) => (
	<TooltipWrapper textId={tooltipTextId}>
		<ImageButton {...props} />
	</TooltipWrapper>
);

ButtonTooltip.propTypes = {
	tooltipTextId: React.PropTypes.string.isRequired
};

export default ButtonTooltip;
