import React from "react";
import Rx from "rx";

import history from "../../components/history.js";
import Title from "./common/title.jsx";
import Loading from "./common/loading.jsx";

import remoteServiceLocator from "../../../api/services/locators/remote.js";
import Qr from "../../components/qr.jsx";
import Translation from "../../components/translation.jsx";

class LoginView extends React.Component {
	constructor() {
		super();
		this.state = {};
		this._remoteService = remoteServiceLocator();
	}

	componentWillMount() {
		this._initialSubscription = (
			this._remoteService.generateTokenAsync()
				.subscribe( token => {
					this.setState( { token } );
					this._remoteService.beginConnect( token );
				} )
		);
	}

	componentWillUnmount() {
		this._initialSubscription.dispose();
	}

	render() {
		if ( !this.state.token ) {
			// return <Translation textId="web.login.generateing.code" />;
			return <Loading />;
		}
		return (
			<div style={{width: "100%", height: "100%", background: "#eee"}}>
				<div style={{cssFloat: "left", background: "#fff", width: "100%"}}>
					<div style={{
							maxWidth: "100%",
							width: "384px",
							minWidth: "40%",
							cssFloat: "left"
						}}>
						<Qr code={this.state.token} style={{margin: "40px"}} id="qr-web"/>
					</div>
					<div style={{
							maxWidth: "100%",
							width: "384px",
							minWidth: "50%",
							cssFloat: "left"
						}}>
						<div style={{margin: "40px" }}>
							<h1>FINAL MESSENGER WEB</h1>
							<span style={{fontWeight: 500}}>
								<Translation textId="web.login.text1" />
							</span>
							<br />
							<br />
							<span style={{color: "gray"}}>
								<Translation textId="web.login.text2" />
							</span>
							<br />
								<br />
							<span style={{fontWeight: 500}}>
								<Translation textId="web.login.create.group" />
								<br />
								<a href="#external"><Translation textId="web.login.create.group.button" /></a>
							</span>
						</div>
					</div>
				</div>
				<div style={{textAlign: "center", clear: "both", padding: "20px", background: "#eee"}}>
					<img src="web/img/ios-android.svg" style={{
							maxWidth: "100%",
							width: "192px",
							margin: "20px"
						}} /> <br />
					<span style={{fontWeight: "500"}}>
						ANDROID & IOS <br />
					</span>
					<input type="text" value={this.state.token} style={{width: "100%", maxWidth: "640px"}} readOnly />
				</div>
				<Title textId="web.login.title" />
			</div>
		);
	}
}

export default LoginView;
