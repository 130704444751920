import Rx from "rx";

import configRepositoryLocator from "../repositories/locators/config.js";

class ConfigService {
  constructor() {
    this._repo = configRepositoryLocator();
  }

  getCurrentConfigAsync() {
    return (
      this._repo.isExistAsync()
        .flatMap( isExists => isExists
          ? this._repo.getCurrentConfigAsync()
          : Rx.Observable.just( null )
        )
    );
  }

  setCurrentConfigAsync( config ) {
    if ( !config ) {
      throw new Error( "Config required" );
    }

    return (
      this._repo.setCurrentConfigAsync( config )
    );
  }

  dropDataAsync() {
    return (
      this._repo.dropDataAsync()
    );
  }
}

export default ConfigService;
