import React from "react";
import Rx from "rx";
import _ from "lodash";
import FullScreen from "../../components/fullscreen.jsx";
import ImageButton from "../../components/button.image.jsx";
import {getAvaColorByName, getContactInitials, getStatusColor, sortContacts}
	from "../../../common/utils.js";
import TopLevel from "../common/top.level.view.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import Translation from "../../components/translation.jsx";
import OnlineStatus from "../../components/online.status.jsx"

let Avatar = ( { color, initials } ) => {
	return <div className="ava" style={{ backgroundColor: color }}>{initials}</div>;
};

let Participant = ( {participant, pid, onDelete} ) => {
	return (
		<div className="item">
			<Avatar {...{
					color: getAvaColorByName( participant.nickname ),
					initials: getContactInitials( participant.nickname )
				}} />
			{onDelete
				? <div
					onClick={ onDelete }
					className="round-btn f-right right icon delete"
					/>
				: null }
			<div className="name">{participant.nickname}</div>
		</div>
	);
};

let ParticipantList = ( { children } ) => (
	<div className="contact-list">
		{children}
		{
			!React.Children.count( children )
			? <span className="small-text">
					<br />
					<Translation textId="group.new.contacts.empty" />
				</span>
			: null
		}
	</div>
);

class ParticipantListBase extends React.Component {
	constructor() {
		super();

		this.onBackPress = this.onBackPress.bind( this );
		this.state = { participants: {} };
	}

	onBackPress() {
		this.props.onDone();
	}

	render() {
		return (
			<FullScreen>
				<TopLevel>
					<header>
						<ImageButton type="icon-arrow-left" onClick={this.onBackPress}/>
						<span className="header-caption">
							<Translation textId={ this.headerTextId } />
						</span>
						<OnlineStatus/>
					</header>
					<main>
						<ParticipantList>
							{ _.map( this.state.participants, ( participant, pid ) => (
									<Participant
										participant={ participant }
										pid={ pid }
										key={ pid }
									/>
							) ) }
							{
								!_.isEmpty( this.state.invites )
								? <div key="hr" className="hr"><Translation textId="group.participantlist.invites"/></div>
								: null
							}
							{ _.map( this.state.invites, ( participant, pid ) => (
									<Participant
										participant={ participant }
										pid={ pid }
										key={ pid }
										onDelete={ this.onDeleteInvite ? this.onDeleteInvite.bind(this, pid) : null }
									/>
							) ) }
						</ParticipantList>
					</main>
				</TopLevel>
				<DeviceBackButton onPress={ this.onBackPress }/>
			</FullScreen>
		);
	}

}

export default ParticipantListBase;
