import Rx from "rx";
import ssgCrypto, {Key, KEY_KINDS, Config} from "ssg.crypto";
import {deserializeObjectWithKeysFromManagedBufferThen,
	serializeObjectWithKeysToNewManagedBufferThen} from "../../common/serializer.js";
import ClientServerIndexValue from "./client.server.index.value.js";

const ITEM_KEY_MAP = {
	prevUKey: KEY_KINDS.INTERMEDIATE
};

class HistoryContainer extends ClientServerIndexValue {
	constructor( apiUrlBase, connectionId, firstDKey, seedKey, lastUKey,
		encryptionSaltKey, macSaltKey, fromIndex, lastIndex, econfig, useUKey ) {
		if ( !( firstDKey instanceof Key ) && useUKey ) {
			throw new Error( "firstDKey required" );
		}

		if ( !( seedKey instanceof Key ) ) {
			throw new Error( "seedKey required" );
		}

		if ( typeof lastIndex !== "number" ) {
			throw new Error( "lastIndex number required" );
		}

		if ( useUKey && ( lastIndex >= 0 ) && !( lastUKey instanceof Key ) ) {
			throw new Error( "lastUKey required" );
		}

		if ( firstDKey && firstDKey.kind !== KEY_KINDS.INTERMEDIATE ) {
			throw new Error( "invalid firstDKey kind" );
		}

		if ( lastUKey && lastUKey.kind !== KEY_KINDS.INTERMEDIATE ) {
			throw new Error( "invalid lastUKey kind" );
		}

		if ( seedKey.kind !== KEY_KINDS.INTERMEDIATE ) {
			throw new Error( "invalid seedKey kind" );
		}

		if ( !( econfig instanceof Config ) ) {
			throw new Error( "econfig is required" );
		}
		super( apiUrlBase, connectionId );

		this._fromIndex = fromIndex;
		this._firstDKey = firstDKey;
		this._seedKey = seedKey;
		this._encryptionSaltKey = encryptionSaltKey;
		this._macSaltKey = macSaltKey;
		this._econfig = econfig;
		this._changesSubject = new Rx.Subject();
		this._uKeysThen = Object.create( null );
		if ( lastIndex >= 0 ) {
			this._uKeysThen[ lastIndex ] = Promise.resolve( lastUKey );
		}
		this._lastIndex = lastIndex;
		this._isAddingItem = false;
		this._useUKey = useUKey;
	}

	getMaxIndexAsync( ) {
		if ( this._lastIndex !== -1 ) {
			return Rx.Observable.just( this._lastIndex );
		}
		return this._callUntilSuccessAsync( cb => {
			this.tryGetMaxIndex( cb );
		} ).map( ( { maxIndex } ) => maxIndex );
	}

	getUKeyDataThen() {
		if ( this._useUKey ) {
			return this.getUKeyDataThen2();
		}
		return Promise.resolve( { lastIndex: this._lastIndex } );
	}

	getUKeyDataThen2() {
		let lastIndex = this._lastIndex;
		return this._uKeysThen[ this._lastIndex ].then( lastUKey => ( {
			lastIndex, lastUKey
		} ) );
	}

	getDKeyDataThen( index ) {
		if ( !this._useUKey ) {
			return Promise.resolve( null );
		}
		return this._makeDKeyThen( index );
	}

	getUnsetRangesAsync( fromIndex, toIndex ) {
		/*if ( !~this._lastIndex && toIndex > this._lastIndex ) {
			toIndex = this._lastIndex;
		}
		if ( fromIndex < this._fromIndex ) {
			fromIndex = this._fromIndex;
		}*/
		return this._callUntilSuccessAsync( cb => {
			this.tryGetUnsetRanges( fromIndex, toIndex, cb );
		} ).map( ( { ranges } ) => ranges );
	}

	getItemsAsync( fromIndex, toIndex ) {
		/*if ( !~this._lastIndex && toIndex > this._lastIndex ) {
			toIndex = this._lastIndex;
		}
		if ( fromIndex < this._fromIndex ) {
			fromIndex = this._fromIndex;
		}*/
		return this._callUntilSuccessAsync( cb => {
			this.tryGetMessages( fromIndex, toIndex, cb );
		} ).flatMap( resp => Rx.Observable.create( observer => {
			let { messages } = resp;
			for( let index = toIndex; index >= fromIndex; index-- ) {
				if ( !messages[ index ] && index > 0 && !this._uKeysThen[ index - 1 ] ) {
					this._uKeysThen[ index - 1 ] = Promise.resolve( null );
					continue;
				}
				if ( !messages[ index ] ) {
					continue;
				}
				observer.onNext(
					this._checkAndDecryptMessageAsync( messages[ index ].value, index )
						.map( json => ( {
							index, json,
							ttlBaseTimestamp: messages[ index ].ttlBaseTimestamp
						} ) )
				);
			}
			observer.onCompleted();
		} ) ).concatAll().reduce(
			( acc, { json, index } ) => {
				acc[ index ] = json;
				return acc;
			},
			Object.create( null )
		);
	}

	observeChanges( ) {
		return this._changesSubject;
	}

	addItemThen( index, value, transaction ) {
		if ( ( this._lastIndex !== -1 ) && ( index <= this._lastIndex ) ) {
			console.warn( "Adding already present history item" );
			return Promise.resolve();
		}
		if ( this._isAddingItem ) {
			console.warn( "Already adding item" );
		}
		if ( index < this._fromIndex ) {
			throw new Error( "index < this._fromIndex" );
		}
		this._isAddingItem = true;
		return (
			this._setItemThen( index, value, transaction )
				.then( () => {
					this._lastIndex = index;
					this._isAddingItem = false;
				} )
		);
	}

	updateItemThen( index, value, transaction ) {
		/*if ( index > this._lastIndex ) {
			// throw new Error( "Invalid history container update item index" );
			console.warn( "Invalid history container update item index", index, this._lastIndex );
			return Promise.resolve();
		}
		if ( index < this._fromIndex ) {
			// throw new Error( "index < this._fromIndex" );
			console.warn( "index < this._fromIndex", index, this._lastIndex );
			return Promise.resolve();
		}*/
		return this._setItemThen( index, value, transaction );
	}

	_setItemThen( index, value, transaction ) {
		let connectionUseHandle = this.incUse();
		let waitSubj = new Rx.Subject();
		transaction.waitFor( waitSubj, "encrypt, mac, ukey" );
		return (
			this._encryptAndSignMessageThen( value, index )
				.then( buffer => {
					if ( buffer ) {
						let base64 = buffer.toString( "base64" );
						this.send( index, base64, transaction );
					}
					waitSubj.onCompleted();
					this.decUse( connectionUseHandle );
					//TODO: do this after transaction completed
					this._changesSubject.onNext( { index, value } );
				} )
		);
	}

	_makeDKeyThen( index ) {
		//TODO: save intermediate keys
		if ( index > this._fromIndex ) {
			return (
				this._makeDKeyThen( index - 1 )
					.then( prevKey =>
						ssgCrypto.createDerivedKeyFromKeysThen(
							prevKey,
							this._seedKey,
							KEY_KINDS.INTERMEDIATE,
							this._econfig
						)
							.then( dkey => {
								if ( prevKey !== this._firstDKey ) {
									prevKey.dispose();
								}
								return dkey;
							} )
					)
			);
		}
		if ( index === this._fromIndex ) {
			return Promise.resolve( this._firstDKey );
		}
		throw new Error( "Invalid index" );
	}

	_makeUKeyThen( index ) {
		if ( this._useUKey ) {
			return this._makeUKeyThen2( index );
		}
		return Promise.resolve( null );
	}

	_makeUKeyThen2( index ) {
		if ( index < this._fromIndex ) {
			throw new Error( "index < this._fromIndex" );
		}
		if ( ( this._lastIndex === -1 ) || ( index === this._lastIndex + 1 ) ) {
			//new message
			this._uKeysThen[ index ] = ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, this._econfig );
		}
		if ( !this._uKeysThen[ index ] ) {
			let startAt = +new Date;
			return new Promise( ( resolve, reject ) => {
				this.getItemsAsync( index + 1, index + 1 )
					.subscribe( () => {
						if ( !this._uKeysThen[ index ] ) {
							console.warn( "History item not found, nowhere to get u-key. Making random" );
							this._uKeysThen[ index ] = ssgCrypto.createRandomKeyThen(
								KEY_KINDS.INTERMEDIATE, this._econfig
							);
						}
						this._uKeysThen[ index ].then( resolve, reject );
					}, reject );
			} ).then( res => {
				return res;
			} );
		}
		return this._uKeysThen[ index ];
	}

	_makeDerivedKeysThen( index ) {
		if ( this._useUKey ) {
			this._makeDerivedKeysThen2( index );
		}
		return (
			Promise.all( [
				ssgCrypto.createDerivedKeyFromKeysThen( this._seedKey, this._encryptionSaltKey, KEY_KINDS.SYMMETRIC_ENCRYPTION, this._econfig ),
				ssgCrypto.createDerivedKeyFromKeysThen( this._seedKey, this._macSaltKey, KEY_KINDS.MAC, this._econfig )
			] )
		);
	}

	_makeDerivedKeysThen2( index ) {
		let startAt = +new Date;
		return (
			Promise.all( [
				this._makeUKeyThen( index ),
				this._makeDKeyThen( index )
			] )
			.then( ( [ uKey, dKey ] ) => uKey && //uKey may be null - in this case there is not possible to decrypt message
				ssgCrypto.createDerivedKeyFromKeysThen(
					uKey, dKey, KEY_KINDS.INTERMEDIATE, this._econfig
				)
			)
			.then( iKey => !iKey
				? [ null, null ]
				: Promise.all( [
					ssgCrypto.createDerivedKeyFromKeysThen( iKey, this._encryptionSaltKey, KEY_KINDS.SYMMETRIC_ENCRYPTION, this._econfig ),
					ssgCrypto.createDerivedKeyFromKeysThen( iKey, this._macSaltKey, KEY_KINDS.MAC, this._econfig )
				] )
			)
			.then( res => {
				return res;
			} )
		);
	}

	_checkAndDecryptMessageAsync( base64, index ) {
		let uKeyCb;
		if ( index > 0 && !this._uKeysThen[ index - 1 ] ) {
			this._uKeysThen[ index - 1 ] = new Promise( resolve => {
				uKeyCb = resolve;
			} );
		}
		return Rx.Observable.defer( () => {
			let buffer = new Buffer( base64, "base64" );
			let mac = buffer.slice( buffer.length - 32 );
			let encrypted = buffer.slice( 0, buffer.length - 32 );
			return Rx.Observable.fromPromise(
				this._makeDerivedKeysThen( index )
					.then( ( [ encryptionKey, macKey ] ) => encryptionKey &&
						ssgCrypto.makeHmacCodeThen( macKey, encrypted, this._econfig )
							.then( generatedCode => {
								if ( !generatedCode.equals( mac ) ) {
									console.error( "Mac verification failed" );
									return null;
								}
								let mb = ssgCrypto.createManagedBuffer(
									ssgCrypto.getDecryptedSize( encrypted.length, this._econfig )
								);
								return ssgCrypto.decryptToManagedBufferThen(
									encrypted, mb, encryptionKey, true, this._econfig
								).then( () =>
									deserializeObjectWithKeysFromManagedBufferThen(
										mb, ITEM_KEY_MAP, this._econfig
									)
								).then( item => { mb.dispose(); return item; } );
							} )
					)
					.then( item => {
						if ( !item ) {
							item = { prevUKey: null, value: null };
						}
						let { prevUKey, value } = item;
						if ( uKeyCb ) {
							uKeyCb( prevUKey || null );
						}
						return value;
					} )
			);
		} );
	}


	_encryptAndSignMessageThen( json, index ) {
		if ( !json ) {
			return Promise.resolve( null );
		}
		return (
			Promise.all( [
				this._makeDerivedKeysThen( index ),
				( index === 0 || !this._useUKey
					? Promise.resolve( null )
					: this._makeUKeyThen( index - 1 ) )
					.then( prevUKey => {
						if ( !prevUKey && index && this._useUKey) {
							console.warn( "Storing message with non-zero index whithout u-key" );
							debugger;
						}
						return serializeObjectWithKeysToNewManagedBufferThen(
							{ prevUKey: prevUKey || undefined, value: json }, ITEM_KEY_MAP
						);
					} )
			] )
				.then( ( [ [ encryptionKey, macKey ], mb ] ) =>
					ssgCrypto.encryptThen( mb, encryptionKey, true, this._econfig )
						.then( encrypted => {
							mb.dispose();
							return (
								ssgCrypto.makeHmacCodeThen( macKey, encrypted, this._econfig )
									.then( macCode => Buffer.concat( [ encrypted, macCode ] ) )
							);
						} )
				)
				.catch( error => {
					console.warn( "failed to encrypt history message", error );
					return null;
				} )
		);
	}
}

export default HistoryContainer;
