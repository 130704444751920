import Rx from "rx";
import _ from "lodash";

class DiagnosticsService {
  constructor() {
    this._serversStatusSubj = new Rx.BehaviorSubject( Object.create( null ) );
  }

  reportServerStatus( server, isAvailable ) {
    let timestamp = +new Date;
    let currentStatus = this._serversStatusSubj.getValue();
    for( let server in currentStatus ) {
      let status = currentStatus[ server ];
      status.timestamp = Math.min( timestamp, status.timestamp );
    }
    currentStatus[ server ] = {
      timestamp,
      isAvailable
    };
    this._serversStatusSubj.onNext( currentStatus );
  }

  observeOnlineStatus() {
    return (
      this._serversStatusSubj
        .map( statuses =>
          _.reduce(
            statuses,
            ( acc, status ) => acc.timestamp < status.timestamp ? status : acc,
            { timestamp: 0, isAvailable: false }
          ).isAvailable ? "online": "offline"
        )
    );
  }
}

export default DiagnosticsService;
