function makeThumbnailByUrl( url, maxWidth, maxHeight, resolve, reject ) {
    let img = document.createElement( "img" );
    img.onload = function( e ) {
        let canvas = document.createElement( "canvas" );
        let scale = Math.min( maxWidth / img.width, maxHeight / img.height );
        canvas.width = img.width * scale;
        canvas.height = img.height * scale;
        canvas.getContext( "2d" ).drawImage( img, 0, 0, canvas.width, canvas.height );
        let dataUri = canvas.toDataURL( "image/jpeg", 0.7 );
        let truncateStr = "data:image/";
        let truncateLength = truncateStr.length;
        if ( dataUri.slice( 0, truncateLength ) !== truncateStr ) {
            reject( null );
            console.warn( "Invalid data url generated", dataUri );
            return;
        }
        resolve( dataUri.slice( truncateLength ) );
    };
    img.onerror = reject;
    img.src = url;
}

export function makeThumbnailThen( file, maxWidth, maxHeight ) {
    return new Promise( ( resolve, reject ) => {
        let reader = new FileReader();
        reader.onload = function( e ) {
            makeThumbnailByUrl( e.target.result, maxWidth, maxHeight, resolve, reject );
        };

        reader.readAsDataURL( file );
    } );
};
