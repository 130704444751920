import ssgCrypto, {KEY_KINDS} from "ssg.crypto";

import keyHelper from "./key.helper.js";
import Decrypter from "./decrypter.js";
import Unpack from "./unpack.js";
import MessageService from "../../services/message.js";
import configuration from "../../../common/configuration.js";
import DebuggableError from "../../errors/debuggable.error.js";
import {decodeMessageToken} from "./address.codec.js";
import BinaryStream from "../binary.stream.js";
import BinarySource from "../binary.source.js";

const CHUNK_SIZE = 16384;

class MessageReceiver {
	constructor( params ) {
		if ( !params.token ) {
			throw new Error( "message token required" );
		}
		this._tokenData = decodeMessageToken( params.token );
		this._messageService = new MessageService( this._tokenData.apiUrlBase );
		this._firstBlock = null;
	}

	getBufferThen( password ) {
		const { apiUrlBase, messageId, algorithm, clientKey } = this._tokenData;
		const blockSize = ssgCrypto.getEncryptionAlgorithmBlockSize( algorithm );
		//TODO: use apiUrlBase
		return (
			this._messageService.receiveMessageThen( messageId )
				.then( ( { message, serverSeed } ) => {
					serverSeed = Buffer.from( serverSeed, "base64" );
					message = Buffer.from( message, "base64" );

					return ssgCrypto.createKeyFromBufferThen(
						keyHelper.saltKey( clientKey, serverSeed, password ),
						KEY_KINDS.SYMMETRIC_ENCRYPTION,
						configuration.getDefaultEncryptionConfig()
					).then( ( key ) => ( {
						message,
						serverSeed,
						key
					} ) )
				} )
				.then( ( { message, serverSeed, key } ) => {
					const firstBlockSize = Math.min( 64, message.length );
					const firstBlock = message.slice( 0, firstBlockSize );
					const restOfMessage = message.slice( firstBlockSize );
					const messagePosition = keyHelper.getMessagePosition(
						clientKey, serverSeed, password
					);
					const block1Offset = messagePosition * blockSize;
					const block2Offset = ( messagePosition + 2 ) * blockSize;

					const block1 = firstBlock.slice( block1Offset, block1Offset + blockSize ); //iv
					const block2 = firstBlock.slice( block2Offset, block2Offset + blockSize ); //encrypted header

					return Decrypter.decryptHeaderThen( {
						buffer: Buffer.concat( [ block1, block2 ] ),
						key,
						encryptionMethod: algorithm
					} ).then( ( header ) => {
						const unpack = new Unpack( {
							chunkSize: blockSize,
							binaryStream: BinaryStream.fromBuffer( message )
						} );

						const encryptedBinaryStream = unpack.createBinaryStream( {
							position: messagePosition,
							chunkCount: header.readUInt32LE( 0 ) + 1/*iv*/
						} );

						const decrypter = new Decrypter( {
							encryptionMethod: algorithm,
							key,
							binaryStream: encryptedBinaryStream
						} );
						const plainBinaryStream = decrypter.getBinaryStream();
						return new Promise( ( resolve ) => {
							plainBinaryStream.getAllBytesCb( resolve );
						} );
					} );
				} )
		);
	}

	getStreamFactoryThen( password, monitorCb ) {
		if ( typeof password !== "string" ) {
			throw new Error( "Password required" );
		}
		let messagePosition, key, firstBlock, block1, block2;
		let { apiUrlBase, messageId, algorithm, clientKey } = this._tokenData;
		let blockSize = ssgCrypto.getEncryptionAlgorithmBlockSize( algorithm );
		return (
		this._messageService.beginReadThen( { id: messageId, apiUrlBase } )
			.then( metadata => {
				if ( !( this._serverSeed = metadata.serverSeed ) ) {
					throw new DebuggableError(
						"serverSeed expected but did not received",
						{ id: this._tokenData.messageId, response: metadata }
					);
				}

				if ( !( this._hash = metadata.hash ) ) {
					throw new DebuggableError(
						"hash expected but did not received",
						{ id: this._tokenData.messageId, response: metadata }
					);
				}

				if ( !( this._dataLength = metadata.dataLength ) ) {
					throw new DebuggableError(
						"dataLength expected but did not received",
						{ id: this._tokenData.messageId, response: metadata }
					);
				}

				if ( !( this._readToken = metadata.readToken ) ) {
					throw new DebuggableError(
						"readToken expected but did not received",
						{ id: this._tokenData.messageId, response: metadata }
					);
				}
				messagePosition = keyHelper.getMessagePosition(
					clientKey,
					this._serverSeed,
					password
				);
				return Promise.all( [
					this._messageService.readChunkThen( {
						apiUrlBase,
						token: this._readToken,
						position: 0,
						length: Math.min( 64, this._dataLength )
					} ),
					ssgCrypto.createKeyFromBufferThen(
						keyHelper.saltKey( clientKey, this._serverSeed, password ),
						KEY_KINDS.SYMMETRIC_ENCRYPTION,
						configuration.getDefaultEncryptionConfig()
					)
				] ).then( ( [ firstBlock_, key_ ] ) => {
						key = key_;
						firstBlock = firstBlock_;
					}
				); //TODO: retry
			} )
			.then( () => {
				this._firstBlock = firstBlock;
				let block1Offset = messagePosition * blockSize;
				let block2Offset = ( messagePosition + 2 ) * blockSize;

				block1 = firstBlock.slice( block1Offset, block1Offset + blockSize ); //iv
				block2 = firstBlock.slice( block2Offset, block2Offset + blockSize ); //encrypted header

				return Decrypter.decryptHeaderThen( {
					buffer: Buffer.concat( [ block1, block2 ] ),
					key: key,
					encryptionMethod: algorithm
				} );
			} )
			.then( header => isBuffered => {
				let packedBinaryStream = this._createBinaryStream( 0, isBuffered, monitorCb );
				let unpack = new Unpack( {
					chunkSize: blockSize,
					binaryStream: packedBinaryStream
				} );

				let encryptedBinaryStream = unpack.createBinaryStream( {
					position: messagePosition,
					chunkCount: header.readUInt32LE( 0 ) + 1/*iv*/
				} );

				let decrypter = new Decrypter( {
					encryptionMethod: algorithm,
					key,
					binaryStream: encryptedBinaryStream
				} );
				let plainBinaryStream = decrypter.getBinaryStream();
				return plainBinaryStream;
			} )
		);
	}

	_createBinaryStream( skipBytesCount, isBuffered, monitorCb ) {
		if ( !( "_dataLength" in this ) ) {
			throw new Error( "Metadata not read" );
		}
		let bs = new BinarySource(
			( start, end, cb ) => {
				this._messageService.readChunkThen( {
					apiUrlBase: this._tokenData.apiUrlBase,
					token: this._readToken,
					position: start + skipBytesCount,
					length: end - start
				} ).then( cb ) //Error?
			},
			this._dataLength - skipBytesCount
		);
		if ( !isBuffered ) {
			return BinaryStream.fromBinarySource( bs, monitorCb );
		}
		//BUG: For some reason real concurency is half of this
		return BinaryStream.createForwardPreRead( bs, CHUNK_SIZE, 10 * CHUNK_SIZE, 5, monitorCb );
	}
}

export default MessageReceiver;
