import _ from "lodash";
import Rx from "rx";

import Multicast from "../transport/multicast.js";
import Contact from "../models/contact.js";
import contactRepositoryLocator from "../repositories/locators/contact.js";
import configuration from "../../common/configuration.js";
import {ChatMessageText,MESSAGE_TYPE} from "../models/chat.message.js";
import ContactWithHistoryService from "./contact.with.history.js";
import {
	deserializeFromBase64StringAsync,
	serializeMessageToBase64StringAsync,
	sendMessageLocalAsync
} from "../models/message/technical.js";
import { receiveMessageAsync } from "../models/message/technical.js";

class GroupsService extends ContactWithHistoryService {
	constructor( ) {
		//No shared contacts allowed
		super( "group" );
		this._joinQueue = new Rx.Subject();
		this._joinQueue.concatAll().subscribe();
	}

	createGroupWithParticipantsAsync( name, nickname, participantIds, contactService, progressSubj ) {
		let group;
		if ( !_.isArray( participantIds ) ) {
			throw new Error( "participantIds expected to be an array" );
		}
		return (
			this.createGroupAsync( name, nickname )
				.tap( g => {group = g;} )
				.flatMap( () => Rx.Observable.fromArray( participantIds ) )
				.flatMap( id => contactService.getContactsAsync()
					.map( contacts => _.find( contacts, candidate => candidate.id === id ) )
				)
				.filter( contact => !!contact ) //skip if not found
				.flatMap( contact =>
					this.createInviteOrNullAsync( group, contact.name )
						.flatMap( inviteString => inviteString
							? contactService.sendInviteToGroupAsync( contact, inviteString )
							: Rx.Observable.empty()
						)
				)
				.tap( () => { progressSubj && progressSubj.onNext( progressSubj.getValue() + 1 ); } )
				.toArray()
				.map( () => { progressSubj && progressSubj.onCompleted(); return group; } )
		);
	}

	createGroupAsync( groupName, nickname ) {
		if ( !groupName ) {
			throw new Error( "groupName required" );
		}
		if ( !nickname ) {
			throw new Error( "nickname required" );
		}
		let contact;
		return (
			Contact.createNewAsync( this._contactType, this.getUnconflictedName( groupName ) )
				.flatMap( c => this._insertContactAsync( contact = c ) )
				.flatMap( () => this._addMulticastAsync( contact,
					Multicast.createNewAsync(
						configuration.getSocketBase(),
						nickname,
						contact.sharedId,
						contact.pid,
						contact.seedKey,
						contact.dhPrivKey,
						contact.signKey,
						configuration.getDefaultEncryptionConfig(),
						this._getP2pPrivateHandlers()
					)
				) )
				.map( m => contact )
		);
	}

	createExternalInviteAsync( contact, nickname ) {
		return (
			this._getMulticastOrNullAsync( contact )
				.flatMap( multicast => {
					if ( !multicast ) {
						return null;
					}
					return multicast.createInviteAsync( nickname );
				} )
				.flatMap( invite =>
					sendMessageLocalAsync( {...invite, type: "invite", name: contact.name } )
						.tap( () => {
							invite.tmpPrivateKey.dispose();
						} )
						.map( localToken =>
							configuration.getWebUrlBase() + "/#external/" + localToken
						)
				)
		);
	}

	_onMulticastCreated( contact, multicast ) {
		//TODO: dispose, error handling
		multicast.observeMeta()
			.flatMap( ( { name } ) => {
				if ( !name ) {
					return Rx.Observable.empty();
				}
				return this.updateAsync( contact.id, { name } );
			} ).subscribe();

		super._onMulticastCreated( contact, multicast );
	}

	createInviteOrNullAsync( contact, nickname ) {
		if ( !nickname ) {
			throw new Error( "nickname required" );
		}
		return (
			this._getMulticastOrNullAsync( contact )
				.flatMap( multicast => {
					if ( !multicast ) {
						return null;
					}
					return multicast.createInviteAsync( nickname );
				} )
				.flatMap( invite => {
					if ( !invite ) {
						return null;
					}
					invite.name = contact.name;

					return (
						serializeMessageToBase64StringAsync( { ...invite, type: "invite" } )
							.tap( () => {
								invite.tmpPrivateKey.dispose();
							} )
					);
				} )
		);
	}

	acceptInviteAsync( inviteData, name ) {
		//TODO: use secure memory management
		let group;

		let subj = new Rx.ReplaySubject();
		this._joinQueue.onNext( Rx.Observable.defer( () => {
			let found = _.find( this._contacts, c => c.sharedId.equals( inviteData.sharedId ) );
			if ( found ) {
				subj.onNext( found );
				subj.onCompleted();
				return (
					this._getMulticastOrNullAsync( found )
						.flatMap( multicast => multicast
							? multicast.removeInviteAsync( inviteData.tmpPid )
							: Rx.Observable.just( null )
						)
				);
			}
//TODO: first create and store contact, than join
			return (
				this.local2ServerTimeAsync( +new Date )
					.flatMap( lastMessageTS =>
						Contact.createFromInviteAsync( inviteData,
							this.getUnconflictedName( name || inviteData.name ),
							this._contactType,
							lastMessageTS
						)
					)
					.tap( g => { group = g; } )
					.flatMap( () => this._insertContactAsync( group ) )
					.flatMap( () => this._addMulticastAsync( group, Multicast.tryJoinAsync(
						inviteData.nickname,
						{...inviteData, signKey: group.signKey, dhPrivKey: group.dhPrivKey, pid: group.pid.toString( "base64" ) },
						this._getP2pPrivateHandlers()
					) ) )
					.flatMap( m => m
						? this._sendMessageAsync( group, { type: "group_join" } ).map( () => m )
						: this._deleteContactAsync( group.id ).map( () => null )
					)
					.tap( m => {
							if ( !m ) {
								group = null;
							}
						},
						error => {
							subj.onError( error );
						},
						() => {
							subj.onNext( group );
							subj.onCompleted();
						}
					)
				);
		} ) );
		return subj;
	}

	acceptInviteIfNotJoinedAsync( inviteString ) {
		return (
			deserializeFromBase64StringAsync( inviteString )
				.flatMap( inviteData => this.acceptInviteDataIfNotJoinedAsync( inviteData ) )
		);
	}

	acceptInviteByTokenIfNotJoinedAsync( inviteToken ) {
		return (
			receiveMessageAsync( inviteToken )
				.flatMap( inviteData => this.acceptInviteDataIfNotJoinedAsync( inviteData ) )
		);
	}

	acceptInviteDataIfNotJoinedAsync( inviteData ) {
		//TODO: use secure memory management
		let multicast, group;

		let subj = new Rx.ReplaySubject();
		this._joinQueue.onNext( Rx.Observable.defer( () => {
			let found = _.find( this._contacts, c => c.sharedId.equals( inviteData.sharedId ) );
			if ( found ) {
				subj.onNext( false );
				subj.onCompleted();
				return (
					this._getMulticastOrNullAsync( found )
						.flatMap( multicast => multicast
							? multicast.removeInviteAsync( inviteData.tmpPid )
							: Rx.Observable.just( null )
						)
				);
			}
//TODO: first create and store contact, than join
			return (
				this.local2ServerTimeAsync( +new Date )
					.flatMap( lastMessageTS =>
						Contact.createFromInviteAsync( inviteData,
							this.getUnconflictedName( inviteData.name ),
							this._contactType,
							lastMessageTS
						)
					)
					.tap( g => group = g )
					.flatMap( () => this._insertContactAsync( group ) )
					.flatMap( () => this._addMulticastAsync( group, Multicast.tryJoinAsync(
						inviteData.nickname,
						{...inviteData,
							signKey: group.signKey, dhPrivKey: group.dhPrivKey,
							pid: group.pid.toString( "base64" )
						},
						this._getP2pPrivateHandlers()
					) ) )
					.flatMap( m => m
						? this._sendMessageAsync( group, { type: "group_join" } ).map( () => m )
						: this._deleteContactAsync( group.id )
					)
					.tap( m => multicast = m )
					.tap( () => {},
						error => {
							subj.onError( error );
						},
						() => {
							subj.onNext( group );
							subj.onCompleted();
						}
					)
				);
		} ) );
		return subj;
	}

	deleteGroupAsync( contact ) {
		if ( contact.status === "failed" ) {
			return (
				this.deleteContactAsync( contact.id )
			);
		}
		return (
			this._sendMessageAsync( contact, {
					type: "group_exit"
				} )
				.flatMap( () => this._getMulticastOrNullAsync( contact ) )
				.filter( m => !!m )
				.flatMap( multicast => {
					return multicast.removeAsync( contact.pid );
				} )
				.flatMap( () => this.deleteContactAsync( contact.id ) )
				.last( { defaultValue: null } )
			);
	}

	deleteParticipantAsync( contact, pid ) {
		return (
			this._sendMessageAsync( contact, {
					type: "group_exit"
				} )
				.flatMap( () => this._getMulticastOrNullAsync( contact ) )
				.flatMap( multicast => {
					if ( !multicast ) {
						return Rx.Observable.just( null );
					}
					return multicast.removeAsync( pid );
				} )
			);
	}

	deleteInviteAsync( contact, pid ) {
		return (
			this._getMulticastOrNullAsync( contact )
				.flatMap( multicast => {
					if ( !multicast ) {
						return Rx.Observable.just( null );
					}
					return multicast.removeInviteAsync( pid );
				} )
			);
	}

	renameSelfAsync( contact, nickname ) {
		return (
			this._getMulticastOrNullAsync( contact )
				.flatMap( multicast => multicast
					? multicast.renameAsync( nickname )
					: Rx.Observable.just( null )
				)
		);
	}

	observeParticipants( contact ) {
		return (
			this._getMulticastOrNullAsync( contact )
				.filter( m => !!m )
				.flatMap( multicast => multicast.observeParticipantsLatest() )
				.map( unfiltered => {
					let participants = Object.create( null );
					for ( let pid in unfiltered ) {
						let p = unfiltered[ pid ];
						if ( p.isExited ) {
							continue;
						}
						if ( !p.aliasTo ) {
							participants[ pid ] = p;
						}
						let p2 = p;
						while ( !p2.nickname && p2.aliasTo ) {
							p2 = unfiltered[ p2.aliasTo ];
						}
						participants[ pid ] = {...p, nickname: p2.nickname || ""};
					}
					return participants;
				} )
			);
	}

	observeInvites( contact ) {
		return (
			this._getMulticastOrNullAsync( contact )
				.flatMap( multicast => {
					if ( !multicast ) {
						return Rx.Observable.empty();
					}
					let ob = multicast.observeInvites();
					return ob;
				} )
			);
	}

	_processMessageJsonAndGetModel( msg, fromContact, multicast, index ) {
		let {json, from } = msg;
		switch( json.type ) {
			case "group_join":
				if ( multicast.isOutgoingParticipant( msg.from ) ) {
					return null;
				}
				return new ChatMessageText(
					MESSAGE_TYPE.SERVICE,
					"service.message.group.join",
					json.timestamp,
					from,
					index,
					msg.p2pIndex
				);
			case "group_exit":
				if ( multicast.isOutgoingParticipant( msg.from ) ) {
					return null;
				}
				return new ChatMessageText(
					MESSAGE_TYPE.SERVICE,
					"service.message.group.left",
					json.timestamp,
					from,
					index,
					msg.p2pIndex
				);
		}
		return super._processMessageJsonAndGetModel( msg, fromContact, multicast, index );
	}
}

export default GroupsService;
