"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _crypto = require("crypto");

var _crypto2 = _interopRequireDefault(_crypto);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var hash = {
	create: function create() {
		var newHash = _crypto2.default.createHash("sha256");
		[].forEach.call(arguments, function (arg) {
			return newHash.update(arg);
		});
		return newHash.digest();
	}
};

exports.default = hash;