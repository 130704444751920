import React from "react";
import _ from "lodash";

import { sortContacts }	from "../../../../common/utils.js";

class PromptContactsView extends React.Component {
	toggleContact( contact ) {
		let selectedIds = this.props.selectedIds.slice( 0 );
		let index = selectedIds.indexOf( contact.id );
		if ( ~index ) {
			selectedIds.splice( index, 1 );
		} else {
			selectedIds.push( contact.id );
		}
		this.props.onChange( selectedIds );
	}

	render() {
		return (
			<div className="list" style={ this.props.style }>
				{
					_.map(
						sortContacts( _.filter( this.props.contacts, { status: "active" } ) ),
						contact =>
							<Contact
								key={ contact.id }
								contact={ contact }
								onChange={ this.toggleContact.bind( this, contact ) }
								checked={ !!~this.props.selectedIds.indexOf( contact.id ) }
								disabled={ !!this.props.disabledIds2Text[ contact.id ] }
								text={ this.props.disabledIds2Text[ contact.id ] || null }
							/>
					)
				}
			</div>
		);
	}

	static get propTypes() {
		return {
			onChange: React.PropTypes.func.isRequired,
			selectedIds: React.PropTypes.arrayOf( React.PropTypes.number ).isRequired,
			contacts: React.PropTypes.array.isRequired,
			disabledIds2Text: React.PropTypes.object
		};
	}

	static get defaultProps() {
		return {
			disabledIds2Text: Object.create( null )
		};
	}
}

class Contact extends React.Component {
	render() {
		return (
			<div className="checkbox">
				<label className="nice-checkbox">
					<input
						type="checkbox"
						value="None"
						onChange={this.props.onChange}
						checked={this.props.checked}
						disabled={this.props.disabled}
					/>
					<span className="check"></span> {this.props.contact.name}
					<div className="date">{this.props.text}</div>
				</label>
			</div>
		);
	}
}

export default PromptContactsView;
