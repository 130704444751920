import React from "react";

import Title from "./title.jsx"
import remoteServiceLocator from "../../../../api/services/locators/remote.js";
import sounds from "../../../components/sounds.js";

class NotificationsView extends React.Component {
    constructor() {
        super();
        this._service = remoteServiceLocator();
        this.state = {unread: 0};
    }

    componentWillMount(){
      this._unreadSubscription = (
  			Rx.Observable.combineLatest(
  				this._service.observeUnreadContacts(),
  				this._service.observeUnreadGroups(),
  				this._service.observeUnreadShared(),
          ( c, g, s ) => c + g + s
        )
        .subscribe( unread => {
            this.setState( {unread} );
        } )
      );
    }

    componentWillUnmount() {
      this._unreadSubscription.dispose();
    }

    componentDidUpdate( prevProps, prevState ) {
      if ( this.state.unread <= prevState.unread ) {
          return;
      }
      sounds.newMessage();
    }

    renderTitle() {
        if ( !this.state.unread ) {
            return null;
        }
        return <Title textId="web.unread.title" params={ [ this.state.unread ] } index="1"/>;
    }

    render() {
        return this.renderTitle();
    }
}
export default NotificationsView;
