import ssgCrypto from "ssg.crypto";

import ClientServerBase from "./client.server.base.js";
import configuration from "../../common/configuration.js";

class ClientServerUser extends ClientServerBase {
	constructor( ) {
		super( configuration.getSocketBase(), "" );
		this._on( "userData", this._gotUserData.bind( this ) ); //This is for list
		this._on( "user", this._gotUser.bind( this ) ); //This is for single user
		this._listenerId2Observable = Object.create( null );
	}

	userIsPrivilegedAsync( id ) {
		if ( typeof id !== "string" ) {
			throw new Error( "id must be a string" );
		}
		return (
			this._callUntilSuccessAsync( cb => {
				this._tryMakeCall( "userGet", { id }, cb );
			} )
				.map( ({user: {isPrivileged}}) => isPrivileged )
		);
	}

	userCreateAsync( id, userKey, isPrivileged, expireDt ) {
		if ( typeof id !== "string" ) {
			throw new Error( "id must be a string" );
		}
		if ( typeof userKey !== "string" ) {
			throw new Error( "userKey must be a string" );
		}
		if ( typeof isPrivileged !== "boolean" ) {
			throw new Error( "isPrivileged must be boolean" );
		}

		return (
			this._callUntilSuccessAsync( cb => {
				this._tryMakeCall( "userCreate", { id, userKey, isPrivileged, expireDt: expireDt || null }, cb );
			} )
		);
	}

	userGetAllAsync( userKey ) {
		if ( typeof userKey !== "string" ) {
			throw new Error( "userKey must be a string" );
		}

		return (
			this._callUntilSuccessAsync( cb => {
				this._tryMakeCall( "userGetAll", { userKey }, cb );
			} )
			.map( ({users}) => users )
		);
	}

	userUpdateAsync( id, userKey, isPrivileged, expireDt ) {
		if ( typeof id !== "string" ) {
			throw new Error( "id must be a string" );
		}
		if ( typeof userKey !== "string" ) {
			throw new Error( "userKey must be a string" );
		}
		if ( typeof isPrivileged !== "boolean" ) {
			throw new Error( "isPrivileged must be boolean" );
		}

		const updateObj = { id, userKey, isPrivileged, expireDt: expireDt || null };

		return (
			this._callUntilSuccessAsync( cb => {
				this._tryMakeCall( "userUpdate", updateObj, cb );
			} )
		);
	}

	userDeleteAsync( id, userKey ) {
		if ( typeof id !== "string" ) {
			throw new Error( "id must be a string" );
		}
		if ( typeof userKey !== "string" ) {
			throw new Error( "userKey must be a string" );
		}

		return (
			this._callUntilSuccessAsync( cb => {
				this._tryMakeCall( "userDelete", { id, userKey }, cb );
			} )
		);
	}

	userGetIsConfiguredAsync( ) {
		return (
			this._callUntilSuccessAsync( cb => {
				this._tryMakeCall( "userGetIsConfigured", {}, cb );
			} )
		);
	}

	userSetKeyAsync( userKey, prevUserKey ) {
		if ( typeof userKey !== "string" ) {
			throw new Error( "userKey must be a string" );
		}
		if ( typeof prevUserKey !== "string" ) {
			throw new Error( "prevUserKey must be a string" );
		}
		return (
			this._callUntilSuccessAsync( cb => {
				this._tryMakeCall( "userSetKey", { userKey, prevUserKey }, cb );
			} )
		);
	}

	observeUserList( userKey ) {
		if ( typeof userKey !== "string" ) {
			throw new Error( "userKey must be a string" );
		}
		let resultObservable = new Rx.BehaviorSubject( [] ); //.getValue is used
		return (
			Rx.Observable.fromPromise(
				ssgCrypto.createRandomBase64StringThen( 32 )
					.then( listenerId => {
						this._listenerId2Observable[ listenerId ] = resultObservable;
						return this._callUntilSuccessThen( cb => {
							this._tryMakeCall( "userObserveList", { userKey, listenerId }, cb );
						} )
					} )
			)
				.flatMap( () => Rx.Observable.combineLatest(
					resultObservable,
					Rx.Observable.create( observer => {
						observer.onNext();
						return () => { this._unsubscribeFromUserList( listenerId ) };
					} ),
					res => res
				) )
		);
	}

	_unsubscribeFromUserList( listenerId ) {
		this._callUntilSuccessAsync( cb => {
			this._tryMakeCall( "userUnobserveList", { listenerId }, cb );
		} ).subscribe();
	}

	observeUser( id ) {
		if ( typeof id !== "string" ) {
			throw new Error( "Id must be a string" );
		}
		let resultObservable = new Rx.ReplaySubject( 1 );
		let listenerId;
		return (
			Rx.Observable.fromPromise( ssgCrypto.createRandomBase64StringThen( 32 ) )
				.tap( randomString => {
					this._listenerId2Observable[ listenerId = randomString ] = resultObservable;
				} )
				.flatMap( listenerId =>
					this._callUntilSuccessAsync( cb => {
						this._tryMakeCall( "userObserve", { id, listenerId }, cb );
					} )
				)
				.flatMap( () => Rx.Observable.combineLatest(
					resultObservable,
					Rx.Observable.create( observer => {
						observer.onNext();
						return () => { this._unsubscribeUser( listenerId ) };
					} ),
					res => res
				) )
				.distinctUntilChanged()
		);
	}

	_unsubscribeUser( listenerId ) {
		this._callUntilSuccessAsync( cb => {
			this._tryMakeCall( "userUnobserve", { listenerId }, cb );
		} ).subscribe();
	}

	_gotUser( message ) { //This is for monitoring single user
		let {user, listenerId} = message;
		let subj = this._listenerId2Observable[ listenerId ];
		if ( subj ) {
			subj.onNext( user );
		}
	}

	_gotUserData( message ) { //This is for monitoring user list
		let {data, listenerId} = message;
		let subj = this._listenerId2Observable[ listenerId ];
		if ( !subj ) {
			return;
		}
		if ( _.isArray( data ) ) {
			subj.onNext( data );
			return;
		}
		let currentData = subj.getValue().slice( 0 );
		switch( data.action ) {
			case "create":
				currentData.push( {
					userId: data.id,
					isPrivileged: data.isPrivileged,
					expireDt: data.expireDt
				} );
				subj.onNext( currentData );
				break;
			case "update": {
				let index = _.findIndex( currentData, { userId: data.id } );
				if ( !~index ) {
					return;
				}
				currentData[ index ] = {
					userId: data.id,
					isPrivileged: data.isPrivileged,
					expireDt: data.expireDt
				};
				subj.onNext( currentData );
				break;
			}
			case "delete": {
				let index = _.findIndex( currentData, { userId: data.id } );
				if ( !~index ) {
					return;
				}
				currentData.splice( index, 1 );
				subj.onNext( currentData );
				break;
			}
			default:
				console.warn( "Invalid user data" );
				break;
		}
	}

	_callUntilSuccessAsync( func ) {
		return (
			super._callUntilSuccessAsync( func )
				.tap( res => {
					if ( res.error ) {
						throw new Error( res.error );
					}
				} )
		);
	}
}

export default ClientServerUser;
