import Service from "../message.js";
let instance = null;
let mockedInstance = null;

export default function() {
	if ( mockedInstance ) {
		return mockedInstance;
	}
	if ( !instance ) {
		instance = Object.create( Service.prototype );
		Service.call( instance );
	}
	return instance;
};

export function mock( newMockedInstance ) {
	if ( mockedInstance ) {
		throw new Error( "Already mocked" );
	}
	mockedInstance = newMockedInstance;
};

export function unmock() { mockedInstance = null; };
