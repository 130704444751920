import crypto from "crypto";
import ssgCrypto, {Key} from "ssg.crypto";
import BinaryStream from "../binary.stream.js";

let ENCRYPTION_CHUNK = 16;
class MessageEncrypter {
	constructor( params ) {
		if ( !( params.key instanceof Key ) ) {
			throw new Error( "key required for encryption" );
		}

		if ( !params.binaryStream ) {
			throw new Error( "binaryStream required for encryption" );
		}

		if ( ( params.binaryStream.size % 16 ) !== 0 ) {
			throw new Error( `BinaryStream size incorrect: ${params.binaryStream.size}` );
		}

		this._binaryStream = params.binaryStream;
		this._key = params.key;
		this._keyHandle = this._key.addKeyUse( "Message Encrypter" );
		this._encryptedBinaryStream = this._createBinaryStream();
	}

	getBinaryStream( ) {
		return this._encryptedBinaryStream;
	}

	_createBinaryStream( ) {
		let plainSize = this._binaryStream.size;
		let encryptedSize = plainSize + ENCRYPTION_CHUNK;

		let prevChunk;
		let bs = new BinaryStream(
			( byteCount, cb ) => {
				if ( !prevChunk ) {
					ssgCrypto.createRandomBufferThen( ENCRYPTION_CHUNK )
						.then( iv => {
							prevChunk = iv;
							this._getNextBytesCb( prevChunk, byteCount, cb );
						} );
					return;
				}
				this._getNextBytesCb( prevChunk, byteCount, cb );
			},
			encryptedSize,
			() => {
				this._key.removeKeyUse( this._keyHandle );
			}
		);
		return bs;
	}

	_getNextBytesCb( prevChunk, count, cb ) {
		let pos = this._encryptedBinaryStream.position;
		let posModChunk = pos % ENCRYPTION_CHUNK;
		let valueBytesInPrevChunk = ENCRYPTION_CHUNK - posModChunk;
		let buffer = new Buffer( count );

		if ( count < valueBytesInPrevChunk ) {
			prevChunk.copy( buffer, posModChunk, 0, count );
			cb( buffer );
			return;
		}
		prevChunk.copy( buffer, 0, posModChunk, ENCRYPTION_CHUNK );
		pos += valueBytesInPrevChunk;
		count -= valueBytesInPrevChunk;
		this._binaryStream.getNextBytesCb(
			Math.min(
				ENCRYPTION_CHUNK * ( ( count / ENCRYPTION_CHUNK + 1 ) | 0 ),
				this._binaryStream.size - this._binaryStream.position
			),
			plain => {
				this._key.postponeManagedBuffer( mb => mb.useAsBuffer( keyBuffer => {
					let cipher = crypto.createCipheriv( "aes-256-cbc", keyBuffer, prevChunk );
					cipher.setAutoPadding( false );
					let encrypted = cipher.update( plain );
					cipher.final();
					encrypted.copy( buffer, valueBytesInPrevChunk, 0, count );
					encrypted.copy( prevChunk, 0, encrypted.length - ENCRYPTION_CHUNK );
					cb( buffer );
				} ) );
			}
		);
	}

	dispose( ) {
		this._encryptedBinaryStream.dispose();
	}
}

export default MessageEncrypter;
