// TODO: joining contact status on startup
// TODO: registrant checks that activation is the first message and refuse registration if it is not the first
// TODO: restore need send deleteAccount message
// TODO: take care of accept invite views mess

import Rx from "rx";
import _ from "lodash";
import ssgCrypto,{KEY_KINDS, Config} from "ssg.crypto";

import keysHelper from "../../common/helpers/keys.js";
import base64 from "../../../common/utils/base64.js";
import ServiceBase from "./service.base.js";
import configuration from "../../common/configuration.js";

import ProfileService from "./profile.js";
import AdminService from "./admin.js";
import ProfileRepository from "../repositories/profile.js";
import ContactRepository from "../repositories/contact/contacts.js";
import {mock as mockProfileRepo, unmock as unmockProfileRepo} from "../repositories/locators/profile.js";
import contactRepositoryLocator, {mock as mockContactRepo, unmock as unmockContactRepo}
	from "../repositories/locators/contact.js";
import profileServiceLocator, {mock as mockProfileService, unmock as unmockProfileService}
	from "./locators/profile.js";
import configServiceLocator from "./locators/config.js";

import seedServiceLocator from "./locators/seed.js";
import contactServiceLocator from "./locators/contact.js";
import groupServiceLocator from "./locators/group.js";
import sharedcontactsServiceLocator from "./locators/sharedcontacts.js";
import adminServiceLocator from "./locators/admin.js";
import draftServiceLocator from "./locators/draft.message.js";
import masterKeyServiceLocator from "./locators/master.key.js";
import ClientServerTrigger from "../transport/client.server.trigger.js";
import PushHub from "../hubs/push.js";
import backupServiceLocator from "./locators/backup.js";
import businessCardServiceLocator from "./locators/business.card.js";
import MailboxSender from "../transport/mailbox.sender.js";
import MailboxReceiver from "../transport/mailbox.receiver.js";
import {receiveMessageAsync} from "../models/message/technical.js";

class CurrentUserService extends ServiceBase {
	constructor( ) {
		super();
		this._configService = configServiceLocator();
		this._profileService = profileServiceLocator();
		this._backupService = backupServiceLocator();
		this._contactRepository = contactRepositoryLocator();
		this._adminService = adminServiceLocator();
		this._contactService = contactServiceLocator();
		this._businessCardService = businessCardServiceLocator();
		this._currentLoginData = null;
		this._pushHub = new PushHub;
		this._dropAccountHandlers = [];
		this._reloadHandlers = [];
	}

	_loadConfigAsync() {
		if ( this._apiUrl && this._econfig ) {
			return Rx.Observable.just();
		}
		return (
			this._configService.getCurrentConfigAsync()
				.tap( config => {
					configuration.setConfiguration( config );
					this._econfig = configuration.getDefaultEncryptionConfig();
					this._apiUrl = configuration.getSocketBase();
				} )
		);
	}

	closeConnection( ) {
		if ( this.socket ) {
			this.socket.disconnect();
			this.socket.removeAllListeners();
		}
	}

	setRegistrationId( registrationId ) {
		this.registrationId = registrationId;
	}

	onAccountDrop( func ) {
		if ( typeof func !== "function" ) {
			throw new Error( "Function required" );
		}
		this._dropAccountHandlers.push( func );
	}

	onReload( func ) {
		if ( typeof func !== "function" ) {
			throw new Error( "Function required" );
		}
		this._reloadHandlers.push( func );
	}

	dropAccountAsync( ) {
		//TODO: delete user data on server first
		this.registrationId && this._pushHub.clearNotifications( this.registrationId );
		return (
			Rx.Observable.combineLatest(
				seedServiceLocator().dropDataAsync().catch((error) => {console.error(error);return Rx.Observable.just();}),
				contactServiceLocator().dropDataAsync().catch((error) => {console.error(error);return Rx.Observable.just();}),
				this._profileService.dropDataAsync().catch((error) => {console.error(error);return Rx.Observable.just();}),
				masterKeyServiceLocator().dropDataAsync().catch((error) => {console.error(error);return Rx.Observable.just();}),
				this._configService.dropDataAsync().catch((error) => {console.error(error);return Rx.Observable.just();}),
				( ...result ) => this.logOutAsync()
			).mergeAll()
			.tap( () => {
				this._dropAccountHandlers.map( handler => handler() );
			} )
		);
	}

	isRegisteredAsync( ) {
		return Rx.Observable.combineLatest(
			seedServiceLocator().isExistAsync(),
			// contact service requires contact repository queue to be initialized
			// it is initialized on login so there'll be exception
			// contactServiceLocator().isExistAsync(),
			this._profileService.isExistAsync(),
			masterKeyServiceLocator().isExistAsync(),
			( ...result ) => {
				return _.every( result );
			}
		);
	}

	registerAndLoginAsync( registrationData, commonProfileData, inviteToken ) {
		if ( !inviteToken ) {
			configuration.setConfiguration( null );
			return (
				this._configService.dropDataAsync()
					.flatMap( () => this.registerLocalAndLoginAsync(
						null, registrationData, commonProfileData, {}
					) )
			);
		}
		return (
			this._contactService.receiveInviteAsync( inviteToken )
				.flatMap( inviteData =>
					(
						inviteData.config
						? this._configService.setCurrentConfigAsync( inviteData.config )
						: this._configService.dropDataAsync()
					)
					.flatMap( () =>
						this._registerAndLoginAsync(
							inviteData, registrationData, commonProfileData, inviteToken
						)
					)
				)
		);
	}

	_registerAndLoginAsync( inviteData, registrationData, commonProfileData, inviteToken ) {
		configuration.setConfiguration( inviteData ? inviteData.config : null );
		switch( inviteData.type ) {
			case "createUserSystem":
				let activationMailbox, activation;
				return (
					Rx.Observable.combineLatest(
						Rx.Observable.fromPromise( ssgCrypto.createRandomBase64StringThen( 32 ) ),
						MailboxReceiver.createNewMailboxDataAsync(
							configuration.getDefaultEncryptionConfig()
						),
						( userId, activationMailbox ) => ( { userId, activationMailbox } )
					)
						.tap( results => {
							registrationData.userId = results.userId;
							activationMailbox = results.activationMailbox;
							activation = {
								dhPubKey: activationMailbox.dhPubKey,
								encryptionSaltKey: activationMailbox.encryptionSaltKey,
								macSaltKey: activationMailbox.macSaltKey,
								connectionId: activationMailbox.connectionIdSender
							};
						} )
						.flatMap( () => this._createMailTokensAsync( inviteData ) )
						.flatMap( mailData =>
							this.registerLocalAndLoginAsync( activation, registrationData, commonProfileData, mailData )
								.flatMap( () => MailboxReceiver.createReceiverTokenAsync(
									configuration.getSocketBase(),
									configuration.getDefaultEncryptionConfig(),
									activationMailbox
								) )
								.flatMap( activationReceiverToken => adminServiceLocator().configureServerAsync(
									registrationData.name1 || registrationData.name2 || "",
									registrationData.userId,
									activationReceiverToken
								) )
								.flatMap( () => MailboxReceiver.createSenderTokenAsync(
									configuration.getSocketBase(),
									configuration.getDefaultEncryptionConfig(),
									activationMailbox
								) )
								.flatMap( activationSenderToken =>
									this.activateAsync( activationSenderToken, mailData, inviteToken )
								)
								.tap( () => this._startListenAdminMessages( mailData.receiverToken0, 0 ) )
						)
				);
			case "activation":
				registrationData.userId = inviteData.userId;
				return (
					this._createMailTokensAsync( inviteData )
						.flatMap( mailData =>
							this.registerLocalAndLoginAsync(
								inviteData, registrationData, commonProfileData, mailData
							)
								.flatMap( () => this.activateAsync( inviteData, mailData, inviteToken ) )
								.tap( () => this._startListenAdminMessages( mailData.receiverToken0, 0 ) )
						)
				);
		}
	}

	_addContactConditionallyAsync( contactService, contactInviteToken ) {
		if ( !contactInviteToken ) {
			return Rx.Observable.just();
		}
		return (
			contactService.receiveInviteAsync( contactInviteToken )
				.flatMap( inviteData =>
					contactService.acceptInviteAsync( inviteData, inviteData.nickname )
				)
		);
	}

	_createMailTokensAsync( { econfig, apiUrlBase } ) {
		return (
			Rx.Observable.combineLatest(
				MailboxReceiver.createTokenPairAsync( apiUrlBase, econfig ),
				MailboxReceiver.createTokenPairAsync( apiUrlBase, econfig ),
				(
					{ senderToken: senderToken0, receiverToken: receiverToken0 },
					{ senderToken: senderToken1, receiverToken: receiverToken1 }
				) => ( { senderToken0, senderToken1, receiverToken0, receiverToken1 } )
			)
		);
	}

	activateAsync( inviteDataOrToken, { senderToken0, senderToken1 }, inviteTokenToDelete, adminService ) {
		let sender = new MailboxSender( inviteDataOrToken );
		adminService = adminService || adminServiceLocator();
		return (
			sender.sendMessageAsync( {
				type: "mailboxTokens",
				mailboxTokens: [ senderToken0, senderToken1 ]
			} )
				.tap( () => {
					sender.dispose();
				} )
				.flatMap( () => inviteTokenToDelete
					? adminService.removeMessageAsync( inviteTokenToDelete )
					: Rx.Observable.just()
				)
		);
	}

	_startListenAdminMessages( receiverToken, startIndex ) {
		if ( this._adminMessagesReceiver ) {
			throw new Error( "adminMessagesReceiver already initialized" );
		}
		// console.log(`_startListenAdminMessages( ${receiverToken}, ${startIndex} )`);
		this._adminMessagesReceiver = new MailboxReceiver(
			receiverToken,
			startIndex,
			this._onAdminMessage.bind( this ),
			this._onAdminMessageIndexUpdate.bind( this ),
			this._onAdminMessageError.bind( this )
		);
	}

	_onAdminMessage( json ) {
		console.log( 'admin message', json );
		switch( json.type ) {
			case "adminInvite":
				return (
					receiveMessageAsync( json.inviteToken )
						.flatMap( invite => adminServiceLocator().joinToAdminAsync( invite ) )
				);
			case "deleteAccount":
				return this.dropAccountAsync();
			case "contact":
				return contactServiceLocator().acceptInviteIfNotJoinedAsync( json.inviteString );
			default:
				console.error( "Invalid admin message", json );
		}
	}

	_onAdminMessageIndexUpdate( adminMessageIndex ) {
		return this._profileService.updateProfileAsync( {
			adminMessageIndex: adminMessageIndex + 1
		} );
	}

	_onAdminMessageError( error ) {
		console.error( "Admin message error", error );
	}

	registerLocalAndLoginAsync( inviteData, registrationData, commonProfileData, mailData ) {
		let { password1, password2, userId } = registrationData;

		commonProfileData.userId = userId;
		if ( inviteData ) {
			commonProfileData.contactInviteToken = inviteData.contactInviteToken;
			commonProfileData.activation = {
				dhPubKey: inviteData.dhPubKey,
				encryptionSaltKey: inviteData.encryptionSaltKey,
				macSaltKey: inviteData.macSaltKey,
				connectionId: inviteData.connectionId
			};
		}

		let getSeedId = ( clientSeed, password ) => {
			if ( password == null ) {
				return (
					Rx.Observable.fromPromise(
						ssgCrypto.createRandomBufferThen( 32 )
							.then( buffer => base64.encode( buffer ) )
					)
				);
			}
			return Rx.Observable.just( keysHelper.getSeedId( clientSeed, password ) );
		};

		return (
			seedServiceLocator().readSeedAsync()
				.flatMap( clientSeed =>
					Rx.Observable.from( [ password1, password2 ] )
						.flatMap( password => getSeedId( clientSeed, password ) )
						.toArray()
						.flatMap( ( [ seedId1, seedId2 ] ) => {
							return Rx.Observable.combineLatest(
								this.getSeedAsync( seedId1 ),
								this.getSeedAsync( seedId2 ),
								( seed1, seed2 ) => ( { seed1, seed2, clientSeed } )
							);
						} )
				)
				.flatMap( data =>
					masterKeyServiceLocator()
						.makeMasterKeyAsync(
							data.seed1,
							data.seed2,
							data.clientSeed,
							password1,
							password2
						)
						.map( () => data )
				)
				.map( data => _.extend( {}, commonProfileData ) )
				.flatMap( commonProfileData => {
					return this._writeProfilesAndLoginAsync( registrationData, commonProfileData, mailData )
				} )
		);
	}

	resetSeedAsync( password1, password2 ) {
		let getSeedId = ( clientSeed, password ) => {
			if ( password == null ) {
				return (
					Rx.Observable.fromPromise(
						ssgCrypto.createRandomBufferThen( 32 )
							.then( buffer => base64.encode( buffer ) )
					)
				);
			}
			return Rx.Observable.just( keysHelper.getSeedId( clientSeed, password ) );
		};
		if (password2 === undefined) {
			password2 = null;
		}
		if (password2 !== null) {
			return (
				this._loadConfigAsync()
				.flatMap(() => seedServiceLocator().readSeedAsync())
				.flatMap((clientSeed) => Rx.Observable.combineLatest(
					getSeedId( clientSeed, password1 ),
					getSeedId( clientSeed, password2 ),
					this.tryGetMasterKey( password1 ),
					this.tryGetMasterKey( password2 ),
					(originalSeedId1, originalSeedId2, mkey1, mkey2) =>
						({originalSeedId1, originalSeedId2, mkey1: mkey1.key, mkey2: mkey2.key})
				))
				.flatMap(({originalSeedId1, originalSeedId2, mkey1, mkey2}) =>
					seedServiceLocator().writeRandomSeedAsync(password1, password2)
					.flatMap(() => seedServiceLocator().readSeedAsync())
					.flatMap((clientSeed) => Rx.Observable.combineLatest(
						getSeedId( clientSeed, password1 ).flatMap(seedId => this.getSeedAsync(seedId)),
						getSeedId( clientSeed, password2 ).flatMap(seedId => this.getSeedAsync(seedId)),
						(serverSeed1, serverSeed2) =>
							({originalSeedId1, originalSeedId2, mkey1, mkey2, serverSeed1, serverSeed2, clientSeed})
					))
				)
				.flatMap(({originalSeedId1, originalSeedId2, mkey1, mkey2, serverSeed1, serverSeed2, clientSeed}) =>
					masterKeyServiceLocator()
						.remakeMasterKeyAsync(
							mkey1,
							mkey2,
							serverSeed1,
							serverSeed2,
							clientSeed,
							password1,
							password2
						)
						.flatMap(() => Rx.Observable.combineLatest(
							this.resetServerSeedAsync( originalSeedId1 ),
							this.resetServerSeedAsync( originalSeedId2 ),
							() => true
						))
				)
			);
		}
		return (
			this._loadConfigAsync()
			.flatMap(() => seedServiceLocator().readSeedAsync())
			.flatMap((clientSeed) => Rx.Observable.combineLatest(
				getSeedId( clientSeed, password1 ),
				getSeedId( clientSeed, password2 ),
				this.tryGetMasterKey( password1 ),
				Rx.Observable.fromPromise(ssgCrypto.createRandomKeyThen( KEY_KINDS.SYMMETRIC_ENCRYPTION, this._econfig )),
				(originalSeedId1, originalSeedId2, mkey1, mkey2) => ({originalSeedId1, originalSeedId2, mkey1: mkey1.key, mkey2})
			))
			.flatMap(({originalSeedId1, originalSeedId2, mkey1, mkey2}) =>
				seedServiceLocator().writeRandomSeedAsync(password1, password2)
				.flatMap(() => seedServiceLocator().readSeedAsync())
				.flatMap((clientSeed) => Rx.Observable.combineLatest(
					getSeedId( clientSeed, password1 ).flatMap(seedId => this.getSeedAsync(seedId)),
					getSeedId( clientSeed, password2 ).flatMap(seedId => this.getSeedAsync(seedId)),
					(serverSeed1, serverSeed2) =>
						({originalSeedId1, originalSeedId2, mkey1, mkey2, serverSeed1, serverSeed2, clientSeed})
				))
			)
			.flatMap(({originalSeedId1, originalSeedId2, mkey1, mkey2, serverSeed1, serverSeed2, clientSeed}) =>
				masterKeyServiceLocator()
					.remakeMasterKeyAsync(
						mkey1,
						mkey2,
						serverSeed1,
						serverSeed2,
						clientSeed,
						password1,
						password2
					)
					.flatMap(() => Rx.Observable.combineLatest(
						this.resetServerSeedAsync( originalSeedId1 ),
						this.resetServerSeedAsync( originalSeedId2 ),
						() => true
					))
			)
		);
	}

	registerNewAsync( nickname, password ) {
		//Set configuration must be called before this method
		let currentPositionBit = this._currentLoginData && this._currentLoginData.positionBit;
		let positionBit = 1 - currentPositionBit;

		return (
			Rx.Observable.combineLatest(
				Rx.Observable.fromPromise(
					ssgCrypto.createRandomKeyThen( KEY_KINDS.SYMMETRIC_ENCRYPTION, this._econfig )
				),
				seedServiceLocator().readSeedAsync()
					.flatMap( clientSeed =>
						this.getSeedAsync( keysHelper.getSeedId( clientSeed, password ) )
							.map( serverSeed => ( { clientSeed, serverSeed } ) )
						),
				( masterKey, { clientSeed, serverSeed } ) => ( { masterKey, clientSeed, serverSeed} )
			)
			.flatMap( ( { masterKey, clientSeed, serverSeed } ) =>
				masterKeyServiceLocator()
					.changePasswordAsync( positionBit, serverSeed, clientSeed, password, masterKey )
			).flatMap( () => this.tryGetMasterKey( password ) )
			.flatMap( ( { key } ) => {
				// HACK: profile/service api does not support operations with another account
				// this requires creating conflicting repositories
				let StorageType = this._profileService._profileRepository._StorageType;
				let {_defaultDetailsRecordSize, _defaultContactRecordCount} = this._contactRepository;
				let newProfileRepository = new ProfileRepository( StorageType );
				let newContactRepository = new ContactRepository(
					StorageType, _defaultDetailsRecordSize, _defaultContactRecordCount
				);
				mockProfileRepo( newProfileRepository );
				let newProfileService = new ProfileService();
				unmockProfileRepo();
				mockProfileService( newProfileService );
				mockContactRepo( newContactRepository );
				let newAdminService = new AdminService();
				unmockContactRepo();
				unmockProfileService();

				return Rx.Observable.combineLatest(
					this._profileService.getProfileAsync(),
					MailboxReceiver.createTokenPairAsync( this._apiUrl, this._econfig ),
					newProfileService.initAsync( key, positionBit ),
					newAdminService.initAsync( key, positionBit ),
					( { backup, userId, activation, adminMessageSenderToken, admin }, newMailPair ) =>
						( { backup, userId, activation, adminMessageSenderToken,
							newProfileService, newAdminService, newMailPair,
							newContactRepository, newProfileRepository, admin
						} )
				);
			} )
			.flatMap( ( { backup, userId, activation, adminMessageSenderToken,
				newProfileService, newAdminService, newMailPair,
				newContactRepository, newProfileRepository,
				admin
			} ) =>
				newProfileService.updateProfileAsync( {
					nickname,
					backup,
					userId,
					activation,
					adminMessageSenderToken: newMailPair.senderToken,
					adminMessageReceiverToken: newMailPair.receiverToken,
					admin
				} )
					.flatMap( () => activation ? this.activateAsync(
						{
							type: "activation",
							econfig: configuration.getDefaultEncryptionConfig(),
							apiUrlBase: configuration.getSocketBase(),
							...activation
						},
						{ senderToken0: adminMessageSenderToken, senderToken1: newMailPair.senderToken }
					) : Rx.Observable.just() )
					.flatMap( () => this._aliasAdminAsync( newAdminService ) )
					//TODO: for some unknown reason contact is not created at
					//      another account if these methods are called
					// .flatMap( () => newProfileService.uninitAsync() )
					// .flatMap( () => newAdminService.uninitAsync() )
					// .flatMap( () => newContactRepository.uninitAsync() )
					// .flatMap( () => newProfileRepository.uninitAsync() )
			)
		);
	}

	_aliasAdminAsync( newAdminService ) {
		//Alias admin invite allows not to modify user table with new invite
		let adminService = adminServiceLocator();
		return (
			adminService.observeIsAdmin()
				.take( 1 )
				.flatMap( isAdmin => isAdmin
					? adminService.createAliasInviteOrNullAsync()
						.flatMap( invite => {
							if ( !invite ) {
								return Rx.Observable.just( null )
							}
							invite.econfig = new Config( invite.econfig );
							return newAdminService.joinToAdminAsync( invite );
						} )
					: Rx.Observable.just()
				)
		);
	}

	loginAndUpdateProfileAsync( password, commonProfileData ) {
		return (
			this.logInAsync( password )
			.flatMap( () => this._profileService.updateProfileAsync( commonProfileData ) )
			.flatMap( () => {
				if ( !commonProfileData || !commonProfileData.contactInviteToken ) {
					return Rx.Observable.just();
				}
				return this._addContactConditionallyAsync(
					contactServiceLocator(), commonProfileData.contactInviteToken
				);
			} )
		);
	}

	_writeProfilesAndLoginAsync( registrationData, commonProfileData, mailData ) {
		let { password1, password2, name1, name2 } = registrationData;
		let { receiverToken0, receiverToken1, senderToken0, senderToken1 } = mailData;

		let profile0 = {
			adminMessageReceiverToken: receiverToken0,
			adminMessageSenderToken: senderToken0,
			nickname: name1,
			...commonProfileData
		};
		if ( password2 == null ) {
			return this.loginAndUpdateProfileAsync( password1, profile0 );
		}
		if ( commonProfileData ) {
			delete commonProfileData.contactInviteToken;
		}
		let profile1 = {
			adminMessageReceiverToken: receiverToken1,
			adminMessageSenderToken: senderToken1,
			nickname: name2,
			...commonProfileData
		};
		profile1.receiveSound = false; //Disable notifications for second account
		return (
			this.loginAndUpdateProfileAsync( password1, profile0 )
				.flatMap( () => this.logOutAsync() )
				.flatMap( () => this.loginAndUpdateProfileAsync( password2, profile1 ) )
				.tap( () => this.reload() )
		);
	}

	tryGetMasterKey( password ) {
		if ( typeof password !== "string" ) {
			throw new Error( "Password must be a string" );
		}
		return (
			seedServiceLocator().readSeedAsync()
				.flatMap( clientSeed =>
					this.getSeedAsync( keysHelper.getSeedId( clientSeed, password ) )
						.flatMap( serverSeed =>
							masterKeyServiceLocator().tryDecryptMasterKeyAsync( serverSeed, clientSeed, password )
						)
				)
				.catch( e => {
					if ( e.message === "404" ) {
						return Rx.Observable.just( null );
					}
					console.error( e );
					// In this case network error will appear as invalid password
					// return Rx.Observable.just( null );

					return Rx.Observable.throw( e );
				} )
		);
	}

	useLoginData( data ) {
		let contactService = contactServiceLocator();
		let groupService = groupServiceLocator();
		let sharedService = sharedcontactsServiceLocator();
		let adminService = adminServiceLocator();
		return (
			Rx.Observable.just( data )
			.tap( data => { this._currentLoginData = data; } )
			.flatMap( data => {
				if ( !data ) {
					return Rx.Observable.just( false );
				}
				let { key, positionBit } = data;
				return (
					Rx.Observable.combineLatest(
						contactService.initAsync( key, positionBit ),
						groupService.initAsync( key, positionBit ),
						sharedService.initAsync( key, positionBit ),
						adminService.initAsync( key, positionBit ),
						this._profileService.initAsync( key, positionBit ),
						() => true
					).take( 1 )
				);
			} )
			.tap( isSuccess => {
				if ( !isSuccess ) {
					return;
				}
				let tos = _.assign( {},
					_.forEach( contactService.getTriggerTos(), d => { d.service = contactService; } ),
					_.forEach( groupService.getTriggerTos(), d => { d.service = groupService; } ),
					_.forEach( sharedService.getTriggerTos(), d => { d.service = sharedService; } )
				);

				let trigger = new ClientServerTrigger(
					configuration.getSocketBase(),
					_.mapValues( tos, ( { fromIndex } ) => ( { fromIndex } ) ),
					to => {
						let { service, ids } = tos[ to ];
						_.forEach( ids, id => {
							service.initContact( id );
						} );
					}
				);
				trigger.getMaxIndexesThen().then( maxIndexes => {
					contactService.setMaxIndexes( maxIndexes );
					groupService.setMaxIndexes( maxIndexes );
					sharedService.setMaxIndexes( maxIndexes );
				} );
				this._businessCardService.onLogin();
			} )
		);
	}

	logInAsync( password ) {
		let currentPositionBit = this._currentLoginData && this._currentLoginData.positionBit;
		return (
			this._loadConfigAsync()
				.flatMap( () => {
					this._backupService.init();
					return this.tryGetMasterKey( password );
				} )
				.flatMap( data => {
					if ( currentPositionBit === null ) {
						return this.useLoginData( data );
					}
					if ( !data ) {
						console.warn( "log in failed" );
						return Rx.Observable.just( false );
					}
					if ( currentPositionBit === data.positionBit ) {
						return Rx.Observable.just( true );
					}
					return (
						this.logOutAsync()
							.flatMap( () => this.useLoginData( data ) )
					);
				} )
		);
	}

	logInAndConnectAsync( password ) {
		let currentPositionBit = this._currentLoginData && this._currentLoginData.positionBit;
		return (
			this.logInAsync( password )
				.flatMap( success => {
					if ( !success ) {
						return Rx.Observable.just( false );
					}
					return (
						this._profileService.getProfileAsync()
							.map( ( { adminMessageReceiverToken, adminMessageIndex } ) => {
								if ( adminMessageReceiverToken ) {
									this._startListenAdminMessages( adminMessageReceiverToken, adminMessageIndex || 0 );
								}
								return true;
							} )
					);
				} )
		);
	}

	logOutAsync( ) {
		draftServiceLocator().dropDrafts();
		this.closeConnection();
		this._adminMessagesReceiver && this._adminMessagesReceiver.dispose();
		this._adminMessagesReceiver = null;
		this._currentLoginData = null;
		return (
			Rx.Observable.combineLatest(
				contactServiceLocator().uninitAsync(),
				groupServiceLocator().uninitAsync(),
				sharedcontactsServiceLocator().uninitAsync(),
				adminServiceLocator().uninitAsync(),
				this._profileService.uninitAsync(),
				() => null
			)
			.tap( () => this._backupService.uninit() )
			.flatMap( () => this._contactRepository.uninitAsync() )
		);
	}

	isLoggedIn( ) {
		return contactServiceLocator().isInitialized();
	}

	getSeedAsync( seedId ) {
		return (
			this.ajax( {
					url: `/seed/${seedId}`,
					responseType: "arraybuffer",
					method: "GET"
				} )
				.map( result => {
					return new Buffer( new Uint8Array( result ) );
				} )
			);
	}

	resetServerSeedAsync( seedId ) {
		return (
			this.ajax( {
					url: `/seed/reset/${seedId}`,
					method: "POST"
				} )
			);
	}

	useInvite( inviteId, seedId1, seedId2 ) {
		return (
			this.ajax( {
				url: "/invite/use",
				responseType: "json",
				method: "POST",
				headers: {
					"Content-Type": "application/json; charset=utf-8"
				},
				body: JSON.stringify( {
					id: inviteId,
					seedId1: seedId1,
					seedId2: seedId2
				} )
			} )
		);
	}

	changePasswordAsync( newPassword ) {
		let { positionBit, key } = this._currentLoginData;

		return (
			Rx.Observable.combineLatest(
				seedServiceLocator().readSeedAsync(),
				this._profileService.getProfileAsync(),
				( clientSeed, profile ) => ( { clientSeed, profile } )
			)
			.flatMap( data =>
				this.getSeedAsync(
					keysHelper.getSeedId( data.clientSeed, newPassword )
				)
				.map( serverSeed => ( { serverSeed, ...data } ) )
			)
			.flatMap( data =>
				masterKeyServiceLocator().changePasswordAsync(
					positionBit,
					data.serverSeed,
					data.clientSeed,
					newPassword,
					key
				)
			)
		);
	}

	observIsOnline( ) {
		return Rx.Observable.just( "online" );//this.hub.isOnlineSubject;
	}

	observeUnreadCountExcept( contactId ) {
		return Rx.Observable.combineLatest(
			contactServiceLocator().observeUnreadCountExcept( contactId ),
			groupServiceLocator().observeUnreadCountExcept( contactId ),
			( contactCount, groupCount ) => contactCount + groupCount
		).distinctUntilChanged();
	}

	getCurrentPositionBit( ) {
		return this._currentLoginData && this._currentLoginData.positionBit;
	}

	upgradeAsync( registrationData, inviteToken ) {
		let {passwordFake} = registrationData;
		return (
			this._contactService.receiveInviteAsync( inviteToken )
				.flatMap( inviteData =>
					this._createMailTokensAsync( inviteData )
						.flatMap( mailData =>
							this._upgradeAsync(passwordFake, inviteData, mailData, inviteToken)
						)
				)
		);
	}

	_upgradeAsync(passwordFake, inviteData, mailData, inviteToken) {
		let { receiverToken0, receiverToken1, senderToken0, senderToken1 } = mailData;
		let profile0 = {
			adminMessageReceiverToken: receiverToken0,
			adminMessageSenderToken: senderToken0,
			userId: inviteData.userId
		};

		if (passwordFake === undefined) {
			return (
				this._profileService.updateProfileAsync( profile0 )
					.flatMap(() => this.activateAsync( inviteData, mailData, inviteToken ))
					.tap( () => {
						 this.reload();
					} )
			);
		}

		let profile1 = {
			adminMessageReceiverToken: receiverToken1,
			adminMessageSenderToken: senderToken1,
			userId: inviteData.userId
		};

		return (
			this._profileService.updateProfileAsync( profile0 )
				.flatMap(() => this.logOutAsync())
				.flatMap(() => this.loginAndUpdateProfileAsync( passwordFake, profile1 ))
				.flatMap(() => this.activateAsync( inviteData, mailData, inviteToken ))
		);
	}

	observeExpireDt() {
		return (
			Rx.Observable.combineLatest(
				this._adminService.observeExpireDt(),
				Rx.Observable.fromPromise(
					seedServiceLocator().getModificationTimeThen().then(
						modificationTime => configuration.getDemoTs() + modificationTime
					)
				),
				this._profileService.getProfileAsync(),
				( expireDt, expireDemo, profile ) => {
					if ( profile && !profile.userId ) {
						return expireDemo;
					}
					return expireDt;
				}
			)
		);
	}


	reload( ) {
		try{
			this._reloadHandlers.map( handler => handler() );

			if ( !global.window || !global.window.location ) {
				return;
			}
			global.window.location = "#";
			global.window.location.reload();
		}catch(e) {
			alert( e && e.message );
		}
	}

	getCurrentVersionString() {
		return process.env.VERSION;
	}
}

export default CurrentUserService;
