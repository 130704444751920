"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _bn = require("bn.js");

var _bn2 = _interopRequireDefault(_bn);

var _bnExternal = require("../bn.external/bn.external.js");

var _bnExternal2 = _interopRequireDefault(_bnExternal);

var _prng = require("../random/locators/prng.js");

var _prng2 = _interopRequireDefault(_prng);

var _prime = require("../helpers/prime.js");

var _prime2 = _interopRequireDefault(_prime);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var random = (0, _prng2.default)();

var SchnorrGroup = function () {
	function SchnorrGroup(params) {
		_classCallCheck(this, SchnorrGroup);

		var p = params.p,
		    q = params.q,
		    g = params.g;


		if (typeof p === "string") {
			p = new _bn2.default(p, 16);
		}

		if (typeof q === "string") {
			q = new _bn2.default(q, 16);
		}

		if (typeof g === "string") {
			g = new _bn2.default(g, 16);
		}
		if (!(p instanceof _bn2.default)) {
			throw new Error("BigNumber p required");
		}
		if (!(q instanceof _bn2.default)) {
			throw new Error("BinNumber q required");
		}
		if (!(g instanceof _bn2.default)) {
			throw new Error("BinNumber g required");
		}
		this._p = p;
		this._q = q;
		this._g = g;
		this._gRed = g.toRed(_bn2.default.red(p));
	}

	_createClass(SchnorrGroup, [{
		key: "verifyGroupThen",
		value: function verifyGroupThen() {
			var _this = this;

			return Promise.all([_prime2.default.getIsPrimeThen(this._p), _prime2.default.getIsPrimeThen(this._q), this.isInGroupThen(this._g)]).then(function (_ref) {
				var _ref2 = _slicedToArray(_ref, 3),
				    b1 = _ref2[0],
				    b2 = _ref2[1],
				    b3 = _ref2[2];

				return b1 && b2 && b3 && _this._g.cmpn(1) === 1;
			});
		}
	}, {
		key: "isInGroupThen",
		value: function isInGroupThen(bn) {
			return _bnExternal2.default.redPowExternalThen(this._g.toRed(_bn2.default.red(this._p)), this._q).then(function (pow) {
				return pow.cmpn(1) === 0;
			});
		}
	}, {
		key: "getP",
		value: function getP() {
			return this._p;
		}
	}, {
		key: "getQ",
		value: function getQ() {
			return this._q;
		}
	}, {
		key: "getG",
		value: function getG() {
			return this._g;
		}
	}, {
		key: "getGRed",
		value: function getGRed() {
			return this._gRed;
		}
	}, {
		key: "toJson",
		value: function toJson() {
			return {
				p: this._p.toString(16),
				q: this._q.toString(16),
				g: this._g.toString(16)
			};
		}
	}]);

	return SchnorrGroup;
}();

exports.default = SchnorrGroup;