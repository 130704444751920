import React from "react";
import _  from "lodash";

import Layout, {
	ListTitleContentView,
	MainTitleContentView,
	ListbarContentView,
	MainContentView,
	MobileMenuContentView,
	CustomContentView
} from "../common/layout.jsx";
import MobileMenu from "../common/mobile.menu.jsx";
import ListTitle from "./list.title.jsx";
import MainTitle from "./main.title.jsx";
import ContactListListBar from "../common/contact.list.jsx";
import NewInviteContent from "../contacts/new.invite.content.jsx";
import NewInviteTitle from "../contacts/new.invite.title.jsx";
import MainContactContent from "../contacts/main.content.jsx";
import MainGroupContent from "../groups/main.content.jsx";
import CreateListBar from "./create.list.bar.jsx";
import SendContactsContent from "../contacts/send.contacts.content.jsx";
import SendContactsTitle from "../contacts/send.contacts.title.jsx";

import ParticipantsContent from "../groups/participants.content.jsx";
import AddContent from "../groups/add.content.jsx";
import AddExternalContent from "../groups/add.external.content.jsx";

import Prompt from "../common/prompt.jsx";
import Confirm from "../common/confirm.jsx";
import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class ChatsView extends React.Component {
	constructor() {
		super();
		this.state = {
			mainView: "contact",
			listView: "chats",
			selectedContact: null,
			activeMobileView: "list",
			inviteCounter: 0,
			searchString: null
		};
		this._service = remoteServiceLocator();
		this.renderListBar = this.renderListBar.bind( this );
		this.goToList = this.goToList.bind( this );
		this.selectContact = this.selectContact.bind( this );
		this.rename = this.rename.bind( this );
		this.delete = this.delete.bind( this );
		this.createChat = this.createChat.bind( this );
		this.startWithNew = this.startWithNew.bind( this );
		this.startWith = this.startWith.bind( this );
		this.onInvited = this.onInvited.bind( this );
		this.renameGroup = this.renameGroup.bind( this );
		this.exitGroup = this.exitGroup.bind( this );
		this.clearHistory = this.clearHistory.bind( this );
		this.sendContacts = this.sendContacts.bind( this );
		this.backFromSendContacts = this.backFromSendContacts.bind( this );
		this.onSetSearchString = this.onSetSearchString.bind( this );
		this.onAddParticipant = this.onAddParticipant.bind( this );
		this.onExternalParticipant = this.onExternalParticipant.bind( this );
		this.showParticipants = this.showParticipants.bind( this );
		this.backFromAdd = this.backFromAdd.bind( this );
	}

	componentWillMount() {
		//TODO: avoid copy-paste
		let openContactIdStr = this.props.params && this.props.params[ 0 ];
		if ( openContactIdStr !== undefined ) {
			this.openContactById( openContactIdStr | 0 );
		}
		this._contactsSubscription = (
			this._service.observeChats()
				.subscribe( contacts => {
					this.setState( { contacts } );
				} )
		);
	}

	openContactById( id ) {
		this._service.observeChats()
			.take( 1 )
			.subscribe( contacts => {
				let selected = _.find( contacts, { id } );
				if ( selected ) {
					this.selectContact( selected );
				}
			} );
	}

	componentWillUnmount() {
		this._contactsSubscription.dispose();
	}

	getSelectedContact() {
		let {selectedContact, contacts} = this.state;
		if ( selectedContact && !_.find( contacts, {id: selectedContact.id } ) ) {
			selectedContact = null;
		}
		return selectedContact;
	}

	goToList() {
		this.setState( {
			activeMobileView: "list"
		} );
	}

	selectContact( contact ) {
		this.setState( {
			selectedContact: contact,
			mainView: "contact",
			activeMobileView: "main"
		} );
	}

	onInvited( contact ) {
		this.setState( {
			selectedContact: contact,
			mainView: "contact",
			activeMobileView: "main"
		} );
	}

	sendContacts() {
		this.setState( {
			mainView: "sendContacts",
			activeMobileView: "main",
			inviteCounter: this.state.inviteCounter + 1
		} );
	}

	backFromSendContacts( selectedIds ) {
		if ( !selectedIds || !selectedIds.length || !this.state.selectedContact ) {
			this.setState( {
				mainView: "contact",
				activeMobileView: "main"
			} );
			return;
		}
		this._service.sendContactsAsync(
			this.state.selectedContact,
			selectedIds
		).subscribe( () => {
			this.setState( {
				mainView: "contact",
				activeMobileView: "main"
			} );
		} );
	}

	clearHistory() {
		this.state.selectedContact && this._service.clearHistoryAsync( this.state.selectedContact ).subscribe();
	}

	renderContent() {
		let contact = this.getSelectedContact();
		let contactId = contact && contact.id;
		let contactType = contact ? contact.type : "normal";
		switch( this.state.mainView ) {
			case "contact":
				switch( contactType ) {
					case "normal":
						return <MainContactContent key={contactId} contactId={contactId} contact={contact} />;
					case "group":
						return <MainGroupContent key={contactId} contact={contact} />;
					default:
						throw new Error( `Unsupported contact type: ${contactType}` );
				}
			case "invite":
				return <NewInviteContent
					onInvite={this.onInvited}
					key={this.state.inviteCounter}
					/>;
			case "sendContacts":
				return <SendContactsContent
					onBack={this.backFromSendContacts}
					toContactId={contactId}
					key={this.state.inviteCounter}
				/>;
			case "participants":
				if ( !contact ) {
					return <MainGroupContent contact={null} />;
				}
				return <ParticipantsContent
					contact={contact}
					onAdd={this.onAddParticipant}
				/>;
			case "add":
				if ( !contact ) {
					return <MainGroupContent contact={null} />;
				}
				return <AddContent
					contact={this.getSelectedContact()}
					onBack={this.backFromAdd}
				/>;
			case "external":
				return <AddExternalContent
					contact={this.getSelectedContact()}
					onBack={this.backFromAdd}
				/>;
			default:
				throw new Error( `Unsupported main view: ${this.state.mainView}` );
		}
	}

	backFromAdd() {
		this.setState( {
			mainView: "contact",
			activeMobileView: "main"
		} );
	}

	renderMainTitle() {
		let contact = this.state.selectedContact;
		let contactId = contact && contact.id;
		let contactType = contact ? contact.type : "normal";
		switch( this.state.mainView ) {
			case "contact":
				if ( contact && ( contactType === "normal" ) && ( contact.status === "active" ) ) {
					return <MainTitle
						contact={contact}
						onSendContacts={ this.sendContacts }
						onGoToList={this.goToList}
						onClearHistory={this.clearHistory}
					/>;
				}
				return <MainTitle
					contact={contact}
					onGoToList={this.goToList}
					onClearHistory={this.clearHistory}
          			onParticipants={this.showParticipants}
					onAdd={this.onAddParticipant}
					onExternal={this.onExternalParticipant}
				/>;
			case "invite":
				return <NewInviteTitle onGoToList={this.goToList}/>;
			case "sendContacts":
				return <SendContactsTitle onGoToList={ this.goToList }/>;
			case "participants":
			case "add":
			case "external":
				return <MainTitle
					contact={this.getSelectedContact()}
					onGoToList={this.onGoToList}
					onClearHistory={this.clearHistory}
					onParticipants={this.showParticipants}
					onAdd={this.onAddParticipant}
					onExternal={this.onExternalParticipant}
				/>;
		}
	}

	onAddParticipant() {
		this.setState( {
			mainView: "add",
			activeMobileView: "main"
		} );
	}

	onExternalParticipant() {
		this.setState( {
			mainView: "external",
			activeMobileView: "main"
		} );
	}

	showParticipants() {
      this.setState( {
  			mainView: "participants",
  			activeMobileView: "main"
  		} );
    }

	renderPopup() {
		if ( this.state.popup ) {
			return this.state.popup();
		}
		return null;
	}

	rename( contact ) {
		this.setState( {
			popup: () =>
				<Prompt
					titleTextId="web.contact.rename.title"
					defaultValue={ contact.name }
					onDone={ this.doRename.bind( this, contact ) }
				/>
		} );
	}

	delete( contact ) {
		this.setState( {
			popup: () =>
				<Confirm
					titleTextId="web.contact.delete.title"
					yesButtonTextId="web.contact.delete.button.yes"
					onDone={ this.doDelete.bind( this, contact ) }
				/>
		} );
	}

	doRename( contact, newName ) {
		if ( !newName ) {
			this.setState( { popup: null } );
			return;
		}
		this._service.renameContactAsync( contact, newName )
			.subscribe( () => {
				this.setState( { popup: null } );
			} );
	}

	doDelete( contact, isConfirmed ) {
		if ( !isConfirmed ) {
			this.setState( { popup: null } );
			return;
		}
		this._service.deleteContactAsync( contact )
			.subscribe( () => {
				this.setState( { popup: null } );
			} );
	}

	createChat() {
		this.setState( {
			listView: "create",
			activeMobileView: "list",
			selectedContact: null,
			mainView: "contact"
		} );
	}

	startWith( contact ) {
		this.setState( {
			listView: "chats",
			activeMobileView: "list",
			selectedContact: contact,
			mainView: "contact"
		} );
	}

	startWithNew() {
		this.setState( {
			listView: "chats",
			activeMobileView: "main",
			selectedContact: null,
			mainView: "invite",
			inviteCounter: this.state.inviteCounter + 1
		} );
	}

	doRenameGroup( contact, newName ) {
		if ( !newName ) {
			this.setState( { popup: null } );
			return;
		}
		this._service.renameContactAsync( contact, newName )
			.subscribe( () => {
				this.setState( { popup: null } );
			} );
	}

	renameGroup( contact ) {
		this.setState( {
			popup: () =>
				<Prompt
					titleTextId="web.group.rename.title"
					defaultValue={ contact.name }
					onDone={ this.doRenameGroup.bind( this, contact ) }
				/>
		} );
	}

	doExitGroup( contact, isConfirmed ) {
		if ( !isConfirmed ) {
			this.setState( { popup: null } );
			return;
		}
		this._service.exitGroupAsync( contact )
			.subscribe( () => {
				this.setState( { popup: null } );
			} );
	}


	exitGroup( contact ) {
		this.setState( {
			popup: () =>
				<Confirm
					titleTextId="web.group.exit.title"
					yesButtonTextId="web.group.exit.button.yes"
					onDone={ this.doExitGroup.bind( this, contact ) }
				/>
		} );
	}

	onSetSearchString( searchString ) {
		this.setState( { searchString } );
	}

	getFilteredContacts() {
		let { searchString, contacts } = this.state;
		if ( !searchString ) {
			return contacts;
		}
		let low = searchString.toLowerCase();
		return _.filter( contacts, ( { name } ) => ~name.toLowerCase().indexOf( low ) );
	}

	renderListBar() {
		let contactId = this.state.selectedContact && this.state.selectedContact.id;
		switch( this.state.listView ) {
			case "chats":
				return <ContactListListBar
						onContactRename={ this.rename }
						onContactDelete={ this.delete }
						onSelectContact={ this.selectContact }
						onGroupRename={ this.renameGroup }
						onGroupExit={ this.exitGroup }
						selectedContactId={ contactId }
						contacts={ this.getFilteredContacts() }
						emptyTextId={ this.state.searchString ? "chats.search.empty" : "web.chats.list.empty" }
						arrow={ this.state.searchString === null }
						displayMeta={true}
					/>;
			case "create":
				return <CreateListBar
						startWith={this.startWith}
						startWithNew={this.startWithNew}
					/>;
		}
	}

	render() {
		let {selectedContact} = this.state;
		let contactId = selectedContact && selectedContact.id;
		let contactName = selectedContact && selectedContact.name;
		let contactType = selectedContact && selectedContact.type;
		let mainTitleTextId = contactType ? `web.chats.main.${contactType}.title` : "web.chats.list.title";
		return (
			<Layout
				activeMobileView={ this.state.activeMobileView }
				mainTitleTextId={mainTitleTextId}
				listTitleTextId="web.chats.list.title"
				mainTitleParams={[contactName]}
				>
				<ListTitleContentView>
					<ListTitle
						onAddClick={this.createChat}
						searchString={this.state.searchString}
						onSetSearchString={ this.state.listView === "chats" ? this.onSetSearchString : null }
					/>
				</ListTitleContentView>
				<MainTitleContentView>
					{this.renderMainTitle()}
				</MainTitleContentView>
				<MobileMenuContentView>
					<MobileMenu active="chats">
						<button className="btn-circle newChat visible-xs" onClick={this.createChat}>+</button>
					</MobileMenu>
				</MobileMenuContentView>
				<ListbarContentView>
					{this.renderListBar()}
				</ListbarContentView>
				<MainContentView>
					{this.renderContent()}
				</MainContentView>
				<CustomContentView>
					{this.renderPopup()}
				</CustomContentView>
			</Layout>
		);
	}
}

export default ChatsView;
