import React from "react";
import { Modal, Button } from "react-bootstrap";

import Translation from "../../../components/translation.jsx";

class PromptView extends React.Component {
	constructor() {
		super();
		this.onClose = this.onClose.bind( this );
		this.onDone = this.onDone.bind( this );
	}

	onClose() {
		this.props.onDone();
	}

	onDone() {
		this.props.onDone( this.refs.input.value );
	}

	render() {
		return (
			<div className="static-modal">
				<Modal.Dialog>
					<Modal.Header>
						<Modal.Title><Translation textId={this.props.titleTextId} /></Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<input
							ref="input"
							type="text"
							defaultValue={this.props.defaultValue}
						/>
					</Modal.Body>

					<Modal.Footer>
						<Button onClick={ this.onClose }>
							<Translation textId="web.common.prompt.close.button" />
						</Button>
						<Button bsStyle="primary" onClick={ this.onDone }>
							<Translation textId="web.common.prompt.save.button" />
						</Button>
					</Modal.Footer>

				</Modal.Dialog>
			</div>
		);
	}

	static get propTypes() {
		return {
			onDone: React.PropTypes.func.isRequired,
			titleTextId: React.PropTypes.string.isRequired,
			defaultValue: React.PropTypes.string
		};
	}
}

export default PromptView;
