import Rx from "rx";
import React from "react";
import ReactDom from "react-dom";
import Translation from "./translation.jsx";
import classNames from "classnames";
import FullScreenComponent from "./fullscreen.jsx";

class ToastComponent extends FullScreenComponent {

	constructor() {
		super();
		this.state = {
			visible: false,
			isFading: false
		};
	}

	renderContent() {
		let className = classNames( "toast", { "hide": this.state.isFading } );
		return (
			<div className={className}>
				<div className="content">
					{this.renderToastContent()}
				</div>
			</div>
		);
	}

	renderToastContent() {
		let { textId, children } = this.props;
		return textId ? <Translation textId={textId} /> : children;
	}

	show() {
		this.setState( { visible: true, isFading: false } );
		this.subscription = Rx.Observable.timer( 2000 ).subscribe( () => this.hide() );
	}

	hide() {
		this.setState( { isFading: true } );
		if ( this.subscription ) {
			this.subscription.dispose();
		}
		this.subscription = Rx.Observable.timer( 1000 ).subscribe( () => this.setState( { visible: false } ) );
	}

	remount() {
		if ( this.state.visible ) {
			ReactDom.render( this.renderContent(), this._mountedElement );
		} else {
			ReactDom.unmountComponentAtNode( this._mountedElement );
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		if ( this.subscription ) {
			this.subscription.dispose();
		}
	}
}

export class DynamicToast extends ToastComponent {

	show( { textId, params, content } ) {
		if ( textId ) {
			this.setState( { textId, params, content: null } );
		} else {
			this.setState( { textId: null, params, content } );
		}
		super.show();
	}

	renderToastContent() {
		let { textId, params, content } = this.state;
		if ( content ) {
			return content;
		}
		return <Translation textId={textId} params={params}/>;
	}
}

export default ToastComponent;
