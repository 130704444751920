import React from "react";
import _ from "lodash";
import classNames from "classnames";

import configuration from "../../../common/configuration.js";
import TopLevel from "../common/top.level.view.jsx";
import HeaderWait from "../common/header.wait.jsx";
import FullScreen from "../../components/fullscreen.jsx";
import Translation from "../../components/translation.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import ImageButton from "../../components/button.image.jsx";
import Button from "../../components/button.jsx";
import OnlineStatus from "../../components/online.status.jsx"

let padNumber = number => {
	if ( number === 0 ) {
		return <Translation textId="options.autoclean.no" />
	}
	if ( number <= 9 ) {
		return "0" + number;
	}
	return number;
};

let Preset = ( { number, autocleanTime, unit, isSelected, onSelect } ) => (
	<div className={ classNames( "item", { selected: isSelected } ) } onClick={ onSelect }>
		<div className="number">{ padNumber( number ) }</div>
		<div className="unit">
			<Translation
				textId={ `options.autoclean.unit.${unit}` }
				params={ [ number ] }
			/>
		</div>
	</div>
);

class SetAutocleanView extends React.Component {
	constructor() {
		super();
		this.state = {
			isInProgress: false,
			autocleanTime: null
		};
		this.onBackPress = this.onBackPress.bind( this );
		this.onDoneClick = this.onDoneClick.bind( this );
	}
	onBackPress() {
		this.props.onBack();
	}
	onDoneClick() {
		let { autocleanTime } = this.state;
		this.setState( { isInProgress: true } );
		if ( autocleanTime !== null ) {
			this.props.onSetAutoclean( { autoclean: autocleanTime } );
		} else {
			this.props.onSetAutoclean( null );
		}
	}
	componentWillMount() {
		this.setState( { autocleanTime: this.props.autocleanTime } );
	}
	componentWillReceiveProps( nextProps ) {
		if ( this.props.autocleanTime !== nextProps.autocleanTime ) {
			this.setState( { autocleanTime: nextProps.autocleanTime } );
		}
	}

	getSelectedPreset() {
		let { presets } = this.props;
		for ( let i = 0; i < presets.length; i++ ) {
			if ( presets[ i ].autocleanTime >= this.state.autocleanTime ) {
				return presets[ i ];
			}
		}
		return presets[ presets.length - 1 ];
	}
	onSelectPreset( { autocleanTime } ) {
		this.setState( { autocleanTime } );
	}

	renderHeader() {
		if ( !this.state.isInProgress ) {
			return (
				<header>
					<ImageButton type="icon-arrow-left" onClick={this.onBackPress}/>
					<span className="header-caption"><Translation textId="options.autoclean.header"/></span>
					<OnlineStatus/>
				</header>
			);
		}
		return <HeaderWait />;
	}

	render() {
		let currentPreset = this.getSelectedPreset();
		return (
			<FullScreen>
				<TopLevel>
					{ this.renderHeader() }
					<main>
						<div className="small-text">
							<Translation textId="options.autoclean.smalltext" />
						</div>
						<div className="selected-time-range">
							<div className="unit">
								<Translation
									textId={ `options.autoclean.unit.${currentPreset.unit}` }
									params={ [ currentPreset.number ] }
								/>
							</div>
							<div className="number">
								{padNumber( currentPreset.number )}
							</div>
						</div>
						<div className="time-ranges">
							{_.map( this.props.presets, preset => (
								<Preset {...preset}
									key={preset.autocleanTime}
									isSelected={preset === currentPreset}
									onSelect={this.onSelectPreset.bind( this, preset )}
								/>
							) )}
						</div>
					</main>
					<footer>
						<Button
							handleClick={this.onDoneClick}
							caption="options.autoclean.continue.button"
							enabled={this.props.enabled && !this.state.isInProgress }
						/>
					</footer>
				</TopLevel>
				<DeviceBackButton onPress={this.onBackPress}/>
			</FullScreen>
		);
	}
}
SetAutocleanView.propTypes = {
	onBack: React.PropTypes.func.isRequired,
	autocleanTime: React.PropTypes.number,
	presets: React.PropTypes.array,
	onSetAutoclean: React.PropTypes.func.isRequired
};
SetAutocleanView.defaultProps = {
	onBack: _.noop,
	autocleanTime: configuration.getDefaultAutocleanTime(),
	presets: [
		{ number: 0, unit: "no", autocleanTime: 0 },
		{ number: 1, unit: "min", autocleanTime: 60 },
		{ number: 10, unit: "min", autocleanTime: 10 * 60 },
		{ number: 1, unit: "hour", autocleanTime: 60 * 60 },
		{ number: 1, unit: "day", autocleanTime: 24 * 3600 },
		{ number: 7, unit: "day", autocleanTime: 7 * 24 * 3600 },
		{ number: 30, unit: "day", autocleanTime: 30 * 24 * 3600 },
		{ number: 1, unit: "year", autocleanTime: 365.25 * 24 * 3600 }
	]
};

export default SetAutocleanView;
