"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _baseSource = require("./base.source.js");

var _baseSource2 = _interopRequireDefault(_baseSource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var MouseSource = function (_BaseSource) {
	_inherits(MouseSource, _BaseSource);

	function MouseSource(callback) {
		_classCallCheck(this, MouseSource);

		var _this = _possibleConstructorReturn(this, (MouseSource.__proto__ || Object.getPrototypeOf(MouseSource)).call(this, callback));

		if (!global.addEventListener) {
			return _possibleConstructorReturn(_this); //not a browser
		}
		global.addEventListener("mousemove", function (event) {
			return _this._handleMove(event);
		}, true);
		global.addEventListener("mousedown", function (event) {
			return _this._handleDown(event);
		}, true);
		global.addEventListener("mouseup", function (event) {
			return _this._handleUp(event);
		}, true);
		return _this;
	}

	_createClass(MouseSource, [{
		key: "_handleMove",
		value: function _handleMove(e) {
			this.gotData(e.screenY, 3);
			this.gotData(e.screenX, 3);
		}
	}, {
		key: "_handleDown",
		value: function _handleDown(e) {
			this.gotData(e.screenY, 4);
			this.gotData(e.screenX, 4);
			this._processTime();
		}
	}, {
		key: "_handleUp",
		value: function _handleUp(e) {
			this._processTime();
		}
	}, {
		key: "_processTime",
		value: function _processTime() {
			if (global.performance) {
				this.gotData(global.performance.now() * 10000, 10);
			} else {
				this.gotData(+new Date(), 2);
			}
		}
	}]);

	return MouseSource;
}(_baseSource2.default);

exports.default = MouseSource;