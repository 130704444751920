import React from "react";

let SVGString = `
<svg class="ldt-blank" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid">
  <style type="text/css">path,ellipse,circle,rect,polygon,polyline,line { stroke-width: 0; }
@keyframes ld-breath {
  0% {
    -webkit-transform: scale(0.86);
    transform: scale(0.86);
  }
  50% {
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
  }
  100% {
    -webkit-transform: scale(0.86);
    transform: scale(0.86);
  }
}
@-webkit-keyframes ld-breath {
  0% {
    -webkit-transform: scale(0.86);
    transform: scale(0.86);
  }
  50% {
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
  }
  100% {
    -webkit-transform: scale(0.86);
    transform: scale(0.86);
  }
}
.ld.ld-breath {
  -webkit-animation: ld-breath 1s infinite;
  animation: ld-breath 1s infinite;
}

text { font-family: Tahoma }</style><g transform="translate(47.25 67.25)">
  <g style="animation-delay: 0s; animation-duration: 1s; font-size: 48px; animation-direction: normal; transform-origin: 13.8203px 0px 0px;" class="ld ld-breath">
    <text fill="#337ab7">L</text>
  </g>
</g><g transform="translate(74.890625 67.25)">
  <g style="animation-delay: -0.142857s; animation-duration: 1s; font-size: 48px; animation-direction: normal; transform-origin: 14.5859px 0px 0px;" class="ld ld-breath">
    <text fill="#5bc0de">o</text>
  </g>
</g><g transform="translate(104.0625 67.25)">
  <g style="animation-delay: -0.285714s; animation-duration: 1s; font-size: 48px; animation-direction: normal; transform-origin: 13.4844px 0px 0px;" class="ld ld-breath">
    <text fill="#5cb85c">a</text>
  </g>
</g><g transform="translate(131.03125 67.25)">
  <g style="animation-delay: -0.428571s; animation-duration: 1s; font-size: 48px; animation-direction: normal; transform-origin: 15.1406px 0px 0px;" class="ld ld-breath">
    <text fill="#f0ad4e">d</text>
  </g>
</g><g transform="translate(161.3125 67.25)">
  <g style="animation-delay: -0.571429s; animation-duration: 1s; font-size: 48px; animation-direction: normal; transform-origin: 5.96875px 0px 0px;" class="ld ld-breath">
    <text fill="#d9534f">i</text>
  </g>
</g><g transform="translate(173.25 67.25)">
  <g style="animation-delay: -0.714286s; animation-duration: 1s; font-size: 48px; animation-direction: normal; transform-origin: 14.6094px 0px 0px;" class="ld ld-breath">
    <text fill="#337ab7">n</text>
  </g>
</g><g transform="translate(202.46875 67.25)">
  <g style="animation-delay: -0.857143s; animation-duration: 1s; font-size: 48px; animation-direction: normal; transform-origin: 15.1406px 0px 0px;" class="ld ld-breath">
    <text fill="#5bc0de">g</text>
  </g>
</g></svg>
`;

class LoadingView extends React.Component {
  render() {
    return <span dangerouslySetInnerHTML={{__html: SVGString}} />;;
  }
}

export default LoadingView;
