import React from "react";
import _ from "lodash";

import Qr from "../../components/qr.jsx";
import InputWithHelp from "../../components/input.withhelp.jsx";
import FullScreen from "../../components/fullscreen.jsx";
import TopLevel from "../common/top.level.view.jsx";
import {DynamicToast} from "../../components/toast.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import ImageButton from "../../components/button.image.jsx";
import {InternetRetryConfirm} from "../../components/dialogs.jsx"
import Translation from "../../components/translation.jsx";
import Button from "../../components/button.jsx";
import OnlineStatus from "../../components/online.status.jsx"

let copy = _.get( global, "cordova.plugins.clipboard.copy" );

class MultiinviteDetailedView extends React.Component {
	constructor() {
		super();
		this.copyInviteCode = this.copyInviteCode.bind( this );
		this.state = {};
	}

	copyInviteCode() {
		copy( this.props.multidescription.multiinviteToken, () => {
			if ( !this.refs || !this.refs.toast ) {
				return;
			}

			this.refs.toast.show( { textId: "invite.copytoast" } ) ;
		} );
	}

	componentWillMount() {
		this.setState( { showToken: !!this.props.multidescription.multiinviteToken } );
	}

	componentWillUpdate( nextProps, nextState ) {
		if ( nextProps.multidescription.multiinviteToken && !this.state.showToken ) {
			this.setState( { showToken: true } );
		} else if ( this.state.showToken && !nextProps.multidescription.multiinviteToken ) {
			this.props.onBack();
		}
	}

	renderInviteData() {
		let {multidescription} = this.props;
		let {multiinviteToken} = multidescription;
		if ( !multiinviteToken ) {
			return <span>Loading...</span>;
		}
		return (
			<MultiinviteContent
				qrValue={multiinviteToken}
				textValue={multiinviteToken}
				copyHandler={this.copyInviteCode}
				onDelete={this.props.onDelete}
			/>
		);
	}

	render() {
		return (
			<FullScreen>
				<TopLevel className="invite-page">
					<header>
						<ImageButton type="icon-arrow-left" onClick={this.props.onBack} id="multiinvite-back"/>
						<span className="header-caption"><Translation textId="invite.header"/></span>
						<OnlineStatus/>
					</header>
					<main>
						{ this.renderInviteData() }
					</main>
					<DeviceBackButton onPress={ this.props.onBack }/>
					<DynamicToast ref="toast"/>
				</TopLevel>
			</FullScreen>
		);
	}

	static get propTypes() {
		return {
			onBack: React.PropTypes.func.isRequired,
			multidescription: React.PropTypes.object.isRequired,
			onDelete: React.PropTypes.func.isRequired
		};
	}
}

class MultiinviteContent extends React.Component {
	render() {
		let {textValue, qrValue, copyHandler} = this.props;
		return (
			<div>
				<div className="big-text"><Translation textId="invite.qr.bigtext"/></div>
				<div className="small-text"><Translation textId="invite.qr.smalltext"/></div>
				<div className="qr-code blue f-left">
					<Qr className="qr-image" code={qrValue}/>
				</div>
				<div className="clear"></div>
				<div className="small-text">
					<Translation textId="invite.plain.text"/>
				</div>
				<div className="invite-code-block-">
					<InputWithHelp
						type="text"
						className="invite-code"
						value={textValue}
						readOnly={true}
						placeholderTextId="invite.token.placeholder"
						tooltipTextId="invite.token.tooltip"
						id="multiinvite-token"
						/>
				</div>
				{copy && <Button caption="invite.button.copy" handleClick={copyHandler} />}
				<Button caption="multiinvite.delete.button" handleClick={this.props.onDelete} />
			</div>
		);
	}
}

export default MultiinviteDetailedView;
