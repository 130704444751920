import React from "react";

import serviceLocator from "../../../api/services/locators/worker.client.js";

import TopLevel from "../common/top.level.view.jsx";
import ArrowButton from "../../components/button.continue.jsx";
import Translation from "../../components/translation.jsx";
import Checkbox from "../../components/checkbox.jsx";
import InputWithHelp from "../../components/input.withhelp.jsx";

class RejoinView extends React.Component {
	constructor() {
		super();
		this.onCheckboxChange = this.onCheckboxChange.bind( this );
		this.onPassword1Change = this.onPassword1Change.bind( this );
		this.onPassword2Change = this.onPassword2Change.bind( this );
		this.onRejoinClick = this.onRejoinClick.bind( this );

		this._service = serviceLocator();
		this.state = {
			haveFake: false,
			password1: "",
			password2: ""
		};
	}

	onCheckboxChange() {
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( {
			haveFake: !this.state.haveFake
		} );
	}

	onPassword1Change( password1 ) {
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( { password1 } );
	}

	onPassword2Change( password2 ) {
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( { password2 } );
	}

	renderPassword1() {
		let {password1} = this.state;
		return (
			<InputWithHelp
				type="password"
				value={password1}
				onChange={this.onPassword1Change}
				placeholderTextId="rejoin.main.password.placeholder"
				tooltipTextId="rejoin.main.password.tooltip"
			/>
		);
	}

	renderPassword2() {
		let {password2, haveFake} = this.state;
		if ( !haveFake ) {
			return null;
		}
		return (
			<InputWithHelp
				type="password"
				value={password2}
				onChange={this.onPassword2Change}
				placeholderTextId="rejoin.fake.password.placeholder"
				tooltipTextId="rejoin.fake.password.tooltip"
			/>
		);
	}

	renderWarning() {
		let warnArray = [];
		if ( this.state.mainPasswordInvalid ) {
			warnArray.push( <Translation textId="rejoin.main.warning" key="1"/> );
		}

		if ( this.state.fakePasswordInvalid ) {
			warnArray.push( <Translation textId="rejoin.fake.warning" key="2"/> );
		}

		if ( this.state.equalsPasswords ) {
			warnArray.push( <Translation textId="rejoin.equals.warning" key="3"/> );
		}

		if ( warnArray.length === 0 ) {
			return null;
		}
		return (
			<div className="warning">
				{ warnArray.map( warn => <span>{warn}<br /></span> ) }
			</div>
		);
	}

	renderCheckBox() {
		return (
			<div className="label">
				<Checkbox
					className="f-right"
					value={this.state.haveFake}
					onClick={this.onCheckboxChange}
				/>
				<Translation textId="registration.fake.checkbox"/>
			</div>
		);
	}

	renderText() {
		return (
			<span className="small-text">
				<Translation textId="rejoin.text"/>
			</span>
		);
	}

	renderButton() {
		let {isInProgress} = this.state;
		return (
			<ArrowButton
				enabled={!isInProgress}
				caption="rejoin.do.button"
				handleClick={this.onRejoinClick}
				id="rejoin"
			/>
		);
	}

	onRejoinClick() {
		let { isInProgress, haveFake, password1, password2 } = this.state;
		if ( isInProgress ) {
			return;
		}
		let equalsPasswords = haveFake && ( password1 === password2 );
		this.setState( {
			isInProgress: !equalsPasswords,
			mainPasswordInvalid: false,
			fakePasswordInvalid: false,
			equalsPasswords
		} );
		if ( equalsPasswords ) {
			return;
		}
		if ( !haveFake ) {
			this._service.isPasswordValidAsync( password1 )
				.subscribe( key => {
					if ( key ) {
						this.doRejoin();
						return;
					}
					this.setState( {
						mainPasswordInvalid: true,
						isInProgress: false
					} );
				} );
			return;
		}
		Rx.Observable.combineLatest(
			this._service.isPasswordValidAsync( password1 ),
			this._service.isPasswordValidAsync( password2 ),
			( mainKey, fakeKey ) => ( { mainKey, fakeKey } )
		).subscribe( ( { mainKey, fakeKey } ) => {
			if ( mainKey && fakeKey ) {
				this.doRejoin();
				return;
			}
			this.setState( {
				mainPasswordInvalid: !mainKey,
				fakePasswordInvalid: !fakeKey,
				isInProgress: false
			} );
		} );
	}

	doRejoin() {
		let {haveFake, password1, password2} = this.state;
		this._service.rejoinContactsAsync( password1, haveFake ? password2 : undefined )
			.subscribe( () => {
				this._service.reload();
			} );
	}

	render() {
		return (
			<TopLevel id="rejoin">
				<header>
					<span className="header-caption">
						<Translation textId="rejoin.header"/>
					</span>
				</header>
				<main>
					<div>
						{this.renderText()}
						{this.renderCheckBox()}
						{this.renderPassword1()}
						{this.renderPassword2()}
						{this.renderWarning()}
						{this.renderButton()}
					</div>
				</main>
			</TopLevel>
		);
	}
}

export default RejoinView;
