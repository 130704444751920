"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _baseSource = require("./base.source.js");

var _baseSource2 = _interopRequireDefault(_baseSource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TouchSource = function (_BaseSource) {
	_inherits(TouchSource, _BaseSource);

	function TouchSource(callback) {
		_classCallCheck(this, TouchSource);

		var _this = _possibleConstructorReturn(this, (TouchSource.__proto__ || Object.getPrototypeOf(TouchSource)).call(this, callback));

		if (!global.addEventListener) {
			return _possibleConstructorReturn(_this); //not a browser
		}
		global.addEventListener("touchstart", function (event) {
			return _this._handleStart(event);
		}, true);
		global.addEventListener("touchend", function (event) {
			return _this._handleEnd(event);
		}, true);
		global.addEventListener("touchmove", function (event) {
			return _this._handleMove(event);
		}, true);
		return _this;
	}

	_createClass(TouchSource, [{
		key: "_handleMove",
		value: function _handleMove(event) {
			this._processTouches(event, 5);
		}
	}, {
		key: "_handleStart",
		value: function _handleStart(event) {
			this._processTouches(event, 6);
			this._processTime(5);
		}
	}, {
		key: "_handleEnd",
		value: function _handleEnd(event) {
			this._processTime(5);
		}
	}, {
		key: "_processTime",
		value: function _processTime(bits) {
			if (global.performance) {
				this.gotData(global.performance.now() * 10000, bits * 2);
			} else {
				this.gotData(+new Date(), bits);
			}
		}
	}, {
		key: "_processTouches",
		value: function _processTouches(event, bits) {
			for (var i = 0; i < event.changedTouches.length; i++) {
				var touch = event.changedTouches[i];
				this.gotData(touch.screenX ^ touch.screenY, bits);
			}
		}
	}]);

	return TouchSource;
}(_baseSource2.default);

exports.default = TouchSource;