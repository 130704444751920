import React from "react";
import BaseMessagesView from "../common/messages.jsx";
import _ from "lodash";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class GroupMessagesView extends BaseMessagesView {
	render() {
		return (
			<div className="chat-body" ref="root" onScroll={ this.onScroll }>
				{ this.renderMessages( true ) }
			</div>
		);
	}
}

export default GroupMessagesView;
