import _ from "lodash";
import React from "react";
import ParticipantList from "./participant.list.jsx";
import Accept from "./accept.jsx";
import {MESSAGE_TYPE} from "../../../api/models/chat.message.js";
import {getWebAvaColorByName} from "../../../common/utils.js";
import ChatView from "../chat/chat.group.jsx";
import FullScreen from "../../components/fullscreen.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

class GroupView extends React.Component {
	constructor() {
		super();
		this._participantListClick = this._participantListClick.bind( this );
		this._participantListClose = this._participantListClose.bind( this );
		this._showAccept = this._showAccept.bind( this );
		this._onAccept = this._onAccept.bind( this );
		this._onReject = this._onReject.bind( this );
		this._onGroupClick = this._onGroupClick.bind( this );
		this._onCloseChat = this._onCloseChat.bind( this );
		this.state = {};
		this._service = serviceLocator();
	}

	componentWillMount() {
		if ( this.props.contact.status === "failed" ) {
			return;
		}

		this._participantsCountSubscription = (
			this._service.observeGroupParticipants( this.props.contact )
				.subscribe( participants =>
					this.setState( {
						participantCount:
							_.filter( participants, ({isExited}) => !isExited ).length
						} )
				)
			);
		this._lastMessageSubscription = (
			this._service.observeMessages( this.props.contact )
				.map( msgs => _.filter( msgs, m => m.type !== MESSAGE_TYPE.SERVICE ) )
				.map( msgs => msgs[ msgs.length - 1 ] )
				.subscribe( message => {
					this.setState( {
						lastMessageText: message ? message.text : ""
					} );
				} )
		);
		this._service.queryHistoryAsync( this.props.contact ).subscribe();
	}

	componentWillUnmount() {
		this._participantsCountSubscription && this._participantsCountSubscription.dispose();
		this._lastMessageSubscription && this._lastMessageSubscription.dispose();
	}

	_participantListClick() {
		this.setState( {
			showParticipantList: true
		} );
	}

	_participantListClose() {
		this.setState( {
			showParticipantList: false
		} );
	}

	_showAccept() {
		this.setState( {
			showAccept: true
		} );
	}

	_onAccept( nickname ) {
		this.setState( {
			showAccept: false
		} );
	}

	_onReject() {
		this.setState( {
			showAccept: false
		} );
	}

	_onGroupClick() {
		let {contact} = this.props;
		if ( contact.status === "failed" ) {
			alert( `Group failed: ${contact.failReason}` );
			if ( confirm( "Delete this grouop?" ) ) {
				this._service.exitGroupAsync( contact ).subscribe();
			}
			return;
		}
		this.setState( {
			showChat: true
		} );
	}

	_onCloseChat() {
		this.setState( {
			showChat: false
		} );
	}

	renderMessageCount() {
		let {contact} = this.props;
		if ( !contact.unreadCount ) {
			return null;
		}
		return (
			<text fontFamily="GothamProMedium" fontSize="14" fill="#ffffff" transform="translate(90, 118)">
				{ contact.unreadCount | 0 }
			</text>
		);
	}

	renderMessagesIcon() {
		let {contact} = this.props;
		if ( !contact.unreadCount ) {
			return null;
		}
		return (
			<path d="M83,107 L69,107 C68.447,107 68,107.448 68,108 L68,118 C68,118.553 68.447,119 69,119 L71,119 L71,122 C71,122.405 71.243,122.77 71.617,122.924 C71.741,122.976 71.871,123 72,123 C72.26,123 72.516,122.899 72.707,122.707 L76.414,119 L83,119 C83.553,119 84,118.553 84,118 L84,108 C84,107.448 83.553,107 83,107 L83,107 Z M82,117 L76,117 C75.744,117 75.488,117.098 75.293,117.293 L73,119.586 L73,118 C73,117.447 72.553,117 72,117 L70,117 L70,109 L82,109 L82,117 L82,117 Z" fill="#ffffff" opacity="0.5" />
		);
	}

	render() {
		let classNames = "item";
		let contact = this.props.contact;
		this.state.newMessageCount && ( classNames += " count-sticker" );
		return (
			<div
				className={ classNames }
				data-count={ this.state.newMessageCount }
				onClick={ this._onGroupClick }
			>
				<svg viewBox="0 0 190 128" version="1.1" xmlns="http://www.w3.org/2000/svg">
					<rect fill="#314e62" x="56" y="5" width="134" height="13" />
					<path d="M190,128 L0,128 L0,41.3228346 L0,0 L55.7303175,0 L59.7162963,17.1338583 L190,17.1338583 L190,128 L190,128 Z"
						fill={ getWebAvaColorByName( contact.name ) }
					/>
					<rect opacity="0.1" x="0" y="98" width="190" height="30" />
					<text transform="translate(14, 14)" fontSize="36" fontFamily="GothamPro" fill="#ffffff">...</text>
					<text fontFamily="GothamProBold" fontSize="14" fill="#ffffff" transform="translate(15, 40)">{ contact.name }</text>
					<foreignObject
						x="15"
						y="50"
						width="160"
						height="38"
					>
						<p style={{
							display: "-webkit-box",
							WebkitLineClamp: 3,
							WebkitBoxOrient: "vertical",
							height: "38px",
							overflow:"hidden",
							fontSize:"13px",
							lineHeight:"1.4",
							color: "#fff",
							textOverflow:"ellipsis"
						}}>{ this.state.lastMessageText }</p>
					</foreignObject>
					<text fontFamily="GothamProMedium" fontSize="14" fill="#ffffff" transform="translate(35, 118)" onClick={ this._participantListClick }>
						{ this.state.participantCount }
					</text>
					{ this.renderMessageCount() }
					{ this.renderMessagesIcon() }
					<path d="M27.554,121.832 C27.385,121.946 27.191,122 27.002,122 C26.678,122 26.361,121.844 26.168,121.555 C26.1,121.457 24.353,119 20,119 C15.586,119 13.85,121.529 13.832,121.555 C13.526,122.014 12.904,122.138 12.445,121.832 C11.986,121.525 11.861,120.906 12.168,120.445 C12.262,120.305 14.527,117 20,117 C25.473,117 27.738,120.305 27.832,120.445 C28.138,120.906 28.013,121.525 27.554,121.832 L27.554,121.832 Z M20,116 C17.791,116 16,114.211 16,112 L16,110 C16,107.79 17.791,106.001 20,106.001 C22.209,106.001 24,107.79 24,110 L24,112 C24,114.211 22.209,116 20,116 L20,116 Z M22,110 C22,108.896 21.105,108 20,108 C18.897,108 18,108.896 18,110 L18,112 C18,113.105 18.897,114 20,114 C21.105,114 22,113.105 22,112 L22,110 L22,110 Z" fill="#ffffff" opacity="0.5" />
				</svg>
				{
					this.state.showParticipantList
					? <ParticipantList onClose={ this._participantListClose }/>
					: null
				}
				{
					this.state.showAccept
					? <Accept onAccept={ this._onAccept } onReject={ this._onReject } />
					: null
				}
				{
					this.state.showChat
					? <FullScreen>
						<ChatView contact={ this.props.contact } onBack={ this._onCloseChat } />
					  </FullScreen>
					: null
				}
			</div>
		);
	}
}

GroupView.propTypes = {
	contact: React.PropTypes.object.isRequired
};

export default GroupView;
