import React from "react";
import Translation from "../../../components/translation.jsx";

class AcceptInviteTitleView extends React.Component {
	render() {
		return (
			<div className="navbar grow">
				<div id="navbar" className="navbar-collapse">
					<img
						className="hidden visible-xs-inline back-button"
						src="web/img/arrow2.svg"
						alt=""
						onClick={ this.props.onGoToList }
					/>
					<div className="name">
						<Translation textId="web.accept.invite.title" />
					</div>
				</div>
			</div>
		);
	}
}

export default AcceptInviteTitleView;
