"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _secureBufferContainer = require("../secure.container/secure.buffer.container.js");

var _secureBufferContainer2 = _interopRequireDefault(_secureBufferContainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CHUNK_SIZE = 32;

var keyContainer = new _secureBufferContainer2.default();

var Password = function () {
	function Password(container) {
		_classCallCheck(this, Password);

		this._secureBuffer = container.allocateXored(CHUNK_SIZE);
		this._length = 0;
		this._container = container;
	}

	_createClass(Password, [{
		key: "setFromString",
		value: function setFromString(str) {
			if (this._secureBuffer.byteLength < str.length * 2) {
				var newChunkCount = Math.ceil(str.length * 2 / CHUNK_SIZE);
				this._reallocate(newChunkCount * CHUNK_SIZE);
			}

			this._secureBuffer.useAsBuffer(function (insecureBuffer) {
				insecureBuffer.fill(0);
				for (var i = 0; i < str.length; i++) {
					insecureBuffer.writeUInt16LE(str.charCodeAt(i), i * 2);
				}
			});
			this._length = str.length;
			return this;
		}
	}, {
		key: "insertString",
		value: function insertString(position, str) {
			var _this = this;

			if (position > this._length) {
				position = this._length;
			}
			if (position < 0) {
				position = 0;
			}
			var newLen = str.length + this._length;
			if (this._secureBuffer.byteLength < 2 * newLen) {
				var newChunkCount = Math.ceil(2 * newLen / CHUNK_SIZE);
				this._reallocate(newChunkCount * CHUNK_SIZE);
			}

			this._secureBuffer.useAsBuffer(function (insecureBuffer) {
				var moveBy = str.length * 2;
				for (var i = _this._length * 2 - 2; i >= position * 2; i -= 2) {
					insecureBuffer[i + moveBy] = insecureBuffer[i];
					insecureBuffer[i + moveBy + 1] = insecureBuffer[i + 1];
				}

				for (var _i = 0; _i < str.length; _i++) {
					insecureBuffer.writeUInt16LE(str.charCodeAt(_i), (position + _i) * 2);
				}
			});
			this._length = newLen;
			return this;
		}
	}, {
		key: "_reallocate",
		value: function _reallocate(newSize) {
			var _this2 = this;

			if (this._secureBuffer.byteLength === newSize) {
				return;
			}
			var newSecureBuffer = this._container.allocateXored(newSize);

			this._secureBuffer.useAsBuffer(function (insecureBuffer) {
				newSecureBuffer.useAsBuffer(function (newInsecureBuffer) {
					insecureBuffer.copy(newInsecureBuffer, 0, 0, Math.min(_this2._length * 2, newSize, _this2._secureBuffer.byteLength));
				});
			});

			this._secureBuffer.dispose();
			this._secureBuffer = newSecureBuffer;
		}
	}, {
		key: "removeString",
		value: function removeString(position, count) {
			if (position >= this._length) {
				return this;
			}

			if (position < 0) {
				count += position;
				position = 0;
			}

			if (count <= 0) {
				return this;
			}

			if (position + count > this._length) {
				count = this._length - position;
			}

			var newLen = this._length - count;

			this._secureBuffer.useAsBuffer(function (insecureBuffer) {
				var moveBy = count * 2;
				for (var i = position * 2; i < (position + count) * 2; i += 2) {
					insecureBuffer[i] = insecureBuffer[i + moveBy];
					insecureBuffer[i + 1] = insecureBuffer[i + moveBy + 1];
				}
				insecureBuffer.fill(0, newLen * 2);
			});
			this._length = newLen;
			return this;
		}
	}, {
		key: "toString",
		value: function toString() {
			var _this3 = this;

			var res = void 0;
			//insecure operation for debugging purposes only
			this._secureBuffer.useAsBuffer(function (insecureBuffer) {
				var ar = new Array(_this3._length);
				for (var i = 0; i < _this3._length; i++) {
					ar[i] = insecureBuffer.readUInt16LE(i * 2);
				}
				res = String.fromCharCode.apply(String, ar);
			});
			return res;
		}
	}, {
		key: "dispose",
		value: function dispose() {
			this._secureBuffer.dispose();
			this._length = 0;
			this._secureBuffer = null;
		}
	}, {
		key: "copyTo",
		value: function copyTo(transformedBuffer) {
			var _this4 = this;

			if (transformedBuffer.byteLength !== this._length * 2) {
				throw new Error("Copy to buffer invalid size");
			}
			transformedBuffer.useAsBuffer(function (bOut) {
				return _this4._secureBuffer.useAsBuffer(function (bIn) {
					return bIn.copy(bOut);
				});
			});
			return this;
		}
	}, {
		key: "length",
		get: function get() {
			return this._length;
		}
	}, {
		key: "byteLength",
		get: function get() {
			return this._length * 2;
		}
	}]);

	return Password;
}();

exports.default = Password;