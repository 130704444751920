import HubBase from "./hub.base.js";
import configuration from "../../common/configuration.js";

class TimeHub extends HubBase {
	constructor( ) {
		super();
		this._messagesSubj = new Rx.Subject();
		this._nextId = 0;
		this._listenMessages( "time" ).subscribe( message => {
			this._messagesSubj.onNext( message );
		} );
	}

	getServerTimeAsync( ) {
		let timeout = configuration.getNetworkTimeout();
		return (
			Rx.Observable.interval( timeout )
				.startWith( -1 )
				.flatMap( () => {
					let id = this._nextId++;
					let sentAt = +new Date;
					this.send( "time", { id } );
					return (
						this._messagesSubj
							.find( message =>
								( message.id === id ) && ( +new Date - sentAt < timeout )
							)
							.map( message => ( {
								serverTime: message.current,
								sentAt
							} ) )
						);
				} )
				.first()
		);
	}
}

export default TimeHub;
