"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _bn = require("bn.js");

var _bn2 = _interopRequireDefault(_bn);

var _key = require("../primitives/key.js");

var _key2 = _interopRequireDefault(_key);

var _keyKind = require("../primitives/key.kind.js");

var _keyKind2 = _interopRequireDefault(_keyKind);

var _prng = require("../random/locators/prng.js");

var _prng2 = _interopRequireDefault(_prng);

var _bnExternal = require("../bn.external/bn.external.js");

var _bnExternal2 = _interopRequireDefault(_bnExternal);

var _schnorr = require("../group/schnorr.js");

var _schnorr2 = _interopRequireDefault(_schnorr);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var random = (0, _prng2.default)();

//TODO: custom memory allocations

var DH = function () {
	function DH(container, masterKey, group, configHashString) {
		_classCallCheck(this, DH);

		if (!(group instanceof _schnorr2.default)) {
			group = new _schnorr2.default(group);
		}
		if (!(typeof configHashString === "string")) {
			throw new Error("configHashString must be a string");
		}
		this._group = group;
		this._container = container;
		this._masterKey = masterKey;
		this._configHashString = configHashString;
	}

	_createClass(DH, [{
		key: "createRandomKeyPairThen",
		value: function createRandomKeyPairThen() {
			var _this = this;

			return random.pseudoRandomDataThen(this._group.getQ().byteLength() + 2).then(function (x) {
				var privBN = new _bn2.default(x).mod(_this._group.getQ().subn(2)).addn(2);
				var privKey = new _key2.default(_this._container, _this._group.getQ().byteLength(), _keyKind2.default.KEY_EXCHANGE_PRIVATE, _this._configHashString, _this._masterKey);
				return new Promise(function (resolve) {
					privKey.postponeManagedBuffer(function (privMB) {
						privMB.useAsBuffer(function (privBuffer) {
							var privAr = privBN.toArray("LE", privBuffer.byteLength);
							for (var i = 0; i < privAr.length; i++) {
								privBuffer[i] = privAr[i];
							}
						});

						setTimeout(function () {
							_this.createPublicKeyThen(privKey).then(function (publicKey) {
								resolve({ publicKey: publicKey, privateKey: privKey });
							});
						}, 0);
					});
				});
			});
		}
	}, {
		key: "createPublicKeyThen",
		value: function createPublicKeyThen(privateKey) {
			var _this2 = this;

			if (!privateKey) {
				throw new Error("privateKey required. to make random key pair call createRandomKeyPairThen");
			}
			var privHandle = privateKey.addKeyUse("dh public key calc");
			var privBN = void 0;
			return new Promise(function (resolve) {
				privateKey.postponeManagedBuffer(function (mb) {
					return mb.useAsBuffer(function (b) {
						var bn = new _bn2.default(b);
						_bnExternal2.default.redPowExternalThen(_this2._group.getGRed(), bn).then(function (pubBN) {
							var publicKey = new _key2.default(_this2._container, _this2._group.getP().byteLength(), _keyKind2.default.KEY_EXCHANGE_PUBLIC, _this2._configHashString, _this2._masterKey);
							publicKey.postponeManagedBuffer(function (pubMB) {
								pubMB.useAsBuffer(function (pubBuffer) {
									var pubAr = pubBN.toArray("LE", pubBuffer.byteLength);
									for (var i = 0; i < pubAr.length; i++) {
										pubBuffer[i] = pubAr[i];
									}
								});
								privateKey.removeKeyUse(privHandle);
								resolve(publicKey);
							});
						});
					});
				}, privHandle);
			});
		}
	}, {
		key: "createSharedKeyThen",
		value: function createSharedKeyThen(privateKey, publicKey) {
			var _this3 = this;

			if (!privateKey.isConsistentTo(publicKey) || privateKey._configHashString !== this._configHashString) {
				//TODO: ??
				throw new Error("Inconsistent key's configuration");
			}
			var privHandle = privateKey.addKeyUse("dh shared key calc");
			var pubHandle = publicKey.addKeyUse("dh shared key calc");
			return new Promise(function (resolve) {
				privateKey.postponeManagedBuffer(function (privMB) {
					return privMB.useAsBuffer(function (privB) {
						var privBN = new _bn2.default(privB);
						publicKey.postponeManagedBuffer(function (pubMB) {
							return pubMB.useAsBuffer(function (pubB) {
								var pubBN = new _bn2.default(pubB).toRed(_bn2.default.red(_this3._group.getP()));

								_bnExternal2.default.redPowExternalThen(pubBN, privBN).then(function (resBN) {
									var resKey = new _key2.default(_this3._container, _this3._group.getP().byteLength(), _keyKind2.default.INTERMEDIATE, _this3._configHashString, _this3._masterKey);
									resKey.postponeManagedBuffer(function (resMB) {
										resMB.useAsBuffer(function (resBuffer) {
											var resAr = resBN.toArray("LE", resBuffer.byteLength);
											for (var i = 0; i < resAr.length; i++) {
												resBuffer[i] = resAr[i];
											}
										});
										privateKey.removeKeyUse(privHandle);
										publicKey.removeKeyUse(pubHandle);
										resolve(resKey);
									});
								});
							});
						}, pubHandle);
					});
				}, privHandle);
			});
		}
	}]);

	return DH;
}();

exports.default = DH;