import React from "react";
import Switch from "../common/switch.jsx";
import Prompt from "../common/prompt.jsx";
import translate from "../../../translations/translate.js";
import Translation from "../../../components/translation.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class AccountView extends React.Component {
	constructor() {
		super();
		this.state = {};

		this._service = remoteServiceLocator();
		this.onAddHelperContact = this.onAddHelperContact.bind( this );
		this.doAddHelperContact = this.doAddHelperContact.bind( this );
	}

	onAddHelperContact() {
		this.setState( {
			popup: () =>
				<Prompt
					titleTextId="web.helper.prompt.title"
					defaultValue={ translate( "web.helper.prompt.default" ) }
					onDone={ this.doAddHelperContact }
				/>
		} );
	}

	doAddHelperContact( name ) {
		if ( !name ) {
			this.setState({popup: null});
			return;
		}
		if ( this.state.inProgress ) {
			return;
		}
		this.setState( {inProgress: true} );
		this._service.addHelperContactAsync( name )
			.subscribe( contactId => {
				this.setState({popup: null, inProgress: false});
			} );
	}

	renderPopup() {
		if ( this.state.popup ) {
			return this.state.popup();
		}
		return null;
	}

	render() {
		return (
			<div className={this.props.className}>
				<div className="settings-box-info">
					<div className="invite">
						<div className="title-sm">
							<Translation textId="web.helper.title"/>
						</div>
						<p>
							<Translation textId="web.helper.text"/>
						</p>
						<button className="btn btn-green" onClick={this.onAddHelperContact}>
							<Translation textId="web.helper.button" />
						</button>
					</div>
				</div>
				{ this.renderPopup() }
			</div>
		);
	}
}

export default AccountView;
