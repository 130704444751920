import React from "react";
import _ from "lodash";

import Button from "../../../components/button.jsx";
import Translation from "../../../components/translation.jsx";
import audioManager from "./audio.manager.js";

const MAX_RECORD_TIME = 60000;
const WARN_RECORD_TIME = 50000;

if ( !( "toJSON" in Error.prototype ) ) {
  Object.defineProperty( Error.prototype, "toJSON", {
      value: function () {
          var alt = {};

          Object.getOwnPropertyNames( this ).forEach( key => {
              alt[ key ] = this[ key ];
          } );
          alt.message = this.message;
          return alt;
      },
      configurable: true,
      writable: true
  } );
}

function toMMSS( timespan ) {
    var sec_num = Math.floor( timespan / 1000 );
    var minutes = Math.floor( sec_num / 60 );
    var seconds = sec_num - ( minutes * 60 );

    if ( minutes < 10 ) { minutes = "0" + minutes; }
    if ( seconds < 10 ) { seconds = "0" + seconds; }
    return minutes + ":" + seconds;
}

let Initializing = () => (
  <Translation textId="voice.recording.init" />
);

let WarnExpiring = ( { startAt } ) => (
  +new Date - startAt < WARN_RECORD_TIME
  ? null
  : (
    <Translation textId="voice.recording.timeout" params={ [ MAX_RECORD_TIME - (+new Date) + startAt ] } />
  )
);

let Recording = ( { onCancelRecording, onStopRecording, startAt, stoppedAt } ) => (
  <div style={ { width: "100%", height: "100%" } }>
    <div style={ { fontSize: "20px", textAlign: "center", padding: "20px" } }>
      <Translation textId="voice.recording.text" />
      <br />
      { toMMSS( ( stoppedAt || +new Date ) - startAt ) }
    </div>
    <div style={ { fontSize: "14px", textAlign: "center", height: "20px", width: "100%", color: "red" } }>
      <WarnExpiring startAt={ startAt } />
    </div>
    <div style={ { width: "50%", height: "100%", display: "inline-block", padding: "20px" } }>
      <Button
        className="green extra-small"
        caption="voice.recording.cancel"
        handleClick={ onCancelRecording }
      />
    </div>
    <div style={ { width: "50%", height: "100%", display: "inline-block", padding: "20px" } }>
      <Button
        className="green extra-small"
        caption="voice.recording.send"
        handleClick={ onStopRecording }
      />
    </div>
  </div>
);

let ErrorView = ( { error, onExit } ) => (
  <div style={ { width: "100%", height: "100%", overflow: "scroll" } }>
    Error: { error.message || ( error + 0 ) }
    <br />
    <Button
      className="green extra-small"
      caption="voice.recording.error.return"
      handleClick={ onExit }
      style={ { background: "#ccc" } }
    />
  </div>
);

let Recorded = () => (
  <Translation textId="voice.recording.recorded" />
);

class AudioRecorder extends React.Component {
  constructor() {
    super();
    this.state = {
      status: "initializing"
    };
    this.onCancelRecording = this.onCancelRecording.bind(this);
    this.onStopRecording = this.onStopRecording.bind(this);
    this.onStartedRecord = this.onStartedRecord.bind(this);
    this.onDataAvailable = this.onDataAvailable.bind(this);
    this.onRecordingProgress = this.onRecordingProgress.bind( this );
    this.onError = this.onError.bind( this );
    // navigator.mediaDevices.enumerateDevices().then( devices => { console.log( "mediaDevices", devices ) } );
    audioManager.startRecord().then( this.onStartedRecord ).catch( this.onError );
  }

  componentWillUnmount() {
    this._timeout && clearTimeout( this._timeout );
    if ( audioManager.isRecording() ) {
      audioManager.stopRecord();
    }
  }

  onRecordingProgress() {
    if ( !audioManager.isRecording() ) {
      this.setState(
        { status: "canceled", stoppedAt: +new Date },
        () => {
          this.props.onRecorded( null );
        }
      );
      return;
    }

    this.forceUpdate();
    if ( +new Date - this.state.startAt >= MAX_RECORD_TIME ) {
      this.onStopRecording();
      return;
    }
    this._timeout = setTimeout( this.onRecordingProgress, 1000 );
  }

  onStartedRecord() {
    if ( this.state.status !== "initializing" || !audioManager.isRecording() ) {
      return;
    }
    this.setState(
      { status: "recording", startAt: +new Date },
      () => {
        this._timeout && clearTimeout( this._timeout );
        this.onRecordingProgress();
      }
    );
  }

  onCancelRecording() {
    this.setState(
      { status: "canceled", stoppedAt: +new Date },
      () => {
        audioManager.stopRecord();
        this.props.onRecorded( null );
      }
    );
  };

  onStopRecording() {
    if ( this.state.status !== "recording" ) {
      return;
    }
    this.setState(
      { status: "recorded", stoppedAt: +new Date },
      () => {
        audioManager.stopRecord().then( this.onDataAvailable );
      }
    );
  };

  onDataAvailable( ui8Array ) {
    let { startAt, stoppedAt, status } = this.state;
    let milliseconds = stoppedAt - startAt;
    if ( status === "canceled" || !ui8Array || ui8Array.length < 1024 || !milliseconds || milliseconds < 500 ) {
      this.props.onRecorded( null );
      return;
    }
    this.props.onRecorded( ui8Array, milliseconds, "audio/ogg" );
  }

  onError( error ) {
    debugger;
    this.setState( { status: "error", error } );
  }

  render() {
    let { status, startAt, error } = this.state;

    switch ( status ) {
      case "initializing":
        return <Initializing />;
      case "recording":
        return (
          <Recording
            onCancelRecording={ this.onCancelRecording }
            onStopRecording={ this.onStopRecording }
            startAt={ startAt }
          />
        );
      case "recorded":
      case "canceled":
        return <Recorded />;
      case "error":
        return <ErrorView error={ error } onExit={ () => this.props.onRecorded( null ) }/>;
    }
  }
}



export default AudioRecorder;
