import files from "../../build/preload.js";

export default function preload() {
	let images = window[ "!!!preload" ] = [], i;
	for ( i = 0; i < files.length; i++ ) {
		//TODO: two builds?
		if ( ( window.useRouter === "web" ) === ( files[ i ].substr( 0, 5 ) === "/web/" ) ) {
			images[ i ] = new Image();
			images[ i ].src = files[ i ];
		}
	}
}
