import React from "react";
import ReactDom from "react-dom";
import Promise from "bluebird";

import RootView from "./views/top/root.jsx";
import Buffer from "buffer";
import Rx from "rx";
import translate from "./translations/translate.js";
import preloader from "../common/preloader.js";
import {FastClick} from "./fastclick.js";

global.Promise = Promise;
Promise.config( {
  longStackTraces: false,
  warnings: false
} );

if ("addEventListener" in document) {
	document.addEventListener("DOMContentLoaded", function() {
		FastClick.attach(document.body);
	}, false);
}

preloader();

//This cause throwing & catching exception on every Observable creation.
//Turn on only when really need and do not commit.
//Rx.config.longStackSupport = true;

translate.setLanguage(
	global.navigator.languages && global.navigator.languages.length
	? global.navigator.languages
	: [ global.navigator.language ]
);

ReactDom.render( <RootView />, global.document.getElementById( "app" ) );
