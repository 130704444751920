import _ from "lodash";
import Rx from "rx";
import Queue from "promise-queue";

import {serializeObject, deserializeObject} from "../../../common/serializer.js";
import {serializeContactToBuffer, encryptedContactRecordSize} from "./contact.list.js";
import ContactListRepository from "./contact.list.split.js";
import ContactDetailsRepository from "./contact.details.js";

class ContactsRepository {
	constructor( StorageType, defaultDetailsRecordSize, defaultContactRecordCount ) {
		this._StorageType = StorageType;
		this._defaultDetailsRecordSize = defaultDetailsRecordSize;
		this._defaultContactRecordCount = defaultContactRecordCount;

		this._contactListRepository = new ContactListRepository( StorageType );
		this._contactDetailsRepository = new ContactDetailsRepository( StorageType );
		this._nextId = null;
		this._monitorSyncListFunc = null;
		this._monitorSyncDetailsFunc = null
	}

	initAsync( encryptionKey, positionBit ) {
		return Rx.Observable.fromPromise( this.initThen( encryptionKey, positionBit ) );
	}

	initThen( encryptionKey, positionBit ) {
		if ( this._initPromise ) {
			//TODO: do not initialize twice
			return this._initPromise;
		}
		this.initQueue();
		this._positionBit = positionBit;
		this._initSubj = new Rx.ReplaySubject();
		this._joiningContacts = [];

		this._contactListRepository.init( encryptionKey );
		this._contactDetailsRepository.init( encryptionKey );
		return this._initPromise = this._enqueue( () => new Promise( resolve => {
			ContactListRepository.isExistAsync( this._StorageType )
				.flatMap( isExistListFile => isExistListFile
					? this._readContactListAsync().flatMap( () => this._ensureListSizeAsync() )
					: this._createNewListAsync()
				)
				.subscribe( () => {
					this._assignListIds();
					this._initSubj.onNext();
					this._initSubj.onCompleted();
					resolve();
				} )
		} ) );
	}

	startCreatingRandomFiles() {
		this._contactDetailsRepository.startCreatingRandomFiles(
			this._defaultDetailsRecordSize,
			this._actualRecordCount
		);
	}

	initQueue( ) {
		this._queue = new Queue( 1 );
	}

	uninitAsync( ) {
		if ( !this._queue ) {
			return Rx.Observable.just();
		}

		delete this._positionBit;
		delete this._queueSubject;
		delete this._initSubj;
		return this._enqueue( () => new Promise( ( resolve, reject ) => {
			Rx.Observable.combineLatest(
				this._contactListRepository.uninitAsync(),
				this._contactDetailsRepository.uninitAsync(),
				() => undefined
			).subscribe( resolve, reject );
		} ) );
	}

	getListAsync( ) {
		return Rx.Observable.fromPromise( this._enqueue( () => {
			let result = (
				_.filter( this._list, it => !it.invalid )
					.map( ( value, index ) => _.assign( {}, value ) )
				);
			return result;
		} ) );
	}

	getDetailsAsync( id, contactType ) {
		return Rx.Observable.fromPromise( this.getDetailsThen( id, contactType ) );
	}

	getDetailsThen( id, contactType ) {
		if ( !contactType ) {
			throw new Error( "contactType required" );
		}
		let index = this._findIndexById( id );
		return this._enqueue( () => {
			if ( !~index ) {
				debugger;
				return Promise.reject( new Error( "Contact not found" ) );
			}
			let actualIndex = this._getActualIndex( index );
			return (
				this._contactDetailsRepository.getContactAtThen( actualIndex, contactType )
			);
		} );
	}

	insertAsync( contact ) {
		contact.id = this._getNextId();
		if ( contact.status === "joining" || contact.noPersist ) {
			this._joiningContacts.push( contact );
			return Rx.Observable.just();
		}
		console.warn( "Inserting contact without intermediate state" );
		return this._insertAsync( contact );
	}

	_insertAsync( contact ) {
		return Rx.Observable.fromPromise( this._insertThen( contact ) );
	}

	_insertThen( contact ) {
		let listItem = contact.getListItemJSON();
		let details = contact.getDetailsJSON();

		return this._enqueue( () => {
			let index = this._findFreeIndex();
			if ( !~index ) {
				debugger;
				return Rx.Observable.throw( new Error( "Free contact not found" ) );
			}

			let actualIndex = this._getActualIndex( index );
			this._list[ index ] = listItem;
			return (
				this._contactDetailsRepository.setContactAtThen(
					actualIndex,
					details,
					this._defaultDetailsRecordSize,
					listItem.type
				)
				.then( () => this._contactListRepository.writeAtThen( actualIndex, listItem ) )
				.catch( e => {
					this._list[ index ] = { invalid: true };
					return Promise.reject( e );
				} )
			);
		} );
	}

	updateAsync( contact ) {
		if ( contact.status === "failed" ) {
			return Rx.Observable.just();
		}
		let index = _.findIndex( this._joiningContacts, { id: contact.id } );
		if ( contact.status === "joining" || contact.noPersist ) {
			if ( !~index ) {
				throw new Error( "Joining contact not found" );
			}
			this._joiningContacts[ index ] = contact;
			return Rx.Observable.just();
		}

		if ( ~index ) {
			this._joiningContacts.splice( index, 1 );
			return this._insertAsync( contact ).map( () => true );
		}

		return this._updateAsync( contact );
	}

	_updateAsync( contact ) {
		return Rx.Observable.fromPromise( this._updateThen( contact ) );
	}

	_updateThen( contact ) {
		if ( !this._initSubj ) {
			throw new Error( "Not initialized" );
		}
		return this._enqueue( () => contact.isDetailsUpdated()
			? this._updateDetailsThen( contact )
				.then( () => this._updateListItemThen( contact.getListItemJSON() ) )
				.then( () => true )
			: this._updateListItemThen( contact.getListItemJSON() )
		);
	}

	_updateDetailsThen( contact ) {
		let id = contact.id;
		let details = contact.getDetailsJSON();
		let contactType = contact.type;

		if ( details.id !== id ) {
			throw new Error( "list and details ids does not match" );
		}
		let index = this._findIndexById( id );
		if ( !~index ) {
			//TODO: sometimes this is called after contact remove
			return Promise.resolve();
		}
		let actualIndex = this._getActualIndex( index );
		contact.resetUpdatedDetails();
		return this._contactDetailsRepository.setContactAtThen(
			actualIndex,
			details,
			this._defaultDetailsRecordSize,
			contactType
		);
	}

	_updateListItemAsync( listItem ) {
		return Rx.Observable.fromPromise( this._updateListItemThen( listItem ) );
	}

	_updateListItemThen( listItem ) {
		let index = this._findIndexById( listItem.id );
		if ( !~index ) {
			//TODO: fix: sometimes this is called after contact remove
			return Promise.resolve( false );
		}
		if ( !this._list[ index ].invalid && ( this._list[ index ] !== listItem ) ) {
			let newData = new Buffer( 240 );
			let oldData = new Buffer( 240 );
			serializeContactToBuffer( listItem, newData );
			serializeContactToBuffer( this._list[ index ], oldData );
			if ( newData.equals( oldData ) ) {
				return Promise.resolve( false );
			}
		}

		this._list[ index ] = listItem;
		let actualIndex = this._getActualIndex( index );

		return this._contactListRepository.writeAtThen( actualIndex, listItem ).then( () => true );
	}

	deleteAsync( id ) {
		return Rx.Observable.fromPromise( this.deleteThen( id ) );
	}

	deleteThen( id ) {
		return this._enqueue( () => {
			let index = this._findIndexById( id );
			if ( !~index ) {
				return Promise.resolve( false );
			}
			let actualIndex = this._getActualIndex( index );
			let lastIndex = _.findIndex( this._list, ({invalid}) => !!invalid );
			if ( !~lastIndex ) {
				lastIndex = this._list.length - 1;
			}
			lastIndex--;
			if ( lastIndex > index ) {
				let lastElement = this._list[ lastIndex ];
				let actualLastIndex = this._getActualIndex( lastIndex );
				let lastDetails;
				return (
					this._contactDetailsRepository.getContactAtThen( actualLastIndex, lastElement.type )
						.catch( error => {
							console.error( "Error getting contact", actualLastIndex, lastElement, this, error );
							debugger;
							throw error;
						} )
						.then( ld => { lastDetails = ld; } )
						.then( () => this._contactListRepository.deleteThen( actualLastIndex ) )
						.then( () => this._contactDetailsRepository.writeRandomThen( actualLastIndex, this._defaultDetailsRecordSize ) )
						.then( () => this._contactListRepository.writeAtThen( actualIndex, lastElement ) )
						.then( () => this._contactDetailsRepository.setContactAtThen(
							actualIndex,
							lastDetails,
							this._defaultDetailsRecordSize,
							lastElement.type
						) )
						.then( () => {
							this._list[ lastIndex ] = { invalid: 1 };
							this._list[ actualIndex ] = lastElement;
							return true;
						} )
				);
			}
			//Current element is the last
			return (
				this._contactListRepository.deleteThen( actualIndex )
					.then( () => this._contactDetailsRepository.writeRandomThen(
						actualIndex, this._defaultDetailsRecordSize
					) )
					.then( () => {
						this._list[ index ] = { invalid: true };
						return true;
					} )
			);
		} );
	}

	dropDataAsync( ) {
		return (
			this._contactListRepository.getItemsCountAsync()
				// .flatMap( count => Rx.Observable.range( 0, Math.max( count, this._defaultContactRecordCount ) ) )
				// .flatMap( index => this._contactDetailsRepository.dropDataAsync( index ) )
				.toArray()
				.flatMap( () => this._contactListRepository.dropDataAsync() )
			);
	}

	isAnyDataAsync( ) {
		return this._enqueue( () => {
//			return Rx.Observable.just( !!this._list && !!this._list.length );
			return this._contactListRepository.isAnyDataThen();
		} );
	}

	_getActualIndex( index ) {
		return this._positionBit ? this._list.length - index - 1 : index;
	}

	_findIndexById( id ) {
		return _.findIndex( this._list, item => item.id === id );
	}

	_findFreeIndex( ) {
		return _.findIndex( this._list, item => item.invalid );
	}

	_getNextId( ) {
		return this._nextId++;
	}

	_assignListIds( ) {
		this._nextId = _.reduce(
			this._list,
			( acc, curr ) => curr.id ? Math.max( acc, curr.id ) : acc, 0
		) + 1;
	}

	_enqueue( func ) {
		return this._queue.add( func );
	}

	_createNewListAsync( ) {
		this._actualRecordSize = this._defaultDetailsRecordSize;
		this._list = new Array( this._defaultContactRecordCount );
		for ( let i = 0; i < this._list.length; i++ ) {
			this._list[ i ] = { invalid: true };
		}
		this._actualRecordCount = this._defaultContactRecordCount;
		return this._contactListRepository.preallocateAsync( this._defaultContactRecordCount );
	}

	_readContactListAsync( ) {
		return (
			this._contactListRepository.getAllAsJsonAsync( this._positionBit )
				.tap( list => {
					let idHashs = Object.create( null );
					for ( let i = 0; i < list.length; i++ ) {
						if ( !list[ i ] ) {
							list[ i ] = { invalid: true };
						}
						let { invalid, id } = list[ i ];
						if ( invalid ) {
							continue;
						}
						if ( idHashs[ id ] ) {
							console.error( "Contact list repository id duplicate" );
							list[ i ] = { invalid: true };
						}
						idHashs[ id ] = 1;
					}
					this._list = list;
				} )
			);
	}

	_ensureListSizeAsync( ) {
		if ( !this._list.length ) {
			return this._createNewListAsync();
		}
		if ( this._list.length >= this._defaultContactRecordCount ) {
			this._actualRecordCount = this._list.length;
			return Rx.Observable.just();
		}
		let insertCount = this._defaultContactRecordCount - this._list.length;
		let lastValidIndex = this._list.length;

		while ( ~--lastValidIndex && this._list[ lastValidIndex ].invalid );
		let insertAtIndex = lastValidIndex + 1;
		if ( this._positionBit ) {
			insertAtIndex = this._list.length - insertAtIndex;
		}
		console.warn( `Resizing from ${this._list.length} to ${this._defaultContactRecordCount}` );

		return (
			this._contactListRepository
				.insertEmptyRecordsAsync( insertAtIndex, insertCount )
				.flatMap( () => this._contactDetailsRepository
					.moveTailContactsAsync( insertAtIndex, this._list.length - insertAtIndex, insertCount ) )
				.flatMap( () => Rx.Observable.range( insertAtIndex, insertCount ) )
				.flatMap( index =>
					this._contactDetailsRepository.writeRandomAsync( index )
				)
				.toArray()
				.catch( error => {
					console.warn( `Failed to insert empty records. Tried to resize from ${this._list.length} to ${this._defaultContactRecordCount}`, error );
					return Rx.Observable.just();
				} )
				.flatMap( () => this._readContactListAsync() )
		);
	}

	hookDataUpdate( onListItemUpdate, onDetailsUpdate ) {
		this._contactListRepository.hookWrite( onListItemUpdate );
		this._contactDetailsRepository.hookWrite( onDetailsUpdate );
	}

	getRawListAsync( ) {
		return (
			this._contactListRepository.getRawDataAsync()
				.map( buffer => {
					let chunks = [];
					for( let i = 0; i < buffer.length; i += encryptedContactRecordSize ) {
						chunks.push( buffer.slice( i, i + encryptedContactRecordSize ) );
					}
					return chunks;
				} )
		);
	}

	getRawDetailsDataAsync( index ) {
		return this._contactDetailsRepository.getRawDataAsync( index );
	}

	setDetailsRawDataAsync( buffer, index ) {
		return this._contactDetailsRepository.setRawDataAsync( buffer, index );
	}

	setRawListAsync( buffers ) {
		if ( !_.every( buffers, buffer =>
			Buffer.isBuffer( buffer ) && buffer.length === encryptedContactRecordSize
		) ) {
			debugger;
			return Rx.Observable.throw( new Error( "Invalid list buffers" ) );
		}
		return this._contactListRepository.setRawDataAsync( Buffer.concat( buffers ) );
	}
}

export default ContactsRepository;
