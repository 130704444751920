import React from "react";
import Translation from "../../../components/translation.jsx";

class SettingsListTitle extends React.Component {
	render() {
		return (
			<div>
				<Translation textId="web.settings.title" />
			</div>
		);
	}
}

export default SettingsListTitle;
