import crypto from "crypto";
import ssgCrypto, {Key} from "ssg.crypto";
import BinaryStream from "../binary.stream.js";
let ENCRYPTION_CHUNK = 16;

class MessageDecrypter {
	constructor( params ) {
		if ( !params.encryptionMethod ) {
			throw new Error( "encryptionMethod required for encryption" );
		}

		if ( !( params.key instanceof Key ) ) {
			throw new Error( "key required for encryption" );
		}

		if ( !( params.binaryStream instanceof BinaryStream ) ) {
			throw new Error( "binaryStream required for decryption" );
		}

		if ( ( params.binaryStream.size % 16 ) !== 0 || params.binaryStream.size < 16 ) {
			throw new Error( "data length incorrect: ${params.binaryStream.size}" );
		}

		this._encryptionMethod = params.encryptionMethod;
		this._binaryStream = params.binaryStream;
		this._key = params.key;

		this._outBinaryStream = this._createBinaryStream();
	}

	getBinaryStream( ) {
		return this._outBinaryStream;
	}

	_createBinaryStream( ) {
		let plainSize = this._binaryStream.size;
		let decryptedSize = plainSize - ENCRYPTION_CHUNK;

		let iv;
		let bs = BinaryStream.fromChunked(
			( chunkCount, cb ) => {
				if ( !iv ) {
					this._binaryStream.getNextBytesCb(
						ENCRYPTION_CHUNK,
						iv_ => {
							iv = iv_;
							this._getNextBytesCb( iv, chunkCount, cb );
						} );
					return;
				}
				this._getNextBytesCb( iv, chunkCount, cb );
			},
			ENCRYPTION_CHUNK,
			decryptedSize / ENCRYPTION_CHUNK
		);
		return bs;
	}

	_getNextBytesCb( iv, chunkCount, cb ) {
		let pos = this._outBinaryStream.position;
		let buffer = new Buffer( chunkCount * ENCRYPTION_CHUNK );

		this._binaryStream.getNextBytesCb(
			ENCRYPTION_CHUNK * chunkCount,
			encrypted => {
				this._key.postponeManagedBuffer( mb => mb.useAsBuffer( keyBuffer => {
					let decipher = crypto.createDecipheriv( "aes-256-cbc", keyBuffer, iv );
					decipher.setAutoPadding( false );
					let plain = decipher.update( encrypted );
					decipher.final();

					encrypted.copy( iv, 0, encrypted.length - ENCRYPTION_CHUNK );
					cb( plain );
				} ) );
			}
		);
	}

	dispose( ) {
		this._encryptedBinaryStream.dispose();
	}

}

MessageDecrypter.decryptHeaderThen = params => {
	if ( !params.key ) {
		throw new Error( "params.key required" );
	}
	if ( !params.buffer ) {
		throw new Error( "params.buffer required" );
	}
	if ( !params.encryptionMethod ) {
		throw new Error( "params.encryptionMethod required" );
	}
	return ssgCrypto.decryptUsingAlgorithmThen( 
		params.buffer, params.key, false, params.encryptionMethod
	);
};

export default MessageDecrypter;
