import React from "react";
import _ from "lodash";
import Rx from "rx";

import Prompt from "../common/prompt.jsx";
import serviceLocator from "../../../api/services/locators/worker.client.js";
import PromptParticipantsBase from "../common/prompt.participants.base.jsx";

class SendContactsView extends PromptParticipantsBase {
	constructor() {
		super();
		this._service = serviceLocator();
		this.headerTextId = "send.contacts.header";
		this.descriptionTextId = "send.contacts.text";
	}

	onContinueClick() {
		let contactIds = this.state.selectedIds;
		let progressSubj = new Rx.BehaviorSubject(0);
		this._service.sendContactsAsync( this.props.contact, contactIds )
			.subscribe( () => {
				this.props.onBack();
			} );
		super.onContinueClick();
		progressSubj.subscribe( current => { //TODO: this is copy-paste
			this.setState( {
				total: this.state.selectedIds.length,
				current: current + 1
			} );
		} );
	}

	onBackPress() {
		this.props.onBack();
		super.onBackPress();
	}

	componentWillMount() {
		this._contactsSubscription = (
			this._service
				.observeContactList()
				.subscribe( contacts =>
					this.setState( {
						contacts: _.filter( contacts, ( { status, multidescriptionId, id } ) => {
							return (
								( status === "active" )
								&&
								!~multidescriptionId
								&&
								( id !== this.props.contact.id )
							);
						} )
					} )
				)
			);
	}

	componentWillUnmount() {
		this._contactsSubscription.dispose();
	}
}

export default SendContactsView;
