import _ from "lodash";
import ClientServerBase from "./client.server.base.js";

class ClientServerOnline extends ClientServerBase {
	constructor( apiUrlBase, connectionId ) {
		super( apiUrlBase, connectionId );
		this._gotMessageHandler = this._gotMessageHandler.bind( this );
		this._pairedHandler = this._pairedHandler.bind( this );
		this._unpairedHandler = this._unpairedHandler.bind( this );

		this._on( "onlineMessage", this._gotMessageHandler );
		this._on( "paired", this._pairedHandler );
		this._on( "unpaired", this._unpairedHandler );
	}

	_gotMessageHandler( message ) {
		let { callId, listenerId } = message;
		if ( listenerId !== this._listenerId ) {
			return;
		}
		message = _.clone( message );
		if ( !callId ) {
			console.error( "Got message from server without callId" );
		}

		delete message.callId;
		if ( !this._onMessage ) {
			console.warn( "Got message without handler" );
			return;
		}
		this._onMessage( message );
	}

	_pairedHandler( message ) {
		let { callId, listenerId } = message;
		if ( listenerId !== this._listenerId ) {
			return;
		}
		message = _.clone( message );
		if ( !callId ) {
			console.error( "Got message from server without callId" );
		}

		delete message.callId;

		if ( !this._onPaired ) {
			console.warn( "No paired handler" );
			return;
		}
		this._onPaired( message );
	}

	_unpairedHandler( message ) {
		let { callId, listenerId } = message;
		if ( listenerId !== this._listenerId ) {
			return;
		}
		message = _.clone( message );
		if ( !callId ) {
			console.error( "Got message from server without callId" );
		}

		delete message.callId;

		if ( !this._onUnpaired ) {
			console.warn( "No unpaired handler" );
		}
		this._onUnpaired( message );
	}

	onGotMessage( func ) {
		if ( this._onMessage ) {
			throw new Error( "onGotMessage double call" );
		}
		this._onMessage = func;
	}

	tryPair( pairData, cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}
		if ( pairData && ( typeof pairData !== "string" ) ) {
			throw new Error( "pairData expected to be string" );
		}
		this._tryMakeCall( "pair", {
			listenerId: null,
			pairData,
			with: this._connectionId
		}, cb );
	}

	tryUnpair( cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}

		this._tryMakeCall( "unpair", {
			listenerId: null,
			with: this._connectionId
		}, cb );
	}

	trySend( body, cb ) {
		if ( !this._connectionInfo ) {
			throw new Error( "Disposed" );
		}
		let message = {
			to: this._connectionId,
			listenerId: null, //To skip echo
			body
		};
		this._tryMakeCall( "onlineMessage", message, cb );
	}

	dispose( ) {
		if ( this._isDisposing ) {
			return;
		}
		this._isDisposing = true;
		this._callUntilSuccessAsync( cb => this.tryUnpair( cb ) )
			.subscribe( () => {
				this._isDisposing = false;
				if ( !this._isDisposed ) {
					this._off( "onlineMessage", this._gotMessageHandler );
					this._off( "paired", this._pairedHandler );
					this._off( "unpaired", this._unpairedHandler );
				}
				super.dispose();
			} );
	}
}

export default ClientServerOnline;
