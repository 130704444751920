import Rx from "rx";
import _ from "lodash";
import ssgCrypto from "ssg.crypto";

import React from "react";
import ReactDom from "react-dom";
import classNames from "classnames";

import configuration from "../../../common/configuration.js";
import serviceLocator from "../../../api/services/locators/worker.client.js";
import { MESSAGE_TYPE } from "../../../api/models/chat.message.js";
import TopLevel from "../common/top.level.view.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import ImageButton from "../../components/button.image.jsx";
import Translation from "../../components/translation.jsx";
import Button from "../../components/button.jsx";
import Tinput from "../../components/tinput.jsx";
import { ModalItemController } from "../common/modal.menu.jsx";
import Keyboard from "../../components/keyboard.jsx";
import MessageView from "./message.jsx";
import OnlineStatus from "../../components/online.status.jsx";
import AudioRecorder from "../../components/audio.recorder.jsx";
import draftServiceLocator from "../../../api/services/locators/draft.message.js";
import audioDownloader from "../../../api/services/audio.downloader.js";
import ForwardMessage from "./forward.jsx";

import {
	bindStateProperty,
	calculateFingerprintAsync,
	getStatusColor
} from "../../../common/utils";

class ChatFooter extends React.Component {
	constructor() {
		super();
		this.state = {
			visible: true,
			useVirtualKeyboard: false
		};
		this.handleClick = this.handleClick.bind( this );
		this.handleHide = this.handleHide.bind( this );
		this.onRecorded = this.onRecorded.bind( this );
		this._service = serviceLocator();
	}

	handleClick() {
		this.setState( { visible: true } );
	}

	handleHide() {
		this.setState( { visible: false } );
	}

	checkHeight() {
		let newHeight = this.getHeight();
		if ( newHeight !== this.height ) {
			this.height = newHeight;
			this.props.heightChange( newHeight );
		}
	}

	getHeight() {
		let node = ReactDom.findDOMNode( this.refs.keyboard || this );
		return node.getBoundingClientRect().height;
	}

	componentWillMount() {
		this._profileSubscription = (
			this._service.observeProfile().subscribe( ( { useVirtualKeyboard } ) => {
				if ( this.state.useVirtualKeyboard === useVirtualKeyboard ) {
					return;
				}
				this.setState( { useVirtualKeyboard } );
			} )
		);
	}

	componentWillUnmount() {
		this._profileSubscription.dispose();
	}

	componentDidMount() {
		this.checkHeight();
	}

	componentDidUpdate() {
		this.checkHeight();
	}

	renderSendOrVoice() {
		if (this.props.inputValue) {
			return (
				<Button
					className="green extra-small"
					caption="chat.button.send"
					handleClick={ this.props.onSend }
					enabled={ this.props.enabled }
				/>
			);
		}
		if ( !_.get( global, "cordova.plugins.OggOpusPlugin" ) ) {
			return (
				<Button
					className="green extra-small"
					caption="chat.button.send"
					handleClick={ null }
					enabled={ false }
				/>
			);
		}
		return (
			<Button
				className="green extra-small"
				caption="chat.button.voice"
				handleClick={ this.props.onVoice }
				enabled={ this.props.enabled }
			/>
		);
	}

	renderInput() {
		if ( this.state.useVirtualKeyboard ) {
			if (this.props.inputValue) {
				return <Keyboard
					withInput={true}
					{...this.props}
					enabled={true}
					onHide={this.handleHide}
					onButtonClick={ this.props.onSend }
					onButtonPressStart={ null }
					buttonTextId="chat.button.send"
					ref="keyboard"
				/>;
			}

			if ( _.get( global, "cordova.plugins.OggOpusPlugin" ) && !this.props.replySenderPid) {
				return <Keyboard
					withInput={true}
					{...this.props}
					enabled={true}
					onHide={this.handleHide}
					onButtonClick={ null }
					onButtonPressStart={ this.props.onVoice }
					buttonTextId="chat.button.voice"
					ref="keyboard"
				/>;
			}

			return <Keyboard
				withInput={true}
				{...this.props}
				enabled={true}
				onHide={this.handleHide}
				onButtonClick={ null }
				onButtonPressStart={ null }
				buttonTextId="chat.button.send"
				ref="keyboard"
			/>;
		}
		let className = classNames( {
			keyboard: true,
			tooltipPadding: false,
			keyboardsolo: !this.props.withInput
		} );
		return (
			<div className={ className } ref="keyboard">
				<div className="input-form bg-b05">
					{this.renderSendOrVoice()}
				   <Tinput
					   className="message"
					   type="multiline"
					   autofocus={true}
					   value={this.props.inputValue}
					   onChange={this.props.handleInput}
					   firstUppercased={true}
					   placeholderTextId="prompt.default.header"
				  />
				   <div className="clear"></div>
			   </div>
			</div>
		);
	}

	onRecorded(data, milliseconds, mimeType) {
		if (!data) {
			this.props.onVoiceRecorded(null);
		} else {
			this.props.onVoiceRecorded(data, milliseconds, mimeType);
		}
	}

	renderRecording() {
		return (
			<div className="keyboard">
				<div className="bg-b05 recording">
					<AudioRecorder onRecorded={this.onRecorded}/>
				</div>
			</div>
		);
	}

	renderReply() {
		return null;
	}

	render() {
		let props = this.props;
		if (this.props.isRecording) {
			return this.renderRecording();
		}
		if ( this.state.visible ) {
			if (!this.props.replyTo || !this.props.replyTo.replySenderPid) {
				return this.renderInput();
			}
			return (
				<div>
					<div style={{
						width: "100%",
						position: "absolute",
						left: "0px",
						top: "60px",
						zIndex: 100,
						overflow: "visible",
						background: "#444",
						padding: "10px",
						paddingRight: "20px"
					}}
					onClick={this.props.onCancelReply}
						>
						Reply To: {props.replyTo.replyText}
					</div>
					<div style={{
						width: "100%",
						position: "absolute",
						right: "0px",
						top: "60px",
						zIndex: 100,
						overflow: "visible",
						padding: "10px",
						textAlign: "right"
						}}
						onClick={this.props.onCancelReply}
						>X</div>
					{this.renderInput()}
				</div>
			);
		}
		return (
			<footer className="ta-center" onClick={ this.handleClick }>
				<img className="icon keyboard-mini clickable" src="images/icon-keyboard.svg"/>
			</footer>
		);
	}
}

class Fingerprint extends React.Component {
	constructor() {
		super();
		this.state = {
			fingerprint: new Array( 24 ).join( "0" )
		};
	}

	calculateFingerprint() {
		let contact = this.props.contact;
		calculateFingerprintAsync( contact.myPrivateSignatureKey, contact.contactPublicSignatureKey )
			.subscribe( fingerprint => this.setState( { fingerprint } ) );
	}

	componentWillMount() {
		//this.calculateFingerprint();
	}

	render() {
		return (
			<div>
				<div className="label"><Translation textId="chat.fingerprint.label"/>:</div>
				<div className="code-block small">
					<div className="bg-digits">
						888888888888888888888888888
					</div>
					<div className="code">
						{ this.state.fingerprint }
					</div>
				</div>
			</div>
		);
	}
}

class RandomPool extends React.Component {
	constructor() {
		super();
		this.state = {
			randomPool: [ "000000000000000000000000", "000000000000000000000000" ]
		};
		this.stopUpdatePool = this.stopUpdatePool.bind( this );
		this.startUpdatePool = this.startUpdatePool.bind( this );
	}

	componentWillMount() {
		global.document.addEventListener( "pause", this.stopUpdatePool );
		global.document.addEventListener( "resume", this.startUpdatePool );
		this.startUpdatePool();
		this.refreshRandomPool();
	}

	componentWillUnmount() {
		this.stopUpdatePool();
		global.document.removeEventListener( "pause", this.stopUpdatePool );
		global.document.removeEventListener( "resume", this.startUpdatePool );
	}

	isVisible() {
		let poolEl = ReactDom.findDOMNode( this.refs.pool );
		let poolRect = poolEl.getBoundingClientRect();
		return poolRect.bottom > 0; //TODO: should not be always true
	}

	startUpdatePool() {
		if ( this.intervalHandle ) {
			return;
		}
		this.intervalHandle = setInterval( () => {
			if ( this.isVisible() ) {
				this.refreshRandomPool();
			}
		}, 1000 );
	}

	stopUpdatePool() {
		if ( !this.intervalHandle ) {
			return;
		}
		clearInterval( this.intervalHandle );
		this.intervalHandle = 0;
	}

	refreshRandomPool() {
		Rx.Observable.range( 0, 8 )
			.flatMap( () => Rx.Observable.fromPromise( ssgCrypto.getRandomIntInRangeThen( 1000000, 2000000 ) ) )
			.map( i => ( "" + i ).substr( 1 ) )
			.bufferWithCount( 4 )
			.map( groups => groups.join( "" ) )
			.toArray()
			.subscribe( randomPool => this.setState( { randomPool } ) );
	}

	render() {
		let { randomPool } = this.state;
		return (
			<div>
				<div className="label"><Translation textId="chat.randompool.label"/>:</div>
				<div className="code-block random-pool">
					<div className="bg-digits">8888888888888888888888888888<br/>8888888888888888888888888888</div>
					<div className="code" ref="pool">
						{ randomPool[ 0 ] }<br />
						{ randomPool[ 1 ] }
					</div>
				</div>
			</div>
		);
	}
}

class Warning extends React.Component {
	constructor() {
		super();
		this.onClick = this.onClick.bind( this );
	}

	onClick() {
		//contactServiceLocator().authenticateAsync( this.props.contact ).subscribe();
	}

	render() {
		if ( this.props.contact.isAuthenticated ) {
			return null;
		}
		return (
			<div>
				<Translation textId="chat.warning"/>
				<div className="btn transparent gray" onClick={ this.onClick }>
					<Translation textId="chat.warning.button"/>
				</div>
			</div>
		);
	}
}

class ChatHead extends React.Component {
	render() {
		return null;
		return (
			<div>
				<Warning {...this.props} />
				<Fingerprint {...this.props} />
			</div>
		);
	}
}

class UnreadCounterView extends React.Component {
	constructor() {
		super();
		this.state = {
			count: 0
		};
	}

	componentWillMount() {
		this.subscription = (
			serviceLocator()
				.observeUnreadCountExcept( this.props.contact.id )
				.subscribe(
					count => { this.setState( { count } ); }
				)
			);
	}

	componentWillUnmount() {
		this.subscription.dispose();
	}

	render() {
		let {count} = this.state;
		if ( !count ) {
			return null;
		}
		if (count > 100) {
			count = "100+";
		}
		return (
			<div
				className="back-count-sticker"
				data-count={ count }
			/>
		);
	}
}

class ChatView extends React.Component {
	constructor() {
		super();
		this.onSend = this.onSend.bind( this );
		this.onEditMessage = this.onEditMessage.bind( this );
		this.onForwardMessage = this.onForwardMessage.bind( this );
		this.onReplyMessage = this.onReplyMessage.bind( this );
		this._draftService = draftServiceLocator();
		this.state = {
			newMessage: "",
			footerHeight: 0,
			maxReceivedIndex: -1,
			sendingMessages: [],
			editingIndex: null,
			renderMessagesMaxCount: 10,
			rerenderVersion: 0,
			expireDt: null,
			isRecording: false,
			isActive: true
		};
		this.onScroll = this.onScroll.bind( this );
		this.onBackPress = this.onBackPress.bind( this );
		this.onSendMessage = this.onSendMessage.bind( this );
		this.onVoiceMessageRecord = this.onVoiceMessageRecord.bind( this );
		this._receivedMessagesIds = Object.create( null );
		this.onNewMessageChange = this.onNewMessageChange.bind( this );
		this.onVoiceMessageRecorded = this.onVoiceMessageRecorded.bind( this );
		this.onPause = this.onPause.bind( this );
		this.onResume = this.onResume.bind( this );
		this.onCancelReply = this.onCancelReply.bind( this );
	}

	static get propTypes() {
		return {
			onBack: React.PropTypes.func,
			contact: React.PropTypes.object.isRequired
		};
	}

	static get defaultProps() {
		return {
			onBack: _.noop
		};
	}

	setMessagesRead() {
		if ( this.state.isActive ) {
			this._service.setReadAllAsync( this.props.contact ).subscribe();
		}
	}

	componentWillMount() {
		let { contact } = this.props;
		let draft = this._draftService.tryGetDraftForContact( contact.id );
		if ( draft ) {
			this.setState( { newMessage: draft } );
		}

		this._expireDtSubscription = (
			this._service.observeExpireDt().subscribe( expireDt => {
				this.setState( { expireDt } );
			} )
		);

		if ( ( contact.multidescriptionId !== -1 ) && ( contact.multidescriptionId !== undefined ) ) {
			this._wgSubscription = (
				serviceLocator().observeContactList()
					.map( contacts => _.find( contacts, { id: contact.multidescriptionId } ) )
					.filter( multidescription => !!multidescription )
					.subscribe( multidescription => {
						this.setState( { wgname: multidescription.name } );
					} )
			);
		}

		this._messageSubscription = (
			this._messagesObservable
				// .debounce( 300 )
				.subscribe( ( { messages, sending, sendingFiles } ) => {
					if ( this._pendingReadAllTimeout ) {
						clearTimeout( this._pendingReadAllTimeout );
						delete this._pendingReadAllTimeout;
					}
					this.setState( {
						messages: _.clone( messages ),
						sendingMessages: sending,
						sendingFiles: _.values( sendingFiles ),
						rerenderVersion: this.state.rerenderVersion + 1
					} );
					this._lastAsyncMessage = _.findLast( messages, m => m.type !== MESSAGE_TYPE.SERVICE );
				} )
		);
		this._topMessageOffset = 0;
		document.addEventListener( "pause", this.onPause, false );
		document.addEventListener( "resume", this.onResume, false );
	}

	onPause() {
		console.error(new Date, 'onPause');
		this.setState( { isActive: false } );
	}

	onResume() {
		this.setState( { isActive: true } );
		console.error(new Date, 'onResume');
	}

	componentDidMount() {
		this._mute = this._service.muteContactNotification( this.props.contact );
		this.scrollToBottom();
		_.forEach( this.state.messages, message => {
			this.onMessageReceived( message );
			this._receivedMessagesIds[ message.id ] = 1;
		} );
		// this._sendingFilesSubscription = (
		// 	this._service.observeSendingFiles( this.props.contact )
		// 		.subscribe( files => {
		// 			this.setState( {
		// 				sendingFiles:
		// 					_.map( files, ( { name, total, sent, error, thumbnailBase64 } ) => ( {
		// 						sendingFile: "yes",
		// 						name, total, sent, error,
		// 						type: MESSAGE_TYPE.OUTGOING,
		// 						thumbnailBase64
		// 					} ) ),
		// 				rerenderVersion: this.state.rerenderVersion + 1
		// 			} );
		// 		} )
		// );
		this._requestMoreHistory();
	}

	componentWillUpdate( props, state ) {
		let { footerHeight, rerenderVersion } = state;
		if ( this.isAtBottom() ) {
			this.shouldScrollToBottom = true;
			if ( this._pendingReadAllTimeout ) {
				return;
			}
			if ( state.isActive && this.state.rerenderVersion !== rerenderVersion ) {
				this._pendingReadAllTimeout = setTimeout( () => {
					this._service.setReadAllAsync( this.props.contact )
						.subscribe( () => {
							delete this._pendingReadAllTimeout;
						} );
						delete this._pendingReadAllTimeout;
				}, 3000 );
			}
		}
	}

	_getTopIndex( state ) {
		let messageKeys = _.keys( state.messages ).slice( -state.renderMessagesMaxCount );
		return messageKeys[ 0 ] || 0;
	}

	componentDidUpdate( prevProps, prevState ) {
		if ( this.shouldScrollToBottom ) {
			this.scrollToBottom();
			this.shouldScrollToBottom = false;
		}
		let scrollNode = ReactDom.findDOMNode( this.refs.scroll );
		if ( scrollNode.scrollTop === 0
			|| ( scrollNode.scrollHeight <= scrollNode.clientHeight ) ) {
			this._requestMoreHistory();
		}

		let topIndex = this._getTopIndex( this.state );
		let prevTopIndex = this._getTopIndex( prevState );

		if ( topIndex !== prevTopIndex ) {
			let label = this.refs.messages.getMessageElement( prevTopIndex );
			if ( label ) {
				label = ReactDom.findDOMNode( label );
				this.refs.scroll.scrollTop += label.offsetTop - this._topMessageOffset;
			}

			let newLabel = this.refs.messages.getMessageElement( topIndex );
			if ( newLabel ) {
				newLabel = ReactDom.findDOMNode( newLabel );
				this._topMessageOffset = newLabel.offsetTop;
			} else {
				this._topMessageOffset = 0;
			}
		}
//Call onMessageReceived for new messages
		_.forEach( this.state.messages, message => {
			if ( this._receivedMessagesIds[ message.id ] ) {
				return;
			}
			this.onMessageReceived( message );
			this._receivedMessagesIds[ message.id ] = 1;
		} );
	}

	componentWillUnmount() {
		clearTimeout( this._pendingReadAllTimeout );
		delete this._pendingReadAllTimeout;
		if ( this.state.newMessage ) {
			this._draftService.setDraftForContact(
				this.props.contact.id, this.state.newMessage
			);
		} else {
			this._draftService.dropDraftForContact( this.props.contact.id );
		}
		this._mute && this._mute.dispose();
		this._expireDtSubscription && this._expireDtSubscription.dispose();
		this._wgSubscription && this._wgSubscription.dispose();
		this._messageSubscription.dispose();
		this._sendingFilesSubscription && this._sendingFilesSubscription.dispose();
	}

	isAtBottom() {
		let node = ReactDom.findDOMNode( this.refs.scroll );
		if ( !node ) {
			return true;
		}
		return node.scrollTop >= node.scrollHeight - node.clientHeight - 100;
	}

	scrollToBottom() {
		let node = ReactDom.findDOMNode( this.refs.scroll );
		if ( !node ) {
			return;
		}
		node.scrollTop = node.scrollHeight - node.clientHeight;
	}

	onMessageReceived( message ) {
		let { index, id } = message;
		this.removeSendingMessage( id );
		if ( this.state.maxReceivedIndex < index ) {
			this.setState( {
				maxReceivedIndex: index,
				rerenderVersion: this.state.rerenderVersion + 1
			} );
		}
		if ( this.isAtBottom() ) {
			setTimeout( () => this.scrollToBottom(), 0 );
		}
	}

	removeSendingMessage( messageId ) {
		if ( _.find( this.state.sendingMessages, {id: messageId} ) ) {
			this.setState( {
				sendingMessages: _.filter(
					this.state.sendingMessages,
					( { id } ) => id !== messageId
				),
				rerenderVersion: this.state.rerenderVersion + 1
			} );
		}
	}

	onEditMessage( message ) {
		this.setState( {
			editingIndex: message.replaceIndex === undefined ? message.index : message.replaceIndex,
			newMessage: message.text,
			replyIndex: null,
			replyText: null,
			replySenderPid: null
		} );
		this.refs.footer && this.refs.footer.handleClick(); //set keyboard visible
	}

	onReplyMessage( message ) {
		this.setState( {
			replyIndex: message.replaceIndex === undefined ? message.index : message.replaceIndex,
			replyText: message.text.length > 100 ? message.text.substr(0, 100) : message.text,
			replySenderPid: message.senderPid,
			editingIndex: null,
			newMessage: ""
		} );
		setTimeout( () => {
			this.refs.footer && this.refs.footer.handleClick(); //set keyboard visible
		}, 1);
	}

	onCancelReply() {
		this.setState( {
			replyIndex: null,
			replyText: null,
			replySenderPid: null
		} );
	}

	onForwardMessage( forwardingMessage ) {
		this.setState( { forwardingMessage } );
	}

	renderMenu() {
		throw new Error( "Not implemented" );
	}

	addSendingMessage( message ) {
		this.setState( {
			sendingMessages: this.state.sendingMessages.concat( [ message ] ),
			rerenderVersion: this.state.rerenderVersion + 1
		} );
	}

	onScroll( event ) {
		if ( event.target.scrollTop === 0 ) {
			this._requestMoreHistory();
		}
	}

	_requestMoreHistory() {
		let { messages, renderMessagesMaxCount } = this.state;
		let msgCount = _.keys( messages ).length;
		if ( renderMessagesMaxCount < msgCount ) {
			this.setState( { renderMessagesMaxCount: renderMessagesMaxCount + 10 } );
			return;
		}
		if ( renderMessagesMaxCount === msgCount ) {
			this.setState( { renderMessagesMaxCount: msgCount + 10 } );
		}
		this._service.queryHistoryAsync( this.props.contact ).subscribe();
	}

	onNewMessageChange( newMessage ) {
		if ( !newMessage ) {
			this.setState( {
				editingIndex: null,
				newMessage
			} );
			return;
		}
		this.setState( { newMessage } );
	}

	onSendMessage( isCanceled ) {
		let { editingIndex, messages, newMessage } = this.state;
		let { contact } = this.props;
		let isEditing = ( editingIndex !== null ) && messages[ editingIndex ];

		if ( !isEditing ) {
			this.onSend();
			return;
		}
		this.setState( {
			editingIndex: null,
			newMessage: ""
		} );
		if ( isCanceled ) {
			return;
		}
		this._service.sendEditTextMessageAsync(
			contact,
			editingIndex,
			newMessage
		)
			.subscribe();
	}

	renderDisconnectedFooter() {
		return (
			<div
				className="small-text"
				style={{
					position: "relative",
					top: "-100px",
					left: 0,
					background: "#000",
					marginLeft: 0,
					textIndent: 0,
					opacity: 1,
					height: "100px"
				}}
			>
				<Translation textId="contact.status.disconnected.explanation" />
			</div>
		);
	}

	renderExpiredFooter() {
		return (
			<div
				className="small-text"
				style={{
					position: "relative",
					top: "-100px",
					left: 0,
					background: "#000",
					marginLeft: 0,
					textIndent: 0,
					opacity: 1,
					height: "100px"
				}}
			>
				<Translation textId="chat.expired.explanation" />
			</div>
		);
	}

	onVoiceMessageRecord() {
		this.setState( { isRecording: true } );
	}

	onVoiceMessageRecorded( ui8Array, milliseconds, mimeType ) {
		let { contact } = this.props;
		this.setState( { isRecording: false } );
		if ( !ui8Array || milliseconds < 1000 ) {
			return;
		}
		ssgCrypto.createRandomBase64StringThen( 32 ).then( messageId => {
			audioDownloader.setCachedAudio( messageId, Promise.resolve( ui8Array ) );
			this._service.sendVoiceMessageAsync( contact, ui8Array, milliseconds, mimeType, messageId ).subscribe();
		} );
	}

	renderFooter() {
		let { newMessage, expireDt } = this.state;
		let { contact } = this.props;
		if ( contact.status === "disconnected" ) {
			return this.renderDisconnectedFooter();
		}
		if ( configuration.getUserExpiration() && expireDt && expireDt < +new Date ) {
			return this.renderExpiredFooter();
		}

		let { editingIndex, messages, isRecording, replyText, replyIndex, replySenderPid } = this.state;
		let isEditing = ( editingIndex !== null ) && messages[ editingIndex ];
		return (
			<ChatFooter
				inputValue={ newMessage }
				handleInput={ this.onNewMessageChange }
				onSend={ this.onSendMessage }
				onVoice={ this.onVoiceMessageRecord }
				onVoiceRecorded={ this.onVoiceMessageRecorded }
				heightChange={ bindStateProperty( this, "footerHeight" ) }
				isEditing={ isEditing }
				replyTo={ {replyText, replyIndex, replySenderPid} }
				onCancelReply={ this.onCancelReply }
				isRecording={ isRecording }
				ref="footer"
			/>
		);
	}

	renderName() {
		let { contact } = this.props;
		let { wgname } = this.state;
		if ( !wgname ) {
			return <span className="header-caption">{contact.name}</span>;
		}
		return <span className="header-caption">
				{contact.name}
				<sup> ({wgname})</sup>
			</span>;
	}

	onBackPress() {
		if ( this.state.editingIndex !== null ) {
			this.setState( { editingIndex: null, newMessage: "" } );
			return;
		}
		this.props.onBack();
	}

	onSend() {
		let {newMessage, replyIndex, replyText, replySenderPid} = this.state;
		let text = _.trim( newMessage );

		this._service
			.sendTextMessageAsync(
				this.props.contact,
				text,
				replySenderPid && {replyIndex, replyText, replySenderPid}
			)
			.subscribe();
		this.setState( {
			newMessage: "",
			replyText: null,
			replyIndex: null,
			replySenderPid: null
		} );
	}

	renderForwardDialog() {
		if ( !this.state.forwardingMessage ) {
			return null;
		}
		return <ForwardMessage
			contact={ this.props.contact }
			message={ this.state.forwardingMessage }
			onBack={ () => { this.setState( { forwardingMessage: null } ); } }
		/>;
	}

	render() {
		let { newMessage, footerHeight } = this.state;
		let { contact } = this.props;
		if ( contact.status === "disconnected" ) {
			footerHeight = "100px";
		}
		return (
			<TopLevel>
				<header className="small">
					<ModalItemController>
						<ImageButton type="icon-hamburger"/>
						{ this.renderMenu() }
					</ModalItemController>
					<ImageButton type="icon-arrow-left" onClick={this.onBackPress}/>
					{ this.renderName() }
					<UnreadCounterView contact={ contact } />
					<OnlineStatus/>
				</header>
				<main ref="scroll" style={ { paddingBottom: footerHeight } } onScroll={ this.onScroll } >
					<ChatHead { ...this.props }/>
					<Messages
						ref="messages"
						messages={ this.state.messages }
						sendingMessages={ this.state.sendingMessages }
						sendingFiles={ this.state.sendingFiles }
						renderMessagesMaxCount={ this.state.renderMessagesMaxCount }
						contact={this.props.contact }
						onEditMessage={ this.onEditMessage }
						onForwardMessage={ this.onForwardMessage }
						onReplyMessage={ this.onReplyMessage }
						editingIndex={ this.state.editingIndex }
						rerenderVersion={ this.state.rerenderVersion }
					/>
				</main>
				{ this.renderFooter() }
				{ this.renderForwardDialog() }
				<DeviceBackButton onPress={ this.onBackPress }/>
			</TopLevel>
		);
	}
}

class Messages extends React.Component {
	getMessageElement( index ) {
		return this.refs[ "msg" + index ];
	}

	shouldComponentUpdate( { rerenderVersion, editingIndex, renderMessagesMaxCount } ) {
		let shouldUpdate = (
			rerenderVersion !== this.props.rerenderVersion
			|| editingIndex !== this.props.editingIndex
			|| renderMessagesMaxCount !== this.props.renderMessagesMaxCount
		);
		return shouldUpdate;
	}

	renderMessages() {
		let { messages, sendingMessages, sendingFiles, renderMessagesMaxCount,
			contact, editingIndex, onEditMessage, onForwardMessage, onReplyMessage } = this.props;
		let messageKeys = _.keys( messages ).slice( -renderMessagesMaxCount );

		return 	_.map( messageKeys, ( position ) => {
			let message = messages[ position ];
			if ( !message ) { return null; }
			let { index } = message;
			let key = `${index}_${position}`;
			let messageView = <MessageView
				message={ message }
				key={ key }
				contact={ contact }
				ref={ index === ( index| 0 ) ? "msg" + index : undefined }
				onEdit={ onEditMessage }
				onForward={ onForwardMessage }
				onReply={ onReplyMessage }
			/>;
			if ( ( editingIndex === message.index )
				|| ( editingIndex === message.replaceIndex ) ) {
				return <div key={ key } className="message-edit">{ messageView }</div>;
			}
			return messageView;
		} );
	}

	renderSendingMessages() {
		return this.props.sendingMessages.map( ( message, position ) => {
			if ( _.find( this.props.messages, { id: message.id } ) ) {
				return null;
			}
			return <MessageView
				status="queued"
				message={ message }
				key={ `unsent_${position}` }
				contact={ this.props.contact }
			/>;
		} );
	}

	renderSendingFiles() {
		if ( !this.props.sendingFiles ) {
			return null;
		}
		return (
			this.props.sendingFiles.map( ( message, position ) => {
				return <MessageView
					status="queued"
					message={ message }
					key={ `unsentfile_${position}` }
					contact={ this.props.contact }
				/>;
			} )
		);
	}

	render() {
		let {messages, sendingMessages, sendingFiles} = this.props;
		if ( !messages ) {
			return <div className="message-list"><span/></div>;
		}
		let isEmpty = (
			_.isEmpty( messages )
			&& _.isEmpty( sendingMessages )
			&& _.isEmpty( sendingFiles )
		);
		if ( isEmpty ) {
			return (
				<div className="message-list">
					<Translation textId="chat.empty.text" />
				</div>
			);
		}
		return (
			<div className="message-list">
				{ this.renderMessages() }
				{ this.renderSendingMessages() }
				{ this.renderSendingFiles() }
			</div>
		);
	}
}

export default ChatView;
