import React from "react";
import _ from "lodash";

import {sortContacts} from "../../../common/utils.js";

import WithFooterView from "../common/with.footer.view.jsx";
import GroupList from "./group.list.jsx";
import Footer from "../common/footer.jsx";
import Translation from "../../components/translation.jsx";
import AddGroupView from "./add.group.jsx";
import OnlineStatus from "../../components/online.status.jsx"
import SearchContacts from "../common/search.contacts.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

class GroupsView extends React.Component {
	constructor() {
		super();
		this.addGroupClick = this.addGroupClick.bind( this );
		this.addGroupClose = this.addGroupClose.bind( this );
		this.renderContactsSublist = this.renderContactsSublist.bind( this );
		this.state = { groups: null };
		this._service = serviceLocator();
	}

	componentWillMount() {
		this.subscription = (
			this._service
				.observeGroupList()
				.subscribe( groups => {
					sortContacts( groups );
					this._setGroups( groups );
				} )
		);
	}

	componentWillUnmount() {
		this.subscription.dispose();
	}

	_setGroups( groups ) {
		this.setState( { groups } );
	}

	addGroupClick() {
		this.setState( { showAddGroup: true } );
	}

	addGroupClose() {
		this.setState( { showAddGroup: false } );
	}

	renderContactsSublist( searchString ) {
		let filterFunc = ( { name, metaState } ) => {
			if ( ~name.toLowerCase().indexOf( searchString ) ) {
				return true;
			}
			return false;
			// if ( !metaState || !metaState.ps ) {
			// 	return false;
			// }
			// return _.some(
			// 	metaState.ps,
			// 	( { nickname } ) => ~nickname.toLowerCase().indexOf( searchString )
			// );
		};

		let groups = _.filter( this.state.groups, filterFunc );
		if ( !groups.length ) {
			return (
				<span className="small-text">
					<br />
					<Translation textId="groups.search.empty" />
				</span>
			);
		}
		return <GroupList items={ groups } />;
	}

	renderGroupListOrNull() {
		if ( !this.state.groups ) {
			return null;
		}
		return <GroupList items={ this.state.groups } />;
	}

	render() {
		return (
			<WithFooterView>
				<header>
					<img
						src="images/icon-add-group.svg"
						className="icon add-group f-right"
						onClick={ this.addGroupClick }
					/>
					<Translation textId="groups.header" />
					<SearchContacts
						onRenderContacts={this.renderContactsSublist}
					/>
					<OnlineStatus/>
				</header>
				<main>{ this.renderGroupListOrNull() }</main>
				<Footer active="groups" />
				{
					this.state.showAddGroup
					? <AddGroupView onClose={this.addGroupClose} />
					: null
				}
			</WithFooterView>
		);
	}
}

export default GroupsView;
