import React from "react";
import classNames from "classnames";

import PromptContacts from "../common/prompt.contacts.jsx";
import Translation from "../../../components/translation.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class AddSharedContactsContentView extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedContactIds: []
		};
		this.onNextClick = this.onNextClick.bind( this );
		this.onChange = this.onChange.bind( this );
		this._service = remoteServiceLocator();
	}

	componentWillMount() {
		this._subscription = (
			this._service.observeContactList()
				.subscribe( contacts => {
					this.setState( {contacts} );
				} )
		);
	}

	componentWillUnmount() {
		this._subscription.dispose();
	}

	onNextClick() {
		if ( !this.state.selectedContactIds.length ) {
			return;
		}
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( {isInProgress: true} );
		this._service.shareContactsAsync(
			this.state.selectedContactIds,
			this.props.contact.id
		).subscribe( () => {
			this.setState( {isInProgress: false} );
			this.props.onDone();
		} );
	}

	onChange( selectedContactIds ) {
		if ( this.state.isInProgress ) {
			return;
		}
		this.setState( {selectedContactIds} );
	}

	render() {
		let nextClassName = classNames( "btn btn-green", {
			disabled: !this.state.selectedContactIds.length || this.state.isInProgress
		} ); 
		let {className} = this.props;
		className = classNames( className, "noflex" );
		return (
			<div className={className}>
				<div className="invite">
					<PromptContacts
						contacts={this.state.contacts}
						onChange={this.onChange}
						selectedIds={this.state.selectedContactIds}
						/>
					<button
						className={nextClassName}
						onClick={this.onNextClick}>
						<Translation textId="web.sharedcontacts.add.next" />
					</button>
				</div>
			</div>
		);
	}

	static get propTypes() {
		return {
			contact: React.PropTypes.object.isRequired,
			onDone: React.PropTypes.func.isRequired,
			className: React.PropTypes.string
		};
	}
}

export default AddSharedContactsContentView;
