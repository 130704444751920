import React from "react";
import _ from "lodash";
import { ButtonToolbar, SplitButton, MenuItem, Dropdown } from "react-bootstrap";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";
import Translation from "../../../components/translation.jsx";

class ChatsMainTitleView extends React.Component {
	renderSendContacts() {
		let {contact, onSendContacts} = this.props;
		let showContactMenuItems = contact && ( contact.status === "active" ) && onSendContacts;
		if ( !showContactMenuItems ) {
			return null;
		}
		return (
			<MenuItem eventKey="1" onClick={this.props.onSendContacts}>
				<Translation textId="web.contacts.menu.send.contacts" />
			</MenuItem>
		);
	}

	renderClearHistory() {
		let {contact, onClearHistory} = this.props;
		let showContactMenuItems = contact && ( contact.status === "active" ) && onClearHistory;
		if ( !showContactMenuItems ) {
			return null;
		}

		return (
			<MenuItem eventKey="2" onClick={onClearHistory}>
				<Translation textId="web.contacts.menu.clear.history" />
			</MenuItem>
		);
	}

	renderClearRemoteHistory() {
		let {contact, onClearRemoteHistory} = this.props;
		let showContactMenuItems = contact && ( contact.status === "active" )
			&& onClearRemoteHistory && ~contact.multidescriptionId;

		if ( !showContactMenuItems ) {
			return null;
		}

		return (
			<MenuItem eventKey="3" onClick={this.props.onClearRemoteHistory}>
				<Translation textId="web.contacts.menu.clear.remotehistory" />
			</MenuItem>
		);
	}

	renderMenu() {
		let { contact } = this.props;
		if ( !contact ) {
			return null;
		}
		if ( contact.type === "group" ) {
			return this.renderGroupMenu( contact );
		}
		return this.renderContactMenu( contact );
	}

	renderContactMenu( contact ) {
		if ( contact.status !== "active" ) {
			return null;
		}
		return (
			<ul
				className="nav navbar-nav navbar-right right"
				style={ { marginTop: 0 } }
			>
				<Dropdown id="contacts_dropdown" componentClass="li">
					<Dropdown.Toggle noCaret={true} useAnchor={true}>
						<img src="web/img/menu_2_moreoptions.svg" alt="" />
					</Dropdown.Toggle>
					<Dropdown.Menu className="dropdown-menu dropdown-nav-menu" bsClass="">
						{ this.renderSendContacts() }
						{ this.renderClearHistory() }
						{ this.renderClearRemoteHistory() }
					</Dropdown.Menu>
				</Dropdown>
			</ul>
		);
	}

	renderGroupMenu( contact ) {
		if ( contact.status === "failed" ) {
			return null;
		}

		//Paste from groups/main.title.jsx
		return (
			<ul
				className="nav navbar-nav navbar-right right"
				style={ { marginTop: 0 } }
			>
				<Dropdown id="contacts_dropdown" componentClass="li">
					<Dropdown.Toggle noCaret={true} useAnchor={true}>
						<img src="web/img/menu_2_moreoptions.svg" alt="" />
					</Dropdown.Toggle>
					<Dropdown.Menu className="dropdown-menu dropdown-nav-menu" bsClass="">
						<MenuItem eventKey="2" onClick={this.props.onClearHistory}>
							<Translation textId="web.contacts.menu.clear.history" />
						</MenuItem>
						<MenuItem eventKey="3" onClick={this.props.onParticipants}>
							<Translation textId="web.group.menu.participants" />
						</MenuItem>
						<MenuItem eventKey="4" onClick={this.props.onExternal}>
							<Translation textId="web.group.menu.external" />
						</MenuItem>
					</Dropdown.Menu>
				</Dropdown>
			</ul>
		);
	}

	render() {
		let {contact} = this.props;
		return (
			<div className="navbar grow">
				<div id="navbar" className="navbar-collapse">
					<img
						className="hidden visible-xs-inline back-button"
						src="web/img/arrow2.svg"
						alt=""
						onClick={ this.props.onGoToList }
					/>
					<div className="name">
						<ContactName contact={contact} key={(contact && contact.id)||"empty"}/>
					</div>
					{this.renderMenu()}
				</div>
			</div>
		);
	}

	static get propTypes() {
		return {};
	}
}

export class ContactName extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentWillMount() {
		let {contact} = this.props;
		if ( !contact ) {
			return;
		}
		if ( ( contact.multidescriptionId !== -1 ) && ( contact.multidescriptionId !== undefined ) ) {
			this._subscription = (
				remoteServiceLocator().observeSharedContacts()
					.map( contacts => _.find( contacts, ({id}) => id === contact.multidescriptionId ) )
					.filter( multidescription => !!multidescription )
					.subscribe( multidescription => {
						this.setState( { wgname: multidescription.name } );
					} )
			);
		}
	}

	componentWillUnmount() {
		this._subscription && this._subscription.dispose();
	}

	render() {
		let {contact} = this.props;
		if ( !contact ) {
			return <span></span>;
		}
		if ( !this.state.wgname ) {
			return <span>{contact.name}</span>;
		}
		return (
			<span>
				{contact.name}
				{" "}({this.state.wgname})
			</span>
		);
	}
}
export default ChatsMainTitleView;
