import React from "react";
import TranslationBase from "../abstract/translation.base.js";

class TranslationUkr extends TranslationBase {
  constructor () {
    super ( "uk", "Українська", {
             "empty": "",
             "logout.soon":                               "Час вводу паролю спливає",
             "voice.recording.text":                      "Записую аудіо лист.",
       		 	 "voice.recording.cancel":                    "Скасувати",
       			 "voice.recording.send":                      "Відправити",
             "voice.recording.init":                      "Підготовка",
       			 "voice.recording.recorded":                  "Запис закінчено",
             "voice.recording.timeout":                   msLeft => `Ваше повідомлення буде відправлено автоматично через ${ ( msLeft / 1000 ) | 0 } секунд`,
             "voice.message.unsupported":                 "Нажаль голосові повідомлення не підтримуються на цьому пристрої",
             "voice.recording.error.return":              "Повернутися до листування",
             "warn.expiring.header": "Підходить час оплати",
             "warn.expiring.text": days => `У Вас залишилось менше чем ${days} днів, Щоби проплатити за Користування ${process.env.APP_NAME}. Активувати зараз?`,
             "warn.expiring.button": "Продовжиті",
             "warn.expired.header": "Час Сплати вичерпана",
             "warn.expired.text": `Будь ласка, сплатіть за Користування ${process.env.APP_NAME}. Поки що Ви НЕ маєте можливість створювати ПОВІДОМЛЕННЯ.`,
             "warn.expired.button": "Продовжити",
             "notification.title": `${process.env.APP_NAME} працює в фоновому режимі`,
             "registration.header": "Перед початком роботи",
             "registration.text": "Важливо: введені дані НЕ передаються на сервер, а зберігаються на Вашому пристрої в зашифрованому стані",
             "upgrade.header": "Повна версія",
       			 "upgrade.text": "",
             "upgrade.password.placeholder":              "ПАРОЛЬ(не обов'язково)",
       			 "upgrade.password.tooltip":                  <span>Пароль від Вашого додаткового облікового запису</span>,
       			 "upgrade.fake.checkbox":                     "У Вас є додатковий обліковий запис?",
       			 "upgrade.fake.text":                         "",
        		 "upgrade.continue.button":                   "ПРОДОВЖИТИ",
             "invite.code.placeholder": "Код запрошення",
             "invite.code.paste.button": "Вставити код запрошення",
             "registration.main.nickname.placeholder": "Ім 'я",
             "registration.main.nickname.tooltip": "Так Вас будут Бачити співрозмовнікі",
             "registration.main.password.placeholder": "Пароль",
             "registration.main.password.tooltip": <span> Пароль повинен містити комбінацію будь - якіх символів з доступних розкладок Клавіатури </span>,
             "registration.fake.checkbox": "Додатковий акаунт",
             "registration.fake.text": `${process.env.APP_NAME} надає можливість сховати дійсне листування, коли це необхідно. Для цього Ви можете створити додатковий обліковий запис, який НЕ буде містити компрометуючі дані, та занеобхідне Ви можете Показати його`,
             "registration.fake.warning": <span> УВАГА! <br/> Використовуйте Різні паролі для основного та додаткового облікового запису! </span>,
             "registration.continue.button": "Продовжити",
             "registration.skip.button": "Пропустити",
             "registration.restore.button": "Відновити резервну копію",
             "registration.selection.header": "Тип реєстрації",
             "registration.selection.text": <span>
             Всі дані (повідомлення, логіні, паролі) забезпечені надійним шифруванням, можливість їх дешифрування сервером {process.env.APP_NAME} відсутня.
             Нам доступні лише метадані а самє - інформація відносно факту відсілання повідомлення адресату (але не самі повідомлення).
             <br />
                           Метадані - необхідний мінімум інформації, необхідний для вказання адресату.
             <br />
                           Ви можете використовувати власний сервер для роботи з {process.env.APP_NAME}. У такому випадка доступ до метадані будете мати лише Ви.
             </span>,
             "registration.selection.demo.button": "Демонстраційний режим",
             "registration.selection.public.button": "Публічній сервер",
             "registration.selection.private.button": "По коду запрошення",
             "demo.expired.text":                         "Демонстраційний режим закінчився. Необхідно сплатити.",
       			 "demo.dialog.text":                          days =>
                                            !days ? "Демонстраційна версія перестане працювати незабаром. Активувати зараз?" :
       																			( days % 10 === 1 && days !== 11 ) ? `Демонстраційна версія перестане працювати через ${days} добу. Активувати зараз?` :
       																			( days % 10 <= 4 && (days/10)|0 !== 1 ) ? `Демонстраційна версія перестане працювати через ${days} доби. Активувати зараз?` :
       																			`Демонстраційна версія перестане працювати через ${days} діб. Активувати зараз?`,
             "demo.dialog.title":                         "Демонстраційний режим",
      			 "demo.dialog.upgrade":                       "Активувати",
      			 "demo.dialog.close":                         "Закрити",
             "demo.dialog.complete":                      `Активація успішна. Тепер ви користуєтесь повною версією ${process.env.APP_NAME}`,
             "restore.do.button": "Відновіті",
             "restore.header": "Відновлення",
             "restore.text": "",
             "restore.code.placeholder": "Введіть код відновлення",
             "restore.code.error": "Невірний код відновлення",
             "restore.code.tooltip": "Вставте код відновлення з буферу обміну або використуйте QR- сканування",
             "restore.code.paste.button": "Вставити код з буфера обміну",
             "restore.progress": "Відновлення запису # {0} з {1}",
             "rejoin.header": "Захист даних",
             "rejoin.text": `Для забезпечення надійного захисту облікового запису ${process.env.APP_NAME} системою буде проведена заміна довгострокового кріптографічніх ключів. Для початку процеса введіть пароль від основного, та при наявності додаткового акаунтів`,
             "rejoin.main.password.placeholder": "Пароль від основного акаунта",
             "rejoin.main.password.tooltip": "Пароль від основного акаунта",
             "rejoin.fake.password.placeholder": "Пароль від додаткового акаунта",
             "rejoin.fake.password.tooltip": "Пароль від додаткового акаунта",
             "rejoin.main.warning": "Пароль від основного акаунта введено невірно",
             "rejoin.fake.warning": "Пароль від додаткового акаунта введено невірно",
             "rejoin.equals.warning": "Паролі від основного та додаткового акаунтів співпадають. Будь ласка, введіть інший пароль",
             "rejoin.do.button": "Продовжити",
             "login.header": "Вхід",
             "login.password.placeholder": "Пароль",
             "login.password.tooltip": "Введіть пароль",
             "login.continue.button": "Продовжити",
             "login.invalidpassword": "Пароль введено невірно",
             "invite.duplicate": "У Вас Вже є контакт з таким ім'ям",
             "nickname.empty": "Поле вводу не может буті порожнім",
             "shake.header": "Ключі ...",
             "shake.text": <span>
             - Шифрування інформації відбувається з використанням випадкових даних, що збираються {process.env.APP_NAME}
                   <br />
                  Рух телефоном дозволить згенерувати унікальний ключ шифрування
                  для Вашого облікового запису
              </span>,
             "contact.status.disconnected.explanation": "Акаунт співрозмовника був видалений або контакт загального типу був доданий двічі",
             "chat.expired.explanation": `Будь ласка, оплатіть використання ${process.env.APP_NAME}`,
             "contact.status.disconnected": "Вилучений",
             "contact.status.invited": "Запрошений",
             "contact.status.joining": "Чекаємо прийняття запрошення",
             "contact.status.online": "В мережі",
             "contact.status.failed": <span style={{color: "red"}}> Помилка </span>,
             "contact.status.offline": "Не в мережі",
             "contact.failed.message": "При додаванні контакту виникла помилка. Для її усунення видаліть контакт і створіть його заново. Рекомендуємо повідомити про цю помилку адміністратору",
             "footer.contacts": "Контакти",
             "footer.groups": "Групи",
             "footer.options": "Налаштування",
             "contacts.header": "Контакти",
             "groups.header": "Групи",
             "options.header": "Налаштування",
             "options.nickname": "ІМ'Я",
             "options.password": "ПАРОЛЬ",
             "options.language": "МОВА",
             "options.web": "ВЕБ - ВЕРСІЯ",
             "options.cleanuptime": "АВТОШРЕДЕР",
             "options.pin": <span style={{fontSize: "80%"}}>ЗАПИТ ПАРОЛЯ</span>,
             "options.delete": <span style={{fontSize: "80%"}}>ВИДАЛИТИ ОБЛІКОВИЙ ЗАПИС</span>,
             "options.updates": "ОНОВЛЕННЯ",
             "options.autoclean.header": "Автошредер",
             "options.autoclean.smalltext": "Повідомлення будуть видалятися після прочитання через цей проміжок часу",
             "options.autoclean.no": "ні",
             "options.autoclean.unit.no": "-",
             "options.pin.no": "ні",
             "options.pin.header": "Запит пароля",
             "options.pin.smalltext": "Запит пароля через деякий час бездіяльності. Це покращує безпеку.",
             "options.autoclean.unit.sec": "сек.",
             "options.autoclean.unit.min": "хв.",
             "options.autoclean.unit.hour": number => number === 1? "година": (number && number <= 4? "години": "годин"),
             "options.autoclean.unit.day": number => number === 1? "доба": (number && number <= 4? "доби": "діб"),
             "options.autoclean.unit.year": number => number === 1? "рік": (number && number <= 4? "роки": "років"),
             "options.autoclean.continue.button": "Встановити",
             "options.autoclean.notset": "Не встановлено",
             "options.backup": "РЕЗЕРВНА КОПІЯ",
             "options.notifications": "СПОВІЩЕННЯ",
             "options.keyboard": <span style={{fontSize: "80%"}}>СВОЯ КЛАВІАТУРА</span>,
             "options.upgrade": "АКТИВАЦІЯ",
             "invite.nickname.tooltip": "Ім'я контакту у Вашому списку контактів",
             "invite.nickname.placeholder": "Ім'я контакту",
             "invite.token.tooltip": "Скопіювати і відправити код запрошення",
             "invite.token.placeholder": "Код запрошення",
             "invite.header": "Запрошення",
             "invite.copytoast": "Код запрошення скопійований в буфер обміну",
             "invite.qr.bigtext": "QR- код запрошення",
             "invite.qr.smalltext": "- Покажіть цей QR- код співрозмовнику для сканування (Меню,` Прийняти запрошення `)",
             "invite.plain.text": "- Ви можете поділитися текстової версією коду запрошення",
             "inviteUrl.plain.text": `- Ви можете поділитися так само відправити посилання, щоб ваш співрозмовник використовував веб - браузер для листування з вами. Це корисно, якщо у співрозмовника не встановлено ${process.env.APP_NAME}, але будьте уважні: посилання одноразова.`,
             "invite.button.generate": "Запросити",
             "invite.button.copy": "Скопіювати в буфер",
             "invite.create.text": "- Щоб додати контакт створіть запрошення",
             "invite.create.external.text":               `— Щоб додати зовнішний контакт створіть запрошення. У результаті буде створено посилання, за яким Ваш співрозмовник зможе зайти за допомогою браузера та анонімно з Вами листуватися. Це корисно в тому випадку , якщо у вашого співрозмовника не встановлено ${process.env.APP_NAME}.`,
             "invite.hide.checkbox": "Не показувати Ваше ім'я в цьому запрошенні",
             "sharecontacts.header": "Спільний вміст",
             "chat.button.send": "Надіслати",
             "chat.button.voice":                         "Голос",
             "chat.menu.clear": "Очистити чат",
             "chat.menu.clear.remote": "Очистити чат співрозмовника",
             "chat.menu.password": "Встановити пароль",
             "chat.menu.delete": "Видалити контакт",
             "chat.menu.rename": "Перейменувати контакт",
             "chat.menu.renamegroup": "Перейменувати групу",
             "chat.menu.send.contacts": "Відправити контакти",
             "chat.menu.send.files": "Відправити файли",
             "chat.menu.send.photo": "Відправити фото",
             "chat.clear.confirm": "Очистити чат?",
             "chat.clear.confirm.button.yes": "Очистити",
             "chat.clear.confirm.remote": "Очистити чат у співрозмовника?",
             "chat.delete.confirm": "Видалити цей контакт?",
             "chat.fingerprint.label": "Відбиток",
             "chat.randompool.label": "Random pool",
             "chat.warning": <div className="warning"> Перевірка коду <br/> на відповідність </div>,
             "chat.warning.button": "Натисніть після перевірки",
             "chat.group.menu.clear": "Очистити чат",
             "chat.group.menu.delete": "Вийти",
             "chat.group.menu.invite": "Додати учасників",
             "chat.group.menu.invite.external": "Зовнішній учасник",
             "chat.group.menu.participants": "Учасники групи",
             "chat.group.delete.confirm": "Після виходу вся історія листування в цій групі буде видалена. Чи підтверджуєте вихід?",
             "chat.group.delete.confirm.button.yes": "Вийти",
             "chat.group.invites.notification": "Користувачі, які не вступили групу, не отримають Ваше повідомлення:",
             "chat.group.empty":                          "Нема кому писати. Всі вийшли або ніхто не заходив до групи крім Вас. Додайте учасників для спілкування.",
             "chat.empty.text": "У Вас немає повідомлень в цьому чаті",
             "chat.message.delete": "Видалити",
             "chat.message.edit": "Редагувати",
             "chat.message.reply": "Відповісти",
             "chat.message.forward": "Переслати",
             "chat.message.edited.text": "Відредаговано",
             "chat.message.deleted.text": "Повідомлення видалено",
             "chat.message.file.text": "Відправлений файл:",
             "chat.message.contact.outgoing.text": "Контакт був відправлений користувачеві",
             "chat.message.contact.incoming.text": "Отримано контакт",
             "chat.message.contact.accept.button": "Додати контакт",
             "chat.message.contact.accept.failed": "При додаванні контакту виникла помилка. Попросіть користувача відправити його заново",
             "chat.message.workgroup.incoming.text": "Отримано запрошення до Робочої групи",
             "chat.message.workgroup.outgoing.text": "Запрошення в Робочу групу відправлено",
             "chat.message.workgroup.accept.button": "Прийняти запрошення до Робочої групи",
             "chat.message.workgroup.unprivileged.text": "Отримано запрошення до Робочої групи. Участь у Робочій групі для користувачів Привілейованих акаунтів. Ваш тип акаунта - непривілейованих. Для зміни типу акаунта зверніться до адміністратора",
             "chat.message.group.incoming.text": "Отримано запрошення до групи",
             "chat.message.group.outgoing.text": "Запрошення в групу відправлено",
             "chat.message.group.accept.button": "Прийняти запрошення в групу",
             "chat.message.file.sending.text": (fname, sent, total) => <span> Відправлення файлу {fname}. <br /> {sent} кб з {total} кб. </span>,
             "chat.message.file.downloading.text": (fname, received, total) => <span> Завантаження файлу {fname}. <br /> {received} кб з {total} кб. </span>,
             "chat.message.voice.sending.text": (sent, total) => "Відправка голосового повідомлення",
             "chat.message.file.downloaded.text": "Файл завантажений {0} та збережений {1}",
             "chat.message.file.open.button": "Відкрити",
             "chat.message.file.open.error":              "Необхідно встановити додаток для відкриття цього файлу",
             "chat.message.file.sending.error": "При відправці файлу виникла помилка",
             "chat.message.voice.sending.error": "При відправці голосового повідомлення виникла помилка",
             "chat.message.file.inactive.text": `Файл отримано {0}. Для скачування використовуйте веб - версію ${process.env.APP_NAME}`,
             "forward.message.header":                    "Пересилка повідомлення",
       			 "forward.message.text":                      "Вкажіть кому переслати це повідомлення",
             "forward.message.button.confirm":            "Переслати",
             "menu.alt": "Меню",
             "contact.rename.header": "Перейменувати",
             "contact.rename.button.done": "Все",
             "contact.rename.text": "Перейменувати контакт {0}",
             "contact.rename.placeholder": "Нове ім'я",
             "contacts.menu.header": "Меню",
             "contacts.menu.clear": "Очистити все",
             "contacts.menu.invite": "Запросити контакт",
             "contacts.menu.invite.external":             "Зовнішній контакт",
             "contacts.menu.useinvite": "Прийняти запрошення",
             "contacts.menu.inviteregistration": `Запросити у ${process.env.APP_NAME}`,
             "contacts.menu.clear.confirm": "Підтвердіть повне видалення історії листування",
             "contacts.menu.share.contacts": "Створити Робочу групу",
             "contacts.menu.add-user": "Додати користувача",
             "contacts.menu.exit": "Вийти",
             "contacts.overflow": "Ви перевищили можливу кількість контактів",
             "contacts.invitealreadyused": "Дане запрошення вже було використано",
             "contacts.empty": `Список контактів поки порожній. Пам'ятайте: ${process.env.APP_NAME} ніколи не використовує особисті дані користувачів (номер мобільного телефону, e-mail, профілі соціальних мереж, інше). Щоб додати контакт натисніть меню в правому верхньому кутку оберіть <Запросити контакт> або <Прийняти запрошення> `,
             "contacts.search.empty": "Контакти не знайдені",
             "contacts.search.placeholder": "Пошук контактів",
             "contacts.workgroup": "Робоча група",
             "contacts.workgroup.collapsed": "Розгорнути",
             "contacts.shared": "Спільні контакты",
             "contacts.add.assistant":                    "Додати Асистента",
             "contacts.add.assistant.text":               `Ви маєте можливість додати контакт Асистента. Це жива людина, що може допомогти Вам, якщо у Вас виникли питання по ${process.env.APP_NAME} або отримати зворотній зв'язок.`,
       			 "contacts.add.assistant.placeholder":        "Ім'я нового контакту",
       			 "contacts.add.assistant.ok.button":          "Додати",
             "group.rename.header": "Перейменувати групу",
             "group.rename.text": "",
             "group.rename.placeholder": "Назва групи",
             "groups.empty": "Групи поки не створені. Для створення групи натисніть «Додати» в правому верхньому кутку",
             "groups.search.empty": "Група не знайдена",
             "group.new.contacts.empty": "Для створення групи необхідні учасники",
             "chats.search.placeholder": "Пошук чатів",
             "chats.search.empty": "Чати не знайдені",
             "useinvite.header": "Прийняти запрош.",
             "useinvite.text": "Прийняти запрошення: для сканування QR- коду натисніть на значок QR в правому верхньому куті",
             "useinvite.text2": "Задайте ім'я для відображення у вашому списку контактів",
             "useinvite.nickname.placeholder": "Ім'я",
             "useinvite.nickname.tooltip": `Поставити ім'я в списку контактів ${process.env.APP_NAME}`,
             "useinvite.continue.button": "Прийняти",
             "useinvite.code.tooltip": "Прийняти запрошення: для сканування QR- коду натисніть на значок QR в правому верхньому куті",
             "useinvite.code.error": "Код запрошення невірний",
             "options.nickname.header": "Ваше ім'я",
             "options.nickname.text": "Ім'я, яке бачать Ваші співрозмовники при запрошенні до списку контактів",
             "options.nickname.placeholder": "Ваше ім'я",
             "options.nickname.button.done": "Далі",
             "options.password.header": "Зміна пароля",
             "options.password.text": () => <span> Пароль захищає дані облікового запису в разі потрапляння пристрою до третім особам. <p> Увага: оскільки ми не зберігаємо пароль на серверах {process.env.APP_NAME}, то його відновлення в разі втрати буде неможливо! </p> </span>,
             "options.password.placeholder": "Новий пароль",
             "options.password.button.done": "Далі",
             "options.password.duplicate.alert": "Даний пароль збігається з одним з уже використовуваних в с основній чи додатковій обліковому записі. Задайте інший пароль",
             "options.password.change.confirm": "Змінити пароль?",
             "options.delete.confirm": `Видалити акаунт? Користувачі зі списку контактів ${process.env.APP_NAME} НЕ будуть сповіщені про видалення`,
             "options.language.header": "Вибір мови",
             "options.update.header": "Оновлення",
             "options.update.tocurrent.text": `Вийшло оновлення ${process.env.APP_NAME}. Оновити месенджер?`,
             "options.update.tocurrent.installedversion": "Поточна: {0}",
             "options.update.tocurrent.latestversion": "Нова: {0}",
             "options.update.uptodate.text": "Ваша версія {0} не вимагає оновлення",
             "options.update.uptodate.noupdates": `Ви використовуєте актуальну версію ${process.env.APP_NAME}`,
             "options.update.notify.always.option": "Повідомляти про нову версію",
             "options.update.notify.skip.option": "Не нагадувати про це оновлення",
             "options.update.notify.never.option": "Відключити відновлення",
             "options.update.notify.text": `Вкажіть, коли ${process.env.APP_NAME} варто повідомляти Вам про оновлення`,
             "options.update.advanced.button": "Більше опцій >>",
             "options.update.installlatest.button": "Встановити останню версію",
             "options.update.tothis.button": "Встановити версію {0}",
             "options.update.versionlist.text": `Оновити ${process.env.APP_NAME} до нової версії?`,
             "options.update.log.downloading": "Завантаження оновлення",
             "options.update.log.download.progress": (loaded, total) =>! total? `Завантажено $ {loaded} кб`: `Завантажено $ {loaded} з $ {total} кб`,
             "options.update.log.download.completed": "Готово!",
             "options.update.log.validation.begin": "Йде перевірка пакета ...",
             "options.update.log.validation.end": "Перевірка завершена",
             "options.update.log.unzip.progress": (loaded, total) => `Розпаковуємо $ {(100 * loaded / total) .toFixed (2)}%`,
             "options.update.log.unzip.completed": "Розпакування завершена",
             "options.update.log.applying": "Застосовуємо оновлення ...",
             "options.web.existing.text": "У Вас є активна веб - сесія.",
             "options.web.existing.activity": timestamp => <span> Остання активність: {timestamp} </ span>,
             "options.web.disconnect.button": "Закрити сесію",
             "options.web.header": "Веб версія",
             "options.web.notification": "У Вас є активна веб - сесія. Натисніть, щоб завершити",
             "options.web.text": `Для доступу до веб - версії введіть в адресний рядок браузера {0}. Потім, в додатку ${process.env.APP_NAME} зайдіть в Налаштування, виберіть пункт Веб - версія і відскануйте QR- код, натиснувши на значок QR в правому верхньому кутку `,
             "options.backup.header": <span style={{fontSize: "80%"}}> Резервна копія </ span>,
             "options.version": "ВЕРСІЯ",
             "prompt.default.header": "Введіть текст",
             "prompt.default.text": "Опис значення",
             "prompt.default.placeholder": "Тут",
             "prompt.default.button.done": "Готово",
             "options.update.revert.text": `Ви можете скасувати всі встановлені оновлення ${process.env.APP_NAME} до спочатку встановленої версії`,
             "options.update.revert.button": "Скасувати оновлення",
             "options.update.revert.confirm": `Підтвердіть видалення оновлень ${process.env.APP_NAME}!`,
             "common.retry": "Повторити спробу",
             "common.loading": "Завантаження ...",
             "common.wait.title": "Зачекайте ...", // (total, current) => total? `Зачекайте ... $ {current} / $ {total}`: "Зачекайте ...",
             "common.error": "Помилка",
             "common.network.error": "Відсутня зв'язок з сервером",
             "common.noInternet.notification": "Підключаємося до інтернету ...",
             "common.contact.name.empty": "",
             "show.password": "Показати пароль",
             "dialogs.paste": "Вставити",
             "dialogs.copy": "Копіювати",
             "dialogs.clear": "Очистити",
             "dialogs.copied": "скопійовано в буфер",
             "dialogs.share": "Відправити",
             "dialog.title.alert": "Помилка",
             "dialog.ok": "Ок",
             "dialog.confirm": "Підтвердити",
             "dialog.retry": "Повторити",
             "dialog.cancel": "Скасувати",
             "remove.account.data": "Видалити дані облікового запису",
             "group.add.nickname.placeholder": "Ім'я в групі",
             "group.add.nickname.header": "ВАШЕ ІМ'Я",
             "group.add.nickname.text": "Так Вас будуть бачити учасники групи",
             "group.add.name.placeholder": "Назва групи",
             "group.add.name.header": "Назва",
             "group.add.name.text": "Введіть назву нової групи",
             "group.participantlist.header": "Учасники",
             "group.add.participants.header": "Додавання учасників",
             "group.add.participants.text": "Додавання учасників",
             "group.add.participants.button": "Додати учасників",
             "group.add.participants.button.empty": "Не додавати в групу",
             "group.participantlist.invites": "Запрошені:",
             "group.invite.delete.confirm": "Видалити запрошення?",
             "workgroup.add.nickname.placeholder": "Ваше ім'я в групі",
             "workgroup.add.nickname.header": "Введіть своє ім'я",
             "workgroup.add.nickname.text": "Так Вас будуть бачити учасники Робочої групи",
             "sharedcontacts.add.workgroup.header": <span style={{fontSize: "70%"}}> Робоча група </span>,
             "sharedcontacts.add.workgroup.text": " Додавання контактів до Робочої групи. Учасники Робочої групи поряд з Вами зможуть вести переписку з загальними контактами ",
             "sharedcontacts.add.contacts.header": " Спільні контакти ",
             "sharedcontacts.add.contacts.text": " Додавання загальних контактів в Робочу групу. Учасники Робочої групи, з якими Ви будете вести переписку .",
             "sharedcontacts.add.name.placeholder": " Назва групи ",
             "sharedcontacts.add.name.header": <span style={{fontSize: "53%"}}> Створити Робочу групу </span>,
             "sharedcontacts.add.name.text": ` Йде створення Робочої групи. Робоча група дозволяє всім її учасникам вести синхронізовану переписку в рамках загального списку користувачів. Учасники Робочої групи можуть самостійно додати користувачів зі свого списку контактів ${process.env.APP_NAME} в загальний `,
             "sharedcontacts.add.nickname.placeholder": " Ім'я в Робочій групі ",
             "sharedcontacts.add.nickname.header": " Ваше ім'я ",
             "sharedcontacts.add.nickname.text": " Щоб кожен раз не вводити ім'я, задайте його за замовчуванням в налаштуваннях ",
             "sharedcontacts.dashboard.header": <span style = {{fontSize: "70%"}}> Робоча група </span>,
             "sharedcontacts.workgroup.edit.header": <span style = {{fontSize: "70%"}}> Робоча група </span>,
             "sharedcontacts.dashboard.workgroup.edit": " Робоча група ",
             "sharedcontacts.dashboard.addcontacts": " Додати контакти ",
             "sharedcontacts.dashboard.participant": " Учасник має право :",
             "sharedcontacts.dashboard.allowworkgroupedit": " Редагувати Робочу групу ",
             "sharedcontacts.dashboard.allowcontactsedit": " Контакти ",
             "sharedcontacts.dashboard.setrights.button": " Зберегти ",
             "sharedcontacts.dashboard.workgroup.rights": " Права ",
             "sharedcontacts.dashboard.workgroup.rights.text": " Вкажіть, хто з користувачів Робочої групи буде мати право її редагувати, а хто - додавати і видаляти загальні контакти ",
             "sharedcontacts.dashboard.workgroup.add": " Додати ",
             "sharedcontacts.dashboard.workgroup.participant.delete": p => ` Видалити учасника ${p? p.nickname:""}?`,
             "sharedcontacts.dashboard.workgroup.participant.delete.yes": " Видалити ",
             "sharedcontacts.dashboard.workgroup.invite.delete": p => ` Видалити запрошеного учасника ${p? p.nickname:""}?`,
             "sharedcontacts.dashboard.multiinvite": " Подивитися візитку ",
             "sharedcontacts.dashboard.multiinvite.create": " Створити візитку ",
             "sharedcontacts.dashboard.autoclean": " Автошредер ",
             "sharedcontacts.dashboard.autoclean.remote": " Автошредер у співрозмовників ",
             "sharedcontacts.workgroup.edit.invites": " Запрошені :",
             "sharedcontacts.search.placeholder": " Пошук спільних контактів ",
             "sharedcontacts.search.empty": " Контакти не знайдені ",
             "send.contacts.header": " Відправити контакти ",
             "send.contacts.text": " Виберіть контакти для відправки. Даний спосіб забезпечує конфіденційність і не вимагає особистої зустрічі ",
             "access.denied": " Немає доступу ",
             "multiinvite.delete.button": " Видалити ",
             "web.common.confirm.button.no": " Скасування ",
             "web.contact.menu.rename": " Перейменувати ",
             "web.contact.menu.delete": " Видалити ",
             "web.contact.menu.send.contact": " Поділитися контактом ",
             "web.contacts.menu.send.contacts": " Відправити контакти ",
             "web.contacts.menu.clear.history": " Очистити історію ",
             "web.contacts.menu.clear.remotehistory": " Очистити історію у співрозмовника ",
             "web.contacts.menu.invite": " Запросити ",
             "web.contacts.menu.invite.external":         "Зовнішний контакт",
             "web.contacts.invite.content.absent": " Користувач ще не прийняв запрошення ",
             "web.contacts.menu.acceptInvite": " Прийняти запрошення ",
             "web.contacts.menu.businessCards": " Візитка ",
             "web.contact.rename.title": " Перейменувати контакт ",
             "web.contact.delete.title": " Видалити контакт ",
             "web.contact.delete.button.yes": " Видалити ",
             "web.group.rename.title": " Ім'я групи ",
             "web.group.exit.title": " Вийти з групи ",
             "web.group.exit.button.yes": " Вийти ",
             "web.group.menu.rename": " Перейменувати ",
             "web.group.menu.exit": " Вийти ",
             "web.group.menu.participants": " Учасники ",
             "web.chats.create": " Створити новий чат з ...",
             "web.chats.title": " Чати ",
             "web.contacts.loading": " Триває завантаження ...",
             "web.common.prompt.close.button": " Закрити ",
             "web.common.prompt.save.button": " Зберегти ",
             "web.accept.invite.text1": " Додавання нового контакту з використанням запрошення ",
             "web.accept.invite.placeholder": " Вставте отриманий код з буфера обміну або введіть його вручну ",
             "web.accept.invite.text2": " Ви можете перейменувати контакт і задати ім'я за замовчуванням ",
             "web.accept.invite.add.button": " Додати в список ",
             "web.accept.invite.title": " Прийняти запрошення ",
             "web.invite.text1": " Покажіть співрозмовнику QR- код для сканування ",
             "web.invite.text2": ` Надішліть текстову версію запрошення. Важливо , щоб запрошення потрапило тільки до потрібного Вам адресату .`,
             "web.invite.text3": ` Надішліть адресу для веб - браузера.`,
             "web.invite.copied.toast": " скопійовано ",
             "web.invite.copy_failed.toast": " Натисніть Ctrl + C для копіювання ",
             "web.invite.copy.button": " Скопіювати в буфер ",
             "web.contacts.title": " Контакти ",
             "web.contact.loading.text": " Завантаження контакту ...",
             "web.newinvite.text1": ` Ви створюєте запрошення в список контактів `,
             "web.newinvite.name.text": " Введіть ім'я нового контакту ",
             "web.newinvite.hide.name.label": " Більше не показувати Ваше ім'я в цьому запрошенні ",
             "web.newinvite.button": " Запросити ",
             "web.newinvite.title": " Нове запрошення ",
             "web.send.contacts.loading.text": " Завантаження ...",
             "web.send.contacts.button": " Відправити ",
             "web.send.contacts.title": " Відправлення контактів ",
             "web.send.contacts.progress.text": " Відправка контактів. Це може зайняти час, яке залежить від кількості відправлених контактів ",
             "web.group.create.name": " Назва групи :",
             "web.group.create.nickname": " Ваше ім'я в групі :",
             "web.group.create.name.placeholder": " Назва ",
             "web.group.create.nickname.placeholder": " Ім'я ",
             "web.group.create.participants": " Учасники :",
             "web.group.create.button": " Створити групу ",
             "web.group.create.title": " Створити нову групу ",
             "web.group.create.progress.text": " Йде створення групи . Це може зайняти час , залежне від кількості додаються учасників ",
             "web.group.loading": " Завантаження групи ...",
             "web.groups.list.empty": <p> У Вас поки немає груп в {process.env.APP_NAME}. <br /> Саме час їх створити! </p>,
             "web.groups.loading.text": " Завантаження ...",
             "web.groups.title": " Групи ",
             "web.chats.list.empty": <p> У Вас поки немає чатів {process.env.APP_NAME}. <br /> Саме час їх створити! </p>,
             "web.contacts.list.empty": <p> У Вас поки немає контактів {process.env.APP_NAME}. <br /> Саме час їх створити! </p>,
             "web.account.nickname.label": " Ваше ім'я ",
             "web.account.nickname.text": " Ім'я , яке бачать Ваші співрозмовники при запрошенні до списку контактів ",
             "web.account.notification.header": " Налаштування повідомлень ",
             "web.account.notification.vibrate": " Вібрація при надходження нових повідомлень ",
             "web.account.notification.incoming": " Звук вхідних повідомлень ",
             "web.account.notification.outgoing": " Звук відправки повідомлень ",
             "web.account.fakeaccount.header": " Додатковий акаунт ",
             "web.account.fakeaccount.text": `${process.env.APP_NAME} надає можливість приховати реальну переписку, якщо це необхідно. Для цього Ви можете створити ще один, додатковий, акаунт, який не буде містити компрометуючих даних, і в разі необхідності показати його замість основного` ,
             "web.account.fakeaccount.create.button": " Створити додатковий акаунт",
             "web.account.backup.header": " Резервна копія даних ",
             "web.account.backup.text": " Резервна копія дозволить використовувати дані поточного акаунта при перевстановлення програми, втраті або зміні пристрою ",
             "web.account.backup.create.button": " Створити резервну копію ",
             "web.account.delete.header": " Видалити акаунт ",
             "web.account.delete.text": ` Видалення облікового запису призведе до очищення списку контактів ${process.env.APP_NAME}, при цьому користувачі з нього не будуть сповіщені про видалення. Якщо Ви хочете повідомити когось про видалення Вашого облікового запису, Вам варто зробити це вручну до видалення облікового запису ${process.env.APP_NAME} `,
             "web.account.delete.button": " Видалити акаунт ",
             "web.account.delete.prompt.title": " Видалення облікового запису ",
             "web.account.delete.prompt.text": " Додатковий акаунт буде видалений разом з основним !",
             "web.account.delete.prompt.button.yes": " Видалити ",
             "web.account.delete.progress.title": " Видаляємо ",
             "web.account.delete.progress.text": " Зачекайте трохи - трохи ",
             "web.account.title": " Акаунт ",
             "web.application.update.head": " Оновлення ",
             "web.application.update.text": ` Ви можете оновити додаток самостійно або включити автооновлення. При автоматичному оновленні ПЗ ${process.env.APP_NAME} дані облікового запису збережуться `,
             "web.application.update.autoupdate.label": " Включити автообновление ",
             "web.application.update.currentversion": " Поточна версія {0}",
             "web.application.update.button": " Оновити ",
             "web.application.invite.head": " Запрошення ",
             "web.application.invite.text": ` Хочете розпочати діалог з кимось, хто ще не встановив ${process.env.APP_NAME}? Надішліть йому запрошення!`,
             "web.application.invite.button": " Запросити ",
             "web.application.language.head": " Вибір мови ",
             "web.application.language.text": `Поки ${process.env.APP_NAME} доступний українською, російською та англійською мовами, але ми працюємо над розширенням списку локалізацій!`,
             "web.application.title": " Додаток ",
             "web.info.title": " Інформація ",
             "web.notifications.title": " Сповіщення ",
             "web.notifications.content": " Для роботи сповіщень про нові повідомлення необхідно в браузері дозволити відправляти оповіщення.",
             "web.settings.menu.account": " Акаунт ",
             "web.settings.menu.account.text": <span> Ваше ім'я, налаштування повідомлень, <br /> створення і видалення акаунтів, резервні копії </span>,
             "web.settings.menu.security": " Безпека ",
             "web.settings.menu.security.text": <span> Створення та видалення паролів <br /> та історії листування </span>,
             "web.settings.menu.application": " Додаток ",
             "web.settings.menu.application.text": <span> Оновлення {process.env.APP_NAME}, запрошення <br /> друзів, покупка і активація <br /> ваучера, вибір мови </span>,
             "web.settings.menu.info": " Інформація ",
             "web.settings.menu.info.text": " Веб - версія, FAQ і зворотний зв'язок ",
             "web.settings.menu.notifications": " Сповіщення ",
             "web.settings.menu.notifications.on": " Включено ",
             "web.settings.menu.notifications.off": " Виключено ",
             "web.settings.menu.notifications.switch": " Включити оповіщення в браузері ",
             "web.settings.menu.notifications.warn": " Браузер заборонив відправлення повідомлень. Будь ласка, увімкніть повідомлення в налаштуваннях браузера.",
             "web.settings.menu.users": " Користувачі ",
             "web.settings.menu.users.text": " Всі користувачі системи ",
             "web.settings.title": " Налаштування ",
             "web.security.password.label": " Пароль ",
             "web.security.password.text": ` Пароль забезпечить недоторканність даних акаунта. Увага: так як ми не зберігаємо дані пароля на серверах ${process.env.APP_NAME}, то його відновлення в разі втрати буде неможливо! Також даний пароль використовується для блокування екрану `,
             "web.security.password.placeholder": " Пароль ",
             "web.security.password.show.label": " Показати пароль ",
             "web.security.password.change.button": " Змінити пароль ",
             "web.security.autolock.label": ` Блокувати екран при згортанні ${process.env.APP_NAME}`,
             "web.security.history.head": " Шредер листування ",
             "web.security.history.text": " Налаштуйте параметри видалення історії листування. Будьте впевнені в конфіденційності Ваших даних!",
             "web.security.history.cleanonexit.label": ` Видаляти історію при виході з ${process.env.APP_NAME}`,
             "web.security.history.ttl.label": " Все листування буде автоматично видалятися через певний час. Задайте інтервал видалення ",
             "web.security.history.autoclean.unit.no": " немає ",
             "web.security.history.autoclean.unit.sec": " секунд ",
             "web.security.history.autoclean.unit.min": " хвилин ",
             "web.security.history.autoclean.unit.hour": number => number == 1? " година ": (number && number <= 4? " години ": " годин "),
             "web.security.history.autoclean.unit.day": number => number == 1? " день ": (number && number <= 4? " дня ": " днів "),
             "web.security.history.autoclean.unit.year": number => number == 1? " рік ": (number && number <= 4? " року ": " років "),
             "web.security.history.cleannow.label": " Негайно видалити історію листування ?",
             "web.security.history.cleannow.button": " Видалити історію ",
             "web.security.title": " Безпека ",
             "web.fakeaccount.create.title": " Додатковий акаунт ",
             "web.fakeaccount.create.text": <p> {process.env.APP_NAME} надає можливість приховати реальну переписку, якщо це необхідно. Для цього Ви можете створити ще один, додатковий, акаунт, який не буде містити компрометуючих даних, і в разі необхідності показати його замість основного </p>,
             "web.fakeaccount.create.button": " Створити додатковий акаунт ",
             "web.fakeaccount.name.placeholder": " Ім'я ",
             "web.fakeaccount.password.placeholder": " Пароль ",
             "web.fakeaccount.dulplicatePassword.toast": " Паролі основного і додаткового акаунтів збігаються. Будь ласка, введіть інший пароль ",
             "web.security.dulplicatePassword.toast": " Даний пароль вже використовується, введіть інший пароль ",
             "web.security.passwordChanged.toast": " Пароль успішно змінений ",
             "web.security.clearHistory.title": " Очищення історії ",
             "web.security.clearHistory.text": " Ви впевнені, що хочете очистити всю історію листування? Відновлення даних буде неможливо!",
             "web.security.clearHistory.button.yes": " Очистити ",
             "web.message.placeholder": "Нове повідомлення",
             "web.contacts.add.modal.title": "Додайте новий контакт",
             "web.contacts.add.modal.cancel.button": " СКАСУВАТИ ",
             "web.contacts.add.invite.menuitem": " Запросити ",
             "web.contacts.add.accept.menuitem": " Прийняти запрошення ",
             "web.contacts.add.external.menuitem": " Зовнішній контакт ",
             "web.menu.chats": " Чати ",
             "web.menu.contacts": " Контакти ",
             "web.menu.groups": " Групи ",
             "web.menu.sharedcontacts": " Робочі групи ",
             "web.menu.settings": " Налаштування ",
             "web.notification.text": " Веб - версія ",
             "web.sharedcontacts.loading": " Завантаження ...",
             "web.sharedcontacts.title": " Робочі групи ",
             "web.sharedcontacts.list.empty": <p>
             Робочі групи {process.env.APP_NAME} поки не створені <br />
              Створити Робочу групу ? </p>,
             "web.helper.prompt.title": " Ім'я контакту - асистента ",
             "web.helper.prompt.default": " Асистент ",
             "web.helper.title": " Асистент ",
             "web.helper.text": " Ви можете анонімно спілкуватися з агентом нашої служби підтримки. Для цього додайте його в контакти, натиснувши кнопку:",
             "web.helper.button": " Додати асистента ",
             "web.file.download": " Завантажити ",
             "web.file.save": " Зберегти ",
             "web.file.save.mobsafari":                   `Тривало натисніть на цей текст та оберіть в меню "Відкрити у новій вкладці/фоновому режимі"`,
             "web.file.open": " Відкрити ",
             "web.file.header": " Файл ",
             "web.sharedcontacts.create.nickname.text": " Поставте ім'я в Робочій групі. Установки за замовчуванням доступні в настройках ",
             "web.sharedcontacts.create.nickname.placeholder": " Ваше ім'я ",
             "web.sharedcontacts.create.name.text": " Ім'я, що відображається у Ваших співрозмовників в списку контактів ",
             "web.sharedcontacts.create.name.placeholder": " Ім'я контакту ",
             "web.sharedcontacts.create.nickname.prev": " Скасувати ",
             "web.sharedcontacts.create.nickname.next": " Далі ",
             "web.sharedcontacts.create.name.prev": " Поставити ім'я ",
             "web.sharedcontacts.create.name.next": " Далі ",
             "web.sharedcontacts.create.workgroup.prev": " Назад ",
             "web.sharedcontacts.create.workgroup.next": " Далі ",
             "web.sharedcontacts.create.rights.prev": " Назад ",
             "web.sharedcontacts.create.rights.next": " Далі ",
             "web.sharedcontacts.create.contacts.prev": " Назад ",
             "web.sharedcontacts.create.contacts.next": " Далі ",
             "web.sharedcontacts.create.workgroup.text": " Виберіть учасників для створення Робочої групи ",
             "web.sharedcontacts.create.contacts.text": " Виберіть учасників для додавання в список. Кожному з них буде надіслано запрошення. Якщо запрошення буде прийнято, в списку співрозмовника з'явиться контакт з ім'ям ` {0} `.",
             "web.sharedcontacts.create.rights.text": " Ви можете задати індивідуальні права для членів Робочої групи ",
             "web.sharedcontacts.create.wait.text": " Робоча група в процесі створення. Це може зайняти час, залежне від кількості учасників та спільних контактів ",
             "web.multidescription.exit.title": " Вийти?",
             "web.multidescription.exit.button.yes": " Вийти ",
             "web.multidescription.rename.title": " Перейменування ",
             "web.multidescription.menu.rights": " Права ",
             "web.multidescription.menu.rename": " Перейменувати ",
             "web.multidescription.menu.workgroup": " Робоча група ",
             "web.multidescription.menu.delete": " Вийти і видалити ",
             "web.workgroup.remove.title": " Видалити учасника робочої групи ",
             "web.workgroup.remove.button.yes": " Видалити ",
             "web.workgroup.add.button": " Додати ",
             "web.workgroup.add.back.button": " Повернутися ",
             "web.workgroup.add.text": " Виберіть учасників для додавання в робочу групу ",
             "web.workgroup.add.continue": " Продовжити ",
             "web.sharedcontacts.rights.done": " Встановити права ",
             "web.sharedcontacts.add.next": " Додати ",
             "web.multidescription.menu.add": " Додати контакти ",
             "web.group.participants.text": " Редагування списку учасників групи ",
             "web.group.participants.invite.button": " Запросити учасника ",
             "web.group.participant.delete.title": " Видалити учасника?",
             "web.group.participant.delete.button.yes": " Видалити ",
             "web.group.add.contacts.button": " Додати в групу ",
             "web.contacts.meta.group": "( група )",
             "web.chats.main.normal.title": " Чат з {0}",
             "web.chats.main.group.title": " Груповий чат {0}",
             "web.chats.list.title": " Чати ",
             "web.contacts.main.title": " Чат з {0}",
             "web.contacts.list.title": " Контакти ",
             "web.groups.main.title": " Груповий чат {0}",
             "web.groups.list.title": " Групи ",
             "web.sharedcontacts.main.title": " Чат з {0}",
             "web.sharedcontacts.list.title": " Спільні контакти ",
             "web.settings.list.title": " Налаштування ",
             "web.settings.account.main.title": ` Налаштування облікового запису ${process.env.APP_NAME}`,
             "web.settings.security.main.title": " Налаштування безпеки ",
             "web.settings.application.main.title": ` Налаштування ${process.env.APP_NAME}`,
             "web.settings.info.main.title": " Про програму ",
             "web.settings.notifications.main.title": " Сповіщення ",
             "web.settings.users.main.title": " Користувачі ",
             "web.login.title": ` Вхід в ${process.env.APP_NAME} Web`,
             "web.login.generateing.code":                "Створюється код...",
       			 "web.login.text1":                           `Для активації Веб-Інтерфейсу, увійдіть на телефоні в свій ${process.env.APP_NAME}, натисніть 'Налаштування', далі - 'ВЕБ ВЕРСІЯ' и відскануйте QR-код зліва`,
       			 "web.login.text2":                           "Для зменшення використання мобільних даних, використовуйте WiFi на телефоні",
       			 "web.login.create.group":                    "Ви також маєте можливість почати нову анонімну групу в браузері! Додавати користувачів в цю групу ви зможете за допомогою посилань",
       			 "web.login.create.group.button":             "Створити анонімну групу",
             "web.chats.newcontact": " Новий контакт ",
             "web.offline.title": " З'єднання з глобальною мережею не встановлено ",
             "web.offline.text": " Увага! Знайдено проблеми з підключенням до глобальної мережі ",
             "web.opener.textId": " Проводиться безпечне з'єднання, будь ласка, очікуйте ...",
             "web.contacts.menu.newtab": " Відкрити в новому вікні ",
             "contact.status.invited.description":
             <span>
     Згоди на діалог від іншої сторони поки не було отримано. Можливі причини : <br /> <br />
1. Контакт отриманий від анонімного користувача, однак власник контакту зараз не в мережі або не підтвердив своєї участі <br />
2. Анонімний користувач відправив Ваш контакт іншому співрозмовнику, який на даний момент не в мережі або відмовився прийняти Вашу контактну картку {process.env.APP_NAME} <br />
3. Контакт був доданий за допомогою множинного запрошення ( візитки ), але власник візитки зараз не в мережі.
</span>,
             "contact.shared.status.invited.description":
<span>
Згоди на діалог від іншої сторони поки не було отримано. Можливі причини : <br /> <br />
1. Контакт отриманий від анонімного користувача, однак власник контакту зараз не в мережі або не підтвердив своєї участі в даній бесіді <br />
2. Анонімний користувач відправив Ваш контакт іншому співрозмовнику, який на даний момент не в мережі або відмовився прийняти Вашу контактну картку {process.env.APP_NAME} <br />
3. Анонімний користувач з робочої групи зробив цей контакт загальним, але власник контакту зараз не в мережі або не підтвердив своєї участі в даній бесіді.
</span>,
             "service.message.group.left": " Користувач {0} покинув групу ",
             "service.message.group.join": " Користувач {0} приєднався до групи ",
             "backup.copytoast": " Токен скопійований ",
             "Backup.qr.bigtext": "",
             "backup.qr.smalltext": ` Збережіть QR- код, без якого відновлення контактів ${process.env.APP_NAME} буде неможливо!`,
             "Backup.plain.text": "",
             "Backup.token.tooltip": "",
             "backup.button.copy": " Скопіювати ",
             "backup.button.delete": " Видалити резервну копію ",
             "backup.button.create": " Створити резервну копію ",
             "backup.create.text":  `
               Ви можете створити резервну копію даних облікового запису ${process.env.APP_NAME} на різні випадки життя:
              перевстановлення додатка, зміна або втрата пристрою, інші ситуації.
              Резервна копія надійно захищена асиметричним шифруванням.
              Дешифровка резервної копії буде можлива лише за наявності унікального коду,
              який буде сгененірован додатком. Пам'ятайте, що при втраті коду відновлення
              резервної копії буде неможливо! Збережіть його за допомогою перенесення на паперовий носій або у вигляді QR- коду!`,
             "backup.fake.checkbox": " Додатковий акаунт ",
             "backup.fake.password.placeholder": " Пароль від додаткового облікового запису ",
             "backup.fake.password.tooltip": " При наявності додаткового облікового запису, введіть пароль від нього в це поле ",
             "backup.fake.password.invalid": " Неправильний пароль ",
             "backup.progress": ` Збереження запису # {0} з {1}`,
             "backup.button.cancel": " Скасувати ",
             "activation.isprivileged.checkbox": " Привілейований ",
             "activation.button.generate": " Додати ",
             "activation.nickname.placeholder": " Ім'я користувача ",
             "activation.nickname.tooltip": ` Для зручності роботи з контактами в списку ${process.env.APP_NAME} задайте ім'я користувача `,
             "activation.text": " Для додавання нового користувача створіть код активації ",
             "activation.header": " Активація нового користувача ",
             "activation.qr.bigtext": "",
             "activation.qr.smalltext": "",
             "activation.token.placeholder": "",
             "activation.button.copy": " Копіювати ",
             "activation.copytoast": " скопійовано ",
             "activation.plain.text": "",
             "activation.user.quota.exceeded": " Досягнуто максимум кількості користувачів ",
             "activation.privileged.quota.exceeded": " Досягнуто максимум кількості Привілейованих користувачів ",
             "web.users.title": " Користувачі ",
             "web.users.add.title": " Новий користувач ",
             "web.users.add.text": ` Ви можете зробити даного користувача адміністратором тільки після його реєстрації в системі ${process.env.APP_NAME}`,
             "web.users.add.nickname.title": " Ім'я ",
             "web.users.add.type.title": " Тип користувача ",
             "web.users.add.close.button": " Скасування ",
             "web.users.add.save.button": " Зберегти ",
             "web.users.add.button": " Додати користувача ",
             "web.users.send.button": " Відправити контакти ",
             "web.users.send.close.button": " Скасувати ",
             "web.users.send.confirm.button": " Відправити ",
             "web.user.send.title": " Відправлення контактів користувачеві {0}",
             "web.user.send.text": " Виберіть контакти для відправки ",
             "web.user.send.progress": " Відправлення контактів в процесі. Це може зайняти деякий час в залежності від кількості обраних контактів.",
             "web.users.activation.close.button": " Закрити ",
             "web.users.add.quota.exceeded": ` Ви перевищили допустиму кількість користувачів і подальше їх додавання в ${process.env.APP_NAME} неможливо `,
             "web.users.edit.privileged.quota.exceeded": " Подальше підвищення рівня привілеїв для даного контакту неможливо, так як Ви перевищили допустиму кількість привілейованих користувачів ",
             "web.users.edit.unactivated": ` Користувач не пройшов процедуру реєстрації ${process.env.APP_NAME}. Тип користувача не може бути змінений Вами до його реєстрації `,
             "web.users.edit.adminlow": " Увага: недостатньо адміністраторів для зміни типу користувачів. Будь ласка, додайте адміністраторів !",
             "web.users.adminlow.error": ` Увага: недостатньо адміністраторів! Пам'ятайте, що адміністратори забезпечують доступ до списку користувачів ${process.env.APP_NAME}! У разі втрати усіма з них доступів до своїх акаунтів, список користувачів буде заблокований, а його відновлення - неможливо з - за особливостей шифрування. Автори ${process.env.APP_NAME} рекомендують додавання як мінімум чотирьох адміністраторів `,
             "web.users.adminlow.warn": ` Увага: недостатньо адміністраторів! Пам'ятайте, що адміністратори забезпечують доступ до списку користувачів ${process.env.APP_NAME}! У разі втрати усіма з них доступів до своїх акаунтів, список користувачів буде заблокований, а його відновлення - неможливо з - за особливостей шифрування. Автори ${process.env.APP_NAME} рекомендують додавання як мінімум чотирьох адміністраторів `,
             "web.users.thead.nickname": " Ім'я ",
             "web.users.thead.type": " Тип ",
             "web.users.edit.button": " Редагувати ",
             "web.users.delete.button": " Видалити ",
             "web.users.cancel.button": " Скасування ",
             "web.users.apply.button": " Застосувати ",
             "web.users.code.button": " Код активації ",
             "web.users.type.user": " Звичайний користувач ",
             "web.users.type.powerUser": " Привілейований користувач ",
             "web.users.type.admin": " Адміністратор ",
             "web.user.confirm.title": " Видалити користувача ?",
             "web.user.confirm.button.yes": " Видалити користувача ",
             "web.user.activation.title": " Код активації для {0}",
             "web.user.activation.text": " Текстовий код ",
             "web.message.delete.title": " Видалити повідомлення ?",
             "web.message.delete.button.yes": " Видалити ",
             "activation.isaddcontact.checkbox": " Додати в список контактів ",
             "group.new.progress.header": " Нова група ",
             "group.new.progress.text": " Створюємо групу. Будь ласка, зачекайте: час очікування залежить від кількості учасників ",
             "group.add.progress.header": "+ Учасники ",
             "group.add.progress.text": " Додаємо учасників. Будь ласка, зачекайте: час очікування залежить від кількості учасників ",
             "web.group.external.main.title": " Чат в групі {0}",
             "web.group.external.title": " Чат ",
             "web.contact.external.main.title": " Чат з {0}",
             "web.contact.external.title": " Чат ",
             "group.external.header": <span style={{fontSize: "80%"}}> Зовнішній учасник </span>,
             "group.external.text": "",
             "group.external.placeholder": " ІМ'Я ",
             "group.external.copytoast": " скопійовано ",
             "group.external.qr.bigtext": "QR- код адреси ",
             "group.external.qr.smalltext": " - Надайте цей QR- код співрозмовнику для сканування ",
             "group.external.plain.text": " - Або надішліть посилання іншим способом: електронною поштою, в SMS або за допомогою іншого месенджера ",
             "group.external.token.tooltip": ` Посилання, за яким співрозмовник, який не зареєстрований в ${process.env.APP_NAME}, зможе вести переписку в групі `,
             "group.external.button.copy": " Скопіювати в буфер ",
             "group.external.duplicate.warn": " Дане ім'я вже зареєстровано, будь ласка, виберіть інше ",
             "group.external.duplicate.invite.warn": " Учасник з таким ім'ям вже запрошений ",
             "web.group.external.nickname": " Ім'я зовнішнього учасника ",
             "web.group.external.nickname.placeholder": " Ім'я ",
             "web.group.external.nickname.text":          "Ваше ім'я в групі",
       			 "web.group.external.name.placeholder":       "Назва",
       		 	 "web.group.external.name.text":              "Назва нової групи",
             "web.group.external.add.button": " Додати ",
             "web.group.external.create.group.button": "Створити нову групу",
             "web.group.menu.external": " Додати зовнішнього учасника ",
             "web.group.menu.new": " Зробити нову групу ",
             "web.group.external.done.button": " Закрити ",
             "web.group.external.duplicate.warn": " Дане ім'я вже зареєстровано, будь ласка, виберіть інше ",
             "web.group.external.duplicate.invite.warn": " Учасник з таким ім'ям вже запрошений ",
             "web.group.external.joining": " В процесі приєднання до групи ",
             "web.group.external.failed": " Спроба приєднання до групи не вдалася. Можливо, посилання вже було використане ",
             "web.group.external.exited": " Ви вийшли з групи ",
             "web.unread.title": cnt => cnt% 10!== 1 || cnt === 11
                 ? (cnt + ` непрочитаних повідомлень - ${process.env.APP_NAME}`)
                 : (cnt + ` непрочитане повідомлення - ${process.env.APP_NAME}`),
             "web.businessCards.text1":                   "Візитка - це запрошення, яке може бути використано скільки завгодно разів. Нові контакти будуть називатися BC, BC(1), BC(2) та ін. При необхідності, перейменуйте їх.",
          	 "web.businessCards.text2":                   "Токен візитки",
          	 "web.businessCards.copy.button":             "Скопіювати",
          	 "web.businessCards.copied.toast":            "Скопійовано",
          	 "web.businessCards.copy_failed.toast":       "Копіювання невдале",
          	 "web.businessCards.create.text":             "Візитка - це запрошення, яке може бути використано скільки завгодно разів. Нові контакти будуть називатися BC, BC(1), BC(2) та ін. При необхідності, перейменуйте їх.",
          	 "web.businessCards.create.button":           "Створити",
             "web.businessCards.title":                   "Візитка",
             "web.businessCards.delete.button":           "Видалити",
             "web.businessCards.delete.title":            "Якщо Ви видалите візитку, ніхто більше не зможе додати Вас у список контактів, користуючись цією візиткою. Навіть якщо Ви створите нову візитку, стара не буде діяти.",
     			   "web.businessCards.delete.button.yes":       "Так, видалити",
             "web.businessCards.qr.download":             "Завантажити як PNG"
    });
  }
}

export default TranslationUkr;
