import _ from "lodash";
import Message from "./message.js";
import mimeTypes from "./mimetypes.js";
import BinarySource from "../binary.source.js";
import BinaryStream from "../binary.stream.js";

let minMessageSize = 0; //1024; This causes problems with very small files

function calculateHeaderSize( message ) {
	let messageText = Buffer.byteLength( message.getText() ) + 4/*chunk count*/ + 4/*padding size*/ + 1/*text end*/;
	let attachmentPrefix = 1/*end of name*/ + 1/*mimetype*/ + 4/*attachment size*/;
	let attachments = message.getAttachments();
	let attachmentNames = _.sumBy( attachments, attachment => Buffer.byteLength( attachment.getName() || "" ) );
	return messageText + attachmentNames + attachments.length * attachmentPrefix;
}

function calculateBodySize( message ) {
	return (
		message.getAttachments()
			.reduce(
				( prev, attachment ) => prev + attachment.getSize(),
				0
			)
	);
}

class MessageSerializer {
	constructor( message, chunkSize ) {
		if ( ( chunkSize | 0 ) !== chunkSize ) {
			throw Error( "MessageSerializer constructor requires valie chunkSize" );
		}
		this._message = message;
		this._chunkSize = chunkSize;

		this._headerSize = calculateHeaderSize( message );
		this._bodySize = calculateBodySize( message );
		this._header = new Buffer( this._headerSize );

		let chunkCount = ( ( this._headerSize + this._bodySize + chunkSize - 1 ) / chunkSize ) | 0;
		this._totalSize = this._headerSize + this._bodySize;

		this._paddingSize = chunkCount * chunkSize - this._headerSize - this._bodySize;
		this._totalSize += this._paddingSize;

		let headerPosition = 0;

		headerPosition = this._writeWord( this._header, headerPosition, chunkCount );
		headerPosition = this._writeWord( this._header, headerPosition, this._paddingSize );
		headerPosition = this._writeMessageText( this._header, headerPosition );
		headerPosition = this._writeAttachmentMetadata( this._header, headerPosition );

		if ( headerPosition !== this._headerSize ) {
			throw new Error( `headerPosition !== this._headerSize: ${headerPosition} !== ${this._headerSize}` );
		}
	}

	createBinaryStream( ) {
		return BinaryStream.createConcat(
			BinaryStream.fromBuffer( this._header ),
			BinaryStream.pseudoRandom( this._paddingSize ),
			..._.map(
				this._message.getAttachments(),
				attachment => attachment.createBinaryStream()
			)
		);
	}

	_writeByte( buffer, position, byte ) {
		buffer[ position++ ] = byte;
		return position;
	}

	_writeWord( buffer, position, word ) {
		buffer.writeUInt32LE( word, ( position += 4 ) - 4 );
		return position;
	}

	_writeString( buffer, position, string ) {
		position += buffer.write( string, position );
		position = this._writeByte( buffer, position, 0xff ); //invalid utf-8 char
		return position;
	}

	_writeAttachmentMetadata( buffer, position ) {
		this._message.getAttachments()
			.forEach( ( attachment, index ) => {
				position = this._writeByte( buffer, position, mimeTypes.getIndex( attachment.getMimeType() ) );
				position = this._writeWord( buffer, position, attachment.getSize() );
				position = this._writeString( buffer, position, attachment.getName() || "" );
			} );
		return position;
	}

	_writeMessageText( buffer, position ) {
		return this._writeString( buffer, position, this._message.getText() );
	}

	_writeBuffer( buffer, position, bufferToWrite ) {
		bufferToWrite.copy( buffer, position );
		return position + bufferToWrite.length;
	}
};

MessageSerializer.calculateMessageSize = ( message, chunkSize ) => {
	if ( chunkSize !== ( chunkSize | 0 ) ) {
		throw new Error( `calculateMessageSize requires chunkSize` );
	}

	if ( Buffer.isBuffer( message ) ) {
		return message.length;
	}
	let headerSize = calculateHeaderSize( message );
	let bodySize = calculateBodySize( message );
	let chunkCount = ( ( headerSize + bodySize + chunkSize - 1 ) / chunkSize ) | 0;
	let paddingSize = chunkCount * chunkSize - headerSize - bodySize;
	return headerSize + bodySize + paddingSize;
};


export default MessageSerializer;
