import Rx from "rx";
import _ from "lodash";
import ssgCrypto,{KEY_KINDS} from "ssg.crypto";
import configuration from "../../common/configuration.js";
import {sendEncryptedMessageThen, receiveMessageThen } from "../../common/utils.js";
import {
	serializeObjectWithKeysToNewManagedBufferAsync,
	serializeObjectWithKeysToNewManagedBufferThen
} from "../../common/serializer.js";
import RemoteControlClient from "../transport/remote.control.client.js";

import history from "../../browser/components/history.js";

export const connectKeyMap = {
	macKey: KEY_KINDS.MAC,
	seedMacKey: KEY_KINDS.INTERMEDIATE,
	seedEncryptionKey: KEY_KINDS.INTERMEDIATE
};

class RemoteService {
	constructor( ) {
		this._connectionStatusSubj = new Rx.BehaviorSubject( false );
		this._pendingTokenData = Object.create( null );
		this._commonObservable = new Rx.ReplaySubject( 1 );
		this._currentSending = Object.create( null );
		this._mutedContactIdHash = Object.create( null );
		this._notificationsPerContact = Object.create( null );
	}

	generateTokenAsync( ) {
		let connectData;
		return Rx.Observable.fromPromise(
			Promise.all( [
				ssgCrypto.createRandomKeyThen(
					KEY_KINDS.MAC,
					configuration.getDefaultEncryptionConfig()
				),
				ssgCrypto.createRandomKeyThen(
					KEY_KINDS.INTERMEDIATE,
					configuration.getDefaultEncryptionConfig()
				),
				ssgCrypto.createRandomKeyThen(
					KEY_KINDS.INTERMEDIATE,
					configuration.getDefaultEncryptionConfig()
				),
				ssgCrypto.createRandomBase64StringThen(
					configuration.getIdsLength()
				)
			] )
			.then( ( [ macKey, seedMacKey, seedEncryptionKey, connectionId ] ) => {
				connectData = {
					apiUrlBase: configuration.getSocketBase(),
					econfig: configuration.getDefaultEncryptionConfig().toJson(),
					macKey, seedMacKey, seedEncryptionKey, connectionId,
					type: "webconnection"
				};
				return serializeObjectWithKeysToNewManagedBufferThen(
					connectData,
					connectKeyMap
				);
			} )
			//TODO: Change message format to skip converting sensetive data to text/buffer
			.then( inviteMB => new Promise( resolve => {
					inviteMB.useAsBuffer( b => {
						sendEncryptedMessageThen( b.toString( "base64" ) )
							.then( resolve );
					} );
				} ).then( token => {
					inviteMB.dispose();
					this._pendingTokenData[ token ] = connectData;
					return token;
				} )
			)
		);
	}

	observeConnectionStatus( ) {
		return this._connectionStatusSubj;
	}

	beginConnect( token ) {
		let tokenData = this._pendingTokenData[ token ];
		console.log('beginConnect', token, tokenData);
		if ( !tokenData || tokenData.connection ) {
			return;
		}
		let {connectionId, seedMacKey, seedEncryptionKey, macKey} = tokenData;
		tokenData.connection = new RemoteControlClient( connectionId, seedMacKey, seedEncryptionKey, macKey );

		tokenData.connection.onPaired( () => {
			if ( tokenData._unpairTimeout ) {
				clearTimeout( tokenData._unpairTimeout );
			}
			this._setActiveConnection( tokenData.connection );
			this._connectionStatusSubj.onNext( true );
		} );
		tokenData.connection.onUnpaired( ( { isForever } ) => {
			this._onUnpaired( tokenData, isForever );
		} );
	}

	_onUnpaired( tokenData, isForever ) {
		if ( isForever ) {
			this._connectionStatusSubj.onNext( false );
			this._setActiveConnection( null );
			//TODO:
			global.location.reload();
			global.location.href = global.location.href;
			return;
		}
		if ( tokenData._unpairTimeout ) {
			clearTimeout( tokenData._unpairTimeout );
		}
		tokenData._unpairTimeout = setTimeout( () => {
			this._connectionStatusSubj.onNext( false );
			this._setActiveConnection( null );
			delete tokenData._unpairTimeout;
		}, configuration.getWebDisconnectTimeout() );
	}

	beginConnectUsingData( connectionId, seedMacKey, seedEncryptionKey, macKey ) {
		if ( this._pendingTokenData.opener ) {
			return;
		}
		let tokenData = { connectionId, seedMacKey, seedEncryptionKey, macKey };
		this._pendingTokenData.opener = tokenData;
		tokenData.connection = new RemoteControlClient( connectionId, seedMacKey, seedEncryptionKey, macKey );

		tokenData.connection.onPaired( () => {
			this._setActiveConnection( tokenData.connection );
			this._connectionStatusSubj.onNext( true );
		} );
		tokenData.connection.onUnpaired( ( { isForever } ) => {
			this._connectionStatusSubj.onNext( false );
			this._setActiveConnection( null );
		} );
	}

	_setActiveConnection( connectionToSet ) {
		for( let token in this._pendingTokenData ) {
			let { seedEncryptionKey, seedMacKey, macKey, connection } =
				this._pendingTokenData[ token ];

			if ( connection !== connectionToSet ) {
				connection.dispose();
				seedEncryptionKey.dispose();
				seedMacKey.dispose();
				macKey.dispose();
				delete this._pendingTokenData[ token ];
			}
		}
		this._commonObservable_ && this._commonObservable_.dispose();
		this._notificationSubscriptions && this._notificationSubscriptions.dispose();
		delete this._notificationSubscriptions;
		this._rcc = connectionToSet;
		let prevProfile = null;
		this._commonObservable_ = this._rcc && (
			this._rcc.observeCommonData()
				.tap( data => {
					data.clientTimeStamp = +new Date;
					if ( _.isEmpty( data.p ) ) {
						data.p = prevProfile;
					} else {
						prevProfile = data.p;
					}
				} )
				.subscribe(
					item => {
						console.log('_commonObservable', item);
						this._commonObservable.onNext( item );
					},
					err => {
						this._commonObservable.onError();
					},
					() => {
						this._commonObservable.onCompleted()
					}
				)
		);
	}

	observeContactList( ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._commonObservable.map( ( { c } ) => c )
		);
	}

	inviteContactAsync( contactName, selfNameOverride, isExternal ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.inviteContactAsync( contactName, selfNameOverride, isExternal )
		);
	}

	getInviteDataAsync( token ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.getInviteDataAsync( token )
		);
	}

	acceptInviteAsync( token, name ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.acceptInviteAsync( token, name )
				.tap( contact => {
					if ( contact ) {
						contact.type = "normal";
					}
				} )
		);
	}

	renameContactAsync( contact, newName ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.renameContactAsync( contact, newName )
		);
	}

	deleteContactAsync( contact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.deleteContactAsync( contact )
		);
	}

	forwardMessageAsync( contact, forwardingMessage ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.forwardMessageAsync( contact, forwardingMessage )
		);
	}

	sendTextMessageAsync( contact, text, replyMessage ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		if (replyMessage && !replyMessage.text) {
			throw new Error("You can reply only to text messages");
		}
		let replyTo = null;
		if (replyMessage) {
			replyTo = {
				replyIndex: replyMessage.replaceIndex === undefined ? replyMessage.index : replyMessage.replaceIndex,
				replyText: replyMessage.text.length <= 100 ? replyMessage.text : (replyMessage.text.substr(0, 100) + "..."),
				replySenderPid: replyMessage.senderPid
			};
		}
		return (
			this._rcc.sendTextMessageAsync( contact, text, replyTo )
		);
	}

	sendFileAsync( contact, file ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.sendFileAsync( contact, file )
		);
	}

	sendVoiceMessageAsync( contact, buffer, milliseconds, mediaType, messageId ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.sendVoiceMessageAsync(
				contact,
				buffer,
				milliseconds,
				mediaType,
				messageId
			)
		);
	}

	sendContactsAsync( contact, contactIdsToSend ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.sendContactsAsync( contact, contactIdsToSend )
		);
	}

	observeServerTimeDiff() {
		return (
			this._commonObservable
				.map( ( { timeDiff, clientTimeStamp, serverTimeStamp } ) =>
					 clientTimeStamp - serverTimeStamp - timeDiff
				)
		);
	}

	observeMessages( contact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}

		let currentMessageList = Object.create( null );
		let currentSending = this._currentSending[ contact.id ] = this._currentSending[ contact.id ] || [];
		let currentSendingIds = Object.create( null );

		_.forEach( currentSending, ( { id } ) => { currentSendingIds[ id ] = 1; } );
		return (
			this._commonObservable.take( 1 ).map( ( { timeDiff, clientTimeStamp, serverTimeStamp } ) =>  ( {
				messages: this._filterMessages(
					contact.autocleanTime,
					timeDiff,
					clientTimeStamp,
					serverTimeStamp,
					contact.cachedMessages
				),
				sending: currentSending
			} ) )
			.concat(
				this._rcc.observeMessages( contact )
					.map( ( { diff, sending, sendingFiles } ) => {
						let fullList = Object.create( null );
						let currentIds = Object.create( null );

						for ( let indexStr in diff ) {
							let message = diff[ indexStr ];
							if ( !_.isEmpty( message ) ) {
								fullList[ indexStr ] = currentMessageList[ indexStr ] = message;
								currentIds[ message.id ] = 1;
							} else {
								delete currentMessageList[ indexStr ];
								delete fullList[ indexStr ];
							}
						}

						for( let indexStr in currentMessageList ) {
							let message = currentMessageList[ indexStr ];
							fullList[ indexStr ] = message;
							currentIds[ message.id ] = 1;
						}

						for ( let i = 0; i < sending.length; i++ ) {
							let { id } = sending[ i ];
							if ( !currentSendingIds[ id ] ) {
								currentSendingIds[ id ] = 1;
								currentSending.push( sending[ i ] );
							}
						}
						{
							let i = 0;
							while ( i < currentSending.length ) {
								if ( currentIds[ currentSending[ i ].id ] ) {
									currentSending.splice( i, 1 );
								} else {
									i++;
								}
							}
						}

						for ( let messageId in sendingFiles ) {
							if ( currentIds[ messageId ] ) {
								continue;
							}
							let fileInfo = sendingFiles[ messageId ];
							let found = _.find( currentSending, { id: messageId } );
							if ( !found ) {
								currentSending.push( {
									type: "file",
									id: messageId,
									...fileInfo
								} );
								currentSendingIds[ messageId ] = 1;
							} else {
								_.assign( found, fileInfo );
							}
						}
						return { messages: fullList, sending: currentSending };
					} )
			)
		);
	}

	observeGroupList( ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._commonObservable.map( ( { g } ) => g )
		);
	}

	createGroupAsync( name, nickname, contactIds ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		if ( !contactIds || !contactIds.length ) {
			throw new Error( "ContactIds required" );
		}
		if ( !name || ( typeof name !== "string" ) ) {
			throw new Error( "Name required" );
		}
		if ( !nickname || ( typeof nickname !== "string" ) ) {
			throw new Error( "Nickname required" );
		}
		return (
			this._rcc.createGroupAsync( name, nickname, contactIds )
				.tap( group => {
					group.type = "group";
				} )
		);
	}

	observeGroupParticipants( contact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.observeGroupParticipants( contact )
		);
	}

	observeGroupInvites( contact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.observeGroupInvites( contact )
		);
	}

	renameGroupParticipantAsync( contact, pid, newNickname ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.renameGroupParticipantAsync( contact, pid, newNickname )
		);
	}

	deleteGroupParticipantAsync( contact, pid ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.deleteGroupParticipantAsync( contact, pid )
		);
	}

	deleteGroupInviteAsync( contact, pid ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.deleteGroupInviteAsync( contact, pid )
		);
	}

	exitGroupAsync( contact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.exitGroupAsync( contact )
		);
	}

	addGroupParticipantsAsync( group, contactIds ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.addGroupParticipantsAsync( group, contactIds )
		);
	}

	addGroupParticipantExternalAsync( group, nickname ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.addGroupParticipantExternalAsync( group, nickname )
		);
	}

	observeProfile( ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._commonObservable
				.map( ( { p } ) => p )
				.filter( p => !_.isEmpty( p ) )
		);
	}

	exitMultidescriptionAsync( contact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.exitMultidescriptionAsync( contact )
		);
	}

	updateProfileAsync( profileChanges ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._rcc.updateProfileAsync( profileChanges )
		);
	}

	observeChats( ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}

		return (
			this._commonObservable.map(
				( { c, g, m, timeDiff, clientTimeStamp, serverTimeStamp } ) => _.flattenDeep( [
					_.filter( c, ( { cachedMessages, autocleanTime } ) =>
				 		this._anyUnfilteredMessages( autocleanTime, timeDiff, clientTimeStamp, serverTimeStamp, cachedMessages )
					),
					_.filter( g, ( { cachedMessages, autocleanTime } ) =>
						this._anyUnfilteredMessages( autocleanTime, timeDiff, clientTimeStamp, serverTimeStamp, cachedMessages )
					),
					_.map( m, ( { sharedList } ) => _.filter( sharedList, ( { cachedMessages, autocleanTime } ) =>
						this._anyUnfilteredMessages( autocleanTime, timeDiff, clientTimeStamp, serverTimeStamp, cachedMessages )
					) )
				] )
			)
		);
	}

	_anyUnfilteredMessages( autocleanTime, timeDiff, clientTimeStamp, serverTimeStamp, messages ) {
		if ( !autocleanTime ) {
			return !_.isEmpty( messages );
		}

		let minTs = +new Date - clientTimeStamp + serverTimeStamp + timeDiff - autocleanTime * 1000;
		return _.some( messages, ( { ttlBaseTimestamp, timestamp } ) =>
			!ttlBaseTimestamp || ( ttlBaseTimestamp > minTs )
		);
	}

	_filterMessages( autocleanTime, timeDiff, clientTimeStamp, serverTimeStamp, messages ) {
		if ( !autocleanTime ) {
			return messages;
		}
		let minTs = +new Date - clientTimeStamp + serverTimeStamp + timeDiff - autocleanTime * 1000;
		return _.pickBy( messages, ( { ttlBaseTimestamp, timestamp } ) =>
			!ttlBaseTimestamp || ( ttlBaseTimestamp > minTs )
		);
	}

	serverTimeStampToLocalAsync( timestamp ) {
		return this._commonObservable.map(
			( { p, timeDiff, clientTimeStamp, serverTimeStamp } ) =>
				timestamp + clientTimeStamp - serverTimeStamp - timeDiff
		).take( 1 );
	}

	createFakeAccountAsync( name, password ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.createFakeAccountAsync( name, password );
	}

	changePasswordAsync( password ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.changePasswordAsync( password );
	}

	clearHistoryAsync( contact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.clearHistoryAsync( contact );
	}

	clearRemoteHistoryAsync( contact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.clearRemoteHistoryAsync( contact );
	}

	setReadAllAsync( contact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.setReadAllAsync( contact );
	}

	queryHistoryAsync( contact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.queryHistoryAsync( contact );
	}

	observeSharedContacts( ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._commonObservable.map( ( { m } ) => m )
		);
	}

	observeUnreadContacts( ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._commonObservable
				.map( ( { c } ) =>
					_.reduce(
						c,
						( sum, contact ) => sum + contact.unreadCount,
						0
					)
				)
				.distinctUntilChanged()
		);
	}

	observeUnreadGroups( ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._commonObservable
				.map( ( { g } ) =>
					_.reduce(
						g,
						( sum, contact ) => sum + contact.unreadCount,
						0
					)
				)
				.distinctUntilChanged()
		);
	}

	observeUnreadShared( ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			this._commonObservable
				.map( ( { m } ) =>
					_.reduce(
						m,
						( sum, sharedcontact ) => sum + _.reduce(
							sharedcontact.sharedList,
							( sum, contact ) => sum + contact.unreadCount,
							0
						),
						0
					)
				)
				.distinctUntilChanged()
		);
	}

	addHelperContactAsync( name ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.addHelperContactAsync( name );
	}

	addSharedContactListAsync( nickname, name, workgroupIds, contactIds, rights ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.addSharedContactListAsync( nickname, name, workgroupIds, contactIds, rights );
	}

	observeSelfRights( multidescription ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.observeSelfRights( multidescription );
	}

	observeWorkgroupRights( multidescription ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.observeWorkgroupRights( multidescription );
	}

	observeWorkgroupParticipants( multidescription ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.observeWorkgroupParticipants( multidescription );
	}

	observeWorkgroupInvites( multidescription ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.observeWorkgroupInvites( multidescription );
	}

	removeWorkgroupParticipantAsync( multidescription, pid ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.removeWorkgroupParticipantAsync( multidescription, pid );
	}

	removeWorkgroupInviteAsync( multidescription, pid ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.removeWorkgroupInviteAsync( multidescription, pid );
	}

	addToWorkGroupAsync( multidescription, contact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.addToWorkGroupAsync( multidescription, contact );
	}

	setWorkgroupRightsAsync( multidescription, modifiedRights ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		let rightsArray = [];
		for ( let pid in modifiedRights ) {
			rightsArray.push( {pid, rights: modifiedRights[ pid ] } );
		}
		return this._rcc.setWorkgroupRightsAsync( multidescription, rightsArray );
	}

	shareContactsAsync( contactIds, multidescriptionId ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}

		return this._rcc.shareContactsAsync( contactIds, multidescriptionId );
	}

	observeOnlineStatus() {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.observeOnlineStatus();
	}

	addSessionAsync( connectData ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return (
			serializeObjectWithKeysToNewManagedBufferAsync(
				connectData,
				connectKeyMap
			)
			.flatMap( mb => Rx.Observable.create( observer => {
				mb.useAsBuffer( b => {
					observer.onNext( b.toString( "base64" ) );
					observer.onCompleted();
				} )
			} ) )
			.flatMap( sessionDataString => this._rcc.addSessionAsync( sessionDataString ) )
		);
	}

	deleteAccountAsync( ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.deleteAccountAsync();
	}

	acceptInviteByInviteIdAsync( inviteId, fromContact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.acceptInviteByInviteIdAsync( inviteId, fromContact );
	}

	joinGroupByInviteIdAsync( inviteId ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.joinGroupByInviteIdAsync( inviteId );
	}

	joinWorkgroupByInviteIdAsync( inviteId ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.joinWorkgroupByInviteIdAsync( inviteId );
	}

	observeUserTable( ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.observeUserTable();
	}

	observeGlobalUserType( ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._commonObservable.map(
			( { isAdmin, isPrivileged, expireDt } ) =>
				( { isAdmin, isPrivileged, expireDt } )
		);
	}

	addUserAsync( user ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.addUserAsync( user );
	}

	editUserAsync( user ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.editUserAsync( user );
	}

	deleteUserAsync( userId ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.deleteUserAsync( userId );
	}

	sendDeleteMessageAsync( message, contact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.sendDeleteMessageAsync(
			message.replaceIndex === undefined ? message.index : message.replaceIndex,
			contact.id,
			contact.type
		);
	}

	sendEditTextMessageAsync( message, newText, contact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.sendEditTextMessageAsync(
			message.replaceIndex === undefined ? message.index : message.replaceIndex,
			newText,
			contact.id,
			contact.type
		);
	}

	getPrivateServerConfigAsync( ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.getPrivateServerConfigAsync();
	}

	muteContactNotification( contact ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		let muteIdAsync = new Rx.ReplaySubject( 1 );
		this._mutedContactIdHash[ contact.id ] = 1;
		this._rcc.muteContactNotificationAsync( contact.id, contact.type )
			.subscribe(
				( mute ) => {
					mute && muteIdAsync.onNext( mute.muteId );
					muteIdAsync.onCompleted();
				},
				error => {
					delete this._mutedContactIdHash[ contact.id ];
					muteIdAsync.onNext( false );
					muteIdAsync.onCompleted();
				}
			);
		return {
			dispose: () => {
				muteIdAsync.subscribe( muteId => {
					delete this._mutedContactIdHash[ contact.id ];
					if ( ( muteId === false ) || ( !this._rcc ) ) {
						return;
					}
					this._rcc.unmuteContactNotificationAsync( muteId )
						.subscribe();
				} );
			}
		};
	}

	observeCommonData() {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.observeCommonData();
	}

	startShowingNotifications() {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		if ( this._notificationSubscriptions || !global.Notification
			|| global.Notification.permission !== "granted" ) {
			return;
		}
		this._notificationSubscriptions = (
			this._rcc.observeNewMessages()
				.subscribe( ( { contactId, message, sender } ) => {
					if ( !global.Notification
						|| ( !global.document.hidden && this._mutedContactIdHash[ contactId ] )
						|| global.localStorage.notifications_muted ) {
						return;
					}
					this.showNotification( contactId, message, sender );
				} )
		);
	}

	showNotification( contactId, message, sender ) {
		let body = null;
		switch( message.contentType ) {
			case "text/plain":
				body = message.text;
				break;
			case "contact":
				body = "New contact";
				break;
			default:
				body = "File";
				break;
		}
		if ( !body ) {
			return;
		}
		if ( !this._notificationsPerContact[ contactId ] ) {
			this._notificationsPerContact[ contactId ] = [];
		}
		let notif = new global.Notification( sender, { body/*, requireInteraction: true*/ } );
		this._notificationsPerContact[ contactId ].push( notif );
		notif.onclick = () => {
			//TODO: open chat
			// global.location.href = `#chats/${contactId}`;
			history.navigateTo( `chats/${contactId}` );
			notif.close();
			global.window.focus();
		};
		notif.onclose = () => {
			let index = this._notificationsPerContact[ contactId ].indexOf( notif );
			if ( !~index ) {
				return;
			}
			this._notificationsPerContact[ contactId ].splice( index, 1 );
		};
	}

	closeNotificationsForContact( contactId ) {
		if ( !this._notificationsPerContact[ contactId ] ) {
			return;
		}
		_.forEach( this._notificationsPerContact[ contactId ], notif => {
			notif.close();
		} );
	}

	sendContactsToUserAsync( user, userIds ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}

		return this._rcc.sendContactsToUserAsync( user.userId, userIds );
	}

	businessCardCreateAsync( name, description ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.businessCardCreateAsync( name, description );
	}

	businessCardUpdateAsync( card, name, description ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.businessCardUpdateAsync( card.token, name, description );
	}

	businessCardDeleteAsync( card ) {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.businessCardDeleteAsync( card.token );
	}

	observeBusinessCards() {
		if ( !this._rcc ) {
			throw new Error( "Connection not done" );
		}
		return this._rcc.observeBusinessCards();
	}
}

export default RemoteService;
