import React from "react";
import _ from "lodash";
import { ButtonToolbar, SplitButton, MenuItem, Dropdown } from "react-bootstrap";
import Translation from "../../../components/translation.jsx";

class MenuView extends React.Component {
	render() {
		let {id, className, icon, iconAlt, iconTitle, items} = this.props;
		return (
			<Dropdown id={id} style={{position: "initial"}} componentClass={"div"}>
				<Dropdown.Toggle noCaret={true} useAnchor={true} className={className}>
					<img src={icon} alt={iconAlt} title={iconTitle}/>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{
						_.map(
							items,
							( {textId, handler}, index ) =>
							<MenuItem eventKey={index} key={index} onClick={handler}>
								<Translation textId={ textId } />
							</MenuItem>
						)
					}
				</Dropdown.Menu>
			</Dropdown>
		);
	}

	static get propTypes() {
		return {
			id: React.PropTypes.string.isRequired,
			className: React.PropTypes.string,
			icon: React.PropTypes.string.isRequired,
			iconAlt: React.PropTypes.string,
			iconTitle: React.PropTypes.string,
			items: React.PropTypes.arrayOf( React.PropTypes.shape( {
				textId: React.PropTypes.string.isRequired,
				handler: React.PropTypes.func.isRequired
			} ) ).isRequired
		};
	}

	static get defaultProps() {
		return {
			iconAlt: "",
			iconTitle: ""
		};
	}
}

export default MenuView;
