import React from "react";
import Translation from "../../../components/translation.jsx";


class CreateProgressContentView extends React.Component {
	render() {
		return (
			<div className={this.props.className}>
				<Translation textId="web.send.contacts.progress.text" />
			</div>
		);
	}
}

export default CreateProgressContentView;
