import Rx from "rx";
import _ from "lodash";
import ssgCrypto, {Key, KEY_KINDS} from "ssg.crypto";

export function serializeObjectWithKeysToNewManagedBufferThen( obj, keyKindMap ) {
	//TODO: refactor this method
	let managedBuffer = null;
	let buffers = [];
	let index = 0;

	_.forEach( keyKindMap, ( kind, path ) => {
		let key = _.get( obj, path );
		if ( key === undefined ) {
			return;
		}
		if ( kind === "UNCONTROLLED" ) {
			return;
		}
		if ( !( key instanceof Key ) ) {
			throw new Error( `${path} expected to be a key` );
		}
		if ( key.kind !== kind ) {
			throw new Error( `Key ${path} kind mismatch. Expected ${kind.name}, got ${key.kind.name}` );
		}
		if ( key.isDisposed() ) {
			throw new Error( `Cannot serialize a disposed key` );
		}
	} );

	let keyHandles = _.mapValues(
		keyKindMap,
		( kind, path ) => {
			let key = _.get( obj, path );
			if ( kind === "UNCONTROLLED" ) {
				//TODO: add key use for all keys inside
				return;
			}
			return key && key.addKeyUse( "serialize" );
		}
	);

	function travel( value, path, isUncontrolled ) {
		if ( keyKindMap[ path ] === "UNCONTROLLED" ) {
			isUncontrolled = true;
		}
		if ( Buffer.isBuffer( value ) ) {
			let f = func => func( value );
			f.byteLength = value.length;
			buffers.push( f );
			let str = `${index}/${value.length}`;
			index += value.length;
			return { _b: str };
		}
		if ( value instanceof Key ) {
			if ( !isUncontrolled && !keyKindMap.hasOwnProperty( path ) ) {
				throw new Error( `Key ${path} not found in the list of keys` );
			}
			let f = func => value.postponeManagedBuffer( mb => mb.useAsBuffer( func ) );
			f.byteLength = value.byteLength;
			buffers.push( f );
			let str = `${index}/${value.byteLength}`;
			index += value.byteLength;
			return { _k: str, k: value.kind.code };
		}
		if ( _.isArray( value ) ) {
			return _.map( value, ( val, key ) =>
				travel( val, path ? path + "[" + key + "]" : "[" + key + "]", isUncontrolled )
			);
		}
		if ( typeof value === "object" && value ) {
			if ( value && value.toJson ) {
				value = value.toJson();
			}
			return _.mapValues( value, ( val, key ) =>
				travel( val, path ? path + "." + key : key, isUncontrolled )
			);
		}
		return value;
	}
	let json = travel( obj, "" );
	let strJSON = JSON.stringify( json );
	let jsonByteLength = Buffer.byteLength( strJSON );

	managedBuffer = ssgCrypto.createManagedBuffer( jsonByteLength + index + 1 );
	managedBuffer.useAsBuffer( buffer => {
		buffer.write( strJSON );
		index = jsonByteLength + 1;
		buffer[ jsonByteLength ] = 0;
	} );

	let leftToWrite = buffers.length;
	if ( !leftToWrite ) {
		return Promise.resolve( managedBuffer );
	}
	return new Promise( resolve => {
		for ( var i = 0; i < buffers.length; i++ ) {
			buffers[ i ]( ( ( index ) => smallBuffer => {
				managedBuffer.useAsBuffer( buffer => {
					smallBuffer.copy( buffer, index );
					leftToWrite--;
					if ( !leftToWrite ) {
						_.forEach( keyHandles,
							( handle, path ) => handle && _.get( obj, path ).removeKeyUse( handle )
						)
						resolve( managedBuffer );
					}
				} )
			} ) ( index ) );
			index += buffers[ i ].byteLength;
		}
	} );
}

export function serializeObjectWithKeysToNewManagedBufferAsync( obj, keyKindMap ) {
	return Rx.Observable.fromPromise(
		serializeObjectWithKeysToNewManagedBufferThen( obj, keyKindMap )
	);
}

export function deserializeObjectWithKeysFromManagedBufferAsync( managedBuffer, keyKindMap, config ) {
	return Rx.Observable.fromPromise(
		deserializeObjectWithKeysFromManagedBufferThen( managedBuffer, keyKindMap, config )
	);
}

export function deserializeObjectWithKeysFromManagedBufferThen( managedBuffer, keyKindMap, config ) {
	let waitPromises = [];
	let result = "Deserialization result not set";
	managedBuffer.useAsBuffer( buffer => {
		let zeroIndex = buffer.indexOf( 0 );
		if ( !~zeroIndex ) {
			resultAsync.onError( new Error( "Invalid buffer content" ) );
			return;
		}
		let json;
		try {
			let str = buffer.toString( "utf8", 0, zeroIndex );
			json = JSON.parse( str );
		} catch( e ) {
			resultAsync.onError( e );
			return;
		}

		function travel( value, path ) {
			if ( _.has( value, "_b" ) ) {
				let [ index, length ] = value._b.split( "/" );
				index |= 0;
				length |= 0;
				let buf = new Buffer( length );
				buffer.copy( buf, 0, zeroIndex + index + 1 );
				return buf;
			}

			if ( _.has( value, "_k" ) ) {
				let [ index, length ] = value._k.split( "/" );
				let kind = KEY_KINDS.byCode( value.k );
				index |= 0;
				length |= 0;
				return ssgCrypto.createKeyFromBufferThen(
					buffer.slice( zeroIndex + index + 1, zeroIndex + length + index + 1 ),
					kind,
					config
				);
			}

			if ( _.isArray( value ) ) {
				return _.map( value, ( val, key ) =>
					travel( val, path ? path + "[" + key + "]" : "[" + key + "]" )
				);
			}

			if ( typeof value !== "object"  || !value ) {
				return value;
			}

			let result = {};
			_.forEach( value, ( val, key ) => {
				let newVal = travel( val, path ? path + "." + key : key );
				if ( newVal && ( typeof newVal.subscribe === "function" ) ) {
					waitPromises.push( new Promise( resolve => {
						newVal.subscribe( newValSync => {
							result[ key ] = newValSync;
							resolve();
						} );
					} ) );
					return;
				}
				if ( newVal && ( typeof newVal.then === "function" ) ) {
					waitPromises.push(
						newVal.then( newValSync => {
							result[ key ] = newValSync;
						} )
					);
					return;
				}
				result[ key ] = newVal
			} );
			return result;
		}
		result = travel( json, "" );
	} );
	return Promise.all( waitPromises ).then( () => result );
}

export function serializeObject( obj, requiredSizeOrBuffer ) {
	let buffer = null;
	if ( Buffer.isBuffer( requiredSizeOrBuffer ) ) {
		buffer = requiredSizeOrBuffer;
	} else if ( requiredSizeOrBuffer ) {
		buffer = new Buffer( requiredSizeOrBuffer );
	}

	let buffers = [];
	let index = 0;

	function travel( value ) {
		if ( Buffer.isBuffer( value ) ) {
			buffers.push( value );
			let str = `${index}/${value.length}`;
			index += value.length;
			return { _b: str };
		}
		if ( value instanceof Key ) {
			throw new Error( "Cannot serialize Key", obj );
		}
		if ( typeof value === "object" ) {
			return _.mapValues( value, val => travel( val ) );
		}
		return value;
	}
	let json = travel( obj );
	let strJSON = JSON.stringify( json );
	let jsonByteLength = Buffer.byteLength( strJSON );
	if ( buffer && buffer.length < index + jsonByteLength + 1 ) {
		throw new Error( "buffer too small" );
	}

	if ( !buffer ) {
		buffer = new Buffer( jsonByteLength + index + 1 );
	}
	buffer.write( strJSON );
	index = jsonByteLength + 1;
	buffer[ jsonByteLength ] = 0;
	for ( var i = 0; i < buffers.length; i++ ) {
		buffers[ i ].copy( buffer, index );
		index += buffers[ i ].length;
	}
	return buffer;
}

export function deserializeObject( buffer ) {
	let zeroIndex = buffer.indexOf( 0 );
	let json = JSON.parse( buffer.toString( "utf8", 0, zeroIndex ) );

	function travel( value ) {
		if ( _.has( value, "_b" ) ) {
			let [ index, length ] = value._b.split( "/" );
			index |= 0;
			length |= 0;
			let buf = new Buffer( length );
			buffer.copy( buf, 0, zeroIndex + index + 1 );
			return buf;
		}
		if ( typeof value !== "object" ) {
			return value;
		}
		return _.mapValues( value, val => travel( val ) );
	}
	return travel( json );
}

export function deserializeJSONOnlyFromManagedBuffer( managedBuffer ) {
	let json;
	managedBuffer.useAsBuffer( buffer => {
		let zeroIndex = buffer.indexOf( 0 );
		json = JSON.parse( buffer.toString( "utf8", 0, zeroIndex ) );
	} );
	return json;
}
