import React from "react";

import ButtonQrView from "../../components/button.qr.jsx";
import Translation from "../../components/translation.jsx";
import ImageButton from "../../components/button.image.jsx";

class RegistrationHeader extends React.Component {
	constructor() {
		super();
		this.state = {
			qrCallback: null
		};
	}
	componentWillMount() {
		this._qcCallbackSubscription = (
			this.context.observeQrCallback().subscribe(qrCallback => {
				this.setState({qrCallback});
			})
		);
	}
	componentWillUnmount() {
		this._qcCallbackSubscription.dispose();
	}
	renderQrButton() {
		let {qrCallback} = this.state;
		if ( !qrCallback ) {
			return null;
		}
		return <ButtonQrView onScan={ qrCallback } />;
	}

	renderBackButton() {
		if ( !this.props.onBack ) {
			return null;
		}
		return <ImageButton type="icon-arrow-left" onClick={this.props.onBack}/>;
	}

	render() {
		return (
			<header className="small">
				{ this.renderBackButton() }
				<span className="header-caption">
					<Translation textId="registration.header" />
				</span>
				{ this.renderQrButton() }
			</header>
		);
	}

	static get contextTypes() {
		return { observeQrCallback: React.PropTypes.func.isRequired };
	}
}

export default RegistrationHeader;
