import React from "react";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import Clipboard from "clipboard";

import serviceLocator from "../../../../api/services/locators/worker.client.js";

import Toast from "../../../components/toast.jsx";
import Qr from "../../../components/qr.jsx";
import Prompt from "../common/prompt.jsx";
import MainTitle from "./main.title.jsx";
import MobileMenu from "./mobile.menu.jsx";
import MainContent from "./main.content.jsx";
import Translation from "../../../components/translation.jsx";
import Title from "../common/title.jsx";
import Tinput from "../common/tinput.jsx";
import FocusManager from "../../../components/focus.manager.js";
import history from "../../../components/history.js";
import Loading from "../common/loading.jsx";

class ExternalChatView extends React.Component {
    constructor() {
        super();
        this.state = {
            popup: null,
            isOnline: true,
            isDragging: false
        };
        this._service = serviceLocator();

        this._service.setStorageNumber( 0 ); //DO NOT store contacts
        this.clearHistory = this.clearHistory.bind( this );
        this.addExternal = this.addExternal.bind( this );
        this.newGroup = this.newGroup.bind( this );
        this.exit = this.exit.bind( this );
        this.delete = this.delete.bind( this );
        this.onCloseModal = this.onCloseModal.bind( this );
        this.doAddExternal = this.doAddExternal.bind( this );
        this.onDrop = this.onDrop.bind( this );
    		this.onDragEnter = this.onDragEnter.bind( this );
    		this.onDragLeave = this.onDragLeave.bind( this );
    		this.onDragOver = this.onDragOver.bind( this );
    }

    componentDidMount() {
  		this._clipboard = new Clipboard( "#copy" );
  		this._clipboard.on( "success", event => {
  			this.refs.copied.show();
  			event.clearSelection();
  		} );
  		this._clipboard.on( "error", event => {
  			this.refs.copy_failed.show();
  		} );
  	}

  	componentWillUnmount() {
  		this._clipboard.destroy();
  	}

    onDrop( event ) {
  		let { contact } = this.props;
  		event.stopPropagation();
  		event.preventDefault();
  		if ( !contact ) {
  			return;
  		}
  		let {files} = event.dataTransfer;
  		let fileArray = [];
  		for ( let i = 0; i < files.length; i++ ) {
  			fileArray.push( files[ i ] );
  		}
  		let isAlerted = false;
  		Rx.Observable.fromArray( fileArray )
  			.concatMap( file => this._service.sendFileAsync( contact, file ).catch( e => {
  				isAlerted || alert( e.message );
  				isAlerted = true;
  				return Rx.Observable.empty();
  			} ) )
  			.toArray()
  			.subscribe();
  		this.setState( { isDragging: false } );
  	}

  	onDragEnter( event ) {
  		this.setState( { isDragging: true } );
  		event.preventDefault();
  		event.stopPropagation();
  	}

  	onDragLeave( event ) {
  		this.setState( { isDragging: false } );
  		event.preventDefault();
  		event.stopPropagation();
  	}

  	onDragOver( event ) {
  		this.setState( { isDragging: true } );
  		event.preventDefault();
  		event.stopPropagation();
  	}

    clearHistory() {
        this._service.clearHistoryAsync( this.props.contact ).subscribe();
    }

    exit() {
        this._service.exitGroupAsync( this.props.contact ).subscribe();
    }

    delete() {
        this._service.deleteContactAsync( this.props.contact ).subscribe();
    }

    addExternal() {
        this.setState( {
            popup: () => <Prompt
					titleTextId="web.group.external.nickname"
					defaultValue={ "" }
					onDone={ this.doAddExternal }
				/>
        } );
    }

    onCloseModal() {
        this.setState( { popup: null } );
    }

    renderQrModal( token ) {
        return (
            <div className="static-modal">
                <Modal.Dialog style={{ overflow: "auto", maxHeight: "100%" }}>
                    <Modal.Header>
                        <Modal.Title>
                            <Translation textId="group.external.qr.bigtext" />
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Qr code={token} className="qr-code"/>
                        <br />
                        <Translation textId="group.external.plain.text" />
                        <br />
                        <span className="code" id="invite_token">{token}</span>
                    </Modal.Body>

                    <Modal.Footer>
                      <a
                        href="javascript:;"
                        id="copy"
                        data-clipboard-target="#invite_token"
                        style={{paddingRight: "140px"}}
                      >
                        <Translation textId="web.invite.copy.button" />
                      </a>

                        <Button onClick={ this.onCloseModal }>
                            <Translation textId="web.users.activation.close.button" />
                        </Button>
                    </Modal.Footer>
                    <Toast textId="web.invite.copied.toast" ref="copied"/>
            				<Toast textId="web.invite.copy_failed.toast" ref="copy_failed"/>
                </Modal.Dialog>
            </div>
        );
    }

    renderErrorModal( error ) {
        return (
            <div className="static-modal">
                <Modal.Dialog>
                    <Modal.Header>
                        <Modal.Title>
                            <Translation textId="common.error" />
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        { error || "Error" }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={ this.onCloseModal }>
                            <Translation textId="web.users.activation.close.button" />
                        </Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </div>
        );
    }

    doAddExternal( nickname ) {
        this.setState( { popup: null } );
        if ( !nickname ) {
            return null;
        }
        this._service.addGroupParticipantExternalAsync( this.props.contact, nickname )
            .subscribe( ( token ) => {
                this.setState( {
                    popup: () => this.renderQrModal( token )
                } );
            }, error => {
                this.setState( {
                    popup: () => this.renderErrorModal( error.message )
                } );
                console.error( error );
            } );
    }

    newGroup() {
        window.open( "#external" );
    }

    renderPopup() {
        if ( !this.state.popup ) {
            return null;
        }
        return this.state.popup();
    }

    renderWindowTitle() {
        const { contact } = this.props;
        if ( contact.type === "group" ) {
            return <Title textId="web.group.external.title" />;
        }
        return <Title textId="web.contact.external.title" />;
    }

    renderOfflinePopup() {
		if ( this.state.isOnline ) {
			return null;
		}
		return (
			<div className="static-modal" style={{zIndex: 100}}>
				<Modal.Dialog>
					<Modal.Header>
						<Modal.Title>
							<Translation textId="web.offline.title"/>
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Translation textId="web.offline.text"/>
					</Modal.Body>

					<Modal.Footer>
					</Modal.Footer>
				</Modal.Dialog>
		  	</div>
		);
	}

    renderJoining() {
        return (
            <div style={{width: "100%", height: "100%"}}>
                <Translation textId="web.group.external.joining"/>
            </div>
        );
    }

    renderFailed() {
        return (
            <div style={ { width: "100%", height: "100%" } }>
                <Translation textId="web.group.external.failed"/>
            </div>
        );
    }

    renderExited() {
      return (
        <div style={ { width: "100%", height: "100%" } }>
          <Translation textId="web.group.external.exited"/>
        </div>
      );
    }

    renderActive() {
        let {contact, status} = this.props;
        return (
    			<div
            className={ this.state.isDragging ? "dragging" : "" }
            style={{width: "100%", height: "100%"}}
            onDrop={this.onDrop}
            onDragEnter={this.onDragEnter}
            onDragLeave={this.onDragLeave}
            onDragOver={this.onDragOver}
          >
            <header className="container">
              <div className="logo hidden-xs">
                <img src="web/img/ico-02.svg" alt="FM" />
              </div>
              <MainTitle
                contact={ contact }
	              onClearHistory={this.clearHistory}
                onExit={this.exit}
                onDelete={this.delete}
                onAddExternal={this.addExternal}
                onNewGroup={this.newGroup}
              />
            </header>
		        <div className="container main">
              <div className="sidebar">
			          <MobileMenu />
			         </div>
			          <MainContent
                  contact={contact}
                />
		        </div>
            { this.renderPopup() }
    				{ this.renderWindowTitle() }
    				{ this.renderOfflinePopup() }
    			</div>
        );
    }

	render() {
		switch( this.props.status ) {
			case "joining":
				return this.renderJoining();
			case "active":
				return this.renderActive();
      case "failed":
          return this.renderFailed();
      case "exited":
          return this.renderExited();
			default:
				return <span>{this.props.status}!</span>;
		}
	}
}

class GroupView extends React.Component {
    constructor( props ) {
        super( props );
        this._service = serviceLocator();
        this._focusManager = new FocusManager();
        this.state = {
            status: "registering"
        };
        this.onError = this.onError.bind( this );
        this.onAddNewGroup = this.onAddNewGroup.bind( this );

        this._service.writeRandomSeedAsync( "", null )
            .flatMap( () => this._service.registerAndLoginAsync(
    			{ password1: "" },
    			{ autoclean: 3600 },
    			null
    		) )
			.subscribe( () => { this.registered(); }, this.onError );
    }

	static get childContextTypes() {
		return { focusManager: React.PropTypes.object.isRequired };
	}

	getChildContext() {
		return { focusManager: this._focusManager };
	}

    registered() {
        let { token } = this.props;
        if ( token ) {
            this._service.joinByTokenAsync( token )
                .subscribe( ( contact ) => {
                    this.startChat( contact );
                }, this.onError );
        } else {
            this.setState( {
                status: "create"
            } );
        }
    }

    startChat( contact ) {
        if ( !contact ) {
            window.location.href = "https://google.com";
            this.setState( {
                status: "failed"
            } );
            return;
        }
        history.clearTheBar( "/");
        this.setState( {
            status: "active",
            contact
        } );
        if ( contact.type === "group" ) {
            this._contactListSubscription = (
                this._service.observeGroupList()
                    .subscribe( contacts => {
                        if ( !_.find( contacts, { id: this.state.contact.id } ) ) {
                            this.setState( { status: "exited", contact: null } );
                            return;
                        }
                        this.forceUpdate();
                    } )
            );
        } else {
            this._contactListSubscription = (
                this._service.observeContactList()
                    .subscribe( contacts => {
                        if ( !_.find( contacts, { id: this.state.contact.id } ) ) {
                            this.setState( { status: "exited", contact: null } );
                            return;
                        }
                        this.forceUpdate();
                    } )
            );
        }
    }

    onError( error ) {
        this.setState( {
            status: "failed"
        } );
        console.error( error );
    }

    renderNewGroupForm() {
        return <NewGroupFormView onAdd={ this.onAddNewGroup } />;
    }

    onAddNewGroup( name, nickname ) {
        this._service.createGroupAsync( name, nickname, [] )
            .flatMap( ( contact ) => this._service.observeGroupList()
                .filter( ( contacts ) => !!_.find( contacts, { id: contact.id } ) )
                .first()
                .map( () => contact )
            )
            .subscribe( ( contact ) => {
                this.startChat( contact );
            } );
    }

    render() {
        switch( this.state.status ) {
            case "registering":
                return (
                    <div style={{width: "100%", height: "100%"}}>
                        <Loading />
                    </div>
                );
            case "create":
                return this.renderNewGroupForm();
            default:
                return <ExternalChatView
                    contact={ this.state.contact }
                    status={ this.state.status }
                />
        }
    }
}

class NewGroupFormView extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            name: "", nickname: "", isInProgress: false
        };
        this.onAddClick = this.onAddClick.bind( this );
        this.onChangeName = this.onChangeName.bind( this );
        this.onChangeNickname = this.onChangeNickname.bind( this );
    }

    onAddClick() {
        this.setState( {
            isInProgress: true
        } );
        this.props.onAdd( this.state.name.trim(), this.state.nickname.trim() );
    }

    onChangeName( name ) {
        if ( this.state.isInProgress ) {
            return;
        }
        this.setState( { name } );
    }

    onChangeNickname( nickname ) {
        if ( this.state.isInProgress ) {
            return;
        }
        this.setState( { nickname } );
    }

    isButtonDisabled() {
      let { isInProgress, nickname, name } = this.state;
      return isInProgress || !nickname.trim() || !name.trim();
    }

    render() {
        return (
            <div style={{width: "100%", height: "100%"}}>
                <Translation textId="web.group.external.name.text" />:
                <Tinput
                    type="text"
                    value={ this.state.name }
                    onChange={ this.onChangeName }
                    placeholderTextId="web.group.external.name.placeholder"
                    style={ { width: "100%" } }
                /><br />

                <Translation textId="web.group.external.nickname.text" />:
                <Tinput
                    type="text"
                    value={ this.state.nickname }
                    onChange={ this.onChangeNickname }
                    placeholderTextId="web.group.external.nickname.placeholder"
                    style={ { width: "100%" } }
                /><br />
                <button
                    className="btn btn-blue"
                    onClick={ this.onAddClick }
                    disabled={ this.isButtonDisabled() }
                >
                    <Translation textId="web.group.external.create.group.button" />
                </button>
            </div>
        );
    }
}

export default GroupView;
