import React from "react";
import ReactDOM from "react-dom";

import Translated from "../../../components/abstract/translated.jsx";
import TextInput, {PasswordInput, MultiLineInput} from "./input.jsx";
import Checkbox from '../../../components/checkbox.jsx';
import Translation from "../../../components/translation.jsx";

class TranslatedInputComponent extends Translated {
	constructor() {
		super();
		this.state = {
			showPassword: false,
			useVirtualKeyboard: false
		};
	}

	componentWillMount() {
		this._useKeyboardSubscription = (
			this.context.focusManager.observeUseVirtualKeyboard()
				.subscribe( useVirtualKeyboard => {
					this.setState( { useVirtualKeyboard } );
				} )
		);
	}

	componentWillUpdate() {
		this.textAreaAjust();
	}

	componentDidMount() {
		if ( this.props.autofocus ) {
			this.refs.input && this.refs.input.focus();
		}
		this.textAreaAjust();
	}

	componentWillUnmount() {
		this._useKeyboardSubscription.dispose();
	}

	textAreaAjust() {
		//TODO: move to auto resizable textare component
		if ( this.props.type !== "multiline" || this.state.useVirtualKeyboard || !this.refs.input ) {
			return;
		}
		let ta = ReactDOM.findDOMNode( this.refs.input );
		ta.style.height = "auto";
	    ta.style.height = Math.min( 144, ta.scrollHeight )+"px";
	}

	static get contextTypes() {
		return { focusManager: React.PropTypes.object.isRequired };
	}

	toggleShowPassword() {
		this.setState({showPassword: ! this.state.showPassword});
	}

	render() {
		let { placeholderTextId, ...props } = this.props;
		props.placeholder = this.translate( this.props.placeholderTextId );
		if ( !this.state.useVirtualKeyboard ) {
			let { onChange, autofocus, ...inputProps } = props;
			inputProps.onChange = event => onChange && onChange( event.target.value );
			inputProps.value = inputProps.value || "";
			if ( inputProps.type === "multiline" ) {
				let { type, ...textAreaProps } = inputProps;
				return <textarea {...textAreaProps}
					ref="input"
				/>;
			}
			return <input {...inputProps} ref="input"/>;
		}
		switch ( props.type ) {
			case "password":
				return (
					<span>
						<PasswordInput {...this.props}
							placeholder={ this.translate( this.props.placeholderTextId ) }
							showPassword={!! this.state.showPassword}
							noEnter={true}
						/>

						<div className="label showPassword" >
							<Checkbox className="f-right " value={!! this.state.showPassword}
								onClick={this.toggleShowPassword.bind(this)}/>
							<Translation textId="show.password"/>
						</div>
					</span>
				);
			case "multiline":
				return <MultiLineInput {...props}/>;
			default:
				return <TextInput {...props}/>;
		}
	}
}

TranslatedInputComponent.propTypes = {
	placeholderTextId: React.PropTypes.string.isRequired
};
export default TranslatedInputComponent;
