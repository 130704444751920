import _ from "lodash";
import Rx from "rx";
import ssgCrypto,{KEY_KINDS, Config} from "ssg.crypto";

import Multicast from "../transport/multicast.js";
import Contact from "../models/contact.js";
import contactRepositoryLocator from "../repositories/locators/contact.js";
import configuration from "../../common/configuration.js";
import { sendEncryptedMessage } from "../../common/utils.js";
import {
	deserializeFromBase64StringAsync,
	serializeMessageToBase64StringAsync,
	sendMessageAsync
} from "../models/message/technical.js";

import HistorySynchronizer from "../transport/history.synchronizer.js";
import ContactsServiceCommon from "./contact.common.js";
import groupsServiceLocator from "./locators/group.js";
import contactServiceLocator from "./locators/contact.js";
import OneWayReceiver from "../transport/oneway.receiver.js";
import profileServiceLocator from "./locators/profile.js";
import messageServiceLocator from "./locators/message.js";
import adminServiceLocator from "./locators/admin.js";
import {decodeMessageToken} from "../models/message/address.codec.js";

class SharedContactsService extends ContactsServiceCommon {
	constructor( ) {
		super( "multidescription" );
		//TODO: error process
		this._onSharedContact = this._onSharedContact.bind( this );
		this._privatePidDictionary = Object.create( null );
		this._multiinviteConnections = Object.create( null );
		this._contactService = contactServiceLocator();
		this._createSharedContactsSubject = new Rx.Subject();
		this._messageService = messageServiceLocator();
		this._adminService = adminServiceLocator();
		this._historySynchronizers = Object.create( null ); //Per multidescription
		this._sharedContactsListenings = Object.create( null );
		this._createSharedContactsSubject.observeOn( Rx.Scheduler.default ).concatAll().subscribe();
	}

	initAsync( encryptionKey, positionBit ) {
		this._privateDataPerContact = Object.create( null );
		this._contactPrivateDataSubscriptions = Object.create( null );
		this._multiinviteSubscriptions = Object.create( null );
		this._sharedSendSubscriptions = Object.create( null );
		this._onContactsChangedSubscription = (
			this.observeContactsFullyLoad()
				.flatMap( () => this._contactService.observeContactsFullyLoad() )
				.flatMap( () => this._contactService.observeContactList() )
				.subscribe( contacts => this._onContactsChanged( contacts ) )
		);

		this._joinQueueCompletedSubj = new Rx.ReplaySubject();
		this._joinQueue = new Rx.Subject();
		this._joinQueue.concatAll().subscribeOnCompleted(
			() => {
				this._joinQueueCompletedSubj.onNext();
				this._joinQueueCompletedSubj.onCompleted();
			}
		);
		return super.initAsync( encryptionKey, positionBit );
	}

	uninitAsync( ) {
		if ( !this._privateDataPerContact ) {
			return Rx.Observable.just();
		}
		this._onContactsChangedSubscription.dispose();
		delete this._onContactsChangedSubscription;
		for ( let contactId in this._multiinviteSubscriptions ) {
			this._multiinviteSubscriptions[ contactId ].dispose();
		}
		delete this._multiinviteSubscriptions;
		for ( let contactId in this._contactPrivateDataSubscriptions ) {
			this._contactPrivateDataSubscriptions[ contactId ].dispose();
		}

		for ( let contactId in this._privateDataPerContact ) {
			this._privateDataPerContact[ contactId ].onCompleted();
		}

		for ( let contactId in this._sharedSendSubscriptions ) {
			this._sharedSendSubscriptions[ contactId ].dispose();
		}
		delete this._privateDataPerContact;
		delete this._contactPrivateDataSubscriptions;
		this._joinQueue.onCompleted();
		return this._joinQueueCompletedSubj.flatMap( () => super.uninitAsync() );
	}

	createSharedGroupAsync( contactName, nickname, workgroupContactIds, contactIds, rights, progressSubj ) {
		let multidescription, multicast;

		return (
			Contact.createNewAsync( this._contactType, this.getUnconflictedName( contactName ) )
				.tap( c => { multidescription = c; } )
				.flatMap( () => Rx.Observable.fromPromise( ssgCrypto.createRandomBufferThen( 32 ) ) )
				.tap( globalId => { multidescription.update( { status: "active", globalId } ); } )
				.flatMap( () => Multicast.createNewAsync(
						configuration.getSocketBase(),
						nickname,
						multidescription.sharedId,
						multidescription.pid,
						multidescription.seedKey,
						multidescription.dhPrivKey,
						multidescription.signKey,
						configuration.getDefaultEncryptionConfig(),
						this._getP2pPrivateHandlers(),
						this._isUseMetaWithRights(),
						this._isUseKeychain()
				) )
				.tap( m => { multicast = m; } )
				.flatMap( () => this._insertContactAsync( multidescription ) )
				.flatMap( () => this._addMulticastAsync( multidescription, Rx.Observable.just( multicast ) ) )
				.concatMap( () => Rx.Observable.fromArray( workgroupContactIds ) )
				.combineLatest( this._contactService.getContactsAsync(), ( id, contacts ) => _.find( contacts, { id } ) )
				.filter( contact => !!contact )
				.concatMap( contact => this._addToWorkGroupAsync( multicast, multidescription, contact, rights[ contact.id ] ) )
				.tap( () => { progressSubj && progressSubj.onNext( progressSubj.getValue() + 1 ); } )
				.toArray()
				.flatMap( () => this.shareContactsAsync( contactIds, multidescription.id ) )
				.flatMap( () => profileServiceLocator().getProfileAsync() )
				.flatMap( ( { autoclean } ) => this.setAutocleanTimeAsync( multidescription.id, autoclean ) )
				.map( () => { progressSubj && progressSubj.onCompleted(); return multidescription.id; } )
		);
	}

	addToWorkGroupAsync( multidescriptionId, contactId ) {
		return (
			Rx.Observable.combineLatest(
				this._getMulticastOrNullAsync( multidescriptionId ),
				this.getDetailedContactAsync( multidescriptionId ),
				this._contactService.getDetailedContactAsync( contactId ),
				( multicast, multidescription, contact ) => ( { multicast, multidescription, contact } )
			)
			.flatMap( ( { multicast, multidescription, contact } ) => multicast
				? this._addToWorkGroupAsync( multicast, multidescription, contact )
				: Rx.Observable.just( null )
			)
		);
	}

	shareContactsAsync( contactIds, multidescriptionId, progressSubj ) {
		return (
			Rx.Observable.combineLatest(
				this._getMulticastOrNullAsync( multidescriptionId ),
				this.getDetailedContactAsync( multidescriptionId ),
				( multicast, multidescription ) => ( { multicast, multidescription } )
			)
				.flatMap( data => Rx.Observable.fromArray( contactIds ).map(
					contactId => ( {...data, contactId } )
				) )
				.flatMap( ( { multicast, multidescription, contactId } ) => multicast
					? this._contactService.getDetailedContactAsync( contactId )
						.flatMap( baseContact =>
							this._contactService.createSharedContactAsync( baseContact, multidescription, multidescription.name )
								.flatMap( newContact => this._getPrivateDataEncryptionKeyAsync( multidescription, newContact )
									.flatMap( key => Rx.Observable.fromPromise(
										ssgCrypto.encryptThen(
											new Buffer( multicast.getPid(), "base64" ),
											key,
											false, //padding
											multicast.econfig
										)//.tap( () => key.dispose() )
									) )
									.flatMap( privateData =>
										this._contactService.shareContactAsync(
											newContact.id,
											multidescription.id,
											privateData
										)
									)
								)
						)
					: Rx.Observable.just( null )
				)
				.tap(
					() => { progressSubj && progressSubj.onNext( progressSubj.getValue() + 1 ); },
					() => { progressSubj && progressSubj.onCompleted(); }
				)
		);
	}

	_sendShareMessageAsync( multicast, contact ) {
		return (
			multicast.sendMessageAsync( {
				type: "share",
				content: {
					sharedId: contact.sharedId
					//TODO: add publicKey of creator and check it!!!
				}
			} )
		);
	}

	sendRenameContactMessageAsync( multidescriptionId, contactId, newName ) {
		return (
			Rx.Observable.combineLatest(
				this._getMulticastOrNullAsync( multidescriptionId ),
				this._contactService.getDetailedContactAsync( contactId ),
				( multicast, contact ) => ( { multicast, contact } )
			)
				.flatMap( ( { multicast, contact } ) => multicast
					? multicast.sendLatestMessageAsync( {
						type: "rename_contact",
						content: {
							sharedId: contact.sharedId,
							newName
						}
					} )
					: Rx.Observable.just( null )
				)
				.flatMap( res => {
					return (
						this._contactService._updateAsync( contactId, { name: newName } ) //Locally
							.map( () => res )
					);
				} )
		);
	}

	_getPrivateDataEncryptionKeyAsync( multidescriptionContact, sharedContact ) {
		//TODO: dispose cache data on delete
		if ( !this._privateDataEncryptionCache ) {
			this._privateDataEncryptionCache = {};
		}
		let {id} = sharedContact;
		if ( !this._privateDataEncryptionCache[ id ] ) {
			this._privateDataEncryptionCache[ id ] = new Rx.ReplaySubject();
			this._getMulticastOrNullThen( multidescriptionContact )
				.then( multicast => multicast
					? ssgCrypto.createDerivedKeyFromKeysThen(
						multidescriptionContact.seedKey,
						sharedContact.seedKey,
						KEY_KINDS.SYMMETRIC_ENCRYPTION,
						multicast.econfig
					)
					: null
				).then( key => {
					key && this._privateDataEncryptionCache[ id ].onNext( key );
					this._privateDataEncryptionCache[ id ].onCompleted();
				} );
		}
		return this._privateDataEncryptionCache[ id ];
	}

	_addToWorkGroupAsync( workgroupMulticast, multidescription, contact, rights ) {
		rights = rights || { allowModifyWorkgroup: true, allowModifyContacts: true };
		return (
			workgroupMulticast.createInviteAsync( contact.name, false, rights )
				.flatMap( invite => {
					invite.name = multidescription.name;
					if ( multidescription.globalId ) {
						invite.globalId = multidescription.globalId;
					}
					return this._workgroupInvite2StringAsync( invite );
				} )
				.flatMap( inviteStr => this._contactService.sendInviteToWorkgroupAsync( contact, inviteStr ) )
			);
	}

	_workgroupInvite2StringAsync( invite ) {
		return (
			serializeMessageToBase64StringAsync( { ...invite, type: "invite" } )
				.tap( () => {
					invite.tmpPrivateKey.dispose();
				} )
		);
	}

	_onMulticastCreated( contact, multicast ) {
		this._syncRemoteAutocleanTimeWithContacts( contact, multicast );
		let prevMultiinviteData = null;
		this._multiinviteSubscriptions[ contact.id ] = (
			this.observeContactList()
				.concatMap( () => {
					if ( contact.multiinviteData === prevMultiinviteData ) {
						return Rx.Observable.empty();
					}
					prevMultiinviteData = contact.multiinviteData;
					if ( !contact.multiinviteData ) {
						if ( this._multiinviteConnections[ contact.id ] ) {
							this._multiinviteConnections[ contact.id ].dispose();
							delete this._multiinviteConnections[ contact.id ];
						}
						return Rx.Observable.empty();
					}
					return (
						deserializeFromBase64StringAsync( contact.multiinviteData )
						.tap( ( { connectionId, dhPrivKey, encryptionSaltKey, macSaltKey, econfig } ) => {
							this._multiinviteConnections[ contact.id ] = new OneWayReceiver(
								contact.apiUrlBase, connectionId, dhPrivKey,
								encryptionSaltKey, macSaltKey,
								contact.multiinviteIndex | 0, econfig,
								this._onMultiinviteMessage.bind( this, contact.id )
							).onIndexUpdate( index =>
								this.updateAsync( contact.id, {
									multiinviteIndex: index + 1
								} )
							);
						} )
					);

				} ).subscribe()
		);

		//TODO: turn on. It is turned off because of inefficiency

		this._historySynchronizers[ contact.id ] = new HistorySynchronizer(
			contact,
			multicast,
			this._contactService
		);
		let prevContactsStr = 0;
		this._sharedSendSubscriptions[ contact.id ] = (
			this._contactService.observeContactsFullyLoad()
				.flatMap( () => this._contactService.observeContactList() )
				.map( () => this._contactService.getSharedContacts( contact.id ) )
				.filter( contacts => {
					let contactsStr = _.map( contacts, "id" ).join();
					if ( contactsStr === prevContactsStr ) {
						return false;
					}
					prevContactsStr = contactsStr;
					return true;
				} )
				.concatMap( () =>
					multicast.observeParticipantsLatest().take( 1 )
						.flatMap( ps => Rx.Observable.fromArray(
							_.filter( _.keys( ps ), pid => !ps[ pid ].isExited )
						) )
				)
				.concatMap( pid => Rx.Observable.defer( () =>
					this._sendInvitesToContactsifNeedAsync( contact, pid )
				) )
				.subscribe()
		);

		super._onMulticastCreated( contact, multicast );
	}

	_syncRemoteAutocleanTimeWithContacts( multidescription, multicast ) {
		//TODO: store subscription to dispose later on multidescription remove or service dispose
		//TODO: check that remoteAutocleanTime is not synced just inside transaction
		let contactService = this._contactService;
		Rx.Observable.combineLatest(
			this.observeRemoteAutocleanTime( multidescription.id )
				.filter( remoteAutocleanTime => typeof remoteAutocleanTime === "number" ),
			this.observeContactList()
				.debounce( 5000 )
				.map( () => contactService.getSharedContacts( multidescription.id ) ),
				( remoteAutocleanTime, contacts ) => ( { remoteAutocleanTime, contacts } )
		)
			.flatMap( ( { remoteAutocleanTime, contacts } ) =>
				Rx.Observable.fromArray( _.filter( contacts, { status: "active" } ) )
					.map( contact => ( { remoteAutocleanTime, contact } ) )
			)
			.concatMap( ( { remoteAutocleanTime, contact } ) => Rx.Observable.defer( () =>
				contactService.setParticipantAutocleanMaxTimeAsync(
					contact, remoteAutocleanTime
				)
			) )
			.subscribe();
	}

	_addContactParticipantAsync( multidescriptionContact, pData ) {
		this._sendInvitesToContactsifNeedAsync( multidescriptionContact, pData.pid )
			.subscribe();
		let { multiinviteData, multiinviteToken } = multidescriptionContact;
		if ( multiinviteToken && !pData ) {
			this._getMulticastOrNullAsync( multidescriptionContact )
				.flatMap( multicast => multicast
					? multicast.sendMessageAsync( {
						type: "multiinvite",
						content: { multiinviteData, multiinviteToken }
					} )
					: Rx.Observable.empty()
				).subscribe();
		}
		return super._addContactParticipantAsync( multidescriptionContact, pData );
	}

	_sendInvitesToContactsifNeedAsync( multidescriptionContact, pid ) {
		let contactService = this._contactService;

		return (
			contactService.observeContactsFullyLoad()
				.map( () => contactService.getSharedContacts( multidescriptionContact.id ) )
				.flatMap( sharedContacts => Rx.Observable.fromArray( sharedContacts ) )
				.filter( ( { status } ) => status === "active" || status === "invited" )
				.flatMap( sharedContact =>
					this._sendInviteToContactAsync(
						multidescriptionContact,
						sharedContact,
						pid
					)
				)
				.toArray()
		);
	}

	_sendInvitesToContactifNeedAsync( multidescriptionContact, sharedContact ) {
		return (
			this._getMulticastOrNullAsync( multidescriptionContact )
				.filter( m => !!m )
				.flatMap( multicast => multicast.observeParticipantsLatest().take( 1 ) )
				.flatMap( ps => Rx.Observable.fromArray(
					_.filter( _.keys( ps ), pid => !ps[ pid ].isExited )
				) )
				.flatMap( pid =>
					this._sendInviteToContactAsync(
						multidescriptionContact,
						sharedContact,
						pid
					)
				)
		);
	}

	_sendInviteToContactAsync( multidescriptionContact, sharedContact, pid ) {
		let hashKey = sharedContact.id + "!"  + pid;
		if ( this._sharedContactsListenings[ hashKey ]
			|| ( pid === multidescriptionContact.pid.toString( "base64" ) )
		) {
			return Rx.Observable.just();
		}
		this._sharedContactsListenings[ hashKey ] = 1;
		let pidBuffer = new Buffer( pid, "base64" );
		if ( !this._privateDataPerContact[ sharedContact.id ] ) {
			this._privateDataPerContact[ sharedContact.id ] = new Rx.ReplaySubject( 1 );
		}
		return (
			this._privateDataPerContact[ sharedContact.id ]
				.concatMap( ( { privateData, encryptedPrivateData } ) => Rx.Observable.defer( () => {
					if ( _.find( privateData, ( { privateData } ) => privateData.equals( pidBuffer ) ) ) {
						return Rx.Observable.just();
					}
					return this._sendInvitesToContactsWithPrivateDataifNeedAsync(
						multidescriptionContact,
						sharedContact,
						encryptedPrivateData,
						pid
					).flatMap( r => r
						? Rx.Observable.just()
						: Rx.Observable.empty()
					);
				} ) )
				.take( 1 )
		);
	}

	//Returns true if processed or false if need to retry later
	_sendInvitesToContactsWithPrivateDataifNeedAsync(
		multidescriptionContact, sharedContact, encryptedPrivateData, pid ) {
		return (
			this._getMulticastOrNullAsync( multidescriptionContact )
				.flatMap( multicast => multicast && ( multicast.getPid() === pid )
					? Rx.Observable.just( true )
					: this._getPrivateDataEncryptionKeyAsync( multidescriptionContact, sharedContact )
						.flatMap( key => Rx.Observable.fromPromise(
							ssgCrypto.encryptThen(
								new Buffer( pid, "base64" ),
								key,
								false, //padding
								multicast.econfig
							)
						) )
						.flatMap( newPrivateData => this._contactService.createAliasInviteIfPrivateDataIsAsync(
							sharedContact,
							encryptedPrivateData,
							newPrivateData,
							( inviteData, transaction ) => this._sendInviteToContactUsingTransaction(
								inviteData, transaction, multicast, pid, sharedContact
							)
						) )
				)
				.map( inviteString => {
					if ( inviteString === true ) {//Self
						return true;
					}

					if ( !inviteString ) {
						//Condition not met
						return false;
					}
					return true;
				} )
		);
	}

	_sendInviteToContactUsingTransaction( inviteData, transaction, multicast, pid, sharedContact ) {
		let waitSubj = new Rx.Subject();
		transaction.waitFor( waitSubj, "Serializing invite" );
		serializeMessageToBase64StringAsync( { ...inviteData, type: "invite" } )
			.flatMap( inviteString => {
				if ( multicast.isDisposed() ) {
					transaction.cancel();
					return Rx.Observable.just();
				}
				return multicast.sendPrivateMessageAsync(
					"shared_contact",
					{
						inviteString,
						name: sharedContact.name
					},
					pid,
					transaction
				).catch( error => Rx.Observable.empty() );
			} ).subscribeOnCompleted( () => {
				waitSubj.onCompleted();
			} );
	}

	_onContactsChanged( contacts ) {
		for ( let i = 0; i < contacts.length; i++ ) {
			let contact = contacts[ i ];
			if ( !contact.hasDetails() || ( contact.multidescriptionId === -1 )
			|| ( ( contact.status !== "active" ) && ( contact.status !== "invited" ) ) ) {
				continue;
			}
			let multidescriptionContact = _.find( this._contacts, { id: contact.multidescriptionId } );
			if ( !multidescriptionContact ) {
				debugger;
				continue;
			}
			if ( multidescriptionContact.status !== "active" ) {
				continue;
			}

			if ( this._contactPrivateDataSubscriptions[ contact.id ] ) {
				continue;
			}
			let privateDataSubj = this._privateDataPerContact[ contact.id ] || new Rx.ReplaySubject( 1 );
			this._privateDataPerContact[ contact.id ] = privateDataSubj;

			this._contactPrivateDataSubscriptions[ contact.id ] = (
				this._getPrivateDataEncryptionKeyAsync( multidescriptionContact, contact )
					.flatMap( key => this._contactService.monitorPrivateData(
						contact, key
					) )
					.subscribe( ( { decryptedPrivateDataArray, encryptedPrivateData } ) => {
						if ( !this._privatePidDictionary[ contact.id ] ) {
							this._privatePidDictionary[ contact.id ] = Object.create( null );
						}
						for( let i = 0; i < decryptedPrivateDataArray.length; i++ ) {
							let { pid, privateData } = decryptedPrivateDataArray[ i ];
							this._privatePidDictionary[ contact.id ][ pid ] = privateData.toString( "base64" );
						}
						privateDataSubj.onNext( {
							privateData: decryptedPrivateDataArray,
							encryptedPrivateData
						} );
						this._sendInvitesToContactifNeedAsync( multidescriptionContact, contact )
							.subscribe();
					},
					error => {
						debugger;
						console.error( error );
					},
					() => {
					}
				)
			);
		}
	}

	acceptWorkGroupInviteIfNotJoinedAsync( inviteString ) {
		//TODO: use secure memory management
		let group;
		return (
			deserializeFromBase64StringAsync( inviteString )
				.flatMap( inviteData => {
					let subj = new Rx.ReplaySubject();
					this._joinQueue.onNext( Rx.Observable.defer( () => {
						let found = _.find(
							this._contacts,
							c => ( !!c.multidescriptionId )
								&& ( c.sharedId.equals( inviteData.sharedId ) )
						);
						if ( found ) {
							subj.onNext( false );
							subj.onCompleted();
							return Rx.Observable.just();
						}

						return (
							this.local2ServerTimeAsync( +new Date )
								.flatMap( lastMessageTS =>
									Contact.createFromInviteAsync(
										inviteData,
										this.getUnconflictedName( inviteData.name ),
										this._contactType,
										lastMessageTS
									)
								)
								.tap( g => { group = g; } )
								.flatMap( () => this._insertContactAsync( group ) )
								.flatMap( () => this._addMulticastAsync(
									group,
									Multicast.tryJoinAsync(
										inviteData.nickname,
										{...inviteData,
											signKey: group.signKey, dhPrivKey: group.dhPrivKey,
											pid: group.pid.toString( "base64" )
										},
										this._getP2pPrivateHandlers(),
										this._isUseMetaWithRights(),
										this._isUseKeychain()
									)
								) )
								.flatMap( m => {
									if ( !m ) {
										subj.onNext( false );
										subj.onCompleted();
										return this._deleteContactAsync( group.id );
									}
									subj.onNext( true );
									subj.onCompleted();
									return Rx.Observable.just();
								} )
								.catch( error => {
									console.error( error );
									subj.onError( error );
									return Rx.Observable.just( false );
								} )
							);
					} ) );
					return subj;
				} )
		);
	}

	getPrivateNameOrNull( contact, pid ) {
		let contactPids = this._privatePidDictionary[ contact.id ];
		if ( !contactPids ) {
			return null;
		}
		let privatePid = contactPids[ pid ];
		if ( !privatePid ) {
			return null;
		}
		return this.getParticipantNameOrNull( contact.multidescriptionId, privatePid );
;
	}

	observeParticipants( contactId ) {
		return (
			this._getMulticastOrNullAsync( contactId )
				.filter( m => !!m )
				.concatMap( multicast => multicast.observeParticipantsLatest() )
				.map( unfiltered => {
					let participants = Object.create( null );
					for ( let pid in unfiltered ) {
						let p = unfiltered[ pid ];
						if ( p.isExited ) {
							continue;
						}
						if ( !p.aliasTo ) {
							participants[ pid ] = p;
						}
						let p2 = p;
						while ( !p2.nickname && p2.aliasTo ) {
							p2 = unfiltered[ p2.aliasTo ];
						}
						participants[ pid ] = {...p, nickname: p2.nickname || ""};
					}
					return participants;
				} )
		);
	}


	observeInvites( contactId ) {
		return (
			this._getMulticastOrNullAsync( contactId )
				.filter( m => !!m )
				.concatMap( multicast => multicast.observeInvites() )
		);
	}

	observeRights( contactId ) {
		return (
			this._getMulticastOrNullAsync( contactId )
				.filter( m => !!m )
				.concatMap( multicast => multicast.observeRights() )
		);
	}

	observeSelfRights( contactId ) {
		return (
			this._getMulticastOrNullAsync( contactId )
				.filter( m => !!m )
				.concatMap( multicast => multicast.observeRights()
					.map( rights => rights[ multicast.getPid() ] )
				)
		);

	}

	setRightsAsync( contactId, modifiedRights ) {
		//TODO: check rights
		return (
			this._getMulticastOrNullAsync( contactId )
				.flatMap( multicast => multicast
					? multicast.changeRightsAsync( modifiedRights )
					: Rx.Observable.just( null )
				)
		);
	}

	_isUseMetaWithRights( ) {
		return true;
	}

	_isUseKeychain( ) {
		return false;
	}

	_getP2pPrivateHandlers( ) {
		return {
			...super._getP2pPrivateHandlers(),
			"shared_contact": this._onSharedContact
		};
	}

	_onSharedContact( multicast, pid, json, p2pIndex ) {
		//TODO: code refactor
		//TODO: serialized contacts access
		let {inviteString, name} = json;
		let selfPid = multicast.getPid();
		/*if ( !this._sharedPublicDataPerPid[ selfPid ] ) {
			this._sharedPublicDataPerPid[ selfPid ] = Object.create( null );
		}
		let publicDataPerSharedId = this._sharedPublicDataPerPid[ selfPid ];*/
//TODO: decode shared id from
		return (
			Rx.Observable.combineLatest(
				this._contactService.observeContactsFullyLoad().take( 1 ),
				this.observeContactsFullyLoad().take( 1 ),
				( contacts, multidescriptions ) => ( { contacts, multidescriptions } )
			)
				.flatMap( ( { contacts, multidescriptions } ) => {
					let multidescription = _.find(
						multidescriptions,
						m => selfPid === m.pid.toString( "base64" )
					);
					if ( !multidescription ) {
						debugger;
						console.error( "New shared contact (p2p): multidescription not found" )
						return Rx.Observable.empty();
						//throw new Error( "multidescription not found" );
						//TODO: sometimes shared contact message comes after multidescription remove
					}
					let resSubj = new Rx.ReplaySubject();

					this._contactService.createSharedContactIfNotExistAsync(
						inviteString,
						name,
						multidescription.id
					).subscribe( result => {
						resSubj.onNext( result );
						resSubj.onCompleted();
					} );
					this._createSharedContactsSubject.onNext( Rx.Observable.defer( () => resSubj ) );
					return resSubj;
				} )
			);
	}

	_processMessageJsonAndGetModel( msg, fromContact, multicast, index ) {
		let result = this._processMessageJsonAndGetModel2( msg, fromContact, multicast, index );
		if ( result && result.subscribe ) {
			return (
				result
					.tapOnCompleted( () => {
						if ( this._muted[ fromContact.id ] ) {
							this.setReadAll( fromContact, msg );
						}
					} )
			);
		}
		if ( this._muted[ fromContact.id ] ) {
			this.setReadAll( fromContact, msg );
		}
		return result ? Rx.Observable.just( result ) : Rx.Observable.empty();
	}

	_onMultiinviteMessage( multidescriptionId, message, error ) {
		if ( !message ) {
			console.error( "Multiinvite message error", error );
			return;
		}
		if ( message.type !== "invite" ) {
			throw new Error( "Received message is not an invite" );
		}
		let contactService = this._contactService;
		Rx.Observable.combineLatest(
			contactService.getContactsAsync(),
			this.getDetailedContactAsync( multidescriptionId ),
			this._getMulticastOrNullAsync( multidescriptionId ),
			( contacts, multidescription, multicast ) => ( { contacts, multidescription, multicast } )
		)
			.flatMap( ( { contacts, multidescription, multicast } ) => {
				//TODO: queue ?
				let found = _.find( contacts, c => message.sharedId.equals( c.sharedId ) );
				if ( !found ) {
					//TODO: one transaction
					return (
						contactService.acceptInviteAsync(
							message, "Unnamed", multidescriptionId
						)
						.flatMap( newContact => newContact
							? this._getPrivateDataEncryptionKeyAsync( multidescription, newContact )
								.flatMap( key => Rx.Observable.fromPromise(
									ssgCrypto.encryptThen(
										new Buffer( multicast.getPid(), "base64" ),
											key,
											false, //padding
											multicast.econfig
										)
								) )
								.flatMap( privateData =>
									contactService.publishPrivateDataAsync( newContact, privateData )
								)
							: Rx.Observable.just( null )
						)
					);
				}
				return Rx.Observable.just();
			} )
			.subscribeOnError( () => {} );
			//TODO: catch specific error
	}

	_processMessageJsonAndGetModel2( msg, fromContact, multicast, index ) {
		let pid = multicast.getPid();
		/*if ( !this._sharedPublicDataPerPid[ pid ] ) {
			this._sharedPublicDataPerPid[ pid ] = Object.create( null );
		}
		let publicData = this._sharedPublicDataPerPid[ pid ];*/
		let {json} = msg;
		let {content} = json;
		switch( json.type ) {
			case "multiinvite": {
				return (
					this.updateAsync( fromContact.id, {
						multiinviteData: content.multiinviteData,
						multiinviteToken: content.multiinviteToken,
						multiinviteIndex: 0
					} )
				);
			}
			case "unmultiinvite": {
				return (
					this.updateAsync( fromContact.id, {
						multiinviteData: undefined,
						multiinviteToken: undefined,
						multiinviteIndex: undefined
					} )
				);
			}
			case "share": {
				//TODO: verify content and check uniqueness
				/*let sharedIdString = content.sharedId.toString( "base64" );
				if ( !publicData[ sharedIdString ] ) {
					publicData[ sharedIdString ] = new Rx.ReplaySubject();
				}
				publicData[ sharedIdString ].onNext( content );
				publicData[ sharedIdString ].onCompleted();*/
				return null;
			}
			case "change_rights":
				return null;
			case "rename_contact": {
				let contactService = this._contactService;
				let found = _.find(
					contactService.getSharedContacts( fromContact.id ),
					c => c.sharedId.equals( content.sharedId )
				);
				if ( !found ) {
					console.error( "Contact not found to rename" );
					return Rx.Observable.empty();
				}
				return (
					this._contactService._updateAsync( found.id, { name: content.newName } ) //Locally
						.flatMap( () => Rx.Observable.empty() )
				);
			}
			default:
				return super._processMessageJsonAndGetModel( msg, fromContact, multicast, index );
		}
	}

	removeParticipantAsync( contactId, pid ) {
		return (
			this._getMulticastOrNullAsync( contactId )
				.flatMap( multicast => multicast
					? multicast.removeAsync( pid )
					: Rx.Observable.just( null )
				)
		);
	}

	removeWorkgroupInviteAsync( contactId, pid ) {
		return (
			this._getMulticastOrNullAsync( contactId )
				.flatMap( multicast => multicast
					? multicast.removeInviteAsync( pid )
					: Rx.Observable.just( null )
				)
		);
	}

	deleteContactAsync( id ) {
		return (
			this.getDetailedContactAsync( id )
				.flatMap( contact => !contact || ( contact.status === "failed" )
					? Rx.Observable.just()
					: this.removeParticipantAsync( id, contact.pid.toString( "base64" ) )
				)
				.flatMap( () => this._deleteContactAsync( id ) )
		);
	}

	_deleteContactAsync( id ) { //delete local data
		let contactService = this._contactService;
		return (
			contactService.observeContactsFullyLoad()
				.flatMap( () => Rx.Observable.fromArray( contactService.getSharedContacts( id ) ) )
				.flatMap( contact => contactService._deleteContactAsync( contact.id ) )
				.toArray()
				.flatMap( () => super._deleteContactAsync( id ) )
		);
	}

	_createMultiinviteTokenAsync( apiUrlBase, connectionId, dhPubKey, encryptionSaltKey, macSaltKey, econfig ) {
		let connectionIdHash = ssgCrypto.hash( new Buffer( connectionId, "base64" ) ).toString( "base64" );
		//TODO: Change message format to skip converting sensetive data to text/buffer
		return (
			sendMessageAsync(
				{
					apiUrlBase,
					connectionId: connectionIdHash,
					dhPubKey, encryptionSaltKey, macSaltKey,
					econfig,
					type: "multiinvite"
				}
			)
		);
	}

	_createMultiinviteDataAsync( connectionId, dhPrivKey, encryptionSaltKey, macSaltKey, econfig ) {
		return (
			serializeMessageToBase64StringAsync( {
				dhPrivKey, encryptionSaltKey, macSaltKey, connectionId, econfig,
				type: "multiinvitePrivate"
			} )
		);
	}

	createMultiInviteAsync( multidescriptionId ) {
		//TODO: Change message format to skip converting sensetive data to text/buffer
		return (
			this._getMulticastOrNullAsync( multidescriptionId )
				.filter( m => !!m )
				.flatMap( multicast => Rx.Observable.fromPromise( Promise.all( [
					ssgCrypto.createRandomKeyExchangeKeyPairThen( multicast.econfig ),
					ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, multicast.econfig ),
					ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, multicast.econfig ),
					ssgCrypto.createRandomBase64StringThen( 32 )
				] ).then( ( [ { privateKey, publicKey }, encryptionSaltKey, macSaltKey, connectionId ] ) =>
						( { privateKey, publicKey, encryptionSaltKey, macSaltKey, connectionId, multicast } )
					)
				) )
			.flatMap( ( { privateKey, publicKey, encryptionSaltKey, macSaltKey, connectionId, multicast } ) =>
				Rx.Observable.combineLatest(
					this._createMultiinviteTokenAsync( multicast.apiUrlBase,
						connectionId, publicKey, encryptionSaltKey, macSaltKey, multicast.econfig ),
					this._createMultiinviteDataAsync( connectionId, privateKey,
						encryptionSaltKey, macSaltKey, multicast.econfig ),
					this._getMulticastOrNullAsync( multidescriptionId ),
					( multiinviteToken, multiinviteData, multicast ) => {
						privateKey.dispose();
						publicKey.dispose();
						encryptionSaltKey.dispose();
						macSaltKey.dispose();
						return { multiinviteToken, multiinviteData, multicast };
					}
				)
			)
			.flatMap( ( { multiinviteToken, multiinviteData, multicast } ) => multicast
				? multicast.sendMessageAsync( {
					type: "multiinvite",
					content: { multiinviteData, multiinviteToken }
				} ).flatMap( () =>
						this.updateAsync( multidescriptionId, {
							multiinviteData,
							multiinviteToken,
							multiinviteIndex: 0
						} )
					)
				: Rx.Observable.just( null )
			)
		);
	}

	deleteMultiInviteAsync( multidescriptionId ) {
		return (
			this._getMulticastOrNullAsync( multidescriptionId )
				.flatMap( multicast => multicast
					? multicast.sendMessageAsync( {
						type: "unmultiinvite",
						content: {}
					} )
					: Rx.Observable.just( null )
				)
				.flatMap( () => this.getDetailedContactAsync( multidescriptionId ) )
				.flatMap( ( { multiinviteToken } ) => {
					if ( !multiinviteToken ) {
						return Rx.Observable.just();
					}
					let { messageId, apiUrlBase } = decodeMessageToken( multiinviteToken );
					return (
						this._messageService.removeMessageByIdAsync( { id: messageId, apiUrlBase } )
							.catch( () => Rx.Observable.just() )
							.flatMap( () => this.updateAsync(
								multidescriptionId, {
									multiinviteData: undefined,
									multiinviteToken: undefined,
									multiinviteIndex: undefined
								}
							) )
					);
				} )
		);
	}

	observeAutocleanTime( multidescriptionId ) {
		return (
			this._getMulticastOrNullAsync( multidescriptionId )
				.flatMap( multicast =>
					multicast ? multicast.observeMetaLatest() : Rx.Observable.empty()
				)
				.map( ( { autocleanTime } ) => autocleanTime )
				.distinctUntilChanged()
		);
	}

	setAutocleanTimeAsync( multidescriptionId, autocleanTime ) {
		if ( typeof autocleanTime !== "number" ) {
			throw new Error( "autocleanTime required" );
		}
		return (
			this._getMulticastOrNullAsync( multidescriptionId )
				.flatMap( multicast => multicast
					? multicast.sendUpdateMessageAsync( { autocleanTime } )
					: Rx.Observable.just( null )
				)
		);
	}

	setRemoteAutocleanTimeAsync( multidescriptionId, remoteAutocleanTime ) {
		if ( typeof remoteAutocleanTime !== "number" ) {
			throw new Error( "remoteAutocleanTime required" );
		}
		if ( multidescriptionId !== ( multidescriptionId | 0 ) ) {
			throw new Error( "multidescriptionId required" );
		}
		return (
			this._getMulticastOrNullAsync( multidescriptionId )
				.flatMap( multicast => multicast
					? multicast.sendUpdateMessageAsync( { remoteAutocleanTime } )
					: Rx.Observable.just( null )
				)
		);
	}

	observeRemoteAutocleanTime( multidescriptionId ) {
		if ( multidescriptionId !== ( multidescriptionId | 0 ) ) {
			throw new Error( "multidescriptionId required" );
		}
		return (
			this._getMulticastOrNullAsync( multidescriptionId )
				.flatMap( multicast =>
					multicast ? multicast.observeMetaLatest() : Rx.Observable.empty()
				)
				.map( ( { remoteAutocleanTime } ) => {
					return remoteAutocleanTime;
				} )
				.distinctUntilChanged()
		);
	}

	rejoinContactAsync( contact ) {
		return (
			Rx.Observable.fromArray( this._contactService.getSharedContacts( contact.id ) )
				.concatMap( ( { id } ) => Rx.Observable.defer( () =>
					this.deleteContactAsync( id, "skipSendUnshareMessage" )
				) )
				.toArray()
				.flatMap( () => super.rejoinContactAsync( contact ) )
		);
	}

	observeContactList( ) {
		return Rx.Observable.combineLatest(
			this._adminService.observeIsPrivileged(),
			super.observeContactList(),
			( isPrivileged, contacts ) => isPrivileged ? contacts : []
		);
	}
}

export default SharedContactsService;
