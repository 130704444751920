import React from "react";
import PromptContacts from "../common/prompt.contacts.jsx";
import Translation from "../../../components/translation.jsx";
import Tinput from "../common/tinput.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class CreateContentView extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedIds: [],
			name: "",
			nickname: ""
		};
		this._service = remoteServiceLocator();
		this.onChangeContacts = this.onChangeContacts.bind( this );
		this.onChangeName = this.onChangeName.bind( this );
		this.onChangeNickame = this.onChangeNickame.bind( this );
		this.onBegin = this.onBegin.bind( this );
	}

	componentWillMount() {
		this._contactsSubscription = (
			this._service.observeContactList()
				.subscribe( contacts => {
					this.setState( {contacts} );
				} )
		);

		this._profileSubscription = (
			this._service.observeProfile()
				.subscribe( ( { nickname } ) => {
					if ( !this.state.nickname ) {
						this.setState( { nickname } );
					}
				} )
		);
	}

	componentWillUnmount() {
		this._contactsSubscription.dispose();
	}

	onChangeContacts( selectedIds ) {
		this.setState( { selectedIds } );
	}

	onChangeName( name ) {
		this.setState( { name } );
	}

	onChangeNickame( nickname ) {
		this.setState( { nickname } );
	}

	onBegin() {
		this.props.onBack( this.state.name, this.state.nickname, this.state.selectedIds );
	}

	render() {
		let promptContacts = (
			this.state.contacts
			? <PromptContacts
				contacts={this.state.contacts}
				onChange={this.onChangeContacts}
				selectedIds={this.state.selectedIds}
			/>
			: null
		);
		return (
			<div className={this.props.className}>
				<div className="invite">
					<Translation textId="web.group.create.name" />
					<Tinput type="text" value={this.state.name} onChange={this.onChangeName} placeholderTextId="web.group.create.name.placeholder"/><br/>
					<Translation textId="web.group.create.nickname" />
					<Tinput type="text" value={this.state.nickname} onChange={this.onChangeNickame} placeholderTextId="web.group.create.nickname.placeholder"/><br/>
					<Translation textId="web.group.create.participants" />
					{promptContacts}
					<br />
					<div className="text-center">
						<button
							className="btn btn-blue"
							onClick={this.onBegin}
							disabled={!this.state.name || !this.state.nickname || !this.state.selectedIds.length}
							>
							<Translation textId="web.group.create.button" />
						</button>
					</div>
				</div>
			</div>
		);
	}

	static get propTypes() {
		return {
			onBack: React.PropTypes.func.isRequired
		};
	}
}

export default CreateContentView;
