import React from "react";
import ReactDOM from "react-dom";
import Rx from "rx";
import {getRangeFromPoint, scrollIntoView} from "../../common/utils.js";
import * as utils from "../../common/utils.js";
import _ from "lodash";
import classNames from "classnames";
import PasteWrapper from "./paste.jsx";
import Clipboard from '../../common/clipboard.js'

function trim( str, value ) {
	if ( value < 0 ) {
		return 0;
	}
	if ( value > str.length ) {
		return str.length;
	}
	return value;
}

function inc( text, cursor, diff ) {
	return trim( text, cursor + (diff || 1) );
}

function dec( text, cursor, diff ) {
	return trim( text, cursor - (diff || 1) );
}

let commands = {
	"setValue": ( state, text = "" ) => ({
		text: text,
		cursor: text.length
	}),
	"setCursor": ( state, position = 0 ) => ({
		text: state.text,
		cursor: position
	}),
	"Insert": ( state, text = "" ) => {
		text = Array.isArray(text) ? text[0] : text; //TODO: Cant apply string in paste method
		let newText = state.text.slice( 0, state.cursor ) + text + state.text.slice( state.cursor );
		return {
			text: newText,
			cursor: inc( newText, state.cursor, text.length )
		};
	},
	"Space": state  => commands[ "Insert" ]( state, " " ),
	"Enter": state => {
		return commands[ "Insert" ]( state, "\n" );
	},
	"Backspace": state  => {
		if ( state.cursor > 0 ) {
			let newText = state.text.slice( 0, state.cursor - 1 ) + state.text.slice( state.cursor );
			return {
				text: newText,
				cursor: dec( newText, state.cursor )
			};
		}
		return state;
	},
	"Del": state => {
		if ( state.cursor < state.text.length ) {
			let newText = state.text.slice( 0, state.cursor ) + state.text.slice( state.cursor + 1 );
			return {
				text: newText,
				cursor: trim( newText, state.cursor )
			};
		}
		return commands[ "Backspace" ]( state );
	},
	"OK": _.identity,
	"Left": state => ({
		text: state.text,
		cursor: dec( state.text, state.cursor )
	}),
	"Right": state  => ({
		text: state.text,
		cursor: inc( state.text, state.cursor )
	})
};

class Cursor extends React.Component {
	render() {
		return <span className="cursor">|</span>;
	}
}

class TextInput extends React.Component {
	constructor() {
		super();
		this.state = {
			hasFocus: false,
			previewChar: null,
			cursor: null
		};
		this.paste = this.paste.bind(this);
		this.copy = this.copy.bind(this);
		this.handleClick = this.handleClick.bind( this );
	}

	static get contextTypes() {
		return { focusManager: React.PropTypes.object.isRequired };
	}

	static get propTypes() {
		return {
			value: React.PropTypes.string,
			onChange: React.PropTypes.func
		};
	}

	static get defaultProps() {
		return {
			value: "",
			readOnly: false,
			onChange: _.noop
		};
	}

	modifyInputState( state, command ) {
		if ( _.isObject( command ) ) {
			return commands[ command.action ]( state, command.args );
		}
		if ( command in commands ) {
			return commands[ command ]( state );
		}
		return commands[ "Insert" ]( state, command );
	}

	trimValue( { text, cursor } ) {
		let maxLength = Number( this.props.maxLength );
		if ( _.isFinite( maxLength ) && text.length > maxLength ) {
			text = text.slice( 0, maxLength );
			cursor = maxLength;
		}
		return { text, cursor };
	}

	executeCommand( command, cb ) {
		let { text, cursor } = this.trimValue( this.modifyInputState( {
			text: this.props.value,
			cursor: this.state.cursor
		}, command ) );


		if (command == 'Enter' && this.props.noEnter) {
			return cb ? cb() : null;
		}

		if (! text && (command !=='Backspace' || this.props.firstUppercased) ) {
		  this.context.focusManager.handleFirstUppercased(this, text);
		}

		this.setState( { cursor }, () => {
			if ( text !== this.props.value ) {
				this.props.onChange( text );
			}
			if ( cb ) {
				cb();
			}
		} );
	}

	fixOffset( offset, target ) {
		let cursor = this.state.cursor;
		if ( this.state.hasFocus ) {
			let node = ReactDOM.findDOMNode( this );
			if ( node === target) {
				return this.props.value.length;
			}
			let part = [].indexOf.call( node.children[0].children, target );
			if ( part === 2 ) {
				return offset + cursor;
			}
			if ( part === 1 ) {
				return cursor;
			}
			return offset;
		}
		return offset;
	}

	handleClick( ev ) {
		if ( this.props.readOnly ) {
			return;
		}

		if ( ev.touches ) {
			if ( ev.touches.length > 1 ) {
				return;
			}
			ev = ev.touches[0];
		}
		let range = getRangeFromPoint( ev.pageX, ev.pageY );
		if ( !range ) {
			this.executeCommand( { action: "setCursor", args: this.props.value.length } );
			if ( !this.state.hasFocus ) { //It happens in Safari some times
				this.getFocus();
			}
			return;
		}
		let target = range.startContainer;
		if ( target.nodeType === Node.TEXT_NODE ) {
			target = target.parentElement;
		}
		let offset = this.fixOffset( range.startOffset, target );
		this.executeCommand( { action: "setCursor", args: offset } );
		if ( !this.state.hasFocus ) {
			this.getFocus();
		}
	}

	getFocus() {
		this.context.focusManager.getFocus( this );
	}

	focus( cb ) {
		if ( global.pasteHack ) {
			global.pasteHack.handler = this.paste;
		}
		this.setState( { hasFocus: true }, cb );
		this.pasteSubscription = Clipboard.pasteObservable.subscribe(this.paste)
	}

	blur( cb ) {
		if ( global.pasteHack ) {
			if ( global.pasteHack.handler === this.paste ) {
				delete global.pasteHack.handler;
			}
		}
		this.setState( { hasFocus: false }, cb );
		this.pasteSubscription.dispose()
	}

	setPreviewChar( previewChar ) {
		this.setState( { previewChar } );
	}

	getValue() {
		return this.props.value;
	}

	getPlaceholderValue() {
		let text = this.props.placeholder || "";
		if ( this.state.hasFocus ) {
			return [
				<Cursor key="1" ref="cursor"/>,
				<span key="2" className="placeholder">{text}</span>
			];
		}
		return <span className="placeholder">{text}</span>;
	}

	formatDisplayValue() {
		return this.props.value.replace( /\s/g, "\u2002" );
	}

	getDisplayValue() {
		let text = this.formatDisplayValue();
		let { hasFocus, cursor, previewChar } = this.state;
		let style = {
			// User select is ugly on the device
			// WebkitUserSelect: "all",
  		// userSelect: "all"
		};
		if ( !text && !previewChar ) {
			return this.getPlaceholderValue();
		}
		if ( hasFocus ) {
			return [
				<span key="0" style={ style }>{ text.slice( 0, cursor ) }</span>,
				previewChar || <Cursor key="1" ref="cursor"/>,
				<span key="2" style={ style }>{ text.slice( cursor ) }</span>
			];
		}
		return text;
	}

	getCursor() {
		return this.refs.cursor;
	}

	getClassName() {
		return classNames( [ "custom-input", this.props.className, { "editing": this.state.hasFocus }, {'unselectable': !!global.cordova } ] );
	}

	componentDidMount() {
		this.executeCommand( { action: "setCursor", args: this.formatDisplayValue().length } );
		if ( this.props.autofocus && !this.props.readOnly) {
			this.getFocus();
		}
	}

	componentDidUpdate() {
		if ( this.state.hasFocus ) {
			let cursor = this.getCursor();
			if ( cursor ) {
				scrollIntoView( ReactDOM.findDOMNode( cursor ) );
			}
		}
	}

	paste(text) {
		this.executeCommand( { action: "Insert", args: [text || ""] } );
		this.getFocus();
	}

	copy() {
		if (! this.props.readOnly) {
			this.getFocus();
		}
		return this.getValue();
	}

	getOnPaste () {
		if ( !this.props.readOnly ) {
			return Clipboard.paste( this.paste );
		}
	}

	getOnClear() {
		if (this.props.value && !this.props.readOnly) {
			return () => this.executeCommand( {'action': 'setValue', args: ''}  );
		}
	}

	getOnCopy() {
		if ( this.props.value ) {
			return Clipboard.copy( this.copy );
		}
	}

	getOnShare() {
		if ( this.props.value ) {
			return () => this.copy();
		}
	}

	componentWillUnmount() {
		if ( this.pasteSubscription ) {
			this.pasteSubscription.dispose();
		}
		this.context.focusManager.unregisterInput( this );
	}

	render() {
		return (
			<PasteWrapper
				onPaste={this.getOnPaste()}
				onCopy={this.getOnCopy()}
				onClear={this.getOnClear()}
				onShare={this.getOnShare()}>
				<div
					className={this.getClassName()}
					onClick={this.handleClick}
					onTouchStart={this.handleClick}
					id={this.props.id}
					tabIndex="0"
					>
					{this.getDisplayValue()}
				</div>
			</PasteWrapper>
		);
	}
}

export class PasswordInput extends TextInput {
	getOnCopy() {
		return null;
	}

	formatDisplayValue() {
		if (this.props.showPassword) {
			return super.formatDisplayValue();
		}
		let mask = "*";
		return new Array( this.props.value.length + 1 ).join( mask );
	}
}

export class MultiLineInput extends TextInput {
	getClassName() {
		return super.getClassName() + " multiline";
	}

	formatDisplayValue() {
		return this.props.value.replace( /(\t| )/g, "\u2002" );
	}
}

export default TextInput;
