import React from "react";
import Rx from "rx";

import Messages from "./messages.jsx";
import Footer from "../common/message.write.footer.jsx";
import InviteContent from "./invite.content.jsx";
import InviteAbsentContent from "./invite.absent.content.jsx";
import Translation from "../../../components/translation.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class MainContentView extends React.Component {
	constructor() {
		super();
		this.state = {
			isDragging: false,
			replyMessage: null
		};
		this.onEdit = this.onEdit.bind( this );
		this.onEditDone = this.onEditDone.bind( this );
		this.onReply = this.onReply.bind( this );
		this.onSent = this.onSent.bind( this );
		this.onDrop = this.onDrop.bind( this );
		this.onDragEnter = this.onDragEnter.bind( this );
		this.onDragLeave = this.onDragLeave.bind( this );
		this.onDragOver = this.onDragOver.bind( this );
		this._service = remoteServiceLocator();
	}

	componentWillMount() {
	}

	componentWillReceiveProps() {
	}

	componentWillUnmount() {
	}

	onDrop( event ) {
		let { contact } = this.props;
		event.stopPropagation();
		event.preventDefault();
		if ( !contact ) {
			return;
		}
		let {files} = event.dataTransfer;
		let fileArray = [];
		for ( let i = 0; i < files.length; i++ ) {
			fileArray.push( files[ i ] );
		}
		let isAlerted = false;
		Rx.Observable.fromArray( fileArray )
			.concatMap( file => this._service.sendFileAsync( contact, file ).catch( e => {
				isAlerted || alert( e.message );
				isAlerted = true;
				return Rx.Observable.empty();
			} ) )
			.toArray()
			.subscribe();
		this.setState( {
			isDragging: false,
			replyMessage: null
		} );
	}

	onDragEnter( event ) {
		this.setState( {
			isDragging: true,
			replyMessage: null
		} );
		event.preventDefault();
		event.stopPropagation();
	}

	onDragLeave( event ) {
		this.setState( { isDragging: false } );
		event.preventDefault();
		event.stopPropagation();
	}

	onDragOver( event ) {
		this.setState( { isDragging: true } );
		event.preventDefault();
		event.stopPropagation();
	}

	onEdit( editingMessage ) {
		let {text} = editingMessage;
		if ( typeof text !== "string" ) {
			console.warn( "Trying to edit non-text message" );
			return;
		}
		this.setState( { editingMessage } );
	}

	onEditDone() {
		this.setState( { editingMessage: null, replyMessage: null } );
	}

	onReply( message ) {
		this.setState({replyMessage: message});
	}

	onSent() {
		this.setState( { editingMessage: null, replyMessage: null } );
	}

	render() {
		if ( !this.props.contact ) {
			return (
				<div className={this.props.className}>
					<Messages contact={null} />
					<Footer contact={null} />
				</div>
			);
		}

		let { contact } = this.props;
		if ( !contact ) {
			return (
				<div className={this.props.className}>
					<p>
						<Translation textId="web.contact.loading.text" />
					</p>
				</div>
			);
		}

		if ( contact.status === "invited" ) {
			if ( contact.inviteToken ) {
				return <InviteContent contact={contact} className={this.props.className} key={contact.id}/>;
			}
			return <InviteAbsentContent
				contact={contact}
				className={this.props.className}
				key={contact.id}
			/>;
		}

		if ( contact.status === "active" ) {
			return (
				<div
					className={this.props.className + " " + ( this.state.isDragging ? "dragging" : "" ) }
					key={contact.id}
					onDrop={this.onDrop}
					onDragEnter={this.onDragEnter}
					onDragLeave={this.onDragLeave}
					onDragOver={this.onDragOver}
				>
					<Messages
						contact={contact}
						onEdit={this.onEdit}
						editingMessage={this.state.editingMessage}
						onReply={this.onReply}
					/>
					<Footer
						contact={contact}
						editingMessage={this.state.editingMessage}
						onEditDone={this.onEditDone}
						replyMessage={this.state.replyMessage}
						onSent={this.onSent}
					/>
				</div>
			);
		}

		if ( contact.status === "failed" ) {
			return (
				<div className={this.props.className}>
					<div className="invite">
						<Translation textId="contact.failed.message" />
						{contact.failReason}
					</div>
				</div>
			);
		}

		if ( contact.status === "disconnected" ) {
			return (
				<div className={this.props.className} key={contact.id}>
					<Messages contact={contact} />
					<div style={{padding: "20px" }}>
						<Translation textId="contact.status.disconnected.explanation" />
					</div>
				</div>
			);
		}
		return (
			<div className={this.props.className} >
				<div className="invite">
					Unsupported status {contact.status}
				</div>
			</div>
		);
	}
}

export default MainContentView;
