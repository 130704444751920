import React from "react";
import Rx from "rx";
import _ from "lodash";

import Translation from "../../components/translation.jsx";
import Qr from "../../components/qr.jsx";
import InputWithHelp from "../../components/input.withhelp.jsx";
import Button from "../../components/button.jsx";
import TopLevel from "../common/top.level.view.jsx";
import ImageButton from "../../components/button.image.jsx";
import OnlineStatus from "../../components/online.status.jsx"
import DeviceBackButton from "../../components/device.back.button.jsx";
import Prompt from "../common/prompt.jsx";
import {DynamicToast} from "../../components/toast.jsx";
import FullScreen from "../../components/fullscreen.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

class AddExternalView extends React.Component {
	constructor() {
		super();
		this.state = {
			token: null,
			ps: null,
			invites: null,
			isInProgress: false
		};
		this.onSetName = this.onSetName.bind( this );
		this.validateName = this.validateName.bind( this );
		this._service = serviceLocator();
	}

	onSetName( nickname ) {
		let { onDone, contact } = this.props;
		nickname = nickname ? nickname.trim() : "";
		if ( !nickname ) {
			onDone();
			return;
		}

		if ( this.state.isInProgress ) {
			return;
		}

		this.setState( { isInProgress: true } );

		this._service
			.addGroupParticipantExternalAsync( contact, nickname.trim() )
			.subscribe( token => {
				this.setState( { isInProgress: false, token } );
			} );
	}

	componentWillMount() {
		let { contact } = this.props;
		this._participantsSubscription = (
			Rx.Observable.combineLatest(
				this._service.observeGroupParticipants( contact ),
				this._service.observeGroupInvites( contact ),
				( ps, invites ) => ( { ps, invites } )
			).subscribe( update => {
				this.setState( update );
			} )
		);
	}

	componentWillUnmount() {
		this._participantsSubscription.dispose();
	}

	validateName( nickname ) {
		let errors = [];
		nickname = nickname.trim();

		if ( !nickname ) {
			errors.push( "nickname.empty" );
		}
		if ( _.find( this.state.ps, { nickname } ) ) {
			errors.push( "group.external.duplicate.warn" );
		}

		if ( _.find( this.state.invites, { nickname } ) ) {
			errors.push( "group.external.duplicate.invite.warn" );
		}

		return errors;
	}

	render() {
		let { onDone, contact } = this.props;
		let { token } = this.state;

		if ( token ) {
			return <ShowInvite token={ token } onDone={ onDone } />;
		}

		return (
			<div>
				<Prompt
					onSetValue={this.onSetName}
					onBack={this.onSetName}
					validate={this.validateName}
					headerTextId="group.external.header"
					textId="group.external.text"
					placeholderTextId="group.external.placeholder"
					defaultValue=""
					fullScreen={true}
					autofocus={true}
					firstUppercased={true}
				/>
			</div>
		);
	}
}

class ShowInvite extends React.Component {
	constructor() {
		super();
		this.onBackPress = this.onBackPress.bind( this );
		this.copy = _.get( global, "cordova.plugins.clipboard.copy" );
		this.onCopyClick = this.onCopyClick.bind( this );
	}

	onCopyClick() {
		let { token } = this.props;
		this.copy( token, () => this.refs.toast.show( { textId: "group.external.copytoast" } ) );
	}

	onBackPress() {
		this.props.onDone();
	}

	renderContent() {
		let {token} = this.props;
		return (
			<div>
				<div className="big-text"><Translation textId="group.external.qr.bigtext"/></div>
				<div className="small-text"><Translation textId="group.external.qr.smalltext"/></div>
				<div className="qr-code blue f-left">
					<Qr className="qr-image" code={token}/>
				</div>
				<div className="clear"></div>
				<div className="small-text">
					<Translation textId="group.external.plain.text"/>
				</div>
				<div className="invite-code-block-">
					<InputWithHelp
						type="text"
						className="invite-code"
						value={token}
						readOnly={true}
						placeholderTextId="empty"
						tooltipTextId="group.external.token.tooltip"
						id="invite-code"
					/>
				</div>
				{this.copy && <Button
					caption="group.external.button.copy"
					handleClick={this.onCopyClick}
				/>}
				<DynamicToast ref="toast"/>
			</div>
		);
	}

	render() {
		return (
			<FullScreen>
				<TopLevel className="invite-page">
					<header>
						<ImageButton type="icon-arrow-left" onClick={this.onBackPress} id="back"/>
						<span className="header-caption"><Translation textId="group.external.header"/></span>
						<OnlineStatus/>
					</header>
					<main>
						{ this.renderContent() }
					</main>
					<DeviceBackButton onPress={ this.onBackPress }/>
				</TopLevel>
			</FullScreen>
		);
	}
}

export default AddExternalView;
