import {isValidBaseURL} from "../../../common/utils.js";
import configuration from "../../../common/configuration.js";
import base32 from "../../../../common/utils/base32.js";

const tokenModes = {
	1: {
		keyLen: 52,
		idLength: 52,
		algorithm: "aes-256-cbc"
	}
};

function createToken( messageId, clientKey ) {
	let mode = tokenModes[ configuration.getDefaultTokenMode() ];
	let clientKeyString = base32.encode( clientKey );
	if ( messageId.length !== mode.idLength ) {
		throw new Error( "Invalid messageId length" );
	}

	if ( clientKeyString.length !== mode.keyLen ) {
		throw new Error( "Invalid clientKey length" );
	}

	return messageId + clientKeyString + configuration.getDefaultTokenMode();
}

export function encodeAsUrl( apiUrlBase, messageId, encryptionKey ) {
	let token = createToken( messageId, encryptionKey )
	return `${apiUrlBase}/#message/read/${ token }`;
}

function encodeApiUrlBuffer( apiUrlBuffer, token ) {
	let byte = token.charCodeAt( 0 ) % 256;
	for ( let i = 0; i < apiUrlBuffer.length; i++ ) {
		apiUrlBuffer[ i ] += byte;
	}
	return base32.encode( apiUrlBuffer );
}

function decodeApiUrl( apiUrlString, token ) {
	let apiUrlBuffer = base32.decode( apiUrlString );

	let byte = token.charCodeAt( 0 ) % 256;
	for ( let i = 0; i < apiUrlBuffer.length; i++ ) {
		apiUrlBuffer[ i ] -= byte;
	}
	return apiUrlBuffer.toString();
}

export function encodeAsToken( apiUrlBase, messageId, encryptionKey ) {
	let token = createToken( messageId, encryptionKey );
	let apiUrlBuffer = new Buffer( apiUrlBase );

	return encodeApiUrlBuffer( apiUrlBuffer, token ) + token;
}

export function encodeAsLocalToken( messageId, encryptionKey ) {
	return createToken( messageId, encryptionKey );
}

export function decodeMessageToken( str ) {
	let match = str.match( /^(.*)\/\#message\/read\/(.*)$/ );
	let token, apiUrlBase;
	let mode;

	if ( match ) {
		token = match[ 2 ];
		apiUrlBase = match[ 1 ];
		mode = tokenModes[ token[ token.length - 1 ] ];

		if ( !mode ) {
			throw new Error( `Unsupported encryption mode #${mode}` );
		}
	} else {
		mode = tokenModes[ str[ str.length - 1 ] ];

		if ( !mode ) {
			throw new Error( `Unsupported encryption mode #${mode}` );
		}

		token = str.substr( - mode.idLength - mode.keyLen - 1 );
		apiUrlBase = decodeApiUrl( str.substr( 0, str.length - token.length ), token );
	}
	if ( !apiUrlBase ) {
		//Local token
		apiUrlBase = configuration.getSocketBase();
	}
	if ( !isValidBaseURL( apiUrlBase ) ) {
		throw new Error( "Invalid base url" );
	}
	let messageId = token.substr( 0, mode.idLength );
	let clientKey = base32.decode( token.substr( mode.idLength, mode.keyLen ) );

	return {
			apiUrlBase,
			clientKey,
			messageId,
			algorithm: mode.algorithm
		};
}
