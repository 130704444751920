import React from "react";
import Big from "./en/big.jsx";
import Mobile from "./en/mobile.jsx";
import BigRu from "./ru/big.jsx";
import MobileRu from "./ru/mobile.jsx";
import BigUa from "./ua/big.jsx";
import MobileUa from "./ua/mobile.jsx";

let langSequence = [ "en", "ru", "ua" ];
class KeyboardView extends React.Component {
	constructor() {
		super();
		this.state = {
			lang: langSequence[ 0 ],
			visible: true,
			inFocus: false
		};
		this.changeLang = this.changeLang.bind( this );
	}

	static get contextTypes() {
		return { focusManager: React.PropTypes.object.isRequired };
	}

	componentDidMount() {
		this.context.focusManager.registerKeyboard( this );
	}

	componentWillUnmount() {
		this.context.focusManager.unregisterKeyboard( this );
	}

	show( cb ) {
		this.setState( { visible: true }, cb );
		this.props.onShow && this.props.onShow();
	}

	hide( cb ) {
		this.setState( { visible: false }, cb );
		this.props.onHide && this.props.onHide();
	}

	isCaps() {
		return false;
	}

	changeLang( lang ) {
		if ( typeof lang !== "string" ) {
			let langIndex = langSequence.indexOf( this.state.lang ) + 1;
			langIndex %= langSequence.length;
			lang = langSequence[ langIndex ];
		}
		this.setState( {lang} );
	}

	render() {
		if ( !this.state.visible ) {
			return null;
		}
		switch( this.state.lang ) {
			case "en":
				return (
					<div>
						<Big onLangChange={this.changeLang} />
						<Mobile onLangChange={this.changeLang} />
					</div>
				);
			case "ru":
				return (
					<div>
						<BigRu onLangChange={this.changeLang} />
						<MobileRu onLangChange={this.changeLang} />
					</div>
				);
			case "ua":
				return (
					<div>
						<BigUa onLangChange={this.changeLang} />
						<MobileUa onLangChange={this.changeLang} />
					</div>
				);
		}
		return null;
	}
}

export default KeyboardView;
