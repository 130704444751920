import React from "react";
import _ from "lodash";
import translate from "../../translations/translate.js";

import FullScreen from "../../components/fullscreen.jsx";
import TopLevel from "../common/top.level.view.jsx";
import Translation from "../../components/translation.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import ImageButton from "../../components/button.image.jsx";
import OnlineStatus from "../../components/online.status.jsx"

class SetLanguageView extends React.Component {
	constructor() {
		super();
		this.onBackPress = this.onBackPress.bind( this );
	}
	onBackPress() {
		this.props.onBack();
	}
	onLanguageSelect( id ) {
		this.props.onSetLanguage( { language: id } );
	}
	render() {
		let languages = translate.getLanguages();
		let currentLanguageId = this.props.profile.language || translate.getCurrentLanguage().getId();
		return (
			<FullScreen>
				<TopLevel>
					<header>
						<ImageButton type="icon-arrow-left" onClick={this.onBackPress}/>
						<span className="header-caption"><Translation textId="options.language.header"/></span>
						<OnlineStatus/>
					</header>
					<main>
						<div className="menu-list">
							{
								_.values( _.mapValues( languages,
									( title, id ) =>
										<div className="item" onClick={ this.onLanguageSelect.bind( this, id ) } key={ id }>
											{
												currentLanguageId === id
												? <img className="icon" src="images/icon-v.svg" />
												: null
											}
											<span className="label">{ title }</span>
										</div>
									) )
							}
						</div>
					</main>
				</TopLevel>
				<DeviceBackButton onPress={ this.onBackPress } />
			</FullScreen>
		);
	}
}

SetLanguageView.propTypes = {
	onSetLanguage: React.PropTypes.func,
	onBack: React.PropTypes.func
};

SetLanguageView.defaultProps = {
	onSetLanguage: _.noop,
	onBack: _.noop
};
export default SetLanguageView;
