"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var BaseCipher = function () {
	function BaseCipher(blockSize, usePadding) {
		_classCallCheck(this, BaseCipher);

		this._usePadding = usePadding;
		this._blockSize = blockSize;
	}

	_createClass(BaseCipher, [{
		key: "append",
		value: function append() {
			throw new Error("Not implemented");
		}
	}, {
		key: "getResultThen",
		value: function getResultThen() {
			throw new Error("Not implemented");
		}
	}, {
		key: "getBlockSize",
		value: function getBlockSize() {
			return this._blockSize;
		}
	}]);

	return BaseCipher;
}();

exports.default = BaseCipher;