import React from "react";
import TranslationBase from "../abstract/translation.base.js";
import configuration from "../../../common/configuration.js";

class TranslationEn extends TranslationBase {
	constructor() {
		super( "en", "English", {
			"empty":                                     "",
			"logout.soon":                               "Logout soon",
			"voice.recording.text":                      "Recording in progress.",
			"voice.recording.cancel":                    "Cancel",
			"voice.recording.send":                      "Send",
			"voice.recording.init":                      "Get ready to say your voice message",
			"voice.recording.recorded":                  "Recording completed",
			"voice.recording.timeout":                   msLeft => `Your message will be sent automatically after ${ ( msLeft / 1000 ) | 0 } second(s)`,
			"voice.message.unsupported":                 "Voice messages are not supported on this device",
			"voice.recording.error.return":              "Return to chat",
			"warn.expiring.header":                      "Expiring",
			"warn.expiring.text":                        days => `You have less than ${days} day(s) to pay to continue using ${process.env.APP_NAME}. Activate now?`,
			"warn.expiring.button":                      "Ok",
			"warn.expired.header":                       "Expired",
			"warn.expired.text":                         `Please pay to continue using ${process.env.APP_NAME}. You will not be able to write messages for now.`,
			"warn.expired.button":                       "Ok",
			"notification.title":                        `${process.env.APP_NAME} background`,
			"registration.header":                       "BEFORE YOU BEGIN",
			"registration.text":												 `Welcome to ${process.env.APP_NAME}`,
			"upgrade.header":                            "FULL VERSION",
			"upgrade.text":                              "",
			"upgrade.password.placeholder":              "PASSWORD (OPTIONAL)",
			"upgrade.password.tooltip":                  <span>Password of your fake account</span>,
			"upgrade.fake.checkbox":                     "Do you have fake account?",
			"upgrade.fake.text":                         "",
			"upgrade.continue.button":                   "CONTINUE",
			"invite.code.placeholder":                   "INVITE CODE",
			"invite.code.paste.button":                  "PASTE INVITE CODE HERE",
			"registration.main.nickname.placeholder":    "YOUR NAME (OPTIONAL)",
			"registration.main.nickname.tooltip":        "This is how others will see you by default",
			"registration.main.password.placeholder":    "PASSWORD (OPTIONAL)",
			"registration.main.password.tooltip":        <span>Your password will not go to the server.<br />Your local data is encrypted with this password.</span>,
			"registration.fake.checkbox":                "Use Fake account?",
			"registration.fake.text":                    "",
			"registration.fake.warning":                 <span>You must use different password<br/>for fake account</span>,
			"registration.continue.button":              "CONTINUE",
			"registration.skip.button":                  "SKIP",
			"registration.restore.button":               "Restore Backup",
			"registration.selection.header":             "BEFORE YOU BEGIN",
			"registration.selection.text":               configuration.getBuildMode() === "enterprise" ?
						<span>
							<p>To activate your account please follow these steps:</p>
							<p>1. Ask your system administrator for activation code</p>
							<p>2. Use this code after tapping Use Activation Code button</p>
							<p>3. Use all features of Kryptos available for Enterprise version</p>
							<p>If you have questions or if you need Kryptos Enterprise on your server please e-mail us info@ssg.systems</p>
					 </span> : "",
			"registration.selection.public.button":      "Use public server",
			"registration.selection.private.button":     "Use Activation Code",
			"registration.selection.demo.button":        "Demo mode",
			"demo.expired.text":                         "Demo expired",
			"demo.dialog.text":                          days => `Your Demo will be expired in ${days} days. Activate now?`,
			"demo.dialog.title":                         "Demo Mode",
			"demo.dialog.upgrade" :                      "Activate",
			"demo.dialog.close":                         "Close",
			"demo.dialog.complete":                      `Activation successfull. Now you use full version of ${process.env.APP_NAME}.`,
			"restore.do.button":                         "RESTORE",
			"restore.header":                            "RESTORE",
			"restore.text":                              "",
			"restore.code.placeholder":                  "Enter invite code",
			"restore.code.error":                        "Invalid backup code",
			"restore.code.tooltip":                      "You need to enter backup code or scan it",
			"restore.code.paste.button":                 "Paste from clipboard",
			"restore.progress":                          "Restoring record #{0} of {1}",
			"rejoin.header":                             "DATA PROTECTION",
			"rejoin.text":                               "To ensure that nobody will be able to use data on previous device we need to change long-lived keys for every contact. Please enter passwords for your main and fake (if you have it) accounts to do this.",
			"rejoin.main.password.placeholder":          "MAIN PASSWORD",
			"rejoin.main.password.tooltip":              "Password for main account",
			"rejoin.fake.password.placeholder":          "FAKE PASSWORD",
			"rejoin.fake.password.tooltip":              "Password for fake account",
			"rejoin.main.warning":                       "Main password invalid",
			"rejoin.fake.warning":                       "Fake password invalid",
			"rejoin.equals.warning":                     "Passwords are equals",
			"rejoin.do.button":                          "CONTINUE",
			"login.header":                              "LOG IN",
			"login.password.placeholder":                "PASSWORD",
			"login.password.tooltip":                    "Enter your password",
			"login.continue.button":                     "CONTINUE",
			"login.invalidpassword":                     "Invalid password",
			"invite.duplicate":                          "You already have contact with this name",
			"nickname.empty":                            "Name shouldn't be empty",
			"shake.header":                              "GENERATE CODE",
			"shake.text":                                "— Your account is creating",
			"contact.status.disconnected.explanation":   "Your partner deleted this contact. This may also be because of contact duplication if this contact is shared.",
			"chat.expired.explanation":                  `Please pay to continue using ${process.env.APP_NAME}`,
			"contact.status.disconnected":               "deleted",
			"contact.status.invited":                    "invited",
			"contact.status.offline":                    "offline",
			"contact.status.online":                     "online",
			"contact.status.failed":                     <span style={{color: "red"}}>error</span>,
			"contact.status.joining":                    "Joining",
			"contact.failed.message":                    "There is an error with this contact. You need to remove it and add again. Please tell developers about this error. Delete this contact?",
			"footer.contacts":                           "CONTACTS",
			"footer.groups":                             "GROUPS",
			"footer.options":                            "OPTIONS",
			"contacts.header":                           "CONTACTS",
			"groups.header":                             "GROUPS",
			"options.header":                            "OPTIONS",
			"options.nickname":                          "NICKNAME",
			"options.password":                          "PASSWORD",
			"options.language":                          "LANGUAGE",
			"options.web":                               "WEB VERSION",
			"options.cleanuptime":                       "CLEANUP TIME",
			"options.pin":                               "REQUEST PASSWORD",
			"options.delete":                            "DELETE ACCOUNT",
			"options.updates":                           "UPDATES",
			"options.autoclean.header":                  "AUTOCLEAN",
			"options.autoclean.notset":                  "Not set",
			"options.autoclean.smalltext":               "Select autoclean timeout",
			"options.backup":                            "BACKUP",
			"options.version":                           "VERSION",
			"options.autoclean.no":                      "no",
			"options.pin.no":                            "not",
			"options.pin.header":                        "REQUEST PASSWORD",
			"options.pin.smalltext":                     "Request password after some time of inactivity",
			"options.autoclean.unit.no":                 "-",
			"options.autoclean.unit.sec":                "sec",
			"options.autoclean.unit.min":                number => number === 1 ? "min" : "mins",
			"options.autoclean.unit.hour":               number => number === 1 ? "hour" : "hours",
			"options.autoclean.unit.day":                number => number === 1 ? "day" : "days",
			"options.autoclean.unit.year":               number => number === 1 ? "year" : "years",
			"options.autoclean.continue.button":         "DONE",
			"options.notifications":                     "NOTIFICATIONS",
			"options.keyboard":                          "VIRTUAL KEYBOARD",
			"options.upgrade":                           "ACTIVATE",
			"invite.nickname.tooltip":                   "This nickname will be in your contact list",
			"invite.nickname.placeholder":               "CONTACT NAME",
			"invite.token.tooltip":                      "You can copy and send this token or use QR code reader",
			"invite.token.placeholder":                  "INVITE TOKEN",
			"invite.header":                             "INVITE",
			"invite.copytoast":                          "Your invite token copied to clipboard",
			"invite.qr.bigtext":                         "QR code version of invite",
			"invite.qr.smalltext":                       "— This code is one-time",
			"invite.plain.text":                         "— Text version of invite",
			"inviteUrl.plain.text":                      "— Url of invite",
			"invite.button.generate":                    "GENERATE INVITE CODE",
			"invite.button.copy":                        "COPY INVITE CODE",
			"invite.create.text":                        "",
			"invite.create.external.text":               "",
			"invite.hide.checkbox":                      "Hide my name",
			"sharecontacts.header":                      "SHARE CONTACTS",
			"chat.button.send":                          "SEND",
			"chat.button.voice":                         "VOICE",
			"chat.menu.clear":                           "CLEAR CHAT",
			"chat.menu.clear.remote":                    "CLEAR PARTNER'S CHAT",
			"chat.menu.password":                        "SET PASSWORD",
			"chat.menu.delete":                          "DELETE CONTACT",
			"chat.menu.rename":                          "RENAME CONTACT",
			"chat.menu.renamegroup":                     "RENAME GROUP",
			"chat.menu.send.contacts":                   "SEND CONTACTS",
			"chat.menu.send.files":                      "SEND FILES",
			"chat.menu.send.photo":                      "SEND PHOTO",
			"chat.clear.confirm":                        "Sure clear chat?",
			"chat.clear.confirm.button.yes":             "Clear",
			"chat.clear.confirm.remote":                 "Sure clear your partner chat?",
			"chat.delete.confirm":                       "Sure delete this contact?",
			"chat.fingerprint.label":                    "Fingerprint",
			"chat.randompool.label":                     "Random pool",
			"chat.warning":                              <div className="warning">You must verify this fingerprint<br/>visually on your partner{"'"}s device</div>,
			"chat.warning.button":                       "CLICK AFTER VERIFICATION",
			"chat.group.menu.clear":                     "CLEAR CHAT",
			"chat.group.menu.delete":                    "EXIT GROUP",
			"chat.group.menu.invite":                    "ADD PARTICIPANTS",
			"chat.group.menu.invite.external":           "EXTERNAL PARTICIPANT",
			"chat.group.menu.participants":              "PARTICIPANTS",
			"chat.group.delete.confirm":                 "You will lose messages of this group forever. Exit group?",
			"chat.group.delete.confirm.button.yes":      "Exit",
			"chat.group.invites.notification":           "Users who did not entered this group yet will not get your message:",
			"chat.group.empty":                          "You alone in this group. Click menu button on the top right to invite participants to this group.",
			"chat.empty.text":                           "You have no messages yet",
			"chat.message.delete":                       "Delete",
			"chat.message.edit":                         "Edit",
			"chat.message.forward":                      "Forward",
			"chat.message.reply":                        "Reply",
			"chat.message.edited.text":                  "Edited at",
			"chat.message.deleted.text":                 "Message deleted",
			"chat.message.file.text":                    "File:",
			"chat.message.contact.incoming.text":        "User sent you a new contact",
			"chat.message.contact.outgoing.text":        "Contact sent",
			"chat.message.contact.accept.button":        "Add this contact",
			"chat.message.contact.accept.failed":        "Add contact failed. Ask to send this contact again.",
			"chat.message.workgroup.incoming.text":      "User has added you to a workgroup. This workgroup will be added to your contact list if you accept this invitation.",
			"chat.message.workgroup.outgoing.text":      "Added user to the workgroup.",
			"chat.message.workgroup.accept.button":      "Accept invitation",
			"chat.message.workgroup.unprivileged.text":  "You received invite to workgroup but you have unprivileged account. This mean you cannot join workgroup. Please contact your administrator to get required privileges.",
			"chat.message.group.incoming.text":          "User has added you to a group.",
			"chat.message.group.outgoing.text":          "Added user to the group.",
			"chat.message.group.accept.button":          "Join group",
			"chat.message.file.sending.text":            ( fname, sent, total ) => <span>Sending file {fname}.<br/>{sent} kb of {total} kb completed.</span>,
			"chat.message.file.sending.error":           "Error sending file",
			"chat.message.voice.sending.text":            ( sent, total ) => "Sending voice message",
			"chat.message.voice.sending.error":           "Error sending voice message",
			"chat.message.file.downloading.text":        ( fname, received, total ) => <span>Downloading file {fname}.<br/>{received} kb of {total} kb.</span>,
			"chat.message.file.downloaded.text":         "File {0} downloaded and stored {1}",
			"chat.message.file.open.button":             "Open",
			"chat.message.file.open.error":              "You need to install application to open this file",
			"chat.message.file.inactive.text":           "File {0}. Use web version to download file.",
			"forward.message.header":                    "Forwaring message",
			"forward.message.text":                      "Select recipient(s) of forwarding message",
			"forward.message.button.confirm":            "Forward message",
			"menu.alt":                                  "Menu",
			"contact.rename.header":                     "RENAME",
			"contact.rename.button.done":                "DONE",
			"contact.rename.text":                       "Rename contact {0}",
			"contact.rename.placeholder":                "NEW CONTACT NAME",
			"contacts.menu.header":                      "MENU",
			"contacts.menu.clear":                       "REMOVE ALL MESSAGES",
			"contacts.menu.invite":                      "INVITE CONTACT",
			"contacts.menu.invite.external":             "INVITE BY LINK",
			"contacts.menu.useinvite":                   "ACCEPT INVITATION",
			"contacts.menu.inviteregistration":          "INVITE TO MESSENGER",
			"contacts.menu.clear.confirm":               "Sure clear all history?",
			"contacts.menu.share.contacts":              "CREATE WORKGROUP",
			"contacts.menu.add-user":                    "ADD USER",
			"contacts.menu.exit":                        "EXIT",
			"contacts.overflow":                         "You exceeded number of contacts",
			"contacts.invitealreadyused":                "Invite already used",
			"contacts.empty":                            "No contacts yet",
			"contacts.search.empty":                     "Contacts not found",
			"contacts.search.placeholder":               "Contacts search",
			"contacts.workgroup":                        "Workgroup",
			"contacts.workgroup.collapsed":              "Tap to expand",
			"contacts.shared":                           "Shared",
			"contacts.add.assistant":                    "Add Assistant",
			"contacts.add.assistant.text":               `Assistant is a real person who can help you if you have any questing regarding ${process.env.APP_NAME}. You can also leave feedback using Assistant. Please note that Assistant will not know your nickname or anything about you. It will be your regular contact.`,
			"contacts.add.assistant.placeholder":        "Contact name",
			"contacts.add.assistant.ok.button":          "Add",
			"group.rename.header":                       "Rename group",
			"group.rename.text":                         "",
			"group.rename.placeholder":                  "Group name",
			"groups.empty":                              "You have no groups yet. Click on the top-right to create one",
			"groups.search.empty":                       "No groups found",
			"group.new.contacts.empty":                  "You need to create at least one contact to create a group",
			"chats.search.placeholder":                  "Search for chat",
			"chats.search.empty":                        "No chats found",
			"useinvite.header":                          "USE INVITE",
			"useinvite.text":                            "",
			"useinvite.text2":                           "",
			"useinvite.nickname.placeholder":            "CONTACT NAME",
			"useinvite.nickname.tooltip":                "This is how (s)he will appear at your contact list",
			"useinvite.continue.button":                 "CONTINUE",
			"useinvite.code.tooltip":                    "Invitation code. It is recommended to use QR code for this. Click top right button to scan.",
			"useinvite.code.error":                      "Wrong invite code",
			"options.nickname.header":                   "SET NICKNAME",
			"options.nickname.text":                     "Set your nickname. This is how others will see you by default.",
			"options.nickname.placeholder":              "NICKNAME",
			"options.nickname.button.done":              "DONE",
			"options.password.duplicate.alert":          "This password is already in use in your account",
			"options.password.header":                   "SET PASSWORD",
			"options.password.text":                     "Set your password",
			"options.password.placeholder":              "PASSWORD",
			"options.password.button.done":              "DONE",
			"options.password.change.confirm":           "Sure to change password?",
			"options.delete.confirm":                    "Sure delete your account?",
			"options.language.header":                   "SET LANGUAGE",
			"options.update.header":                     "Update",
			"options.update.tocurrent.text":             "You can update your messenger",
			"options.update.tocurrent.installedversion": "installed: {0}",
			"options.update.tocurrent.latestversion":    "latest: {0}",
			"options.update.uptodate.text":              "Your version {0} is up to date",
			"options.update.uptodate.noupdates":         "No updates available",
			"options.update.notify.always.option":       "Always notify",
			"options.update.notify.skip.option":         "Skip only this update",
			"options.update.notify.never.option":        "Never notify",
			"options.update.notify.text":                "Select notification strategy",
			"options.update.advanced.button":            "Advanced >>",
			"options.update.installlatest.button":       "Install the latest version",
			"options.update.tothis.button":              "Install version {0}",
			"options.update.versionlist.text":           "You may update or rollback to the following versions",
			"options.update.log.downloading":            "Downloading update",
			"options.update.log.download.progress":      ( loaded, total ) => !total ? `Downloaded ${loaded} kb` : `Downloaded ${loaded} of ${total} kb`,
			"options.update.log.download.completed":     "Done!",
			"options.update.log.validation.begin":       "Update validation..",
			"options.update.log.validation.end":         "Validation completed",
			"options.update.log.unzip.progress":         ( loaded, total ) => `Unzipping ${(100 * loaded / total).toFixed( 2 )}%`,
			"options.update.log.unzip.completed":        "done",
			"options.update.log.applying":               "Applying update",
			"options.web.existing.text":                 "You have active session.",
			"options.web.existing.activity":             timestamp => <span>Last activity: {timestamp}</span>,
			"options.web.disconnect.button":             "Close session",
			"options.web.header":                        "WEB VERSION",
			"options.web.notification":                  "Connected web. Click to close.",
			"options.web.text":                          "Navigate to address {0} in your browser and scan qr-code to access web version.",
			"options.backup.header":                     "BACKUP",
			"prompt.default.header":                     "TEXT HERE",
			"prompt.default.text":                       "Prompt explanation",
			"prompt.default.placeholder":                "ENTER",
			"prompt.default.button.done":                "DONE",
			"options.update.revert.text":                `You can also revert an update you applied to the initially installed version of ${process.env.APP_NAME}.`,
			"options.update.revert.button":              "Revert",
			"options.update.revert.confirm":             "Sure to revert all changes?",
			"common.retry":                              "Retry",
			"common.loading":                            "Loading...",
			"common.wait.title":                         ( total, current ) => total ? `Wait... ${current}/${total}` : "Wait...",
			"common.error":                              "Error",
			"common.network.error":                      "No internet connection",
			"common.noInternet.notification":            "No internet connection. Limited Functionaly",
			"common.contact.name.empty":                 "",
			"show.password":                             "Show password",
			"dialogs.paste":                             "Paste",
			"dialogs.copy":                              "Copy",
			"dialogs.clear":                             "Clear",
			"dialog.title.alert":                        "Alert",
			"dialog.ok":                                 "Ok",
			"dialog.confirm":                            "Confirm",
			"dialog.retry":                              "Retry",
			"dialog.cancel":                             "Cancel",
			"dialogs.copied":                            "Copied to clipboard",
			"dialogs.share":                             "Share",
			"remove.account.data":                       "Remove account data",
			"group.add.nickname.placeholder":            "Your name in a group",
			"group.add.nickname.header":                 "NICKNAME",
			"group.add.nickname.text":                   "",
			"group.add.name.placeholder":                "Group name",
			"group.add.name.header":                     "GROUP NAME",
			"group.add.name.text":                       "Enter name of a new group",
			"group.participantlist.header":              "PARTICIPANT LIST",
			"group.participantlist.invites":             "Invited:",
			"group.add.participants.header":             "+PARTICIPANTS",
			"group.add.participants.text":               "Add contacts to the group",
			"group.add.participants.button":             "Add to group",
			"group.add.participants.button.empty":       "Do not add to group",
			"group.invite.delete.confirm":               "Delete invite?",
			"workgroup.add.nickname.placeholder":        "Your name in a workgroup",
			"workgroup.add.nickname.header":             "NICKNAME",
			"workgroup.add.nickname.text":               "You may set your default nickname at the options page",
			"sharedcontacts.add.workgroup.header":       <span style={{fontSize: "70%"}}>ADD TO WORKGROUP</span>,
			"sharedcontacts.add.workgroup.text":         "Adding contacts to workgroup",
			"sharedcontacts.add.contacts.header":        "+CONTACTS",
			"sharedcontacts.add.contacts.text":          "Adding shared contacts for workgroup",
			"sharedcontacts.add.name.placeholder":       "NAME",
			"sharedcontacts.add.name.header":            <span style={{fontSize: "70%"}}>CREATE WORKGROUP</span>,
			"sharedcontacts.add.name.text":              "",
			"sharedcontacts.add.nickname.placeholder":   "Your name in workgroup",
			"sharedcontacts.add.nickname.header":        "Your name",
			"sharedcontacts.add.nickname.text":          "You can set your name in options",
			"sharedcontacts.dashboard.header":           "WORKGROUP",
			"sharedcontacts.workgroup.edit.header":      "WORKGROUP",
			"sharedcontacts.dashboard.workgroup.edit":   "Workgroup",
			"sharedcontacts.dashboard.addcontacts":      "Add contacts",
			"sharedcontacts.dashboard.participant":      "Participant rights",
			"sharedcontacts.dashboard.allowworkgroupedit":"Workgroup",
			"sharedcontacts.dashboard.allowcontactsedit":"Contacts",
			"sharedcontacts.dashboard.setrights.button": "Save",
			"sharedcontacts.dashboard.workgroup.rights": "Rights",
			"sharedcontacts.dashboard.workgroup.add":    "Add",
			"sharedcontacts.dashboard.workgroup.participant.delete": p => `Are you sure you want to delete participant ${p?p.nickname:""}?`,
			"sharedcontacts.dashboard.workgroup.participant.delete.yes": "Delete",
			"sharedcontacts.dashboard.workgroup.invite.delete": p => `Are you sure you want to delete invited participant ${p?p.nickname:""}?`,
			"sharedcontacts.dashboard.multiinvite":      "View Multiple Invite",
			"sharedcontacts.dashboard.multiinvite.create":"Create Multiple Invite",
			"sharedcontacts.dashboard.autoclean":        "Set Autoclean",
			"sharedcontacts.dashboard.autoclean.remote": "Set Autoclean for contacts",
			"sharedcontacts.workgroup.edit.invites":     "Invited:",
			"sharedcontacts.dashboard.workgroup.rights.text":"Please choose edit rights",
			"sharedcontacts.search.placeholder":         "Search",
			"sharedcontacts.search.empty":               "No contacts found",
			"send.contacts.header":                      "Send contacts",
			"send.contacts.text":                        "Choose contacts to send",
			"access.denied":                             "Access denied",
			"multiinvite.delete.button":                 "Delete",
			"web.common.confirm.button.no":              "Cancel",
			"web.contact.menu.rename":                   "Rename",
			"web.contact.menu.delete":                   "Delete",
			"web.contact.menu.send.contact":             "Share contact",
			"web.contacts.menu.send.contacts":           "Send contacts",
			"web.contacts.menu.clear.history":           "Clear history",
			"web.contacts.menu.clear.remotehistory":     "Clear parnter's history",
			"web.contacts.menu.invite":                  "Invite",
			"web.contacts.menu.invite.external":         "External Invite",
			"web.contacts.invite.content.absent":        "User has not accepted invite yet",
			"web.contacts.menu.acceptInvite":            "Accept Invite",
			"web.contacts.menu.businessCards":           "Business Card",
			"web.contact.rename.title":                  "Rename Contact",
			"web.contact.delete.title":                  "Delete Contact",
			"web.contact.delete.button.yes":             "Delete",
			"web.group.rename.title":                    "Rename group",
			"web.group.exit.title":                      "Exit group",
			"web.group.exit.button.yes":                 "Exit",
			"web.group.menu.rename":                     "Rename",
			"web.group.menu.exit":                       "Exit",
			"web.group.menu.participants":               "Participants",
			"web.chats.create":                          "Create new chat with...",
			"web.chats.title":                           "Chats",
			"web.contacts.loading":                      "Loading in progress...",
			"web.common.prompt.close.button":            "Close",
			"web.common.prompt.save.button":             "Save changes",
			"web.accept.invite.text1":                   "You add a new contact by using invite",
			"web.accept.invite.placeholder":             "Enter invite code",
			"web.accept.invite.text2":                   "You can rename contact or leave the default name",
			"web.accept.invite.add.button":              "Add to list",
			"web.accept.invite.title":                   "Accept invite",
			"web.invite.text1":                          "Show QR-code to scan",
			"web.invite.text2":                          `Send text code. It is very important that this
			                                              code must me received by intended person only.`,
		  "web.invite.text3":                          `Send address to chat in browser. Please note that this address is onetime.`,
			"web.invite.copied.toast":                   "Copied",
			"web.invite.copy_failed.toast":              "Press Ctrl+C to copy",
			"web.invite.copy.button":                    "Copy",
			"web.contacts.title":                        "Contacts",
			"web.contact.loading.text":                  "Loading...",
			"web.newinvite.text1":                       `To add a contact you should create invite and let your friend to accept it or vice versa.
			                                              You are creating an invite.`,
			"web.newinvite.name.text":                   "Enter name of your new contact",
			"web.newinvite.hide.name.label":             "Hide my name",
			"web.newinvite.button":                      "Invite",
			"web.newinvite.title":                       "New invite",
			"web.send.contacts.loading.text":            "Loading...",
			"web.send.contacts.button":                  "Send",
			"web.send.contacts.title":                   "Sending contacts",
			"web.send.contacts.progress.text":           "Sending contacts in progress. It can take some time which depends on number of selected contacts.",
			"web.group.create.name":                     "Group name:",
			"web.group.create.nickname":                 "Your name:",
			"web.group.create.name.placeholder":         "Group",
			"web.group.create.nickname.placeholder":     "Nickname",
			"web.group.create.participants":             "Participants:",
			"web.group.create.button":                   "Create group",
			"web.group.create.title":                    "Creating new group",
			"web.group.create.progress.text":            "Group is being created. It can take some time which depends on number of group participants.",
			"web.group.loading":                         "Loading group...",
			"web.groups.list.empty":                     <p>You have no groups yet. <br />Time to create one!</p>,
			"web.groups.loading.text":                   "Loading...",
			"web.groups.title":                          "Groups",
			"web.chats.list.empty":                      <p>You have no chats yet. <br />Time to create one!</p>,
			"web.contacts.list.empty":                   <p>You have no contacts yet. <br />Time to create one!</p>,
			"web.account.nickname.label":                "Your name",
			"web.account.nickname.text":                 "This name will appear at other's contact list",
			"web.account.notification.header":           "Notifications settings",
			"web.account.notification.vibrate":          "Vibrate on incoming messages",
			"web.account.notification.incoming":         "Sound on incoming messages",
			"web.account.notification.outgoing":         "Sound on outgoing messages",
			"web.account.fakeaccount.header":            "Fake account",
			"web.account.fakeaccount.text":              "This will help you to hide the real contact list with the message history when you are forced to show your data.",
			"web.account.fakeaccount.create.button":     "Create account",
			"web.account.backup.header":                 "Backup",
			"web.account.backup.text":                   "Backup will allow you to continue using your account in if you lose your device or delete an application.",
			"web.account.backup.create.button":          "Create backup",
			"web.account.delete.header":                 "Delete account",
			"web.account.delete.text":                   "Deleting account will lose your data. Make the backup to be able to use it lately.",
			"web.account.delete.button":                 "Delete account",
			"web.account.title":                         "Account",
			"web.account.delete.prompt.title":           "Delete account",
			"web.account.delete.prompt.text":            "Your fake account will also be deleted if you have it.",
			"web.account.delete.prompt.button.yes":      "Delete",
			"web.account.delete.progress.title":         "Deleting",
			"web.account.delete.progress.text":          "Please wait",
			"web.application.update.head":               "Application update",
			"web.application.update.text":               "You can update an application manually or use automatic update.",
			"web.application.update.autoupdate.label":   "Turn on automatic update",
			"web.application.update.currentversion":     "Current version: {0}",
			"web.application.update.button":             "Update now",
			"web.application.invite.head":               "Invite",
			"web.application.invite.text":               "Invite your friend!",
			"web.application.invite.button":             "Invite",
			"web.application.language.head":             "Language selection",
			"web.application.language.text":             "Our application is available in two languages for now. Expect more soon!",
			"web.application.title":                     "Application",
			"web.info.title":                            "Information",
			"web.notifications.title":                   "Notifications",
			"web.notifications.content":                 "Allow sending notifications in your browser settings if you need to be notified of new messages.",
			"web.settings.menu.account":                 "Account",
			"web.settings.menu.account.text":            <span>Your name, notification settings,<br />create and delete accounts, backup.</span>,
			"web.settings.menu.security":                "Security",
			"web.settings.menu.security.text":           <span>Create or change password<br />manage message history</span>,
			"web.settings.menu.application":             "Application",
			"web.settings.menu.application.text":        <span>Application update, invite<br />friends, change language,<br /></span>,
			"web.settings.menu.info":                    "Information",
			"web.settings.menu.info.text":               "Web-application, FAQ & feedback",
			"web.settings.menu.notifications":           "Notifications",
			"web.settings.menu.notifications.on":        "On",
			"web.settings.menu.notifications.off":       "Off",
			"web.settings.menu.notifications.switch":    "Enable browser notifications",
			"web.settings.menu.notifications.warn":      "Browser denied sending notifications. Please enable notifications in browser's settings.",
			"web.settings.menu.users":                   "Users",
			"web.settings.menu.users.text":              "All users of the system",
			"web.settings.title":                        "Settings",
			"web.security.password.label":               "Password",
			"web.security.password.text":                "Password will protect you against unauthorized access. It is not stored anywhere so it is not possible to restore your data if you lose it.",
			"web.security.password.placeholder":         "Password",
			"web.security.password.show.label":          "Show password",
			"web.security.password.change.button":       "Change password",
			"web.security.autolock.label":               "Lock application on minimize",
			"web.security.history.head":                 "History management",
			"web.security.history.text":                 "",
			"web.security.history.cleanonexit.label":    "Clean history on exit",
			"web.security.history.ttl.label":            "Choose lifetime of your messages.",
			"web.security.history.autoclean.unit.no":    "no",
			"web.security.history.autoclean.unit.sec":   "seconds",
			"web.security.history.autoclean.unit.min":   "minutes",
			"web.security.history.autoclean.unit.hour":  number => number === 1 ? "hour" : "hours",
			"web.security.history.autoclean.unit.day":   number => number === 1 ? "day" : "days",
			"web.security.history.autoclean.unit.year":  number => number === 1 ? "year" : "years",
			"web.security.history.cleannow.label":       "Wish to remove your message history?",
			"web.security.history.cleannow.button":      "Remove history",
			"web.security.title":                        "Security",
			"web.fakeaccount.create.title":              "Fake Account Creation",
			"web.fakeaccount.create.text":               <p>If you need to hide the real correspondence,
			                                                it is a good idea to create fake account which
			                                                do not contains discreditable data. You can show
			                                                it instead of the real one. You can create only one
			                                                additional account. <br />
			                                                <b>Important:</b> If you are already inside a fake
			                                                account, the real one will be overriden!</p>,
			"web.fakeaccount.create.button":             "Create",
			"web.fakeaccount.name.placeholder":          "Name",
			"web.fakeaccount.password.placeholder":      "Password",
			"web.fakeaccount.dulplicatePassword.toast":  "Duplicate password",
			"web.security.dulplicatePassword.toast":     "Duplicate password",
			"web.security.passwordChanged.toast":        "Password changed",
			"web.security.clearHistory.title":           "History cleanup",
			"web.security.clearHistory.text":            "Are you sure you wish to delete all your message history?",
			"web.security.clearHistory.button.yes":      "Delete history",
			"web.message.placeholder":                   "New message",
			"web.contacts.add.modal.title":              "Add new contact",
			"web.contacts.add.modal.cancel.button":      "CANCEL",
			"web.contacts.add.invite.menuitem":          "Invite",
			"web.contacts.add.accept.menuitem":          "Accept invite",
			"web.contacts.add.external.menuitem":        "External contact",
			"web.menu.chats":                            "Chats",
			"web.menu.contacts":                         "Contacts",
			"web.menu.groups":                           "Groups",
			"web.menu.sharedcontacts":                   "Workgroup",
			"web.menu.settings":                         "Settings",
			"web.notification.text":                     "Web version is active",
			"web.sharedcontacts.loading":                "Loading...",
			"web.sharedcontacts.title":                  "Workgroups",
			"web.sharedcontacts.list.empty":             <p>You have no workgroups.<br />
			                                                Click to create one.</p>,
			"web.helper.prompt.title":                   "Helper contact name",
			"web.helper.prompt.default":                 "Helper",
			"web.helper.title":                          "Helper",
			"web.helper.text":                           "You can chat with our support agent. Add him to your contact list by clicking a button below:",
			"web.helper.button":                         "Add Helper",
			"web.file.download":                         "Download",
			"web.file.save":                             "Save",
			"web.file.save.mobsafari":                   `Please long tap here than choose "Open in New Tab/Background mode"`,
			"web.file.open":                             "Open",
			"web.file.header":                           "File",
			"web.sharedcontacts.create.nickname.text":   "Select how you would like others to see you in a workgroup.",
			"web.sharedcontacts.create.nickname.placeholder":"Nickname",
			"web.sharedcontacts.create.name.text":       "Enter name of workgroup. This is how shared contacts will see you and other workgroup members.",
			"web.sharedcontacts.create.name.placeholder":"Contact name",
			"web.sharedcontacts.create.nickname.prev":   "Prev",
			"web.sharedcontacts.create.nickname.next":   "Next",
			"web.sharedcontacts.create.name.prev":       "Prev",
			"web.sharedcontacts.create.name.next":       "Next",
			"web.sharedcontacts.create.workgroup.prev":  "Prev",
			"web.sharedcontacts.create.workgroup.next":  "Next",
			"web.sharedcontacts.create.rights.prev":     "Prev",
			"web.sharedcontacts.create.rights.next":     "Next",
			"web.sharedcontacts.create.contacts.prev":   "Prev",
			"web.sharedcontacts.create.contacts.next":   "Next",
			"web.sharedcontacts.create.workgroup.text":  "Select contacts for workgroup",
			"web.sharedcontacts.create.contacts.text":   "Select contacts you want to a shared list. They will be asked to add a new contact with name `{0}` to communicate with all workgroup members at once.",
			"web.sharedcontacts.create.rights.text":     "You can set rights of your workgroup members",
			"web.sharedcontacts.create.wait.text":       "New Workgroup is being created. This can take some time which depends on number of workgroup members and number of contacts.",
			"web.multidescription.exit.title":           "Exit?",
			"web.multidescription.exit.button.yes":      "Exit",
			"web.multidescription.rename.title":         "Rename",
			"web.multidescription.menu.rights":          "Rights",
			"web.multidescription.menu.rename":          "Rename",
			"web.multidescription.menu.workgroup":       "Workgroup",
			"web.multidescription.menu.delete":          "Exit & Delete",
			"web.workgroup.remove.title":                "Remove from workgroup",
			"web.workgroup.remove.button.yes":           "Remove",
			"web.workgroup.add.button":                  "Add new",
			"web.workgroup.add.back.button":             "Back",
			"web.workgroup.add.text":                    "Select contacts you wish to add to workgroup",
			"web.workgroup.add.continue":                "Continue",
			"web.sharedcontacts.rights.done":            "Set rights",
			"web.sharedcontacts.add.next":               "Add",
			"web.multidescription.menu.add":             "Add contacts",
			"web.group.participants.text":               "Edit participant list",
			"web.group.participants.invite.button":      "Invite",
			"web.group.participant.delete.title":        "Delete participant?",
			"web.group.participant.delete.button.yes":   "Delete",
			"web.group.add.contacts.button":             "Invite",
			"web.contacts.meta.group":                   "(Group)",
			"web.chats.main.normal.title":               "Chat with {0}",
			"web.chats.main.group.title":                "Group chat {0}",
			"web.chats.list.title":                      "Chats",
			"web.contacts.main.title":                   "Chat with {0}",
			"web.contacts.list.title":                   "Contacts",
			"web.groups.main.title":                     "Group chat {0}",
			"web.groups.list.title":                     "Groups",
			"web.sharedcontacts.main.title":             "Chat with {0}",
			"web.sharedcontacts.list.title":             "Shared Contacts",
			"web.settings.list.title":                   "Settings",
			"web.settings.account.main.title":           "Account Settings",
			"web.settings.security.main.title":          "Security Settings",
			"web.settings.application.main.title":       "Application Settings",
			"web.settings.info.main.title":              "About",
			"web.settings.notifications.main.title":     "Notifications",
			"web.settings.users.main.title":             "Users",
			"web.login.title":                           "Web Final Messanger Login",
			"web.login.generateing.code":                "Generating Code...",
			"web.login.text1":                           `Please open your ${process.env.APP_NAME}, choose Settings, than Web Interface and scan this QR-code to activate Web Interface`,
			"web.login.text2":                           "It is recommended to use WiFi on your phone to minimize mobile data usage",
			"web.login.create.group":                    "You can also create anonymous group right inside your browser and invite others by using one-time links!",
			"web.login.create.group.button":             "Create anonymous Group",
			"web.chats.newcontact":                      "New Contact",
			"web.offline.title":                         "Offline",
			"web.offline.text":                          "Problems with internet connection",
			"web.opener.textId":                         "This window was opened from another window. Needs to exchange data with that window to start secure session. This should not take a log time.",
			"web.contacts.menu.newtab":                  "Open in new window",
			"contact.status.invited.description":        <span>
			                                                This contact is not paired yet. This can be because of:<br /><br />
			                                                1. Someone sent this contact to you, but the other party(person behind this contact) is offline or not confirmed partnership.<br />
			                                                2. Someone sent you as a contact, but the receiver is offline or not accepted partnership.<br />
			                                                3. You added this contact using multiple invite qr-code or token, but the other party(owner of this multiple invite) is offline or not confirmed partnership yet.
			                                             </span>,
			"contact.shared.status.invited.description": <span>
			                                                This contact is not paired yet. This can be because of:<br /><br />
			                                                1. Someone sent this contact to you, but the other party(person behind this contact) is offline or not confirmed partnership.<br />
			                                                2. Someone sent you as a contact, but the receiver is offline or not accepted partnership.<br />
			                                                3. One of workgroup participants added this contact as shared, but the other party(person behind this shared contact) is offline or not confirmed partnership yet.
			                                            </span>,
			"service.message.group.left":                "User {0} left the group",
			"service.message.group.join":                "User {0} joined the group",
			"backup.copytoast":                          "Backup token copied",
			"backup.qr.bigtext":                         "Backup qr code",
			"backup.qr.smalltext":                       "You can make a photo or print qr code",
			"backup.plain.text":                         "",
			"backup.token.tooltip":                      "",
			"backup.button.copy":                        "Copy token",
			"backup.button.delete":                      "Delete backup",
			"backup.button.create":                      "Create backup",
			"backup.create.text":                        "You can create a backup to be able to restore it in case you lose your phone",
			"backup.fake.checkbox":                      "Have fake account",
			"backup.fake.password.placeholder":          "Fake account password",
			"backup.fake.password.tooltip":              "Please enter password for your fake account(if you have it) in order to create backup",
			"backup.fake.password.invalid":              "Password invalid",
			"backup.progress":                           `Saving record #{0} of {1}`,
			"backup.button.cancel":                      "Cancel",
			"activation.isprivileged.checkbox":          "Privileged",
			"activation.button.generate":                "ADD USER",
			"activation.nickname.placeholder":           "Nickname",
			"activation.nickname.tooltip":               "Nickname will allow to differentiate user in user list.",
			"activation.text":                           "Creating activation code.",
			"activation.header":                         "NEW ACTIVATION",
			"activation.qr.bigtext":                     "",
			"activation.qr.smalltext":                   "",
			"activation.token.placeholder":              "",
			"activation.button.copy":                    "Copy",
			"activation.plain.text":                     "",
			"activation.user.quota.exceeded":            "Users quota exceeded.",
			"activation.privileged.quota.exceeded":      "Privileged users quota exceeded.",
			"web.users.title":                           "Users",
			"activation.copytoast":                      "Copied",
			"web.users.add.title":                       "New user",
			"web.users.add.text":                        "You will be able to add user admin rights only after his registration.",
			"web.users.add.nickname.title":              "Nickname",
			"web.users.add.type.title":                  "Type",
			"web.users.add.close.button":                "Cancel",
			"web.users.add.save.button":                 "Save",
			"web.users.add.button":                      "Add User",
			"web.users.send.button":                     "Send contacts",
			"web.users.send.close.button":               "Cancel",
			"web.users.send.confirm.button":             "Send",
			"web.user.send.title":                       "Send contacts to {0}",
			"web.user.send.text":                        "Choose contacts to send",
			"web.user.send.progress":                    "In progress...",
			"web.users.activation.close.button":         "Close",
			"web.users.add.quota.exceeded":              "You cannot add more users. Quota exceeded. ",
			"web.users.edit.privileged.quota.exceeded":  "You cannot upgrade user's privileges. Privileged users quota exceeded. ",
			"web.users.edit.unactivated":                "User is not registered yet. You are not able to change user type until he/she registers. ",
			"web.users.edit.adminlow":                   "System is low on admin users. That is why you cannot change user type. Please add more admin users to ensure that control over user system will not be lost.",
			"web.users.adminlow.error":                  "System is very low on admin users. Please add more admin users to ensure that control over user system will not be lost.",
			"web.users.adminlow.warn":                   "System is low on admin users. Please add more admin users to ensure that control over user system will not be lost.",
			"web.users.thead.nickname":                  "Nickname",
			"web.users.thead.type":                      "Type",
			"web.users.edit.button":                     "Edit",
			"web.users.delete.button":                   "Delete",
			"web.users.cancel.button":                   "Cancel",
			"web.users.apply.button":                    "Apply",
			"web.users.code.button":                     "Activation code",
			"web.users.type.user":                       "User",
			"web.users.type.powerUser":                  "Privileged",
			"web.users.type.admin":                      "Admin",
			"web.user.confirm.title":                    "Delete user?",
			"web.user.confirm.button.yes":               "Delete user",
			"web.user.activation.title":                 "Activation for {0}",
			"web.user.activation.text":                  "Text token",
			"web.message.delete.title":                  "Delete message?",
			"web.message.delete.button.yes":             "Delete",
			"activation.isaddcontact.checkbox":          "Add to contact list",
			"group.new.progress.header":                 "NEW GROUP",
			"group.new.progress.text":                   "Group is being created. Please wait. It may take some time depending on number of participants.",
			"group.add.progress.header":                 "+PARTICIPANTS",
			"group.add.progress.text":                   "Participants are added. Please wait. It may take some time depending on number of it number.",
			"web.group.external.main.title":             "Chat in group {0}",
			"web.group.external.title":                  "Chat",
			"web.contact.external.main.title":           "Chat with {0}",
			"web.contact.external.title":                "Chat",
			"group.external.header":                     "EXTERNAL PART.",
			"group.external.text":                       "",
			"group.external.placeholder":                "NAME",
			"group.external.copytoast":                  "Copied",
			"group.external.qr.bigtext":                 "QR-code of address",
			"group.external.qr.smalltext":               "— Give a new participant to scan QR-code",
			"group.external.plain.text":                 "— Or send this address using another available messenger, email or SMS",
			"group.external.token.tooltip":              "Your participant should use this address to take part in conversation at this group",
			"group.external.button.copy":                "Copy to buffer",
			"group.external.duplicate.warn":             "Participant with this nickname already exists",
			"group.external.duplicate.invite.warn":      "Participant with this nickname already invited",
			"web.group.external.nickname":               "Nickname of external participant",
			"web.group.external.nickname.placeholder":   "Nickname",
			"web.group.external.nickname.text":          "Your Nickname",
			"web.group.external.name.placeholder":       "Group Name",
			"web.group.external.name.text":              "Group Name",
			"web.group.external.add.button":             "Add",
			"web.group.external.create.group.button":    "Create New Group",
			"web.group.menu.external":                   "Add external participant",
			"web.group.menu.new":                        "Create new group",
			"web.group.external.done.button":            "Close",
			"web.group.external.duplicate.warn":         "Participant with this nickname already exists",
			"web.group.external.duplicate.invite.warn":  "Participant with this nickname already invited",
			"web.group.external.joining":                "Group joining is in progress...",
			"web.group.external.failed":                 "Group joining failed. This may be because this link is already used.",
			"web.group.external.exited":                 "You exited the group",
			"web.unread.title":                          `{0} unread messages - ${process.env.APP_NAME}`,
			"web.businessCards.text1":                   "You can you Business Card as invite that can be used multiple times.",
			"web.businessCards.text2":                   "Business Card token",
			"web.businessCards.copy.button":             "Copy",
			"web.businessCards.copied.toast":            "Copied",
			"web.businessCards.copy_failed.toast":       "Copy failed",
			"web.businessCards.create.text":             "Business Card is an invite that can be used multiple times. You can print it on your own paper busines card. Be aware that adding contact by Business Card is anonymous. This mean that you will need to manually rename new contacts to meaningfull names. By default new contacts will be named BC, BC(1), BS(2) etc",
			"web.businessCards.create.button":           "Create",
			"web.businessCards.title":                   "Business Card",
			"web.businessCards.delete.button":           "Delete",
			"web.businessCards.delete.title":            "Delete your Business Card? Users will no longer be able to use this Business Card if you do this.",
			"web.businessCards.delete.button.yes":       "Yes, Delete",
			"web.businessCards.qr.download":             "Download as PNG"
		} );
	}
}

export default TranslationEn;
