"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _bn = require("bn.js");

var _bn2 = _interopRequireDefault(_bn);

var _prng = require("../random/locators/prng.js");

var _prng2 = _interopRequireDefault(_prng);

var _bnExternal = require("../bn.external/bn.external.js");

var _bnExternal2 = _interopRequireDefault(_bnExternal);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var random = (0, _prng2.default)();
var FIRST_PRIMES_LIMIT = 100000;
var BLOCK_SIZE = 10000;

var Helper = function () {
	function Helper() {
		_classCallCheck(this, Helper);
	}

	_createClass(Helper, [{
		key: "getPrimeThen",
		value: function getPrimeThen(byteLength) {
			return this._getPrimeThen(byteLength);
		}
	}, {
		key: "getIsPrimeThen",
		value: function getIsPrimeThen(bn) {
			var firstPrimes = this._getFirstPrimes();
			for (var i = 0; i < firstPrimes.length; i++) {
				if (bn.modn(firstPrimes[i]) === 0) {
					return Promise.resolve(false);
				}
			}
			return _bnExternal2.default.millerTestExternalThen({ bn: bn, rounds: 64 });
		}
	}, {
		key: "getSchnorrGroupThen",
		value: function getSchnorrGroupThen(params) {
			var _this = this;

			if (!params.qLength) {
				throw new Error("qLength required");
			}
			if (!params.pLength) {
				throw new Error("pLength required");
			}
			return this.getPrimeThen(params.qLength).then(function (q) {
				var pMinBuffer = new Buffer(params.pLength);
				pMinBuffer.fill(0);
				pMinBuffer[0] = 64;

				var pMin = new _bn2.default(pMinBuffer);
				var rMin = pMin.add(q).subn(1).div(q);

				var pMaxBuffer = new Buffer(params.pLength);
				pMinBuffer.fill(0xFF);
				var pMax = new _bn2.default(pMinBuffer);
				var rMax = pMax.subn(1).div(q);
				var rRange = rMax.sub(rMin);
				return _this._generateRRecursiveThen({
					rMin: rMin, rMax: rMax, rRange: rRange, q: q, pLength: params.pLength
				}).then(function (r) {
					var data = {
						rMin: rMin, rMax: rMax, rRange: rRange, q: q, r: r,
						pLength: params.pLength, p: q.mul(r).addn(1)
					};
					return _this._generateHGRecursiveThen(data).then(function (_ref) {
						var h = _ref.h,
						    gRed = _ref.gRed;
						return {
							r: data.r, h: h, q: q, p: data.p, gRed: gRed
						};
					});
				});
			});
		}
	}, {
		key: "_generateRRecursiveThen",
		value: function _generateRRecursiveThen(params) {
			var _this2 = this;

			return random.pseudoRandomDataThen(params.rRange.byteLength() + 2).then(function (rBuffer) {
				var r = new _bn2.default(rBuffer).mod(params.rRange).add(params.rMin);
				if (r.modn(2) !== 0) {
					r = r.addn(1);
				}
				var p = r.mul(params.q).addn(1);
				return _this2.getIsPrimeThen(p).then(function (isPrime) {
					return isPrime ? r : _this2._generateRRecursiveThen(params);
				});
			});
		}
	}, {
		key: "_generateHGRecursiveThen",
		value: function _generateHGRecursiveThen(_ref2) {
			var _this3 = this;

			var p = _ref2.p,
			    r = _ref2.r;

			return random.pseudoRandomDataThen(p.byteLength() + 2).then(function (hBuffer) {
				var h = new _bn2.default(hBuffer).mod(p.subn(1)).addn(1);
				return _bnExternal2.default.redPowExternalThen(h.toRed(_bn2.default.red(p)), r).then(function (res) {
					return res.cmpn(1) === 0 ? _this3._generateRRecursiveThen(params) : { h: h, gRed: res };
				});
			});
		}
	}, {
		key: "_getFirstPrimes",
		value: function _getFirstPrimes() {
			if (!this._firstPrimes) {
				var firstPrimes = {};

				for (var i = 2; i < FIRST_PRIMES_LIMIT; i++) {
					firstPrimes[i] = 1;
				}

				for (var _i = 2; _i < FIRST_PRIMES_LIMIT; _i++) {
					for (var j = _i * 2; j < FIRST_PRIMES_LIMIT; j += _i) {
						delete firstPrimes[j];
					}
				}

				this._firstPrimes = Object.keys(firstPrimes).map(function (key) {
					return key | 0;
				});
			}
			return this._firstPrimes;
		}
	}, {
		key: "_getPrimeThen",
		value: function _getPrimeThen(byteLength) {
			var _this4 = this;

			if (!byteLength) {
				throw new Error("byteLength required");
			}
			return this._getBaseBNThen(byteLength).then(function (baseBN) {
				return _this4._getFirstPrimeOrNullThen(baseBN, _this4._getNotDividedByFirstPrimes(baseBN, 0));
			});
		}
	}, {
		key: "_getBaseBNThen",
		value: function _getBaseBNThen(byteLength) {
			return random.pseudoRandomDataThen(byteLength).then(function (buffer) {
				buffer[0] |= 128;
				return new _bn2.default(buffer);
			});
		}
	}, {
		key: "_getFirstPrimeOrNullThen",
		value: function _getFirstPrimeOrNullThen(baseBN, candidates) {
			var _this5 = this;

			if (candidates.length === 0) {
				return Promise.resolve(null);
			}
			var candidate = candidates.shift();
			var cloned = baseBN.clone();
			cloned.iaddn(candidate);

			return _bnExternal2.default.millerTestExternalThen({ bn: cloned, rounds: 30 }).then(function (isPrime) {
				if (isPrime) {
					return cloned;
				}

				return _this5._getFirstPrimeOrNullThen(baseBN, candidates);
			});
		}
	}, {
		key: "_getNotDividedByFirstPrimes",
		value: function _getNotDividedByFirstPrimes(baseBN, baseOffset) {
			var possiblePrimeOffsets = {};
			for (var i = 0; i < BLOCK_SIZE; i++) {
				possiblePrimeOffsets[i] = 1;
			}
			var firstPrimes = this._getFirstPrimes();
			for (var _i2 = 0; _i2 < firstPrimes.length; _i2++) {
				var fPrime = firstPrimes[_i2];
				var mod = baseBN.modn(fPrime);
				mod += baseOffset;
				mod = mod % fPrime || fPrime;

				for (var j = fPrime - mod; j < BLOCK_SIZE; j += fPrime) {
					delete possiblePrimeOffsets[j];
				}
			}
			var result = Object.keys(possiblePrimeOffsets).map(function (offset) {
				return (offset | 0) + baseOffset;
			});
			return result;
		}
	}]);

	return Helper;
}();

exports.default = new Helper();