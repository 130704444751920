import React from "react";
import _ from "lodash";

import Translation from "../../../components/translation.jsx";
import {getContactInitials} from "../../../../common/utils.js";
import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class CreateChatListBar extends React.Component {
	constructor() {
		super();
		this.state = {
			contacts: null
		};
		this._service = remoteServiceLocator();
	}

	componentWillMount() {
		this._contactsSubscription = (
			this._service.observeContactList()
			.subscribe( contacts => {
				this.setState( {contacts} );
			} )
		);
	}

	componentWillUnmount() {
		this._contactsSubscription.dispose();
	}

	render() {
		let {contacts} = this.state;
		let contactElements;
		if ( contacts ) {
			contactElements = _.map( contacts, contact =>
				<li key={contact.id}>
					<a href="javascript:;" onClick={this.props.startWith.bind( this, contact )}>
						<div className="btn-circle blue">{getContactInitials( contact.name )}</div>
						<span className="contact-info">{contact.name}</span>
						<div className="clearfix"></div>
					</a>
				</li>
			);
		} else {
			contactElements = [];
		}

		return (
			<div className="grow contact contact-new active">
				<ul>
					<li className="hidden-xs new-chat-line">
						<Translation textId="web.chats.create" />
					</li>
					<li>
						<a href="javascript:;" onClick={this.props.startWithNew}>
							<div className="btn-circle new">+</div>
							<span className="contact-info"><Translation textId="web.chats.newcontact"/></span>
							<div className="clearfix"></div>
						</a>
					</li>
					{contactElements}
				</ul>
			</div>
		);
	}

	static get propTypes() {
		return {
			startWith: React.PropTypes.func.isRequired,
			startWithNew: React.PropTypes.func.isRequired
		};
	}
}

export default CreateChatListBar;
