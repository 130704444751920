import React from "react";
import classNames from "classnames";

import Translation from "../../../components/translation.jsx";
import PromptContacts from "../common/prompt.contacts.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class AddContactToGroupContentView extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedIds: [],
			contacts: null
		};
		this.onChange = this.onChange.bind( this );
		this.onAddClick = this.onAddClick.bind( this );

		this._service = remoteServiceLocator();
	}

	componentWillMount() {
		this._contactsSubscription = (
			this._service.observeContactList()
  			.subscribe( contacts => {
  				this.setState( {contacts} );
  			} )
		);
	}

	componentWillUnmount() {
		this._contactsSubscription.dispose();
	}

	onChange( selectedIds ) {
		this.setState( {selectedIds} );
	}

  onAddClick() {
    if ( this.state.isInProgress ) {
      return;
    }
    this.setState( {isInProgress: true} );
    let {selectedIds} = this.state;
    let {contact} = this.props;
    this._service.addGroupParticipantsAsync( contact, selectedIds )
      .subscribe( () => {
        this.setState( {isInProgress: false} );
        this.props.onBack();
      } );
  }

	render() {
		if ( !this.state.contacts ) {
			return <Translation textId="web.send.contacts.loading.text" />;
		}
		return (
			<div className={this.props.className}>
				<div className="invite">
					<PromptContacts
						onChange={this.onChange}
						selectedIds={this.state.selectedIds}
						contacts={this.state.contacts}
					/>
					<div className="text-center">
						<button
							className={ classNames( "btn btn-blue", { disabled: this.state.isInProgress } ) }
							onClick={ this.onAddClick }
						>
							<Translation textId="web.group.add.contacts.button" />
						</button>
					</div>
				</div>
			</div>
		);
	}

  static get propTypes() {
    return {
      onBack: React.PropTypes.func.isRequired
    };
  }
}

export default AddContactToGroupContentView;
