import React from "react";

class OptionComponent extends React.Component {
	shouldComponentUpdate( nextProps, nextState ) {
		return nextProps.item !== this.props.item;
	}
	render() {
		return (
			<option id={ this.props.item.id }>{ this.props.item.text }</option>
		);
	}
}

class DropdownComponent extends React.Component {
	constructor() {
		super();
		this._onChange = this._onChange.bind( this );
	}
	_getOptions() {
		return (
				this.props.items.map( item => <OptionComponent item={ item } key={ item.id } /> )
			);
	}
	_onChange() {
		let selectedId = this.refs.select.value;
		let items = this.props.items;
		let selectedItem = null;

		for ( let i = 0; i < items.length; i++ ) {
			if ( selectedId == items[ i ].id ) {
				selectedItem = items[ i ];
				break;
			}
		}
		if ( !selectedItem ) {
			console.error( "DropdownView: selected item does not exists" );
		}
		this.props.onChange( selectedItem );
	}
	render() {
		return (
			<select value={ this.props.selectedItem.id } onChange={ this._onChange } ref="select">
				{ this._getOptions() }
			</select>
		);
	}
}

DropdownComponent.propTypes = {
	items: React.PropTypes.array.isRequired
};
export default DropdownComponent;
