import React from "react";
import Rx from "rx";
import _ from "lodash";

import TopLevel from "../common/top.level.view.jsx";
import HeaderWait from "../common/header.wait.jsx";

import FullScreen from "../../components/fullscreen.jsx";
import ButtonQrView from "../../components/button.qr.jsx";
import Translation from "../../components/translation.jsx";
import InputInviteView from "../registration/input.invite.jsx";
import InputWithHelp from "../../components/input.withhelp.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import ArrowButton from "../../components/button.continue.jsx";
import ImageButton from "../../components/button.image.jsx";
import Contact from "../../../api/models/contact.js";
import {bindStateProperty} from "../../../common/utils";
import {DynamicToast} from "../../components/toast.jsx";
import OnlineStatus from "../../components/online.status.jsx"

import serviceLocator from "../../../api/services/locators/worker.client.js";

class UseInviteView extends React.Component {
	constructor() {
		super();
		this.state = {
			nickname: "",
			inviteData: null,
			inviteToken: "",
			authPassword: "",
			isValid: false,
			isInProgress: false
		};
		this.onScan = this.onScan.bind( this );
		this.onInviteSet = this.onInviteSet.bind( this );
		this.onContinue = this.onContinue.bind( this );
	}

	static get propTypes() {
		return {
			onBack: React.PropTypes.func
		};
	}

	static get defaultProps() {
		return {
			onBack: _.noop
		};
	}

	componentWillMount() {
		this._nicknamesCache = [];

		this._subscription = serviceLocator().observeContactList().subscribe( contactList => {
			contactList
				.filter( ({multidescriptionId}) => multidescriptionId === -1 )
				.map( contact => contact.name )
		} );
	}

	componentDidMount() {
	}

	validate() {
		let nickname = this.state.nickname.trim()
		let isValid = !!nickname
		  && !_.find( this._nicknamesCache, nickname )
			&& this.state.inviteData;

		if (!isValid && nickname && this.state.inviteData) {
			this.refs.toast.show( { textId: "invite.duplicate" } )
		}
		this.setState( { isValid } );
	}

	onScan( text ) {
		let parts = text.split( "|" );
		let inviteToken = parts[ 0 ];
		let authPassword = parts[ 1 ] || "";
		this.setState( { inviteToken, authPassword } );
	}

	setInviteData( inviteData ) {
		if ( inviteData ) {
			let { nickname = "" } = inviteData;
			this.setState( { inviteData, nickname }, () => this.validate() );
		} else {
			this.setState( { inviteData }, () => this.validate() );
		}
	}

	onInviteSet( inviteData ) {
		this.setInviteData( inviteData );
	}

	onContinue() {
		let errorHandler = (e) => {
			switch ( e.message || e ) {
				case "Invite already used":
					this.refs.toast.show( { textId: "contacts.invitealreadyused" } );
					break;
				default:
					console.error(e);
					this.refs.toast.show( { textId: "contacts.overflow" } );
					break;
			}
			this.setState( { isInProgress: false } );
		};

		let { inviteData, nickname, inviteToken } = this.state;
		this.setState( { isInProgress: true } );
		serviceLocator().acceptInviteAsync( inviteToken, nickname.trim() )
			.flatMap( () => inviteData.type === "invite"
				? serviceLocator().removeMessageAsync( inviteToken )//TODO: move this logic to service
				: Rx.Observable.just()
			)
			.subscribe( this.props.onBack, errorHandler );
	}

	cleanInviteData() {
		this.setInviteData(null);
	}

	renderHeader() {
		if ( !this.state.isInProgress ) {
			return (
				<header>
					<ImageButton type="icon-arrow-left" onClick={this.props.onBack} id="back"/>
					<span className="header-caption"><Translation textId="useinvite.header"/></span>
					<ButtonQrView onScan={ this.onScan }/>
					<OnlineStatus/>
				</header>
			);
		}
		return <HeaderWait />;
	}

	render() {
		let { inviteToken, authPassword, nickname } = this.state;
		return (
			<TopLevel>
				{ this.renderHeader() }
				<main>
					<div className="small-text">
						<Translation textId="useinvite.text" />
					</div>
					<InputInviteView
						token={inviteToken}
						authPassword={authPassword}
						onTokenChange={bindStateProperty( this, "inviteToken" )}
						onInviteSet={this.onInviteSet}
						cleanInviteData={this.cleanInviteData.bind(this)}
						id="token"
						type="invite/multiinvite"
					/>

					<div className="small-text">
						<Translation textId="useinvite.text2" />
					</div>

					<InputWithHelp
						type="text"
						tooltipTextId="useinvite.nickname.tooltip"
						placeholderTextId="useinvite.nickname.placeholder"
						value={nickname}
						firstUppercased={true}
						onChange={bindStateProperty( this, "nickname", () => this.validate() )}
						id="name"
					/>
		 			<DynamicToast ref="toast"/>
					<ArrowButton
						enabled={this.state.isValid && !this.state.isInProgress}
						caption="useinvite.continue.button"
						handleClick={this.onContinue}
						id="continue-accept"
					/>
				</main>
				<DeviceBackButton onPress={this.props.onBack}/>
			</TopLevel>
		);
	}
}

export default UseInviteView;
// TODO: mutiinvite accept
