import React from "react";
import _ from "lodash";
import Prompt from "../common/prompt.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

class RenameContactView extends React.Component {
	constructor() {
		super();
		this.onSetNickname = this.onSetNickname.bind( this );
		this.validateNickname = this.validateNickname.bind( this );
		this._service = serviceLocator();
		this.state = {};
	}

	componentWillMount() {
		let { contact } = this.props;
		this.contactListSubscription = (
			( contact.type === "multidescription"
			? this._service.observeSharedContacts()
			: this._service.observeContactList() )
				.map( contactList =>
					_.filter(
						contactList,
						( { id, multidescriptionId } ) =>
							( multidescriptionId === contact.multidescriptionId )
							&&
							( id !== contact.id )
					)
				)
				.map( contactList => _.map( contactList, "name" ) )
				.subscribe( names => {
					this.setState( {names} );
				} )
		);
	}

	componentWillUnmount() {
		this.contactListSubscription.dispose();
	}

	onSetNickname( value ) {
		let { onBack, contact } = this.props;
		let name = value.trim();
		if ( name === contact.name ) {
			onBack();
			return;
		}
		// name = this._service.getUnconflictedName( name, contact.multidescriptionId );

		this._service.renameContactAsync( contact, name )
			.subscribe( onBack );
	}

	validateNickname( nickname ) {
		let errors = [];
		nickname = nickname.trim();
		let isDuplicate = _.find( this.state.names, nickname );

		if ( isDuplicate ) errors.push( "invite.duplicate" );
		if ( !nickname ) errors.push( "nickname.empty" );
		return errors;
	}

	render() {
		let { onBack, contact } = this.props;
		return (
			<Prompt
				onSetValue={this.onSetNickname}
				onBack={onBack}
				validate={this.validateNickname}
				headerTextId="contact.rename.header"
				textId="contact.rename.text"
				placeholderTextId="contact.rename.placeholder"
				defaultValue={contact.name}
				fullScreen={false}
				autofocus={true}
				firstUppercased={true}
			/>
		);
	}
}

export default RenameContactView;
