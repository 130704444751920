import React from "react";
import Rx from "rx";
import classNames from "classNames";

import Translation from "../../../components/translation.jsx";

class MobileMenu extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentWillMount() {
	}

	componentWillUnmount() {
	}

	renderCount( count ) {
		if ( !count ) {
			return null;
		}
		return <div className="amount">{count}</div>;
	}

	render() {
		return null;
	}
}

export default MobileMenu;
