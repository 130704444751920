import Rx from "rx";

import HookableRepository from "./hookable.js";

class SeedRepository extends HookableRepository {
	constructor( StorageType ) {
		super( StorageType, "seed.dat" );
	}

	replaceAsync( buffer ) {
		return this.replaceDataAsync( buffer );
	}

	readAsBufferAsync( ) {
		return this.lockStorageAsync( storage => storage.readAsBufferAsync() );
	}

	getModificationTimeAsync() {
		return Rx.Observable.fromPromise(this.getModificationTimeThen());
	}

	getModificationTimeThen() {
		return this.lockStorageThen( storage =>
			storage.getModificationTimeThen().then(modificationTime => {
				if (modificationTime > +new Date) {
					return (
						storage.readAsBufferThen()
							.then(buffer => storage.replaceThen(buffer))
							.then(() => +new Date)
					);
				}
				return modificationTime;
			})
		);
	}
}

export default SeedRepository;
