import Rx from "rx";
import _ from "lodash";
import ssgCrypto, {Key, KEY_KINDS, Config} from "ssg.crypto";

import {
	receiveMessageThen,
	isValidToken,
} from "../common/utils.js";
import { receiveMessageAsync } from "../api/models/message/technical.js";
import { connectKeyMap } from "../api/services/remote.js";

import { mock, unmock } from "../api/repositories/storage/default.storage.js";
import RemoteFileStorage from "../api/repositories/storage/remote.file.storage.js";
if (!global.window) {
	//set default storage to remote storage for Worker process
	mock( RemoteFileStorage );
}

import MessageModel from "../api/models/message/message.js";
import MessageSender from "../api/models/message/message.sender.js";
import {MESSAGE_TYPE} from "../api/models/chat.message.js";
import BinarySource from "../api/models/binary.source.js";
import BinaryStream from "../api/models/binary.stream.js";
import Attachment from "../api/models/message/attachment.js";
import MessageSerializer from "../api/models/message/message.serializer.js";
import {decodeMessageToken} from "../api/models/message/address.codec.js";
import {setRegistrationIdToSkip} from "../api/transport/client.server.base.js";

import configuration from "../common/configuration.js";
import {
	deserializeObjectWithKeysFromManagedBufferThen,
	deserializeObject
} from "../common/serializer.js";
import currentUserServiceLocator from "../api/services/locators/current.user.js";
import adminServiceLocator from "../api/services/locators/admin.js";
import sharedcontactsServiceLocator from "../api/services/locators/sharedcontacts.js";
import profileServiceLocator from "../api/services/locators/profile.js";
import groupServiceLocator from "../api/services/locators/group.js";
import contactServiceLocator from "../api/services/locators/contact.js";
import logServiceLocator from "../api/services/locators/log.js";
import seedServiceLocator from "../api/services/locators/seed.js";
import diagnosticsServiceLocator from "../api/services/locators/diagnostics.js";
import backupServiceLocator from "../api/services/locators/backup.js";
import messageServiceLocator from "../api/services/locators/message.js";
import timeServiceLocator from "../api/services/locators/time.js";
import RemoteControlServer from "../api/transport/remote.control.server.js";
import businessCardServiceLocator from "../api/services/locators/business.card.js";

const DEBOUNCE_INTERVAL = 200;
const IDLE_TIMEOUT = 2000;

function mapContactInList( contact ) {
	if ( !contact ) {
		return null;
	}
	let converted = {
		name: contact.name,
		id: contact.id,
		status: contact.status,
		multidescriptionId: contact.multidescriptionId,
		isAuthenticated: contact.isAuthenticated,
		lastMessageTS: contact.lastMessageTS,
		unreadCount: contact.unreadCount,
		inviteToken: contact.inviteToken,
		failReason: contact.failReason,
		pid: contact.pid && contact.pid.toString( "base64" ),
		type: contact.type,
		isExternal: contact.isExternal
	};
	if ( contact.multiinviteToken ) {
		converted.multiinviteToken = contact.multiinviteToken;
	}
	return converted;
}

class Service {
	constructor() {
		this._contactService = contactServiceLocator();
		this._groupService = groupServiceLocator();
		this._sharedcontactsService = sharedcontactsServiceLocator();
		this._adminService = adminServiceLocator();
		this._profileService = profileServiceLocator();
		this._currentUserService = currentUserServiceLocator();
    this._logService = logServiceLocator();
    this._seedService = seedServiceLocator();
    this._diagnosticsService = diagnosticsServiceLocator();
		this._backupService = backupServiceLocator();
		this._messageService = messageServiceLocator();
		this._timeService = timeServiceLocator();
		this._businessCardService = businessCardServiceLocator();

		this.addToIdleQueue = this.addToIdleQueue.bind( this );
		this._contactService.setIdleHandler( this.addToIdleQueue );
		this._groupService.setIdleHandler( this.addToIdleQueue );
		this._sharedcontactsService.setIdleHandler( this.addToIdleQueue );
		this._adminService.setIdleHandler( func => func() );

		this._messagesObservables = Object.create( null );
		this._inviteId2String = Object.create( null );
    this._remoteMethods = Object.create( null );
    this._remoteObservableMethods = Object.create( null );
		this.remoteObservables = Object.create( null );
    this._mutes = Object.create( null );
		this._initRemoteMethods();
		this._mutesCounter = 0;
		this._callIdCounter = 0;
		this._subjs = Object.create( null );
		this._rpcDataSubj = new Rx.BehaviorSubject( {
			decodingTokens: Object.create( null )
		} );

		this._contactService.onNewMessage( () => {
			this.onNewMessageAsync().subscribe();
		} );
		this._groupService.onNewMessage( () => {
			this.onNewMessageAsync().subscribe();
		} );
		this._callInProgressCount = 0;
		this._prevObservableResultTS = performance.now();
		this._onCallDone = this._onCallDone.bind( this );
		this._onObservableResult = this._onObservableResult.bind( this );
		this._checkRunIdleQueue = this._checkRunIdleQueue.bind( this );
		this._currentUserService.onReload( () => {
			this._callClientAsync( "reload", { isAccountDropped: false } ).subscribe();
		} );
		this._currentUserService.onAccountDrop( () => {
			setTimeout( () => {
				this._callClientAsync( "reload", { isAccountDropped: true } ).subscribe();
			}, 1000 );
			return Rx.Observable.just();
		} );
	}

	_initRemoteMethods( ) {
		this._remoteMethods.inviteContact = this._remote_inviteContact;
		this._remoteMethods.getInviteData = this._remote_getInviteData;
		this._remoteMethods.acceptInvite = this._remote_acceptInvite;
		this._remoteMethods.renameContact = this._remote_renameContact;
		this._remoteMethods.deleteContact = this._remote_deleteContact;
		this._remoteMethods.sendMessage = this._remote_sendMessage;
		this._remoteMethods.sendContacts = this._remote_sendContacts;
		this._remoteMethods.createGroup = this._remote_createGroup;
		this._remoteMethods.exitGroup = this._remote_exitGroup;
		this._remoteMethods.updateProfile = this._remote_updateProfile;
		this._remoteMethods.createFakeAccount = this._remote_createFakeAccount;
		this._remoteMethods.changePassword = this._remote_changePassword;
		this._remoteMethods.clearHistory = this._remote_clearHistory;
		this._remoteMethods.clearRemoteHistory = this._remote_clearRemoteHistory;
		this._remoteMethods.setReadAll = this._remote_setReadAll;
		this._remoteMethods.queryHistory = this._remote_queryHistory;
		this._remoteMethods.addHelperContact = this._remote_addHelperContact;
		this._remoteMethods.addSharedContactList = this._remote_addSharedContactList;
		this._remoteMethods.exitMultidescription = this._remote_exitMultidescription;
		this._remoteMethods.removeWorkgroupParticipant = this._remote_removeWorkgroupParticipant;
		this._remoteMethods.removeWorkgroupInvite = this._remote_removeWorkgroupInvite;
		this._remoteMethods.addToWorkGroup = this._remote_addToWorkGroup;
		this._remoteMethods.setWorkgroupRights = this._remote_setWorkgroupRights;
		this._remoteMethods.shareContacts = this._remote_shareContacts;
		this._remoteMethods.deleteGroupParticipant = this._remote_deleteGroupParticipant;
		this._remoteMethods.addGroupParticipants = this._remote_addGroupParticipants;
		this._remoteMethods.addGroupParticipantExternal = this._remote_addGroupParticipantExternal;
		this._remoteMethods.deleteGroupInvite = this._remote_deleteGroupInvite;
		this._remoteMethods.deleteAccount = this._remote_deleteAccount;
		this._remoteMethods.acceptInviteByInviteId = this._remote_acceptInviteByInviteId;
		this._remoteMethods.joinGroupByInviteId = this._remote_joinGroupByInviteId;
		this._remoteMethods.joinByToken = this._remote_joinByToken;
		this._remoteMethods.joinWorkgroupByInviteId = this._remote_joinWorkgroupByInviteId;
		this._remoteMethods.addUser = this._remote_addUser;
		this._remoteMethods.editUser = this._remote_editUser;
		this._remoteMethods.deleteUser = this._remote_deleteUser;
		this._remoteMethods.sendDeleteMessage = this._remote_sendDeleteMessage;
		this._remoteMethods.sendEditTextMessage = this._remote_sendEditTextMessage;
		this._remoteMethods.getPrivateServerConfig = this._remote_getPrivateServerConfig;
		this._remoteMethods.muteContactNotification = this._remote_muteContactNotification;
		this._remoteMethods.unmuteContactNotification = this._remote_unmuteContactNotification;
    this._remoteMethods.isRegistered = this._remote_isRegistered;
    this._remoteMethods.registerAndLogin = this._remote_registerAndLogin;
    this._remoteMethods.writeRandomSeed = this._remote_writeRandomSeed;
    this._remoteMethods.dropAccount = this._remote_dropAccount;
    this._remoteMethods.logInAndConnect = this._remote_logInAndConnect;
		this._remoteMethods.setWorkgroupAutocleanTime = this._remote_setWorkgroupAutocleanTime;
		this._remoteMethods.setWorkgroupRemoteAutocleanTime = this._remote_setWorkgroupRemoteAutocleanTime;
		this._remoteMethods.createMultiInvite = this._remote_createMultiInvite;
		this._remoteMethods.deleteMultiInvite = this._remote_deleteMultiInvite;
    this._remoteMethods.error = this._remote_error;
		this._remoteMethods.isWebConnectionTokenValid = this._remote_isWebConnectionTokenValid;
		this._remoteMethods.makeWebConnection = this._remote_makeWebConnection;
		this._remoteMethods.closeWebConnection = this._remote_closeWebConnection;
		this._remoteMethods.dropBackup = this._remote_dropBackup;
		this._remoteMethods.cancelCreateBackup = this._remote_cancelCreateBackup;
		this._remoteMethods.rejoinContacts = this._remote_rejoinContacts;
		this._remoteMethods.isPasswordValid = this._remote_isPasswordValid;
		this._remoteMethods.setChatMessageProcessed = this._remote_setChatMessageProcessed;
		this._remoteMethods.isServerConfigured = this._remote_isServerConfigured;
		this._remoteMethods.createUserSystemCreationToken = this._remote_createUserSystemCreationToken;
		this._remoteMethods.disposeObservable = this._remote_disposeObservable;
		this._remoteMethods.setRegistrationId = this._remote_setRegistrationId;
		this._remoteMethods.getDemoLeftTime = this._remote_getDemoLeftTime;
		this._remoteMethods.upgrade = this._remote_upgrade;
		this._remoteMethods.ping = this._remote_ping;
		this._remoteMethods.businessCardCreate = this._remote_businessCardCreate;
		this._remoteMethods.businessCardDelete = this._remote_businessCardDelete;
		this._remoteMethods.businessCardUpdate = this._remote_businessCardUpdate;
		this._remoteMethods.setConfiguration = this._remote_setConfiguration;

		this._remoteObservableMethods.uploadFile = this._remote_uploadFile;
		this._remoteObservableMethods.observeContactList = this._remote_observeContactList;
		this._remoteObservableMethods.observeGroupList = this._remote_observeGroupList;
		this._remoteObservableMethods.observeContactMessages = this._remote_observeContactMessages;
		this._remoteObservableMethods.observeGroupMessages = this._remote_observeGroupMessages;
		this._remoteObservableMethods.observeProfile = this._remote_observeProfile;
		this._remoteObservableMethods.observeChats = this._remote_observeChats;
		this._remoteObservableMethods.observeSharedContacts = this._remote_observeSharedContacts;
		this._remoteObservableMethods.observeSelfRights = this._remote_observeSelfRights;
		this._remoteObservableMethods.observeWorkgroupParticipants = this._remote_observeWorkgroupParticipants;
		this._remoteObservableMethods.observeWorkgroupRights = this._remote_observeWorkgroupRights;
		this._remoteObservableMethods.observeWorkgroupInvites = this._remote_observeWorkgroupInvites;
		this._remoteObservableMethods.observeGroupParticipants = this._remote_observeGroupParticipants;
		this._remoteObservableMethods.observeGroupInvites = this._remote_observeGroupInvites;
		this._remoteObservableMethods.observeWorkgroupAutocleanTime = this._remote_observeWorkgroupAutocleanTime;
		this._remoteObservableMethods.observeWorkgroupRemoteAutocleanTime = this._remote_observeWorkgroupRemoteAutocleanTime;
		this._remoteObservableMethods.observeUserTable = this._remote_observeUserTable;
		this._remoteObservableMethods.observeGlobalUserType = this._remote_observeGlobalUserType;
		this._remoteObservableMethods.observeCommonData = this._remote_observeCommonData;
    this._remoteObservableMethods.observeOnlineStatus = this._remote_observeOnlineStatus;
		this._remoteObservableMethods.observeUnreadCountExcept = this._remote_observeUnreadCountExcept;
		this._remoteObservableMethods.observeTimeResync = this._remote_observeTimeResync;
		this._remoteObservableMethods.observeWebConnectionSession = this._remote_observeWebConnectionSession;
		this._remoteObservableMethods.observeBackup = this._remote_observeBackup;
		this._remoteObservableMethods.createBackup = this._remote_createBackup;
		this._remoteObservableMethods.restoreBackup = this._remote_restoreBackup;
		this._remoteObservableMethods.observeExpireDt = this._remote_observeExpireDt;
		this._remoteObservableMethods.observeBusinessCards = this._remote_observeBusinessCards;
		this._idleQueue = [];
	}

	_afterLogin() {
		this._backupService.init();
	}

	_remote_inviteContact( { contactName, selfNameOverride, isExternal } ) {
		if ( selfNameOverride === undefined ) {
			return (
				this._profileService.getProfileAsync()
					.flatMap( ( { nickname } ) =>
						this._contactService.createContactAndInviteAsync( contactName, nickname, isExternal )
					).map( mapContactInList )
			);
		}
		return (
			this._contactService
				.createContactAndInviteAsync( contactName, selfNameOverride, isExternal )
				.map( mapContactInList )
		);
	}

	_remote_observeContactList() {
		let currentContacts = Object.create( null );
		let isFirst = true;
		return (
			Rx.Observable.combineLatest(
				this._sharedcontactsService.observeContactList(),
				this._contactService.observeContactList(),
				( multidescriptions, contacts ) => ( { multidescriptions, contacts } )
			)
				.debounce( DEBOUNCE_INTERVAL )
				.map( ( { multidescriptions, contacts } ) =>
					_.map(
						_.filter( contacts, (contact) => contact.multidescriptionId === -1 || !_.find(multidescriptions, {id: contact.multidescriptionId}) ),
						mapContactInList
					)
				)
				.map( contacts => this._compactContacts( currentContacts, contacts ) )
				.filter( diff => isFirst ? !( isFirst = false ) : !_.isEmpty( diff ) )
		);
	}

	_compactContacts( currentContacts, contacts ) {
		let diff = [];
		let ids = Object.create( null );

		for ( var i = 0; i < contacts.length; i++ ) {
			var contact = contacts[ i ];
			var json = JSON.stringify( contact );
			if ( json !== currentContacts[ contact.id ] ) {
				diff.push( contact );
				currentContacts[ contact.id ] = json;
			}
			ids[ contact.id ] = 1;
		}
		for ( var id in currentContacts ) {
			if ( !ids[ id ] ) {
				delete currentContacts[ id ];
				diff.push( { id } );
			}
		}
		return diff;
	}

	_remote_observeGroupList( ) {
		let currentContacts = Object.create( null );
		let isFirst = true;
		return (
			this._groupService.observeContactList()
				.debounce( DEBOUNCE_INTERVAL )
				.map( contactList =>
					_.map(
						contactList,
						mapContactInList
					)
				)
				.map( contacts => this._compactContacts( currentContacts, contacts ) )
				.filter( diff => isFirst ? !( isFirst = false ) : !_.isEmpty( diff ) )
		);
	}

	_remote_getInviteData( { token, type } ) {
		let allowedTypes = type.split( "/" );
		return (
			this._contactService.receiveInviteAsync( token )
				.map( inviteData => {
					if ( !~allowedTypes.indexOf( inviteData.type ) ) {
						return null;
					}
					inviteData.tmpPrivateKey && inviteData.tmpPrivateKey.dispose();
					inviteData.seedKey && inviteData.seedKey.dispose();
					inviteData.creatorPublicKey && inviteData.creatorPublicKey.dispose();
					return { nickname: inviteData.nickname };
				} )
				.catch( () => Rx.Observable.just( null ) )
		);
	}

	_remote_acceptInvite( { token, name } ) {
		return (
			this._contactService.receiveInviteAsync( token )
				.flatMap( inviteData =>
					this._contactService.acceptInviteAsync( inviteData, name )
				)
				.catch( error => {
					console.error( "_remote_acceptInvite caught error", error );
					return Rx.Observable.just( false );
				} )
				.flatMap( contact => contact
					? this._contactService.ensureLoadedDetailsAsync( contact )
					: Rx.Observable.just( contact )
				)
				.map( contact => contact ? mapContactInList( contact ) : contact )
		);
	}

	_remote_renameContact( {contactId, contactType, newName} ) {
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
			case "multidescription":
				service = this._sharedcontactsService;
				break;
		}
		newName = service.getUnconflictedName( newName );
		return service.updateAsync( contactId, {name: newName} );
	}

	_remote_deleteContact( { contactId } ) {
		return (
			this._contactService.deleteContactAsync( contactId )
		);
	}

	_remote_sendMessage( { contactId, contactType, message } ) {
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
			default:
				throw new Error( "Invalid contact type" );
		}
		return (
			Rx.Observable.combineLatest(
				service.ensureLoadedDetailsAsync( contactId, true ),
				message.id
				? Rx.Observable.just( message.id )
				: Rx.Observable.fromPromise( ssgCrypto.createRandomBase64StringThen( 32 ) ),
				( contact, id ) => ( { contact, id } )
			)
				.flatMap( ( { contact, id } ) => {
					return (
						service._sendMessageAsync( contact, { id, ...message } )
					);
				} )

		);
	}

	_remote_sendContacts( { contactId, contactIdsToSend } ) {
		return this._contactService.sendContactsAsync( contactId, _.toArray( contactIdsToSend ) );
	}

	_compactMessagesObservable( isGroup, contactId, observable ) {
		let currentMessageList = Object.create( null );
		let isSentAny = false;
        let service = isGroup ? this._groupService : this._contactService;

		return Rx.Observable.combineLatest(
			observable,
			this._contactService.observeContactList(),
			this._groupService.observeContactList(),
			this._sharedcontactsService.observeContactList(),
			( messages, contacts, groups, workgroups ) =>
				( { messages, contacts, groups, workgroups } )
		)
		.debounce( DEBOUNCE_INTERVAL )
		.map( ( { messages, contacts, groups, workgroups } ) => {
			let outMessages = Object.create( null );
			for( let indexStr in messages ) {
				let message = messages[ indexStr ];
				let {id} = message;
				let convertedMessage = this.convertMessage( message, contacts, groups, workgroups );
				convertedMessage.sender = service
						.getParticipantNameOrNull( contactId, message.senderPid );
        if ( !convertedMessage.sender ) {
            let contact = _.find( isGroup ? groups : contacts, { id: contactId } );
            if ( contact ) {
                convertedMessage.sender = contact.name;
            }
        }

				if (message.replyTo && message.replyTo.replySenderPid) {
					convertedMessage.replyingSender = service
							.getParticipantNameOrNull( contactId, message.replyTo.replySenderPid );
				}

				outMessages[ indexStr ] = convertedMessage;
			}
			return outMessages;
		} );
	}

	convertMessage( message, contacts, groups, workgroups ) {
		let json = message.toJson();
		let inviteString;
		switch ( json.contentType ) {
			case "text/plain":
				return json;
			case "contact":
				inviteString = json.contactInviteDataString;
				delete json.contactInviteDataString;
				break;
			case "group":
				inviteString = json.groupInviteDataString;
				delete json.groupInviteDataString;
				break;
			case "workgroup":
				inviteString = json.workgroupInviteDataString;
				delete json.workgroupInviteDataString;
				break;
			default:
				return json;
		}

		let buffer = new Buffer( inviteString, "base64" );
		let inviteData = deserializeObject( buffer );

		json.inviteId = ssgCrypto.hash( buffer ).toString();
		json.name = inviteData.name;
		json.nickname = inviteData.nickname;

		let findFunc;
		if ( inviteData.globalId ) {
			findFunc = ( { sharedId, globalId } ) => ( sharedId.equals( inviteData.sharedId ) )
				|| ( globalId && globalId.equals( inviteData.globalId ) );
		} else {
			findFunc = ( { sharedId, globalId } ) => ( sharedId.equals( inviteData.sharedId ) );
		}
		json.isDuplicate = !!(_.find( contacts, findFunc ) || _.find( groups, findFunc ) || _.find( workgroups, findFunc ) );
		this._inviteId2String[ json.inviteId ] = inviteString;
		return json;
	}

	_remote_acceptInviteByInviteId( { inviteId, multidescriptionId } ) {
		let inviteString = this._inviteId2String[ inviteId ];
		if ( !inviteString ) {
			return Rx.Observable.just( false );
		}
		return (
			this._contactService.acceptInviteIfNotJoinedAsync(
				inviteString,
				multidescriptionId
			)
				.map( () => true )
		);
	}

	_remote_joinGroupByInviteId( { inviteId } ) {
		let inviteString = this._inviteId2String[ inviteId ];
		if ( !inviteString ) {
			return Rx.Observable.just( false );
		}
		return (
			this._groupService.acceptInviteIfNotJoinedAsync( inviteString )
				.map( () => true )
		);
	}

	_remote_joinByToken( { token } ) {
		return (
			receiveMessageAsync( token )
				.flatMap( inviteData => {
					if ( inviteData && inviteData.name ) {
						return (
							this._groupService.acceptInviteDataIfNotJoinedAsync( inviteData )
								.map( group => {
									if ( !group ) {
										return group;
									}
									group = mapContactInList( group );
									group.type = "group";
									return group;
								} )
						);
					}
					return (
						this._contactService.acceptInviteAsync( inviteData, inviteData.nickname )
							.map( contact => {
								if ( !contact ) {
									return contact;
								}
								contact = mapContactInList( contact );
								contact.type = "normal";
								return contact;
							} )
					);
				} )
		);
	}

	_remote_joinWorkgroupByInviteId( { inviteId } ) {
		let inviteString = this._inviteId2String[ inviteId ];
		if ( !inviteString ) {
			return Rx.Observable.just( false );
		}
		return (
			this._sharedcontactsService.acceptWorkGroupInviteIfNotJoinedAsync(
				inviteString
			)
			.map( () => true )
		);
	}

	_remote_observeContactMessages( { contactId } ) {
		return this._compactMessagesObservable( false, contactId,
			this._contactService.ensureLoadedDetailsAsync( contactId, true )
				.flatMap( contact => {
					if ( !contact ) {
						//Deleted
						return Rx.Observable.empty();
					}
					if ( !this._messagesObservables[ contactId ] ) {
						this._messagesObservables[ contactId ] = [];
					}
					let observable = this._contactService.observeMessages( contact );
					this._messagesObservables[ contactId ].push( observable );
					this._contactService.requestMoreHistory( contactId, observable );
					this._contactService.initContact( contactId );
					return (
						observable
							.finally( () => {
								let index = this._messagesObservables[ contactId ].indexOf( observable );
								this._messagesObservables[ contactId ].splice( index, 1 );
							} )
					);
				} )
		);
	}

	_remote_observeGroupMessages( { contactId } ) {
		return this._compactMessagesObservable( true, contactId,
			this._groupService.ensureLoadedDetailsAsync( contactId, true )
				.flatMap( contact => {
					if ( !contact ) {
						return Rx.Observable.empty();
					}
					if ( !this._messagesObservables[ contactId ] ) {
						this._messagesObservables[ contactId ] = [];
					}
					let observable = this._groupService.observeMessages( contact );
					this._messagesObservables[ contactId ].push( observable );
					this._groupService.requestMoreHistory( contactId, observable );
					this._groupService.initContact( contactId );
					return (
						observable
							.finally( () => {
								let index = this._messagesObservables[ contactId ].indexOf( observable );
								this._messagesObservables[ contactId ].splice( index, 1 );
							} )
					);
				} )
		);
	}

	_remote_createGroup( { name, nickname, contactIds } ) {
		return (
			this._groupService.createGroupWithParticipantsAsync(
				name,
				nickname,
				_.toArray( contactIds ),
				this._contactService
			)
			.map( mapContactInList )
		);
	}

	_remote_observeGroupParticipants( { contactId } ) {
		return (
			this._groupService.observeParticipants( contactId )
				.map( ps => _.mapValues( ps, ({nickname}) => ({nickname}) ) )
		);
	}

	_remote_observeGroupInvites( { contactId } ) {
		return (
			this._groupService.observeInvites( contactId )
				.map( ps => _.mapValues( ps, ( { nickname } ) => ( { nickname } ) ) )
		);
	}

	_remote_observeWorkgroupAutocleanTime( { contactId } ) {
		return this._sharedcontactsService.observeAutocleanTime( contactId );
	}

	_remote_observeWorkgroupRemoteAutocleanTime( { contactId } ) {
		return this._sharedcontactsService.observeRemoteAutocleanTime( contactId );
	}

	_remote_setWorkgroupAutocleanTime( { contactId, autocleanTime } ) {
		return this._sharedcontactsService.setAutocleanTimeAsync( contactId, autocleanTime );
	}

	_remote_setWorkgroupRemoteAutocleanTime( { contactId, autocleanTime } ) {
		return this._sharedcontactsService.setRemoteAutocleanTimeAsync( contactId, autocleanTime );
	}

	_remote_createMultiInvite( { contactId } ) {
		return this._sharedcontactsService.createMultiInviteAsync( contactId );
	}

	_remote_deleteMultiInvite( { contactId } ) {
		return this._sharedcontactsService.deleteMultiInviteAsync( contactId );
	}

	_remote_renameGroupParticipant( { contactId, pid, newNickname } ) {
		if ( !pid ) {
			return this._groupService.renameSelfAsync( contactId, newNickname );
		}
		throw new Error( "Not implemented" );
	}

	_remote_deleteGroupParticipant( { contactId, pid } ) {
		return (
			this._groupService.ensureLoadedDetailsAsync( contactId )
				.flatMap( contact =>
					this._groupService.deleteParticipantAsync( contact, pid )
				)
		);
	}

	_remote_deleteGroupInvite( { contactId, pid } ) {
		return (
			this._groupService.ensureLoadedDetailsAsync( contactId )
				.flatMap( contact =>
					this._groupService.deleteInviteAsync( contact, pid )
				)
		);
	}

	_remote_addGroupParticipants( { contactId, contactIds } ) {
		contactIds = _.toArray( contactIds );
		return (
			this._groupService.ensureLoadedDetailsAsync( contactId )
				.flatMap( group =>
					Rx.Observable.fromArray( contactIds )
						.flatMap( id => this._contactService.getContactsAsync()
							.map( contacts => _.find( contacts, { id } ) )
						)
						.filter( contact => !!contact ) //skip if not found
						.flatMap( contact =>
							this._groupService.createInviteOrNullAsync( group, contact.name )
								.flatMap( invite => invite
									? this._contactService.sendInviteToGroupAsync( contact, invite )
									: Rx.Observable.just( null )
								)
						)
					.toArray()
				)
		);
	}

	_remote_addGroupParticipantExternal( { contactId, nickname } ) {
		return (
			this._groupService.ensureLoadedDetailsAsync( contactId )
				.flatMap( group => this._groupService.createExternalInviteAsync( group, nickname ) )
		);
	}

	_remote_observeProfile( ) {
		return (
			this._profileService.mutationObservable
				.filter( profile => !!profile )
				.map( profile => {
					//Skip sensitive data
					let { backup, activation, admin, ...profileData } = profile;
					return profileData;
				} )
		);
	}

	_remote_exitGroup( { id } ) {
		return (
			this._groupService.ensureLoadedDetailsAsync( id )
				.flatMap( contact => this._groupService.deleteGroupAsync( contact ) )
		);
	}

	_remote_exitMultidescription( {id} ) {
		return this._sharedcontactsService.deleteContactAsync( id );
	}

	_remote_updateProfile( {profileChanges} ) {
		return (
			this._profileService.updateProfileAsync( profileChanges )
		);
	}

	_remote_observeChats( ) {
		let currentContacts = Object.create( null );
		return Rx.Observable.combineLatest(
			this._contactService.observeContactsWithMessages(),
			this._groupService.observeContactsWithMessages(),
			( contacts, groups ) =>
				this._compactContacts( currentContacts,
					_.map( contacts, contact => {
						let mappedContact = mapContactInList( contact );
						mappedContact.type = "normal";
						return mappedContact;
					} )
						.concat(
							_.map( groups, contact => {
								let mappedContact = mapContactInList( contact );
								mappedContact.type = "group";
								return mappedContact;
							} )
						)
				)
		).filter( diff => !_.isEmpty( diff ) );
	}

	_remote_createFakeAccount( { name, password } ) {
		return (
			this._currentUserService.tryGetMasterKey( password )
				.flatMap( masterKey => {
					if ( masterKey ) {
						return Rx.Observable.just( false );
					}
					return (
						this._currentUserService.registerNewAsync( name, password )
							.map( () => true )
					);
				} )
		);

	}


	_remote_changePassword( {password} ) {
		return (
			this._currentUserService.tryGetMasterKey( password )
				.flatMap( masterKey => {
					if ( masterKey ) {
						return Rx.Observable.just( false );
					}
					return (
						this._currentUserService.changePasswordAsync( password )
							.map( () => true )
					);
				} )
		);
	}

	_remote_clearHistory( {contactType, contactId} ) {
		if ( !contactType ) {
			this._contactService.clearHistory();
			this._groupService.clearHistory();
			return;
		}
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
		}
		service.clearContactHistory( contactId );
		return Rx.Observable.just( true );
	}

	_remote_clearRemoteHistory( { contactId } ) {
		return (
			this._contactService.askParticipantsToCleanHistoryAsync( contactId )
				.map( () => true )
		);
	}

	_remote_setReadAll( {contactType, contactId} ) {
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
		}
		return (
			service.getDetailedContactAsync( contactId )
				.map( contact => {
					if ( contact ) {
						service.setReadAll( contact );
					}
					return {};
				} )
		);
	}

	_remote_queryHistory( { contactType, contactId } ) {
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
		}
		let messagesObservables = this._messagesObservables[ contactId ];
		if ( !messagesObservables ) {
			return Rx.Observable.just( false );
		}
		return (
			service.getDetailedContactAsync( contactId )
				.map( contact => {
					messagesObservables.forEach( messagesObservable => {
						service.requestMoreHistory( contactId, messagesObservable )
					} );
					return true;
				} )
		);
	}

	_remote_observeSharedContacts( ) {
		let currentContacts = Object.create( null );
		let sent = Object.create( null );
		let isSentAny = false;
		return (
			Rx.Observable.combineLatest(
				this._sharedcontactsService.observeContactList(),
				this._contactService.observeContactList(),
				( multidescriptions, contacts ) => ( { multidescriptions, contacts } )
			)
			.debounce( DEBOUNCE_INTERVAL )
			.map( ( { multidescriptions, contacts } ) =>
				_.map( multidescriptions, multidescription => ( {
					sharedList: this._compactContacts(
						currentContacts[ multidescription.id ] = currentContacts[ multidescription.id ] || Object.create( null ),
						_.map( _.filter(
							contacts,
							{ multidescriptionId: multidescription.id }
						), mapContactInList )
					),
					name: multidescription.name,
					id: multidescription.id,
					status: multidescription.status,
					multiinviteToken: multidescription.multiinviteToken
				} ) )

			)
			.tap( diffs => {
				_.forEach( diffs, ( { id } ) => { sent[ id ] = true; } );
				isSentAny = true;
			} )
		);
	}

	_remote_observeCommonData() {
		let currentShared = Object.create( null );
		let sentShared = Object.create( null );
		let currentContacts = Object.create( null );
		let currentGroups = Object.create( null );
		let prevIsAdmin = null;
		let prevIsPrivileged = null;
		let prevProfile = null;
		let prevProfileStr = null;
		return (
			Rx.Observable.combineLatest(
				this._sharedcontactsService.observeContactList().tap( () => { console.log( "Got shared contact list" ); } ),
				this._contactService.observeContactList().tap( () => { console.log( "Got contact list" ); } ),
				this._groupService.observeContactList().tap( () => { console.log( "Got group list" ); } ),
				this._adminService.observeIsAdmin().tap( () => { console.log( "Got isAdmin" ); } ),
				this._adminService.observeIsPrivileged().tap( () => { console.log( "Got isPrivileged" ); } ),
				this._currentUserService.observeExpireDt().tap( () => { console.log( "Got expireDt" ); } ),
				this._remote_observeProfile().tap( () => { console.log( "Got profile" ); } ),
				( multidescriptions, contacts, groups, isAdmin, isPrivileged, expireDt, profile ) => ( {
					m: _.map( multidescriptions, multidescription => ( {
						sharedList: this._compactContacts(
							currentShared[ multidescription.id ] = currentShared[ multidescription.id ] || Object.create( null ),
							_.map( _.filter(
								contacts,
								{ multidescriptionId: multidescription.id }
							), mapContactInList )
						),
						name: multidescription.name,
						id: multidescription.id
					} ) ),
					c: this._compactContacts(
						currentContacts,
						_.map( _.filter( contacts, { multidescriptionId: -1 } ), mapContactInList )
					),
					g: this._compactContacts(
						currentGroups,
						_.map( groups, mapContactInList )
					),
					isAdmin, isPrivileged, expireDt,
					p: ( profile === prevProfile || prevProfileStr === JSON.stringify( profile ) ) ? null : profile
				} )
			)
			.filter( ( { m, c, g, isAdmin, isPrivileged, p } ) => {
				if ( ( isAdmin !== prevIsAdmin ) || ( isPrivileged !== prevIsPrivileged ) ) {
					return true;
				}
				if ( !_.isEmpty( c ) || !_.isEmpty( g ) || _.some( m,
					( { id, sharedList } ) => !sentShared[ id ] || !_.isEmpty( sharedList )
				) ) {
					return true;
				}
				if ( p ) {
					return true;
				}
				return false;
			} )
			.tap( ( { isAdmin, isPrivileged, p } ) => {
				prevIsAdmin = isAdmin;
				prevIsPrivileged = isPrivileged;
				if ( p ) {
					prevProfile = p;
					prevProfileStr = JSON.stringify( p );
				}
			} )
		);
	}

	_remote_addHelperContact( { name } ) {
		return this._contactService.addHelperContactAsync( name );
	}

	_remote_addSharedContactList( {nickname, name, workgroupIds, contactIds, rights} ) {
		if ( !nickname || ( typeof nickname !== "string" ) ) {
			return Rx.Observable.throw( new Error( "String nickname required" ) );
		}
		if ( !name || ( typeof name !== "string" ) ) {
			return Rx.Observable.throw( new Error( "String name required" ) );
		}
		workgroupIds = _.toArray( workgroupIds );
		contactIds = _.toArray( contactIds );
		if ( !contactIds.length ) {
			return Rx.Observable.throw( new Error( "contactIds required" ) );
		}
		if ( !rights ) {
			return Rx.Observable.throw( new Error( "rights required" ) );
		}
		return (
			this._sharedcontactsService.createSharedGroupAsync(
				name, nickname, workgroupIds, contactIds, rights
			)
		);
	}

	_remote_observeSelfRights( { contactId } ) {
		return (
			this._sharedcontactsService.observeSelfRights( contactId )
		);
	}

	_remote_observeWorkgroupParticipants( {contactId} ) {
		return (
			this._sharedcontactsService.observeParticipants( contactId )
				.map( ps => _.mapValues( ps, ({nickname}) => ({nickname}) ) )
		);
	}

	_remote_observeWorkgroupInvites( {contactId, pid} ) {
		return (
			this._sharedcontactsService.observeInvites( contactId, pid )
				.map( ps => _.mapValues( ps, ({nickname}) => ({nickname}) ) )
		);
	}

	_remote_observeWorkgroupRights( {contactId} ) {
		return (
			this._sharedcontactsService.observeRights( contactId )
		);
	}

	_remote_removeWorkgroupParticipant( {contactId, pid} ) {
		return (
			this._sharedcontactsService.removeParticipantAsync( contactId, pid )
		);
	}

	_remote_removeWorkgroupInvite( {contactId, pid} ) {
		return (
			this._sharedcontactsService.removeWorkgroupInviteAsync( contactId, pid )
		);
	}

	_remote_addToWorkGroup( {multidescriptionId, contactId} ) {
		return (
			this._sharedcontactsService.addToWorkGroupAsync( multidescriptionId, contactId )
				.map( () => null )
		);
	}

	_remote_setWorkgroupRights( {multidescriptionId, modifiedRights} ) {
		modifiedRights = _.toArray( modifiedRights );
		return (
			this._sharedcontactsService.setRightsAsync( multidescriptionId, modifiedRights )
				.map( () => null )
		);
	}

	_remote_shareContacts( { contactIds, multidescriptionId } ) {
		contactIds = _.toArray( contactIds );
		return (
			this._sharedcontactsService.shareContactsAsync( contactIds, multidescriptionId )
		);
	}

	_remote_deleteAccount( ) {
		return this._currentUserService.dropAccountAsync();
	}

	_remote_observeUserTable( ) {
		return this._adminService.observeUserTable();
	}

	_remote_observeGlobalUserType( ) {
		let prevIsAdmin = null;
		let prevIsPrivileged = null;
		return Rx.Observable.combineLatest(
			this._adminService.observeIsAdmin(),
			this._adminService.observeIsPrivileged(),
			( isAdmin, isPrivileged ) => ( { isAdmin, isPrivileged } )
		).filter( ( { isAdmin, isPrivileged } ) => {
			if ( ( isAdmin === prevIsAdmin ) && ( isPrivileged === prevIsPrivileged ) ) {
				return false;
			}
			prevIsAdmin = isAdmin;
			prevIsPrivileged = isPrivileged;
			return true;
		} );
	}

	_remote_addUser( { user } ) {
		return (
			this._adminService.updateUserTableAsync( [ user ], [], [] )
				.map( () => user.activationSenderToken )
		);
	}

	_remote_editUser( { user } ) {
		return (
			this._adminService.updateUserTableAsync( [], [ user ], [] )
				.map( () => user )
		);
	}

	_remote_deleteUser( { userId } ) {
		return (
			this._adminService.updateUserTableAsync( [], [], [ userId ] )
		);
	}

	_remote_sendDeleteMessage( { messageIndex, contactId, contactType } ) {
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
			default:
				throw new Error( "Invalid contact type" );
		}
		return (
			service.ensureLoadedDetailsAsync( contactId )
				.flatMap( contact =>
					service.sendDeleteMessageAsync( contact, messageIndex )
				)
		);
	}

	_remote_sendEditTextMessage( { messageIndex, newText, contactId, contactType } ) {
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
			default:
				throw new Error( "Invalid contact type" );
		}
		return (
			service.ensureLoadedDetailsAsync( contactId )
				.flatMap( contact =>
					service.sendEditTextMessageAsync( contact, messageIndex, newText )
				)
		);
	}

	_remote_getPrivateServerConfig( ) {
		return Rx.Observable.just( configuration.getPrivateServerConfig() );
	}

	_remote_muteContactNotification( { contactId, contactType } ) {
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
			default:
				throw new Error( "Invalid contact type" );
		}
		let muteId = ++this._mutesCounter;
		this._mutes[ muteId ] = service.mute( contactId );
		return Rx.Observable.just( { muteId } );
	}

	_remote_unmuteContactNotification( { muteId } ) {
		if ( !this._mutes[ muteId ] ) {
			console.error( "Mute not found" );
			return Rx.Observable.just( {} );
		}

		this._mutes[ muteId ].dispose();
		delete this._mutes[ muteId ];
		return Rx.Observable.just( {} );
	}

    processMessage( data ) {
        let { callId, name, params } = data;
        if ( this._remoteMethods[ name ] ) {
			this._callInProgressCount++;
            return (
                this._remoteMethods[ name ].call( this, params, data )
                    .map( result => ( {
                        callId, result
                    } ) )
					.tap( _.noop, this._onCallDone, this._onCallDone )
            );
        }
        if ( this._remoteObservableMethods[ name ] ) {
			this._onObservableResult();
            return (
                this._remoteObservableMethods[ name ].call( this, params, data )
                    .map( item => ( {
                        name: "onNext",
                        callId,
                        item
                    } ) )
                    .catch( error => Rx.Observable.just( {
                        name: "onError",
                        callId,
                        message: error.message || error
                    } ) )
                    .concat( Rx.Observable.just( {
                        name: "onCompleted",
                        callId
                    } ) )
					.tap( this._onObservableResult, this._onObservableResult, () => {
						delete this.remoteObservables[ callId ];
					} )
            );
        }
		if ( this._subjs[ callId ] ) {
			this._subjs[ callId ].onNext( data.result );
			this._subjs[ callId ].onCompleted();
			return;
		}
    }

	_remote_disposeObservable( { callId } ) {
		if ( this.remoteObservables[ callId ] ) {
			this.remoteObservables[ callId ].dispose();
			delete this.remoteObservables[ callId ];
		}
		return Rx.Observable.just();
	}

	_remote_setRegistrationId( { registrationId } ) {
		this._contactService.setRegistrationId( registrationId );
		this._groupService.setRegistrationId( registrationId );
		this._currentUserService.setRegistrationId( registrationId );
		setRegistrationIdToSkip( registrationId );
		return Rx.Observable.just();
	}

	_remote_getDemoLeftTime() {
		//TODO: move to CurrentUserService
		return Rx.Observable.fromPromise(
			this._seedService.getModificationTimeThen().then(
				modificationTime => Math.max(0, configuration.getDemoTs() + modificationTime - (+new Date))
			)
		);
	}

	_remote_upgrade( { registrationData, inviteToken } ) {
		return this._currentUserService.upgradeAsync( registrationData, inviteToken );
	}

	_remote_ping( {} ) {
		return Rx.Observable.just();
	}

  _remote_observeOnlineStatus() {
      return this._diagnosticsService.observeOnlineStatus();
  }

  _remote_isRegistered() {
      return this._currentUserService.isRegisteredAsync();
  }

  _remote_registerAndLogin( { inviteData, registrationData, commonProfileData, inviteToken } ) {
      return this._currentUserService.registerAndLoginAsync(
          registrationData, commonProfileData, inviteToken
      ).tap( () => { this._afterLogin(); } );
  }

  _remote_dropAccount() {
      return this._currentUserService.dropAccountAsync();
  }

  _remote_logInAndConnect( { password } ) {
    return (
			this._currentUserService.logInAndConnectAsync( password )
				.tap( isSuccess => {
					if ( isSuccess ) {
						this._afterLogin();
					}
				} )
		);
  }

	_remote_observeExpireDt() {
		return this._currentUserService.observeExpireDt();
	}

  _remote_error( error ) {
		let message = error && error.message;
		if ( !message ) {
			console.error( "Error without details" );
			return Rx.Observable.just( false );
		}

      return this._logService.errorAsync( message );
  }

  _remote_writeRandomSeed( { password1, password2 } ) {
      return this._seedService.writeRandomSeedAsync( password1, password2 );
  }

	_remote_observeUnreadCountExcept( { contactId, contactType } ) {
		switch ( contactType ) {
			case "normal":
				return this._contactService.observeUnreadCountExcept( contactId );
			case "group":
				return this._groupService.observeUnreadCountExcept( contactId );
		}
		return Rx.Observable.combineLatest(
			this._contactService.observeUnreadCountExcept( contactId ),
			this._groupService.observeUnreadCountExcept( contactId ),
			( count1, count2 ) => count1 + count2
		);
	}

	_remote_observeTimeResync() {
		return this._timeService.monitorTimeResync();
	}

	_remote_isWebConnectionTokenValid( { token } ) {
		if ( !isValidToken( token ) ) {
			return Rx.Observable.just( false );
		}

		let { decodingTokens } = this._rpcDataSubj.getValue();
		if ( !decodingTokens[ token ] ) {
			decodingTokens[ token ] = receiveMessageThen( token, "" );
		}
		return Rx.Observable.fromPromise( decodingTokens[ token ].then(
			decoded => {
				if ( !decoded ) {
					return false;
				}
				let json = deserializeObject( new Buffer( decoded, "base64" ) );
				return json.type === "webconnection";
			},
			error => false
		) );
	}

	_remote_makeWebConnection( { token } ) {
		let rpcData = this._rpcDataSubj.getValue();
		let { decodingTokens } = rpcData;
		if ( !decodingTokens[ token ] ) {
			decodingTokens[ token ] = receiveMessageThen( token, "" );
		}
		let mb = null;
		return Rx.Observable.fromPromise( decodingTokens[ token ].then( str => {
			let buf = new Buffer( str, "base64" );
			mb = ssgCrypto.createManagedBuffer( buf.length );
			mb.useAsBuffer( b => { buf.copy( b ); } );

			return deserializeObjectWithKeysFromManagedBufferThen(
				mb,
				connectKeyMap,
				configuration.getDefaultEncryptionConfig()
			);
		} ).then( ( { apiUrlBase, connectionId, seedMacKey, seedEncryptionKey, macKey, econfig } ) => {
			mb.dispose();
			if ( rpcData.session ) {
				rpcData.session.forEach( s => { s.dipose(); } );
				delete rpcData.session;
			}
			econfig = new Config( econfig );
			let session = new RemoteControlServer(
				apiUrlBase,
				connectionId,
				seedMacKey,
				seedEncryptionKey,
				macKey,
				this._contactService,
				this._profileService,
				this._groupService,
				this._sharedcontactsService,
				this._adminService,
				this._currentUserService,
				this._timeService,
				this._businessCardService,
				econfig
			);
			this._initSession( session, apiUrlBase, econfig );
			rpcData.sessions = [ session ];
			this._rpcDataSubj.onNext( rpcData );
		} ) );
	}

	_initSession( session, apiUrlBase, econfig ) {
		let unpairTimeout = null;
		session.onAddSession( this._onAddSession.bind( this, apiUrlBase, econfig ) );
		session.onPaired( () => {
			if ( unpairTimeout ) {
				clearTimeout( unpairTimeout );
			}
		} );
		session.onUnpaired( ( { isForever } ) => {
			if ( isForever ) {
				this._removeSession( session );
				return;
			}
			if ( unpairTimeout ) {
				clearTimeout( unpairTimeout );
			}
			unpairTimeout = setTimeout( () => {
				unpairTimeout = null;
				this._removeSession( session );
			}, configuration.getWebDisconnectTimeout() );
		} );
	}

	_removeSession( session ) {
		let rpcData = this._rpcDataSubj.getValue();
		let index = rpcData.sessions.indexOf( session );
		if ( ~index ) {
			rpcData.sessions.splice( index, 1 );
			this._rpcDataSubj.onNext( rpcData );
		}
		session.dispose();
	}

	_onAddSession( apiUrlBase, econfig, connectionId, seedMacKey, seedEncryptionKey, macKey ) {
		let session = new RemoteControlServer(
			apiUrlBase,
			connectionId,
			seedMacKey,
			seedEncryptionKey,
			macKey,
			this._contactService,
			this._profileService,
			this._groupService,
			this._sharedcontactsService,
			this._adminService,
			this._currentUserService,
			this._timeService,
			this._businessCardService,
			econfig
		);
		this._initSession( session, apiUrlBase, econfig );
		let rpcData = this._rpcDataSubj.getValue();
		rpcData.sessions.push( session );
		this._rpcDataSubj.onNext( rpcData );
	}

	_remote_closeWebConnection() {
		let rpcData = this._rpcDataSubj.getValue();
		if ( !rpcData.sessions ) {
			return Rx.Observable.just( false );
		}
		rpcData.sessions.forEach( s => { s.dispose(); } );
		delete rpcData.sessions;
		this._rpcDataSubj.onNext( rpcData );
		return Rx.Observable.just( true );
	}

	_remote_observeWebConnectionSession() {
		return (
			this._rpcDataSubj.concatMap( ( { sessions } ) => sessions && sessions.length
				? Rx.Observable.fromArray( sessions.map( s => s.observeActivity() ) )
					.mergeAll().startWith( +new Date ).map( timestamp => ( {
						isConnected: true,
						timestamp,
						count: sessions.length,
					} ) )
				: Rx.Observable.just( {
					isConnected: false,
					timestamp: +new Date,
					count: 0,
				} )
			)
		);
	}

	_remote_uploadFile( { contactId, messageId, contentType, size, fileName } ) {
		let binarySource = new BinarySource( ( start, end, cb ) => {
			this._callClientAsync( "readUploadingFile", { contactId, messageId, start, end } )
				.subscribe( uint8a => {
					cb( new Buffer( uint8a ) )
				} );
		}, size, _.noop, _.noop );

		let sender;
		let onError = error => {
			sender.getProgress().onError( error );
		};

		let chunkSize = 16;
		let message = new MessageModel();
		let readSubj = new Rx.Subject();
		let total = size;
		let attachment = new Attachment(
			() => BinaryStream.createMonitoredStream(
				BinaryStream.fromBinarySource( binarySource ),
				buffer => { readSubj.onNext( buffer.length ); }
			),
			fileName,
			contentType
		);
		message.addAttachment( attachment );
		let fakeMessage = new MessageModel();
		sender = new MessageSender( {
			mainBinaryStream: new MessageSerializer( message, chunkSize ).createBinaryStream(),
			mainPassword: "",
			fakeBinaryStream: new MessageSerializer( fakeMessage, chunkSize ).createBinaryStream(),
			fakePassword: "fake",
			isSingleRead: false,
			expirationTS: 1000 * 60 * 60 * 24 * 365 + +new Date(),
			algorithm: "aes-256-cbc"
		} );
		return (
			readSubj
				.scan( ( sum, len ) => sum + len, 0 )
				.map( sent => {
					if ( sent >= total ) {
						readSubj.onCompleted();
					}
					return { sent, total };
				} )
				.concat(
					Rx.Observable.fromPromise( sender.completeThen() )
						.pluck( "addresses" )
						.map( addresses => _.find( addresses, "name", "token" ) )
						.pluck( "value" )
				)
		);
	}

	_remote_observeBackup() {
		return this._backupService.observeBackup().map( backupData => backupData
			? { token: backupData.token }
			: null
		);
	}

	_remote_cancelCreateBackup() {
		return Rx.Observable.just( this._backupService.cancelCreateBackup() );
	}

	_remote_dropBackup() {
		return this._backupService.dropBackupAsync();
	}

	_remote_createBackup( { fakePassword } ) {
		let progressSubj = new Rx.ReplaySubject( 1 );
		this._backupService
			.createBackupAsync( fakePassword, progressSubj )
			.subscribeOnError( ( err ) => { progressSubj.onError( err ); } );
		return progressSubj;
	}

	_remote_restoreBackup( { token } ) {
		let { messageId, apiUrlBase } = decodeMessageToken( token );
		let progressSubj = new Rx.ReplaySubject( 1 );
		this._backupService.restoreBackupAsync( token, progressSubj ).subscribe( () => {
			this._messageService.removeMessageByIdAsync( { id: messageId, apiUrlBase } )
				.subscribe( () => {
					progressSubj.onCompleted();
				} );
		} );
		return progressSubj;
	}

	_remote_rejoinContacts( { password1, password2 } ) {
		return this._backupService.rejoinContactsAsync( password1, password2 );
	}

	_remote_isPasswordValid( { password } ) {
		return this._currentUserService.tryGetMasterKey( password ).map( key => !!key );
	}

	_remote_setChatMessageProcessed( { contactId, contactType, messageIndex } ) {
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
		}
		if ( !service ) {
			return Rx.Observable.throw( new Error( "Invalid contact type" ) );
		}
		return (
			service.ensureLoadedDetailsAsync( contactId )
				.flatMap( contact => service.observeMessages( contact ) )
				.take( 1 )
				.flatMap( messages => {
					let message = messages[ messageIndex ];
					if ( !message ) {
						console.warn( "No message to set processed" );
						return Rx.Observable.just( false );
					}
					message.isProcessed = true;
					return service.updateMessageInHistoryAsync( message, contactId );
				} )
		);
	}

	_remote_isServerConfigured() {
		return this._adminService.isServerConfiguredAsync();
	}

	_remote_createUserSystemCreationToken() {
		return this._adminService.createCreationTokenAsync();
	}

	_remote_businessCardCreate( { name, description } ) {
		return Rx.Observable.fromPromise(
			this._businessCardService.createCardThen( name, description )
		);
	}

  _remote_businessCardDelete( { token } ) {
	  return Rx.Observable.fromPromise(
		  this._businessCardService.deleteCardThen( token )
	  );
  }

  _remote_businessCardUpdate( { token, name, description } ) {
		return Rx.Observable.fromPromise(
			this._businessCardService.updateCardThen( token, name, description )
		);
	}

	_remote_observeBusinessCards() {
		return this._businessCardService.observeCards();
	}

	_remote_setConfiguration( { config } ) {
		configuration.setConfiguration( config );
		return Rx.Observable.just();
	}

	fileDropDataAsync( fileName ) {
		return (
			this._callClientAsync( "fileDropData", { fileName } )
		);
	}

	fileReadAsBufferAsync( fileName ) {
		return (
			this._callClientAsync( "fileReadAsBuffer", { fileName } )
				.map( uinta => new Buffer( uinta ) )
		);
	}

	fileReplaceAsync( fileName, buffer ) {
		let startAt = performance.now();

		return (
			this._callClientAsync(
				"fileReplace",
				{ fileName, data: buffer.toString( "base64" ) }
			)
		);
	}

	fileReadAtPositionAsync( fileName, start, length ) {
		return (
			this._callClientAsync(
				"fileReadAtPosition",
				{ fileName, start, length }
			)
			.map( uinta => new Buffer( uinta ) )
		);
	}

	fileWriteAtPositionAsync( fileName, buffer, start ) {
		let startAt = performance.now();
		return (
			this._callClientAsync(
				"fileWriteAtPosition",
				{ fileName, start, data: buffer.toString( "base64" ) }
			)
				// .tap( () => {
				// 	console.log( `File ${fileName} wrote in ${( performance.now() - startAt)|0}ms` );
				// } )
		);
	}

	fileGetSizeAsync( fileName ) {
		return (
			this._callClientAsync(
				"fileGetSize",
				{ fileName }
			)
		);
	}

	fileRenameAsync( fileName, newFileName ) {
		return (
			this._callClientAsync(
				"fileRename",
				{ fileName, newFileName }
			)
		);
	}

	fileGetModificationTimeAsync( fileName ) {
		return (
			this._callClientAsync(
				"fileGetModificationTime",
				{ fileName }
			)
		);
	}

	fileIsExistsAsync( fileName ) {
		return (
			this._callClientAsync(
				"fileIsExists",
				{ fileName }
			)
		);
	}

	onNewMessageAsync() {
		return (
			this._callClientAsync(
				"onNewMessage",
				{}
			)
		);
	}

	_callClientAsync( name, params ) {
		this._callInProgressCount++;
    let callId = this._callIdCounter++;
		callId += "B";
    let subj = this._subjs[ callId ] = new Rx.ReplaySubject( 1, null, Rx.Scheduler.immediate );
    let msg = { name, params, callId };
		try {
    	postMessage( msg );
		} catch(e) {
			console.error('postMessage error', JSON.stringify(msg), e);
		}
		subj.subscribe( _.noop , this._onCallDone, this._onCallDone );
    return subj;
	}

	_onCallDone() {
		this._callInProgressCount--;
		this._prevObservableResultTS = performance.now();
		if ( this._callInProgressCount ) {
			return;
		}
		if ( this._idleTimeout ) {
			clearTimeout( this._idleTimeout );
		}
		this._idleTimeout = setTimeout( this._checkRunIdleQueue, IDLE_TIMEOUT );
	}

	_onObservableResult() {
		this._prevObservableResultTS = performance.now();
		if ( this._callInProgressCount ) {
			return;
		}
		if ( this._idleTimeout ) {
			clearTimeout( this._idleTimeout );
		}
		this._idleTimeout = setTimeout( this._checkRunIdleQueue, IDLE_TIMEOUT );
	}

	_checkRunIdleQueue() {
		delete this._idleTimeout;
		if ( this._callInProgressCount || !this._idleQueue.length ) {
			return;
		}
		if ( performance.now() - this._prevObservableResultTS < IDLE_TIMEOUT )  {
			this._idleTimeout = setTimeout( this._checkRunIdleQueue, IDLE_TIMEOUT );
			return;
		}

		let task = this._idleQueue.shift();
		let result;
		this._callInProgressCount++;
		try {
			result = task();
		} catch( error ) {
			result = error;
		}

		let startAt = performance.now();
		if ( !result || ( !result.then && !result.subscribe ) ) {
			return this._onCallDone();
		}
		if ( result.then ) {
			return result.then( this._onCallDone );
		}
		result.subscribe( _.noop, this._onCallDone, this._onCallDone );
	}

	addToIdleQueue( func ) {
		if ( typeof func !== "function" ) {
			throw new Error( "Function required" );
		}
		this._idleQueue.push( func );
		if ( !this._idleTimeout ) {
			this._checkRunIdleQueue();
		}
	}
}
export default Service;

if (!global.window) {
	//we run as a Worker
	let service = new Service();
	self.onmessage = event => {
	    let res;
		try {
			res = service.processMessage( event.data );
		} catch( e ) {
			res = Rx.Observable.throw( e );
		}
		if ( res ) {
	        service.remoteObservables[ event.data.callId ] = res.subscribe( data => {
	            postMessage( data );
	        }, error => {
				postMessage( {
					callId: event.data.callId,
					name: "onError",
					message: error.message
				} );
			} );
		}
	};
	if ( RemoteFileStorage.setService ) {
		RemoteFileStorage.setService( service );
	}
}
