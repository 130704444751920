import React from "react";
import Translation from "./translation.jsx";
import _ from "lodash";
import classNames from "classnames";
import Tappable from "react-tappable";

class Button extends React.Component {
	constructor() {
		super();
		this.state = {
			handled: false
		};
		this.onTap = this.onTap.bind( this );
	}

	static get propTypes() {
		return {
			caption: React.PropTypes.string,
			handleClick: React.PropTypes.func
		};
	}

	static get defaultProps() {
		return {
			enabled: true
		};
	}

	onTap( ev ) {
		if ( !this.props.enabled ) {
			return;
		}
		this.props.handleClick( ev );
	}

	getClassName() {
		return classNames( [ "btn", this.props.className, {
			"transparent": !this.props.enabled
		} ] );
	}

	render() {
		let { children, caption, enabled, handleClick, ...props } = this.props;
		return (
			<Tappable
				{ ...props }
				onTap={this.onTap}
				classBase="btn"
				component="div"
				className={ this.getClassName() }
			>
				{ caption && <Translation textId={ caption}/> }
				{ children }
			</Tappable>
		);
	}
}

export default Button;
