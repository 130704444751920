import React from "react";
import classNames from "classnames";
import _ from "lodash";

import WithFooterView from "../common/with.footer.view.jsx";
import Footer from "../common/footer.jsx";
import MenuBody from "../common/menu.body.jsx";
import Translation from "../../components/translation.jsx";
import translate from "../../translations/translate.js";
import SetNickname from "./nickname.jsx";
import SetPassword from "./password.jsx";
import SetLanguage from "./language.jsx";
import SetAutoclean from "./autoclean.jsx";
import history from "../../components/history.js";
import DeviceBackButton from "../../components/device.back.button.jsx";
import WebConnectionView from "./web.jsx";
import BackupView from "./backup.jsx";
import PinView from "./pin.jsx";
import {Alert, Confirm, InternetRetryConfirm} from "../../components/dialogs.jsx";
import OnlineStatus from "../../components/online.status.jsx";
import Checkbox from "../../components/checkbox.jsx";
import Upgrade from "../upgrade/index.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";
import configuration from "../../../common/configuration.js";

export function getCleanupFormatted( seconds ) {
	if ( !seconds ) {
		return <Translation textId="options.autoclean.notset" />;
	}

	if ( seconds < 3600 ) {
		let mins = seconds / 60 | 0;
		return (
			<span>
			{ mins }&nbsp;
			<Translation textId="options.autoclean.unit.min" params={[ mins ]} />
			</span>
		);
	}

	if ( seconds < 3600 * 24 ) {
		let hours = seconds / 3600 | 0;
		return (
			<span>
			{ hours }&nbsp;
			<Translation textId="options.autoclean.unit.hour" params={[ hours ]} />
			</span>
		);
	}

	if ( seconds < 365.25 * 3600 * 24 ) {
		let days = seconds / 3600 / 24 | 0;
		return (
			<span>
			{ days }&nbsp;
			<Translation textId="options.autoclean.unit.day" params={[ days ]} />
			</span>
		);
	}
	let years = seconds / 3600 / 24 / 365.25 | 0;
	return (
		<span>
		{ years }&nbsp;
		<Translation textId="options.autoclean.unit.year" params={[ years ]} />
		</span>
	);
}

export function getPinFormatted( seconds ) {
	if ( !seconds ) {
		return "0";
	}

	if ( seconds < 3600 ) {
		let mins = seconds / 60 | 0;
		return (
			<span>
			{ mins }&nbsp;
			<Translation textId="options.autoclean.unit.min" params={[ mins ]} />
			</span>
		);
	}

	if ( seconds < 3600 * 24 ) {
		let hours = seconds / 3600 | 0;
		return (
			<span>
			{ hours }&nbsp;
			<Translation textId="options.autoclean.unit.hour" params={[ hours ]} />
			</span>
		);
	}

	if ( seconds < 365.25 * 3600 * 24 ) {
		let days = seconds / 3600 / 24 | 0;
		return (
			<span>
			{ days }&nbsp;
			<Translation textId="options.autoclean.unit.day" params={[ days ]} />
			</span>
		);
	}
	let years = seconds / 3600 / 24 / 365.25 | 0;
	return (
		<span>
		{ years }&nbsp;
		<Translation textId="options.autoclean.unit.year" params={[ years ]} />
		</span>
	);
}

class OptionsView extends React.Component {
	constructor() {
		super();
		this.state = {
			languageTitle: "",
			profile: null
		};
		this.onSetNicknameSelect = this.onSetNicknameSelect.bind( this );
		this.onSetPasswordSelect = this.onSetPasswordSelect.bind( this );
		this.onSetLanguageSelect = this.onSetLanguageSelect.bind( this );
		this.onSetAutocleanSelect = this.onSetAutocleanSelect.bind( this );
		this.onDeleteSelect = this.onDeleteSelect.bind( this );
		this.onWebSelect = this.onWebSelect.bind( this );
		this.onBackupSelect = this.onBackupSelect.bind( this );
		this.onBackFromNickname = this.onBackFromNickname.bind( this );
		this.onBackFromPassword = this.onBackFromPassword.bind( this );
		this.onBackFromLanguage = this.onBackFromLanguage.bind( this );
		this.onBackFromAutoclean = this.onBackFromAutoclean.bind( this );
		this.onBackFromWeb = this.onBackFromWeb.bind( this );
		this.onBackFromBackup = this.onBackFromBackup.bind( this );
		this.onSetNickname = this.onSetNickname.bind( this );
		this.onSetPassword = this.onSetPassword.bind( this );
		this.onSetLanguage = this.onSetLanguage.bind( this );
		this.onSetAutoclean = this.onSetAutoclean.bind( this );
		this.onBackPress = this.onBackPress.bind( this );
		this.onDeleteConfirm = this.onDeleteConfirm.bind( this );
		this.onDeleteReset = this.onDeleteReset.bind( this );
		this.onSetPinSelect = this.onSetPinSelect.bind( this );
		this.onBackFromPin = this.onBackFromPin.bind( this );
		this.onSetPin = this.onSetPin.bind( this );
		this.toggleNotifications = this.toggleNotifications.bind( this );
		this.toggleKeyboard = this.toggleKeyboard.bind( this );
		this.toggleUpgrade = this.toggleUpgrade.bind( this );
		this.onBackFromUpgrade = this.onBackFromUpgrade.bind( this );
		this._service = serviceLocator();
	}

	componentWillMount() {
		this._profileSubscription = (
			this._service
				.observeProfile()
				.subscribe(
					profile => {
						this.setState( { profile } );
					},
					error => {
						console.error( "reload profile error", error );
					}
				)
		);
		this._languageSubscription = (
			translate.observeLanguageChanges()
				.subscribe( language => {
					this.setState( {
						languageTitle: language.getTitle()
					} );
				} )
		);
		if ( this.props.page === "upgrade" ) {
			this.setState( { upgradeVisible: true } );
		}
	}

	componentWillUnmount() {
		this._profileSubscription.dispose();
	}

	onSetNicknameSelect() {
		this.setState( { setNicknameVisible: true } );
	}
	onSetPasswordSelect() {
		this.setState( { setPasswordVisible: true } );
	}
	onSetLanguageSelect() {
		this.setState( { setLanguageVisible: true } );
	}
	onSetAutocleanSelect() {
		this.setState( { setAutocleanVisible: true } );
	}
	onSetPinSelect() {
		this.setState( { setPinVisible: true } );
	}
	onDeleteSelect() {
		this.setState( { isDeleteConfirming: true },
			() => setTimeout( () => { this.refs.cofirmDeleteAccount.show(); }, 0 )
		);
	}

	onDeleteConfirm() {
		this._service.dropAccountAsync()
			.subscribe( () => {
				this.setState( { isDeleteConfirming: false } );
			} );
	}

	onDeleteReset() {
		this.setState( { isDeleteConfirming: false } );
	}

	onWebSelect() {
		this.setState( { webConnectVisible: true } );
	}

	onBackupSelect() {
		this.setState( { backupVisible: true } );
	}

	onBackFromNickname() {
		this.setState( { setNicknameVisible: false } );
	}

	onBackFromPassword() {
		this.setState( { setPasswordVisible: false } );
	}

	onBackFromLanguage() {
		this.setState( { setLanguageVisible: false } );
	}

	onBackFromAutoclean() {
		this.setState( { setAutocleanVisible: false } );
	}

	onBackFromPin() {
		this.setState( { setPinVisible: false } );
	}

	onBackFromWeb() {
		this.setState( { webConnectVisible: false } );
	}

	onBackFromBackup() {
		this.setState( { backupVisible: false } );
	}

	onBackFromUpgrade() {
		this.setState( { upgradeVisible: false } );
	}

	onSetNickname() {
		this.updateProfile( this.state.newNickname );
		this.setState( { setNicknameVisible: false } );
	}

	onSetPassword( ) {
		this._service.changePasswordAsync( this.state.newPassword )
			.subscribe(
				() => this.setState( { setPasswordVisible: false } ),
				error => this.refs.noInternet.show()
			);
	}

	onSetLanguage( updateData ) {
		this.updateProfile( updateData );
		this.setState( { setLanguageVisible: false } );
	}

	onSetAutoclean( updateData ) {
		this.updateProfile( updateData );
		this.setState( { setAutocleanVisible: false } );
	}

	onSetPin( pinTime ) {
		this.updateProfile( { pinTime } );
	}

	updateProfile( updateData ) {
		if ( !updateData ) {
			return;
		}
		this._service
			.updateProfileAsync( updateData )
			.subscribe(
				result => {},
				error => {
					this.refs.noInternet.show();
					console.error( "update error", error )
				}
			);
	}

	toggleNotifications() {
		this.updateProfile( {
			receiveSound: !this.state.profile.receiveSound
		} );
	}

	toggleKeyboard() {
		this.updateProfile( {
			useVirtualKeyboard: !this.state.profile.useVirtualKeyboard
		} );
	}

	toggleUpgrade() {
		this.setState( {
			upgradeVisible: !this.state.upgradeVisible
		} );
	}

	getActiveLanguageFormatted() {
		return this.state.languageTitle;
	}

	getVersionMenuItem() {
		return {
			titleTextId: "options.version",
			value: process.env.VERSION,
			icon: "images/icon-info.svg",
			iconClass: "trash",
			onSelect: _.noop
		};
	}

	getMenuItems() {
		let { profile } = this.state;
		let items = [
			{ titleTextId: "options.nickname", value: profile.nickname, icon: "images/icon-menu-pen.svg", iconClass: "pen", onSelect: this.onSetNicknameSelect },
			{ titleTextId: "options.password", value: null, icon: "images/icon-menu-shield.svg", iconClass: "shield", onSelect: this.onSetPasswordSelect },
			{ titleTextId: "options.language", value: this.getActiveLanguageFormatted(), icon: "images/icon-menu-flag.svg", iconClass: "flag", onSelect: this.onSetLanguageSelect },
			{ titleTextId: "options.cleanuptime", value: getCleanupFormatted(profile.autoclean), icon: "images/icon-menu-timer.svg", iconClass: "timer", onSelect: this.onSetAutocleanSelect },
			{ titleTextId: "options.web", value: null, icon: "images/icon-menu-window.svg", iconClass: "trash", onSelect: this.onWebSelect },
			{ titleTextId: "options.backup", value: null, icon: "images/icon-menu-window.svg", iconClass: "trash", onSelect: this.onBackupSelect },
			{ titleTextId: "options.delete", value: null, icon: "images/icon-menu-trash.svg", iconClass: "trash", onSelect: this.onDeleteSelect },
			{ titleTextId: "options.pin", value: getPinFormatted(profile.pinTime), icon: "images/icon-menu-timer.svg", iconClass: "timer", onSelect: this.onSetPinSelect },
			{ titleTextId: "options.notifications", value: <Checkbox className="f-right " value={ !!profile.receiveSound } />, icon: "images/icon-menu-window.svg", iconClass: "timer", onSelect: this.toggleNotifications },
			{ titleTextId: "options.keyboard", value: <Checkbox className="f-right " value={ !!profile.useVirtualKeyboard } />, icon: "images/icon-menu-window.svg", iconClass: "timer", onSelect: this.toggleKeyboard },
		];
		let version = this.getVersionMenuItem();
		if ( version ) {
			items.push( version );
		}
		if (configuration.getDemoTs() && !this.state.profile.userId) {
			items.push( {
				titleTextId: "options.upgrade",
				value: null,
				icon: "images/icon-menu-window.svg",
				iconClass: "timer",
				onSelect: this.toggleUpgrade
			} );
		}
		items.forEach( item => { item.id = item.title; } );
		return items;
	}
	getCurrentFullscreenView() {
		if ( this.state.setNicknameVisible ) {
			return <span>
				<SetNickname
						{ ...this.state }
						nickname={ this.state.profile.nickname }
						onBack={ this.onBackFromNickname }
						onSetNickname={ (newNickname) => { this.state.newNickname = newNickname; this.onSetNickname() }}
					 >
					 <InternetRetryConfirm ref="noInternet" onConfirm={this.onSetNickname} />
			 	</SetNickname>
			</span>;
		}
		if ( this.state.setPasswordVisible ) {
			return <span>
				<SetPassword
					{ ...this.state }
					password=""
					onBack={ this.onBackFromPassword }
					onSetPassword={ (newPassword) => { this.state.newPassword = newPassword; this.refs.confirmPasswordChange.show()} }
				/>
				<InternetRetryConfirm ref="noInternet" onConfirm={this.onSetPassword} />
				<Confirm ref='confirmPasswordChange' textId="options.password.change.confirm" onConfirm={this.onSetPassword} />
				<Alert ref='dulplicatePassword' textId="options.password.duplicate.alert"/>
			</span>;
		}
		if ( this.state.setLanguageVisible ) {
			return <SetLanguage
						{ ...this.state }
						onBack={ this.onBackFromLanguage }
						onSetLanguage={ this.onSetLanguage }
					 />;
		}
		if ( this.state.setAutocleanVisible ) {
			return <SetAutoclean
						autocleanTime = { this.state.profile.autoclean }
						onBack={ this.onBackFromAutoclean }
						onSetAutoclean={ this.onSetAutoclean }
					 />;
		}
		if ( this.state.setPinVisible ) {
			return <PinView
				pinTime={ this.state.profile.pinTime }
				onBack={ this.onBackFromPin }
				onSelect={ this.onSetPin }
			/>;
		}
		if ( this.state.webConnectVisible ) {
			return <WebConnectionView onBack={ this.onBackFromWeb } />;
		}
		if ( this.state.backupVisible ) {
			return <BackupView onBack={ this.onBackFromBackup } />;
		}
		if ( this.state.upgradeVisible ) {
			return <Upgrade onBack={ this.onBackFromUpgrade }/>;
		}
		return null;
	}
	onBackPress() {
		history.navigateTo( "contacts" );
	}
	render() {
		let mainClassName = classNames( {
			red: this.state.isDeleteConfirming
		} );
		return (
			<WithFooterView className={mainClassName}>
				<header>
					<Translation textId="options.header" />
					<OnlineStatus/>
				</header>
				<main>
					{
						this.state.profile
						? <MenuBody items={ this.getMenuItems() } />
						: null
					}
				</main>
				<Footer active="options" />
				{ this.getCurrentFullscreenView() }
				<Confirm
					ref="cofirmDeleteAccount"
					textId="options.delete.confirm"
					onConfirm={this.onDeleteConfirm}
					onReset={this.onDeleteReset}
				/>
				<DeviceBackButton onPress={ this.onBackPress }/>
			</WithFooterView>
		);
	}
}

export default OptionsView;
