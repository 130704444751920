import _ from "lodash";
import Rx from "rx";
import ssgCrypto,{KEY_KINDS, Key, Config} from "ssg.crypto";

import Multicast, {getMessageConnectionIdBySharedId} from "../transport/multicast.js";
import Contact from "../models/contact.js";
import contactRepositoryLocator from "../repositories/locators/contact.js";
import {ChatMessageText,ChatMessageFile,ChatMessageImage,ChatMessageBase,ChatMessageAudio,MESSAGE_TYPE} from "../models/chat.message.js";
import Attachment from "../models/message/attachment.js";
import BinaryStream from "../models/binary.stream.js";
import MessageSerializer from "../models/message/message.serializer.js";
import MessageModel from "../models/message/message.js";
import MessageSender from "../models/message/message.sender.js";
import timeServiceLocator from "./locators/time.js";
import profileServiceLocator from "./locators/profile.js";
import configuration from "../../common/configuration.js";
import {makeThumbnailThen} from "../../common/image.js";
import PushHub from "../hubs/push.js";
import messageServiceLocator from "./locators/message.js";
import {decodeMessageToken} from "../models/message/address.codec.js";

let contactLoadQueue = [];
let isContactLoading = false;

class ContactsServiceCommon {
	constructor( contactType ) {
		this._fullyLoadedObservable = new Rx.ReplaySubject( 1 );
		this._registrationIdAsync = new Rx.ReplaySubject( 1 );
		this._contactRepository = contactRepositoryLocator();
		this._profileService = profileServiceLocator();
		this._isInitialized = false;
		this._detailsThen = null;
		this._contactType = contactType;
		this._onMessageRead = this._onMessageRead.bind( this );
		this._timeService = timeServiceLocator();
	}

	setIdleHandler( func ) {
		if ( typeof func !== "function" ) {
			throw new Error( "Function required" );

		}
		if ( this._idleHandler ) {
			throw new Error( "Duplicate idle handler" );
		}
		this._idleHandler = func;
	}

	initAsync( encryptionKey, positionBit /*0 or 1*/ ) {
		if ( !( encryptionKey instanceof Key )
			|| ( encryptionKey.kind !== KEY_KINDS.SYMMETRIC_ENCRYPTION ) ) {
			throw new Error( "Invalid Key kind" );
		}
		if ( ( positionBit !== 0 ) && ( positionBit !== 1 ) ) {
			throw new Error( "Invalid positionBit" );
		}
		if ( this._isInitialized ) {
			return Rx.Observable.just();
		}
		this._isInitialized = true;
		this._contacts = null;
		this.mutationObservable = new Rx.BehaviorSubject();
		this._subscriptions = [];
		this._positionBit = positionBit;
		this._detailsThen = {};
		this._contactId2MulticastThen = Object.create( null );
		this._messagesSubjectPerContact = Object.create( null );
		this._contactId2Participants = Object.create( null );
		this._readSetSubj = new Rx.BehaviorSubject( Object.create( null ) );
		this._sentReadIds = Object.create( null );
		this._sendingFiles = Object.create( null );
		this._contactsUpdating = Object.create( null );
		this._contactsNeedUpdate = Object.create( null );
		this._messageProcessingSubj = new Rx.Subject();
		this._messageProcessingSubj.concatAll().subscribe();

		this._makeSoundSubscription = (
			this._profileService.mutationObservable
				.filter( profile => !!profile )
				.concat( this._profileService.getProfileAsync() )
				.map( ( { receiveSound } ) => !!receiveSound )
				.distinctUntilChanged()
				.subscribe( () => {
					this._sendNotifyes();
				} )
		);
		this._muted = Object.create( null );
		return (
			this._contactRepository.initAsync( encryptionKey, positionBit )
				.flatMap( () => this._contactRepository.getListAsync() )
				.tap( list => {
					this._contacts = _.filter( list, candidate => candidate.type === this._contactType )
						.map( it => Contact.fromListItem( it ) )
				} )
				.tap( () => { this.mutationObservable.onNext(); } )
				.tap( () => { this._createAllMulticastsOneByOne(); } )
		);
	}

	uninitAsync( ) {
		if ( !this._isInitialized ) {
			return Rx.Observable.just();
		}
		this._isInitialized = false;
		this._subscriptions.forEach( subscription => { subscription.dispose(); } );
		this.mutationObservable.onCompleted();
		this._messageProcessingSubj.onCompleted();
		return (
			Rx.Observable.fromArray( _.values( this._contactId2MulticastThen ) )
				.flatMap( promise => Rx.Observable.fromPromise( promise ) )
				.flatMap( multicast => multicast.disposeAsync() )
				.toArray()
				.tap( () => {
					this._detailsAsync = null;
					this._fullyLoadedObservable = new Rx.ReplaySubject( 1 );
					this._contacts = null;
					this._contactId2MulticastThen = null;
					this._messagesSubjectPerContact = null;
					this._contactId2Participants = null;
					this._readSetSubj = null;
					this._sentReadIds = null;
					this._sendingFiles = null;
					this.mutationObservable = new Rx.BehaviorSubject();
					this._makeSoundSubscription && this._makeSoundSubscription.dispose();
					delete this._makeSoundSubscription;
				} )
		);
	}

	mute( contactId ) {
		let isDisposed = false;
		this._muted[ contactId ] = ( this._muted[ contactId ] || 0 ) + 1;
		return {
			dispose: () => {
				if ( isDisposed ) {
					return;
				}
				this._muted[ contactId ]--;
				isDisposed = true;
			}
		};
	}

	isMuted( contactId ) {
		return !!this._muted[ contactId ];
	}

	setRegistrationId( registrationId ) {
		this._registrationIdAsync.onNext( registrationId );
		this._registrationIdAsync.onCompleted();
	}

	getParticipantNameOrNull( contactId, pid ) {
		let contact = _.find( this._contacts, c => c.id === contactId );
		if ( !contact || !contact.pid ) {
			return null;
		}

		if ( contact.pid.toString( "base64" ) === pid ) {
			return "Me";//TODO: use translation
		}

		let ps = this._contactId2Participants[ contactId ];
		while( ps && ps[ pid ] && ps[ pid ].aliasTo && !ps[ pid ].nickname ) {
			pid = ps[ pid ].aliasTo;
		}

		if ( !ps || !ps[ pid ] ) {
			return null;
		}
		return ps[ pid ].nickname;
	}

	dropDataAsync( ) {
		return this._contactRepository.dropDataAsync().tap( () => {
			//This method can be run without initialization
			this.mutationObservable && this.mutationObservable.onNext();
		} );
	}

	isInitialized( ) {
		return this._isInitialized;
	}

	_checkIfAllContactsLoaded() {
		if ( !this._isFullyLoaded && !_.find( this._contacts,
			candidate => ( candidate.status !== "failed" ) && ( candidate.status !== "disconnected" )
				&& ( candidate.status !== "joining" ) && !this._contactId2MulticastThen[ candidate.id ]
		) ) {
			this._isFullyLoaded = true;
			this._fullyLoadedObservable.onNext( this._contacts );
			this._fullyLoadedObservable.onCompleted();
		}
	}

	_createAllMulticastsOneByOne( ) {
		let contact = _.findLast( this._contacts,
			candidate => ( candidate.status !== "failed" ) && ( candidate.status !== "disconnected" )
				&& ( candidate.status !== "joining" ) && !this._contactId2MulticastThen[ candidate.id ]
		);

		if ( !contact ) {
			this._checkIfAllContactsLoaded();
			return;
		}

		this._idleHandler( () => {
			this._getMulticastOrNullThen( contact )
				.then( () => {
					this._createAllMulticastsOneByOne();
				} )
		} );
	}

	_sendNotifyes( ) {
		//TODO: queue
		//Request will not be sent if there's no more notifications for a particular server
		if ( !this._contacts ) {
			return;
		}
		Rx.Observable.fromArray( _.filter(
			this._contacts,
			contact => contact.status !== "failed"
		) )
			.reduce(
				( acc, { apiUrlBase, sharedId } ) => {
					if ( !acc[ apiUrlBase ] ) {
						acc[ apiUrlBase ] = { connectionIds: [], apiUrlBase };
					}
					let connectionId = getMessageConnectionIdBySharedId( sharedId );
					acc[ apiUrlBase ].connectionIds.push( connectionId );
					return acc;
				}, {
					[ configuration.getApiBase() ]: {
						connectionIds: [],
						apiUrlBase: configuration.getApiBase()
					}
				}
			)
			.flatMap( apiUrlBase2Data => Rx.Observable.fromArray( _.values( apiUrlBase2Data ) ) )
			.flatMap( value => Rx.Observable.combineLatest(
				this._registrationIdAsync,
				this._profileService.getProfileAsync(),
				( registrationId, { receiveSound } ) => ( { value , registrationId, receiveSound } )
			) )
			.subscribe( ( { value, registrationId, receiveSound } ) => {
				let { connectionIds, apiUrlBase } = value;
				//TODO: hide bundleId
				// console.log( 'Sending registrationId to server', registrationId, connectionIds, receiveSound );
				new PushHub( apiUrlBase ).sendNotifyThen(
					registrationId,
					this._positionBit + this._contactType,
					receiveSound ? connectionIds : [],
					receiveSound
				);
			} );
	}

	_insertContactAsync( contact ) {
		let contacts = this._contacts;
		if ( !contacts ) {
			throw new Error( "Not initialized" );
		}
		return (
			this._contactRepository.insertAsync( contact )
				.tap( newId => {
					contacts.push( contact );
					setTimeout( () =>
						this.mutationObservable.onNext( newId ),
					0 );
				} )
		);
	}

	_addMulticastAsync( contact, multicastThen ) {
		return Rx.Observable.fromPromise(
			this._addMulticastThen( contact, multicastThen )
		);
	}

	_addMulticastThen( contact, multicastThen ) {
		if ( contact.id !== ( contact.id | 0 ) ) {
			throw new Error( `Invalid contact id ${contact.id}` );
		}

		if ( this._contactId2MulticastThen[ contact.id ] ) {
			throw new Error( "Internal error: duplicate multicast for contact" );
		}

		if ( contact.type !== this._contactType ) {
			throw new Error( "Invalid contact type" );
		}

		if ( contact.status === "failed" ) {
			throw new Error( "Creating multicast for failed contact" );
		}

		if ( multicastThen.subscribe ) {
			multicastThen = new Promise( ( resolve, reject ) => {
				multicastThen.subscribe( resolve, reject );
			} );
		}

		if ( !this._messagesSubjectPerContact[ contact.id ] ) {
			this._messagesSubjectPerContact[ contact.id ] = new Rx.BehaviorSubject( Object.create( null ) );
		}
		return this._contactId2MulticastThen[ contact.id ] = (
			multicastThen.then( multicast => {
				if ( multicast ) {
					multicast._contact = contact;
					this._onMulticastCreated( contact, multicast );
				} else {
					delete this._contactId2MulticastThen[ contact.id ];
				}
				this._checkIfAllContactsLoaded();
				return multicast;
			} )
		);
	}

	observeNewMessages() {
		if ( !this._newMessageSubj ) {
			this._newMessageSubj = new Rx.Subject();
		}
		return this._newMessageSubj;
	}

	_pushMessageAsync( contactId, message ) {
		let contact = this._findContactById( contactId );
		if ( !contact || ( contact.status === "failed" ) ) {
			//Ignore messages for removed or failed contacts
			return Rx.Observable.just();
		}
		let initialMaxIndex = this._maxIndexes && this._maxIndexes[ getMessageConnectionIdBySharedId( contact.sharedId ) ];
		let isNew = !( message.index <= initialMaxIndex );

		if ( !this._messagesSubjectPerContact[ contactId ] ) {
			this._messagesSubjectPerContact[ contactId ] = new Rx.BehaviorSubject( Object.create( null ) );
		}

		let messagesSubj = this._messagesSubjectPerContact[ contactId ];
		let valObject = messagesSubj.getValue();
		valObject[ message.index ] = message;
		messagesSubj.onNext( valObject );

		if ( contact.historyFromIndex > message.index ) {
			console.warn( "Skipping message as historyFromIndex > index" );
			return Rx.Observable.just();
		}

		if ( isNew && this._onNewMessage && !this._muted[ contactId ]
			&& ( message.type !== MESSAGE_TYPE.OUTGOING ) ) {
			this._onNewMessage();
		}
		if ( this._newMessageSubj && isNew
			&& message.type !== MESSAGE_TYPE.OUTGOING ) {
			this._newMessageSubj.onNext( {
				contactId,
				message: message.toJson(),
				sender: this.getParticipantNameOrNull( contactId, message.senderPid ) || contact.name
			} );
		}
		return this._updateContactIdexesAsync( contact, message );
	}

	_onMulticastCreated( contact, multicast ) {
		this._subscribeMulticastHandlers( multicast, contact );
	}

	_subscribeMulticastHandlers( multicast, contact ) {
		multicast.hookMetaFullStateChange( metaState =>
			this.updateAsync( contact.id, { metaState }, "lazy" )
		);

		this._subscriptions.push(
			multicast.observeErrors()
				.flatMap( error => this.setContactFailedAsync( contact.id, error ) )
				.subscribe()
		);

		this._subscriptions.push(
			multicast.observeExit()
				.subscribe( () => {
					this._deleteContactAsync( contact.id ).subscribe();
				} )
		);

		this._subscriptions.push(
			multicast.observeParticipantsLatest()
				.subscribe( ps => {
					this._sendNotifyes();
					this._contactId2Participants[ contact.id ] = ps;
				} )
		);

		this._subscriptions.push(
			multicast.observeParticipants()
				.map( ps => {
					let participantsPerGroup = _.reduce( ps, ( acc, {isExited}, pid ) => {
						let rpid = multicast.getRootAliasPid( pid );
						acc[ rpid ] = acc[ rpid ] || 0;
						if ( !isExited ) {
							acc[ rpid ] = acc[ rpid ] + 1;
						}
						return acc;
					}, Object.create( null ) );
					return participantsPerGroup;
				} )
				.concatMap( participantsPerGroup => {
					if ( ( ( contact.status === "invited" ) || ( contact.status === "joining" ) )
						&& ( _.filter( participantsPerGroup ).length >= 2 ) ) {
						return this.updateAsync( contact.id, { status: "active", token: null } );
					}
					if ( contact.status === "joining" ) {
						return this.updateAsync( contact.id, { status: "invited" } );
					}
					return Rx.Observable.empty();
				} )
				.subscribeOnError( error => {
					console.error( "error on _onMulticastCreated", error );
				} )
		);

		multicast.onAsyncMessage( ( asyncMsg, index, from, hashStr ) => {
			asyncMsg.subscribe( ( { keyUpdatesPerPid, ...msg } ) => {
				if ( !msg ) {
					console.warn( "Got invalid message", contact );
					return;
				}
				this._enqueueProcessMessage( msg, contact, multicast, index, from, hashStr, keyUpdatesPerPid );
			} );
		} );

		multicast.onNewParticipant( pData =>
			this._addContactParticipantAsync( contact, pData )
		);

		multicast.onP2pKeyDataUpdate( ( pid, keyData ) =>
			this._onP2pKeyDataUpdateThen( contact, pid, keyData )
		);
	}

	_enqueueProcessMessage( msg, contact, multicast, index, from, hashStr, keyUpdatesPerPid ) {
		// this._messageProcessingSubj.onNext( Rx.Observable.defer( () => {
			let timeout = setInterval( () => {
				console.warn( "Processing message too long", msg );
			}, 10000 );
			let res = this._processMessageJsonAndGetModel( msg, contact, multicast, index );
			if ( !res ) {
				res = Rx.Observable.just();
			}
			if ( !res.subscribe ) {
				res = Rx.Observable.just( res );
			}
			let model1;
			res.flatMap( model => {
				model1 = model;
				if ( !model ) {
					return Rx.Observable.just();
				}
				if ( !( model instanceof ChatMessageBase ) ) {
					model = new ChatMessageBase(
						multicast.isOutgoingParticipant( msg.from )
							? MESSAGE_TYPE.OUTGOING
							: MESSAGE_TYPE.INCOMING,
							msg.json.timestamp || +new Date,
							msg.from,
							index,
							msg.p2pIndex
					);
				}
				if ( !model.ttlBaseTimestamp && model.type !== MESSAGE_TYPE.INCOMING ) {
					model.ttlBaseTimestamp = model.timestamp;
				}
				return this._pushMessageAsync( contact.id, model );
			} )
			.flatMap( () => {
				//Update contact
				return Rx.Observable.fromPromise(
					this._updateMultipleParticipantKeysThen(
						contact, keyUpdatesPerPid, index + 1
					)
				);
			} ).subscribe(
				() => {},
				error => {
					console.warn( "Processing message error", error, error.stack, JSON.stringify( msg ) );
					clearInterval( timeout );
					if ( hashStr ) {
						multicast.setMessageProcessed( hashStr );
					}
				},
				() => {
					clearInterval( timeout );
					if ( hashStr ) {
						multicast.setMessageProcessed( hashStr );
					}
				}
			);
//		} ) );
	}

	_processMessageJsonAndGetModel( msg, fromContact, multicast, index ) {
		let { json } = msg;
		switch( json.type ) {
			case "text":
				return (
					multicast.isOutgoingParticipant( msg.from )
						? new ChatMessageText(
							MESSAGE_TYPE.OUTGOING,
							json.text,
							json.timestamp,
							msg.from,
							index,
							msg.p2pIndex,
							json.id,
							undefined,//edit index
							json.replyTo
						)
						: new ChatMessageText(
							MESSAGE_TYPE.INCOMING,
							json.text,
							json.timestamp,
							msg.from,
							index,
							msg.p2pIndex,
							json.id,
							undefined,//edit index
							json.replyTo
						)
				);
			case "file":
				if ( json.thumbnailBase64 ) {
					return (
						new ChatMessageImage(
							multicast.isOutgoingParticipant( msg.from )
								? MESSAGE_TYPE.OUTGOING
								: MESSAGE_TYPE.INCOMING,
							json.thumbnailBase64,
							json.fileToken,
							json.name,
							json.contentType,
							json.timestamp,
							msg.from,
							index,
							msg.p2pIndex,
							json.id
						)
					);
				}

				if ( json.milliseconds ) {
					return (
						new ChatMessageAudio(
							multicast.isOutgoingParticipant( msg.from )
								? MESSAGE_TYPE.OUTGOING
								: MESSAGE_TYPE.INCOMING,
							json.milliseconds,
							json.fileToken,
							json.name,
							json.contentType,
							json.timestamp,
							msg.from,
							index,
							msg.p2pIndex,
							json.id
						)
					);
				}
				return (
					new ChatMessageFile(
						multicast.isOutgoingParticipant( msg.from )
							? MESSAGE_TYPE.OUTGOING
							: MESSAGE_TYPE.INCOMING,
						json.fileToken,
						json.name,
						json.contentType,
						json.timestamp,
						msg.from,
						index,
						msg.p2pIndex,
						json.id
					)
				);
			default:
				console.log( "Unknown message", json );
				return null;
		}
	}

	setReadAll( contact ) {
		//TODO: queue
		if ( contact.unreadCount === 0 ) {
			return;
		}

		if ( !this._findContactById( contact.id ) ) {
			return; //deleted
		}

		this.updateAsync(
			contact.id,
			{ unreadCount: 0 },
			true //isLazy
		).subscribe();
	}

	_updateContactIdexesAsync( contact, message ) {
		if ( contact.status === "failed" ) {
			return Rx.Observable.just();
		}
		if ( !this._findContactById( contact.id ) ) {
			return Rx.Observable.just();
		}

		if ( !message ) {
			return Rx.Observable.just();
		}
		let { index } = message;
		//, p2pIndex
		let from = message.from || message.senderPid; //TODO: make one way

		// if ( typeof p2pIndex !== "number" ) {
		// 	debugger;
		// 	console.error( "trying to set read message without p2pIndex" );
		// 	return;
		// }

		if ( contact.historyFromIndex > message.index ) {
			return Rx.Observable.just();
		}

		if ( index !== ( index | 0 ) ) {
			debugger;
			console.warn( "trying to set read message without index" );
			return Rx.Observable.just();
		}

		if ( !from ) {
			debugger;
			console.error( "SenderPid from field" );
			return Rx.Observable.just();
		}
		let updateJSON = {
			mainIndexInaccurate: index + 1
		};
		if ( message.type !== MESSAGE_TYPE.OUTGOING ) {
			updateJSON.unreadCount = contact.unreadCount + 1;
		}
		return (
			this.local2ServerTimeAsync( +new Date )
				.flatMap( serverNow => {
					updateJSON.lastMessageTS = serverNow;
					return this.updateAsync(
						contact.id,
						updateJSON,
						true //isLazy
					);
				} )
		);
	}

	_onP2pKeyDataUpdateThen( contact, pid, keyData ) {
		return this._updateParticipantKeysThen( contact, { pid, ...keyData } );
	}

	_updateParticipantKeysThen( contact, keyUpdates ) {
		let { pid, ...ku } = keyUpdates;
		return this._updateMultipleParticipantKeysThen( contact, { [ pid ]: ku } );
	}

	_updateMultipleParticipantKeysThen( contact, keyUpdatesPerPid, newMainIndex ) {
		if ( newMainIndex ) {
			contact.mainIndex = newMainIndex;
		}
		return (
			Promise.all( _.map( keyUpdatesPerPid, ( keyData, pid ) =>
				this._getParticipantKeyBuffersUpdateThen( keyData, pid )
			) ).then( updateData => {
				updateData.forEach( ud => {
					contact.updateParticipantData( ud );
				} );
				return new Promise( ( resolve, reject ) => {
					this.storeContactAsync( contact ).subscribe( resolve, reject );
				} );
			} ).then( () => {
				_.forEach( keyUpdatesPerPid, keyData => {
					for ( let name in keyData ) {
						if ( keyData[ name + "Handle" ] ) {
							 keyData[ name ].removeKeyUse( keyData[ name + "Handle" ] );
							 delete keyData[ name + "Handle" ];
						}
					}
				} );
			} )
		);
	}

	_getParticipantKeyBuffersUpdateThen( keyData, pid ) {
		let keysThen = [];
		let { nextIndex, sChainIndex, rChainIndex } = keyData;
		let updateData = { pid };
		if ( nextIndex ) {
			updateData.nextIndex = nextIndex;
		}
		if ( sChainIndex !== undefined ) {
			updateData.sChainIndex = sChainIndex;
		}
		if ( rChainIndex !== undefined ) {
			updateData.rChainIndex = rChainIndex;
		}
		for( let keyName in keyData ) {
			if ( keyName.substr( -3 ) === "Key" ) {
				let bufferName = keyName.substr( 0, keyName.length - 3 ) + "Buffer";
				let key = keyData[ keyName ];
				if ( !key ) {
					updateData[ bufferName ] = new Buffer( 32 ).fill( 0 );
					continue;
				}
				keysThen.push( new Promise( resolve => {
					key.postponeManagedBuffer( mb => {
						mb.useAsBuffer( b => {
							updateData[ bufferName ] = Buffer.concat( [ b ] );
							resolve();
						} );
					} );
				} ) );
			}
		}
		return Promise.all( keysThen ).then( () => updateData );
		// return (
		// 	Promise.all( keysThen )
		// 		.then( () => new Promise( ( resolve, reject ) => {
		// 			contact.waitForParticipantAsync( keyData.pid )
		// 				.subscribe( resolve, reject )
		// 		} ) )
		// 		.then( () => {
		// 			contact.updateParticipantData( updateData );
		// 			return new Promise( ( resolve, reject ) => {
		// 				this.storeContactAsync( contact ).subscribe( resolve, reject );
		// 			} );
		// 		} )
		// );
	}

	_addContactParticipantAsync( contact, pData ) {
		if ( contact.hasParticipant( pData.pid ) ) {
			return Rx.Observable.just();
		}
		contact.addParticipantData( pData.pid );
		return Rx.Observable.fromPromise(
			this._updateParticipantKeysThen( contact, pData )
		).flatMap( () =>
			this.storeContactAsync( contact )
		);
	}

	_updateParticipantKeyAsync( contact, pid, key, propName ) {
		return (
			contact.waitForParticipantAsync( pid )
				.flatMap( () =>
					Rx.Observable.create( observer => {
						key.postponeManagedBuffer( mb => {
							mb.useAsBuffer( buffer => {
								contact.updateParticipantData( {
									pid,
									[ propName ]: buffer
								} )
							} );
							observer.onNext();
							observer.onCompleted();
						} );
					} )
				)
		);
	}

	_removeContactParticipantAsync( contact, pid ) {
		contact.removeParticipantData( pid );
		return this.storeContactAsync( contact );
	}

	updateAsync( id, changesJSON, isLazy ) {
		let contact = this._findContactById( id );
		if ( !contact ) {
			console.warn( "Trying to update non-existing contact" );
			return Rx.Observable.just();
		}
		if ( isLazy ) {
			return (
				this.ensureLoadedDetailsAsync( contact )
					.tap( () => {
						contact.update( changesJSON );
						if ( contact.historyFromIndex > contact.mainIndex ) {
							console.warn( "Contact update historyFromIndex > mainIndex",
								contact.historyFromIndex,
								contact.mainIndex
							);
						}
						this.storeContactLazily( contact );
					} )
			);
		}
		return (
			this.ensureLoadedDetailsAsync( contact )
				.flatMap( () => {
					contact.update( changesJSON );
					if ( contact.historyFromIndex > contact.mainIndex ) {
						console.warn( "Contact update historyFromIndex > mainIndex",
							contact.historyFromIndex,
							contact.mainIndex
						);
					}
					return this.storeContactAsync( contact );
				} )
		);
	}

	deleteContactAsync( id ) {
		return this._deleteContactAsync( id );
	}

	_deleteContactAsync( id ) {
		let index = _.findIndex( this._contacts, c => c.id === id );
		if ( !~index ) {
			console.warn( "trying to remove non existing contact" );
			return Rx.Observable.just( null );
		}

		this._contacts.splice( index, 1 );
		delete this._detailsThen[ id ];
		return (
			this._contactRepository.deleteAsync( id )
				.tap( () => {
					if ( this._contactId2MulticastThen[ id ] ) {
						this._contactId2MulticastThen[ id ].then( multicast => {
							multicast && multicast.dispose();
						} );
					}
					//TODO: remove keys
					delete this._contactId2MulticastThen[ id ];
					delete this._messagesSubjectPerContact[ id ];
					delete this._contactId2Participants[ id ];

					this.mutationObservable.onNext( id );
					this._sendNotifyes();
				} )
		);
	}

	onNewMessage( func ) {
		this._onNewMessage = func;
	}

	observeContactList( ) {
		return this.mutationObservable.map( () => this._contacts ).filter( cl => !!cl );
	}

	getContactsAsync( ) {
		return Rx.Observable.just( this._contacts );
	}

	authenticateAsync( contact ) {
		return this.updateAsync( id, { isAuthenticated: true } );
	}

	isExistAsync( ) {
		return this._contactRepository.isAnyDataAsync();
	}

	ensureLoadedDetailsAsync( contact, isPriority ) {
		return Rx.Observable.fromPromise( this.ensureLoadedDetailsThen( contact, isPriority ) );
	}

	//TODO: rewrite
	ensureLoadedDetailsThen( contact, isPriority ) {
		if ( typeof contact === "number" ) {
			contact = this._findContactById( contact );
		}

		if ( !contact ) {
			return Promise.resolve( null );
		}

		if ( !this._findContactById( contact.id ) ) {
			throw new Error( "Contact deleted" );
		}

		if ( !contact.id ) {
			throw new Error( "Contact id not set" );
		}

		if ( !this._isInitialized ) {
			//TODO: wait for proper shutdown before uninit
			return;
		}

		if ( this._detailsThen && !this._detailsThen[ contact.id ] ) {
			this._detailsThen[ contact.id ] = (
				contact.hasDetails()
				? Promise.resolve( contact )
				: this._enqueueContactLoad(
					contact.id,
					() => this._loadDetailsThen( contact ),
					isPriority
				)
			);
		} else if ( this._detailsThen && isPriority ) {
			this._reorderPriorityContactLoading( contact.id );
		}
		return this._detailsThen && this._detailsThen[ contact.id ];
	}

	_loadDetailsThen( contact ) {
		return (
			this._contactRepository.getDetailsThen( contact.id, contact.type )
				.then( details => {
					if ( !details || ( details.id !== contact.id ) ) {
						debugger;
						contact.status = "failed";
						if ( !details ) {
							details = {
								id: contact.id,
								failReason: "details null"
							};
						} else {
							details.failReason = `details.id !== contact.id: ${details.id} !== ${contact.id}`;
							details.id = contact.id;
						}
						return (
							this.storeContactThen( contact )
								.then( () => contact )
						);
					}
					//TODO: this is called multiple times
					if ( !contact.hasDetails() ) {
						contact.setDetails( details );
						this.mutationObservable.onNext( contact.id );
					}
					return contact;
				} )
		);
	}

	_enqueueContactLoad( contactId, loadFunc, isPriority ) {
		if ( typeof contactId !== "number" ) {
			throw new Error( "contactId required" );
		}
		return new Promise( ( resolve, reject ) => {
			let replaceLoadFunc = () => loadFunc().then(
				res => { resolve( res ); return res; },
				reject
			);
			if ( isPriority ) {
				contactLoadQueue.unshift( { contactId, loadFunc: replaceLoadFunc } );
			} else {
				contactLoadQueue.push( { contactId, loadFunc: replaceLoadFunc } );
			}
			this._continueLoadingContact();
		} );
	}

	_continueLoadingContact() {
		if ( isContactLoading || !contactLoadQueue.length ) {
			return;
		}
		isContactLoading = true;
		let resThen;
		try {
			resThen = contactLoadQueue.splice( 0, 1 )[ 0 ].loadFunc();
		} catch( e ) {}
		if ( !resThen || !resThen.then ) {
			resThen = Promise.resolve();
		}
		resThen.then( () => {
			isContactLoading = false;
			this._continueLoadingContact();
		} );
	}

	_reorderPriorityContactLoading( contactId ) {
		let foundInQueueIndex = _.findIndex( contactLoadQueue, { contactId } );
		if ( !~foundInQueueIndex ) {
			return;
		}
		let item = contactLoadQueue.splice( foundInQueueIndex, 1 )[ 0 ];
		contactLoadQueue.splice( 0, 0, item );
	}

	observeMessages( contact ) {
		if ( !this._messagesSubjectPerContact[ contact.id ] ) {
			this._messagesSubjectPerContact[ contact.id ] =
				new Rx.BehaviorSubject( Object.create( null ) );
		}
		return this._messagesSubjectPerContact[ contact.id ];
	}

	server2LocalTimeAsync( ts ) {
		return this._timeService.server2LocalTimeAsync( ts );
	}

	local2ServerTimeAsync( ts ) {
		return this._timeService.local2ServerTimeAsync( ts );
	}

	createMessageFileWithProgressAsync( file ) {
		let sender;
		let onError = error => {
			sender.getProgress().onError( error );
			// sender.getCompletition().onError( error );
		};
		let chunkSize = 16;
		let message = new MessageModel();
		let readSubj = new Rx.Subject();
		let total = file.size;
		let attachment = new Attachment(
			() => BinaryStream.createMonitoredStream(
				BinaryStream.fromFile( file, onError ),
				buffer => { readSubj.onNext( buffer.length ); }
			),
			file.name,
			file.type
		);
		message.addAttachment( attachment );
		let fakeMessage = new MessageModel();
		sender = new MessageSender( {
			mainBinaryStream: new MessageSerializer( message, chunkSize ).createBinaryStream(),
			mainPassword: "",
			fakeBinaryStream: new MessageSerializer( fakeMessage, chunkSize ).createBinaryStream(),
			fakePassword: "fake",
			isSingleRead: false,
			expirationTS: 1000 * 60 * 60 * 24 * 365 + +new Date(),
			algorithm: "aes-256-cbc"
		} );
		return {
			progress: readSubj.scan( ( sum, len ) => sum + len, 0 ).map( sent => ( { sent, total } ) ),
			completition: Rx.Observable.fromPromise( sender.completeThen() )
				.pluck( "addresses" )
				.map( addresses => _.find( addresses, "name", "token" ) )
				.pluck( "value" )
		};
	}

	observeSendingFiles( contact ) {
		if ( !this._sendingFiles[ contact.id ] ) {
			this._sendingFiles[ contact.id ] = new Rx.BehaviorSubject([]);
		}
		return this._sendingFiles[ contact.id ];
	}

	sendFileAsync( contact, file, id ) {
		if ( file.type.split( "/" )[ 0 ] === "image" ) {
			return (
				Rx.Observable.fromPromise(
					makeThumbnailThen( file, 320, 320 )
						.catch( () => undefined )
				)
					.flatMap( thumbnailBase64 =>
						this._sendFileAsync( contact, file, id, thumbnailBase64 )
					)
			);
		}

		return this._sendFileAsync( contact, file, id );
	}

	_sendFileAsync( contact, file, id, thumbnailBase64 ) {
		let info = {
			name: file.name,
			contentType: file.type,
			size: file.size,
			sent: 0,
			total: file.size,
			id,
			thumbnailBase64
		};

		return (
			( id
				? Rx.Observable.just( id )
				: Rx.Observable.fromPromise(
					ssgCrypto.createRandomBase64StringThen( configuration.getIdsLength() )
				)
			)
			.tap( id => {
				if ( !this._sendingFiles[ contact.id ] ) {
					this._sendingFiles[ contact.id ] = new Rx.BehaviorSubject( [] );
				}
				let files = this._sendingFiles[ contact.id ].getValue();
				info.id = id;
				files.push( info );
				this._sendingFiles[ contact.id ].onNext( files );
			} )
			.flatMap( id => {
				let { progress, completition } = this.createMessageFileWithProgressAsync( file );
				progress.subscribe( ( { sent, total } ) => {
					info.sent = sent;
					info.total = total;
					this._sendingFiles[ contact.id ].onNext( this._sendingFiles[ contact.id ].getValue() );
				}, error => {
					info.error = error;
					this._sendingFiles[ contact.id ].onNext( this._sendingFiles[ contact.id ].getValue() );
				} );
				return (
					completition
						.flatMap( fileToken =>
							this._sendMessageAsync( contact, {
								type: "file",
								contentType: file.type,
								size: file.size,
								name: file.name,
								fileToken,
								thumbnailBase64,
								id
							} )
						)
						.tapOnCompleted( () => {
							let files = this._sendingFiles[ contact.id ].getValue();
							files.splice( files.indexOf( info ), 1 );
							this._sendingFiles[ contact.id ].onNext( files );
						} )
				);
			} )
		);
	}

	sendMessageAsync( contact, text, id ) {
		return this._sendMessageAsync( contact, {
				type: "text",
				text,
				id
			} );
	}

	_sendMessageAsync( contact, message ) {
		return Rx.Observable.fromPromise(
			this._sendMessageThen( contact, message )
		);
	}

	_sendMessageThen( contact, message ) {
		if ( contact.status === "failed" ) {
			console.warn( "Trying to send message to a failed contact" );
			return Promise.resolve();
		}
		return (
			Promise.all( [
				this._timeService.local2ServerTimeThen( +new Date ),
				this._getMulticastOrNullThen( contact, true )
			] )
				.then( ( [ timestamp, multicast ] ) => {
					if ( !multicast ) {
						return null;
					}
					let json = { ...message, timestamp };
					return multicast.sendMessageThen( json );
				} )
		);
	}

	_getMulticastOrNullAsync( contact, isPriority ) {
		return Rx.Observable.fromPromise(
			this._getMulticastOrNullThen( contact, isPriority )
		);
	}

	_getMulticastOrNullThen( contact, isPriority ) {
		if ( typeof contact === "number" ) {
			contact = this._findContactById( contact );
		}
		if ( !contact ) {
			return Promise.resolve( null );
		}

		if ( contact.type !== this._contactType ) {
			throw new Error( "Invalid contact type" );
		}

		if ( !this._findContactById( contact.id ) ) {
			return Promise.resolve( null );
		}

		if ( this._contactId2MulticastThen[ contact.id ] ) {
			return this._contactId2MulticastThen[ contact.id ];
		}

		if ( contact.status === "joining" ) {
			return new Promise( ( resolve, reject ) => {
				let check = () => {
					if ( this._contactId2MulticastThen[ contact.id ] ) {
						this._contactId2MulticastThen[ contact.id ].then( resolve, reject );
						return;
					}
					setTimeout( check, 1000 );
				};
				check();
			} );
		}

		return this._addMulticastThen( contact,
			this.ensureLoadedDetailsThen( contact, isPriority )
				.then( () => this._getPerParticipantDataThen( contact ) )
				.then( perParticipant => new Promise( ( resolve, reject ) => {
					Multicast.fromJSONAsync( {
						privateKey: contact.signKey,
						pid: contact.pid,
						creatorPublicKey: contact.creatorPublicKey,
						creatorPid: contact.creatorPid,
						seedKey: contact.seedKey,
						dhPrivKey: contact.dhPrivKey,
						sharedId: contact.sharedId,
						apiUrlBase: contact.apiUrlBase,
						messageIndex: contact.mainIndex,
						econfig: contact.econfig,
						perParticipant,
						metaState: contact.metaState
					},
					this._getP2pPrivateHandlers(),
					this._isUseMetaWithRights(),
					this._isUseKeychain() ).subscribe( resolve, reject );
				} ) )
				.catch( error => {
					console.error( error );
					return null;
				} )
		);
	}

	_getPerParticipantDataThen( contact ) {
		let econfig = (
			contact.econfig instanceof Config
				? contact.econfig
				: ssgCrypto.parseConfig( contact.econfig )
		);
		return Promise.all(
			contact.getParticipants().map( ( p, index ) =>
				Promise.all( [
					contact.createParticipantRootKeyThen( p.pid ),
					contact.createParticipantSChainKeyThen( p.pid ),
					contact.createParticipantRChainKeyThen( p.pid ),
					contact.createParticipantPubKeyThen( p.pid ),
					contact.createParticipantPrivKeyThen( p.pid )
				] ).then( ( [ rootKey, sChainKey, rChainKey, dhPubKey, dhPrivKey ] ) =>
					( { ...p, rootKey, sChainKey, rChainKey, dhPubKey, dhPrivKey } )
				)
			)
		);
	}

	clearHistory( ) {
		for ( let contactId in this._messagesSubjectPerContact ) {
			this._messagesSubjectPerContact[ contactId ].onNext( Object.create( null ) );
			let found = this._findContactById( contactId );
			this.setReadAll( found );
		}
	}

	clearContactHistory( contactId ) {
		if ( this._messagesSubjectPerContact[ contactId ] ) {
			this._messagesSubjectPerContact[ contactId ].onNext( Object.create( null ) );
		}
		let found = this._findContactById( contactId );
		this.setReadAll( found );
	}

	decodeLatestPrivateDataAsync( contact, encryptionKey ) {
		return (
			this.monitorPrivateData( contact, encryptionKey )
				.first( { defaultValue: null } )
		);
	}

	observePrivateData( contact ) {
		return (
			this._getMulticastOrNullAsync( contact )
				.filter( m => !!m )
				.flatMap( multicast =>
					multicast.observePrivateDataLatest()
				)
		);
	}

	monitorPrivateData( contact, encryptionKey ) {
		if ( contact.status === "failed" ) {
			console.warn( "Trying to monitor private data for failed contact" );
			return Rx.Observable.just();
		}

		let econfig = (
			contact.econfig instanceof Config
				? contact.econfig
				: ssgCrypto.parseConfig( contact.econfig )
			);

		return (
			this._getMulticastOrNullAsync( contact )
				.filter( m => !!m )
				.flatMap( multicast =>
					multicast.observePrivateDataLatest()
						.flatMap( encryptedPrivateData =>
							Rx.Observable.fromArray(
								_.map( encryptedPrivateData,
									( privateData, pid ) => {
										/*if ( !_.find( contact.getParticipants(), {pid} ) ) {
											//Participant removed
											//!!Or it is invited but invite is not accepted
											return Rx.Observable.just( null );
										}*/
										return (
											Buffer.isBuffer( privateData )
												? Rx.Observable.fromPromise(
													ssgCrypto.decryptToNewBufferThen(
														privateData,
														encryptionKey,
														false,
														econfig
													)
													.then( privateData => ( { privateData, pid } ) )
												)
												: Rx.Observable.empty()
										);
									}
								)
							)
							.mergeAll()
							.toArray()
							.map( decryptedPrivateDataArray =>
								( { decryptedPrivateDataArray, encryptedPrivateData } )
							)
						)
					)
		);
	}

	observeContactsFullyLoad( ) {
		return this._fullyLoadedObservable;
	}

	getDetailedContactAsync( id ) {
		let contact = this._findContactById( id );
		if ( !contact ) {
			//TODO: or null...
			return Rx.Observable.empty();
		}
		return this.ensureLoadedDetailsAsync( contact )
	}

	_findContactById( id ) {
		return _.find( this._contacts, { id } );
	}

	_getP2pPrivateHandlers( ) {
		return {};
	}

	_onMessageRead( multicast, pid, json, p2pIndex ) {
		let val = this._readSetSubj.getValue();
		let {id} = json;
		if ( !id ) {
			throw new Error( "Invalid read message" );
		}
		if ( !val[ id ] ) {
			val[ id ] = [];
		}
		if ( ~val[ id ].indexOf( pid ) ) {
			return;
		}
		val[ id ].push( pid );
		this._readSetSubj.onNext( val );
	}

	sendMessageRead( contactId, message ) {
		let { id, senderPid, index } = message;
		if ( ( typeof id !== "string" ) || !id ) {
			throw new Error( "String id required" );
		}
		if ( this._sentReadIds[ id ] ) {
			return;
		}
		if ( this._findContactById( contactId ).status === "failed" ) {
			console.warn( "Trying to send read message to failed contact" );
			return;
		}

		this._sentReadIds[ id ] = true;
		this._doSendMessageRead( contactId, id, senderPid, index );
	}

	_doSendMessageRead( contactId, id, pid, index ) {
		this._getMulticastOrNullAsync( contactId )
			.flatMap( multicast => multicast
				? multicast.sendPrivateMessageAsync( "read", { id }, pid )
				: Rx.Observable.just()
			)
			.catch( error => Rx.Observable.empty() )
			.subscribe();
	}

	observeUnreadCountExcept( exceptContactId ) {
		return (
			this.mutationObservable
				.map( () => {
					let count = 0;
					if ( !this._contacts ) {
						return 0;
					}
					for( let i = 0; i < this._contacts.length; i++ ) {
						let {id, unreadCount} = this._contacts[ i ];
						if ( id == exceptContactId ) {
							continue;
						}
						count += unreadCount;
					}
					return count;
				} )
				.distinctUntilChanged()
		);
	}

	observUnreadCount( contactId ) {
		return (
			this.mutationObservable
				.map( () => {
					let found = this._findContactById( contactId );
					if ( !found ) {
						return 0;
					}
					return found.unreadCount;
				} )
				.distinctUntilChanged()
		);
	}

	getUnconflictedName( baseName, multidescriptionId = -1 ) {
		let i = 1;
		let name;
		while ( _.find(
			this._contacts,
			{
				name: name = baseName + ( i > 1 ? `(${i})` : "" ),
				multidescriptionId
			}
		) ) {
			i++;
		}
		return name;
	}

	observeContactsWithMessages( ) {
		return (
			this.observeContactList()
				.concatMap( contacts =>
					this._profileService.getProfileAsync()
						.filter( profile => !!profile )
						.flatMap( ( { autoclean } ) => autoclean
						? timeServiceLocator().local2ServerTimeAsync( +new Date - autoclean * 1000 )
						: Rx.Observable.just( -1 )
					)
					.map( timeLimit => {
						let filtered = _.filter( contacts,
							( { id, lastMessageTS, unreadCount } ) =>
								unreadCount || ( lastMessageTS > timeLimit )
						);
						return filtered;
					} )
				)
		);
	}

	setContactFailedAsync( contactId, failReason ) {
		// alert( `Contact is failing ${failReason}. Press OK to restart` );
		debugger;
		console.error( "Contact error", failReason );
		currentUserServiceLocator().reload();
		return new Rx.Subject();
	}

	// setContactFailedAsync( contactId, failReason ) {
	// 	let contact = _.find( this._contacts, { id: contactId } );
	// 	if ( !contact ) {
	// 		return Rx.Observable.just();
	// 	}
	//
	// 	if ( contact.status === "failed" ) {
	// 		return Rx.Observable.just();
	// 	}
	//
	// 	return Rx.Observable.combineLatest(
	// 		this._contactId2MulticastThen[ contactId ]
	// 		? Rx.Observable.fromPromise( this._contactId2MulticastThen[ contactId ] )
	// 		: Rx.Observable.just(),
	// 		this.updateAsync( contactId, {
	// 			status: "failed",
	// 			failReason
	// 		} ),
	// 		multicast => multicast && multicast.dispose()
	// 	);
	// }

	getTriggerTos( ) {
		let res = Object.create( null );
		for ( let i = 0; i < this._contacts.length; i++ ) {
			let {sharedId, id, mainIndexInaccurate} = this._contacts[ i ];
			let mcid = Multicast.getMessageConnectionIdBySharedId( sharedId );
			if ( !res[ mcid ] ) {
				res[ mcid ] = { fromIndex: mainIndexInaccurate, ids: [ id ] };
			} else {
				res[ mcid ].fromIndex = Math.min( res[ mcid ].fromIndex, mainIndexInaccurate );
				res[ mcid ].ids.push( id );
			}
		}
		return res;
	}

	setMaxIndexes( maxIndexes ) {
		this._maxIndexes = maxIndexes;
	}

	initContact( id ) {
		let contact = this._findContactById( id );
		if ( !contact || ( contact.status === "failed" )
		|| ( contact.status === "disconnected" ) || ( contact.status === "joining" ) ) {
			//deleted or failed
			return;
		}
		this._getMulticastOrNullThen( id, true );
	}

	getMessageMaxIndexOrNullAsync( contactId ) {
		return (
			this._getMulticastOrNullAsync( contactId )
				.flatMap( multicast => multicast
					? multicast.getMessageMaxIndexAsync()
					: Rx.Observable.just( null )
				)
		);
	}

	getContactBySharedIdAsync( sharedId ) {
		let found = _.find(
			this._contacts,
			c => c.sharedId.equals( sharedId )
		);
		return Rx.Observable.just( found );
	}

	storeContactAsync( contact ) {
		if ( ( contact.status !== "failed" ) && !contact.hasDetails() ) {
			throw new Error( "No details" );
		}
		let prevSubj = this._contactsUpdating[ contact.id ] || Rx.Observable.just();
		let subj;
		this._contactsNeedUpdate[ contact.id ] = false;
		// console.log( "storeContactAsync begin" );
		return (
			prevSubj
				.tap( () => {
					subj = this._contactsUpdating[ contact.id ] = new Rx.ReplaySubject( 1 );
				} )
				.flatMap( () => this._contactRepository.updateAsync( contact )  )
				.tap( isUpdated => {
					if ( isUpdated ) {
						this.mutationObservable.onNext( contact.id );
					}
					delete this._contactsUpdating[ contact.id ];
					if ( this._contactsNeedUpdate[ contact.id ] ) {
						this.storeContactAsync( contact ).subscribe();
					}
					subj.onNext();
					subj.onCompleted();
					// console.log( "storeContactAsync end" );
				}, error => {
					subj.onError( error );
				} )
		);
	}

	storeContactLazily( contact ) {
		if ( this._contactsUpdating[ contact.id ] ) {
			this._contactsNeedUpdate[ contact.id ] = true;
			return;
		}
		this.storeContactAsync( contact ).subscribe();
	}

	removeMessageAsync( token ) {
		let { messageId, apiUrlBase } = decodeMessageToken( token );
		return messageServiceLocator().removeMessageByIdAsync( { id: messageId, apiUrlBase } );
	}

//TODO: reencrypt history content and change it's encryption key
	rejoinContactAsync( contact ) {
		let { name, multidescriptionId, id, unreadCount, lastMessageTS, globalId,
			historyContainerId, historyEncryptionKey, historyMacKey,
			historyFromIndex, multiinviteToken, multiinviteIndex,
			cachedMessages, inviteToken, failReason } = contact;
		let multicast, newContact, invite, privateData, newMulticast;
		//TODO: use one transaction
		return (
			this._getMulticastOrNullAsync( id )
				.filter( m => !!m )
				.tap( m => { multicast = m; } )
				.flatMap( () => multicast.observeParticipantsLatest().take( 1 ) )
				.flatMap( () => multicast.observePrivateDataLatest().take( 1 ) )
				.tap( pd => { privateData = pd[ multicast.getPid() ]; } )
				.flatMap( () => multicast.conditionalyCreateInviteAsync(
					"", true, () => true, privateData
				) )
				.tap( i => { i.econfig = new Config( i.econfig ); invite = i; } )
				.flatMap( () => Contact.createFromInviteAsync(
					invite, name, this._contactType, lastMessageTS
				) )
				.tap( c => {
					newContact = c;
					newContact.update( {
						multidescriptionId,
						unreadCount, lastMessageTS, globalId, historyContainerId,
						historyEncryptionKey, historyMacKey, historyFromIndex,
						multiinviteToken, multiinviteIndex,
						cachedMessages, inviteToken, failReason
					} );
				} )
				.flatMap( () => this._insertContactAsync( newContact ) )
				.flatMap( () => this._addMulticastAsync( newContact, Multicast.joinAsync(
					"", //nickname
					{ ...invite,
						signKey: newContact.signKey,
						dhPrivKey: newContact.dhPrivKey,
						pid: newContact.pid.toString( "base64" )
					},
					this._getP2pPrivateHandlers(),
					this._isUseMetaWithRights(),
					this._isUseKeychain()
				) ) )
				.tap( m => { newMulticast = m; } )
				.flatMap( () => multicast.removeAsync( multicast.getPid() ) )
				.flatMap( () => this._deleteContactAsync( id ) )
				.flatMap( () => this.observeContactList().filter( () =>
					!!{ active: 1, invited: 1, failed: 1, disconnected: 1} [ newContact.status ]
				).take( 1 ) )
				.last( { defaultValue: null } ) //In case if multicast is null there's no elements is sequence
		);
	}

	_isUseMetaWithRights( ) {
		return false;
	}

	_isUseKeychain( ) {
		return false;
	}
}

export default ContactsServiceCommon;
