import _ from "lodash";
import BaseStorage from "./base.storage.js";

let storageNumber = void 0;

let storage = global.localStorage || Object.create( null );

// HACK:
if ( global.location
	&& global.location.hash
	&& global.location.hash.substr( 0, "#external".length ) === "#external" ) {
	storage = Object.create( null );
}

function fileName2StorageKey( fileName ) {
	if ( storageNumber === void 0 ) {
		return "__inmemoryfile__" + fileName.split( "." ).join( "_" );
	}

	return "__inmemoryfile__" + storageNumber + "_" + fileName.split( "." ).join( "_" );
}

class MemoryStorage extends BaseStorage {
	constructor( fileName ) {
		super();
		this._storageKey = fileName2StorageKey( fileName );
		this._buffer = this._readAsBuffer();
	}

	_readAsBuffer( ) {
		if ( !storage[ this._storageKey ] ) {
			return new Buffer( 0 );
		}
		return Buffer.from( storage[ this._storageKey ], "base64" );
	}

	dropDataThen( ) {
		delete storage[ this._storageKey ];
		this._buffer.fill( 0 );
		this._buffer = new Buffer( 0 );
		return Promise.resolve( null );
	}

	readAsBufferThen( ) {
		return Promise.resolve( this._buffer );
	}

	replaceThen( buffer ) {
		storage[ this._storageKey ] = Buffer.isBuffer( buffer ) ? buffer.toString( "base64" ) : buffer;
		this._buffer = buffer;
		return Promise.resolve( null );
	}

	readAtPositionThen( start, length ) {
		return Promise.resolve( this._buffer.slice( start, start + length ) );
	}

	writeAtPositionThen( buffer, start ) {
		let target = this._buffer;
		if ( !Buffer.isBuffer( buffer ) ) {
			buffer = Buffer.from( buffer, "base64" );
		}

		if ( target.length < start + buffer.length ) {
			let newTarget = new Buffer( start + buffer.length );
			newTarget.fill( 0 );
			target.copy( newTarget );
			target = newTarget;
		}
		buffer.copy( target, start );
		storage[ this._storageKey ] = target.toString( "base64" );
		this._buffer = target;
		return Promise.resolve( null );
	}

	getSizeThen( ) {
		return Promise.resolve( this._buffer.length );
	}

	renameThen( newFileName ) {
		let newStorageKey = fileName2StorageKey( newFileName );
		storage[ newStorageKey ] = storage[ this._storageKey ];
		delete storage[ this._storageKey ];
		this._storageKey = newStorageKey;

		return Promise.resolve( null );
	}

	getModificationTimeThen() {
		return Promise.resolve(+new Date);
	}

	static isFileExistsAsync( fileName ) {
		return Rx.Observable.fromPromise( MemoryStorage.isFileExistsThen( fileName ) );
	}

	static isFileExistsThen( fileName ) {
		return Promise.resolve( !!storage[ fileName2StorageKey( fileName ) ] );
	}

	static setStorageNumber( number ) {
		storageNumber = number;
		if ( number == "0" ) {
			storage = Object.create( null );
		} else {
			storage = localStorage;
		}
	}

	static getMaxContacts( ) {
		if ( storageNumber == "0" ) {
			return 10000;
		}
		return 50;
	}
}

export default MemoryStorage;
