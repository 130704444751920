import Rx from "rx";
import base32 from "../../../../common/utils/base32.js";
import ssgCrypto from "ssg.crypto";
import configuration from "../../../common/configuration.js";
import DebuggableError from "../../errors/debuggable.error.js";

function generateClientKeyWithDifferentPositionsThen( serverSeed, password1, password2 ) {
	if ( password1 === password2 ) {
		return Promise.reject( new DebuggableError( "password1 === password2", { password1, password2 } ) );
	}
	return (
		ssgCrypto.createRandomBufferThen( 32 )
			.then( key => {
				if ( getMessagePosition( key, serverSeed, password1 )
					=== getMessagePosition( key, serverSeed, password2 ) ) {
					return generateClientKeyWithDifferentPositionsThen( serverSeed, password1, password2 );
				}
				return key;
			} )
	);
}

function generateClientKeyWithDifferentPositionsAsync( serverSeed, password1, password2 ) {
	return Rx.Observable.fromPromise(
		generateClientKeyWithDifferentPositionsThen( serverSeed, password1, password2 )
	);
}

function getMessagePosition( clientKey, serverSeed, password ) {
	//TODO: use kdf from ssgCrypto ?
	return ssgCrypto.hash( new Buffer( password ), clientKey, serverSeed, clientKey )[ 0 ] & 1;
}

export default {
	saltKey: ( clientKey, serverSeed, password ) => {
		//TODO: use kdf from ssgCrypto
		return ssgCrypto.hash( new Buffer( password ), clientKey, serverSeed );
	},
	getMessagePosition,
	generateClientKeyWithDifferentPositionsAsync,
	generateClientKeyWithDifferentPositionsThen,
	encodeMessageToken: ( id, clientKey ) => {
		let token = id + base32.encode( clientKey );
		let mode = configuration.getTokenMode( token.length );
		if ( mode.idLength !== id.length ) {
			throw new Error( `Token mode misconfiguration: ${mode.idLength} !== ${id.length}` );
		}
		return token;
	}
};
