import React from "react";
import _ from "lodash";

import FullScreen from "../../components/fullscreen.jsx";
import TopLevel from "../common/top.level.view.jsx";
import MenuBody from "../common/modal.menu.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import Translation from "../../components/translation.jsx";
import translate from "../../translations/translate.js";
import Rename from "../contacts/rename.jsx";
import SendContacts from "../contacts/send.contacts.jsx";
import {ModalMenuItem, MenuItem} from "../common/modal.menu.jsx";
import ImageButton from "../../components/button.image.jsx";
import OnlineStatus from "../../components/online.status.jsx"

import serviceLocator from "../../../api/services/locators/worker.client.js";
import downloadServiceLocator from "../../../api/services/locators/download.js";
import {openExternalView, closeExternalView} from "../../../common/lifecycle_observer.js"

const MAX_FILE_SIZE_KB = 1024 * 1024 * 1024;

class ChatMenuView extends React.Component {
	static get propTypes() {
		return {
			onBack: React.PropTypes.func,
			contact: React.PropTypes.object.isRequired
		};
	}

	static get defaultProps() {
		return {
			onBack: _.noop
		};
	}

	constructor() {
		super();
		this._service = serviceLocator();
		this.onClearSelect = this.onClearSelect.bind( this );
		this.onDeleteSelect = this.onDeleteSelect.bind( this );
		this.onSendFilesSelect = this.onSendFilesSelect.bind( this );
		this.onSendPhoto = this.onSendPhoto.bind( this );
		this.inputOnChange = this.inputOnChange.bind( this );
		this.onRemoteClearSelect = this.onRemoteClearSelect.bind( this );
		this._isFileSystemActive = downloadServiceLocator().isInitialized();
		this.fileExtViewIds = [];
	}

	onClearSelect() {
		if ( !confirm( translate( "chat.clear.confirm" ) ) ) {
			return;
		}
		this._service.clearHistoryAsync( this.props.contact ).subscribe();
		this.props.onBack();
	}

	onRemoteClearSelect() {
		if ( !confirm( translate( "chat.clear.confirm.remote" ) ) ) {
			return;
		}
		this._service.clearRemoteHistoryAsync( this.props.contact )
			.subscribe( () => {
				this.props.onBack();
			} );
	}

	onDeleteSelect() {
		if ( !confirm( translate( "chat.delete.confirm" ) ) ) {
			return;
		}
		this._service.deleteContactAsync( this.props.contact ).subscribeOnError( error => {
			alert( ( error && error.message ) || ( error + "" ) );
		} );
	}

	onSendFilesSelect() {
		this.fileExtViewIds.push(openExternalView());
		this.refs.input.click();
	}

	onSendPhoto() {
		if (!navigator.camera || !navigator.camera.getPicture) {
			return this.onSendFilesSelect();
		}
		const extViewId = openExternalView();
		navigator.camera.getPicture( dataBase64 => {
			try {
				let buf = new Buffer( dataBase64, "base64" );
				this._service.sendFileAsync(
					this.props.contact,
					new Blob( [ buf ], { type: "image/jpeg" } ),
					{ name: "photo.jpg" }
				).subscribe();
				closeExternalView(extViewId);
				this.props.onBack();
			} catch(error) {
				alert("Error taking photo: " + error.message);
			}
		}, error => {
			closeExternalView(extViewId);
			console.error(error);
			alert("Error making photo: " + error.message);
		}, { destinationType: /*DATA_URL*/0, saveToPhotoAlbum: false } );
	}

	inputOnChange() {
		this.fileExtViewIds.forEach(closeExternalView);
		let files = this.refs.input.files;
		let fileArray = [];
		for( let i = 0; i < files.length; i++ ) {
			fileArray.push( files[ i ] );
		}
		let isAlerted = false;
		let startAt = +new Date;
		Rx.Observable.fromArray( fileArray )
			.concatMap( file => {
				if ( file.size > MAX_FILE_SIZE_KB * 1024 ) {
					isAlerted || alert( `You can send file of size up to ${MAX_FILE_SIZE_KB} kb in this version of app.` );
					isAlerted = true;
					return Rx.Observable.empty();
				}
				return this._service.sendFileAsync( this.props.contact, file );
			} )
			.toArray()
			.subscribe();
		this.props.onBack();
	}

	renderOptionalRemoteCleanMenuItem() {
		if ( this.props.contact.multidescriptionId === -1 ) {
			return null;
		}
		return (
			<MenuItem
				titleTextId="chat.menu.clear.remote"
				icon="images/icon-menu-trash.svg"
				iconClass="trash"
				onClick={this.onRemoteClearSelect}
				id="menu-clear"
			/>
		);
	}

	renderSendFileMenuItem() {
		return (
			<MenuItem
				titleTextId="chat.menu.send.files"
				icon="images/icon-doc.svg"
				iconClass="window"
				onClick={this.onSendFilesSelect}
				id="menu-send-files"
			/>
		);
	}

	renderSendPhotoMenuItem() {
		if ( global.device && ( global.device.platform !== "Android" ) ) {
			return;
		}
		return (
			<MenuItem
				titleTextId="chat.menu.send.photo"
				icon="images/icon-doc.svg"
				iconClass="window"
				onClick={this.onSendPhoto}
				id="menu-send-photo"
			/>
		);
	}

	render() {
		let { onBack, contact }  = this.props;
		return (
			<TopLevel>
				<header><Translation textId="contacts.menu.header"/>
					<ImageButton type="icon-header-close" onClick={onBack}/>
					<OnlineStatus/>
				</header>
				<main>
					<MenuBody>
						<MenuItem
							titleTextId="chat.menu.clear"
							icon="images/icon-menu-trash.svg"
							iconClass="trash"
							onClick={this.onClearSelect}
							id="menu-clear"
						/>
						<ModalMenuItem
							titleTextId="chat.menu.rename"
							icon="images/icon-menu-pen.svg"
							iconClass="pen"
							id="menu-rename"
						>
							<Rename contact={contact}/>
						</ModalMenuItem>
						<ModalMenuItem
							titleTextId="chat.menu.send.contacts"
							icon="images/icon-menu-window.svg"
							iconClass="window"
							id="menu-send-contacts"
						>
							<SendContacts contact={contact}/>
						</ModalMenuItem>
						{this.renderSendFileMenuItem()}
						{this.renderSendPhotoMenuItem()}
						<MenuItem
							titleTextId="chat.menu.delete"
							icon="images/icon-del-user.svg"
							iconClass="trash"
							onClick={this.onDeleteSelect}
							id="menu-delete"
						/>
					{this.renderOptionalRemoteCleanMenuItem()}
					</MenuBody>
				</main>
				<input
					type="file"
					ref="input"
					multiple
					onChange={this.inputOnChange}
					style={{ visibility: "hidden"}}
				/>
				<DeviceBackButton onPress={onBack}/>
			</TopLevel>
		);
	}
}

export default ChatMenuView;
