import Rx from "rx";
import ssgCrypto from "ssg.crypto";
import base64 from "../../../common/utils/base64.js";

export default {
	getEncryptionKeyBySeedsAndPassword: function( clientSeed, serverSeed, password ) {
		return ssgCrypto.hash( clientSeed, serverSeed, new Buffer( password ) ); //TODO: ssgCrypto
	},
	getPositionBit: function( clientSeed, password ) {
		return ssgCrypto.hash( clientSeed, new Buffer( password ), clientSeed )[ 0 ] & 1;
	},
	getSeedId: function( clientSeed, password ) {
		return base64.encode( ssgCrypto.hash( clientSeed, new Buffer( password ) ) );
	}
};
