import React from 'react'
import translate from "../translations/translate.js"
import {openExternalView, closeExternalView} from "../../common/lifecycle_observer.js"
import _ from 'lodash'


//NOTE: Prompt not implemented https://github.com/apache/cordova-plugin-dialogs

if ( !global.cordova ) {
  navigator.notification = {
    alert: (text, onOk) => {
      alert(text);
      onOk();
    },
    confirm: (text, onConfirm) => {
      onConfirm(confirm(text) ? 1 : 2)
    }
  }
}

class Dialog extends React.Component {
  shouldComponentUpdate () {
    return false;
  }

  render () {
    return null;
  }
}

class Alert extends Dialog {
  show(message) {
    const extViewId = openExternalView();
    navigator.notification.alert(
      message || translate(this.props.textId),
      (res) => {
        closeExternalView(extViewId);
        this.props.onOk(res);
      },
      translate(this.props.titleTextId  || 'dialog.title.alert'),
      translate(this.props.buttonTextId || 'dialog.ok')
     )
  }

  static get propTypes() {
    return {
      textId: React.PropTypes.string.isRequired,
      onOk:   React.PropTypes.func.isRequired
    };
  }

  static get defaultProps() {
    return {
      onOk: () => {}
    }
  }
}

class Confirm extends Dialog {
  show() {
      let {textId, titleTextId, params} = this.props;
      if ( !params ) {
        params = [];
      }
      let text = translate(textId, ...params);
      if ( typeof text === "function" ) {
          text = text( ...params );
      }
    const extViewId = openExternalView();
    navigator.notification.confirm(
      text,
      (id) => {
        closeExternalView(extViewId);
          id === 1 ? this.props.onConfirm() : this.props.onReset();
      },
      translate( this.props.titleTextId ? titleTextId : "dialog.confirm" ),
      this.props.buttonTextIds.map(translate)
     )
  }

  static get propTypes() {
    return {
      textId: React.PropTypes.string.isRequired,
      onConfirm: React.PropTypes.func.isRequired,
      params: React.PropTypes.array
    };
  }

  static get defaultProps() {
    return {
      onReset: () => {},
      buttonTextIds: ['dialog.confirm','dialog.cancel']
    }
  }
}

class InternetRetryConfirm extends Confirm {
  static get defaultProps() {
    return {
      onReset: () => {},
      buttonTextIds: ['dialog.retry','dialog.cancel'],
      textId: "common.network.error"
    }
  }
}

export {Confirm, Alert, InternetRetryConfirm}
