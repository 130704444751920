import _ from 'lodash'
import React from 'react'

// https://github.com/apache/cordova-plugin-inappbrowser
let openLink = window.open;

let onClick = link => e => {
	e.preventDefault();
	e.stopPropagation();
	openLink( link, '_system' );
}

let prepareMessage = _.memoize ( ( message ) => {
	let randomSeparator = Math.random().toString();
	let links = [];

	let onlyText = ( message + "" )
		.replace( /((:?http|https):\/\/|www\.)[^\s]+/ig, link => {
			let fulllink = ( /\w+:\/\//i.test( link ) ? '' : 'http://' ) + link;
			links.push(
				<a
					href="javascript:;"
					onClick={ onClick( fulllink ) }
					onTouchStart={ onClick( fulllink ) }
					rel="noreferrer"
					key={"l" + links.length} >{ link }</a>
			)
			return randomSeparator;
		} )
		.split( randomSeparator )
		.map( ( text, index ) => <span key={"t" + index}>{text}</span> );

	return	_.filter( _.flattenDeep( _.zip( onlyText, links ) ) );
});

export default prepareMessage;
