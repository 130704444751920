import React from "react";

import Translation from "../../../components/translation.jsx";
import { ButtonToolbar, SplitButton, MenuItem, Dropdown } from "react-bootstrap";

class ExternalMainTitle extends React.Component {
    renderClearHistory() {
        let {contact, onClearHistory} = this.props;
		if ( !onClearHistory ) {
			return null;
		}

		return (
			<MenuItem eventKey="2" onClick={onClearHistory}>
				<Translation textId="web.contacts.menu.clear.history" />
			</MenuItem>
		);
    }

    renderAddExternal() {
        let { contact, onAddExternal } = this.props;
		if ( !onAddExternal ) {
			return null;
		}

		return (
			<MenuItem eventKey="3" onClick={onAddExternal}>
				<Translation textId="web.group.menu.external" />
			</MenuItem>
		);
    }

    renderNewGroup() {
        let { onNewGroup } = this.props;
		if ( !onNewGroup ) {
			return null;
		}

		return (
			<MenuItem eventKey="4" onClick={onNewGroup}>
				<Translation textId="web.group.menu.new" />
			</MenuItem>
		);
    }

    renderExit() {
        let { contact, onExit } = this.props;
		if ( !onExit ) {
			return null;
		}

		return (
			<MenuItem eventKey="5" onClick={onExit}>
				<Translation textId="web.group.menu.exit" />
			</MenuItem>
		);
    }

    renderDelete() {
        let { contact, onDelete } = this.props;
		if ( !onDelete ) {
			return null;
		}
//TODO: rename
		return (
			<MenuItem eventKey="5" onClick={onDelete}>
				<Translation textId="web.group.menu.exit" />
			</MenuItem>
		);
    }

    renderInactiveGroupMenu() {
        return (
            <ul
                className="nav navbar-nav navbar-right right"
                style={ { marginTop: 0 } }
            >
                <Dropdown id="contacts_dropdown" componentClass="li">
                    <Dropdown.Toggle noCaret={true} useAnchor={true}>
                        <img src="web/img/menu_2_moreoptions.svg" alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-nav-menu" bsClass="">
                        {this.renderNewGroup()}
                    </Dropdown.Menu>
                </Dropdown>
            </ul>
        );
    }

    renderActiveGroupMenu() {
        return (
			<ul
				className="nav navbar-nav navbar-right right"
				style={ { marginTop: 0 } }
			>
				<Dropdown id="contacts_dropdown" componentClass="li">
					<Dropdown.Toggle noCaret={true} useAnchor={true}>
						<img src="web/img/menu_2_moreoptions.svg" alt="" />
					</Dropdown.Toggle>
					<Dropdown.Menu className="dropdown-menu dropdown-nav-menu" bsClass="">
						{this.renderClearHistory()}
                        {this.renderAddExternal()}
                        {this.renderNewGroup()}
                        {this.renderExit()}
					</Dropdown.Menu>
				</Dropdown>
			</ul>
		);
    }

    renderActiveContactMenu() {
        return (
			<ul
				className="nav navbar-nav navbar-right right"
				style={ { marginTop: 0 } }
			>
				<Dropdown id="contacts_dropdown" componentClass="li">
					<Dropdown.Toggle noCaret={true} useAnchor={true}>
						<img src="web/img/menu_2_moreoptions.svg" alt="" />
					</Dropdown.Toggle>
					<Dropdown.Menu className="dropdown-menu dropdown-nav-menu" bsClass="">
						{this.renderClearHistory()}
                        {this.renderNewGroup()}
                        {this.renderDelete()}
					</Dropdown.Menu>
				</Dropdown>
			</ul>
		);
    }

    renderMenu() {
		let { contact } = this.props;
		if ( !contact || ( contact.status !== "active" && contact.status !== "invited" ) ) {
            return this.renderInactiveGroupMenu();
		}
        if ( contact.type === "group" ) {
            return this.renderActiveGroupMenu();
        }
        return this.renderActiveContactMenu();
	}

    renderText() {
        let { contact } = this.props;
        if ( !contact ) {
            return null;
        }
        if ( contact.type === "group" ) {
            return (
                <Translation
                    textId="web.group.external.main.title"
                    params={ [ contact.name ] }
                />
            );
        }
        return (
            <Translation
                textId="web.contact.external.main.title"
                params={ [ contact.name || "anonymous" ] }
            />
        );
    }

    render() {
        return (
            <div className="navbar grow">
                <div id="navbar" className="navbar-collapse">
                    <div className="name">
                        { this.renderText() }
                    </div>
                    { this.renderMenu() }
                </div>
            </div>
        );
    }
}

export default ExternalMainTitle;
