import React from "react";
import history from "../components/history.js";
import wcServiceLocator from "../../api/services/locators/worker.client.js";

class DefaultView extends React.Component {
	constructor() {
		super();
		this._service = wcServiceLocator();
	}

	componentWillMount() {
		this._service.isRegisteredAsync().subscribe( registered => {
			if ( registered ) {
				history.navigateTo( "login" );
			} else {
				history.navigateTo( "registration" );
			}
		} );
	}

	render() {
		return <section style={ { width: "100%", height: "100%" } }><main /></section>;
	}
}

export default DefaultView;
