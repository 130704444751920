import ssgCrypto from "ssg.crypto";

import serviceLocator from "../../../../api/services/locators/worker.client.js";
import BaseEditMessage from "../common/message.write.footer.jsx";

class EditMessageView extends BaseEditMessage {
    constructor() {
        super();
        //This is fake RPC.
        this._service = {
            sendEditTextMessageAsync: this.sendEditTextMessageAsync.bind( this ),
            sendTextMessageAsync: this.sendTextMessageAsync.bind( this ),
            sendFileAsync: this.sendFileAsync.bind( this ),
            observeGlobalUserType: this.observeGlobalUserType.bind( this ),
            sendVoiceMessageAsync: this.sendVoiceMessageAsync.bind( this )
        };
        this._baseService = serviceLocator();
    }

    sendEditTextMessageAsync( editingMessage, text, contact ) {
        return this._baseService.sendEditTextMessageAsync(
            contact,
            editingMessage.replaceIndex === undefined ? editingMessage.index : editingMessage.replaceIndex,
            text
        );
    }

    sendTextMessageAsync( contact, text, replyMessage ) {
      if (replyMessage && !replyMessage.text) {
  			throw new Error("You can reply only to text messages");
  		}
  		let replyTo = null;
  		if (replyMessage) {
  			replyTo = {
  				replyIndex: replyMessage.replaceIndex === undefined ? replyMessage.index : replyMessage.replaceIndex,
  				replyText: replyMessage.text.length <= 100 ? replyMessage.text : (replyMessage.text.substr(0, 100) + "..."),
  				replySenderPid: replyMessage.senderPid
  			};
  		}
        return this._baseService.sendTextMessageAsync( contact, text, replyTo );
    }

    sendFileAsync( contact, file ) {
        return this._baseService.sendFileAsync( contact, file );
    }

    sendVoiceMessageAsync( ...args ) {
        return this._baseService.sendVoiceMessageAsync( ...args );
    }

    observeGlobalUserType() {
      return this._baseService.observeGlobalUserType();
    }
}

export default EditMessageView;
