import React from "react";
import classNames from "classnames";
import _ from "lodash";
import ViewBigimg from "../../components/img-viewer/index.js";

import Button from "../../components/button.jsx";

import Translation from "../../components/translation.jsx";
import Timestamp from "../../components/timestamp.jsx";
import PasteWrapper from "../../components/paste.jsx";
import prepareMessage from "../../components/prepareMessage.jsx";
import AudioPlayer from "../../components/audio.player.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import autoPlayer from "../../audio/autoplayer.js";

import {
	ChatMessageText, ChatMessageFile, ChatMessageContact,
	ChatMessageWorkgroup, ChatMessageGroup, ChatMessageDelete, MESSAGE_TYPE,
	ChatMessageImage
} from "../../../api/models/chat.message.js";
import {
	getAvaColorByName,
	getContactInitials,
	openFileWith
} from "../../../common/utils";
import Clipboard from '../../../common/clipboard.js'

import serviceLocator from "../../../api/services/locators/worker.client.js";
import audioDownloader from "../../../api/services/audio.downloader.js";

import downloadServiceLocator from "../../../api/services/locators/download.js";
import { deserializeFromBase64StringAsync } from "../../../api/models/message/technical.js";

global.easeOutQuart = function(t, b, c, d) { //disable zoom animation in ViewBigimg because it is slow
  return c + b
}

class MessageView extends React.Component {
	constructor() {
		super();
		this.state = {};
		this._service = serviceLocator();
	}

	shouldComponentUpdate( nextProps, nextState ) {
		if ( this.props.message !== nextProps.message ) {
			return true;
		}
		if ( this.props.onEdit !== nextProps.onEdit ) {
			return true;
		}
		if ( this.state.status !== nextState.status ) {
			return true;
		}
		if ( this.props.message.sent ) {
			return true;
		}
		return false;
	}

	render( ) {
		let { contact, message, status, onEdit, onForward, onReply } = this.props;
		let MessageViewComponent;
		switch ( message.type ) {
			case MESSAGE_TYPE.INCOMING.name:
				MessageViewComponent = IncomingMessageView;
				break;
			case MESSAGE_TYPE.OUTGOING.name:
				MessageViewComponent = OutgoingMessageView;
				break;
			case MESSAGE_TYPE.SERVICE.name:
				MessageViewComponent = ServiceMessageView;
				break;
			default:
				MessageViewComponent = OutgoingMessageView;
				// throw new Error( "Invalid message type:" + message.type );
		}
		return <MessageViewComponent
			contact={ contact }
			message={ message }
			status={ status }
			onEdit={ onEdit }
			onForward={ onForward }
			onReply={ onReply }
		/>;
	}
}

class SenderNameView extends React.Component {
	render() {
		let {contact, message} = this.props;

		return <div className="name">{message.sender}</div>;
	}
}

class SenderAvaView extends React.Component {
	render() {
		let {contact, message} = this.props;
		let initialsOrNull, background, service;

		initialsOrNull = getContactInitials( message.sender );
		background = getAvaColorByName( message.sender );
		return (
			<div className="ava" style={ { background } } >
				{ initialsOrNull }
			</div>
		);
	}
}

class MessageDeleteView extends React.Component {
	render() {
		return (
			<div className="text" style={ { fontStyle: "italic" } }>
				<Translation textId="chat.message.deleted.text"/>
			</div>
		);
	}
}

class MessageTextView extends React.Component {
	renderReply() {
		const {replyTo, replyingSender} = this.props.message;
		const {contact} = this.props;
		if (!replyTo || !replyTo.replySenderPid) {
			return null;
		}
		return (
			<div style={{background: '#444', fontSize: '80%', padding: '5px', borderRadius: '3px', color: '#ddd'}}>
				{replyingSender || contact.name}: {replyTo.replyText}
			</div>
		);
	}
	render() {
		let {message, status} = this.props;
		let divClassName = classNames( {
			"pending": status === "queued",
			text: true
		} );
		return (
			<div className={divClassName}>
				{ this.renderReply() }
				{ prepareMessage( message.text ) }
			</div>
		);
	}
}

class MessageFileView extends React.Component {
	constructor() {
		super();
		// this.state = { status: "initial" };
		this.state = { status: "inactive" };
		this.downloadFileClick = this.downloadFileClick.bind( this );
		this.openFileClick = this.openFileClick.bind( this );
		this.retry = this.retry.bind( this );
		this._service = downloadServiceLocator();
	}

	componentWillMount() {
		if ( !this._service.isInitialized() ) {
			this.setState( { status: "inactive" } );
			return;
		}
		this._stateSubscription = (
			this._service.observeDownloadState( this.props.message )
				.subscribe( this.gotState.bind( this ) )
		);
	}

	componentWillUnmount() {
		if ( this._stateSubscription ) {
			this._stateSubscription.dispose();
		}
	}

	gotState( state ) {
		if ( state.error ) {
			this.setState( {
				fileStateMessage: error.message || "Error",
				status: "error"
			} );
			return;
		}
		if ( state.inactive ) {
			// this._service.isFileExistsAsync( this.props.message.fileName )
			// 	.subscribe( isExists => {
			// 		this.setState( { status: isExists ? "downloaded": "notdownloaded" } );
			// 	} );
			return;
		}
		let { total, wrote } = state;
		this.setState( { total, wrote, status: "downloading" } );
	}

	downloadFileClick( event ) {
		event.preventDefault();
		if ( this.state.status !== "notdownloaded" ) {
			return;
		}
		this.setState( { status: "downloading" } );
		this._service.startDownloadFile( this.props.message );
	}


	openFileClick() {
		let { fileName, contentType } = this.props.message;
		this._service.openDownloadedFile( fileName, contentType );
	}

	renderNotDownloaded() {
		return this.renderInactive();


		let { message } = this.props;
		return (
			<div className="text">
				{ this.renderPreview() }
				<span>
					<Translation textId="chat.message.file.text"/>
					<br />
					<a
						href="javascript:;"
						onMouseDown={ this.downloadFileClick }
						onTouchStart={ this.downloadFileClick }
						download={ message.fileName }
					>
						{ message.fileName }
					</a>
					<br/>
					{ this.state.fileStateMessage || "" }
				</span>
			</div>
		);
	}

	renderDownloading() {
		let { message } = this.props;
		return (
			<div className="text">
				{ this.renderPreview() }
				<Translation
					textId="chat.message.file.downloading.text"
					params={ [
						message.fileName,
						( this.state.wrote / 1024 ) | 0,
						( this.state.total / 1024 ) | 0
					] }
				/>
			</div>
		);
	}

	renderDownloaded() {
		let { message } = this.props;
		return (
			<div className="text">
				{ this.renderPreview() }
				<span>
					<Translation
						textId="chat.message.file.downloaded.text"
						params={ [ message.fileName, this._service.getFilePath( message.fileName ) ] }
					/>
					<br />
					<a
						href="javascript:;"
						onMouseDown={ this.openFileClick }
						onTouchStart={ this.openFileClick }
						download={ message.fileName }
					>
						<Translation textId="chat.message.file.open.button" />
					</a>
				</span>
			</div>
		);
	}

	retry() {
		this.setState( { status: "notdownloaded", fileStateMessage: null } );
	}

	renderPreview() {
		return null;
	}

	renderError() {
		return (
			<div className="text">
				{ this.renderPreview() }
				{ this.state.fileStateMessage || " error" }
				<br />
				<a href="javascript:;"
					onMouseDown={ this.retry }
					onTouchStart={ this.retry }
				>Retry</a>
			</div>
		);
	}

	renderInactive() {
		let { message } = this.props;
		return (
			<div className="text">
				{ this.renderPreview() }
				<Translation
					textId="chat.message.file.inactive.text"
					params={ [ message.fileName ] }
				/>
			</div>
		);
	}

	render() {
		switch ( this.state.status ) {
			case "initial":
			case "notdownloaded":
				return this.renderNotDownloaded();
			case "downloading":
				return this.renderDownloading();
			case "downloaded":
				return this.renderDownloaded();
			case "error":
				return this.renderError();
			case "inactive":
				return this.renderInactive();
			default:
				return this.renderError();
		}
	}
}

class MessageImageView extends MessageFileView {
	componentWillUnmount() {
		if ( this.state.url ) {
			URL.revokeObjectURL( this.state.url );
		}
		this._service.removeFileDownloadProgress( this.props.message );
		if ( this.state.viewer ) {
			this.state.viewer.destroy();
		}
		super.componentWillUnmount();
		this._isUnmounted = true;
	}

	downloadFileClick( event ) {
		event.preventDefault();
		if ( this.state.status === "downloading" ) {
			return;
		}
		if ( this.state.status === "downloaded" ) {
			let { viewer } = this.state;
			if ( !this.state.viewer ) {
				viewer = new ViewBigimg;
				this.setState( { viewer } );
			}
			viewer.show( this.state.url );
			return;
		}
		this.setState( { status: "downloading" } );
		this._service.downloadFileAsBufferThen( this.props.message ).then( buffer => {
			if ( this._isUnmounted ) {
				return;
			}
			let blob = new Blob( [ buffer ], { type: this.props.message.contentType } );
			let url = URL.createObjectURL( blob );
			let viewer = new ViewBigimg;
			this.setState( { status: "downloaded", url, viewer } );
			viewer.show( url );
		} );
	}

	renderInactive() {
		let { message } = this.props;
		return (
			<div className="text">
				{ this.renderPreview() }
				<span>
					<Translation textId="chat.message.file.text"/>
					<br />
					<a
						href="javascript:;"
						onMouseDown={ this.downloadFileClick }
						onTouchStart={ this.downloadFileClick }
						download={ message.fileName }
					>
						{ message.fileName }
					</a>
					<br/>
					{ this.state.fileStateMessage || "" }
				</span>
			</div>
		);
	}

	// renderDownloading() {
	// 	let { message } = this.props;
	// 	return (
	// 		<div className="text">
	// 			{ this.renderPreview() }
	// 			<br />
	// 			...
	// 		</div>
	// 	);
	// }

	renderPreview() {
		return (
			<span>
				<img
					src={ `data:image/${this.props.message.thumbnailBase64}` }
					onMouseDown={ this.downloadFileClick }
					onTouchStart={ this.downloadFileClick }
				/>
				<br />
			</span>
		);
	}

	onBackPress() {
		if ( !this.state.viewer ) {
			return null;
		}
		this.state.viewer.destroy();
		this.setState( { viewer: null } );
	}

	renderDeviceBackButton() {
		if ( !this.state.viewer ) {
			return null;
		}
		return <DeviceBackButton onPress={ this.onBackPress.bind( this ) }/>;
	}

	renderDownloaded() {
		let { message } = this.props;
		return (
			<div className="text">
				{ this.renderPreview() }
				<span>
					<a
						href="javascript:;"
						onMouseDown={ this.openFileClick }
						onTouchStart={ this.openFileClick }
						download={ message.fileName }
					>
						<Translation textId="chat.message.file.open.button" />
					</a>
					{ this.renderDeviceBackButton() }
				</span>
			</div>
		);
	}
}

class MessageAudioView extends React.Component {
	constructor() {
		super();
		this._service = downloadServiceLocator();
		this.state = { status: "initial" };
		this.onEnded = this.onEnded.bind( this );
	}

	componentDidMount() {
		this.download();
		autoPlayer.addAudioMessageComponentInstance( this );
	}

	componentWillUnmount() {
		autoPlayer.removeAudioMessageComponentInstance( this );
		this._audioPromise && audioDownloader.cancelQuery( this._audioPromise );
	}

	download() {
		this.setState( { status: "downloading" } );
		this._audioPromise = audioDownloader.queryAudio( this.props.message );
		this._audioPromise.then( buffer => {
			this._audioPromise = null;
			if ( !buffer ) {
				return;
			}
			this.setState( { status: "downloaded", buffer } );
		} );
	}

	onPlay() {
		this.refs.audio && this.refs.audio.onPlay();
	}

	onEnded() {
		autoPlayer.playNext( this );
	}

	renderPlayer() {
		let { message } = this.props;
		return (
			<AudioPlayer
				buffer={ this.state.buffer }
				mimeType={ message.mimeType }
				ref="audio"
				milliseconds={ message.milliseconds }
				id={ message.id }
				onEnded={ this.onEnded }
			/>
		);
	}

	render() {
		switch ( this.state.status ) {
			case "initial":
			case "downloading":
			case "downloaded":
				return this.renderPlayer();
		}
		return (
			<span>
				...
			</span>
		);
	}
}

class MessageContactView extends React.Component {
	constructor() {
		super();
		this.state = { isInProgress: false };
		this._service = serviceLocator();
		this.onAcceptClick = this.onAcceptClick.bind( this );
	}

	onAcceptClick() {
		if ( this.acceptSubscription ) {
			return;
		}
		let { message, contact } = this.props;
		this.setState( { isInProgress: true } );
		this.acceptSubscription = (
			this._service.acceptInviteByInviteIdAsync(
				message.inviteId,
				contact
			)
			.flatMap( () =>
				this._service.setChatMessageProcessedAsync( contact, message )
			)
			.subscribe()
		);
	}

	renderText() {
		if ( this.props.message.type === MESSAGE_TYPE.INCOMING.name ) {
			return <Translation textId="chat.message.contact.incoming.text"/>;
		}
		return <Translation textId="chat.message.contact.outgoing.text"/>;
	}

	renderUndecoded() {
		return (
			<div className="text">
				{ this.renderText() }
			</div>
		);
	}

	renderDuplicate() {
		return (
			<div className="text">
				{ this.renderText() }
				<br />
				<b>{ this.props.message.nickname }</b>
			</div>
		);
	}

	renderPrompt() {
		return (
			<div className="text">
				{ this.renderText() }
				<br />
				<b>{ this.props.message.nickname }</b>
				<br />
				<Button
					caption="chat.message.contact.accept.button"
					handleClick={ this.onAcceptClick }
					enabled={ !this.state.isInProgress }
				/>
			</div>
		);
	}

	render() {
		let { message } = this.props;


		if (
			message.isDuplicate ||
			( message.type !== MESSAGE_TYPE.INCOMING.name ) ||
			message.isProcessed
		) {
			return this.renderDuplicate();
		}
		return this.renderPrompt();
	}
}

class MessageWorkgroupView extends React.Component {
	constructor() {
		super();
		this.state = {isInProgress: false};
		this._service = serviceLocator();

		this.onAcceptClick = this.onAcceptClick.bind( this );
	}

	componentWillMount() {
		let {inviteId, isDuplicate} = this.props.message;
		this._subscription = (
			Rx.Observable.combineLatest(
				this._service.observeContactList(),
				this._service.observeGlobalUserType(),
				( contacts, {isPrivileged} ) =>
					( { contacts, isPrivileged } )
			)
				.subscribe( ( { contacts, isPrivileged } ) => {
					this.setState( ( {
						contacts,
						isPrivileged
					} ) );
				} )
		);
	}

	componentWillUnmount() {
		this._subscription.dispose();
	}

	onAcceptClick() {
		if ( this.acceptSubscription ) {
			return;
		}
		let { message, contact } = this.props;
		this.setState( { isInProgress: true } );
		this.acceptSubscription = (
			this._service.joinWorkgroupByInviteIdAsync(
				message.inviteId
			)
			.flatMap( () =>
				this._service.setChatMessageProcessedAsync( contact, message )
			)
			.subscribe()
		);
	}

	renderText() {
		if ( this.props.message.type === MESSAGE_TYPE.INCOMING.name ) {
			return <Translation textId="chat.message.workgroup.incoming.text"/>;
		}
		return <Translation textId="chat.message.workgroup.outgoing.text"/>;
	}

	renderUndecoded() {
		return (
			<div className="text">
				{ this.renderText() }
			</div>
		);
	}

	renderDuplicate() {
		return (
			<div className="text">
				{ this.renderText() }
				<br />
				<b>{ this.props.message.name }</b>
			</div>
		);
	}

	renderPrompt() {
		return (
			<div className="text">
				{ this.renderText() }
				<br />
				<b>{ this.props.message.name }</b>
				<br />
				<Button
					caption="chat.message.workgroup.accept.button"
					handleClick={ this.onAcceptClick }
					enabled={ !this.state.isInProgress }
				/>
			</div>
		);
	}

	renderUnpriviledged() {
		return (
			<div className="text">
				<Translation textId="chat.message.workgroup.unprivileged.text" />
			</div>
		);
	}

	render() {
		let { message } = this.props;

		if ( !this.state.isPrivileged && ( message.type === MESSAGE_TYPE.INCOMING.name ) && !message.isProcessed ) {
			return this.renderUnpriviledged();
		}

		if ( message.isDuplicate || ( message.type !== MESSAGE_TYPE.INCOMING.name ) || message.isProcessed ) {
			return this.renderDuplicate();
		}
		return this.renderPrompt();
	}
}

class MessageGroupView extends React.Component {
	constructor() {
		super();
		this.state = { isInProgress: false };
		this._service = serviceLocator();
		this.onAcceptClick = this.onAcceptClick.bind( this );
	}

	onAcceptClick() {
		if ( this.acceptSubscription ) {
			return;
		}
		let { message, contact } = this.props;
		this.setState( { isInProgress: true } );
		this.acceptSubscription = (
			this._service.joinGroupByInviteIdAsync(
				message.inviteId
			)
			.flatMap( () =>
				this._service.setChatMessageProcessedAsync( contact, message )
			)
			.subscribe()
		);
	}

	renderText() {
		if ( this.props.message.type === MESSAGE_TYPE.INCOMING.name ) {
			return <Translation textId="chat.message.group.incoming.text"/>;
		}
		return <Translation textId="chat.message.group.outgoing.text"/>;
	}

	renderUndecoded() {
		return (
			<div className="text">
				{ this.renderText() }
			</div>
		);
	}

	renderDuplicate() {
		return (
			<div className="text">
				{ this.renderText() }
				<br />
				<b>{ this.props.message.name }</b>
			</div>
		);
	}

	renderPrompt() {
		return (
			<div className="text">
				{ this.renderText() }
				<br />
				<b>{ this.props.message.name }</b>
				<br />
				<Button
					caption="chat.message.group.accept.button"
					handleClick={ this.onAcceptClick }
					enabled={ !this.state.isInProgress }
				/>
			</div>
		);
	}

	render() {
		let {message} = this.props;

		if ( message.isDuplicate ||
			( message.type !== MESSAGE_TYPE.INCOMING.name )
			|| message.isProcessed ) {
			return this.renderDuplicate();
		}
		return this.renderPrompt();
	}
}

class MessageContentView extends React.Component {
	render() {
		let { contact, message, status } = this.props;
		let MessageContentView;

		if ( message instanceof ChatMessageDelete ) {
			MessageContentView = MessageDeleteView;
		}

		if ( message instanceof ChatMessageText ) {
			MessageContentView = MessageTextView;
		}

		if ( message instanceof ChatMessageFile ) {
			debugger;
			MessageContentView = MessageFileView;
		}

		if ( message instanceof ChatMessageImage ) {
			MessageContentView = MessageImageView;
		}

		if ( message instanceof ChatMessageContact ) {
			MessageContentView = MessageContactView;
		}

		if ( message instanceof ChatMessageWorkgroup ) {
			MessageContentView = MessageWorkgroupView;
		}

		if ( message instanceof ChatMessageGroup ) {
			MessageContentView = MessageGroupView;
		}

		if ( message.sendingFile ) {
			if (message.thumbnailBase64) {
				MessageContentView =  MessageSendingImageView;
			} else if ( message.milliseconds ){
				MessageContentView = MessageSendingAudioView;
			} else {
				debugger;
				MessageContentView =  MessageSendingFileView;
			}
		}

		if ( !MessageContentView ) {
			switch( message.contentType ) {
				case "contact":
					MessageContentView = MessageContactView;
					break;
				case "group":
					MessageContentView = MessageGroupView;
					break;
				case "workgroup":
					MessageContentView = MessageWorkgroupView;
					break;
				case "delete":
					MessageContentView = MessageDeleteView;
					break;
				default:
					if ( message.text ) {
						MessageContentView = MessageTextView;
					} else if ( message.thumbnailBase64 ) {
						MessageContentView = MessageImageView;
					} else {
						if ( message.milliseconds ) { //TODO: check audio
							MessageContentView = MessageAudioView;
						} else {
							MessageContentView = MessageFileView;
						}
					}
			}
		}

		return <ChatMessageWrapper
			type={ MessageContentView }
			props={ this.props }
		/>;
	}
}

class ChatMessageWrapper extends React.Component {
	constructor() {
		super();
		this.copy = this.copy.bind( this );
		this.onDelete = this.onDelete.bind( this );
	}

	componentWillMount() {
		this._service = serviceLocator();
	}

	getOnCopy() {
		let { message } = this.props.props;
		if ( !message.text ) {
			return null;
		}
		return Clipboard.copy( this.copy );
	}

	copy() {
		return this.props.props.message.text;
	}

	getOnEdit() {
		let { message, onEdit } = this.props.props;
		let { status, type, contentType } = message;
		if ( ( status === "queued" ) || ( type !== MESSAGE_TYPE.OUTGOING.name )
			|| ( contentType !== "text/plain" ) || !onEdit ) {
			return null;
		}
		return () => onEdit( message );
	}

	getOnForward() {
		let { message, onForward } = this.props.props;
		if ( !onForward || ( !message.text && !message.fileToken ) ) {
			return null;
		}
		return () => onForward( message );
	}

	getOnDelete() {
		let { status, type, contentType } = this.props.props.message;
		if ( ( status === "queued" )
		|| ( type !== MESSAGE_TYPE.OUTGOING.name )
		|| ( contentType === "delete" ) ) {
			return null;
		}
		return this.onDelete;
	}

	getOnReply() {
		let {onReply, message} = this.props.props;
		let { status, type, contentType } = message;
		if ( ( status === "queued" )
		|| ( type === MESSAGE_TYPE.OUTGOING.name )
		|| ( contentType === "delete" )
		|| !onReply) {
			return null;
		}
		return () => onReply(message);
	}

	onDelete() {
		let { index, replaceIndex } = this.props.props.message;
		this._service.sendDeleteMessageAsync(
			this.props.props.contact,
			replaceIndex === undefined ? index : replaceIndex
		).subscribe();
	}

	render() {
		let MessageContentView = this.props.type;
		let { props } = this.props;
		let { message } = props;

		if ( ( message.contentType === "delete" ) || !this.getOnForward() ) {
			return <MessageContentView { ...props } />;
		}
		return (
			<PasteWrapper
				showOnTap={ true }
				onCopy={ this.getOnCopy() }
				onEdit={ this.getOnEdit() }
				onForward={ this.getOnForward() }
				onDelete={ this.getOnDelete() }
				onReply={ this.getOnReply() }
				isRight={ message.type === MESSAGE_TYPE.OUTGOING.name }
			>
				<MessageContentView { ...props } />
			</PasteWrapper>
		);
	}
}

class IncomingMessageView extends React.Component {
	renderTimestamp() {
		let { message } = this.props;
		if ( message.replaceIndex === undefined ) {
			return <Timestamp value={ message.timestamp } mode="server" />;
		}

		return (
			<span>
				<Translation textId="chat.message.edited.text" />
				{" "}
				<Timestamp value={ message.timestamp } mode="server" />
			</span>
		);
	}

	render() {
		let { contact, message, status, className, onForward, onReply } = this.props;
		return (
			<div
				className={ "item incoming " + ( className || "" ) }
				style={ { background: "unset" } }
			>
				<SenderAvaView message={ message } contact={ contact } />
				<SenderNameView message={ message } contact={ contact } />
				<MessageContentView contact={ contact } message={ message } status={ status } onForward={ onForward } onReply={ onReply } />
				<div className="date">
					{ this.renderTimestamp() }
				</div>
			</div>
		);
	}
}

class OutgoingMessageView extends React.Component {
	renderTimestamp() {
		let { message } = this.props;
		if ( !message.timestamp ) {
			return "..";
		}
		if ( message.replaceIndex === undefined ) {
			return <Timestamp value={ message.timestamp } mode="server"/>;
		}

		return (
			<span>
				<Translation textId="chat.message.edited.text" />
				{" "}
				<Timestamp value={ message.timestamp } mode="server"/>
			</span>
		);
	}
	renderDateElement() {
		let { contact, message, status } = this.props;
		return <div className="date">
			{ message.isRead ? <img src="images/icon-v.svg"/> : null }
			{
				( status === "queued" ) && message.timestamp
				? <Timestamp value={ message.timestamp } mode="server"/>
				: this.renderTimestamp()
			}
		</div>
	}

	render() {
		let { contact, message, status, className, onEdit, onForward } = this.props;
		return (
			<div className="item outgoing" style={ { background: "unset" } }>
				<SenderNameView message={ message } contact={ contact } />
				<MessageContentView contact={ contact } message={ message } status={ status } onEdit={ onEdit } onForward={ onForward } />
				{ this.renderDateElement() }
			</div>
		);
	}
}

class ServiceMessageView extends React.Component {
	render() {
		let { contact, message, status, className } = this.props;

		return (
			<div
				className={ "item service " + ( this.props.className || "" ) }
				style={ { background: "unset" } }>
				<div className="text">
					<Translation textId={ message.text } params={ [ message.sender ] } />
				</div>
				<div className="date">
					<Timestamp value={ message.timestamp } mode="server" />
				</div>
			</div>
		);
	}
}

class MessageSendingFileView extends React.Component {
	render() {
		let { contact, message, status, className } = this.props;
		let { name, sent, total, error } = message;
		if ( error ) {
			return (
				<div className="item outgoing" style={ { background: "unset" } }>
					<span className="error">
						<Translation textId="chat.message.file.sending.error" />
					</span>
				</div>
			);
		}
		return (
			<div className="item outgoing" style={ { background: "unset" } } >
				<Translation
					textId="chat.message.file.sending.text"
					params={ [ name, ( sent / 1024 ) | 0, ( total / 1024 ) | 0 ] }
				/>
			</div>
		);
	}
}

class MessageSendingImageView extends React.Component {
	render() {
		let { contact, message, status, className } = this.props;
		let { name, sent, total, error, thumbnailBase64 } = message;
		if ( error ) {
			return (
				<div className="item outgoing" style={ { background: "unset" } }>
					<span className="error">
						<Translation textId="chat.message.file.sending.error" />
					</span>
				</div>
			);
		}
		return (
			<div className="item outgoing" style={{background: "unset"}}>
				<img src={`data:image/${thumbnailBase64}`} style={{maxWidth: "100%"}}/>
				<br />
				<Translation
					textId="chat.message.file.sending.text"
					params={ [ name, ( sent / 1024 ) | 0, ( total / 1024 ) | 0 ] }
				/>
			</div>
		);
	}
}

class MessageSendingAudioView extends React.Component {
	render() {
		let { contact, message, status, className } = this.props;
		let { sent, total, error } = message;
		if ( error ) {
			return (
				<div className="item outgoing" style={{background: "unset"}}>
					<span className="error">
						<Translation textId="chat.message.voice.sending.error" />
					</span>
				</div>
			);
		}
		return (
			<div className="item outgoing" style={ { background: "unset" } }>
				<Translation
					textId="chat.message.voice.sending.text"
					params={ [ ( sent / 1024 ) | 0, ( total / 1024 ) | 0 ] }
				/>
			</div>
		);
	}
}

export default MessageView;
