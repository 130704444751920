import React from "react";
import classNames from "classnames";
import Rx from "rx";
import Clipboard from "clipboard";
import _ from "lodash";

import Qr from "../../../components/qr.jsx";
import Toast from "../../../components/toast.jsx";
import Translation from "../../../components/translation.jsx";
import PromptContacts from "../common/prompt.contacts.jsx";
import Tinput from "../common/tinput.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class AddContactToGroupContentView extends React.Component {
	constructor() {
		super();
		this.state = {
			name: ""
		};
		this.onChangeNickame = this.onChangeNickame.bind( this );
		this.onAddClick = this.onAddClick.bind( this );

		this._service = remoteServiceLocator();
	}

	componentWillMount() {
		let { contact } = this.props;
		this._participantsSubscription = (
			Rx.Observable.combineLatest(
				this._service.observeGroupParticipants( contact ),
				this._service.observeGroupInvites( contact ),
				( ps, invites ) => ( { ps, invites } )
			)
				.subscribe( data => {
					this.setState( data );
				} )
		);
	}

	componentWillUnmount() {
		this._participantsSubscription.dispose();
	}

	onChangeNickame( nickname ) {
		this.setState( { nickname } );
	}

	onAddClick() {
		let { isInProgress, nickname } = this.state;
		let { contact } = this.props;
		if ( isInProgress ) {
			return;
		}
		this.setState( { isInProgress: true } );
		this._service.addGroupParticipantExternalAsync( contact, nickname )
			.subscribe( url => {
				this.setState( { isInProgress: false, url } );
			}, error => {
				console.error( error );
				this.props.onBack();
			} );
	}

	renderWarnings() {
		let { ps, invites, nickname } = this.state;
		if ( !ps || !invites || !nickname ) {
			return null;
		}
		if ( _.find( ps, { nickname } ) ) {
			return <Translation textId="web.group.external.duplicate.warn" />;
		}
		if ( _.find( invites, { nickname } ) ) {
			return <Translation textId="web.group.external.duplicate.invite.warn" />;
		}
	}

	isButtonDisabled() {
		let { ps, invites, nickname } = this.state;
		if ( !this.state.nickname ) {
			return true;
		}
		if ( _.find( ps, { nickname } ) ) {
			return true;
		}
		if ( _.find( invites, { nickname } ) ) {
			return true;
		}
		return false;
	}

	render() {
		if ( !this.state.ps ) {
			return <Translation textId="web.send.contacts.loading.text" />;
		}
		if ( this.state.url ) {
			return <InviteUrlContentView
				className={ this.props.className }
				url={ this.state.url }
				onBack={ this.props.onBack }
			/>;
		}
		return (
			<div className={ this.props.className }>
				<div className="invite">
					<Translation textId="web.group.external.nickname" />
					<br />
					<Tinput
						type="text"
						value={ this.state.nickname }
						onChange={ this.onChangeNickame }
						placeholderTextId="web.group.external.nickname.placeholder"
						style={ { width: "100%" } }
					/>
					<br/>
					{ this.renderWarnings() }
					<br />
					<div className="text-center">
						<button
							className="btn btn-blue"
							onClick={ this.onAddClick }
							disabled={ this.isButtonDisabled() }
						>
							<Translation textId="web.group.external.add.button" />
						</button>
					</div>
				</div>
			</div>
		);
	}

  static get propTypes() {
    return {
      onBack: React.PropTypes.func.isRequired
    };
  }
}

class InviteUrlContentView extends React.Component {
	componentDidMount() {
		this._clipboard = new Clipboard( "#copy" );
		this._clipboard.on( "success", event => {
			this.refs.copied.show();
			event.clearSelection();
		} );
		this._clipboard.on( "error", event => {
			this.refs.copy_failed.show();
		} );
	}

	componentWillUnmount() {
		this._clipboard.destroy();
	}

	render() {
		return (
			<div className={this.props.className} >
				<div className="message-code">
					<p>
						<Translation textId="web.invite.text1" />
					</p>
					<Qr code={this.props.url} className="qr-code" />
					<p>
						<Translation textId="web.invite.text2" />
					</p>
					<div className="code" id="invite_token">{this.props.url}</div>
					<div className="links">
						{/*<a href="javascript:;">
							<img src="web/img/ico-43.svg" alt=""/>Отправить
						</a>
						<span> или </span>*/}
						<a
							href="javascript:;"
							id="copy"
							data-clipboard-target="#invite_token"
						>
							<Translation textId="web.invite.copy.button" />
						</a>
					</div>
					<br />
					<div className="text-center">
						<button
							className="btn btn-blue"
							onClick={ this.props.onBack }
						>
							<Translation textId="web.group.external.done.button" />
						</button>
					</div>
				</div>
				<Toast textId="web.invite.copied.toast" ref="copied"/>
				<Toast textId="web.invite.copy_failed.toast" ref="copy_failed"/>
			</div>
		);
	}
}

export default AddContactToGroupContentView;
