import ssgCrypto,{Key,KEY_KINDS,Config} from "ssg.crypto";
import ClientServerOneWay from "./client.server.oneway.js";
import Transaction from "./transaction.js";
import {key2StringAsync} from "../../common/utils.js";
import {serializeMessageToManagedBufferThen} from "../../api/models/message/technical.js";

class OneWaySender {
	constructor( apiUrlBase, connectionId, dhPubKey, encryptionSaltKey, macSaltKey, econfig ) {
		if ( !( dhPubKey instanceof Key ) ) {
			throw new Error( "dhPubKey must be a key" );
		}
		if ( !( encryptionSaltKey instanceof Key ) ) {
			throw new Error( "encryptionSaltKey must be a key" );
		}
		if ( !( macSaltKey instanceof Key ) ) {
			throw new Error( "macSaltKey must be a key" );
		}
		if ( dhPubKey.kind !== KEY_KINDS.KEY_EXCHANGE_PUBLIC ) {
			throw new Error( "dhPubKey must be KEY_EXCHANGE_PUBLIC" );
		}
		if ( encryptionSaltKey.kind !== KEY_KINDS.INTERMEDIATE ) {
			throw new Error( "encryptionSaltKey must be INTERMEDIATE" );
		}
		if ( macSaltKey.kind !== KEY_KINDS.INTERMEDIATE ) {
			throw new Error( "macSaltKey must be INTERMEDIATE" );
		}
		if ( !( econfig instanceof Config ) ) {
			throw new Error( "econfig must be Config" );
		}
		this._connection = new ClientServerOneWay( apiUrlBase, connectionId, false );
		this._dhPubKey = dhPubKey;
		this._encryptionSaltKey = encryptionSaltKey;
		this._macSaltKey = macSaltKey;
		this._econfig = econfig;
		this._connectionId = connectionId;
	}

	sendMessageAsync( message ) {
		return Rx.Observable.fromPromise(
			ssgCrypto.createRandomKeyExchangeKeyPairThen( this._econfig )
				.then( ( { privateKey, publicKey } ) =>
					ssgCrypto.createSharedKeyThen( privateKey, this._dhPubKey, this._econfig )
						.then( sharedKey => Promise.all( [
							this._makeEncryptionKeyThen( sharedKey ), //SYMM
							this._makeMacKeyThen( sharedKey ), //MAC
							serializeMessageToManagedBufferThen( message )
						] ) )
						.then( ( [ encryptionKey, macKey, contentMB ] ) =>
							this._constructEncryptedMessageThen( publicKey, encryptionKey, macKey, contentMB )
								.then( () => {
									encryptionKey.dispose();
									macKey.dispose();
									contentMB.dispose();
									publicKey.dispose();
									privateKey.dispose();
								} )
						)
				)
		);
	}

	_constructEncryptedMessageThen( ephemeralPubKey, encryptionKey, macKey, contentMB ) {
		return (
			ssgCrypto.encryptThen( contentMB, encryptionKey, true, this._econfig )
				.then( encrypted => new Promise( resolve => {
					ephemeralPubKey.postponeManagedBuffer( mb => mb.useAsBuffer( keyBuffer => {
						if ( keyBuffer.length !== this._econfig.getKeySize( KEY_KINDS.KEY_EXCHANGE_PUBLIC ) ) {
							throw new Error( "DH Public Key mismatch" );
						}
						resolve( { keyBuffer: Buffer.concat( [ keyBuffer ] ), encrypted } );
					} ) );
				} ) )
				.then( ( { encrypted, keyBuffer } ) =>
					ssgCrypto.makeHmacCodeThen( macKey, encrypted, this._econfig )
						.then( mac => Buffer.concat( [ keyBuffer, encrypted, mac ] ) )
				)
				.then( body => new Promise( ( resolve, reject ) => {
					Transaction.runWithRetriesAsync( transaction =>
						this._connection.send( {
							toHash: this._connectionId,
							body: body.toString( "base64" )
						}, transaction )
					).subscribe( resolve, reject );
				} ) )
		);
	}

	_makeEncryptionKeyThen( sharedKey ) {
		return (
			ssgCrypto.createDerivedKeyFromKeysThen(
				sharedKey,
				this._encryptionSaltKey,
				KEY_KINDS.SYMMETRIC_ENCRYPTION,
				this._econfig
			)
		);
	}

	_makeMacKeyThen( sharedKey ) {
		return (
			ssgCrypto.createDerivedKeyFromKeysThen(
				sharedKey,
				this._macSaltKey,
				KEY_KINDS.MAC,
				this._econfig
			)
		);
	}

	dispose( ) {
		this._connection.dispose();
	}
}

export default OneWaySender;
