import _ from "lodash";
import ssgCrypto, {KEY_KINDS} from "ssg.crypto";

import HistorySyncConnection from "./history.sync.connection.js";
import Transaction from "./transaction.js";

const PAGE_SIZE = 10;
const MIN_INTERVAL = 1000;
const MAX_INTERVAL = 600000;

function getConnectionId( pid1, pid2 ) {
	if ( pid1 > pid2 ) {
		return getConnectionId( pid2, pid1 );
	}
	return `hs_${pid1}_${pid2}`;
}

class HistorySynchronizer {
	constructor( multidescription, multicast, contactsService ) {
		this._multidescription = multidescription;
		this._multicast = multicast;
		this._contactsService = contactsService;

		this._connectionsPerPid = Object.create( null );
		this._requestRangesPerContact = Object.create( null );
		this._syncInfoPerConnection = Object.create( null );
		this._initSubscriptionsPerContact = Object.create( null );
		this._interval = MIN_INTERVAL;
		this._continueSync = this._continueSync.bind( this );
		this._syncTimeout = null;
		this._contactsSubscription = (
			Rx.Observable.combineLatest(
				contactsService.observeContactList(),
				multicast.observeParticipantsLatest()
					.map( ps => this.getParticipantsToConnect( ps ) ),
				( contacts, participants ) => ( { contacts, participants } )
			)
				.subscribe( ( { contacts, participants } ) => {
					this.onUpdated( contacts, participants );
				} )
		);

		//TODO: remove
		// this._multicast = multicast;
		// this._pid = multicast.getPid();
		// this._connections = Object.create( null );
		// this._historyContainer = historyContainer;
		// this._syncInfoPerConnection = Object.create( null );
		// this._interval = MIN_INTERVAL;
		// this._contact = contact;
		// this._subscribeToParticipants();
		// this._initSubscription = (
		// 	multicast.getMessageMaxIndexAsync()
		// 		.flatMap( toIndex => this._historyContainer.getUnsetRangesAsync( fromIndex, toIndex ) )
		// 		.subscribe( ranges => {
		// 			this._requestRanges = ranges;
		// 			this._continueSync();
		// 		} )
		// 	);
	}

	setSyncTimeout() {
		if ( this._syncTimeout ) {
			return;
		}
		this._syncTimeout = setTimeout( this._continueSync, this._interval );
	}

	getParticipantsToConnect( allPs ) {
		let aliasPs = Object.create( null );
		let selfPid = this._multicast.getPid();
		for ( let pid in allPs ) {
			if ( pid !== selfPid ) {
				aliasPs[ pid ] = allPs[ pid ];
			}
		}
		return aliasPs;
	}

	onUpdated( contacts, participants ) {
		let filteredContacts = _.filter(
			contacts,
			contact => contact.hasDetails() &&
				contact.multidescriptionId === this._multidescription.id &&
				contact.status === "active"
		);
		this._connectParticipants( participants );
		this._watchContacts( filteredContacts );
	}

	_connectParticipants( participants ) {
		let { econfig, apiUrlBase, privateKey } = this._multicast;
		let selfPid = this._multicast.getPid();
		let needResetTimers = false;

		_.forEach( participants, ( { publicKey, isExited }, pid ) => {
			if ( this._connectionsPerPid[ pid ] || isExited || pid === selfPid ) {
				return;
			}
			needResetTimers = true;
			let connectionSubj = new Rx.ReplaySubject();
			this._connectionsPerPid[ pid ] = connectionSubj;

			Promise.all( [
				ssgCrypto.createKeyFromBufferThen( new Buffer( 32 ).fill( 1 ), KEY_KINDS.INTERMEDIATE, econfig ),
				ssgCrypto.createKeyFromBufferThen( new Buffer( 32 ).fill( 2 ), KEY_KINDS.INTERMEDIATE, econfig ),
				ssgCrypto.createVerifierThen( publicKey, econfig ),
			] )
				.then( ( [ seedMacKey, seedEncryptionKey, verifier ] ) => {
					if ( !this._connectionsPerPid[ pid ] ) {
						return;
					}
					let connection = new HistorySyncConnection(
						apiUrlBase,
						getConnectionId( this._multicast.getPid(), pid ),
						seedMacKey,
						seedEncryptionKey,
						ssgCrypto.createSigner( privateKey, econfig ),
						verifier,
						econfig,
						this._contactsService,
						this._multidescription.id
					);
					connectionSubj.onNext( connection );
					connectionSubj.onCompleted();
					this._onCreatedConnection( pid, connection );
				} );
		} );

		for ( let pid in this._connectionsPerPid ) {
			if ( participants[ pid ] && !participants[ pid ].isExited ) {
				continue;
			}
			this._connectionsPerPid[ pid ].subscribe( hc => hc.dispose() );
			delete this._connectionsPerPid[ pid ];
		}

		if ( needResetTimers ) {
			this._interval = MIN_INTERVAL;
			this._continueSync();
		}
	}

	_onCreatedConnection( pid, connection ) {
		connection.onPaired( () => {
			let info = this._syncInfoPerConnection[ pid ] = Object.create( null );
			info.isPaired = true;
			info.requestedEmptyRanges = Object.create( null );
		} );
		connection.onUnpaired( () => {
			let info = this._syncInfoPerConnection[ pid ];
			if ( !info ) {
				return;
			}
			info.isPaired = false;
		} );
	}

	_watchContacts( contacts ) {
		_.forEach( contacts, contact => {
			if ( this._initSubscriptionsPerContact[ contact.id ] ) {
				return;
			}
			this._initSubscriptionsPerContact[ contact.id ] = (
				Rx.Observable.combineLatest(
			 		this._contactsService.getMessageMaxIndexOrNullAsync( contact.id )
						.filter( index => index !== null ),
					this._contactsService.getHistoryContainerAsync( contact.id ),
					( toIndex, historyContainer ) => ( { toIndex, historyContainer } )
				)
			 		.flatMap( ( { toIndex, historyContainer } ) => {
						return historyContainer.getUnsetRangesAsync(
							contact.historyFromIndex | 0, toIndex
						)
					} )
			 		.subscribe( ranges => {
			 			this._requestRangesPerContact[ contact.id ] = ranges;
			 			this._continueSync();
			 		} )
			);
		} );
		for ( let cid in this._initSubscriptionsPerContact ) {
			if ( !_.find( contacts, ( { id } ) => id == cid ) ) {
				this._initSubscriptionsPerContact[ cid ].dispose();
				delete this._initSubscriptionsPerContact[ cid ];
				delete this._requestRangesPerContact[ cid ];
			}
		}
	}

	_continueSync( ) {
		this._syncTimeout = null;
		if ( this._isDisposed ) {
			return;
		}
		for ( let pid in this._syncInfoPerConnection ) {
			let info = this._syncInfoPerConnection[ pid ];
			if ( info.syncInProgress || !info.isPaired ) {
				this._interval = MIN_INTERVAL;
				this.setSyncTimeout();
				return;
			}
		}

		let earliestRequestPeriod = 0;
		let earliestRequestPid;
		for ( let pid in this._syncInfoPerConnection ) {
			let info = this._syncInfoPerConnection[ pid ];
			let period = new Date - ( info.lastRequestTime | 0 );
			if ( earliestRequestPeriod < period ) {
				earliestRequestPeriod = period;
				earliestRequestPid = pid;
			}
		}
		if ( !earliestRequestPid ) {
			this._interval = Math.min( MAX_INTERVAL, this._interval * 2 );
			this.setSyncTimeout();
			return;
		}
		this._startSyncWith( earliestRequestPid );
	}

	_startSyncWith( earliestRequestPid ) {
		let info = this._syncInfoPerConnection[ earliestRequestPid ];
		if ( !info ) {
			this.setSyncTimeout();
			return;
		}
		let connectionAsync = this._connectionsPerPid[ earliestRequestPid ];
		if ( !connectionAsync ) {
			this.setSyncTimeout();
			return;
		}
		info.syncInProgress = true;
		info.lastRequestTime = +new Date;
		connectionAsync.subscribe( connection => {
			let isAnyDataToSync = false;
			if ( this._isDisposed ) {
				info.syncInProgress = false;
				return;
			}

			_.forEach( this._requestRangesPerContact, ( requestRanges, scid ) => {
				if ( !requestRanges.length ) {
					return;
				}
				if ( _.every(
					requestRanges,
					( { fromIndex, toIndex } ) => info.requestedEmptyRanges[ `${fromIndex}_${toIndex}` ]
				) ) {
					return;
				}
				this.doSyncContact( scid | 0, info, requestRanges, connection );
				isAnyDataToSync = true;
			} );
			if ( isAnyDataToSync ) {
				this.setSyncTimeout();
			} else {
				info.syncInProgress = false;
			}
		} );
	}

	doSyncContact( contactId, info, requestRanges, connection ) {
		let isSuccessRequested = false;
		this._contactsService.getDetailedContactAsync( contactId )
			.flatMap( contact =>
				contact
				? Rx.Observable.fromArray( requestRanges )
					.filter( ( { fromIndex, toIndex } ) => !info.requestedEmptyRanges[ `${fromIndex}_${toIndex}` ] )
					.map( range => ( { contact, ...range } ) )
				: Rx.Observable.throw( new Error( "No contact found" ) )
			)
			.concatMap( ( { fromIndex, toIndex, contact } ) => {
				return (
					connection.queryHistoryItemsAsync( fromIndex, toIndex, contact )
						.map( newIndexValues => this.recalcRanges( newIndexValues, fromIndex, toIndex ) )
						.catch( error => {
							console.error( "Error during querying history", error );
							return Rx.Observable.empty();
						} )
						.tap( ( { newIndexValues } ) => {
							if ( _.isEmpty( newIndexValues ) ) {
								info.requestedEmptyRanges[ `${fromIndex}_${toIndex}` ] = true;
							} else {
								isSuccessRequested = true;
							}
						} )
				);
			} )
			.reduce( ( { ranges, indexValues }, { newIndexValues, newRanges } ) => {
				return {
					ranges: ranges.concat( newRanges ),
					indexValues: { ...newIndexValues, ...indexValues }
				};
			}, { ranges: [], indexValues: Object.create( null ) } )
			.flatMap( ( { ranges, indexValues } ) =>
				this._contactsService.getHistoryContainerAsync( contactId )
					.map( historyContainer => ( { ranges, indexValues, historyContainer } ) )
			)
			.flatMap( ( { ranges, indexValues, historyContainer } ) =>
				this.updateHistoryItemsAsync( indexValues, historyContainer )
					.map( () => ranges )
			)
			.subscribe( ranges => {
				this._requestRangesPerContact[ contactId ] = ranges;
				info.syncInProgress = false;
				if ( isSuccessRequested ) {
					this._interval = MIN_INTERVAL;
				}
			}, error => {
				//TODO: check that contact is just deleted
			} );
	}

	recalcRanges( newIndexValues, fromIndex, toIndex ) {
		let newRanges = [];
		let currFrom = fromIndex;
		for( let index = fromIndex; index <= toIndex; index++ ) {
			if ( !newIndexValues[ index ] ) {
				continue;
			}
			if ( currFrom < index ) {
				newRanges.push( { fromIndex: currFrom, toIndex: index - 1 } );
			}
			currFrom = index + 1;
		}
		if ( currFrom <= toIndex ) {
			newRanges.push( { fromIndex: currFrom, toIndex } );
		}
		return { newIndexValues, newRanges };
	}

	updateHistoryItemsAsync( indexValues, historyContainer ) {
		return Rx.Observable.create( observer => {
			let leftCalls = 0;
			for( let index in indexValues ) {
				let value = indexValues[ index ];
				leftCalls++;
				Transaction.runWithRetriesAsync( transaction => {
					//index is a string here but we need number
					historyContainer.updateItemThen( index|0, value, transaction );
				} ).subscribe( () => {
					leftCalls--;
					if ( !leftCalls ) {
						observer.onNext();
						observer.onCompleted();
					}
				} );
			}
			if ( !leftCalls ) {
				observer.onNext();
				observer.onCompleted();
			}
		} );
	}

	dispose( ) {
		if ( !this._isDisposed ) {
			throw new Error( "Already disposed" );
		}
		this._isDisposed = true;
		// this._initSubscription.dispose();
		// this._participantsSubscription && this._participantsSubscription.dispose();
		// this._historyContainer.dispose();
		for ( let pid in this._connectionsPerPid ) {
			this._connectionsPerPid[ pid ].subscribe( hc => hc.dispose() );
			delete this._connectionsPerPid[ pid ];
		}
	}
}

export default HistorySynchronizer;
