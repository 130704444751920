import _ from "lodash";

import MessageReceiver from "../models/message/message.receiver.js";
import MessageDeserializer from "../models/message/message.deserializer.js";
import rxHelper from "../../common/helpers/rx.helper.js";
import translate from "../../browser/translations/translate.js";
import BinaryStream from "../models/binary.stream.js";
const CHUNK_SIZE = 8192;

function truncateAsync( writer, size ) {
	return Rx.Observable.create( ( observer ) => {
		writer.onwriteend && observer.onError( new Error( "!!writer.onwrite" ) );
		writer.onerror && observer.onError( new Error( "!!writer.onerror" ) );

		writer.onwriteend = ( event ) => {
			writer.onwriteend = null;
			writer.onerror = null;
			observer.onNext( writer );
			observer.onCompleted();
		};

		writer.onerror = ( error ) => {
			writer.onwriteend = null;
			writer.onerror = null;
			observer.onError( error );
		};
		writer.truncate( size );
	} );
}

function writeAsync( writer, buffer ) {
	let ab = new ArrayBuffer( buffer.length );
	let ui8a = new Uint8Array( ab );
	// buffer.copy( ui8a );
	for( let i = 0; i < buffer.length; i++ ) {
		ui8a[ i ] = buffer[ i ];
	}
	return Rx.Observable.create( ( observer ) => {
		writer.onwriteend && observer.onError( new Error( "!!writer.onwrite" ) );
		writer.onerror && observer.onError( new Error( "!!writer.onerror" ) );

		writer.onwriteend = ( event ) => {
			writer.onwriteend = null;
			writer.onerror = null;
			observer.onNext();
			observer.onCompleted();
		};

		writer.onerror = ( error ) => {
			writer.onwriteend = null;
			writer.onerror = null;
			observer.onError( error );
		};
		writer.write( ab );
	} );
}

class DonwloadService {
	constructor( ) {
		if ( global.requestFileSystem ) {
			this._rxRequestFileSystem = rxHelper.doubleCallbackToObservable(
				global.requestFileSystem, global
			);
			this._rxResolveLocalFileSystemURL = rxHelper.doubleCallbackToObservable(
				global.resolveLocalFileSystemURL, global
			);
		}
		this._downloadStateSubjs = Object.create( null );
	}

	_getFileAsync( fileName, options ) {
		return (
			this._rxRequestFileSystem( LocalFileSystem.PERSISTENT, 0 )
				.flatMap( () => this._rxResolveLocalFileSystemURL(
					cordova.file.externalRootDirectory || cordova.file.documentsDirectory
				) )
				.flatMap( deRoot => rxHelper.doubleCallbackToObservable( deRoot.getDirectory, deRoot )
					( "Downloads", { create: true } )
				)
				.flatMap( de => rxHelper.doubleCallbackToObservable( de.getFile, de )
					( fileName, options )
				)
		);
	}

	isFileExistsAsync( fileName ) {
		return (
			this._getFileAsync( fileName, { create: false, exclusive: false } )
				.map( () => true )
				.catch( error => {
					if ( error.code === FileError.NOT_FOUND_ERR ) {
						return Rx.Observable.just( false );
					}
					console.error( `Strange file error ${fileName}: ${error.code}` );
					return Rx.Observable.just( false );
				} )
		);
	}

	saveFileAsync( binaryStream, fileName ) {
		let writer;
		let startAt = +new Date;
		let chunkCount = ( ( binaryStream.size + CHUNK_SIZE - 1 ) / CHUNK_SIZE ) | 0;
		return (
			this._getFileAsync( fileName, { create: true, exclusive: false } )
				.flatMap( fe => rxHelper.doubleCallbackToObservable( fe.createWriter, fe )() )
				.tap( w => { writer = w; } )
				.flatMap( () => truncateAsync( writer, 0 ) )
				.flatMap( () => Rx.Observable.range( 0, chunkCount ) )
				.concatMap( chunkIndex =>
					Rx.Observable.defer( () => {
						let size = Math.min( CHUNK_SIZE, binaryStream.size - binaryStream.position );
						let subj = new Rx.ReplaySubject();
						binaryStream.getNextBytesCb(
							size,
							chunk => {
								writeAsync( writer, chunk )
									.subscribe( res => {
											subj.onNext( {
												total: binaryStream.size,
												wrote: binaryStream.position
											} );
											subj.onCompleted();
										},
										error => {
											console.warn( "Write file error", error );
											subj.onError( error );
										}
									);
							}
						);
						return subj;
					} )
				)
		);
	}

	getFilePath( fileName ) {
		if ( ~fileName.indexOf( '/' ) || ~fileName.indexOf( '\\' ) || ~fileName.indexOf( '"' ) || ~fileName.indexOf( '\'' ) || ~fileName.indexOf( ':' ) ) {
			alert( "Invalid FileName: " + fileName );
			return;
		}
		return `Downloads/${fileName}`;
	}

	openDownloadedFile( fileName, contentType ) {
		if ( ~fileName.indexOf( '/' ) || ~fileName.indexOf( '\\' ) || ~fileName.indexOf( '"' ) || ~fileName.indexOf( '\'' ) || ~fileName.indexOf( ':' ) ) {
			alert( "Invalid FileName: " + fileName );
			return;
		}
		this._getFileAsync( fileName, { create: false, exclusive: false } )
			.subscribe( fe => {
				let path = decodeURIComponent( fe.toURL() );
				if ( !path ) {
					console.error( "No file created" );
					return;
				}
				if ( _.get( global, "cordova.plugins.fileOpener2" ) ) {
					global.cordova.plugins.fileOpener2.open(
						path,
						contentType,
						{
							error: function( errorObj ) {
								// if ( _.get( global, "cordova.InAppBrowser.open" ) ) {
								// 	global.cordova.InAppBrowser.open( path, "_system" );
								// 	return;
								// }
								console.error( 'Error opening file', errorObj );
								alert(translate("chat.message.file.open.error", fe.path));
							},
							success : function () {}
						}
					);
					return;
				}

				if ( _.get( global, "cordova.InAppBrowser.open" ) ) {
					global.cordova.InAppBrowser.open( path, "_system" );
					return;
				}
				window.open( path, "_blank" );
			} );
	}

	isInitialized( ) {
		return !!global.requestFileSystem;
	}

	removeFileDownloadProgress( message ) {
		if ( this._downloadStateSubjs[ message.id ] ) {
			delete this._downloadStateSubjs[ message.id ];
		}
	}

	downloadFileAsBufferThen( message ) {
		if ( !message.id ) {
			throw new Error( "Message id required" );
		}
		if ( !this._downloadStateSubjs[ message.id ] ) {
			this._downloadStateSubjs[ message.id ] = new Rx.BehaviorSubject( {
				total: 0,
				wrote: 0
			} );
		}
		let stateSubj = this._downloadStateSubjs[ message.id ];

		return (
			new MessageReceiver( { token: message.fileToken } )
				.getStreamFactoryThen( "", function() {
					stateSubj.onNext( { total: this.size, wrote: this.position } );
				} )
				.then( streamFactory => new MessageDeserializer( streamFactory ).getThen() )
				.then( fileMessage => {
					if ( fileMessage.getAttachments().length === 0 ) {
						throw new Error( "No attachments found" );
					}
					return fileMessage.getAttachments()[ 0 ].createBinaryStream( "buffered" );
				} )
				.then( fileBinaryStream => new Promise( resolve => {
					fileBinaryStream.getAllBytesCb( attachmentBuffer => {
						console.log( "Got file stream buffer", attachmentBuffer );
						resolve( attachmentBuffer );
					} );
				} ) )
			);
		}

	startDownloadFile( message ) {
		if ( !message.id ) {
			throw new Error( "Message id required" );
		}
		if ( !this._downloadStateSubjs[ message.id ] ) {
			this._downloadStateSubjs[ message.id ] = new Rx.BehaviorSubject( {
				total: 0,
				wrote: 0
			} );
		}
		let stateSubj = this._downloadStateSubjs[ message.id ];
		new MessageReceiver( { token: message.fileToken } )
			.getStreamFactoryThen( "" )
			.then( streamFactory => new MessageDeserializer( streamFactory ).getThen() )
			.then( fileMessage => {
				if ( fileMessage.getAttachments().length === 0 ) {
					throw new Error( "No attachments found" );
				}
				return fileMessage.getAttachments()[ 0 ].createBinaryStream( "buffered" );
			} )
			.then( fileBinaryStream => {
				this.saveFileAsync(
					fileBinaryStream,
					message.fileName,
					message.contentType
				)
				.subscribe( state => {
					stateSubj.onNext( state );
				}, error => {
					stateSubj.onNext( { error } );
				}, () => {
					stateSubj.onNext( { inactive: true } );
				} )
			} );
	}

	observeDownloadState( message ) {
		if ( !this._downloadStateSubjs[ message.id ] ) {
			this._downloadStateSubjs[ message.id ] = new Rx.BehaviorSubject( {
				inactive: true
			} );
		}
		return this._downloadStateSubjs[ message.id ];
	}

	stopDownload( message ) {
		throw new Error( "Not implemented" );
	}
}

export default DonwloadService;
