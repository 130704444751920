import React from "react";

import TestView from "../test.jsx";
import CreateMessageView from "../message/create/index.jsx";
import ReadMessageView from "../message/read/index.jsx";
import history from "../../components/history.js";
import RegistrationView from "../registration/index.jsx";
import LoginView from "../login/login.jsx";
import ContactsView from "../contacts/index.jsx";
import GroupsView from "../groups/index.jsx";
import OptionsView from "../options/index.jsx";
import ShakeView from "../shake/index.jsx";
import DefaultView from "../default.jsx";

import UpdatesView from "../admin/updates.jsx";
import LoggedIn from "./loggedin.jsx";
import FullScreen from "../../components/fullscreen.jsx";

class Router extends React.Component {
	renderMessangerView() {
		//<FullScreen>
		let segments = this.props.path;
		switch ( segments[ 0 ] ) {
			case "contacts":
				return <LoggedIn><ContactsView /></LoggedIn>;
			case "groups":
				return <LoggedIn><GroupsView /></LoggedIn>;
			case "options":
				return <LoggedIn><OptionsView page={segments[1]}/></LoggedIn>;
			case "registration":
				return <RegistrationView />;
			case "login":
				return <LoginView />;
			case "shake":
				return <ShakeView/>;
		}
		return <DefaultView />;
	}

	render() {
		let segments = this.props.path;

		switch ( segments[ 0 ] ) {
			case "message":
				switch ( segments[ 1 ] ) {
					case "create":
						return <CreateMessageView />;
					case "read":
						return <ReadMessageView token={ segments[ 2 ] } />;
				}
			case "test":
				return <TestView />;
			case "updates":
				return <UpdatesView/>;
		}

		return (
			<FullScreen>
				{ this.renderMessangerView() }
			</FullScreen>
		);
	}
}

export default Router;
