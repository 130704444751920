import React from "react";
import _ from "lodash";
import FullScreen from "../../components/fullscreen.jsx";
import TopLevel from "../common/top.level.view.jsx";
import MenuBody from "../common/modal.menu.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import Translation from "../../components/translation.jsx";
import Rename from "../groups/rename.jsx";
import {ModalMenuItem, MenuItem} from "../common/modal.menu.jsx";
import {Confirm} from "../../components/dialogs.jsx"
import ImageButton from "../../components/button.image.jsx";
import PromptParticipants from "../groups/prompt.participants.jsx";
import AddExternalParticipant from "../groups/add.external.jsx";
import Participants from "../groups/participant.list.jsx";
import OnlineStatus from "../../components/online.status.jsx"
import {openExternalView, closeExternalView} from "../../../common/lifecycle_observer.js"

import serviceLocator from "../../../api/services/locators/worker.client.js";

const MAX_FILE_SIZE_KB = 1024 * 1024 * 1024;

class ChatMenuView extends React.Component {
	static get propTypes() {
		return {
			onBack: React.PropTypes.func,
			contact: React.PropTypes.object.isRequired
		};
	}

	static get defaultProps() {
		return {
			onBack: _.noop
		};
	}

	constructor() {
		super();
		this.onClearSelect = this.onClearSelect.bind( this );
		this.onDeleteSelect = this.onDeleteSelect.bind( this );
		this.onInviteSelect = this.onInviteSelect.bind( this );
		this.onChoseInvite = this.onChoseInvite.bind( this );
		this.onParticipants = this.onParticipants.bind( this );
		this.onCloseParticipants = this.onCloseParticipants.bind( this );
		this.doDelete = this.doDelete.bind( this );
		this.doClear = this.doClear.bind( this );
		this.onSendFilesSelect = this.onSendFilesSelect.bind( this );
		this.inputOnChange = this.inputOnChange.bind( this );
		this.onInviteExternalSelect = this.onInviteExternalSelect.bind( this );
		this.onInviteExternal = this.onInviteExternal.bind( this );
		this.onSendPhoto = this.onSendPhoto.bind( this );

		this.state = {};
		this._service = serviceLocator();
		this.fileExtViewIds = [];
	}

	componentWillUnmount() {
	}

	onClearSelect() {
		this.refs.confirmClear.show();
	}

	doClear() {
		this._service.clearHistoryAsync( this.props.contact ).subscribe();
		this.props.onBack();
	}

	onDeleteSelect() {
		this.refs.confirmDelete.show();
	}

	doDelete() {
		this._service.exitGroupAsync( this.props.contact )
			.subscribe( () => {
				this.props.onBack();
			} );
	}

	onInviteSelect() {
		this.setState( {
			showInviteSelect: true
		} );
	}

	onInviteExternalSelect() {
		this.setState( {
			showInviteExternalSelect: true
		} );
	}

	onInviteExternal() {
		this.setState( {
			showInviteExternalSelect: false
		} );
	}

	onChoseInvite( participantIds ) {
		let group = this.props.contact;
		let progressSubj = new Rx.BehaviorSubject( 0 );
		if ( !participantIds ) {
			this.setState( { showInviteSelect: false } );
			return;
		}
		this.setState( { showAddProgress: true } );

		this._service.addGroupParticipantsAsync( group, participantIds )
			.subscribe( () => {
				this.setState( {
					showInviteSelect: false,
					showAddProgress: false
				} );
			} );

		return progressSubj;
	}

	onParticipants() {
		this.setState( {
			showParticipants: true
		} );
	}

	onCloseParticipants() {
		this.setState( {
			showParticipants: false
		} );
	}

	showFullscreenView() {
		if ( this.state.showAddProgress ) {
			return <AddProgress />;
		}
		if ( this.state.showInviteSelect ) {
			return <PromptParticipants onDone={ this.onChoseInvite } />;
		}

		if ( this.state.showInviteExternalSelect ) {
			return <AddExternalParticipant
				contact={ this.props.contact }
				onDone={ this.onInviteExternal }
			/>;
		}

		if ( this.state.showParticipants ) {
			return <Participants
				contact={this.props.contact}
				onDone={ this.onCloseParticipants }
			/>;
		}
	}

	onSendFilesSelect() {
		this.fileExtViewIds.push(openExternalView());
		this.refs.input.click();
	}

	onSendPhoto() {
		if (!navigator.camera || !navigator.camera.getPicture) {
			return this.onSendFilesSelect();
		}
		const extViewId = openExternalView();
		navigator.camera.getPicture( dataBase64 => {
			try {
				let buf = new Buffer( dataBase64, "base64" );
				this._service.sendFileAsync(
					this.props.contact,
					new Blob( [ buf ], { type: "image/jpeg" } ),
					{ name: "photo.jpg" }
				).subscribe();
				closeExternalView(extViewId);
				this.props.onBack();
			} catch(error) {
				alert("Error taking photo: " + error.message);
			}
		}, error => {
			closeExternalView(extViewId);
			console.error(error);
			alert("Error making photo: " + error.message);
		}, { destinationType: /*DATA_URL*/0, saveToPhotoAlbum: false } );
	}

	inputOnChange() {
		this.fileExtViewIds.forEach(closeExternalView);
		let files = this.refs.input.files;
		let fileArray = [];
		for( let i = 0; i < files.length; i++ ) {
			fileArray.push( files[ i ] );
		}
		let isAlerted = false;
		Rx.Observable.fromArray( fileArray )
			.concatMap( file => {
				if ( file.size > MAX_FILE_SIZE_KB * 1024 ) {
					isAlerted || alert( `You can send file of size up to ${MAX_FILE_SIZE_KB} kb in this version of app.` );
					isAlerted = true;
					return Rx.Observable.empty();
				}
				return this._service.sendFileAsync( this.props.contact, file );
			} )
			.toArray()
			.subscribe();
		this.props.onBack();
	}

	renderSendFileMenuItem() {
		return (
			<MenuItem
				titleTextId="chat.menu.send.files"
				icon="images/icon-doc.svg"
				iconClass="window"
				onClick={this.onSendFilesSelect}
				id="menu-send-files"
			/>
		);
	}

	renderSendPhotoMenuItem() {
		if ( global.device && ( global.device.platform !== "Android" ) ) {
			return;
		}
		return (
			<MenuItem
				titleTextId="chat.menu.send.photo"
				icon="images/icon-doc.svg"
				iconClass="window"
				onClick={this.onSendPhoto}
				id="menu-send-photo"
			/>
		);
	}

	render() {
		let { onBack, contact }  = this.props;
		return (
			<TopLevel>
				<header>
					<Translation textId="contacts.menu.header"/>
					<ImageButton type="icon-header-close" onClick={onBack}/>
					<OnlineStatus/>
				</header>
				<main>
					<MenuBody>
						<MenuItem
							titleTextId="chat.group.menu.clear"
							icon="images/icon-menu-trash.svg"
							iconClass="trash"
							onClick={this.onClearSelect}
						/>
						<MenuItem
							titleTextId="chat.group.menu.delete"
							icon="images/icon-del-user.svg"
							iconClass="trash"
							onClick={this.onDeleteSelect}
						/>
						<MenuItem
							titleTextId="chat.group.menu.invite"
							icon="images/icon-add-user.svg"
							iconClass="trash"
							onClick={this.onInviteSelect}
						/>
						<MenuItem
							titleTextId="chat.group.menu.invite.external"
							icon="images/icon-add-user.svg"
							iconClass="trash"
							onClick={this.onInviteExternalSelect}
						/>
						<ModalMenuItem
								titleTextId="chat.menu.renamegroup"
								icon="images/icon-menu-pen.svg"
								iconClass="pen"
							>
							<Rename contact={contact}/>
						</ModalMenuItem>
						<MenuItem
							titleTextId="chat.group.menu.participants"
							icon="images/icon-menu-window.svg"
							iconClass="window"
							onClick={this.onParticipants}
						/>
						{this.renderSendFileMenuItem()}
						{this.renderSendPhotoMenuItem()}
					</MenuBody>
				</main>
				<DeviceBackButton onPress={onBack}/>
				{ this.showFullscreenView() }
				<Confirm
					ref="confirmDelete"
					textId="chat.group.delete.confirm"
					buttonTextIds={["chat.group.delete.confirm.button.yes"]}
					onConfirm={this.doDelete}
				/>
				<Confirm
					ref="confirmClear"
					textId="chat.clear.confirm"
					buttonTextIds={["chat.clear.confirm.button.yes"]}
					onConfirm={this.doClear}
				/>
				<input
					type="file"
					ref="input"
					multiple
					onChange={ this.inputOnChange }
					style={ { visibility: "hidden" } }
				/>
			</TopLevel>
		);
	}
}

class AddProgress extends React.Component {
	render() {
		return (
			<FullScreen>
				<TopLevel>
					<header>
						<span className="header-caption">
							<Translation textId="group.add.progress.header" />
						</span>
						<OnlineStatus/>
					</header>
					<main>
						<span className="small-text">
							<br />
							<Translation textId="group.add.progress.text" />
						</span>
					</main>
				</TopLevel>
			</FullScreen>
		);
	}
}


export default ChatMenuView;
