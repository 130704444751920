import React from "react";
import {openExternalView, closeExternalView} from "../../common/lifecycle_observer.js"

class ButtonQrComponent extends React.Component {
	constructor() {
		super();
		this.takeQrCodeClick = this.takeQrCodeClick.bind( this );
	}
	takeQrCodeClick() {
		//Black screen on iOS at first run if we will not do this
		cordova.plugins.diagnostic.requestCameraAuthorization(
			() => {
				const extViewId = openExternalView();
				cordova.plugins.barcodeScanner.scan(
					result => {
						closeExternalView(extViewId);
						if ( result.cancelled ) {
							console.warn( "Scan cancelled", result );
							return;
						}
						this.props.onScan && this.props.onScan( result.text );
					},
					error => {
						closeExternalView(extViewId);
						alert("Scanning failed: " + error );
					},
					{
						formats : "QR_CODE",
						disableSuccessBeep: true,
						resultDisplayDuration: 0,
						preferFrontCamera: false,
						prompt: "Place a QR code inside the scan area",
						disableAnimations: true,
					}
				);
			}, error => {
				alert("Scanning failed: " + (error.message || error) );
			},
			{}
		);

	}
	hasScannerFunction() {
		return global.cordova && cordova.plugins && cordova.plugins.barcodeScanner && cordova.plugins.barcodeScanner.scan;
	}
	render() {
		if ( !this.hasScannerFunction() ) {
			return null;
		}
		return (
			<img
				className="icon qr-code f-right"
				src="images/qr-code.svg"
				onTouchEnd={ this.takeQrCodeClick  }/>
		);
	}
}

export default ButtonQrComponent;
