import React from "react";
import Switch from "../common/switch.jsx";
import Translation from "../../../components/translation.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class AccountView extends React.Component {
	constructor() {
		super();
		this.state = {
			frozen: false
		};

		this.autoUpdateOnChange = this.switchOnChange.bind( this, "autoUpdate" );
		this.languageOnChange = this.languageOnChange.bind( this );
		this._service = remoteServiceLocator();
	}

	componentWillMount() {
		this._profileSubscription = (
			this._service.observeProfile()
				.subscribe( ({language, autoUpdate}) => {
					this.setState( {
						language,
						autoUpdate: !!autoUpdate
					} );
				} )
		);
	}

	componentWillUnmount() {
		this._profileSubscription.dispose();
	}

	switchOnChange( name, event ) {
		return;
		if ( this.state.frozen ) {
			return;
		}
		this.setState( {
			[name]: event.target.checked,
			frozen: true
		} );
		this._service.updateProfileAsync( {
			[name]: event.target.checked
		} ).subscribe(
			() => {},
			() => {
				this.setState( {frozen: false} );
			}
		);
	}

	languageOnChange( event ) {
		let language = event.target.value;
		this.setState( {
			language,
			frozen: true
		} );
		this._service.updateProfileAsync( {language} ).subscribe(
			() => {},
			() => {
				this.setState( {frozen: false} );
			}
		);
	}

	render() {
		return (
			<div className={this.props.className}>
				<div className="invite">
					{/*TODO: Remove update<div className="title-sm"><Translation textId="web.application.update.head" /></div>
					<p><Translation textId="web.application.update.text" /></p>*/}
					<div className="settings-box">
						{/*<div className="settings-block">
							<p><Translation textId="web.application.update.autoupdate.label" /></p>
							<Switch
								id="autoUpdate"
								checked={this.state.autoUpdate}
								onChange={this.autoUpdateOnChange}
							/>
							<div className="clearfix"></div>
						</div>*/}
						<div className="settings-account">
							{/*TODO: remove update
								<div className="text-center">
								<div className="current"><Translation textId="web.application.update.currentversion" params={["1.0"]}/></div>
								<button className="btn btn-green disabled"><Translation textId="web.application.update.button" /></button>
							</div>
							TODO: implement invite
							<div className="title-sm"><Translation textId="web.application.invite.head" /></div>
							<p><Translation textId="web.application.invite.text" /></p>
							<div className="text-center">
								<button className="btn btn-green disabled"><Translation textId="web.application.invite.button" /></button>
							</div>*/}
							<div className="title-sm"><Translation textId="web.application.language.head" /></div>
							<p><Translation textId="web.application.language.text" /></p>
							<select className="form-control" value={this.state.language} onChange={this.languageOnChange}>
								<option value="ru">Русский</option>
								<option value="en">English</option>
							</select>
							{/*}<div className="title-sm">Подписка и оплата</div>
							<p>Вы приобрели ваучер на 30 дней. Осталось 10 дней до окончании услуги.</p>
							<div className="btn-box text-center">
								<button className="btn btn-blue">активировать код</button>
								<button className="btn btn-green">купить ваучер</button>
							</div>{*/}
						</div>
					</div>
				</div>
 			</div>
		);
	}
}

export default AccountView;
