import Rx from "rx";
import _ from "lodash";
import ssgCrypto, {KEY_KINDS, Config} from "ssg.crypto";
import {
		serializeObjectWithKeysToNewManagedBufferThen,
		deserializeObjectWithKeysFromManagedBufferThen,
		serializeObjectWithKeysToNewManagedBufferAsync,
		deserializeObjectWithKeysFromManagedBufferAsync,
		deserializeJSONOnlyFromManagedBuffer
} from "../../../common/serializer.js";

import {
	sendEncryptedMessageThen,
	receiveMessageThen as receiveMessageStringThen,
	sendEncryptedMessageLocalThen
} from "../../../common/utils.js";

const inviteKeyMap = {
	tmpPrivateKey: KEY_KINDS.SIGNATURE_PRIVATE,
	seedKey: KEY_KINDS.INTERMEDIATE,
	creatorPublicKey: KEY_KINDS.SIGNATURE_PUBLIC,
	metaState: "UNCONTROLLED"
};

const multiinviteKeyMap = {
	dhPubKey: KEY_KINDS.KEY_EXCHANGE_PUBLIC,
	encryptionSaltKey: KEY_KINDS.INTERMEDIATE,
	macSaltKey: KEY_KINDS.INTERMEDIATE
};

const multiinvitePrivateKeyMap = {
	dhPrivKey: KEY_KINDS.KEY_EXCHANGE_PRIVATE,
	encryptionSaltKey: KEY_KINDS.INTERMEDIATE,
	macSaltKey: KEY_KINDS.INTERMEDIATE
};

const backupKeyMap = {
	privateKey: KEY_KINDS.KEY_EXCHANGE_PRIVATE,
	macKey: KEY_KINDS.MAC,
	encryptionKey:KEY_KINDS.SYMMETRIC_ENCRYPTION
};

let keyMapPerType = {
	invite: inviteKeyMap,
	multiinvite: multiinviteKeyMap,
	multiinvitePrivate: multiinvitePrivateKeyMap,
	backup: backupKeyMap,
	users: {},
	createUserSystem: {},
	mailboxReceiver: multiinvitePrivateKeyMap,
	mailboxSender: multiinviteKeyMap,
	activation: multiinviteKeyMap,
	mailboxTokens: multiinviteKeyMap
};

export function deserializeFromBase64StringAsync( str ) {
	return Rx.Observable.fromPromise( deserializeFromBase64StringThen( str ) );
};

export function deserializeFromBase64StringThen( str ) {
	let buf = new Buffer( str, "base64" );
	let mb = ssgCrypto.createManagedBuffer( buf.length );
	mb.useAsBuffer( b => buf.copy( b ) );
	buf.fill( 0 );
	return (
		deserializeFromManagedBufferThen( mb )
			.then( result => {
				mb.dispose();
				return result;
			} )
	);
};

export function deserializeFromManagedBufferAsync( mb, econfig_ ) {
	return Rx.Observable.fromPromise(
		deserializeFromManagedBufferThen( mb, econfig_ )
	);
};

export function deserializeFromManagedBufferThen( mb, econfig_ ) {
	let json = deserializeJSONOnlyFromManagedBuffer( mb );
	let econfig = econfig_ || new Config( json.econfig );
	let keyMap = keyMapPerType[ json.type ] || {};

	return (
		deserializeObjectWithKeysFromManagedBufferThen(
			mb,
			keyMap,
			econfig
		).then( obj => {
			obj.dispose = () => {
				_.forOwn( keyMap, ( value, key ) => {
					obj[ key ].dispose();
				} );
			};
			obj.econfig = econfig;
			return obj;
		} )
	);
};
export function receiveMessageAsync( token, password = "" ) {
	return Rx.Observable.fromPromise(
		receiveMessageThen( token, password )
	);
};

export function receiveMessageThen( token, password = "" ) {
	//TODO: make binary message, do not use base64 conversion
	return (
		receiveMessageStringThen( token, password )
			.then( encodedStr => deserializeFromBase64StringThen( encodedStr ) )
	);
};

export function serializeMessageToBase64StringAsync( message ) {
	return Rx.Observable.fromPromise(
		serializeMessageToBase64StringThen( message )
	);
};

export function serializeMessageToBase64StringThen( message ) {
	return (
		serializeMessageToManagedBufferThen( message )
			.then( inviteMB => new Promise( resolve => {
				inviteMB.useAsBuffer( b => {
					resolve( b.toString( "base64" ) );
				} );
				inviteMB.dispose();
			} ) )
	);
};

export function serializeMessageToManagedBufferAsync( message ) {
	let keyMap = keyMapPerType[ message.type ] || {};
	return serializeObjectWithKeysToNewManagedBufferAsync( message, keyMap );
};

export function serializeMessageToManagedBufferThen( message ) {
	let keyMap = keyMapPerType[ message.type ] || {};
	return serializeObjectWithKeysToNewManagedBufferThen( message, keyMap );
};

export function sendMessageAsync( message, password = "" ) {
	return Rx.Observable.fromPromise( sendMessageThen( message, password ) );
};

export function sendMessageThen( message, password = "" ) {
	if ( !message.econfig ) {
		throw new Error( "econfig required in message" );
	}
	//TODO: Change message format to skip converting sensetive data to text/buffer
	return (
		serializeMessageToBase64StringThen( message )
			.then( encodedStr => sendEncryptedMessageThen( encodedStr, password ) )
	);
};

export function sendMessageLocalAsync( message, password = "" ) {
	return Rx.Observable.fromPromise( sendMessageLocalThen( message, password ) );
};

export function sendMessageLocalThen( message, password = "" ) {
	if ( !message.econfig ) {
		throw new Error( "econfig required in message" );
	}
	//TODO: Change message format to skip converting sensetive data to text/buffer
	return (
		serializeMessageToBase64StringThen( message )
			.then( encodedStr => sendEncryptedMessageLocalThen( encodedStr, password ) )
	);
};
