import React from "react";
import _ from "lodash";

import longTaskServiceLocator from "../../api/services/locators/long.task.js";

import Button from "./button.jsx";
import Translation from "./translation.jsx";

const MAX_RECORD_TIME = 60000;
const WARN_RECORD_TIME = 50000;

if ( !( "toJSON" in Error.prototype ) ) {
  Object.defineProperty( Error.prototype, "toJSON", {
      value: function () {
          var alt = {};

          Object.getOwnPropertyNames( this ).forEach( key => {
              alt[ key ] = this[ key ];
          } );
          alt.message = this.message;
          return alt;
      },
      configurable: true,
      writable: true
  } );
}

function toMMSS( timespan ) {
    var sec_num = Math.floor( timespan / 1000 );
    var minutes = Math.floor( sec_num / 60 );
    var seconds = sec_num - ( minutes * 60 );

    if ( minutes < 10 ) { minutes = "0" + minutes; }
    if ( seconds < 10 ) { seconds = "0" + seconds; }
    return minutes + ":" + seconds;
}

let WarnExpiring = ( { startAt } ) => (
  +new Date - startAt < WARN_RECORD_TIME
  ? null
  : (
    <Translation textId="voice.recording.timeout" params={ [ MAX_RECORD_TIME - (+new Date) + startAt ] } />
  )
);

let Initializing = () => (
  <Translation textId="voice.recording.init" />
);

let Recording = ( { onCancelRecording, onStopRecording, startAt } ) => (
  <div style={ { width: "100%", height: "100%" } }>
    <div style={ { fontSize: "20px", textAlign: "center", padding: "20px" } }>
      <Translation textId="voice.recording.text" />
      <br />
      { toMMSS( +new Date - startAt ) }
    </div>
    <div style={ { fontSize: "14px", textAlign: "center", height: "20px", width: "100%", color: "red" } }>
      <WarnExpiring startAt={ startAt } />
    </div>
    <div style={ { width: "50%", height: "100%", display: "inline-block", padding: "20px" } }>
      <Button
        className="green extra-small"
        caption="voice.recording.cancel"
        handleClick={ onCancelRecording }
      />
    </div>
    <div style={ { width: "50%", height: "100%", display: "inline-block", padding: "20px" } }>
      <Button
        className="green extra-small"
        caption="voice.recording.send"
        handleClick={ onStopRecording }
      />
    </div>
  </div>
);

let ErrorView = ( { error } ) => (
  <div style={{width: "100%", height: "100%", overflow: "scroll"}}>Error: { JSON.stringify( error ) }</div>
);

let Recorded = () => (
  <Translation textId="voice.recording.recorded" />
);

class AudioRecorder extends React.Component {
  constructor() {
    super();
    this.state = {
      status: "initializing"
    };
    this.onCancelRecording = this.onCancelRecording.bind(this);
    this.onStopRecording = this.onStopRecording.bind(this);
    this.onStartedRecord = this.onStartedRecord.bind(this);
    this.onDataAvailable = this.onDataAvailable.bind(this);
    this.onRecordingProgress = this.onRecordingProgress.bind( this );
    this.onError = this.onError.bind( this );
    this._longTaskService = longTaskServiceLocator();
  }

  componentDidMount() {
    if ( !_.get( global, "cordova.plugins.diagnostic" ) ) {
      setTimeout( () => {
        this.initRecorder();
      }, 0);
    } else {
      cordova.plugins.diagnostic.requestMicrophoneAuthorization((status) => {
        if ( !status.hasPermission && status !== "authorized" && status !== "GRANTED" ) {
          this.setState( { status: "error", error: {
            message: "Application do not have permissions for audio"/* + JSON.stringify( status )*/ }
          } );
          return;
        }
        setTimeout( () => {
          this.initRecorder();
        }, 0);
      } );
    }
  }

  initRecorder() {
    try {
      this._longTaskHandle = this._longTaskService.runLongTask( "Recording audio message" );
      cordova.plugins.OggOpusPlugin.startRecord(() => {
        this.onStartedRecord();
      }, error => {
        this.setState( { status: "error", error } );
      });
    }
    catch(error) {
      debugger;
      this.setState( { status: "error", error } );
    }
  }

  stopAllTracks() {
    let { mediastream } = this.state;
    mediastream && mediastream.getTracks().forEach( track => {
      track.stop && track.stop();
    } );
  }

  componentWillUnmount() {
    this._timeout && clearTimeout( this._timeout );
  }

  onRecordingProgress() {
    if ( !cordova.plugins.OggOpusPlugin.isRecording() ) {
      this.props.onRecorded( null );
      return;
    }
    this.forceUpdate();
    if ( +new Date - this.state.startAt >= MAX_RECORD_TIME ) {
      this.onStopRecording();
      return;
    }
    this._timeout = setTimeout( this.onRecordingProgress, 1000 );
  }

  onDoneRecording( ...args ) {
    this._longTaskHandle.dispose();
    this.props.onRecorded( ...args );
  }

  onStartedRecord() {
    this.setState( { status: "recording", startAt: +new Date } );
    this._timeout && clearTimeout( this._timeout );
    this.onRecordingProgress();
  }

  onCancelRecording() {
    cordova.plugins.OggOpusPlugin.stopRecord((msg) => {
    }, error => {
      alert(error.message || (error + ""));
    });
    this.onDoneRecording( null );
  };

  onStopRecording() {
    if ( this.state.status !== "recording" ) {
      console.warn( "Invalid status for stopping recording", this.state.status );
      return;
    }
    let { startAt } = this.state;
    let milliseconds = +new Date - startAt;
    this.setState( { status: "recorded", startAt: null } );
    let startAt2 = +new Date;
    if ( !cordova.plugins.OggOpusPlugin.isRecording() ) {
      this.onDoneRecording( null );
      return;
    }
    cordova.plugins.OggOpusPlugin.stopRecord( (msg) => {
      this.onDataAvailable( new Buffer( msg, "base64" ), milliseconds );
    }, error => {
      alert( error.message || ( error + "" ) );
    } );
  };

  onDataAvailable( ui8Array, milliseconds ) {
    this.stopAllTracks();
    if (!ui8Array || ui8Array.length < 1024) {
      this.onDoneRecording( null );
      return;
    }
    if (!milliseconds) {
      this.onDoneRecording( null );
      return;
    }
    this.onDoneRecording( ui8Array, milliseconds, "audio/ogg" );
  }

  onError( error ) {
    this.setState( { status: "error", error } );
  }

  render() {
    let { status, startAt, error } = this.state;

    switch(status) {
      case "initializing":
        return <Initializing />;
      case "recording":
        return (
          <Recording
            onCancelRecording={ this.onCancelRecording }
            onStopRecording={ this.onStopRecording }
            startAt={ startAt }
          />
        );
      case "recorded":
        return <Recorded />;
      case "error":
        return <ErrorView error={ error }/>;
    }
  }
}



export default AudioRecorder;
