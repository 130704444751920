import React from "react";

import history from "../../components/history.js";

import Translation from "../../components/translation.jsx";
import Timg from "../../components/timg.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

class FooterView extends React.Component {
	constructor() {
		super();
		this._onOptionsClick = this._onOptionsClick.bind( this );
		this._onContactClick = this._onContactClick.bind( this );
		this._onGroupsClick = this._onGroupsClick.bind( this );
		this._service = serviceLocator();
		this.state = {};
	}

	componentWillMount() {
		this._unreadContactsSubscription = (
			this._service.observeUnreadCountExcept( -1, "normal" )
				.subscribe( unreadContactsCount => {
					this.setState( { unreadContactsCount } );
				} )
		);
		this._unreadGroupsSubscription = (
			this._service.observeUnreadCountExcept( -1, "group" )
				.subscribe( unreadGroupsCount => {
					this.setState( { unreadGroupsCount } );
				} )
		);
	}

	componentWillUnmount() {
		this._unreadContactsSubscription.dispose();
		this._unreadGroupsSubscription.dispose();
	}

	_onOptionsClick() {
		if ( this.props.active === "options" ) {
			return;
		}
		history.navigateTo( "options" );
	}
	_onContactClick() {
		if ( this.props.active === "contacts" ) {
			return;
		}
		history.navigateTo( "contacts" );
	}
	_onGroupsClick() {
		if ( this.props.active === "groups" ) {
			return;
		}
		history.navigateTo( "groups" );
	}
	getContactsAvaStyle(unreadContactsCount) {
		let style = {
			position: "relative",
			top: "-12%",
			left: "78%",
			width: 0,
			height: 0,
			overflow: "visible"
		};
		return style;
	}
	getStickerClassName(unreadContactsCount) {
		let cn = "count-sticker";
		if (unreadContactsCount > 100) {
			cn += " small";
		}
		return cn;
	}
	render() {
		return (
			<footer>
				<div className="menu-bar">
					<div
						className={ "item f-left" + ( this.props.active === "contacts" ? " active" : "" ) }
						onMouseDown={ this._onContactClick }
						style={ { position: "relative" } }
					>
						<div
							className={this.getStickerClassName(this.state.unreadContactsCount)}
							data-count={this.state.unreadContactsCount > 100 ? "100+": (this.state.unreadContactsCount || undefined)}
							style={this.getContactsAvaStyle(this.state.unreadContactsCount)} />
						<Timg
							src="images/icon-footer-contacts.svg"
							altTextId="footer.contacts" className="icon contacts" />
						<br />
						<span className="label">
							<Translation textId="footer.contacts" />
						</span>
					</div>
					<div
						className={ "item f-left" + ( this.props.active === "groups" ? " active" : "" ) }
						onMouseDown={ this._onGroupsClick }
					>
						<div
							className={this.getStickerClassName(this.state.unreadGroupsCount)}
							data-count={this.state.unreadGroupsCount > 100 ? "100+" : (this.state.unreadGroupsCount || undefined)}
							style={this.getContactsAvaStyle(this.state.unreadGroupsCount)} />
						<Timg src="images/icon-footer-groups.svg" altTextId="footer.groups" className="icon groups" />
						<br />
						<span className="label">
							<Translation textId="footer.groups" />
						</span>
					</div>
					<div
						className={ "item f-left" + ( this.props.active === "options" ? " active" : "" ) }
						onMouseDown={ this._onOptionsClick }
					>
						<Timg src="images/icon-footer-options.svg" altTextId="footer.options" className="icon options" />
						<br />
						<span className="label">
							<Translation textId="footer.options" />
						</span>
					</div>
					<div style={{clear: "both"}} />
				</div>
			</footer>
		);
	}
}

FooterView.propTypes = {
	active: React.PropTypes.oneOf( [ "contacts", "groups", "options" ] ).isRequired
};

export default FooterView;
