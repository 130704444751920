import React from "react";
import Rx from "rx";
import _ from "lodash";
import classNames from "classnames";

import serviceLocator from "../../../api/services/locators/worker.client.js";
import configuration from "../../../common/configuration.js";

import Checkbox from "../../components/checkbox.jsx";
import Translation from "../../components/translation.jsx";
import Qr from "../../components/qr.jsx";
import InputWithHelp from "../../components/input.withhelp.jsx";
import ArrowButton from "../../components/button.continue.jsx";
import Button from "../../components/button.jsx";
import TopLevel from "../common/top.level.view.jsx";
import ImageButton from "../../components/button.image.jsx";
import OnlineStatus from "../../components/online.status.jsx"
import DeviceBackButton from "../../components/device.back.button.jsx";
import {DynamicToast} from "../../components/toast.jsx";

class ActivationView extends React.Component {
	constructor() {
		super();
		this.state = {
			status: "initial"
		};
		this.onCreate = this.onCreate.bind( this );
		this.onBackPress = this.onBackPress.bind( this );
		this._service = serviceLocator();
	}

	componentWillMount() {
		this._subscription = (
			this._service.observeUserTable()
				.subscribe( users => {
					if ( this.state.status === "view" ) {
						return;
					}
					let config = configuration.getPrivateServerConfig();
					let privilegedCount = _.sumBy( users, ( { type } ) => type === "user" ? 0 : 1 );
					let isPrivilegedQuotaExceeded = config.maxPrivileged <= privilegedCount;
					if ( config.maxUsers <= users.length ) {
						this.setState( { status: "usersQuotaExceeded" } );
						return;
					}
					this.setState( {
						status: "create",
						isPrivilegedQuotaExceeded
					} );
				} )
		);
	}

	componentWillUnmount() {
		this._subscription.dispose();
	}

	onBackPress() {
		this.props.onBack();
	}

	onCreate( nickname, isPrivileged, isAddContact ) {
		let user = { nickname, type: isPrivileged ? "powerUser" : "user", isAddContact };
		this._service.addUserAsync( user )
			.subscribe( token => {
				this.setState( { token, status: "view" } );
			} );
	}

	renderContent() {
		switch( this.state.status ) {
			case "initial":
				return null;
			case "create":
				return <CreateActivationView
					onCreate={this.onCreate}
					isPrivilegedQuotaExceeded={this.state.isPrivilegedQuotaExceeded}
				/>;
			case "view":
				return <ActivationDetailsView token={this.state.token} />;
			case "usersQuotaExceeded":
				return <Translation textId="activation.user.quota.exceeded"/>;
		}
	}

	render() {
		return (
			<TopLevel className="invite-page">
				<header>
					<ImageButton type="icon-arrow-left" onClick={this.onBackPress} id="back"/>
					<span className="header-caption"><Translation textId="activation.header"/></span>
					<OnlineStatus/>
				</header>
				<main>
					{ this.renderContent() }
				</main>
				<DeviceBackButton onPress={ this.onBackPress }/>
			</TopLevel>
		);
	}
}

class CreateActivationView extends React.Component {
	constructor() {
		super();
		this.state = {
			nickname: "",
			isPrivileged: false,
			isLoading: false,
			isAddContact: false
		};
		this.onNicknameChange = this.onNicknameChange.bind( this );
		this.onIsPrivilegedChange = this.onIsPrivilegedChange.bind( this );
		this.onIsAddContactChange = this.onIsAddContactChange.bind( this );
		this.onCreateClick = this.onCreateClick.bind( this );
		this.copy = _.get( global, "cordova.plugins.clipboard.copy" );
	}

	onNicknameChange( nickname ) {
		this.setState( { nickname } );
	}

	onIsPrivilegedChange() {
		this.setState( { isPrivileged: !this.state.isPrivileged } );
	}

	onIsAddContactChange() {
		this.setState( { isAddContact: !this.state.isAddContact } );
	}

	onCreateClick() {
		let {nickname, isPrivileged, isAddContact, isLoading} = this.state;
		if ( this.state.isLoading ) {
			return;
		}
		this.setState( { isLoading: true } );
		this.props.onCreate( nickname, isPrivileged, isAddContact );
	}

	renderIsAddContactCheckbox() {
		let checkboxCN = classNames( "f-right" );
		return (
			<span>
				<Checkbox
					className={checkboxCN}
					value={this.state.isAddContact}
					onClick={this.onIsAddContactChange}
				/>
				<div className="label">
					<Translation textId="activation.isaddcontact.checkbox"/>
				</div>
			</span>
		);
	}

	renderIsPrivilegedCheckbox() {
		if ( this.props.isPrivilegedQuotaExceeded ) {
			return <Translation textId="activation.privileged.quota.exceeded" />;
		}
		return (
			<span>
				<Checkbox
					className="f-right"
					value={this.state.isPrivileged}
					onClick={this.onIsPrivilegedChange}
				/>
				<div className="label">
					<Translation textId="activation.isprivileged.checkbox"/>
				</div>
			</span>
		);
	}

	render() {
		return (
			<div>
				<div className="small-text"><Translation textId="activation.text"/></div>
				<InputWithHelp
					type="text"
					value={this.state.nickname}
					onChange={this.onNicknameChange}
					placeholderTextId="activation.nickname.placeholder"
					firstUppercased={true}
					tooltipTextId="activation.nickname.tooltip"
					autofocus={true}
				/>
				<br/>
				<br/>
				{ this.renderIsPrivilegedCheckbox() }
				<br/>
				<br/>
				{ this.renderIsAddContactCheckbox() }
				<br/>
				<br/>
				<ArrowButton
					enabled={!this.state.isLoading && !!this.state.nickname}
					caption="activation.button.generate"
					handleClick={this.onCreateClick}
					id="continue"
				/>
			</div>
		);
	}
}

class ActivationDetailsView extends React.Component {
	constructor() {
		super();
		this.onCopyClick = this.onCopyClick.bind( this );
	}

	onCopyClick() {
		let { token } = this.props;
		copy( token, () => this.refs.toast.show( { textId: "activation.copytoast" } ) );
	}

	render() {
		let {onCopyClick, token} = this.props;
		return (
			<div>
				<div className="big-text"><Translation textId="activation.qr.bigtext"/></div>
				<div className="small-text"><Translation textId="activation.qr.smalltext"/></div>
				<div className="qr-code blue f-left">
					<Qr className="qr-image" code={token}/>
				</div>
				<div className="clear"></div>
				<div className="small-text">
					<Translation textId="activation.plain.text"/>
				</div>
				<div className="invite-code-block-">
					<InputWithHelp
						type="text"
						className="invite-code"
						value={token}
						readOnly={true}
						placeholderTextId="activation.token.placeholder"
						tooltipTextId="activation.token.tooltip"
						id="invite-code"
					/>
				</div>
				{this.copy && <Button
					caption="activation.button.copy"
					handleClick={onCopyClick}
				/>}
				<DynamicToast ref="toast"/>
			</div>
		);
	}
}

export default ActivationView;
