import Rx from "rx";
import _ from "lodash";

import MetaConnection from "./meta.connection.js";

class MetaConnectionWithRights extends MetaConnection {
	constructor( ...args ) {
		super( ...args );
		if ( !this._fullState.rightsPerParticipant ) {
			this._fullState.rightsPerParticipant = Object.create( null );
		}
		if ( !this._fullState.rightsPerInvite ) {
			this._fullState.rightsPerInvite = Object.create( null );
		}
		this._rightsPerParticipant = this._fullState.rightsPerParticipant;
		this._rightsPerInvite = this._fullState.rightsPerInvite;

		this._rightsSubject = new Rx.BehaviorSubject( {
			...this._rightsPerParticipant,
			...this._rightsPerInvite
		} );
	}

	_changeRights( changes ) {
		for ( let i = 0; i < changes.length; i++ ) {
			if ( !this._isRightsValid( changes[ i ].rights ) ) {
				return Rx.Observable.throw( new Error( "Invalid rights object" ) );
			}
		}

		let changedParticipantRights = false;
		let changedInviteRights = false;

		for ( let i = 0; i < changes.length; i++ ) {
			let {pid, rights} = changes[ i ];
			if ( this._participantsSubject.getValue()[ pid ] ) {
				this._rightsPerParticipant[ pid ] = rights;
				changedParticipantRights = true;
				continue;
			}
			let invite = this._invitesSubject.getValue()[ pid ];
			if ( !invite || invite.used ) {
				throw new Error( "Participant not found" );
			}
			changedInviteRights = true;
			this._rightsPerInvite[ pid ] = rights;
		}
		this._rightsSubject.onNext( { ...this._rightsPerParticipant, ...this._rightsPerInvite } );
		this._fullState.rightsPerParticipant = this._rightsPerParticipant;
		this._fullState.rightsPerInvite = this._rightsPerInvite;
		if ( !this._fullStateChangeHook ) {
			console.warn( "Skipping store _fullStateChangeHook while updating rights" );
			return Rx.Observable.empty();
		}
		return (
			this._fullStateChangeHook( this._fullState )
				.flatMap( () => Rx.Observable.empty() )
		);
	}

	_processMessageAsync( json, from, index ) {
		if ( !this._isValidMessage( json ) ) {
			debugger;
			return Rx.Observable.throw( new Error( "Invalid meta message" ) );
		}
		if ( !this._hasRights( json, from, index ) ) {
			debugger;
			return Rx.Observable.throw( new Error( "Access denied" ) );
		}
		let {content, type} = json;

		switch ( type ) {
			case "create":
				return (
					super._processMessageAsync( json, from, index )
						.concat( Rx.Observable.defer( () =>
							this._changeRights( [ { pid: content.pid, rights: {
								allowModifyWorkgroup: true,
								allowModifyContacts: true
							} } ] )
						) )
				);
			case "change_rights":
				return this._changeRights( content.changes );
			case "invite":
				return super._processMessageAsync( json, from, index ).concat(
						Rx.Observable.defer( () => {
							return this._changeRights( [ { pid: content.tmpPid, rights: content.rights } ] );
						} )
					);
			case "accept":
				let rights = this._rightsPerInvite[ from ];
				if ( !rights ) {
					return Rx.Observable.throw( new Error( "Rights for invite not found" ) );
				}
				delete this._rightsPerInvite[ from ];
				return (
						super._processMessageAsync( json, from, index )
							.tapOnCompleted( () => this._changeRights( [ {pid: content.pid, rights} ] ) )
					);
			default:
				return super._processMessageAsync( json, from, index );
		}
	}

	_hasRights( json, from ) {
		let {type, content} = json;
		switch ( type ) {
			case "change_rights":
			case "invite":
				let currentRights = this._rightsPerParticipant[ from ];
				if ( !currentRights || !currentRights.allowModifyWorkgroup ) {
					return false;
				}
		}

		switch ( type ) {
			case "change_rights": {
				let currentRights = this._rightsPerParticipant[ from ];
				if ( currentRights.allowModifyContacts ) {
					break;
				}
				let changes = content.changes;
				for ( let i = 0; i < changes.length; i++ ) {
					let {pid, rights: newRights} = changes[ i ];
					let oldRights = this._rightsPerParticipant[ pid ];
					if ( !oldRights.allowModifyContacts && newRights.allowModifyContacts ) {
							return false;
					}
				}
				break;
			}
			case "invite": {
				let currentRights = this._rightsPerParticipant[ from ];
				let newRights = content.rights;
				if ( !currentRights.allowModifyContacts && newRights.allowModifyContacts ) {
					return false;
				}
				break;
			}
		}
		return true;
	}

	_isRightsValid( rights ) {
		if ( !rights ) {
			return false;
		}
		if ( typeof rights.allowModifyContacts !== "boolean" ) {
			return false;
		}
		if ( typeof rights.allowModifyWorkgroup !== "boolean" ) {
			return false;
		}
		return true;
	}

	_isValidMessage( json ) {
		let {type, content} = json;
		switch( type ) {
			case "create":
				return !!content.rights;
			case "invite":
				return this._isRightsValid( content.rights );
			case "change_rights":
				return (
					_.every( content.changes, ({pid, rights}) =>
						   ( typeof pid === "string")
						&& this._isRightsValid( rights )
					)
				);
		}
		return true;
	}

	observeRights( ) {
		return this._rightsSubject;
	}

	sendCreateMessage( content, transaction ) {
		if ( !content.rights ) {
			content = { ...content, rights: {} };
		}
		super.sendCreateMessage( content, transaction );
	}

	sendInviteMessage( content, transaction ) {
		if ( !this._isRightsValid( content.rights ) ) {
			content.rights = this._rightsPerParticipant[ this._pid ];
		}

		return super.sendInviteMessage( content, transaction );
	}

	sendChangeRights( content, transaction ) {
		let {changes} = content;
		for ( let i = 0; i < changes.length; i++ ) {
			if ( !this._isRightsValid( changes[ i ].rights ) ) {
				throw new Error( "Invalid rights object" );
			}
			if ( typeof changes[ i ].pid !== "string" ) {
				throw new Error( "Pid string required" );
			}
		}
		let json = {
			type: "change_rights",
			content
		};
		if ( !this._hasRights( json, this._pid ) ) {
			throw new Error( "Access denied" );
		}

		this._sendMessage( json, transaction );
	}

	_sendMessage( json, transaction, keyMap ) {
		if ( !this._isValidMessage( json ) ) {
			throw new Error( "Invalid change rights message" );
		}
		super._sendMessage( json, transaction, keyMap );
	}
}

export default MetaConnectionWithRights;
