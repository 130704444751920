import React from "react";
import Rx from "rx";
import _ from "lodash";
import FullScreen from "../../components/fullscreen.jsx";
import ImageButton from "../../components/button.image.jsx";
import {getContactInitials, getStatusColor, sortContacts}
	from "../../../common/utils.js";
import WithFooterView from "../common/with.footer.view.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import Translation from "../../components/translation.jsx";
import PromptParticipants from "./prompt.participants.jsx";
import Prompt from "../common/prompt.jsx";
import TopLevel from "../common/top.level.view.jsx";
import OnlineStatus from "../../components/online.status.jsx"

import serviceLocator from "../../../api/services/locators/worker.client.js";

class AddGroupView extends React.Component {
	constructor() {
		super();
		this.cancelSetName = this.cancelSetName.bind( this );
		this.cancelSetNickname = this.cancelSetNickname.bind( this );
		this.setNickname = this.setNickname.bind( this );
		this.setName = this.setName.bind( this );
		this.selectParticipants = this.selectParticipants.bind( this );
		this.state = { step: 0 };
		this._service = serviceLocator();
	}

	componentWillMount() {
		this._profileSubscription = this._service.getProfileAsync()
			.subscribe( profile => {
				if ( profile.nickname ) {
					this.setState( { step: 2, nickname: profile.nickname } );
					return;
				}
				this.setState( { step: 1, askNickname: true } );
			} );
	}

	componentWillUnmount() {
		this._profileSubscription.dispose();
	}

	_createGroup( participantIds ) {
		let {name, nickname} = this.state;
		let progressSubj = new Rx.BehaviorSubject(0);
		// this.setState( { step: 4 } );
		this._service
			.createGroupAsync( name, nickname, participantIds )
			.subscribe(
				() => {
					this.props.onClose();
				},
				error => alert( error )
			);

		return progressSubj;
	}

	cancelSetName() {
		if ( !this.state.askNickname ) {
			this.props.onClose();
			return;
		}
		this.setState( {step: 1} );
	}

	cancelSetNickname() {
		this.props.onClose();
	}

	setNickname( nickname ) {
		this.setState( {nickname, step: 2} );
	}

	setName( name ) {
		this.setState( {name, step: 3} );
	}

	selectParticipants( participantIds ) {
		if ( participantIds ) {
			return this._createGroup( participantIds );
		}

		if ( this.state.askNickname ) {
			this.setState( { step: 1 } );
			return;
		}
		this.props.onClose();
	}

	render() {
		switch( this.state.step ) {
			case 0:
				return null;
			case 1:
				return <NicknamePrompt
					nickname={ this.state.nickname }
					cancelSetNickname={ this.cancelSetNickname }
					setNickname={ this.setNickname }
				/>;
			case 2:
				return <NamePrompt
					nickname={ this.state.name }
					cancelSetName={ this.cancelSetName }
					setName={ this.setName }
				/>;
			case 3:
				return <PromptParticipants
					onDone={ this.selectParticipants }
				/>;
			case 4:
				return <CreationProgress />;
		}
	}
}


class NicknamePrompt extends React.Component {
	constructor() {
		super();
		this.validate = this.validate.bind( this );
	}

	validate( value ) {
		if ( value.trim() ) {
			return [];
		}
		return [ "common.contact.name.empty" ];
	}

	render() {
		return <Prompt
			defaultValue={ this.props.nickname || "" }
			onBack={ this.props.cancelSetNickname }
			onSetValue={ this.props.setNickname }
			placeholderTextId="group.add.nickname.placeholder"
			headerTextId="group.add.nickname.header"
			textId="group.add.nickname.text"
			autofocus={true}
			validate={ this.validate }
		/>;
	}
}

class NamePrompt extends React.Component {
	constructor() {
		super();
		this.validate = this.validate.bind( this );
	}

	validate( value ) {
		if ( value.trim() ) {
			return [];
		}
		return [ "common.contact.name.empty" ];
	}

	render() {
		return <Prompt
			defaultValue={ this.props.name || ""}
			onBack={ this.props.cancelSetName }
			onSetValue={ this.props.setName }
			placeholderTextId="group.add.name.placeholder"
			headerTextId="group.add.name.header"
			textId="group.add.name.text"
			autofocus={true}
			validate={ this.validate }
		/>;
	}
}

class CreationProgress extends React.Component {
	render() {
		return (
			<FullScreen>
				<TopLevel>
					<header>
						<span className="header-caption">
							<Translation textId="group.new.progress.header" />
						</span>
						<OnlineStatus/>
					</header>
					<main>
						<span className="small-text">
							<br />
							<Translation textId="group.new.progress.text" />
						</span>
					</main>
				</TopLevel>
			</FullScreen>
		);
	}
}

export default AddGroupView;
