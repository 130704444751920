import React from "react";

import TranslatedComponent from "../../../components/abstract/translated.jsx";

let titles = Object.create( null );

function updateTitle() {
	let maxKey = 0;
	for ( var key in titles ) {
		if ( key | 0 > maxKey ) {
			maxKey = key | 0;
		}
	}
	document.title = titles[ maxKey ] || "";
}

class TitleView extends TranslatedComponent {
	componentDidMount() {
		this._prevTitle = document.title;
		document.title = this.getTitle();
	}

	componentDidUpdate() {
		titles[ this.props.index || 0 ] = this.getTitle();
		updateTitle();
	}

	getTitle() {
		if ( !this.props.params ) {
			return this.translate( this.props.textId );
		}
		return this.translate( this.props.textId, ...this.props.params );
	}

	componentWillUnmount() {
		delete titles[ this.props.index || 0 ];
		updateTitle();
	}

	render() {
		return null;
	}

	static get propTypes() {
		return {
			textId: React.PropTypes.string.isRequired,
			params: React.PropTypes.array
		};
	}
}

export default TitleView;
