import Rx from "rx";
import ssgCrypto, {KEY_KINDS} from "ssg.crypto";
import configuration from "../../common/configuration.js";

import masterKeyRepositoryLocator from "../repositories/locators/master.key.js";

class MasterKeyService {
	constructor( ) {
		this._masterKeyRepository = masterKeyRepositoryLocator();
	}

	_getEncryptionKey( serverSeed, clientSeed, password ) {
		//TODO: use kdf and Password class
		return ssgCrypto.hash( serverSeed, clientSeed, new Buffer( password ) );
	}

	tryDecryptMasterKeyAtPositionAsync( positionBit, serverSeed, clientSeed, password ) {
		//TODO: use kdf
		let keyBuffer = this._getEncryptionKey( serverSeed, clientSeed, password );

		return (
			Rx.Observable.combineLatest(
				Rx.Observable.fromPromise(
					ssgCrypto.createKeyFromBufferThen( keyBuffer, KEY_KINDS.SYMMETRIC_ENCRYPTION, configuration.getDefaultEncryptionConfig() )
				),
				this._masterKeyRepository.readAsync( positionBit ),
				( key, encryptedBuffer ) => ( { key, encryptedBuffer } )
			)
				.flatMap( ( { key, encryptedBuffer } ) => Rx.Observable.fromPromise(
					ssgCrypto.decryptToNewBufferThen( encryptedBuffer, key, false, configuration.getDefaultEncryptionConfig() )
						.then( plainBuffer => ( { key, plainBuffer } ) )
				) )
				.map( ( { key, plainBuffer } ) => {
					let keySize = key.byteLength;
					key.dispose();

					for ( var i = keySize; i < plainBuffer.length; i++ ) {
						if ( plainBuffer[ i ] ) {
							//Non-zero byte
							return false;
						}
					}
					return plainBuffer.slice( 0, keySize );
				} )
				.flatMap( keyBuffer =>
					keyBuffer
					? Rx.Observable.fromPromise(
						ssgCrypto.createKeyFromBufferThen(
							keyBuffer, KEY_KINDS.SYMMETRIC_ENCRYPTION, configuration.getDefaultEncryptionConfig()
						)
					)
					: Rx.Observable.just( keyBuffer )
				)
		);
	}

	_encryptAndWriteMasterKeyAsync( positionBit, serverSeed, clientSeed, password, masterKey ) {
		let totalSize = this._masterKeyRepository.getKeyBufferSize();

		if ( password === null || password === undefined ) {
			return (
				Rx.Observable.fromPromise( ssgCrypto.createRandomBufferThen( totalSize ) )
					.flatMap( randomBuffer => this._masterKeyRepository.writeAsync( positionBit, randomBuffer ) )
			);
		}

//TODO: use ssgCrypto to derive keys
		let keyBuffer = this._getEncryptionKey( serverSeed, clientSeed, password );
		let keySize = keyBuffer.length;

		let zeros = new Buffer( totalSize - ssgCrypto.getEncryptedSize( keySize, false, configuration.getDefaultEncryptionConfig() ) );
		zeros.fill( 0 );

		return (
			Rx.Observable.fromPromise(
				Promise.all( [
					ssgCrypto.createKeyFromBufferThen(
						keyBuffer, KEY_KINDS.SYMMETRIC_ENCRYPTION, configuration.getDefaultEncryptionConfig()
					),
					new Promise( resolve => {
						masterKey.postponeManagedBuffer( mb => mb.useAsBuffer( b => {
							resolve( Buffer.concat( [ b, zeros ] ) );
						} ) );
					} )
				] )
					.then( ( [ key, buffer ] ) =>
						ssgCrypto.encryptThen( buffer, key, false, configuration.getDefaultEncryptionConfig() )
							.then( ebuf => { key.dispose(); return ebuf; } )
					)
			)
				.flatMap( encryptedBuffer => this._masterKeyRepository.writeAsync( positionBit, encryptedBuffer ) )
		);
	}

	makeMasterKeyAsync( serverSeed0, serverSeed1, clientSeed, password0, password1 ) {
		return (
			Rx.Observable.fromPromise(
				Promise.all( [
					ssgCrypto.createRandomKeyThen( KEY_KINDS.SYMMETRIC_ENCRYPTION, configuration.getDefaultEncryptionConfig() ),
					ssgCrypto.createRandomKeyThen( KEY_KINDS.SYMMETRIC_ENCRYPTION, configuration.getDefaultEncryptionConfig() ),
					ssgCrypto.createRandomBufferThen( 1 )
				] )
				.then(
					( [ masterKey0, masterKey1, randomByte ] ) =>
					( { masterKey0, masterKey1, randomBit: randomByte & 1 } )
				)
			)
			.flatMap( ( { masterKey0, masterKey1, randomBit } ) =>
				this._encryptAndWriteMasterKeyAsync( randomBit, serverSeed0, clientSeed, password0, masterKey0 )
				.flatMap( () =>
					this._encryptAndWriteMasterKeyAsync( 1 - randomBit, serverSeed1, clientSeed, password1, masterKey1 )
				).map( () => true )
			)
		);
	}

	remakeMasterKeyAsync(
		mkey0,
		mkey1,
		serverSeed0,
		serverSeed1,
		clientSeed,
		password0,
		password1) {
			return (
				Rx.Observable.fromPromise(ssgCrypto.createRandomBufferThen( 1 ))
				.map((randomByte) => randomByte & 1)
				.flatMap( (randomBit) => this._encryptAndWriteMasterKeyAsync( randomBit, serverSeed0, clientSeed, password0, mkey0 )
					.flatMap( () =>
						this._encryptAndWriteMasterKeyAsync( 1 - randomBit, serverSeed1, clientSeed, password1, mkey1 )
					)
				).map( () => true )
			);
		}

	tryDecryptMasterKeyAsync( serverSeed, clientSeed, password ) {
		return (
			Rx.Observable.combineLatest(
				this.tryDecryptMasterKeyAtPositionAsync( 0, serverSeed, clientSeed, password ),
				this.tryDecryptMasterKeyAtPositionAsync( 1, serverSeed, clientSeed, password ),
				( res0, res1 ) => {
					if ( res0 ) {
						return { key: res0, positionBit: 0 };
					}
					if ( res1 ) {
						return { key: res1, positionBit: 1 };
					}
					return null;
				}
			)
		);
	}

	changePasswordAsync( positionBit, serverSeed, clientSeed, newPassword, masterKey ) {
		return (
			this._encryptAndWriteMasterKeyAsync( positionBit, serverSeed, clientSeed, newPassword, masterKey )
		);
	}

	isExistAsync( ) {
		return (
			this._masterKeyRepository.readAsync( 1 )
				.map( key => !!key )
			);
	}

	dropDataAsync( ) {
		return this._masterKeyRepository.dropDataAsync();
	}
}

export default MasterKeyService;
