import Rx from "rx";
import _ from "lodash";
import HookableRepository from "./hookable.js";
import ssgCrypto,{Config} from "ssg.crypto";
import Queue from "promise-queue";

import DebuggableError from "../errors/debuggable.error.js";
import {
	serializeObjectWithKeysToNewManagedBufferAsync,
	deserializeObjectWithKeysFromManagedBufferAsync,
	serializeObjectWithKeysToNewManagedBufferThen,
	deserializeObjectWithKeysFromManagedBufferThen,
	deserializeJSONOnlyFromManagedBuffer
} from "../../common/serializer.js";
import configuration from "../../common/configuration.js";

class EncryptedRepository extends HookableRepository {
	constructor( StorageType, fileName ) {
		if ( arguments.length > 2 ) {
			throw new Error( "Too many arguments" );
		}
		super( StorageType, fileName );
		this._readyThen = new Promise( resolve => {
			this._readyCb = resolve;
		} );
	}

	uninitAsync( ) {
		let targetReadyThen = this._readyThen;
		this._readyThen = new Promise( resolve => {
			this._readyCb = resolve;
		} );
		if ( !this._encryptionKey ) {
			return Rx.Observable.just( null );
		}
		return Rx.Observable.fromPromise(
			targetReadyThen
				.then( () =>
					this.lockStorageThen( storage => {
						delete this._positionBit;
						delete this._encryptionKey;
						return null;
					} )
				)
		);
	}
	_bufferToJsonObjectAsync( mb, keyMap ) {
		return Rx.Observable.fromPromise( this._bufferToJsonObjectThen( mb, keyMap ) );
	}

	_bufferToJsonObjectThen( mb, keyMap ) {
		let json;
		try {
			json = deserializeJSONOnlyFromManagedBuffer( mb );
		} catch( e ) {
			return Promise.reject( e );
		}
		let econfig = (
			json.econfig
			? new Config( json.econfig )
			: configuration.getDefaultEncryptionConfig()
		);
		ssgCrypto.addTrustedConfig( econfig );
		return deserializeObjectWithKeysFromManagedBufferThen(
			mb,
			keyMap,
			econfig
		);
	}

	_jsonObjectToBufferAsync( json, plainSize, keyMap ) {
		return Rx.Observable.fromPromise(
			this._jsonObjectToBufferThen( json, plainSize, keyMap )
		);
	}

	_jsonObjectToBufferThen( json, plainSize, keyMap ) {
		return (
			serializeObjectWithKeysToNewManagedBufferThen( json, keyMap )
				.then( mb => {
					if ( mb.byteLength >= plainSize ) {
						//TODO: get block size from encryption config and fill with random
						plainSize = ( ( mb.byteLength + 1023 + 16 ) / 1024 | 0 ) * 1024 - 16;
						// console.warn( "Contact is bigger than by default:" + plainSize );
					}

					let newMB = ssgCrypto.createManagedBuffer( plainSize );
					newMB.useAsBuffer( newb => mb.useAsBuffer( b => b.copy( newb ) ) );
					mb.dispose();
					return newMB;
				} )
		);
	}

	_decryptAsync( buffer ) {
		return Rx.Observable.fromPromise( this._decryptThen( buffer ) );
	}

	_decryptThen( buffer ) {
		if ( buffer.length % 16 ) {
			//TODO: remove
			buffer = buffer.toString();
			buffer = Buffer.from( buffer, "base64" );
		}
		let resultMB = ssgCrypto.createManagedBuffer( ssgCrypto.getDecryptedSize( buffer.length, configuration.getDefaultEncryptionConfig() ) );
		return (
			ssgCrypto.decryptToManagedBufferThen( buffer, resultMB, this._encryptionKey, false, configuration.getDefaultEncryptionConfig() )
				.then( () => resultMB )
		);
	}

	_encryptAsync( mb ) {
		return Rx.Observable.fromPromise( this._encryptThen( mb ) );
	}

	_encryptThen( mb ) {
		if ( !this._encryptionKey ) {
			throw new Error( "Not initialized encrypted repository" );
		}
		return ssgCrypto.encryptThen( mb, this._encryptionKey, false, configuration.getDefaultEncryptionConfig() );
	}

	writeObjectThen( object, encryptedSize, keyMap ) {
		if ( !this._encryptionKey ) {
			throw new Error( "Not initialized encrypted repository" );
		}
		if ( typeof encryptedSize !== "number" ) {
			throw new Error( "encryptedSize expected to be a number" );
		}
		let plainSize = ssgCrypto.getDecryptedSize( encryptedSize, configuration.getDefaultEncryptionConfig() );

		return (
			this._jsonObjectToBufferThen( object, plainSize, keyMap )
				.then( mb => this._encryptThen( mb ).then( res => { mb.dispose(); return res; } ) )
				.then( encryptedBuffer => this.replaceDataThen( encryptedBuffer ) )
		);
	}

	readObjectThen( keyMap ) {
		if ( !this._encryptionKey ) {
			throw new Error( "Not initialized encrypted repository" );
		}
		return (
			this.lockStorageThen( storage => storage.readAsBufferThen() )
				.then( encryptedBuffer => encryptedBuffer.length
					? this._decryptThen( encryptedBuffer )
					: null
				)
				.then( mb => mb
					? this._bufferToJsonObjectThen( mb, keyMap )
						.then( res => { mb.dispose(); return res; } )
					: null
				)
			);
	}
}

export default EncryptedRepository;
