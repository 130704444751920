import React from "react";
import Rx from "rx";
import _ from "lodash";
import ssgCrypto,{Config} from "ssg.crypto";
import configuration from "../../../common/configuration.js";
import {
	bindStateProperty,
	receiveMessageThen,
	isValidToken,
} from "../../../common/utils.js";
import {
	deserializeObjectWithKeysFromManagedBufferAsync
} from "../../../common/serializer.js";

import Timestamp from "../../components/timestamp.jsx";
import FullScreen from "../../components/fullscreen.jsx";
import TopLevel from "../common/top.level.view.jsx";
import ButtonQrView from "../../components/button.qr.jsx";
import Translation from "../../components/translation.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import ArrowButton from "../../components/button.continue.jsx";
import ImageButton from "../../components/button.image.jsx";
import TranslatedInput from "../../components/tinput.jsx";
import TooltipButton from "../../components/button.tooltip.jsx";
import {InternetRetryConfirm} from "../../components/dialogs.jsx"
import {DynamicToast} from "../../components/toast.jsx";
import OnlineStatus from "../../components/online.status.jsx"
import Notifications from "../common/notifications.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

let paste = _.get( global, "cordova.plugins.clipboard.paste" );

class ConnectNotificationView extends React.Component {
	render() {
		return (
			<div style={{
					width: "100%",
					height: "100%",
					background: "#ccc",
					color: "#000",
					padding: "4px 12px",
					border: "#fff solid 2px",
					borderRadius: "10px"
				}} onClick={this.props.onClose}>
				<Translation textId="options.web.notification" />
			</div>
		);
	}
/*				<div style={{
						cssFloat:"right"
				}}>x</div>
*/
	static get propTypes() {
		return {
			onClose: React.PropTypes.func.isRequired
		};
	}
}

class UseWebTokenView extends React.Component {
	constructor() {
		super();
		this.state = {
			connectionData: null,
			token: "",
			inProgress: false,
			isConnected: null
		};
		this.onScan = this.onScan.bind( this );
		this.onConnectDataSet = this.onConnectDataSet.bind( this );
		this.cleanData = this.cleanData.bind( this );
		this.onContinue = this.onContinue.bind( this );
		this._service = serviceLocator();
	}

	static get propTypes() {
		return {
			onBack: React.PropTypes.func
		};
	}

	static get defaultProps() {
		return {
			onBack: _.noop
		};
	}

	componentWillMount() {
		this._subscription = (
			this._service.observeWebConnectionSession()
				.subscribe( sessInfo => {
					console.log( "Got session update", sessInfo );
					let { isConnected, timestamp, count } = sessInfo;
					this.setState( { isConnected, timestamp, count } );
				} )
		);
	}

	componentWillUnmount() {
		this._subscription && this._subscription.dispose();
	}

	onScan( text ) {
		let parts = text.split( "|" );
		let connectToken = parts[ 0 ];
		let authPassword = parts[ 1 ] || "";
		this.setState( { connectToken, authPassword }, this.onContinue );
	}

	onConnectDataSet( isValidToken ) {
		this.setState( { isValidToken } );
	}

	onContinue() {
		if ( this.state.inProgress ) {
			return;
		}

		let { connectToken, isValidToken } = this.state;
		if ( !connectToken || !isValidToken ) {
			return;
		}
		this.makeConnectionAsync( connectToken )
			.subscribe( () => {
				this.setState( { inProgress: false }, this.props.onBack );
			} );

		this.setState( { inProgress: true } );
	}

	makeConnectionAsync( connectToken ) {
		let disconnect = () => {
			this._service.closeWebConnectionAsync().subscribe();
		};

		let notificationFactory = () => <ConnectNotificationView onClose={ disconnect } />;

		let closeNotification = () => {
			Notifications.unregisterNotification( notificationFactory );
		};

		return this._service.makeWebConnectionAsync( connectToken ).tap( () => {
			Notifications.registerNotification( notificationFactory, "24px" );
			this._service.observeWebConnectionSession()
				.filter( ( { isConnected } ) => !isConnected )
				.subscribe( closeNotification );
		} );
	}

	cleanData() {
		this.onConnectDataSet( null );
	}

	renderContent() {
		let { connectToken, authPassword, isConnected, inProgress, isValidToken, timestamp, count } = this.state;
		let webAddress = configuration.getWebUrlBase();
		if ( isConnected === null ) {
			return null;
		}
		if ( isConnected ) {
			return <SessionDetails
				isConnected={isConnected}
				timestamp={timestamp}
				count={count}
			/>;
		}
		return (
			<main>
				<div className="clear"></div>
				<div className="small-text">
					<Translation textId="options.web.text" params={[webAddress]}/>
				</div>
				<InputDataTokenView
					token={connectToken}
					authPassword={authPassword}
					onTokenChange={bindStateProperty( this, "connectToken" )}
					onDataSet={this.onConnectDataSet}
					cleanData={this.cleanData}
				/>

				<DynamicToast ref="toast"/>
				<ArrowButton
					enabled={!!isValidToken && !inProgress}
					caption="useinvite.continue.button"
					handleClick={this.onContinue}
				/>
			</main>
		);
	}

	renderQrButton() {
		let { isConnected } = this.state;
		if ( isConnected ) {
			return null;
		}
		return <ButtonQrView onScan={ this.onScan }/>;
	}

	render() {
		return (
			<FullScreen>
			<TopLevel>
				<header>
					<ImageButton type="icon-arrow-left" onClick={this.props.onBack}/>
					<span className="header-caption">
						<Translation textId="options.web.header" />
					</span>
					{ this.renderQrButton() }
					<OnlineStatus/>
				</header>
				{this.renderContent()}
				<DeviceBackButton onPress={this.props.onBack}/>
			</TopLevel>
			</FullScreen>
		);
	}
}

//TODO: move to common views
class InputDataTokenView extends React.Component {
	constructor() {
		super();
		this.state = {
			value: "",
			status: "default"
		};
		this.onPaste    = this.onPaste.bind( this );
		this.tryToken   = this.tryToken.bind( this );
		this._service   = serviceLocator();
	}

	static get defaultProps() {
		return {
			onDataSet: _.noop,
			onTokenChange: _.noop
		};
	}

	isPasteAvailable() {
		return !!paste;
	}

	onPaste() {
		paste( text => {
			let parts = text.split( "|" );
			let token = parts[ 0 ];
			this.props.onTokenChange( token );
		} );
	}

	componentWillReceiveProps( { token, authPassword, cleanData } ) {
		if ( token !== this.props.token ) {
			this.tryToken( token, authPassword, cleanData );
		}
	}

	tryToken(token, authPassword) {
		this.abortRequest();
		if ( isValidToken( token || this.props.token ) ) {
			this.requestData( token || this.props.token, authPassword || this.props.authPassword );
		} else {
			this.props.cleanData();
			this.setState( { status: "error" } );
		}
	}

	abortRequest() {
		if ( this.subscription ) {
			this.subscription.dispose();
		}
		this.setState( { status: "default" } );
	}

	requestData( token, authPassword ) {
		this.setState( { status: "pending" } );
		this._service.isWebConnectionTokenValidAsync( token )
			.subscribe( isValid => {
				this.setState( { status: isValid ? "valid" : "error" } );
				this.props.onDataSet( isValid );
			}, error => {
				debugger;
				this.refs.noInternet.show();
			} )
	}

	getIcon( status ) {
		switch ( status ) {
			case "pending":
				return <ImageButton type="loading"/>;
			case "valid":
				return <ImageButton type="icon-check"/>;
			case "error":
				return <TooltipButton type="icon-error" tooltipTextId="useinvite.code.error"/>;
		}
		return <TooltipButton type="question-mark" tooltipTextId="useinvite.code.tooltip"/>;
	}

	render() {
		let { token, onTokenChange } = this.props;
		let pasteButton = (
			<div className="btn gray dashed" onClick={this.onPaste}>
				<Translation textId="invite.code.paste.button"/>
			</div>
		);

		return (
			<div className="invite-code-block">
				<div className="input-wrapper">
					<TranslatedInput
						className="invite-code"
						type="text"
						placeholderTextId="invite.code.placeholder"
						value={token}
						onChange={onTokenChange}
						autofocus={true}
						readOnly={this.state.status === 'valid'}
					/>
					<div className="info-button-wrapper">
						{this.getIcon( this.state.status )}
					</div>
				</div>
				{!token && this.isPasteAvailable() && pasteButton}
				<InternetRetryConfirm ref="noInternet"
					onConfirm={ this.tryToken }
					onReset={ this.props.cleanData }/>
			</div>
		);
	}
}

class SessionDetails extends React.Component {
	constructor() {
		super();
		this.state = {};
		this.disconnect = this.disconnect.bind( this );
		this._service = serviceLocator();
	}

	disconnect() {
		this._service.closeWebConnectionAsync().subscribe();
	}

	componentWillMount() {
	}

	componentWillReceiveProps( nextProps ) {
	}

	componentWillUnmount() {
	}

	render() {
		let {isConnected, timestamp, count} = this.props;

		if ( !isConnected ) {
			return null;
		}
		return (
			<main>
				<br /><br />
				<span className="small-text">
					<Translation textId="options.web.existing.text" />
				</span><br />
				<span className="small-text">
					<Translation
						textId="options.web.existing.activity"
						params={[<Timestamp value={timestamp} />]}
					/>
				({count})
				</span><br />
				<ArrowButton
					enabled={true}
					caption="options.web.disconnect.button"
					handleClick={this.disconnect}
				/>
			</main>
		);
	}

	static get propTypes() {
		return {
			isConnected: React.PropTypes.bool.isRequired
		};
	}
}

export default UseWebTokenView;
