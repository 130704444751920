import Rx from "rx";
import Message from "./message.js";
import BinaryStream from "../binary.stream.js";

class MessageUnpack {
	constructor( params ) {
		if ( !( this._chunkSize = params.chunkSize ) ) {
			throw new Error( "chunkSize required" );
		}
		if ( !( this._binaryStream = params.binaryStream ) ) {
			throw new Error( "binaryStream required" );
		}
	}

	createBinaryStream( params ) {
		if ( !params.chunkCount ) {
			throw new Error( "chunkCount required" );
		}

		if ( !( "position" in params ) ) {
			throw new Error( "position required" );
		}

		if ( this._isBinaryStreamCreated ) {
			throw new Error( "BinaryStream already created" );
		}
		this._isBinaryStreamCreated = true;

		let packChunks = this._binaryStream.size / this._chunkSize;
		let chunkCount = params.chunkCount;
		chunkCount = ( chunkCount + packChunks - 1 ) % packChunks + 1;

		let resultBs = BinaryStream.fromChunked( ( requestChunkCount, cb ) => {
			this.getNextChunksCb_( requestChunkCount, resultBs.position, chunkCount, params.position, cb );
		}, this._chunkSize, chunkCount );
		return resultBs;
	}

	getNextChunksCb_( requestChunkCount, position, chunkCount, messagePosition, cb ) {
		let startChunk = ( ( position + this._chunkSize - 1 ) / this._chunkSize ) | 0;
		let endChunk = startChunk + requestChunkCount;
		let packChunks = this._binaryStream.size / this._chunkSize;

		let packStartChunk = this._binaryStream.position / this._chunkSize;

		let packEndChunk = (
			chunkCount < packChunks - endChunk - messagePosition
			? endChunk * 2 + messagePosition
			: packChunks - chunkCount + endChunk
		);

		this._binaryStream.getNextBytesCb(
			( packEndChunk - packStartChunk ) * this._chunkSize,
			packBuffer => {
				cb( this.unpackBuffer_( packBuffer, startChunk, endChunk, messagePosition, packStartChunk, chunkCount ) );
			}
		);
	}

	unpackBuffer_( packBuffer, startChunk, endChunk, messagePosition, packStartChunk, chunkCount ) {
		// let chunkCount = endChunk - startChunk;
		let resultingBuffer = new Buffer( ( endChunk - startChunk ) * this._chunkSize );
		let packChunks = this._binaryStream.size / this._chunkSize;
		for ( let i = startChunk; i < endChunk; i++ ) {
			if ( chunkCount/* - i */< packChunks - /*2 */ i - messagePosition ) {
				packBuffer.copy( resultingBuffer,
						( i - startChunk )  * this._chunkSize,
						( i * 2 - packStartChunk + messagePosition ) * this._chunkSize,
						( i * 2 - packStartChunk + messagePosition + 1 ) * this._chunkSize
					);
			} else {
				packBuffer.copy( resultingBuffer,
						( i - startChunk ) * this._chunkSize,
						( packChunks - chunkCount - packStartChunk + i ) * this._chunkSize,
						( packChunks - chunkCount - packStartChunk + i + 1 ) * this._chunkSize
					);
			}
		}
		return resultingBuffer;
	}

	/*
	extractEncryptedBuffer( params ) {
		if ( !params.chunkCount ) {
			throw new Error( "chunkCount required" );
		}

		if ( !( "position" in params ) ) {
			throw new Error( "position required" );
		}
		let packChunks = this._buffer.length / this._chunkSize;
		let chunkCount = params.chunkCount;
		chunkCount = ( chunkCount + packChunks - 1 ) % packChunks + 1;

		let resultingBuffer = new Buffer( chunkCount * this._chunkSize );
		for ( let i = 0; i < chunkCount; i++ ) {
			if ( chunkCount/ * - i * /< packChunks - / *2 * / i - params.position ) {
				this._buffer.copy( resultingBuffer,
						i * this._chunkSize,
						( i * 2 + params.position ) * this._chunkSize,
						( i * 2 + params.position + 1 ) * this._chunkSize
					);
			} else {
				this._buffer.copy( resultingBuffer,
						i * this._chunkSize,
						( packChunks - chunkCount + i ) * this._chunkSize,
						( packChunks - chunkCount + i + 1 ) * this._chunkSize
					);
			}
		}
		return resultingBuffer;
	}
*/
}

export default MessageUnpack;
