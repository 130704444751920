import React from "react";
import _ from "lodash";
import FullScreen from "../../components/fullscreen.jsx";
import TopLevel from "../common/top.level.view.jsx";
import Translation from "../../components/translation.jsx";
import Tinput from "../../components/tinput.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import ImageButton from "../../components/button.image.jsx";
import Button from "../../components/button.jsx";
import {DynamicToast} from "../../components/toast.jsx";
import OnlineStatus from "../../components/online.status.jsx"

class PromptView extends React.Component {
	constructor() {
		super();
		this.state = {
			isValid: true
		};
		this.onValueChange = this.onValueChange.bind( this );
		this.onBackPress = this.onBackPress.bind( this );
		this.onDoneClick = this.onDoneClick.bind( this );
	}

	componentWillMount() {
		let { defaultValue } = this.props;
		let errors = this.validate( defaultValue );
		this.setState( {
			value: defaultValue,
			isValid: !errors.length
		} );
	}

	onBackPress() {
		this.props.onBack();
	}

	onValueChange( newValue ) {
		let errors = this.validate( newValue )
		this.setState( {
			value: newValue,
			isValid: !errors.length
		} );
		this.showErrors(errors);
	}

	onDoneClick() {
		this.props.onSetValue( this.state.value );
	}

	validate( value ) {
		return this.props.validate( value )
	}

	showErrors (errors) {
		if (!Array.isArray(errors)) return;

		errors.forEach( (error) => {
			if ( error ) {
				this.refs.toast.show( { textId: error } )
			}
		});
	}

	render() {
		let { value, isValid } = this.state;
		let {defaultValue, onBack, onSetValue, headerTextId, textId, validate, doneButtonTextId, fullScreen,
			...props} = this.props;
		let body = (
			<TopLevel id={this.props.id}>
				<header>
					<ImageButton type="icon-arrow-left" onClick={this.onBackPress}/>
					<span className="header-caption"><Translation textId={this.props.headerTextId}/></span>
					<OnlineStatus/>
				</header>
				<main>
					<span className="small-text">
						<Translation textId={ this.props.textId } params={ [ this.props.defaultValue ] }/>
					</span>
					<Tinput
						{...props}
						value={value}
						onChange={ this.onValueChange }
						placeholderTextId={ this.props.placeholderTextId }
					/>
					<Button
						enabled={isValid}
						handleClick={this.onDoneClick}
						caption={this.props.doneButtonTextId}
						id="prompt-done"
					/>
				</main>
				<DeviceBackButton onPress={ this.onBackPress }/>
				<DynamicToast ref="toast"/>
			</TopLevel>
		);
		if ( this.props.fullScreen ) {
			return <FullScreen>{body}</FullScreen>;
		}
		return body;
	}
}

PromptView.propTypes = {
	onSetValue: React.PropTypes.func,
	onBack: React.PropTypes.func,
	validate: React.PropTypes.func,
	headerTextId: React.PropTypes.string,
	textId: React.PropTypes.string,
	placeholderTextId: React.PropTypes.string,
	defaultValue: React.PropTypes.string,
	type: React.PropTypes.string,
	fullScreen: React.PropTypes.bool
};

PromptView.defaultProps = {
	onSetValue: _.noop,
	onBack: _.noop,
	validate: _.constant( true ),
	headerTextId: "prompt.default.header",
	textId: "prompt.default.text",
	placeholderTextId: "prompt.default.placeholder",
	doneButtonTextId: "prompt.default.button.done",
	defaultValue: "",
	type: "text",
	fullScreen: true
};
export default PromptView;
