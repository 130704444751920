import Rx from "rx";

class BaseStorage {
	readAsBufferAsync( ...params ) {
		return Rx.Observable.fromPromise( this.readAsBufferThen( ...params ) );
	}
	replaceAsync( ...params ) {
		return Rx.Observable.fromPromise( this.replaceThen( ...params ) );
	}
	readAtPositionAsync( ...params ) {
		return Rx.Observable.fromPromise( this.readAtPositionThen( ...params ) );
	}
	writeAtPositionAsync( ...params ) {
		return Rx.Observable.fromPromise( this.writeAtPositionThen( ...params ) );
	}
	dropDataAsync( ...params ) {
		return Rx.Observable.fromPromise( this.dropDataThen( ...params ) );
	}
	getSizeAsync( ...params ) {
		return Rx.Observable.fromPromise( this.getSizeThen( ...params ) );
	}
	getModificationTimeAsync( ...params ) {
		return Rx.Observable.fromPromise( this.getModificationTimeThen( ...params ) );
	}
	renameAsync( ...params ) {
		return Rx.Observable.fromPromise( this.renameThen( ...params ) );
	}

	readAsBufferThen() {
		throw new Error( "Not implemented" );
	}
	replaceThen() {
		throw new Error( "Not implemented" );
	}
	readAtPositionThen() {
		throw new Error( "Not implemented" );
	}
	writeAtPositionThen() {
		throw new Error( "Not implemented" );
	}
	dropDataThen() {
		throw new Error( "Not implemented" );
	}
	getSizeThen() {
		throw new Error( "Not implemented" );
	}
	renameThen() {
		throw new Error( "Not implemented" );
	}
	getModificationTimeThen() {
		throw new Error( "Not implemented" );
	}
}

export default BaseStorage;
