"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

exports.default = function () {
	if (mockedInstance) {
		return mockedInstance;
	}
	if (!instance) {
		instance = new _secureBufferContainer2.default();
	}
	return instance;
};

exports.mock = mock;
exports.unmock = unmock;

var _secureBufferContainer = require("../secure.buffer.container.js");

var _secureBufferContainer2 = _interopRequireDefault(_secureBufferContainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = null;
var mockedInstance = null;

;

function mock(newMockedInstance) {
	mockedInstance = newMockedInstance;
};

function unmock() {
	mockedInstance = null;
};