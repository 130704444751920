import Rx from "rx";
import serviceLocator from "../../api/services/locators/worker.client.js";

let hash = global.location.hash.substr( 1 );
let parseRegex = /^(?:(\d+)(?:\/|$))?(.*)$/;
let parsed = hash.match( parseRegex );

let navigationSubject = new Rx.BehaviorSubject( hash );
let initialStorageNumber = parsed[ 1 ];
let currentPath = parsed[ 2 ];
let isStopped = false;
if ( initialStorageNumber ) {
	serviceLocator().setStorageNumber( initialStorageNumber );
}

let pathObservable = navigationSubject.map( hash => {
	let parsed = hash.match( parseRegex );
	if ( parsed[ 1 ] !== initialStorageNumber ) {
		global.location.reload();
		navigationSubject.onCompleted();
		isStopped = true;
	}
	return parsed[ 2 ];
} );
pathObservable.subscribe( path => { currentPath = path } );

function setLocation( location ) {
	if ( isStopped ) {
		return;
	}
	if ( global.history.replaceState ) {
		global.history.replaceState( null, null, location );
	} else {
		global.location.replace( location );
	}
}

let history = {
	getStorageNumber: () => initialStorageNumber,
	navigateTo: function( path ) {
		if ( initialStorageNumber !== void 0 ) {
			path = `${initialStorageNumber}/${path}`;
		}
		setLocation( `#${path}` );
		navigationSubject.onNext( path );
	},
	clearTheBar: function( path ) {
		setLocation( path || "/" );
	},
	getNavigationObservable: function() {
		return pathObservable;
	},
	getCurrentPath: function() {
		return currentPath;
	}
};

global.addEventListener( "hashchange", event => {
	navigationSubject.onNext( global.location.hash.substr( 1 ) );
} );

export default history;
