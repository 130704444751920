import React from "react";
import Messages from "./messages.jsx";
import Footer from "./message.write.footer.jsx";
import Translation from "../../../components/translation.jsx";

class MainContentView extends React.Component {
	constructor() {
		super();
		this.state = {
			editingMessage: null
		};
		this.onEdit = this.onEdit.bind( this );
		this.onEditDone = this.onEditDone.bind( this );
		this.onReply = this.onReply.bind( this );
		this.onSent = this.onSent.bind( this );
		this.onDrop = this.onDrop.bind( this );
		this.onDragEnter = this.onDragEnter.bind( this );
		this.onDragLeave = this.onDragLeave.bind( this );
		this.onDragOver = this.onDragOver.bind( this );
	}

	componentWillMount() {
	}

	componentWillReceiveProps() {
	}

	componentWillUnmount() {
	}

	onDrop( event ) {
		let { contact } = this.props;
		event.stopPropagation();
		event.preventDefault();
		if ( !contact ) {
			return;
		}
		let {files} = event.dataTransfer;
		let fileArray = [];
		for ( let i = 0; i < files.length; i++ ) {
			fileArray.push( files[ i ] );
		}
		let isAlerted = false;
		Rx.Observable.fromArray( fileArray )
			.concatMap( file => this._service.sendFileAsync( contact, file ).catch( e => {
				isAlerted || alert( e.message );
				isAlerted = true;
				return Rx.Observable.empty();
			} ) )
			.toArray()
			.subscribe();
		this.setState( {
			isDragging: false,
			replyMessage: null
		} );
	}

	onDragEnter( event ) {
		this.setState( {
			isDragging: true,
			replyMessage: null
		} );
		event.preventDefault();
		event.stopPropagation();
	}

	onDragLeave( event ) {
		this.setState( { isDragging: false } );
		event.preventDefault();
		event.stopPropagation();
	}

	onDragOver( event ) {
		this.setState( { isDragging: true } );
		event.preventDefault();
		event.stopPropagation();
	}

	onEdit( editingMessage ) {
		let { text } = editingMessage;
		if ( typeof text !== "string" ) {
			console.warn( "Trying to edit non-text message" );
			return;
		}
		this.setState( { editingMessage } );
	}

	onEditDone( text ) {
		this.setState( { editingMessage: null } );
	}

	onReply( message ) {
		this.setState({replyMessage: message});
	}

	onSent() {
		this.setState( { editingMessage: null, replyMessage: null } );
	}

	render() {
		let {contact} = this.props;
		let {editingMessage} = this.state;
		return (
			<div
				className={"message wide " + ( this.state.isDragging ? "dragging" : "")}
				key={contact.id}
				onDrop={this.onDrop}
				onDragEnter={this.onDragEnter}
				onDragLeave={this.onDragLeave}
				onDragOver={this.onDragOver}
				>
				<Messages
					contact={contact}
					onEdit={this.onEdit}
					editingMessage={editingMessage}
					onReply={this.onReply}
				/>
				<Footer
					contact={contact}
					editingMessage={editingMessage}
					onEditDone={this.onEditDone}
					replyMessage={this.state.replyMessage}
					onSent={this.onSent}
				/>
			</div>
		);
	}
}

export default MainContentView;
