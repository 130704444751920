import Rx from "rx";
import _ from "lodash";
import React from "react";
import ReactDom from "react-dom";
import classNames from "classnames";

import configuration from "../../common/configuration.js";
import serviceLocator from "../../api/services/locators/worker.client.js";
import FocusManager from "./focus.manager.js";
import Keyboard from "./keyboard.jsx";

class FullScreenContextOwner extends React.Component {
	constructor() {
		super();
		this.state = {
			isKeyboardVisible: false,
			useVirtualKeyboard: null
		};
		this._service = serviceLocator();
		this.focusManager = new FocusManager();
		this.onShowKeyboard = this.onShowKeyboard.bind( this );
		this.onHideKeyboard = this.onHideKeyboard.bind( this );
		this.getQrCallback = this.getQrCallback.bind( this );
		this.setQrCallback = this.setQrCallback.bind( this );
		this.observeQrCallback = this.observeQrCallback.bind( this );
		this._qrCallbackSubj = new Rx.BehaviorSubject( null );
	}

	onShowKeyboard() {
		this.setState( { isKeyboardVisible: true } );
	}

	onHideKeyboard() {
		this.setState( { isKeyboardVisible: false } );
	}

	getQrCallback() {
		return this._qrCallbackSubj.getValue();
	}

	setQrCallback(cb) {
		this._qrCallbackSubj.onNext( cb );
	}

	observeQrCallback() {
		return this._qrCallbackSubj;
	}

	static get childContextTypes() {
		return {
			focusManager: React.PropTypes.object.isRequired,
			getQrCallback: React.PropTypes.func.isRequired,
			setQrCallback: React.PropTypes.func.isRequired,
			observeQrCallback: React.PropTypes.func.isRequired
		};
	}

	getChildContext() {
		return {
			focusManager: this.focusManager,
			getQrCallback: this.getQrCallback,
			setQrCallback: this.setQrCallback,
			observeQrCallback: this.observeQrCallback
		};
	}

	componentWillMount() {
		this._profileSubscription = (
			this._service.observeProfile().subscribe( ( { useVirtualKeyboard } ) => {
				if ( !!useVirtualKeyboard === this.state.isKeyboardVisible ) {
					return;
				}
				this.setState( { useVirtualKeyboard: !!useVirtualKeyboard } );
			} )
		);
		this._keyboardTimeout = setTimeout(
			() => {
				if ( this.state.useVirtualKeyboard === null ) {
					this.setState( { useVirtualKeyboard: configuration.getUseVirtualKeyboard() } );
				}
			}, 500
		);
	}

	componentDidMount() {
		global.document.addEventListener( "mousedown", this.focusManager.handleClick );
		global.document.addEventListener( "touchstart", this.focusManager.handleClick );
	}

	componentWillUnmount() {
		global.document.removeEventListener( "mousedown", this.focusManager.handleClick );
		global.document.removeEventListener( "touchstart", this.focusManager.handleClick );
		this._profileSubscription.dispose();
		clearTimeout( this._keyboardTimeout );
	}

	render() {
		let { isKeyboardVisible, useVirtualKeyboard } = this.state;
		return (
			<div className={classNames( [ "modal-page view-root", { "withKeyboard": isKeyboardVisible } ] )}>
				{
					useVirtualKeyboard
					? <Keyboard onShow={this.onShowKeyboard} onHide={this.onHideKeyboard}/>
					: null
				}
				{ this.props.children }
			</div>
		);
	}
}

class FullScreenComponent extends React.Component {

	componentWillMount() {
		this._mountedElement = global.document.createElement( "DIV" );
		global.document.body.appendChild( this._mountedElement );
		this.remount();
	}

	componentDidUpdate() {
		this.remount();
	}

	componentWillUnmount() {
		ReactDom.unmountComponentAtNode( this._mountedElement );
		global.document.body.removeChild( this._mountedElement );
		this._mountedElement = null;
	}

	remount() {
		ReactDom.render( this.renderContent(), this._mountedElement );
	}

	renderContent() {
		return <FullScreenContextOwner>{ this.props.children }</FullScreenContextOwner>;
	}

	render() {
		return null;
	}
}

export default FullScreenComponent;
