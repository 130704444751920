import React from "react";
import { ButtonToolbar, SplitButton, MenuItem, Dropdown } from "react-bootstrap";
import Translation from "../../../components/translation.jsx";

class GroupsMainTitleView extends React.Component {
	render() {
		return (
			<div className="navbar grow">
				<div id="navbar" className="navbar-collapse">
					<img
						className="hidden visible-xs-inline back-button"
						src="web/img/arrow2.svg"
						alt=""
						onClick={ this.props.onGoToList }
					/>
					<div className="name">
						{ this.props.contact ? this.props.contact.name : "" }
					</div>
					<ul
						className="nav navbar-nav navbar-right right"
						style={ { marginTop: 0 } }
					>
						<Dropdown id="contacts_dropdown" componentClass="li">
							<Dropdown.Toggle noCaret={true} useAnchor={true}>
								<img src="web/img/menu_2_moreoptions.svg" alt="" />
							</Dropdown.Toggle>
							<Dropdown.Menu className="dropdown-menu dropdown-nav-menu" bsClass="">
								<MenuItem eventKey="2" onClick={this.props.onClearHistory}>
									<Translation textId="web.contacts.menu.clear.history" />
								</MenuItem>
								<MenuItem eventKey="3" onClick={this.props.onParticipants}>
									<Translation textId="web.group.menu.participants" />
								</MenuItem>
								<MenuItem eventKey="4" onClick={this.props.onExternal}>
									<Translation textId="web.group.menu.external" />
								</MenuItem>
							</Dropdown.Menu>
						</Dropdown>
					</ul>
				</div>
			</div>
		);
	}

	static get propTypes() {
		return {
		};
	}
}

export default GroupsMainTitleView;
