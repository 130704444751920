import React from "react";
import _ from "lodash";

import Layout, {
	ListTitleContentView,
	MainTitleContentView,
	ListbarContentView,
	MainContentView,
	MobileMenuContentView,
	CustomContentView
} from "../common/layout.jsx";
import MobileMenu from "../common/mobile.menu.jsx";
import MainTitle from "./main.title.jsx";
import MainContent from "./main.content.jsx";
import ListTitle from "./list.title.jsx";
import GroupListListBar from "../common/contact.list.jsx";
import CreateTitle from "./create.title.jsx";
import CreateContent from "./create.content.jsx";
import ParticipantsContent from "./participants.content.jsx";
import AddContent from "./add.content.jsx";
import CreateProgressContent from "./create.progress.content.jsx";
import AddExternalContent from "./add.external.content.jsx";

import Prompt from "../common/prompt.jsx";
import Confirm from "../common/confirm.jsx";
import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class GroupsView extends React.Component {
	constructor() {
		super();
		this.state = {
			mainView: "contact",
			selectedContact: null,
			activeMobileView: "list",
			searchString: null
		};
		this._service = remoteServiceLocator();
		this.onCreateNewGroup = this.onCreateNewGroup.bind( this );
		this.backFromCreate = this.backFromCreate.bind( this );
		this.selectContact = this.selectContact.bind( this );
		this.rename = this.rename.bind( this );
		this.exit = this.exit.bind( this );
		this.clearHistory = this.clearHistory.bind( this );
    	this.showParticipants = this.showParticipants.bind( this );
		this.onGoToList = this.onGoToList.bind( this );
		this.onAdd = this.onAdd.bind( this );
		this.onExternal = this.onExternal.bind( this );
		this.backFromAdd = this.backFromAdd.bind( this );
		this.onSetSearchString = this.onSetSearchString.bind( this );
	}

	componentWillMount() {
		//TODO: avoid copy-paste
		let openContactIdStr = this.props.params && this.props.params[ 0 ];
		this._contactsSubscription = (
			this._service.observeGroupList()
			.subscribe( contacts => {
				if ( openContactIdStr !== undefined ) {
					let selected = _.find( contacts, {id: openContactIdStr | 0} );
					if ( selected ) {
						this.selectContact( selected );
					}
					openContactIdStr = undefined;
				}
				this.setState( {contacts} );
			} )
		);
	}

	componentWillUnmount() {
		this._contactsSubscription.dispose();
	}

	getSelectedContact() {
		let {selectedContact, contacts} = this.state;
		if ( selectedContact && !_.find( contacts, {id: selectedContact.id } ) ) {
			selectedContact = null;
		}
		return selectedContact;
	}

	getSelectedContactId() {
		let selectedContact = this.getSelectedContact();
		let contactId = selectedContact && selectedContact.id;
		return contactId;
	}

	onGoToList() {
		this.setState( {
			activeMobileView: "list",
      mainView: "contact"
		} );
	}

	onAdd() {
		this.setState( {
			mainView: "add",
			activeMobileView: "main"
		} );
	}

  showParticipants() {
    this.setState( {
			mainView: "participants",
			activeMobileView: "main"
		} );
  }

	onCreateNewGroup() {
		this.setState( {
			mainView: "create",
			activeMobileView: "main"
		} );
	}

	onExternal() {
		this.setState( {
			mainView: "external",
			activeMobileView: "main"
		} );
	}

	backFromCreate( name, nickname, contactIds ) {
		if ( this.state.inProgress ) {
			return;
		}
		this.setState( {
			inProgress: true,
			activeMobileView: "main",
			mainView: "createProgress"
		} );
		this._service.createGroupAsync( name, nickname, contactIds )
			.subscribe( selectedContact => {
				this.setState( {
					mainView: "contact",
					activeMobileView: "main",
					selectedContact
				} );
			} );
	}

	clearHistory() {
		let selectedContact = this.getSelectedContact();
		selectedContact && this._service.clearHistoryAsync( selectedContact ).subscribe();
	}

	backFromAdd() {
		this.setState( {
			mainView: "contact",
			activeMobileView: "main"
		} );
	}

	renderMainTitle() {
		switch( this.state.mainView ) {
			case "contact":
				return <MainTitle
					contact={this.getSelectedContact()}
					onGoToList={this.onGoToList}
					onClearHistory={this.clearHistory}
          			onParticipants={this.showParticipants}
					onAdd={this.onAdd}
					onExternal={this.onExternal}
				/>;
			case "create":
			case "createProgress":
				return <CreateTitle onGoToList={this.onGoToList} />;
			case "participants":
			case "add":
			case "external":
				return <MainTitle
					contact={this.getSelectedContact()}
					onGoToList={this.onGoToList}
					onClearHistory={this.clearHistory}
					onParticipants={this.showParticipants}
					onAdd={this.onAdd}
					onExternal={this.onExternal}
				/>;
		}
	}

	renderContent() {
		switch( this.state.mainView ) {
			case "contact":
				return <MainContent contact={this.getSelectedContact()} />;
			case "createProgress":
				return <CreateProgressContent />;
			case "create":
				return <CreateContent onBack={this.backFromCreate} />;
			case "participants": {
				let contact = this.getSelectedContact();
				if ( !contact ) {
					return <MainContent contact={null} />;
				}
				return <ParticipantsContent
					contact={contact}
					onAdd={this.onAdd}
				/>;
			}
			case "add":
				let contact = this.getSelectedContact();
				if ( !contact ) {
					return <MainContent contact={null} />;
				}
				return <AddContent
					contact={this.getSelectedContact()}
					onBack={this.backFromAdd}
				/>;
			case "external":
				return <AddExternalContent
					contact={this.getSelectedContact()}
					onBack={this.backFromAdd}
				/>;
		}
	}

	renderPopup() {
		if ( this.state.popup ) {
			return this.state.popup();
		}
		return null;
	}

	selectContact( selectedContact ) {
		this.setState( {
			mainView: "contact",
			activeMobileView: "main",
			selectedContact
		} );
	}

	doRename( contact, newName ) {
		if ( !newName ) {
			this.setState( { popup: null } );
			return;
		}
		this._service.renameContactAsync( contact, newName )
			.subscribe( () => {
				this.setState( { popup: null } );
			} );
	}

	rename( contact ) {
		this.setState( {
			popup: () =>
				<Prompt
					titleTextId="web.group.rename.title"
					defaultValue={ contact.name }
					onDone={ this.doRename.bind( this, contact ) }
				/>
		} );
	}

	doExit( contact, isConfirmed ) {
		if ( !isConfirmed ) {
			this.setState( { popup: null } );
			return;
		}
		this._service.exitGroupAsync( contact )
			.subscribe( () => {
				this.setState( { popup: null } );
			} );
	}


	exit( contact ) {
		this.setState( {
			popup: () =>
				<Confirm
					titleTextId="web.group.exit.title"
					yesButtonTextId="web.group.exit.button.yes"
					onDone={ this.doExit.bind( this, contact ) }
				/>
		} );
	}

	onSetSearchString( searchString ) {
		this.setState( { searchString } );
	}

	getFilteredContacts() {
		let { searchString, contacts } = this.state;
		if ( !searchString ) {
			return contacts;
		}
		let low = searchString.toLowerCase();
		return _.filter( contacts, ( { name } ) => ~name.toLowerCase().indexOf( low ) );
	}

	render() {
		let contactId = this.getSelectedContactId();
		let contact = this.getSelectedContact();
		let mainTitleTextId = contact ? "web.groups.main.title": "web.groups.list.title";
		return (
			<Layout
				activeMobileView={ this.state.activeMobileView }
				mainTitleTextId={mainTitleTextId}
				listTitleTextId="web.groups.list.title"
				mainTitleParams={[contact && contact.name]}
				>
				<ListTitleContentView>
					<ListTitle
						onAddClick={this.onCreateNewGroup}
						searchString={ this.state.searchString }
						onSetSearchString={ this.onSetSearchString }
					/>
				</ListTitleContentView>
				<MainTitleContentView>
					{this.renderMainTitle()}
				</MainTitleContentView>
				<MobileMenuContentView>
					<MobileMenu active="groups">
						<button className="btn-circle newChat visible-xs" onClick={this.onCreateNewGroup}>+</button>
					</MobileMenu>
				</MobileMenuContentView>
				<ListbarContentView>
					<GroupListListBar
						onGroupRename={ this.rename }
						onGroupExit={ this.exit }
						onSelectContact={ this.selectContact }
						selectedContactId={ contactId }
						contacts={ this.getFilteredContacts() }
						emptyTextId={ this.state.searchString ? "groups.search.empty" : "web.groups.list.empty" }
						arrow={ this.state.searchString === null }
					/>
				</ListbarContentView>
				<MainContentView>
					{this.renderContent()}
				</MainContentView>
				<CustomContentView>
					{this.renderPopup()}
				</CustomContentView>
			</Layout>
		);
	}
}

export default GroupsView;
