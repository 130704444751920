import React from "react";
import _ from "lodash";
import {isValidToken} from "../../../common/utils.js";
import Translation from "../../components/translation.jsx";
import TranslatedInput from "../../components/tinput.jsx";
import ImageButton from "../../components/button.image.jsx";
import TooltipButton from "../../components/button.tooltip.jsx";
import {InternetRetryConfirm} from "../../components/dialogs.jsx"
import configuration from "../../../common/configuration.js";

import serviceLocator from "../../../api/services/locators/worker.client.js";

let paste = _.get( global, "cordova.plugins.clipboard.paste" );

class InputInviteView extends React.Component {
	constructor() {
		super();
		this.state = {
			value: "",
			status: "default"
		};
		this.onPaste    = this.onPaste.bind( this );
		this.tryToken   = this.tryToken.bind( this );
	}

	static get defaultProps() {
		return {
			onInviteSet: _.noop,
			onTokenChange: _.noop
		};
	}

	isPasteAvailable() {
		return !!paste;
	}

	onPaste() {
		paste( text => {
			let parts = text.split( "|" );
			let inviteToken = parts[ 0 ];
			this.props.onTokenChange( inviteToken );
		} );
	}

	componentWillReceiveProps( { token, authPassword } ) {
		if ( token !== this.props.token ) {
			this.tryToken( token, authPassword );
		}
	}

	tryToken( token, authPassword ) {
		this.abortRequest();
		if ( isValidToken( token || this.props.token ) ) {
			this.requestInvite( token || this.props.token, authPassword || this.props.authPassword );
		} else {
			this.props.onInviteSet( null );
			this.setState( { status: "error" } );
		}
	}

	abortRequest() {
		if ( this.subscription ) {
			this.subscription.dispose();
		}
		this.setState( { status: "default" } );
		//this.props.onInviteSet( null );
	}

	requestInvite( token, authPassword ) {
		this.setState( { status: "pending" } );
		this.subscription = serviceLocator()
			.getInviteDataAsync( token, authPassword, this.props.type )
			.subscribe( inviteBlob => {
				if (
					inviteBlob
					//( inviteBlob.apiUrlBase === configuration.getSocketBase() )
					//&& ( ( inviteBlob.type === "activation" ) || ( inviteBlob.type === "createUserSystem" ) )
				) {
					this.setState( { status: "valid" } );
					this.props.onInviteSet( inviteBlob );
				} else {
					this.setState( { status: "error" } );
					this.props.onInviteSet( null );
					console.error( "inviteBlob invalid" );
					console.error( inviteBlob );
				}
			}, error => {
				this.setState( { status: "error" } );
			} )
	}

	getIcon( status ) {
		switch ( status ) {
			case "pending":
				return <ImageButton type="loading"/>;
			case "valid":
				return <ImageButton type="icon-check"/>;
			case "error":
				return <TooltipButton type="icon-error" tooltipTextId="useinvite.code.error"/>;
		}
		return <TooltipButton type="question-mark" tooltipTextId="useinvite.code.tooltip"/>;
	}

	render() {
		let { token, onTokenChange } = this.props;
		let pasteButton = (
			<div className="btn gray dashed" onClick={this.onPaste}>
				<Translation textId="invite.code.paste.button"/>
			</div>
		);

		return (
			<div className="invite-code-block">
				<div className="input-wrapper">
					<TranslatedInput
						className="invite-code"
						type="text"
						placeholderTextId="invite.code.placeholder"
						value={token}
						onChange={onTokenChange}
						autofocus={true}
						readOnly={this.state.status === 'valid'}
						name={this.props.name}
					/>
					<div className="info-button-wrapper">
						{this.getIcon( this.state.status )}
					</div>
				</div>
				{!token && this.isPasteAvailable() && pasteButton}
				<InternetRetryConfirm ref="noInternet"
					onConfirm={this.tryToken}
					onReset={ () => this.props.cleanInviteData() }/>
			</div>
		);
	}
}

export default InputInviteView;
