import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import MediaQuery from "react-responsive";
import ssgCrypto from "ssg.crypto";

import Tinput from "./tinput.jsx";
import Keyboard from "../keyboard/keyboard.jsx";
import Translation from "../../../components/translation.jsx";
import AudioRecorder from "./audio.recorder.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";
import draftServiceLocator from "../../../../api/services/locators/draft.message.js";
import configuration from "../../../../common/configuration.js";
import audioDownloader from "../../../../api/services/audio.downloader.js";

function insertAtCursor(myField, myValue) {
	//cp from http://stackoverflow.com/questions/11076975/insert-text-into-textarea-at-cursor-position-javascript
	//IE support
	if (document.selection) {
		myField.focus();
		sel = document.selection.createRange();
		sel.text = myValue;
	}
	//MOZILLA and others
	else if (myField.selectionStart || myField.selectionStart == '0') {
		var startPos = myField.selectionStart;
		var endPos = myField.selectionEnd;
		myField.value = (
			myField.value.substring(0, startPos)
			+ myValue
			+ myField.value.substring(endPos, myField.value.length)
		);
		myField.selectionStart = startPos + myValue.length;
		myField.selectionEnd = startPos + myValue.length;
	} else {
		myField.value += myValue;
	}
}

class ContactListFooterView extends React.Component {
	constructor() {
		super();
		this.state = {
			text: "",
			isInProgress: false,
			useVirtualKeyboard: false,
			expireDt: null
		};
		this.onSendClick = this.onSendClick.bind( this );
		this.onInputChange = this.onInputChange.bind( this );
		this.toggleKeyboard = this.toggleKeyboard.bind( this );
		this.sendFiles = this.sendFiles.bind( this );
		this.fileInputOnChange = this.fileInputOnChange.bind( this );
		this.onKeyDown = this.onKeyDown.bind( this );
		this.onVoiceClick = this.onVoiceClick.bind( this );
		this.onFinishVoiceRecording = this.onFinishVoiceRecording.bind( this );

		this._service = remoteServiceLocator();
		this._draftService = draftServiceLocator();
		this._textSubj = new Rx.BehaviorSubject( this.state.text );
	}

	observeText() {
		return this._textSubj;
	}

	componentWillMount() {
		if ( configuration.getUseWebVirtualKeyboard() === "off" ) {
			this.context.focusManager.useVirtualKeyboard = false;
		}
		if ( configuration.getUserExpiration() ) {
			this._expireDtSubscription = (
				this._service.observeGlobalUserType().subscribe( ( { expireDt } ) => {
					this.setState( { expireDt } );
				} )
			);
		}
		this._subscription = (
			this.context.focusManager.observeUseVirtualKeyboard()
				.subscribe( useVirtualKeyboard => {
					this.setState( { useVirtualKeyboard } );
				} )
		);
		if ( this.props.editingMessage ) {
			this._textSubj.onNext( this.props.editingMessage.text );
			this.setState( { text: this.props.editingMessage.text } );
		} else {
			this.restoreDraft();
		}
		global.document.addEventListener( "keydown", this.onKeyDown );
	}

	componentWillUnmount() {
		this.storeDraft();
		this._subscription.dispose();
		this._expireDtSubscription && this._expireDtSubscription.dispose();
		global.document.removeEventListener( "keydown", this.onKeyDown );
	}

	componentWillReceiveProps( nextProps ) {
		if ( this.props.editingMessage !== nextProps.editingMessage ) {
			this.setState( {
				text: nextProps.editingMessage ? nextProps.editingMessage.text : ""
			} );
		}
		if ( this.props.contact !== nextProps.contact ) {
			this.exchangeDraftWithCurrentMessage( nextProps );
		}
	}

	restoreDraft() {
		if ( !this.props.contact ) {
			return;
		}
		let draft = this._draftService.tryGetDraftForContact( this.props.contact.id );
		if ( draft ) {
			this.setState( { text: draft } );
		}
	}

	storeDraft() {
		if ( !this.props.contact ) {
			return;
		}
		if ( !this.props.editingMessage && this.state.text ) {
			this._draftService.setDraftForContact(
				this.props.contact.id, this.state.text
			);
		} else {
			this._draftService.dropDraftForContact( this.props.contact.id );
		}
	}

	exchangeDraftWithCurrentMessage( nextProps ) {
		if ( this.props.contact ) {
			if ( !this.props.editingMessage && this.state.text ) {
				this._draftService.setDraftForContact(
					this.props.contact.id, this.state.text
				);
			} else {
				this._draftService.dropDraftForContact( this.props.contact.id );
			}
		}

		if ( nextProps.contact ) {
			let draft = this._draftService.tryGetDraftForContact( nextProps.contact.id );
			if ( draft ) {
				this.setState( { text: draft } );
			}
		}
	}

	componentWillUpdate( props, state ) {
		if ( state.text !== this.state.text ) {
			this._textSubj.onNext( state.text );
		}
	}

	sendFiles() {
		if ( !this.props.contact ) {
			return;
		}
		this.refs.fileInput.click();
	}

	toggleKeyboard() {
		if ( !this.props.contact ) {
			return;
		}
		let { focusManager } = this.context;
		focusManager.useVirtualKeyboard = !focusManager.useVirtualKeyboard;
	}

	onSendClick() {
		let { contact, editingMessage } = this.props;
		let { text } = this.state;
		if ( !contact || !text.trim() || this.state.isInProgress ) {
			return;
		}
		this.setState( { isInProgress: true } );
		setTimeout( () => {
			this.setState( { isInProgress: false, text: "" } );
		}, 1 );
		if ( editingMessage ) {
			this._service.sendEditTextMessageAsync( editingMessage, text, contact )
				.subscribe( () => {
					this.props.onEditDone();
				} );
		} else {
			this._service.sendTextMessageAsync( contact, text, this.props.replyMessage )
				.subscribe( () => {
					this.props.onSent && this.props.onSent();
				} );
		}
	}

	onInputChange( text ) {
		if ( this.state.isInProgress || !this.props.contact ) {
			return;
		}
		if ( !text && this.props.editingMessage ) {
			this.props.onEditDone();
		}
		this.setState( { text } );
	}

	fileInputOnChange() {
		let {contact} = this.props;
		let files = this.refs.fileInput.files;
		let fileArray = [];
		for( let i = 0; i < files.length; i++ ) {
			fileArray.push( files[ i ] );
		}
		let isAlerted = false;
		Rx.Observable.fromArray( fileArray )
			.concatMap( file => this._service.sendFileAsync( contact, file ).catch( e => {
				isAlerted || alert( e.message );
				isAlerted = true;
				return Rx.Observable.empty();
			} ) )
			.toArray()
			.subscribe();
	}

	onKeyDown( event ) {
		if ( !event.ctrlKey && !event.shiftKey && ( event.key === "Enter" ) ) {
			this.onSendClick();
			return;
		}

		if ( event.ctrlKey && ( event.key === "Enter" ) ) {
			let domNode = ReactDOM.findDOMNode( this.refs.input );
			insertAtCursor( domNode, "\n" );
			this.onInputChange( domNode.value );
			return;
		}
		if ( ( event.key === "Escape" ) && this.props.onEditDone ) {
			this.props.onEditDone();
			return;
		}
	}

	onVoiceClick() {
		this.setState( { isRecordingVoice: true } );
	}

	onFinishVoiceRecording( buffer, milliseconds, mediaType ) {
		if ( !buffer ) {
			this.setState( { isRecordingVoice: false } );
			return;
		}

		ssgCrypto.createRandomBase64StringThen( 32 ).then( messageId => {
			audioDownloader.setCachedAudio( messageId, Promise.resolve( buffer ) )
			this._service.sendVoiceMessageAsync(
				this.props.contact,
				buffer,
				milliseconds,
				mediaType,
				messageId
			).subscribe( () => {
				this.setState( { isRecordingVoice: false } );
			}, ( error ) => {
				this.setState( { isRecordingVoice: false } );
				alert( "Error sending voice message: " + ( error.message || ( error + "" ) ) );
			} );
		} );
	}

	renderKeyboardOptional() {
		if ( configuration.getUseWebVirtualKeyboard() === "off" ) {
			return (
				<div style={ { display: "block" } } />
			);
		}
		return (
			<div style={ { display: "block" } }>
				<MediaQuery query='(min-width: 768px)'>
					{ this.state.useVirtualKeyboard ? <Keyboard /> : null }
				</MediaQuery>
				<MediaQuery query='(max-width: 767px)'>
					<Keyboard />
				</MediaQuery>
			</div>
		);
	}

	renderKeyboardButtonOptional() {
		let buttonClass = "btn-transparent";
		if ( configuration.getUseWebVirtualKeyboard() === "off" ) {
			return null;
		}

		return (
			<MediaQuery query='(min-width: 768px)'>
				<button className={buttonClass} onClick={ this.toggleKeyboard }>
					<svg version="1.2" baseProfile="tiny"
						xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 24" >
						<path fill="#010202" d="M18,5H2C0.9,5,0,5.9,0,7l0,10c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V7C20,5.9,19.1,5,18,5z M9,8h2v2H9V8z
							M9,11h2v2H9V11z M6,8h2v2H6V8z M6,11h2v2H6V11z M5,13H3v-2h2V13z M5,10H3V8h2V10z M14,17H6v-2h8V17z M14,13h-2v-2h2V13z M14,10h-2
							V8h2V10z M17,13h-2v-2h2V13z M17,10h-2V8h2V10z"/>
						<line fill="none" x1="0" y1="24" x2="0" y2="0"/>
						<line fill="none" x1="0" y1="24" x2="0" y2="0"/>
					</svg>
				</button>
			</MediaQuery>
		);
	}

	renderSendOrVoiceButton() {
		let buttonClass = "btn-transparent";
		if ( this.state.text ) {
			return (
				<button className={ buttonClass } onClick={ this.onSendClick }>
					<svg version="1.2" baseProfile="tiny"
						xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 21.9 20.8" >
						<path fill="#010202" d="M21.9,0L0,10.1l7.5,3.6v7.2l6.6-4l6,2.8L21.9,0z M19.3,2.8L8.5,12.5l-5.1-2.4L19.3,2.8z M9,18.2v-3.9
							l3.6,1.7L9,18.2z M9.9,13.1l10.3-9.2l-1.2,13.5L9.9,13.1z"/>
					</svg>
				</button>
			);
		}
		return (
			<button className={ buttonClass } onClick={ this.onVoiceClick }>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
					<path
						fill="#263238"
						d="M11.999 14.942c2.001 0 3.531-1.53 3.531-3.531V4.35c0-2.001-1.53-3.531-3.531-3.531S8.469 2.35 8.469 4.35v7.061c0 2.001 1.53 3.531 3.53 3.531zm6.238-3.53c0 3.531-2.942 6.002-6.237 6.002s-6.237-2.471-6.237-6.002H3.761c0 4.001 3.178 7.297 7.061 7.885v3.884h2.354v-3.884c3.884-.588 7.061-3.884 7.061-7.885h-2z"/>
				</svg>
			</button>
		);
	}

	renderExpired() {
		return (
			<div className="footerMessage">
				<div className="btn-transparent active">
					<Translation textId="chat.expired.explanation"/>
				</div>
			</div>
		);
	}

	renderRecording() {
		return (
			<div className="footerMessage">
				<div className="message-header">
					<AudioRecorder onRecorded={ this.onFinishVoiceRecording }/>
				</div>
			</div>
		);
	}

	renderEmptyGroup() {
		return (
			<div className="footerMessage">
				<div className="message-header">
					<Translation textId="chat.group.empty" />
				</div>
			</div>
		);
	}

	renderReply() {
		let {replyMessage, contact} = this.props;
		if (!replyMessage) {
			return null;
		}
		return (
			<div style={{padding: "10px"}}>
				<b>{
					typeof replyMessage.sender === "string"
					? replyMessage.sender
					: contact.name
				}
				</b>:{" "}
				{replyMessage.text.length > 100 ? (replyMessage.text.substr(0, 100) + "...") : replyMessage.text}
			</div>
		);
	}

	render() {
		const { expireDt, isRecordingVoice, text } = this.state;
		if ( expireDt && expireDt < +new Date ) {
			return this.renderExpired();
		}

		if ( this.props.renderEmptyGroup ) {
			return this.renderEmptyGroup();
		}

		if ( isRecordingVoice ) {
			return this.renderRecording();
		}
		let buttonClass = "btn-transparent";
		let headerClass = classNames( {
			"message-header": true,
			active: !!this.props.contact && !this.state.isInProgress
		} );
		return (
			<div className="footerMessage" key={this.props.replyMessage && this.props.replyMessage.index}>
				{this.renderReply()}
				<div className={headerClass}>
					<button className={buttonClass} onClick={ this.sendFiles } onTouchStart={ this.sendFiles } style={{cursor: "pointer"}}>
						<svg version="1.2" baseProfile="tiny"
							xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 18.8 20.6" >
							<path fill="#010202" d="M16,9.8l-8.6,8.5c-0.7,0.7-1.6,1.1-2.6,1.1S3,18.9,2.4,18.2c-1.4-1.4-1.4-3.7,0-5.1l10.5-11
								c0.5-0.5,1.2-0.8,1.9-0.8s1.4,0.3,1.9,0.8c1.1,1.1,1.1,2.8,0,3.9l-9.4,9.3c-0.3,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4
								c-0.3-0.3-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.4-1l7.2-7.1l-0.9-0.9l-7.2,7.1c-1.1,1.1-1.1,2.7,0,3.8C4.9,16.7,5.6,17,6.3,17
								c0.7,0,1.4-0.3,1.9-0.8l9.4-9.3c1.6-1.6,1.6-4.1,0-5.7C16.8,0.4,15.8,0,14.8,0c-1,0-2.1,0.4-2.9,1.2l-10.5,11c-1.9,1.9-1.9,5,0,7
								c1,1,2.2,1.4,3.5,1.4c1.3,0,2.5-0.5,3.5-1.4l8.6-8.5L16,9.8z"/>
						</svg>
					</button>
					<input
						type="file"
						ref="fileInput"
						multiple
						onChange={this.fileInputOnChange}
						style={ { visibility: "hidden", width: "0px", height: "0px" } }
					/>
					<Tinput
						className="message-input"
						type="multiline"
						placeholderTextId="web.message.placeholder"
						value={this.state.text}
						onChange={this.onInputChange}
						autofocus={true}
						readOnly={!!this.state.isInProgress || !this.props.contact}
						ref="input"
					/>
					<div className="right">
						{ this.renderKeyboardButtonOptional() }
						{ this.renderSendOrVoiceButton() }
					</div>
				</div>
				{ this.renderKeyboardOptional() }
			</div>
		);
	}

	static get contextTypes() {
		return { focusManager: React.PropTypes.object.isRequired };
	}
}


export default ContactListFooterView;
