import Rx from "rx";
import _ from "lodash";
import ssgCrypto, {Config, Key, KEY_KINDS} from "ssg.crypto";
import Queue from "promise-queue";

import {serializeObject, deserializeObject} from "../../common/serializer.js";

import ClientServerOrdered from "./client.server.ordered.js";

// import ClientServerJSON from "./client.server.json.js";
function key2StringAsync( key ) {
	let resSubj = new Rx.ReplaySubject();
	key.postponeManagedBuffer( mb => mb.useAsBuffer( b => {
		resSubj.onNext( b.toString("base64"));
		resSubj.onCompleted();
	} ) );

	return resSubj;
}

class ClientServerJSONMEK extends ClientServerOrdered {
	constructor( apiUrlBase, connectionId, sendFrom, fromIndex, signer, econfig, metaEncryptionKeyThen ) {
		if ( !( econfig instanceof Config ) ) {
			throw new Error( "econfig of type Config required" );
		}
		super( apiUrlBase, connectionId, sendFrom, fromIndex, signer );
		this.onOrderedMessage( this._gotEncryptedMessageMEK.bind( this ) );
		this._econfig = econfig;
		this._encrypredWithKeys = new Rx.Subject();
		this._encrypingHandler = null;
		this._keysPromises = Object.create( null );
		this._keysCallbacks = Object.create( null );
		this._queue = new Queue( 1 );

		this._keyThen = new Promise( resolve => {
			this._keyCb = resolve;
		})
		metaEncryptionKeyThen.then( key => {
			this._keyCb( key );
		} );
	}

	onEncrypt( func ) {
		if ( typeof func !== "function" ) {
			throw new Error( "encrypingHandler is not a function" );
		}
		if ( this._encrypingHandler ) {
			throw new Error( "encrypingHandler already set" );
		}
		this._encrypingHandler = func;
	}

	onSendPrivate( func ) {
		if ( typeof func !== "function" ) {
			throw new Error( "onSendPrivateHandler is not a function" );
		}
		if ( this._onSendPrivateHandler ) {
			throw new Error( "onSendPrivateHandler already set" );
		}
		this._onSendPrivateHandler = func;
	}

	addKey( from, hash, key ) {
		if ( !( key instanceof Key ) ) {
			throw new Error( "Key key required" );
		}
		let resultItem = { from, key, keyHandle };
		let hashStr = hash.toString( "base64" );
		let keyHandle = key.addKeyUse( "Mek" );

		if ( !this._keysPromises[ hashStr ] ) {
			this._keysPromises[ hashStr ] = Promise.resolve( resultItem );
		} else if ( this._keysCallbacks[ hashStr ] ) {
			this._keysCallbacks[ hashStr ]( resultItem );
		} else {
			key.removeKeyUse( keyHandle );
			// currently for self messages key is provided more then once. From each p2p output
			// throw new Error( "Key already provided" );
		}
	}

	onAsyncMessage( func ) {
		if ( this._onAsyncMessage ) {
			throw new Error( "this._onAsyncMessage already set" );
		}
		this._onAsyncMessage = func;
	}

	onSelfMessage( func ) {
		if ( typeof func !== "function" ) {
			throw new Error( "Function required" );
		}
		if ( this._onSelfMessage ) {
			throw new Error( "Self message handler already set" );
		}
		this._onSelfMessage = func;
	}

	_gotEncryptedMessageMEK( from, body, index, fullBody ) {
		if ( !this._onAsyncMessage ) {
			console.warn( "MEK message without handler" );
			return;
		}
		if ( from === this._sendFromBuffer.toString( "base64" ) ) {
			//Ignore self message
			this._onSelfMessage && this._onSelfMessage( index );
			this.setProcessedMessage( index );
			return;
		}
		if ( !body ) {
			this._onAsyncMessage( from, body, index, "" );
			this.setProcessedMessage( index );
			return;
		}
		this.setProcessedMessage( index );
		let hashStr = ssgCrypto.hash( fullBody ).toString( "base64" );
		this._onAsyncMessage(
			Rx.Observable.fromPromise( this._decryptThen( from, body, hashStr, index ) ),
			index,
			from,
			hashStr
		);
	}

	send( json, transaction ) {
		if ( !this._encrypingHandler ) {
			throw new Error( "no encrypt handler. nobody will know how to decrypt it" );
		}
		let plainBody = serializeObject( json );
		return this._sendBufferOrManagedBufferThen( plainBody, transaction );
	}

	sendPrivately( json, transaction ) {
		this._onSendPrivateHandler( transaction, json );
	}

	_sendBufferOrManagedBufferThen( plainBody, transaction ) {
		let keyThen = ssgCrypto.createRandomKeyThen( KEY_KINDS.SYMMETRIC_ENCRYPTION, this._econfig );
		this._sendWaitSubj = new Rx.ReplaySubject();
		transaction.waitFor( this._sendWaitSubj, "mek encryption" );
		let connectionUseHandle = this.incUse();

		return (
			Promise.all( [
				keyThen,
				this._encryptThen( plainBody, keyThen )
			] ).then( ( [ key, encryptedBuffer ] ) => {
				this._sendingKey = key; //HACK
				this._sendingKeyHandle = key.addKeyUse( "Sending" );
				super.send( encryptedBuffer, transaction );
				this.decUse( connectionUseHandle );
				key.dispose();
			} )
		);
	}

	_sendLowLevel( transaction, index, buffer ) {
		let hash = ssgCrypto.hash( buffer );
		let key = this._sendingKey;
		delete this._sendingKey;
		this._encrypingHandler( hash, key, transaction );
		key.removeKeyUse( this._sendingKeyHandle );
		delete this._sendingKeyHandle;
		this._sendWaitSubj.onCompleted();
		delete this._sendWaitSubj;
		super._sendLowLevel( transaction, index, buffer );
	}

	_encryptThen( buffer, keyThen ) {
		if ( !this._queue ) {
			return new Promise( _.noop );
		}
		return this._queue.add( () =>
			keyThen.then( key =>
				ssgCrypto.encryptThen( buffer, key, true, this._econfig )
			)
		);
	}

	_decryptThen( from, body, hashStr, index ) { //TODO: more classes?
		if ( !this._keysPromises[ hashStr ] ) {
			this._keysPromises[ hashStr ] = new Promise( resolve => {
				this._keysCallbacks[ hashStr ] = resolve;
			} );
		}
		let startAt = performance.now();
		let interval = setInterval( () => {
			let len = (performance.now() - startAt)|0;
			if ( len > 12000 ) {
				clearInterval( interval );
				interval = 0;
				console.error( `Unable to decrypt incomming message within ${len}ms` );
				if ( this._multicast && this._multicast._contact ) {
					console.error( this._multicast._contact, index );
				}
				return;
			}
			console.warn( `Unable to decrypt incomming message within ${len}ms` );
		}, 5000 );
		return (
			this._keysPromises[ hashStr ]
				.then( ( { from: expectedFrom, key, keyHandle, msgProps } ) => {
					// if ( expectedFrom && ( from !== expectedFrom ) ) {
					// 	throw new Error( `Mek from field mismatch: expected ${expectedFrom} but ${from}` );
					// }
					if ( this.isDisposed() ) {
						return null;
					}
					return (
						ssgCrypto.decryptToNewBufferThen( body, key, true, this._econfig )
							.then( plain => ( { plain, msgProps } ) )
							//.tap( () => key.removeKeyUse( keyHandle ) )
							//TODO: remove unused key handles
					);
				} )
				.then( data => {
					if ( interval ) {
						clearInterval( interval );
					} else {
						let len = ( performance.now() - startAt )|0;
						console.warn( `Decrypted incomming message within ${len}ms` );
					}
					if ( !data ) {
						return data;
					}
					let { plain, msgProps } = data;
					return (
						this._deserializeThen( plain )
							.then( json => ( { json, from, ...msgProps } ) )
					);
				} )
		);
	}

	_deserializeThen( buffer ) {
		return Promise.resolve( deserializeObject( buffer ) );
	}

	getMaxIndexAsync( ) {
		return this._callUntilSuccessAsync( cb => {
			this._unauthConnection.tryGetMaxIndex( cb );
		} ).map( ( { maxIndex } ) => maxIndex );
	}

	dispose( ) {
		delete this._queue;
		super.dispose();
	}
}

export default ClientServerJSONMEK;
