import React from "react";
import _ from "lodash";
import classNames from "classNames";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";
import webNotificationsServiceLocator from "../../../../api/services/locators/web.notifications.js";

import Menu from "../common/menu.jsx";
import Translation from "../../../components/translation.jsx";

class GroupListListBar extends React.Component {
	constructor() {
		super();
		this._webNotificationsService = webNotificationsServiceLocator();
		this.state = {
			isNotificationsEnabled: false
		};
	}

	componentWillMount() {
		this._notificationsSubscription = (
			this._webNotificationsService.observeNotifications()
				.subscribe( isNotificationsEnabled => {
					this.setState( { isNotificationsEnabled } );
				} )
		);
	}

	componentWillUnmount() {
		this._notificationsSubscription.dispose();
	}

	renderAdmin() {
		if ( !this.props.isAdmin ) {
			return null;
		}
		return (
			<li className={classNames( {active: this.props.activeView === "users"} )}>
				<a href="javascript:;" onClick={this.props.onChangeActiveView.bind( this, "users" )}>
					<img src="web/img/ico-48.svg" alt="" />
					<span className="contact-info">
						<Translation textId="web.settings.menu.users" />
						<div className="date">
							<Translation textId="web.settings.menu.users.text" />
						</div>
					</span>
					<div className="clearfix"></div>
				</a>
			</li>
		);
	}


	renderNotificationsContent() {
		let buttonStyle = { marginLeft: "20px" };
		if ( this.state.isNotificationsEnabled ) {
			return (
				<span>
					<Translation textId="web.settings.menu.notifications.on" />
				</span>
			);
		}
		return (
			<span>
				<Translation textId="web.settings.menu.notifications.off" />
			</span>
		);
	}

	render() {
		return (
			<div className={"grow contact settings active " + this.props.className}>
				<ul>
					<li className={classNames( {active: this.props.activeView === "account"} )}>
						<a href="javascript:;" onClick={this.props.onChangeActiveView.bind( this, "account" )}>
							<img src="web/img/ico-45.svg" alt="" />
							<span className="contact-info">
								<Translation textId="web.settings.menu.account" />
								<div className="date">
									<Translation textId="web.settings.menu.account.text" />
								</div>
							</span>
							<div className="clearfix"></div>
						</a>
					</li>
					<li className={classNames( {active: this.props.activeView === "security"} )}>
						<a href="javascript:;" onClick={this.props.onChangeActiveView.bind( this, "security" )}>
							<img src="web/img/ico-46.svg" alt="" />
							<span className="contact-info">
								<Translation textId="web.settings.menu.security" />
								<div className="date">
									<Translation textId="web.settings.menu.security.text" />
								</div>
							</span>
							<div className="clearfix"></div>
						</a>
					</li>
					<li className={classNames( {active: this.props.activeView === "application"} )}>
						<a href="javascript:;" onClick={this.props.onChangeActiveView.bind( this, "application" )}>
							<img src="web/img/ico-47.svg" alt="" />
							<span className="contact-info">
								<Translation textId="web.settings.menu.application" />
								<div className="date">
									<Translation textId="web.settings.menu.application.text" />
								</div>
							</span>
							<div className="clearfix"></div>
						</a>
					</li>
					<li className={classNames( {active: this.props.activeView === "info"} )}>
						<a href="javascript:;" onClick={this.props.onChangeActiveView.bind( this, "info" )}>
							<img src="web/img/ico-48.svg" alt="" />
							<span className="contact-info">
								<Translation textId="web.settings.menu.info" />
								<div className="date">
									<Translation textId="web.settings.menu.info.text" />
								</div>
							</span>
							<div className="clearfix"></div>
						</a>
					</li>
					<li className={classNames( {active: this.props.activeView === "notifications"} )}>
						<a href="javascript:;" onClick={this.props.onChangeActiveView.bind( this, "notifications" )}>
							<img src="web/img/ico-48.svg" alt="" />
							<span className="contact-info">
								<Translation textId="web.settings.menu.notifications" />
								<div className="date">
									{ this.renderNotificationsContent() }
								</div>
							</span>
							<div className="clearfix"></div>
						</a>
					</li>
					{this.renderAdmin()}
				</ul>
			</div>
		);
	}

	static get propTypes() {
		return {
			activeView: React.PropTypes.string.isRequired,
			onChangeActiveView: React.PropTypes.func.isRequired,
			isAdmin: React.PropTypes.bool.isRequired
		};
	}
}

export default GroupListListBar;
