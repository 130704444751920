import Rx from "rx";
import _ from "lodash";

import profileRepositoryLocator from "../repositories/locators/profile.js";
import configuration from "../../common/configuration.js";

let defaultProfile = {};

class ProfileService {
	constructor( ) {
		this._profileRepository = profileRepositoryLocator();
		this.mutationObservable = new Rx.ReplaySubject( 1 );
	}

	dropDataAsync( ) {
		return this._profileRepository.dropDataAsync().tap( () => {
			this.mutationObservable.onNext();
		} );
	}

	fixProfile( profile ) {
		if ( !profile ) {
			return profile;
		}
		if ( profile.useVirtualKeyboard !== !!profile.useVirtualKeyboard ) {
			profile.useVirtualKeyboard = configuration.getUseVirtualKeyboard();
		}
		return profile;
	}

	initAsync( encryptionKey, positionBit /*0 or 1*/ ) {
		if ( this._isInitialized ) {
			throw new Error( "Profile service already initialized" );
		}
		this._isInitialized = true;
		return (
			this._profileRepository.initAsync( encryptionKey, positionBit )
				.tap( profile => {
					this.fixProfile( profile );
					this.mutationObservable.onNext( profile );
				} )
		);
	}

	uninitAsync( ) {
		return (
			this._profileRepository.uninitAsync()
				.tap( () => {
					this._isInitialized = false;
					this.mutationObservable = new Rx.ReplaySubject( 1 );
				} )
		);
	}

	isInitialized( ) {
		return this._isInitialized;
	}

	getProfileAsync( ) {
		return this.mutationObservable.filter( profile => !!profile ).take( 1 );
	}

	getProfileThen() {
		return new Promise( ( resolve, reject ) => {
			this.getProfileAsync().subscribe( resolve, reject );
		} );
	}

	updateProfileAsync( profileChanges ) {
		// let oldLanguage = translate.getCurrentLanguage().getId();
		// profileChanges.language && translate.setLanguage( [ profileChanges.language ] );
		return (
			this.mutationObservable.take( 1 )
				.map( profile => this.fixProfile( _.assign( {}, profile, profileChanges ) ) )
				.flatMap( newProfile => this._profileRepository.updateProfileAsync( profileChanges )
					.tap( () => {
						this.fixProfile( newProfile );
						this.mutationObservable.onNext( newProfile );
					} )
					.tapOnError( () => {
						// profileChanges.language && translate.setLanguage( [ oldLanguage ] );
					} )
				)
		);
	}

	observeProfile() {
		return this.mutationObservable.filter( profile => !!profile );
	}

	updateProfileThen( profileChanges ) {
		return new Promise( ( resolve, reject ) => {
			this.updateProfileAsync( profileChanges ).subscribe( resolve, reject );
		} );
	}

	isExistAsync( ) {
		return (
			this._profileRepository.isAnyDataAsync()
		);
	}
}

export default ProfileService;
