import _ from "lodash";

import serviceLocator from "../../../api/services/locators/worker.client.js";
import PromptParticipantsBase from "../common/prompt.participants.base.jsx";
import {sortContacts} from "../../../common/utils.js";

class PromptParticipants extends PromptParticipantsBase {
	constructor() {
		super();
		this.headerTextId = "group.add.participants.header";
		this.descriptionTextId = "group.add.participants.text";
	}

	getButtonCaption() {
		if ( this.state.selectedIds.length === 0 ) {
			return "group.add.participants.button.empty";
		}
		return "group.add.participants.button";
	}

	isButtonEnabled() {
		return !this.state.done;
	}

	componentWillMount() {
		this._contactsSubscription = (
			serviceLocator()
				.observeContactList()
				.subscribe( contacts => {
					this.setState( {
						contacts: sortContacts( _.filter( contacts, {
							status: "active", multidescriptionId: -1
						} ) )
					} );
				} )
			);
	}

	componentWillUnmount() {
		this._contactsSubscription.dispose();
	}
}

export default PromptParticipants;
