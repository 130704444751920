import React from "react";
import FullScreen from "../../components/fullscreen.jsx";
import TopLevel from "../common/top.level.view.jsx";
import ImageButton from "../../components/button.image.jsx";
import OnlineStatus from "../../components/online.status.jsx"

class AcceptView extends React.Component {
	constructor() {
		super();
		this._nicknameChanged = this._nicknameChanged.bind( this );
		this._acceptClick = this._acceptClick.bind( this );
		this._rejectClick = this._rejectClick.bind( this );
		this.state = {
			nickname: ""
		};
	}
	_nicknameChanged( newValue ) {
		this.setState( { nickname: newValue } );
	}
	_acceptClick() {
		this.props.onAccept( this.state.nickname );
	}
	_rejectClick() {
		this.props.onReject();
	}
	render() {
		return (
			<FullScreen>
				<TopLevel id="screen-transition-group">
					<header>
						<ImageButton type="icon-header-close" onClick={onBack}/>
						TRANSITION GROUP
						<OnlineStatus/>
					</header>
					<main>
						<div className="btns">
							<div className="btn small red-border transparent" onClick={ this._rejectClick }>REJECT</div>
							<div className="btn small green transparent" onClick={ this._acceptClick }>ACCEPT</div>
						</div>
						<div className="big-text">Your nickname in group</div>
						<div className="small-text">— Other will see you as:</div>
						<input placeholder="Nickname" type="text" value={ this.state.nickname } onChange={ this._nicknameChanged } />
					</main>
				</TopLevel>
			</FullScreen>
		);
	}
}

AcceptView.propTypes = {
	onAccept: React.PropTypes.func.isRequired,
	onReject: React.PropTypes.func.isRequired
};
export default AcceptView;
