"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _triplesec = require("triplesec");

var _secureBufferContainer = require("../secure.container/locators/secure.buffer.container.js");

var _secureBufferContainer2 = _interopRequireDefault(_secureBufferContainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AES = _triplesec.ciphers.AES;


function usingAes(keyTransformedBuffer, func) {
	//wrapper to create and destroy aes object
	keyTransformedBuffer.useAsBuffer(function (insecureKey) {
		var keyCopy = (0, _secureBufferContainer2.default)().allocatePlain(insecureKey.length);
		try {
			keyCopy.useAsBuffer(function (insecureKeyCopy) {
				keyCopy.useAsUint32Array(function (insecureKeyCopy32) {
					insecureKey.copy(insecureKeyCopy);
					var keyWordArray = new _triplesec.WordArray(insecureKeyCopy32);
					keyWordArray.endian_reverse();
					var aes = new AES({ clone: function clone() {
							return keyWordArray;
						} });
					try {
						func({
							encryptBlock: function encryptBlock(insecureUint32Array, offset) {
								reverseEndiannesUint32Array(insecureUint32Array);
								aes.encryptBlock(insecureUint32Array, offset);
								reverseEndiannesUint32Array(insecureUint32Array);
							},
							decryptBlock: function decryptBlock(insecureUint32Array, offset) {
								reverseEndiannesUint32Array(insecureUint32Array);
								aes.decryptBlock(insecureUint32Array, offset);
								reverseEndiannesUint32Array(insecureUint32Array);
							}
						});
					} finally {
						aes.scrub();
					}
				});
			});
		} finally {
			keyCopy.dispose();
		}
	});
};

function xor(a, x) {
	var length = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : a.length;

	for (var i = 0; i < length; i++) {
		a[i] ^= x[i];
	}
}

function xorInside(buffer, sourceIndex, targetIndex, length) {
	for (var i = 0; i < length; i++) {
		buffer[i + targetIndex] ^= buffer[i + sourceIndex];
	}
}

function reverseEndiannesUint32Array(ar) {
	//TODO: do not reverse for BE systems
	_triplesec.WordArray.prototype.endian_reverse.call({ words: ar });
}

var inplaceCrypto = {
	encryptAesCbc: function encryptAesCbc(dataTransformedBuffer, keyTransformedBuffer, ivBuffer) {
		var uint32length = dataTransformedBuffer.byteLength / 4;
		if (uint32length % 4) {
			throw new Error("dataManagedBuffer size must be a multiplication of 16");
		}
		if (!uint32length) {
			return;
		}
		dataTransformedBuffer.useAsUint32Array(function (insecureUint32Array) {
			dataTransformedBuffer.useAsBuffer(function (insecureBuffer) {
				usingAes(keyTransformedBuffer, function (aes) {
					xor(insecureBuffer, ivBuffer, 16);
					aes.encryptBlock(insecureUint32Array, 0);
					for (var i = 4; i < uint32length; i += 4) {
						xorInside(insecureBuffer, (i - 4) * 4, i * 4, 16);
						aes.encryptBlock(insecureUint32Array, i);
					}
				});
			});
		});
	},
	decryptAesCbc: function decryptAesCbc(dataTransformedBuffer, keyTransformedBuffer, ivBuffer) {
		var uint32length = dataTransformedBuffer.byteLength / 4;
		if (uint32length % 4) {
			throw new Error("dataManagedBuffer size must be a multiplication of 16");
		}
		if (!uint32length) {
			return;
		}
		dataTransformedBuffer.useAsUint32Array(function (insecureUint32Array) {
			dataTransformedBuffer.useAsBuffer(function (insecureBuffer) {
				usingAes(keyTransformedBuffer, function (aes) {
					for (var i = uint32length - 4; i > 0; i -= 4) {
						aes.decryptBlock(insecureUint32Array, i);
						xorInside(insecureBuffer, (i - 4) * 4, i * 4, 16);
					}

					aes.decryptBlock(insecureUint32Array, 0);
					xor(insecureBuffer, ivBuffer, 16);
				});
			});
		});
	}
};

exports.default = inplaceCrypto;