import React from "react";
import Rx from "rx";
import classNames from "classNames";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

import Translation from "../../../components/translation.jsx";
import configuration from "../../../../common/configuration.js";

class MobileMenu extends React.Component {
	constructor() {
		super();
		this.state = {};
		this._service = remoteServiceLocator();
	}

	componentWillMount() {
		this._countSubscription = (
			Rx.Observable.combineLatest(
				this._service.observeUnreadContacts(),
				this._service.observeUnreadGroups(),
				this._service.observeUnreadShared(),
				this._service.observeGlobalUserType(),
				( contactCount, groupCount, sharedCount, { isPrivileged } ) =>
					( { contactCount, groupCount, sharedCount, isPrivileged } )
			)
			.subscribe( data => {
				this.setState( data );
			} )
		);
	}

	componentWillUnmount() {
		this._countSubscription.dispose();
	}

	renderCount( count ) {
		if ( !count ) {
			return null;
		}
		return <div className="amount">{count}</div>;
	}

	renderSharedContacts() {
		if ( !configuration.getShowWorkgroups() || !this.state.isPrivileged ) {
			return null;
		}
		let sharedcontactsClassName = classNames( {
			sharedcontacts: true,
			active: this.props.active === "sharedcontacts"
		} );
		return (
			<a className={sharedcontactsClassName} href="#sharedcontacts">
				<div className="visible-xs mobile-menu">
					<Translation textId="web.menu.sharedcontacts" />
				</div>
				{ this.renderCount( this.state.sharedCount ) }
			</a>
		);
	}

	render() {
		let chatsClassName = classNames( {
			chat: true,
			active: this.props.active === "chats"
		} );
		let contactsClassName = classNames( {
			face: true,
			active: this.props.active === "contacts"
		} );
		let groupsClassName = classNames( {
			groups: true,
			active: this.props.active === "groups"
		} );
		let settingsClassName = classNames( {
			"settings-ico": true,
			active: this.props.active === "settings"
		} );
		return (
			<div>
				<a className={chatsClassName} href="#chats">
					<div className="visible-xs mobile-menu">
						<Translation textId="web.menu.chats" />
					</div>
					{ this.renderCount(
						( this.state.contactCount | 0 )
						+( this.state.groupCount | 0 )
						+( ( this.state.isPrivileged && this.state.sharedCount ) | 0 )
					) }
				</a>
				<a className={contactsClassName} href="#contacts">
					<div className="visible-xs mobile-menu">
						<Translation textId="web.menu.contacts" />
					</div>
					{ this.renderCount( this.state.contactCount ) }
				</a>
				<a className={groupsClassName} href="#groups">
					<div className="visible-xs mobile-menu">
						<Translation textId="web.menu.groups" />
					</div>
					{ this.renderCount( this.state.groupCount ) }
				</a>
				{ this.renderSharedContacts() }
				<a className={settingsClassName} href="#settings">
					<div className="visible-xs mobile-menu">
						<Translation textId="web.menu.settings" />
					</div>
				</a>
				<div className="clearfix"></div>
				{this.props.children}
			</div>
		);
	}

	static get propTypes() {
		return {
			active: React.PropTypes.oneOf( [ "chats", "contacts", "groups", "settings", "sharedcontacts" ] ).isRequired
		};
	}
}

export default MobileMenu;
