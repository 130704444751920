"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _prngBase = require("./prng.base.js");

var _prngBase2 = _interopRequireDefault(_prngBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PseudoRandomGeneratorExtended = function (_GeneratorBase) {
	_inherits(PseudoRandomGeneratorExtended, _GeneratorBase);

	function PseudoRandomGeneratorExtended(random) {
		_classCallCheck(this, PseudoRandomGeneratorExtended);

		return _possibleConstructorReturn(this, (PseudoRandomGeneratorExtended.__proto__ || Object.getPrototypeOf(PseudoRandomGeneratorExtended)).call(this, random));
	}

	_createClass(PseudoRandomGeneratorExtended, [{
		key: "getUInt32Then",
		value: function getUInt32Then() {
			return this.pseudoRandomDataThen(4).then(function (buffer) {
				return buffer.readUInt32LE(0);
			});
		}
	}, {
		key: "getIntRangeThen",
		value: function getIntRangeThen(min, max) {
			var _this2 = this;

			var rangeLen = max - min;
			var maxRange = 4 * (1 << 30);
			if (rangeLen <= 0 || isNaN(rangeLen)) {
				throw new Error("Invalid range " + min + "-" + max);
			}
			if (rangeLen > maxRange) {
				throw new Error("getIntRangeThen expects rangeLen to be up to 32 bits");
			}

			var validResultRange = maxRange - maxRange % rangeLen - 1;

			var _uint2Result = void 0;
			_uint2Result = function uint2Result(uint) {
				if (uint <= validResultRange) {
					return uint % rangeLen + min;
				}
				return _this2.getUInt32Then().then(function (uint) {
					return _uint2Result(uint);
				});
			};
			return this.getUInt32Then().then(function (uint) {
				return _uint2Result(uint);
			});
		}
	}]);

	return PseudoRandomGeneratorExtended;
}(_prngBase2.default);

;

exports.default = PseudoRandomGeneratorExtended;