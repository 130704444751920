import Rx from "rx";
import TimeHub from "../hubs/time.js";
import configuration from "../../common/configuration.js";

class TimeService {
	constructor( ) {
		this._resyncThen = new Promise( resolve => {
			configuration.monitorSetConfiguration().subscribe( () => {
				var hub = new TimeHub;
			  hub.getServerTimeAsync().subscribe( ( { serverTime, sentAt } ) => {
					let diff = serverTime - ( sentAt + + new Date ) / 2;
					resolve( diff );
				} );
			} );
		} );

		//TODO: more events to resync time
	}

	monitorTimeResync( ) {
		return Rx.Observable.fromPromise( this._resyncThen );
	}

	local2ServerTimeAsync( ts ) {
		return Rx.Observable.fromPromise( this.local2ServerTimeThen( ts ) );
	}

	local2ServerTimeThen( ts ) {
		return this._resyncThen.then( diff => ts + diff );
	}

	server2LocalTimeAsync( ts ) {
		return Rx.Observable.fromPromise( this.server2LocalTimeThen( ts ) );
	}

	server2LocalTimeThen( ts ) {
		return this._resyncThen.then( diff => ts - diff );
	}
}


export default TimeService;
