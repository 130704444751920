import React from "react";
import classNames from "classnames";
import _ from "lodash";
import { ProgressBar } from "react-bootstrap";

import Confirm from "../common/confirm.jsx";
import Timestamp from "../../../components/timestamp.jsx";
import Translation from "../../../components/translation.jsx";

import MessageReceiver from "../../../../api/models/message/message.receiver.js";
import MessageDeserializer from "../../../../api/models/message/message.deserializer.js";
import remoteServiceLocator from "../../../../api/services/locators/remote.js";
import {deserializeFromBase64StringAsync} from "../../../../api/models/message/technical.js";
import {getContactInitials, createBlobFromStream} from "../../../../common/utils.js";
import prepareMessage from "../../../components/prepareMessage.jsx"
import audioManager from "./audio.manager.js";
import audioDownloader from "../../../../api/services/audio.downloader.js";
import autoPlayer from "../../../audio/autoplayer.js";
import Forward from "./forward.jsx";

function toMMSS( timespan ) {
    var sec_num = Math.floor( timespan/1000 );
    var minutes = Math.floor( sec_num / 60 );
    var seconds = sec_num - ( minutes * 60 );

    if ( minutes < 10 ) { minutes = "0" + minutes; }
    if ( seconds < 10 ) { seconds = "0" + seconds; }
    return minutes + ":" + seconds;
}

class MessageView extends React.Component {
	constructor() {
		super();
		this.state = {
			popup: null,
			timestamp: null
		};
		this.onDeleteClick = this.onDeleteClick.bind( this );
		this.doDelete = this.doDelete.bind( this );
		this.onEditClick = this.onEditClick.bind( this );
    this.onForwardClick = this.onForwardClick.bind( this );
    this.onReplyClick = this.onReplyClick.bind( this );
		this._service = remoteServiceLocator();
	}

	componentWillMount() {
		this._service.serverTimeStampToLocalAsync( this.props.message.timestamp )
			.subscribe( timestamp => {
				this.setState( { timestamp } );
			} );
	}

	onDeleteClick() {
		this.setState( {
			popup: () =>
				<Confirm
					titleTextId="web.message.delete.title"
					yesButtonTextId="web.message.delete.button.yes"
					onDone={ this.doDelete }
				/>
		} );
	}

	doDelete( isConfirmed ) {
		if ( this.state.isInProgress ) {
			return;
		}
		if ( !isConfirmed ) {
			this.setState( { popup: null } );
			return;
		}
		this.setState( { isInProgress: true } );
		this.props.doDeleteAsync( this.props.message ).subscribe( () => {
			this.setState( { isInProgress: false, popup: null } );
		}, error => {
			console.error( error );
			this.setState( { isInProgress: false, popup: null } );
		} );
	}

	onEditClick() {
		this.props.onEdit( this.props.message );
	}

  onForwardClick() {
    this.setState( {
      popup: () =>
        <Forward
          contact={ this.props.contact }
          message={ this.props.message }
          onBack={ () => { this.setState( { popup: null } ); } }
        />
    } );
  }

  onReplyClick() {
    this.props.onReply( this.props.message );
  }

	renderPopup() {
		if ( !this.state.popup ) {
			return null;
		}
		return this.state.popup();
	}

	renderContent() {
		let { message, contact, isEditing } = this.props;
		if ( message.type === "SERVICE" ) {
			return <Translation textId={ message.text } params={ [ message.sender ] }/>;
		}
		if ( message.fileToken && message.contentType ) {
			if ( message.contentType.split( "/" )[ 0 ] === "image" ) {
				return <ImageMessageContent message={ message } />;
			}
			if ( message.milliseconds ) {
				return <AudioMessageContent message={ message } />;
			}
			return <FileMessageContent message={ message } />;
		}

		switch ( message.contentType ) {
			case "text/plain":
				if ( isEditing ) {
					return (
						<div style={ { border: "thin dotted yellow" } }>
							{ prepareMessage( message.text ) }
						</div>
					);
				}
				return message.text;
			case "contact":
				return <ContactMessageContent message={ message } contact={ contact }/>;
			case "group":
				return <GroupMessageContent message={ message }/>;
			case "workgroup":
				return <WorkgroupMessageContent message={ message }/>;
			case "delete":
				return <DeleteMessageContent message={ message }/>;
			default:
				return <span>Unknown message</span>;
		}
	}

	renderSender() {
		let { message, shouldRenderSender } = this.props;
		if ( !shouldRenderSender ) {
			return null;
		}
		if ( ( message.sender === "Me" ) && ( message.type === "OUTGOING" ) ) {
			return null;
		}
		if ( _.isEmpty( message.sender ) ) {
			return null;
		}
		let initials = getContactInitials( message.sender );
		return (
			<div className="box-name">
				<div className="circle blue">{ initials }</div>
				{ message.sender }
			</div>
		);
	}

	renderReadMark() {
		let { message } = this.props;
		if ( !message.isRead || ( message.type !== "OUTGOING" ) ) {
			return null;
		}
		return (
			<span className="double-check">
				<img src="web/img/ok.svg" alt=""/>
			</span>
		);
	}

	renderDeleteButton(offset) {
		let { message } = this.props;
		if ( ( message.type !== "OUTGOING" ) || ( message.contentType === "delete" ) ) {
			return null;
		}
		return (
			<span className="delete" style={{right:offset}} onClick={ this.onDeleteClick } key="delete">
				<img src="web/img/list_delete.svg" alt=""/>
			</span>
		);
	}

	renderEditButton(offset) {
		let { message, onEdit } = this.props;
		if ( ( message.type !== "OUTGOING" ) || ( message.contentType === "delete" ) || !onEdit || !message.text ) {
			return null;
		}

		return (
			<span className="delete" style={{right:offset}} onClick={ this.onEditClick } key="edit">
				<img src="web/img/list_rename.svg" alt=""/>
			</span>
		);
	}

  renderForwardButton(offset) {
		let { message } = this.props;
    let { text, fileToken, contentType } = message;
		if ( ( !text && !fileToken ) || ( contentType === "delete" ) ) {
			return null;
		}
		return (
			<span className="delete" style={{right:offset}} onClick={ this.onForwardClick } key="forward">
				<img src="web/img/forward.svg" style={ { top: 0, left: 0, width: "24px", height: "24px" } } alt="" />
			</span>
		);
	}

  renderReplyButton(offset) {
    let { message, onReply } = this.props;
		if ( ( message.type === "OUTGOING" ) || ( message.contentType === "delete" ) || !onReply || !message.text ) {
			return null;
		}

		return (
			<span className="delete" style={{right:offset}} onClick={ this.onReplyClick } key="reply">
				<img src="web/img/reply.svg" style={ { top: 0, left: 0, width: "24px", height: "24px" } } alt=""/>
			</span>
		);
  }

  renderButtons() {
    let buttons = [];
    let methods = [this.renderDeleteButton, this.renderEditButton, this.renderForwardButton, this.renderReplyButton];
    for(let i = 0; i < methods.length; i++) {
      let button = methods[i].call(this, (buttons.length * 25) + 'px');
      if (!button) {
        continue;
      }
      buttons.push(button);
    }
    return buttons;
  }

	renderTime() {
		return (
			<div className="time">
				{
					this.props.message.replaceIndex === undefined
					? null
					: <Translation textId="chat.message.edited.text" />
				}
				{" "}
				{
					this.state.timestamp
					? <Timestamp value={ this.state.timestamp } />
					: null
				}
			</div>
		);
	}

  renderReply() {
    let {message, contact} = this.props;
    let {replyTo, replyingSender} = message;
    if (!replyTo || !replyTo.replySenderPid) {
      return null;
    }
    let style = {
      padding: "10px",
      borderLeft: "#444 4px solid"
    };
    let sender = typeof replyingSender === 'string' ? replyingSender : contact.name;
    return (
      <div style={style}>
        <b>{ sender }</b>: { replyTo.replyText }
      </div>
    );
  }

	render() {
		let {message} = this.props;
		let className = classNames( [ "answer", {
			right: message.type === "OUTGOING",
			left: message.type === "INCOMING"
		} ] );
		return (
			<div className={ className }>
				{ this.renderSender() }
				<div className="text">
          { this.renderReply() }
					{ this.renderContent() }
					{ this.renderReadMark() }
          { this.renderButtons() }
				</div>
				{ this.renderTime() }
				{ this.renderPopup() }
			</div>
		);
	}
}

export class SendingMessage extends React.Component {
	renderText() {
		let { text, timestamp, isSent } = this.props.message;
		if ( isSent ) {
			return (
				<div>
					<MessageView
						message={ {
							contentType: "text/plain",
							text,
							type: "OUTGOING",
							timestamp: +new Date
						} }
						shouldRenderSender={ false }
					/>
				</div>
			);
		}
		return (
			<div style={ { opacity: 0.5 } }>
				<MessageView
					message={ {
						contentType: "text/plain",
						text,
						type: "OUTGOING"
					} }
					shouldRenderSender={ false }
				/>
			</div>
		);
	}

	renderFile() {
		let { message } = this.props;
		if ( message.thumbnailBase64 ) {
			return <SendingImage { ...message } />;
		}
		if ( message.milliseconds ) {
			return <SendingAudio { ...message } />;
		}
		return <SendingFile { ...message } />;
	}

	render() {
		switch( this.props.message.type ) {
			case "text":
				return this.renderText();
			case "file":
				return this.renderFile();
		}
		return null;
	}
}

class SendingFile extends React.Component {
	renderProgress() {
		let { sent, total } = this.props;
		if ( !total ) {
			return null;
		}
		let percent = ( 100 * sent / total) | 0;
		return <ProgressBar
			now={percent}
			label={`${percent}%`}
		/>;
	}

	render() {
		return (
			<div className="answer right">
				<div className="text">
					Sending { this.props.name }
					{ this.renderProgress() }
				</div>
				<div className="time">...</div>
			</div>
		);
	}
}

class SendingImage extends SendingFile {
	render() {
		return (
			<div className="answer right">
				<div className="text">
					<img src={`data:image/${this.props.thumbnailBase64}`} style={{maxWidth: "100%"}} />
					<br />
					Sending { this.props.name }
					{this.renderProgress()}
				</div>
				<div className="time">...</div>
			</div>
		);
	}
}

class SendingAudio extends SendingFile {
	render() {
		return (
			<div className="answer right">
				<div className="text">
					{this.renderProgress()}
				</div>
				<div className="time">...</div>
			</div>
		);
	}
}

class DeleteMessageContent extends React.Component {
	render() {
		return <Translation textId="chat.message.deleted.text" />;
	}
}

class FileMessageContent extends React.Component {
	constructor() {
		super();
		this.state = {}
		this.downloadClick = this.downloadClick.bind( this );
	}

	downloadToBlobThen() {
		return new Promise( ( resolve, reject ) => {
			new MessageReceiver( { token: this.props.message.fileToken } )
				.getStreamFactoryThen( "" )
				.then( streamFactory => new MessageDeserializer( streamFactory ).getThen() )
				.then( message => {
					if ( message.getAttachments().length === 0 ) {
						throw new Error( "No attachments found" );
					}
					return message.getAttachments()[ 0 ].createBinaryStream( "buffered" );
				} )
				.then( fileBinaryStream => {
					let { progress, completition } = createBlobFromStream(
						fileBinaryStream,
						this.props.message.contentType
					);
					progress.subscribe( ( { total, received } ) => {
						this.setState( { total, received } );
					} );

					completition.subscribe( resolve, reject );
				} );
		} );
	}

  downloadToBufferThen() {
    return new Promise( ( resolve, reject ) => {
      new MessageReceiver( { token: this.props.message.fileToken } )
				.getStreamFactoryThen( "" )
				.then( streamFactory => new MessageDeserializer( streamFactory ).getThen() )
				.then( message => {
					if ( message.getAttachments().length === 0 ) {
						throw new Error( "No attachments found" );
					}
					return message.getAttachments()[ 0 ].createBinaryStream( "buffered" );
				} )
				.then( binaryStream => {
          binaryStream.getAllBytesCb( resolve );
        } )
        .catch( reject );
    } );
  }

	downloadClick( event ) {
		event.preventDefault();
		this.setState( { isDownloading: true } );
		this.downloadToBlobThen()
			.then( blob => {
					let blobUrl = URL.createObjectURL( blob );
					this.setState( { blob, blobUrl, isDownloading: false } );
				}, error => {
					this.setState( { fileStateMessage: error.message, isDownloading: false } );
				} );
	}

	componentWillUnmount() {
		if ( this.state.blobUrl ) {
			URL.revokeObjectURL( this.state.blobUrl );
		}
	}

	renderDownload() {
		if ( this.state.blob || this.state.isDownloading ) {
			return null;
		}

		return (
			<a
				href="javascript:;"
				onClick={ this.downloadClick }
			>
				<Translation textId="web.file.download" />
			</a>
		);
	}

	renderSave() {
		if ( !this.state.blob ) {
			return null;
		}
		let { message } = this.props;

		let ua = window.navigator.userAgent;
		let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
		let webkit = !!ua.match(/WebKit/i);
		let iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
		if ( iOSSafari ) {
			return this.renderMobileSafariSave();
		}
		return (
			<a
				href={ this.state.blobUrl }
				download={ message.fileName }
				>
				<Translation textId="web.file.save" />
			</a>
		);
	}

	renderMobileSafariSave() {
		return (
			<div>
				<a
					href={ this.state.blobUrl }
					onClick={ (e) => { e.preventDefault(); } }
				>
					<Translation textId="web.file.save.mobsafari" />
			  </a>
			</div>
		);
	}

	renderOpen() {
		if ( !this.state.blob ) {
			return null;
		}
		return (
			<a
				href={ this.state.blobUrl }
				target="_blank"
			>
				<Translation textId="web.file.open" />
			</a>
		);
	}

	renderProgress() {
		let { received, total, blob } = this.state;
		if ( !total || blob) {
			return null;
		}
		let percent = ( 100 * received / total) | 0;
		return <ProgressBar
			now={percent}
			label={`${percent}%`}
		/>;
	}

	render() {
		let { message } = this.props;
		return (
			<span>
				<b><Translation textId="web.file.header" /></b><br />
				{ message.fileName }
				<br/>
				{ this.renderDownload() }
				{ this.renderSave() }
				{ " " }
				{ /*this.renderOpen()*/ null }
				{ this.renderProgress() }
				{ this.state.fileStateMessage || "" }
			</span>
		);
	}
}

class ImageMessageContent extends FileMessageContent {
	renderThumbnail() {
		return <img src={`data:image/${this.props.message.thumbnailBase64}`} style={{maxWidth: "100%"}} />;
	}

	render() {
		let { message } = this.props;
		return (
			<span>
				<b><Translation textId="web.file.header" /></b><br />
				{ this.renderThumbnail() }
				<br />
				{ message.fileName }
				<br/>
				{ this.renderDownload() }
				{ this.renderSave() }
				{ " " }
				{ /*this.renderOpen()*/ null }
				{ this.renderProgress() }
				{ this.state.fileStateMessage || "" }
			</span>
		);
	}
}

class AudioMessageContent extends FileMessageContent {
	constructor() {
    super();
    this.state = {
			...this.state,
      isPlaying: false,
      isLoaded: false,
      position: 0
    };
    this.onRangeChange = this.onRangeChange.bind( this );
    this.onStop = this.onStop.bind( this );
    this.onPlay = this.onPlay.bind( this );
    this.refreshTime = this.refreshTime.bind( this );
  }

  onRangeChange( event ) {
		if ( !this.state.blobUrl ) {
			return;
		}
		audioManager.seekPlay( event.target.value / 1000, this.props.message.id );
  }

  onStop( event ) {
    event.preventDefault();
    event.stopPropagation();
		if ( !this.state.blobUrl ) {
			return;
		}
		audioManager.pausePlay( this.props.message.id );
    this.setState( { isPlaying: false } );
  }

  onPlay( event ) {
    event && event.preventDefault();
    event && event.stopPropagation();
    if ( this.state.isPlaying ) {
      return;
    }
		if ( !this.state.blobUrl ) {
			return;
		}
		audioManager.startPlay( this.state.blobUrl, this.props.message.id, !event );
    this.setState( { isPlaying: true } );
		this.refreshTime();
  }

  refreshTime() {
    let { blobUrl } = this.state;
    if ( this._timeout ) {
      clearTimeout( this._timeout );
      delete this._timeout;
    }

    if ( !blobUrl ) {
      return;
    }

    let { currentId, currentTime, status, isEnded } = audioManager.getPlayState();

    if ( currentId !== this.props.message.id ) {
      this.setState( {
        position: 0,
        isPlaying: false
      } );
      return;
    }

    this.setState( {
      position: currentTime * 1000,
      isPlaying: status === "playing" || status === "loading"
    } );

    if ( isEnded ) {
      this.setState( { position: 0 } );
      autoPlayer.playNext( this );
      return;
    }

    this._timeout = setTimeout( this.refreshTime, 100 );
  }

  getMilliseconds() {
    return this.props.message.milliseconds | 0;
  }

	componentDidMount() {
		this._downloadPromise = audioDownloader.queryAudio( this.props.message );

		this._downloadPromise.then( audioManager.decodeThen ).then(
			blobUrl => {
        this.setState( { blobUrl } );
			},
			error => {
				this.setState( { fileStateMessage: error.message } );
			}
		);
    autoPlayer.addAudioMessageComponentInstance( this );
	}

  componentWillUnmount() {
    if ( this._timeout ) {
      clearTimeout( this._timeout );
      delete this._timeout;
    }
    audioManager.stopPlay( this.props.message.id );
    audioDownloader.cancelQuery( this._downloadPromise );
    autoPlayer.removeAudioMessageComponentInstance( this );
    this.state.blobUrl && URL.revokeObjectURL( this.state.blobUrl );
  }

  renderButton() {
		if ( !this.state.blobUrl ) {
      return (
        <div onClick={ this.onStop }>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={34} height={34}>
            <path
              d="M256,475c-120.942,0-219-98.058-219-219    S135.058,37,256,37s219,98.058,219,219S376.942,475,256,475z"
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              strokeWidth="10"
            />
            <path
              d="M266,281.798c0,2.321-2.238,4.202-5,4.202h-14    c-2.761,0-5-1.881-5-4.202V88.491c0-2.321,2.239-3.491,5-3.491h14c2.762,0,5,1.17,5,3.491V281.798z"
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              strokeWidth="10"
            />
            <path
              d="M246.691,287c-1.953,0-4.691-2.819-4.691-5.581    v-13.576c0-2.762,2.738-3.843,4.692-3.843h162.616c1.953,0,2.691,1.081,2.691,3.842v13.578c0,2.761-0.738,5.58-2.691,5.58H246.691    z"
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              strokeWidth="10"
            />
          </svg>
        </div>
      );
		}

    if ( this.state.isPlaying ) {
      return (
        <div onClick={ this.onStop }>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" width={34} height={34}>
            <path fill="#263238" fillOpacity=".5" d="M9.2 25c0 .5.4 1 .9 1h3.6c.5 0 .9-.4.9-1V9c0-.5-.4-.9-.9-.9h-3.6c-.4-.1-.9.3-.9.9v16zm11-17c-.5 0-1 .4-1 .9V25c0 .5.4 1 1 1h3.6c.5 0 1-.4 1-1V9c0-.5-.4-.9-1-.9 0-.1-3.6-.1-3.6-.1z" />
          </svg>
        </div>
      );
    }
    return (
      <div onClick={ this.onPlay }>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" width={34} height={34}>
          <path fill="#263238" fillOpacity=".5" d="M8.5 8.7c0-1.7 1.2-2.4 2.6-1.5l14.4 8.3c1.4.8 1.4 2.2 0 3l-14.4 8.3c-1.4.8-2.6.2-2.6-1.5V8.7z" />
        </svg>
      </div>
    );
  }

  renderRange() {
    return (
      <input
        type="range"
        min={ 0 }
        max={ this.getMilliseconds() }
        value={ this.state.position }
        onChange={ this.onRangeChange }
      />
    );
  }

  renderPosition() {
    return (
      <div style={ { display: "inline-block", width: "7rem" } }>
        { toMMSS( this.state.position ) }
      </div>
    );
  }

  renderTime() {
    return (
      <div style={ { display: "inline-block", width: "7rem" } }>
        { toMMSS( this.getMilliseconds() ) }
      </div>
    );
  }

  render() {
    return (
      <div style={ { display: "flex", height: "50px", alignItems: "center" } }>
        { this.renderButton() }
        { this.renderPosition() }
        { this.renderRange() }
        { this.renderTime() }
        { this.state.fileStateMessage || null }
      </div>
    );
  }
}

class ContactMessageContent extends React.Component {
	constructor() {
		super();
		this.state = { isAccepted: false };
		this.onAcceptClick = this.onAcceptClick.bind( this );
	}

	onAcceptClick() {
		if ( this.state.isAccepted ) {
			return;
		}
		this.setState( { isAccepted: true } );
		remoteServiceLocator()
			.acceptInviteByInviteIdAsync( this.props.message.inviteId, this.props.contact )
			.subscribe( () => {} );
	}

	renderAcceptButton() {
		let { isDuplicate, type } = this.props.message;
		if ( ( isDuplicate ) || ( type !== "INCOMING" ) ) {
			return null;
		}
		if ( this.state.isAccepted ) {
			return "...";
		}
		return (
			<a href="javascript:;" onClick={ this.onAcceptClick } >
				<Translation textId="chat.message.contact.accept.button" />
			</a>
		);
	}

	renderText() {
		if ( this.props.message.type === "INCOMING" ) {
			return <Translation textId="chat.message.contact.incoming.text" />;
		}
		return <Translation textId="chat.message.contact.outgoing.text" />;
	}

	render() {
		return (
			<span>
				{ this.renderText() }
				<br />
				<b>{ this.props.message.nickname }</b>
				<br />
				{ this.renderAcceptButton() }
			</span>
		);
	}
}

class GroupMessageContent extends React.Component {
	constructor() {
		super();
		this.state = { isAccepted: false };
		this.onAcceptClick = this.onAcceptClick.bind( this );
	}

	onAcceptClick() {
		if ( this.state.isAccepted ) {
			return;
		}
		this.setState( { isAccepted: true } );
		remoteServiceLocator()
			.joinGroupByInviteIdAsync( this.props.message.inviteId )
			.subscribe( () => {} );
	}

	renderAcceptButton() {
		if ( this.props.message.isDuplicate ) {
			return null;
		}
		if ( this.state.isAccepted ) {
			return "...";
		}
		return (
			<a href="javascript:;" onClick={ this.onAcceptClick } >
				<Translation textId="chat.message.group.accept.button" />
			</a>
		);
	}

	renderText() {
		if ( this.props.message.type === "INCOMING" ) {
			return <Translation textId="chat.message.group.incoming.text" />;
		}
		return <Translation textId="chat.message.group.outgoing.text" />;
	}

	render() {
		return (
			<span>
				{ this.renderText() }
				<br />
				<b>{ this.props.message.name }</b>
				<br />
				{ this.renderAcceptButton() }
			</span>
		);
	}
}

class WorkgroupMessageContent extends React.Component {
	constructor() {
		super();
		this.state = { isAccepted: false };
		this.onAcceptClick = this.onAcceptClick.bind( this );
	}

	onAcceptClick() {
		if ( this.props.message.isDuplicate ) {
			return null;
		}
		if ( this.state.isAccepted ) {
			return "...";
		}
		this.setState( { isAccepted: true } );
		remoteServiceLocator()
			.joinWorkgroupByInviteIdAsync( this.props.message.inviteId )
			.subscribe( () => {} );
	}

	renderAcceptButton() {
		if ( this.props.message.isDuplicate ) {
			return null;
		}
		return (
			<a href="javascript:;" onClick={ this.onAcceptClick } >
				<Translation textId="chat.message.workgroup.accept.button" />
			</a>
		);
	}

	renderText() {
		if ( this.props.message.type === "INCOMING" ) {
			return <Translation textId="chat.message.workgroup.incoming.text" />;
		}
		return <Translation textId="chat.message.workgroup.outgoing.text" />;
	}

	render() {
		return (
			<span>
				{ this.renderText() }
				<br />
				<b>{ this.props.message.nickname }</b>
				<br />
				{ this.renderAcceptButton() }
			</span>
		);
	}
}

export default MessageView;
