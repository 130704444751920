import React from "react";
import _ from "lodash";
import classNames from "classnames";

import TopLevel from "../common/top.level.view.jsx";
import HeaderWait from "../common/header.wait.jsx";
import FullScreen from "../../components/fullscreen.jsx";
import Translation from "../../components/translation.jsx";
import DeviceBackButton from "../../components/device.back.button.jsx";
import ImageButton from "../../components/button.image.jsx";
import OnlineStatus from "../../components/online.status.jsx"

const presets = [
    { number: 0, unit: "sec", pinTime: 0 },
    { number: 1, unit: "min", pinTime: 60 },
    { number: 10, unit: "min", pinTime: 10 * 60 },
    { number: 1, unit: "hour", pinTime: 60 * 60 },
    { number: 1, unit: "day", pinTime: 24 * 3600 },
];

const padNumber = number => {
	// if ( number === 0 ) {
	// 	return <Translation textId="options.pin.no" />
	// }
	if ( number <= 9 ) {
		return "0" + number;
	}
	return number;
};

const Preset = ( { number, pinTime, unit, isSelected, onSelect } ) => (
	<div className={ classNames( "item", { selected: isSelected } ) } onClick={ onSelect }>
		<div className="number">{ padNumber( number ) }</div>
		<div className="unit">
			<Translation
				textId={ `options.autoclean.unit.${unit}` }
				params={ [ number ] }
			/>
		</div>
	</div>
);

class PinView extends React.Component {
    constructor() {
        super();
        this.onBackPress = this.onBackPress.bind( this );
    }

    onBackPress() {
        this.props.onBack();
    }

    renderHeader() {
		if ( !this.props.isInProgress ) {
			return (
				<header>
					<ImageButton type="icon-arrow-left" onClick={ this.onBackPress }/>
					<span className="header-caption"><Translation textId="options.pin.header"/></span>
					<OnlineStatus/>
				</header>
			);
		}
		return <HeaderWait />;
	}

    onSelectPreset( pinTime ) {
        this.props.onSelect( pinTime );
    }

    getSelectedPreset() {
        return _.find( presets, { pinTime: this.props.pinTime } ) || presets[ 0 ];
    }

    render() {
        const currentPreset = this.getSelectedPreset();
        return (
			<FullScreen>
				<TopLevel>
					{ this.renderHeader() }
					<main>
						<div className="small-text">
							<Translation textId="options.pin.smalltext" />
						</div>
						<div className="selected-time-range">
							<div className="unit">
								<Translation
									textId={ `options.autoclean.unit.${currentPreset.unit}` }
									params={ [ currentPreset.number ] }
								/>
							</div>
							<div className="number">
								{ padNumber( currentPreset.number ) }
							</div>
						</div>
						<div className="time-ranges">
							{_.map( presets, preset => (
								<Preset { ...preset }
									key={ preset.pinTime }
									isSelected={ preset === currentPreset }
									onSelect={ this.onSelectPreset.bind( this, preset.pinTime ) }
								/>
							) )}
						</div>
					</main>
				</TopLevel>
				<DeviceBackButton onPress={ this.onBackPress }/>
			</FullScreen>
		);
    }
}

export default PinView;
