import React from "react";
import _ from "lodash";

import Layout, {
	ListTitleContentView,
	MainTitleContentView,
	ListbarContentView,
	MainContentView,
	MobileMenuContentView,
	CustomContentView
} from "../common/layout.jsx";
import MainTitle from "./main.title.jsx";
import ContactListListBar from "../common/contact.list.jsx";
import MainContent from "./main.content.jsx";
import NewInviteContent from "./new.invite.content.jsx";
import BusinessCardsContent from "./business.cards.content.jsx";
import NewInviteTitle from "./new.invite.title.jsx";
import MobileMenu from "../common/mobile.menu.jsx";
import ListTitle from "./list.title.jsx";
import AcceptInviteContent from "./accept.invite.content.jsx";
import AcceptInviteTitle from "./accept.invite.title.jsx";
import SendContactsContent from "./send.contacts.content.jsx";
import SendContactsTitle from "./send.contacts.title.jsx";
import BusinessCardsTitle from "./business.cards.title.jsx";
import AddModal from "./add.modal.jsx";
import Prompt from "../common/prompt.jsx";
import Confirm from "../common/confirm.jsx";
import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class ContactsView extends React.Component {
	constructor() {
		super();
		this.state = {
			mainView: "contact",
			selectedContactId: null,
			activeMobileView: "list",
			searchString: null
		};
		this._service = remoteServiceLocator();
		this.rename = this.rename.bind( this );
		this.delete = this.delete.bind( this );
		// this.sendContact = this.sendContact.bind( this );
		this.addContact = this.addContact.bind( this );

		this.invite = this.invite.bind( this );
		this.inviteExternal = this.inviteExternal.bind( this );
		this.sendContacts = this.sendContacts.bind( this );
		this.clearHistory = this.clearHistory.bind( this );
		this.onInvited = this.onInvited.bind( this );
		this.backFromBusinessCards = this.backFromBusinessCards.bind( this );
		this.selectContact = this.selectContact.bind( this );
		this.goToList = this.goToList.bind( this );
		this.acceptInvite = this.acceptInvite.bind( this );
		this.businessCards = this.businessCards.bind( this );
		this.backFromAccept = this.backFromAccept.bind( this );
		this.backFromSendContacts = this.backFromSendContacts.bind( this );
		this.clearPopup = this.clearPopup.bind( this );
		this.onSetSearchString = this.onSetSearchString.bind( this );
	}

	componentWillMount() {
		//TODO: avoid copy-paste
		let openContactIdStr = this.props.params && this.props.params[ 0 ];
		this._contactsSubscription = (
			this._service.observeContactList()
			.subscribe( contacts => {
				this.setState( { contacts } );
				if ( openContactIdStr !== undefined ) {
					let select = _.find( contacts, { id: openContactIdStr | 0 } );
					this.selectContact( select );
				}
			} )
		);
	}

	componentWillUnmount() {
		this._contactsSubscription.dispose();
	}

	getSelectedContact() {
		let { selectedContactId, contacts } = this.state;
		if ( !selectedContactId ) {
			return null;
		}
		let found = _.find( contacts, { id: selectedContactId } );
		return found || null;
	}

	getSelectedContactId() {
		let { selectedContactId, contacts } = this.state;
		if ( selectedContactId && !_.find( contacts, { id: selectedContactId } ) ) {
			return null;
		}
		return selectedContactId || null;
	}

	renderContent() {
		let contactId = this.getSelectedContactId();
		let contact = this.getSelectedContact();
		switch( this.state.mainView ) {
			case "contact":
				return <MainContent key={ "c" + contactId } contact={ contact }/>;
			case "invite":
				return <NewInviteContent
					onInvite={ this.onInvited }
					key={ this.state.inviteCounter }
					/>;
			case "inviteExternal":
				return <NewInviteContent
					onInvite={ this.onInvited }
					key={ this.state.inviteCounter }
					isExternal={true}
					/>;
			case "accept":
				return <AcceptInviteContent
					onBack={this.backFromAccept}
					key={this.state.inviteCounter}
					/>;
			case "sendContacts":
				return <SendContactsContent
					onBack={this.backFromSendContacts}
					toContactId={contactId}
					key={this.state.inviteCounter}
					/>;
			case "businessCards":
				return <BusinessCardsContent
					onBack={this.backFromBusinessCards}
					key={this.state.inviteCounter}
				/>
			default:
				throw new Error( `Unsupported main view: ${this.state.mainView}` );
		}
	}

	renderMainTitle() {
		switch( this.state.mainView ) {
			case "contact":
				return (
					<MainTitle
						contact={ this.getSelectedContact() }
						onInvite={ this.invite }
						onInviteExternal={ this.inviteExternal }
						onSendContacts={ this.sendContacts }
						onClearHistory={ this.clearHistory }
						onGoToList={ this.goToList }
						onAcceptInvite={ this.acceptInvite }
						onBusinessCards={ this.businessCards }
					/>
				);
			case "invite":
				return <NewInviteTitle onGoToList={ this.goToList }/>;
			case "inviteExternal":
				return <NewInviteTitle onGoToList={ this.goToList } isExternal={true}/>;
			case "accept":
				return <AcceptInviteTitle onGoToList={ this.goToList }/>;
			case "sendContacts":
				return <SendContactsTitle onGoToList={ this.goToList }/>;
			case "businessCards":
				return <BusinessCardsTitle onGoToList={ this.goToList }/>
			default:
				throw new Error( `Unsupported main view: ${this.state.mainView}` );
		}

	}

	renderPopup() {
		if ( this.state.popup ) {
			return this.state.popup();
		}
		return null;
	}

	clearPopup() {
		this.setState( { popup: null } );
	}

	goToList() {
		this.setState( {
			activeMobileView: "list"
		} );
	}

	doRename( contact, newName ) {
		if ( !newName ) {
			this.setState( { popup: null } );
			return;
		}
		this._service.renameContactAsync( contact, newName )
			.subscribe( () => {
				this.setState( { popup: null } );
			} );
	}

	doDelete( contact, isConfirmed ) {
		if ( !isConfirmed ) {
			this.setState( { popup: null } );
			return;
		}
		this._service.deleteContactAsync( contact )
			.subscribe( () => {
				this.setState( { popup: null } );
			} );
	}

	rename( contact ) {
		this.setState( {
			popup: () =>
				<Prompt
					titleTextId="web.contact.rename.title"
					defaultValue={ contact.name }
					onDone={ this.doRename.bind( this, contact ) }
				/>
		} );
	}

	delete( contact ) {
		this.setState( {
			popup: () =>
				<Confirm
					titleTextId="web.contact.delete.title"
					yesButtonTextId="web.contact.delete.button.yes"
					onDone={ this.doDelete.bind( this, contact ) }
				/>
		} );
	}

	/*sendContact( contact ) {
		//TODO: DO or remove!
		this.setState( { mainView: "invite", activeMobileView: "main" } );
	}*/

	addContact() {
		this.setState( {
			popup: () =>
				<AddModal
					onCancel={ this.clearPopup }
					onInvite={ this.invite }
					onAccept={ this.acceptInvite }
					onExternal={ this.inviteExternal }
				/>
		} );
	}

	invite() {
		// BUG: Not fully working on mobile
		this.setState( {
			mainView: "invite",
			activeMobileView: "main",
			inviteCounter: this.state.inviteCounter + 1,
			popup: null
		} );
	}

	inviteExternal() {
		// BUG: Not fully working on mobile
		this.setState( {
			mainView: "inviteExternal",
			activeMobileView: "main",
			inviteCounter: this.state.inviteCounter + 1,
			popup: null
		} );
	}

	sendContacts() {
		this.setState( {
			mainView: "sendContacts",
			activeMobileView: "main",
			inviteCounter: this.state.inviteCounter + 1
		} );
	}

	clearHistory() {
		let selectedContact = this.getSelectedContact();
		if ( selectedContact ) {
			this._service.clearHistoryAsync( selectedContact ).subscribe();
		}
	}

	backFromSendContacts( selectedIds ) {
		if ( !selectedIds || !selectedIds.length ) {
			this.setState( {
				mainView: "contact",
				activeMobileView: "main"
			} );
			return;
		}
		this._service.sendContactsAsync(
			this.getSelectedContact(),
			selectedIds
		).subscribe( () => {
			this.setState( {
				mainView: "contact",
				activeMobileView: "main"
			} );
		} );
	}

	backFromAccept( contact ) {
		if ( contact ) {
			this.setState( {
				selectedContactId: contact.id,
				mainView: "contact",
				activeMobileView: "main"
			} );
		} else {
			this.setState( {
				mainView: "contact",
				activeMobileView: "main"
			} );
		}
	}

	onInvited( contact ) {
		this.setState( {
			selectedContactId: contact && contact.id,
			mainView: "contact",
			activeMobileView: "main"
		} );
	}

	backFromBusinessCards() {
		this.setState( {
			mainView: "contact",
			activeMobileView: "main"
		} );
	}

	selectContact( contact ) {
		this.setState( {
			selectedContactId: contact && contact.id,
			mainView: "contact",
			activeMobileView: "main"
		} );
	}

	acceptInvite() {
		this.setState( {
			activeMobileView: "main",
			mainView: "accept",
			inviteCounter: this.state.inviteCounter + 1,
			popup: null
		} );
	}

	businessCards() {
		this.setState( {
			activeMobileView: "main",
			mainView: "businessCards",
			inviteCounter: this.state.inviteCounter + 1,
			popup: null
		} );
	}

	onSetSearchString( searchString ) {
		this.setState( { searchString } );
	}

	getFilteredContacts() {
		let { searchString, contacts } = this.state;
		if ( !searchString ) {
			return contacts;
		}
		let low = searchString.toLowerCase();
		return _.filter( contacts, ( { name } ) => ~name.toLowerCase().indexOf( low ) );
	}

	render() {
		let contactId = this.getSelectedContactId();
		let contact = this.getSelectedContact();
		let mainTitleTextId = contact ? "web.contacts.main.title": "web.contacts.list.title";
		return (
			<Layout
				activeMobileView={ this.state.activeMobileView }
				mainTitleTextId={mainTitleTextId}
				listTitleTextId="web.contacts.list.title"
				mainTitleParams={[contact && contact.name]}
				>
				<ListTitleContentView>
					<ListTitle
						onAddClick={ this.addContact }
						searchString={ this.state.searchString }
						onSetSearchString={ this.onSetSearchString }
					/>
				</ListTitleContentView>
				<MainTitleContentView>
					{this.renderMainTitle()}
				</MainTitleContentView>
				<MobileMenuContentView>
					<MobileMenu active="contacts">
						<button className="btn-circle newChat visible-xs" onClick={this.addContact}>+</button>
					</MobileMenu>
				</MobileMenuContentView>
				<ListbarContentView>
					<ContactListListBar
						onContactRename={ this.rename }
						onContactDelete={ this.delete }
						onSendContact={ this.sendContact }
						onSelectContact={ this.selectContact }
						selectedContactId={ contactId }
						contacts={ this.getFilteredContacts() }
						arrow={ this.state.searchString === null }
						emptyTextId={ this.state.searchString ? "contacts.search.empty" : "web.contacts.list.empty" }
					/>
				</ListbarContentView>
				<MainContentView>
					{this.renderContent()}
				</MainContentView>
				<CustomContentView>
					{this.renderPopup()}
				</CustomContentView>
			</Layout>
		);
	}
}

export default ContactsView;
