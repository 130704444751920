import Rx from "rx";
import ObservableModel from "../abstract/observable.model.js";
import BinaryStream from "../binary.stream.js";

class Attachment extends ObservableModel {
	constructor( binaryStreamFactory, name, mimeType ) {
		if ( arguments.length !== 3 ) {
			throw new Error( `Attachment constructor expects 3 parameters but received ${arguments.length}` );
		}
		if ( typeof binaryStreamFactory !== "function" )  {
			throw new Error( "binaryStreamFactory must be a function" );
		}
		super();
		this._binaryStreamFactory = binaryStreamFactory;
		this._binaryStream = binaryStreamFactory();
		if ( !( this._binaryStream instanceof BinaryStream ) ) {
			throw new Error( `BinaryStream requred` );
		}
		this._size = this._binaryStream.size;
		this._name = name;
		this._mimeType = mimeType;
	}

	getSize( ) {
		return this._size;
	}

	getName( ) {
		return this._name;
	}

	getMimeType( ) {
		return this._mimeType;
	}

	createBinaryStream( isBuffered ) {
		if ( this._binaryStream ) {
			let binaryStream = this._binaryStream;
			delete this._binaryStream;
			return binaryStream;
		}
		return this._binaryStreamFactory( isBuffered );
	}

	setName( name ) {
		this._name = name;
		this.onChange();
	}

	dispose( ) {
		this._binaryStream && this._binaryStream.dispose();
		delete this._binaryStream;
	}
}

Attachment.fromFile = ( file, onError ) => {
	return (
		new Attachment(
				() => BinaryStream.fromFile( file, onError ),
				file.name,
				file.type
			)
	);
};

export default Attachment;
