import decoderWorkerFactory from "../../../audio/decoderWorkerFactory.js";
import waveWorkerFactory from "../../../audio/waveWorkerFactory.js";

import Recorder from "../../../audio/recorder.js";
import AudioPlayer from "../../../audio/player.js";

class AudioManager {
  constructor() {
    this.recorder = null;
    this._onDataAvailable = this._onDataAvailable.bind( this );
    this._currentPlayingId = null;
    this._currentAudioPlayer = null;
    this._currentPlayStatus = "stopped";
  }

  startRecord() {
    if ( this.isRecording() ) {
      throw new Error( "Already recording" );
    }
    if ( this._currentPlayingId ) {
      this.pausePlay( this._currentPlayingId );
    }
    this.recorder = new Recorder( {
      numberOfChannels: 1,
      encoderSampleRate: 12000,
      encoderApplication: 2048,
      originalSampleRateOverride: 12000
    } );
    this.recorder.ondataavailable = this._onDataAvailable;
    return Promise.all( [ new Promise( resolve => {
      this.recorder.onstart = resolve;
    } ), this.recorder.start() ] );
  }

  _onDataAvailable( ui8Array ) {
    try {
      if ( this._dataWaiting ) {
        this._dataWaiting( ui8Array );
      }
    } catch( e ) {
      console.error( "_onDataAvailable", e );
    }
    this._dataWaiting = null;
  }

  stopRecord() {
    if ( !this.recorder ) {
      throw new Error( "Not recording" );
    }
    return new Promise( resolve => {
      this._dataWaiting = resolve;
      this.recorder.stop();
      this.recorder = null;
    } );
  }

  isRecording() {
    return !!this.recorder;
  }

  startPlay( blobUrl, id, isSuppressWarning ) {
    if ( this.recorder ) {
      this.recorder.stop();
      this.recorder = null;
    }

    if ( this._currentPlayingId !== id ) {
      this.stopPlay( this._currentPlayingId );

      this._currentPlayStatus = "playing";
      this._currentPlayingId = id;
      this._currentAudioPlayer = new AudioPlayer( blobUrl );
      this._currentAudioPlayer.play( isSuppressWarning );
    }

    //resume
    switch( this._currentPlayStatus ) {
      case "paused":
        this._currentPlayStatus = "playing";
        this._currentAudioPlayer.play();
        break;
      case "loadingpaused":
        this._currentPlayStatus = "loading";
        break;
    }
  }

  pausePlay( id ) {
    if ( this._currentPlayingId !== id ) {
      return;
    }
    switch( this._currentPlayStatus ) {
      case "playing":
        this._currentPlayStatus = "paused";
        this._currentAudioPlayer.pause();
        break;
      case "loading":
        this._currentPlayStatus = "loadingpaused";
        break;
    }
  }

  seekPlay( position, id ) {
    if ( this._currentPlayingId !== id ) {
      return;
    }

    switch( this._currentPlayStatus ) {
      case "paused":
      case "playing":
        this._currentAudioPlayer.setCurrentTime( position );
        break;
    }
  }

  stopPlay( id ) {
    if ( id && this._currentPlayingId === id ) {
      this._currentAudioPlayer.dispose();
      this._currentAudioPlayer = null;
      this._currentPlayingId = null;
      this._currentPlayStatus = "stopped";
    }
  }

  getPlayState() {
    if ( this._currentPlayStatus === "playing" || this._currentPlayStatus === "paused" ) {
      if ( !this._currentAudioPlayer.isPlaying() ) {
        this._currentPlayStatus = "paused";
      }
      return {
        currentId: this._currentPlayingId,
        currentTime: this._currentAudioPlayer.getCurrentTime(),
        status: this._currentPlayStatus,
        isEnded: this._currentAudioPlayer && this._currentAudioPlayer.isEnded()
      };
    }
    return {
      currentId: this._currentPlayingId,
      currentTime: 0,
      status: this._currentPlayStatus,
      isEnded: this._currentAudioPlayer && this._currentAudioPlayer.isEnded()
    };
  }

  decodeThen( oggOpusBuffer ) {
    return new Promise( resolve => {
      let decoderWorker = decoderWorkerFactory();
      let waveWorker = waveWorkerFactory();
      oggOpusBuffer = Buffer.concat( [ oggOpusBuffer ] ); //clone
      decoderWorker.onmessage = ( e ) => {
        if ( e.data === null ) {
          waveWorker.postMessage( { command: "done" } );
        }
        // e.data contains decoded buffers as float32 values
        else {
          waveWorker.postMessage( {
            command: "encode",
            buffers: e.data
          }, e.data.map( function( typedArray ) {
            return typedArray.buffer;
          } ) );
        }
      };
      waveWorker.onmessage =  ( e ) => {   // null signifies that no more data is expected and worker is closed.
        if ( !e.data || !e.data.page ) {
          return;
        }
        var dataBlob = new Blob( [ e.data.page ], { type: "audio/wav" } );
        var url = URL.createObjectURL( dataBlob );
        decoderWorker.terminate();
        waveWorker.terminate();
        resolve( url );
      };
      setTimeout( () => {
        decoderWorker.postMessage( {
          command: "init",
          decoderSampleRate: 12000,
          outputBufferSampleRate: 12000
        } );
        waveWorker.postMessage( {
          command: "init",
          wavBitDepth: 16,
          wavSampleRate: 12000
        } );
      }, 10 );

      setTimeout( () => {
        decoderWorker.postMessage( {
          command: "decode",
          pages: oggOpusBuffer
        }, [ oggOpusBuffer.buffer ] );

        decoderWorker.postMessage( {
          command: "done"
        } );
      }, 100 );
    } );
  }
}

export default new AudioManager();
