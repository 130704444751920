import React from "react";
import Rx from "rx";
import _ from "lodash";
import classNames from "classnames";

import translate from "../translations/translate.js";
import Button from "./button.jsx";
import Tinput from "./tinput.jsx";

class KeyboardButton extends React.Component {
	constructor() {
		super();
		this.state = {
			showTooltip: false
		};

		this._lastDownTime = 0;
	}

	handleMouseUp(event) {
		if ( this.props.inFocus ) {
			let interval = +new Date - this._lastDownTime;
			if ( interval >= 0 && interval <= 100 ) { return; }
			this._lastDownTime = +new Date;
			this.props.keyDown( this.props.keyCode );
			event && event.preventDefault && event.preventDefault();
		}
	}

	handleMouseEnter() {
		if ( !this.state.showTooltip ) {
			this.setState( { showTooltip: true } );
			this.showKeyPreview();
		}
	}

	handleMouseLeave() {
		if ( this.state.showTooltip ) {
			this.setState( { showTooltip: false } );
			this.hideKeyPreview();
		}
	}

	showKeyPreview() {}

	hideKeyPreview() {}

	renderBody() {
		return this.props.keyCode;
	}

	renderTooltip() {
		return null;
	}

	render() {
		let { showTooltip } = this.state;
		let { inFocus, refId, rightmost, keyCode, keyDown, keyPreview, ...props } = this.props;
		let tooltipText= this.renderTooltip();
		let tooltip = inFocus && showTooltip && tooltipText;
		return (
			<span {...props} id={this.props.keyCode}>
				{tooltip ?
					<span
						className={"button-tooltip" + rightmost}
						style={{display: tooltip ? 'block' : 'none'}}
						>{tooltipText}
					</span>
					: null
				}
				{this.renderBody()}
			</span>
		);
	}
}

class LetterButton extends KeyboardButton {
	showKeyPreview() {
		this.props.keyPreview( this.props.keyCode );
	}

	hideKeyPreview() {
		this.props.keyPreview( null );
	}

	renderTooltip() {
		return this.renderBody();
	}
}

class ShiftButton extends KeyboardButton {
	renderBody() {
		return <img className="icon shift" src="images/icon-shift.svg" alt="Shift"/>;
	}
}

class BackspaceButton extends KeyboardButton {
	constructor() {
		super();
		this.mouseEnter = new Rx.Subject();
		this.mouseLeave = new Rx.Subject();
		this.tapHandled = false;
	}

	componentDidMount() {
		this.subscription = this.mouseEnter
			.concatMap( () => Rx.Observable.interval( 250 ).takeUntil( this.mouseLeave ) )
			.tap( () => this.tapHandled = true )
			.tap( () => this.props.keyDown( this.props.keyCode ) )
			.subscribe();
	}

	componentWillUnmount() {
		this.subscription.dispose();
	}

	handleMouseUp(event) {
		if ( this.props.inFocus && !this.tapHandled ) {
			let interval = +new Date - this._lastDownTime;
			// Samsung A5 2017's webview requires this hack. It calls all touch events, than all mouse events
			if ( interval >= 0 && interval <= 200 ) { return; }
			this._lastDownTime = +new Date;
			this.props.keyDown( this.props.keyCode );
			event && event.preventDefault && event.preventDefault();
		}
	}

	handleMouseEnter() {
		this.mouseEnter.onNext();
	}

	handleMouseLeave() {
		this.tapHandled = false;
		this.mouseLeave.onNext();
	}

	renderBody() {
		return <img className="icon backspace" src="images/icon-backspace.svg" alt="Backspace"/>;
	}
}

class LanguageButton extends KeyboardButton {
	renderBody() {
		return <span className="lang"><img src="images/icon-glob.svg" alt="Lang"/></span>;
	}
}

class SpaceButton extends KeyboardButton {
	renderBody() {
		return <span className="spacebar">space</span>;
	}
}

class DotButton extends LetterButton {
	renderBody() {
		return <span className="dot">.</span>;
	}
}

class EnterButton extends KeyboardButton {
	renderBody() {
		return <span className="enter green">enter</span>;
	}
}

class NumbersButton extends KeyboardButton {
	renderBody() {
		return <span className="numbers">123</span>;
	}
}

class AlphaButton extends KeyboardButton {
	renderBody() {
		return <span className="numbers">abc</span>;
	}
}

class PunctuationButton extends KeyboardButton {
	renderBody() {
		return <span className="numbers">#+=</span>;
	}
}

let specialKeys = {
	"Shift": ShiftButton,
	"Backspace": BackspaceButton,
	"Lang": LanguageButton,
	"Space": SpaceButton,
	".": DotButton,
	"Enter": EnterButton,
	"123": NumbersButton,
	"Alpha": AlphaButton,
	"#+=": PunctuationButton
};

let Layouts = {
	en: {
		alpha: [
			"qwertyuiop".split( "" ),
			"asdfghjkl".split( "" ),
			_.flatten( [ "Shift", "zxcvbnm".split( "" ), "Backspace" ] ),
			[ "123", "Lang", ",", "Space",  ".", "Enter"]
		],
		caps: [
			"QWERTYUIOP".split( "" ),
			"ASDFGHJKL".split( "" ),
			_.flatten( [ "Shift", "ZXCVBNM".split( "" ), "Backspace" ] ),
			[ "123", "Lang", ",", "Space",  ".", "Enter"]
		],
		numeric: [
			"1234567890".split( "" ),
			"-/:;()\u00A3&@\"".split( "" ),
			_.flatten( [ "#+=", ".,?!\'$#€".split( "" ), "Backspace" ] ),
			[ "Alpha", "Lang", "Space", "Enter"]
		],
		punctuation: [
			"[]{}#%^*+=".split( "" ),
			"_\\|~<>\u20AC\u00A3\uFFE5".split( "" ),
			_.flatten( [ "123", ".,?!\'".split( "" ), "Backspace" ] ),
			[ "Alpha", "Lang", "Space", "Enter" ]
		],
		next: "ru"
	},
	uk: {
		alpha: [
			"йцукенгшщзхї".split( "" ),
			"фівапролджє".split( "" ),
			_.flatten( [ "Shift", "ячсмитьбю".split( "" ), "Backspace" ] ),
			[ "123", "Lang", ",", "Space",  ".", "Enter"]
		],
		caps: [
			"ЙЦУКЕНГШЩЗХЇ".split( "" ),
			"ФІВАПРОЛДЖЄ".split( "" ),
			_.flatten( [ "Shift", "ЯЧСМИТЬБЮ".split( "" ), "Backspace" ] ),
			[ "123", "Lang", ",", "Space",  ".", "Enter"]
		],
		numeric: [
			"1234567890".split( "" ),
			"-/:;()\u00A3&@\"".split( "" ),
			_.flatten( [ "#+=", ".,?!\'$#€".split( "" ), "Backspace" ] ),
			[ "Alpha", "Lang", "Space", "Enter" ]
		],
		punctuation: [
			"[]{}#%^*+=".split( "" ),
			"_\\|~<>\u20AC\u00A3\uFFE5".split( "" ),
			_.flatten( [ "123", ".,?!\'".split( "" ), "Backspace" ] ),
			[ "Alpha", "Lang", "Space", "Enter" ]
		],
		next: "en"
	},
	ru: {
		alpha: [
			"йцукенгшщзхъ".split( "" ),
			"фывапролджэ".split( "" ),
			_.flatten( [ "Shift", "ячсмитьбю".split( "" ), "Backspace" ] ),
			[ "123", "Lang", ",", "Space",  ".", "Enter"]
		],
		caps: [
			"ЙЦУКЕНГШЩЗХЪ".split( "" ),
			"ФЫВАПРОЛДЖЭ".split( "" ),
			_.flatten( [ "Shift", "ЯЧСМИТЬБЮ".split( "" ), "Backspace" ] ),
			[ "123", "Lang", ",", "Space",  ".", "Enter"]
		],
		numeric: [
			"1234567890".split( "" ),
			"-/:;()\u00A3&@\"".split( "" ),
			_.flatten( [ "#+=", ".,?!\'$#€".split( "" ), "Backspace" ] ),
			[ "Alpha", "Lang", "Space", "Enter" ]
		],
		punctuation: [
			"[]{}#%^*+=".split( "" ),
			"_\\|~<>\u20AC\u00A3\uFFE5".split( "" ),
			_.flatten( [ "123", ".,?!\'".split( "" ), "Backspace" ] ),
			[ "Alpha", "Lang", "Space", "Enter" ]
		],
		next: "uk"
	}
};

class Keyboard extends React.Component {
	constructor() {
		super();
		this.state = {
			language: "en",
			mode: "alpha",
			visible: false,
			inFocus: false,
		};
		this.activeButton = null;
		this.handleKey = this.handleKey.bind( this );
		this.handleKeyPreview = this.handleKeyPreview.bind( this );
		this.handleMouseDown = this.handleMouseDown.bind( this );
		this.handleMouseMove = this.handleMouseMove.bind( this );
		this.handleMouseUp = this.handleMouseUp.bind( this );
		this.handleTouchStart = this.handleTouchStart.bind( this );
		this.handleTouchMove = this.handleTouchMove.bind( this );
		this.handleTouchEnd = this.handleTouchEnd.bind( this );
		this.handleTouchCancel = this.handleTouchCancel.bind( this );
		this.onButtonClick = this.onButtonClick.bind( this );
		this.capsLock = false;
	}

	static get contextTypes() {
		return { focusManager: React.PropTypes.object.isRequired };
	}

	static get defaultProps() {
		return {
			layouts: Layouts,
			onShow: _.noop,
			onHide: _.noop
		};
	}

	componentDidMount() {
		this.context.focusManager.registerKeyboard( this );
	}

	componentWillUnmount() {
		this.context.focusManager.unregisterKeyboard( this );
	}

	componentWillMount() {
		this.updateLayout( {
			language: translate.getCurrentLanguage().getId()
		 } );
	}

	updateLayout( layout ) {
		let { language, mode } = { ...this.state, ...layout };
		let rows = this.props.layouts[ language ][ mode ];
		let width = this.getButtonWidth( rows );
		this.setState( { commonKeyStyle: { width }, ...layout } );
	}

	show( cb ) {
		this.setState( { visible: true }, cb );
		this.props.onShow();
	}

	hide( cb ) {
		this.setState( { visible: false }, cb );
		this.props.onHide();
	}

	isCaps () {
		return this.state.mode === "caps";
	}

	isAlphaOrCaps() {
		let { mode } = this.state;
		return ( mode === "alpha" ) || ( mode === "caps" );
	}

	handleKey( key ) {
		let commands = {
			"Shift": () => {
				  if (this.isCaps() && this.capsLock) {
						this.capsLock = false;
						return this.toLowerCase();
					}
					this.isCaps() ? this.capsLock = true : this.toUpperCase()
		 	},
			"Lang": () => this.updateLayout( {
				mode: "alpha",
				language: Layouts[this.state.language || "en"].next
			} ),
			"123": () => this.updateLayout( { mode: "numeric" } ),
			"Alpha": () => this.updateLayout( { mode: "alpha" } ),
			"#+=": () => this.updateLayout( { mode: "punctuation" } ),
			"Space": () => {
				if ( !this.isAlphaOrCaps() ) {
					this.updateLayout( { mode: "alpha" } );
				}
				this.context.focusManager.handleKey( key );
			}
		};
		if ( key in commands ) {
			commands[ key ]();
		} else {
			this.context.focusManager.handleKey( key );
			if ( key.length == 1 && this.isCaps() && !this.capsLock) {
				// Samsung A5 2017's webview requires this hack. It calls all touch events, than all mouse events
				//setTimeout( () => {
					this.toLowerCase();
				//}, 50 );
			 }
		}
	}

	toUpperCase() {
		return this.updateLayout( { mode: "caps" } );
	}
	toLowerCase() {
		return this.updateLayout( { mode: "alpha" } );
	}

	handleKeyPreview( key ) {
		this.context.focusManager.handleKeyPreview( key );
	}

	handleMouseDown( { clientX, clientY } ) {
		this.setState( { inFocus: true }, () => this.handleMouseMove( { clientX, clientY } ) );
	}

	handleMouseUp(event) {
		this.setState( { inFocus: false } );
		if ( this.activeButton ) {
			this.activeButton.handleMouseLeave();
			this.activeButton.handleMouseUp(event);
			this.activeButton = null;
		}
	}

	handleMouseMove( { clientX, clientY } ) {
		if ( !this.state.inFocus ) {
			return;
		}
		let el = global.document.elementFromPoint( clientX, clientY );
		let ref;
		do {
			if (el) ref = el.attributes[ "data-ref" ];
		} while (el && !ref && ( el = el.parentElement ) );
		if ( ref ) {
			let button = this.refs[ ref.value ];
			if ( button !== this.activeButton ) {
				if ( this.activeButton ) {
					this.activeButton.handleMouseLeave();
				}
				this.activeButton = button;
				button.handleMouseEnter();
			}
		} else {
			if ( this.activeButton ) {
				this.activeButton.handleMouseLeave();
				this.activeButton = null;
			}
		}
	}

	handleTouchStart( ev ) {
		if ( ev.touches.length === 1 ) {
			this.handleMouseDown( ev.touches[ 0 ] );
			ev.stopPropagation();
			ev.preventDefault();
		}
	}

	handleTouchMove( ev ) {
		if ( ev.changedTouches.length === 1 ) {
			this.handleMouseMove( ev.changedTouches[ 0 ] );
			ev.stopPropagation();
		}
	}

	handleTouchEnd( ev ) {
		this.handleMouseUp(ev);
		ev.stopPropagation();
	}

	handleTouchCancel( ev ) {
		if ( this.activeButton ) {
			this.activeButton.handleMouseLeave();
			this.activeButton = null;
		}
		ev.stopPropagation();
	}
	renderRow( buttons, i ) {
		if (! this.state.renderEnter && !this.props.withInput) buttons = _.difference(buttons, ['Enter']);

		let { inFocus, commonKeyStyle } = this.state;
		let ref = "key" + i;

		let style = (key) => {
			let width = parseFloat(commonKeyStyle.width);
			if (key == 'Enter') return {width: '20%'};
			if (key == 'Space') return {width: (95 - _.difference(buttons, ['Enter', 'Space']).length * 10) - 20*_.includes(buttons, 'Enter') +'%'};
			if (i == 3) return {width: '10%'};
			if (-1 !== ['Shift', 'Backspace'].indexOf(key)) {
				return {width: (100 -(buttons.length - 2) * width)/2 + '%'
				};
			}
			if (-1 !== [',','.'].indexOf(key)) {
				return _.assign({fontWeight:'bolder'}, commonKeyStyle)
			}
			return commonKeyStyle;
		}
		let row = buttons.map( ( key, j ) => React.createElement( specialKeys[ key ] || LetterButton, {
			key: key.toLowerCase(),
			ref: ref + j,
			'data-ref': ref + j,
			keyCode: key,
			keyDown: this.handleKey,
			keyPreview: this.handleKeyPreview,
			inFocus,
			style: style(key),
			className: 'key',
			rightmost: j < 9 ? '' : ' rightmost'
		} ) );

		return <div key={i} className="row">{row}</div>;
	}

	getMaxButtonsPerRow( rows ) {
		let minCount = 10;
		let rowLengths = rows.map( row => row.length );
		return Math.max( minCount, ...rowLengths );
	}

	getButtonWidth( rows ) {
		return ( 99 / this.getMaxButtonsPerRow( rows ) ).toFixed( 2 ) + "%";
	}

	get isValid() {
		return !!_.trim( this.props.inputValue );
	}

	onButtonClick() {
		this.toUpperCase()
		this.props.onButtonClick && this.props.onButtonClick();
	}

	renderInput() {
		let { inputValue, handleInput, withInput }  = this.props;
		return (
			!withInput ?
			null :
				 <div className="input-form bg-b05">
					<Button
						className="green extra-small"
						caption={this.props.buttonTextId}
						handleClick={this.onButtonClick}
						handlePressStart={this.props.onButtonPressStart}
						enabled={!!this.props.onButtonClick || !!this.props.onButtonPressStart}
					/>
					<Tinput
						className="message"
						type="multiline"
						autofocus={true}
						value={inputValue}
						onChange={handleInput}
						firstUppercased={true}
						placeholderTextId="prompt.default.header"
						/>
					<div className="clear"></div>
				</div>
			);
	}

	render() {
		let { visible, language, mode } = this.state;

		if ( !visible && !this.props.withInput ) {
			return null;
		}

		let rows = this.props.layouts[ language ][ mode ];

		let buttons = rows.map( ( row, i ) => this.renderRow( row, i ) );
		let className = classNames( {
			keyboard: true,
			tooltipPadding: false,
			keyboardsolo: !this.props.withInput
		} );

		return (
			<div className={ className }>
				{this.renderInput()}

				<div className="rows bg-b05"
					 onMouseDown={this.handleMouseDown}
					 onMouseMove={this.handleMouseMove}
					 onMouseUp={this.handleMouseUp}
					 onMouseLeave={this.handleMouseUp}
					 onTouchStart={this.handleTouchStart}
					 onTouchMove={this.handleTouchMove}
					 onTouchEnd={this.handleTouchEnd}
					 onTouchCancel={this.handleTouchCancel}>
					{buttons}
				</div>
			</div>
		);
	}
}

export default Keyboard;
