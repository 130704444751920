import Rx from "rx";
import _ from "lodash";
import ssgCrypto from "ssg.crypto";

import { makeThumbnailThen } from "../../common/image.js";
import configuration from "../../common/configuration.js";
import DefaultStorage from "../repositories/storage/default.storage.js";
import LocalStorage from "../../api/repositories/storage/local.storage.js";
import translate from "../../browser/translations/translate.js";
import sounds from "../../browser/components/sounds.js";
import longTaskServiceLocator from "./locators/long.task.js";
import { MESSAGE_TYPE, ChatMessageBase } from "../models/chat.message.js";
import configServiceLocator from "./locators/config.js";

import Service from "../../browser/service.js";

const MAX_FILE_SIZE_MB = 64;

let localStorage = global.localStorage;

export default class WorkerClient {
	constructor( worker ) {
    this._sendingMessages = Object.create( null );
    this._subjs = Object.create( null );
		this._sendingFiles = Object.create( null );
    this._worker = worker;
    this._callIdCounter = 0;
		if ( this._worker ) {
    	this._worker.onmessage = event => { this._onmessage( event.data ); };
		} else {
			this._service = new Service();
			// HACK:
			this._service._callClientAsync = ( name, params ) => {
				return this._remoteMethods[ name ].call(this, params);
			};
		}

		this._remoteMethods = {};
		this._remoteMethods.onNext = this._onNext;
		this._remoteMethods.onCompleted = this._onCompleted;
		this._remoteMethods.onError = this._onError;

		this._remoteMethods.fileDropData = this._remote_fileDropData;
		this._remoteMethods.fileReadAsBuffer = this._remote_fileReadAsBuffer;
		this._remoteMethods.fileReplace = this._remote_fileReplace;
		this._remoteMethods.fileReadAtPosition = this._remote_fileReadAtPosition;
		this._remoteMethods.fileWriteAtPosition = this._remote_fileWriteAtPosition;
		this._remoteMethods.fileGetSize = this._remote_fileGetSize;
		this._remoteMethods.fileGetModificationTime = this._remote_fileGetModificationTime;
		this._remoteMethods.fileRename = this._remote_fileRename;
		this._remoteMethods.fileIsExists = this._remote_fileIsExists;
		this._remoteMethods.readUploadingFile = this._remote_readUploadingFile;
		this._remoteMethods.onNewMessage = this._remote_onNewMessage;
		this._remoteMethods.reload = this._remote_reload;

		this._onlineStatus = this._callRemoteObservable( "observeOnlineStatus", {} );
		this._storageNumber = "_";
		this.onPause = this.onPause.bind( this );
		this.onResume = this.onResume.bind( this );
		this._isForeground = true;
		this.subscribeToMinimize();
		this._initNotifications();
		this._observeWebBackgroundMode();
		this._profileSubj = new Rx.BehaviorSubject( null );

		this._callRemoteObservable( "observeProfile", {} )
			.subscribe( profile => {
				this._profileSubj.onNext( profile );
			}, error => {
				this._profileSubj.onError( error );
			}, () => {
				this._profileSubj.onCompleted();
			} );
	}

	_observeWebBackgroundMode() {
		this._longTaskService = longTaskServiceLocator();
		this._languageSubscription = (
			translate.observeLanguageChanges()
				.subscribe( () => {
					if ( this._longWebTaskHandle ) {
						this._longTaskService.updateDescription(
							this._longWebTaskHandle,
							translate( "web.notification.text" )
						);
					}
				} )
		);

		this.observeWebConnectionSession()
			.map( ( { isConnected } ) => isConnected )
			.startWith( false )
			.distinctUntilChanged()
			.subscribe( isConnected => {
				if ( isConnected ) {
					if ( !this._longWebTaskHandle ) {
						this._longWebTaskHandle = this._longTaskService.runLongTask(
							translate( "web.notification.text" )
						);
					}
				} else if ( this._longWebTaskHandle ) {
					this._longWebTaskHandle.dispose();
					delete this._longWebTaskHandle;
				}
			} );
	}

	startUpdateBadges() {
		if ( !this._push ) {
			return;
		}
		if ( this._badgesSubscription ) {
			this._badgesSubscription.dispose();
		}
		this._badgesSubscription = (
			this.observeUnreadCountExcept( -1 )
				.subscribe( count => {
					this._push.setApplicationIconBadgeNumber( _.noop, _.noop, count );
				} )
		);
	}

	stopUpdateBadges() {
		if ( this._badgesSubscription ) {
			this._badgesSubscription.dispose();
			this._badgesSubscription = null;
		}
	}

	_initNotifications( ) {
		if ( !global.PushNotification ) {
			console.warn( "PushNotification object not defined" );
			return;
		}
		let config = configuration.getPushNotificationConfig();
		console.warn( "Trying to init notifications", config );
		this._push = global.PushNotification.init( config );
		this._push.on( "registration", data => {
			let { registrationId } = data;
			console.warn( "Got registrationId", JSON.stringify( data ) );
			if ( global.navigator ) {
	      let isIOS = /iPad|iPhone|iPod/.test( global.navigator.userAgent );
	      let isAndroid = /Android/.test( global.navigator.userAgent ) || /Android/.test( global.navigator.appVersion );

	      if ( isIOS ) {
	        registrationId = "i!" + registrationId;
	      } else if ( isAndroid ) {
	        registrationId = "a!" + registrationId;
	      }
	    }

			this._callClientAsync( "setRegistrationId", { registrationId } ).subscribe();
		} );
		this._push.on( "notification", data => {
			if ( this._isPushNotificationMuted || !this.isForeground() ) {
				return;
			}
			sounds.newMessage();
		} );

		this._push.on( "error", function( data ) {
			console.error( "Init notifications error!", data );
		} );
	}

	subscribeToMinimize() {
		global.document.addEventListener( "pause", this.onPause, false );
		global.document.addEventListener( "resume", this.onResume, false );
	}

	unSubscribeToMinimize() {
		global.document.removeEventListener( "pause", this.onPause, false );
		global.document.removeEventListener( "resume", this.onResume, false );
	}

	onPause() {
		this._isForeground = false;
	}

	onResume() {
		this._isForeground = true;
	}

	isForeground() {
		return this._isForeground;
	}

	_afterLogin() {
		this.mutePushNotifications();
		this._isLoggedIn = true;
		let currentContacts = Object.create( null );
		this._contactsObservable = new Rx.ReplaySubject( 1, null, Rx.Scheduler.immediate );

		this._callRemoteObservable( "observeContactList", {} )
			.map( diff => {
				let array = _.toArray( diff );
				array.forEach( contact => {
					contact.type = "normal";
				} );
				return array;
			} )
			.map( diff => this._uncompactContacts( currentContacts, diff ) )
			.subscribe(
				list => {
					this._contactsObservable.onNext( list );
				},
				error => { this._contactsObservable.onError( error ); },
				() => { this._contactsObservable.onCompleted(); }
			);

		let currentGroups = Object.create( null );
		this._groupsObservable = new Rx.ReplaySubject( 1, null, Rx.Scheduler.immediate );
		this._callRemoteObservable( "observeGroupList", {} )
			.map( diff => {
				let array = _.toArray( diff );
				array.forEach( contact => {
					contact.type = "group";
				} );
				return array;
			} )
			.map( diff => this._uncompactContacts( currentGroups, diff ) )
			.subscribe(
				list => { this._groupsObservable.onNext( list ); },
				error => { this._groupsObservable.onError( error ); },
				() => { this._groupsObservable.onCompleted(); }
			);

		let currentSharedContacts = Object.create( null );
		this._sharedContactsObservable = new Rx.ReplaySubject( 1, null, Rx.Scheduler.immediate );
		this._callRemoteObservable( "observeSharedContacts", {} )
			.map( contacts => _.toArray( contacts ) )
			.tap( contacts => {
				this._uncompactSharedContacts( currentSharedContacts, contacts )
			} )
			.subscribe(
				list => { this._sharedContactsObservable.onNext( list ); },
				error => { this._sharedContactsObservable.onError( error ); },
				() => { this._sharedContactsObservable.onCompleted(); }
			);

		this.getProfileAsync().subscribe( ( { language } ) => {
			if ( language ) {
				translate.setLanguage( [ language ] );
			}
		} );
		this.startUpdateBadges();
	}

	_remote_onNewMessage() {
		if ( !this.isForeground() ) {
			return Rx.Observable.just();
		}

		let profile = this._profileSubj.getValue();
		if ( profile && profile.receiveSound ) {
			sounds.newMessage();
		}

		return Rx.Observable.just();
	}

	_remote_fileDropData( { fileName } ) {
		let storage = new DefaultStorage( fileName );
		return storage.dropDataAsync();
	}

	_remote_fileReadAsBuffer( { fileName } ) {
		let storage = new DefaultStorage( fileName );
		return storage.readAsBufferAsync();
	}

	_remote_fileReplace( { fileName, data } ) {
		let storage = new DefaultStorage( fileName );
		// let startAt = performance.now();
		return (
			storage.replaceAsync( /*new Buffer( data, "base64" )*/data )
				// .tap( () => {
				//  	console.log( "fileReplace", fileName, performance.now() - startAt );
				// } )
		);
	}

	_remote_fileReadAtPosition( { fileName, start, length } ) {
		let storage = new DefaultStorage( fileName );
		return storage.readAtPositionAsync( start, length );
	}

	_remote_fileWriteAtPosition( { fileName, start, data } ) {
		let storage = new DefaultStorage( fileName );
		return storage.writeAtPositionAsync( /*new Buffer( data, "base64" )*/data, start );
	}

	_remote_fileGetSize( { fileName } ) {
		let storage = new DefaultStorage( fileName );
		return storage.getSizeAsync();
	}

	_remote_fileGetModificationTime( { fileName } ) {
		let storage = new DefaultStorage( fileName );
		return storage.getModificationTimeAsync();
	}

	_remote_fileRename( { fileName, newFileName } ) {
		let storage = new DefaultStorage( fileName );
		return storage.renameAsync( newFileName );
	}

	_remote_fileIsExists( { fileName } ) {
		return DefaultStorage.isFileExistsAsync( fileName );
	}

  _onmessage( data ) {
		if ( this._remoteMethods[ data.name ] ) {
			let res;
			try {
				res = this._remoteMethods[ data.name ].call( this, data.params, data );
				if ( res === undefined ) {
					return;
				}
			} catch( e ) {
				res = Rx.Observable.throw( e );
			}
			res.subscribe( result => {
				let msg = { result, callId: data.callId };
		        this._worker.postMessage( msg );
			}, error => {
				let msg = { name: "error", callId: data.callId, params: { message: error.message } };
		        this._worker.postMessage( msg );
			} );
			return;
		}
		this._onReturn( data );
  }

    _onNext( params, { callId, item } ) {
        let subj = this._subjs[ callId ];
        if ( !subj ) {
            return;
        }
        subj.onNext( item );
    }

    _onError( params, { callId, message } ) {
        let subj = this._subjs[ callId ];
        if ( !subj ) {
            return;
        }
        delete this._subjs[ callId ];
        subj.onError( new Error( message ) );
    }

    _onCompleted( params, { callId } ) {
        let subj = this._subjs[ callId ];
        if ( !subj ) {
            return;
        }
        delete this._subjs[ callId ];
        subj.onCompleted();
    }

    _onReturn( { callId, result } ) {
        let subj = this._subjs[ callId ];
        if ( !subj ) {
            return;
        }
        delete this._subjs[ callId ];
        subj.onNext( result );
        subj.onCompleted();
    }

	_callClientAsync( name, params ) {
		if ( this._service ) {
			return this._service._remoteMethods[name].call(this._service, params);
		}
    let callId = this._callIdCounter++;
    let subj = this._subjs[ callId ] = new Rx.ReplaySubject( 1, null, Rx.Scheduler.immediate );
    let msg = { name, params, callId };
    this._worker.postMessage( msg );
    return subj;
	}

  _callRemoteObservable( name, params ) {
		if ( this._service ) {
			return this._service._remoteObservableMethods[name].call(this._service, params);
		}
    let callId = this._callIdCounter++;
    let subj = this._subjs[ callId ] = new Rx.ReplaySubject( 1, null, Rx.Scheduler.immediate );
    let msg = { name, params, callId };
		let subscriptionCount = 0;
		let observer = null;
    this._worker.postMessage( msg );
//TODO: this is not a good hack.
    return Rx.Observable.combineLatest(
			Rx.Observable.create( _observer => {
				observer = _observer;
				subscriptionCount++;
				observer.onNext();
				return () => {
					subscriptionCount--;
					if ( !subscriptionCount ) {
						this._callClientAsync( "disposeObservable", { callId } ).subscribe();
					}
				};
			} ),
			subj.tapOnCompleted( () => {
				observer.onCompleted();
			} ),
			( ignore, res ) => res
		);
	}

	_uncompactContacts( currentContacts, diff, type2Set ) {
		for ( var i = 0; i < diff.length; i++ ) {
			var d = diff[ i ];
			if ( type2Set ) {
				d.type = type2Set;
			}
			if ( !d.status ) {
				delete currentContacts[ d.id ];
			} else {
				currentContacts[ d.id ] = d;
			}
		}
		return _.values( currentContacts );
	}

	observeContactList( ) {
		return this._contactsObservable;
	}

	observeGroupList( ) {
		return this._groupsObservable;
	}

	inviteContactAsync( contactName, selfNameOverride, isExternal ) {
		return (
			this._callClientAsync( "inviteContact", { contactName, selfNameOverride, isExternal } )
				.tap( contact => {
					contact.type = "normal";
				} )
		);
	}

	getInviteDataAsync( token, password, type ) {
		return this._callClientAsync( "getInviteData", { token, type } );
	}

	acceptInviteAsync( token, name ) {
		return this._callClientAsync( "acceptInvite", { token, name } );
	}

	renameContactAsync( contact, newName ) {
		return this._callClientAsync( "renameContact", {
			contactId: contact.id,
			contactType: contact.type,
			newName
		} );
	}

	deleteContactAsync( contact ) {
		return this._callClientAsync( "deleteContact", {
			contactId: contact.id
		} );
	}

	sendTextMessageAsync( contact, text, replyTo ) {
		let message = { type: "text", text, replyTo };
		if ( !this._sendingMessages[ contact.id ] ) {
			this._sendingMessages[ contact.id ] = new Rx.BehaviorSubject( [] );
		}
		return (
			Rx.Observable.fromPromise( ssgCrypto.createRandomBase64StringThen( 32 ) )
				.tap( id => {
					message.id = id;
					let sending = this._sendingMessages[ contact.id ].getValue();
					sending.push( { type: "OUTGOING", sender: "", text, id, replyTo } );
					this._sendingMessages[ contact.id ].onNext( sending );
					this._monitorSendingMessage( contact, id );
				} )
				.flatMap( id =>
					this._callClientAsync( "sendMessage", {
						contactId: contact.id,
						contactType: contact.type,
						message
					} )
				)
				.tap( () => {
					message.isSent = true;
					this._sendingMessages[ contact.id ].onNext( this._sendingMessages[ contact.id ].getValue() );
				} )
		);
	}

	forwardMessageAsync( contact, forwardingMessage ) {
		let message = {
			type: "text",
			contentType: forwardingMessage.contentType
		};

		if ( forwardingMessage.text ) {
			message.text = forwardingMessage.text;
			message.type = "text";
		}
		if ( forwardingMessage.fileToken ) {
			message.fileToken = forwardingMessage.fileToken;
			message.type = "file";
		}
		if ( forwardingMessage.milliseconds ) {
			message.milliseconds = forwardingMessage.milliseconds;
		}
		if ( forwardingMessage.thumbnailBase64 ) {
			message.thumbnailBase64 = forwardingMessage.thumbnailBase64;
		}
		if ( forwardingMessage.fileName ) {
			message.name = forwardingMessage.fileName;
		}
		return this._callClientAsync( "sendMessage", {
			contactId: contact.id,
			contactType: contact.type,
			message
		} );
	}

	_monitorSendingMessage( contact, id ) {
		let messagesObservable;
		switch( contact.type ) {
			case "normal":
				messagesObservable = this._callRemoteObservable( "observeContactMessages", {
					contactId: contact.id
				} );
				break;
			case "group":
				messagesObservable = this._callRemoteObservable( "observeGroupMessages", {
					contactId: contact.id
				} );
				break;
		}
		messagesObservable
			.filter( messages => _.find( messages, { id } ) )
			.take( 1 )
			.subscribe( messages => {
				let sending = this._sendingMessages[ contact.id ].getValue();
				let index = _.findKey( sending, { id } );
				sending.splice( index, 1 );
				this._sendingMessages[ contact.id ].onNext( sending );
			} );
	}

	sendFileMessageAsync( contact, fileToken, fileSize, fileType, fileName, messageId, thumbnailBase64, messageProperties ) {
		let message = {
			type: "file",
			contentType: fileType,
			size: fileSize,
			name: fileName,
			fileToken,
			id: messageId,
			thumbnailBase64,
			...( messageProperties || {} )
		};

		return this._callClientAsync( "sendMessage", {
			contactId: contact.id,
			contactType: contact.type,
			message
		} );
	}

	sendContactsAsync( contact, contactIdsToSend ) {
		return this._callClientAsync( "sendContacts", {
			contactId: contact.id,
			contactIdsToSend
		} );
	}

	observeMessages( contact ) {
		let resObservable;
		switch( contact.type ) {
			case "normal":
				resObservable = this._callRemoteObservable( "observeContactMessages", {
					contactId: contact.id
				} );
				break;
			case "group":
				resObservable = this._callRemoteObservable( "observeGroupMessages", {
					contactId: contact.id
				} );
				break;
			default:
				throw new Error( "ContactType required" );
		}
		if ( !this._sendingFiles[ contact.id ] ) {
			this._sendingFiles[ contact.id ] = new Rx.BehaviorSubject( Object.create( null ) );
		}

		if ( !this._sendingMessages[ contact.id ] ) {
			this._sendingMessages[ contact.id ] = new Rx.BehaviorSubject( [] );
		}

		return Rx.Observable.combineLatest(
			resObservable,
			this._sendingFiles[ contact.id ],
			this._sendingMessages[ contact.id ].observeOn( Rx.Scheduler.default ),
			( messages, sendingFiles, sending ) => ( { messages, sendingFiles, sending } )
		);
	}

	createGroupAsync( name, nickname, contactIds ) {
		return this._callClientAsync( "createGroup", {
			name, nickname, contactIds
		} );
	}

	observeGroupParticipants( contact ) {
		return this._callRemoteObservable( "observeGroupParticipants", {
			contactId: contact.id
		} );
	}

	observeGroupInvites( contact ) {
		return this._callRemoteObservable( "observeGroupInvites", {
			contactId: contact.id
		} );
	}

	renameGroupParticipantAsync( contact, pid, newNickname ) {
		return this._callClientAsync( "renameGroupParticipant", {
			contactId: contact.id,
			pid, newNickname
		} );
	}

	deleteGroupParticipantAsync( contact, pid ) {
		return this._callClientAsync( "deleteGroupParticipant", {
			contactId: contact.id,
			pid
		} );
	}

	deleteGroupInviteAsync( contact, pid ) {
		return this._callClientAsync( "deleteGroupInvite", {
			contactId: contact.id,
			pid
		} );
	}

	addGroupParticipantsAsync( group, contactIds ) {
		return this._callClientAsync( "addGroupParticipants", {
			contactId: group.id,
			contactIds
		} );
	}

	addGroupParticipantExternalAsync( group, nickname ) {
		return this._callClientAsync( "addGroupParticipantExternal", {
			contactId: group.id,
			nickname
		} );
	}

	exitGroupAsync( contact ) {
		return this._callClientAsync( "exitGroup", {
			id: contact.id
		} );
	}

	observeProfile( ) {
		return this._profileSubj.filter( profile => !!profile );
	}

	getProfileAsync() {
		return this.observeProfile().take( 1 );
	}

	exitMultidescriptionAsync( contact ) {
		return this._callClientAsync( "exitMultidescription", {
			id: contact.id
		} );
	}

	updateProfileAsync( profileChanges ) {
		return this._callClientAsync( "updateProfile", {
			profileChanges
		} ).tap( () => {
			if ( profileChanges.language ) {
				translate.setLanguage( [ profileChanges.language ] );
			}
		} );
	}

	observeChats( ) {
		let currentContacts = Object.create( null );
		return (
			this._callRemoteObservable( "observeChats", {} )
				.map( contacts => _.toArray( contacts ) )
				.map( diff => this._uncompactContacts( currentContacts, diff ) )
		);
	}

	createFakeAccountAsync( name, password ) {
		return this._callClientAsync( "createFakeAccount", {
			name, password
		} );
	}

	changePasswordAsync( password ) {
		return this._callClientAsync( "changePassword", {
			password
		} );
	}

	clearHistoryAsync( contact ) {
		let contactId = contact && contact.id;
		let contactType = contact && contact.type;
		return this._callClientAsync( "clearHistory", {
			contactId, contactType
		} );
	}

	clearRemoteHistoryAsync( contact ) {
		let contactId = contact && contact.id;
		let contactType = contact && contact.type;
		return this._callClientAsync( "clearRemoteHistory", {
			contactId, contactType
		} );
	}

	setReadAllAsync( contact ) {
		return this._callClientAsync( "setReadAll", {
			contactType: contact.type,
			contactId: contact.id
		} );
	}

	queryHistoryAsync( contact ) {
		return this._callClientAsync( "queryHistory", {
			contactType: contact.type,
			contactId: contact.id
		} );
	}

	observeSharedContacts( ) {
		return this._sharedContactsObservable;
	}

	_uncompactSharedContacts( currentContacts, contacts ) {
		let mIdsSet = Object.create( null );
		contacts.forEach( contact => {
			mIdsSet[ contact.id ] = 1;
			contact.type = "multidescription";
			contact.sharedList = this._uncompactContacts(
				currentContacts[ contact.id ] = currentContacts[ contact.id ] || Object.create( null ),
				_.toArray( contact.sharedList ),
				"normal"
			);
			contact.sharedList.forEach( contact => {
				contact.type = "normal";
			} );
		} );

		_.forEach( currentContacts, ( m, mId ) => {
			if ( !mIdsSet[ mId ] ) {
				delete currentContacts[ mId ];
			}
		} );
		return contacts;
	}

	addHelperContactAsync( name ) {
		return (
			this._callClientAsync( "addHelperContact", {name} )
		);
	}

	sendVoiceMessageAsync( contact, ui8Array, milliseconds, mimeType, messageId ) {
		return this.sendFileAsync(
			contact,
			new Blob( [ ui8Array ], { type: mimeType } ),
			{ milliseconds, messageId, name: "message.ogg" }
		);
	}

	sendFileAsync( contact, file, messageProperties ) {
		if ( !messageProperties ) {
			messageProperties = {};
		}
		let type = file.type || "application/octet-stream";
		if ( file.size > MAX_FILE_SIZE_MB * 1024 * 1024 ) {
			return Rx.Observable.throw( new Error( `You can send file of size up to ${MAX_FILE_SIZE_MB} mb.` ) );
		}
		if ( !this._sendingFiles[ contact.id ] ) {
			this._sendingFiles[ contact.id ] = new Rx.BehaviorSubject( Object.create( null ) );
		}
		let thumbnailBase64Then = null;
		if ( type.split( "/" )[ 0 ] === "image" ) {
			thumbnailBase64Then = (
				makeThumbnailThen( file, 320, 320 )
					.catch( () => undefined )
			);
		} else {
			thumbnailBase64Then = Promise.resolve();
		}


		let messageId;
		let fileInfo;
		return (
			Rx.Observable.fromPromise( Promise.all( [
				ssgCrypto.createRandomBase64StringThen( 32 ),
			 	thumbnailBase64Then
			] ) )
			.flatMap( ( [ id, thumbnailBase64 ] ) => {
				messageId = messageProperties.messageId || id;
				fileInfo = {
					name: file.name || messageProperties.name,
					contentType: type,
					thumbnailBase64,
					sendingFile: file,
					total: file.size,
					...messageProperties
				};
				let files = this._sendingFiles[ contact.id ].getValue();
				files[ messageId ] = fileInfo;
				this._sendingFiles[ contact.id ].onNext( files );

				let resultSubj = this._createMessageFileWithProgressAsync(
					contact.id,
					messageId,
					type,
					file.size,
					file.name || messageProperties.name
				);
				resultSubj.filter( res => typeof res !== "string" )
					.subscribe( ( { sent, total } ) => {
						fileInfo.sent = sent;
						this._sendingFiles[ contact.id ].onNext( files );
					}, error => {
						fileInfo.error = error;
						this._sendingFiles[ contact.id ].onNext( files );
					} );
				return resultSubj.filter( res => typeof res === "string" );
			} )
			.concatMap( fileToken =>
				this.sendFileMessageAsync( contact, fileToken, file.size,
					type, file.name, messageId, fileInfo.thumbnailBase64, messageProperties )
			)
			.tap( () => {
				let files = this._sendingFiles[ contact.id ].getValue();
				delete files[ messageId ];
				this._sendingFiles[ contact.id ].onNext( files );
			} )
		);
	}

	_createMessageFileWithProgressAsync( contactId, messageId, contentType, size, fileName ) {
		return this._callRemoteObservable( "uploadFile", {
			contactId, messageId, contentType, size, fileName
		} );
	}

	_remote_readUploadingFile( { contactId, messageId, start, end } ) {
		let files = this._sendingFiles[ contactId ].getValue();
		if ( !files ) {
			throw new Error( "No files uploading for contact" );
		}
		let fileInfo = files[ messageId ];
		if ( !fileInfo ) {
			throw new Error( "Uploading file not found" );
		}
		let reader = new FileReader();
		let slicedFile = fileInfo.sendingFile.slice( start, end );
		return Rx.Observable.create( observer => {
			reader.onerror = error => {
				debugger;
				observer.onError( error );
				console.error( "Error reading sliced file", error, start, end );
				reader.onload = null;
				reader.onerror = null;
			};
			reader.onload = event => {
				let result = new Uint8Array( reader.result );
				if ( !result.length ) {
					debugger;
				}
				observer.onNext( result );
				observer.onCompleted();
			};
			reader.readAsArrayBuffer( slicedFile );
		} );
	}

	addSharedContactListAsync( nickname, name, workgroupIds, contactIds, rights ) {
		return this._callClientAsync( "addSharedContactList", {
			nickname, name, workgroupIds, contactIds, rights
		} );
	}

	observeSelfRights( { id } ) {
		return this._callRemoteObservable( "observeSelfRights", {
			contactId: id
		} );
	}

	observeWorkgroupParticipants( {id} ) {
		return this._callRemoteObservable( "observeWorkgroupParticipants", {
			contactId: id
		} );
	}

	observeWorkgroupRights( {id} ) {
		return this._callRemoteObservable( "observeWorkgroupRights", {
			contactId: id
		} );
	}

	observeWorkgroupInvites( {id} ) {
		return this._callRemoteObservable( "observeWorkgroupInvites", {
			contactId: id
		} );
	}

	observeWorkgroupAutocleanTime( {id} ) {
		return this._callRemoteObservable( "observeWorkgroupAutocleanTime", {
			contactId: id
		} );
	}

	observeWorkgroupRemoteAutocleanTime( {id} ) {
		return this._callRemoteObservable( "observeWorkgroupRemoteAutocleanTime", {
			contactId: id
		} );
	}

	setWorkgroupAutocleanTimeAsync( {id}, autocleanTime ) {
		return this._callClientAsync( "setWorkgroupAutocleanTime", {
			contactId: id, autocleanTime
		} );
	}

	setWorkgroupRemoteAutocleanTimeAsync( {id}, autocleanTime ) {
		return this._callClientAsync( "setWorkgroupRemoteAutocleanTime", {
			contactId: id, autocleanTime
		} );
	}

	removeWorkgroupParticipantAsync( {id}, pid ) {
		return this._callClientAsync( "removeWorkgroupParticipant", {
			contactId: id, pid
		} );
	}

	removeWorkgroupInviteAsync( {id}, pid ) {
		return this._callClientAsync( "removeWorkgroupInvite", {
			contactId: id, pid
		} );
	}

	addToWorkGroupAsync( {id: multidescriptionId}, {id: contactId} ) {
		return this._callClientAsync( "addToWorkGroup", {
			multidescriptionId, contactId
		} );
	}

	setWorkgroupRightsAsync( {id: multidescriptionId }, modifiedRights ) {
		return this._callClientAsync( "setWorkgroupRights", {
			multidescriptionId, modifiedRights
		} );
	}

	shareContactsAsync( contactIds, multidescriptionId ) {
		return this._callClientAsync( "shareContacts", {
			contactIds, multidescriptionId
		} );
	}

	createMultiInviteAsync( { id } ) {
		return this._callClientAsync( "createMultiInvite", {
			contactId: id
		} );
	}

	deleteMultiInviteAsync( { id } ) {
		return this._callClientAsync( "deleteMultiInvite", {
			contactId: id
		} );
	}

	observeOnlineStatus( ) {
		return this._onlineStatus;
	}

	deleteAccountAsync( ) {
		return this._callClientAsync( "deleteAccount", {} );
	}

	acceptInviteByInviteIdAsync( inviteId, { multidescriptionId } ) {
		return this._callClientAsync(
			"acceptInviteByInviteId",
			{ inviteId, multidescriptionId }
		);
	}

	joinGroupByInviteIdAsync( inviteId ) {
		return this._callClientAsync(
			"joinGroupByInviteId",
			{ inviteId }
		);
	}

	joinByTokenAsync( token ) {
		return this._callClientAsync(
			"joinByToken",
			{ token }
		);
	}

	joinWorkgroupByInviteIdAsync( inviteId ) {
		return this._callClientAsync(
			"joinWorkgroupByInviteId",
			{ inviteId }
		);
	}

	observeUserTable( ) {
		return this._callRemoteObservable( "observeUserTable", {} ).map( users => _.toArray( users ) );
	}

	observeGlobalUserType( ) {
		return this._callRemoteObservable( "observeGlobalUserType", {} );
	}

	addUserAsync( user ) {
		return this._callClientAsync( "addUser", { user } );
	}

	editUserAsync( user ) {
		return this._callClientAsync( "editUser", { user } );
	}

	deleteUserAsync( userId ) {
		return this._callClientAsync( "deleteUser", { userId } );
	}

	sendDeleteMessageAsync( { id: contactId, type: contactType }, messageIndex ) {
		return this._callClientAsync(
			"sendDeleteMessage",
			{ messageIndex, contactId, contactType }
		);
	}

	sendEditTextMessageAsync( { id: contactId, type: contactType }, messageIndex, newText ) {
		return this._callClientAsync(
			"sendEditTextMessage",
			{ messageIndex, newText, contactId, contactType }
		);
	}

	getPrivateServerConfigAsync( ) {
		return this._callClientAsync( "getPrivateServerConfig", {} );
	}

	muteContactNotificationAsync( contactId, contactType ) {
		return this._callClientAsync(
			"muteContactNotification",
			{ contactId, contactType }
		);
	}

	muteContactNotification( contact ) {
		let muteIdAsync = new Rx.ReplaySubject( 1 );
		this.muteContactNotificationAsync( contact.id, contact.type )
			.subscribe(
				( { muteId } ) => {
					muteIdAsync.onNext( muteId );
					muteIdAsync.onCompleted();
				},
				error => {
					muteIdAsync.onNext( false );
					muteIdAsync.onCompleted();
				}
			);
		return {
			dispose: () => {
				muteIdAsync.subscribe( muteId => {
					if ( muteId === false ) {
						return;
					}
					this.unmuteContactNotificationAsync( muteId )
						.subscribe();
				} );
			}
		};
	}

	unmuteContactNotificationAsync( muteId ) {
		return this._callClientAsync(
			"unmuteContactNotification",
			{ muteId }
		);
	}

	observeCommonData() {
		let currentShared = Object.create( null );
		let currentContacts = Object.create( null );
		let currentGroups = Object.create( null );
		return this._callRemoteObservable(
			"observeCommonData",
			{}
		).map( ( { m, c, g, isAdmin, isPrivileged, p } ) => ( {
			m: this._uncompactSharedContacts( currentShared, _.toArray( m ) ),
			c: this._uncompactContacts( currentContacts, _.toArray( c ), "normal" ),
			g: this._uncompactContacts( currentGroups, _.toArray( g ), "group" ),
			isAdmin, isPrivileged, p
		} ) );
	}

    isRegisteredAsync() {
		//This needed to early determine which view to show: registration or login
		if ( localStorage[ "isRegistered" + this._storageNumber ] ) {
			return Rx.Observable.just( true );
		}
		// return Rx.Observable.just( localStorage[ "isRegistered" + this._storageNumber ] );
       return this._callClientAsync( "isRegistered", {} );
    }

	registerAndLoginAsync( registrationData, commonProfileData, inviteToken ) {
		return this._callClientAsync(
			"registerAndLogin",
			{ registrationData, commonProfileData, inviteToken }
		).flatMap(() =>
			configServiceLocator().getCurrentConfigAsync()
		).tap( config => {
			configuration.setConfiguration( config );
			this._afterLogin();
			localStorage[ "isRegistered" + this._storageNumber ] = true;
			if ( registrationData.password2 !== null && registrationData.password2 !== undefined ) {
				this.reload();
			}
		} );
	}

	dropAccountAsync() {
		return (
			this._callClientAsync( "dropAccount", {} )
				.tap( () => {
					this._isLoggedIn = false;
					delete localStorage[ "isRegistered" + this._storageNumber ];
					this.reload();
				} )
		);
	}

	mutePushNotifications() {
		this._isPushNotificationMuted = true;
	}

	unmutePushNotifications() {
		this._isPushNotificationMuted = false;
	}

	_remote_reload( { isAccountDropped } ) {
		if ( isAccountDropped ) {
			delete localStorage[ "isRegistered" + this._storageNumber ];
		}
		this.reload();
		return new Rx.ReplaySubject( 1 );
	}

	reload() {
		this.unmutePushNotifications();
		global.window.location.hash = "#";
		setTimeout( () => {
			global.window.location.href = global.window.location.href;
			global.window.location.reload();
		}, 0 );
	}

	isLoggedIn() {
		return !!this._isLoggedIn;
	}

	logInAndConnectAsync( password ) {
		return (
			Rx.Observable.combineLatest(
				this._callClientAsync( "logInAndConnect", { password } ),
				configServiceLocator().getCurrentConfigAsync(),
				( isSuccess, config ) => {
					if ( isSuccess ) {
						configuration.setConfiguration( config );
						this._afterLogin();
					}
					return isSuccess;
				}
			)
		);
	}

	observeExpireDt() {
		return this._callRemoteObservable( "observeExpireDt", {} );
	}

	writeRandomSeedAsync( password1, password2 ) {
		return (
			this._callClientAsync( "writeRandomSeed", { password1, password2 } )
		);
	}

	errorAsync( error ) {
		return (
			this._callClientAsync(
				"error",
				{ message: ( error && error.message ) || ( error + "" ) }
			)
		);
	}

	observeUnreadCountExcept( contactId, contactType ) {
		return (
			this._callRemoteObservable( "observeUnreadCountExcept", { contactId, contactType } )
		);
	}

	isWebConnectionTokenValidAsync( token ) {
		return (
			this._callClientAsync( "isWebConnectionTokenValid", { token } )
		);
	}

	makeWebConnectionAsync( token ) {
		return (
			this._callClientAsync( "makeWebConnection", { token } )
		);
	}

	closeWebConnectionAsync() {
		return (
			this._callClientAsync( "closeWebConnection", {} )
		);
	};

	observeWebConnectionSession() {
		return (
			this._callRemoteObservable( "observeWebConnectionSession", {} )
		);
	}

	observeBackup() {
		return (
			this._callRemoteObservable( "observeBackup", {} )
		);
	}

	cancelCreateBackupAsync() {
		return (
			this._callClientAsync( "cancelCreateBackup", {} )
		);
	}

	createBackupAsync( fakePassword ) {
		let taskHandle = this._longTaskService.runLongTask( "Backup in progress" );

		return (
			this._callRemoteObservable( "createBackup", { fakePassword } )
				.tap( _.noop,
					error => { taskHandle.dispose(); },
					() => { taskHandle.dispose(); }
				)
		);
	}

	dropBackupAsync() {
		return (
			this._callClientAsync( "dropBackup", {} )
		);
	}

	rejoinContactsAsync( password1, password2 ) {
		return (
			this._callClientAsync( "rejoinContacts", { password1, password2 } )
		);
	}

	observeTimeResync() {
		return (
			this._callRemoteObservable( "observeTimeResync", {} )
		);
	}

	isPasswordValidAsync( password ) {
		return (
			this._callClientAsync( "isPasswordValid", { password } )
		);
	}

	restoreBackupAsync( token ) {
		return (
			this._callRemoteObservable( "restoreBackup", { token } )
				.tapOnCompleted( () => {
					localStorage[ "isRegistered" + this._storageNumber ] = true;
				} )
		);
	}
//params: (contact, message)
	setChatMessageProcessedAsync( contact, message ) {
		let { id: contactId, type: contactType } = contact;
		let { index: messageIndex } = message;
		return (
			this._callClientAsync(
				"setChatMessageProcessed",
				{ contactId, contactType, messageIndex }
			)
		);
	}

	isServerConfiguredAsync() {
		return this._callClientAsync( "isServerConfigured", {} );
	}

	createUserSystemCreationTokenAsync() {
		return this._callClientAsync( "createUserSystemCreationToken", {} );
	}

	getDemoLeftTimeAsync() {
		return this._callClientAsync( "getDemoLeftTime", {} );
	}

	upgradeAsync( registrationData, inviteToken ) {
		return this._callClientAsync( "upgrade", { registrationData, inviteToken } );
	}

	pingAsync( registrationData, inviteToken ) {
		return this._callClientAsync( "ping", {} );
	}

	setConfigurationAsync( config ) {
		return this._callClientAsync( "setConfiguration", { config } );
	}

	setStorageNumber( initialStorageNumber ) {
		this._storageNumber = initialStorageNumber | 0;
		LocalStorage.setStorageNumber( initialStorageNumber );
	}
}
