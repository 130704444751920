import Rx from "rx";
import ssgCrypto from "ssg.crypto";

import * as utils from "../../common/utils.js";
import base64 from "../../../common/utils/base64.js";

import seedRepositoryLocator from "../repositories/locators/seed.js";
import configuration from "../../common/configuration.js";

class SeedService {
	constructor( ) {
		this._seedRepository = seedRepositoryLocator();
	}

	dropDataAsync( ) {
		return this._seedRepository.dropDataAsync();
	}

	writeRandomSeedAsync( password1, password2, progressSubject ) {
		if ( typeof password1 !== "string" ) {
			return Rx.Observable.throw( new Error( "First password required and must be a string" ) );
		}

		if ( ( typeof password2 !== "string" ) && ( password2 !== null ) ) {
			return Rx.Observable.throw( new Error( "Second password must be string or null" ) );
		}

		if ( password1 === password2 ) {
			throw new Error();
		}
		let reseedCount = configuration.getMasterKeyReseedCount();
		let result = (
			Rx.Observable.interval( 500 ) //TODO: some gyro
				.tap( i => { progressSubject && progressSubject.onNext( ( i + 1 ) / reseedCount ); } )
				.tapOnCompleted( () => { progressSubject && progressSubject.onCompleted(); } )
				.take( reseedCount )
				.startWith( null )
				.flatMap( () => Rx.Observable.fromPromise( ssgCrypto.createRandomBufferThen( 32 ) ) )
				.toArray()
				.map( buffers => ssgCrypto.hash( Buffer.concat( buffers ) ) )
				.flatMap( clientSeed => {
					if ( password2 === null ) {
						return Rx.Observable.fromPromise(
							ssgCrypto.createRandomBufferThen( 32 )
								.then( appendSeed => Buffer.concat( [ clientSeed, appendSeed ] ) )
						);
					}
					//Use two passwords
					return Rx.Observable.fromPromise(
						ssgCrypto.getPseudorandomFuncThen()
							.then( func => {
								let appendSeed;
								do {
									appendSeed = func( 32 );
								} while (
										this._getPositionBitWithAppend( clientSeed, appendSeed, password1 )
										=== this._getPositionBitWithAppend( clientSeed, appendSeed, password2 )
									);
								return Buffer.concat( [ clientSeed, appendSeed ] );
							} )
					);
				} )
				.flatMap( clientSeed => {
					return this._seedRepository.replaceAsync( clientSeed ).map( clientSeed );
				} )
				.shareReplay()
		);

		if ( progressSubject ) {
			/*let bitsPerReseed = 32;//random.getBitsRequiredForReseed(); TODO: use gyro info
			Rx.Observable.combineLatest(
					random.observeReseedProgress().first(),
					random.observeReseedProgress().takeUntil( result ),
					( first, current ) => ( current - first ) / ( reseedCount * bitsPerReseed )
				)
				.multicast( progressSubject )
				.connect();
			progressSubject.onNext( 1 );
			progressSubject.onCompleted();
		*/
		}

		return result;
	}
	getServerSeedIdAsync( password ) {
		if ( password === null || password === undefined )  {
			return Rx.Observable.fromPromise(
				ssgCrypto.createRandomBufferThen( 32 )
					.then( seedIdBuffer => base64.encode( seedIdBuffer ) )
			);
		}

		let passwordBuffer = new Buffer( password );
		return Rx.Observable.fromPromise(
			this._seedRepository.readAsBufferThen()
				.then( clientSeed => ssgCrypto.hash( clientSeed, passwordBuffer ) )
				.then( seedIdBuffer => base64.encode( seedIdBuffer ) )
		);
	}

	getPositionBitAsync( password ) {
		return (
			this._seedRepository.readAsBufferAsync()
				.map( clientSeed => keysHelper.getPositionBit( clientSeed, password ) )
			);
	}

	_getPositionBitWithAppend( clientSeed, appendSeed, password ) {
		return ssgCrypto.hash( clientSeed, appendSeed, new Buffer( password ), clientSeed, appendSeed )[ 0 ] & 1;
	}

	readSeedAsync( ) {
		return this._seedRepository.readAsBufferAsync().map( seed => seed.length ? seed : null );
	}

	getEncryptionKeyAsync( serverSeed, password ) {
		return (
			this._seedRepository.readAsBufferAsync()
				.map( clientSeed => ssgCrypto.hash( clientSeed, serverSeed, new Buffer( password ) ) )
				.share()
		);
	}

	isExistAsync() {
		return (
			this._seedRepository.isAnyDataAsync()
			);
	}

	getModificationTimeThen() {
		return this._seedRepository.getModificationTimeThen();
	}

	getModificationTimeAsync() {
		return this._seedRepository.getModificationTimeAsync();
	}
}

export default SeedService;
