import ssgCrypto from "ssg.crypto";

import HubBase from "./hub.base.js";
import {callUntilSuccessAsync} from "../../common/utils.js";

class PushHub extends HubBase {
  clearNotifications( registrationId ) {
    ssgCrypto.createRandomBase64StringThen( 32 )
      .then( callId => {
        this.send( "clearNotify", { registrationId, callId } );
      } );
  }

  sendNotifyThen( registrationId, bundleId, connectionIds, makeSound ) {
    return (
      ssgCrypto.createRandomBase64StringThen( 32 )
        .then( callId => {
          this.send( "notify", {
            registrationId, bundleId, connectionIds, makeSound, callId
          } );
        } )
    );
  }
}

export default PushHub;
