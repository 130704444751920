import React from "react";

class SwitchComponent extends React.Component {
	render() {
		return (
			<div className="onoffswitch">
				<input
					type="checkbox"
					className="onoffswitch-checkbox"
					id={this.props.id}
					checked={this.props.checked}
					onChange={this.props.onChange}
				/>
				<label className="onoffswitch-label" htmlFor={this.props.id}>
					<span className="onoffswitch-inner"></span>
					<span className="onoffswitch-switch"></span>
				</label>
			</div>
		);
	}

	static get propTypes() {
		return {
			id: React.PropTypes.string.isRequired,
			onChange: React.PropTypes.func.isRequired,
			checked: React.PropTypes.bool.isRequired
		};
	}
}

export default SwitchComponent;
