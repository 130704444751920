import Rx from "rx";
// import Queue from "promise-queue";
import ssgCrypto, {KEY_KINDS, Key} from "ssg.crypto";

import {callUntilSuccessAsync, callUntilSuccessThen} from "../../common/utils.js";
import ClientServerOrdered from "./client.server.ordered.js";
import {
	serializeObject,
	deserializeObject,
	serializeObjectWithKeysToNewManagedBufferThen,
	deserializeObjectWithKeysFromManagedBufferThen,
	deserializeJSONOnlyFromManagedBuffer
} from "../../common/serializer.js";

class Queue {
	constructor() {
		this._currentTask = null;
		this._pendingTasks = [];
	}

	enqueue( generator ) {
		return new Promise( ( resolve, reject ) => {
			this._pendingTasks.push( { generator, resolve, reject } );
			this._run();
		} );
	}

	runOutOfOrder( generator ) {
		return new Promise( ( resolve, reject ) => {
			this._pendingTasks.splice( 0, 0, { generator, resolve, reject } );
			this._run();
		} );
	}

	_run() {
		if ( this._currentTask || !this._pendingTasks.length ) {
			return;
		}
		this._currentTask = this._pendingTasks.splice( 0, 1 )[ 0 ];
		try {
			this._currentTask.generator()
				.then( success => {
					try{
						this._currentTask.resolve( success );
					}catch(err){}
					delete this._currentTask;
					this._run();
				}, error => {
					try {
						this._currentTask.reject( e );
					}catch(err){}
					delete this._currentTask;
					this._run();
				} );
		} catch( e ) {
			try {
				this._currentTask.reject( e );
			}catch(err){}
			delete this._currentTask;
			this._run();
		}
	}
}

function keyToStringThen( key ) {
	if ( !key ) {
		return Promise.resolve( key + "" );
	}
	return new Promise( resolve => {
		key.postponeManagedBuffer( mb => {
			mb.useAsBuffer( b => {
				resolve( b.toString( "base64" ) );
			} );
		} );
	} );
}

function printKey( format, ...keys ) {
	let keyHandles = keys.map( key => key && key.addKeyUse( "Print" ) );
	return (
		Promise.all( keys.map( key => keyToStringThen( key ) ) )
			.then( sKeys => {
				for ( let i = 0; i < sKeys.length; i++ ) {
					format = format.split( `{${i}}` ).join( sKeys[i] );
					keys[ i ] && keys[ i ].removeKeyUse( keyHandles[ i ] );
				}
				console.log( format );
			} )
	);
}

class KDFChain {
	constructor( key, econfig ) {
		if ( key && key.kind !== KEY_KINDS.INTERMEDIATE ) {
			throw new Error( "key kind not INTERMEDIATE" );
		}

		this._zeroKeyThen = ssgCrypto.createKeyFromBufferThen(
			new Buffer( 1 ).fill( 0 ),
			KEY_KINDS.INTERMEDIATE,
			econfig
		);
		this._oneKeyThen = ssgCrypto.createKeyFromBufferThen(
			new Buffer( 1 ).fill( 1 ),
			KEY_KINDS.INTERMEDIATE,
			econfig
		);

		this._econfig = econfig;
		this._key = key;
		this._isBusy = false;
	}

	ratchetThen( inputKey ) {
		if ( !inputKey || inputKey.kind !== KEY_KINDS.INTERMEDIATE ) {
			throw new Error( "inputKey kind not INTERMEDIATE" );
		}
		if ( this._isBusy ) {
			throw new Error( "Already busy" );
		}
		this._isBusy = true;
		if ( !this._key ) {
			return Promise.all( [
				this._zeroKeyThen,
				this._oneKeyThen
			] ).then( ( [ zeroKey, oneKey ] ) => Promise.all( [
				ssgCrypto.createDerivedKeyFromKeysThen( zeroKey, inputKey, KEY_KINDS.INTERMEDIATE, this._econfig ),
				ssgCrypto.createDerivedKeyFromKeysThen( oneKey, inputKey, KEY_KINDS.INTERMEDIATE, this._econfig )
			] ) ).then( ( [ nextChainKey, outputKey ] ) => {
				this._key = nextChainKey;
				this._isBusy = false;
				return outputKey;
			} );
		}

		return Promise.all( [
			this._zeroKeyThen,
			this._oneKeyThen,
			ssgCrypto.createDerivedKeyFromKeysThen( this._key, inputKey, KEY_KINDS.INTERMEDIATE, this._econfig )
		] ).then( ( [ zeroKey, oneKey, intermediateKey ] ) => Promise.all( [
			ssgCrypto.createDerivedKeyFromKeysThen( zeroKey, intermediateKey, KEY_KINDS.INTERMEDIATE, this._econfig ),
			ssgCrypto.createDerivedKeyFromKeysThen( oneKey, intermediateKey, KEY_KINDS.INTERMEDIATE, this._econfig ),
			Promise.resolve( intermediateKey )
		] ) ).then( ( [ nextChainKey, outputKey, intermediateKey ] ) => {
			this._key.dispose();
			intermediateKey.dispose();
			this._key = nextChainKey;
			this._isBusy = false;
			return outputKey;
		} );
	}

	get key() {
		return this._key;
	}

	dispose() {
		if ( this._isBusy ) {
			throw new Error( "Cannot dispose while busy" );
		}
		if ( this._key ) {
			this._key.dispose()
		}
		this._zeroKeyThen.then( zeroKey => { zeroKey.dispose(); } );
		this._oneKeyThen.then( oneKey => { oneKey.dispose(); } );
		delete this._key;
	}
}

class RootChain extends KDFChain {
	constructor( rootKey, publicKey, privateKey, econfig ) {
		super( rootKey, econfig );
		this._publicKey = publicKey;
		this._privateKey = privateKey;
	}

	ratchetWithPrivateThen( newPrivateKey ) {
		// this._privateKey.dispose();
		this._privateKey = newPrivateKey;
		return this._doRatchetThen();
	}

	ratchetWithPublicThen( newPublicKey ) {
		// this._publicKey.dispose();
		this._publicKey = newPublicKey;
		return this._doRatchetThen();
	}

	_doRatchetThen() {
		return (
			ssgCrypto.createSharedKeyThen( this._privateKey, this._publicKey, this._econfig )
				.then( sharedKey => this.ratchetThen( sharedKey ) )
		);
	}

	ratchetWithZeroKeyThen() {
		return (
			this._zeroKeyThen.then( zeroKey =>
				this.ratchetThen( zeroKey )
			)
		);
	}

	ratchetWithOneKeyThen() {
		return (
			this._oneKeyThen.then( oneKey =>
				this.ratchetThen( oneKey )
			)
		);
	}

	ratchetThen( key ) {
		let oldKey = this._key;
		let keyHandle = oldKey.addKeyUse();
		return (
			super.ratchetThen( key )
				// .then( outputKey =>
				// 	printKey( "Root key {0} => {1} using {2}", oldKey, this._key, key )
				// 		.then( () => outputKey )
				// )
		);
	}

	get publicKey() {
		return this._publicKey;
	}

	get privateKey() {
		return this._privateKey;
	}
}

class SendChain extends KDFChain {
	constructor( key, index, econfig ) {
		super( key, econfig );
		this._index = index;
	}

	ratchetThen() {
		return (
			Promise.all( [ this._zeroKeyThen, this._oneKeyThen ] )
				.then( ( [ zeroKey, oneKey ] ) =>
					super.ratchetThen( zeroKey )
						.then( intermediateKey => Promise.all( [
							Promise.resolve( ++this._index ),
							Promise.resolve( intermediateKey ),
							ssgCrypto.createDerivedKeyFromKeysThen(
								zeroKey,
								intermediateKey, KEY_KINDS.MAC, this._econfig
							),
							ssgCrypto.createDerivedKeyFromKeysThen(
								oneKey,
								intermediateKey, KEY_KINDS.SYMMETRIC_ENCRYPTION, this._econfig
							)
						] ) )
				)
				.then( ( [ index, intermediateKey, macKey, encKey ] ) => {
					intermediateKey.dispose();
					return { index, macKey, encKey };
				} )
		);
	}

	get index() {
		return this._index;
	}

	replaceKey( newKey ) {
		if ( !newKey || newKey.kind !== KEY_KINDS.INTERMEDIATE ) {
			throw new Error( "Invalid key kind" );
		}
		if ( this._isBusy ) {
			throw new Error( "Not while busy" );
		}
		// printKey( "Replacing send key {0} => {1}", this._key, newKey );
		this._key && this._key.dispose();
		this._key = newKey;
		this._index = 0;
	}
}

class ReceiveChain extends KDFChain {
	constructor( key, index, econfig ) {
		super( key, econfig );
		this._index = index;
	}

	_ratchetNextThen() {
		return (
			Promise.all( [ this._zeroKeyThen, this._oneKeyThen ] )
				.then( ( [ zeroKey, oneKey ] ) =>
					super.ratchetThen( zeroKey )
						.then( intermediateKey => Promise.all( [
							Promise.resolve( ++this._index ),
							Promise.resolve( intermediateKey ),
							ssgCrypto.createDerivedKeyFromKeysThen(
								zeroKey,
								intermediateKey,
								KEY_KINDS.MAC,
								this._econfig
							),
							ssgCrypto.createDerivedKeyFromKeysThen(
								oneKey,
								intermediateKey,
								KEY_KINDS.SYMMETRIC_ENCRYPTION,
								this._econfig
							)
						] ) )
				)
				.then( ( [ index, intermediateKey, macKey, encKey ] ) => {
					intermediateKey.dispose();
					return { index, macKey, encKey };
				} )
		);
	}

	ratchetThen( newIndex ) {
		if ( this._index >= newIndex ) {
			throw new Error( "receive ratchet with this._index >= newIndex" );
		}
		if ( this._index === newIndex - 1 ) {
			return this._ratchetNextThen();
		}
		return (
			this._zeroKeyThen
				.then( zeroKey => super.ratchetThen( zeroKey ) )
				.then( intermediateKey => {
					this._index++;
					intermediateKey.dispose();
					return this.ratchetThen( newIndex );
				} )
		);
	}

	replaceKey( newKey ) {
		if ( !newKey || newKey.kind !== KEY_KINDS.INTERMEDIATE ) {
			throw new Error( "Invalid key kind" );
		}
		if ( this._isBusy ) {
			throw new Error( "Not while busy" );
		}
		// printKey( "Replacing receive key {0} => {1}", this._key, newKey );
		this._key && this._key.dispose();
		this._key = newKey;
		this._index = 0;
	}

	get index() {
		return this._index;
	}
}

export class KeyState {
	constructor( data, seedKey, dhInitPrivKey, dhInitPubKey, storeFunc, econfig ) {
		if ( typeof data.nextIndex !== "number" ) {
			throw new Error( "nextIndex required" );
		}
		if ( !seedKey || seedKey.kind !== KEY_KINDS.INTERMEDIATE ) {
			throw new Error( "seedKey required" );
		}
		if ( !dhInitPrivKey || dhInitPrivKey.kind !== KEY_KINDS.KEY_EXCHANGE_PRIVATE ) {
			throw new Error( "dhInitPrivKey required" );
		}
		if ( !dhInitPubKey || dhInitPubKey.kind !== KEY_KINDS.KEY_EXCHANGE_PUBLIC ) {
			throw new Error( "dhInitPrivKey required" );
		}
		this._nextIndex = data.nextIndex;
		this._rootChain = new RootChain( data.rootKey, data.dhPubKey, data.dhPrivKey, econfig );
		this._sendChain = new SendChain( data.sChainKey, data.sChainIndex, econfig );
		this._receiveChain = new ReceiveChain( data.rChainKey, data.rChainIndex, econfig );
		this._queue = new Queue();
		this._seedKey = seedKey;
		this._storeFunc = storeFunc;
		this._econfig = econfig;
		this._isDisposed = false;
		this._headerKeyThen = (
			ssgCrypto.createSharedKeyThen( dhInitPrivKey, dhInitPubKey, this._econfig )
				.then( sharedKey => this._isDisposed ? null
					: ssgCrypto.createDerivedKeyFromKeysThen(
						seedKey, sharedKey, KEY_KINDS.SYMMETRIC_ENCRYPTION, this._econfig
					)
				)
		);
		this._currentSelfPublicBufferThen = (
			ssgCrypto.createKeyExchangePublicKeyThen( data.dhPrivKey, econfig )
				.then( publicKey => new Promise( resolve => {
					publicKey.postponeManagedBuffer( mb => {
						mb.useAsBuffer( b => {
							resolve( Buffer.concat( [ b ] ) );
						} );
					} );
				} ) )
		);
	}

	static createNewThen( pid1, pid2, seedKey, dhPrivKey, dhPubKey, storer, econfig ) {
		return (
			Promise.all( [
				ssgCrypto.createKeyFromBufferThen(
					new Buffer( pid1 > pid2 ? pid2 : pid1, "base64" ), KEY_KINDS.INTERMEDIATE, econfig
				),
				ssgCrypto.createKeyFromBufferThen(
					new Buffer( pid1 > pid2 ? pid1 : pid2, "base64" ), KEY_KINDS.INTERMEDIATE, econfig
				)
			] ).then( ( [ key1, key2 ] ) => ssgCrypto.createDerivedKeyFromKeysThen(
				key1, key2, KEY_KINDS.INTERMEDIATE, econfig
			) ).then( pidKey => ssgCrypto.createDerivedKeyFromKeysThen(
					seedKey, pidKey, KEY_KINDS.INTERMEDIATE, econfig
				).then( preKey => {
					pidKey.dispose();
					return preKey;
				} ).then( preKey => ssgCrypto.createSharedKeyThen(
						dhPrivKey, dhPubKey, econfig
					).then( sharedKey => ssgCrypto.createDerivedKeyFromKeysThen(
						preKey, sharedKey, KEY_KINDS.INTERMEDIATE, econfig
					).then( rootKey => {
						preKey.dispose();
						sharedKey.dispose();
						return rootKey;
					} ) )
				)
			).then( rootKey => {
				let ks = new KeyState(
					{
						rootKey, sChainKey: null, sChainIndex: 0,
						rChainKey: null, rChainIndex: 0,
						dhPrivKey, dhPubKey, nextIndex: 0
				 	},
					seedKey, dhPrivKey, dhPubKey, storer, econfig
				);
				return ks._rootChain.ratchetWithZeroKeyThen().then( newRootOutputKey => {
					if ( pid1 < pid2 ) {
						ks._sendChain.replaceKey( newRootOutputKey );
						return ks._storeKeysThen( ks, false );
					}
					ks._receiveChain.replaceKey( newRootOutputKey );
					return ks._makeNewKeyPairThen().then( () =>
						ks._storeKeysThen( ks, false )
					);
				} );
			} )
		);
	}

	getHeaderKeyThen() {
		return this._headerKeyThen;
	}

	_makeNewKeyPairThen() {
		return (
			ssgCrypto.createRandomKeyExchangeKeyPairThen( this._econfig )
				.then( ( { publicKey, privateKey } ) => {
					this._currentSelfPublicBufferThen = new Promise( resolve => {
						publicKey.postponeManagedBuffer( mb => {
							mb.useAsBuffer( b => {
								resolve( Buffer.concat( [ b ] ) );
							} );
							setTimeout( () => { publicKey.dispose(); }, 0 );
						} );
					} );
					return this._rootChain.ratchetWithPrivateThen( privateKey );
				} )
				.then( newRootOutputKey => {
					this._sendChain.replaceKey( newRootOutputKey );
				} )
		);
	}

	createSendKeysThen() {
		return this._queue.runOutOfOrder( () =>
			Promise.all( [
				this._sendChain.ratchetThen(),
				//TODO: optimize by caching self publicKey
				ssgCrypto.createKeyExchangePublicKeyThen( this._rootChain.privateKey, this._econfig )
					.then( publicKey => new Promise( resolve => {
						publicKey.postponeManagedBuffer( mb => {
							mb.useAsBuffer( b => {
								resolve( Buffer.concat( [ b ] ) );
							} );
							setTimeout( () => {
								publicKey.dispose();
							}, 0 );
						} );
					} ) )
			] ).then( ( [ { index, macKey, encKey }, publicKeyBuffer ] ) =>
				( { senderChainIndex: index, macKey, encKey, publicKeyBuffer } )
			).then( res => this._storeKeysThen( res, false ) )
		);
	}

	createReceiveKeysThen( remotePublicKeyBuffer, chainIndex, newNextIndex, processFunc ) {
		if ( !Buffer.isBuffer( remotePublicKeyBuffer ) ) {
			throw new Error( "remotePublicKeyBuffer required" );
		}
		if ( typeof chainIndex !== "number" ) {
			throw new Error( "chainIndex required" );
		}
		if ( typeof newNextIndex !== "number" ) {
			throw new Error( "newNextIndex required" );
		}
		if ( typeof processFunc !== "function" ) {
			throw new Error( "processFunc required" );
		}
		return this._queue.enqueue( () =>
			new Promise( resolve => {
				this._rootChain.publicKey.postponeManagedBuffer( mb => {
					mb.useAsBuffer( b => {
						resolve( b.equals( remotePublicKeyBuffer) );
					} );
				} );
			} )
			.then( isPublicKeyTheSame => {
				if ( isPublicKeyTheSame ) {
					return;
				}
				return this._doReceiveRatchetThen( remotePublicKeyBuffer );
			} )
			.then( () => this._receiveChain.ratchetThen( chainIndex ) )
			.then( result => {
				this._nextIndex = newNextIndex;
				return (
					processFunc( result )
						.then( () => this._storeKeysThen( result, true ) )
				);
			} )
		);
	}

	_doReceiveRatchetThen( remotePublicKeyBuffer ) {
		return (
			ssgCrypto.createKeyFromBufferThen(
				remotePublicKeyBuffer, KEY_KINDS.KEY_EXCHANGE_PUBLIC, this._econfig
			)
				.then( publicKey => this._rootChain.ratchetWithPublicThen( publicKey ) )
				.then( newRootOutputKey => {
					this._receiveChain.replaceKey( newRootOutputKey );
					return this._makeNewKeyPairThen();
				} )
		);
	}

	_storeKeysThen( resultToReturn, isReceive ) {
		if ( this._storeInProgress ) {
			throw new Error( "Store already in progress" );
		}
		this._storeInProgress = true;

		let rootKeyHandle = this._rootChain.key.addKeyUse( "Store" );
		let sChainKeyHandle = this._sendChain.key.addKeyUse( "Store" );
		let rChainKeyHandle = this._receiveChain.key && this._receiveChain.key.addKeyUse( "Store" );
		let dhPrivKeyHandle = this._rootChain.privateKey.addKeyUse( "Store" );
		let dhPubKeyHandle = this._rootChain.publicKey.addKeyUse( "Store" );

		let storeData = {
			rootKey: this._rootChain.key,
			sChainKey: this._sendChain.key,
			sChainIndex: this._sendChain.index,
			rChainKey: this._receiveChain.key,
			rChainIndex: this._receiveChain.index,
			dhPrivKey: this._rootChain.privateKey,
			dhPubKey: this._rootChain.publicKey,
			nextIndex: this._nextIndex
		};

		return this._storeFunc( storeData, isReceive, this._seedKey,
			this._rootChain.privateKey, this._rootChain.publicKey
		).then( () =>  {
			this._rootChain.key.removeKeyUse( rootKeyHandle );
			this._sendChain.key.removeKeyUse( sChainKeyHandle );
			this._receiveChain.key && this._receiveChain.key.removeKeyUse( rChainKeyHandle );
			this._rootChain.privateKey.removeKeyUse( dhPrivKeyHandle );
			this._rootChain.publicKey.removeKeyUse( dhPubKeyHandle );
			this._storeInProgress = false;
			return resultToReturn;
		} );
	}

	dispose() {
		this._headerKeyThen.then( hKey => {
			hKey && hKey.dispose();
		} );
		this._rootChain.dispose();
		this._sendChain.dispose();
		this._receiveChain.dispose();
		this._isDisposed = true;
	}
}

export class ClientServerJSONP2PSender extends ClientServerOrdered {
	constructor( apiUrlBase, connectionId, keyState, econfig ) {
		let signer = {
			getSignByteLength: index => { throw new Error( "Not implemented" ); },
			makeSignThen: ( bs, index ) => { throw new Error( "Not implemented" ); }
		};
		super( apiUrlBase, connectionId, "", 0, signer );

		this._econfig = econfig;
		this._keyState = keyState;
	}

	sendThen( json, transaction ) {
		let optionalKey2KindMap = this._getKeyMapForMessage( json );
		let waitSubjs = new Rx.Subject();
		let connectionUseHandle = this.incUse();
		transaction.waitFor( waitSubjs, "serializing p2p" );
		return (
			this._keyState.createSendKeysThen()
				.then( ( { encKey, macKey, senderChainIndex, publicKeyBuffer } ) =>
					Promise.all( [
						this._encryptThen( json, encKey, optionalKey2KindMap ),
						this._makeEncryptedHeaderThen( publicKeyBuffer, senderChainIndex )
					] )
						.then( ( [ encryptedBuffer, encryptedHeader ] ) =>
							this._serializeEncryptedAndMacThen( encryptedBuffer, encryptedHeader, macKey )
						)
				).then( serialized => {
					this._send( serialized, transaction );
					waitSubjs.onCompleted();
					this.decUse( connectionUseHandle );
				} )
		);
	}

	_send( buffer, transaction ) {
		this._unauthConnection.send( {
				to: this._connectionId,
				body: Buffer.concat( [ buffer ] ).toString( "base64" )
			},
			transaction
		);
	}

	_encryptThen( json, encKey, optionalKey2KindMap ) {
		return (
			serializeObjectWithKeysToNewManagedBufferThen( json, optionalKey2KindMap )
				.then( plainMB =>
					ssgCrypto.encryptThen( plainMB, encKey, true, this._econfig )
						.then( encryptedBuffer => {
							plainMB.dispose();
							return encryptedBuffer;
						} )
				)
		);
	}

	_makeEncryptedHeaderThen( publicKeyBuffer, senderChainIndex ) {
		let chainIndexBuffer = new Buffer( 4 );
		chainIndexBuffer.writeUInt32LE( senderChainIndex, 0 );
		let plainHeader = Buffer.concat( [ publicKeyBuffer, chainIndexBuffer ] );
		return (
			this._keyState.getHeaderKeyThen()
				.then( encKey =>
					ssgCrypto.encryptThen( plainHeader, encKey, true, this._econfig )
				)
		);
	}

	_serializeEncryptedAndMacThen( encryptedBuffer, encryptedHeader, macKey ) {
		let macMessage = Buffer.concat( [ encryptedHeader, encryptedBuffer ] );
		return (
			ssgCrypto.makeHmacCodeThen( macKey, macMessage, this._econfig )
				.then( mac => Buffer.concat( [ macMessage, mac ] ) )
		);
	}

	_getKeyMapForMessage( json ) {
		switch ( json.type ) {
			case "mek":
				return {
					"data.key": KEY_KINDS.SYMMETRIC_ENCRYPTION
				};
			default:
				return {};
		}
	}

//override
	_parseMessageThen( body, index ) {
		return Promise.resolve( {
			signature: new Buffer( 0 ),
			from: "",
			body,
			index,
			content: body
		} );
	}
}

export class ClientServerJSONP2PReceiver extends ClientServerOrdered {
	constructor( apiUrlBase, connectionId, keyState, nextIndex, econfig, onError ) {
		let signer = {
			getSignByteLength: index => { throw new Error( "Not implemented" ); },
			makeSignThen: ( bs, index ) => { throw new Error( "Not implemented" ); }
		};
		super( apiUrlBase, connectionId, "", nextIndex, signer );
		this._econfig = econfig;
		this._keyState = keyState;
		this._onError = onError;
		//Do not use already impemented verification mechanism
		this.addParticipant(
			"", {
				getSignByteLength: () => 0,
				verifyThen: () => Promise.resolve( true )
			}
		);
		this.onOrderedMessage( this._gotEncryptedMessage.bind( this ) );
	}

	tryListen( cb ) {
		this._unauthConnection.tryListenAndDelete( this._index, cb );
	}

	_gotEncryptedMessage( from, body, index, fullBody ) {
		if ( !this._onJsonMessage ) {
			throw new Error( "No onJsonMessage handler" );
		}
		if ( !body ) {
			return this._onJsonMessage( body, index ) || Promise.resolve();
		}

		return this._parseEncryptedMessageThen( body ).then( parsed =>
			this._keyState.createReceiveKeysThen(
				parsed.publicKeyBuffer,
				parsed.chainIndex,
				index + 1, //nextIndex
				keys => this._processUsingKeysThen( keys, parsed, index )
			)
		).catch( error => {
			this._onError( error );
		} );
	}

	_parseEncryptedMessageThen( body ) {
		//TODO: get hash size from config
		let dhPubBufferSize = this._econfig.getKeySize( KEY_KINDS.KEY_EXCHANGE_PUBLIC );
		let headerSize = 4 + dhPubBufferSize;
		let encryptedHeaderSize = ssgCrypto.getEncryptedSize( headerSize, true, this._econfig );
		if ( body.length < encryptedHeaderSize ) {
			throw new Error( "Message body < encryptedHeaderSize" );
		}

		let macSize = 32;
		let mac = body.slice( body.length - macSize );
		let macedBody = body.slice( 0, body.length - macSize );

		let encryptedHeader = macedBody.slice( 0, encryptedHeaderSize );
		let encryptedMessage = macedBody.slice( encryptedHeaderSize );
		return (
			this._keyState.getHeaderKeyThen()
				.then( headerKey => ssgCrypto.decryptToNewBufferThen(
						encryptedHeader, headerKey, true, this._econfig
					) )
				.then( header => ( {
					chainIndex: header.readUInt32LE( dhPubBufferSize ),
					publicKeyBuffer: header.slice( 0, dhPubBufferSize ),
					mac, macedBody, encryptedMessage
				} ) )
		);
	}

	_processUsingKeysThen( { macKey, encKey }, parsed, index ) {
		return (
			this._verifyAndDecryptThen( macKey, encKey, parsed )
				.then( obj => {
					if ( !this._onJsonMessage ) {
						throw new Error( "Got unhandled message" );
					}

					let res = this._onJsonMessage( obj, index );
					if ( res && res.subscribe ) {
						res = new Promise( ( resolve, reject ) => {
							res.subscribe( resolve, reject );
						} );
					} else if ( !res || !res.then ) {
						res = Promise.resolve( res );
					}

					return res.then( result => {
						macKey.dispose();
						encKey.dispose();
						return result;
					} );
				} )
		);
	}

	_verifyAndDecryptThen( macKey, encKey, parsed ) {
		let messageMB = ssgCrypto.createManagedBuffer(
			ssgCrypto.getDecryptedSize( parsed.encryptedMessage.length, this._econfig )
		);
		return (
			ssgCrypto.makeHmacCodeThen( macKey, parsed.macedBody, this._econfig )
				.then( mac => {
					if ( !mac.equals( parsed.mac ) ) {
						console.error( "Mac code not match" );
						throw new Error( "Mac code not match" );
					}
					return ssgCrypto.decryptToManagedBufferThen(
						parsed.encryptedMessage, messageMB, encKey, true, this._econfig
					);
				} )
				.then( () => {
					let json = deserializeJSONOnlyFromManagedBuffer( messageMB );
					let keyMap = this._getKeyMapForMessage( json );
					return deserializeObjectWithKeysFromManagedBufferThen(
						messageMB, keyMap, this._econfig
					).then( obj => { messageMB.dispose(); return obj; } );
				} )
		);
	}

	onJsonMessage( func ) {
		if ( typeof func !== "function" ) {
			throw new Error( "onJsonMessage function required" );
		}
		if ( this._onJsonMessage ) {
			throw new Error( "onJsonMessage already set" );
		}
		this._onJsonMessage = func;
	}

	listenAsync( ) {
		return this._tryListenUntilSuccessAsync( this );
	}

	_decryptThen( from, body, index, keyThen ) {
		let resultMB = ssgCrypto.createManagedBuffer( ssgCrypto.getDecryptedSize( body.length, this._econfig ) );
		return (
			keyThen
				.then( key =>
					this._isDisposed
					? resultMB.dispose()
					: ssgCrypto.decryptToManagedBufferThen(
						body,
						resultMB,
						key,
						true,
						this._econfig
					).then( () => resultMB )
				)
		);
	}

	_tryListenUntilSuccessAsync( connection ) {
		return callUntilSuccessAsync( connection.tryListen.bind( connection ) );
	}

	_getKeyMapForMessage( json ) {
		switch ( json.type ) {
			case "mek":
				return {
					"data.key": KEY_KINDS.SYMMETRIC_ENCRYPTION
				};
			default:
				return {};
		}
	}

	//override
	_parseMessageThen( body, index ) {
		return Promise.resolve( {
			signature: new Buffer( 0 ),
			from: "",
			body,
			index,
			content: body
		} );
	}
}
