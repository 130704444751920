import React from "react";

import serviceLocator from "../../../api/services/locators/worker.client.js";
import Qr from "../../components/qr.jsx";

class CreateUserSystemView extends React.Component {
	constructor() {
		super();
		this.state = {};
		this._service = serviceLocator();
	}

	componentWillMount() {
		this._subscription = (
			this._service.isServerConfiguredAsync()
				.flatMap( isConfigured => isConfigured
					? Rx.Observable.just( false )
					: this._service.createUserSystemCreationTokenAsync()
				)
				.subscribe( token => {
					if ( !token ) {
						this.setState( { isConfigured: true } );
						return;
					}
					this.setState( { token } );
				} )
		);
	}

	componentWillUnmount() {
		this._subscription.dispose();
	}

	render() {
		if ( this.state.isConfigured ) {
			return <span>User system already configured</span>;
		}

		if ( !this.state.token ) {
			return <span>Wait...</span>;
		}
		return (
			<span>
				{this.state.token}
				<Qr code={this.state.token} />
			</span>
		);
	}
}

export default CreateUserSystemView;
