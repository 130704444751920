import Rx from "rx";
import _ from "lodash";
import ssgCrypto,{KEY_KINDS} from "ssg.crypto";

import configuration from "../../common/configuration.js";
import Contact from "../models/contact.js";
import Multicast from "../transport/multicast.js";
import ClientServerUser from "../transport/client.server.user.js";
import profileServiceLocator from "./locators/profile.js";
import contactServiceLocator from "./locators/contact.js";
import currentUserServiceLocator from "./locators/current.user.js";
import ContactsServiceCommon from "./contact.common.js";
import {
	deserializeFromBase64StringAsync,
	serializeMessageToBase64StringAsync,
	sendMessageAsync,
	receiveMessageAsync
} from "../models/message/technical.js";
import MailboxSender from "../transport/mailbox.sender.js";
import MailboxReceiver from "../transport/mailbox.receiver.js";

class AdminService extends ContactsServiceCommon {
	constructor( ) {
		super( "admin" );
		this._profileService = profileServiceLocator();
		this._contactService = contactServiceLocator();
		this._userConnectionPromise = new Promise( resolve => {
			configuration.monitorSetConfiguration().take(1).subscribe( () => {
				resolve( this._userConnection = new ClientServerUser() );
			} );
		} );

		this._joinQueue = new Rx.Subject();
		//TODO: super.uninit is not called because init method replace this
		this._joinQueue.concatAll().subscribeOnCompleted(
			() => {
				delete this._joinQueue;
				super.uninitAsync();
			}
		);
	}

	_sendNotifyes( ) {}

	initAsync( encryptionKey, positionBit ) {
		if ( configuration.getBuildMode() !== "enterprise" ) {
			this._adminData = Rx.Observable.just( null );
			this._sharedUserObservable = Rx.Observable.just( { isPrivileged: true, expireDt: null } );
			return Rx.Observable.just();
		}
		this._adminData = new Rx.ReplaySubject( 1 );
		this._joinQueue = new Rx.Subject();
		this._joinQueue.concatAll().subscribe();
		this._activatedUserSinceLastSave = new Rx.BehaviorSubject( [] );
		this._sharedUserObservable = new Rx.ReplaySubject(1);

		return super.initAsync( encryptionKey, positionBit ).tap( () => {
			if ( this._contacts && this._contacts.length ) {
				this.initContact( this._contacts[ 0 ].id );
			}
			this._observeUser().subscribe( this._sharedUserObservable );

			this._subscription = (
				Rx.Observable.combineLatest(
					this.observeContactsFullyLoad().flatMap( () => this.getContactsAsync() ),
					this._profileService.getProfileAsync(),
					( contacts, { admin } ) => ( { contacts, admin } )
				)
					.subscribe( ( { contacts, admin } ) => {
						if ( !contacts.length && admin ) {
							debugger;
						}
						this._createSharedUsertableObservable();
						this._observeUnactivatedUsers();
						if ( ( contacts.length === 0 ) || !admin ) {
							this._adminData.onNext( null );
							return;
						}
						this._adminData.onNext( admin );
					} )
			);
		} );
	}

	_createSharedUsertableObservable( ) {
		this._sharedUserTableObserbable = (
			this._adminData
				.filter( data => !!data )
				.concatMap( ( { tableToken } ) =>
					receiveMessageAsync( tableToken )
				)
				.map( users => {
					if ( !users ) {
						return users;
					}
					if ( users.type !== "users" ) {
						return null;
					}
					return users.users;
				} )
				.shareReplay( 1 )
		);
	}

	_observeUnactivatedUsers( ) {
		let mailboxReceivers = Object.create( null );

		this._unactivtedUsersSubscription = (
			this._sharedUserTableObserbable
				.subscribe( users => {
					_.forEach( users, user => {
						if ( user.isActivated && mailboxReceivers[ user.userId ] )  {
							mailboxReceivers[ user.userId ].dispose();
							delete mailboxReceivers[ user.userId ];
						}

						if ( !user.isActivated && user.activationReceiverToken && !mailboxReceivers[ user.userId ] )  {
							mailboxReceivers[ user.userId ] = new MailboxReceiver(
								user.activationReceiverToken, 0, msg => {
									let array = this._activatedUserSinceLastSave.getValue();
									array.push( user.userId );
									this._activatedUserSinceLastSave.onNext( array );
									mailboxReceivers[ user.userId ].dispose();
									delete mailboxReceivers[ user.userId ];
								}, () => {}
							);
						}
					} );
				} )
		);
	}

	uninitAsync( ) {
		this._subscription && this._subscription.dispose();
		this._adminData && this._adminData.onCompleted && this._adminData.onCompleted();
		this._joinQueue && this._joinQueue.onCompleted();
		this._unactivtedUsersSubscription && this._unactivtedUsersSubscription.dispose();
		return super.uninitAsync();
	}

	observeIsAdmin( ) {
		return this._adminData.map( data => !!data ).distinctUntilChanged();
	}

	updateUserTableAsync( addedUsers, editedUsers, deletedUserIds ) {
		this._checkUsers( addedUsers, editedUsers, deletedUserIds );
//TODO: ensure that editing table is of latest version
		return (
			this.observeContactsFullyLoad()
			.flatMap( ( [ admin ] ) => {
				if ( !admin ) {
					debugger;
					return Rx.Observable.throw( new Error( "No admin contact" ) );
				}
				let subj = new Rx.ReplaySubject();
				this._joinQueue.onNext( Rx.Observable.defer( () => {
					this._adminData.filter( data => !!data ).take( 1 )
						.concatMap( ( { tableToken, userKey } ) => receiveMessageAsync( tableToken )
							.flatMap( tableMessage => this._getMulticastOrNullAsync( admin )
								.flatMap( multicast => multicast
									? this._doUpdateUserTableAsync(
										addedUsers, editedUsers, deletedUserIds, admin,
										tableMessage.users, userKey, multicast
									)
									: Rx.Observable.just( false )
								)
							)
						)
						.subscribe(
							result => { subj.onNext(); },
							error => { subj.onError( error ); },
							() => { subj.onCompleted(); }
						);
					return subj;
				} ) )
				return subj;
			} )
		);
	}

	_applyActivatedUsers( users ) {
		_.forEach( this._activatedUserSinceLastSave.getValue(), userId => {
			let user = _.find( users, { userId } );
			if ( user ) {
				user.isActivated = true;
				delete user.activationSenderToken;
			}
		} );
		return users;
	}

	_checkUsers( addedUsers, editedUsers, deletedUserIds ) {
		if ( !_.isArray( addedUsers ) ) {
			throw new Error( "addedUsers expected to be an array" );
		}

		if ( !_.isArray( editedUsers ) ) {
			throw new Error( "editedUsers expected to be an array" );
		}

		if ( !_.isArray( deletedUserIds ) ) {
			throw new Error( "deletedUserIds expected to be an array" );
		}

		if ( !_.every( addedUsers, ({nickname}) => typeof nickname === "string" ) ) {
			throw new Error( "Invalid addedUsers table" );
		}

		if ( !_.every( editedUsers, ( { nickname } ) => typeof nickname === "string" ) ) {
			throw new Error( "Invalid editedUsers table" );
		}

		if ( !_.every( deletedUserIds, userId => typeof userId === "string" ) ) {
			throw new Error( "Invalid userId table" );
		}
	}

	_doUpdateUserTableAsync( addedUsers, editedUsers, deletedUserIds, admin, currentUsers, userKey, multicast ) {
		let { econfig } = multicast;
		return (
			this._inviteNewUsersAsync( addedUsers, econfig )
				.flatMap( serverUsers => this._updateUsersOnServerAsync(
					addedUsers, editedUsers, deletedUserIds, userKey
				) )
				.flatMap( () => this._sendAdminInvitesAsync( editedUsers, currentUsers, multicast ) )
				.flatMap( () => this._sendRemovedMessagesAsync( deletedUserIds, currentUsers ) )
				.tap( () => {
					//This will edit currentUsers object
					this._applyActivatedUsers( currentUsers );
					this._activatedUserSinceLastSave.onNext( [] );
					this._applyEditUsers( currentUsers, addedUsers, editedUsers, deletedUserIds );
				} )
				.flatMap( () => this._replaceClientUserTableAsync(
					currentUsers,
					multicast,
					userKey
				) )
				.flatMap( () => this._removeNonadminsFromMulticastAsync(
					currentUsers, econfig, multicast
				) )
		);
	}

	_applyEditUsers( users, addedUsers, editedUsers, deletedUserIds ) {
		_.forEach( addedUsers, user => {
			users.push( user );
		} );
		_.forEach( editedUsers, user => {
			let index = _.findIndex( users, { userId: user.userId } );
			if ( !~index ) {
				return;
			}
			users[ index ] = { ...users[ index ], ...user };
		} );
		_.forEach( deletedUserIds, userId => {
			let index = _.findIndex( users, { userId } );
			if ( !~index ) {
				return;
			}
			users.splice( index, 1 );
		} );
	}

	_inviteNewUsersAsync( newUsers, econfig ) {
		return (
			Rx.Observable.fromArray( newUsers )
				.concatMap( user => Rx.Observable.defer( () =>
					this._inviteNewUserAsync( user, econfig )
				) )
				.toArray()
		);
	}

	_inviteNewUserAsync( user, econfig ) {
		let apiUrlBase = configuration.getSocketBase();
		let senderAdditionalData = {
			nickname: user.nickname,
			type: "activation",
			apiUrlBase,
			config: configuration.toJSON()
		};
		return (
			this._addNewUserToContactsConditionalyAsync( user, econfig )
				.flatMap( () => Rx.Observable.fromPromise(
					ssgCrypto.createRandomBase64StringThen( 32 )
				) )
				.tap( userId => {
					senderAdditionalData.userId = userId;
					senderAdditionalData.contactInviteToken = user.inviteToken;
					delete user.inviteToken;
				} )
				.flatMap( userId =>
					MailboxReceiver.createTokenPairAsync(
						apiUrlBase, econfig, senderAdditionalData
					).map( ( { senderToken: activationSenderToken, receiverToken: activationReceiverToken } ) =>
						( { userId, activationSenderToken, activationReceiverToken } )
					)
			)
				.tap( ( { userId, activationSenderToken, activationReceiverToken } ) => {
					user.activationSenderToken = activationSenderToken;
					user.activationReceiverToken = activationReceiverToken;
					user.userId = userId;
				} )
		);
	}

	_addNewUserToContactsConditionalyAsync( user, econfig ) {
		if ( !user.isAddContact ) {
			return Rx.Observable.just();
		}
		delete user.isAddContact;

		return (
			this._profileService.getProfileAsync()
				.flatMap( ( { nickname } ) =>
					this._contactService.createContactAndInviteAsync(
						user.nickname || "unnamed", nickname || "Invited Contact"
					)
				)
				.tap( contact => {
					user.inviteToken = contact.inviteToken;
				} )
		);
	}

	_removeNonadminsFromMulticastAsync( users, econfig, multicast ) {
		let actualInviteIdHash = Object.create( null );
		_.forEach(
			users,
			user => {
				if ( user.type === "admin" ) {
					user.invitePid0 && ( actualInviteIdHash[ user.invitePid0 ] = 1 );
					user.invitePid1 && ( actualInviteIdHash[ user.invitePid1 ] = 1 );
				}
			}
		);
		let removedInvitePidsKeyed = Object.create( null );
		return (
			Rx.Observable.combineLatest(
				multicast.observeParticipantsLatest().take( 1 ),
				multicast.observeInvitesLatest().take( 1 ),
				( ps, invites ) => ( { ps, invites } )
			)
				.flatMap( ( { ps, invites } ) => {
					let pid2Remove = [];
					let invites2Remove = [];
					let thisPid = multicast.getPid();
					for( let pid in ps ) {
						let inviteId = multicast.getInvitePidByParticipantPid( pid ) || "creator";
						if ( ( pid !== thisPid ) && !actualInviteIdHash[ inviteId ] ) {
							removedInvitePidsKeyed[ inviteId ] = 1;
							pid2Remove.push( pid );
						}
					}

					if ( !actualInviteIdHash[
						multicast.getInvitePidByParticipantPid( thisPid ) || "creator"
					] ) {
						pid2Remove.push( thisPid );
					}

					for( let pid in invites ) {
						if ( !actualInviteIdHash[ pid ] ) {
							removedInvitePidsKeyed[ pid ] = 1;
							invites2Remove.push( pid );
						}
					}
					return (
						Rx.Observable.fromArray( invites2Remove )
							.concatMap( pid => Rx.Observable.defer( () =>
								multicast.removeInviteAsync( pid )
						) )
						.concat(
							Rx.Observable.fromArray( pid2Remove )
								.concatMap( pid => Rx.Observable.defer( () =>
									multicast.removeAsync( pid )
								) )
						)
						.toArray()
					);
				} )
				//TODO: remove. These changes would not be applied
				/*.tap( () => {
					_.forEach( users, user => {
						if ( removedInvitePidsKeyed[ user.invitePid0 ] ) {
							delete user.invitePid0;
						}
						if ( removedInvitePidsKeyed[ user.invitePid1 ] ) {
							delete user.invitePid1;
						}
					} );
				} )*/
		);
	}

	_updateUsersOnServerAsync( addedUsers, editedUsers, deletedUserIds, userKey ) {
		return (
			Rx.Observable.fromArray( addedUsers )
				.concatMap( ( { userId, type, expireDt } ) => Rx.Observable.defer( () => {
					return this._userConnection.userCreateAsync( userId, userKey, type !== "user", expireDt )
				} ) )
				.concat(
					Rx.Observable.fromArray( editedUsers )
						.concatMap( ( { userId, type, expireDt } ) => Rx.Observable.defer( () => {
							return this._userConnection.userUpdateAsync( userId, userKey, type !== "user", expireDt )
						} ) )
				)
				.concat(
					Rx.Observable.fromArray( deletedUserIds )
						.concatMap( userId => Rx.Observable.defer( () =>
							this._userConnection.userDeleteAsync( userId, userKey )
						) )
				)
				.toArray()
		);
	}

	_sendAdminInvitesAsync( editedUsers, currentUsers, multicast ) {
		let currentUsersKeyed = _.keyBy( currentUsers, "userId" );
		editedUsers = _.map(
			editedUsers,
			user => ( { ...currentUsersKeyed[ user.userId ], ...user } )
		);
		let admins2Send = _.filter( editedUsers,
			( { type, invitePid0, invitePid1 } ) =>
				( type === "admin" ) &&
				( !invitePid0 || !invitePid1 )
		);
		let { econfig } = multicast;
		return (
			Rx.Observable.fromArray( admins2Send )
				.concatMap( user => Rx.Observable.defer( () =>
				this._getCurrentMailboxTokensAsync( user.activationReceiverToken )
					.filter( tokens => !!tokens )
					.flatMap( ( [ mailboxToken0, mailboxToken1 ] ) =>
						Rx.Observable.combineLatest(
							this._inviteCreateAsync( multicast ),
							this._inviteCreateAsync( multicast ),
							( { token: inviteToken0, pid: pid0 },
								{ token: inviteToken1, pid: pid1 } ) =>
								( { mailboxToken0, mailboxToken1, inviteToken0,
									inviteToken1, pid0, pid1 } )
						)
					)
					.flatMap( ( { mailboxToken0, mailboxToken1, inviteToken0, inviteToken1, pid0, pid1 } ) => {
						let mailboxSender0 = new MailboxSender( mailboxToken0, console.error );
						let mailboxSender1 = new MailboxSender( mailboxToken1, console.error );
						let message0 = { type: "adminInvite", inviteToken: inviteToken0 };
						let message1 = { type: "adminInvite", inviteToken: inviteToken1 };
						return Rx.Observable.combineLatest(
							mailboxSender0.sendMessageAsync( message0 ),
							mailboxSender1.sendMessageAsync( message1 ),
							() => {
								mailboxSender0.dispose();
								mailboxSender1.dispose();
								let index = _.findIndex( currentUsers, { userId: user.userId } );
								currentUsers[ index ].invitePid0 = pid0;
								currentUsers[ index ].invitePid1 = pid1;
							}
						);
					} )
				) )
				.toArray()
		);
	}

	_inviteCreateAsync( multicast ) {
		return (
			multicast.createInviteAsync( "" )
				.flatMap( invite =>
					sendMessageAsync( {...invite, type: "invite" } )
						.map( token => ( { token, pid: invite.tmpPid } ) )
				)
		);
	}

	_getCurrentMailboxTokensAsync( activationReceiverToken ) {
		let lastTokens = null;
		let receiver = new MailboxReceiver(
			activationReceiverToken,
			0, //StartIndex
			message => {
				if ( message.type !== "mailboxTokens" ) {
					return;
				}
				if ( message.mailboxTokens.length !== 2 ) {
					console.error( "Invalid mailbox token count. Ignored" );
					return;
				}
				lastTokens = message.mailboxTokens;
			},
			index => {},
			error => { console.error( error ); }
		);
		return (
			receiver.waitUntilAllProcessedAsync()
				.map( () => {
					receiver.dispose();
					return lastTokens;
				} )
		);
	}

	_sendRemovedMessagesAsync( deletedUserIds, currentUsers ) {
		let keyed = _.keyBy( deletedUserIds );
		let activationTokens = [];

		for ( let i = 0; i < currentUsers.length; i++ ) {
			let { userId, activationReceiverToken } = currentUsers[ i ];
			if ( keyed[ userId ] && activationReceiverToken ) {
				activationTokens.push( activationReceiverToken );
			}
		}

		return (
			Rx.Observable.fromArray( activationTokens )
				.concatMap( activationToken => Rx.Observable.defer( () =>
					this._getCurrentMailboxTokensAsync( activationToken )
						.filter( tokens => !!tokens )
						.concatMap( tokens => Rx.Observable.fromArray( tokens ) )
						.flatMap( senderToken => {
							let mailboxSender = new MailboxSender( senderToken );
							return (
								mailboxSender.sendMessageAsync( { type: "deleteAccount" } )
									.tap( () => { mailboxSender.dispose(); } )
							);
						} )
				) )
				.toArray()
		);
	}

	_replaceClientUserTableAsync( users, multicast, userKey ) {
		_.forEach(
			users,
			user => {
				if ( user.type !== "admin" ) {
					delete user.invitePid0;
					delete user.invitePid1;
				}
			}
		);
		return (
			sendMessageAsync( {
				users,
				type: "users",
				econfig: multicast.econfig
			} )
			.flatMap( tableToken =>
				multicast.sendLatestMessageAsync( {
					type: "updateUserTable", tableToken, userKey
				} )
				.flatMap( ( { index } ) =>
					this._updateAdminDataAsync( { tableToken, userKey, index } )
				)
			)
		);
	}

	_updateAdminDataAsync( admin ) {
		if ( !admin ) {
			this._adminData.onNext( admin );
			return this._profileService.updateProfileAsync( { admin } );
		}
		return this._adminData.take( 1 ).flatMap( currentData => {
			if ( currentData && admin && ( currentData.index > admin.index ) ) {
				return Rx.Observable.just();
			}
			this._adminData.onNext( admin );
			return this._profileService.updateProfileAsync( { admin } );
		} );
	}

	_getCurrentAdminDataAsync( index ) {
		return (
			this._adminData
			//TODO:
			//sometimes this causes user table to stop working
				// .filter( currentData => {
				// 	if ( !currentData || currentData.index === undefined ) {
				// 		return true;
				// 	}
				// 	return currentData.index >= index - 1;
				// } )
				.take( 1 )
		);
	}

	_onMulticastCreated( contact, multicast ) {
		multicast.onSelfMessage( index => {
			this._onSelfMessage( contact, multicast, index );
		} );
		super._onMulticastCreated( contact, multicast );
	}

	_onSelfMessage( contact, multicast, index ) {
		return (
			this._getCurrentAdminDataAsync( index )
				.flatMap( currentData => {
					if ( !currentData || ( currentData.index !== index - 1 ) ) {
						return Rx.Observable.empty();
					}
					let { tableToken, userKey } = currentData;
					return this._updateAdminDataAsync( { tableToken, userKey, index } );
				} )
		);
	}

	_processMessageJsonAndGetModel( msg, fromContact, multicast, index ) {
		let { json } = msg;
		switch( json.type ) {
			case "updateUserTable":
				let { tableToken, userKey } = json;
				return (
					this._adminData.take( 1 )
						.flatMap( currentData => {
							if ( currentData && ( currentData.index >= index ) ) {
								return Rx.Observable.just( null );
							}
							return this._updateAdminDataAsync( { tableToken, userKey, index } );
						} )
				);
			case "requestUserTable":
				return (
					this._getCurrentAdminDataAsync( index )
						.flatMap( currentData => {
							if ( !currentData ) {
								return Rx.Observable.just();
							}
							let { tableToken, userKey } = currentData;
							multicast.sendMessageAsync( {
								type: "updateUserTable",
								tableToken, userKey
							}, index + 1 ).subscribe();
							if ( currentData.index <= index ) {
								return Rx.Observable.just();
							}
							return this._updateAdminDataAsync( { tableToken, userKey, index } );
						} )
				);
		}
	}

	observeUserTable( ) {
		return (
			Rx.Observable.combineLatest(
				this._sharedUserTableObserbable,
				this._activatedUserSinceLastSave,
				users => this._applyActivatedUsers( users )
			)
		);
	}

	joinToAdminAsync( inviteData ) {
		let multicast, contact;
		let subj = new Rx.ReplaySubject();
		this._joinQueue.onNext( Rx.Observable.defer( () => {
			let found = _.find( this._contacts, c => c.sharedId.equals( inviteData.sharedId ) );
			if ( found ) {
				subj.onNext( false );
				subj.onCompleted();
				return (
					this._getMulticastOrNullAsync( found )
						.flatMap( multicast => multicast
							? multicast.removeInviteAsync( inviteData.tmpPid )
							: Rx.Observable.just( null )
						)
				);
			}

			return (
				this.local2ServerTimeAsync( +new Date )
					.flatMap( lastMessageTS =>
						Contact.createFromInviteAsync( inviteData,
							"Admin",
							this._contactType,
							lastMessageTS
						)
					)
					.tap( c => contact = c )
					.flatMap( () => this._insertContactAsync( contact ) )
					.flatMap( () => this._addMulticastAsync( contact, Multicast.tryJoinAsync(
						inviteData.nickname,
						{
							...inviteData, signKey: contact.signKey,
							dhPrivKey: contact.dhPrivKey, pid: contact.pid.toString( "base64" )
						},
						this._getP2pPrivateHandlers(),
						this._isUseMetaWithRights(),
						this._isUseKeychain()
					) ) )
					.tap( m => multicast = m )
					.flatMap( m => m
						? Rx.Observable.just( m )
						: this._deleteContactAsync( contact.id )
					)
					.flatMap( () => multicast
						? multicast.sendMessageAsync( {
							type: "requestUserTable"
						} ).map( () => true )
						: Rx.Observable.just( false )
					)
					.flatMap( shouldWait => this.observeContactList().filter( contacts =>
						!shouldWait || ( contacts[ 0 ] && ( { active: 1, invited: 1, failed: 1 }[ contacts[ 0 ].status ] ) )
					).take( 1 ) )
					.tap( () => {
						},
						error => {
							subj.onError( error );
						},
						() => {
							subj.onNext( true );
							subj.onCompleted();
						}
					)
				);
		} ) );
		return subj;
	}

	_observeUser( ) {
		return (
			this._profileService.mutationObservable
				.filter( profile => !!profile && !!profile.userId )
				.take( 1 )
				.flatMap( ( { userId } ) =>
					Rx.Observable.fromPromise( this._userConnectionPromise )
					.flatMap( userConnection => userConnection.observeUser( userId ) )
				)
		);
	}

	observeExpireDt( ) {
		if (!configuration.getUserExpiration()) {
			return Rx.Observable.just( null ).delay(100);
		}
		return (
			this._sharedUserObservable
				.map( user => user ? user.expireDt: null )
		);
	}

	observeIsPrivileged( ) {
		return (
			this._sharedUserObservable
				.filter( user => !!user )
				.map( ( { isPrivileged } ) => !!isPrivileged )
				.startWith( false )
		);
	}

	isServerConfiguredAsync( ) {
		return (
			Rx.Observable.fromPromise( this._userConnectionPromise )
				.flatMap( userConnection => userConnection.userGetIsConfiguredAsync() )
				.map( ( { isConfigured } ) => isConfigured )
		);
	}

	configureServerAsync( nickname, userId, activationReceiverToken ) {
		let contact, userKey, tableToken, multicast;
		let econfig = configuration.getDefaultEncryptionConfig();
		let apiUrlBase = configuration.getSocketBase();
		return (
			sendMessageAsync( { users: [ {
				nickname,
				userId,
				type: "admin",
				isActivated: true,
				activationReceiverToken,
				invitePid0: "creator",
				invitePid1: "creator"
			} ], type: "users", econfig } )
				.tap( token => { tableToken = token; } )
				.flatMap( () => Contact.createNewAsync( this._contactType, "" ) )
				.tap( c => contact = c )
				.flatMap( () => Rx.Observable.fromPromise(
					ssgCrypto.createRandomBase64StringThen( 32 )
				) )
				.tap( randomString => { userKey = randomString; } )
				.flatMap( () => this._insertContactAsync( contact ) )
				.flatMap( () => this._addMulticastAsync(
					contact,
					Multicast.createNewAsync(
						configuration.getSocketBase(),
						"",
						contact.sharedId,
						contact.pid,
						contact.seedKey,
						contact.dhPrivKey,
						contact.signKey,
						configuration.getDefaultEncryptionConfig(),
						this._getP2pPrivateHandlers(),
						this._isUseMetaWithRights(),
						this._isUseKeychain()
					)
				) )
				.flatMap( m => {
					multicast = m;
					this._adminData.onNext( null );
					return this._updateAdminDataAsync({ tableToken, userKey, index: 0 });
				} )
				.flatMap( () => multicast.sendMessageAsync( {
					type: "updateUserTable",
					tableToken, userKey
				} ) )
				.flatMap( () => this._userConnection.userSetKeyAsync( userKey, "" ) )
				.flatMap( () => this._userConnection.userCreateAsync( userId, userKey, true ) )
		);
	}

	createCreationTokenAsync( ) {
		return sendMessageAsync( {
			type: "createUserSystem",
			econfig: configuration.getDefaultEncryptionConfig(),
			apiUrlBase: configuration.getSocketBase(),
			config: configuration.toJSON()
		} );
	}

	createAliasInviteOrNullAsync( ) {
		if ( !this._contacts[ 0 ] ) {
			return Rx.Observable.just( null );
		}
		return (
			this._getMulticastOrNullAsync( this._contacts[ 0 ] )
				.flatMap( multicast => multicast
					? multicast.createInviteAsync( "", true )
					: Rx.Observable.just( null )
				)
		);
	}

	_deleteContactAsync( contactId ) {
		return (
			super._deleteContactAsync( contactId )
				.flatMap( () =>
					this._updateAdminDataAsync( null )
				)
		);
	}

	sendContactsToUserAsync( userId, userIds ) {
		return (
			Rx.Observable.combineLatest(
				this._sharedUserTableObserbable.take( 1 ),
				this.local2ServerTimeAsync( +new Date ),
				( users, serverTime ) => ( { users, serverTime } )
			)
				.flatMap( ( { users, serverTime } ) => Rx.Observable.fromArray( userIds )
					.concatMap( user2Id => Rx.Observable.defer( () =>
						Contact.createNewAsync( this._contactType, "", serverTime )
							.flatMap( newContact =>
								Multicast.createNewAsync(
									newContact.apiUrlBase,
									"",
									newContact.sharedId,
									newContact.pid,
									newContact.seedKey,
									newContact.dhPrivKey,
									newContact.signKey,
									configuration.getDefaultEncryptionConfig(),
									this._getP2pPrivateHandlers()
								)
									//Skip warning about unset new participant handler
									.flatMap( multicast => {
										multicast.onNewParticipant( () => Rx.Observable.just() );
										return (
											this._sendInviteToUserAsync( multicast, users, userId, user2Id )
												.flatMap( () => this._sendInviteToUserAsync( multicast, users, user2Id, userId ) )
												.flatMap( () => multicast.removeAsync( multicast.getPid() ) )
										);
									} )
							)
					) )
				)
				.toArray()
				.map( () => true )
		);
	}

	_sendInviteToUserAsync( multicast, users, userId, user2Id ) {
		let invite;
		const userSource = _.find( users, { userId } );
		const userDest = _.find( users, { userId: user2Id } );
		if ( !userSource || !userDest || !userDest.activationReceiverToken ) {
			return Rx.Observable.just();
		}
		return (
			multicast.createInviteAsync( userSource.nickname )
				.tap( i => {
					invite = i;
					// invite.globalId = globalId;
				} )
				.flatMap( () => serializeMessageToBase64StringAsync( { ...invite, type: "invite" } ) )
				.tap( () => {
					invite.tmpPrivateKey.dispose();
				} )
			.flatMap( inviteString =>
				this._getCurrentMailboxTokensAsync( userDest.activationReceiverToken )
					.filter( tokens => !!tokens )
					.concatMap( tokens => Rx.Observable.fromArray( tokens ) )
					.flatMap( senderToken => {
						let mailboxSender = new MailboxSender( senderToken );
						let message = { type: "contact", inviteString };
						return (
							mailboxSender.sendMessageAsync( message )
								.tap( () => { mailboxSender.dispose(); } )
						);
					} )

			)
		);
	}

	setContactFailedAsync( contactId, failReason ) {
		//TODO: alert user
		// alert( `Admin is failing ${failReason}. Press OK to restart` );
		return new Rx.Subject();
	}

	getTriggerTos( ) {//No need to include admin contact to trigger as it is fully initialized on init
		return Object.create( null );
	}
}

export default AdminService;
