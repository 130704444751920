import React from "react";
import _ from "lodash";

class TranslationBase {
	constructor( id, title, table ) {
		if ( !table ) {
			throw new Error( "Translation table required" );
		}
		this._table = table;
		this._id = id;
		this._title = title;
	}
	translate( key, ...params ) {
		if ( !_.has( this._table, key ) ) {
			console.warn( `Key ${key} is missing in translation for ${({}).toString.call( this ).match( /\s([a-zA-Z]+)/ )[ 1 ]}` );
			return "";
		}
		let translation = this._table[ key ] || "";
		return translation;
	}
	getAllKeys() {
		return _.keys( this._table );
	}
	getId() {
		return this._id;
	}
	getTitle() {
		return this._title;
	}
}

export default TranslationBase;
