import React from "react";
import ReactDOM from "react-dom";
import Rx from "rx";
import _ from "lodash";

import configuration from "../../../common/configuration.js";
import {bindStateProperty} from "../../../common/utils.js";
import TopLevel from "../common/top.level.view.jsx";
import InputInviteView from "./input.invite.jsx";
import Checkbox from "../../components/checkbox.jsx";
import InputWithHelp from "../../components/input.withhelp.jsx";
import Translation from "../../components/translation.jsx";
import ArrowButton from "../../components/button.continue.jsx";
import history from "../../components/history.js";
import translate from "../../translations/translate.js";
import FullScreen from "../../components/fullscreen.jsx";
import ShakeView from "../shake/index.jsx";
import SetAutoclean from "../options/autoclean.jsx";
import Contact from "../../../api/models/contact.js";
import {DynamicToast} from "../../components/toast.jsx";
import {InternetRetryConfirm} from "../../components/dialogs.jsx";
import ImageButton from "../../components/button.image.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

class RegistrationBodyView extends React.Component {
	constructor() {
		super();
		this._service = serviceLocator();
		this.state = {
			fakeAcc: false,
			shakeVisible: false,
			setAutocleanVisible: false,
			isValid: false,
			inviteData: null,
			commonProfileData: {
				autoclean: configuration.getDefaultAutocleanTime(),
				language: translate.getCurrentLanguage().getId(),
				receiveSound: true
			},
			name1: "",
			name2: "",
			password1: "",
			password2: "",
			inviteToken: "",
			authPassword: ""
		};
		this.onInviteSet         = this.onInviteSet.bind( this );
		this.toggleFakeAccount   = this.toggleFakeAccount.bind( this );
		this.onContinueClick     = this.onContinueClick.bind( this );
		this.onShakeDone         = this.onShakeDone.bind( this );
		this.onBackFromAutoclean = this.onBackFromAutoclean.bind( this );
		this.onSetAutoclean      = this.onSetAutoclean.bind( this );
		this.gotoFirstScreen     = this.gotoFirstScreen.bind( this );
	}

	componentWillMount() {
		if ( this.props.requireInviteCode ) {
			this.context.setQrCallback(this.setInviteCode.bind( this ));
		}
		this.validate();
	}

	componentDidMount() {
	}

	onInviteSet( inviteData ) {
		this.setInviteData( inviteData );
	}

	toggleFakeAccount() {
		this.setState( { fakeAcc: !this.state.fakeAcc }, () => this.validate() );
	}

	componentDidUpdate( prevProps, prevState ) {
		if ( !prevState.fakeAcc && this.state.fakeAcc ) {
			let element = ReactDOM.findDOMNode( this.refs.scrollingNode );
			element.scrollTop = element.scrollHeight;
		}
	}

	setInviteData( inviteData ) {
		this.setState( {
			inviteData,
			name1: ( inviteData && inviteData.nickname ) || "",
			name2: ( inviteData && inviteData.nickname ) || ""
		}, () => this.validate() );
	}

	setInviteCode( text ) {
		let [ inviteToken, authPassword ] = text.split( "|" );
		this.setState( { inviteToken, authPassword } );
	}

	validate() {
		let { fakeAcc, password1, password2, inviteData } = this.state;
		let isValid = ( !fakeAcc || password1 != password2 ) && ( !this.props.requireInviteCode || !!inviteData );
		this.setState( { isValid } );
	}

	getRegistrationData() {
		let { fakeAcc, name1, name2, password1, password2 } = this.state;
		if ( fakeAcc ) {
			return { name1, name2, password1, password2 };
		}
		return { name1, password1 };
	}

	onContinueClick() {
		let { inviteData } = this.state;
		let { requireInviteCode } = this.props;
		let registrationData = this.getRegistrationData();

		if ( !inviteData && requireInviteCode ) {
			return;
		}
		this.setState( {
			shakeProps: { registrationData }
		} );
		if ( !this.state.commonProfileData.autoclean ) {
			this.setState( { setAutocleanVisible: true } );
		} else {
			this.setState( { shakeVisible: true } );
		}
	}

	onBackFromAutoclean() {
		this.setState( { setAutocleanVisible: false } );
	}

	onSetAutoclean( updateData ) {
		this.setState( {
			setAutocleanVisible: false,
			shakeVisible: true,
			commonProfileData: updateData
		} );
	}

	onShakeDone() {
		let { inviteData, commonProfileData, inviteToken } = this.state;
		let registrationData = this.getRegistrationData();

		if ( !inviteData && this.props.requireInviteCode ) {
			throw new Error( "User invite data is missing" );
		}
		this.setState( { isLoading: true } );

		(
			inviteData && inviteData.config
			? this._service.setConfigurationAsync( inviteData.config )
			: Rx.Observable.just()
		)
			.flatMap( () =>
				this._service.registerAndLoginAsync( registrationData, commonProfileData, inviteToken )
			)
			.subscribe(
				() => {
					history.navigateTo( "contacts" );
				},
				error => {
					if ( error.xhr ) {
						this.refs.noInternetRegister.show();
					} else {
						debugger;
						this.refs.toast.show( { content: `${error.message}` } );
						console.error( error );
						this._service.dropAccountAsync().subscribe();
						alert( "There was an error during registration" );
					}
				}
			);
	}

	gotoFirstScreen() {
		this.setState( {
			shakeVisible: false,
			setAutocleanVisible: false,
			isLoading: false,
			isValid: true
		} );
	}

	renderShake() {
		if ( !this.state.shakeVisible ) {
			return null;
		}
		return (
			<FullScreen>
				<ShakeView {...this.state.shakeProps} onDone={ this.onShakeDone }/>
			</FullScreen>
		);
	}

	renderSetAutoClean() {
		if ( !this.state.setAutocleanVisible ) {
			return null;
		}
		return (
			<SetAutoclean
				autocleanTime={ this.state.commonProfileData.autoclean }
				onBack={ this.onBackFromAutoclean }
				onSetAutoclean={ this.onSetAutoclean }
			/>
		);
	}

	isEmpty() {
		let { name1, password1, name2, password2 } = this.state;
		return !name1 && !name2 && !password1 && !password2 && !this.props.requireInviteCode;
	}

	render() {
		let { name1, password1, name2, password2, fakeAcc, inviteToken, authPassword } = this.state;
		return (
			<main ref="scrollingNode">
				<span className="small-text">
					<Translation textId="registration.text" />
				</span>
				{this.props.requireInviteCode && (
					<InputInviteView
						token={inviteToken}
						authPassword={authPassword}
						onTokenChange={bindStateProperty( this, "inviteToken" )}
						onInviteSet={this.onInviteSet}
						type="activation/createUserSystem"
					/>
				)}
				<Panel
					style={{ marginTop: "60px" }}
					name={name1} password={password1}
					nameChange={bindStateProperty( this, "name1", () => this.validate() ) }
					passwordChange={bindStateProperty( this, "password1", () => this.validate() ) }/>

				<div className="fake-account-panel row">
					<Checkbox className="f-right" value={!!fakeAcc} onClick={this.toggleFakeAccount}/>
					<div className="label">
						<Translation textId="registration.fake.checkbox"/>
					</div>
					{fakeAcc && (
						<FakePanel
							showWarning={password1 == password2}
							name={name2} password={password2}
							nameChange={bindStateProperty( this, "name2", () => this.validate() )}
							passwordChange={bindStateProperty( this, "password2", () => this.validate() )}
						/>
					)}
				</div>
				<ArrowButton
					enabled={this.state.isValid}
					caption={ this.isEmpty() ? "registration.skip.button" : "registration.continue.button"}
					handleClick={this.onContinueClick}
					id="continue"
				/>
				<DynamicToast ref="toast"/>
				<InternetRetryConfirm ref="noInternetRegister" onConfirm={this.onShakeDone} onReset={this.gotoFirstScreen}/>

				{ this.renderShake() }
				{ this.renderSetAutoClean() }
			</main>
		);
	}
	static get contextTypes() {
		return { setQrCallback: React.PropTypes.func.isRequired };
	}
}

class Panel extends React.Component {
	render() {
		let { name, nameChange, password, passwordChange, ...divProps } = this.props;
		return (
			<div {...divProps}>
				<InputWithHelp
					type="text"
					value={name}
					onChange={nameChange}
					autofocus={true}
					placeholderTextId="registration.main.nickname.placeholder"
					tooltipTextId="registration.main.nickname.tooltip"
					firstUppercased={true}
					id="name"
				/>
				<InputWithHelp
					type="password"
					value={password} onChange={passwordChange}
					placeholderTextId="registration.main.password.placeholder"
					tooltipTextId="registration.main.password.tooltip"
				/>
			</div>
		);
	}
}

let Warning = props => (
	<div className="warning">
		<Translation textId="registration.fake.warning"/>
	</div>
);

class FakePanel extends React.Component {
	render() {
		let { showWarning, ...props } = this.props;
		return (
			<div>
				<span className="small-text">
					<br/>
					<Translation textId="registration.fake.text" />
				</span>

				<Panel {...props} />
				{showWarning && <Warning />}
			</div>
		);
	}
}

export default RegistrationBodyView;
