import React from "react";

import Prompt from "../common/prompt.jsx";

import serviceLocator from "../../../api/services/locators/worker.client.js";

class AddAssistantView extends React.Component {
  constructor() {
    super();
    this.validateName = this.validateName.bind( this );
    this.onSetName = this.onSetName.bind( this );
    this._service = serviceLocator();
  }

  validateName( name ) {
    return !!name;
  }

  onSetName( name ) {
    this._service.addHelperContactAsync( name ).subscribe( this.props.onBack );
  }

  render() {
    return (
      <Prompt
				onSetValue={this.onSetName}
				onBack={this.props.onBack}
				validate={this.validateName}
				headerTextId="contacts.add.assistant"
				textId="contacts.add.assistant.text"
				placeholderTextId="contacts.add.assistant.placeholder"
        doneButtonTextId="contacts.add.assistant.ok.button"
				defaultValue="Assistant"
				fullScreen={true}
				autofocus={true}
				firstUppercased={true}
			/>
    );
  }
}

export default AddAssistantView;
