import Rx from "rx";

class ObservableModel {
	constructor() {
		this._subject = new Rx.Subject();
	}
	onChange() {
		this._subject.onNext( this );
	}
	getObservable() {
		return this._subject;
	}
}
export default ObservableModel;
