import BaseStorage from "./base.storage.js";
import {unmocked as DefaultStorage} from "./default.storage.js";

let service = null;
class RemoteFileStorage extends BaseStorage {
    constructor( fileName ) {
      super();
      this._fileName = fileName;
    }

    dropDataThen() {
      return new Promise( ( resolve, reject ) => {
          service.fileDropDataAsync( this._fileName ).subscribe( resolve, reject );
      } );
    }

    readAsBufferThen() {
      return new Promise( ( resolve, reject ) => {
          service.fileReadAsBufferAsync( this._fileName ).subscribe( resolve, reject );
      } );
    }

    replaceThen( bufferOrString ) {
      return new Promise( ( resolve, reject ) => {
          service.fileReplaceAsync(  this._fileName, bufferOrString ).subscribe( resolve, reject );
      } );
    }

    readAtPositionThen( start, length ) {
      return new Promise( ( resolve, reject ) => {
          service.fileReadAtPositionAsync( this._fileName, start, length )
              .subscribe( resolve, reject );
      } );
    }

    writeAtPositionThen( bufferOrString, start ) {
        return new Promise( ( resolve, reject ) => {
            service.fileWriteAtPositionAsync( this._fileName, bufferOrString, start )
                .subscribe( resolve, reject );
        } );
    }

    getSizeThen() {
        return new Promise( ( resolve, reject ) => {
            service.fileGetSizeAsync( this._fileName ).subscribe( resolve, reject );
        } );
    }

    renameThen( newFileName ) {
      return new Promise( ( resolve, reject ) => {
        service.fileRenameAsync( this._fileName, newFileName )
          .subscribe( res => {
            this._fileName = newFileName;
            resolve();
          }, reject );
      } );
    }

    getModificationTimeThen() {
      return new Promise( ( resolve, reject ) => {
        service.fileGetModificationTimeAsync( this._fileName ).subscribe( resolve, reject );
      } );
    }

    static isFileExistsAsync( fileName ) {
		return service.fileIsExistsAsync( fileName );
	}

    static isFileExistsThen( fileName ) {
      return new Promise( ( resolve, reject ) => {
        RemoteFileStorage.isFileExistsAsync( fileName ).subscribe( resolve, reject );
      } );
    }
}

function Variant(fileName) {
  if (!global.window) {
    Object.setPrototypeOf(this, RemoteFileStorage.prototype);
    RemoteFileStorage.call(this, fileName);
  } else {
    Object.setPrototypeOf(this, DefaultStorage.prototype);
    DefaultStorage.call(this, fileName);
  }
}

Variant.setService = ( newService ) => {
  service = newService;
};

Variant.isFileExistsAsync = ( fileName ) => {
  if (!global.window) {
    return RemoteFileStorage.isFileExistsAsync( fileName );
  }
  return DefaultStorage.isFileExistsAsync( fileName );
};

Variant.isFileExistsThen = ( fileName ) => {
  if (!global.window) {
    return RemoteFileStorage.isFileExistsThen( fileName );
  }
  return DefaultStorage.isFileExistsThen( fileName );
};

export default Variant;
