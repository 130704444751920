import Rx from "rx";
import _ from "lodash";
import ssgCrypto, {Key,KEY_KINDS} from "ssg.crypto";

import {serializeObject, deserializeObject} from "../../common/serializer.js";

import {ClientServerJSONP2PSender, ClientServerJSONP2PReceiver, KeyState} from "./client.server.json.p2p.js";

class P2PConnection {
	constructor(
		apiUrlBase,
		sid,
		rid,
		pData,
		permanentKeys,
		econfig,
		pid1,
		pid2
	) {
		if ( !( permanentKeys.dhPrivKey instanceof Key ) ) {
			throw new Error( "dhPrivKey must be of type Key" );
		}

		if ( !( permanentKeys.dhPubKey instanceof Key ) ) {
			throw new Error( "dhPubKey must be of type Key" );
		}

		if ( !econfig ) {
			throw new Error( "Econfig required" );
		}

		//This is actually two connection sharing the same encryption key
		this._sid = sid;
		this._rid = rid;
		this._pid1 = pid1;
		this._pid2 = pid2;
		this._apiUrlBase = apiUrlBase;
		this._econfig = econfig;
		this._errorsSubj = new Rx.ReplaySubject();
		this._initKeysThenConnections( pData, permanentKeys );
		this._mekFuncsPerIndex = {};

		this._onPrivate = Object.create( null );
	}

	_storeFunc( data, isReceive ) {
		if ( !isReceive || !this._mekFuncsPerIndex[ data.nextIndex - 1 ] ) {
			return this._keyDataStorer( data );
		}
		let res = this._mekFuncsPerIndex[ data.nextIndex - 1 ]( data );
		if ( res.subscribe ) {
			res = new Promise( ( resolve, reject ) => {
				res.subscribe( resolve, reject );
			} );
		}
		return res;
	}

	_initKeysThenConnections( pData, permanentKeys ) {
		if ( !pData.rootKey ) {
			this._keyStateThen = KeyState.createNewThen(
				this._pid1, this._pid2,
				permanentKeys.seedKey, permanentKeys.dhPrivKey, permanentKeys.dhPubKey,
				this._storeFunc.bind( this ), this._econfig
			);
		} else {
			this._keyStateThen = Promise.resolve( new KeyState(
				pData, permanentKeys.seedKey, permanentKeys.dhPrivKey,
				permanentKeys.dhPubKey, this._storeFunc.bind( this ), this._econfig
			) );
		}

		this._connectionSenderThen = this._keyStateThen.then( keyState =>
			new ClientServerJSONP2PSender(
				this._apiUrlBase, this._sid, keyState, this._econfig
			)
		);
		this._connectionReceiverThen = this._keyStateThen.then( keyState => {
			let receiver = new ClientServerJSONP2PReceiver(
				this._apiUrlBase, this._rid, keyState, pData.nextIndex, this._econfig,
				error => { this._errorsSubj.onNext( "Receive error: " + ( error.message || error ) ); }
			);
			receiver.onJsonMessage( ( body, index ) => {
				return this._onMessage( body, index );
			} );
			return receiver;
		} );
	}

	observeErrors( ) {
		return this._errorsSubj;
	}

	onMEKMessage( func ) {
		if ( this._onMEKMessage ) {
			throw new Error( "already subscribed to onMEKMessage" );
		}
		if ( typeof func !== "function" ) {
			throw new Error( "Handler must be a function" );
		}
		this._onMEKMessage = func;
	}

	onKeyDataUpdate( keyDataStorer ) {
		if ( this._keyDataStorer ) {
			throw new Error( "keyDataStorer already set" );
		}
		this._keyDataStorer = keyDataStorer;
	}

	onPrivateMessage( type, func ) {
		if ( typeof type !== "string" ) {
			throw new Error( "String type required" );
		}
		if ( typeof func !== "function" ) {
			throw new Error( "Handler required" );
		}
		if ( this._onPrivate[ type ] ) {
			throw new Error( `Private message handler for ${type} is already set` );
		}
		this._onPrivate[ type ] = func;
	}

	onConnected( func ) {
		this._connectionReceiverThen.then( receiver => {
			receiver.onConnected( func );
		} );
	}

	sendMEK( data, transaction ) {
		let waitSubj = new Rx.Subject();
		transaction.waitFor( waitSubj, "Sender creation" );
		let keyHandle = data.key.addKeyUse( "Sending mek" );
		this._connectionSenderThen.then( sender => sender.sendThen(
			{
				type: "mek",
				data
			},
			transaction,
			{
				"data.key": KEY_KINDS.SYMMETRIC_ENCRYPTION
			}
		) ).then( () => {
			waitSubj.onCompleted();
			data.key.removeKeyUse( keyHandle );
		} );
	}

	sendPrivate( type, data, transaction ) {
		if ( type === "mek" ) {
			throw new Error( `Invalid message type` );
		}
		if ( typeof type !== "string" ) {
			throw new Error( "Type string required" );
		}
		let waitSubj = new Rx.Subject();
		transaction.waitFor( waitSubj, "Sender creation" );
		this._connectionSenderThen.then( sender => sender.sendThen(
			{
				type,
				data
			},
			transaction
		) ).then( () => {
			waitSubj.onCompleted();
		} ).catch( error => {
			this._errorsSubj.onNext( "Send error: " + ( error.message || error ) );
		} );
	}

	_onMessage( message, index ) {
		if ( !message ) {
			this._errorsSubj.onNext( "Got p2p in connection failed message" );
			debugger;
			return;
		}

		switch( message.type ) {
			case "mek":
				if ( !this._onMEKMessage ) {
					console.warn( `Got MEK message without handler` );
					return;
				}
				this._mekFuncsPerIndex[ index ] = (
					keyData => this._onMEKMessage( message.data, index, keyData )
				);
				return Rx.Observable.just();
			default:
				if ( !this._onPrivate[ message.type ] ) {
					console.warn( `Got message with invalid type "${message.type}"` );
					return;
				}
				return this._onPrivate[ message.type ]( message.data, index );
		}
	}

	listenAsync() {
		return (
			Rx.Observable.fromPromise( this._connectionReceiverThen )
				.flatMap( receiver => receiver.listenAsync() )
		);
	}

	dispose( ) {
		if ( this._isDisposed ) {
			throw new Error( "P2p double dispose" );
		}
		this._isDisposed = true;
		this._connectionReceiverThen.then( receiver => { receiver.dispose(); } );
		this._connectionSenderThen.then( sender => { sender.dispose(); } );
	}
}

export default P2PConnection;
