import React from "react";
import Switch from "../common/switch.jsx";
import Prompt from "../common/prompt.jsx";
import translate from "../../../translations/translate.js";
import Translation from "../../../components/translation.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";
import webNotificationsServiceLocator from "../../../../api/services/locators/web.notifications.js";

class NotificationContentView extends React.Component {
	constructor() {
		super();
		this._webNotificationsService = webNotificationsServiceLocator();
		this._service = remoteServiceLocator();
		this.state = { isEnabled: false };
		this.toggleNotifications = this.toggleNotifications.bind( this );
	}

	componentWillMount() {
		this._notificationsSubscription = (
			this._webNotificationsService.observeNotifications()
				.subscribe( isEnabled => {
					this.setState( { isEnabled } );
				} )
		);
	}

	componentWillUnmount() {
		this._notificationsSubscription.dispose();
	}

	toggleNotifications() {
		this._webNotificationsService.toggleNotifications();
	}

	render() {
		return (
			<div className={ this.props.className }>
				<div className="invite">
					<div className="settings-box">
						<div className="settings-block">
							<Translation textId="web.notifications.content"/>
							<br />
							<br />
							<p><Translation textId="web.settings.menu.notifications.switch"/></p>
							<Switch
								id="lock"
								checked={ this.state.isEnabled }
								onChange={ this.toggleNotifications }
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default NotificationContentView;
