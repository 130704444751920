import React from "react";
import Translation from "../../../components/translation.jsx";

class SendContactsTitleView extends React.Component {
	render() {
		return (
			<div className="navbar grow">
				<div id="navbar" className="navbar-collapse collapse">
					<div className="name">
						<Translation textId="web.send.contacts.title" />
					</div>
				</div>
			</div>
		);
	}
}

export default SendContactsTitleView;
