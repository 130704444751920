let mimeTypes = [
  "application/octet-stream",//default
  "application/atom+xml",
  "application/ecmascript",
  "application/EDI-X12",
  "application/EDIFACT",
  "application/json",
  "application/javascript",
  "application/ogg",
  "application/pdf",
  "application/postscript",
  "application/rdf+xml",
  "application/rss+xml",
  "application/soap+xml",
  "application/font-woff",
  "application/xhtml+xml",
  "application/xml",
  "application/xml-dtd",
  "application/xop+xml",
  "application/zip",
  "application/gzip",
  "application/example",
  "application/x-nacl",
  "audio/basic",
  "audio/L24",
  "audio/mp4",
  "audio/mpeg",
  "audio/ogg",
  "audio/opus",
  "audio/vorbis",
  "audio/vnd.rn-realaudio",
  "audio/vnd.wave",
  "audio/webm",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "message/http",
  "message/imdn+xml",
  "message/rfc822",
  "model/iges",
  "model/mesh",
  "model/vrml",
  "model/x3d+binary",
  "model/x3d+fastinfoset",
  "model/x3d-vrml",
  "model/x3d+xml",
  "text/cmd",
  "text/css",
  "text/csv",
  "text/html",
  "text/javascript",
  "text/plain",
  "text/rtf",
  "text/vcard",
  "text/vnd.abc",
  "text/xml",
  "video/avi",
  "video/mpeg",
  "video/mp4",
  "video/ogg",
  "video/quicktime",
  "video/webm",
  "video/x-matroska",
  "video/x-ms-wmv",
  "video/x-flv",
  "application/x-7z-compressed",
  "application/x-chrome-extension",
  "application/x-deb",
  "application/x-dvi",
  "application/x-font-ttf",
  "application/x-javascript",
  "application/x-latex",
  "application/x-mpegURL",
  "application/x-rar-compressed",
  "application/x-shockwave-flash",
  "application/x-stuffit",
  "application/x-tar",
  "application/x-xpinstall",
  "audio/x-aac",
  "audio/x-caf",
  "image/x-xcf",
  "text/x-gwt-rpc",
  "text/x-jquery-tmpl",
  "application/x-pkcs12",
  "test/application-stream"
];
export default {
	getAll: () => mimeTypes,
	getByIndex: index => mimeTypes[ index ] || mimeTypes[ 0 ],
	getIndex: mimeType => mimeTypes.indexOf( mimeType ) === -1 ? 0 : mimeTypes.indexOf( mimeType )
};
