import React from "react";
import Clipboard from "clipboard";
import { Button } from "react-bootstrap";
import qr from "qr-image";

import Translation from "../../../components/translation.jsx";
import Qr from "../../../components/qr.jsx";
import Toast from "../../../components/toast.jsx";
import Confirm from "../common/confirm.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class BusinessCardContentView extends React.Component {
	constructor() {
		super();
		this.state = {
			isLoaded: false,
			card: undefined,
			isCreateInProgress: false,
			popup: null
		};
		this.doCreateClick = this.doCreateClick.bind ( this );
		this.doDeleteClick = this.doDeleteClick.bind( this );
		this.doDelete = this.doDelete.bind( this );

		this._service = remoteServiceLocator();
	}

	componentDidMount() {
		this._clipboard = new Clipboard( "#copy" );
		this._clipboard.on( "success", event => {
			this.refs.copied.show();
			event.clearSelection();
		} );
		this._clipboard.on( "error", event => {
			this.refs.copy_failed.show();
		} );

		this._bcSubscription = (
			this._service.observeBusinessCards()
				.subscribe( cards => {
					this.setState( {
						isLoaded: true,
						card: cards[ 0 ],
						isCreateInProgress: cards[ 0 ] ? false : this.state.isCreateInProgress
					} );
				} )
		);
	}

	doCreateClick() {
		if ( this.state.isCreateInProgress ) {
			return;
		}
		this.setState( { isCreateInProgress: true } );
		this._service.businessCardCreateAsync( "", "" ).subscribe();
	}

	doDeleteClick() {
		this.setState( {
			popup: () =>
				<Confirm
					titleTextId="web.businessCards.delete.title"
					yesButtonTextId="web.businessCards.delete.button.yes"
					onDone={ this.doDelete }
				/>
		} );
	}

	doDelete() {
		let { card } = this.state;
		if ( !card ) {
			this.setState( { popup: null } );
			return;
		}
		this._service.businessCardDeleteAsync( card ).subscribe( () => {
			this.setState( { popup: null } );
		}, error => {
			console.error( error );
			alert( "Error deleting business card: " + error.message );
		} );
	}

	componentWillUnmount() {
		this._clipboard.destroy();
		if ( this._bcSubscription ) {
			this._bcSubscription.dispose();
		}
	}

	renderPopup() {
		if ( this.state.popup ) {
			return this.state.popup();
		}
		return null;
	}

	getQRDataUri( card ) {
		return (
			"data:image/png;base64," +
			qr.imageSync( card.token, { type: "png" } ).toString( "base64" )
		);
	}

	renderCard( card ) {
		return (
			<div className={this.props.className} >
				<div className="message-code">
					<p>
						<Translation textId="web.businessCards.text1" />
					</p>
					<Qr code={card.token} className="qr-code" />
						<a href={ this.getQRDataUri( card ) } download="business.card.png">
							<Translation textId="web.businessCards.qr.download" />
						</a>
					<p>
						<Translation textId="web.businessCards.text2" />
					</p>
					<div className="code" id="card_token">{card.token}</div>
					<div className="links">
						<a
							href="javascript:;"
							id="copy"
							data-clipboard-target="#card_token"
						>
							<Translation textId="web.businessCards.copy.button" />
						</a>
					</div>
					<div className="text-center">
						<Button
							onClick={this.doDeleteClick}
						>
							<Translation textId="web.businessCards.delete.button" />
						</Button>
					</div>
				</div>
				<Toast textId="web.businessCards.copied.toast" ref="copied"/>
				<Toast textId="web.businessCards.copy_failed.toast" ref="copy_failed"/>
				{ this.renderPopup() }
			</div>
		);
	}

	renderNoCard() {
		return (
			<div className={this.props.className} >
				<div className="message-code">
					<p>
						<Translation textId="web.businessCards.create.text" />
					</p>
					<div className="text-center">
						<Button
							onClick={this.doCreateClick}
							disabled={this.state.isCreateInProgress}
						>
							<Translation textId="web.businessCards.create.button" />
						</Button>
					</div>
				</div>
				{ this.renderPopup() }
			</div>
		);
	}

	render() {
		let { card, showDeleteConfirm } = this.state;
		if ( card && showDeleteConfirm ) {
			return this.renderDeleteConfirm();
		}
		if ( card ) {
			return this.renderCard( card );
		}
		return this.renderNoCard();
	}
}


export default BusinessCardContentView;
