import React from "react";
import Translation from "../../components/translation.jsx";
import OnlineStatus from "../../components/online.status.jsx"

export default ( { total, current } ) => (
    <header>
        <span className="header-caption">
            <Translation textId="common.wait.title" params={[total, current]}/>
        </span>
        <OnlineStatus/>
    </header>
);
