import Rx from "rx";
import React from "react";
import _ from "lodash";

import Messages from "./messages.jsx";
import Footer from "../common/message.write.footer.jsx";
import Translation from "../../../components/translation.jsx";

import remoteServiceLocator from "../../../../api/services/locators/remote.js";

class MainGroupContentView extends React.Component {
	render() {
		if ( !this.props.contact ) {
			return (
				<div className={this.props.className}>
					<Messages contact={null} />
					<Footer contact={null} />
				</div>
			);
		}

		let {contact} = this.props;
		if ( !contact ) {
			return (
				<div className={this.props.className}>
					<p>
						<Translation textId="web.group.loading" />
					</p>
				</div>
			);
		}

		return <MainGroupContentActiveView {...this.props} />;
	}
}

class MainGroupContentActiveView extends React.Component {
	constructor() {
		super();
		this.state = {
			showWarning: false,
			replyMessage: null
		};
		this.onEdit = this.onEdit.bind( this );
		this.onEditDone = this.onEditDone.bind( this );
		this.onReply = this.onReply.bind( this );
		this.onSent = this.onSent.bind( this );
		this.onDrop = this.onDrop.bind( this );
		this.onDragEnter = this.onDragEnter.bind( this );
		this.onDragLeave = this.onDragLeave.bind( this );
		this.onDragOver = this.onDragOver.bind( this );

		this._service = remoteServiceLocator();
	}

	componentDidMount() {
		this._subscription = (
			Rx.Observable.combineLatest(
				this._service.observeGroupInvites( this.props.contact ),
				this.refs.footer.observeText(),
				( invites, text ) => !_.isEmpty( invites ) && text && invites
			)
				.subscribe( invites => {
					if ( !invites && !this.state.showWarning ) {
						return;
					}
					this.setState( {
						showWarning: !!invites,
						invites
					} );
				} )
		);
		this._participantsSubscription = (
			this._service.observeGroupParticipants( this.props.contact )
				.subscribe( participants => {
					this.setState( { participants } );
				} )
		);
	}

	componentWillUnmount() {
		this._subscription && this._subscription.dispose();
		this._participantsSubscription && this._participantsSubscription.dispose();
	}

	renderWarning() {
		if ( !this.state.showWarning ) {
			return null;
		}
		return <Warning invites={ this.state.invites } />;
	}

	onDrop( event ) {
		let { contact } = this.props;
		event.stopPropagation();
		event.preventDefault();
		if ( !contact ) {
			return;
		}
		let {files} = event.dataTransfer;
		let fileArray = [];
		for ( let i = 0; i < files.length; i++ ) {
			fileArray.push( files[ i ] );
		}
		let isAlerted = false;
		Rx.Observable.fromArray( fileArray )
			.concatMap( file => this._service.sendFileAsync( contact, file ).catch( e => {
				isAlerted || alert( e.message );
				isAlerted = true;
				return Rx.Observable.empty();
			} ) )
			.toArray()
			.subscribe();
		this.setState( {
			isDragging: false,
			replyMessage: null
		} );
	}

	onDragEnter( event ) {
		this.setState( {
			isDragging: true,
			replyMessage: null
		} );
		event.preventDefault();
		event.stopPropagation();
	}

	onDragLeave( event ) {
		this.setState( { isDragging: false } );
		event.preventDefault();
		event.stopPropagation();
	}

	onDragOver( event ) {
		this.setState( { isDragging: true } );
		event.preventDefault();
		event.stopPropagation();
	}

	onEdit( editingMessage ) {
		let {text} = editingMessage;
		if ( typeof text !== "string" ) {
			console.warn( "Trying to edit non-text message" );
			return;
		}
		this.setState( { editingMessage } );
	}

	onEditDone( text ) {
		this.setState( { editingMessage: null } );
	}

	onReply( message ) {
		this.setState({replyMessage: message});
	}

	onSent() {
		this.setState( { editingMessage: null, replyMessage: null } );
	}

	renderFooter() {
		let { participants } = this.state;
		let { contact } = this.props;

		return (
			<Footer
				ref="footer"
				contact={contact}
				editingMessage={this.state.editingMessage}
				onEditDone={this.onEditDone}
				replyMessage={this.state.replyMessage}
				onSent={this.onSent}
				renderEmptyGroup={ !!participants && _.size( participants ) <= 1 }
			/>
		);
	}

	render() {
		let { contact, className } = this.props;
		let { participants } = this.state;
		if ( !contact ) {
			return (
				<div className={this.props.className}>
					<Messages contact={null} />
					<Footer contact={null} />
				</div>
			);
		}

		return (
			<div
				className={className + " " + ( this.state.isDragging ? "dragging" : "" ) }
				key={contact.id}
				onDrop={this.onDrop}
				onDragEnter={this.onDragEnter}
				onDragLeave={this.onDragLeave}
				onDragOver={this.onDragOver}
				>
				{this.renderWarning() }
				<Messages
					contact={contact}
					onEdit={this.onEdit}
					editingMessage={this.state.editingMessage}
					onReply={this.onReply}
				/>
				{ this.renderFooter() }
			</div>
		);
	}
}

class Warning extends React.Component {
	render() {
		return (
			<div className="warning">
				<Translation textId="chat.group.invites.notification" />{" "}
				{ _.map( this.props.invites, ({nickname}) => nickname ).join( ", " ) }
			</div>
		);
	}
}

export default MainGroupContentView;
