import Message from "./message.js";
import mimeTypes from "./mimetypes.js";
import Attachment from "./attachment.js";
import BinarySource, {CHUNK_SIZE} from "../binary.source.js";
import BinaryStream from "../binary.stream.js";

class MessageDeserializer {
	constructor( binaryStreamFactory ) {
		if ( typeof binaryStreamFactory !== "function" ) {
			throw new Error( "binaryStreamFactory function required" );
		}

		let binaryStream = binaryStreamFactory();
		if ( !( binaryStream instanceof BinaryStream ) ) {
			throw new Error( `BinaryStream required` );
		}
		if ( binaryStream.position !== 0 ) {
			throw new Error( `Nonzero stream position on deserialize: ${binaryStream.position}` );
		}
		this._binaryStreamFactory = binaryStreamFactory;
		this._attachmentDataStartIndex = 1 << 31;
		this._attachmentDataOffset = 0;
		this._message = new Message();
		this._readThen = new Promise( resolve => {
			this._readResolve = resolve;
		} );
		this._size = binaryStream.size;

		this._readHeaderCb( binaryStream, () => {
			this._readAttachmentsCb( binaryStream, attachments => {
				attachments.forEach( attachment => this._message.addAttachment( attachment ) );
				this._readResolve();
			} );
		} );
	}

	getThen( ) {
		return this._readThen.then( () => this._message );
	}

	_readString( buffer, position ) {
		var out, len, c;
		var char2, char3;

		out = "";
		len = buffer.length;
		while ( position < len ) {
			c = buffer[ position++ ];
			switch ( c >> 4 )
			{
				case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
					// 0xxxxxxx
					out += String.fromCharCode( c );
					break;
				case 12: case 13:
					// 110x xxxx   10xx xxxx
					char2 = buffer[ position++ ];
					/*if ( ( char2 & 0xC0 ) !== 0x80 ) {
						return out;
					}*/
					out += String.fromCharCode( ((c & 0x1F) << 6) | (char2 & 0x3F) );
					break;
				case 14:
					// 1110 xxxx  10xx xxxx  10xx xxxx
					char2 = buffer[ position++ ];
					/*if ( ( char2 & 0xC0 ) !== 0x80 ) {
						return out;
					}*/
					char3 = buffer[ position++ ];
					/*if ( ( char3 & 0xC0 ) !== 0x80 ) {
						return out;
					}*/
					out += String.fromCharCode( ((c & 0x0F) << 12) |
					((char2 & 0x3F) << 6) |
					((char3 & 0x3F) << 0) );
					break;
				default:
					return { result: out, position };
			}
		}

		return { result: out };
	}

	_readStringCb( binaryStream, cb ) {
		let readBuffer = new Buffer( 0 );

		let readRecursive = () => {
			let getLength = Math.min( CHUNK_SIZE, binaryStream.size - binaryStream.position );
			if ( getLength === 0 ) {
				cb( this._readString( readBuffer, 0 ).result );
				return ;
			}
			binaryStream.getNextBytesCb( getLength, buffer => {
				readBuffer = Buffer.concat( [ readBuffer, buffer ] );
				let { result, position: positionIncrement } = this._readString( readBuffer, 0 );
				if ( positionIncrement ) {
					binaryStream.pushBack( buffer.slice( positionIncrement ) );
					cb( result );
					return;
				}
				readRecursive();
			} )
		};
		readRecursive();
	}

	_readHeaderCb( binaryStream, cb ) {
		if ( binaryStream.position !== 0 ) {
			throw new Error( "Header position must be zero" );
		}
		binaryStream.getNextBytesCb( 8, buf => {
			this._chunkCount = buf.readUInt32LE( 0 );
			this._padding = buf.readUInt32LE( 4 );

			this._readStringCb( binaryStream, text => {
				this._message.setText( text );
				cb( text );
			} );
		} );
	}

	_createAttachmentBinaryStream( dataPosition, size ) {
		//TODO: add verification logic: read baseBinaryStream to end and check hash
		if ( this._size < size + dataPosition ) {
			throw new Error( `total message size < attachment size + dataPosition: ${this._size} < ${size} + ${dataPosition}` );
		}
		let baseBinaryStream;
		return new BinaryStream(
			( byteCount, cb ) => {
				if ( !baseBinaryStream ) {
					baseBinaryStream = this._binaryStreamFactory( "buffered" );
					if ( baseBinaryStream.position !== 0 ) {
						throw new Error( `Nonzero stream position on deserialize: ${binaryStream.position}` );
					}
					baseBinaryStream.skipCb( dataPosition, () => {
						baseBinaryStream.getNextBytesCb( byteCount, cb );
					} );
					return;
				}
				baseBinaryStream.getNextBytesCb( byteCount, cb );
			},
			size
		);
	}

	_readAttachmentsCb( binaryStream, cb ) {
		let totalAttachmentDataSize = 0;
		let attachments = [];
		let attachmentDataPosition;
		let finish = () => {
			cb( attachments.map(
				( { posInsideAttachmentData, size, name, mimeType } ) =>
					new Attachment(
						() => this._createAttachmentBinaryStream(
							posInsideAttachmentData + attachmentDataPosition,
							size
						),
						name,
						mimeType
					)
			) );
		};
		let readRecursive = () => {
			if ( binaryStream.position + this._padding + totalAttachmentDataSize >= binaryStream.size ) {
				if ( binaryStream.position + this._padding + totalAttachmentDataSize > binaryStream.size ) {
					console.error( `binaryStream.position + this._padding + totalAttachmentDataSize > binaryStream.size: ${binaryStream.position} + ${this._padding} + ${totalAttachmentDataSize} > ${binaryStream.size}` );
				}
				attachmentDataPosition = binaryStream.position + this._padding;
				finish();
				return;
			}
			binaryStream.getNextBytesCb( 5, buf => {
				let mimeTypeByte = buf[ 0 ];
				let mimeType = mimeTypes.getByIndex( mimeTypeByte );
				let size = buf.readUInt32LE( 1 );

				this._readStringCb( binaryStream, name => {
					attachments.push( {
						posInsideAttachmentData: totalAttachmentDataSize,
						size,
						name,
						mimeType
					} );
					totalAttachmentDataSize += size;
					readRecursive();
				} );
			} );
		};
		readRecursive();
	}
}

export default MessageDeserializer;
