import React from "react";
import Prompt from "../common/prompt.jsx";

class SetPasswordView extends React.Component {
	render() {
		return (
			<Prompt
				onSetValue={this.props.onSetPassword}
				onBack={this.props.onBack}
				headerTextId="options.password.header"
				textId="options.password.text"
				placeholderTextId="options.password.placeholder"
				defaultValue={ this.props.password }
				type="password"
				defaultLatinNoCaps={true}
				autofocus={true}
			/>
		);
	}
}

export default SetPasswordView;
