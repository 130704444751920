import _ from "lodash";
import Rx from "rx";

import configuration from "../../common/configuration.js";
import ssgCrypto, {KEY_KINDS} from "ssg.crypto";

let listItemProperties = [
	"id",
	"type",
	"name",
	"sharedId",
	"unreadCount",
	"mainIndexInaccurate",
	"isAuthenticated",
	"status",
	"apiUrlBase",
	"lastMessageTS",
	"multidescriptionId",
	"globalId",
	"isExternal"
];

let detailsProperties = [
	"dhPrivKey",
	"signKey",
	"econfig",
	"connectionInfo",
	"pid",
	"seedKey",
	"mainIndex",
	"creatorPublicKey",
	"creatorPid",
	"inviteToken",
	"historyContainerId",
	"historyEncryptionSaltKey",
	"historyMacSaltKey",
	"historyFirstDKey",
	"historyLastUKey",
	"historyFromIndex",
	"historyUseUKey",
	"multiinviteData",
	"multiinviteToken",
	"failReason",
	"multiinviteIndex",
	"metaState",
	"cachedMessages",
	"pLen"
];

//pid, rootKey, sChainKey, rChainKey, dhPrivKey, dhPubKey, sChainIndex, rChainIndex, nextIndex
//32+32+32+32+32+32+4+4+4=204
const PARTICIPANT_LENGTH = 204;
export default class Contact {
	constructor( json ) {
		this.isAuthenticated = false;
		this._mutationsObservable = new Rx.Subject();
		this._isUpdatedDetails = false;
		this._waitSubj = Object.create( null );
		if ( !json ) {
			return;
		}
		let keys = _.keys( json );
		for ( let i = 0; i < keys.length; i++ ) {
			if ( this[ keys[ i ] ] ) {
				throw new Error( `Property ${keys[i]} property is hiding prototype member` );
			}
		}
		_.assign( this, json );
		if ( this.connectionInfo && !Buffer.isBuffer( this.connectionInfo ) ) {
			debugger;
			throw new Error( "connectionInfo must be a buffer" );
		}
		this._validate();
	}

	static fromListItem( json ) {
		return new Contact( json );
	}

	static createNewAsync( type, name, lastMessageTS, isExternal ) {
		let econfig = configuration.getDefaultEncryptionConfig();
		return Rx.Observable.fromPromise( Promise.all( [
			ssgCrypto.createRandomBufferThen( configuration.getIdsLength() ),//sharedId
			ssgCrypto.createRandomBufferThen( configuration.getIdsLength() ),//pid
			ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, econfig ),//seedKey
			ssgCrypto.createRandomKeyExchangeKeyPairThen( econfig ),//dhKeyPair
			ssgCrypto.createRandomSignatureKeyPairThen( econfig ),//signKeyPair
			ssgCrypto.createRandomBase64StringThen( configuration.getIdsLength() ),//historyContainerId
			ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, econfig ),//historyEncryptionSaltKey
			ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, econfig ),//historyMacSaltKey
			ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, econfig )//historyFirstDKey
		] ).then(
			( [
				sharedId,
				pid,
				seedKey,
				dhKeyPair,
				signKeyPair,
				historyContainerId,
				historyEncryptionSaltKey,
				historyMacSaltKey,
				historyFirstDKey
			] ) => new Contact( {
				type,
				name,
				sharedId,
				status: "invited",
				unreadCount: 0,
				apiUrlBase: configuration.getApiBase(),
				dhPrivKey: dhKeyPair.privateKey,
				signKey: signKeyPair.privateKey,
				mainIndexInaccurate: 0,
				mainIndex: 0,
				econfig: configuration.getDefaultEncryptionConfig(),
				connectionInfo: new Buffer( 0 ),
				pid,
				multidescriptionId: -1,
				seedKey,
				creatorPublicKey: signKeyPair.publicKey,
				creatorPid: pid,
				historyContainerId,
				historyEncryptionSaltKey,
				historyMacSaltKey,
				historyFirstDKey,
				historyLastUKey: undefined,
				historyFromIndex: 0,
				historyUseUKey: configuration.getHistoryUseUKey(),
				lastMessageTS,
				pLen: PARTICIPANT_LENGTH,
				cachedMessages: {},
				isExternal
			} )
		) );
	}

	static createDisconnectedAsync( invite, name, type, multidescriptionId = -1 ) {
		let econfig = configuration.getDefaultEncryptionConfig();
		return Rx.Observable.fromPromise( Promise.all( [
			ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, econfig ),
			ssgCrypto.createRandomKeyThen( KEY_KINDS.SIGNATURE_PUBLIC, econfig ),
			ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, econfig ),
			ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, econfig ),
			ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, econfig )
		] ).then( ( [ seedKey, creatorPublicKey, historyEncryptionSaltKey,
			historyMacSaltKey, historyFirstDKey ] ) =>
			new Contact( {
				type,
				name,
				sharedId: invite.sharedId,
				status: "disconnected",
				unreadCount: 0,
				apiUrlBase: invite.apiUrlBase,
				mainIndexInaccurate: 0,
				mainIndex: 0,
				econfig: invite.econfig,
				connectionInfo: new Buffer( 0 ),
				pid: null,
				multidescriptionId,
				seedKey,
				creatorPublicKey,
				creatorPid: null,
				historyContainerId: null,
				historyEncryptionSaltKey,
				historyMacSaltKey,
				historyFirstDKey,
				historyUseUKey: configuration.getHistoryUseUKey(),
				historyFromIndex: 0,
				pLen: PARTICIPANT_LENGTH,
				cachedMessages: {}
			} )
		) );
	}

	static createFromInviteAsync( invite, name, type, lastMessageTS ) {
		if ( !invite.metaState ) {
			console.warn( "Meta state in invite is necessary to speed-up contact initialization" );
		}
		if ( !lastMessageTS ) {
			console.warn("lastMessageTS not set");
		}
		return (
			Rx.Observable.fromPromise( Promise.all( [
				ssgCrypto.createRandomBase64StringThen( configuration.getIdsLength() ),
				ssgCrypto.createRandomSignatureKeyPairThen( invite.econfig ), //TODO: no need for public key
				ssgCrypto.createRandomKeyExchangeKeyPairThen( invite.econfig ), //TODO: no need for public key
				ssgCrypto.createRandomBase64StringThen( invite.econfig.getIdLength() ),
				ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, invite.econfig ),
				ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, invite.econfig ),
				ssgCrypto.createRandomKeyThen( KEY_KINDS.INTERMEDIATE, invite.econfig )
			] ).then( ( [ historyContainerId,
					{ privateKey: signKey, publicKey: sPubKey },
					{ privateKey: dhPrivKey, publicKey: dhPubKey },
					pid,
					historyEncryptionSaltKey,
					historyMacSaltKey,
					historyFirstDKey,
				] ) => {
					sPubKey.dispose();
					dhPubKey.dispose();
					return new Contact( {
						type,
						name,
						sharedId: invite.sharedId,
						status: "joining",
						unreadCount: 0,
						mainIndex: 0,
						mainIndexInaccurate: 0,
						apiUrlBase: invite.apiUrlBase,
						dhPrivKey,
						signKey,
						econfig: invite.econfig,
						connectionInfo: new Buffer( 0 ),
						pid,
						multidescriptionId: -1,
						seedKey: invite.seedKey,
						creatorPublicKey: invite.creatorPublicKey,
						creatorPid: invite.creatorPid,
						historyContainerId,
						historyEncryptionSaltKey,
						historyMacSaltKey,
						historyFirstDKey,
						historyFromIndex: 0,
						historyUseUKey: configuration.getHistoryUseUKey(),
						metaState: invite.metaState,
						globalId: invite.globalId,
						lastMessageTS,
						pLen: PARTICIPANT_LENGTH,
						cachedMessages: {}
					} );
				} )
			)
		);
	}

	setDetails( json ) {
		if ( this.hasDetails() ) {
			throw new Error( "Details already set" );
		}
		this.update( json );
		if ( !Buffer.isBuffer( this.connectionInfo ) && ( this.status !== "failed" ) ) {
			debugger;
			throw new Error( "connectionInfo must be a buffer" );
		}
		this._convertConnectionInfo();
		this._validate();
	}

	_convertConnectionInfo() {
		let {pLen, connectionInfo} = this;
		if ( !pLen ) {
			pLen = PARTICIPANT_LENGTH;
		}
		if ( pLen === PARTICIPANT_LENGTH ) {
			return;
		}
		let chunks = [], chunk;
		for ( let index = 0; index < connectionInfo.length; index += pLen ) {
			chunk = new Buffer( PARTICIPANT_LENGTH );
			connectionInfo.copy( chunk, 0, index, index + pLen );
			chunks.push( chunk );
		}
		this.connectionInfo = Buffer.concat( chunks );
		this.pLen = PARTICIPANT_LENGTH;
	}

	getDetailsJSON( ) {
		let details = _.pick( this, detailsProperties );
		details = _.mapValues( details, val => val && val.toJson ? val.toJson() : val );
		details.id = this.id;
		return details;
	}

	hasParticipant( pid ) {
		let pidBuffer = new Buffer( pid, "base64" );
		//TODO: calculate size and used offsets based on config

		let index = 0;
		for( index = 0; index < this.connectionInfo.length; index += PARTICIPANT_LENGTH ) {
			if ( pidBuffer.equals( this.connectionInfo.slice( index, index + 32 ) ) ) {
				return true;
			}
		}

		return false;
	}

	addParticipantData( pid ) {
		if ( !pid ) {
			throw new Error( "pid required" );
		}

		if ( this.hasParticipant( pid ) ) {
			throw new Error( "Participant already exist" );
		}

		if ( !this.connectionInfo ) {
			this.connectionInfo = new Buffer(0);
		}

		this._isUpdatedDetails = true;

		let pidBuffer = new Buffer( pid, "base64" );
		//TODO: calculate size and used offsets based on config
		let buf = new Buffer( PARTICIPANT_LENGTH );//TODO: use secure buffer
		buf.fill(0);
		pidBuffer.copy( buf );
		this.connectionInfo = Buffer.concat( [ this.connectionInfo, buf ] );
		this._validate();
		if ( this._waitSubj[ pid ] ) {
			this._waitSubj[ pid ].onNext();
			this._waitSubj[ pid ].onCompleted();
		}
	}

	waitForParticipantAsync( pid ) {
		if ( this.hasParticipant( pid ) ) {
			return Rx.Observable.just();
		}

		if ( !this._waitSubj[ pid ] ) {
			this._waitSubj[ pid ] = new Rx.ReplaySubject();
		}

		return this._waitSubj[ pid ];
	}

	removeParticipantData( pid ) {
		if ( !pid ) {
			throw new Error( "pid required" );
		}
		if ( !this.connectionInfo ) {
			this.connectionInfo = new Buffer(0);
		}

		this._isUpdatedDetails = true;

		let pidBuffer = new Buffer( pid, "base64" );
		//TODO: calculate size and used offsets based on config

		let index = 0;
		for( index = 0; index < this.connectionInfo.length; index += PARTICIPANT_LENGTH ) {
			if ( pidBuffer.equals( this.connectionInfo.slice( index, index + 32 ) ) ) {
				break;
			}
		}

		if ( index * PARTICIPANT_LENGTH >= this.connectionInfo.length ) {
			throw new Error( "participant not found" );
		}

		this.connectionInfo = Buffer.concat( [
			this.connectionInfo.slice( 0, index ),
			this.connectionInfo.slice( index + PARTICIPANT_LENGTH )
		] );
		this._validate();
	}

	getParticipantIndex( pid ) {
		let pidBuffer = new Buffer( pid, "base64" );

		let index = 0;
		for( index = 0; index < this.connectionInfo.length; index += PARTICIPANT_LENGTH ) {
			if ( pidBuffer.equals( this.connectionInfo.slice( index, index + 32 ) ) ) {
				break;
			}
		}

		if ( index >= this.connectionInfo.length ) {
			return -1;
		}
		return index / PARTICIPANT_LENGTH;
	}

	//pid, (rootKey), sChainKey, rChainKey, dhPrivKey, dhPubKey, sChainIndex, rChainIndex, nextIndex
	createParticipantRootKeyThen( pid ) {
		let startIndex = this.getParticipantIndex( pid );
		if ( startIndex === -1 ) {
			throw new Error( "Participant not found", pid );
		}
		startIndex *= PARTICIPANT_LENGTH;
		startIndex += 32;
		return this._createParticipantKeyThen(
			startIndex,
			configuration.getDefaultEncryptionConfig(),
			KEY_KINDS.INTERMEDIATE
		);
	}

	//pid, rootKey, (sChainKey), rChainKey, dhPrivKey, dhPubKey, sChainIndex, rChainIndex, nextIndex
	createParticipantSChainKeyThen( pid ) {
		let startIndex = this.getParticipantIndex( pid );
		if ( startIndex === -1 ) {
			throw new Error( "Participant not found", pid );
		}
		startIndex *= PARTICIPANT_LENGTH;
		startIndex += 64;
		return this._createParticipantKeyThen(
			startIndex,
			configuration.getDefaultEncryptionConfig(),
			KEY_KINDS.INTERMEDIATE
		);
	}

	//pid, rootKey, sChainKey, (rChainKey), dhPrivKey, dhPubKey, sChainIndex, rChainIndex, nextIndex
	createParticipantRChainKeyThen( pid ) {
		let startIndex = this.getParticipantIndex( pid );
		if ( startIndex === -1 ) {
			throw new Error( "Participant not found", pid );
		}
		startIndex *= PARTICIPANT_LENGTH;
		startIndex += 96;
		return this._createParticipantKeyThen(
			startIndex,
			configuration.getDefaultEncryptionConfig(),
			KEY_KINDS.INTERMEDIATE
		);
	}

	//pid, rootKey, sChainKey, rChainKey, (dhPrivKey), dhPubKey, sChainIndex, rChainIndex, nextIndex
	createParticipantPrivKeyThen( pid ) {
		let startIndex = this.getParticipantIndex( pid );
		if ( startIndex === -1 ) {
			throw new Error( "Participant not found", pid );
		}
		startIndex *= PARTICIPANT_LENGTH;
		startIndex += 128;
		return this._createParticipantKeyThen(
			startIndex,
			configuration.getDefaultEncryptionConfig(),
			KEY_KINDS.KEY_EXCHANGE_PRIVATE
		);
	}

	//pid, rootKey, sChainKey, rChainKey, dhPrivKey, (dhPubKey), sChainIndex, rChainIndex, nextIndex
	createParticipantPubKeyThen( pid ) {
		let startIndex = this.getParticipantIndex( pid );
		if ( startIndex === -1 ) {
			throw new Error( "Participant not found", pid );
		}
		startIndex *= PARTICIPANT_LENGTH;
		startIndex += 160;
		return this._createParticipantKeyThen(
			startIndex,
			configuration.getDefaultEncryptionConfig(),
			KEY_KINDS.KEY_EXCHANGE_PUBLIC
		);
	}

	updateParticipantData( p ) {
		if ( !p.pid ) {
			throw new Error( "pid required" );
		}
		this._validate();
		p = _.clone( p );

		if ( !this.connectionInfo ) {
			this.connectionInfo = new Buffer(0);
		}

		this._isUpdatedDetails = true;

		let index = this.getParticipantIndex( p.pid );

		if ( index === -1 ) {
			throw new Error( "participant not found" );
		}
		index *= PARTICIPANT_LENGTH;
		delete p.pid;
		//pid, rootKey, sChainKey, rChainKey, dhPrivKey, dhPubKey, sChainIndex, rChainIndex, nextIndex

		if ( p.rootBuffer ) {
			if ( p.rootBuffer.length !== 32 ) {
				throw new Error( "rootBuffer expected to be 32 bytes" );
			}
			p.rootBuffer.copy( this.connectionInfo, index + 32 );
		}
		delete p.rootBuffer;

		if ( p.sChainBuffer ) {
			if ( p.sChainBuffer.length !== 32 ) {
				throw new Error( "sChainBuffer expected to be 32 bytes" );
			}
			p.sChainBuffer.copy( this.connectionInfo, index + 64 );
		}
		delete p.sChainBuffer;

		if ( p.rChainBuffer ) {
			if ( p.rChainBuffer.length !== 32 ) {
				throw new Error( "sChainBuffer expected to be 32 bytes" );
			}
			p.rChainBuffer.copy( this.connectionInfo, index + 96 );
		}
		delete p.rChainBuffer;

		if ( p.dhPrivBuffer ) {
			if ( p.dhPrivBuffer.length !== 32 ) {
				throw new Error( "dhPrivBuffer expected to be 32 bytes" );
			}
			p.dhPrivBuffer.copy( this.connectionInfo, index + 128 );
		}
		delete p.dhPrivBuffer;

		if ( p.dhPubBuffer ) {
			if ( p.dhPubBuffer.length !== 32 ) {
				throw new Error( "dhPubBuffer expected to be 32 bytes" );
			}
			p.dhPubBuffer.copy( this.connectionInfo, index + 160 );
			/*if ( !( "nextIndex" in p ) ) {
				TODO: this sometimes got triggered!
				throw new Error( "dhPubBuffer update must come with nextIndex" );
			}*/
		}
		delete p.dhPubBuffer;

		if ( "sChainIndex" in p ) {
			this.connectionInfo.writeUInt32LE( p.sChainIndex, index + 192 );
		}
		delete p.sChainIndex;

		if ( "rChainIndex" in p ) {
			this.connectionInfo.writeUInt32LE( p.rChainIndex, index + 196 );
		}
		delete p.rChainIndex;

		if ( "nextIndex" in p ) {
			let prevIndex = this.connectionInfo.readUInt32LE( index + 200 );
			if ( prevIndex > p.nextIndex ) {
				throw new Error( "Cannot decrease participant nextIndex" );
			}
			this.connectionInfo.writeUInt32LE( p.nextIndex, index + 200 );
		}
		delete p.nextIndex;

		if ( !_.isEmpty( p ) ) {
			throw new Error( `Unknown participant properties ${_.keys( p )}` );
		}
		this._validate();
		this._mutationsObservable.onNext();
	}

	getParticipants( ) {
		if ( !this.connectionInfo ) {
			return [];
		}
		this._validate();

		let participants = [];
		for( let index = 0; index < this.connectionInfo.length; index += PARTICIPANT_LENGTH ) {
			participants.push( {
				pid: this.connectionInfo.slice( index, index + 32 ).toString( "base64" ),
				sChainIndex: this.connectionInfo.readUInt32LE( index + 192 ),
				rChainIndex: this.connectionInfo.readUInt32LE( index + 196 ),
				nextIndex: this.connectionInfo.readUInt32LE( index + 200 )
			} );
		}

		return participants;
	}

	_createParticipantKeyThen( startIndex, econfig, kind ) {
		//TODO: use secure buffer
		let keyBuffer = this.connectionInfo.slice(
			startIndex,
			startIndex + econfig.getKeySize( kind )
		);

		let isZero = true;
		for( let i = 0; i < keyBuffer.length; i++ ) {
			if ( keyBuffer[ i ] ) {
				isZero = false;
				break;
			}
		}
		if ( isZero ) {
			return Promise.resolve( null );
		}
		return ssgCrypto.createKeyFromBufferThen(
			keyBuffer,
			kind,
			econfig
		);
	}

	_isKeySet( pid, offset, keySize ) {
		let index = this.getParticipantIndex( pid );
		if ( !~index ) {
			return false;
		}
		index *= PARTICIPANT_LENGTH;
		let zero = new Buffer( keySize ).fill( 0 );
		let keyBuffer = this.connectionInfo.slice(
			index + offset,
			index + offset + keySize
		);
		return !keyBuffer.equals( zero );
	}

	update( changes ) {
		for ( let i in changes ) {
			if ( ( this[ i ] !== changes[ i ] ) && ~detailsProperties.indexOf( i ) ) {
				this._isUpdatedDetails = true;
			}
		}
		_.assign( this, changes );
		if ( this.connectionInfo && !Buffer.isBuffer( this.connectionInfo ) ) {
			debugger;
			throw new Error( "connectionInfo must be a buffer" );
		}
		this._mutationsObservable.onNext();
	}

	resetUpdatedDetails( ) {
		this._isUpdatedDetails = false;
	}

	isDetailsUpdated( ) {
		return this._isUpdatedDetails;
	}

	forceUpdateDetails() {
		this._isUpdatedDetails = true;
	}

	getListItemJSON( ) {
		return _.pick( this, listItemProperties );
	}

	hasDetails( ) {
		return !!this.dhPrivKey;
	}

	_validate( ) {
		let {connectionInfo, pLen} = this;
		if ( !connectionInfo ) {
			return;
		}
		for ( let index = 0; index < connectionInfo.length; index += pLen ) {
			let nextIndex = connectionInfo.readUInt32LE( index + 200 );
			if ( nextIndex > 100000 ) {
				debugger;
			}
		}
	}

	observeChanges( ) {
		return this._mutationsObservable;
	}

	static hasLisItemProperties( json ) {
		return _.some( listItemProperties, prop => _.has( json, prop ) );
	}

	static hasDetailsProperties( json ) {
		return _.some( detailsProperties, prop => _.has( json, prop ) );
	}

}
